import { Button, List, Row, Typography } from 'antd'
import {
  Primary,
  TableBorderColor,
  White,
} from 'src/features/theme/KdsThemeColor'
import styled from 'styled-components'

const { Text } = Typography

export const ImportDescription = styled(Text)`
  font-weight: bold;
`

export const EmployeesHeaderContainer = styled.div`
  padding: 24px;
  min-height: 85vh;
`

export const SelectFileButtonGroup = styled(Row)`
  margin-top: 24px;
`

export const SelectFileInput = styled.input`
  display: none;
`

export const SelectFileNameWrap = styled.div`
  margin-left: 16px;
`

export const SelectFileName = styled(Text)`
  color: ${Primary};
  padding: 0 8px;
`

export const PlaceNodeWarningMessageWrap = styled.div`
  margin-top: 8px;
`
export const PlaceNodeWarningMessage = styled(Text)`
  white-space: pre-wrap;
`

export const CSVImportButtonGroup = styled.div`
  margin-top: 20px;
`

export const CSVImportButton = styled(Button)``
export const SampleDownloadButton = styled(Button)`
  margin-left: 16px;
`

export const EmployeesHeaderWrap = styled.div`
  width: 100%;
  min-width: 800px;
  margin-top: 32px;
`

export const EmployeesHeader = styled.div`
  width: 100%;
  height: 46px;
  display: flex;
  align-items: center;
  flex-direction: row;
  background: ${White};
  border-bottom: 1px solid ${TableBorderColor};
`

export const HeaderRow = styled.div`
  font-weight: bold;
  padding-left: 15px;
  width: ${props => props.theme.width};
`

export const TableBody = styled<any>(List)`
  width: 100%;
`

export const TableRowWrap = styled(List.Item)`
  position: relative;
  min-height: 46px;
  background-color: ${White};
`

export const TableRow = styled.div`
  width: ${props => props.theme.width};
  padding-left: 16px;
`
