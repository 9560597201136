import { css } from '@emotion/react'
import {
  ImproveStatusStatusEnum,
  IndicatedIssue,
  IndicatedIssueGroup,
  IndicatedIssueStatusEnum,
  User,
  UserGroup,
} from '@ulysses-inc/harami_api_client'
import { Button, Form, Input, Modal, Select, Space, Typography } from 'antd'
import { FormikProps } from 'formik'
import React, { useState } from 'react'
import ExternalLinkSvg from 'src/assets/icons/external_link2.svg?react'
import FormikErrorMessage from 'src/components/formikErrorMessage/FormikErrorMessage'
import { Header } from 'src/components/header/Header'
import { HeaderBackButton } from 'src/components/header/HeaderBackButton'
import { HeaderTitle } from 'src/components/header/HeaderTitle'
import { localStorageKeys } from 'src/constants/localStorageKeys'
import dateUtil from 'src/exShared/util/date'
import ImproveEmailLogsContainer from 'src/features/improveEmailLogs/ImproveEmailLogsContainer'
import {
  EditImproveForm,
  EditImproveProps,
} from 'src/features/improves/improveDetail/EditImproveProps'
import { ExcelOutputButton } from 'src/features/improves/improveDetail/header/ExcelOutputButton'
import { SubmitDropdownButton } from 'src/features/improves/improveDetail/header/SubmitDropdownButton'
import { Primary } from 'src/features/theme/KdsThemeColor'
import styled from 'styled-components'

const { Option } = Select
const { Text } = Typography

const Title = styled.p`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 1.2em;
`

const FormBlock = styled.div`
  padding: 0 20px;
`

const Error = styled(FormikErrorMessage)`
  font-size: 14px;
`

const sendMailPlaceholders = {
  audit: '30日までに改善よろしくお願いします。',
  revise: '指摘に対して改善を行いました。よろしくお願いします。',
}

type OwnProps = EditImproveProps &
  FormikProps<EditImproveForm> & {
    isEditing: boolean
    setIsEditing: React.Dispatch<React.SetStateAction<boolean>>
    handleEditStart: () => void
    validateValues: () => Promise<boolean>
  }

const ImproveDetailPageHeader: React.FC<OwnProps> = props => {
  const {
    isAuditor,
    isPlaceUser,
    isAuditUserGroupUser,
    isFirstAudit,
    isAuditorWorking,
    isEditing,
    handleEditStart,
    improveUUID,
    changeIsShowImproveEmailLogsDrawer,
    getImproveEmailLogs,
    values: { indicatedIssueGroups },
  } = props
  const [visibleSubmitModal, setVisibleSubmitModal] = useState(false)
  const [visibleEmailConfirmModal, setVisibleEmailConfirmModal] =
    useState(false)

  const handleSaveInProgress = () => {
    props.setFieldValue('message', '')
    props.saveDraftImprove(props.values)
    return
  }
  const handleSaveInReviseProgress = () => {
    if (props.isPlaceUser) {
      props.saveDraftRevise(props.values)
      return
    }
  }
  const handleValidateSubmit = async () => {
    if (await props.validateValues()) {
      setVisibleSubmitModal(true)
    }
  }
  const handleModalSubmit = async () => {
    if (!props.values.message) {
      return
    }
    await props.handleSubmit()
    setVisibleSubmitModal(false)
    setVisibleEmailConfirmModal(false)
    props.setIsEditing(false)
  }
  const handleCancelEdit = () => {
    props.resetImprove()
    props.getImprove(props.match.params.improveUUID)
    props.setIsEditing(false)
  }
  const handleComplete = () => {
    props.completeImprove(props.values)
  }
  const handleReviseSubmit = async () => {
    if (await props.validateValues()) {
      setVisibleSubmitModal(true)
    }
  }
  const goImproveExcelConversionLogs = () => {
    props.goImproveExcelConversionLogs(props.history, props.improveUUID)
  }
  const userName = localStorage.getItem(localStorageKeys.loginUserName) || ''
  const loginCompanyName =
    localStorage.getItem(localStorageKeys.loginCompanyName) || ''

  const addImproveExcelConversionLogs = () => {
    props.addImproveExcelConversionLogs(
      props.improveUUID,
      loginCompanyName,
      userName,
    )
  }
  const isCompleted: boolean =
    props.improve.status?.status === ImproveStatusStatusEnum.COMPLETE

  const hasIssuesWithNoRevise = () => {
    // FIXME: 不要な変数宣言。下のループ内での条件にヒットしたら return true すれば良いし、そうじゃなければ関数の最後で return false すれば良い
    let hasIssuesWithNoRevise = false

    // FIXME: 新しく射影を作らないのであれば、map ではなく forEach が妥当
    // FIXME: !indicatedIssueGroups なら return false する
    props.improve.indicatedIssueGroups?.map((group: IndicatedIssueGroup) => {
      if (
        group.indicatedIssues?.find(
          (issue: IndicatedIssue) => issue.revise === undefined,
        )
      ) {
        // FIXME: 上の条件に合致したら return true でループを終了する
        hasIssuesWithNoRevise = true
      }
    })
    // FIXME: return false で良い
    return hasIssuesWithNoRevise
  }

  const AuditorButtonGroup = () => {
    if (isFirstAudit) {
      return (
        <Space direction="horizontal" size={12}>
          <ExcelOutputButton
            handleDownloadFileListClick={goImproveExcelConversionLogs}
            handleOutputClick={addImproveExcelConversionLogs}
          />

          <Button type="primary" ghost onClick={() => handleSaveInProgress()}>
            一時保存
          </Button>

          <SubmitDropdownButton
            text="提出"
            isButtonColored={true}
            improveUUID={improveUUID}
            changeIsShowImproveEmailLogsDrawer={
              changeIsShowImproveEmailLogsDrawer
            }
            getImproveEmailLogs={getImproveEmailLogs}
            handleSubmitClick={() => handleValidateSubmit()}
            disabled={false}
          />
        </Space>
      )
    } else if (isEditing) {
      const canSubmit = (indicatedIssueGroups || []).some(
        (group: IndicatedIssueGroup) => {
          if (!group.indicatedIssues?.length) {
            return false
          }
          return (
            group.indicatedIssues[group.indicatedIssues.length - 1].status
              ?.status === IndicatedIssueStatusEnum.Incomplete
          )
        },
      )
      return (
        <Space direction="horizontal" size={12}>
          <ExcelOutputButton
            handleDownloadFileListClick={goImproveExcelConversionLogs}
            handleOutputClick={addImproveExcelConversionLogs}
          />

          <Button
            type="primary"
            ghost
            onClick={() =>
              Modal.confirm({
                onOk: () => handleCancelEdit(),
                title: '編集した内容が破棄されますがよろしいですか？',
                okText: '実行',
                cancelText: 'キャンセル',
              })
            }
          >
            戻る
          </Button>

          <Button
            type="primary"
            disabled={!canSubmit}
            onClick={() => handleValidateSubmit()}
          >
            差し戻す
          </Button>
        </Space>
      )
    } else {
      return (
        <Space direction="horizontal" size={12}>
          <ExcelOutputButton
            handleDownloadFileListClick={goImproveExcelConversionLogs}
            handleOutputClick={addImproveExcelConversionLogs}
          />

          <Button
            disabled={!(isAuditUserGroupUser || isAuditor)}
            onClick={() => {
              handleEditStart()
            }}
          >
            差し戻し選択
          </Button>

          <SubmitDropdownButton
            text="完了"
            isButtonColored={
              (isAuditUserGroupUser || isAuditor) && !hasIssuesWithNoRevise()
            }
            improveUUID={improveUUID}
            changeIsShowImproveEmailLogsDrawer={
              changeIsShowImproveEmailLogsDrawer
            }
            getImproveEmailLogs={getImproveEmailLogs}
            handleSubmitClick={() =>
              Modal.confirm({
                onOk: () => handleComplete(),
                title: 'すべての指摘事項の改善が完了しましたか？',
                content: '完了したことが現場に通知されます',
                okText: '完了',
                cancelText: 'キャンセル',
              })
            }
            disabled={
              !(isAuditUserGroupUser || isAuditor) || hasIssuesWithNoRevise()
            }
          />
        </Space>
      )
    }
  }

  const PlaceUserButtonGroup = () => {
    return (
      <Space direction="horizontal" size={12}>
        <ExcelOutputButton
          handleDownloadFileListClick={goImproveExcelConversionLogs}
          handleOutputClick={addImproveExcelConversionLogs}
        />

        <Button
          type="primary"
          ghost
          disabled={!isPlaceUser}
          onClick={() => handleSaveInReviseProgress()}
        >
          一時保存
        </Button>

        <SubmitDropdownButton
          text="提出"
          isButtonColored={!!isPlaceUser}
          improveUUID={improveUUID}
          changeIsShowImproveEmailLogsDrawer={
            changeIsShowImproveEmailLogsDrawer
          }
          getImproveEmailLogs={getImproveEmailLogs}
          handleSubmitClick={() => handleReviseSubmit()}
          disabled={!isPlaceUser}
        />
      </Space>
    )
  }

  const CompleteButtonGroup = () => {
    return (
      <Space direction="horizontal" size={12}>
        <ExcelOutputButton
          handleDownloadFileListClick={goImproveExcelConversionLogs}
          handleOutputClick={addImproveExcelConversionLogs}
        />

        <Button
          onClick={() => {
            changeIsShowImproveEmailLogsDrawer(true)
            getImproveEmailLogs(improveUUID)
          }}
        >
          提出履歴
        </Button>
        <ImproveEmailLogsContainer improveUUID={improveUUID} />
      </Space>
    )
  }

  const getFieldName = (idx: number, iig: IndicatedIssueGroup): string => {
    const lastIndicatedIssueIndex: number = iig.indicatedIssues
      ? iig.indicatedIssues.length - 1
      : 0
    return `indicatedIssueGroups[${idx}]['indicatedIssues'][${lastIndicatedIssueIndex}]`
  }
  const updateReviseDueDate = (userGroupId?: string) => {
    props.improve?.indicatedIssueGroups?.map(
      (g: IndicatedIssueGroup, idx: number) => {
        if (
          g.indicatedIssues &&
          g.indicatedIssues[g.indicatedIssues.length - 1]?.revise?.dueDate
        ) {
          props.setFieldValue(
            `${getFieldName(idx, g)}['revise']['dueDate']['userGroupId']`,
            Number(userGroupId),
          )
          props.setFieldValue(
            `${getFieldName(idx, g)}['revise']['dueDate']['improveStatus']`,
            Number(ImproveStatusStatusEnum.AUDITOR_WORKING),
          )
        }
      },
    )
  }

  const emailPopupTitle = isAuditorWorking
    ? '提出先の指定'
    : '提出メッセージの入力'

  const LogGroup = styled.div`
    display: flex;
    flex-direction: column;
    padding-bottom: 24px;
  `

  const InfoText = styled(Text)`
    padding-bottom: 8px;
  `

  const ContentText = styled(Text)`
    padding: 12px 16px 12px 16px;
    background-color: #f5f5f5;
    white-space: pre-wrap;
  `

  const TitleStyle = styled.div`
    margin-top: -8px;
    font-size: 20px;
    font-family: 'Noto Sans JP', sans-serif;
  `
  const InfoTextBoldStyle = styled.div`
    font-size: 14px;
    font-weight: 700;
    font-family: 'Noto Sans JP', sans-serif;
  `
  const InfoTextStyle = styled.div`
    font-size: 14;
    font-family: 'Noto Sans JP', sans-serif;
  `

  const EmailConfirmTitleGroup = styled.div`
    display: flex;
    flex-direction: row;
    padding-bottom: 24px;
    padding-top: 8px;
    margin-bottom: 24px;
    border-bottom: 1px solid #e9e9e9;
  `
  const EmailConfirmTitleSubGroup = styled.div`
    display: flex;
    flex-direction: row;
    padding-right: 48px;
  `
  const UsersStyledText = styled.div`
    color: #595959;
    padding-top: 8px;
  `

  const renderUserGroupUsers = (isEditModal: boolean, userGroup: UserGroup) => {
    const users = userGroup?.users
    const maxShownUserNumber = 5
    const slicedUsers = users?.slice(0, maxShownUserNumber)
    const otherUsersText =
      users?.length > maxShownUserNumber
        ? `と他${users.length - maxShownUserNumber}名`
        : ''
    const prefix = !isEditModal ? '対象ユーザー : ' : ''
    const postfix = isEditModal ? ' へ送信します' : ''
    if (!users?.length) {
      return
    }

    return (
      <UsersStyledText>
        {prefix}
        {slicedUsers?.map((u: User, i: number) => {
          if (i === 0) {
            return u.name
          }
          return '、' + u.name
        })}
        {otherUsersText}
        {postfix}
      </UsersStyledText>
    )
  }

  const renderSendToAddresses = (title: string, userGroup: UserGroup) => {
    return (
      <LogGroup key={1}>
        <InfoText>
          <InfoTextBoldStyle>{title}</InfoTextBoldStyle>
        </InfoText>
        <ContentText>
          {userGroup?.name}
          {renderUserGroupUsers(false, userGroup)}
        </ContentText>
      </LogGroup>
    )
  }
  const renderEmailContents = (
    <>
      {renderSendToAddresses('提出先', props.values.placeUserGroup)}
      <LogGroup key={2}>
        <InfoText>
          <InfoTextBoldStyle>メッセージ</InfoTextBoldStyle>
        </InfoText>
        <ContentText>{props.values.message}</ContentText>
      </LogGroup>
      {renderSendToAddresses('改善内容の返送先', props.values.auditUserGroup)}
    </>
  )
  const modalFooter = [
    <Button
      key="back"
      onClick={() => setVisibleEmailConfirmModal(false)}
      style={{ minWidth: '132px' }}
    >
      キャンセル
    </Button>,
    <Button
      key="submit"
      type="primary"
      style={{ minWidth: '132px' }}
      onClick={() => {
        setVisibleEmailConfirmModal(false)
        props.handleSubmit()
      }}
    >
      提出
    </Button>,
  ]
  const emailConfirmModal = (
    <Modal
      title="提出先の確認"
      open={visibleEmailConfirmModal}
      onCancel={() => setVisibleEmailConfirmModal(false)}
      footer={modalFooter}
    >
      <TitleStyle>{props.improve.reportName}</TitleStyle>
      <EmailConfirmTitleGroup>
        <EmailConfirmTitleSubGroup>
          <InfoTextBoldStyle>実施日 :&nbsp;</InfoTextBoldStyle>
          <InfoTextStyle>
            {dateUtil.formatYYYYMMDD_JP(props.improve.reportCreatedAt)}
          </InfoTextStyle>
        </EmailConfirmTitleSubGroup>
        <EmailConfirmTitleSubGroup>
          <InfoTextBoldStyle>現場名 :&nbsp;</InfoTextBoldStyle>
          <InfoTextStyle>{props.improve.place?.name}</InfoTextStyle>
        </EmailConfirmTitleSubGroup>
      </EmailConfirmTitleGroup>
      {renderEmailContents}
    </Modal>
  )

  const auditorWorkingModalFooter = [
    <Button
      key="back"
      onClick={() => setVisibleSubmitModal(false)}
      style={{ minWidth: '132px' }}
    >
      キャンセル
    </Button>,
    <Button
      key="submit"
      type="primary"
      style={{ minWidth: '132px' }}
      disabled={
        !props.values.placeUserGroup ||
        !props.values.auditUserGroup ||
        props.values.message === ''
      }
      onClick={() => {
        setVisibleSubmitModal(false)
        setVisibleEmailConfirmModal(true)
      }}
    >
      指定内容を確認
    </Button>,
  ]
  const placeUserWorkingModalFooter = [
    <Button
      key="back"
      onClick={() => setVisibleSubmitModal(false)}
      style={{ minWidth: '132px' }}
    >
      キャンセル
    </Button>,
    <Button
      key="submit"
      type="primary"
      style={{ minWidth: '132px' }}
      disabled={props.values.message === ''}
      onClick={() => {
        updateReviseDueDate(props.improve.auditUserGroup?.id?.toString())
        handleModalSubmit()
      }}
    >
      提出
    </Button>,
  ]

  const editEmailModalForAuditor = (
    <Modal
      title={emailPopupTitle}
      open={visibleSubmitModal}
      onCancel={() => setVisibleSubmitModal(false)}
      footer={auditorWorkingModalFooter}
    >
      <div style={{ fontWeight: 700, paddingBottom: 8, marginTop: -8 }}>
        提出先
      </div>
      <Form.Item colon={false} style={{ marginBottom: '16' }}>
        <Select
          showSearch
          optionFilterProp="children"
          placeholder="ユーザーグループを選択"
          style={{ width: '100%' }}
          onSelect={(userGroupId: string | undefined) => {
            props.setFieldValue(
              'placeUserGroup',
              props.userGroups.find(
                userGroup => userGroup.id?.toString() === userGroupId,
              ),
            )
          }}
          onDeselect={() => props.setFieldValue('placeUserGroup', undefined)}
          value={props.values.placeUserGroup?.id?.toString()}
        >
          {(props.userGroups ?? []).length &&
            props.userGroups.map(userGroup => (
              <Option
                key={userGroup.id?.toString()}
                value={userGroup.id?.toString() || ''}
              >
                {userGroup.name}
              </Option>
            ))}
        </Select>
        {renderUserGroupUsers(true, props.values.placeUserGroup)}
      </Form.Item>
      <div style={{ fontWeight: 700, paddingBottom: 8, marginTop: -8 }}>
        メッセージ
      </div>
      <Form.Item colon={false}>
        <Input.TextArea
          rows={4}
          placeholder={sendMailPlaceholders.audit}
          style={{ width: '100%' }}
          onChange={e => props.setFieldValue('message', e.target.value)}
        />
        <Error name="message" errors={props.errors} touched={props.touched} />
      </Form.Item>
      <div style={{ fontWeight: 700, paddingBottom: 8, marginTop: -8 }}>
        改善内容の返送先
      </div>
      <Form.Item colon={false} style={{ marginBottom: '16' }}>
        <Select
          showSearch
          optionFilterProp="children"
          placeholder="ユーザーグループを選択"
          style={{ width: '100%' }}
          onSelect={(userGroupId: string | undefined) => {
            props.setFieldValue(
              'auditUserGroup',
              props.userGroups.find(
                userGroup => userGroup.id?.toString() === userGroupId,
              ),
            )
          }}
          onDeselect={() => props.setFieldValue('auditUserGroup', undefined)}
          value={props.values.auditUserGroup?.id?.toString()}
        >
          {(props.userGroups ?? []).length &&
            props.userGroups.map(userGroup => (
              <Option
                key={userGroup.id?.toString()}
                value={userGroup.id?.toString() || ''}
              >
                {userGroup.name}
              </Option>
            ))}
        </Select>
        {renderUserGroupUsers(true, props.values.auditUserGroup)}
      </Form.Item>
    </Modal>
  )
  const editEmailMessageModalForPlaceUser = (
    <Modal
      title={emailPopupTitle}
      open={visibleSubmitModal}
      onCancel={() => setVisibleSubmitModal(false)}
      footer={placeUserWorkingModalFooter}
    >
      {renderSendToAddresses('提出先', props.improve.auditUserGroup)}
      <div style={{ fontWeight: 700, paddingBottom: 8 }}>メッセージ</div>
      <Form.Item colon={false}>
        <Input.TextArea
          rows={4}
          placeholder={sendMailPlaceholders.audit}
          style={{ width: '100%' }}
          onChange={e => props.setFieldValue('message', e.target.value)}
        />
        <Error name="message" errors={props.errors} touched={props.touched} />
      </Form.Item>
    </Modal>
  )
  // TODO: 既にデータがあるもので improve.auditUserGroupsが未設定の場合、こちらのモーダルをだし分けるようにしている
  // 一旦、従来のモーダルを表示させている
  // 既存のデータは8月までにユーザに周知して完了ステータスに推移が完了するため、そのタイミングでこちらは削除する
  const editEmailModalForPlaceUser = (
    <Modal
      title={emailPopupTitle}
      open={visibleSubmitModal}
      onCancel={() => setVisibleSubmitModal(false)}
      footer={[
        <Button
          key="back"
          onClick={() => setVisibleSubmitModal(false)}
          style={{ minWidth: '132px' }}
        >
          キャンセル
        </Button>,
        <Button
          key="submit"
          type="primary"
          style={{ minWidth: '132px' }}
          disabled={!props.values.auditUserGroup || props.values.message === ''}
          onClick={() => handleModalSubmit()}
        >
          送信
        </Button>,
      ]}
    >
      <Title>共有したい相手を指定してください</Title>
      <FormBlock>
        <Form.Item
          colon={false}
          label="ユーザーグループ名"
          style={{ marginBottom: '16' }}
        >
          <Select
            showSearch
            optionFilterProp="children"
            placeholder="ユーザーグループを選択"
            style={{ width: '100%' }}
            onSelect={(userGroupId: string | undefined) => {
              props.setFieldValue(
                'auditUserGroup',
                props.userGroups.find(
                  userGroup => userGroup.id?.toString() === userGroupId,
                ),
              )
              updateReviseDueDate(userGroupId)
            }}
            onDeselect={() => props.setFieldValue('auditUserGroup', undefined)}
            value={props.values.auditUserGroup?.id?.toString()}
          >
            {(props.userGroups ?? []).length &&
              props.userGroups.map(userGroup => (
                <Option
                  key={userGroup.id?.toString()}
                  value={userGroup.id?.toString() || ''}
                >
                  {userGroup.name}
                </Option>
              ))}
          </Select>
        </Form.Item>
        <Form.Item colon={false} label="メッセージ">
          <Input.TextArea
            rows={4}
            placeholder={
              isAuditorWorking
                ? sendMailPlaceholders.audit
                : sendMailPlaceholders.revise
            }
            style={{ width: '100%' }}
            onChange={e => props.setFieldValue('message', e.target.value)}
          />
          <Error name="message" errors={props.errors} touched={props.touched} />
        </Form.Item>
      </FormBlock>
    </Modal>
  )

  return (
    <>
      <Header
        rightSlot={
          (isAuditorWorking && AuditorButtonGroup()) ||
          (isCompleted && CompleteButtonGroup()) ||
          (!isAuditorWorking && PlaceUserButtonGroup()) ||
          null
        }
        sticky
      >
        <HeaderBackButton onClick={() => props.goBack(props.history)} />
        <HeaderTitle
          css={styles.headerTitle}
          title={props.values.reportName || 'カイゼン'}
        />
        <Button
          type="link"
          icon={<ExternalLinkSvg fill={Primary} />}
          onClick={() =>
            props.goReportDetail(props.history, {
              reportUUID: props.improve.reportUUID!,
            })
          }
        ></Button>
      </Header>
      {emailConfirmModal}
      {props.isAuditorWorking
        ? editEmailModalForAuditor
        : props.improve.auditUserGroup
          ? editEmailMessageModalForPlaceUser
          : editEmailModalForPlaceUser}
    </>
  )
}

const styles = {
  headerTitle: css`
    // 長いレポート名が設定されていることもあるから
    max-width: calc(100vw - 600px);
  `,
}

export default ImproveDetailPageHeader
