import { Image } from '@ulysses-inc/harami_api_client'
import { FC } from 'react'
import notFoundImage from 'src/assets/images/imageNotFound1000x1000.png'
import ReactPDFImage from 'src/features/reports/result/downloadPDF/ReactPDFImage'
import { gridLayoutTableBodyCell } from '../../../shared/styles'

type Props = {
  value?: Image
}

export const TableBodyCellSignature: FC<Props> = ({ value }) => {
  return (
    <ReactPDFImage
      style={[
        gridLayoutTableBodyCell.baseTableBodyCell,
        gridLayoutTableBodyCell.tableBodyCell,
      ]}
      uri={value?.url !== undefined ? `${value?.url ?? ''}` : notFoundImage}
    ></ReactPDFImage>
  )
}
