import { ScheduleTypeEnum } from '@ulysses-inc/harami_api_client'
import { Col } from 'antd'
import { FormikProps } from 'formik'
import React from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { RootState } from 'src/state/store'
import ActivenessRow from './ActivenessRow'
import { ScheduleForm } from './EditSchedule'
import { EditScheduleContentRow } from './EditSchedule.styled'
import FrequencyRow from './FrequencyRow'
import NameAndCopyButtonRow from './NameAndCopyButtonRow'
import NotifiedGroupsRow from './NotifiedGroupsRow'
import PeriodPerDayRow from './PeriodPerDayRow'
import PeriodRow from './PeriodRow'
import PlaceRow from './PlaceRow'
import TemplateRow from './TemplateRow'
import TypeRow from './TypeRow'

const EditScheduleContent: React.FC<{
  formProps: FormikProps<ScheduleForm>
}> = ({ formProps }) => {
  const scheduleId = useParams<{ scheduleId?: string }>().scheduleId ?? ''
  const placeNodes = useSelector(
    (state: RootState) => state.placesState.placeGroups.nodes,
  )
  const templates = useSelector(
    (state: RootState) => state.schedulesState.schedulesTemplates.templates,
  )

  const {
    values: { type },
  } = formProps

  return (
    <EditScheduleContentRow>
      <Col span={20} offset={2}>
        <NameAndCopyButtonRow {...formProps} scheduleId={scheduleId} />
        <ActivenessRow {...formProps} />
        <TemplateRow {...formProps} templates={templates} />
        <PlaceRow {...formProps} placeNodes={placeNodes} />
        <TypeRow {...formProps} />
        <FrequencyRow {...formProps} />
        <PeriodRow {...formProps} />
        {type === ScheduleTypeEnum.Date && <PeriodPerDayRow {...formProps} />}
        <NotifiedGroupsRow {...formProps} />
      </Col>
    </EditScheduleContentRow>
  )
}

export default EditScheduleContent
