import { Radio, Typography } from 'antd'
import { FC, SVGProps } from 'react'
import {
  LayoutDescription,
  LayoutRadio,
  LayoutRadioArea,
} from './EditTemplateOption.styled'

const { Text } = Typography

const LayoutRadioRow: FC<{
  onClick: () => void
  checked: boolean
  name: string
  description: string[]
  Image: FC<
    SVGProps<SVGSVGElement> & {
      title?: string | undefined
    }
  >
}> = ({ onClick, checked, name, description, Image }) => (
  <LayoutRadio onClick={onClick}>
    <LayoutRadioArea>
      <Radio checked={checked}>{name}</Radio>
    </LayoutRadioArea>
    <LayoutDescription>
      <Image style={{ minWidth: 200, marginRight: 8 }} />
      {description.map((line, i) => (
        <Text key={i}>{line}</Text>
      ))}
    </LayoutDescription>
  </LayoutRadio>
)

export default LayoutRadioRow
