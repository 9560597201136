import {
  ReportNodeSchema,
  ResponseTypeEnum,
  TemplateNodeTypeEnum,
} from '@ulysses-inc/harami_api_client'
import React from 'react'
import getCalculateScorePercentage from 'src/exShared/hooks/reports/getCalculateScorePercentage'
import { getDescendantNodes } from 'src/exShared/util/nodes/getDescendantNodes'
import { WebReportResultProps } from 'src/features/reports/result/WebReportResultProps'
import styled from 'styled-components'
import { Divider } from './Divider'
import { Question } from './Question'

interface OwnProps {
  sectionNode: ReportNodeSchema
}

const NestedSectionDefault = styled.div`
  width: 100%;
  margin-bottom: 16px;
`

const NestedSectionForEmployeeCheck = styled.div`
  width: 100%;
`

const NestedSectionContent = styled.div`
  width: 100%;
`

const HeaderContent = styled.div`
  align-items: center;
  display: flex;
  height: 52px;
  width: 100%;
`

const SectionNameContent = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`

const SectionName = styled.span`
  width: 100%;
  font-weight: bold;
`

const EmployeeCode = styled.div`
  fontsize: 14;
  font-color: #595959;
`

const ResultScore = styled.span`
  font-color: rgba(0, 0, 0, 0);
`

export const Section: React.FC<WebReportResultProps & OwnProps> = (
  props: WebReportResultProps & OwnProps,
) => {
  // 従業員チェックのセクション
  const isEmployeeCheckSection =
    props.sectionNode.section?.isEmployeeCheck === 1 &&
    !props.sectionNode.section?.originalNodeUuid
  // 従業員チェックの項目（従業員氏名・コード表示部分）
  const isEmployeeCheckItem =
    props.sectionNode.section?.isEmployeeCheck === 1 &&
    !!props.sectionNode.section?.originalNodeUuid
  const renderSectionContent = () => {
    return (
      <NestedSectionContent>
        {props.getReportNodesNodes(props.sectionNode).map((node, index) => {
          if (
            props.sectionNode.section?.isEmployeeCheck === 1 &&
            props.sectionNode.section?.originalNodeUuid &&
            index === 0
          ) {
            return <></>
          }
          // 従業員チェックの項目において、インフォメーションが含まれている場合、
          // 従業員チェックのセクション直下にも表示されてしまう。
          // https://user-images.githubusercontent.com/112599381/189292510-0617cb53-7e32-41c5-8b03-9a41b5cc94bf.png
          // インフォメーションは回答によらず表示する処理（src/exShared/util/report/isAnswered.ts: L14）となっているため、
          // 以下で、従業員チェックのセクション直下にはインフォメーションを表示しないように処理している
          if (
            isEmployeeCheckSection &&
            node.question?.responseType === ResponseTypeEnum.INFORMATION
          ) {
            return <></>
          }
          if (node.type === TemplateNodeTypeEnum.Section) {
            return <Section key={index} {...props} sectionNode={node} />
          }
          if (node.type === TemplateNodeTypeEnum.Question)
            return (
              <Question
                key={`${props.sectionNode.id}-question-${index}`}
                {...props}
                node={node}
                question={node.question}
              />
            )
          return <></>
        })}
      </NestedSectionContent>
    )
  }

  const { dictOfNode: sectionChildNodes } = getDescendantNodes(
    props.sectionNode.nodes ?? [],
    props.report.nodes,
    { ignoreRepeatOriginalSection: true },
  )

  const { sumAllTotalScores, sumAllResultScores, calculateScorePercentage } =
    getCalculateScorePercentage(sectionChildNodes)
  const renderHeaderContent = () => {
    return (
      <>
        <SectionNameContent>
          <SectionName>
            {props.sectionNode.section?.name}
            {isEmployeeCheckSection
              ? ` (${props.sectionNode.section?.answeredEmployeeCheckCount})`
              : ``}
          </SectionName>
          {isEmployeeCheckItem && (
            <EmployeeCode>
              {props.sectionNode.section?.employeeCode}
            </EmployeeCode>
          )}
        </SectionNameContent>
        {props.report.isShowScore && !isEmployeeCheckSection && (
          <ResultScore>
            {sumAllResultScores}/{sumAllTotalScores}({calculateScorePercentage}
            %)
          </ResultScore>
        )}
      </>
    )
  }

  // 従業員チェックのセクション用のスタイルを適用
  const NestedSection = isEmployeeCheckSection
    ? NestedSectionForEmployeeCheck
    : NestedSectionDefault
  return (
    <NestedSection id={`Section-${props.sectionNode.id}`}>
      {
        // 従業員チェックの項目の場合、Divider非表示
        !isEmployeeCheckItem && <Divider />
      }
      <HeaderContent>{renderHeaderContent()}</HeaderContent>
      {renderSectionContent()}
    </NestedSection>
  )
}
