import {
  NumberValue,
  ResponseNumberDecimalPoint,
} from '@ulysses-inc/harami_api_client'
import { FC } from 'react'
import { Text } from 'src/components/nativeCompat/Text'
import { convertDecimal } from 'src/exShared/util/numberDecimalPoint/numberDecimalPoint'
import { getFormattedRecordedAtWithIoT } from 'src/util/date'
import { CellInner, TableBodyCellWrapper } from './Common.dumb'
import { styles } from './TableBodyCellIoT.dumb'

type Props = {
  value?: NumberValue
  scale?: string
  decimalPoint?: ResponseNumberDecimalPoint
  recordedAt?: Date
}

export const TableBodyCellIoT: FC<Props> = ({
  value,
  scale,
  decimalPoint,
  recordedAt,
}) => {
  const isInvalid = value?.isInvalid === 1
  const numberValue = value?.numberValue
  const cellValue =
    numberValue !== undefined ? convertDecimal(numberValue, decimalPoint) : ''

  return (
    <TableBodyCellWrapper
      backgroundColor={isInvalid ? 'invalid' : 'default'}
      fontColor={isInvalid ? 'invalid' : 'default'}
    >
      <CellInner>
        {cellValue}
        {scale}
      </CellInner>

      {recordedAt && (
        <Text small style={styles.recordedAtText}>
          {getFormattedRecordedAtWithIoT(recordedAt)}
        </Text>
      )}
    </TableBodyCellWrapper>
  )
}
