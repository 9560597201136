import { Select, Typography } from 'antd'
import { ComponentProps, FC, useId } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import {
  toggleEditable,
  updateApprovalFlowId,
} from 'src/state/ducks/templates/editTemplate/actions'
import { RootState } from 'src/state/store'
import {
  ApprovalFlowsSelect,
  BasicRow,
  FormLabel,
} from './EditTemplateOption.styled'

const { Text } = Typography
const { Option } = Select

const ApprovalFlowRow: FC = () => {
  const dispatch = useDispatch()

  const approvalFlows = useSelector(
    (state: RootState) => state.approvalFlowsState.approvalFlows.approvalFlows,
    shallowEqual,
  )
  const templateApprovalFlowId = useSelector(
    (state: RootState) => state.templatesState.editTemplate.approvalFlowId,
  )

  const onChange: ComponentProps<typeof Select>['onChange'] = value => {
    dispatch(updateApprovalFlowId(Number(value)))

    if (!value) {
      dispatch(toggleEditable(true))
    }
  }

  const labelId = useId()

  return (
    <BasicRow>
      <FormLabel id={labelId}>
        <Text strong>承認フローの設定</Text>
      </FormLabel>
      <div>
        <ApprovalFlowsSelect
          showSearch
          optionFilterProp="children"
          value={templateApprovalFlowId || undefined}
          onChange={onChange}
          allowClear
          aria-labelledby={labelId}
        >
          {approvalFlows.map(({ id, name }) => (
            <Option key={id} value={id || 0}>
              {name}
            </Option>
          ))}
        </ApprovalFlowsSelect>
      </div>
    </BasicRow>
  )
}

export default ApprovalFlowRow
