import {
  ExcelConversionDayOfWeekEnum,
  ExcelConversionScheduleFrequencyMonthly,
  ExcelConversionScheduleFrequencyWeekly,
  ScheduleFrequencyEnum,
} from '@ulysses-inc/harami_api_client'
import { InputNumber, Radio, Select } from 'antd'
import { FormikErrors, FormikTouched } from 'formik'
import React from 'react'
import FormikErrorMessage from 'src/components/formikErrorMessage/FormikErrorMessage'
import styled from 'styled-components'

const { Option } = Select

const FrequencySelect = styled(Select)`
  width: 200px;
`

const FrequencyContainer = styled.div`
  margin-top: 10px;
`

interface OwnProps {
  frequency: ScheduleFrequencyEnum | null
  frequencyWeekly?: ExcelConversionScheduleFrequencyWeekly
  frequencyMonthly?: ExcelConversionScheduleFrequencyMonthly
  touched: FormikTouched<any>
  submitCount: number
  errors: FormikErrors<any>
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
}

const FrequencySelectOption: React.FC<OwnProps> = (props: OwnProps) => {
  return (
    <div>
      <FrequencySelect
        value={props.frequency || undefined}
        onChange={(value: unknown) => {
          if (typeof value !== 'number') return
          props.setFieldValue('frequency', value)
        }}
      >
        <Option value={ScheduleFrequencyEnum.Daily}>毎日</Option>
        <Option value={ScheduleFrequencyEnum.Weekly}>毎週</Option>
        <Option value={ScheduleFrequencyEnum.Monthly}>毎月</Option>
      </FrequencySelect>
      <div>
        {props.submitCount > 0 && (
          <FormikErrorMessage name="frequency" errors={props.errors} />
        )}
      </div>
      {props.frequency === ScheduleFrequencyEnum.Weekly && (
        <FrequencyContainer>
          <FrequencyContainer>
            <Radio.Group
              value={props.frequencyWeekly?.dayOfWeek}
              onChange={e => {
                props.setFieldValue('frequencyWeekly', {
                  ...props.frequencyWeekly,
                  dayOfWeek: Number(e.target.value),
                })
              }}
            >
              <Radio value={ExcelConversionDayOfWeekEnum.Monday}>月</Radio>
              <Radio value={ExcelConversionDayOfWeekEnum.Tuesday}>火</Radio>
              <Radio value={ExcelConversionDayOfWeekEnum.Wednesday}>水</Radio>
              <Radio value={ExcelConversionDayOfWeekEnum.Thursday}>木</Radio>
              <Radio value={ExcelConversionDayOfWeekEnum.Friday}>金</Radio>
              <Radio value={ExcelConversionDayOfWeekEnum.Saturday}>土</Radio>
              <Radio value={ExcelConversionDayOfWeekEnum.Sunday}>日</Radio>
            </Radio.Group>
          </FrequencyContainer>
          <div>
            {props.submitCount > 0 && (
              <FormikErrorMessage
                name="frequencyWeekly.dayOfWeek"
                errors={props.errors}
              />
            )}
          </div>
        </FrequencyContainer>
      )}
      {props.frequency === ScheduleFrequencyEnum.Monthly && (
        <FrequencyContainer>
          <div>
            <InputNumber
              value={props.frequencyMonthly?.dayOfMonth}
              onChange={(value: string | number | undefined | null) => {
                if (value === undefined && typeof value !== 'number') return
                props.setFieldValue('frequencyMonthly', {
                  ...props.frequencyMonthly,
                  dayOfMonth: value,
                })
              }}
            />
            日
          </div>
          <div>
            {props.submitCount > 0 && (
              <FormikErrorMessage
                name="frequencyMonthly.dayOfMonth"
                errors={props.errors}
              />
            )}
          </div>
        </FrequencyContainer>
      )}
    </div>
  )
}

export default FrequencySelectOption
