import {
  CaretDownOutlined,
  CaretRightOutlined,
  DeleteOutlined,
  EditOutlined,
  HomeOutlined,
  TagOutlined,
} from '@ant-design/icons'
import { PlaceNodeTypeEnum } from '@ulysses-inc/harami_api_client'
import { Button, List, Modal, Row } from 'antd'
import React, { useState } from 'react'
import { PlaceGroupsProps } from 'src/features/places/PlaceGroups'
import { FlatNode } from 'src/features/places/PlaceGroupsProps'
import {
  Danger,
  Gray,
  ModalDeleteButtonColor,
  Primary,
  TableBorderColor,
  White,
} from 'src/features/theme/KdsThemeColor'
import styled from 'styled-components'

const PlaceGroupListContainer = styled(Row)`
  justify-content: flex-start;
  margin: 15px 0 15px 15px;
  overflow-x: auto;
`

const PlaceGroupList: React.FC<PlaceGroupsProps> = (
  props: PlaceGroupsProps,
) => {
  const [activePlaceNodeId, setActivePlaceNodeId] = useState<string>()
  const [collapsedGroup, setCollapsedGroup] = useState<number[]>([])

  const closeGroup = (userGroupId: number) => {
    setCollapsedGroup([...collapsedGroup, userGroupId])
  }

  const openGroup = (userGroupId: number) => {
    setCollapsedGroup([...collapsedGroup.filter(id => id !== userGroupId)])
  }
  return (
    <PlaceGroupListContainer justify="center">
      <div style={{ width: '100%', minWidth: '600px', paddingRight: '15px' }}>
        <div
          style={{
            width: '100%',
            height: 46,
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row',
            background: White,
            borderBottom: `1px solid ${TableBorderColor}`,
          }}
        >
          <div
            style={{ width: '100%', fontWeight: 'bold', paddingLeft: '15px' }}
          >
            現場グループ名
          </div>
        </div>
        <List
          style={{ width: '100%' }}
          itemLayout="horizontal"
          dataSource={props.flatNodes.filter(
            node => !node.path.find(p => collapsedGroup.includes(p)),
          )}
          renderItem={(node: FlatNode) => {
            const name =
              node.type === PlaceNodeTypeEnum.Place
                ? node.place?.name
                : node.placeGroup?.name
            const offsetX = node.path.length * 20
            const isGroup = node.type === PlaceNodeTypeEnum.PlaceGroup
            const isOpen = !collapsedGroup.includes(node.id)
            return (
              <List.Item
                style={{
                  position: 'relative',
                  minHeight: '46px',
                  backgroundColor: White,
                }}
                onMouseEnter={() => setActivePlaceNodeId(node.uuid)}
                onMouseLeave={() => setActivePlaceNodeId(undefined)}
              >
                {isGroup && (
                  <Button
                    style={{
                      width: '100%',
                      height: '100%',
                      paddingLeft: '15px',
                      marginLeft: offsetX + 'px',
                      display: 'flex',
                      alignItems: 'center',
                      border: 'none',
                      userSelect: 'none',
                      boxShadow: 'none',
                      justifyContent: 'flex-start',
                    }}
                    onClick={() =>
                      isOpen ? closeGroup(node.id) : openGroup(node.id)
                    }
                  >
                    {isOpen ? <CaretDownOutlined /> : <CaretRightOutlined />}
                    <TagOutlined />
                    <span
                      style={{
                        textAlign: 'left',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      {name}
                    </span>
                  </Button>
                )}
                {!isGroup && (
                  <div
                    style={{
                      width: '100%',
                      height: '100%',
                      paddingLeft: '15px',
                      marginLeft: offsetX + 'px',
                      display: 'flex',
                      alignItems: 'center',
                      border: 'none',
                      userSelect: 'none',
                    }}
                  >
                    <HomeOutlined />
                    <span
                      style={{
                        textAlign: 'left',
                        whiteSpace: 'pre-wrap',
                        wordBreak: 'break-all',
                        marginLeft: '8px',
                      }}
                    >
                      {name}
                    </span>
                  </div>
                )}
                {node.type === PlaceNodeTypeEnum.PlaceGroup &&
                  activePlaceNodeId === node.uuid && (
                    <div
                      style={{
                        position: 'absolute',
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'flex-end',
                        flexDirection: 'row',
                        height: '100%',
                        width: '400px',
                        right: 0,
                      }}
                    >
                      <Button
                        style={{
                          height: '100%',
                          width: '160px',
                          borderRadius: 0,
                          backgroundColor: Gray,
                          borderColor: Gray,
                          color: White,
                        }}
                        onClick={() => props.openAddPlaceGroup(node.id)}
                      >
                        <EditOutlined />
                        グループを追加
                      </Button>
                      <Button
                        style={{
                          height: '100%',
                          width: '120px',
                          borderRadius: 0,
                          backgroundColor: Primary,
                          borderColor: Primary,
                          color: White,
                        }}
                        onClick={() =>
                          props.openEditPlaceGroup(
                            node,
                            node.path.length
                              ? node.path[node.path.length - 1]
                              : undefined,
                          )
                        }
                      >
                        <EditOutlined />
                        編集
                      </Button>
                      <Button
                        style={{
                          height: '100%',
                          width: '120px',
                          borderRadius: 0,
                          color: White,
                          borderColor: Danger,
                          backgroundColor: Danger,
                        }}
                        onClick={() => {
                          Modal.confirm({
                            onOk: () => {
                              props.deletePlaceGroup(node.uuid)
                            },
                            title: `「${node.placeGroup?.name}」の削除を実行しますがよろしいですか？`,
                            okText: '削除',
                            cancelText: 'キャンセル',
                            okButtonProps: {
                              style: {
                                backgroundColor: ModalDeleteButtonColor,
                                border: 'none',
                              },
                            },
                          })
                        }}
                      >
                        <DeleteOutlined />
                        削除
                      </Button>
                    </div>
                  )}
              </List.Item>
            )
          }}
        />
      </div>
    </PlaceGroupListContainer>
  )
}

export default PlaceGroupList
