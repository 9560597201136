import { Schedule } from '@ulysses-inc/harami_api_client'
import { FormikProps } from 'formik'
import React from 'react'
import { useSelector } from 'react-redux'
import LoadingOverlay from 'src/components/loading/LoadingOverlay'
import { RootState } from 'src/state/store'
import EditSchedulePage from './EditSchedulePage'

type Override<T, U> = { [P in keyof T]: P extends keyof U ? U[P] : T[P] }

export type ScheduleForm = Override<Schedule, { placeNodes: string[] }> & {
  isCopied: boolean
  visibleEndReportDate: boolean
}

export type TBaseFormRowProps<
  T extends Record<string, unknown> = Record<string, unknown>,
> = Pick<
  FormikProps<ScheduleForm>,
  | 'setFieldValue'
  | 'handleSubmit'
  | 'values'
  | 'errors'
  | 'touched'
  | 'submitCount'
> &
  T

const EditSchedule: React.FC = () => {
  const isLoadingAddSchedule = useSelector(
    (state: RootState) => state.schedulesState.addSchedule.isLoading,
  )
  const isLoadingUpdateSchedule = useSelector(
    (state: RootState) => state.schedulesState.updateSchedule.isLoading,
  )

  const isSpinning = isLoadingAddSchedule || isLoadingUpdateSchedule
  const overlayTip = (() => {
    if (isLoadingAddSchedule) {
      return 'スケジュールを登録中です...'
    } else if (isLoadingUpdateSchedule) {
      return 'スケジュールを更新中です...'
    }
    return ''
  })()

  return (
    <LoadingOverlay
      tip={overlayTip}
      spinning={isSpinning}
      size="large"
      render={<EditSchedulePage />}
    />
  )
}

export default EditSchedule
