import { CaretLeftOutlined, CaretRightOutlined } from '@ant-design/icons'
import {
  ReportStatusEnum,
  ScheduleListInRangeItem,
  ScheduleListInRangeItemInfo,
  ScheduleTypeEnum,
} from '@ulysses-inc/harami_api_client'
import { ConfigProvider, Table, Typography } from 'antd'
import { addDays, endOfDay, isSameMonth, isSameYear } from 'date-fns'
import { FC, useEffect, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import {
  flattenNodes,
  mergedPlaceNodeIds,
} from 'src/exShared/util/place/placeNode'
import PlaceFilterDropDown from 'src/features/dashboard/PlaceFilterDropDown'
import StatusFilterDropDown from 'src/features/dashboard/StatusFilterDropDown'
import { RootState } from 'src/state/store'
import date from 'src/util/date'
import { Primary } from '../theme/KdsThemeColor'
import {
  DateTitle,
  DropDownArea,
  MonthLabel,
  NextWeek,
  PrevWeek,
  TableWrapper,
} from './shared.components'
import {
  useColumnsState,
  useCurrentEndDateState,
  useCurrentStartDateState,
  useFilteredPlaceGroupNodeIdsState,
  useFilteredPlaceNodeIdsState,
  useGetDevianceCountColumns,
  useGetScheduleStatusColumns,
  useHasPresentDayState,
  useIsFilteredState,
  useRequestScheduleData,
} from './shared.hooks'
import {
  ScheduleStatus,
  TColumnValuesByDayOfWeek,
  TDashboardDataType,
  TDayOfWeekIn3Letters,
  TItem,
} from './shared.types'

const daysOfWeekIn3Letters: TDayOfWeekIn3Letters[] = [
  'mon',
  'tue',
  'wed',
  'thu',
  'fri',
  'sat',
  'sun',
]
type TColumnNumberForDevianceCount = 0 | 1 | 2 | 3 | 4 | 5 | 6

const isDayOfWeekNumber = (x: number): x is TColumnNumberForDevianceCount =>
  x >= 0 || x <= 6
const convertNumberToDayOfWeekIn3Letters = (n: TColumnNumberForDevianceCount) =>
  daysOfWeekIn3Letters[n]

export const makeDefaultValuesForItem = (type: TDashboardDataType) =>
  daysOfWeekIn3Letters.reduce<TColumnValuesByDayOfWeek>(
    (carrier, dow) => ({
      ...carrier,
      [dow]: {
        // scheduleStatus が null の場合は ScheduleStatus.DEFAULT の status として扱われる
        // ScheduleStatus.DEFAULT をセットしないのは、後続の処理で「記録中がある場合」と「スケジュールがない場合」を分ける必要があるため。
        // null の場合は 「スケジュールがない場合」 として扱われる
        scheduleStatus: null,
        devianceCount: type === 'schedule' ? null : 0,
      },
    }),
    {} as TColumnValuesByDayOfWeek,
  )

export const getMergedScheduleStatus = (
  prevStatus: ScheduleStatus,
  newStatus: ScheduleStatus,
) => {
  if (
    prevStatus === ScheduleStatus.INCOMPLETE ||
    newStatus === ScheduleStatus.INCOMPLETE
  ) {
    return ScheduleStatus.INCOMPLETE
  }

  if (prevStatus === ScheduleStatus.DEFAULT) {
    return ScheduleStatus.DEFAULT
  }

  return newStatus
}

export const isScheduleCompleted = (
  info: ScheduleListInRangeItemInfo,
): boolean =>
  !!info.report?.uuid &&
  info.report?.status?.status !== ReportStatusEnum.INCOMPLETE &&
  info.report?.status?.status !== ReportStatusEnum.APPROVAL_REMAND

/** 日にちスケジュールの当日(今日)のスケジュールで、スケジュール終了時刻を経過していない */
export const isTodaysScheduleNotDueYet = (
  info: ScheduleListInRangeItemInfo,
): boolean =>
  !!(
    info.scheduleType === ScheduleTypeEnum.Date &&
    info.scheduleInDate?.targetDate &&
    date.isToday(info.scheduleInDate.targetDate) &&
    info.scheduleInDate.endTime &&
    date.isAfterNow(info.scheduleInDate.endTime)
  )

/** 日にちスケジュールで当日以降のスケジュールは「-」にする */
export const isFutureDateSchedule = (
  info: ScheduleListInRangeItemInfo,
): boolean =>
  !!(
    info.scheduleType === ScheduleTypeEnum.Date &&
    info.scheduleInDate?.targetDate &&
    date.isAfterNow(info.scheduleInDate.targetDate)
  )

/** 期間スケジュールで、期間終了日を経過していない */
export const isDurationScheduleNotDueYet = (
  info: ScheduleListInRangeItemInfo,
): boolean =>
  !!(
    info.scheduleType === ScheduleTypeEnum.Duration &&
    info.scheduleInDuration?.through &&
    date.isAfterNow(endOfDay(info.scheduleInDuration.through))
  )

/** レポート開始日が対象日付よりも後の日付なら、スケジュール開始日以前のスケジュールは「-」にする */
export const isDateScheduleStartReportDateAfterDueDate = (
  info: ScheduleListInRangeItemInfo,
): boolean =>
  !!(
    info.scheduleType === ScheduleTypeEnum.Date &&
    info.startReportDate &&
    info.scheduleInDate?.targetDate &&
    date.isAfter(info.startReportDate, info.scheduleInDate.targetDate) &&
    !date.isSameDay(info.startReportDate, info.scheduleInDate.targetDate)
  )

/** レポート開始日が対象日付よりも後の日付なら、スケジュール開始日以前のスケジュールは「-」にする */
export const isDurationScheduleStartReportDateAfterDueDate = (
  info: ScheduleListInRangeItemInfo,
): boolean =>
  !!(
    info.scheduleType === ScheduleTypeEnum.Duration &&
    info.startReportDate &&
    info.scheduleInDuration?.through &&
    date.isAfter(info.startReportDate, info.scheduleInDuration.through)
  )

const getScheduleStatus = (
  info: ScheduleListInRangeItemInfo,
): ScheduleStatus => {
  switch (true) {
    case isScheduleCompleted(info):
      return ScheduleStatus.COMPLETED
    case isTodaysScheduleNotDueYet(info):
    case isFutureDateSchedule(info):
    case isDurationScheduleNotDueYet(info):
    case isDateScheduleStartReportDateAfterDueDate(info):
    case isDurationScheduleStartReportDateAfterDueDate(info):
      return ScheduleStatus.DEFAULT
    default:
      return ScheduleStatus.INCOMPLETE
  }
}

const isDayOfWeekLowerCase = (x: string): x is TDayOfWeekIn3Letters =>
  ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'].includes(x)

const convertToScheduleStatusItem = (
  items: ScheduleListInRangeItem[],
): TItem[] => {
  const result: { [placeNodeUUID: string]: TItem } = {}
  items.forEach(({ targetDate, infos }) => {
    if (!targetDate || !infos || !infos.length) {
      return
    }
    const dayOfTheWeekEng = date
      .formatISODayOfWeek(new Date(targetDate))
      .toLocaleLowerCase()

    infos.forEach(info => {
      const placeNode = info.placeNode
      const placeNodeUUID = placeNode?.uuid ?? ''

      if (!(placeNodeUUID in result)) {
        // 同一現場で 1 回もステータス判定されていないとき
        result[placeNodeUUID] = {
          ...makeDefaultValuesForItem('schedule'),
          key: placeNodeUUID,
          place: { uuid: placeNodeUUID, name: placeNode?.place?.name ?? '' },
        }
      }

      if (!isDayOfWeekLowerCase(dayOfTheWeekEng)) {
        return
      }

      const prevStatus =
        result[placeNodeUUID]?.[dayOfTheWeekEng].scheduleStatus ??
        ScheduleStatus.COMPLETED

      // 期間スケジュールの場合の表示条件
      // 1. 最終日はアイコンに反映する
      // 2. 最終日以外はアイコンに反映しない
      //    ただし、スケジュールに表示は必要なため、
      //    他に表示すべきスケジュールがないときは "-" を表示する
      if (info.scheduleType === ScheduleTypeEnum.Duration) {
        if (
          date.formatYYYYMMDD_hyphen_locale(
            info.scheduleInDuration?.through,
          ) !== targetDate
        ) {
          return
        }
      }

      const newStatus = getScheduleStatus(info)
      const mergedScheduleStatus = getMergedScheduleStatus(
        prevStatus,
        newStatus,
      )

      const placeResult = result[placeNodeUUID]
      if (placeResult) {
        placeResult[dayOfTheWeekEng].scheduleStatus = mergedScheduleStatus
      }
    })
  })
  return Object.values(result)
}

const convertToDevianceCountItem = (items: ScheduleListInRangeItem[]) => {
  const devianceCountItems: TItem[] = []
  items.forEach(({ infos }, index) => {
    if (isDayOfWeekNumber(index)) {
      const dayOfWeek = convertNumberToDayOfWeekIn3Letters(index)

      infos?.forEach(({ placeNode, report }) => {
        if (devianceCountItems.some(val => val?.key === placeNode?.uuid)) {
          const targetItem = devianceCountItems.find(
            val => val?.place.uuid === placeNode?.uuid,
          )

          if (!targetItem) return

          const addcount = report?.invalidCount ?? 0

          if (dayOfWeek) {
            targetItem[dayOfWeek].devianceCount =
              (targetItem[dayOfWeek].devianceCount ?? 0) + addcount
          }
        } else {
          const firstCount = report?.invalidCount ?? 0
          const pushCount = devianceCountItems.push({
            ...makeDefaultValuesForItem('deviance'),
            key: placeNode?.uuid ?? '',
            place: {
              name: placeNode?.place?.name ?? '',
              uuid: placeNode?.uuid ?? '',
            },
          })

          // countItemsの要素数(pushCount) - 1のインデックスに異常数が記録されている
          const countItem = devianceCountItems[pushCount - 1]
          if (countItem && dayOfWeek) {
            countItem[dayOfWeek].devianceCount = firstCount
          }
        }
      })
    }
  })

  return devianceCountItems
}

// 年月表示部分を生成する処理 (always: endDate - startDate = 6)
const renderMonth = (startDate: Date, endDate: Date) => {
  let label = date.formatYYYYMM_JP(startDate)

  if (!isSameYear(startDate, endDate)) {
    label += ` ~ ${date.formatYYYYMM_JP(endDate)}`
  } else if (!isSameMonth(startDate, endDate)) {
    label += ` ~ ${date.formatMM_JP(endDate)}`
  }

  return <MonthLabel>{label}</MonthLabel>
}

const isIncomplete = (scheduleStatus: ScheduleStatus | null) =>
  scheduleStatus === ScheduleStatus.INCOMPLETE
export const filterScheduleItems = (data: TItem[]): TItem[] => {
  const firstMonday = data[0]?.mon

  if (firstMonday) {
    return data.filter(item =>
      daysOfWeekIn3Letters.some(d =>
        firstMonday.scheduleStatus !== null
          ? isIncomplete(item[d].scheduleStatus)
          : firstMonday.devianceCount !== null
            ? !!item[d].devianceCount
            : undefined,
      ),
    )
  }

  return []
}

const { Text } = Typography

interface IProps {
  type: TDashboardDataType
}

const DashboardStatusTable: FC<IProps> = ({ type }) => {
  const isSchedule = type === 'schedule'

  const placeNodes = useSelector(
    (state: RootState) => flattenNodes(state.placesState.placeGroups.nodes),
    shallowEqual,
  )
  const isLoading = useSelector(
    ({
      scheduleListInRangeItemsState: {
        scheduleListInRangeItems: schedule,
        invalidCountScheduleListInRangeItems: deviance,
      },
    }: RootState) => (isSchedule ? schedule : deviance).isLoading,
  )
  const schedules = useSelector(
    ({
      scheduleListInRangeItemsState: {
        scheduleListInRangeItems: { scheduleListInRangeItems },
        invalidCountScheduleListInRangeItems: {
          invalidCountScheduleListInRangeItems,
        },
      },
    }: RootState) =>
      isSchedule
        ? scheduleListInRangeItems
        : invalidCountScheduleListInRangeItems,
    shallowEqual,
  )

  const requestScheduleData = useRequestScheduleData()

  const [startDate, setCurrentStartDate] = useCurrentStartDateState(type)
  const [endDate, setCurrentEndDate] = useCurrentEndDateState(type)
  const [hasPresentDay, setHasPresentDay] = useHasPresentDayState(type)
  const [columns, setColumns] = useColumnsState(type)
  const [filteredPlaceNodeIds] = useFilteredPlaceNodeIdsState(type)
  const [filteredPlaceGroupNodeIds] = useFilteredPlaceGroupNodeIdsState(type)
  const [isFiltered] = useIsFilteredState(type)

  const [scheduleItems, setScheduleItems] = useState<TItem[] | undefined>()

  const getScheduleStatusColumns = useGetScheduleStatusColumns()
  const getDevianceCountColumns = useGetDevianceCountColumns()

  useEffect(() => {
    const convertedDataSource = (
      isSchedule ? convertToScheduleStatusItem : convertToDevianceCountItem
    )(schedules)

    setScheduleItems(
      isFiltered
        ? filterScheduleItems(convertedDataSource)
        : convertedDataSource,
    )
  }, [isFiltered, isSchedule, schedules])

  const getColumns = isSchedule
    ? getScheduleStatusColumns
    : getDevianceCountColumns

  const onWeekClick = (type: 'nextWeek' | 'lastWeek') => {
    const isNextWeek = type === 'nextWeek'

    if (isLoading || (hasPresentDay && isNextWeek)) {
      return
    }

    const addDayNumber: number = isNextWeek ? 7 : -7

    const newStartDate = startDate
      ? addDays(startDate, addDayNumber)
      : undefined
    const newEndDate = endDate ? addDays(endDate, addDayNumber) : undefined

    if (!newStartDate || !newEndDate) {
      return
    }

    const isPresentDay =
      newStartDate && newEndDate
        ? date.isWithinRange(date.today(), newStartDate, endOfDay(newEndDate))
        : false

    setCurrentStartDate(newStartDate)
    setCurrentEndDate(newEndDate)
    setHasPresentDay(isPresentDay)
    setColumns(getColumns(newStartDate, newEndDate))

    if (!newStartDate || !newEndDate) {
      return
    }
    const placeNodeIds = mergedPlaceNodeIds(
      placeNodes,
      filteredPlaceNodeIds,
      filteredPlaceGroupNodeIds,
    )
    requestScheduleData(isSchedule, placeNodeIds, newStartDate, newEndDate)
  }

  return (
    <>
      <DropDownArea>
        <PlaceFilterDropDown isSchedule={isSchedule} />
        <StatusFilterDropDown isSchedule={isSchedule} />
      </DropDownArea>

      <TableWrapper>
        <DateTitle isSchedule={isSchedule}>
          <PrevWeek
            style={{ color: Primary }}
            theme={{ opacity: isLoading ? 0.4 : 1 }}
            onClick={() => onWeekClick('lastWeek')}
          >
            <CaretLeftOutlined />
            <Text style={{ color: Primary }}>前の週</Text>
          </PrevWeek>
          {startDate && endDate && renderMonth(startDate, endDate)}
          <NextWeek
            style={{ color: Primary }}
            theme={{ opacity: isLoading || hasPresentDay ? 0.4 : 1 }}
            onClick={() => onWeekClick('nextWeek')}
          >
            <CaretRightOutlined />
            <Text style={{ color: Primary }}>次の週</Text>
          </NextWeek>
        </DateTitle>
        {/* 謎の warning が出る (Warning: Each child in a list should have a unique "key" prop. Check the render method of `Table`.) */}

        <ConfigProvider
          theme={{
            components: {
              Table: {
                headerBg: '#eff2f5',
              },
            },
          }}
        >
          <Table
            className="table-striped-rows"
            columns={columns}
            dataSource={scheduleItems}
            pagination={false}
            loading={isLoading}
            scroll={{ x: 870 }}
          />
        </ConfigProvider>
      </TableWrapper>
    </>
  )
}

export default DashboardStatusTable
