import { IndustryTypeEnum } from '@ulysses-inc/harami_api_client'
import { Button, Col } from 'antd'
import * as qs from 'qs'
import { useHistory, useLocation } from 'react-router-dom'
import { getIndustries } from 'src/features/templateEdit/onboarding/ShowIndustry'
import { IndustryGroups } from 'src/features/templateEdit/onboarding/ShowIndustryInfo'
import TemplateHeader from 'src/features/templateEdit/onboarding/TemplateHeader'
import { IndustryInfo } from 'src/features/templateEdit/onboarding/types'
import { Primary } from 'src/features/theme/KdsThemeColor'
import styled from 'styled-components'

type Settings = {
  title: string
  subTitle?: string
  backTitle: string
  showIndustries: IndustryInfo[][]
  onBack: () => void
  onClick: (industry: IndustryInfo) => void
}

export const CreateTemplateIndustryScene = () => {
  const history = useHistory()
  const location = useLocation()

  const searchParams = qs.parse(history.location.search, {
    ignoreQueryPrefix: true,
  })
  const industryType: IndustryTypeEnum = searchParams['industry']
    ? Number(searchParams['industry'])
    : 0

  const settingsForFirstScreen = {
    title: 'ひな形をお選びください',
    subTitle:
      '空のひな形もしくは業界ごとのおすすめテンプレートから作成してください。',
    backTitle: '戻る',
    showIndustries: IndustryGroups,
    onBack: () => {
      history.push('/templates')
    },
    onClick: (industry: IndustryInfo) => {
      if (industry.title === '空のひな形') {
        // 空のテンプレート
        history.push('/templates/pages/add')
      } else {
        history.push(`/templates/create/type?industry=${industry.type}`)
      }
    },
  }

  const settingsForSecondScreen = {
    title: 'テンプレートをお選びください',
    backTitle: '業種選択に戻る',
    showIndustries: getIndustries(industryType),
    onBack: () => {
      history.push('/templates/create/industry')
    },
    onClick: (industryInfo: IndustryInfo) => {
      if (!industryInfo.templateId) {
        // 空のテンプレート
        history.push('/templates/pages/add')
      } else {
        history.push(
          `/templates/${industryInfo.templateId}/pages/edit?industry=true`,
        )
      }
    },
  }

  // `/templates/create/industry`と`/templates/create/type`の2つのパスで
  // このコンポーネントを使っているため、このような手当が必要。
  let settings: Settings
  if (location.pathname === '/templates/create/type') {
    settings = settingsForSecondScreen
  } else {
    settings = settingsForFirstScreen
  }

  return (
    <div>
      <TemplateHeader
        title={settings.title}
        subTitle={settings.subTitle}
        backTitle={settings.backTitle}
        onBack={() => settings.onBack()}
      />

      {settings.showIndustries.map((industries, index: number) => {
        return (
          <TableHeader key={`tableHeader-${index}`}>
            <TableRow key={`tableRow-${index}`}>
              {industries.map((industry: IndustryInfo, index: number) => {
                return (
                  <ButtonWrapper key={`industry-col-${index}`}>
                    <CreateButton
                      onClick={() => settings.onClick(industry)}
                      disabled={industry.disabled}
                    >
                      <SvgTag>
                        {industry.svgComponent && (
                          <SvgTagIconWrap>
                            <industry.svgComponent
                              width={48}
                              height={48}
                              fill={Primary}
                            />
                          </SvgTagIconWrap>
                        )}
                        <SvgTagTitle>{industry.title}</SvgTagTitle>
                      </SvgTag>
                    </CreateButton>
                  </ButtonWrapper>
                )
              })}
            </TableRow>
          </TableHeader>
        )
      })}
    </div>
  )
}

export default CreateTemplateIndustryScene

const TableHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

const CreateButton = styled(Button)`
  min-height: 88px;
  width: 20vw;
  padding: 12px 24px;
  justify-content: flex-start;
`

const TableRow = styled.div`
  width: 70%;
  display: flex;
  flex-wrap: wrap;
  margin-left: 5vw;
`

const ButtonWrapper = styled(Col)`
  margin: 24px 12px 0;
  &:last-child {
    margin-bottom: 24px;
  }
`

const SvgTag = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
`

const SvgTagIconWrap = styled.div`
  vertical-align: center;
  height: 48px;
`

const SvgTagTitle = styled.span`
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  text-align: left;
  white-space: pre-wrap;
`
