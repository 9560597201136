import { DownloadOutlined } from '@ant-design/icons'
import { Dropdown, MenuProps } from 'antd'
import { FC } from 'react'

// FIXME: ハンドラーの実装は props で受け取るのではなくこのボタンでやるべき
// FIXME: ハンドラー内で実行するのに必要なパラメーターは global state から取るべき
interface OwnProps {
  /** 「出力」をクリックしたときのハンドラー */
  handleOutputClick: () => void

  /** 「ダウンロードファイル一覧」をクリックしたときのハンドラー */
  handleDownloadFileListClick: () => void
}

export const ExcelOutputButton: FC<OwnProps> = props => {
  const { handleOutputClick, handleDownloadFileListClick } = props

  const menuProps: MenuProps = {
    items: [
      {
        label: 'ダウンロードファイル一覧',
        key: '1',
      },
    ],
    onClick: handleDownloadFileListClick,
  }
  return (
    <Dropdown.Button onClick={() => handleOutputClick()} menu={menuProps}>
      <DownloadOutlined />
      Excel出力
    </Dropdown.Button>
  )
}
