import { SearchOutlined } from '@ant-design/icons'
import { css } from '@emotion/react'
import { DebouncedInput } from 'src/components/debounsedInput/DebounsedInput'
import {
  // 名前がコンポーネントと重複するため
  ReportListFilters as ReportListFiltersType,
} from 'src/features/reports/listBetaFilters/types'
import { useReportListFilters } from 'src/features/reports/listBetaFilters/useReportListFilters'

type Props = {
  filters: ReportListFiltersType
  resetFilterOf: ReturnType<typeof useReportListFilters>['resetFilterOf']
  setFilterOf: ReturnType<typeof useReportListFilters>['setFilterOf']
}

/**
 * レポート名をフィルタするための入力欄
 *
 * 他のフィルタ項目と異なり、レポート名の入力項目だけは
 * DOMツリー状で別階層に配置されるデザインとなっているため、
 * 個別のコンポーネントとして切り出したもの。
 */
export const ReportListFiltersReportName = ({
  filters,
  resetFilterOf,
  setFilterOf,
}: Props) => {
  return (
    <DebouncedInput
      allowClear
      css={styles.input}
      delay={300}
      onChangeValue={value => {
        setFilterOf.reportName(value)
        resetFilterOf.currentPage()
      }}
      placeholder="レポート名で検索"
      suffix={<SearchOutlined style={{ color: '434E5D' }} />}
      value={filters.reportName}
    />
  )
}

const styles = {
  input: css`
    width: 360px;
  `,
}
