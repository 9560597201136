import {
  DatetimeValue,
  ResponseDatetimeSubTypeEnum,
} from '@ulysses-inc/harami_api_client'
import { FC } from 'react'
import { formatDatetimeValue } from '../../datetimeFormatter.util'
import { CellInner, TableBodyCellWrapper } from './Common.dumb'

type Props = {
  value?: DatetimeValue
  subType: ResponseDatetimeSubTypeEnum
}

export const TableBodyCellDatetime: FC<Props> = ({ value, subType }) => {
  const isInvalid = value?.isInvalid === 1
  const datetimeValue = value?.datetimeValue

  return (
    <TableBodyCellWrapper
      backgroundColor={isInvalid ? 'invalid' : 'default'}
      fontColor={isInvalid ? 'invalid' : 'default'}
    >
      <CellInner>
        {datetimeValue && formatDatetimeValue(subType, datetimeValue)}
      </CellInner>
    </TableBodyCellWrapper>
  )
}
