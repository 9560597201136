import {
  AddApprovalFlowRequest,
  ApprovalFlow,
  ApprovalFlowsApi,
  DeleteApprovalFlowRequest,
  ErrorCodeEnum,
  GetApprovalFlowRequest,
  UpdateApprovalFlowRequest,
} from '@ulysses-inc/harami_api_client'
import { call, put, takeEvery } from 'redux-saga/effects'
import * as notificationServiceActions from 'src/features/notificationService/slice'
import interceptionsActions from 'src/state/ducks/interceptions/actions'
import BaseClient from 'src/state/middleware/saga/baseClient'
import {
  HTTPError,
  errorIs,
  handleHTTPError,
} from 'src/state/middleware/saga/handleHttpError'
import actions from './actions'
import { ActionTypes } from './types'

const baseClient = new BaseClient()

const getApprovalFlowRequest = (req: GetApprovalFlowRequest) => {
  return baseClient
    .getApi(ApprovalFlowsApi)
    .getApprovalFlow(req)
    .then(approvalFlow => approvalFlow)
    .catch(handleHTTPError)
}

const getApprovalFlowsRequest = () => {
  return baseClient
    .getApi(ApprovalFlowsApi)
    .getApprovalFlows()
    .then(approvalFlows => approvalFlows)
    .catch(handleHTTPError)
}

const addApprovalFlowRequest = (req: AddApprovalFlowRequest) => {
  return baseClient
    .getApi(ApprovalFlowsApi)
    .addApprovalFlow(req)
    .catch(handleHTTPError)
}

const updateApprovalFlowRequest = (req: UpdateApprovalFlowRequest) => {
  return baseClient
    .getApi(ApprovalFlowsApi)
    .updateApprovalFlow(req)
    .catch(handleHTTPError)
}

const deleteApprovalFlowRequest = (req: DeleteApprovalFlowRequest) => {
  return baseClient
    .getApi(ApprovalFlowsApi)
    .deleteApprovalFlow(req)
    .catch(handleHTTPError)
}

function* getApprovalFlow(action: ReturnType<typeof actions.getApprovalFlow>) {
  try {
    const approvalFlow: ApprovalFlow = yield call(getApprovalFlowRequest, {
      approvalFlowId: action.approvalFlowId,
    })
    yield put(actions.getSuccessApprovalFlow(approvalFlow))
  } catch (error) {
    // TODO remove `as`
    yield put(interceptionsActions.handleHttpError(error as HTTPError))
    // TODO remove `as`
    yield put(actions.getErrorApprovalFlow(error as Error))
  }
}

function* getApprovalFlows() {
  try {
    const approvalFlows: ApprovalFlow[] = yield call(getApprovalFlowsRequest)
    yield put(actions.getSuccessApprovalFlows(approvalFlows))
  } catch (error) {
    // TODO remove `as`
    yield put(interceptionsActions.handleHttpError(error as HTTPError))
    // TODO remove `as`
    yield put(actions.getErrorApprovalFlows(error as Error))
  }
}

function* addApprovalFlow(action: ReturnType<typeof actions.addApprovalFlow>) {
  try {
    const approvalFlows: ApprovalFlow[] = yield call(addApprovalFlowRequest, {
      approvalFlow: action.approvalFlow,
    })
    yield put(actions.getSuccessApprovalFlows(approvalFlows))
    yield put(actions.addSuccessApprovalFlow())
    yield put(
      notificationServiceActions.showNotification({
        message: '承認フローを登録しました',
      }),
    )
  } catch (error) {
    // TODO remove `as`
    yield put(interceptionsActions.handleHttpError(error as HTTPError))
    // TODO remove `as`
    yield put(actions.addErrorApprovalFlow(error as Error))
  }
}

function* updateApprovalFlow(
  action: ReturnType<typeof actions.updateApprovalFlow>,
) {
  try {
    const approvalFlows: ApprovalFlow[] = yield call(
      updateApprovalFlowRequest,
      {
        approvalFlow: action.approvalFlow,
        approvalFlowId: action.approvalFlowId,
      },
    )
    yield put(actions.getSuccessApprovalFlows(approvalFlows))
    yield put(actions.updateSuccessApprovalFlow())
    yield put(
      notificationServiceActions.showNotification({
        message: '承認フローを更新しました',
      }),
    )
  } catch (error) {
    // NOTE: 承認フローの更新中にユーザーが削除された場合、404エラーが返却されるためハンドリングしてユーザーに通知する
    // handleHttpError に 404 エラーのハンドリングがないため、ここでハンドリングしている
    if (errorIs(error, ErrorCodeEnum.NotFoundError)) {
      yield put(
        notificationServiceActions.showNotification({
          message:
            '承認フローの編集中に削除されたユーザーが設定されています。一度画面をリロードして再設定してください。',
          notificationType: 'error',
        }),
      )
    } else {
      // TODO remove `as`
      yield put(interceptionsActions.handleHttpError(error as HTTPError))
    }
    // TODO remove `as`
    yield put(actions.updateErrorApprovalFlow(error as Error))
  }
}

function* deleteApprovalFlow(
  action: ReturnType<typeof actions.deleteApprovalFlow>,
) {
  try {
    yield call(deleteApprovalFlowRequest, {
      approvalFlowId: action.approvalFlowId,
    })
    const approvalFlows: ApprovalFlow[] = yield call(getApprovalFlowsRequest)
    yield put(actions.getSuccessApprovalFlows(approvalFlows))
    yield put(actions.deleteSuccessApprovalFlow())
  } catch (error) {
    // TODO remove `as`
    yield put(interceptionsActions.handleHttpError(error as HTTPError))
    // TODO remove `as`
    yield put(actions.deleteErrorApprovalFlow(error as Error))
  }
}

const sagas = [
  takeEvery(ActionTypes.REQUEST_GET_APPROVAL_FLOW, getApprovalFlow),
  takeEvery(ActionTypes.REQUEST_GET_APPROVAL_FLOWS, getApprovalFlows),
  takeEvery(ActionTypes.REQUEST_ADD_APPROVAL_FLOW, addApprovalFlow),
  takeEvery(ActionTypes.REQUEST_UPDATE_APPROVAL_FLOW, updateApprovalFlow),
  takeEvery(ActionTypes.REQUEST_DELETE_APPROVAL_FLOW, deleteApprovalFlow),
]

export default sagas
