import { PlaceNodeTypeEnum } from '@ulysses-inc/harami_api_client'
import { Select } from 'antd'
import { FC } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'src/state/store'
import { FlatNode, flattenNodes } from './EditPlaceGroup'
import { useFormikProps } from './EditPlaceGroup.hooks'
import { PlaceGroupText, PlaceGroupTitle } from './EditPlaceGroup.styled'

const { Option } = Select

type TProps = Pick<ReturnType<typeof useFormikProps>, 'setFieldValue'>

const ParentRow: FC<TProps> = ({ setFieldValue }) => {
  const nodes = useSelector(
    (state: RootState) => state.placesState.placeGroups.nodes,
  )
  const activeNodeId = useSelector(
    (state: RootState) => state.placesState.placeGroups.activeNodeId,
  )
  const activeParentNodeId = useSelector(
    (state: RootState) => state.placesState.placeGroups.activeParentNodeId,
  )

  const placeGroupNodes = flattenNodes(nodes, []).filter(
    ({ type }) => type === PlaceNodeTypeEnum.PlaceGroup,
  )
  const activeNode = placeGroupNodes.find(({ uuid }) => uuid === activeNodeId)
  const activeParentNode = placeGroupNodes.find(
    ({ id }) => id === activeParentNodeId,
  )
  const activeRootId = activeNode?.path[0]
  const activeRootNode = placeGroupNodes.find(({ id }) => id === activeRootId)
  let activeRelationNodeIds: number[] = []
  let activeChildNodeIds: number[] = []
  if (activeRootId !== undefined && activeRootNode !== undefined) {
    // 選択された現場グループの最上位に紐づく全現場グループ
    activeRelationNodeIds = flattenNodes(
      activeRootNode.nodes as FlatNode[],
      activeNode?.path,
    ).map(({ id }) => id)
    activeRelationNodeIds.push(activeRootId)
    // 選択された現場グループに紐づく現場グループ
    activeChildNodeIds = flattenNodes(
      activeNode?.nodes as FlatNode[],
      activeNode?.path,
    ).map(({ id }) => id)
  }

  const isRoot = activeNode === undefined || activeNode.isRootNode === 1
  const isNew = activeNode === undefined && activeParentNode !== undefined
  const isExisting = activeNode !== undefined

  const defaultValue = activeParentNode
    ? activeParentNode.id.toString() || undefined
    : 'root'
  const onChange = (parentNodeId: string) =>
    setFieldValue('parentNodeId', parentNodeId)

  const availableGroups = placeGroupNodes.filter(
    ({ id }) =>
      id !== activeNode?.id &&
      activeRelationNodeIds.includes(id) &&
      !activeChildNodeIds.includes(id),
  )

  return (
    <>
      <PlaceGroupTitle>
        <PlaceGroupText>親グループ</PlaceGroupText>
      </PlaceGroupTitle>
      <Select
        disabled={isRoot}
        style={{ width: '100%' }}
        defaultValue={defaultValue}
        onChange={onChange}
      >
        {isRoot && (
          <Option key="root" value="root">
            最上位
          </Option>
        )}
        {isNew && (
          // グループに追加する場合
          <Option
            key={activeParentNode?.id.toString()}
            value={activeParentNode?.id.toString()}
          >
            {activeParentNode?.placeGroup?.name}
          </Option>
        )}
        {isExisting &&
          availableGroups.map(({ id, placeGroup }) => (
            <Option key={id?.toString()} value={id?.toString()}>
              {placeGroup?.name}
            </Option>
          ))}
      </Select>
    </>
  )
}

export default ParentRow
