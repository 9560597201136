import { useClearHandler } from './hooks/useClearHandler'
import { useCopyHandler } from './hooks/useCopyHandler'
import { usePasteHandler } from './hooks/usePasteHandler'
import { useSelectAllHandler } from './hooks/useSelectAllHandler'

/**
 * ブラウザーイベントをハンドリングするためだけの空コンポーネント
 *
 * `Table` コンポーネントと分離することで、イベント発火時に `Table` が再レンダリングされないようにする
 */
export const EventHandler = () => {
  useCopyHandler()
  usePasteHandler()
  useClearHandler()
  useSelectAllHandler()
  return <></>
}
