import moment from 'moment'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { MomentDatePicker } from 'src/components/datepicker/MomentDatePicker'
import { isValidDateStr } from 'src/exShared/util/date'
import {
  continueRangeSelect,
  doubleClickCell,
  finishRangeSelect,
  patchInputTable,
  pickDate,
  startRangeSelect,
} from 'src/state/ducks/editGridVariables/actions'
import {
  MAX_END_DATE,
  MIN_START_DATE,
} from 'src/state/ducks/editGridVariables/constants'
import {
  useIsInDrag,
  useIsInEdit,
  useIsSelected,
} from 'src/state/ducks/editGridVariables/selectors'
import { RootState } from 'src/state/store'
import {
  BaseCell,
  DATE_CELL_WIDTH,
  DownIcon,
  gridCellDatePickerStyle,
} from '../EditGridVariables.styled'
import { DATE_PICKER_FORMAT } from '../constants'

type Props = {
  /** 行番号 */
  rowNumber: number
  /** 列番号 */
  colNumber: number
}

/**
 * 適用開始日セル
 */
export const StartDateCell = ({ rowNumber, colNumber }: Props) => {
  const isSelected = useIsSelected(rowNumber, colNumber)
  const isInEdit = useIsInEdit(rowNumber, colNumber)

  /**
   * OPTIMIZE: このセルの mouseenter イベントが発火したときに `CONTINUE_RANGE_SELECT` を呼ぶかどうかの判定のためにモードを見ている
   *           このため、セルをクリックするなどしてモードを変更するごとに全セルの再レンダリングが発生している
   */
  const isInDrag = useIsInDrag()

  const dateValue = useSelector(
    (state: RootState) =>
      state.editGridVariablesState.editGridVariables.inputTable[rowNumber]?.from
        .dateValue,
  )

  const errors = useSelector(
    (state: RootState) =>
      state.editGridVariablesState.editGridVariables.inputTable[rowNumber]?.from
        .errors,
    shallowEqual,
  )
  const hasErrors = (errors || []).length > 0

  const disableDate = (current: moment.Moment | null) => {
    if (!current) {
      return false
    }

    const currentDate = current.toDate()
    // 2023/01/01~2099/12/31 の範囲外の場合はNG
    const disabledRange =
      currentDate.getTime() < MIN_START_DATE.getTime() ||
      MAX_END_DATE.getTime() < currentDate.getTime()

    return disabledRange
  }

  const dispatch = useDispatch()
  const onMouseUp = () => {
    dispatch(finishRangeSelect())
  }

  const onMouseDown = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (e.button !== 0) {
      return
    }

    dispatch(startRangeSelect(rowNumber, colNumber))
  }

  const onMouseEnter = () => {
    // drag モード以外では範囲選択したくないのと、その場合に action dispatch するのは無駄なのでガードする
    if (!isInDrag) {
      return
    }

    dispatch(continueRangeSelect(rowNumber, colNumber))
  }

  const handleDoubleClick = () => {
    dispatch(doubleClickCell(rowNumber, colNumber))
  }

  return (
    <>
      <MomentDatePicker
        allowClear={false}
        css={gridCellDatePickerStyle}
        disabledDate={m => disableDate(m)}
        format={DATE_PICKER_FORMAT}
        value={isValidDateStr(dateValue) ? moment(dateValue) : undefined}
        onChange={date => {
          if (!date) return
          dispatch(pickDate())
          dispatch(
            patchInputTable({ [rowNumber]: { from: date.toISOString() } }),
          )
        }}
        placeholder=""
        style={{ display: isInEdit ? 'flex' : 'none' }}
        open={isInEdit}
        suffixIcon={<DownIcon />}
      />
      <BaseCell
        $cellState={{ isInEdit, hasErrors, isSelected }}
        width={DATE_CELL_WIDTH}
        onMouseDown={e => onMouseDown(e)}
        onMouseEnter={onMouseEnter}
        onMouseUp={onMouseUp}
        onDoubleClick={handleDoubleClick}
        style={{ display: isInEdit ? 'none' : 'flex' }}
      >
        {isValidDateStr(dateValue)
          ? moment(dateValue).format(DATE_PICKER_FORMAT)
          : dateValue}
        <DownIcon />
      </BaseCell>
    </>
  )
}
