import {
  DeleteOutlined,
  ImportOutlined,
  OrderedListOutlined,
  PlusOutlined,
} from '@ant-design/icons'
import { Employee, GetEmployeesRequest } from '@ulysses-inc/harami_api_client'
import { Drawer, Layout, Modal } from 'antd'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Header } from 'src/components/header/Header'
import { HeaderTitle } from 'src/components/header/HeaderTitle'
import Loading from 'src/components/loading/Loading'
import { GlobalNavigation } from 'src/features/globalNavigation/GlobalNavigation'
import { ModalDeleteButtonColor } from 'src/features/theme/KdsThemeColor'
import { useListFilter } from 'src/hooks/filter/useListFilter'
import employeesOperations from 'src/state/ducks/employees/operations'
import { GetEmployeesFilter } from 'src/state/ducks/employees/types'
import placesOperations from 'src/state/ducks/places/operations'
import { RootState } from 'src/state/store'
import EmployeeList from './EmployeeList'
import {
  AddEmployeeButton,
  ContentWrapper,
  EmployeeTabs,
  EmployeeTabsWrap,
  EmployeesPagination,
  ListContainer,
  ListHeader,
} from './Employees.styled'
import EmployeesFilterDropDown from './EmployeesFilterDropDown'
import EditEmployee from './editEmployees/EditEmployee'
import ImportEmployees from './importEmployees/ImportEmployees'

enum EmployeeTabIndex {
  EmployeeList = '1',
  EmployeeImport = '2',
}

const Employees = () => {
  const dispatch = useDispatch()

  const {
    isLoading,
    request,
    filter,
    segmentIndex,
    count,
    isShowEditEmployeeDrawer,
  } = useSelector((state: RootState) => state.employeesState.employees)

  const [chooseEmployees, setChooseEmployees] = useState<{
    [key: string]: boolean
  }>({})
  const { filters, setListFilter } = useListFilter()

  const changeEmployeesPage = (
    page: number,
    pageSize?: number,
    filter?: GetEmployeesFilter,
  ) => {
    employeesOperations.changeEmployeesPage(dispatch, page, pageSize, filter)
  }
  const changeEmployeesSize = (
    pageSize: number,
    filter?: GetEmployeesFilter,
  ) => {
    employeesOperations.changeEmployeesSize(dispatch, pageSize, filter)
  }
  const updatePagination = (limit: number, offset: number) => {
    employeesOperations.updatePagination(dispatch, limit, offset)
  }
  const getPlaces = () => {
    placesOperations.getPlaces(dispatch, {})
    placesOperations.getPlaceGroups(dispatch)
  }
  const updateSegmentIndex = (index: string) => {
    employeesOperations.updateSegmentIndex(dispatch, index)
  }
  const changeIsShowEditEmployeeDrawer = (isShow: boolean) => {
    employeesOperations.changeIsShowEditEmployeeDrawer(dispatch, isShow)
  }
  const openAddEmployee = () => {
    employeesOperations.updateActiveEmployeeUUID(dispatch, '')
    employeesOperations.changeIsShowEditEmployeeDrawer(dispatch, true)
  }
  const deleteMultipleEmployees = (
    uuids: string[],
    request: GetEmployeesRequest,
    filter?: GetEmployeesFilter,
  ) => {
    const employees: Employee[] = uuids.map(uuid => {
      return {
        uuid,
        name: '',
        employeeCode: '',
      }
    })
    employeesOperations.deleteMultipleEmployees(
      dispatch,
      employees,
      request,
      filter,
    )
  }

  useEffect(() => {
    getPlaces()
    if (filters && filters.employees) {
      const employeesFilter = filters.employees
      updatePagination(
        employeesFilter.pagination?.limit ?? 25,
        employeesFilter.pagination?.offset ?? 0,
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setListFilter({
      ...(filters ?? {}),
      employees: {
        pagination: request,
        filter: filters?.employees?.filter,
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [request.limit, request.offset])

  const currentPage =
    request.offset === undefined || request.limit === undefined
      ? 1
      : request.offset / request.limit + 1

  // 閉じるボタン or Drawer外のクリックしたとき
  const onClose = () => {
    updateSegmentIndex(EmployeeTabIndex.EmployeeList)
    changeIsShowEditEmployeeDrawer(false)
  }

  const renderEmployeeList = () => {
    return (
      <ListContainer>
        <ListHeader justify="space-between" align="bottom">
          <EmployeesFilterDropDown />
          <div>
            <AddEmployeeButton type="primary" onClick={() => openAddEmployee()}>
              <PlusOutlined />
              従業員を追加
            </AddEmployeeButton>
            <AddEmployeeButton
              onClick={() => {
                Modal.confirm({
                  onOk: () => {
                    deleteMultipleEmployees(
                      Object.keys(chooseEmployees),
                      request,
                      filter,
                    )
                    setChooseEmployees({})
                  },
                  title: '選択した従業員を一括で削除しますがよろしいですか？',
                  okText: (
                    <>
                      <DeleteOutlined />
                      一括削除
                    </>
                  ),
                  okButtonProps: {
                    style: {
                      backgroundColor: ModalDeleteButtonColor,
                      border: 'none',
                    },
                  },
                  cancelText: 'キャンセル',
                })
              }}
              disabled={Object.keys(chooseEmployees).length === 0}
            >
              <DeleteOutlined />
              従業員一括削除
            </AddEmployeeButton>
          </div>
        </ListHeader>
        {!isLoading && (
          <EmployeeList
            chooseEmployees={chooseEmployees}
            setChooseEmployees={setChooseEmployees}
          />
        )}
        {isLoading && <Loading />}
      </ListContainer>
    )
  }

  return (
    // TODO: サイドメニュー描写の責務を`Routes.tsx`に移植する
    <GlobalNavigation
      resetPagination={() => {
        setListFilter({
          ...(filters ?? {}),
          employees: {
            pagination: { limit: 25, offset: 0 },
            filter: filters?.employees?.filter,
          },
        })
      }}
    >
      <Layout>
        <Header>
          <HeaderTitle title="従業員" />
        </Header>

        <EmployeeTabsWrap className="card-container">
          <EmployeeTabs
            type="card"
            activeKey={segmentIndex}
            onChange={value => updateSegmentIndex(value)}
            items={[
              {
                children: (
                  <>
                    <ContentWrapper>{renderEmployeeList()}</ContentWrapper>
                    <Drawer
                      placement="right"
                      maskClosable={false}
                      closable={false}
                      onClose={() => onClose()}
                      destroyOnClose={true}
                      open={isShowEditEmployeeDrawer}
                      width={600}
                      drawerStyle={{ padding: 0 }}
                    >
                      <EditEmployee />
                    </Drawer>
                    <EmployeesPagination
                      showSizeChanger
                      current={currentPage}
                      pageSizeOptions={['10', '25', '50', '100']}
                      pageSize={request.limit ?? 25}
                      defaultCurrent={1}
                      total={count}
                      onChange={(pageNum, pageSize) =>
                        changeEmployeesPage(pageNum, pageSize, filter)
                      }
                      onShowSizeChange={(_, size) =>
                        changeEmployeesSize(size, filter)
                      }
                    />
                  </>
                ),
                key: EmployeeTabIndex.EmployeeList,
                label: (
                  <span>
                    <OrderedListOutlined />
                    従業員一覧
                  </span>
                ),
              },
              {
                children: <ImportEmployees />,
                key: EmployeeTabIndex.EmployeeImport,
                label: (
                  <span>
                    <ImportOutlined />
                    従業員インポート
                  </span>
                ),
              },
            ]}
          ></EmployeeTabs>
        </EmployeeTabsWrap>
      </Layout>
    </GlobalNavigation>
  )
}

export default Employees
