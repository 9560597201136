import { ApprovalFlow } from '@ulysses-inc/harami_api_client'
import { combineReducers } from 'redux'
import {
  AddApprovalFlowAction,
  ApprovalFlowAction,
  ApprovalFlowsAction,
  UpdateApprovalFlowAction,
} from './actions'
import { ActionTypes } from './types'

interface ApprovalFlowState {
  approvalFlow: ApprovalFlow
  approvalFlowId: string
  isLoading: boolean
  error: Error | null
}

const initialApprovalFlowState: ApprovalFlowState = {
  approvalFlow: {},
  approvalFlowId: '',
  isLoading: false,
  error: null,
}

const approvalFlow = (
  state: ApprovalFlowState = initialApprovalFlowState,
  action: ApprovalFlowAction,
): ApprovalFlowState => {
  switch (action.type) {
    case ActionTypes.REQUEST_GET_APPROVAL_FLOW: {
      return {
        ...state,
        isLoading: action.isLoading,
        approvalFlowId: action.approvalFlowId,
      }
    }
    case ActionTypes.SUCCESS_GET_APPROVAL_FLOW: {
      return {
        ...state,
        isLoading: action.isLoading,
        approvalFlow: action.approvalFlow,
        error: null,
      }
    }
    case ActionTypes.ERROR_GET_APPROVAL_FLOW: {
      return {
        ...state,
        isLoading: action.isLoading,
        error: action.error,
      }
    }
    default: {
      return { ...state }
    }
  }
}

interface ApprovalFlowsState {
  approvalFlows: ApprovalFlow[]
  isLoading: boolean
  error: Error | null
}

const initialApprovalFlowsState: ApprovalFlowsState = {
  approvalFlows: [],
  isLoading: false,
  error: null,
}

const approvalFlows = (
  state: ApprovalFlowsState = initialApprovalFlowsState,
  action: ApprovalFlowsAction,
): ApprovalFlowsState => {
  switch (action.type) {
    case ActionTypes.REQUEST_GET_APPROVAL_FLOWS: {
      return {
        ...state,
        isLoading: action.isLoading,
        approvalFlows: [],
        error: null,
      }
    }
    case ActionTypes.SUCCESS_GET_APPROVAL_FLOWS: {
      return {
        ...state,
        isLoading: action.isLoading,
        approvalFlows: action.approvalFlows,
        error: null,
      }
    }
    case ActionTypes.ERROR_GET_APPROVAL_FLOWS: {
      return {
        ...state,
        isLoading: action.isLoading,
        error: action.error,
      }
    }
    default: {
      return { ...state }
    }
  }
}

interface AddApprovalFlowState {
  approvalFlow: ApprovalFlow
  isLoading: boolean
  isCompleted: boolean
  error: Error | null
}

const initialAddApprovalFlowState: AddApprovalFlowState = {
  approvalFlow: {},
  isLoading: false,
  isCompleted: false,
  error: null,
}

const addApprovalFlow = (
  state: AddApprovalFlowState = initialAddApprovalFlowState,
  action: AddApprovalFlowAction,
): AddApprovalFlowState => {
  switch (action.type) {
    case ActionTypes.REQUEST_ADD_APPROVAL_FLOW: {
      return {
        ...state,
        isLoading: action.isLoading,
        approvalFlow: action.approvalFlow,
        error: null,
      }
    }
    case ActionTypes.SUCCESS_ADD_APPROVAL_FLOW: {
      return {
        ...state,
        isLoading: action.isLoading,
        isCompleted: action.isCompleted,
        error: null,
      }
    }
    case ActionTypes.ERROR_ADD_APPROVAL_FLOW: {
      return {
        ...state,
        isLoading: action.isLoading,
        error: action.error,
      }
    }
    case ActionTypes.RESET_ADD_APPROVAL_FLOW: {
      return {
        ...state,
        isCompleted: action.isCompleted,
      }
    }
    default: {
      return { ...state }
    }
  }
}

interface UpdateApprovalFlowState {
  approvalFlow: ApprovalFlow
  approvalFlowId: string
  isLoading: boolean
  isCompleted: boolean
  error: Error | null
}

const initialUpdateApprovalFlowState: UpdateApprovalFlowState = {
  approvalFlow: {},
  isLoading: false,
  isCompleted: false,
  approvalFlowId: '',
  error: null,
}

const updateApprovalFlow = (
  state: UpdateApprovalFlowState = initialUpdateApprovalFlowState,
  action: UpdateApprovalFlowAction,
): UpdateApprovalFlowState => {
  switch (action.type) {
    case ActionTypes.REQUEST_UPDATE_APPROVAL_FLOW: {
      return {
        ...state,
        isLoading: action.isLoading,
        approvalFlow: action.approvalFlow,
        approvalFlowId: action.approvalFlowId,
        error: null,
      }
    }
    case ActionTypes.SUCCESS_UPDATE_APPROVAL_FLOW: {
      return {
        ...state,
        isLoading: action.isLoading,
        isCompleted: action.isCompleted,
        error: null,
      }
    }
    case ActionTypes.ERROR_UPDATE_APPROVAL_FLOW: {
      return {
        ...state,
        isLoading: action.isLoading,
        error: action.error,
      }
    }
    case ActionTypes.RESET_UPDATE_APPROVAL_FLOW: {
      return {
        ...state,
        isCompleted: action.isCompleted,
      }
    }
    default: {
      return { ...state }
    }
  }
}

const approvalFlowsReducer = combineReducers({
  approvalFlow,
  approvalFlows,
  addApprovalFlow,
  updateApprovalFlow,
})

export default approvalFlowsReducer
