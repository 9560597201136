import { css } from '@emotion/react'
import { ReactNode } from 'react'
import { Header } from 'src/components/header/Header'
import { HeaderTitle } from 'src/components/header/HeaderTitle'
import { StatusIconLegend } from 'src/features/scheduledReportSummary/components/StatusIconLegend'
import { ColorTextTertiary } from '../theme/KdsThemeColor'

type Props = {
  children: ReactNode
  withPadding?: boolean
}

const styles = {
  text: css`
    color: ${ColorTextTertiary};
    font-size: 12px;
    align-items: center;
    display: flex;
  `,
}

/**
 * データの取得状態に関わらず必ず表示する部分を抜き出したコンポーネント
 */
export const ScheduledReportSummaryWrapper = (props: Props) => {
  const { children, withPadding } = props

  return (
    <div>
      <Header rightSlot={<StatusIconLegend />}>
        <HeaderTitle isBeta title="実施状況" />
        <div css={styles.text}>期間スケジュールには未対応です</div>
      </Header>
      <div css={css({ padding: withPadding ? '24px' : 0 })}>{children}</div>
    </div>
  )
}
