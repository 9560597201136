import { Template } from '@ulysses-inc/harami_api_client'
import { Layout } from 'antd'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Header } from 'src/components/header/Header'
import { HeaderTabButton } from 'src/components/header/HeaderTabButton'
import { GlobalNavigation } from 'src/features/globalNavigation/GlobalNavigation'
import { Disable } from 'src/features/theme/KdsThemeColor'
import placesOperations from 'src/state/ducks/places/operations'
import {
  changeTemplatesPage as changeTemplatesPageAction,
  changeTemplatesSize as changeTemplatesSizeAction,
  updatePagination,
} from 'src/state/ducks/templateList/actions'
import { RootState } from 'src/state/store'
import { adjustCurrentPage } from 'src/util/adjustCurrentPage'
import { isNullish } from 'src/util/isNullish'
import Loading from '../../../components/loading/Loading'
import { useListFilter } from '../../../hooks/filter/useListFilter'
import Actions from './Actions'
import { DeleteErrorModal } from './DeleteErrroModal'
import Table from './Table'
import { TemplatesPagination } from './Templates.styled'

export type TTemplateWithId = Omit<Template, 'id'> &
  Required<Pick<Template, 'id'>>

export const isTemplateWithId = (v: Template): v is TTemplateWithId =>
  !isNullish(v.id)

const { Content } = Layout

const TemplatesScene = () => {
  const {
    templateListState: { templates, isLoading, request, filter, count },
  } = useSelector((state: RootState) => state)

  const dispatch = useDispatch()
  const changeTemplatesPage = (page: number, pageSize?: number) => {
    dispatch(changeTemplatesPageAction(page, pageSize, filter))
  }
  const changeTemplatesSize = (pageSize: number) => {
    dispatch(changeTemplatesSizeAction(pageSize, filter))
  }

  const {
    // localstorageから読み出したフィルタリング設定＆ページネーション設定
    filters,
    setListFilter,
  } = useListFilter()

  useEffect(() => {
    placesOperations.getPlaces(dispatch, {})
    placesOperations.getPlaceGroups(dispatch)

    // マウント時に、localstorageから読み出したフィルタリング設定＆ページネーション設定が存在する場合は、
    // それをstoreにセットする。
    //
    // - ひな形個別ページからの戻り時
    //   => フィルタリング設定＆ページネーション設定のどちらもリストアされる
    //
    // - GlobalNavigationから別画面に遷移し、戻ってきたとき
    //   => フィルタリング設定のみリストアされる。
    //      これは、サイドメニュー(GlobalNavigation)をクリックして別画面に遷移したときに、
    //      ページネーションの設定をリセットしているため。
    const templatesFilter = filters?.templates
    dispatch(
      // TODO: 意図通りに動作していないと思われるが、ここを修正するより、ひな形一覧ページ自体をいちから作り直したほうがよい。
      updatePagination(
        templatesFilter?.pagination?.limit ?? 25,
        templatesFilter?.pagination?.offset ?? 0,
      ),
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    // ユーザのUI操作によりstoreのフィルタリング設定＆ページネーション設定に変更があった場合は、
    // localstorageに保存しておく。
    setListFilter({
      ...filters,
      templates: {
        pagination: request,
        filter: filters?.templates?.filter,
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [request.limit, request.offset])

  const selectedTemplateIdsState = useState(new Set<number>())
  const [selectedTemplateIds, setSelectedTemplateIds] = selectedTemplateIdsState

  useEffect(() => {
    // ひな形一覧が更新された際、selectedTemplateIdsに不要なものが含まれていたら削除する
    const prunedSelectedTemplateIds = templates
      // idがないことは想定されない。以降、このコメントは省略。
      .filter((template): template is TTemplateWithId =>
        isTemplateWithId(template),
      )
      .filter(template => selectedTemplateIds.has(template.id))
      .map(template => template.id)

    setSelectedTemplateIds(new Set(prunedSelectedTemplateIds))

    // 無限ループに入るので`selectedTemplateIds`はdepsから外す
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templates])

  // 現在のページを調整する
  const currentPage = adjustCurrentPage(request.limit, request.offset, count)

  return (
    // TODO: サイドメニュー描写の責務を`Routes.tsx`に移植する
    <GlobalNavigation
      // サイドバーのメニューをクリックして他画面へ遷移した時に発動する。
      // `resetPagination`ではなく`onClickMenuItem`くらいが正しい名前と思われる。
      resetPagination={() => {
        // フィルタリング設定＆ページネーション設定をlocalstorageに保存しておく。その際、
        // - フィルタリング設定はそのまま保存する
        // - ページネーション設定（ページあたりの表示上限件数と、オフセット）は初期値にリセットしておく
        setListFilter({
          ...filters,
          templates: {
            pagination: { limit: 25, offset: 0 },
            filter: filters?.templates?.filter,
          },
        })
      }}
    >
      <Header>
        <HeaderTabButton type="link">
          <Link to="/templates">ひな形</Link>
        </HeaderTabButton>
        <HeaderTabButton type="link" theme={{ color: Disable }}>
          <Link to="/multipleChoiceSets">セット選択肢</Link>
        </HeaderTabButton>
      </Header>
      <Layout className="layout">
        <Content>
          <Actions {...{ selectedTemplateIdsState }} />
          {isLoading ? (
            <Loading />
          ) : (
            <Table {...{ selectedTemplateIdsState }} />
          )}
        </Content>
        <TemplatesPagination
          showSizeChanger
          current={currentPage}
          pageSizeOptions={['10', '25', '50', '100']}
          pageSize={request.limit ?? 25}
          defaultCurrent={1}
          total={count}
          onChange={changeTemplatesPage}
          onShowSizeChange={(_, size) => changeTemplatesSize(size)}
        />
      </Layout>
      <DeleteErrorModal />
    </GlobalNavigation>
  )
}

export default TemplatesScene
