import {
  TemplateNodeSchema,
  TemplateNodeTypeEnum,
} from '@ulysses-inc/harami_api_client'
import React from 'react'
import { MoveNodeEvent } from 'src/features/templateEdit/dragAndDrop/DnDTemplateType'
import { SectionBackgroundColor } from 'src/features/theme/KdsThemeColor'
import styled from 'styled-components'
import StyledIndentation from '../common/StyledIndentation'
import AddTemplateModifyButtonGroupInEmpty from '../itemAppender/AddTemplateModifyButtonGroupInEmpty'
import Question from '../question/Question'
import SectionContainer from './Section'

const SectionContentContainer = styled.div`
  display: flex;
`

const SectionContentWrapper = styled.div`
  flex: 1;
`

interface OwnProps {
  node: TemplateNodeSchema
  activeNodeId: number
  path: number[]
  useTemplateNodeNodes: (
    templateNode: TemplateNodeSchema,
  ) => Array<TemplateNodeSchema>
  addTemplateNode: (
    targetNode: TemplateNodeSchema | null,
    parentNode: TemplateNodeSchema | null,
    position: 'top' | 'bottom',
    addTemplateNodeType: TemplateNodeTypeEnum,
  ) => void
  moveNode: (event: MoveNodeEvent) => void
}

const SectionContent: React.FC<OwnProps> = (props: OwnProps) => {
  const templateNodeNodes = props.useTemplateNodeNodes(props.node)
  return (
    <SectionContentContainer key={props.node.id} data-testid="section-content">
      <StyledIndentation color={SectionBackgroundColor} />
      <SectionContentWrapper>
        {props.node.nodes.length === 0 ? (
          <AddTemplateModifyButtonGroupInEmpty
            node={props.node}
            parentNode={props.node}
            addTemplateNode={props.addTemplateNode}
            moveNode={props.moveNode}
          />
        ) : (
          templateNodeNodes.map((node, index) => {
            if (node.type === TemplateNodeTypeEnum.Question) {
              return (
                <Question
                  key={node.id}
                  node={node}
                  parentNode={props.node}
                  path={[...props.path, props.node.id]}
                  isActive={props.activeNodeId === node.id}
                  isLastChild={templateNodeNodes.length === index + 1}
                />
              )
            }
            return (
              <SectionContainer
                key={node.id}
                node={node}
                parentNode={props.node}
                path={[...props.path, props.node.id]}
                isActive={props.activeNodeId === node.id}
              />
            )
          })
        )}
      </SectionContentWrapper>
    </SectionContentContainer>
  )
}

export default SectionContent
