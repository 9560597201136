import deepEqual from 'fast-deep-equal'
import * as qs from 'qs'
import { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import {
  ReportListFilters,
  isValidFilterValueOf,
} from 'src/features/reports/listBetaFilters/types'
import { isValidDateString } from 'src/util/datetime/isValidDateString'
import { removeMatchingKeysAndValues } from 'src/util/removeMatchingKeysAndValues'

export const initialFilters: ReportListFilters = {
  dateFrom: null,
  dateTo: null,
  deviatedAnswer: [],
  employeeNameOrCode: '',
  page: 1,
  pageSize: 50,
  placeNodeUuids: [],
  reportName: '',
  statuses: [],
}

const qsOptions = {
  parse: { ignoreQueryPrefix: true },
  stringify: {
    skipNulls: true,
    encode: false,
  },
}

/**
 * 型の保証と不正な値のデフォルト値へのフォールバックを行う
 */
const sanitize = {
  date: (arg: unknown): string | null => {
    if (isValidDateString(arg)) {
      return arg
    }
    return initialFilters.dateFrom
  },
  deviatedAnswer: (arg: unknown): ReportListFilters['deviatedAnswer'] => {
    if (Array.isArray(arg)) {
      return arg.filter(isValidFilterValueOf.deviatedAnswer)
    }
    return initialFilters.deviatedAnswer
  },
  employeeNameOrCode: (arg: unknown): string => {
    if (typeof arg === 'string') {
      return arg
    }
    return initialFilters.employeeNameOrCode
  },
  page: (arg: unknown): number => {
    const parsedArg = Number(arg)
    if (parsedArg >= 1) return parsedArg
    return initialFilters.page
  },
  pageSize: (arg: unknown): number => {
    const parsedArg = Number(arg)
    if (parsedArg >= 1 && parsedArg <= 100) return parsedArg
    return initialFilters.pageSize
  },
  placeNodeUuids: (arg: unknown): string[] => {
    if (Array.isArray(arg)) {
      return arg.filter((uuid): uuid is string => typeof uuid === 'string')
    }
    return initialFilters.placeNodeUuids
  },
  reportName: (arg: unknown): string => {
    if (typeof arg === 'string') {
      return arg
    }
    return initialFilters.reportName
  },
  statuses: (arg: unknown): ReportListFilters['statuses'] => {
    if (Array.isArray(arg)) {
      return arg.filter(isValidFilterValueOf.status)
    }
    return initialFilters.statuses
  },
}

export const useReportListFilters = () => {
  const history = useHistory()
  const location = useLocation()

  const getFiltersFromQueryString = (): ReportListFilters => {
    const rawFilters = qs.parse(location.search, qsOptions.parse)
    return {
      dateFrom: sanitize.date(rawFilters['dateFrom']),
      dateTo: sanitize.date(rawFilters['dateTo']),
      deviatedAnswer: sanitize.deviatedAnswer(rawFilters['deviatedAnswer']),
      employeeNameOrCode: sanitize.employeeNameOrCode(
        rawFilters['employeeNameOrCode'],
      ),
      page: sanitize.page(rawFilters['page']),
      pageSize: sanitize.pageSize(rawFilters['pageSize']),
      placeNodeUuids: sanitize.placeNodeUuids(rawFilters['placeNodeUuids']),
      reportName: sanitize.reportName(rawFilters['reportName']),
      statuses: sanitize.statuses(rawFilters['statuses']),
    }
  }

  const [filters, setFilters] = useState<ReportListFilters>(() =>
    getFiltersFromQueryString(),
  )

  // フィルタが変更されたらURLを更新する
  useEffect(() => {
    // URLが散らかるのを防ぎたいため、初期値と同じフィルタは取り除く
    const filtersToSet = removeMatchingKeysAndValues(filters, initialFilters)
    const newQueryString = qs.stringify(filtersToSet, qsOptions.stringify)

    const isAlreadySynchronized =
      newQueryString === history.location.search.substring(1)
    if (isAlreadySynchronized) return

    history.push({ search: newQueryString })
  }, [filters, history])

  // URLが変更されたらフィルタを更新する
  useEffect(
    () => {
      const newFilters = getFiltersFromQueryString()

      const isAlreadySynchronized = deepEqual(newFilters, filters)
      if (isAlreadySynchronized) return

      setFilters(newFilters)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      // getFiltersFromQueryString,
      location.search,
    ],
  )

  /**
   * フィルタの値を更新する
   *
   * 型のおかげで本来はsanitize関数が不要なプロパティもあるものの、
   * 一貫性を重視してすべてのプロパティに一括してsanitize関数を適用している。
   */
  const setFilterOf = {
    dateRange: (dateFrom: string, dateTo: string) => {
      setFilters(prev => ({
        ...prev,
        dateFrom: sanitize.date(dateFrom),
        dateTo: sanitize.date(dateTo),
        page: initialFilters.page,
      }))
    },
    deviatedAnswer: (deviatedAnswer: ReportListFilters['deviatedAnswer']) => {
      setFilters(prev => ({
        ...prev,
        deviatedAnswer: sanitize.deviatedAnswer(deviatedAnswer),
        page: initialFilters.page,
      }))
    },
    employeeNameOrCode: (employeeNameOrCode: string) => {
      setFilters(prev => ({
        ...prev,
        employeeNameOrCode: sanitize.employeeNameOrCode(employeeNameOrCode),
        page: initialFilters.page,
      }))
    },
    pageAndPageSize: (page: number, pageSize: number) => {
      setFilters(prev => ({
        ...prev,
        page: sanitize.page(page),
        pageSize: sanitize.pageSize(pageSize),
      }))
    },
    placeNodeUuids: (placeNodeUuids: string[]) => {
      setFilters(prev => ({
        ...prev,
        placeNodeUuids: sanitize.placeNodeUuids(placeNodeUuids),
        page: initialFilters.page,
      }))
    },
    reportName: (reportName: string) => {
      setFilters(prev => ({
        ...prev,
        reportName: sanitize.reportName(reportName),
        page: initialFilters.page,
      }))
    },
    statuses: (statuses: ReportListFilters['statuses']) => {
      setFilters(prev => ({
        ...prev,
        statuses: sanitize.statuses(statuses),
        page: initialFilters.page,
      }))
    },
  }

  const resetFilterOf = {
    // 名前がややこしいのは、レポート名のフィルタ設定UIだけがドロワーの外に配置されており、他とは独立した挙動を求められていることに起因する
    allExceptReportName: () => {
      setFilters(current => ({
        ...initialFilters,
        reportName: current.reportName,
      }))
    },
    dateRange: () => {
      setFilters(prev => ({
        ...prev,
        dateFrom: null,
        dateTo: null,
      }))
    },
    currentPage: () => {
      setFilters(prev => ({
        ...prev,
        page: 1,
      }))
    },
  }

  // 名前がややこしいのは、レポート名のフィルタ設定UIだけがドロワーの外に配置されており、他とは独立した挙動を求められていることに起因する
  const isSomeFilterActiveExceptReportName = (() => {
    const {
      page: _exclude_1,
      pageSize: _exclude_2,
      reportName: _exclude_3,
      ...restFilters
    } = filters
    const {
      page: _exclude_4,
      pageSize: _exclude_5,
      reportName: _exclude_6,
      ...restInitialFilters
    } = initialFilters
    return !deepEqual(restFilters, restInitialFilters)
  })()

  return {
    filters,
    isSomeFilterActiveExceptReportName,
    setFilterOf,
    resetFilterOf,
  }
}
