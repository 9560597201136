import { logEvent as fireBaseLogEvent } from 'firebase/analytics'

import { firebaseAnalytics } from 'src/features/firebase/firebase'

/**
 * - 'pageQuestionAndLogicCount': 1ページ(セクション)あたりの質問数+条件分岐数
 * - 'pageCount': ページ(セクション)数
 */
export type ValidationErrorType = 'pageQuestionAndLogicCount' | 'pageCount'

/**
 * - 'rowCount': 1 表あたりの行数
 * - 'cellCount': 1 レポートあたりの、type == question である node の数
 * - 'tableCount': 1 レポートあたりの表数
 * - 'logicNestCount': 条件分岐のネスト数
 */
export type ErrorCodeErrorType =
  | 'rowCount'
  | 'cellCount'
  | 'tableCount'
  | 'logicNestCount'

/**
 * event log の対象となるソフトリミット関係のエラー種別
 */
export type ErrorType = ValidationErrorType | ErrorCodeErrorType

/**
 * event log の出力対象となる操作
 *
 * - 'updateTemplate': ひな形の保存
 * - 'patchGridVariables' 取り込み詳細の保存
 * - 'addNode' ひな形詳細での、質問や条件分岐(node)の追加
 * - 'dragNode' ひな形詳細での、質問の Drag & Drop
 */
export type Operation =
  | 'updateTemplate'
  | 'patchGridVariables'
  | 'addNode'
  | 'dragNode'

type LogEventParams = {
  errorType: ErrorType
  errorMessage: string
  templateId: number
  templateName: string
}

/**
 * 表形式のひな形・ソフトリミット超過を示す event log を
 * Firebase Analytics に出力する
 *
 * @param logEventParams
 */
export const logGridLayoutSoftLimitErrorEvent = (
  operation: Operation,
  logEventParams: LogEventParams,
) => {
  if (!firebaseAnalytics) {
    return
  }

  fireBaseLogEvent(firebaseAnalytics, 'grid_soft_limit_reach', {
    from: 'web',
    operation,
    error_type: logEventParams.errorType,
    error_message: logEventParams.errorMessage,
    template_id: logEventParams.templateId,
    template_name: logEventParams.templateName,
  })
}
