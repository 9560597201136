import { FC } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { RootState } from 'src/state/store'
import { TemplateHintTable } from './EditTemplateOption.styled'
import HintAction from './HintAction'
import HintName from './HintName'

const columns = [
  {
    title: 'ヒント',
    dataIndex: 'hint',
    key: 'hint',
    width: '90%',
  },
  {
    title: '操作',
    dataIndex: 'action',
    key: 'action',
    width: '10%',
  },
]

const HintTable: FC = () => {
  const templateHints = useSelector(
    (state: RootState) => state.templatesState.templateHints.templateHints,
    shallowEqual,
  )

  const data = templateHints.map(hint => ({
    key: `${hint.id}`,
    hint: <HintName {...{ hint }} />,
    action: <HintAction {...{ hint }} />,
  }))

  return (
    <TemplateHintTable columns={columns} dataSource={data} pagination={false} />
  )
}

export default HintTable
