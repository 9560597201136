import { TemplateLayoutTypeEnum } from '@ulysses-inc/harami_api_client'
import { Typography } from 'antd'
import { FC } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import LayoutScroll1Svg from 'src/assets/icons/layout_scroll_1.svg?react'
import LayoutScroll2Svg from 'src/assets/icons/layout_scroll_2.svg?react'
import LayoutSlideSvg from 'src/assets/icons/layout_slide.svg?react'
import { updateLayoutType } from 'src/state/ducks/templates/editTemplate/actions'
import { RootState } from 'src/state/store'
import { BasicRow, ChooseLayout, FormLabel } from './EditTemplateOption.styled'
import LayoutRadioRow from './LayoutRadioRow'

const { Text } = Typography

const LayoutRow: FC = () => {
  const dispatch = useDispatch()
  const layoutType = useSelector(
    (state: RootState) => state.templatesState.editTemplate.layoutType,
    shallowEqual,
  )

  const onClickOne = () =>
    dispatch(updateLayoutType(TemplateLayoutTypeEnum.Scroll))
  const onClickTwo = () =>
    dispatch(updateLayoutType(TemplateLayoutTypeEnum.ScrollTwoColumn))
  const onClickSlide = () =>
    dispatch(updateLayoutType(TemplateLayoutTypeEnum.Slide))

  const isOne = layoutType === TemplateLayoutTypeEnum.Scroll
  const isTwo = layoutType === TemplateLayoutTypeEnum.ScrollTwoColumn
  const isSlide = layoutType === TemplateLayoutTypeEnum.Slide

  return (
    <BasicRow>
      <FormLabel>
        <Text strong>ひな形のレイアウト</Text>
      </FormLabel>
      <ChooseLayout>
        <LayoutRadioRow
          onClick={onClickOne}
          checked={isOne}
          name="スクロール(1列)"
          description={[
            'セクション内の全ての質問が１ページ内に表示されます。（従来）',
          ]}
          Image={LayoutScroll1Svg}
        />
        <LayoutRadioRow
          onClick={onClickTwo}
          checked={isTwo}
          name="スクロール(2列)"
          description={[
            'セクション内の全ての質問が１ページ内に二列で表示されます。予め設定したデフォルト回答で完結する定常チェックにおすすめです。',
          ]}
          Image={LayoutScroll2Svg}
        />
        <LayoutRadioRow
          onClick={onClickSlide}
          checked={isSlide}
          name="紙芝居"
          description={[
            '一度に１質問しか表示されません。回答後自動的に次の質問に進みます。',
          ]}
          Image={LayoutSlideSvg}
        />
      </ChooseLayout>
    </BasicRow>
  )
}

export default LayoutRow
