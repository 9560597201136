import { Page } from '@react-pdf/renderer'
import {
  ReportNodeSchema,
  ReportPageSchema,
} from '@ulysses-inc/harami_api_client'
import React from 'react'
import { ReportResultSchema } from 'src/exShared/types/types'
import DeviatedAnswers from '../../../../deviatedAnswers/DeviatedAnswers'
import ReportSummaryPage from '../../../../reportSummaryPage/ReportSummaryPage'
import Scoring from '../../../../scoring/Scoring'
import ReportSummaryOptions from '../../../reportOptions/ReportSummaryOptions'
import { reportDetailLayout } from '../../shared/styles'

interface ReportDetailGridLayoutProps {
  report: ReportResultSchema
  deviatedAnswers: ReportNodeSchema[]
  getPages: () => Array<ReportPageSchema>
}

const ReportDetailDefaultLayout: React.FC<ReportDetailGridLayoutProps> = (
  props: ReportDetailGridLayoutProps,
) => {
  return (
    <>
      {props.report.isShowScore && <Scoring reportResult={props.report} />}
      {props.deviatedAnswers.length > 0 && (
        <Page size="A4" style={reportDetailLayout.body}>
          <DeviatedAnswers
            deviatedAnswers={props.deviatedAnswers}
            nodes={props.report.nodes}
          />
        </Page>
      )}
      {props.getPages().map((page: ReportPageSchema) => (
        <Page key={page.id} size="A4" style={reportDetailLayout.body}>
          <ReportSummaryPage page={page} nodes={props.report.nodes} />
        </Page>
      ))}
      {props.report.images.length > 0 &&
        props.report.manualMemos.length > 0 && (
          <Page size="A4" style={reportDetailLayout.body}>
            <ReportSummaryOptions
              images={props.report.images}
              manualMemos={props.report.manualMemos}
            />
          </Page>
        )}
    </>
  )
}

export default ReportDetailDefaultLayout
