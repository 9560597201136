import { PlaceNode, PlaceNodeTypeEnum } from '@ulysses-inc/harami_api_client'
import { localStorageKeys } from 'src/constants/localStorageKeys'
import { mergedPlaceNodeIds } from 'src/exShared/util/place/placeNode'
import { splitLoginPlaceUUIDs } from 'src/exShared/util/place/splitLoginPlace'

const getLoginPlaceNodes = (): PlaceNode[] => {
  // ログインユーザが直接所属している現場/グループ
  const loginPlaceNodesStr = localStorage.getItem(
    localStorageKeys.loginUserPlaceNodes,
  )
  return loginPlaceNodesStr ? JSON.parse(loginPlaceNodesStr) : []
}

const getChildNodes = (groupNode: PlaceNode): PlaceNode[] => {
  if (!groupNode.nodes) {
    return []
  }
  let childPlaceNodes: PlaceNode[] = []
  groupNode.nodes.forEach(obj => {
    const node = obj as PlaceNode
    if (node.type === PlaceNodeTypeEnum.PlaceGroup) {
      childPlaceNodes = childPlaceNodes.concat(getChildNodes(node))
    } else {
      childPlaceNodes.push(node)
    }
  })
  return childPlaceNodes
}

const removeDuplicatePlaceNodes = (placeNodes: PlaceNode[]): PlaceNode[] => {
  return placeNodes.reduce(
    (accumulators: PlaceNode[], placeNode: PlaceNode) => {
      if (!accumulators.some(a => a.uuid === placeNode.uuid)) {
        accumulators.push(placeNode)
      }
      return accumulators
    },
    [],
  )
}

const splitAllPlaceNodes = (placeNodes: PlaceNode[], places: PlaceNode[]) => {
  const placeGroups = placeNodes.filter(
    node => node.type === PlaceNodeTypeEnum.PlaceGroup,
  )

  return {
    accessiblePlaces: removeDuplicatePlaceNodes(places ?? []),
    accessiblePlaceGroups: removeDuplicatePlaceNodes(placeGroups ?? []),
  }
}

const splitAccessiblePlaceNode = (
  placeNodes: PlaceNode[],
  places: PlaceNode[],
) => {
  const loginPlaceNodes = getLoginPlaceNodes()
  let accessiblePlaceGroups: PlaceNode[] = []
  let accessiblePlaces: PlaceNode[] = []
  if (loginPlaceNodes.length === 0) {
    // 未所属の場合は全現場を選択可能
    accessiblePlaceGroups = placeNodes.filter(
      node => node.type === PlaceNodeTypeEnum.PlaceGroup,
    )
    accessiblePlaces = places
  } else {
    accessiblePlaceGroups = placeNodes
      .filter(node => node.type === PlaceNodeTypeEnum.PlaceGroup)
      .filter(node =>
        loginPlaceNodes.find(loginNode => loginNode.uuid === node.uuid),
      )

    loginPlaceNodes.forEach(loginPlaceNode => {
      if (loginPlaceNode.type === PlaceNodeTypeEnum.Place) {
        accessiblePlaces.push(loginPlaceNode)
      } else if (loginPlaceNode.type === PlaceNodeTypeEnum.PlaceGroup) {
        const placeGroup = placeNodes.find(
          group => group.uuid === loginPlaceNode.uuid,
        )
        if (placeGroup) {
          accessiblePlaces = accessiblePlaces.concat(getChildNodes(placeGroup))
        }
      }
    })
  }
  return {
    accessiblePlaces: removeDuplicatePlaceNodes(accessiblePlaces ?? []),
    accessiblePlaceGroups: removeDuplicatePlaceNodes(
      accessiblePlaceGroups ?? [],
    ),
  }
}

const initializePlaceFilter = (
  placeNodes?: PlaceNode[],
  isIncludeGroup?: boolean,
) => {
  const loginPlaceUUIDs = splitLoginPlaceUUIDs()
  const placeNodeIds = mergedPlaceNodeIds(
    placeNodes ?? [],
    loginPlaceUUIDs.loginPlaceIds,
    loginPlaceUUIDs.loginPlaceGroupIds,
    isIncludeGroup,
  )
  return placeNodeIds && placeNodeIds.length > 0
    ? { $in: placeNodeIds }
    : undefined
}

export { initializePlaceFilter, splitAccessiblePlaceNode, splitAllPlaceNodes }
