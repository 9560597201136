import { ReportNodeSchema } from '@ulysses-inc/harami_api_client'
import { ReportNodesDict } from 'src/exShared/types/types'
import { isOriginalRepeatedSection } from 'src/exShared/util/nodes/isOriginalRepeatedSection'
import { isSection } from 'src/exShared/util/nodes/isSection'

/**
 * 与えたノード群において、指定したセクション群を起点としてノードを下位方向に再帰的に走査し、
 * 全てのセクションを抽出したうえで、抽出した順に配列として返す。
 *
 * @param reportNodesDict 走査対象のノード群。`ReportResultSchema.nodes`(レポート結果に含まれる全てのノード)を想定。
 * @param startingSectionNodeIds 走査の開始点となるノードのid群。`ReportResultSchema.pages.nodes[]`(ページごとにまとめられたセクションID群)をマージしたものを想定。
 * @param includeOriginalRepeatedSection 繰り返しセクションのマスタを結果に含めるかどうか
 */
export const getSectionNodes = (
  reportNodesDict: ReportNodesDict,
  startingSectionNodeIds: number[],
  includeOriginalRepeatedSection = false,
): ReportNodeSchema[] => {
  const pickedSectionNodes: ReportNodeSchema[] = []

  const recursiveTask = sectionNodeIds => {
    sectionNodeIds.forEach(sectionNodeId => {
      const thisNode = reportNodesDict[sectionNodeId]
      if (!isSection(thisNode)) {
        return
      }
      if (
        !includeOriginalRepeatedSection &&
        isOriginalRepeatedSection(thisNode)
      ) {
        return
      }
      pickedSectionNodes.push(thisNode)

      const childNodesExist = thisNode.nodes.length > 0
      if (childNodesExist) {
        recursiveTask(thisNode.nodes)
      }
    })
  }

  recursiveTask(startingSectionNodeIds)

  return pickedSectionNodes
}
