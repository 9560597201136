import { ScheduleTypeEnum } from '@ulysses-inc/harami_api_client'
import React from 'react'
import FrequencySelectOptionInDate from 'src/features/schedules/editSchedule/FrequencySelectOptionInDate'
import FrequencySelectOptionInDuration from 'src/features/schedules/editSchedule/FrequencySelectOptionInDuration'
import { TBaseFormRowProps } from './EditSchedule'
import { FormLabel, FormRow, Text } from './EditSchedule.styled'

const ScheduleFrequencyRow: React.FC<TBaseFormRowProps> = ({
  setFieldValue,
  values: {
    type,
    frequency,
    frequencyWeekly,
    frequencyMonthly,
    frequencyCustom,
  },
  errors,
  touched,
  submitCount,
}) => (
  <FormRow>
    <FormLabel>
      <Text strong>頻度</Text>
    </FormLabel>
    {type === ScheduleTypeEnum.Date && (
      <FrequencySelectOptionInDate
        errors={errors}
        touched={touched}
        submitCount={submitCount}
        frequency={frequency ?? null}
        frequencyWeekly={frequencyWeekly}
        frequencyMonthly={frequencyMonthly}
        setFieldValue={setFieldValue}
      />
    )}
    {type === ScheduleTypeEnum.Duration && (
      <FrequencySelectOptionInDuration
        errors={errors}
        touched={touched}
        submitCount={submitCount}
        frequency={frequency ?? null}
        frequencyWeekly={frequencyWeekly}
        frequencyMonthly={frequencyMonthly}
        frequencyCustom={frequencyCustom}
        setFieldValue={setFieldValue}
      />
    )}
  </FormRow>
)

export default ScheduleFrequencyRow
