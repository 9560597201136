import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'src/state/store'

type TPlaceLoadingStatus = 'initial' | 'loading' | 'finish'

/**
 * getPlaces, getPlaceGroups のリクエスト完了を待ちたいときに使用する
 *
 * @returns 初期状態, リクエスト中, リクエスト完了
 */
export const usePlaceLoadingStatus = () => {
  const {
    placesState: {
      places: { isLoading: isLoadingPlaces },
      placeGroups: { isLoading: isLoadingPlaceGroups },
    },
  } = useSelector((state: RootState) => state)

  // 現場、現場グループのデータ取得状態を管理する
  const [placeStatus, setPlaceStatus] = useState<TPlaceLoadingStatus>('initial')

  // 現場、現場グループのリクエスト処理状態を検知する
  useEffect(() => {
    const isLoading = isLoadingPlaces || isLoadingPlaceGroups
    const isLoaded = !isLoadingPlaces && !isLoadingPlaceGroups
    if (placeStatus === 'initial' && isLoading) {
      setPlaceStatus('loading')
    } else if (placeStatus === 'loading' && isLoaded) {
      setPlaceStatus('finish')
    }
  }, [isLoadingPlaces, isLoadingPlaceGroups, placeStatus])

  return placeStatus
}
