// prettier-ignore
export const ActionTypes = {
  REQUEST_GET_IMPROVE_EMAIL_LOGS: 'harami/improveEmailLogs/REQUEST_GET_IMPROVE_EMAIL_LOGS',
  SUCCESS_GET_IMPROVE_EMAIL_LOGS: 'harami/improveEmailLogs/SUCCESS_GET_IMPROVE_EMAIL_LOGS',
  ERROR_GET_IMPROVE_EMAIL_LOGS: 'harami/improveEmailLogs/ERROR_GET_IMPROVE_EMAIL_LOGS',
  REQUEST_ADD_IMPROVE_EMAIL_LOG: 'harami/improveEmailLogs/REQUEST_ADD_IMPROVE_EMAIL_LOGS',
  SUCCESS_ADD_IMPROVE_EMAIL_LOG: 'harami/improveEmailLogs/SUCCESS_ADD_IMPROVE_EMAIL_LOGS',
  ERROR_ADD_IMPROVE_EMAIL_LOG: 'harami/improveEmailLogs/ERROR_ADD_IMPROVE_EMAIL_LOGS',
  CHANGE_IS_SHOW_IMPROVE_EMAIL_LOGS_DRAWER: 'harami/improveEmailLogs/CHANGE_IS_SHOW_IMPROVE_EMAIL_LOGS_DRAWER',
} as const
