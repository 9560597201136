import { HomeOutlined, TagOutlined } from '@ant-design/icons'
import { PlaceNodeTypeEnum } from '@ulysses-inc/harami_api_client'
import { Select, Typography } from 'antd'
import { FC, useId } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { updatePlaceNodes } from 'src/state/ducks/templates/editTemplate/actions'
import { RootState } from 'src/state/store'
import { BasicRow, FormLabel } from './EditTemplateOption.styled'

const { Text } = Typography
const { Option } = Select

const PlaceRow: FC = () => {
  const dispatch = useDispatch()

  const templatePlaceNodes = useSelector(
    (state: RootState) => state.placesState.placeNodes.nodes,
    shallowEqual,
  )
  const selectedPlaceNodes = useSelector(
    (state: RootState) => state.templatesState.editTemplate.selectedPlaceNodes,
    shallowEqual,
  )

  const onChange = (placeNodeIds: string[]) =>
    dispatch(updatePlaceNodes(placeNodeIds))

  // FIXME `?` いらないはずだが元コードに存在し怖いので調査するまでつけておく
  const value = selectedPlaceNodes?.map(({ uuid }) => uuid) ?? []

  const filterOption = (inputValue: string, option: any) => {
    const optionText = option.children.props.children[1].props.children // アイコンを除いたテキストを取得する
    return optionText?.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
  }
  const labelId = useId()

  return (
    <BasicRow>
      <FormLabel id={labelId}>
        <Text strong>現場の設定</Text>
      </FormLabel>
      <Select
        mode="multiple"
        style={{ width: '500px' }}
        optionFilterProp="children"
        filterOption={filterOption}
        onChange={onChange}
        value={value}
        aria-labelledby={labelId}
      >
        {templatePlaceNodes?.map(({ uuid, type, place, placeGroup }) => (
          <Option key={uuid} value={uuid}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{ marginRight: 8 }}>
                {type === PlaceNodeTypeEnum.PlaceGroup ? (
                  <TagOutlined />
                ) : (
                  <HomeOutlined />
                )}
              </div>
              <span>
                {type === PlaceNodeTypeEnum.PlaceGroup
                  ? placeGroup?.name
                  : place?.name || ''}
              </span>
            </div>
          </Option>
        ))}
      </Select>
    </BasicRow>
  )
}

export default PlaceRow
