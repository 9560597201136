import { ImproveEmail } from '@ulysses-inc/harami_api_client'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ImproveEmailLogsDrawer from 'src/features/improveEmailLogs/ImproveEmailLogsDrawer'
import {
  DispatchProps,
  OwnProps,
  StateProps,
} from 'src/features/improveEmailLogs/ImproveEmailLogsProps'
import improveEmailLogsOperations from 'src/state/ducks/improveEmailLogs/operations'
interface State {
  improveEmailLogsState: {
    improveEmailLogs: {
      improveEmailLogs: ImproveEmail[]
      isLoading: boolean
      isShow: boolean
    }
  }
}

const useStateProps = (): StateProps => {
  return {
    improveEmailLogs: useSelector(
      (state: State) =>
        state.improveEmailLogsState?.improveEmailLogs?.improveEmailLogs,
    ),
    isLoading: useSelector(
      (state: State) =>
        state.improveEmailLogsState?.improveEmailLogs?.isLoading,
    ),
    isShow: useSelector(
      (state: State) => state.improveEmailLogsState?.improveEmailLogs?.isShow,
    ),
  }
}

const useReducerProps = (): DispatchProps => {
  const dispatch = useDispatch()
  return {
    getImproveEmailLogs: (improveUUID: string) => {
      improveEmailLogsOperations.getImproveEmailLogs(dispatch, {
        improveUUID: improveUUID,
      })
    },
    changeIsShowImproveEmailLogsDrawer: (isShow: boolean) => {
      improveEmailLogsOperations.changeIsShowImproveEmailLogsDrawer(
        dispatch,
        isShow,
      )
    },
  }
}

const ImproveEmailLogsDrawerContainer: React.FC<OwnProps> = (
  _props: OwnProps,
) => {
  const stateProps = useStateProps()
  const dispatchProps = useReducerProps()
  const props = { ...stateProps, ...dispatchProps, ..._props }
  return <ImproveEmailLogsDrawer {...props} />
}

export default ImproveEmailLogsDrawerContainer
