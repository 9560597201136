import { Layout, Pagination } from 'antd'
import styled from 'styled-components'

const { Content } = Layout

export const ContentWrapper = styled(Content)`
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  width: 100%;
  overflow-x: auto;
`

export const ListContainer = styled.div`
  max-width: 830px;
  width: 100%;
`

export const UserGroupsPagination = styled(Pagination)`
  margin-top: 16px;
  margin-bottom: 16px;
  display: flex;
  justify-content: center;
`
