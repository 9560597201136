import React, { DetailedHTMLProps, HTMLAttributes } from 'react'
import { Black } from 'src/features/theme/KdsThemeColor'

const SmallFont = {
  fontSize: 12,
  lineHeight: '17px',
}

const DefaultFont = {
  fontSize: 16,
  lineHeight: '20px',
}

const LargeFont = {
  fontSize: 20,
  lineHeight: '26px',
}

// reactで定義されている型をそのまま流用
type DivProps = DetailedHTMLProps<
  HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>

type TextProps = DivProps & {
  small?: boolean
  large?: boolean
  color?: string
}

export const Text: React.FC<TextProps> = props => {
  const { small, large, color, style, children, ...restProps } = props

  let responsiveStyles = DefaultFont
  if (small) {
    responsiveStyles = SmallFont
  } else if (large) {
    responsiveStyles = LargeFont
  }

  const newStyle = {
    color: color || Black,
    ...responsiveStyles,
    ...style,
  }

  return (
    <div {...restProps} style={newStyle}>
      {children}
    </div>
  )
}
