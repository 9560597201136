import {
  ReportQuestion,
  ResponseTypeEnum,
} from '@ulysses-inc/harami_api_client'
import { isNullish } from 'src/util/isNullish'

export const isAnswered = (
  question?: ReportQuestion,
  isReportResult?: boolean,
): boolean => {
  if (!question) {
    return false
  }
  if (
    isReportResult &&
    (question.responseType === ResponseTypeEnum.INFORMATION ||
      question.responseType === ResponseTypeEnum.URL)
  ) {
    // インフォメーションまたはURLの場合は回答によらず表示する
    return true
  }

  if (!question.responseAnswer) {
    return false
  }

  if (question.responseAnswer.noAnswer?.reason) {
    return true
  }

  // 添付画像、テキストメモ、マニュアルメモがあれば表示
  // レポート中画面は必須未回答チェックをしたいため、この条件はレポート結果画面のみとする
  if (
    isReportResult &&
    ((question.responseAnswer.images ?? []).length > 0 ||
      (question.responseAnswer.manualMemos ?? []).length > 0 ||
      (question.responseAnswer.textMemos ?? []).length > 0)
  ) {
    return true
  }

  switch (question.responseType) {
    case ResponseTypeEnum.MULTIPLE_CHOICE:
      return question.responseAnswer.multipleChoice?.response !== undefined
    case ResponseTypeEnum.NUMBER:
      return question.responseAnswer.numberValue?.numberValue !== undefined
    case ResponseTypeEnum.SIGNATURE:
      if (!question.responseAnswer.signatures) return false
      return (
        question.responseAnswer.signatures.length > 0 &&
        !!question.responseAnswer.signatures[0]?.url
      )
    case ResponseTypeEnum.TEXT:
      return (question.responseAnswer.textValue?.textValue || null) !== null
    case ResponseTypeEnum.RESULT_IMAGE:
      if (!question.responseAnswer.resultImages) return false
      return question.responseAnswer.resultImages.length > 0
    case ResponseTypeEnum.DATETIME:
      return question.responseAnswer.datetimeValue?.datetimeValue !== undefined
    case ResponseTypeEnum.INFORMATION:
      if (!question.responseAnswer.informations) return false
      return question.responseAnswer.informations.length > 0
    case ResponseTypeEnum.RESPONSE_SET:
      if (!question.responseAnswer.responseSets) return false
      return question.responseAnswer.responseSets.length > 0
    case ResponseTypeEnum.EMPLOYEE:
      if (!question.responseAnswer.employees) return false
      return question.responseAnswer.employees.length > 0
    case ResponseTypeEnum.FORMULA:
      return question.responseAnswer.formulaValue?.formulaValue !== undefined
    case ResponseTypeEnum.TIME_MEASUREMENT:
      return !isNullish(question.responseAnswer.timeMeasurementValue?.value)
    default:
      return (question?.responseAnswer ?? null) !== null
  }
}

export default isAnswered
