import { StyleSheet } from '@react-pdf/renderer'
import { Gray, TableBorderColor, White } from 'src/features/theme/KdsThemeColor'

export const gridLayoutTableBodyCell = StyleSheet.create({
  tableRow: {
    flexDirection: 'row',
  },
  baseTableBodyCell: {
    padding: 5,
    backgroundColor: White,
    textAlign: 'center',
    fontSize: 8,
    fontWeight: 500,
    borderTop: 'none',
    borderBottom: `1px solid ${TableBorderColor}`,
    borderLeft: 'none',
    borderRight: `1px solid ${TableBorderColor}`,
  },
  tableBodyFirstCell: {
    width: 25,
    color: Gray,
    borderLeft: `1px solid ${TableBorderColor}`,
  },
  tableBodyCell: {
    width: 65,
  },
  tableBodyCellNotWorked: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: 0,
    width: 65,
  },
})
