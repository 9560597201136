import {
  ResponseDatetimeSubTypeEnum,
  ResponseFormulaTokenTypeEnum,
  ResponseTypeEnum,
  TemplateLayoutTypeEnum,
  TemplateNodeSchema,
  TemplateNodeTypeEnum,
} from '@ulysses-inc/harami_api_client'
import { TemplateNodesDict, TemplatePagesDict } from 'src/exShared/types/types'
import { isSection } from 'src/exShared/util/nodes/isSection'
import { createNodePositionChecker } from 'src/features/templateEdit/common/nodePositionChecker'
import { isNullish } from 'src/util/isNullish'
import { ValidationErrorType } from '../firebase/featureSpecificEventLog/gridLayoutSoftLimitError'
import { validate as validateTimeMeasurementRule } from './editResponseTimeMeasurementRule/validateMeasurementRule'

// 表形式で登録できる最大のページ数
const MAX_GRID_PAGE = 70
// 表形式で登録できるページ単位の最大質問、条件分岐数
const MAX_GRID_PAGE_QUESTION_AND_LOGIC = 50

// 表形式のひな形 条件分岐最大ネスト数
const MAX_GRID_LOGIC_NEST_COUNT = 3

// 渡された nodeIds の node 及び、その子 node に質問があるかを返す
const hasQuestionNode = (
  templateNodes: TemplateNodesDict,
  nodeIds: number[],
): boolean => {
  // nodeIds の node 及び、その子 node に一つでも質問があれば true を返す
  return nodeIds.some(id => {
    const node = templateNodes[id]
    if (!node) {
      return false
    }
    if (node.type === TemplateNodeTypeEnum.Question) {
      return true
    }
    if (node.nodes.length === 0) {
      return false
    }

    // node に子 node がある場合は、再起的に呼び出す
    return hasQuestionNode(templateNodes, node.nodes)
  })
}

// page に少なくとも一つの質問が紐づいているかのチェック
export const validatePageHasQuestion = (
  templateNodes: TemplateNodesDict,
  templatePages: TemplatePagesDict,
): boolean => {
  // 1 page ずつ確認して、全ての page が少なくとも一つの質問を持っていることを確認している
  return Object.keys(templatePages).every(pageId => {
    // page 直下の node の id 配列を取得
    const nodeIdsDividedByPage = templatePages[Number(pageId)]?.nodes
    if (!nodeIdsDividedByPage) {
      return false
    }
    return hasQuestionNode(templateNodes, nodeIdsDividedByPage)
  })
}

/**
 * 渡された nodeIds の node 及び、その子 node に質問・条件分岐がある場合、質問数を加算して返す
 *
 * @param templateNodes
 * @param nodeIds
 * @param tmpQuestionAndLogicCount
 * @returns
 */
const getQuestionAndLogicNodeCount = (
  templateNodes: TemplateNodesDict,
  nodeIds: number[],
  tmpQuestionAndLogicCount: number,
): number => {
  // nodeIds の node 及び、その子 node に質問があればカウントを加算し、質問数を返す
  let currentQuestionAndLogicCount = tmpQuestionAndLogicCount

  for (const nodeId of nodeIds) {
    const node = templateNodes[nodeId]
    if (node) {
      if (
        node.type === TemplateNodeTypeEnum.Question ||
        node.type === TemplateNodeTypeEnum.Logic
      ) {
        currentQuestionAndLogicCount += 1
      }
      // node に子 node がある場合は、再起的に呼び出す
      currentQuestionAndLogicCount = getQuestionAndLogicNodeCount(
        templateNodes,
        node.nodes,
        currentQuestionAndLogicCount,
      )
    }
  }
  return currentQuestionAndLogicCount
}

/**
 * page 紐づく質問数+条件分岐数が最大数を超過していないかのチェック
 * 最大数を超過した場合、 false を返す
 * 質問がない、もしくは最大数を超過していない場合、 true を返す
 *
 * @param templateNodes
 * @param templatePages
 * @returns
 */
export const validatePageQuestionAndLogicCount = (
  templateNodes: TemplateNodesDict,
  templatePages: TemplatePagesDict,
): boolean => {
  // 1 page ずつ確認して、全ての page で質問数が最大数を超過していないことを確認する
  return Object.keys(templatePages).every(pageId => {
    // page 直下の node の id 配列を取得
    const nodeIdsDividedByPage = templatePages[Number(pageId)]?.nodes
    // 質問がなければ
    if (!nodeIdsDividedByPage) {
      return true
    }
    return (
      MAX_GRID_PAGE_QUESTION_AND_LOGIC >=
      getQuestionAndLogicNodeCount(templateNodes, nodeIdsDividedByPage, 0)
    )
  })
}

// pages から、取り込み項目詳細が登録済みかどうかをチェックする
// 1ページでも登録済みの場合、 false を返す
// それ以外の場合、 true を返す
export const validateHasRegisteredVariables = (
  templateNodes: TemplateNodesDict,
  templatePages: TemplatePagesDict,
): boolean => {
  for (const pageId in templatePages) {
    const nodeIds = templatePages[pageId]?.nodes
    // `nodeIds` の中に「セクション」なノードが含まれているので、それを探す
    if (!nodeIds) {
      continue
    }
    const sectionId = nodeIds.find(nodeId => {
      const maybeSectionNode = templateNodes[nodeId]
      return maybeSectionNode !== undefined && isSection(maybeSectionNode)
    })

    if (
      sectionId !== undefined &&
      templateNodes[sectionId]?.section?.hasRegisteredVariables
    ) {
      return false
    }
  }
  return true
}

/**
 * 引数で受け取った時間計測質問が参照している日時質問が、仕様的に正しいかどうかをチェックする
 *
 * @param tmQuestionName エラーメッセージに載せる時間計測の質問名
 * @param tm 時間計測質問
 * @param questionNodes すべての質問ノード
 * @returns エラーメッセージ(エラーなしなら空文字)
 */
export const validateTimeMeasurement = (
  tmNode: TemplateNodeSchema,
  questionNodes: TemplateNodeSchema[],
  sameParent: (uuid1: string, uuid2: string) => boolean,
) => {
  const tmQuestionName = tmNode.question?.name || ''
  const tm = tmNode.question?.responseTimeMeasurements?.[0]

  if (isNullish(tm)) {
    return `質問名：「${tmQuestionName}」の計測が未設定です`
  }

  const { startQuestionNodeUUID, endQuestionNodeUUID } = tm

  // 参照先の日時質問が存在するかどうかをチェックする
  // OPTIMIZE: questionNodes を連想配列にするなどして、ハッシュ探索でノードを引き当てられるようにしたい
  //           質問数の多いひな形で、保存に時間が掛かるのであれば、やる価値のある最適化になるはず
  const start = questionNodes.find(n => n.uuid === startQuestionNodeUUID)
    ?.question?.responseDatetimes?.[0]

  if (isNullish(start)) {
    return `質問名：「${tmQuestionName}」の「開始時間の質問」を選択してください`
  }
  if (start.subType === ResponseDatetimeSubTypeEnum.DATE) {
    return `質問名：「${tmQuestionName}」の「開始時間の質問」のフォーマットを「日時」または「時刻」に変更してください`
  }

  const end = questionNodes.find(n => n.uuid === endQuestionNodeUUID)?.question
    ?.responseDatetimes?.[0]
  if (isNullish(end)) {
    return `質問名：「${tmQuestionName}」の「終了時間の質問」を選択してください`
  }
  if (end.subType === ResponseDatetimeSubTypeEnum.DATE) {
    return `質問名：「${tmQuestionName}」の「終了時間の質問」のフォーマットを「日時」または「時刻」に変更してください`
  }

  // 参照先の日時質問のフォーマットをチェックし、同じフォーマットかどうかをチェックする
  if (start.subType !== end.subType) {
    return `質問名：「${tmQuestionName}」の「開始時間の質問」と「終了時間の質問」に、同一フォーマット(日時または時刻)の質問を選択してください`
  }

  // 開始/終了/時間計測が同一の親(同一階層のノード)であるかを確認する
  const isSameParent =
    sameParent(tmNode.uuid || '', startQuestionNodeUUID) &&
    sameParent(tmNode.uuid || '', endQuestionNodeUUID)
  if (!isSameParent) {
    return `質問名：「${tmQuestionName}」の計測設定が不正です`
  }

  // ルール設定のvalidationチェック
  const tmr = tm.rule
  const errRuleMsg = validateTimeMeasurementRule(
    !!tmr,
    start.subType,
    tmr?.type,
    tmr?.value ?? 0,
  )
  if (errRuleMsg !== '') {
    return `質問名：「${tmQuestionName}」のルール設定で、${errRuleMsg}`
  }

  return ''
}

/**
 * 表形式用の計算式質問のバリデーション
 * ページ直下の計算式は考慮していない
 *
 * @param formulaNode 計算式質問
 * @param questionNodes すべての質問ノード
 * @param templateNodesDict すべてのノード辞書
 * @returns エラーメッセージ(エラーなしなら空文字)
 */
export const validateFormulaForGrid = (
  formulaNode: TemplateNodeSchema,
  questionNodes: TemplateNodeSchema[],
  templateNodesDict: TemplateNodesDict,
) => {
  const responseFormula = formulaNode.question?.responseFormulas?.[0]
  if (
    !responseFormula ||
    !responseFormula.tokens ||
    responseFormula.tokens.length === 0
  ) {
    return `質問名：「${formulaNode.question?.name}」の計算式が未設定です`
  }
  if (responseFormula.tokens.length < 3) {
    return `質問名：「${formulaNode.question?.name}」の計算式が不正です`
  }

  const questionTokens = responseFormula.tokens.filter(
    token => token.type === ResponseFormulaTokenTypeEnum.QUESTION,
  )
  if (questionTokens.length === 0) {
    return `質問名：「${formulaNode.question?.name}」の計算式には、質問を1つ以上含めてください`
  }

  const templateNodes = Object.values(templateNodesDict)
  const parentNode = templateNodes.find(node =>
    node.nodes.some(nodeId => nodeId === formulaNode.id),
  )
  if (!parentNode) {
    return `質問名：「${formulaNode.question?.name}」の計算式が不正です`
  }

  // 兄弟ノードの数値ノードのUUIDを取得
  const numberNodeUUIDs = questionNodes
    .filter(node => parentNode.nodes.some(nodeId => nodeId === node.id))
    .filter(node => node.question?.responseType === ResponseTypeEnum.NUMBER)
    .map(node => node.uuid)
  const isNotExistsNumberNode = questionTokens.some(
    token =>
      token.questionNodeUUID === undefined ||
      !numberNodeUUIDs.includes(token.questionNodeUUID),
  )
  if (isNotExistsNumberNode) {
    return `質問名：「${formulaNode.question?.name}」の計算式が不正です`
  }

  return ''
}

/**
 * page 配下の validate
 * 現状ではエラーメッセージを一つしか設定できないので一つでもエラーになったらその時点で return する
 *
 * @param templateNodes
 * @param templatePages
 * @returns
 */
export const validateGridTemplatePage = (
  templateNodes: TemplateNodesDict,
  templatePages: TemplatePagesDict,
): [boolean, string, ValidationErrorType?] => {
  if (Object.keys(templatePages).length === 0) {
    return [false, '少なくとも1つページを設定してください']
  }

  if (!validatePageHasQuestion(templateNodes, templatePages)) {
    return [false, '少なくとも1つ質問を設定してください']
  }

  const questionNodes = Object.values(templateNodes).filter(
    node => node.type === TemplateNodeTypeEnum.Question,
  )
  const { sameParent } = createNodePositionChecker(templateNodes)

  let questionErrorMessage = ''
  const isInvalidQuestion = questionNodes.some(node => {
    // 表形式のひな形における以下チケットの事象に対応するため、必須かどうか問わず回答項目未選択の場合入力不可能とした
    // https://kaminashi.atlassian.net/browse/HPB-3172
    // 表形式以外も上記仕様に {@link https://kaminashi.atlassian.net/browse/INE-1222}
    const isUnselectedResponseType = !node.question?.responseType
    if (isUnselectedResponseType) {
      questionErrorMessage = `質問名：「${node.question?.name}」の回答項目が設定されていません`
      return true
    }

    // APIサーバ側の handler でのリクエストボディのバリデーションでは、コードポイントで数えているのでそれに合わせている。
    // そのため「node.question.name.length」を使っていない。
    if (node.question?.name && Array.from(node.question.name).length > 255) {
      questionErrorMessage = '質問名の長さは最大でも255文字でなければなりません'
      return true
    }

    // ***以下で各回答種別で特有のバリデーションを行う***

    // *時間計測質問のバリデーション
    if (node.question?.responseType === ResponseTypeEnum.TIME_MEASUREMENT) {
      // この時間計測質問(tm)が参照している質問が、仕様的に正しい日時質問かどうかをチェックする
      const errMsg = validateTimeMeasurement(node, questionNodes, sameParent)

      if (errMsg !== '') {
        questionErrorMessage = errMsg
        return true
      }
    }

    // 計算式質問のバリデーション
    if (node.question?.responseType === ResponseTypeEnum.FORMULA) {
      const errMsg = validateFormulaForGrid(node, questionNodes, templateNodes)

      if (errMsg !== '') {
        questionErrorMessage = errMsg
        return true
      }
    }

    return false
  })
  if (isInvalidQuestion) {
    return [false, questionErrorMessage]
  }

  const sectionNodes = Object.values(templateNodes).filter(node => {
    return node.type === TemplateNodeTypeEnum.Section
  })

  let sectionErrorMessage = ''
  const isInvalidSection = sectionNodes.some(node => {
    // セクション名は必須: 半角/全角スペースのみの場合もエラーとする
    if (!node.section?.name || node.section.name.trim() === '') {
      sectionErrorMessage = 'セクション名を入力してください'
      return true
    }

    // APIサーバ側の handler でのリクエストボディのバリデーションでは、コードポイントで数えているのでそれに合わせている。
    // そのため「node.question.name.length」を使っていない。
    if (node.section?.name && Array.from(node.section.name).length > 255) {
      sectionErrorMessage =
        'セクション名の長さは最大でも255文字でなければなりません'
      return true
    }

    return false
  })
  if (isInvalidSection) {
    return [false, sectionErrorMessage]
  }

  // ひな形内でセクション名は重複できない
  // セクション名の必須チェックの後に実行されることを想定している
  const set = new Set<string>()
  sectionNodes.forEach(sectionNode => {
    if (sectionNode.section?.name) {
      set.add(sectionNode.section?.name)
    }
  })
  if (set.size !== sectionNodes.length) {
    return [false, 'セクション名は重複できません']
  }

  // 1 ページの合計質問数+条件分岐数が MAX_GRID_PAGE_QUESTION 以上の場合、エラーとする
  // NOTE: 表形式では ページとセクションはほぼ同義となる
  // そのため、1ページに複数セクション登録できるような仕様変更が行われた場合
  // 該当処理の見直しが必要
  if (!validatePageQuestionAndLogicCount(templateNodes, templatePages)) {
    return [
      false,
      `質問数、条件分岐数の合計が${MAX_GRID_PAGE_QUESTION_AND_LOGIC}個を超えたため、保存できません。質問または条件分岐を減らしてください。`,
      'pageQuestionAndLogicCount',
    ]
  }

  // ページ数が MAX_GRID_PAGE 以上の場合、エラーとする
  // NOTE: 表形式では ページとセクションはほぼ同義となる
  // そのため、1ページに複数セクション登録できるような仕様変更が行われた場合
  // 該当処理の見直しが必要
  if (Object.keys(templatePages).length > MAX_GRID_PAGE) {
    return [
      false,
      `セクション数が${MAX_GRID_PAGE}個を超えたため、保存できません。ページを減らしてください。`,
      'pageCount',
    ]
  }

  return [true, '']
}

/**
 * 指定された Question node で分岐を追加しようとした時に、
 * 分岐のネスト数がソフトリミットを超過していないかを検証する。
 *
 * @param layoutType 行形式のひな形の場合、ソフトリミットの制約をかけないために指定
 * @param clickedQuestionNode
 * @param allNodes
 * @returns ソフトリミットを超過している場合、エラーメッセージが返る。超過していない場合は、空文字が返る
 */
export const validateLogicNestCountOnInsertingNewLogic = (
  layoutType: TemplateLayoutTypeEnum,
  clickedQuestionNode: TemplateNodeSchema,
  allNodes: TemplateNodesDict,
): string => {
  if (layoutType !== TemplateLayoutTypeEnum.Grid) {
    return ''
  }
  return validateLogicCount(
    // 条件分岐を追加するアイコンをクリックした質問までの条件分岐に、1つ追加したら条件を超過する場合、エラーとなる、という意味
    countCurrentAndParentLogicNode(clickedQuestionNode, allNodes) + 1,
  )
}

/**
 * Question node が Drag & Drop されようとした時に、
 * Drop 先で、条件分岐のネスト数が、ソフトリミットを超過していないかを検証する
 *
 * @param layoutType 行形式のひな形の場合、ソフトリミットの制約をかけないために指定
 * @param draggedQuestionNode Drag されている質問
 * @param dropParentNode Drop 先の親ノード
 * @param allNodes
 * @returns
 */
export const validateLogicNestCountOnDraggingQuestion = (
  layoutType: TemplateLayoutTypeEnum,
  draggedQuestionNode: TemplateNodeSchema,
  dropParentNode: TemplateNodeSchema | null,
  allNodes: TemplateNodesDict,
): string => {
  if (layoutType !== TemplateLayoutTypeEnum.Grid) {
    return ''
  }
  return validateLogicCount(
    (dropParentNode
      ? countCurrentAndParentLogicNode(dropParentNode, allNodes)
      : 0) +
      workChildrenAndGetMaxLogicNestCount(draggedQuestionNode, allNodes, 0),
  )
}

const validateLogicCount = (logicCount: number) => {
  return logicCount <= MAX_GRID_LOGIC_NEST_COUNT
    ? ''
    : `条件分岐は${
        MAX_GRID_LOGIC_NEST_COUNT + 1
      }階層以上設定することができません。`
}

/**
 * 自分自身も含め、子ノード内で、最大の条件分岐のネスト数を取得する
 *
 * @param currentNode
 * @param allNodes
 * @param logicCountToParentNode
 * @returns
 */
const workChildrenAndGetMaxLogicNestCount = (
  currentNode: TemplateNodeSchema,
  allNodes: TemplateNodesDict,
  logicCountToParentNode: number,
) => {
  const logicCountToThisNode =
    currentNode.type === TemplateNodeTypeEnum.Logic
      ? logicCountToParentNode + 1
      : logicCountToParentNode

  let maxLogicCount = logicCountToThisNode
  for (const nodeId of currentNode.nodes) {
    const child = allNodes[nodeId]
    if (!child) {
      continue
    }
    const tempMax = workChildrenAndGetMaxLogicNestCount(
      child,
      allNodes,
      logicCountToThisNode,
    )

    maxLogicCount = Math.max(maxLogicCount, tempMax)
  }

  return maxLogicCount
}

/**
 * 自分自身も含め、自分自身から、最上位の親ノードまでの間にある、条件分岐数をカウントする
 *
 * @param currentNode
 * @param allNodes
 * @returns
 */
const countCurrentAndParentLogicNode = (
  currentNode: TemplateNodeSchema,
  allNodes: TemplateNodesDict,
): number => {
  const parentIdByNodeId: { [key: number]: number } = {}
  Object.values(allNodes).forEach(node => {
    node.nodes.forEach(nodeId => (parentIdByNodeId[nodeId] = node.id))
  })

  let currentNodeId: number | undefined = currentNode.id

  let parentLogicCount = 0
  while (currentNodeId) {
    const tempNode: TemplateNodeSchema | undefined = allNodes[currentNodeId]
    if (!tempNode) {
      break
    }
    if (tempNode.type === TemplateNodeTypeEnum.Logic) {
      parentLogicCount++
    }

    currentNodeId = parentIdByNodeId[tempNode.id]
  }

  return parentLogicCount
}
