import { DownOutlined, UpOutlined } from '@ant-design/icons'
import { css } from '@emotion/react'
import { PlaceNode, Template } from '@ulysses-inc/harami_api_client'
import { Button, Checkbox, Dropdown, Input, Select, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { useDebounce } from 'src/exShared/hooks/useDebounce'
import { usePlaceLoadingStatus } from 'src/exShared/hooks/usePlaceLoadingStatus'
import {
  initializePlaceFilter,
  splitAccessiblePlaceNode,
} from 'src/exShared/libraries/filterDropDown/place'
import { mergedPlaceNodeIds } from 'src/exShared/util/place/placeNode'
import { splitLoginPlaceUUIDs } from 'src/exShared/util/place/splitLoginPlace'
import { flattenNodes } from 'src/features/places/EditPlaceGroup'
import {
  SchedulesFilter,
  schedulesListFilter,
} from 'src/features/schedules/schedulesListFilter'
import { BorderColor, White } from 'src/features/theme/KdsThemeColor'
import { GetSchedulesFilter } from 'src/state/ducks/schedules/types'
import { RootState } from 'src/state/store'
import styled from 'styled-components'

const { Text } = Typography
const { Option } = Select

const TableCell = styled.div`
  display: table-cell;
  border: 1px solid ${BorderColor};
  background: ${White};
  vertical-align: middle;
`
const FilterButton = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  cursor: pointer;
`

const UpOutlinedIcon = styled(UpOutlined)`
  color: rgba(0, 0, 0, 0.65);
`

const DownOutlinedIcon = styled(DownOutlined)`
  color: rgba(0, 0, 0, 0.65);
`

const SelectFilterTableCell = styled(TableCell)`
  min-width: ${({ theme }) => theme.minWidth};
  background-color: ${({ theme }) => theme.backgroundColor};
  height: 32px;
`

const DropDownTableCell = styled(TableCell)`
  width: 400px;
  background-color: ${White};
  padding: 16px;
`

const styles = {
  clearButtonRow: css`
    display: flex;
    justify-content: flex-end;
  `,
}

interface OwnProps {
  getSchedules: (filter?: GetSchedulesFilter) => void
}

const SchedulesStatusOptions: { label: string; value: number }[] = [
  { label: 'アクティブ', value: 1 },
  { label: '停止中', value: 0 },
]

const SchedulesFilterDropDown: React.FC<OwnProps> = (props: OwnProps) => {
  const request = useSelector(
    (state: RootState) => state.schedulesState.schedules.request,
  )
  const templates = useSelector(
    (state: RootState) => state.schedulesState.schedulesTemplates.templates,
  )
  const places = useSelector(
    (state: RootState) => state.placesState.places.places,
    shallowEqual,
  )
  const placeNodes = useSelector(
    (state: RootState) => flattenNodes(state.placesState.placeGroups.nodes, []),
    shallowEqual,
  )

  const [isOpen, setIsOpen] = useState(false)
  const [scheduleName, setScheduleName] = useState<string>('')
  const [templateIds, setTemplateIds] = useState<number[]>([])
  const [statuses, setStatuses] = useState<number[]>([])
  const [placeNodeIds, setPlaceNodeIds] = useState<string[]>([])
  const [placeGroupNodeIds, setPlaceGroupNodeIds] = useState<string[]>([])
  const filters = schedulesListFilter.get()
  const schedulesRequest = filters?.filter
  const placeLoadingStatus = usePlaceLoadingStatus()

  const { loginPlaceGroupIds, loginPlaceIds } = splitLoginPlaceUUIDs()

  useEffect(() => {
    if (placeLoadingStatus !== 'finish') {
      return
    }
    if (!schedulesRequest) {
      const placeFilter = initializePlaceFilter(placeNodes)
      if (placeFilter === undefined || placeFilter.$in.length === 0) {
        props.getSchedules()
        setPlaceNodeIds(loginPlaceIds)
        setPlaceGroupNodeIds(loginPlaceGroupIds)
        return
      }
      const filter = { placeNodeId: placeFilter }
      props.getSchedules(filter)
      setPlaceNodeIds(loginPlaceIds)
      setPlaceGroupNodeIds(loginPlaceGroupIds)
      return
    }

    let filterPlaceNodeIds: string[] = []
    let filterPlaceGroupNodeIds: string[] = []
    if (
      (schedulesRequest?.placeNodeIds ?? []).length > 0 ||
      (schedulesRequest?.placeGroupNodeIds ?? []).length > 0
    ) {
      filterPlaceNodeIds = schedulesRequest?.placeNodeIds ?? []
      filterPlaceGroupNodeIds = schedulesRequest?.placeGroupNodeIds ?? []
    } else {
      filterPlaceNodeIds = loginPlaceIds
      filterPlaceGroupNodeIds = loginPlaceGroupIds
    }
    setPlaceNodeIds(filterPlaceNodeIds)
    setPlaceGroupNodeIds(filterPlaceGroupNodeIds)
    setScheduleName(schedulesRequest?.scheduleName ?? '')
    setTemplateIds(schedulesRequest?.templateIds ?? [])
    setStatuses(schedulesRequest?.statuses ?? [])
    const filter = getFilter({
      scheduleName: schedulesRequest?.scheduleName,
      templateIds: schedulesRequest?.templateIds,
      placeNodeIds: filterPlaceNodeIds,
      placeGroupNodeIds: filterPlaceGroupNodeIds,
      statuses: schedulesRequest?.statuses,
    })
    props.getSchedules(filter)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [placeLoadingStatus])

  const isFilterOn = () => {
    return (
      !!scheduleName ||
      templateIds.length > 0 ||
      statuses.length > 0 ||
      placeGroupNodeIds.length > 0 ||
      placeNodeIds.length > 0
    )
  }

  const clearFilter = () => {
    const conditions = {
      placeNodeIds: loginPlaceIds,
      placeGroupNodeIds: loginPlaceGroupIds,
    }
    const filter = getFilter(conditions)
    props.getSchedules(filter)
    setScheduleName('')
    setTemplateIds([])
    setStatuses([])
    setPlaceNodeIds([])
    setPlaceGroupNodeIds([])
    saveFilter(conditions)
  }

  const saveFilter = (conditions: SchedulesFilter) => {
    schedulesListFilter.set({
      ...filters,
      pagination: request,
      filter: conditions,
    })
  }

  const getFilter = (conditions: SchedulesFilter): GetSchedulesFilter => {
    const allPlaceNodeIds = mergedPlaceNodeIds(
      placeNodes,
      conditions.placeNodeIds ?? [],
      conditions.placeGroupNodeIds ?? [],
    )
    const scheduleNameCondition =
      conditions.scheduleName !== undefined &&
      conditions.scheduleName.length > 0
        ? { $like: conditions.scheduleName }
        : undefined
    const templateIdCondition =
      conditions.templateIds && conditions.templateIds.length > 0
        ? { $in: conditions.templateIds }
        : undefined
    const placeNodeIdCondition =
      allPlaceNodeIds && allPlaceNodeIds.length > 0
        ? { $in: allPlaceNodeIds }
        : undefined
    const statusesCondition =
      conditions.statuses && conditions.statuses.length > 0
        ? { $in: conditions.statuses }
        : undefined
    saveFilter(conditions)
    return {
      scheduleName: scheduleNameCondition,
      templateId: templateIdCondition,
      placeNodeId: placeNodeIdCondition,
      status: statusesCondition,
    }
  }

  const accessiblePlaceNode = splitAccessiblePlaceNode(placeNodes, places)

  const debounce = useDebounce(300)
  const handleScheduleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setScheduleName(e.target.value)
    debounce(() => {
      const filterPlaceNodeIds =
        placeNodeIds.length > 0 || placeGroupNodeIds.length > 0
          ? placeNodeIds
          : loginPlaceIds
      const filterPlaceGroupNodeIds =
        placeNodeIds.length > 0 || placeGroupNodeIds.length > 0
          ? placeGroupNodeIds
          : loginPlaceGroupIds
      const filter = getFilter({
        scheduleName: e.target.value,
        templateIds,
        statuses,
        placeNodeIds: filterPlaceNodeIds,
        placeGroupNodeIds: filterPlaceGroupNodeIds,
      })
      props.getSchedules(filter)
    })
  }

  const groundArea = (
    <SelectFilterTableCell theme={{ width: '160px' }}>
      <FilterButton>
        <div style={{ flex: 1, marginLeft: 12 }}>
          フィルター：{isFilterOn() ? 'ON' : 'OFF'}
        </div>
        <div style={{ minWidth: 20, marginRight: 12 }}>
          {isOpen ? <UpOutlinedIcon /> : <DownOutlinedIcon />}
        </div>
      </FilterButton>
    </SelectFilterTableCell>
  )

  const hoverArea = (
    <div style={{ display: 'flex' }}>
      <DropDownTableCell>
        <div css={styles.clearButtonRow}>
          <Button onClick={clearFilter}>クリア</Button>
        </div>
        <div style={{ marginBottom: 5 }}>
          <Text style={{ marginLeft: 3 }}>スケジュール名</Text>
        </div>
        <Input
          allowClear
          onChange={handleScheduleNameChange}
          value={scheduleName}
        />
        <div style={{ marginTop: 16, marginBottom: 5 }}>
          <Text style={{ marginLeft: 3 }}>ひな形名</Text>
        </div>
        <Select
          mode="multiple"
          style={{ width: '100%' }}
          value={templateIds}
          optionFilterProp="label"
          onChange={(selectedTemplateIds: number[]) => {
            setTemplateIds(selectedTemplateIds || [])
            const filterPlaceNodeIds =
              placeNodeIds.length > 0 || placeGroupNodeIds.length > 0
                ? placeNodeIds
                : loginPlaceIds
            const filterPlaceGroupNodeIds =
              placeNodeIds.length > 0 || placeGroupNodeIds.length > 0
                ? placeGroupNodeIds
                : loginPlaceGroupIds
            const filter: GetSchedulesFilter = getFilter({
              scheduleName,
              templateIds: selectedTemplateIds || [],
              statuses,
              placeNodeIds: filterPlaceNodeIds,
              placeGroupNodeIds: filterPlaceGroupNodeIds,
            })
            props.getSchedules(filter)
          }}
        >
          {templates.map((template: Template, index: number) => (
            <Option key={index} value={template.id || 0} label={template.name}>
              {template.name}
            </Option>
          ))}
        </Select>
        <>
          <div style={{ marginTop: 16, marginBottom: 5 }}>
            <Text>ステータス</Text>
          </div>
          <Checkbox.Group
            options={SchedulesStatusOptions}
            value={statuses}
            onChange={checkedValue => {
              const selectedStatuses = checkedValue as number[]
              setStatuses(selectedStatuses)
              const filterPlaceNodeIds =
                placeNodeIds.length > 0 || placeGroupNodeIds.length > 0
                  ? placeNodeIds
                  : loginPlaceIds
              const filterPlaceGroupNodeIds =
                placeNodeIds.length > 0 || placeGroupNodeIds.length > 0
                  ? placeGroupNodeIds
                  : loginPlaceGroupIds
              const filter = getFilter({
                scheduleName,
                templateIds,
                statuses: selectedStatuses || [],
                placeNodeIds: filterPlaceNodeIds,
                placeGroupNodeIds: filterPlaceGroupNodeIds,
              })
              props.getSchedules(filter)
            }}
          />
        </>
        <div style={{ marginTop: 16, marginBottom: 5 }}>
          <Text style={{ marginLeft: 3 }}>現場グループ名</Text>
        </div>
        <Select
          mode="multiple"
          style={{ width: '100%' }}
          value={placeGroupNodeIds}
          optionFilterProp="label"
          onChange={(selectedPlaceGroupIds: string[]) => {
            setPlaceGroupNodeIds(selectedPlaceGroupIds || [])
            const filterPlaceNodeIds =
              placeNodeIds.length > 0 ||
              (selectedPlaceGroupIds ?? []).length > 0
                ? placeNodeIds
                : loginPlaceIds
            const filterPlaceGroupNodeIds =
              placeNodeIds.length > 0 ||
              (selectedPlaceGroupIds ?? []).length > 0
                ? selectedPlaceGroupIds
                : loginPlaceGroupIds
            const filter = getFilter({
              scheduleName,
              templateIds,
              statuses,
              placeNodeIds: filterPlaceNodeIds,
              placeGroupNodeIds: filterPlaceGroupNodeIds || [],
            })
            props.getSchedules(filter)
          }}
        >
          {accessiblePlaceNode.accessiblePlaceGroups.map(
            (placeNode: PlaceNode, index: number) => (
              <Option
                key={index}
                value={placeNode.uuid}
                label={placeNode.placeGroup?.name}
              >
                {placeNode.placeGroup?.name}
              </Option>
            ),
          )}
        </Select>
        <div style={{ marginTop: 16, marginBottom: 5 }}>
          <Text style={{ marginLeft: 3 }}>現場名</Text>
        </div>
        <Select
          mode="multiple"
          style={{ width: '100%' }}
          value={placeNodeIds}
          optionFilterProp="label"
          onChange={(selectedPlaceNodeIds: string[]) => {
            setPlaceNodeIds(selectedPlaceNodeIds || [])
            const filterPlaceNodeIds =
              (selectedPlaceNodeIds ?? []).length > 0 ||
              placeGroupNodeIds.length > 0
                ? selectedPlaceNodeIds
                : loginPlaceIds
            const filterPlaceGroupNodeIds =
              (selectedPlaceNodeIds ?? []).length > 0 ||
              placeGroupNodeIds.length > 0
                ? placeGroupNodeIds
                : loginPlaceGroupIds
            const filter = getFilter({
              scheduleName,
              templateIds,
              statuses,
              placeNodeIds: filterPlaceNodeIds || [],
              placeGroupNodeIds: filterPlaceGroupNodeIds,
            })
            props.getSchedules(filter)
          }}
        >
          {accessiblePlaceNode.accessiblePlaces.map(
            (placeNode: PlaceNode, index: number) => (
              <Option
                key={index}
                value={placeNode.uuid}
                label={placeNode.place?.name}
              >
                {placeNode.place?.name}
              </Option>
            ),
          )}
        </Select>
      </DropDownTableCell>
    </div>
  )

  return (
    <Dropdown
      dropdownRender={() => hoverArea}
      trigger={['click']}
      open={isOpen}
      onOpenChange={setIsOpen}
    >
      {groundArea}
    </Dropdown>
  )
}

export default SchedulesFilterDropDown
