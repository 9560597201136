import { css } from '@emotion/react'
import { globalNavigationTheme } from 'src/features/globalNavigation/theme'

// メニュー項目のコンポーネントに種類（内部リンク・外部リンク・サブメニューほか）が多く、
// Elementの種類やラッパーを種類に応じて切り替える必要があった。
// 一つのコンポーネントで対処するのではなく、コンポーネント自体を分割したうえで、
// 以下のようにスタイルのみを共通化するのが最もコードを簡素にできると判断した。
export const sumMenuItemStyle = {
  container: css`
    align-items: center;
    color: ${globalNavigationTheme.textColorSubMenu};
    cursor: pointer;
    display: flex;
    height: 40px;
    justify-content: flex-start;
    padding: 0 16px;
    text-decoration: none;
    transition: background 0.25s;
    width: 200px;
    :hover {
      background: ${globalNavigationTheme.backgroundHoverSubMenu};
    }
  `,
  title: css`
    color: ${globalNavigationTheme.textColorSubMenu};
  `,
}
