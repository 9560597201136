import { Image } from '@ulysses-inc/harami_api_client'
import { FC } from 'react'
import styled from 'styled-components'
import { TableBodyCellWrapper } from './Common.dumb'

type Props = {
  value?: Image
}

const StyledImg = styled.img`
  aspect-ratio: 2;
  height: 34px;
  display: block;
  margin: 0 auto;
`

export const TableBodyCellSignature: FC<Props> = ({ value }) => {
  return (
    <TableBodyCellWrapper
      style={{
        padding: '0px',
      }}
    >
      <StyledImg src={value?.url || ''}></StyledImg>
    </TableBodyCellWrapper>
  )
}
