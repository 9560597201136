import { FeatureNameEnum } from '@ulysses-inc/harami_api_client'
import { useFeature } from 'src/features/featureFlags/useFeatureFlags'
import { appEnv } from 'src/util/appEnv'

/**
 * 表形式&日時質問オプションの「現在時刻のみ記録可能」機能が有効かどうかを返す
 * 最終的にこのコードは消される
 */
export const useFixedCurrentTimeEnabled = (): boolean => {
  const enabled =
    useFeature(FeatureNameEnum.FIXED_CURRENT_TIME).canUse === 'yes'

  // ローカル環境では無条件で表示する
  if (appEnv.isLocal) return true

  return enabled
}
