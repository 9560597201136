import { PlaceNode, PlaceNodeTypeEnum } from '@ulysses-inc/harami_api_client'
import { localStorageKeys } from 'src/constants/localStorageKeys'

const getLoginPlaceNodes = (): PlaceNode[] => {
  // ログインユーザが直接所属している現場/グループ
  const loginPlaceNodesStr = localStorage.getItem(
    localStorageKeys.loginUserPlaceNodes,
  )
  return loginPlaceNodesStr ? JSON.parse(loginPlaceNodesStr) : []
}

const splitLoginPlaceUUIDs = () => {
  const loginPlaceNodes = getLoginPlaceNodes()
  const loginPlaceGroupIds = loginPlaceNodes
    .filter(node => node.type === PlaceNodeTypeEnum.PlaceGroup)
    .map(node => node.uuid)
  const loginPlaceIds = loginPlaceNodes
    .filter(node => node.type === PlaceNodeTypeEnum.Place)
    .map(node => node.uuid)
  return { loginPlaceGroupIds, loginPlaceIds }
}

export { splitLoginPlaceUUIDs }
