// prettier-ignore
export const ActionTypes = {
  /**
   * templates
   */
  REQUEST_CHANGE_GRID_VARIABLE_TEMPLATES_PAGE: 'harami/gridVariables/REQUEST_CHANGE_GRID_VARIABLE_TEMPLATES_PAGE',
  SUCCESS_GET_GRID_VARIABLE_TEMPLATES: 'harami/gridVariables/SUCCESS_GET_GRID_VARIABLE_TEMPLATES',
  ERROR_GET_GRID_VARIABLE_TEMPLATES: 'harami/gridVariables/ERROR_GET_GRID_VARIABLE_TEMPLATES',
  REQUEST_CHANGE_GRID_VARIABLE_TEMPLATES_SIZE: 'harami/gridVariables/REQUEST_CHANGE_GRID_VARIABLE_TEMPLATES_SIZE',
  UPDATE_PAGINATION: 'harami/gridVariables/UPDATE_PAGINATION',
  RESET_PAGINATION: 'harami/gridVariables/RESET_PAGINATION',
  UPDATE_FILTER: 'harami/gridVariables/UPDATE_FILTER'
} as const
