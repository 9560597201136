import {
  ImporoveExcelConvertStatusEnum,
  ImproveExcelConverts,
} from '@ulysses-inc/harami_api_client'
import { List, Row } from 'antd'
import { ListProps } from 'antd/lib/list'
import React, { JSX, useEffect, useState } from 'react'
import { RowActionButton } from 'src/components/rowActionButton/RowActionButton'
import { getStatusNameForDownload } from 'src/constants/improveStatus'
import date from 'src/exShared/util/date'
import { TableBorderColor, White } from 'src/features/theme/KdsThemeColor'
import styled from 'styled-components'
import { getStatusBadge } from './ImproveExcelConversionLogStatus'

const ButtonRow = styled.div`
  position: absolute;
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  height: 100%;
  right: 0;
`

const TableBody = styled<
  (props: ListProps<ImproveExcelConverts>) => JSX.Element
>(List)`
  width: 100%;
`

const TableRowWrap = styled(List.Item)`
  position: relative;
  height: auto;
  background-color: ${White};
`

const TableRow = styled.div`
  width: ${props => props.theme.width};
  padding-left: 16px;
`

const LogsHeaderContainer = styled(Row)`
  margin: 24px;
  overflow-x: auto;
  justify-content: start;
`

const LogsHeaderWrap = styled.div`
  width: 100%;
  min-width: 800px;
`

const LogsHeader = styled.div`
  width: 100%;
  height: 46px;
  display: flex;
  align-items: center;
  flex-direction: row;
  background: ${White};
  border-bottom: 1px solid ${TableBorderColor};
`

const HeaderRow = styled.div`
  font-weight: bold;
  padding-left: 15px;
  width: ${props => props.theme.width};
`

interface OwnProps {
  logs: ImproveExcelConverts[]
  getImproveExcelConversionLogFiles: (fileUUIDs: string[]) => void
  chooseLogUUIDs: { [key: string]: string }
  setChooseLogUUIDs: React.Dispatch<
    React.SetStateAction<{ [key: string]: string }>
  >
}

const ImproveExcelConversionLogList: React.FC<OwnProps> = (props: OwnProps) => {
  const [activeLogId, setActiveLogId] = useState<number>()

  useEffect(() => {
    Object.keys(props.chooseLogUUIDs).forEach(logUUID => {
      const isContain =
        props.logs.findIndex(log => log.logFile?.file?.uuid === logUUID) >= 0
      if (!isContain) {
        handleCheckLog(logUUID, false)
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.logs])

  const handleCheckLog = (fileUUID: string | undefined, checked: boolean) => {
    if (fileUUID === undefined) {
      return
    }
    if (checked) {
      props.chooseLogUUIDs[fileUUID] = fileUUID
      props.setChooseLogUUIDs({ ...props.chooseLogUUIDs })
    } else {
      delete props.chooseLogUUIDs[fileUUID]
      props.setChooseLogUUIDs({ ...props.chooseLogUUIDs })
    }
  }

  return (
    <LogsHeaderContainer justify="center">
      <LogsHeaderWrap>
        <LogsHeader>
          <HeaderRow theme={{ width: '25%' }}>日付</HeaderRow>
          <HeaderRow theme={{ width: '25%' }}>出力者</HeaderRow>
          <HeaderRow theme={{ width: '25%' }}>カイゼンステータス</HeaderRow>
          <HeaderRow theme={{ width: '25%' }}>実行結果</HeaderRow>
        </LogsHeader>
        <TableBody
          itemLayout="horizontal"
          dataSource={props.logs}
          renderItem={(log: ImproveExcelConverts) => (
            <TableRowWrap
              onMouseEnter={() => setActiveLogId(log.id)}
              onMouseLeave={() => setActiveLogId(undefined)}
            >
              <TableRow theme={{ width: '25%' }}>
                {date.formatYYYYMMDD(log.createdAt)}
              </TableRow>
              <TableRow theme={{ width: '25%' }}>{log.userName || ''}</TableRow>
              <TableRow theme={{ width: '25%' }}>
                {log.improveStatus?.status
                  ? getStatusNameForDownload(log.improveStatus?.status)
                  : '不明'}
              </TableRow>
              <TableRow theme={{ width: '25%' }}>
                {getStatusBadge(log.status)}
              </TableRow>
              {activeLogId === log.id &&
                log.id !== undefined &&
                log.status === ImporoveExcelConvertStatusEnum.SUCCESS && (
                  <ButtonRow>
                    <RowActionButton
                      onClick={() =>
                        log.logFile?.file?.uuid !== undefined &&
                        props.getImproveExcelConversionLogFiles([
                          log.logFile.file.uuid,
                        ])
                      }
                      type="download"
                    />
                  </ButtonRow>
                )}
            </TableRowWrap>
          )}
        />
      </LogsHeaderWrap>
    </LogsHeaderContainer>
  )
}

export default ImproveExcelConversionLogList
