import { Modal } from 'antd'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as actions from 'src/features/auth/error/state'
import { useAuthProcess } from 'src/features/auth/useAuthProcess'
import { RootState } from 'src/state/store'

export const AuthErrorDialog = () => {
  const dispatch = useDispatch()
  const { cleanupAndPushOutUser } = useAuthProcess()

  const { summary: title, message: content } = useSelector(
    (state: RootState) => state.authErrorState,
  )

  useEffect(() => {
    if (!content) {
      return
    }

    Modal.info({
      title,
      content,
      onOk() {
        // NOTE: 先に送られたAPIリクエストの結果が401で返ってきた場合に isAuthExpired が true になる
        // なので、もう一度サーバーに問い合わせてログアウト処理を行う必要はない
        cleanupAndPushOutUser({ serverRequest: false })
        dispatch(actions.reset())
      },
    })
  }, [cleanupAndPushOutUser, dispatch, title, content])

  // hooksで実装してもよいのだが、配置場所が特殊なため、
  // あえてコンポーネントにして取り回しを良くしたもの
  return null
}
