import {
  Template,
  TemplateIcon,
  TemplateLayoutTypeEnum,
  TemplateManual,
} from '@ulysses-inc/harami_api_client'
import { ActionTypes } from 'src/state/ducks/templates/editTemplate/types'
import { TemplateArgs } from 'src/state/ducks/templates/types'
import { HTTPError } from 'src/state/middleware/saga/handleHttpError'
import { isNonNegativeInteger } from 'src/util/isNonNegativeInteger'

/**
 * ひな型テンプレートからのひな形作成に必要なデータを取得する
 *
 * ひな形更新時の処理とほぼ共通の処理であり、Stateも共有している。
 * 一方で、ひな形更新時と異なり３つのエンドポイント(ひな形、ひな形ページ、ひな形ヒント)を
 * 一つのsagaで叩いているなど、処理の流れが微妙に異なっている。
 *
 * TODO: 整理が必要。ひな形更新と共通のコードにできないか？
 */
export const getPreparedTemplate = (templateId: number) => {
  return {
    type: ActionTypes.REQUEST_GET_PREPARED_TEMPLATE,
    templateId: templateId,
    isLoading: true,
  }
}

export const getTemplate = (templateId: number) => {
  return {
    type: ActionTypes.REQUEST_GET_TEMPLATE,
    isLoading: true,
    templateId,
  }
}

export const getSuccessTemplate = (template: Template) => {
  return {
    type: ActionTypes.SUCCESS_GET_TEMPLATE,
    isLoading: false,
    name: template.name,
    isShowScore: template.isShowScore,
    isEditable: template.isEditable,
    isExcelConversion: template.isExcelConversion,
    isHideQuestionOptions: template.isHideQuestionOptions,
    isAudit: template.isAudit,
    isKeyboard: template.isKeyboard,
    layoutType: template.layoutType,
    hasVariables: template.hasVariables ?? false,
    manuals: template.manuals,
    icons: template.icons,
    approvalFlowId: isNonNegativeInteger(template.approvalFlows?.[0]?.id)
      ? template.approvalFlows[0].id
      : 0,
    placeNodes: template.placeNodes,
    excelConversionFlows: template.excelConversionFlows,
  }
}

export const getErrorTemplate = (error: HTTPError) => {
  return {
    type: ActionTypes.ERROR_GET_TEMPLATE,
    isLoading: false,
    error: error,
  }
}

export const getEmptyTemplate = () => {
  return {
    type: ActionTypes.GET_EMPTY_TEMPLATE,
  }
}

export const getEmptyGridTemplate = (hasVariables: boolean) => {
  return {
    type: ActionTypes.GET_EMPTY_GRID_TEMPLATE,
    hasVariables,
  }
}

export const addTemplate = (templateArgs: TemplateArgs) => {
  return {
    type: ActionTypes.ADD_TEMPLATE,
    isLoading: true,
    ...templateArgs,
  }
}

export const addSuccessTemplate = () => {
  return {
    type: ActionTypes.SUCCESS_ADD_TEMPLATE,
    isLoading: false,
  }
}

export const addErrorTemplate = (error: HTTPError) => {
  return {
    type: ActionTypes.ERROR_ADD_TEMPLATE,
    isLoading: false,
    error,
  }
}

export const updateTemplate = (
  templateArgs: TemplateArgs,
  templateId: number,
) => {
  return {
    type: ActionTypes.REQUEST_UPDATE_TEMPLATE,
    isLoading: true,
    ...templateArgs,
    templateId,
  }
}

export const updateSuccessTemplate = () => {
  return {
    type: ActionTypes.SUCCESS_UPDATE_TEMPLATE,
    isLoading: false,
  }
}

export const updateErrorTemplate = (error: HTTPError) => {
  return {
    type: ActionTypes.ERROR_UPDATE_TEMPLATE,
    isLoading: false,
    error,
  }
}

export const resetTemplate = () => {
  return {
    type: ActionTypes.RESET_TEMPLATE,
  }
}

/**
 * ひな形名入力欄の値を更新する
 */
export const updateTemplateName = (name: string) => {
  return { type: ActionTypes.UPDATE_TEMPLATE_NAME, name }
}

export const setTemplateNameErrorMessage = (message: string) => {
  return { type: ActionTypes.SET_TEMPLATE_NAME_ERROR_MESSAGE, message }
}

/**
 * 配点機能の有無を切り替える
 */
export const toggleShowScore = (isShowScore: boolean) => {
  return {
    type: ActionTypes.TOGGLE_SHOW_SCORE,
    isShowScore,
  }
}

/**
 * レポート完了後の編集許可を切り替える
 */
export const toggleEditable = (isEditable: boolean) => {
  return {
    type: ActionTypes.TOGGLE_EDITABLE,
    isEditable,
  }
}

/**
 * EXCEL出力機能の有無を切り替える
 */
export const toggleExcelConversion = (isExcelConversion: boolean) => {
  return {
    type: ActionTypes.TOGGLE_EXCEL_CONVERSION,
    isExcelConversion,
  }
}

/**
 * カイゼン機能の有無を切り替える
 */
export const toggleAudit = (isAudit: boolean) => {
  return {
    type: ActionTypes.TOGGLE_AUDIT,
    isAudit,
  }
}

/**
 * 数値回答で標準キーボードを使うかどうかを切り替える
 */
export const toggleTenkey = (isKeyboard: boolean) => {
  return {
    type: ActionTypes.TOGGLE_TENKEY,
    isKeyboard,
  }
}

/**
 * ひな形のレイアウトを切り替えられる（スクロール1列 or スクロール2列 or 紙芝居）
 */
export const updateLayoutType = (layoutType: TemplateLayoutTypeEnum) => {
  return {
    type: ActionTypes.UPDATE_LAYOUT_TYPE,
    layoutType,
  }
}

/**
 * マニュアルを追加する
 */
export const addTemplateManual = () => {
  return {
    type: ActionTypes.ADD_TEMPLATE_MANUAL,
  }
}

/**
 * マニュアルを削除する
 */
export const deleteTemplateManual = (templateManual: TemplateManual) => {
  return {
    type: ActionTypes.DELETE_TEMPLATE_MANUAL,
    templateManual,
  }
}

/**
 * マニュアルを更新する（マニュアル名やマニュアル内容を更新する）
 */
export const updateTemplateManual = (templateManual: TemplateManual) => {
  return {
    type: ActionTypes.UPDATE_TEMPLATE_MANUAL,
    templateManual,
  }
}

/**
 * ひな形にアイコンを設定または削除する
 */
export const updateTemplateIcons = (templateIcons: Array<TemplateIcon>) => {
  return {
    type: ActionTypes.UPDATE_TEMPLATE_ICONS,
    icons: templateIcons,
  }
}

/**
 * ひな形に承認フローを設定する
 */
export const updateApprovalFlowId = (approvalFlowId: number) => {
  return {
    type: ActionTypes.UPDATE_APPROVAL_FLOW_ID,
    approvalFlowId,
  }
}

/**
 * ひな形に現場を設定する
 */
export const updatePlaceNodes = (placeNodeIds: string[] | undefined) => {
  return {
    type: ActionTypes.UPDATE_PLACE_NODES,
    placeNodeIds,
  }
}

// 意図と名前があっていない。本来はtemplatePagesのsliceに入るべきだったもの？
export const clearAddTemplatePagesError = () => {
  return {
    type: ActionTypes.CLEAR_ADD_TEMPLATE_PAGES_ERROR,
  }
}

export type EditTemplateAction =
  | ReturnType<typeof getPreparedTemplate>
  | ReturnType<typeof getTemplate>
  | ReturnType<typeof getSuccessTemplate>
  | ReturnType<typeof getErrorTemplate>
  | ReturnType<typeof getEmptyTemplate>
  | ReturnType<typeof getEmptyGridTemplate>
  | ReturnType<typeof addTemplate>
  | ReturnType<typeof addSuccessTemplate>
  | ReturnType<typeof addErrorTemplate>
  | ReturnType<typeof clearAddTemplatePagesError>
  | ReturnType<typeof updateTemplateName>
  | ReturnType<typeof setTemplateNameErrorMessage>
  | ReturnType<typeof updateTemplate>
  | ReturnType<typeof updateSuccessTemplate>
  | ReturnType<typeof updateErrorTemplate>
  | ReturnType<typeof toggleShowScore>
  | ReturnType<typeof toggleEditable>
  | ReturnType<typeof toggleExcelConversion>
  | ReturnType<typeof toggleAudit>
  | ReturnType<typeof toggleTenkey>
  | ReturnType<typeof updateLayoutType>
  | ReturnType<typeof resetTemplate>
  | ReturnType<typeof addTemplateManual>
  | ReturnType<typeof deleteTemplateManual>
  | ReturnType<typeof updateTemplateIcons>
  | ReturnType<typeof updateTemplateManual>
  | ReturnType<typeof updateApprovalFlowId>
  | ReturnType<typeof updatePlaceNodes>
