import {
  IndicatedIssue,
  IndicatedIssueGroup,
  IndicatedIssueStatusEnum,
} from '@ulysses-inc/harami_api_client'
import { Button, Layout } from 'antd'
import {
  FormikProps,
  FormikTouched,
  getIn,
  setNestedObjectValues,
} from 'formik'
import React, { useCallback, useEffect, useState } from 'react'
import InformationSvg from 'src/assets/icons/information_2.svg?react'
import LoadingOverlay from 'src/components/loading/LoadingOverlay'
import date from 'src/exShared/util/date'
import { UUID } from 'src/exShared/util/uuid'
import {
  EditImproveForm,
  EditImproveProps,
} from 'src/features/improves/improveDetail/EditImproveProps'
import SubInfos from 'src/features/improves/improveDetail/ImproveDetailSubInfo'
import ImproveList from 'src/features/improves/improveDetail/ImproveList'
import PageHeader from 'src/features/improves/improveDetail/header/ImproveDetailPageHeader'
import styled from 'styled-components'

const Content = styled.div`
  margin: 16px 15px 0;
  overflow-x: auto;
`

const AddIndicationButton = styled(Button)`
  margin: 16px 15px 0;
  width: 104px;
`

const InformationText = styled.span`
  color: #595959;
  font-weight: bold;
  font-size: 14px;
  margin: 16px 15px 0;
  display: flex !important;
  align-items: center;
  font-family: 'Noto Sans JP', sans-serif;
`

const styles = {
  informationText: {
    width: '22px',
    height: '28px',
    paddingRight: '6px',
  },
}

const ImprovesScene: React.FC<
  EditImproveProps & FormikProps<EditImproveForm>
> = (props: EditImproveProps & FormikProps<EditImproveForm>) => {
  const {
    getImprove,
    getUserGroups,
    getIndicatedIssueLabels,
    resetImprove,
    match,
  } = props
  const mounted = useCallback(() => {
    getImprove(match.params.improveUUID)
    getUserGroups()
    getIndicatedIssueLabels()
  }, [getImprove, getUserGroups, getIndicatedIssueLabels, match])
  useEffect(() => {
    mounted()
    return () => {
      resetImprove()
    }
  }, [mounted, resetImprove])
  const [isEditing, setIsEditing] = useState(false)
  const handleEditStart = () => {
    props.values.indicatedIssueGroups?.forEach(
      (issue: IndicatedIssueGroup, index) => {
        if (!issue.indicatedIssues?.length) {
          return
        }
        const indicatedIssue =
          issue.indicatedIssues[issue.indicatedIssues.length - 1]
        if (typeof indicatedIssue.status?.status === 'undefined') {
          return
        }
        // Set the initial value of revise comments.
        if (
          indicatedIssue.status?.status === IndicatedIssueStatusEnum.Incomplete
        ) {
          if (!indicatedIssue.revise) {
            props.setFieldValue(
              `indicatedIssueGroups[${index}]['indicatedIssues'][${
                issue.indicatedIssues.length - 1
              }]['rejectComment']['comment']`,
              'こちらの改善もお願いします。',
            )
          } else if (!indicatedIssue.rejectComment?.comment?.length) {
            props.setFieldValue(
              `indicatedIssueGroups[${index}]['indicatedIssues'][${
                issue.indicatedIssues.length - 1
              }]['rejectComment']['comment']`,
              '改善が見られませんでした',
            )
          }
        }
      },
    )
    setIsEditing(true)
  }
  const items = props.values?.indicatedIssueGroups || []
  const handleAddIndicatedIssue = () => {
    const index = items.length
    const indicatedIssueGroupUUID = UUID()
    const indicatedIssueUUID = UUID()
    const addedIndicatedIssueGroup: IndicatedIssueGroup = {
      uuid: indicatedIssueGroupUUID,
      indicatedIssues: [
        {
          uuid: indicatedIssueUUID,
          comment: '',
          images: [],
          isRequired: 1,
          status: {
            status: IndicatedIssueStatusEnum.Incomplete,
          },
          indicatedIssueDate: date.today(),
          indicatedIssueGroupUUID,
        },
      ],
    }
    if (
      !props.isFirstAudit &&
      isEditing &&
      addedIndicatedIssueGroup?.indicatedIssues?.length
    ) {
      addedIndicatedIssueGroup.indicatedIssues[0].rejectComment = {
        indicatedIssueUUID,
        comment: 'こちらの改善もお願いします。',
      }
    }
    props.setFieldValue(
      `indicatedIssueGroups[${index}]`,
      addedIndicatedIssueGroup,
      false,
    )
  }
  const resetField = (name: string) => {
    props.setFieldValue(name, getIn(props.improve, name))
  }
  const isCompleted = props.improve?.status?.status === 3 ? true : false
  const validateValues = async () => {
    const errors = await props.validateForm(props.values)
    const touched = setNestedObjectValues<FormikTouched<any>>(
      props.values,
      true,
    )
    if (
      touched.indicatedIssueGroups &&
      Array.isArray(touched.indicatedIssueGroups)
    ) {
      touched.indicatedIssueGroups = touched.indicatedIssueGroups?.map(
        (group: IndicatedIssueGroup) =>
          ({
            ...group,
            indicatedIssues: group.indicatedIssues?.map(
              (issue: IndicatedIssue) => {
                if (typeof issue.revise !== 'object') {
                  return {
                    ...issue,
                    revise: {
                      images: true,
                      comment: true,
                      dueDate: true,
                    },
                  }
                }
                return issue || true
              },
            ),
          }) as FormikTouched<any>,
      )
    }
    props.setTouched(touched, false)
    return !Object.keys(errors).length
  }
  const infoText = {
    default: '太枠内をご記入ください',
    reject: '完了する指摘のチェックを外し、追記内容は太枠内にご記入ください',
  }
  const isRejectStatus: boolean =
    props.improve?.status?.status === 1 && !props.isFirstAudit ? true : false

  return (
    <LoadingOverlay
      tip="カイゼンを更新中です..."
      spinning={props.isLoading}
      render={
        <Layout>
          <PageHeader
            {...props}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            handleEditStart={handleEditStart}
            validateValues={validateValues}
          />
          <SubInfos {...props} />
          {!isCompleted && !isRejectStatus && (
            <InformationText>
              <InformationSvg style={styles.informationText} />
              {infoText.default}
            </InformationText>
          )}
          {isRejectStatus && isEditing && (
            <InformationText>
              <InformationSvg style={styles.informationText} />
              {infoText.reject}
            </InformationText>
          )}
          <Content>
            <ImproveList
              items={items}
              indicatedIssueLabels={props.indicatedIssueLabels}
              reportQuestions={props.improve.reportQuestions || []}
              reportUUID={props.values?.reportUUID || ''}
              improveUUID={props.improve.uuid || ''}
              placeUserGroupId={props.improve.placeUserGroup?.id || 0}
              setFieldTouched={props.setFieldTouched}
              setFieldValue={props.setFieldValue}
              handleChange={props.handleChange}
              resetField={resetField}
              isPlaceUser={props.isPlaceUser}
              isAuditor={props.isAuditor}
              isAuditorWorking={props.isAuditorWorking}
              isCompleted={isCompleted}
              isFirstAudit={props.isFirstAudit}
              isFirstRevise={props.isFirstRevise}
              isEditing={isEditing}
              isRejectStatus={isRejectStatus}
              errors={props.errors}
              touched={props.touched}
            />
          </Content>
          {props.isAuditorWorking && (props.isFirstAudit || isEditing) && (
            <AddIndicationButton
              type="primary"
              onClick={() => handleAddIndicatedIssue()}
            >
              指摘を追加
            </AddIndicationButton>
          )}
        </Layout>
      }
    />
  )
}

export default ImprovesScene
