import { AliasToken } from 'antd/es/theme/interface'
import { primitiveColors } from 'src/features/theme/primitiveColors'

// antdのConfigProviderのtoken propsの型
type AntdToken = Partial<AliasToken>

/**
 * Tomatoで使用する色情報をセマンティックに定義したもの
 *
 * 以下の二つの役割を持つ:
 * - antdのConfigProviderのtoken propに与えることで、antdのテーマを設定する
 * - harami独自のコンポーネントに色を指定する際に、必要に応じて引用して使用する
 *
 * antdのトークンを全て網羅して定義いる訳ではないので、不足しているものは適宜追加してよい。
 * なお、antdのトークンとして定義されていない役割や色は、デザインの一貫性を考えると原則として使うべきではないものの、
 * もしどうしても例外的に使用しなければならない場合は、
 * 該当のコンポーネント等にセマンティックなカラーを局所的に定義して対応すること。
 *
 * TomatoのFigma:
 * https://www.figma.com/design/3swIU7IP5FEamZ3VErR7hU/%F0%9F%8D%85-Web?node-id=147%3A3005&t=VmSTPslINcanXh8s-1
 *
 * 色情報をFigmaからエクスポートするために使用したプラグイン:
 * https://www.figma.com/community/plugin/888356646278934516/design-tokens
 *
 * エクスポートした色情報データ:
 * - `exportedTokenSample.json5`を参照のこと。
 * - このデータを元に、末尾のスクリプトを使用して、このカラーパレットを生成した。
 * - 参考: https://kaminashi-inc.slack.com/archives/C047X5HBRK5/p1710831213811019?thread_ts=1710816091.562839&cid=C047X5HBRK5
 *
 * Antdの色管理に関する参考資料:
 * - https://ant.design/docs/react/customize-theme
 * - https://ant.design/docs/spec/colors
 * - https://ant.design/theme-editor
 */
export const semanticColors = {
  // Seed Tokens
  colorBgBase: primitiveColors.white,
  colorError: primitiveColors.red._6,
  colorInfo: primitiveColors.blue._6,
  colorLink: primitiveColors.blue._6,
  colorPrimary: primitiveColors.blue._6,
  colorSuccess: primitiveColors.green._6,
  colorTextBase: primitiveColors.gray._9,
  colorWarning: primitiveColors.yellow._6,

  // Map Tokens
  colorBgContainer: primitiveColors.white,
  colorBgElevated: primitiveColors.white,
  colorBgLayout: primitiveColors.gray._1,
  colorBgMask: primitiveColors.neutralColor._45,
  colorBgSpotlight: primitiveColors.neutralColor._88,
  colorBorder: primitiveColors.gray._4,
  colorBorderSecondary: primitiveColors.gray._3,
  colorErrorActive: primitiveColors.red._7,
  colorErrorBg: primitiveColors.red._0,
  colorErrorBgHover: primitiveColors.red._1,
  colorErrorBorder: primitiveColors.red._2,
  colorErrorBorderHover: primitiveColors.red._3,
  colorErrorHover: primitiveColors.red._4,
  colorErrorText: primitiveColors.red._9,
  colorErrorTextActive: primitiveColors.red._10,
  colorErrorTextHover: primitiveColors.red._8,
  colorFill: primitiveColors.neutralColor._15,
  colorFillQuaternary: primitiveColors.neutralColor._2,
  colorFillSecondary: primitiveColors.neutralColor._6,
  colorFillTertiary: primitiveColors.neutralColor._4,
  colorLinkActive: primitiveColors.blue._8,
  colorLinkHover: primitiveColors.blue._7,
  colorPrimaryActive: primitiveColors.blue._8,
  colorPrimaryBg: primitiveColors.blue._0,
  colorPrimaryBgHover: primitiveColors.blue._1,
  colorPrimaryBorder: primitiveColors.blue._6,
  colorPrimaryBorderHover: primitiveColors.blue._6,
  colorPrimaryHover: primitiveColors.blue._5,
  colorPrimaryText: primitiveColors.blue._9,
  colorPrimaryTextActive: primitiveColors.blue._8,
  colorPrimaryTextHover: primitiveColors.blue._10,
  colorSuccessActive: primitiveColors.green._7,
  colorSuccessBg: primitiveColors.green._0,
  colorSuccessBgHover: primitiveColors.green._1,
  colorSuccessBorder: primitiveColors.green._2,
  colorSuccessBorderHover: primitiveColors.green._3,
  colorSuccessHover: primitiveColors.green._4,
  colorSuccessText: primitiveColors.green._9,
  colorSuccessTextActive: primitiveColors.green._10,
  colorSuccessTextHover: primitiveColors.green._8,
  colorText: primitiveColors.neutralColor._88,
  colorTextQuaternary: primitiveColors.neutralColor._25,
  colorTextSecondary: primitiveColors.neutralColor._65,
  colorTextTertiary: primitiveColors.neutralColor._45,
  colorWarningActive: primitiveColors.yellow._7,
  colorWarningBg: primitiveColors.yellow._0,
  colorWarningBgHover: primitiveColors.yellow._1,
  colorWarningBorder: primitiveColors.yellow._2,
  colorWarningBorderHover: primitiveColors.yellow._3,
  colorWarningHover: primitiveColors.yellow._4,
  colorWarningText: primitiveColors.yellow._9,
  colorWarningTextActive: primitiveColors.yellow._10,
  colorWarningTextHover: primitiveColors.yellow._8,

  // Alias Tokens
  colorBgContainerDisabled: primitiveColors.neutralColor._4,
  colorBgTextActive: primitiveColors.neutralColor._15,
  colorBgTextHover: primitiveColors.neutralColor._6,
  colorFillAlter: primitiveColors.neutralColor._2,
  colorFillContent: primitiveColors.neutralColor._6,
  colorIcon: primitiveColors.neutralColor._45,
  colorIconHover: primitiveColors.neutralColor._88,
  colorSplit: primitiveColors.gray._3,
  colorTextDescription: primitiveColors.neutralColor._45,
  colorTextDisabled: primitiveColors.neutralColor._25,
  colorTextHeading: primitiveColors.neutralColor._88,
  colorTextPlaceholder: primitiveColors.neutralColor._25,
  controlItemBgActive: primitiveColors.gray._2,
  controlItemBgActiveHover: primitiveColors.blue._1,
  controlItemBgHover: primitiveColors.neutralColor._4,
} as const satisfies AntdToken

/**
 * 以下は、Figmaからエクスポートした色情報を加工するためのスクリプトを念のため残しておいたもの。
 * `deno run --allow-read --allow-write ./thisCode.ts` で実行できる。
 *
 * ```ts
 * import fs from 'node:fs'
 * import jsonFile from './exportedTokenSample.json5' with { type: 'json' }
 *
 * const categories = [
 *   'fill',
 *   'background',
 *   'text',
 *   'сontrolitem',
 *   'border',
 *   'primary',
 *   'success',
 *   'warning',
 *   'error',
 * ]
 *
 * const result = {}
 *
 * for (const category of categories) {
 *   result[category] = {}
 *
 *   for (const [key, value] of Object.entries(jsonFile.colors.light[category])) {
 *     result[category][key] = value.value
 *   }
 * }
 *
 * fs.writeFileSync('result.json', JSON.stringify(result))
 * ```
 */
