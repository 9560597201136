import { addDays, differenceInDays, isAfter } from 'date-fns'
import { Fragment, JSX, ReactNode, createElement } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import date from 'src/exShared/util/date'
import {
  TPseudoAtom,
  TUsePseudoRecoilState,
  usePseudoRecoilState,
} from 'src/state/ducks/pseudoRecoil'
import {
  getInvalidCountScheduleListInRangeItems,
  getScheduleListInRangeItems,
  resetPlaceScheduleListInRangeItems,
} from 'src/state/ducks/scheduleListInRangeItems/actions'
import CircleSvg from '../../assets/icons/circle.svg?react'
import CrossSvg from '../../assets/icons/cross.svg?react'
import {
  Black,
  Danger,
  Gray,
  InvalidCellBackgroundColor,
  Success,
  SuccessCellBackgroundColor,
} from '../theme/KdsThemeColor'
import {
  DayOfTheMonth,
  DayOfTheWeek,
  InvalidCell,
  PlaceLabel,
  StatusCell,
  StatusCellDisable,
  TableHeader,
} from './shared.components'
import {
  ScheduleStatus,
  TColumn,
  TColumnValue,
  TColumnValueByPlaceAndDay,
  TDashboardDataType,
  TItem,
  TPlace,
  columnsForScheduleState,
  currentEndDateForScheduleState,
  currentInvalidCountEndDateState,
  currentInvalidCountStartDateState,
  currentStartDateForScheduleState,
  filteredInvalidCountPlaceGroupNodeIdsState,
  filteredPlaceGroupNodeIdsForScheduleState,
  filteredPlaceNodeIdsForScheduleState,
  hasPresentDayForScheduleState,
  invalidCountColumnsState,
  invalidCountFilteredPlaceNodeIdsState,
  invalidCountHasPresentDayState,
  isCountFilteredState,
  isFilteredForScheduleState,
} from './shared.types'

const useDashboardStateFactory = <V>(
  atomA: TPseudoAtom<V>,
  atomB: TPseudoAtom<V>,
) => {
  const [stateA, setStateA] = usePseudoRecoilState(atomA)
  const [stateB, setStateB] = usePseudoRecoilState(atomB)

  return (type: TDashboardDataType): TUsePseudoRecoilState<V> =>
    type === 'schedule' ? [stateA, setStateA] : [stateB, setStateB]
}

// states presented by recoil
export const useFilteredPlaceGroupNodeIdsState = (type: TDashboardDataType) =>
  useDashboardStateFactory(
    filteredPlaceGroupNodeIdsForScheduleState,
    filteredInvalidCountPlaceGroupNodeIdsState,
  )(type)
export const useFilteredPlaceNodeIdsState = (type: TDashboardDataType) =>
  useDashboardStateFactory(
    filteredPlaceNodeIdsForScheduleState,
    invalidCountFilteredPlaceNodeIdsState,
  )(type)
export const useCurrentStartDateState = (type: TDashboardDataType) =>
  useDashboardStateFactory(
    currentStartDateForScheduleState,
    currentInvalidCountStartDateState,
  )(type)
export const useCurrentEndDateState = (type: TDashboardDataType) =>
  useDashboardStateFactory(
    currentEndDateForScheduleState,
    currentInvalidCountEndDateState,
  )(type)
export const useIsFilteredState = (type: TDashboardDataType) =>
  useDashboardStateFactory(
    isFilteredForScheduleState,
    isCountFilteredState,
  )(type)
export const useHasPresentDayState = (type: TDashboardDataType) =>
  useDashboardStateFactory(
    hasPresentDayForScheduleState,
    invalidCountHasPresentDayState,
  )(type)
export const useColumnsState = (type: TDashboardDataType) =>
  useDashboardStateFactory(
    columnsForScheduleState,
    invalidCountColumnsState,
  )(type)

/**
 * API と通信し、特定の global store に取得した値を保存する関数を返します
 *
 * (action => redux store)
 * getScheduleListInRangeItems => RootState.scheduleListInRangeItemsState.scheduleListInRangeItems
 * getInvalidCountScheduleListInRangeItems => RootState.scheduleListInRangeItemsState.invalidCountScheduleListInRangeItems
 *
 * @returns Function
 */
export const useRequestScheduleData = () => {
  const dispatch = useDispatch()

  return (
    isSchedule: boolean,
    mergedPlaceNodeIds: string[],
    startDate: Date = new Date(0), // 1970-01-01T00:00:00.000Z
    endDate: Date = new Date(0), // 1970-01-01T00:00:00.000Z
  ) => {
    dispatch(
      (isSchedule
        ? getScheduleListInRangeItems
        : getInvalidCountScheduleListInRangeItems)(
        {
          placeNodeId: { $in: mergedPlaceNodeIds },
          startDate,
          endDate,
          isNeedInvalidCount: 1,
        },
        'Asia/Tokyo',
      ),
    )
  }
}

const isTPlace = (x: TColumnValue): x is TPlace =>
  typeof x === 'object' && 'name' in x && 'uuid' in x
/** 最初のカラム（列の一番左、店舗名が入る場所）のデータ */
const initColumn: TColumn = {
  // - `useDashboardStateFactory` など type variable を使っている箇所があり、
  // この file を .tsx にできないので `createElement` で書いている
  title: () => createElement(TableHeader),
  dataIndex: 'place',
  align: 'center',
  textWrap: 'word-break',
  width: 200,
  render: (value: TColumnValue) => {
    // - `useDashboardStateFactory` など type variable を使っている箇所があり、
    // この file を .tsx にできないので `createElement` で書いている
    return createElement(PlaceLabel, null, isTPlace(value) ? value.name : '')
  },
}

/** startDate と endDate を含む連続した日付を Date 型で取得する */
const getDatesBetween = (startDate: Date, endDate: Date): Date[] =>
  [...Array(differenceInDays(endDate, startDate) + 1)].map((_, i) =>
    addDays(startDate, i),
  )

/** TColumn 生成時の共通部分を埋める */
const packColumnData = ({
  dayOfTheMonth,
  dayOfTheWeek,
  dataIndex,
  render,
}: {
  dayOfTheMonth: string
  dayOfTheWeek: string
  dataIndex: string
  render: TColumn['render']
}): TColumn => ({
  // - `useDashboardStateFactory` など type variable を使っている箇所があり、
  // この file を .tsx にできないので `createElement` で書いている
  title: () =>
    createElement(TableHeader, null, [
      createElement(DayOfTheMonth, { theme: { color: Black } }, dayOfTheMonth),
      createElement(DayOfTheWeek, { theme: { color: Black } }, dayOfTheWeek),
    ]),
  dataIndex,
  align: 'center',
  textWrap: 'word-break',
  render,
  width: 60,
})

const isTNewColumnValue = (x: TColumnValue): x is TColumnValueByPlaceAndDay =>
  'scheduleStatus' in x && 'devianceCount' in x
/** レポートの入力 o/x/- または逸脱数を表示する jsx を返す関数を引数に取り、`startDate` `endDate` を与えれば `antd.Column[]` を生成できる関数を返す */
const getColumnsFactory =
  (
    renderCell: (dependencies: {
      targetDate: string
      value: TColumnValueByPlaceAndDay
      record: TItem
    }) => JSX.Element,
  ) =>
  (startDate: Date | undefined, endDate: Date | undefined): TColumn[] => {
    if (!startDate || !endDate) {
      return []
    }

    return [
      initColumn,
      ...getDatesBetween(startDate, endDate).map(currentDate => {
        const dayOfTheMonth = date.formatDayOfMonth_locale(currentDate)
        const dayOfTheWeek = date.formatISODayOfWeek_locale(currentDate)
        const targetDate = date.formatYYYYMMDD_hyphen_locale(currentDate)
        const dayOfTheWeekEng = date
          .formatISODayOfWeek(currentDate)
          .toLocaleLowerCase()

        const render = (value: TColumnValue, record: TItem) => {
          if (isAfter(currentDate, date.today())) {
            // - `useDashboardStateFactory` など type variable を使っている箇所があり、
            // この file を .tsx にできないので `createElement` で書いている
            return createElement(
              'div',
              null,
              createElement(StatusCellDisable, null, '-'),
            )
          }

          // - `useDashboardStateFactory` など type variable を使っている箇所があり、
          // この file を .tsx にできないので `createElement` で書いている
          return isTNewColumnValue(value)
            ? renderCell({ targetDate, value, record })
            : createElement(Fragment)
        }

        return packColumnData({
          render,
          dayOfTheMonth,
          dayOfTheWeek,
          dataIndex: dayOfTheWeekEng,
        })
      }),
    ]
  }

/** LinkToPlaceDashboard component を生成するだけの hook */
const useLinkToPlaceDashboard = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const goPlaceDashboard = (placeNodeUUID: string, targetDate: string) => {
    dispatch(resetPlaceScheduleListInRangeItems())
    history.push(`/dashboards/places/${placeNodeUUID}?targetDate=${targetDate}`)
  }

  // - component に名前をつけるために直接 return せず const している
  // - `useDashboardStateFactory` など type variable を使っている箇所があり、
  // この file を .tsx にできないので `createElement` で書いている
  const LinkToPlaceDashboard = ({
    children,
    placeUUID,
    date,
  }: {
    placeUUID: string
    date: string
    children?: ReactNode
  }) =>
    createElement(
      'div',
      {
        onClick: () => goPlaceDashboard(placeUUID, date),
        style: { cursor: 'pointer' },
      },
      children,
    )

  return LinkToPlaceDashboard
}

// レポート入力状況用の `antd.Column[]` を生成する関数を返す hook
export const useGetScheduleStatusColumns = (): ((
  startDate: Date,
  endDate: Date,
) => TColumn[]) => {
  const LinkToPlaceDashboard = useLinkToPlaceDashboard()

  return getColumnsFactory(({ targetDate, value, record }) => {
    const isCompleted = value.scheduleStatus === ScheduleStatus.COMPLETED
    const background = isCompleted
      ? SuccessCellBackgroundColor
      : InvalidCellBackgroundColor
    const Icon = isCompleted ? CircleSvg : CrossSvg
    const fill = isCompleted ? Success : Danger

    // - `useDashboardStateFactory` など type variable を使っている箇所があり、
    // この file を .tsx にできないので `createElement` で書いている
    return createElement(
      LinkToPlaceDashboard,
      {
        placeUUID: record.place.uuid,
        date: targetDate,
      },
      [ScheduleStatus.COMPLETED, ScheduleStatus.INCOMPLETE].includes(
        value.scheduleStatus ?? ScheduleStatus.DEFAULT,
      )
        ? createElement(
            StatusCell,
            {
              theme: { background },
            },
            createElement(Icon, { fill }),
          )
        : createElement(StatusCell, null, '-'),
    )
  })
}

// 逸脱状況用の `antd.Column[]` を生成する関数を返す hook
export const useGetDevianceCountColumns = (): ((
  startDate: Date,
  endDate: Date,
) => TColumn[]) => {
  const LinkToPlaceDashboard = useLinkToPlaceDashboard()

  // - `useDashboardStateFactory` など type variable を使っている箇所があり、
  // この file を .tsx にできないので `createElement` で書いている
  return getColumnsFactory(({ targetDate, value, record }) =>
    createElement(
      LinkToPlaceDashboard,
      {
        placeUUID: record.place.uuid,
        date: targetDate,
      },
      createElement(
        InvalidCell,
        {
          theme: value.devianceCount
            ? { color: Danger, background: InvalidCellBackgroundColor }
            : { color: Gray },
        },
        value.devianceCount,
      ),
    ),
  )
}
