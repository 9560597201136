import { useMutation } from '@tanstack/react-query'
import { ExportsApi } from '@ulysses-inc/harami_api_client'
import { baseClient } from 'src/state/middleware/saga/baseClient'

type Variables = {
  reportExportId: number
}

/**
 * レポート出力結果ファイルをダウンロードする
 *
 * 厳密にはMutationではないが、複雑な非同期処理はMutationにまとめたほうが後の取り回しが良いためこうした
 */
export const useMutationDownloadFile = () => {
  return useMutation({
    mutationFn: async ({ reportExportId }: Variables) => {
      const { downloadUrl, downloadFileName } = await baseClient
        .getApi(ExportsApi)
        .getReportsExportDownload({
          exportId: String(reportExportId),
        })

      const blob = await fetch(downloadUrl).then(res => res.blob())
      const aTag = document.createElement('a')
      aTag.href = URL.createObjectURL(blob)
      aTag.download = downloadFileName
      aTag.click()
      URL.revokeObjectURL(aTag.href)
    },
  })
}
