import { MultipleChoice } from '@ulysses-inc/harami_api_client'
import { Checkbox } from 'antd'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'
import { FC } from 'react'
import { TMultipleChoicesState } from './AddMultipleChoiceDrawer'
import {
  ButtonSuffixText,
  Score,
  ScoreGroup,
} from './AddMultipleChoiceDrawer.styled'

const ScoreColumn: FC<{
  multipleChoicesState: TMultipleChoicesState
  multipleChoice: MultipleChoice
}> = ({
  multipleChoicesState: [multipleChoices, setMultipleChoices],
  multipleChoice,
}) => {
  const { id, score, isExcludedFromScoring } = multipleChoice

  const onScoreChange = (value: string | number | undefined | null) => {
    if (typeof value !== 'number') return

    setMultipleChoices({
      ...multipleChoices,
      [`${id}`]: {
        ...multipleChoice,
        score: value,
      },
    })
  }

  const onCheckboxChange = (e: CheckboxChangeEvent) => {
    setMultipleChoices({
      ...multipleChoices,
      [`${id}`]: {
        ...multipleChoice,
        isExcludedFromScoring: e.target.checked,
      },
    })
  }

  return (
    <ScoreGroup>
      <Score
        defaultValue={score}
        disabled={isExcludedFromScoring}
        onChange={onScoreChange}
      />
      <ButtonSuffixText />
      <Checkbox onChange={onCheckboxChange} checked={isExcludedFromScoring}>
        配点対象外
      </Checkbox>
    </ScoreGroup>
  )
}

export default ScoreColumn
