import {
  PlaceNode,
  ScheduleListInRangeItem,
  ScheduleListInRangeItemInfo,
} from '@ulysses-inc/harami_api_client'
import { ConfigProvider, Layout, Table } from 'antd'
// https://github.com/import-js/eslint-plugin-import/issues/1479
import { parse } from 'date-fns' // eslint-disable-line import/no-duplicates
// eslint-disable-next-line import/no-duplicates
import ja from 'date-fns/locale/ja'
import { parse as parseQueryString } from 'qs'
import React, { JSX, useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { RouteComponentProps, useHistory } from 'react-router-dom'
import { Header } from 'src/components/header/Header'
import { HeaderBackButton } from 'src/components/header/HeaderBackButton'
import {
  ScheduleStatus,
  getScheduleStatus,
} from 'src/exShared/util/scheduleListInRange/convertScheduleListInRange'
import PlaceCalendar from 'src/features/dashboardPlace/PlaceCalendar'
import {
  Danger,
  InvalidCellBackgroundColor,
  Primary,
  Success,
  SuccessCellBackgroundColor,
} from 'src/features/theme/KdsThemeColor'
import { RootState } from 'src/state/store'
import styled from 'styled-components'
import CircleSvg from '../../assets/icons/circle.svg?react'
import CrossSvg from '../../assets/icons/cross.svg?react'
import PlaceSvg from '../../assets/icons/place.svg?react'
import placesOperations from '../../state/ducks/places/operations'
import scheduleListInRangeItemsOperations from '../../state/ducks/scheduleListInRangeItems/operations'

interface StateProps {
  placeNodeUUID: string
  targetDate: Date

  places: PlaceNode[]
  scheduleListInRangeItems: ScheduleListInRangeItem[]
  isLoading: boolean
}

interface DispatchProps {
  getPlaces: () => void
  getPlaceScheduleListInRangeItems: (targetDate: Date) => void
}

interface NavigationProps {
  goBack: () => void
  goReportResult: (reportUUID: string, templateId: number) => void
}

type PlaceDashboardProps = StateProps & DispatchProps & NavigationProps

const useStateProps = (placeNodeUUID: string): StateProps => {
  const history = useHistory()
  const qs = parseQueryString(history.location.search, {
    ignoreQueryPrefix: true,
  })
  return {
    placeNodeUUID: placeNodeUUID,
    targetDate: useSelector((state: RootState) => {
      if (
        state.scheduleListInRangeItemsState.placeScheduleListInRangeItems
          .targetDate
      ) {
        return state.scheduleListInRangeItemsState.placeScheduleListInRangeItems
          .targetDate
      }
      return parse(
        qs['targetDate'] as string,
        'yyyy-MM-dd',
        new Date(2000, 0, 1),
        { locale: ja },
      )
    }, shallowEqual),
    places: useSelector(
      (state: RootState) => state.placesState.places.places,
      shallowEqual,
    ),
    scheduleListInRangeItems: useSelector(
      (state: RootState) =>
        state.scheduleListInRangeItemsState.placeScheduleListInRangeItems
          .scheduleListInRangeItems,
      shallowEqual,
    ),
    isLoading: useSelector(
      (state: RootState) =>
        state.scheduleListInRangeItemsState.placeScheduleListInRangeItems
          .isLoading,
      shallowEqual,
    ),
  }
}

const useReducerProps = (stateProps: StateProps): DispatchProps => {
  const dispatch = useDispatch()

  return {
    getPlaces: () => {
      placesOperations.getPlaces(dispatch, {})
    },
    getPlaceScheduleListInRangeItems: (targetDate: Date) => {
      scheduleListInRangeItemsOperations.getPlaceScheduleListInRangeItems(
        dispatch,
        {
          placeNodeId: { $in: [stateProps.placeNodeUUID] },
          startDate: targetDate,
          endDate: targetDate,
          isNeedInvalidCount: 1,
        },
        'Asia/Tokyo',
      )
    },
  }
}

const useNavigationProps = (): NavigationProps => {
  const history = useHistory()

  return {
    goBack: () => {
      history.goBack()
    },
    goReportResult: (reportUUID: string, templateId: number) => {
      history.push(`/reports/${reportUUID}?templateId=${templateId}`)
    },
  }
}

const PlaceDashboardContainer: React.FC<
  RouteComponentProps<{ placeNodeUUID: string }>
> = (_props: RouteComponentProps<{ placeNodeUUID: string }>) => {
  const stateProps = useStateProps(_props.match.params['placeNodeUUID'])
  const dispatchProps = useReducerProps(stateProps)
  const navigationProps = useNavigationProps()
  const props = { ...stateProps, ...dispatchProps, ...navigationProps }
  return <PlaceDashboardScene {...props} />
}

const Body = styled.div`
  padding: 2rem;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  max-width: 1024px;
  position: relative;
  flex-direction: column;
`
const TableWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`

const TableHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  font-size: 18px;
  font-weight: bold;
`

const PlaceLabelWrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: 18px;
`
const PlaceLabel = styled.div`
  font-weight: bold;
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const InvalidCell = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 56px;
  height: 56px;
  font-size: 18px;
  font-weight: bold;
  background: ${props => props?.theme?.background || 'none'};
  color: ${props => props?.theme?.color || 'black'};
`
const StatusCell = styled.div`
  width: 56px;
  height: 56px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: ${props => props?.theme?.background || 'none'};
  &:hover {
    box-shadow:
      0px 2px 5px -1px rgba(0, 0, 0, 0.1),
      0px 1px 10px 0px rgba(0, 0, 0, 0.08),
      0px 1px 15px 0px rgba(0, 0, 0, 0.12) !important;
    transform: translate3d(0, -4px, 0);
  }
`

const StatusCellDisable = styled.div`
  width: 56px;
  height: 56px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: ${props => props?.theme?.background || 'none'};
`

type DataSource = {
  key: string
  scheduleName: string
  invalidCount: number
  status: ScheduleStatus
  templateId?: number
  reportUUID?: string
}

type TableColumn = {
  title: () => JSX.Element
  dataIndex: string
  align: 'center' | 'left'
  width?: number
  render: (value: any, row: any) => any
}

const PlaceDashboardScene: React.FC<PlaceDashboardProps> = (
  props: PlaceDashboardProps,
) => {
  const [dataSource, setDataSource] = useState<DataSource[]>([])
  const [targetDate, setTargetDate] = useState<Date>(props.targetDate)

  useEffect(() => {
    setTargetDate(props.targetDate)
    props.getPlaces()
    props.getPlaceScheduleListInRangeItems(props.targetDate)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const dataSource: DataSource[] = []
    props.scheduleListInRangeItems.forEach((item, rangeIndex) => {
      item.infos?.forEach(
        (info: ScheduleListInRangeItemInfo, infoIndex: number) => {
          dataSource.push({
            key: `${rangeIndex}-${infoIndex}`,
            scheduleName: info.scheduleName || '',
            invalidCount:
              info.report?.invalidCount !== undefined
                ? info.report?.invalidCount
                : 0,
            status: getScheduleStatus(info),
            templateId: info.templateId,
            reportUUID: info.report?.uuid,
          })
        },
      )
    })

    setDataSource(dataSource)
  }, [props.scheduleListInRangeItems])

  const renderStatusIcon = (status: ScheduleStatus, disabled: boolean) => {
    switch (status) {
      case ScheduleStatus.COMPLETED: {
        return (
          <StatusCell theme={{ background: SuccessCellBackgroundColor }}>
            <CircleSvg fill={Success} />
          </StatusCell>
        )
      }
      case ScheduleStatus.INCOMPLETE: {
        if (disabled) {
          return (
            <StatusCellDisable
              theme={{ background: InvalidCellBackgroundColor }}
            >
              <CrossSvg fill={Danger} />
            </StatusCellDisable>
          )
        }
        return (
          <StatusCell theme={{ background: InvalidCellBackgroundColor }}>
            <CrossSvg fill={Danger} />
          </StatusCell>
        )
      }
      default: {
        return <StatusCellDisable>-</StatusCellDisable>
      }
    }
  }

  const columns: TableColumn[] = [
    {
      title: () => <TableHeader>スケジュール名</TableHeader>,
      dataIndex: 'scheduleName',
      align: 'left',
      render: (value: any, _: any) => {
        return <div>{value}</div>
      },
    },
    {
      title: () => <TableHeader>ルール逸脱</TableHeader>,
      dataIndex: 'invalidCount',
      align: 'center',
      width: 140,
      render: (value: any, row: any) => {
        if (!row.reportUUID || !row.templateId) {
          return <InvalidCell>-</InvalidCell>
        }
        if (value === 0) {
          return <InvalidCell>0</InvalidCell>
        }
        return (
          <InvalidCell
            theme={{ background: InvalidCellBackgroundColor, color: Danger }}
          >
            {value}
          </InvalidCell>
        )
      },
    },
    {
      title: () => <TableHeader>実施状況</TableHeader>,
      dataIndex: 'status',
      align: 'center',
      width: 140,
      render: (value: any, row: any) => {
        const status = value as ScheduleStatus
        const disabled = !row.reportUUID || !row.templateId
        return (
          <div
            onClick={() => {
              if (disabled) {
                return
              }
              props.goReportResult(row.reportUUID, row.templateId)
            }}
            style={!disabled ? { cursor: 'pointer' } : {}}
          >
            {renderStatusIcon(status, disabled)}
          </div>
        )
      },
    },
  ]
  return (
    <Layout>
      <Header>
        <HeaderBackButton onClick={() => props.goBack()} />
        <PlaceLabelWrapper>
          <PlaceSvg style={{ marginRight: 4 }} fill={Primary} />
          <PlaceLabel>
            {props.places.find(place => place.uuid === props.placeNodeUUID)
              ?.place?.name || ''}
          </PlaceLabel>
        </PlaceLabelWrapper>
        <PlaceCalendar
          targetDate={targetDate}
          placeNodeUUID={props.placeNodeUUID}
          changeTargetDate={(newTargetDate: Date) => {
            setTargetDate(newTargetDate)
            props.getPlaceScheduleListInRangeItems(newTargetDate)
          }}
        />
      </Header>
      <Body>
        <TableWrapper>
          <ConfigProvider
            theme={{
              components: {
                Table: {
                  headerBg: '#eff2f5',
                },
              },
            }}
          >
            <Table
              style={{ minWidth: 600 }}
              className="table-striped-rows"
              columns={columns}
              dataSource={dataSource}
              pagination={false}
              loading={props.isLoading}
            />
          </ConfigProvider>
        </TableWrapper>
      </Body>
    </Layout>
  )
}

export default PlaceDashboardContainer
