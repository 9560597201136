import { ChangeEvent, useEffect, useRef } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import {
  continueRangeSelect,
  doubleClickCell,
  finishRangeSelect,
  patchInputTable,
  startRangeSelect,
} from 'src/state/ducks/editGridVariables/actions'
import {
  useIsInDrag,
  useIsInEdit,
  useIsSelected,
} from 'src/state/ducks/editGridVariables/selectors'
import { RootState } from 'src/state/store'
import { BaseCell, GridCellTextInput } from '../EditGridVariables.styled'
import { useAutosizeTextArea } from '../hooks/useAutosizeTextArea'

type Props = {
  /** 行番号 */
  rowNumber: number
  /** 列番号 */
  colNumber: number
  /** 取り込み項目番号 */
  variableNumber: 1 | 2 | 3 | 4 | 5 | 6
  /** TextArea が最低限確保する高さ。これがないと、編集モードになったときにセルの高さが縮む */
  textAreaMinHeight: number
}

/**
 * 取り込み項目 1〜6 セル
 */
export const VariableItemCell = ({
  rowNumber,
  colNumber,
  variableNumber,
  textAreaMinHeight,
}: Props) => {
  const isSelected = useIsSelected(rowNumber, colNumber)
  const isInEdit = useIsInEdit(rowNumber, colNumber)

  /**
   * OPTIMIZE: このセルの mouseenter イベントが発火したときに `CONTINUE_RANGE_SELECT` を呼ぶかどうかの判定のためにモードを見ている
   *           このため、セルをクリックするなどしてモードを変更するごとに全セルの再レンダリングが発生している
   */
  const isInDrag = useIsInDrag()

  const variableValue = useSelector(
    (state: RootState) =>
      state.editGridVariablesState.editGridVariables.inputTable[rowNumber]
        ?.values[variableNumber]?.value,
  )
  const errors = useSelector(
    (state: RootState) =>
      state.editGridVariablesState.editGridVariables.inputTable[rowNumber]
        ?.values[variableNumber]?.errors,
    shallowEqual,
  )
  const hasErrors = (errors || []).length > 0

  const dispatch = useDispatch()

  const onMouseUp = () => {
    dispatch(finishRangeSelect())
  }

  const onMouseDown = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (e.button !== 0) {
      return
    }

    dispatch(startRangeSelect(rowNumber, colNumber))
  }

  const onMouseEnter = () => {
    // drag モード以外では範囲選択したくないのと、その場合に action dispatch するのは無駄なのでガードする
    if (!isInDrag) {
      return
    }

    dispatch(continueRangeSelect(rowNumber, colNumber))
  }

  const handleDoubleClick = () => {
    dispatch(doubleClickCell(rowNumber, colNumber))
  }

  // このセルが選択されていて、かつ、edit モードのとき、text input のカーソルをあわせる
  const textAreaRef = useRef<HTMLTextAreaElement>(null)
  useEffect(() => {
    if (isInEdit && textAreaRef.current !== null) {
      textAreaRef.current.focus()
      textAreaRef.current.selectionStart = variableValue?.length || 0
    }
  }, [isSelected, isInEdit, variableValue])

  useAutosizeTextArea(textAreaRef.current, variableValue || '')

  const onChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    dispatch(
      patchInputTable({
        [rowNumber]: { values: { [variableNumber]: event.target.value } },
      }),
    )
  }

  return (
    <>
      {/* editable */}
      <GridCellTextInput
        onChange={onChange}
        value={variableValue}
        style={{
          display: isInEdit ? 'flex' : 'none',
          minHeight: `${textAreaMinHeight}px`,
        }}
        ref={textAreaRef}
      />

      {/* readonly */}
      <BaseCell
        $cellState={{ isInEdit, hasErrors, isSelected }}
        onMouseDown={e => onMouseDown(e)}
        onMouseEnter={onMouseEnter}
        onMouseUp={onMouseUp}
        onDoubleClick={handleDoubleClick}
        style={{
          display: isInEdit ? 'none' : 'flex',
          wordBreak: 'break-all',
        }}
      >
        {variableValue}
      </BaseCell>
    </>
  )
}
