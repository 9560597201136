import {
  BranchesOutlined,
  CheckCircleOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons'
import {
  NumberCondition,
  NumberLogicTypeEnum,
  TemplateLogic,
  TemplateNodeSchema,
  TemplateNodeTypeEnum,
} from '@ulysses-inc/harami_api_client'
import { Button, Modal, Row, Typography } from 'antd'
import { useFormik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import FormikErrorMessage from 'src/components/formikErrorMessage/FormikErrorMessage'
import {
  BranchesIconColor,
  Danger,
  Success,
  White,
} from 'src/features/theme/KdsThemeColor'
import {
  addTemplateNumberLogic,
  updateTemplateNumberLogic,
} from 'src/state/ducks/templates/actions'
import { closeEditLogic } from 'src/state/ducks/templates/editLogic/actions'
import { RootState } from 'src/state/store'
import { isDefined } from 'src/util/idDefined'
import styled from 'styled-components'

// この色はこのコンポーネントでしか使っていない。整理が必要。
export const DisabledButtonBg = '#E0E0E0'

const { Title, Text } = Typography

const SettingModal = styled(Modal)`
  width: '100%';
`

const ModalTitleWrap = styled.div`
  display: flex;
  align-items: center;
`

const ModalTitle = styled(Title)`
  && {
    margin-bottom: 0px;
    margin-left: 10px;
  }
`

const ModalCheckButtonGroup = styled.div`
  margin-top: 15px;
`

const ModalCheckButtonSuffixText = styled.span`
  margin-left: 5px;
`

const NormalCheckButtonWrap = styled.div`
  margin-right: 15px;
  margin-bottom: 15px;
`

const EditNumberLogicModal = () => {
  const dispatch = useDispatch()

  const {
    editLogic: { isOpenNumberModal, questionNode },
    templatePages: { templateNodes },
  } = useSelector((state: RootState) => state.templatesState)

  const createInitialValue = (): TemplateLogic => {
    const logicNodes = questionNode?.nodes
      .filter(
        (nodeId: number) =>
          templateNodes[nodeId]?.type === TemplateNodeTypeEnum.Logic,
      )
      .filter(
        (nodeId: number) =>
          !!templateNodes[nodeId]?.logic?.numberConditions?.[0],
      )
      .map((nodeId: number) => templateNodes[nodeId])
      .filter(isDefined)

    if (logicNodes && logicNodes.length > 0) {
      const numberConditions = logicNodes.map(
        (node: TemplateNodeSchema) => node.logic?.numberConditions?.[0],
      )
      return {
        numberConditions: numberConditions as NumberCondition[],
      }
    }
    return {
      numberConditions: [],
    }
  }

  const { errors, values, touched, setFieldValue, handleSubmit } = useFormik({
    initialValues: createInitialValue(),
    onSubmit: (values, formikHelpers) => {
      if (values.numberConditions && values.numberConditions.length !== 0) {
        // 単に型の都合であり、実際にはundefinedになることはない想定
        if (!questionNode) return

        const isUpdate = questionNode.nodes.some((nodeId: number) => {
          const thisNode = templateNodes[nodeId]
          return thisNode && thisNode.type === TemplateNodeTypeEnum.Logic
        })
        if (isUpdate) {
          dispatch(
            updateTemplateNumberLogic(questionNode, values.numberConditions),
          )
        } else {
          dispatch(
            addTemplateNumberLogic(questionNode, values.numberConditions),
          )
        }
        dispatch(closeEditLogic())
        formikHelpers.resetForm()
        return
      }

      formikHelpers.setFieldError(
        'numberConditions',
        '正常か違反の最低どちらか一つを選択してください',
      )
      formikHelpers.setFieldTouched('numberConditions', true, false)
      formikHelpers.resetForm()
    },
    enableReinitialize: true,
  })

  const isUpdate = questionNode?.nodes.some(
    (nodeId: number) =>
      templateNodes[nodeId]?.type === TemplateNodeTypeEnum.Logic,
  )

  const isNormalSelected = values.numberConditions?.some(
    (numberCondition: NumberCondition) =>
      numberCondition.logicType === NumberLogicTypeEnum.NORMAL,
  )
  const isInvalidSelected = values.numberConditions?.some(
    (numberCondition: NumberCondition) =>
      numberCondition.logicType === NumberLogicTypeEnum.INVALID,
  )

  const onClickNormalButton = () => {
    if (isNormalSelected) {
      const filteredConditions = values.numberConditions?.filter(
        (numberCondition: NumberCondition) =>
          numberCondition.logicType !== NumberLogicTypeEnum.NORMAL,
      )
      setFieldValue('numberConditions', filteredConditions)
    } else {
      const newConditions = [
        ...(values?.numberConditions ?? []),
        { logicType: NumberLogicTypeEnum.NORMAL },
      ]
      setFieldValue('numberConditions', newConditions)
    }
  }

  const onClickInvalidButton = () => {
    if (isInvalidSelected) {
      const filteredConditions = values.numberConditions?.filter(
        (numberCondition: NumberCondition) =>
          numberCondition.logicType !== NumberLogicTypeEnum.INVALID,
      )
      setFieldValue('numberConditions', filteredConditions)
    } else {
      const newConditions = [
        ...(values?.numberConditions ?? []),
        { logicType: NumberLogicTypeEnum.INVALID },
      ]
      setFieldValue('numberConditions', newConditions)
    }
  }

  return (
    <SettingModal
      title={
        <ModalTitleWrap>
          <BranchesOutlined rotate={90} style={{ color: BranchesIconColor }} />
          <ModalTitle level={4}>条件分岐の設定</ModalTitle>
        </ModalTitleWrap>
      }
      style={{ minWidth: '1000px' }}
      open={isOpenNumberModal}
      onOk={() => handleSubmit()}
      onCancel={() => dispatch(closeEditLogic())}
      okText="設定"
      cancelText="キャンセル"
    >
      {questionNode !== undefined ? (
        <>
          <Row>
            <Text strong>
              「{questionNode.question?.name ?? ''}
              」には以下の条件分岐を設定可能です。
            </Text>
          </Row>
          <Row>
            <ModalCheckButtonGroup>
              <NormalCheckButtonWrap>
                <Button
                  onClick={() => {
                    onClickNormalButton()
                  }}
                  style={
                    isNormalSelected
                      ? {
                          backgroundColor: isUpdate
                            ? DisabledButtonBg
                            : Success,
                          color: White,
                          borderColor: isUpdate ? DisabledButtonBg : Success,
                        }
                      : {
                          backgroundColor: White,
                          color: Success,
                          borderColor: Success,
                        }
                  }
                  icon={<CheckCircleOutlined />}
                  disabled={isNormalSelected && isUpdate}
                >
                  正常
                </Button>
                <ModalCheckButtonSuffixText>の場合</ModalCheckButtonSuffixText>
              </NormalCheckButtonWrap>
              <div>
                <Button
                  onClick={() => {
                    onClickInvalidButton()
                  }}
                  style={
                    isInvalidSelected
                      ? {
                          backgroundColor: isUpdate ? DisabledButtonBg : Danger,
                          color: White,
                          borderColor: isUpdate ? DisabledButtonBg : Danger,
                        }
                      : {
                          backgroundColor: White,
                          color: Danger,
                          borderColor: Danger,
                        }
                  }
                  icon={<ExclamationCircleOutlined />}
                  disabled={isInvalidSelected && isUpdate}
                >
                  逸脱
                </Button>
                <ModalCheckButtonSuffixText>の場合</ModalCheckButtonSuffixText>
              </div>
            </ModalCheckButtonGroup>
          </Row>
          <Row>
            <FormikErrorMessage
              name="numberConditions"
              errors={errors}
              touched={touched}
            />
          </Row>
        </>
      ) : null}
    </SettingModal>
  )
}

export default EditNumberLogicModal
