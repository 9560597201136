import { MultipleChoice } from '@ulysses-inc/harami_api_client'
import { FC } from 'react'
import { Gray } from 'src/features/theme/KdsThemeColor'
import { multipleChoiceColors } from 'src/features/theme/MultipleChoiceColor'
import { CellInner, TableBodyCellWrapper } from './Common.dumb'

const getCustomFontColor = (color?: string) => {
  if (color === undefined) {
    return undefined
  }

  if (color === '' || color === multipleChoiceColors.default) {
    // 選択肢の設定色が白orグレーの場合、可視性を高めるためにGray6を使用
    return Gray
  }

  return color
}

type Props = {
  value?: MultipleChoice
}

export const TableBodyCellMultipleChoice: FC<Props> = ({ value }) => {
  const isInvalid = value?.isInvalid === 1

  return (
    <TableBodyCellWrapper
      backgroundColor={isInvalid ? 'invalid' : 'default'}
      fontColor={isInvalid ? 'invalid' : 'default'}
      customFontColor={isInvalid ? undefined : getCustomFontColor(value?.color)}
    >
      <CellInner>{value?.response}</CellInner>
    </TableBodyCellWrapper>
  )
}
