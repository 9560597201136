// prettier-ignore
export const ActionTypes = {
  REQUEST_GET_PREPARED_TEMPLATE: 'harami/templates/REQUEST_GET_PREPARED_TEMPLATE',
  REQUEST_GET_TEMPLATE: 'harami/templates/REQUEST_GET_TEMPLATE',
  SUCCESS_GET_TEMPLATE: 'harami/templates/SUCCESS_GET_TEMPLATE',
  ERROR_GET_TEMPLATE: 'harami/templates/ERROR_GET_TEMPLATE',
  GET_EMPTY_TEMPLATE: 'harami/templates/GET_EMPTY_TEMPLATE',
  GET_EMPTY_GRID_TEMPLATE: 'harami/templates/GET_EMPTY_GRID_TEMPLATE',
  ADD_TEMPLATE: 'harami/templates/ADD_TEMPLATE',
  SUCCESS_ADD_TEMPLATE: 'harami/templates/SUCCESS_ADD_TEMPLATE',
  ERROR_ADD_TEMPLATE: 'harami/templates/ERROR_ADD_TEMPLATE',
  CLEAR_ADD_TEMPLATE_PAGES_ERROR: 'harami/templates/CLEAR_ADD_TEMPLATE_PAGES_ERROR',
  REQUEST_UPDATE_TEMPLATE: 'harami/templates/REQUEST_UPDATE_TEMPLATE',
  SUCCESS_UPDATE_TEMPLATE: 'harami/templates/SUCCESS_UPDATE_TEMPLATE',
  ERROR_UPDATE_TEMPLATE: 'harami/templates/ERROR_UPDATE_TEMPLATE',
  UPDATE_TEMPLATE_NAME: 'harami/templates/UPDATE_TEMPLATE_NAME',
  UPDATE_TEMPLATE_ICONS: 'harami/templates/UPDATE_TEMPLATE_ICONS',
  UPDATE_PLACE_NODES: 'harami/templates/UPDATE_PLACE_NODES',
  SET_TEMPLATE_NAME_ERROR_MESSAGE: 'harami/templates/SET_TEMPLATE_NAME_ERROR_MESSAGE',
  RESET_TEMPLATE: 'harami/templates/RESET_TEMPLATE',
  TOGGLE_SHOW_SCORE: 'harami/templates/TOGGLE_SHOW_SCORE',
  TOGGLE_EDITABLE: 'harami/templates/TOGGLE_EDITABLE',
  TOGGLE_EXCEL_CONVERSION: 'harami/templates/TOGGLE_EXCEL_CONVERSION',
  TOGGLE_AUDIT: 'harami/templates/TOGGLE_AUDIT',
  TOGGLE_TENKEY: 'harami/templates/TOGGLE_TENKEY',
  UPDATE_LAYOUT_TYPE: 'harami/templates/UPDATE_LAYOUT_TYPE',
  ADD_TEMPLATE_MANUAL: 'harami/templates/ADD_TEMPLATE_MANUAL',
  DELETE_TEMPLATE_MANUAL: 'harami/templates/DELETE_TEMPLATE_MANUAL',
  UPDATE_TEMPLATE_MANUAL: 'harami/templates/UPDATE_TEMPLATE_MANUAL',
  UPDATE_APPROVAL_FLOW_ID: 'harami/templates/UPDATE_APPROVAL_FLOW_ID',
} as const satisfies { [key in string]: string }
