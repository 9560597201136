import {
  CaretDownOutlined,
  LeftOutlined,
  RightOutlined,
} from '@ant-design/icons'
import {
  ScheduleListInRangeItem,
  ScheduleListInRangeItemInfo,
  ScheduleTypeEnum,
} from '@ulysses-inc/harami_api_client'
import { Button, Dropdown, Spin } from 'antd'
import {
  addMonths,
  eachDayOfInterval,
  eachWeekOfInterval,
  endOfMonth,
  endOfWeek,
  getDate,
  getDay,
  getMonth,
  isAfter,
  startOfMonth,
  subMonths,
} from 'date-fns'
import React, { useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import CalendarSvg from 'src/assets/icons/calendar.svg?react'
import dateUtil from 'src/exShared/util/date'
import {
  ScheduleStatus,
  getScheduleStatus,
} from 'src/exShared/util/scheduleListInRange/convertScheduleListInRange'
import {
  BorderColor,
  Danger,
  InvalidCellBackgroundColor,
  Primary,
  White,
} from 'src/features/theme/KdsThemeColor'
import scheduleListInRangeItemsOperations from 'src/state/ducks/scheduleListInRangeItems/operations'
import { RootState } from 'src/state/store'
import styled from 'styled-components'

interface OwnProps {
  placeNodeUUID: string
  targetDate: Date
  changeTargetDate: (targetDate: Date) => void
}

interface StateProps {
  scheduleListInRangeItems: ScheduleListInRangeItem[]
  isLoading: boolean
}

interface DispatchProps {
  getMonthlyPlaceScheduleListInRangeItems: (
    placeNodeUUID: string,
    startDate: Date,
    endDate: Date,
  ) => void
}

type PlaceCalendarProps = OwnProps & StateProps & DispatchProps

const useStateProps = (): StateProps => {
  return {
    scheduleListInRangeItems: useSelector(
      (state: RootState) =>
        state.scheduleListInRangeItemsState.monthlyPlaceScheduleListInRangeItems
          .scheduleListInRangeItems,
      shallowEqual,
    ),
    isLoading: useSelector(
      (state: RootState) =>
        state.scheduleListInRangeItemsState.monthlyPlaceScheduleListInRangeItems
          .isLoading,
      shallowEqual,
    ),
  }
}

const useReducerProps = (): DispatchProps => {
  const dispatch = useDispatch()

  return {
    getMonthlyPlaceScheduleListInRangeItems: (
      placeNodeUUID: string,
      startDate: Date,
      endDate: Date,
    ) => {
      scheduleListInRangeItemsOperations.getMonthlyPlaceScheduleListInRangeItems(
        dispatch,
        {
          placeNodeId: { $in: [placeNodeUUID] },
          startDate: startDate,
          endDate: endDate,
        },
        'Asia/Tokyo',
      )
    },
  }
}

const DropDownMenu = styled.div`
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
`

const DropDownContent = styled.div`
  display: table-cell;
  border: 1px solid ${BorderColor};
  background: ${White};
  vertical-align: middle;
  background-color: ${White};
  padding: 16px;
`

const CalendarHeader = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  font-weight: bold;
  font-size: 16px;
`

const CalendarFooter = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
`
const TableWrap = styled.div`
  min-width: 225px;
  min-height: 225px;
  display: flex;
  justify-content: center;
  align-items: center;
`
const Th = styled.th`
  width: 32px;
  text-align: center;
`

const Td = styled.td`
  font-size: 14px;
  text-align: center;
`

const PlaceCalendar: React.FC<PlaceCalendarProps> = (
  props: PlaceCalendarProps,
) => {
  const [isOpen, setIsOpen] = useState(false)
  const [targetMonth, setTargetMonth] = useState<Date>(props.targetDate)
  const [alerts, setAlerts] = useState<{ [date: string]: boolean }>({})

  useEffect(() => {
    const targetMonth = startOfMonth(props.targetDate)
    setTargetMonth(targetMonth)
    props.getMonthlyPlaceScheduleListInRangeItems(
      props.placeNodeUUID,
      targetMonth,
      endOfMonth(targetMonth),
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.targetDate])

  useEffect(() => {
    const today = dateUtil.formatYYYYMMDD_hyphen()
    const alerts: { [date: string]: boolean } = {}
    props.scheduleListInRangeItems.forEach((item: ScheduleListInRangeItem) => {
      if (!item?.infos || !item.targetDate) {
        return
      }
      if (today < item.targetDate) {
        return
      }

      const alertReports = item.infos
        .filter(info => {
          if (
            info.scheduleType === ScheduleTypeEnum.Duration &&
            info.scheduleInDuration?.through
          ) {
            // 期間スケジュールの場合、期間の最終日のみチェック対象
            const formattedThrough = dateUtil.formatYYYYMMDD_hyphen_locale(
              info.scheduleInDuration.through,
            )
            return formattedThrough === item.targetDate
          }
          return true
        })
        .find((info: ScheduleListInRangeItemInfo) => {
          const scheduleStatus = getScheduleStatus(info)
          return scheduleStatus === ScheduleStatus.INCOMPLETE
        })

      alerts[item.targetDate] = !!alertReports
    })
    setAlerts(alerts)
  }, [props.scheduleListInRangeItems])

  const getCalendarArray = () => {
    const sundays = eachWeekOfInterval({
      start: startOfMonth(targetMonth),
      end: endOfMonth(targetMonth),
    })
    return sundays.map(sunday =>
      eachDayOfInterval({ start: sunday, end: endOfWeek(sunday) }),
    )
  }

  const renderCalendar = () => {
    return (
      <div>
        <CalendarHeader>
          <Button
            onClick={() => {
              const newTargetMonth = subMonths(targetMonth, 1)
              setTargetMonth(newTargetMonth)
              props.getMonthlyPlaceScheduleListInRangeItems(
                props.placeNodeUUID,
                newTargetMonth,
                endOfMonth(newTargetMonth),
              )
            }}
            type="link"
          >
            <LeftOutlined />
          </Button>
          <div>{dateUtil.formatYYYYMM_JP(targetMonth)}</div>
          <Button
            onClick={() => {
              const newTargetMonth = addMonths(targetMonth, 1)
              setTargetMonth(newTargetMonth)
              props.getMonthlyPlaceScheduleListInRangeItems(
                props.placeNodeUUID,
                newTargetMonth,
                endOfMonth(newTargetMonth),
              )
            }}
            type="link"
          >
            <RightOutlined />
          </Button>
        </CalendarHeader>

        <TableWrap>
          {props.isLoading && <Spin />}
          {!props.isLoading && (
            <table>
              <thead>
                <tr>
                  <Th>日</Th>
                  <Th>月</Th>
                  <Th>火</Th>
                  <Th>水</Th>
                  <Th>木</Th>
                  <Th>金</Th>
                  <Th>土</Th>
                </tr>
              </thead>
              <tbody>
                {getCalendarArray().map((weekRow, rowNum) => (
                  <tr key={rowNum}>
                    {weekRow.map(currentDate => {
                      const formattedDate =
                        dateUtil.formatYYYYMMDD_hyphen(currentDate)

                      if (
                        getMonth(currentDate) !== getMonth(targetMonth) ||
                        isAfter(currentDate, dateUtil.today())
                      ) {
                        return (
                          <Td
                            style={{ color: '#BFBFBF' }}
                            key={getDay(currentDate)}
                          >
                            {getDate(currentDate)}
                          </Td>
                        )
                      }
                      if (alerts[formattedDate]) {
                        return (
                          <Td key={getDay(currentDate)}>
                            <div
                              style={{
                                padding: 4,
                                color: Danger,
                                fontWeight: 'bold',
                                background: InvalidCellBackgroundColor,
                                cursor: 'pointer',
                              }}
                              onClick={() => {
                                props.changeTargetDate(currentDate)
                                setIsOpen(false)
                              }}
                            >
                              {getDate(currentDate)}
                            </div>
                          </Td>
                        )
                      }
                      return (
                        <Td key={getDay(currentDate)}>
                          <div
                            style={{
                              padding: 4,
                              cursor: 'pointer',
                            }}
                            onClick={() => {
                              props.changeTargetDate(currentDate)
                              setIsOpen(false)
                            }}
                          >
                            {getDate(currentDate)}
                          </div>
                        </Td>
                      )
                    })}
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </TableWrap>

        <CalendarFooter>
          <Button
            onClick={() => {
              const newTargetMonth = startOfMonth(dateUtil.today())
              setTargetMonth(newTargetMonth)
              props.getMonthlyPlaceScheduleListInRangeItems(
                props.placeNodeUUID,
                newTargetMonth,
                endOfMonth(newTargetMonth),
              )
            }}
            type="link"
          >
            今月
          </Button>
        </CalendarFooter>
      </div>
    )
  }

  const groundArea = (
    <DropDownMenu style={{ cursor: 'pointer' }}>
      <CalendarSvg style={{ marginRight: 4 }} fill={Primary} />
      {dateUtil.formatMMDD_JP(props.targetDate)}
      <CaretDownOutlined style={{ color: Primary, marginLeft: 4 }} />
    </DropDownMenu>
  )

  const hoverArea = (
    <div style={{ display: 'flex' }}>
      <DropDownContent>{renderCalendar()}</DropDownContent>
    </div>
  )

  return (
    <Dropdown
      dropdownRender={() => hoverArea}
      trigger={['click']}
      open={isOpen}
      onOpenChange={setIsOpen}
    >
      {groundArea}
    </Dropdown>
  )
}

const PlaceDashboardContainer: React.FC<OwnProps> = (ownProps: OwnProps) => {
  const stateProps = useStateProps()
  const dispatchProps = useReducerProps()
  const props = { ...stateProps, ...dispatchProps, ...ownProps }
  return <PlaceCalendar {...props} />
}

export default PlaceDashboardContainer
