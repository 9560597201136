import {
  DayOfWeekEnum,
  ScheduleFrequencyEnum,
  ScheduleTypeEnum,
} from '@ulysses-inc/harami_api_client'
import { Checkbox } from 'antd'
import { addMonths, addWeeks, getWeekOfMonth, startOfMonth } from 'date-fns'
import moment, { Moment } from 'moment'
import React from 'react'
import { MomentDatePicker } from 'src/components/datepicker/MomentDatePicker'
import FormikErrorMessage from 'src/components/formikErrorMessage/FormikErrorMessage'
import date from 'src/exShared/util/date'
import { ScheduleForm, TBaseFormRowProps } from './EditSchedule'
import {
  CheckboxArea,
  CheckboxText,
  FormLabel,
  FormRow,
  Text,
  TimePickerArea,
  TimePickerMargin,
} from './EditSchedule.styled'

const dateFormat = 'YYYY/MM/DD'

const getStartReportDate = (schedule: ScheduleForm) => {
  const today = date.today()
  // OpenAPI上で月曜~日曜を1~7で設定しているので日曜のずれを補正する
  const todayWeek = today.getDay() !== 0 ? today.getDay() : DayOfWeekEnum.Sunday
  const yesterday = date.addDays(date.endOfDay(today), -1)

  // 頻度=毎日
  if (schedule.frequency === ScheduleFrequencyEnum.Daily) {
    return yesterday
  }

  // 頻度=週単位
  if (schedule.frequency === ScheduleFrequencyEnum.Weekly) {
    return yesterday
  }

  // 頻度=月単位
  if (schedule.frequency === ScheduleFrequencyEnum.Monthly) {
    const scheduleMonthlyEvery = schedule.frequencyMonthly?.every ?? 0
    const scheduleWeekOfMonth = schedule.frequencyMonthly?.weekOfMonth ?? 0
    const scheduleDayOfWeek = schedule.frequencyMonthly?.dayOfWeek ?? 0
    // タイプが期間の場合は最初の開始日は現在日から開始（現在日以前は非活性）
    if (schedule.type === ScheduleTypeEnum.Duration) return yesterday
    // 各サイクル設定が未設定の場合は現在日から開始（現在日以前は非活性）
    if (scheduleMonthlyEvery === 0) return yesterday
    if (scheduleWeekOfMonth === 0) return yesterday
    if (scheduleDayOfWeek === 0) return yesterday

    const todayWeekOfMonth = getWeekOfMonth(today)
    if (todayWeekOfMonth < scheduleWeekOfMonth) {
      const thisMonthFirstDay = date.startOfMonth(today)
      const todayMonthTargetWeekDay = addWeeks(
        thisMonthFirstDay,
        scheduleWeekOfMonth - 1,
      )
      const thisMonthTargetDayOfWeek =
        todayMonthTargetWeekDay.getDay() !== 0
          ? todayMonthTargetWeekDay.getDay()
          : DayOfWeekEnum.Sunday
      const diffDays = scheduleDayOfWeek - thisMonthTargetDayOfWeek
      const thisMonthTargetDay = date.addDays(todayMonthTargetWeekDay, diffDays)
      if (today.getMonth() === thisMonthTargetDay.getMonth()) {
        return thisMonthTargetDay
      }
    }

    if (todayWeekOfMonth === scheduleWeekOfMonth) {
      if (todayWeek <= scheduleDayOfWeek) {
        const diffDays = scheduleDayOfWeek - todayWeek
        const targetDate = date.addDays(today, diffDays)
        if (today.getMonth() === targetDate.getMonth()) {
          return date.addDays(date.endOfDay(targetDate), -1)
        }
      }
    }

    const nextMonthFirstDay = startOfMonth(addMonths(today, 1))
    const nextMonthTargetWeekDay = addWeeks(
      nextMonthFirstDay,
      scheduleWeekOfMonth - 1,
    )
    const nextMonthTargetDayOfWeek =
      nextMonthTargetWeekDay.getDay() !== 0
        ? nextMonthTargetWeekDay.getDay()
        : DayOfWeekEnum.Sunday
    if (nextMonthTargetDayOfWeek <= scheduleDayOfWeek) {
      const diffDays = scheduleDayOfWeek - nextMonthTargetDayOfWeek
      return date.addDays(nextMonthTargetWeekDay, diffDays)
    } else {
      const diffDays = 7 - (nextMonthTargetDayOfWeek - scheduleDayOfWeek)
      return date.addDays(nextMonthTargetWeekDay, diffDays)
    }
  }

  // 頻度=一度だけ
  if (schedule.frequency === ScheduleFrequencyEnum.Once) {
    return yesterday
  }

  // 頻度=カスタム
  if (schedule.frequency === ScheduleFrequencyEnum.Custom) {
    return yesterday
  }
  // いずれにもマッチしない場合
  return yesterday
}

const disabledPastToday = (
  current: moment.Moment | null,
  schedule: ScheduleForm,
) => {
  if (current === null) return false
  const currentDate = current.toDate()
  return currentDate < getStartReportDate(schedule)
}

const SchedulePeriodRow: React.FC<TBaseFormRowProps> = ({
  setFieldValue,
  values,
  errors,
  submitCount,
}) => {
  const { frequency, visibleEndReportDate, startReportDate, endReportDate } =
    values

  const onEndReportDateVisibilityChange = () =>
    setFieldValue('visibleEndReportDate', !visibleEndReportDate)
  const onStartReportDateChange = (date: Moment | null) => {
    setFieldValue('startReportDate', date?.toDate())
  }
  const onEndReportDateChange = (date: Moment | null) =>
    setFieldValue('endReportDate', date?.toDate())

  const shouldDisableEndReportDatePicker = (current: Moment) =>
    current.toDate() <= date.addDays(date.today(), 0)

  return (
    <FormRow>
      <FormLabel>
        <Text strong>日付</Text>
      </FormLabel>
      <CheckboxArea>
        {frequency !== ScheduleFrequencyEnum.Once && (
          <>
            <Checkbox
              checked={visibleEndReportDate}
              onChange={onEndReportDateVisibilityChange}
            />
            <CheckboxText>終了日を設定する</CheckboxText>
          </>
        )}
      </CheckboxArea>
      <TimePickerArea>
        <div>
          <MomentDatePicker
            disabledDate={current => disabledPastToday(current, values)}
            format={dateFormat}
            value={startReportDate && moment(startReportDate)}
            onChange={onStartReportDateChange}
            placeholder="開始日"
          />
          <div>
            {submitCount > 0 && (
              <FormikErrorMessage name="startReportDate" errors={errors} />
            )}
          </div>
        </div>
        {visibleEndReportDate ? (
          <>
            <TimePickerMargin>〜</TimePickerMargin>
            <div>
              <MomentDatePicker
                disabledDate={shouldDisableEndReportDatePicker}
                format={dateFormat}
                value={endReportDate && moment(endReportDate)}
                onChange={onEndReportDateChange}
                placeholder="終了日"
              />
              <div>
                {submitCount > 0 && (
                  <FormikErrorMessage name="endReportDate" errors={errors} />
                )}
              </div>
            </div>
          </>
        ) : (
          <TimePickerMargin>から開始</TimePickerMargin>
        )}
      </TimePickerArea>
    </FormRow>
  )
}

export default SchedulePeriodRow
