import { Schedule } from '@ulysses-inc/harami_api_client'
import { Checkbox } from 'antd'
import { Dispatch, FC, SetStateAction } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'src/state/store'
import {
  HeaderRow,
  SchedulesHeader,
  SchedulesHeaderContainer,
  SchedulesHeaderWrap,
  TableBody,
} from './ScheduleList.styled'
import ScheduleListRow from './ScheduleListRow'

export type TChooseScheduleUUIDsState = {
  chooseScheduleUUIDsState: [string[], Dispatch<SetStateAction<string[]>>]
}

const ScheduleListContent: FC<TChooseScheduleUUIDsState> = ({
  chooseScheduleUUIDsState,
}) => {
  const [chooseScheduleUUIDs, setChooseScheduleUUIDs] = chooseScheduleUUIDsState

  const schedules = useSelector(
    (state: RootState) => state.schedulesState.schedules.schedules,
  )

  const isCheckedAll = () => {
    if (schedules.length === 0) return false
    const unChecked = schedules.filter(
      ({ uuid }) => !(uuid && chooseScheduleUUIDs.some(v => v === uuid)),
    )
    return unChecked.length === 0
  }

  const handleCheckAll = (checked: boolean) => {
    if (checked) {
      const uuids = schedules
        .map(({ uuid }) => uuid ?? '')
        .filter(uuid => uuid !== '')
      setChooseScheduleUUIDs(uuids)
    } else {
      setChooseScheduleUUIDs([])
    }
  }

  return (
    <SchedulesHeaderContainer justify="center">
      <SchedulesHeaderWrap>
        <SchedulesHeader>
          <HeaderRow theme={{ width: '3%' }}>
            <Checkbox
              checked={isCheckedAll()}
              onChange={e => handleCheckAll(e.target.checked)}
            />
          </HeaderRow>
          <HeaderRow theme={{ width: '35%' }}>スケジュール名</HeaderRow>
          <HeaderRow theme={{ width: '35%' }}>ひな形名</HeaderRow>
          <HeaderRow theme={{ width: '10%' }}>ステータス</HeaderRow>
          <HeaderRow theme={{ width: '10%' }}>開始日</HeaderRow>
          <HeaderRow theme={{ width: '10%' }}>終了日</HeaderRow>
        </SchedulesHeader>
        <TableBody
          itemLayout="horizontal"
          dataSource={schedules}
          renderItem={(schedule: Schedule) => (
            <ScheduleListRow {...{ schedule, chooseScheduleUUIDsState }} />
          )}
        />
      </SchedulesHeaderWrap>
    </SchedulesHeaderContainer>
  )
}

export default ScheduleListContent
