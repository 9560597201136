import { AppRoleEnum, User } from '@ulysses-inc/harami_api_client'
import { useFormik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { localStorageKeys } from 'src/constants/localStorageKeys'
import { UUID } from 'src/exShared/util/uuid'
import Yup from 'src/features/validation/yup'
import { addUser, updateUser } from 'src/state/ducks/users/actions'
import { RootState } from 'src/state/store'
import { isNullish } from 'src/util/isNullish'
import { UserForm } from './EditUserLegacy'
import {
  isAllCharsSame,
  validateLoginIdPolicy,
  validatePasswordPolicy,
} from './EditUserLegacy.validation'

const exists = (val: string): boolean => {
  return !isNullish(val) && val !== ''
}

const LOGIN_ID_POLICY_MSG = '6文字以上の英数字記号で設定してください'
const PASSWORD_POLICY_MSG = '8文字以上の英数字記号で設定してください'
const PASSWORD_LOGIN_ID_MATCH_MSG =
  'ログインIDとパスワードに同じものは登録できません'
const ALL_SAME_CHARS_MSG = '2種類以上の文字を使用してください'

const commonSchema = Yup.object().shape({
  givenName: Yup.string()
    .required()
    .matches(/^[^\s]*$/, '名に半角スペースは使用できません')
    .label('名'),
  familyName: Yup.string()
    .required()
    .matches(/^[^\s]*$/, '姓に半角スペースは使用できません')
    .label('姓'),
  givenNameKana: Yup.string().matches(
    /^[^\s]*$/,
    '名(フリガナ)に半角スペースは使用できません',
  ),
  familyNameKana: Yup.string().matches(
    /^[^\s]*$/,
    '姓(フリガナ)に半角スペースは使用できません',
  ),
  loginId: Yup.string()
    .required()
    .test(
      'login-id-policy',
      ({ label }) => `${label}は${LOGIN_ID_POLICY_MSG}`,
      value => validateLoginIdPolicy(value ?? ''),
    )
    .test(
      'login-id-same',
      ({ label }) => `${label}は${ALL_SAME_CHARS_MSG}`,
      value => !isAllCharsSame(value ?? ''),
    )
    .label('ログインID'),
  email: Yup.string().email().required().label('メールアドレス'),
  role: Yup.number().required().label('権限'),
  groupIds: Yup.array(),
  placeNodeIds: Yup.array(),
})

export const createValidationSchema = commonSchema.concat(
  Yup.object().shape({
    password: Yup.string()
      .label('パスワード')
      .required()
      .test(
        'password-policy',
        ({ label }) => `${label}は${PASSWORD_POLICY_MSG}`,
        value => validatePasswordPolicy(value ?? ''),
      )
      .test(
        'password-same',
        ({ label }) => `${label}は${ALL_SAME_CHARS_MSG}`,
        value => !isAllCharsSame(value ?? ''),
      )
      .test(
        'password-match-login-id',
        PASSWORD_LOGIN_ID_MATCH_MSG,
        (value, ctx) => ctx.parent?.loginId !== value,
      ),
  }),
)

export const updateValidationSchema = commonSchema.concat(
  Yup.object().shape({
    newPassword: Yup.string()
      .test(
        'password-policy',
        ({ label }) => `${label}は${PASSWORD_POLICY_MSG}`,
        value => (value ? validatePasswordPolicy(value) : true), // 入力値がある場合のみバリデーションを有効とする
      )
      .test(
        'password-same',
        ({ label }) => `${label}は${ALL_SAME_CHARS_MSG}`,
        value => (value ? !isAllCharsSame(value ?? '') : true), // 入力値がある場合のみバリデーションを有効とする
      )
      .test(
        'password-match-login-id',
        PASSWORD_LOGIN_ID_MATCH_MSG,
        (value, ctx) => (value ? ctx.parent?.loginId !== value : true), // 入力値がある場合のみバリデーションを有効とする
      )
      .label('新しいパスワード'),
    newPasswordConfirm: Yup.string()
      .when('newPassword', {
        is: exists,
        then: schema =>
          schema
            .required()
            .test(
              'no-match-new-password',
              '新しいパスワードと異なります',
              (value, ctx) => ctx.parent?.newPassword === value,
            ),
      })
      .label('新しいパスワード(確認用)'),
    currentPassword: Yup.string()
      .when('newPassword', {
        is: exists,
        then: schema => schema.required(),
      })
      .label('現在のパスワード'),
  }),
)

export const useFormikProps = (isEdit: boolean) => {
  const activeUser = useSelector(({ usersState }: RootState) => {
    const activeUserId = usersState.users.activeUserId
    return activeUserId
      ? usersState.users.users.find(({ uuid }) => uuid === activeUserId)
      : undefined
  })
  const placeNodes = useSelector(
    ({ placesState }: RootState) => placesState.placeNodes.nodes,
  )

  const dispatch = useDispatch()
  const dispatchUpdateUser = (
    user: User,
    currentPassword: string,
    isEdit: boolean,
  ) => {
    if (!isEdit) {
      dispatch(addUser(user))
    } else {
      if (user.uuid !== undefined) {
        dispatch(updateUser(user.uuid, user, currentPassword))
      }
    }
  }

  return useFormik<UserForm>({
    initialValues: {
      id: activeUser?.id,
      uuid: activeUser?.uuid ?? UUID(),
      familyName: activeUser?.name?.split(' ')[0] ?? '',
      givenName: activeUser?.name?.split(' ')[1] ?? '',
      familyNameKana: activeUser?.nameKana?.split(' ')[0] ?? '',
      givenNameKana: activeUser?.nameKana?.split(' ')[1] ?? '',
      loginId: activeUser?.loginId ?? '',
      email: activeUser?.email ?? '',
      currentPassword: '',
      newPassword: '',
      newPasswordConfirm: '',
      password: '',
      role: activeUser?.role?.role ?? AppRoleEnum.REPORTER,
      groupIds: activeUser?.userGroups?.map(({ id }) => `${id ?? ''}`) ?? [],
      placeNodeIds: activeUser?.placeNodes?.map(({ uuid }) => uuid) ?? [],
    },
    validationSchema: isEdit ? updateValidationSchema : createValidationSchema,
    onSubmit: (form: UserForm) => {
      const {
        id,
        uuid,
        familyName,
        familyNameKana,
        givenName,
        givenNameKana,
        role,
        password,
        newPassword,
        currentPassword,
        groupIds,
        placeNodeIds,
      } = form

      if (/ /.test(familyName) || / /.test(givenName)) {
        // 姓名どちらかに空白がある
        return
      }

      dispatchUpdateUser(
        {
          ...form,
          name: `${familyName} ${givenName}`,
          nameKana:
            familyNameKana && givenNameKana
              ? `${familyNameKana} ${givenNameKana}`
              : '',
          password: id ? newPassword : password,
          role: { role },
          userGroups: groupIds.map(strId => ({ id: Number(strId) })) ?? [],
          placeNodes:
            placeNodeIds.map(strId => ({
              id: 0,
              uuid: strId,
              type: 0,
              isRootNode: 0,
              parentNodes: [],
              nodes: [],
            })) ?? [],
        },
        currentPassword ?? '',
        // 新規作成の場合`formikBag.activeUser?`が`undefined`になり、以下条件が`false`になる
        activeUser?.uuid === uuid,
      )
      if (
        localStorage.getItem(localStorageKeys.loginUserUuid) ===
        activeUser?.uuid
      ) {
        localStorage.setItem(
          localStorageKeys.loginUserName,
          `${familyName} ${givenName}`,
        )
        localStorage.setItem(localStorageKeys.loginUserRole, role.toString())
        const updatePlaceNodes = placeNodes.filter(({ uuid }) =>
          placeNodeIds.some(placeNodeUUID => placeNodeUUID === uuid),
        )
        localStorage.setItem(
          localStorageKeys.loginUserPlaceNodes,
          JSON.stringify(updatePlaceNodes),
        )
      }
    },
  })
}
