import { MultipleChoice } from '@ulysses-inc/harami_api_client'
import { FC, useState } from 'react'
import { TMultipleChoicesState } from './AddMultipleChoiceDrawer'
import { ResponseInput } from './AddMultipleChoiceDrawer.styled'

const ResponseColumn: FC<{
  lastKey: number
  addNewMultipleChoice: () => void
  multipleChoicesState: TMultipleChoicesState
  multipleChoice: MultipleChoice
}> = ({
  lastKey,
  addNewMultipleChoice,
  multipleChoicesState: [multipleChoices, setMultipleChoices],
  multipleChoice,
}) => {
  const [keyDownCode, setKeyDownCode] = useState(0)

  const onEnterKeyUp = (multipleChoiceId: number) => {
    if (lastKey === multipleChoiceId) {
      addNewMultipleChoice()
    }
  }

  const multipleChoiceId = multipleChoice.id ?? 0

  return (
    <ResponseInput
      onChange={e => {
        setMultipleChoices({
          ...multipleChoices,
          [`${multipleChoiceId}`]: {
            ...multipleChoice,
            response: e.target.value,
          },
        })
      }}
      defaultValue={multipleChoice.response}
      onKeyDown={e => setKeyDownCode(e.which)}
      onKeyUp={e => {
        // FIXME `which` is deprecated
        if (e.which === 13 && e.which === keyDownCode) {
          onEnterKeyUp(multipleChoiceId)
        }
      }}
    />
  )
}

export default ResponseColumn
