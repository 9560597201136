import {
  GetMultipleChoiceSets,
  GetMultipleChoiceSetsRequest,
  MultipleChoiceSet,
} from '@ulysses-inc/harami_api_client'
import { ActionTypes } from './types'

export const getMultipleChoiceSets = (
  request: GetMultipleChoiceSetsRequest,
) => {
  return {
    type: ActionTypes.REQUEST_GET_MULTIPLE_CHOICE_SETS,
    isLoading: true,
    request,
  }
}

const getSuccessMultipleChoiceSets = ({
  multipleChoiceSets,
  count,
}: GetMultipleChoiceSets) => {
  return {
    type: ActionTypes.SUCCESS_GET_MULTIPLE_CHOICE_SETS,
    isLoading: false,
    multipleChoiceSets,
    count,
  }
}

const getErrorMultipleChoiceSets = (error: Error) => {
  return {
    type: ActionTypes.ERROR_GET_MULTIPLE_CHOICE_SETS,
    isLoading: false,
    error,
  }
}

export const changeMultipleChoiceSetsPage = (
  page: number,
  pageSize?: number,
) => {
  const offset = pageSize ? (page - 1) * pageSize : 0
  return {
    type: ActionTypes.REQUEST_CHANGE_MULTIPLE_CHOICE_SETS_PAGE,
    isLoading: true,
    request: {
      limit: pageSize,
      offset,
    },
  }
}

export const changeMultipleChoiceSetsSize = (pageSize: number) => {
  return {
    type: ActionTypes.REQUEST_CHANGE_MULTIPLE_CHOICE_SETS_SIZE,
    isLoading: true,
    request: {
      limit: pageSize,
      offset: 0,
    },
  }
}

const deleteMultipleChoiceSet = (multipleChoiceSetId: string) => {
  return {
    type: ActionTypes.REQUEST_DELETE_MULTIPLE_CHOICE_SET,
    isLoading: true,
    multipleChoiceSetId,
  }
}

const deleteSuccessMultipleChoiceSet = (multipleChoiceSetId: string) => {
  return {
    type: ActionTypes.SUCCESS_DELETE_MULTIPLE_CHOICE_SET,
    isLoading: false,
    multipleChoiceSetId,
  }
}

const deleteErrorMultipleChoiceSet = (error: Error) => {
  return {
    type: ActionTypes.ERROR_DELETE_MULTIPLE_CHOICE_SET,
    isLoading: false,
    error,
  }
}

export type MultipleChoiceSetsAction =
  | ReturnType<typeof getMultipleChoiceSets>
  | ReturnType<typeof getSuccessMultipleChoiceSets>
  | ReturnType<typeof getErrorMultipleChoiceSets>
  | ReturnType<typeof changeMultipleChoiceSetsPage>
  | ReturnType<typeof changeMultipleChoiceSetsSize>
  | ReturnType<typeof deleteMultipleChoiceSet>
  | ReturnType<typeof deleteSuccessMultipleChoiceSet>
  | ReturnType<typeof deleteErrorMultipleChoiceSet>

export const getMultipleChoiceSet = (multipleChoiceSetId: string) => {
  return {
    type: ActionTypes.REQUEST_GET_MULTIPLE_CHOICE_SET,
    isLoading: true,
    multipleChoiceSetId,
  }
}

const getSuccessMultipleChoiceSet = (multipleChoiceSet: MultipleChoiceSet) => {
  return {
    type: ActionTypes.SUCCESS_GET_MULTIPLE_CHOICE_SET,
    isLoading: false,
    multipleChoiceSet,
  }
}

const getErrorMultipleChoiceSet = (error: Error) => {
  return {
    type: ActionTypes.ERROR_GET_MULTIPLE_CHOICE_SET,
    isLoading: false,
    error,
  }
}

export const addMultipleChoiceSet = (multipleChoiceSet: MultipleChoiceSet) => {
  return {
    type: ActionTypes.REQUEST_ADD_MULTIPLE_CHOICE_SET,
    isLoading: true,
    multipleChoiceSet,
  }
}

const addSuccessMultipleChoiceSet = () => {
  return {
    type: ActionTypes.SUCCESS_ADD_MULTIPLE_CHOICE_SET,
    isLoading: false,
  }
}

const addErrorMultipleChoiceSet = (error: Error) => {
  return {
    type: ActionTypes.ERROR_ADD_MULTIPLE_CHOICE_SET,
    isLoading: false,
    error,
  }
}

export const updateMultipleChoiceSet = (
  multipleChoiceSetId: string,
  multipleChoiceSet: MultipleChoiceSet,
) => {
  return {
    type: ActionTypes.REQUEST_UPDATE_MULTIPLE_CHOICE_SET,
    isLoading: true,
    multipleChoiceSetId,
    multipleChoiceSet,
  }
}

const updateSuccessMultipleChoiceSet = () => {
  return {
    type: ActionTypes.SUCCESS_UPDATE_MULTIPLE_CHOICE_SET,
    isLoading: false,
  }
}

const updateErrorMultipleChoiceSet = (error: Error) => {
  return {
    type: ActionTypes.ERROR_UPDATE_MULTIPLE_CHOICE_SET,
    isLoading: false,
    error,
  }
}

export const resetMultipleChoiceSet = () => {
  return {
    type: ActionTypes.RESET_MULTIPLE_CHOICE_SET,
  }
}

export type MultipleChoiceSetAction =
  | ReturnType<typeof getMultipleChoiceSet>
  | ReturnType<typeof getSuccessMultipleChoiceSet>
  | ReturnType<typeof getErrorMultipleChoiceSet>
  | ReturnType<typeof addMultipleChoiceSet>
  | ReturnType<typeof addSuccessMultipleChoiceSet>
  | ReturnType<typeof addErrorMultipleChoiceSet>
  | ReturnType<typeof updateMultipleChoiceSet>
  | ReturnType<typeof updateSuccessMultipleChoiceSet>
  | ReturnType<typeof updateErrorMultipleChoiceSet>
  | ReturnType<typeof resetMultipleChoiceSet>

export default {
  getMultipleChoiceSets,
  getSuccessMultipleChoiceSets,
  getErrorMultipleChoiceSets,
  changeMultipleChoiceSetsPage,
  changeMultipleChoiceSetsSize,
  deleteMultipleChoiceSet,
  deleteSuccessMultipleChoiceSet,
  deleteErrorMultipleChoiceSet,
  getMultipleChoiceSet,
  getSuccessMultipleChoiceSet,
  getErrorMultipleChoiceSet,
  addMultipleChoiceSet,
  addSuccessMultipleChoiceSet,
  addErrorMultipleChoiceSet,
  updateMultipleChoiceSet,
  updateSuccessMultipleChoiceSet,
  updateErrorMultipleChoiceSet,
  resetMultipleChoiceSet,
}
