import { PlaceNodeTypeEnum } from '@ulysses-inc/harami_api_client'
import { Input } from 'antd'
import { FC } from 'react'
import { useSelector } from 'react-redux'
import FormikErrorMessage from 'src/components/formikErrorMessage/FormikErrorMessage'
import { RootState } from 'src/state/store'
import { flattenNodes } from './EditPlaceGroup'
import { useFormikProps } from './EditPlaceGroup.hooks'
import { PlaceGroupText, PlaceGroupTitle } from './EditPlaceGroup.styled'

type TProps = Pick<
  ReturnType<typeof useFormikProps>,
  'handleChange' | 'values' | 'errors' | 'touched'
>

const NameRow: FC<TProps> = ({ handleChange, values, touched, errors }) => {
  const nodes = useSelector(
    (state: RootState) => state.placesState.placeGroups.nodes,
  )
  const activeNodeId = useSelector(
    (state: RootState) => state.placesState.placeGroups.activeNodeId,
  )
  const placeGroupNodes = flattenNodes(nodes, []).filter(
    ({ type }) => type === PlaceNodeTypeEnum.PlaceGroup,
  )
  const activeNode = placeGroupNodes.find(({ uuid }) => uuid === activeNodeId)

  return (
    <>
      <PlaceGroupTitle>
        <PlaceGroupText>現場グループ名</PlaceGroupText>
      </PlaceGroupTitle>
      <Input
        onChange={handleChange('name')}
        defaultValue={activeNode?.placeGroup?.name ?? ''}
        value={values.name}
      />
      {/*
        FIXME formik.ErrorMessage は useFormik と一緒に使えないので置き換えたが、うまく動作していない模様
        errors がそもそもここまで到達していないし、到達していたとしてもすぐ re-render されてしまい
        エラーとして表示されるのは一瞬になる
      */}
      <FormikErrorMessage name="name" errors={errors} touched={touched} />
    </>
  )
}

export default NameRow
