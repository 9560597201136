import { Modal } from 'antd'
import { ModalDeleteButtonColor } from 'src/features/theme/KdsThemeColor'

type DeleteConfirmDialogType = (props: {
  title?: React.ReactNode
  content?: React.ReactNode
  onOk: (close: () => void) => void
  onCancel?: () => void
}) => ReturnType<typeof Modal.confirm>

const DeleteConfirmDialog: DeleteConfirmDialogType = ({
  title = '削除してよろしいですか？',
  content = '',
  onOk,
  onCancel,
}) => {
  return Modal.confirm({
    title,
    content,
    okText: '削除',
    cancelText: 'キャンセル',
    onOk,
    onCancel,
    okButtonProps: {
      style: {
        backgroundColor: ModalDeleteButtonColor,
        border: 'none',
      },
    },
  })
}
export default DeleteConfirmDialog
