import { CopyOutlined, FileAddOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import { Dispatch, FC, SetStateAction, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { GetTemplatesFilter } from 'src/exShared/types/types'
import { flattenNodePath } from 'src/exShared/util/place/flattenNodePath'
import { getUniqueFlattenNodes } from 'src/exShared/util/place/getUniqueFlattenNodes'
import { flattenNodes } from 'src/exShared/util/place/placeNode'
import { getFilter } from 'src/exShared/util/template/getFilter'
import {
  copyTemplates,
  getTemplates,
} from 'src/state/ducks/templateList/actions'
import { RootState } from 'src/state/store'
import { useListFilter } from '../../../hooks/filter/useListFilter'
import {
  ActionRow,
  CopyButton,
  DropDownArea,
  StyledTemplatesSortDropDown,
} from './Templates.styled'
import TemplatesFilterDropDown from './TemplatesFilterDropDown'
import { templatesSortOrderStorage } from './templatesSortOrder'

const Actions: FC<{
  selectedTemplateIdsState: [Set<number>, Dispatch<SetStateAction<Set<number>>>]
}> = ({
  selectedTemplateIdsState: [selectedTemplateIds, setSelectedTemplateIds],
}) => {
  const history = useHistory()
  const {
    templateListState: { request, filter },
    placesState: {
      places: { places },
      placeGroups: { nodes: placeGroups },
    },
  } = useSelector((state: RootState) => state)

  const placeNodes = getUniqueFlattenNodes([
    ...flattenNodes(placeGroups),
    ...places,
  ])
  const flattenedNodePath = flattenNodePath([...placeNodes, ...placeGroups], [])

  const dispatch = useDispatch()

  const createTemplatePages = () => {
    history.push('/templates/create/industry')
  }

  const { filters } = useListFilter()
  const templatesSortOrder = templatesSortOrderStorage.get() ?? undefined

  const [sortColumnName, setSortColumn] = useState<string>(
    templatesSortOrder?.sortColumn ?? '',
  )
  const [orderName, setOrder] = useState<string>(
    templatesSortOrder?.sortOrder ?? 'asc',
  )

  const initFilter = {
    templateName: filters?.templates?.filter?.templateName,
    placeNodeIds: filters?.templates?.filter?.placeNodeIds,
    placeGroupNodeIds: filters?.templates?.filter?.placeGroupNodeIds,
  }

  const setSortCondition = (filter: GetTemplatesFilter | undefined) => {
    if (filter) {
      filter.sortColumn = { $in: sortColumnName }
      filter.sortOrder = { $in: orderName }
    }
    return filter
  }

  const getFilterTemplates = (filter: GetTemplatesFilter | undefined) => {
    dispatch(getTemplates(request, setSortCondition(filter)))
  }

  const sortFilter = (sortColumn: string, sortOrder: string) => {
    return {
      templateName: filters?.templates?.filter?.templateName,
      placeNodeIds: filters?.templates?.filter?.placeNodeIds,
      placeGroupNodeIds: filters?.templates?.filter?.placeGroupNodeIds,
      sortColumn,
      sortOrder,
    }
  }

  const getSortedTemplates = (
    isClear: boolean,
    sortColumn: string,
    sortOrder: string,
  ) => {
    templatesSortOrderStorage.set({
      sortColumn,
      sortOrder,
    })
    setSortColumn(sortColumn)
    setOrder(sortOrder)
    dispatch(
      getTemplates(
        request,
        getFilter(
          placeNodes,
          flattenedNodePath,
          isClear ? initFilter : sortFilter(sortColumn, sortOrder),
        ),
      ),
    )
  }

  const onClickCopyButton = () => {
    dispatch(copyTemplates(Array.from(selectedTemplateIds), request, filter))
    setSelectedTemplateIds(new Set([]))
  }

  return (
    <ActionRow justify="space-between">
      <DropDownArea>
        <TemplatesFilterDropDown
          places={places}
          placeNodes={placeNodes}
          getFilterTemplates={getFilterTemplates}
          flattenNodePath={flattenedNodePath}
          request={request}
        />
        <StyledTemplatesSortDropDown
          getSortedTemplates={getSortedTemplates}
          templatesSortOrder={templatesSortOrder}
        />
      </DropDownArea>
      <div>
        <CopyButton
          disabled={!selectedTemplateIds.size}
          onClick={onClickCopyButton}
          type="default"
        >
          <CopyOutlined />
          コピー
        </CopyButton>
        <Button onClick={createTemplatePages} type="primary">
          <FileAddOutlined />
          ひな形を追加
        </Button>
      </div>
    </ActionRow>
  )
}

export default Actions
