import {
  ReportStatusEnum,
  TemplateLayoutTypeEnum,
} from '@ulysses-inc/harami_api_client'
import { ReportResultSchema } from 'src/exShared/types/types'
import date from 'src/exShared/util/date'
import { ReportResultAction } from '../actions'
import { ActionTypes } from '../types'
import { generateLogicNodeTable } from './grid/utils/generateLogicNodeTable'
import { generateNodePathInfos } from './grid/utils/generateNodePathsInfos'
import { generateVisibleGridColumns } from './grid/utils/generateVisibleGridColumns'

export interface ReportResultState {
  reportId?: string
  templateId: number
  templateImages: { [key: string]: string }
  isLoadingReportResult: boolean
  isLoadingTemplatePages: boolean
  isLoadingAddComment: boolean
  isLoadingReportStatus: boolean
  error: Error | null
  report: ReportResultSchema
}

const initialReportResultState: ReportResultState = {
  reportId: '',
  templateId: -1,
  templateImages: {},
  isLoadingReportResult: false,
  isLoadingTemplatePages: false,
  isLoadingAddComment: false,
  isLoadingReportStatus: false,
  error: null,
  report: {
    id: 0,
    uuid: '',
    name: '',
    place: {},
    templateId: -1,
    templates: [],
    pages: {},
    nodes: {},
    pageIds: [],
    manualMemos: [],
    images: [],
    hints: [],
    comment: '',
    schedules: [],
    assignee: {},
    scorePercentage: 0,
    reportDate: date.today(),
    approvals: [],
    approvalFlows: [],
    attachmentFiles: [],
    status: { status: ReportStatusEnum.INCOMPLETE },
    isInvalid: 0,
    isShowScore: false,
    isEditable: true,
    isExcelConversion: false,
    isHideQuestionOptions: false,
    isAudit: false,
    isKeyboard: false,
    layoutType: TemplateLayoutTypeEnum.Scroll,
    icons: [],
    improve: { uuid: '' },
    linkedIndicatedIssues: [],
    nodePathInfos: {
      uuidPaths: {},
      idPaths: {},
    },
    logicInfos: {
      nodeTable: {},
      visibleGridColumns: {},
    },
  },
}

const reportResult = (
  state: ReportResultState = initialReportResultState,
  action: ReportResultAction,
): ReportResultState => {
  switch (action.type) {
    case ActionTypes.REQUEST_GET_REPORT_RESULT: {
      return {
        ...state,
        isLoadingReportResult: action.isLoading,
        error: null,
        reportId: action.reportId,
        templateImages: {},
      }
    }
    case ActionTypes.SUCCESS_GET_REPORT_RESULT: {
      const { idPaths, uuidPaths } = generateNodePathInfos(
        action.report.pageIds,
        action.report.pages,
        action.report.nodes,
      )
      const logicNodeTable = generateLogicNodeTable(
        action.report.layoutType,
        idPaths,
        action.report.nodes,
      )
      const visibleGridColumns = generateVisibleGridColumns(
        action.report.layoutType,
        action.report.nodes,
        idPaths,
        logicNodeTable,
      )

      return {
        ...state,
        isLoadingReportResult: action.isLoading,
        report: {
          ...action.report,
          nodePathInfos: {
            idPaths,
            uuidPaths,
          },
          logicInfos: {
            nodeTable: logicNodeTable,
            visibleGridColumns,
          },
        },
        templateImages: action.templateImages,
        error: null,
      }
    }
    case ActionTypes.ERROR_GET_REPORT_RESULT: {
      return {
        ...state,
        isLoadingReportResult: action.isLoading,
        error: action.error,
      }
    }
    case ActionTypes.REQUEST_ADD_REPORT_COMMENT: {
      return {
        ...state,
        isLoadingAddComment: action.isLoading,
        reportId: action.reportId,
        error: null,
      }
    }
    case ActionTypes.SUCCESS_ADD_REPORT_COMMENT: {
      return {
        ...state,
        isLoadingAddComment: action.isLoading,
        report: { ...state.report, comment: action.comment },
        error: null,
      }
    }
    case ActionTypes.ERROR_ADD_REPORT_COMMENT: {
      return {
        ...state,
        isLoadingAddComment: action.isLoading,
        error: action.error,
      }
    }
    case ActionTypes.REQUEST_UPDATE_REPORT_STATUS: {
      return {
        ...state,
        isLoadingReportStatus: action.isLoading,
        error: null,
      }
    }
    case ActionTypes.SUCCESS_UPDATE_REPORT_STATUS: {
      return {
        ...state,
        isLoadingReportStatus: action.isLoading,
        error: null,
      }
    }
    case ActionTypes.ERROR_UPDATE_REPORT_STATUS: {
      return {
        ...state,
        isLoadingReportStatus: action.isLoading,
        error: action.error,
      }
    }
    case ActionTypes.CHANGE_REPORT_STATUS: {
      return {
        ...state,
        report: {
          ...state.report,
          status: {
            ...state.report.status,
            status: action.status,
          },
        },
      }
    }
    case ActionTypes.SUCCESS_ADD_REPORT_ATTACHMENT_FILE: {
      return {
        ...state,
        report: {
          ...state.report,
          attachmentFiles: [...state.report.attachmentFiles, action.file],
        },
      }
    }
    case ActionTypes.SUCCESS_DELETE_REPORT_ATTACHMENT_FILE: {
      return {
        ...state,
        report: {
          ...state.report,
          attachmentFiles: state.report.attachmentFiles.filter(
            file => file.uuid !== action.fileId,
          ),
        },
      }
    }
    case ActionTypes.RESET_REPORT_RESULT: {
      return initialReportResultState
    }
    default: {
      return { ...state }
    }
  }
}

export default reportResult
