import { ScheduledReportSummaryFilters } from 'src/features/scheduledReportSummary/useFilters'
import { queryKeyPrefix } from 'src/features/tanStackQuery/queryKeyPrefix'

const prefix = queryKeyPrefix.scheduledReportSummary

export const queryKeys = {
  scheduledReportSummariesWithDaysInfo: (
    filters: ScheduledReportSummaryFilters,
  ) => [...prefix, 'scheduledReportSummariesWithDaysInfo', filters],
}
