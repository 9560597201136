import {
  GetMultipleChoiceSetsRequest,
  MultipleChoiceSet,
} from '@ulysses-inc/harami_api_client'
import Redux from 'redux'
import actions from './actions'

const getMultipleChoiceSets = (
  dispatch: Redux.Dispatch,
  request: GetMultipleChoiceSetsRequest,
) => {
  dispatch(actions.getMultipleChoiceSets(request))
}

const changeMultipleChoiceSetsPage = (
  dispatch: Redux.Dispatch,
  page: number,
  pageSize?: number,
) => {
  dispatch(actions.changeMultipleChoiceSetsPage(page, pageSize))
}

const changeMultipleChoiceSetsSize = (
  dispatch: Redux.Dispatch,
  pageSize: number,
) => {
  dispatch(actions.changeMultipleChoiceSetsSize(pageSize))
}

const getMultipleChoiceSet = (
  dispatch: Redux.Dispatch,
  multipleChoiceSetId: string,
) => {
  dispatch(actions.getMultipleChoiceSet(multipleChoiceSetId))
}

const deleteMultipleChoiceSet = (
  dispatch: Redux.Dispatch,
  multipleChoiceSetId: string,
) => {
  dispatch(actions.deleteMultipleChoiceSet(multipleChoiceSetId))
}

const addMultipleChoiceSet = (
  dispatch: Redux.Dispatch,
  multipleChoiceSet: MultipleChoiceSet,
) => {
  dispatch(actions.addMultipleChoiceSet(multipleChoiceSet))
}

const updateMultipleChoiceSet = (
  dispatch: Redux.Dispatch,
  multipleChoiceSetId: string,
  multipleChoiceSet: MultipleChoiceSet,
) => {
  dispatch(
    actions.updateMultipleChoiceSet(multipleChoiceSetId, multipleChoiceSet),
  )
}

const resetMultipleChoiceSet = (dispatch: Redux.Dispatch) => {
  dispatch(actions.resetMultipleChoiceSet())
}

export default {
  getMultipleChoiceSets,
  changeMultipleChoiceSetsPage,
  changeMultipleChoiceSetsSize,
  getMultipleChoiceSet,
  deleteMultipleChoiceSet,
  addMultipleChoiceSet,
  updateMultipleChoiceSet,
  resetMultipleChoiceSet,
}
