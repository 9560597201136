import { Spin } from 'antd'
import { SpinSize } from 'antd/lib/spin'
import React, { ReactNode } from 'react'
import styled from 'styled-components'

const SpinOverlay = styled(Spin)`
  font-size: 24px;
  position: fixed !important;
`

interface OwnProps {
  tip?: string
  spinning: boolean
  size?: SpinSize
  render?: ReactNode
  children?: ReactNode
}

const LoadingOverlay: React.FC<OwnProps> = (props: OwnProps) => {
  return <SpinOverlay {...props}>{props.children || props.render}</SpinOverlay>
}

export default LoadingOverlay
