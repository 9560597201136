import { css } from 'styled-components'

export const CellFontSize = 12
export const CellLineHeight = 17
export const CellMaxWidth = 120
export const CellMinWidth = 80

export const commonCellStyles = css`
  padding: 8px 8px;
  /*
    TODO: 可変は難しそうだったので一旦固定の幅にしている。改修は下記チケットで対応する。
    https://kaminashi.atlassian.net/browse/HPB-2788
  */
  max-width: ${CellMaxWidth}px;
  min-width: ${CellMinWidth}px;
`

export const bodyFontStyles = css`
  font-weight: 500;
  font-size: ${CellFontSize}px;
  line-height: ${CellLineHeight}px;
`
