import { Layout, Row } from 'antd'
import styled from 'styled-components'

const { Content } = Layout

export const CompanySettingsPageContent = styled(Content)`
  margin: 2rem;
  max-width: 400px;
`

export const FormRow = styled(Row)`
  margin-top: 5px;
  margin-bottom: 20px;
`
export const FormLabel = styled.div`
  margin-top: 15px;
  margin-bottom: 5px;
  width: 100%;
`

export const ToggleWrap = styled.div`
  display: flex;
  margin-top: 10px;
  margin-right: 24px;
`

export const SwitchWrap = styled.div`
  margin-right: 10px;
`

export const ButtonWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`
