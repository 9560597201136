import { Text as ReadPDFText, StyleSheet, View } from '@react-pdf/renderer'
import React from 'react'
import Grid from '../../Grid'

const styles = StyleSheet.create({
  titleLabel: {
    padding: 5,
    fontSize: 8,
    margin: 'auto',
    flexDirection: 'row',
    color: '#656565',
  },
  tableCell: {
    margin: 'auto',
    padding: 5,
    flexDirection: 'row',
  },
  optionTextMemo: {
    width: '100%',
  },
})

interface OwnProps {
  memo: string
}

const OptionTextMemo: React.FC<OwnProps> = (props: OwnProps) => {
  return (
    <Grid borderLeft borderRight style={styles.optionTextMemo}>
      <View>
        <ReadPDFText style={styles.titleLabel}>- テキストメモ</ReadPDFText>
        <ReadPDFText style={styles.tableCell}>{props.memo}</ReadPDFText>
      </View>
    </Grid>
  )
}

export default OptionTextMemo
