import { TemplateNodeSchema } from '@ulysses-inc/harami_api_client'

/**
 * ノード群の中に指定したUUIDのノードが存在するかどうか
 */
export const isExistsNode = (
  nodes: TemplateNodeSchema[],
  nodeUUID?: string,
): boolean => {
  if (!nodeUUID) {
    return false
  }
  const findIndex = nodes.findIndex(node => node.uuid === nodeUUID)
  return findIndex >= 0
}
