import { Typography } from 'antd'
import { Collapse } from 'react-collapse'
import {
  Primary,
  SectionBackgroundColor,
  SectionColor,
  White,
} from 'src/features/theme/KdsThemeColor'
import styled from 'styled-components'

export const { Text } = Typography

export const SectionWrapper = styled.div`
  align-items: center;
  display: flex;
  height: 46px;
`

export const SectionOpenButtonWrapper = styled.div`
  margin-left: 15px;
`

export const SectionNameParagraphWrapper = styled.div`
  flex: 1;
`

export const SectionNameParagraph = styled.div`
  width: 100%;
  padding-left: 10px;
  color: ${SectionColor};
`

export const Table = styled.div`
  display: table;
  border-collapse: collapse;
  height: 46px;
  width: 100%;
  line-height: 46px;
`

export const TableCell = styled.div`
  display: table-cell;
  border: 1px solid rgba(0, 0, 0, 0.12);
  background: ${SectionBackgroundColor};
`

export const DraggingPlaceholder = styled.div`
  display: flex;
  height: 46px;
  line-height: 46px;
  width: 100%;
  background: ${Primary};
`

export const DragHandle = styled.div`
  margin-left: 0.7rem;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  width: 2rem;
  height: 100%;
  z-index: 1;
  outline: none;
  border-radius: 50%;
  cursor: grab;
`

export const BasicTableCell = styled(TableCell)`
  min-width: ${({ theme }) => theme.minWidth};
  color: ${White};
`

export const SectionTableCell = styled(TableCell)`
  width: ${({ theme }) => theme.width};
`

export const SectionContentWrapper = styled(Collapse)`
  border: 0;
`

export const ColumnText = styled(Text)`
  margin-left: 10px;
  color: ${SectionColor};
`

export const CheckBoxText = styled.span`
  color: ${SectionColor};
`

export const ConfirmModalContentList = styled.ul`
  max-height: 220px;
  padding-left: 20px;
  padding-bottom: 30px;
  overflow-y: scroll;
`

export const HandleIcon = () => {
  return (
    <svg viewBox="0 0 24 24" width="24" height="24" focusable="false">
      <path fill="none" d="M0 0h24v24H0V0z"></path>
      <path
        fill={White}
        d="M11 18c0 1.1-.9 2-2 2s-2-.9-2-2 .9-2 2-2 2 .9 2 2zm-2-8c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0-6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm6 4c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"
      ></path>
    </svg>
  )
}
