import {
  TemplateNodeSchema,
  TemplatePageSchema,
  TemplateQuestionExcelConversionTypes,
} from '@ulysses-inc/harami_api_client'
import { moveTemplateNode } from 'src/state/ducks/templates/actions'
import { TemplatePagesState } from 'src/state/ducks/templates/templatePages/reducer'

/**
 * 型エラーやコードの構造があまりにも邪悪なため、一時的に廊下に立たされている関数です。
 * 直ったらまた教室(reducer.ts)に戻ってきなさい。
 */
export const moveTemplateNodeReducer = (
  state: TemplatePagesState,
  action: ReturnType<typeof moveTemplateNode>,
) => {
  const templatePages = state.templatePages
  const templateNodes = state.templateNodes
  if (action.isSameParent) {
    if (!action.moveNodeEvent.dragNodeParent) {
      const originalParent: TemplatePageSchema =
        state.templatePages[state.activePageId]
      const changeNodes = (originalParent?.nodes || []).filter(
        nodeId => nodeId !== action.moveNodeEvent.dragNode.id,
      )
      const dropItemPosition = changeNodes.findIndex(
        nodeId => nodeId === action.moveNodeEvent.dropNode.id,
      )
      const insertPosition =
        action.moveNodeEvent.dropPosition === 'TOP'
          ? dropItemPosition
          : dropItemPosition + 1
      changeNodes.splice(insertPosition, 0, action.moveNodeEvent.dragNode.id)
      if (state.activePageId in templatePages) {
        templatePages[state.activePageId].nodes = [...changeNodes]
      }
    } else {
      const originalParent: TemplateNodeSchema =
        state.templateNodes[action.moveNodeEvent.dragNodeParent.id]
      const changeNodes = (originalParent?.nodes || []).filter(
        nodeId => nodeId !== action.moveNodeEvent.dragNode.id,
      )
      const dropItemPosition = changeNodes.findIndex(
        nodeId => nodeId === action.moveNodeEvent.dropNode.id,
      )
      const insertPosition =
        action.moveNodeEvent.dropPosition === 'TOP'
          ? dropItemPosition
          : dropItemPosition + 1
      changeNodes.splice(insertPosition, 0, action.moveNodeEvent.dragNode.id)
      if (action.moveNodeEvent.dragNodeParent.id in templateNodes) {
        templateNodes[action.moveNodeEvent.dragNodeParent.id].nodes = [
          ...changeNodes,
        ]
      }
    }
  } else {
    if (!action.moveNodeEvent.dragNodeParent) {
      const originalParent: TemplatePageSchema =
        state.templatePages[state.activePageId]
      const changeOriginalNodes = (originalParent?.nodes || []).filter(
        nodeId => nodeId !== action.moveNodeEvent.dragNode.id,
      )
      if (state.activePageId in templatePages) {
        templatePages[state.activePageId].nodes = [...changeOriginalNodes]
      }
    } else {
      const originalParent: TemplateNodeSchema =
        state.templateNodes[action.moveNodeEvent.dragNodeParent.id]
      const changeOriginalNodes = (originalParent?.nodes || []).filter(
        nodeId => nodeId !== action.moveNodeEvent.dragNode.id,
      )
      if (action.moveNodeEvent.dragNodeParent.id in templateNodes) {
        templateNodes[action.moveNodeEvent.dragNodeParent.id].nodes = [
          ...changeOriginalNodes,
        ]
      }
    }

    if (!action.moveNodeEvent.dropNodeParent) {
      const newParent: TemplatePageSchema =
        state.templatePages[state.activePageId]
      const dropItemPosition = (newParent?.nodes || []).findIndex(
        nodeId => nodeId === action.moveNodeEvent.dropNode.id,
      )
      const insertPosition =
        action.moveNodeEvent.dropPosition === 'TOP'
          ? dropItemPosition
          : dropItemPosition + 1
      const changeNewNodes = newParent?.nodes || []
      changeNewNodes.splice(insertPosition, 0, action.moveNodeEvent.dragNode.id)
      if (state.activePageId in templatePages) {
        templatePages[state.activePageId].nodes = [...changeNewNodes]
      }

      if (
        templateNodes[action.moveNodeEvent.dragNode.id].question
          ?.excelConversionTypes
      ) {
        templateNodes[
          action.moveNodeEvent.dragNode.id
        ].question.excelConversionTypes = templateNodes[
          action.moveNodeEvent.dragNode.id
        ].question.excelConversionTypes?.filter(
          (type: TemplateQuestionExcelConversionTypes) =>
            type.excelConversionType !== 4, // TODO: yamlが別ブランチにあるので後で置き換え
        )
      }
    } else {
      const newParent: TemplateNodeSchema =
        state.templateNodes[action.moveNodeEvent.dropNodeParent.id]
      const dropItemPosition =
        newParent?.nodes?.findIndex(
          nodeId => nodeId === action.moveNodeEvent.dropNode.id,
        ) ?? 0
      const insertPosition =
        action.moveNodeEvent.dropPosition === 'TOP'
          ? dropItemPosition
          : dropItemPosition + 1
      const changeNewNodes = newParent?.nodes || []
      changeNewNodes.splice(insertPosition, 0, action.moveNodeEvent.dragNode.id)

      if (action.moveNodeEvent.dropNodeParent.id in templateNodes) {
        templateNodes[action.moveNodeEvent.dropNodeParent.id].nodes = [
          ...changeNewNodes,
        ]
      }

      if (newParent.section?.isRepeat === 1) {
        if (
          templateNodes[action.moveNodeEvent.dragNode.id].question
            ?.excelConversionTypes
        ) {
          templateNodes[
            action.moveNodeEvent.dragNode.id
          ].question.excelConversionTypes = templateNodes[
            action.moveNodeEvent.dragNode.id
          ].question.excelConversionTypes?.filter(
            (type: TemplateQuestionExcelConversionTypes) =>
              type.excelConversionType === 4, // TODO: yamlが別ブランチにあるので後で置き換え
          )
        }
      }
    }
  }
  return {
    ...state,
    templateNodes: templateNodes,
    templatePages: templatePages,
    isDirty: true,
  }
}
