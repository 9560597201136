import {
  ReportNodeSchema,
  TemplateNodeTypeEnum,
} from '@ulysses-inc/harami_api_client'
import React from 'react'
import { WebReportResultProps } from 'src/features/reports/result/WebReportResultProps'
import { Question } from './Question'
import { Section } from './Section'

interface OwnProps {
  node: ReportNodeSchema
}

const Logic: React.FC<OwnProps & WebReportResultProps> = (
  props: OwnProps & WebReportResultProps,
) => {
  return (
    <>
      {props.getReportNodesNodes(props.node).map((node, index) => {
        if (node.type === TemplateNodeTypeEnum.Section) {
          return <Section key={index} {...props} sectionNode={node} />
        }
        if (node.type === TemplateNodeTypeEnum.Question)
          return (
            <Question
              key={`${props.node.id}-question-${index}`}
              {...props}
              node={node}
              question={node.question}
            />
          )
        return <></>
      })}
    </>
  )
}

export default Logic
