import { createSlice } from '@reduxjs/toolkit'

interface GlobalNavigationState {
  isCollapsed: boolean
}

const initialState: GlobalNavigationState = {
  isCollapsed: false,
}

export const globalNavigationSlice = createSlice({
  name: 'harami/globalNavigation',
  initialState,
  reducers: {
    toggle: state => {
      state.isCollapsed = !state.isCollapsed
    },
    close: state => {
      state.isCollapsed = true
    },
    // openは必要になったタイミングで定義する
  },
})

export const { toggle, close } = globalNavigationSlice.actions
export default globalNavigationSlice.reducer
