import { appEnv } from 'src/util/appEnv'

export const getBasePath = () => {
  if (appEnv.isLocal) return 'http://localhost:18888'
  if (appEnv.isDevelopment) return 'https://api.report.kaminashi-dev.com'
  if (appEnv.isProduction) return 'https://api.report.kaminashi.com'
  throw new Error('環境の判定に失敗しました')
}

export const getKaminashiIDBasePath = () => {
  if (appEnv.isLocal) return 'http://localhost:4000'
  if (appEnv.isDevelopment) return 'https://dev.id.kaminashi.com'
  if (appEnv.isProduction) return 'https://id.kaminashi.com'
  throw new Error('環境の判定に失敗しました')
}
