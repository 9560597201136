import { ReportNodeSchema } from '@ulysses-inc/harami_api_client'
import { ReportNodesDict } from 'src/exShared/types/types'
import { isOriginalRepeatedSection } from 'src/exShared/util/nodes/isOriginalRepeatedSection'

type OptionArg = {
  ignoreRepeatOriginalSection?: boolean
}

type GetDescendantNodesResult = {
  arrayOfNode: ReportNodeSchema[]
  arrayOfNodeId: number[]
  dictOfNode: ReportNodesDict
}

/**
 * ノードを再帰的に下位方向に走査して取得し、結果を複数の形式で返す。
 * 結果には走査の開始点となるノードのid群も含まれる。
 *
 * @param startingNodeIds 走査の開始点となるノードのid群
 * @param allNodes 走査対象のノード群
 * @param option
 * @param option.ignoreRepeatOriginalSection 繰り返しセクションのマスタとその子孫ノードを抽出対象から除くか
 */
export const getDescendantNodes = (
  startingNodeIds: number[],
  allNodes: ReportNodesDict,
  option?: OptionArg,
): GetDescendantNodesResult => {
  const { ignoreRepeatOriginalSection = false } = option || {}
  const resultNodes: ReportNodeSchema[] = []

  const recursiveTask = (startingNodeIds: number[]) => {
    const filteredStartingNodes = startingNodeIds
      .map(startingNodeId => allNodes[startingNodeId])
      .filter(startingNode => {
        if (
          ignoreRepeatOriginalSection &&
          isOriginalRepeatedSection(startingNode)
        ) {
          return false
        }
        return true
      })

    for (const startingNode of filteredStartingNodes) {
      resultNodes.push(startingNode)

      const grandchildNodeIds = startingNode.nodes ?? []
      if (grandchildNodeIds.length === 0) continue
      recursiveTask(grandchildNodeIds)
    }
  }

  recursiveTask(startingNodeIds)

  return {
    arrayOfNode: resultNodes,
    arrayOfNodeId: resultNodes.map(resultNode => resultNode.id),
    dictOfNode: resultNodes.reduce(
      (acc, resultNode) => ({ ...acc, [resultNode.id]: resultNode }),
      {},
    ),
  }
}
