import * as Sentry from '@sentry/browser'
import { appEnv } from 'src/util/appEnv'

export const initializeSentry = () => {
  if (appEnv.isLocal) return

  Sentry.init({
    dsn: import.meta.env['VITE_APP_SENTRY_DSN'],
    environment: import.meta.env['VITE_APP_ENV'],
    normalizeDepth: 10,
  })
}
