import { StyleSheet, Text, View } from '@react-pdf/renderer'
import {
  DatetimeValue,
  ResponseDatetime,
  ResponseDatetimeSubTypeEnum,
} from '@ulysses-inc/harami_api_client'
import React from 'react'
import dateUtil from 'src/exShared/util/date'
import convertDisplayDatetimeCharacters from 'src/exShared/util/datetimeRule/convertDisplayDatetimeCharacters'
import BreakText from '../../BreakText'
import Col from '../../Col'
import Grid from '../../Grid'
import RecordedTime from './RecordedTime'

interface OwnProps {
  questionName: string
  subType?: ResponseDatetimeSubTypeEnum
  responseDatetime?: ResponseDatetime
  datetimeValue?: DatetimeValue
  recordedAt?: Date
  isTimeDisplayed: boolean
}

const getFormattedDatetimeValue = (
  subType: ResponseDatetimeSubTypeEnum,
  datetimeValue?: Date,
) => {
  if (datetimeValue === undefined) return '未回答'
  switch (subType) {
    case ResponseDatetimeSubTypeEnum.DATETIME:
      return dateUtil.formatYYYYMMDDHHMM_JP(new Date(datetimeValue))
    case ResponseDatetimeSubTypeEnum.DATE:
      return dateUtil.formatYYYYMMDD_JP(new Date(datetimeValue))
    case ResponseDatetimeSubTypeEnum.TIME:
      return dateUtil.formatHHMM_JP(new Date(datetimeValue))
    default:
      return '未回答'
  }
}

const styles = StyleSheet.create({
  gridLeft: { width: '70%' },
  gridRight: { width: '30%' },
  fullWidthWrap: { width: '100%' },
  dateTimeText: { padding: 5 },
  dateTimeRule: { color: '#656565', paddingLeft: 5 },
  recordedTimeWrap: { width: '100%', marginBottom: 5 },
})

const DateTime: React.FC<OwnProps> = (props: OwnProps) => {
  return (
    <Grid style={styles.fullWidthWrap}>
      <Col borderLeft borderRight style={styles.gridLeft}>
        <BreakText text={props.questionName} />
      </Col>
      <Col borderRight style={styles.gridRight}>
        {props.subType !== undefined && (
          <Text style={styles.dateTimeText}>
            {getFormattedDatetimeValue(
              props.subType,
              props.datetimeValue?.datetimeValue,
            )}
          </Text>
        )}
        {props.responseDatetime?.rule?.value !== undefined && (
          <Text style={styles.dateTimeRule}>
            ({convertDisplayDatetimeCharacters(props.responseDatetime)})
          </Text>
        )}
        <View style={styles.recordedTimeWrap}>
          <RecordedTime
            isTimeDisplayed={props.isTimeDisplayed}
            recordedAt={props.recordedAt}
          />
        </View>
      </Col>
    </Grid>
  )
}

export default DateTime
