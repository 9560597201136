import {
  MultipleChoiceGroup,
  ResponseTypeEnum,
  TemplateLayoutTypeEnum,
  TemplateNodeSchema,
  TemplateNodeTypeEnum,
  TemplateQuestion,
} from '@ulysses-inc/harami_api_client'
import {
  ReactNode,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useDispatch } from 'react-redux'
import { GRID_VARIABLES_COUNT } from 'src/constants/responseGridVariable'
import AddMultipleChoiceDrawer from 'src/features/templateEdit/addMultipleChoices/AddMultipleChoiceDrawer'
import { createNode } from 'src/features/templateEdit/common/createNode'
import { HoveringPosition } from 'src/features/templateEdit/dragAndDrop/DnDTemplateType'
import Logic from 'src/features/templateEdit/questionLogic/Logic'
import QuestionOptionBar from 'src/features/templateEdit/questionOptions/QuestionOptionBar'
import { updateTemplateNode } from 'src/state/ducks/templates/actions'
import { showEditDeviateComment } from 'src/state/ducks/templates/editDeviateComment/actions'
import { useTemplateNodeNodes } from 'src/state/ducks/templates/selectors'
import AddTemplateModifyButtonGroup from '../itemAppender/AddTemplateModifyButtonGroup'
import { GridVariableQuestionBody } from './GridVariableQuestionBody'
import { QuestionProps } from './Question'
import {
  LogicWrapper,
  MultipleChoiceSetText,
  MultipleChoiceSetTextWrapper,
  MultipleChoiceTag,
  MultipleChoiceTagWrapper,
  datetimeTag,
  employeeTag,
  formulaTag,
  informationTag,
  numberTag,
  resultImageTag,
  signatureTag,
  textTag,
  timeMeasurementTag,
} from './Question.components'
import { useDragAndDrop } from './Question.hooks'
import { checkIsEmployeeCheck } from './Question.utils'
import QuestionBody from './QuestionBody'

const isEqual = (prev: QuestionProps, next: QuestionProps) => {
  const prevIsRepeat = prev.path.some(
    parentId => prev.templateNodes[parentId]?.section?.isRepeat,
  )
  const nextIsRepeat = next.path.some(
    parentId => next.templateNodes[parentId]?.section?.isRepeat,
  )

  const isTemplateNodesPropNotChangedWhenUsingGridLayout = () => {
    return (
      prev.layoutType !== TemplateLayoutTypeEnum.Grid ||
      prev.templateNodes === next.templateNodes
    )
  }

  return (
    prev.isActive === next.isActive &&
    prev.node.question === next.node.question &&
    prev.node.nodes === next.node.nodes &&
    prev.isShowScore === next.isShowScore &&
    prev.isExcelConversion === next.isExcelConversion &&
    prevIsRepeat === nextIsRepeat &&
    prev.parentNode?.section?.isEmployeeCheck ===
      next.parentNode?.section?.isEmployeeCheck &&
    prev.templateHints === next.templateHints &&
    prev.isLastChild === next.isLastChild &&
    // 表形式で、かつtemplateNodesが異なる場合に再レンダリングさせる
    // 「質問を追加」を実行した際に、異なるページの取り込み項目が空配列となってしまう場合があるため、再レンダリングさせる必要がある
    isTemplateNodesPropNotChangedWhenUsingGridLayout()
  )
}

const renderResponseTypeCell = (
  question: TemplateQuestion | undefined,
): ReactNode => {
  if (!question) {
    // おそらく型の都合で書かれたコードである。実際にここに到達するケースは存在しないのでは？
    return null
  }

  const { responseType, responseMultipleChoices, responseMultipleChoiceSets } =
    question

  switch (responseType) {
    case ResponseTypeEnum.MULTIPLE_CHOICE: {
      const multipleChoiceGroup = (responseMultipleChoices ?? [])[0]

      if (multipleChoiceGroup) {
        return (
          <MultipleChoiceTagWrapper>
            {multipleChoiceGroup.responses.map(({ id, color, response }) => (
              <MultipleChoiceTag key={id} color={color}>
                {response}
              </MultipleChoiceTag>
            ))}
          </MultipleChoiceTagWrapper>
        )
      } else {
        // おそらく型の都合で書かれたコードである。実際にここに到達するケースは存在しないのでは？
        return null
      }
    }
    case ResponseTypeEnum.NUMBER:
      return numberTag()
    case ResponseTypeEnum.SIGNATURE:
      return signatureTag()
    case ResponseTypeEnum.TEXT:
      return textTag()
    case ResponseTypeEnum.RESULT_IMAGE:
      return resultImageTag()
    case ResponseTypeEnum.DATETIME:
      return datetimeTag()
    case ResponseTypeEnum.TIME_MEASUREMENT:
      return timeMeasurementTag()
    // INFORMATIONとURLは別typeだがUI上はインフォメーションとして同一に扱う
    case ResponseTypeEnum.INFORMATION:
    case ResponseTypeEnum.URL:
      return informationTag()
    case ResponseTypeEnum.RESPONSE_SET: {
      return (
        <MultipleChoiceSetTextWrapper>
          <MultipleChoiceSetText>
            {(responseMultipleChoiceSets ?? [])[0]?.name ?? ''}
          </MultipleChoiceSetText>
        </MultipleChoiceSetTextWrapper>
      )
    }
    case ResponseTypeEnum.EMPLOYEE:
      return employeeTag()
    case ResponseTypeEnum.FORMULA:
      return formulaTag()
    default:
      return null
  }
}

// eslint-disable-next-line react/display-name
const QuestionFrame = memo<QuestionProps>(
  // eslint: react/prop-types で怒られるので props に直接 QuestionProps の指定が必要
  (props: QuestionProps) => {
    const {
      node,
      parentNode,
      path,
      isLastChild,
      templateNodes,
      isActive,
      layoutType,
    } = props
    const isGridVariableQuestion =
      node.question?.responseType === ResponseTypeEnum.GRID_VARIABLE

    const templateNodeNodes = useTemplateNodeNodes(node)

    const dispatch = useDispatch()
    const useAddTemplateNode = (
      targetNode: TemplateNodeSchema | null,
      parentNode: TemplateNodeSchema | null,
      position: 'top' | 'bottom',
      addTemplateNodeType: TemplateNodeTypeEnum,
    ) => {
      createNode(templateNodes, dispatch, {
        targetNode,
        parentNode,
        position,
        addTemplateNodeType,
        question: isGridVariableQuestion ? undefined : targetNode?.question,
      })
    }

    const [isInRepeat, setIsInRepeat] = useState<boolean | undefined>(undefined)
    const isVisibleAddMultipleChoiceDrawerState = useState(false)
    const updateMultipleChoiceGroupState = useState<MultipleChoiceGroup>()
    const isOpenState = useState(true)
    /**
     * 現在選択されている質問種別を表示するためのコンポーネント自体を保持している(!?)。
     * 最終的に質問名の右隣のセルに描写される。
     */
    const selectAnswerState = useState<ReactNode>()
    const hoverPositionState = useState<HoveringPosition>(undefined)

    const ref = useRef<HTMLDivElement>(null)

    const [, setSelectAnswer] = selectAnswerState

    const resetNodeExcelConversion = useCallback(
      () =>
        dispatch(
          updateTemplateNode(node.id, {
            ...node,
            question: {
              ...node.question,
              excelConversionTypes: [],
            },
          }),
        ),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [node],
    )

    useEffect(() => {
      setSelectAnswer(renderResponseTypeCell(node.question))
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [node.question, node.question?.responseMultipleChoices])

    useEffect(() => {
      if (isInRepeat === undefined && path && templateNodes) {
        setIsInRepeat(
          path.some(parentId => templateNodes[parentId]?.section?.isRepeat),
        )
        return
      }
      if (
        isInRepeat !==
        path.some(parentId => templateNodes[parentId]?.section?.isRepeat)
      ) {
        setIsInRepeat(i => !i)
        resetNodeExcelConversion()
      }
    }, [path, templateNodes, isInRepeat, resetNodeExcelConversion])

    const dragAndDropProps = useDragAndDrop({
      hoverPositionState,
      parentNode,
      node,
      templateInfo: {
        layoutType,
        templateNodes,
      },
      ref,
    })

    if (dragAndDropProps.isDragging) {
      return <></>
    }

    const [
      isVisibleAddMultipleChoiceDrawer,
      setIsVisibleAddMultipleChoiceDrawer,
    ] = isVisibleAddMultipleChoiceDrawerState
    const [updateMultipleChoiceGroup, setUpdateMultipleChoiceGroup] =
      updateMultipleChoiceGroupState
    const [isOpen] = isOpenState

    const isEmployeeCheck = checkIsEmployeeCheck(parentNode, node)
    const dispatchUpdateTemplateNode = (
      nodeId: number,
      changeNode: TemplateNodeSchema,
    ) => dispatch(updateTemplateNode(nodeId, changeNode))
    const dispatchShowEditDeviateComment = (node: TemplateNodeSchema) =>
      dispatch(showEditDeviateComment(node))
    const onCloseAddMultipleChoiceDrawer = () => {
      setIsVisibleAddMultipleChoiceDrawer(false)
      setUpdateMultipleChoiceGroup(undefined)
    }

    // 質問を追加(上)：「従業員」と「取り込み項目」は常に非表示
    const isVisibleAddQuestionTop =
      isActive && !isEmployeeCheck && !isGridVariableQuestion
    // オプションバー：「取り込み項目」は常に非表示
    const isVisibleOptionBar = isActive && !isGridVariableQuestion
    // 質問を追加(下)：
    // ・「取り込み項目」以外の質問の場合はアクティブの場合に表示
    // ・「取り込み項目1~5」は常に非表示
    // ・「取り込み項目6」は質問が追加されるまでは常時表示(質問が追加れたたら常に非表示)
    const indexOfChildren = parentNode?.nodes.indexOf(node.id)
    const isVisibleAddQuestionBottom =
      (isActive && !isGridVariableQuestion) ||
      (indexOfChildren === GRID_VARIABLES_COUNT - 1 && isLastChild)

    return (
      <div data-testid="question-frame">
        <AddTemplateModifyButtonGroup
          isOpen={isVisibleAddQuestionTop}
          node={node}
          position="top"
          parentNode={parentNode}
          addTemplateNode={useAddTemplateNode}
        />
        {isGridVariableQuestion ? (
          <GridVariableQuestionBody
            {...props}
            gridVariableNumber={(indexOfChildren ?? 0) + 1}
          />
        ) : (
          <div ref={ref}>
            <QuestionBody
              {...{
                ...props,
                ...dragAndDropProps,
                isOpenState,
                selectAnswerState,
                updateMultipleChoiceGroupState,
                isVisibleAddMultipleChoiceDrawerState,
                hoverPositionState,
              }}
            />
          </div>
        )}
        {isVisibleOptionBar && (
          <QuestionOptionBar
            node={node}
            parentNode={parentNode}
            updateTemplateNode={dispatchUpdateTemplateNode}
            showEditDeviateComment={dispatchShowEditDeviateComment}
            isEmployeeCheck={isEmployeeCheck}
          />
        )}
        <LogicWrapper isOpened={isOpen}>
          {templateNodeNodes.map(n => (
            <Logic
              key={node.id}
              node={n}
              parentNode={node}
              path={[...path, node.id]}
            />
          ))}
        </LogicWrapper>
        <AddTemplateModifyButtonGroup
          isOpen={isVisibleAddQuestionBottom}
          node={node}
          position="bottom"
          parentNode={parentNode}
          addTemplateNode={useAddTemplateNode}
        />
        <AddMultipleChoiceDrawer
          onClose={onCloseAddMultipleChoiceDrawer}
          isVisible={isVisibleAddMultipleChoiceDrawer}
          updateMultipleChoiceGroup={updateMultipleChoiceGroup}
        />
      </div>
    )
  },
  isEqual,
)

export default QuestionFrame
