import { css } from '@emotion/react'
import { IndicatedIssueLabel } from '@ulysses-inc/harami_api_client'
import React from 'react'
import { White } from 'src/features/theme/KdsThemeColor'
import { useWindowSize } from 'src/hooks/useWindowSize'

const styles = {
  labels: css`
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-top: 8px;
    margin-left: 8px;
  `,
  label: css`
    height: 18px;
    padding-left: 8px;
    padding-right: 8px;
    border-radius: 8px;
    margin-right: 8px;
  `,
  labelText: css`
    color: ${White};
    font-size: 12px;
    margin-top: 0;
  `,
  otherLabelText: css`
    color: #595959;
    font-size: 12px;
    font-weight: 400;
    margin-top: 0;
  `,
}

interface OwnProps {
  labels: IndicatedIssueLabel[]
}

const IndicatedIssueLabels: React.FC<OwnProps> = (props: OwnProps) => {
  const { width: windowWidth = 0 } = useWindowSize()
  const maxWidth = windowWidth * 0.38

  let total = 0
  let otherLabelsNum = 0

  return (
    <div css={styles.labels}>
      {props.labels.map((label: IndicatedIssueLabel, index) => {
        total += 40 + (label.name?.length || 0) * 12
        if (total < maxWidth) {
          return (
            <div
              key={index}
              css={[
                styles.label,
                css`
                  background-color: ${label.color};
                  border-color: ${label.color};
                `,
              ]}
            >
              <div css={styles.labelText}>{label.name}</div>
            </div>
          )
        }
        otherLabelsNum += 1
        return <></>
      })}
      {otherLabelsNum > 0 && (
        <div css={styles.otherLabelText}>他{otherLabelsNum}個タグ</div>
      )}
    </div>
  )
}

export default IndicatedIssueLabels
