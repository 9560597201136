import Icon from '@ant-design/icons'
import {
  Template,
  TemplateLayoutTypeEnum,
} from '@ulysses-inc/harami_api_client'
import { FC, useEffect, useState } from 'react'
import ExternalLinkSvg from 'src/assets/icons/external_link.svg?react'
import {
  ConfirmModalContentTemplates,
  ConfirmModalContentTemplatesList,
  ConfirmModalContentWrapper,
  Link,
} from './AddMultipleChoiceDrawer.styled'

/** 表形式ひな形とそれ以外でパスを出し分ける */
const createPath = (id: number, layoutType: TemplateLayoutTypeEnum) =>
  layoutType === TemplateLayoutTypeEnum.Grid
    ? `/templates/${id}/pages/editGridTemplate`
    : `/templates/${id}/pages/edit`

const AlertModalContent: FC<{
  relatedTemplates: Template[] | null
  templateId: number
  templateName: string
  isInUse: boolean
}> = ({ relatedTemplates, templateId, templateName, isInUse }) => {
  const [otherTemplates, setOtherTemplates] = useState<Template[]>([])

  useEffect(() => {
    setOtherTemplates(
      relatedTemplates?.filter(template => template.id !== templateId) || [],
    )
  }, [relatedTemplates, templateId, templateName])

  if (!otherTemplates?.length && !isInUse) {
    return null
  }

  return (
    <ConfirmModalContentWrapper>
      <ConfirmModalContentTemplates>
        <ConfirmModalContentTemplatesList>
          {isInUse && <li>編集中のひな形：{templateName}</li>}
          {otherTemplates.map(template => {
            // 型エラー回避のためのワークアラウンド
            if (!template.id || !template.layoutType) return null

            return (
              <li key={template.id}>
                <Link
                  href={createPath(template.id, template.layoutType)}
                  target="_blank"
                >
                  {template.name}
                  <sup style={{ paddingLeft: '4px' }}>
                    <Icon component={ExternalLinkSvg} />
                  </sup>
                </Link>
              </li>
            )
          })}
        </ConfirmModalContentTemplatesList>
      </ConfirmModalContentTemplates>
    </ConfirmModalContentWrapper>
  )
}

export default AlertModalContent
