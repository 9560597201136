import { ScheduleListInRangeItem } from '@ulysses-inc/harami_api_client'
import { combineReducers } from 'redux'
import {
  InvalidCountScheduleListInRangeItemsAction,
  MonthlyPlaceScheduleListInRangeItemsAction,
  PlaceScheduleListInRangeItemsAction,
  ScheduleListInRangeItemsAction,
} from './actions'
import { ActionTypes } from './types'

/**
 * 複数現場の実施状況(一覧表示用)
 */
interface ScheduleListInRangeItemsState {
  scheduleListInRangeItems: ScheduleListInRangeItem[]
  startDate: Date | null
  endDate: Date | null
  isLoading: boolean
  error: Error | null
}

const initialScheduleListInRangeItemsState: ScheduleListInRangeItemsState = {
  scheduleListInRangeItems: [],
  startDate: null,
  endDate: null,
  isLoading: false,
  error: null,
}

const scheduleListInRangeItems = (
  state: ScheduleListInRangeItemsState = initialScheduleListInRangeItemsState,
  action: ScheduleListInRangeItemsAction,
): ScheduleListInRangeItemsState => {
  switch (action.type) {
    case ActionTypes.REQUEST_GET_SCHEDULE_LIST_IN_RANGE_ITEMS: {
      return {
        ...state,
        isLoading: action.isLoading,
        scheduleListInRangeItems: [],
        startDate: null,
        endDate: null,
        error: null,
      }
    }
    case ActionTypes.SUCCESS_GET_SCHEDULE_LIST_IN_RANGE_ITEMS: {
      return {
        ...state,
        isLoading: action.isLoading,
        scheduleListInRangeItems: action.scheduleListInRangeItems,
        startDate: action.startDate,
        endDate: action.endDate,
        error: null,
      }
    }
    case ActionTypes.ERROR_GET_SCHEDULE_LIST_IN_RANGE_ITEMS: {
      return {
        ...state,
        isLoading: action.isLoading,
        scheduleListInRangeItems: [],
        startDate: null,
        endDate: null,
        error: action.error,
      }
    }
    default: {
      return { ...state }
    }
  }
}

/**
 * 複数現場の実施状況(逸脱一覧表示用)
 */
interface InvalidCountScheduleListInRangeItemsState {
  invalidCountScheduleListInRangeItems: ScheduleListInRangeItem[]
  startDate: Date | null
  endDate: Date | null
  isLoading: boolean
  error: Error | null
}

const initialnvalidCountScheduleListInRangeItemsState: InvalidCountScheduleListInRangeItemsState =
  {
    invalidCountScheduleListInRangeItems: [],
    startDate: null,
    endDate: null,
    isLoading: false,
    error: null,
  }

const invalidCountScheduleListInRangeItems = (
  state: InvalidCountScheduleListInRangeItemsState = initialnvalidCountScheduleListInRangeItemsState,
  action: InvalidCountScheduleListInRangeItemsAction,
): InvalidCountScheduleListInRangeItemsState => {
  switch (action.type) {
    case ActionTypes.REQUEST_GET_INVALID_COUNT_SCHEDULE_LIST_IN_RANGE_ITEMS: {
      return {
        ...state,
        isLoading: action.isLoading,
        invalidCountScheduleListInRangeItems: [],
        startDate: null,
        endDate: null,
        error: null,
      }
    }
    case ActionTypes.SUCCESS_GET_INVALID_COUNT_SCHEDULE_LIST_IN_RANGE_ITEMS: {
      return {
        ...state,
        isLoading: action.isLoading,
        invalidCountScheduleListInRangeItems: action.scheduleListInRangeItems,
        startDate: action.startDate,
        endDate: action.endDate,
        error: null,
      }
    }
    case ActionTypes.ERROR_GET_INVALID_COUNT_SCHEDULE_LIST_IN_RANGE_ITEMS: {
      return {
        ...state,
        isLoading: action.isLoading,
        invalidCountScheduleListInRangeItems: [],
        startDate: null,
        endDate: null,
        error: action.error,
      }
    }
    default: {
      return { ...state }
    }
  }
}

/**
 * 特定現場の実施状況(詳細表示用)
 */
interface PlaceScheduleListInRangeItemsState {
  placeNodeUUID: string
  scheduleListInRangeItems: ScheduleListInRangeItem[]
  targetDate: Date | null
  isLoading: boolean
  error: Error | null
}

const initialPlaceScheduleListInRangeItemsState: PlaceScheduleListInRangeItemsState =
  {
    placeNodeUUID: '',
    scheduleListInRangeItems: [],
    targetDate: null,
    isLoading: false,
    error: null,
  }

const placeScheduleListInRangeItems = (
  state: PlaceScheduleListInRangeItemsState = initialPlaceScheduleListInRangeItemsState,
  action: PlaceScheduleListInRangeItemsAction,
): PlaceScheduleListInRangeItemsState => {
  switch (action.type) {
    case ActionTypes.REQUEST_GET_PLACE_SCHEDULE_LIST_IN_RANGE_ITEMS: {
      return {
        ...state,
        placeNodeUUID: action.request.placeNodeId?.$in?.[0] ?? '',
        isLoading: action.isLoading,
        scheduleListInRangeItems: [],
        targetDate: null,
        error: null,
      }
    }
    case ActionTypes.SUCCESS_GET_PLACE_SCHEDULE_LIST_IN_RANGE_ITEMS: {
      return {
        ...state,
        isLoading: action.isLoading,
        scheduleListInRangeItems: action.scheduleListInRangeItems,
        targetDate: action.targetDate,
        error: null,
      }
    }
    case ActionTypes.ERROR_GET_PLACE_SCHEDULE_LIST_IN_RANGE_ITEMS: {
      return {
        ...state,
        isLoading: action.isLoading,
        scheduleListInRangeItems: [],
        targetDate: null,
        error: action.error,
      }
    }
    case ActionTypes.RESET_PLACE_SCHEDULE_LIST_IN_RANGE_ITEMS: {
      return {
        ...state,
        placeNodeUUID: '',
        isLoading: false,
        scheduleListInRangeItems: [],
        targetDate: null,
        error: null,
      }
    }
    default: {
      return { ...state }
    }
  }
}

/**
 * 特定現場の月刊実施状況(カレンダー表示用)
 */
interface MonthlyPlaceScheduleListInRangeItemsState {
  placeNodeUUID: string
  scheduleListInRangeItems: ScheduleListInRangeItem[]
  isLoading: boolean
  error: Error | null
}

const initialMonthlyPlaceScheduleListInRangeItemsState: MonthlyPlaceScheduleListInRangeItemsState =
  {
    placeNodeUUID: '',
    scheduleListInRangeItems: [],
    isLoading: false,
    error: null,
  }

const monthlyPlaceScheduleListInRangeItems = (
  state: MonthlyPlaceScheduleListInRangeItemsState = initialMonthlyPlaceScheduleListInRangeItemsState,
  action: MonthlyPlaceScheduleListInRangeItemsAction,
): MonthlyPlaceScheduleListInRangeItemsState => {
  switch (action.type) {
    case ActionTypes.REQUEST_GET_MONTHLY_PLACE_SCHEDULE_LIST_IN_RANGE_ITEMS: {
      return {
        ...state,
        placeNodeUUID: action.request.placeNodeId?.$in?.[0] ?? '',
        isLoading: action.isLoading,
        scheduleListInRangeItems: [],
        error: null,
      }
    }
    case ActionTypes.SUCCESS_GET_MONTHLY_PLACE_SCHEDULE_LIST_IN_RANGE_ITEMS: {
      return {
        ...state,
        isLoading: action.isLoading,
        scheduleListInRangeItems: action.scheduleListInRangeItems,
        error: null,
      }
    }
    case ActionTypes.ERROR_GET_MONTHLY_PLACE_SCHEDULE_LIST_IN_RANGE_ITEMS: {
      return {
        ...state,
        isLoading: action.isLoading,
        error: action.error,
      }
    }
    default: {
      return { ...state }
    }
  }
}

const scheduleListInRangeItemsReducer = combineReducers({
  scheduleListInRangeItems,
  invalidCountScheduleListInRangeItems,
  placeScheduleListInRangeItems,
  monthlyPlaceScheduleListInRangeItems,
})

export default scheduleListInRangeItemsReducer
