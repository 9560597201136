import { TemplateNodeSchema } from '@ulysses-inc/harami_api_client'
import { ActionTypes } from 'src/state/ducks/templates/editSerialNumber/types'

/**
 * 「繰り返し出力」というEXCEL出力ラベルに付与される連番に関するなんらかの処理
 * 詳細不明
 */
export const setRepeatSerialNumbers = (nodes: TemplateNodeSchema[]) => {
  return {
    type: ActionTypes.SET_REPEAT_SERIAL_NUMBERS,
    nodes,
  }
}

/**
 * 「繰り返し出力」というEXCEL出力ラベルに付与される連番に関するなんらかの処理
 * 詳細不明
 */
export const updateRepeatSerialNumber = (
  parentNodeId: number,
  number: number,
) => {
  return {
    type: ActionTypes.UPDATE_REPEAT_SERIAL_NUMBER,
    parentNodeId,
    number,
  }
}

export type EditSerialNumberAction =
  | ReturnType<typeof setRepeatSerialNumbers>
  | ReturnType<typeof updateRepeatSerialNumber>
