import { Typography } from 'antd'
import { White } from 'src/features/theme/KdsThemeColor'
import styled from 'styled-components'

const { Text } = Typography

export const TemplatePageContentWrapper = styled.div`
  padding: 20px 20px 200px 15px;
  min-height: 800px;
  background: ${White};
  overflow-x: auto;
`

export const ErrorMessageText = styled(Text)`
  color: red;
  font-weight: bold;
  margin-left: 20px;
`
