import {
  Approval,
  ApprovalFlow,
  Improve,
  MultipleChoiceSet,
  ReportIcon,
  ReportImage,
  ReportPlace,
  ReportQuestionsIndicatedIssueGroup,
  ReportStatus,
  TemplateHint,
  TemplateLayoutTypeEnum,
  TemplateManual,
  User,
} from '@ulysses-inc/harami_api_client'
import { ReportNodesDict, ReportPagesDict } from 'src/exShared/types/types'

// prettier-ignore
export const ActionTypes = {
  /**
   * report
   */
  REQUEST_CHANGE_REPORTS_PAGE: 'harami/reports/REQUEST_CHANGE_REPORTS_PAGE',
  REQUEST_CHANGE_REPORTS_SIZE: 'harami/reports/REQUEST_CHANGE_REPORTS_SIZE',
  UPDATE_PAGINATION: 'harami/reports/UPDATE_PAGINATION',

  /**
   * deleteReport
   */
  REQUEST_DELETE_REPORT: 'harami/reports/REQUEST_DELETE_REPORT',
  SUCCESS_DELETE_REPORT: 'harami/reports/SUCCESS_DELETE_REPORT',
  ERROR_DELETE_REPORT: 'harami/reports/ERROR_DELETE_REPORT',

  /**
   * updateReportPlaces
   */
  REQUEST_UPDATE_REPORT_PLACES: 'harami/reports/REQUEST_UPDATE_REPORT_PLACES',
  SUCCESS_UPDATE_REPORT_PLACES: 'harami/reports/SUCCESS_UPDATE_REPORT_PLACES',
  ERROR_UPDATE_REPORT_PLACES: 'harami/reports/ERROR_UPDATE_REPORT_PLACES',

  /**
   * reports
   */
  REQUEST_GET_REPORTS: 'harami/reports/REQUEST_GET_REPORTS',
  SUCCESS_GET_REPORTS: 'harami/reports/SUCCESS_GET_REPORTS',
  ERROR_GET_REPORTS: 'harami/reports/ERROR_GET_REPORTS',

  /**
   * reportResult
   */
  REQUEST_GET_REPORT_RESULT: 'harami/reports/REQUEST_GET_REPORT_RESULT',
  SUCCESS_GET_REPORT_RESULT: 'harami/reports/SUCCESS_GET_REPORT_RESULT',
  ERROR_GET_REPORT_RESULT: 'harami/reports/ERROR_GET_REPORT_RESULT',
  REQUEST_GET_TEMPLATE_PAGES: 'harami/reports/REQUEST_GET_TEMPLATE_PAGES',
  SUCCESS_GET_TEMPLATE_PAGES: 'harami/reports/SUCCESS_GET_TEMPLATE_PAGES',
  ERROR_GET_TEMPLATE_PAGES: 'harami/reports/ERROR_GET_TEMPLATE_PAGES',
  REQUEST_ADD_REPORT_COMMENT: 'harami/reports/REQUEST_ADD_REPORT_COMMENT',
  SUCCESS_ADD_REPORT_COMMENT: 'harami/reports/SUCCESS_ADD_REPORT_COMMENT',
  ERROR_ADD_REPORT_COMMENT: 'harami/reports/ERROR_ADD_REPORT_COMMENT',
  REQUEST_UPLOAD_REPORT_ATTACHMENT_FILE: 'harami/reports/REQUEST_UPLOAD_REPORT_ATTACHMENT_FILE',
  REQUEST_ADD_REPORT_ATTACHMENT_FILE: 'harami/reports/REQUEST_ADD_REPORT_ATTACHMENT_FILE',
  SUCCESS_ADD_REPORT_ATTACHMENT_FILE: 'harami/reports/SUCCESS_ADD_REPORT_ATTACHMENT_FILE',
  ERROR_ADD_REPORT_ATTACHMENT_FILE: 'harami/reports/ERROR_ADD_REPORT_ATTACHMENT_FILE',
  REQUEST_DELETE_REPORT_ATTACHMENT_FILE: 'harami/reports/REQUEST_DELETE_REPORT_ATTACHMENT_FILE',
  SUCCESS_DELETE_REPORT_ATTACHMENT_FILE: 'harami/reports/SUCCESS_DELETE_REPORT_ATTACHMENT_FILE',
  ERROR_DELETE_REPORT_ATTACHMENT_FILE: 'harami/reports/ERROR_DELETE_REPORT_ATTACHMENT_FILE',
  RESET_REPORT_RESULT: 'harami/reports/RESET_REPORT_RESULT',
  REQUEST_UPDATE_REPORT_STATUS: 'harami/reports/REQUEST_UPDATE_REPORT_STATUS',
  SUCCESS_UPDATE_REPORT_STATUS: 'harami/reports/SUCCESS_UPDATE_REPORT_STATUS',
  ERROR_UPDATE_REPORT_STATUS: 'harami/reports/ERROR_UPDATE_REPORT_STATUS',
  CHANGE_REPORT_STATUS: 'harami/reports/CHANGE_REPORT_STATUS',

  /**
   * reportsTemplates
   */
  RESET_REPORTS_TEMPLATES: 'harami/reports/RESET_REPORTS_TEMPLATES',
  REQUEST_GET_REPORTS_TEMPLATES: 'harami/reports/REQUEST_GET_REPORTS_TEMPLATES',
  SUCCESS_GET_REPORTS_TEMPLATES: 'harami/reports/SUCCESS_GET_REPORTS_TEMPLATES',
  ERROR_GET_REPORTS_TEMPLATES: 'harami/reports/ERROR_GET_REPORTS_TEMPLATES',
} as const

export interface ReportSchema {
  id?: number
  uuid: string
  name?: string
  pages: ReportPagesDict
  nodes: ReportNodesDict
  pageIds: Array<number>
  multipleChoiceSets: Array<MultipleChoiceSet>
  scorePercentage: number
  manualMemos: Array<ReportImage>
  images: Array<ReportImage>
  hints: Array<TemplateHint>
  comment: string
  manuals: Array<TemplateManual>
  approvalFlows: Array<ApprovalFlow>
  approvals: Array<Approval>
  isInvalid: boolean
  isExistsRequiredLeak: boolean
  templateId: number
  scheduleId: number
  scheduleUUID: string
  assignee: User
  isShowScore: boolean
  isEditable: boolean
  isExcelConversion: boolean
  isHideQuestionOptions: boolean
  isAudit: boolean
  isKeyboard: boolean
  layoutType: TemplateLayoutTypeEnum
  icons: Array<ReportIcon>
  reportDate: Date
  place: ReportPlace
  status: ReportStatus
  improve: Improve
  linkedIndicatedIssues: Array<ReportQuestionsIndicatedIssueGroup>
}

export interface ReportVisibleSchema {
  pages: { [key: number]: boolean }
  nodes: { [key: number]: boolean }
}

export interface GetReportsFilter {
  reportName?: { $like: string }
  templateId?: { $in: number[] }
  placeNodeId?: { $in: string[] }
  reportDate?: { $gte: Date; $lte: Date }
  updatedAt?: { $gte: Date; $lte: Date }
  status?: { $in: number[] }
  isInvalid?: { $in: number[] }
  answer?: {
    employee?: { $like: string }
  }
}

export type PersistedReportLocation = 'on_server' | 'on_device'
