import {
  GetImproveExcelConvertsRequest,
  ImproveExcelConverts,
} from '@ulysses-inc/harami_api_client'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RouteComponentProps, useHistory } from 'react-router-dom'
import {
  DispatchProps,
  StateProps,
} from 'src/features/improveExcelConversionLogs/ImproveExcelConversionLogsProps'
import ImproveExcelConversionLogsScene from 'src/features/improveExcelConversionLogs/improveExcelConversionLogsScene'
import improveExcelConversionLogsOperations from 'src/state/ducks/improveExcelConversionLogs/operations'
import { GetImproveExcelConversionLogsFilter } from 'src/state/ducks/improveExcelConversionLogs/types'

interface State {
  improveExcelConversionLogsState: {
    improveExcelConversionLogs: {
      improveExcelConversionLogs: ImproveExcelConverts[]
      isLoading: boolean
      count: number
      filter?: GetImproveExcelConversionLogsFilter
      request: GetImproveExcelConvertsRequest
    }
  }
}

const useStateProps = (): StateProps => {
  return {
    logs: useSelector(
      (state: State) =>
        state.improveExcelConversionLogsState.improveExcelConversionLogs
          .improveExcelConversionLogs,
    ),
    isLoading: useSelector(
      (state: State) =>
        state.improveExcelConversionLogsState.improveExcelConversionLogs
          .isLoading,
    ),
    count: useSelector(
      (state: State) =>
        state.improveExcelConversionLogsState.improveExcelConversionLogs.count,
    ),
    filter: useSelector(
      (state: State) =>
        state.improveExcelConversionLogsState.improveExcelConversionLogs.filter,
    ),
    request: useSelector(
      (state: State) =>
        state.improveExcelConversionLogsState.improveExcelConversionLogs
          .request,
    ),
  }
}

const useDispatchProps = (): DispatchProps => {
  const dispatch = useDispatch()
  const history = useHistory()

  return {
    getLogs: (
      request: GetImproveExcelConvertsRequest,
      filter?: GetImproveExcelConversionLogsFilter,
    ) => {
      improveExcelConversionLogsOperations.getImproveExcelConversionLogs(
        dispatch,
        request,
        filter,
      )
    },
    changeLogsPage: (
      improveUUID: string,
      page: number,
      pageSize?: number,
      filter?: GetImproveExcelConversionLogsFilter,
    ) => {
      improveExcelConversionLogsOperations.changeImproveExcelConversionLogsPage(
        dispatch,
        improveUUID,
        page,
        pageSize,
        filter,
      )
    },
    changeLogsSize: (
      improveUUID: string,
      pageSize: number,
      filter?: GetImproveExcelConversionLogsFilter,
    ) => {
      improveExcelConversionLogsOperations.changeImproveExcelConversionLogsSize(
        dispatch,
        improveUUID,
        pageSize,
        filter,
      )
    },
    updatePagination: (improveUUID: string, limit: number, offset: number) => {
      improveExcelConversionLogsOperations.updatePagination(
        dispatch,
        improveUUID,
        limit,
        offset,
      )
    },
    goImproveDetail: (improveUUID: string) => {
      history.push(`/improves/${improveUUID}`)
    },
    getImproveExcelConversionLogFiles: (fileUUIDs: string[]) => {
      improveExcelConversionLogsOperations.getImproveExcelConversionLogFiles(
        dispatch,
        fileUUIDs,
      )
    },
  }
}

const ImproveExcelConversionLogsContainer: React.FC<
  RouteComponentProps<{
    improveUUID: string
  }>
> = props => {
  const improveUUID = props.match.params['improveUUID']
  const stateProps = useStateProps()
  const dispatchProps = useDispatchProps()
  const mergedProps = { ...stateProps, ...dispatchProps, improveUUID }
  return <ImproveExcelConversionLogsScene {...mergedProps} />
}

export default ImproveExcelConversionLogsContainer
