// eslint-disable-next-line
// @ts-ignore
import Header from '@editorjs/header'
// eslint-disable-next-line
// @ts-ignore
import Image from '@editorjs/image'
// eslint-disable-next-line
// @ts-ignore
import Paragraph from '@editorjs/paragraph'
import { getUnixTime } from 'date-fns'
import date from 'src/exShared/util/date'
import { uploadImagesRequestByFiles } from 'src/state/ducks/images/sagas'
import './editor-override.css'

export const editorJsCommonSettings = {
  initialBlocks: [{ type: 'paragraph', data: { text: '' } }],
  outputOption: {
    time: getUnixTime(date.today()),
    version: '2.12.4',
  },
  // editor.js本体に付加するプラグイン類
  tools: {
    paragraph: Paragraph,
    header: Header,
    image: {
      class: Image,
      config: {
        uploader: {
          uploadByFile: (file: File) =>
            uploadImagesRequestByFiles([file]).then(images => ({
              success: 1,
              file: {
                url: images[0].url,
                uuid: images[0].uuid,
              },
            })),
        },
      },
    },
  },
}
