import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'src/state/store'

const PREFIX = 'PSEUDO_RECOIL_STATE.'

type TStateValue = unknown

export type TPseudoAtom<V = TStateValue> = {
  key: string
  default: V
}

type TPseudoRecoilStateAction<V = TStateValue> = {
  type: string
  payload: V
}

export type TUsePseudoRecoilState<V> = [V, (payload: V) => void]

type TEntirePseudoRecoilState = Record<string, TStateValue>

const reducer = (
  state: TEntirePseudoRecoilState = {},
  action: TPseudoRecoilStateAction,
): TEntirePseudoRecoilState =>
  action.type.includes(PREFIX)
    ? {
        ...state,
        [action.type]: action.payload,
      }
    : state

export const usePseudoRecoilState = <V>(
  atom: TPseudoAtom<V>,
): TUsePseudoRecoilState<V> => {
  const type = `${PREFIX}${atom.key}`
  const currentValue = useSelector<RootState>(
    state => state.pseudoRecoilState[type],
  )

  const dispatch = useDispatch()
  const updater = (payload: V) => void dispatch({ type, payload })

  // `unique symbol` を使えば `as` は必要ないがそれをすると src/state/store の crashReportMiddleware で error になる
  return [(currentValue ?? atom.default) as V, updater]
}

// 何もしていないが recoil との互換性のために置いてある
export const pseudoAtom = <V>(options: TPseudoAtom<V>): TPseudoAtom<V> =>
  options

export default reducer
