import { ReactNode } from 'react'

type Props = {
  messages: ReactNode[]
}

/**
 * CSVファイルに不備が合った際にユーザーに通知するメッセージ
 */
export const CsvValidationErrorMessage = (props: Props) => {
  return (
    <>
      {props.messages.map((message, index) => (
        <div key={index}>{message}</div>
      ))}
    </>
  )
}
