// prettier-ignore
export const ActionTypes = {
  REQUEST_GET_SCHEDULE_LIST_IN_RANGE_ITEMS: 'harami/schedules/REQUEST_GET_SCHEDULE_LIST_IN_RANGE_ITEMS',
  SUCCESS_GET_SCHEDULE_LIST_IN_RANGE_ITEMS: 'harami/schedules/SUCCESS_GET_SCHEDULE_LIST_IN_RANGE_ITEMS',
  ERROR_GET_SCHEDULE_LIST_IN_RANGE_ITEMS: 'harami/schedules/ERROR_GET_SCHEDULE_LIST_IN_RANGE_ITEMS',
  //
  REQUEST_GET_PLACE_SCHEDULE_LIST_IN_RANGE_ITEMS: 'harami/schedules/REQUEST_GET_PLACE_SCHEDULE_LIST_IN_RANGE_ITEMS',
  SUCCESS_GET_PLACE_SCHEDULE_LIST_IN_RANGE_ITEMS: 'harami/schedules/SUCCESS_GET_PLACE_SCHEDULE_LIST_IN_RANGE_ITEMS',
  ERROR_GET_PLACE_SCHEDULE_LIST_IN_RANGE_ITEMS: 'harami/schedules/ERROR_GET_PLACE_SCHEDULE_LIST_IN_RANGE_ITEMS',
  RESET_PLACE_SCHEDULE_LIST_IN_RANGE_ITEMS: 'harami/schedules/RESET_PLACE_SCHEDULE_LIST_IN_RANGE_ITEMS',
  //
  REQUEST_GET_INVALID_COUNT_SCHEDULE_LIST_IN_RANGE_ITEMS: 'harami/schedules/REQUEST_GET_INVALID_COUNT_SCHEDULE_LIST_IN_RANGE_ITEMS',
  SUCCESS_GET_INVALID_COUNT_SCHEDULE_LIST_IN_RANGE_ITEMS: 'harami/schedules/SUCCESS_GET_INVALID_COUNT_SCHEDULE_LIST_IN_RANGE_ITEMS',
  ERROR_GET_INVALID_COUNT_SCHEDULE_LIST_IN_RANGE_ITEMS: 'harami/schedules/ERROR_GET_INVALID_COUNT_SCHEDULE_LIST_IN_RANGE_ITEMS',
  RESET_INVALID_COUNT_SCHEDULE_LIST_IN_RANGE_ITEMS: 'harami/schedules/RESET_INVALID_COUNT_SCHEDULE_LIST_IN_RANGE_ITEMS',
  //
  REQUEST_GET_MONTHLY_PLACE_SCHEDULE_LIST_IN_RANGE_ITEMS: 'harami/schedules/REQUEST_GET_MONTHLY_PLACE_SCHEDULE_LIST_IN_RANGE_ITEMS',
  SUCCESS_GET_MONTHLY_PLACE_SCHEDULE_LIST_IN_RANGE_ITEMS: 'harami/schedules/SUCCESS_GET_MONTHLY_PLACE_SCHEDULE_LIST_IN_RANGE_ITEMS',
  ERROR_GET_MONTHLY_PLACE_SCHEDULE_LIST_IN_RANGE_ITEMS: 'harami/schedules/ERROR_GET_MONTHLY_PLACE_SCHEDULE_LIST_IN_RANGE_ITEMS',
} as const

export type GetScheduleListInRangeItems = {
  placeNodeId?: { $in: string[] }
  startDate: Date
  endDate: Date
  isNeedInvalidCount?: number
  isSkipCompletedSchedule?: number
}
