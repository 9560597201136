import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import { css } from '@emotion/react'
import { Button, InputNumber } from 'antd'

type PaginationProps = {
  current: number
  onPageChange: (page: number) => void
  pagination?: { nextPage: boolean; prevPage: boolean }
}

export const Pagination = (props: PaginationProps) => {
  const handleNumberChange = (value: number | null) => {
    value && props.onPageChange(value)
  }

  return (
    <div css={styles.container}>
      <Button
        disabled={!props.pagination?.prevPage}
        onClick={() => {
          handleNumberChange(props.current - 1)
        }}
        icon={<LeftOutlined />}
      />
      <div css={styles.pageInputContainer}>
        <InputNumber
          controls={false}
          keyboard={false}
          css={styles.pageInput}
          value={props.current}
          min={1}
          max={100} // 50件 x 100ページ = 5000件で上限を設定
          onChange={handleNumberChange}
        />
        <div>ページ</div>
      </div>
      <Button
        disabled={!props.pagination?.nextPage}
        onClick={() => {
          handleNumberChange(props.current + 1)
        }}
        icon={<RightOutlined />}
      />
    </div>
  )
}

const styles = {
  container: css`
    padding: 16px 0;
    gap: 26px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  `,
  pageInput: css`
    box-sizing: content-box;
    width: 56px;
  `,
  pageInputContainer: css`
    gap: 4px;
    display: flex;
    align-items: center;
  `,
}
