import {
  GetReportsRequest,
  ReportListItem,
} from '@ulysses-inc/harami_api_client'
import { ReportsAction } from '../actions'
import { ActionTypes, GetReportsFilter } from '../types'

interface ReportsState {
  reports: ReportListItem[]
  offlineReports: ReportListItem[]
  isLoading: boolean
  error: Error | null
  count: number
  request: GetReportsRequest
  filter?: GetReportsFilter
  todayReports: ReportListItem[]
}

const initialReportsState: ReportsState = {
  reports: [],
  offlineReports: [],
  isLoading: false,
  error: null,
  count: 0,
  request: {
    offset: 0,
    limit: 25,
  },
  filter: undefined,
  todayReports: [],
}

const reports = (
  state: ReportsState = initialReportsState,
  action: ReportsAction,
): ReportsState => {
  switch (action.type) {
    case ActionTypes.REQUEST_GET_REPORTS: {
      return {
        ...state,
        isLoading: action.isLoading,
        request: action.request,
        filter: action.filter,
      }
    }
    case ActionTypes.SUCCESS_GET_REPORTS: {
      if (action.count === 0) {
        return {
          ...state,
          isLoading: action.isLoading,
          reports: [],
          error: null,
          count: 0,
        }
      }
      return {
        ...state,
        reports: action.reports,
        isLoading: action.isLoading,
        error: null,
        count: action.count,
      }
    }
    case ActionTypes.ERROR_GET_REPORTS: {
      return {
        ...state,
        isLoading: action.isLoading,
        error: action.error,
      }
    }
    case ActionTypes.REQUEST_CHANGE_REPORTS_PAGE: {
      return {
        ...state,
        request: action.request,
        filter: action.filter,
      }
    }
    case ActionTypes.REQUEST_CHANGE_REPORTS_SIZE: {
      return {
        ...state,
        request: action.request,
        filter: action.filter,
      }
    }
    case ActionTypes.UPDATE_PAGINATION: {
      return {
        ...state,
        request: action.request,
      }
    }
    case ActionTypes.REQUEST_DELETE_REPORT: {
      return {
        ...state,
        isLoading: action.isLoading,
      }
    }
    case ActionTypes.SUCCESS_DELETE_REPORT: {
      return {
        ...state,
        reports: state.reports.filter(
          report => report.uuid !== action.reportId,
        ),
        isLoading: action.isLoading,
        error: null,
      }
    }
    case ActionTypes.ERROR_DELETE_REPORT: {
      return {
        ...state,
        isLoading: action.isLoading,
        error: action.error,
      }
    }
    case ActionTypes.REQUEST_UPDATE_REPORT_PLACES: {
      return {
        ...state,
        isLoading: action.isLoading,
        request: action.request,
        filter: action.filter,
      }
    }
    case ActionTypes.SUCCESS_UPDATE_REPORT_PLACES: {
      return {
        ...state,
        isLoading: action.isLoading,
        error: null,
        reports: action.reports,
        count: action.count,
      }
    }
    case ActionTypes.ERROR_UPDATE_REPORT_PLACES: {
      return {
        ...state,
        isLoading: action.isLoading,
        error: action.error,
      }
    }
    default: {
      return { ...state }
    }
  }
}

export default reports
