import { CheckCircleFilled } from '@ant-design/icons'
import { Result } from 'antd'

/**
 * CSVファイルの送信に成功したときに表示するメッセージ
 */
export const ResultOfSuccess = () => {
  return (
    <Result
      icon={<CheckCircleFilled style={{ color: '#40C057' }} />}
      subTitle={
        <>
          ユーザー登録が完了したら担当者からご連絡いたします。
          <br />
          しばらくお待ちください。
        </>
      }
      title="アップロードが完了しました"
    />
  )
}
