import {
  TemplateNodeSchema,
  TemplateNodeTypeEnum,
} from '@ulysses-inc/harami_api_client'
import { Input } from 'antd'
import React from 'react'
import {
  Black,
  SectionBackgroundColor,
  White,
} from 'src/features/theme/KdsThemeColor'
import styled from 'styled-components'

const { TextArea } = Input

interface OwnProps {
  multiline?: boolean
  name: string
  node: TemplateNodeSchema
  onChangeText: (text: string) => void
  onUpdate: (text: string) => void
}

const SectionInput = styled(Input)`
  background: ${SectionBackgroundColor};
  color: ${White};
  &:focus {
    background: ${White};
    color: ${Black};
  }
`

/**
 * セクションタイトルまたは質問文を編集するためのコンポーネント
 */
const SelectNodeEditor: React.FC<OwnProps> = (props: OwnProps) => {
  const ENTER_KEY = 'Enter'
  const { multiline = false, node, name, onUpdate, onChangeText } = props

  const onChange: React.ChangeEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  > = e => {
    onUpdate(e.currentTarget.value)
  }

  const onKeyPress: React.KeyboardEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  > = e => {
    /**
     * Enterが押下されたときは次の質問にフォーカスを移す。ただし、以下の場合を除く。
     * - IMEの変換確定時
     *   参考）https://qiita.com/ledsun/items/31e43a97413dd3c8e38e#fn2
     * - Shift + Enter が押されたとき（textareaで改行を許容するため）
     */
    if (!e.shiftKey && e.key === ENTER_KEY) {
      // preventDefaultしないと、次の入力欄がtextareaだった場合に、先頭に空行が挿入されてしまう
      e.preventDefault()
      onChangeText(e.currentTarget.value)
    }
  }

  const commonProps = {
    onChange,
    onKeyPress,
    autoFocus: true,
    tabIndex: node.id,
    value: name,
  }

  const isSection = node.type === TemplateNodeTypeEnum.Section

  if (isSection) {
    return <SectionInput {...commonProps} type="text" maxLength={255} />
  }

  if (multiline) {
    return (
      <TextArea {...commonProps} autoSize placeholder="Shift + Enter で改行" />
    )
  }

  // 基本的にどのタイプの質問であっても複数行入力ができる仕様になったので、
  // これはいらなくなるかもしれないが、一応とっておく
  return <Input {...commonProps} type="text" />
}

export default SelectNodeEditor
