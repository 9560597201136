import { FC } from 'react'
import { CellInner, TableBodyCellWrapper } from './Common.dumb'

export const TableBodyCellNotCalculated: FC = () => {
  return (
    <TableBodyCellWrapper backgroundColor="fixed">
      <CellInner>未計算</CellInner>
    </TableBodyCellWrapper>
  )
}
