import { Employee, GetEmployeesRequest } from '@ulysses-inc/harami_api_client'
import { ActionTypes, GetEmployeesFilter } from './types'

export const getEmployees = (
  request: GetEmployeesRequest,
  filter?: GetEmployeesFilter,
) => {
  return {
    type: ActionTypes.REQUEST_GET_EMPLOYEES,
    isLoading: true,
    request,
    filter,
  }
}

const getSuccessEmployees = (employees: Employee[], count: number) => {
  return {
    type: ActionTypes.SUCCESS_GET_EMPLOYEES,
    isLoading: false,
    employees,
    count,
  }
}

const getErrorEmployees = (error: Error) => {
  return {
    type: ActionTypes.ERROR_GET_EMPLOYEES,
    isLoading: false,
    error,
  }
}

const changeEmployeesPage = (
  page: number,
  pageSize?: number,
  filter?: GetEmployeesFilter,
) => {
  const offset = pageSize ? (page - 1) * pageSize : 0
  return {
    type: ActionTypes.REQUEST_CHANGE_EMPLOYEES_PAGE,
    isLoading: true,
    request: {
      limit: pageSize,
      offset,
    },
    filter,
  }
}

const changeEmployeesSize = (pageSize: number, filter?: GetEmployeesFilter) => {
  return {
    type: ActionTypes.REQUEST_CHANGE_EMPLOYEES_SIZE,
    isLoading: true,
    request: {
      limit: pageSize,
      offset: 0,
    },
    filter,
  }
}

const updatePagination = (limit: number, offset: number) => {
  return {
    type: ActionTypes.UPDATE_PAGINATION,
    request: {
      limit,
      offset,
    },
  }
}

export const addEmployee = (employee: Omit<Employee, 'id' | 'uuid'>) => {
  return {
    type: ActionTypes.REQUEST_ADD_EMPLOYEE,
    isLoading: true,
    employee,
  }
}

const addSuccessEmployee = (employee: Employee) => {
  return {
    type: ActionTypes.SUCCESS_ADD_EMPLOYEE,
    isLoading: false,
    employee,
  }
}

const addErrorEmployee = (error: Error) => {
  return {
    type: ActionTypes.ERROR_ADD_EMPLOYEE,
    isLoading: false,
    error,
  }
}

export const updateEmployee = (employeeUUID: string, employee: Employee) => {
  return {
    type: ActionTypes.REQUEST_UPDATE_EMPLOYEE,
    isLoading: true,
    employee,
    employeeUUID,
  }
}

const updateSuccessEmployee = (employee: Employee) => {
  return {
    type: ActionTypes.SUCCESS_UPDATE_EMPLOYEE,
    isLoading: false,
    employee,
  }
}

const updateErrorEmployee = (error: Error) => {
  return {
    type: ActionTypes.ERROR_UPDATE_EMPLOYEE,
    isLoading: false,
    error,
  }
}

const deleteEmployee = (employeeUUID: string) => {
  return {
    type: ActionTypes.REQUEST_DELETE_EMPLOYEE,
    isLoading: true,
    employeeUUID,
  }
}

const deleteSuccessEmployee = (employeeUUID: string) => {
  return {
    type: ActionTypes.SUCCESS_DELETE_EMPLOYEE,
    isLoading: false,
    employeeUUID,
  }
}

const deleteErrorEmployee = (error: Error) => {
  return {
    type: ActionTypes.ERROR_DELETE_EMPLOYEE,
    isLoading: false,
    error,
  }
}

const deleteMultipleEmployees = (
  employees: Employee[],
  request: GetEmployeesRequest,
  filter?: GetEmployeesFilter,
) => {
  return {
    type: ActionTypes.REQUEST_DELETE_MULTIPLE_EMPLOYEES,
    isLoading: true,
    employees,
    request,
    filter,
  }
}

const deleteMultipleSuccessEmployees = (
  employees: Employee[],
  count: number,
) => {
  return {
    type: ActionTypes.SUCCESS_DELETE_MULTIPLE_EMPLOYEES,
    isLoading: false,
    employees,
    count,
  }
}

export const changeIsShowEditEmployeeDrawer = (
  isShowEditEmployeeDrawer: boolean,
) => {
  return {
    type: ActionTypes.CHANGE_IS_SHOW_EDIT_EMPLOYEE_DRAWER,
    isShowEditEmployeeDrawer,
  }
}

const updateActiveEmployeeUUID = (activeEmployeeUUID: string) => {
  return {
    type: ActionTypes.UPDATE_ACTIVE_EMPLOYEE_UUID,
    activeEmployeeUUID,
  }
}

const updateSegmentIndex = (index: string) => {
  return {
    type: ActionTypes.UPDATE_SEGMENT_INDEX,
    segmentIndex: index,
  }
}

const addMultipleEmployees = (
  employees: Omit<Employee, 'id' | 'uuid'>[],
  request: GetEmployeesRequest,
  filter?: GetEmployeesFilter,
) => {
  return {
    type: ActionTypes.REQUEST_ADD_MULTIPLE_EMPLOYEES,
    isLoading: true,
    employees,
    request,
    filter,
  }
}

const addSuccessMultipleEmployees = (employees: Employee[], count: number) => {
  return {
    type: ActionTypes.SUCCESS_ADD_MULTIPLE_EMPLOYEES,
    isLoading: false,
    employees,
    count,
  }
}

const addErrorMultipleEmployees = (error: Error) => {
  return {
    type: ActionTypes.ERROR_ADD_MULTIPLE_EMPLOYEES,
    isLoading: false,
    error,
  }
}

const updatePlaceNodeWarning = (message: string) => {
  return {
    type: ActionTypes.UPDATE_PLACE_NODE_WARNING,
    message,
  }
}

export type EmployeesAction =
  | ReturnType<typeof getEmployees>
  | ReturnType<typeof getSuccessEmployees>
  | ReturnType<typeof getErrorEmployees>
  | ReturnType<typeof changeEmployeesPage>
  | ReturnType<typeof changeEmployeesSize>
  | ReturnType<typeof updatePagination>
  | ReturnType<typeof addEmployee>
  | ReturnType<typeof addSuccessEmployee>
  | ReturnType<typeof addErrorEmployee>
  | ReturnType<typeof updateEmployee>
  | ReturnType<typeof updateSuccessEmployee>
  | ReturnType<typeof updateErrorEmployee>
  | ReturnType<typeof deleteEmployee>
  | ReturnType<typeof deleteSuccessEmployee>
  | ReturnType<typeof deleteErrorEmployee>
  | ReturnType<typeof deleteMultipleEmployees>
  | ReturnType<typeof deleteMultipleSuccessEmployees>
  | ReturnType<typeof changeIsShowEditEmployeeDrawer>
  | ReturnType<typeof updateActiveEmployeeUUID>
  | ReturnType<typeof updateSegmentIndex>
  | ReturnType<typeof addMultipleEmployees>
  | ReturnType<typeof addSuccessMultipleEmployees>
  | ReturnType<typeof addErrorMultipleEmployees>
  | ReturnType<typeof updatePlaceNodeWarning>

export default {
  getEmployees,
  getSuccessEmployees,
  getErrorEmployees,
  changeEmployeesPage,
  changeEmployeesSize,
  updatePagination,
  addEmployee,
  addSuccessEmployee,
  addErrorEmployee,
  updateEmployee,
  updateSuccessEmployee,
  updateErrorEmployee,
  deleteEmployee,
  deleteSuccessEmployee,
  deleteErrorEmployee,
  deleteMultipleEmployees,
  deleteMultipleSuccessEmployees,
  changeIsShowEditEmployeeDrawer,
  updateActiveEmployeeUUID,
  updateSegmentIndex,
  addMultipleEmployees,
  addSuccessMultipleEmployees,
  addErrorMultipleEmployees,
  updatePlaceNodeWarning,
}
