import {
  ResponseTypeEnum,
  TemplateNodeSchema,
  TemplateNodeTypeEnum,
} from '@ulysses-inc/harami_api_client'
import { Dispatch, SetStateAction } from 'react'

export type TReactState<T> = [T, Dispatch<SetStateAction<T>>]

export const checkIsEmployeeCheck = (
  parentNode: TemplateNodeSchema | null,
  { id, type, question }: TemplateNodeSchema,
): boolean =>
  !!(
    parentNode?.section?.isEmployeeCheck &&
    type === TemplateNodeTypeEnum.Question &&
    question?.responseType === ResponseTypeEnum.EMPLOYEE &&
    id === parentNode.nodes[0]
  )
