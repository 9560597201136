import { appEnv } from 'src/util/appEnv'

const localNodeUUIDs = [
  '84b42dc0-d2ae-4716-9f8a-3eef1ba2b9ca',
  'a064602b-961b-46e6-9a7e-f0538d51c2c0',
  '3bc73b0d-44b7-43de-86f8-9f7263a5fd21',
  '9ff12b55-05c1-486d-a64b-4cffa181a1f9',
]

const devNodeUUIDs = [
  '491a5c82-d414-4c8c-854d-a50a9260da72',
  'da19b4a0-55ca-41fd-a254-be88e31f2f48',
  'c6ce0e0b-8af6-4537-b0f4-ed14724fd44b',
  'cdb31a18-c032-4d59-8af7-cbac1523bc22',
  '9e1cca09-2eae-45ff-913f-2cc9dda892a0',
  '81edc50d-1b9a-42b9-90a5-712914503743',
  '4dbe34c0-58b0-445c-b5c8-8d49e86e6ac9',
]

const prodNodeUUIDs = [
  //--------------- company_id: 112, company_name: 虎昭産業_茨城工場 ---------------//
  /* ひな形: 【カミナシ作成】★【パン・F便】生野菜 殺菌帳票 */
  /* ページ: 【TP用生野菜殺菌 連続式（ラクーン）】 */
  // 質問: 殺菌濃度
  '72fac246-ce9a-478f-bae0-4584c1cfa62c',
  // 質問: 殺菌水pH
  '41ccd0a0-9a00-414f-9ddb-f6aa1bc3ce1d',
  // 質問: チラー水温
  'f2ae55ad-7106-4770-a6be-61a1c341c9ae',
  /* ページ: 【サラダ用生野菜殺菌 連続式（ラクーン）】 */
  // 質問: 殺菌濃度
  '041238d3-3666-4304-9243-64a0ce0f616c',
  // 質問: 殺菌水pH
  '9cbd20cf-a2f3-4eb3-9797-81f33add4fd7',
  // 質問: チラー水温
  '74e1ae7d-f142-4a80-807d-921f3510bcfe',
  /* 【カミナシ作成】★【パン・N便】生野菜 殺菌帳票 */
  /* ページ: 【TP用生野菜殺菌 連続式（ラクーン）】 */
  // 質問: 殺菌濃度
  '4b253b4d-2943-4d8b-89f2-d24562ac052a',
  // 質問: 殺菌水pH
  'f6f23824-c72a-47cb-aaf9-51eb7d2f0a9a',
  // 質問: チラー水温
  '6de6682c-adeb-487e-b5ba-92f52bee1ec3',
  /* ページ: 【サラダ用生野菜殺菌 連続式（ラクーン）】 */
  // 質問: 殺菌濃度
  '150428bf-7bd8-4704-9fbf-0e6cdd7db21f',
  // 質問: 殺菌水pH
  '4b680d78-163e-430f-abec-5c5e53e39caf',
  // 質問: チラー水温
  '93a497b2-884a-4d19-9c5a-f82d6457b6c4',
  /* ひな形: 【カミナシ作成】★【パン・Y便】生野菜 殺菌帳票 */
  /* ページ: 【TP用生野菜殺菌 連続式（ラクーン）】 */
  // 質問: 殺菌濃度
  '4326b1cf-a315-4502-a687-56554229df1a',
  // 質問: 殺菌水pH
  'b53a37b1-1f45-4882-8520-d505f8737d5e',
  // 質問: チラー水温
  '17d165e3-aa42-4314-a3ee-6009ff3be325',
  /* ページ: 【サラダ用生野菜殺菌 連続式（ラクーン）】 */
  // 質問: 殺菌濃度
  '09a8d9fa-9459-41d8-b4be-1e398ad4b75e',
  // 質問: 殺菌水pH
  'ad4d4210-1484-4287-bcff-7693d8714157',
  // 質問: チラー水温
  '83c535a6-dcb2-44d1-8305-124064121f42',
  /* ひな形: 【カミナシ作成】★【惣菜・N便】生野菜 殺菌帳票 */
  /* ページ: 【TP用生野菜殺菌 連続式（ラクーン）】 */
  // 質問: 殺菌濃度
  '372cc7b3-0588-4036-83f4-ed822c8de958',
  // 質問: 殺菌水pH
  '5f91e59b-ba46-4123-a09e-e8f49fa3bcf6',
  // 質問: チラー水温
  'fb944e45-ef1a-47bd-b43e-e34379e46def',
  /* ページ: 【サラダ用生野菜殺菌 連続式（ラクーン）】 */
  // 質問: 殺菌濃度
  '7e96d8d0-00c8-4302-8c1b-52fb384a3f8d',
  // 質問: 殺菌水pH
  '5b93b21b-39ab-4df7-a097-8dd9a16661b3',
  // 質問: チラー水温
  '75870567-7bf6-4711-862e-fb6ce4b00f3f',
  /* ひな形: 【カミナシ作成】★【惣菜・Y便】生野菜 殺菌帳票 */
  /* ページ: 【TP用生野菜殺菌 連続式（ラクーン）】 */
  // 質問: 殺菌濃度
  '06239cd8-1088-479c-9126-a39536adcaf2',
  // 質問: 殺菌水pH
  '382d6ac8-c03d-4269-8e0f-a2cc322d23d1',
  // 質問: チラー水温
  '70cb617b-d9aa-499a-bb9f-513897a34ef0',
  /* ページ: 【サラダ用生野菜殺菌 連続式（ラクーン）】 */
  // 質問: 殺菌濃度
  '2e90f79a-9966-4973-80b3-60c8c20fbaf0',
  // 質問: 殺菌水pH
  '432febdb-da31-48a6-be60-2151a8459092',
  // 質問: チラー水温
  '6bd217fa-ba93-4ce2-b56d-087aa502d936',
  //------------------------------//

  //--------------- company_id: 2, company_name: カミナシ ---------------//
  /* ページ: 【TP用生野菜殺菌 連続式（ラクーン）】 */
  // 質問: 殺菌濃度
  '46d1059c-3fd0-413a-99f4-7d75f7939eb8',
  // 質問: 殺菌水pH
  '706ae3df-de42-446e-9f93-c3fdd8caccd0',
  // 質問: チラー水温
  'b559d6c2-2398-4507-9b43-2c7c9b837efb',
]

// IoT 機器の読み取りボタンの表示対象の場合、 true を返す
export function isIoT(nodeUUID: string | undefined): boolean {
  let nodeUUIDs: string[]
  if (appEnv.isLocal) {
    nodeUUIDs = localNodeUUIDs
  } else if (appEnv.isDevelopment) {
    nodeUUIDs = devNodeUUIDs
  } else if (appEnv.isProduction) {
    nodeUUIDs = prodNodeUUIDs
  } else {
    nodeUUIDs = []
  }

  return nodeUUID && nodeUUIDs.includes(nodeUUID) ? true : false
}
