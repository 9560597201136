import { Typography } from 'antd'
import { TextProps } from 'antd/lib/typography/Text'
import { FormikErrors, FormikTouched, getIn } from 'formik'

const { Text } = Typography

type Props = {
  name: string
  touched?: FormikTouched<any>
  errors: FormikErrors<any>
} & TextProps

const FormikErrorMessage = (props: Props) => {
  const { name, touched, errors, ...other } = props
  const error = getIn(props.errors, props.name)
  const touch =
    props.touched !== undefined ? getIn(props.touched, props.name) : true
  return (
    <>
      {touch && error && (
        <Text type="danger" {...other}>
          {error}
        </Text>
      )}
    </>
  )
}

export default FormikErrorMessage
