import {
  Image,
  IndicatedIssue,
  IndicatedIssueLabel,
} from '@ulysses-inc/harami_api_client'
import { combineReducers } from 'redux'
import {
  IndicatedIssueLabelAction,
  IndicatedIssueReviseAction,
} from './actions'
import { ActionTypes } from './types'

interface IndicatedIssueDetailState {
  indicatedIssue: IndicatedIssue
  indicatedIssueId: string
  isLoading: boolean
  image: Image
  file?: File
  error: Error | null
  segmentIndex: number
}

const initialIndicatedIssueDetailState = {
  indicatedIssue: {},
  isLoading: false,
  indicatedIssueId: '',
  image: {},
  error: null,
  segmentIndex: 0,
}

const indicatedIssueDetail = (
  state: IndicatedIssueDetailState = initialIndicatedIssueDetailState,
  action: IndicatedIssueReviseAction,
): IndicatedIssueDetailState => {
  switch (action.type) {
    case ActionTypes.REQUEST_UPDATE_INDICATED_ISSUE_REVISES: {
      return {
        ...state,
        isLoading: action.isLoading,
        error: null,
      }
    }
    case ActionTypes.SUCCESS_UPDATE_INDICATED_ISSUE_REVISES: {
      return {
        ...state,
        isLoading: action.isLoading,
        error: null,
      }
    }
    case ActionTypes.ERROR_UPDATE_INDICATED_ISSUE_REVISES: {
      return {
        ...state,
        isLoading: action.isLoading,
        error: action.error,
      }
    }
    default: {
      return { ...state }
    }
  }
}

interface IndicatedIssueLabelState {
  indicatedIssueLabels: Array<IndicatedIssueLabel>
  indicatedIssueLabel?: IndicatedIssueLabel
  isShowDrawer: boolean
  isLoading: boolean
  error: Error | null
}

const indicatedIssueLabelInitialState = {
  indicatedIssueLabels: [],
  indicatedIssueLabel: {
    name: '',
    color: '',
  },
  isShowDrawer: false,
  isLoading: false,
  error: null,
}

const indicatedIssueLabel = (
  state: IndicatedIssueLabelState = indicatedIssueLabelInitialState,
  action: IndicatedIssueLabelAction,
) => {
  switch (action.type) {
    case ActionTypes.REQUEST_GET_INDICATED_ISSUE_LABELS: {
      return { ...state, isLoading: action.isLoading }
    }
    case ActionTypes.SUCCESS_GET_INDICATED_ISSUE_LABELS: {
      return {
        ...state,
        isLoading: action.isLoading,
        error: null,
        indicatedIssueLabels: action.indicatedIssueLabels,
      }
    }
    // 以下のコードには到達し得ない。所要のコード修正がされるまで一旦コメントアウトする。
    // case ActionTypes.ERROR_GET_INDICATED_ISSUE_LABELS: {
    //   return { ...state, error: action.error, isLoading: action.isLoading }
    // }
    case ActionTypes.REQUEST_ADD_INDICATED_ISSUE_LABEL: {
      return {
        ...state,
        indicatedIssueLabel: undefined,
      }
    }
    case ActionTypes.SUCCESS_ADD_INDICATED_ISSUE_LABEL: {
      return {
        ...state,
        indicatedIssueLabels: [
          ...state.indicatedIssueLabels,
          action.indicatedIssueLabel,
        ],
        indicatedIssueLabel: undefined,
        error: null,
        isShowDrawer: false,
      }
    }
    case ActionTypes.REQUEST_UPDATE_INDICATED_ISSUE_LABEL: {
      return {
        ...state,
        indicatedIssueLabel: undefined,
      }
    }
    case ActionTypes.SUCCESS_UPDATE_INDICATED_ISSUE_LABEL: {
      const newIndicatedIssueLabels = state.indicatedIssueLabels.map(tl => {
        if (tl.uuid === action.indicatedIssueLabel.uuid) {
          return action.indicatedIssueLabel
        }
        return tl
      })
      return {
        ...state,
        indicatedIssueLabels: [...newIndicatedIssueLabels],
        indicatedIssueLabel: undefined,
        error: null,
        isShowDrawer: false,
      }
    }
    case ActionTypes.SUCCESS_DELETE_INDICATED_ISSUE_LABEL: {
      const newIndicatedIssueLabels = state.indicatedIssueLabels.filter(
        tl => tl.uuid !== action.uuid,
      )
      return {
        ...state,
        indicatedIssueLabels: [...newIndicatedIssueLabels],
        error: null,
      }
    }
    case ActionTypes.ERROR_ADD_INDICATED_ISSUE_LABEL: {
      return { ...state, error: action.error }
    }
    case ActionTypes.RESET_EDIT_INDICATED_ISSUE_LABEL: {
      return { ...state, indicatedIssueLabel: undefined }
    }
    case ActionTypes.CHANGE_EDIT_INDICATED_ISSUE_LABEL: {
      return {
        ...state,
        indicatedIssueLabel: { ...action.indicatedIssueLabel },
      }
    }
    case ActionTypes.CHANGE_IS_SHOW_DRAWER: {
      return { ...state, isShowDrawer: action.isShowDrawer }
    }

    default: {
      return { ...state }
    }
  }
}

const indicatedIssuesReducer = combineReducers({
  indicatedIssueDetail,
  indicatedIssueLabel,
})

export default indicatedIssuesReducer
