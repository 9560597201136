import { DownOutlined, UpOutlined } from '@ant-design/icons'
import { Button, Tabs, Typography } from 'antd'
import { BorderColor, White } from 'src/features/theme/KdsThemeColor'
import styled from 'styled-components'

export const Body = styled.div`
  padding: 2rem;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  max-width: 1024px;
  position: relative;
  flex-direction: column;
`

export const WrapTabs = styled(Tabs)`
  & > .ant-tabs-nav {
    padding-left: 16px;
  }
`

// DashboardStatusTable
export const DropDownArea = styled.div`
  border-collapse: separate;
  border-spacing: 8px 0;
  margin-left: 8px;
`

export const DateTitle = styled.div<{ isSchedule: boolean }>`
  width: 400px;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  ${({ isSchedule }) => (isSchedule ? 'max-width: 100%;' : '')}
`
export const PrevWeek = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  opacity: ${({ theme }) => theme.opacity};
  cursor: pointer;
`

export const NextWeek = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  opacity: ${({ theme }) => theme.opacity};
  cursor: pointer;
`

export const TableWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`

// PlaceFilterDropDown, StatusFilterDropDown
const { Text } = Typography

export const TableCell = styled.div`
  display: table-cell;
  border: 1px solid ${BorderColor};
  background: ${White};
  vertical-align: middle;
`

export const FilterButton = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  cursor: pointer;
`

export const FilterLabel = styled.div`
  flex: 1;
  margin-left: 12px;
`

export const ExpandIconWrap = styled.div`
  min-width: 20px;
  margin-right: 12px;
`

export const UpOutlinedIcon = styled(UpOutlined)`
  color: rgba(0, 0, 0, 0.65);
`

export const DownOutlinedIcon = styled(DownOutlined)`
  color: rgba(0, 0, 0, 0.65);
`

export const SelectFilterTableCell = styled(TableCell)`
  min-width: ${({ theme }) => theme.minWidth};
  background-color: ${({ theme }) => theme.backgroundColor};
  height: 32px;
`

// PlaceFilterDropDown
export const SelectLabelWrap = styled.div`
  margin-top: 16px;
  margin-bottom: 5px;
`

export const SelectLabel = styled(Text)`
  margin-left: 3px;
`

export const DropDownTableCellForPlaceFilterDropDown = styled(TableCell)`
  width: 400px;
  background-color: ${White};
  padding: 16px;
`

// StatusFilterDropDown
export const FilterSelectButton = styled(Button)`
  justify-content: flex-start;
  width: 100%;
`

export const DropDownTableCellForStatusFilterDropDown = styled(TableCell)`
  background-color: ${White};
  width: 200px;
`

// DashboardSceneComponent, DashboardSceneInvalidCountTableUtil
export const StatusCellDisable = styled.div`
  width: 56px;
  height: 56px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: ${props => props?.theme?.background || 'none'};
`

export const PlaceLabel = styled.div`
  display: flex;
  justify-content: center;
  text-align: left;
  white-space: pre-wrap;
  word-break: break-all;
`

export const TableHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`

export const DayOfTheMonth = styled.div`
  font-size: 21px;
  color: ${props => props?.theme?.color || 'black'};
`

export const DayOfTheWeek = styled.div`
  font-size: 14px;
  color: ${props => props?.theme?.color || 'black'};
`

// DashboardSceneComponent
export const MonthLabel = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 60px;
  width: 450px;
  font-size: 24px;
  font-weight: bold;
`

export const StatusCell = styled.div`
  width: 56px;
  height: 56px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: ${props => props?.theme?.background || 'none'};
  &:hover {
    box-shadow:
      0px 2px 5px -1px rgba(0, 0, 0, 0.1),
      0px 1px 10px 0px rgba(0, 0, 0, 0.08),
      0px 1px 15px 0px rgba(0, 0, 0, 0.12) !important;
    transform: translate3d(0, -4px, 0);
  }
`

// DashboardSceneInvalidCountTableUtil
export const InvalidCell = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 56px;
  height: 56px;
  font-size: 18px;
  font-weight: bold;
  background: ${props => props?.theme?.background || 'none'};
  color: ${props => props?.theme?.color || 'black'};
`
