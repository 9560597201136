import { CompanySettings } from '@ulysses-inc/harami_api_client'
import { ActionTypes } from './types'

export const getCompanySettings = () => {
  return {
    type: ActionTypes.REQUEST_GET_COMPANY_SETTINGS,
    isLoading: true,
  }
}

const getSuccessCompanySettings = (companySettings: CompanySettings) => {
  return {
    type: ActionTypes.SUCCESS_GET_COMPANY_SETTINGS,
    isLoading: false,
    companySettings,
  }
}

const getErrorCompanySettings = (error: Error) => {
  return {
    type: ActionTypes.ERROR_GET_COMPANY_SETTINGS,
    isLoading: false,
    error,
  }
}

export type GetCompanySettingsAction =
  | ReturnType<typeof getCompanySettings>
  | ReturnType<typeof getSuccessCompanySettings>
  | ReturnType<typeof getErrorCompanySettings>

export const updateCompanySettings = (companySettings: CompanySettings) => {
  return {
    type: ActionTypes.REQUEST_UPDATE_COMPANY_SETTINGS,
    isLoading: true,
    companySettings,
  }
}

const updateSuccessCompanySettings = () => {
  return {
    type: ActionTypes.SUCCESS_UPDATE_COMPANY_SETTINGS,
    isLoading: false,
  }
}

const updateErrorCompanySettings = (error: Error) => {
  return {
    type: ActionTypes.ERROR_UPDATE_COMPANY_SETTINGS,
    isLoading: false,
    error,
  }
}

export type UpdateCompanySettingsAction =
  | ReturnType<typeof updateCompanySettings>
  | ReturnType<typeof updateSuccessCompanySettings>
  | ReturnType<typeof updateErrorCompanySettings>

export default {
  getCompanySettings,
  getSuccessCompanySettings,
  getErrorCompanySettings,
  updateCompanySettings,
  updateSuccessCompanySettings,
  updateErrorCompanySettings,
}
