import { AppRoleEnum } from '@ulysses-inc/harami_api_client'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { localStorageKeys } from 'src/constants/localStorageKeys'
import { ChannelTalkLauncher } from 'src/features/users/channelTalk/ChannelTalkLauncher'
import { getUser as getUserAction } from 'src/state/ducks/users/actions'
import { RootState } from 'src/state/store'
import { appEnv } from 'src/util/appEnv'

/**
 * チャネルトークの起動に必要となる情報を用意する責務を負うコンポーネント
 */
export const ChannelTalk = () => {
  // APIキー
  const pluginKey = import.meta.env['VITE_APP_CHANNEL_TALK_PLUGIN_KEY']

  // ユーザー情報
  const dispatch = useDispatch()
  const user = useSelector((state: RootState) => state.usersState.user.user)
  useEffect(() => {
    const currentUserId = localStorage.getItem(localStorageKeys.loginUserUuid)

    if (currentUserId) {
      dispatch(getUserAction(currentUserId))
    }
  }, [dispatch])

  // ユーザーロール名
  // TODO: Since it looks `user.role` always has `{id:0, role:0}`,
  //       we retrieve login user's role from the web browser's local storage instead.
  //       Btw, we should fix that `user.role`'s behavior if it's not intended.
  const userRoleVal = parseInt(
    localStorage.getItem(localStorageKeys.loginUserRole) ?? '0',
    10,
  )
  const userRoleName = roleToJapanese(userRoleVal)

  // 以下の経緯により、ブルーチップ社ではチャネルトークを表示しない
  // https://github.com/Ulysses-inc/harami_web/commit/3f3fe2a5edced88900961fd308d7c234c3eb407f
  const isBlueChip = ((companyName): boolean => {
    const regex = new RegExp(/^【BC】.*/)
    const conditions = companyName === undefined ? '' : companyName
    return regex.test(conditions)
  })(user?.company?.name)

  const shouldBootChannelTalk =
    pluginKey && user && !appEnv.isLocal && !isBlueChip

  if (!shouldBootChannelTalk) return null

  return (
    // コードをすっきりさせるためコンポーネントを分割した
    // (チャネルトークに必要な情報の用意と、チャネルトークの起動は別の責務)
    <ChannelTalkLauncher
      pluginKey={pluginKey}
      user={user}
      userRoleName={userRoleName}
      userCode={
        localStorage.getItem(localStorageKeys.loginUserRumpCustomerMemberId) ??
        ''
      }
      isIdAdmin={Boolean(
        parseInt(
          localStorage.getItem(localStorageKeys.loginUserIsRumpRealmAdmin) ||
            '0',
          10,
        ),
      )}
    />
  )
}

// FIXME: To reduce support team's cognitive load, we translate the role value into
//        Japanese labels instead of just using English words like OWNER/EDITOR/LEADER/REPORTER.
//        Obviously we're not taking an ideal way below since there is no common definition or
//        shared function to achieve this in the harami_client repository today.
//        Hope someone in the future fix this :D
function roleToJapanese(role: AppRoleEnum) {
  switch (role) {
    case AppRoleEnum.OWNER:
      return '管理者'
    case AppRoleEnum.EDITOR:
      return '編集者'
    case AppRoleEnum.LEADER:
      return 'リーダー'
    case AppRoleEnum.REPORTER:
      return 'レポート作成者'
    default:
      return '不明'
  }
}
