import { useCallback } from 'react'
import { useStore } from 'react-redux'
import { useSourceTemplateIdParam } from 'src/features/templateEdit/useSourceTemplateIdParam'
import { RootState } from 'src/state/store'

import {
  ErrorType,
  Operation,
  logGridLayoutSoftLimitErrorEvent,
} from '../firebase/featureSpecificEventLog/gridLayoutSoftLimitError'

/**
 * 表形式のひな形 ソフトリミット関係の firebase analytics への event log を生成する関数
 * を取得するためのカスタムフック
 *
 * @returns
 */
export const useGridLayoutSoftLimitErrorLog = (operation: Operation) => {
  const store = useStore<RootState>()
  const templateId = useSourceTemplateIdParam()

  const logSoftLimitErrorEvent = useCallback(
    (errorType: ErrorType, errorMessage: string) => {
      logGridLayoutSoftLimitErrorEvent(operation, {
        templateId,
        templateName: store.getState().templatesState.editTemplate.name,
        errorType,
        errorMessage,
      })
    },
    // グローバルな store への参照は変更されないはずなので、依存配列では特に意識しなくて良いはず
    // 参考： https://dev.to/dylangrandmont/it-s-ok-to-usestore-with-react-redux-1fia
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [operation],
  )

  return { logSoftLimitErrorEvent }
}
