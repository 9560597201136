import { css } from '@emotion/react'
import { FC } from 'react'
import Dot from 'src/assets/icons/dot.svg?react'

export interface PhotoSliderBulletsProps {
  bulletCount: number
  activeBulletIndex: number
  onClickBullet: (index: number) => void
}

export const PhotoSliderBullets: FC<PhotoSliderBulletsProps> = props => {
  const { bulletCount, activeBulletIndex, onClickBullet } = props

  return (
    <div css={styles.container}>
      {Array.from({ length: bulletCount }).map((_, index) => (
        <Dot
          key={index}
          css={styles.dot}
          fill={index === activeBulletIndex ? '#757575' : '#E9ECEF'}
          onClick={() => onClickBullet(index)}
        />
      ))}
    </div>
  )
}

const styles = {
  container: css`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
  `,
  dot: css`
    cursor: pointer;
    margin: 0 3px;
  `,
}
