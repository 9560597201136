/**
 * localStorageのキーはグローバルなため一括管理する
 */
export const localStorageKeys = {
  /**
   * ユーザー情報
   */

  accessToken: 'accessToken',
  loginCompanyContractPlan: 'loginCompanyContractPlan',
  loginCompanyId: 'loginCompanyId',
  loginCompanyName: 'loginCompanyName',
  loginUserLoginId: 'loginUserLoginId', // TODO: ID統合が完了したら削除
  loginUserRumpCustomerMemberId: 'loginUserRumpCustomerMemberId',
  loginUserName: 'loginUserName',
  loginUserPlaceNodes: 'loginUserPlaceNodes',
  loginUserRole: 'loginUserRole',
  loginUserUuid: 'loginUserUuid',
  loginUserIsRumpRealmAdmin: 'loginUserIsRumpRealmAdmin', // bool 値は1 または 0 で保存する
  promoBanner: 'promoBanner', // TODO: 機能は消去したが localStorage のクリアのために key だけ残している。2024年12月以降に削除したい

  /**
   * その他
   */

  listFilter: 'listFilter', // 一覧系画面のフィルタ条件
  listFilterSchedules: 'listFilterSchedules', // スケジュール一覧画面のフィルタ条件
  preferShinReportList20240321: 'preferShinReportList20240321', // シン・レポート一覧を利用するかどうかの設定
  sortOrderTemplates: 'sortOrderTemplates', // ひな形一覧の並び替えの条件
} as const
