import { ReportNodeSchema } from '@ulysses-inc/harami_api_client'

/**
 * 与えられたノードが「繰り返しセクションマスタ」に該当するか判定する。
 *
 * Note: セクションには以下の3種類がある。
 * - 通常のセクション
 * - 繰り返しセクションマスタ(テンプレであり実データは持たない)
 * - 繰り返しセクションインスタンス(現場で入力された実データ)
 */
export const isRepeatedSectionMaster = (node: ReportNodeSchema) => {
  const isRepeatedSection = node.section?.isRepeat === 1
  const hasOriginalNode = !!node.section?.originalNodeUuid

  return isRepeatedSection && !hasOriginalNode
}
