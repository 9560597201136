import { StyleSheet, View } from '@react-pdf/renderer'
import { Employee } from '@ulysses-inc/harami_api_client'
import React from 'react'
import BreakText from '../../BreakText'
import Col from '../../Col'
import Grid from '../../Grid'
import RecordedTime from './RecordedTime'

interface OwnProps {
  questionName: string
  employees: Array<Employee>
  recordedAt?: Date
  isTimeDisplayed: boolean
}

const styles = StyleSheet.create({
  gridLeft: { width: '70%' },
  gridRight: { width: '30%' },
  fullWidthWrap: { width: '100%' },
})

const Employees: React.FC<OwnProps> = (props: OwnProps) => {
  return (
    <Grid style={styles.fullWidthWrap}>
      <Col borderLeft borderRight style={styles.gridLeft}>
        <BreakText text={props.questionName} />
      </Col>
      <Col borderRight style={{ ...styles.gridRight }}>
        <View style={styles.fullWidthWrap}>
          {props.employees.length > 0 &&
            props.employees.map((employee: Employee) => (
              <BreakText key={employee.id} text={employee.name ?? ''} />
            ))}
          {props.employees.length === 0 && <BreakText text="未回答" />}
        </View>
        {props.employees.length > 0 && (
          <View style={styles.fullWidthWrap}>
            <RecordedTime
              isTimeDisplayed={props.isTimeDisplayed}
              recordedAt={props.recordedAt}
            />
          </View>
        )}
      </Col>
    </Grid>
  )
}

export default Employees
