import {
  CellDefaultBackgroundColor,
  CellDefaultFontColor,
  CellFixedBackgroundColor,
  CellInvalidBackgroundColor,
  CellInvalidFontColor,
  CellUnAnsweredFontColor,
  TableBorderColor,
} from 'src/features/theme/KdsThemeColor'
import styled from 'styled-components'
import { bodyFontStyles, commonCellStyles } from '../styles'

type BackgroundColor = 'default' | 'invalid' | 'fixed'

const getBackgroundColor = (color?: BackgroundColor) => {
  switch (color) {
    case 'fixed':
      return CellFixedBackgroundColor
    case 'invalid':
      return CellInvalidBackgroundColor
    case 'default':
    default:
      return CellDefaultBackgroundColor
  }
}

type FontColor = 'default' | 'invalid' | 'notInputted'

const getFontColor = (color?: FontColor) => {
  switch (color) {
    case 'invalid':
      return CellInvalidFontColor
    case 'notInputted':
      return CellUnAnsweredFontColor
    case 'default':
    default:
      return CellDefaultFontColor
  }
}

export const TableBodyCellWrapper = styled.td<{
  backgroundColor?: BackgroundColor
  fontColor?: FontColor
  /* fontColorとcustomFontColorの両方が指定されていた場合、customFontColorが優先される */
  customFontColor?: string
}>`
  ${bodyFontStyles}
  ${commonCellStyles}

  background-color: ${props => getBackgroundColor(props.backgroundColor)};
  color: ${props =>
    props.customFontColor
      ? props.customFontColor
      : getFontColor(props.fontColor)};

  border-right: 1px solid ${TableBorderColor};
  border-bottom: 1px solid ${TableBorderColor};
`

export const CellInner = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`

export const CellNotWorked = styled.td`
  ${commonCellStyles}
  background-color: ${CellFixedBackgroundColor};
  padding: 0;

  border-right: 1px solid ${TableBorderColor};
  border-bottom: 1px solid ${TableBorderColor};
`

export const HorizontalLine = styled.div`
  height: 1px;
  background-color: ${TableBorderColor};
`
