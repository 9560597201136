import { Button, Col } from 'antd'
import styled from 'styled-components'

export const TableHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

export const CreateButton = styled(Button)`
  min-height: 88px;
  width: 20vw;
  padding: 12px 24px;
  justify-content: flex-start;
`

export const TableRow = styled.div`
  width: 70%;
  display: flex;
  flex-wrap: wrap;
  margin-left: 5vw;
`

export const ButtonWrapper = styled(Col)`
  margin: 16px 12px 0;
  &:last-child {
    margin-bottom: 24px;
  }
`

export const SvgTag = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
`

export const SvgTagIconWrap = styled.div`
  vertical-align: center;
  height: 48px;
`

export const Description = styled.div`
  width: 100%;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  text-align: center;
  margin-top: 64px;
`

export const SvgTagTitle = styled.span`
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  text-align: left;
  white-space: pre-wrap;
`
