import { CheckOutlined } from '@ant-design/icons'
import { IndicatedIssueLabel } from '@ulysses-inc/harami_api_client'
import { Drawer, Input } from 'antd'
import { FormikHelpers, FormikProps, useFormik } from 'formik'
import { FC, useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import FormikErrorMessage from 'src/components/formikErrorMessage/FormikErrorMessage'
import { UUID } from 'src/exShared/util/uuid'
import {
  Danger,
  Default,
  Info,
  Primary,
  Success,
  Warning,
  White,
} from 'src/features/theme/KdsThemeColor'
import { primitiveColors } from 'src/features/theme/primitiveColors'
import Yup from 'src/features/validation/yup'
import indicatedIssuesOperations from 'src/state/ducks/indicatedIssues/operations'
import { RootState } from 'src/state/store'
import FooterButton from 'src/views/components/drawer/FooterButton'
import styled from 'styled-components'

const THEME_COLORS = {
  InputBorder: primitiveColors.gray._4,
  InputBorderPrimary: primitiveColors.blue._4,
}

const IndicatedIssueLabelPageHeader = styled.div`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
`

const IndicatedIssueLabelArea = styled.div`
  margin-top: 15px;
  input {
    border-color: ${THEME_COLORS.InputBorder};
    &:hover {
      border-color: ${THEME_COLORS.InputBorderPrimary} !important;
    }
    &:focus {
      border-color: ${THEME_COLORS.InputBorderPrimary} !important;
    }
  }
`

const ColorCircles = styled.div`
  display: flex;
  height: 40px;
`

const ColorCircle = styled.div`
  width: 34px;
  height: 34px;
  border-radius: 50%;
  background: ${props => props.theme.backgroundColor};
  margin-right: 6px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    background-color: ${props => props.theme.backgroundColor};
    color: ${White};
    filter: brightness(110%);
    width: 40px;
    height: 40px;
  }
`

interface StateProps {
  indicatedIssueLabel?: IndicatedIssueLabel
  isShowDrawer: boolean
  isLoadingIndicatedIssueLabel: boolean
}

interface DispatchProps {
  addIndicatedIssueLabel: (indicatedIssueLabel: IndicatedIssueLabel) => void
  updateIndicatedIssueLabel: (indicatedIssueLabel: IndicatedIssueLabel) => void
  changeIsShowDrawer: (isShowDrawer: boolean) => void
}

interface MergeProps {
  addIndicatedIssueLabel: (indicatedIssueLabel: IndicatedIssueLabel) => void
  updateIndicatedIssueLabel: (indicatedIssueLabel: IndicatedIssueLabel) => void
}

type EditIndicatedIssueLabelProps = StateProps & DispatchProps & MergeProps

const useStateProps = (): StateProps => {
  return {
    indicatedIssueLabel: useSelector(
      (state: RootState) =>
        state.indicatedIssuesState.indicatedIssueLabel.indicatedIssueLabel,
      shallowEqual,
    ),
    isShowDrawer: useSelector(
      (state: RootState) =>
        state.indicatedIssuesState.indicatedIssueLabel.isShowDrawer,
      shallowEqual,
    ),
    isLoadingIndicatedIssueLabel: useSelector(
      (state: RootState) =>
        state.indicatedIssuesState.indicatedIssueLabel.isLoading,
      shallowEqual,
    ),
  }
}

const useDispatchProps = (): DispatchProps => {
  const dispatch = useDispatch()
  return {
    addIndicatedIssueLabel: (indicatedIssueLabel: IndicatedIssueLabel) => {
      indicatedIssuesOperations.addIndicatedIssueLabel(
        dispatch,
        indicatedIssueLabel,
      )
    },
    updateIndicatedIssueLabel: (indicatedIssueLabel: IndicatedIssueLabel) => {
      indicatedIssuesOperations.updateIndicatedIssueLabel(
        dispatch,
        indicatedIssueLabel,
      )
    },
    changeIsShowDrawer: (isShowDrawer: boolean) => {
      indicatedIssuesOperations.resetEditIndicatedIssueLabel(dispatch)
      indicatedIssuesOperations.changeIsShowDrawer(dispatch, isShowDrawer)
    },
  }
}

const useMergeProps = (
  _: StateProps,
  dispatchProps: DispatchProps,
): MergeProps => {
  return {
    addIndicatedIssueLabel: (indicatedIssueLabel: IndicatedIssueLabel) => {
      dispatchProps.addIndicatedIssueLabel(indicatedIssueLabel)
    },
    updateIndicatedIssueLabel: (indicatedIssueLabel: IndicatedIssueLabel) => {
      dispatchProps.updateIndicatedIssueLabel(indicatedIssueLabel)
    },
  }
}

const validationSchema = Yup.object().shape({
  name: Yup.string().required().label('ラベル名'),
})

const EditIndicatedIssueLabelDrawerScene: FC<
  EditIndicatedIssueLabelProps & FormikProps<IndicatedIssueLabel>
> = (
  props: EditIndicatedIssueLabelProps & FormikProps<IndicatedIssueLabel>,
) => {
  const { handleSubmit, setFieldValue } = props
  const [name, setName] = useState<string>()
  const [color, setColor] = useState<string>()

  useEffect(() => {
    if (props.indicatedIssueLabel) {
      setName(props.indicatedIssueLabel.name)
      setColor(props.indicatedIssueLabel.color)
      setFieldValue('name', props.indicatedIssueLabel.name)
      setFieldValue('color', props.indicatedIssueLabel.color)
    } else {
      setName('')
      setColor(Danger)
      setFieldValue('name', '')
      setFieldValue('color', Danger)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.indicatedIssueLabel?.name, props.indicatedIssueLabel?.color])

  return (
    <Drawer
      placement="right"
      closable={false}
      destroyOnClose={true}
      maskClosable={false}
      width={600}
      onClose={() => props.changeIsShowDrawer(false)}
      open={props.isShowDrawer}
      drawerStyle={{ padding: 0 }}
    >
      <IndicatedIssueLabelPageHeader>
        {props.indicatedIssueLabel?.uuid ? 'ラベルの編集' : 'ラベルの追加'}
      </IndicatedIssueLabelPageHeader>
      <IndicatedIssueLabelArea>
        <Input
          placeholder="ラベル名"
          onChange={e => {
            setName(e.target.value)
            setFieldValue('name', e.target.value)
          }}
          value={name}
        />
        <FormikErrorMessage
          name="name"
          errors={props.errors}
          touched={props.touched}
        />
      </IndicatedIssueLabelArea>
      <IndicatedIssueLabelArea>
        <ColorCircles>
          {color &&
            [Danger, Warning, Success, Primary, Info, Default].map(c => {
              return (
                <ColorCircle
                  onClick={() => {
                    setColor(c)
                    setFieldValue('color', c)
                  }}
                  theme={{ backgroundColor: c }}
                  key={c}
                >
                  {color.toUpperCase() === c.toUpperCase() && (
                    <CheckOutlined style={{ color: White }} />
                  )}
                </ColorCircle>
              )
            })}
        </ColorCircles>
      </IndicatedIssueLabelArea>

      <FooterButton
        spinning={props.isLoadingIndicatedIssueLabel}
        onCancel={() => props.changeIsShowDrawer(false)}
        onSubmit={() => handleSubmit()}
        isKds
      />
    </Drawer>
  )
}

const EditIndicatedIssueLabelDrawer: FC = () => {
  const stateProps = useStateProps()
  const dispatchProps = useDispatchProps()
  const mergeProps = useMergeProps(stateProps, dispatchProps)
  const formikProps = useFormik({
    initialValues: {
      uuid: stateProps.indicatedIssueLabel?.uuid || undefined,
      name: stateProps.indicatedIssueLabel?.name || '',
      color: stateProps.indicatedIssueLabel?.color || Danger,
    },
    validationSchema,
    onSubmit: (
      indicatedIssueLabel: IndicatedIssueLabel,
      formikHelpers: FormikHelpers<IndicatedIssueLabel>,
    ) => {
      if (!stateProps.indicatedIssueLabel?.uuid) {
        mergeProps.addIndicatedIssueLabel({
          ...indicatedIssueLabel,
          id: 0,
          uuid: UUID(),
        })
      } else {
        mergeProps.updateIndicatedIssueLabel({
          ...indicatedIssueLabel,
          uuid: stateProps.indicatedIssueLabel.uuid,
        })
      }
      formikHelpers.resetForm()
    },
    enableReinitialize: true,
  })
  const props = {
    ...stateProps,
    ...dispatchProps,
    ...mergeProps,
    ...formikProps,
  }
  return <EditIndicatedIssueLabelDrawerScene {...props} />
}

export default EditIndicatedIssueLabelDrawer
