import { CopyOutlined, FilterOutlined } from '@ant-design/icons'
import { css } from '@emotion/react'
import { Button, message } from 'antd'
import { ColumnType, ColumnsType } from 'antd/es/table'
import moment from 'moment/moment'
import { ErrorMessage } from 'src/components/errorMessage/ErrorMessage'
import { HEADER_HEIGHT } from 'src/components/header/Header'
import { RevalidationSpinner } from 'src/components/revalidationSpinner/RevalidationSpinner'
import { Spinner } from 'src/components/spinner/Spinner'
import { Table } from 'src/components/table/Table'
import { WithDrawer } from 'src/components/withDrawer/WithDrawer'
import { sessionStorageKeys } from 'src/constants/sessionStorageKeys'
import { FiltersDrawer } from 'src/features/scheduledReportSummary/FiltersDrawer'
import { ScheduledReportSummaryWrapper } from 'src/features/scheduledReportSummary/ScheduledReportSummaryWrapper'
import { useQueryScheduledReportSummariesWithDaysInfo } from 'src/features/scheduledReportSummary/api/useQueryScheduledReportSummariesWithDaysInfo'
import { DayHeaderCell } from 'src/features/scheduledReportSummary/components/DayHeaderCell'
import { LimitedRangePicker } from 'src/features/scheduledReportSummary/components/LimitedRangePicker'
import { StatusCell } from 'src/features/scheduledReportSummary/components/StatusCell'
import { SubHeader } from 'src/features/scheduledReportSummary/components/SubHeader'
import { toFriendlyTsv } from 'src/features/scheduledReportSummary/toFriendlyTsv'
import {
  ScheduledReportDailyDetail,
  ScheduledReportSummary as ScheduledReportSummaryType,
} from 'src/features/scheduledReportSummary/types'
import { useFilters } from 'src/features/scheduledReportSummary/useFilters'
import { useOnClickOutside } from 'src/hooks/useOnClickOutside'
import { useScrollRestorer } from 'src/hooks/useScrollRestorer'
import { useTogglable } from 'src/hooks/useTogglable'
import { checkIfToday } from 'src/util/datetime/checkIfToday'
import { formatToJapaneseDate } from 'src/util/datetime/formatToJapaneseDate'
import invariant from 'tiny-invariant'

export const maxSelectableDays = 7

const dayHeaderColumnWidth = 88

// ステータスセルでのみpaddingを0にしたいがためのハック
const cellClassName = 'scheduled-report-summary-status-cell'

export const ScheduledReportSummary = () => {
  useScrollRestorer(
    `.ant-table-body`,
    sessionStorageKeys.uiScheduledReportSummariesScrollY,
  )

  const { filters, isSomeFilterInDrawerActive, setFilterOf, resetFilterOf } =
    useFilters()

  const filterDrawer = useTogglable()

  const { classNameToIgnore, ref: drawerRef } =
    useOnClickOutside<HTMLDivElement>(filterDrawer.close)

  const summariesQuery = useQueryScheduledReportSummariesWithDaysInfo(filters)

  if (summariesQuery.isError) {
    return (
      <ScheduledReportSummaryWrapper withPadding>
        <ErrorMessage>エラーが発生しました</ErrorMessage>
      </ScheduledReportSummaryWrapper>
    )
  }

  const { targetDates = [], summaries = [] } = summariesQuery.data || {}

  const copyDataIntoClipboard = async () => {
    if (summariesQuery.isPending) return
    const tsv = toFriendlyTsv(summariesQuery.data)
    await navigator.clipboard.writeText(tsv)
    message
      .success({ content: 'クリップボードにコピーしました', duration: 1.5 })
      .then()
  }

  const dailyDetailColumns = targetDates.map(
    (targetDay, index): ColumnType<ScheduledReportSummaryType> => {
      return {
        className: cellClassName,
        // antdのAPIの仕様上、「このカラムにはこの配列の何番目のデータを表示する」という指定はできない。
        // このため、配列全体をdataIndexに指定し、render関数でindexを使って値を取得している。
        dataIndex: ['dailyDetails'],
        render: (details: ScheduledReportDailyDetail[]) => {
          const status = details[index]?.status
          invariant(status)
          return (
            <StatusCell
              status={status}
              reportUuid={details[index]?.reportUuid}
            />
          )
        },
        title: () => {
          return (
            <DayHeaderCell isActive={checkIfToday(targetDay)}>
              {formatToJapaneseDate(targetDay)}
            </DayHeaderCell>
          )
        },
        width: dayHeaderColumnWidth,
      }
    },
  )

  const columns: ColumnsType<ScheduledReportSummaryType> = [
    {
      dataIndex: 'placeName',
      ellipsis: true,
      fixed: 'left',
      title: '現場名',
      width: 184,
    },
    {
      dataIndex: 'scheduleName',
      ellipsis: true,
      fixed: 'left',
      title: 'スケジュール名',
      width: 264,
    },
    ...dailyDetailColumns,
    {
      key: 'spacer',
    },
  ]

  return (
    <ScheduledReportSummaryWrapper>
      <SubHeader
        leftPane={
          <LimitedRangePicker
            onChange={(_, [startDate, endDate]) => {
              // なお、antdの仕様上ここの値が片方だけ空文字になることはない
              if (startDate === '' && endDate === '') {
                resetFilterOf.dateRange()
              }
              setFilterOf.dateRange(startDate, endDate)
            }}
            placeholder={['開始日', '終了日']}
            value={
              filters.dateRange && [
                moment(filters.dateRange[0]),
                moment(filters.dateRange[1]),
              ]
            }
          />
        }
        rightPane={
          <div css={styles.headerRightPane}>
            <Button
              disabled={summariesQuery.isPending}
              icon={<CopyOutlined />}
              onClick={copyDataIntoClipboard}
            >
              データをコピー
            </Button>
            <Button
              className={classNameToIgnore}
              icon={<FilterOutlined />}
              onClick={filterDrawer.toggle}
              style={{
                background: isSomeFilterInDrawerActive ? '#C7F1FF' : undefined,
              }}
            >
              フィルター
            </Button>
          </div>
        }
      />

      <WithDrawer
        drawerContent={
          <FiltersDrawer
            filters={filters}
            onCloseButtonClicked={filterDrawer.close}
            resetFilterOf={resetFilterOf}
            setFilterOf={setFilterOf}
          />
        }
        drawerRef={drawerRef}
        isDrawerOpen={filterDrawer.isOpen}
        onCloseDrawer={filterDrawer.close}
      >
        <div css={css(styles.tableArea)}>
          <Table
            columns={columns}
            dataSource={summaries}
            kaminashiOption={{ disableRowHoverBackground: true }}
            loading={{
              indicator: <Spinner />,
              // キャッシュがない場合(≒初回のデータ取得時)に表示される、画面をブロックするタイプのスピナー
              spinning: summariesQuery.isPending,
            }}
            pagination={false}
            rowKey={record => `${record.placeNodeUuid}-${record.scheduleUuid}`}
            scroll={{ y: styles.tableRowsHeight }}
            size="small"
          />
        </div>
      </WithDrawer>
      {
        // キャッシュがある場合(≒2回目以降のデータ取得時)に表示される、画面をブロックしないタイプのスピナー
        summariesQuery.isRefetching && <RevalidationSpinner />
      }
    </ScheduledReportSummaryWrapper>
  )
}

const styles = {
  headerRightPane: css`
    display: flex;
    gap: 16px;
  `,
  tableArea: css`
    background: white;
    // 余っている高さをすべて占有したい
    height: calc(
      100vh -
        ${
          HEADER_HEIGHT + 64 // SubHeader
        }px
    );
    .ant-table-cell.${cellClassName} {
      padding: 0 !important;
    }
  `,
  // 行エリアだけを縦スクロール可能にしたい
  tableRowsHeight: `calc(100vh - ${
    72 + // Header
    64 + // SubHeader
    39 // Table Header
  }px)`,
}
