import { takeEvery } from 'redux-saga/effects'
import { ActionTypes } from '../types'
import { getImprove } from './getImprove'
import { deleteImprove, getImproves } from './improves'
import { updateImprove } from './updateImprove'

const sagas = [
  takeEvery(ActionTypes.REQUEST_GET_IMPROVE, getImprove),
  takeEvery(ActionTypes.REQUEST_UPDATE_IMPROVE, updateImprove),
  takeEvery(ActionTypes.REQUEST_DELETE_IMPROVE, deleteImprove),
  takeEvery(ActionTypes.REQUEST_GET_IMPROVES, getImproves),
  takeEvery(ActionTypes.REQUEST_CHANGE_IMPROVES_PAGE, getImproves),
  takeEvery(ActionTypes.REQUEST_CHANGE_IMPROVES_SIZE, getImproves),
]

export default sagas
