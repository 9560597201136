import {
  ReportNodeSchema,
  ReportPageSchema,
  ReportQuestion,
  ResponseTypeEnum,
} from '@ulysses-inc/harami_api_client'
import { shallowEqual, useSelector } from 'react-redux'
import { ReportNodesDict } from 'src/exShared/types/types'
import {
  selectBodyRows,
  selectHeaderRow,
} from 'src/state/ducks/reports/reportResult/grid/selectors'

const CHUNK_SIZE = 11

export type GirdLayoutPage = {
  pageName: string
  sectionName: string
  headerCellNodes: ReportNodeSchema[]
  bodyRowNodes: ReportNodeSchema[][]
}

export const getGridLayoutPages = (
  pages: ReportPageSchema[],
  allNodes: ReportNodesDict,
): { [key: string]: GirdLayoutPage } => {
  const girdLayoutPageList: { [key: string]: GirdLayoutPage } = {}
  pages.map((page: ReportPageSchema) => {
    if (!page) return

    const originalSectionNodeIds =
      page.nodes?.filter(
        nodeId => !allNodes[nodeId]?.section?.originalNodeUuid,
      ) ?? []

    originalSectionNodeIds.map((originalSectionNodeId: number) => {
      // 表示する質問名を取得する
      const headerRow = useSelector(
        selectHeaderRow(originalSectionNodeId),
        shallowEqual,
      )

      // 取り込みありの項目で表示できる項目が1つでもある
      // もしくは取り込み項目が1つもなく他の表示項目(数値やテキストなど)場合、PDF に出力する
      const shouldShowTable = headerRow
        .map(r => r.question)
        .filter((item): item is ReportQuestion => !!item)
        .some(
          q =>
            q.responseType !== ResponseTypeEnum.GRID_VARIABLE ||
            (q.responseType === ResponseTypeEnum.GRID_VARIABLE &&
              q.responseGridVariables &&
              q.responseGridVariables[0]?.isEnabled),
        )
      if (!shouldShowTable) {
        return
      }

      // 各表毎に最初のページはページ名、セクション名を設定する
      girdLayoutPageList[`${originalSectionNodeId}-${0}`] = {
        pageName: page.name,
        sectionName: allNodes[originalSectionNodeId]?.section?.name ?? '',
        headerCellNodes: [],
        bodyRowNodes: [],
      }

      // 表示する質問名を分割する
      splitHeaderRows(girdLayoutPageList, headerRow, originalSectionNodeId)

      // 表示する回答を取得、分割する
      const bodyRows = useSelector(
        selectBodyRows(page.id || 0, originalSectionNodeId),
        shallowEqual,
      )
      splitBodyRows(girdLayoutPageList, bodyRows, originalSectionNodeId)
    })
  })
  return girdLayoutPageList
}

/**
 * 表形式のヘッダー行を PDF に表示する最大列数で分割する
 * @param girdLayoutPageList
 * @param headerRows
 * @param originalSectionNodeId
 */
const splitHeaderRows = (
  girdLayoutPageList: { [key: string]: GirdLayoutPage },
  headerRows: ReportNodeSchema[],
  originalSectionNodeId: number,
) => {
  let headerPageNumber = 0
  const girdLayoutPage = girdLayoutPageList[`${originalSectionNodeId}-${0}`]
  headerRows.forEach(headerRow => {
    if (!girdLayoutPage) {
      return
    }
    // 質問数が上限に到達した場合、次のページを設定する
    if (girdLayoutPage.headerCellNodes.length >= CHUNK_SIZE) {
      girdLayoutPageList[`${originalSectionNodeId}-${headerPageNumber}`] =
        JSON.parse(JSON.stringify(girdLayoutPage))

      // 新しいページを設定する
      girdLayoutPage.headerCellNodes = []
      girdLayoutPage.bodyRowNodes = []
      headerPageNumber++
    }
    girdLayoutPage.headerCellNodes.push(headerRow)
  })
  girdLayoutPageList[`${originalSectionNodeId}-${headerPageNumber}`] =
    JSON.parse(JSON.stringify(girdLayoutPage))
}

/**
 * 表形式の回答内容を PDF に表示する最大列数で分割する
 * @param girdLayoutPageList
 * @param headerRows
 * @param originalSectionNodeId
 */
const splitBodyRows = (
  girdLayoutPageList: { [key: string]: GirdLayoutPage },
  bodyRowNodes: ReportNodeSchema[][],
  originalSectionNodeId: number,
) => {
  bodyRowNodes.forEach((bodyRowNode, index) => {
    let bodyPageNumber = 0
    let bodyRows: ReportNodeSchema[] = []
    let currentGridLayoutPage: GirdLayoutPage | undefined =
      girdLayoutPageList[`${originalSectionNodeId}-${bodyPageNumber}`]

    if (!currentGridLayoutPage) {
      return
    }

    bodyRowNode.forEach(node => {
      if (!currentGridLayoutPage) {
        return
      }
      if (bodyRows.length >= CHUNK_SIZE) {
        if (index in currentGridLayoutPage.bodyRowNodes) {
          currentGridLayoutPage.bodyRowNodes[index]?.push(
            JSON.parse(JSON.stringify(bodyRows)),
          )
        } else {
          currentGridLayoutPage.bodyRowNodes.push(
            JSON.parse(JSON.stringify(bodyRows)),
          )
        }

        bodyPageNumber++
        bodyRows = []

        // 回答数が上限に到達した場合、次のページを設定する
        currentGridLayoutPage =
          girdLayoutPageList[`${originalSectionNodeId}-${bodyPageNumber}`]
      }

      bodyRows.push(node)
    })

    if (index in currentGridLayoutPage.bodyRowNodes) {
      currentGridLayoutPage.bodyRowNodes[index]?.push(
        JSON.parse(JSON.stringify(bodyRows)),
      )
    } else {
      currentGridLayoutPage.bodyRowNodes.push(
        JSON.parse(JSON.stringify(bodyRows)),
      )
    }
  })
}
