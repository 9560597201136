import { TemplateNodeSchema } from '@ulysses-inc/harami_api_client'
import { ActionTypes } from 'src/state/ducks/templates/editHint/types'

/**
 * ヒントの編集モーダルを開く
 */
export const showEditHint = (
  node: TemplateNodeSchema,
  hintId: string,
  blocks: Array<any>,
) => {
  return {
    type: ActionTypes.SHOW_EDIT_HINT,
    node,
    hintId,
    blocks,
  }
}

/**
 * ヒントの編集モーダルを閉じる
 */
export const closeEditHint = () => {
  return {
    type: ActionTypes.CLOSE_EDIT_HINT,
  }
}

export type EditHintAction =
  | ReturnType<typeof showEditHint>
  | ReturnType<typeof closeEditHint>
