import { AntDesignOutlined } from '@ant-design/icons'
import { List, Pagination, Row, Typography } from 'antd'
import {
  Success,
  TableBorderColor,
  White,
} from 'src/features/theme/KdsThemeColor'
import styled from 'styled-components'

export const { Text } = Typography

export const ReportsPagination = styled(Pagination)`
  margin-top: 16px;
  margin-bottom: 16px;
  display: flex;
  justify-content: center;
`

export const ListHeader = styled.div`
  width: 100%;
  height: 46px;
  display: flex;
  align-items: center;
  flex-direction: row;
  background: ${White};
  border-bottom: 1px solid ${TableBorderColor};
`

export const ContentRow = styled(Row)`
  margin: 15px;
`

export const NextApprovers = styled.div`
  flex-direction: row;
  justify-content: flex-end;
  padding-left: 12;
  padding-right: 12;
`

export const ButtonGroupRow = styled.div`
  position: absolute;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: row;
  height: 100%;
  width: 120px;
  right: 0;
  background-color: ${White};
`

export const TableRowWrap = styled(List.Item)`
  position: relative;
  min-height: 46px;
  background-color: ${White};
`

export const TableRow = styled.div`
  word-break: break-all;
  width: ${props => props.theme.width};
  padding-left: 16px;
`

export const TableHeaderRow = styled(Row)`
  margin: 15px;
`

export const TableHeaderLabel = styled.div`
  margin-left: 16px;
  width: ${props => props.theme.width};
  font-weight: bold;
`

export const ListHeaderWrap = styled.div`
  width: 100%;
`

export const CompleteIcon = styled(AntDesignOutlined)`
  color: ${Success};
`

export const CompleteText = styled(Text)`
  color: ${Success};
  margin-left: 10px;
`

export const BulkApprovingText = styled.span`
  margin-left: 8px;
`
