import { ResponseDatetimeSubTypeEnum } from '@ulysses-inc/harami_api_client'

/**
 * 時間計測質問の逸脱ルール設定のバリデーション
 * @param hasRule ルール設定されたか否か
 * @param subType 計測対象の日時質問のフォーマット
 * @param ruleType 逸脱ルールの種別(以上or以下)
 * @param value 逸脱ルールの時間設定（単位は分）
 * @returns 異常の場合にエラーメッセージを返却(正常時は空文字)
 */
export const validate = (
  hasRule: boolean,
  subType: ResponseDatetimeSubTypeEnum | undefined,
  ruleType: number | undefined,
  value: number,
) => {
  if (!hasRule) {
    return ''
  }

  if (value < 0) {
    return '時間を設定してください'
  } else if (subType === ResponseDatetimeSubTypeEnum.TIME && value >= 24 * 60) {
    return '23時間59分までの時間を設定してください'
  } else if (value >= 48 * 60) {
    return '47時間59分までの時間を設定してください'
  }

  if (ruleType === undefined) {
    return '以下または以上を選択してください'
  }
  return ''
}
