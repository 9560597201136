import {
  ReportNodeSchema,
  TemplateNodeTypeEnum,
} from '@ulysses-inc/harami_api_client'
import {
  NodeIdPaths,
  NodeUUIDPaths,
  ParentNodeIdInfo,
  ParentNodeUUIDInfo,
  ReportNodesDict,
  ReportPagesDict,
} from 'src/exShared/types/types'

/**
 * 各 node における Path (階層)情報を生成する
 *
 * @param report
 * @returns
 */
export const generateNodePathInfos = (
  pageIds: number[],
  pages: ReportPagesDict,
  allNodes: ReportNodesDict,
) => {
  const idPaths = {} as NodeIdPaths
  const uuidPaths = {} as NodeUUIDPaths
  for (const pageId of pageIds) {
    const page = pages[pageId]
    if (!page || !page.nodes) continue

    for (const nodeId of page.nodes) {
      const rootSectionNode = allNodes[nodeId]
      if (!rootSectionNode) continue

      walkReportNodesAndSetPaths(
        rootSectionNode,
        allNodes,
        idPaths,
        uuidPaths,
        [],
      )
    }
  }
  return {
    idPaths,
    uuidPaths,
  }
}

interface CurrentPathInfo {
  id: number
  uuid: string
  type: TemplateNodeTypeEnum
}

const walkReportNodesAndSetPaths = (
  node: ReportNodeSchema,
  allNodes: ReportNodesDict,
  idPaths: NodeIdPaths,
  uuidPaths: NodeUUIDPaths,
  currentParentInfos: CurrentPathInfo[],
) => {
  const parentNodeIdInfos: ParentNodeIdInfo[] = []
  const parentNodeUUIDInfos: ParentNodeUUIDInfo[] = []

  for (const parentInfo of currentParentInfos) {
    parentNodeIdInfos.push({
      nodeId: parentInfo.id,
      type: parentInfo.type,
    })
    parentNodeUUIDInfos.push({
      nodeUUID: parentInfo.uuid,
      type: parentInfo.type,
    })
  }
  idPaths[node.id] = parentNodeIdInfos
  uuidPaths[node.uuid ?? ''] = parentNodeUUIDInfos

  const nextParentInfos = currentParentInfos.concat({
    id: node.id,
    uuid: node.uuid ?? '',
    type: node.type,
  })

  for (const childNodeId of node.nodes) {
    const childNode = allNodes[childNodeId]
    if (!childNode) continue
    walkReportNodesAndSetPaths(
      childNode,
      allNodes,
      idPaths,
      uuidPaths,
      nextParentInfos,
    )
  }
}
