import { PlusOutlined } from '@ant-design/icons'
import { Layout } from 'antd'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { Header } from 'src/components/header/Header'
import { HeaderTabButton } from 'src/components/header/HeaderTabButton'
import Loading from 'src/components/loading/Loading'
import { Disable } from 'src/features/theme/KdsThemeColor'
import {
  changeMultipleChoiceSetsPage,
  changeMultipleChoiceSetsSize,
  getMultipleChoiceSets,
} from 'src/state/ducks/multipleChoiceSets/actions'
import { RootState } from 'src/state/store'
import MultipleChoiceSetList from './MultipleChoiceSetList'
import {
  ActionRow,
  AddMultipleChoiceSetButton,
  MultipleChoiceSetsPagination,
} from './MultipleChoiceSets.dumb'

const { Content } = Layout

const MultipleChoiceSetsContainer: React.FC = () => {
  const multipleChoiceSets = useSelector(
    (state: RootState) =>
      state.multipleChoiceSetsState.multipleChoiceSets.multipleChoiceSets,
  )
  const isLoading = useSelector(
    (state: RootState) =>
      state.multipleChoiceSetsState.multipleChoiceSets.isLoading,
  )
  const count = useSelector(
    (state: RootState) =>
      state.multipleChoiceSetsState.multipleChoiceSets.count,
  )
  const request = useSelector(
    (state: RootState) =>
      state.multipleChoiceSetsState.multipleChoiceSets.request,
  )

  const dispatch = useDispatch()
  const history = useHistory()
  const goAddMultipleChoiceSet = () => {
    history.push('/multipleChoiceSets/add')
  }

  useEffect(() => {
    dispatch(getMultipleChoiceSets(request))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const currentPage =
    request.offset === undefined || request.limit === undefined
      ? 1
      : Math.trunc(request.offset / request.limit) + 1

  return (
    <Layout>
      <Header>
        <HeaderTabButton type="link" theme={{ color: Disable }}>
          <Link to="/templates">ひな形</Link>
        </HeaderTabButton>
        <HeaderTabButton type="link">
          <Link to="/multipleChoiceSets">セット選択肢</Link>
        </HeaderTabButton>
      </Header>
      <Content>
        {isLoading ? (
          <Loading />
        ) : (
          <>
            <ActionRow justify="end">
              <AddMultipleChoiceSetButton
                onClick={goAddMultipleChoiceSet}
                type="primary"
              >
                <PlusOutlined />
                セット選択肢を追加
              </AddMultipleChoiceSetButton>
            </ActionRow>
            <MultipleChoiceSetList multipleChoiceSets={multipleChoiceSets} />
          </>
        )}
      </Content>
      <MultipleChoiceSetsPagination
        showSizeChanger
        current={currentPage}
        pageSizeOptions={['10', '25', '50', '100']}
        pageSize={request.limit ?? 25}
        defaultCurrent={1}
        total={count}
        onChange={(pageNum, pageSize) =>
          dispatch(changeMultipleChoiceSetsPage(pageNum, pageSize))
        }
        onShowSizeChange={(_, size) =>
          dispatch(changeMultipleChoiceSetsSize(size))
        }
      />
    </Layout>
  )
}

export default MultipleChoiceSetsContainer
