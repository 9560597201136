// eslintがpackage.jsonのexportsフィールドを解釈できないために
// エラーになっているにすぎないため、無視する。
// eslint-disable-next-line import/no-unresolved
import { stringify } from 'csv-stringify/browser/esm/sync'
import range from 'lodash.range'
import moment from 'moment'
import { useCallback, useEffect } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { Row, Table } from 'src/state/ducks/editGridVariables/types'
import { RootState } from 'src/state/store'
import { DATE_PICKER_FORMAT } from '../constants'
import { getEndPosition } from '../helpers/getEndPosition'
import { getStartPosition } from '../helpers/getStartPosition'

export const useCopyHandler = () => {
  const isInSelect = useSelector(
    (state: RootState) =>
      state.editGridVariablesState.editGridVariables.mode === 'select',
  )

  const selectedCells = useSelector(
    (state: RootState) =>
      state.editGridVariablesState.editGridVariables.selectedCells,
    shallowEqual,
  )

  const table = useSelector(
    (state: RootState) =>
      state.editGridVariablesState.editGridVariables.inputTable,
    shallowEqual,
  )

  const handleCopy = useCallback(
    (e: ClipboardEvent) => {
      if (!isInSelect) {
        return
      }

      e.preventDefault()

      const [startRowNum, startColNum] = getStartPosition(selectedCells)
      const [endRowNum, endColNum] = getEndPosition(selectedCells)

      // どこも選択されていなければ何もしない
      if (
        startRowNum === undefined ||
        startColNum === undefined ||
        endRowNum === undefined ||
        endColNum === undefined
      ) {
        return
      }

      if (e.clipboardData === null) {
        return
      }

      const data = convertTable(
        table,
        startRowNum,
        startColNum,
        endRowNum,
        endColNum,
      )

      const tsv = generateTsv(data)

      e.clipboardData.setData('text/plain', tsv)
    },
    [isInSelect, selectedCells, table],
  )

  useEffect(() => {
    document.addEventListener('copy', handleCopy)
    document.addEventListener('cut', handleCopy) // カットもコピーとして扱う

    // WARN: 以下のように明示的にイベントハンドラーを解除しなければならない
    return () => {
      document.removeEventListener('copy', handleCopy)
      document.removeEventListener('cut', handleCopy)
    }
  }, [handleCopy])
}

/**
 * テーブルを二次元配列に変換する
 * 開始セル・終了セルの座標を受け取り、その範囲内に存在するテーブルのデータを変換の対象とする
 *
 * @param table テーブルデータ
 * @param startRowNum 開始行番号
 * @param startColNum 開始列番号
 * @param endRowNum 終了行番号
 * @param endColNum 終了列番号
 * @returns 二次元配列
 */
export const convertTable = (
  table: Table,
  startRowNum: number,
  startColNum: number,
  endRowNum: number,
  endColNum: number,
) => {
  const rowRange = range(startRowNum, endRowNum + 1)
  const colRange = range(startColNum, endColNum + 1)

  return rowRange.map(rowIndex => {
    const sourceRow = table[rowIndex]

    if (sourceRow === undefined) {
      // 未入力の行をコピーしようとしたら、空文字だけで構成された行を生成する
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      return colRange.map(_ => '')
    } else {
      return colRange.map(colIndex =>
        extractCellValueByColIndex(colIndex, sourceRow),
      )
    }
  })
}

/**
 * 列番号に対応するセルの値を、行から取り出して返す
 *
 * @param colIndex 列番号
 * @param row 行
 * @returns セルの値
 */
const extractCellValueByColIndex = (colIndex: number, row: Row) => {
  switch (colIndex) {
    case 1:
      return toYYMMDDIfValidISO8601(row.from.dateValue)
    case 2:
      return toYYMMDDIfValidISO8601(row.through.dateValue)
    case 3:
      return row.sectionName.name
    case 4:
      return row.variableSection.name
    // WARN: 2023/02 の仕様だと取り込み項目は 1~6 で固定だが、可変にしたくなったときにこれでは対応できない
    case 5:
    case 6:
    case 7:
    case 8:
    case 9:
    case 10:
      /**
       * 列番号と取り込み項目番号の対応関係
       *   - 列番号 5  => 取り込み項目 1
       *   - 列番号 10 => 取り込み項目 6
       */
      return row.values[colIndex - 4]?.value || ''
    // 11 列目以降は列として存在しない
    default:
      return ''
  }
}

/**
 * ISO 8601 表記のタイムスタンプ文字列を、yyyy/MM/dd 形式の文字列に変換する
 *
 * Date への変換に失敗して `Invalid Date` とならないようにするため、
 * ISO 8601 でない場合は、変換せずにそのまま返す
 *
 * @param maybeISO8601 ISO 8601 表記のタイムスタンプ文字列と思われる値
 * @returns
 */
const toYYMMDDIfValidISO8601 = (maybeISO8601: string) => {
  const date = moment(maybeISO8601)
  return date.isValid() ? date.format(DATE_PICKER_FORMAT) : maybeISO8601
}

/**
 * 二次元配列から tsv を生成する
 *
 * @param data 二次元配列
 * @returns tsv
 */
export const generateTsv = (data: string[][]) =>
  stringify(data, {
    delimiter: '\t', // TSV
    record_delimiter: 'windows', // ユーザーの OS に関わらず、レコードは CRLF で区切る
    quoted_match: /\n/, // LF または CRLF が含まれるセルは、ダブルクォーテーションで囲む
  })
