import { css } from '@emotion/react'
import { Form as AForm, Select as ASelect, Typography } from 'antd'
import { ComponentProps, FC } from 'react'
import { Danger, PlaceholderText } from 'src/features/theme/KdsThemeColor'

export const Form: FC<ComponentProps<typeof AForm>> = props => (
  <AForm
    {...props}
    css={css`
      margin-top: -16px;
    `}
  />
)

export const Select: FC<ComponentProps<typeof ASelect>> = props => (
  <ASelect
    {...props}
    css={css`
      width: 100%;
    `}
  />
)

export const EditFormControl: FC<React.ComponentProps<'div'>> = props => (
  <div
    {...props}
    css={css`
      margin-top: 16px;
      margin-bottom: 5px;
    `}
  />
)

export const EditFormLabel: FC<
  ComponentProps<typeof Typography.Text>
> = props => (
  <Typography.Text
    {...props}
    css={css`
      margin-left: 3px;
    `}
  />
)

export const EmptyOptionValue: FC<
  ComponentProps<typeof Typography.Text>
> = props => (
  <Typography.Text
    {...props}
    css={css`
      color: ${PlaceholderText}; // PlaceholderでもDisabledでもないので、色を変える必要はないのでは？
    `}
  />
)

export const ErrorText: FC<ComponentProps<typeof Typography.Text>> = props => (
  <Typography.Text
    {...props}
    css={css`
      color: ${Danger};
    `}
  />
)
