import * as Sentry from '@sentry/browser'
import { useEffect } from 'react'
import { localStorageKeys } from 'src/constants/localStorageKeys'
import { appEnv } from 'src/util/appEnv'

/**
 * SentryにuserIdを設定する
 */
export const useSetSentryUserId = () => {
  // 本来はloginUserIdはリアクティブな値として受け取れるようにして、
  // その値をきっかけとしてuseEffectが発火するのが理想
  useEffect(() => {
    const loginUserId = localStorage.getItem(localStorageKeys.loginUserUuid)

    if (loginUserId && !appEnv.isLocal) {
      Sentry.setUser({ id: loginUserId })
    }
  }, [])
}
