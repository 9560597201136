import { DeleteOutlined, SearchOutlined } from '@ant-design/icons'
import { ResponseSetCategory } from '@ulysses-inc/harami_api_client'
import { Button, Input, InputRef, Table } from 'antd'
import React, { ComponentProps, useRef } from 'react'
import { ClampText } from 'src/components/clampText/ClampText'
import {
  ResponseSetCategoryForm,
  ResponseSetForm,
} from 'src/features/template/multipleChoiceSets/ResponseSetTypes'
import { isRecordWithKey } from 'src/util/isRecordWithKey'
import styled from 'styled-components'
import ResponseSetTags from './ResponseSetTags'

const { Column } = Table

const FilterDropdownContainer = styled.div`
  padding: 8px;
`

const FilterDropdownInput = styled(Input)`
  width: 188px;
  margin-bottom: 8px;
  display: block;
`

const FilterDropdownSearchButton = styled(Button)`
  width: 90px;
  margin-right: 8px;
`

const FilterDropdownResetButton = styled(Button)`
  width: 90px;
`

interface OwnProps {
  responses?: ResponseSetForm[]
  isLoadingResponses: boolean
  onDelete: (key: number) => void
  onChangeDiscriminationKey: (discriminationKey: string, key: number) => void
  onChangeName: (name: string, key: number) => void
  onInputConfirmTag: (responseKey: number, tagName: string) => void
  onCloseTag: (responseKey: number, tagIndex: number) => void
}

const ResponseSetTable: React.FC<OwnProps> = (props: OwnProps) => {
  const searchInput = useRef<InputRef | null>()
  const placeHolderContents = (key: string) => {
    if (key === 'discriminationKey') {
      return '判別キー名を入力'
    } else if (key === 'categories') {
      return 'カテゴリー名を入力'
    } else if (key === 'name') {
      return '選択肢名を入力'
    } else {
      return ''
    }
  }

  const getColumnSearchProps = (key: string) => {
    const result: Pick<
      ComponentProps<typeof Column>,
      'filterDropdown' | 'filterIcon'
    > = {
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <FilterDropdownContainer>
          <FilterDropdownInput
            ref={node => {
              searchInput.current = node
            }}
            placeholder={placeHolderContents(key)}
            value={
              typeof selectedKeys[0] === 'string' // 型の都合で追加した分岐
                ? selectedKeys[0]
                : ''
            }
            onChange={e =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => {
              confirm()
            }}
          />
          <FilterDropdownSearchButton
            size="small"
            type="primary"
            onClick={() => {
              confirm()
            }}
          >
            検索
          </FilterDropdownSearchButton>
          <FilterDropdownResetButton
            onClick={() => {
              clearFilters?.()
            }}
            size="small"
          >
            クリア
          </FilterDropdownResetButton>
        </FilterDropdownContainer>
      ),
      filterIcon: () => <SearchOutlined />,
    }
    return result
  }

  const textSearchFilterProps = (dataIndex: string) => {
    const result: Pick<
      ComponentProps<typeof Column>,
      'onFilter' | 'onFilterDropdownOpenChange'
    > = {
      onFilter: (value, record) => {
        if (typeof value !== 'string') {
          return true
        }
        // 単に型の都合
        if (!isRecordWithKey(record, dataIndex)) return true
        return (record[dataIndex] || '')
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
      },
      onFilterDropdownOpenChange: visible => {
        if (visible) {
          setTimeout(() => searchInput.current?.select())
        }
      },
    }
    return result
  }

  return (
    <Table
      dataSource={props.responses}
      scroll={{ y: 480 }}
      pagination={false}
      loading={props.isLoadingResponses}
    >
      <Column
        title="判別キー"
        dataIndex="discriminationKey"
        key="discriminationKey"
        width="20%"
        {...getColumnSearchProps('discriminationKey')}
        {...textSearchFilterProps('discriminationKey')}
        render={(text: string, responseSet: ResponseSetForm) => (
          <ClampText
            editable={{
              onChange: (text: string) => {
                props.onChangeDiscriminationKey(text, responseSet.key)
              },
            }}
          >
            {text}
          </ClampText>
        )}
      />
      <Column
        title="カテゴリー"
        dataIndex="categories"
        key="categories"
        width="30%"
        {...getColumnSearchProps('categories')}
        onFilter={(value, record) => {
          if (typeof value !== 'string') {
            return true
          }
          return (record.categories ?? [])
            .map((category: ResponseSetCategory) => category.name)
            .join(',')
            .toLowerCase()
            .includes(value.toLowerCase())
        }}
        onFilterDropdownOpenChange={visible => {
          if (visible) {
            setTimeout(() => searchInput.current?.select())
          }
        }}
        render={(
          tags: ResponseSetCategoryForm[],
          responseSet: ResponseSetForm,
        ) => (
          <ResponseSetTags
            tags={tags}
            onInputConfirm={(tagName: string) =>
              props.onInputConfirmTag(responseSet.key, tagName)
            }
            onClose={(index: number) =>
              props.onCloseTag(responseSet.key, index)
            }
          />
        )}
      />
      <Column
        title="選択肢名"
        dataIndex="name"
        key="name"
        width="45%"
        {...getColumnSearchProps('name')}
        {...textSearchFilterProps('name')}
        render={(text: string, responseSet: ResponseSetForm) => (
          <ClampText
            editable={{
              onChange: (text: string) => {
                props.onChangeName(text, responseSet.key)
              },
            }}
          >
            {text}
          </ClampText>
        )}
      />
      <Column
        dataIndex="action"
        key="action"
        width="5%"
        render={(_: unknown, responseSet: ResponseSetForm) => {
          return (
            <Button
              danger
              type="primary"
              icon={<DeleteOutlined />}
              onClick={() => props.onDelete(responseSet.key)}
            />
          )
        }}
      />
    </Table>
  )
}

export default ResponseSetTable
