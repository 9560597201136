import { Employee } from '@ulysses-inc/harami_api_client'
import { List, Row } from 'antd'
import { ListProps } from 'antd/lib/list'
import { JSX } from 'react'
import { TableBorderColor, White } from 'src/features/theme/KdsThemeColor'
import styled from 'styled-components'

export const ButtonGroupRow = styled.div`
  position: absolute;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: row;
  height: 100%;
  right: 0;
`

export const TableBody = styled<(props: ListProps<Employee>) => JSX.Element>(
  List,
)`
  width: 100%;
`

export const TableRowWrap = styled(List.Item)`
  position: relative;
  min-height: 46px;
  background-color: ${White};
`

export const TableRow = styled.div`
  word-break: break-all;
  width: ${props => props.theme.width};
  padding-left: 16px;
`

export const EmployeesHeaderContainer = styled(Row)`
  margin: 15px;
  overflow-x: auto;
  justify-content: flex-start;
`

export const EmployeesHeaderWrap = styled.div`
  width: 100%;
  min-width: 800px;
`

export const EmployeesHeader = styled.div`
  width: 100%;
  height: 46px;
  display: flex;
  align-items: center;
  flex-direction: row;
  background: ${White};
  border-bottom: 1px solid ${TableBorderColor};
`

export const HeaderRow = styled.div`
  font-weight: bold;
  padding-left: 15px;
  width: ${props => props.theme.width};
`
