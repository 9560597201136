import {
  AddPlaceV2Request,
  GetPlacesRequest,
  GetPlacesV2Request,
  PlaceNode,
  UpdatePlaceV2Request,
} from '@ulysses-inc/harami_api_client'
import { ActionTypes, GetPlacesFilter } from './types'

export const getPlaces = (request: GetPlacesRequest) => {
  return {
    type: ActionTypes.REQUEST_GET_PLACES,
    isLoading: true,
    request,
  }
}

const getSuccessPlaces = (places: PlaceNode[], count: number) => {
  return {
    type: ActionTypes.SUCCESS_GET_PLACES,
    isLoading: false,
    places,
    count,
  }
}

const getErrorPlaces = (error: Error) => {
  return {
    type: ActionTypes.ERROR_GET_PLACES,
    isLoading: false,
    error,
  }
}

const changePlacesPage = (page: number, pageSize?: number) => {
  const offset = pageSize ? (page - 1) * pageSize : 0
  return {
    type: ActionTypes.REQUEST_CHANGE_PLACES_PAGE,
    isLoading: true,
    request: {
      limit: pageSize,
      offset,
    },
  }
}

const changePlacesSize = (pageSize: number) => {
  return {
    type: ActionTypes.REQUEST_CHANGE_PLACES_SIZE,
    isLoading: true,
    request: {
      limit: pageSize,
      offset: 0,
    },
  }
}

const getPlacesV2 = (request: GetPlacesV2Request, filter?: GetPlacesFilter) => {
  return {
    type: ActionTypes.REQUEST_GET_PLACES_V2,
    isLoading: true,
    request,
    filter: filter,
  }
}

const changePlacesV2Page = (
  page: number,
  pageSize?: number,
  filter?: GetPlacesFilter,
) => {
  const offset = pageSize ? (page - 1) * pageSize : 0
  return {
    type: ActionTypes.REQUEST_CHANGE_PLACES_V2_PAGE,
    isLoading: true,
    request: {
      limit: pageSize,
      offset,
    },
    filter: filter,
  }
}

const changePlacesV2Size = (pageSize: number, filter?: GetPlacesFilter) => {
  return {
    type: ActionTypes.REQUEST_CHANGE_PLACES_V2_SIZE,
    isLoading: true,
    request: {
      limit: pageSize,
      offset: 0,
    },
    filter: filter,
  }
}

const addPlace = (request: AddPlaceV2Request) => {
  return {
    type: ActionTypes.REQUEST_ADD_PLACE,
    isLoading: true,
    request,
  }
}

const addSuccessPlace = (place: PlaceNode) => {
  return {
    type: ActionTypes.SUCCESS_ADD_PLACE,
    isLoading: false,
    place,
  }
}

const addErrorPlace = (error: Error) => {
  return {
    type: ActionTypes.ERROR_ADD_PLACE,
    isLoading: false,
    error,
  }
}

const updatePlace = (request: UpdatePlaceV2Request) => {
  return {
    type: ActionTypes.REQUEST_UPDATE_PLACE,
    isLoading: true,
    request,
  }
}

const updateSuccessPlace = (place: PlaceNode) => {
  return {
    type: ActionTypes.SUCCESS_UPDATE_PLACE,
    isLoading: false,
    place,
  }
}

const updateErrorPlace = (error: Error) => {
  return {
    type: ActionTypes.ERROR_UPDATE_PLACE,
    isLoading: false,
    error,
  }
}

const deletePlace = (placeId: string) => {
  return {
    type: ActionTypes.REQUEST_DELETE_PLACE,
    isLoading: true,
    placeId,
  }
}

const deleteSuccessPlace = (placeId: string) => {
  return {
    type: ActionTypes.SUCCESS_DELETE_PLACE,
    isLoading: false,
    placeId,
  }
}

const deleteErrorPlace = (error: Error) => {
  return {
    type: ActionTypes.ERROR_DELETE_PLACE,
    isLoading: false,
    error,
  }
}

const changeUpdatedPlacesFlag = (updatedPlaces: boolean) => {
  return {
    type: ActionTypes.CHANGE_UPDATED_PLACES_FLAG,
    updatedPlaces,
  }
}

const changeIsShowEditPlaceDrawer = (isShowEditPlaceDrawer: boolean) => {
  return {
    type: ActionTypes.CHANGE_IS_SHOW_EDIT_PLACE_DRAWER,
    isShowEditPlaceDrawer,
  }
}

const updateSegmentIndex = (index: number) => {
  return {
    type: ActionTypes.UPDATE_SEGMENT_INDEX,
    segmentIndex: index,
  }
}

const updateActivePlaceId = (activePlaceId?: string) => {
  return {
    type: ActionTypes.UPDATE_ACTIVE_PLACE_ID,
    activePlaceId: activePlaceId,
  }
}

export type PlacesAction =
  | ReturnType<typeof getPlaces>
  | ReturnType<typeof getSuccessPlaces>
  | ReturnType<typeof getErrorPlaces>
  | ReturnType<typeof changePlacesPage>
  | ReturnType<typeof changePlacesSize>
  | ReturnType<typeof getPlacesV2>
  | ReturnType<typeof changePlacesV2Page>
  | ReturnType<typeof changePlacesV2Size>
  | ReturnType<typeof addPlace>
  | ReturnType<typeof addSuccessPlace>
  | ReturnType<typeof addErrorPlace>
  | ReturnType<typeof updatePlace>
  | ReturnType<typeof updateSuccessPlace>
  | ReturnType<typeof updateErrorPlace>
  | ReturnType<typeof deletePlace>
  | ReturnType<typeof deleteSuccessPlace>
  | ReturnType<typeof deleteErrorPlace>
  | ReturnType<typeof changeUpdatedPlacesFlag>
  | ReturnType<typeof changeIsShowEditPlaceDrawer>
  | ReturnType<typeof updateSegmentIndex>
  | ReturnType<typeof updateActivePlaceId>

export const getPlaceGroups = () => {
  return {
    type: ActionTypes.REQUEST_GET_PLACE_GROUPS,
    isLoading: true,
  }
}

const getSuccessPlaceGroups = (nodes: PlaceNode[]) => {
  return {
    type: ActionTypes.SUCCESS_GET_PLACE_GROUPS,
    isLoading: false,
    nodes,
  }
}

const getErrorPlaceGroups = (error: Error) => {
  return {
    type: ActionTypes.ERROR_GET_PLACE_GROUPS,
    isLoading: false,
    error,
  }
}

const addPlaceGroup = (node: PlaceNode) => {
  return {
    type: ActionTypes.REQUEST_ADD_PLACE_GROUP,
    isLoading: true,
    node,
  }
}

const addSuccessPlaceGroup = (nodes: PlaceNode[]) => {
  return {
    type: ActionTypes.SUCCESS_ADD_PLACE_GROUP,
    isLoading: false,
    nodes,
  }
}

const addErrorPlaceGroup = (error: Error) => {
  return {
    type: ActionTypes.ERROR_ADD_PLACE_GROUP,
    isLoading: false,
    error,
  }
}

const updatePlaceGroup = (nodeId: string, node: PlaceNode) => {
  return {
    type: ActionTypes.REQUEST_UPDATE_PLACE_GROUP,
    isLoading: true,
    nodeId,
    node,
  }
}

const updateSuccessPlaceGroup = (nodes: PlaceNode[]) => {
  return {
    type: ActionTypes.SUCCESS_UPDATE_PLACE_GROUP,
    isLoading: false,
    nodes,
  }
}

const updateErrorPlaceGroup = (error: Error) => {
  return {
    type: ActionTypes.ERROR_UPDATE_PLACE_GROUP,
    isLoading: false,
    error,
  }
}

const deletePlaceGroup = (nodeId: string) => {
  return {
    type: ActionTypes.REQUEST_DELETE_PLACE_GROUP,
    isLoading: true,
    nodeId,
  }
}

const deleteSuccessPlaceGroup = (nodeId: string) => {
  return {
    type: ActionTypes.SUCCESS_DELETE_PLACE_GROUP,
    isLoading: false,
    nodeId,
  }
}

const deleteErrorPlaceGroup = (error: Error) => {
  return {
    type: ActionTypes.ERROR_DELETE_PLACE_GROUP,
    isLoading: false,
    error,
  }
}

const changeIsShowEditPlaceGroupDrawer = (
  isShowEditPlaceGroupDrawer: boolean,
) => {
  return {
    type: ActionTypes.CHANGE_IS_SHOW_EDIT_PLACE_GROUP_DRAWER,
    isShowEditPlaceGroupDrawer,
  }
}

const updateActiveNodeId = (
  activeNodeId?: string,
  activeParentNodeId?: number,
) => {
  return {
    type: ActionTypes.UPDATE_ACTIVE_NODE_ID,
    activeNodeId: activeNodeId,
    activeParentNodeId: activeParentNodeId,
  }
}

export type PlaceGroupsAction =
  | ReturnType<typeof getPlaceGroups>
  | ReturnType<typeof getSuccessPlaceGroups>
  | ReturnType<typeof getErrorPlaceGroups>
  | ReturnType<typeof addPlaceGroup>
  | ReturnType<typeof addSuccessPlaceGroup>
  | ReturnType<typeof addErrorPlaceGroup>
  | ReturnType<typeof updatePlaceGroup>
  | ReturnType<typeof updateSuccessPlaceGroup>
  | ReturnType<typeof updateErrorPlaceGroup>
  | ReturnType<typeof deletePlaceGroup>
  | ReturnType<typeof deleteSuccessPlaceGroup>
  | ReturnType<typeof deleteErrorPlaceGroup>
  | ReturnType<typeof changeIsShowEditPlaceGroupDrawer>
  | ReturnType<typeof updateActiveNodeId>

const getPlaceNodes = () => {
  return {
    type: ActionTypes.REQUEST_GET_PLACE_NODES,
    isLoading: true,
  }
}

const getSuccessPlaceNodes = (nodes: PlaceNode[]) => {
  return {
    type: ActionTypes.SUCCESS_GET_PLACE_NODES,
    isLoading: false,
    nodes,
  }
}

const getErrorPlaceNodes = (error: Error) => {
  return {
    type: ActionTypes.ERROR_GET_PLACE_NODES,
    isLoading: false,
    error,
  }
}

export type PlaceNodesAction =
  | ReturnType<typeof getPlaceNodes>
  | ReturnType<typeof getSuccessPlaceNodes>
  | ReturnType<typeof getErrorPlaceNodes>

export default {
  getPlaces,
  getSuccessPlaces,
  getErrorPlaces,
  changePlacesPage,
  changePlacesSize,
  getPlacesV2,
  changePlacesV2Page,
  changePlacesV2Size,
  addPlace,
  addSuccessPlace,
  addErrorPlace,
  updatePlace,
  updateSuccessPlace,
  updateErrorPlace,
  deletePlace,
  deleteSuccessPlace,
  deleteErrorPlace,
  changeUpdatedPlacesFlag,
  changeIsShowEditPlaceDrawer,
  updateSegmentIndex,
  updateActivePlaceId,
  getPlaceGroups,
  getSuccessPlaceGroups,
  getErrorPlaceGroups,
  addPlaceGroup,
  addSuccessPlaceGroup,
  addErrorPlaceGroup,
  updatePlaceGroup,
  updateSuccessPlaceGroup,
  updateErrorPlaceGroup,
  deletePlaceGroup,
  deleteSuccessPlaceGroup,
  deleteErrorPlaceGroup,
  changeIsShowEditPlaceGroupDrawer,
  updateActiveNodeId,
  getPlaceNodes,
  getSuccessPlaceNodes,
  getErrorPlaceNodes,
}
