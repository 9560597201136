import {
  GetImprovesRequest,
  Improve,
  PlaceNode,
} from '@ulysses-inc/harami_api_client'
import React from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
  DispatchProps,
  StateProps,
} from 'src/features/improves/improveList/ImprovesProps'
import ImprovesScene from 'src/features/improves/improveList/ImprovesScene'
import improvesOperations from 'src/state/ducks/improves/operations'
import { GetImprovesFilter } from 'src/state/ducks/improves/types'
import placesOperations from 'src/state/ducks/places/operations'

interface State {
  improvesState: {
    improves: {
      improves: Improve[]
      isLoading: boolean
      count: number
      request: GetImprovesRequest
      filter: GetImprovesFilter
    }
  }
  placesState: {
    placeNodes: {
      nodes: PlaceNode[]
      isLoading: boolean
    }
    places: {
      places: PlaceNode[]
      isLoading: boolean
    }
  }
}

const useStateProps = (): StateProps => {
  return {
    improves: useSelector(
      (state: State) => state.improvesState.improves.improves,
      shallowEqual,
    ),
    isLoading: useSelector(
      (state: State) => state.improvesState.improves.isLoading,
      shallowEqual,
    ),
    count: useSelector(
      (state: State) => state.improvesState.improves.count,
      shallowEqual,
    ),
    request: useSelector(
      (state: State) => state.improvesState.improves.request,
      shallowEqual,
    ),
    filter: useSelector(
      (state: State) => state.improvesState.improves.filter,
      shallowEqual,
    ),
    places: useSelector(
      (state: State) => state.placesState.places.places,
      shallowEqual,
    ),
    isLoadingPlace: useSelector(
      (state: State) => state.placesState.places.isLoading,
      shallowEqual,
    ),
  }
}

const useDispatchProps = (): DispatchProps => {
  const dispatch = useDispatch()
  const history = useHistory()
  return {
    getImproves: (request: GetImprovesRequest, filter?: GetImprovesFilter) => {
      improvesOperations.getImproves(dispatch, request, filter)
    },
    changeImprovesPage: (
      page: number,
      pageSize?: number,
      filter?: GetImprovesFilter,
    ) => {
      improvesOperations.changeImprovesPage(dispatch, page, pageSize, filter)
    },
    changeImprovesSize: (pageSize: number, filter?: GetImprovesFilter) => {
      improvesOperations.changeImprovesSize(dispatch, pageSize, filter)
    },
    goImproveDetail: (improveUUID: string) => {
      history.push(`/improves/${improveUUID}`)
    },
    goReportDetail: ({ reportUUID }: { reportUUID?: string }) => {
      history.push(`/reports/${reportUUID}`)
    },
    deleteImprove: (improveUUID: string) => {
      improvesOperations.deleteImprove(dispatch, improveUUID)
    },
    updatePagination: (limit: number, offset: number) => {
      improvesOperations.updatePagination(dispatch, limit, offset)
    },
    getPlaces: () => {
      placesOperations.getPlaces(dispatch, {})
    },
    getPlaceGroups: () => {
      placesOperations.getPlaceGroups(dispatch)
    },
  }
}

const ImprovesContainer: React.FC = () => {
  const stateProps = useStateProps()
  const dispatchProps = useDispatchProps()
  const props = { ...stateProps, ...dispatchProps }
  return <ImprovesScene {...props} />
}

export default ImprovesContainer
