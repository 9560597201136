import {
  ReportNodeSchema,
  TemplateNodeTypeEnum,
} from '@ulysses-inc/harami_api_client'

/**
 * 与えられたノードが「繰り返しセクションのマスタ」に該当するか判定する。
 *
 * Note: セクションには以下の3種類がある。
 * - 通常のセクション
 * - 繰り返しセクションのマスタ(テンプレであり実データは持たない)
 * - 繰り返しセクションのインスタンス(現場で入力された実データ)
 */
export const isOriginalRepeatedSection = (node: ReportNodeSchema): boolean => {
  const isSection = node.type === TemplateNodeTypeEnum.Section
  const isRepeatedSection = node.section?.isRepeat === 1
  const hasOriginalNode = !!node.section?.originalNodeUuid

  return isSection && isRepeatedSection && !hasOriginalNode
}
