import { CalendarOutlined, CloseOutlined } from '@ant-design/icons'
import { ImproveEmail } from '@ulysses-inc/harami_api_client'
import { Drawer, Typography } from 'antd'
import React from 'react'
import styled from 'styled-components'
import { EmailLogsProps } from './ImproveEmailLogsProps'

const { Text } = Typography

const DrawerBodyStyle = {
  paddingLeft: '24px',
  fontSize: 14,
}

const DrawerHeaderStyle = {
  paddingLeft: '24px',
  paddingTop: '32px',
  paddingBottom: '32px',
  fontWeight: 'bold',
}

const Contents = styled.div`
  margin-top: -24px;
  margin-bottom: 5px;
`

const LogGroup = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 24px;
  padding-bottom: 24px;
  border-bottom: 1px solid #e9e9e9;
`

const DateText = styled(Text)`
  color: #595959;
  font-size: 12px;
  font-weight: 500;
  padding-bottom: 8px;
`

const InfoText = styled(Text)`
  margin-left: -3px;
  padding-bottom: 8px;
`

const TitleText = styled(Text)`
  font-weight: bold;
  font-size: 20px;
  font-family: 'Noto Sans JP', sans-serif;
`

const NameText = styled(Text)`
  font-weight: bold;
  padding: 0 3px 0 3px;
  font-family: 'Noto Sans JP', sans-serif;
`

const ContentText = styled(Text)`
  padding: 12px 16px 12px 16px;
  background-color: #f5f5f5;
  font-weight: 500;
  white-space: pre-wrap;
`

const getDateText = (date: string) => {
  const d = new Date(date)
  return (
    ' ' +
    d.getFullYear() +
    '年' +
    (d.getMonth() + 1) +
    '月' +
    d.getDate() +
    '日'
  )
}

const ImproveEmailLogsDrawer: React.FC<EmailLogsProps> = (
  props: EmailLogsProps,
) => {
  const mails: ImproveEmail[] = props.improveEmailLogs['improveEmails']
  const renderLogs = (
    <>
      {mails?.map((mail: ImproveEmail, index) => (
        <LogGroup key={index}>
          <DateText>
            <CalendarOutlined />
            {getDateText(mail?.data?.sendAt.toString())}
          </DateText>
          <InfoText>
            <NameText>{mail?.data?.userName}</NameText>が
            <NameText>{mail?.data?.userGroupName}</NameText>
            へ提出しました。
          </InfoText>
          <ContentText>{mail?.data?.content}</ContentText>
        </LogGroup>
      ))}
    </>
  )

  return (
    <Drawer
      title={<TitleText>提出履歴</TitleText>}
      maskClosable={true}
      placement="right"
      open={props.isShow}
      closeIcon={
        <CloseOutlined
          size={20}
          style={{
            marginTop: 13,
          }}
        />
      }
      width="600"
      height="93"
      headerStyle={DrawerHeaderStyle}
      bodyStyle={DrawerBodyStyle}
      onClose={() => props.changeIsShowImproveEmailLogsDrawer(false)}
    >
      <Contents>{renderLogs}</Contents>
    </Drawer>
  )
}

export default ImproveEmailLogsDrawer
