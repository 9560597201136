import { Primary, White } from 'src/features/theme/KdsThemeColor'
import styled from 'styled-components'

export const Area = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`

export const Panel = styled.div`
  width: 800px;
  background-color: ${White};
  margin-top: 60px;
  text-align: center;
`

export const StyledImg = styled.img`
  width: 40%;
  margin: 30px auto;
`

export const Title = styled.h1`
  color: ${Primary};
  font-weight: bold;
  width: 80%;
  margin: 0 auto;
`

export const Description = styled.p`
  font-size: 16px;
  width: 65%;
  margin: 30px auto;
  text-align: left;
`

export const MaintenanceTitle = styled.div`
  font-size: 20px;
  font-weight: bold;
`

export const MaintenanceTime = styled.div`
  font-size: 18px;
  margin: 10px auto;
`

export const Note = styled.p`
  font-size: 16px;
  width: 65%;
  margin: 30px auto 60px;
  text-align: left;
`

export const LinkText = styled.a`
  color: ${Primary};
  font-weight: bold;
  text-decoration: underline;
  &:hover {
    color: ${Primary};
    text-decoration: underline;
    opacity: 0.7;
  }
`
