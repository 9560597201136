import {
  GetImproveRequest,
  Improve,
  ImprovesApi,
} from '@ulysses-inc/harami_api_client'
import { call, put } from 'redux-saga/effects'
import interceptionsActions from 'src/state/ducks/interceptions/actions'
import BaseClient from 'src/state/middleware/saga/baseClient'
import {
  HTTPError,
  handleHTTPError,
} from 'src/state/middleware/saga/handleHttpError'
import { getImprove as getImproveActions } from '../actions'

const baseClient = new BaseClient()

const getImproveRequest = (req: GetImproveRequest) => {
  return baseClient
    .getApi(ImprovesApi)
    .getImprove(req)
    .then(improve => improve)
    .catch(handleHTTPError)
}

function* getImprove(
  action: ReturnType<typeof getImproveActions.getRequestImprove>,
) {
  try {
    const improve: Improve = yield call(getImproveRequest, {
      improveUUID: action.improveUUID,
    })
    yield put(getImproveActions.getSuccessImprove(improve))
  } catch (error) {
    // TODO remove `as`
    yield put(interceptionsActions.handleHttpError(error as HTTPError))
    // TODO remove `as`
    yield put(getImproveActions.getErrorImprove(error as Error))
  }
}

export { getImprove }
