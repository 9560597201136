import { Input } from 'antd'
import { ComponentProps, useEffect, useRef, useState } from 'react'
import useDebounceValue from 'src/exShared/hooks/useDebounceValue'

type Props = {
  delay: number
  onChangeValue: (value: string) => void
  value: string
} & Omit<ComponentProps<typeof Input>, 'onChange' | 'value'>

export const DebouncedInput = ({
  delay,
  onChangeValue,
  value: valueProp,
  ...rest
}: Props) => {
  const [innerValue, setInnerValue] = useState<string>(valueProp)

  useEffect(() => {
    setInnerValue(valueProp)
  }, [valueProp])

  const debouncedValue = useDebounceValue(innerValue, delay)
  const isFirstRender = useRef(true)

  useEffect(
    () => {
      if (isFirstRender.current) {
        isFirstRender.current = false
        return
      }
      onChangeValue(debouncedValue)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      debouncedValue,
      // onChangeValue, 無限ループになるため
    ],
  )

  return (
    <Input
      {...rest}
      onChange={
        // 先行して内部のstateには値を反映する。
        // その後、debounceの働きにより親から新しい値(props.value)が渡されてくることになるが、
        // debounceの仕組み上、その値は常に最新の値であることが保証されているため問題は起こらない。
        e => setInnerValue(e.target.value)
      }
      value={innerValue}
    />
  )
}
