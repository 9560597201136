import { EndDateCell } from './cells/EndDateCell'
import { RowNumberCell } from './cells/RowNumberCell'
import { SectionNameCell } from './cells/SectionNameCell'
import { StartDateCell } from './cells/StartDateCell'
import { VariableItemCell } from './cells/VariableItemCell'
import { VariableSectionCell } from './cells/VariableSectionCell'

type Props = {
  /** 行番号 */
  rowNumber: number
  /** 行の高さ */
  rowHeight: number
  /** セクション名セルのオプション */
  sectionNameOptions: { value: string; label: string }[]
}

export const BodyRow = ({
  rowNumber,
  rowHeight,
  sectionNameOptions,
}: Props) => {
  return (
    <>
      {/* # */}
      <RowNumberCell rowNumber={rowNumber} />

      {/* 適用開始日 */}
      <StartDateCell rowNumber={rowNumber} colNumber={1} />

      {/* 適用終了日 */}
      <EndDateCell rowNumber={rowNumber} colNumber={2} />

      {/* セクション名 */}
      <SectionNameCell
        rowNumber={rowNumber}
        colNumber={3}
        options={sectionNameOptions}
      />

      {/* 取り込みセクション */}
      <VariableSectionCell
        rowNumber={rowNumber}
        colNumber={4}
        textAreaMinHeight={rowHeight}
      />

      {/* 取り込み項目 1〜6 */}
      <VariableItemCell
        rowNumber={rowNumber}
        variableNumber={1}
        colNumber={5}
        textAreaMinHeight={rowHeight}
      />
      <VariableItemCell
        rowNumber={rowNumber}
        variableNumber={2}
        colNumber={6}
        textAreaMinHeight={rowHeight}
      />
      <VariableItemCell
        rowNumber={rowNumber}
        variableNumber={3}
        colNumber={7}
        textAreaMinHeight={rowHeight}
      />
      <VariableItemCell
        rowNumber={rowNumber}
        variableNumber={4}
        colNumber={8}
        textAreaMinHeight={rowHeight}
      />
      <VariableItemCell
        rowNumber={rowNumber}
        variableNumber={5}
        colNumber={9}
        textAreaMinHeight={rowHeight}
      />
      <VariableItemCell
        rowNumber={rowNumber}
        variableNumber={6}
        colNumber={10}
        textAreaMinHeight={rowHeight}
      />
    </>
  )
}
