import { DeleteOutlined } from '@ant-design/icons'
import { Employee, PlaceNodeTypeEnum } from '@ulysses-inc/harami_api_client'
import { Checkbox, Modal } from 'antd'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RowActionButton } from 'src/components/rowActionButton/RowActionButton'
import { getUniqueFlattenNodes } from 'src/exShared/util/place/getUniqueFlattenNodes'
import { flattenNodes } from 'src/exShared/util/place/placeNode'
import { ModalDeleteButtonColor } from 'src/features/theme/KdsThemeColor'
import employeesOperations from 'src/state/ducks/employees/operations'
import { RootState } from 'src/state/store'
import {
  ButtonGroupRow,
  EmployeesHeader,
  EmployeesHeaderContainer,
  EmployeesHeaderWrap,
  HeaderRow,
  TableBody,
  TableRow,
  TableRowWrap,
} from './EmployeesList.styled'

interface Props {
  chooseEmployees: { [key: string]: boolean }
  setChooseEmployees: (employees: { [key: string]: boolean }) => void
}

const EmployeeList: React.FC<Props> = (props: Props) => {
  const dispatch = useDispatch()

  const { chooseEmployees, setChooseEmployees } = props

  const [activeEmployeeUUID, setActiveEmployeeUUID] = useState<string>()

  const { employees = [] } = useSelector(
    (state: RootState) => state.employeesState.employees,
  )

  const {
    places: { places },
    placeGroups: { nodes },
  } = useSelector((state: RootState) => state.placesState)

  const placeNodes = getUniqueFlattenNodes([
    ...flattenNodes(nodes ?? []),
    ...(places ?? []),
  ])

  useEffect(() => {
    Object.keys(chooseEmployees).forEach(employeeUUID => {
      const isContain =
        employees.findIndex(employee => employee.uuid === employeeUUID) >= 0
      if (!isContain) {
        handleCheckEmployee(employeeUUID, false)
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employees])

  const openEditEmployee = (employee: Employee) => {
    employeesOperations.updateActiveEmployeeUUID(dispatch, employee.uuid ?? '')
    employeesOperations.changeIsShowEditEmployeeDrawer(dispatch, true)
  }
  const deleteEmployee = (uuid: string) => {
    employeesOperations.deleteEmployee(dispatch, uuid)
  }

  const isCheckedAll = () => {
    if (employees.length === 0) return false
    const unChecked = employees.filter(
      (employee: Employee) =>
        employee.uuid !== undefined &&
        employee.uuid !== '' &&
        !Object.hasOwnProperty.call(chooseEmployees, employee.uuid),
    )
    return unChecked.length === 0
  }

  const handleCheckAll = (checked: boolean) => {
    if (checked) {
      employees.forEach((employee: Employee) => {
        if (employee.uuid === undefined || employee.uuid === '') {
          return
        }
        chooseEmployees[employee.uuid] = true
      })
      setChooseEmployees({ ...chooseEmployees })
      return
    }
    employees.forEach((employee: Employee) => {
      if (employee.uuid === undefined || employee.uuid === '') {
        return
      }
      delete chooseEmployees[employee.uuid]
    })
    setChooseEmployees({ ...chooseEmployees })
  }

  const handleCheckEmployee = (employeeUUID: string, checked: boolean) => {
    if (checked) {
      chooseEmployees[employeeUUID] = true
      setChooseEmployees({ ...chooseEmployees })
    } else {
      delete chooseEmployees[employeeUUID]
      setChooseEmployees({ ...chooseEmployees })
    }
  }
  return (
    <EmployeesHeaderContainer justify="center">
      <EmployeesHeaderWrap>
        <EmployeesHeader>
          <HeaderRow theme={{ width: '3%' }}>
            <Checkbox
              checked={isCheckedAll()}
              onChange={e => handleCheckAll(e.target.checked)}
            />
          </HeaderRow>
          <HeaderRow theme={{ width: '30%' }}>従業員コード</HeaderRow>
          <HeaderRow theme={{ width: '30%' }}>ユーザー名</HeaderRow>
          <HeaderRow theme={{ width: '37%' }}>現場名</HeaderRow>
        </EmployeesHeader>
        <TableBody
          itemLayout="horizontal"
          dataSource={employees}
          renderItem={employee => (
            <TableRowWrap
              key={employee.uuid}
              onMouseEnter={() => setActiveEmployeeUUID(employee.uuid)}
              onMouseLeave={() => setActiveEmployeeUUID(undefined)}
            >
              <TableRow theme={{ width: '3%' }}>
                <Checkbox
                  checked={
                    employee.uuid !== undefined && employee.uuid !== ''
                      ? Object.hasOwnProperty.call(
                          chooseEmployees,
                          employee.uuid,
                        )
                      : false
                  }
                  onChange={e =>
                    employee.uuid &&
                    handleCheckEmployee(employee.uuid, e.target.checked)
                  }
                />
              </TableRow>
              <TableRow theme={{ width: '30%' }}>
                {employee.employeeCode}
              </TableRow>
              <TableRow theme={{ width: '30%' }}>{employee.name}</TableRow>
              <TableRow theme={{ width: '37%' }}>
                {employee.placeNodes && employee.placeNodes.length > 0
                  ? employee.placeNodes
                      .map(node => {
                        if (node.type === PlaceNodeTypeEnum.Place) {
                          return (
                            placeNodes.find(
                              placeNode => placeNode.uuid === node.uuid,
                            )?.place?.name || ''
                          )
                        } else {
                          return (
                            placeNodes.find(
                              placeNode => placeNode.uuid === node.uuid,
                            )?.placeGroup?.name || ''
                          )
                        }
                      })
                      .join(' ')
                  : ''}
              </TableRow>
              {activeEmployeeUUID === employee.uuid && (
                <ButtonGroupRow>
                  <RowActionButton
                    onClick={() => openEditEmployee(employee)}
                    type="edit"
                  />
                  <RowActionButton
                    onClick={() => {
                      Modal.confirm({
                        onOk: () => deleteEmployee(employee.uuid ?? ''),
                        title: `「${employee.name}」の削除を実行しますがよろしいですか？`,
                        okText: (
                          <>
                            <DeleteOutlined />
                            削除
                          </>
                        ),
                        okButtonProps: {
                          style: {
                            backgroundColor: ModalDeleteButtonColor,
                            border: 'none',
                          },
                        },
                        cancelText: 'キャンセル',
                      })
                    }}
                    type="delete"
                  />
                </ButtonGroupRow>
              )}
            </TableRowWrap>
          )}
        />
      </EmployeesHeaderWrap>
    </EmployeesHeaderContainer>
  )
}

export default EmployeeList
