import { Button, Pagination, Row } from 'antd'
import styled from 'styled-components'

export const AddFlowButton = styled(Button)`
  border: none;
`

export const DownloadListButton = styled(Button)`
  margin-right: 16px;
`

export const ActionRow = styled(Row)`
  margin: 15px;
`

export const FlowsPagination = styled(Pagination)`
  margin-top: 16px;
  margin-bottom: 16px;
  display: flex;
  justify-content: center;
`
