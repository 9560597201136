import { Typography } from 'antd'
import { ComponentProps, useState } from 'react'

const { Paragraph } = Typography

type Props = {
  children: string
  line?: number
} & Exclude<ComponentProps<typeof Paragraph>, 'ellipsis'>

/**
 * 与えられた文字列を指定された行数で表示し、はみ出た部分は`...`で表示したうえ、Tooltipも提供するコンポーネント
 *
 * 実体はantdのParagraphコンポーネントであるため、APIはParagraphのものをそのまま利用できる
 */
export const ClampText = (props: Props) => {
  const { children, line = 1, style, ...restProps } = props

  const [isEllipsized, setIsEllipsized] = useState(false)

  return (
    <Paragraph
      {...restProps}
      ellipsis={{
        onEllipsis: setIsEllipsized,
        rows: line,
        tooltip: isEllipsized && children,
      }}
      style={{ ...style, marginBottom: 0 }}
    >
      {children}
    </Paragraph>
  )
}
