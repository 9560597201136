import { useEffect, useState } from 'react'

type WindowSize = {
  width: number | undefined
  height: number | undefined
}

/**
 * 以下より流用
 * https://usehooks.com/useWindowSize/
 */
export const useWindowSize = () => {
  // haramiではSSRを行っていないため関係ないが、
  // SSR時にはwindowが存在しないためこの手のhooksでは初期値をundefinedにしておくのが原則。
  // https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState<WindowSize>({
    width: undefined,
    height: undefined,
  })

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }

    window.addEventListener('resize', handleResize)

    // 初回レンダリング時の実行は手動で行う必要がある
    handleResize()

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return windowSize
}
