import { Button } from 'antd'
import styled, { css } from 'styled-components'

/**
 * ヘッダーで使用するタブボタン
 */
export const HeaderTabButton = styled(Button)`
  ${({ theme }) =>
    theme.color &&
    css`
      color: ${theme.color};
    `}
  font-size: 20px;
  font-weight: bold;
  padding: 0;

  &:not(:first-child) {
    // ヘッダー内のアイテムは通常16px間隔で配置されるが、
    // タブはそれより少し広い24px間隔で配置するデザイン要件があるため。
    // https://www.figma.com/file/3swIU7IP5FEamZ3VErR7hU/%F0%9F%8D%85-Web?type=design&node-id=2196-8523&mode=design&t=Gpzh5oniMeMjDDul-0
    margin-left: 8px;
  }
`
