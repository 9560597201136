// prettier-ignore
export const ActionTypes = {
  REQUEST_GET_EXCEL_CONVERSION_LOGS: 'harami/excelConversionLogs/REQUEST_GET_EXCEL_CONVERSION_LOGS',
  SUCCESS_GET_EXCEL_CONVERSION_LOGS: 'harami/excelConversionLogs/SUCCESS_GET_EXCEL_CONVERSION_LOGS',
  ERROR_GET_EXCEL_CONVERSION_LOGS: 'harami/excelConversionLogs/ERROR_GET_EXCEL_CONVERSION_LOGS',
  REQUEST_CHANGE_EXCEL_CONVERSION_LOGS_PAGE: 'harami/excelConversionLogs/REQUEST_CHANGE_EXCEL_CONVERSION_LOGS_PAGE',
  REQUEST_CHANGE_EXCEL_CONVERSION_LOGS_SIZE: 'harami/excelConversionLogs/REQUEST_CHANGE_EXCEL_CONVERSION_LOGS_SIZE',
  UPDATE_PAGINATION: 'harami/excelConversionLogs/UPDATE_PAGINATION',

  REQUEST_GET_EXCEL_CONVERSION_LOG_FILES: 'harami/excelConversionLogs/REQUEST_GET_EXCEL_CONVERSION_LOG_FILES',
  SUCCESS_GET_EXCEL_CONVERSION_LOG_FILES: 'harami/excelConversionLogs/SUCCESS_GET_EXCEL_CONVERSION_LOG_FILES',
  ERROR_GET_EXCEL_CONVERSION_LOG_FILES: 'harami/excelConversionLogs/ERROR_GET_EXCEL_CONVERSION_LOG_FILES',
} as const

export interface GetExcelConversionLogsFilter {
  flowUUIDs?: { $in: string[] }
  status?: { $in: number[] }
  placeNodeUUIDs?: { $in: string[] }
  logDate?: { $gte: Date; $lte: Date }
}
