import { useCallback, useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import {
  RowPatch,
  patchInputTable,
} from 'src/state/ducks/editGridVariables/actions'
import { RootState } from 'src/state/store'
import { getStartPosition } from '../helpers/getStartPosition'

/**
 * クリップボードからのペースト機能を提供するフック
 */
export const usePasteHandler = () => {
  const mode = useSelector(
    (state: RootState) => state.editGridVariablesState.editGridVariables.mode,
  )

  const selectedCells = useSelector(
    (state: RootState) =>
      state.editGridVariablesState.editGridVariables.selectedCells,
    shallowEqual,
  )

  const [startRowNum, startColNum] = getStartPosition(selectedCells)

  const dispatch = useDispatch()

  const handlePaste = useCallback(
    (e: ClipboardEvent) => {
      if (mode !== 'select') {
        return
      }

      e.preventDefault()

      // どこも選択されていなければ何もしない
      if (startRowNum === undefined || startColNum === undefined) {
        return
      }

      if (e.clipboardData === null) {
        return
      }

      const patches = convertTsvToRowPatches(
        e.clipboardData.getData('text/plain'),
        startRowNum,
        startColNum,
      )
      dispatch(patchInputTable(patches))
    },
    [mode, startRowNum, startColNum, dispatch],
  )

  useEffect(() => {
    document.addEventListener('paste', handlePaste)

    // WARN: 以下のように明示的にイベントハンドラーを解除しなければならない
    return () => {
      document.removeEventListener('paste', handlePaste)
    }
  }, [handlePaste])
}

/**
 * tsv 形式の文字列を行パッチの連想配列に変換する
 *
 * tsv については、タブで区切られ、CR または CRLF で改行されている文字列のみサポートする
 *
 * @param tsv tsv 形式の文字列
 * @param startRowNum 始端の行番号
 * @param startColNum 始端の列番号
 * @returns 行パッチの連想配列
 */
export const convertTsvToRowPatches = (
  tsv: string,
  startRowNum: number,
  startColNum: number,
) => {
  /**
   * この関数では、tsv とテーブルのマッピング処理を行う
   * (スプレッドシートで、選択範囲の左上を開始地点として、クリップボードの内容がペーストされる様子を思い浮かべてほしい)
   * 下記に param と returns の例を示す
   *
   * (1)
   *   param:
   *     tsv = | hoge | fuga | piyo |
   *           | foo  | bar  | baz  |
   *     startRowNum, startColNum = { 1, 1 }
   *
   *   returns:
   *       | from | through | sectionName | variableSection | values[1] | values[2] | values[3] | values[4] | values[5] | values[6] |
   *    1: | hoge |  fuga   |    piyo     |                 |           |           |           |           |           |           |
   *    2: | foo  |  bar    |    baz      |                 |           |           |           |           |           |           |
   *
   * (2)
   *   param:
   *     tsv = | hoge | fuga | piyo |
   *           | foo  | bar  | baz  |
   *     startRowNum, startColNum = { 3, 5 }
   *
   *   returns:
   *       | from | through | sectionName | variableSection | values[1] | values[2] | values[3] | values[4] | values[5] | values[6] |
   *    3: |      |         |             |                 |   hoge    |   fuga    |   piyo    |           |           |           |
   *    4: |      |         |             |                 |   foo     |   bar     |   baz     |           |           |           |
   *
   */

  // tsv からテーブル状のデータを生成する
  const tsvTable = tsv
    // Windows Excel からコピーした際など末尾に改行コードが挿入されている場合があるので、末尾の改行コードを削除する
    .replace(/[\r\n]+$/, '')
    .split(/\r\n|\n/)
    .map(v => v.split('\t'))

  const patches: { [key: number]: RowPatch } = {}

  for (
    let tsvRowNum = 0, rowNum = startRowNum;
    tsvRowNum < tsvTable.length;
    tsvRowNum++, rowNum++
  ) {
    let from
    let through
    let sectionName
    let variableSection
    // WARN: 2023/02 の仕様だと取り込み項目は 1~6 で固定だが、可変にしたくなったときにこれでは対応できない
    const values: { [key: number]: string | undefined } = {
      1: undefined,
      2: undefined,
      3: undefined,
      4: undefined,
      5: undefined,
      6: undefined,
    }

    for (
      let tsvColNum = 0, colNum = startColNum;
      tsvColNum < (tsvTable[0] || []).length;
      tsvColNum++, colNum++
    ) {
      const v = tsvTable[tsvRowNum]?.[tsvColNum]
      switch (colNum) {
        case 1:
          from = v
          break
        case 2:
          through = v
          break
        case 3:
          sectionName = v
          break
        case 4:
          variableSection = v
          break
        // WARN: 2023/02 の仕様だと取り込み項目は 1~6 で固定だが、可変にしたくなったときにこれでは対応できない
        case 5:
        case 6:
        case 7:
        case 8:
        case 9:
        case 10:
          /**
           * 列番号と取り込み項目番号の対応関係
           *   - 列番号 5  => 取り込み項目 1
           *   - 列番号 10 => 取り込み項目 6
           */
          values[colNum - 4] = v
          break
        // クリップボードデータの 11 列目以降は無視する
        default:
      }
    }

    const patch: RowPatch = {
      from,
      through,
      sectionName,
      variableSection,
      values,
    }

    patches[rowNum] = patch
  }

  return patches
}
