import {
  ExcelConversionStepTypeEnum,
  TemplateExcelConversionFlow,
  TemplateNodeSchema,
  TemplateNodeTypeEnum,
  TemplatePageSchema,
} from '@ulysses-inc/harami_api_client'

/**
 * 対象ページの配下の質問を使用しているExcel変換設定の一覧を取得する
 * @param pageId 対象ページ
 * @param templatePages テンプレートの全ページ情報
 * @param templateNodes テンプレートの全ノード情報
 * @param excelConversionFlows テンプレートのExcel変換設定の一覧
 * @returns
 */
const getRelatedExcelConversionFlowInPage = (
  pageId: number,
  templatePages: { [key: number]: TemplatePageSchema },
  templateNodes: { [key: number]: TemplateNodeSchema },
  excelConversionFlows: Array<TemplateExcelConversionFlow>,
): string[] => {
  const page = templatePages[pageId]
  if (!page?.nodes) {
    return []
  }
  const relatedFlowNames = page.nodes.flatMap(nodeId => {
    const node = templateNodes[nodeId]
    switch (node?.type) {
      case TemplateNodeTypeEnum.Section:
        return getRelatedExcelConversionFlowInSection(
          nodeId,
          templateNodes,
          excelConversionFlows,
        )
      case TemplateNodeTypeEnum.Question:
        return getRelatedExcelConversionFlowInQuestion(
          nodeId,
          templateNodes,
          excelConversionFlows,
        )
    }
    return []
  })

  return Array.from(new Set(relatedFlowNames))
}

/**
 * 対象セクションの配下の質問を使用しているExcel変換設定の一覧を取得する
 * @param sectionId 対象セクション
 * @param templateNodes テンプレートの全ノード情報
 * @param excelConversionFlows テンプレートのExcel変換設定の一覧
 * @returns
 */
const getRelatedExcelConversionFlowInSection = (
  sectionId: number,
  templateNodes: { [key: number]: TemplateNodeSchema },
  excelConversionFlows: Array<TemplateExcelConversionFlow>,
): string[] => {
  const section = templateNodes[sectionId]
  if (!section?.nodes) {
    return []
  }

  const relatedFlowNames = section.nodes.flatMap(nodeId => {
    const node = templateNodes[nodeId]
    switch (node?.type) {
      case TemplateNodeTypeEnum.Section:
        return getRelatedExcelConversionFlowInSection(
          nodeId,
          templateNodes,
          excelConversionFlows,
        )
      case TemplateNodeTypeEnum.Question:
        return getRelatedExcelConversionFlowInQuestion(
          nodeId,
          templateNodes,
          excelConversionFlows,
        )
    }
    return []
  })

  return Array.from(new Set(relatedFlowNames))
}

/**
 * 対象質問と条件分岐が設定されている場合その配下の質問を使用しているExcel変換設定の一覧を取得する
 * @param questionId 対象質問
 * @param templateNodes テンプレートの全ノード情報
 * @param excelConversionFlows テンプレートのExcel変換設定の一覧
 * @returns
 */
const getRelatedExcelConversionFlowInQuestion = (
  questionId: number,
  templateNodes: { [key: number]: TemplateNodeSchema },
  excelConversionFlows: Array<TemplateExcelConversionFlow>,
): string[] => {
  const question = templateNodes[questionId]

  if (!question.createdAt) {
    // 画面上で新規に追加された質問の場合は、スキップ
    return []
  }
  let relatedFlowNames: string[] = []

  if (
    question.question?.excelConversionTypes?.find(
      type => type.excelConversionType === ExcelConversionStepTypeEnum.Single,
    )
  ) {
    // 単数出力ラベルが設定されている場合(ノードのuuidでさらに付き合わせ)
    const names: string[] = excelConversionFlows
      .filter(flow => flow.stepType === ExcelConversionStepTypeEnum.Single)
      .filter(flow => flow.templateNodeUUID === question.uuid)
      .map(flow => flow.flowName)
      .filter((item): item is string => typeof item == 'string')
    relatedFlowNames = relatedFlowNames.concat(names)
  }

  const repeatLabel = question.question?.excelConversionTypes?.find(
    type => type.excelConversionType === ExcelConversionStepTypeEnum.Repeat,
  )
  if (repeatLabel) {
    // 連続出力ラベルが設定されている場合(シリアル番号でさらに付き合わせ)
    const names: string[] = excelConversionFlows
      .filter(flow => flow.stepType === ExcelConversionStepTypeEnum.Repeat)
      .filter(flow => flow.serialNumber === repeatLabel.serialNumber)
      .map(flow => flow.flowName)
      .filter((item): item is string => typeof item == 'string')
    relatedFlowNames = relatedFlowNames.concat(names)
  }

  const multipleLabel = question.question?.excelConversionTypes?.find(
    type => type.excelConversionType === ExcelConversionStepTypeEnum.Multiple,
  )

  if (multipleLabel) {
    // 繰り返し出力ラベルが設定されている場合(シリアル番号でさらに付き合わせ)
    const names: string[] = excelConversionFlows
      .filter(flow => flow.stepType === ExcelConversionStepTypeEnum.Multiple)
      .filter(flow => flow.serialNumber === multipleLabel.serialNumber)
      .map(flow => flow.flowName)
      .filter((item): item is string => typeof item == 'string')
    relatedFlowNames = relatedFlowNames.concat(names)
  }

  const periodLabel = question.question?.excelConversionTypes?.find(
    type => type.excelConversionType === ExcelConversionStepTypeEnum.Period,
  )

  if (periodLabel) {
    // 期間出力ラベルが設定されている場合
    const names: string[] = excelConversionFlows
      .filter(flow => flow.stepType === ExcelConversionStepTypeEnum.Period)
      .map(flow => flow.flowName)
      .filter((item): item is string => typeof item == 'string')
    relatedFlowNames = relatedFlowNames.concat(names)
  }

  if (question.nodes) {
    const child = question.nodes.flatMap(nodeId =>
      getRelatedExcelConversionFlowInLogic(
        nodeId,
        templateNodes,
        excelConversionFlows,
      ),
    )
    relatedFlowNames = relatedFlowNames.concat(child)
  }

  return Array.from(new Set(relatedFlowNames))
}

/**
 * 対象条件分岐の配下の質問を使用しているExcel変換設定の一覧を取得する
 * @param logicId 対象条件分岐
 * @param templateNodes テンプレートの全ノード情報
 * @param excelConversionFlows テンプレートのExcel変換設定の一覧
 * @returns
 */
const getRelatedExcelConversionFlowInLogic = (
  logicId: number,
  templateNodes: { [key: number]: TemplateNodeSchema },
  excelConversionFlows: Array<TemplateExcelConversionFlow>,
): string[] => {
  const logic = templateNodes[logicId]

  if (!logic.nodes) {
    return []
  }

  const relatedFlowNames = logic.nodes.flatMap(nodeId => {
    const node = templateNodes[nodeId]

    switch (node?.type) {
      case TemplateNodeTypeEnum.Section:
        return getRelatedExcelConversionFlowInSection(
          nodeId,
          templateNodes,
          excelConversionFlows,
        )
      case TemplateNodeTypeEnum.Question:
        return getRelatedExcelConversionFlowInQuestion(
          nodeId,
          templateNodes,
          excelConversionFlows,
        )
    }
    return []
  })

  return Array.from(new Set(relatedFlowNames))
}

export {
  getRelatedExcelConversionFlowInLogic,
  getRelatedExcelConversionFlowInPage,
  getRelatedExcelConversionFlowInQuestion,
  getRelatedExcelConversionFlowInSection,
}
