import { ReportExportStatusEnum } from '@ulysses-inc/harami_api_client'
import { Button, Table } from 'antd'
import { ColumnsType } from 'antd/es/table'
import { format } from 'date-fns'
import { ErrorMessage } from 'src/components/errorMessage/ErrorMessage'
import { ReportExportListWrapper } from 'src/features/reportExport/list/ReportExportListWrapper'
import { useMutationDownloadFile } from 'src/features/reportExport/list/api/useMutationDownloadFile'
import {
  ReportExport,
  useQueryReportExports,
} from 'src/features/reportExport/list/api/useQueryReportExports'
import { StatusIcon } from 'src/features/reportExport/list/components/StatusIcon'
import { useReportExportListFilters } from 'src/features/reportExport/list/filters/useReportExportListFilters'
import { useLastDefinedValue } from 'src/hooks/useLastDefinedValue'

export const ReportExportList = () => {
  const { filters, setFilters } = useReportExportListFilters()
  const reportExportsQuery = useQueryReportExports(filters)

  // ページ切替時にページネーターが消失するとユーザー体験が悪いため
  const reportExportsCount = useLastDefinedValue(reportExportsQuery.data?.count)

  const { mutate: downloadFile } = useMutationDownloadFile()

  const columns: ColumnsType<ReportExport> = [
    {
      title: 'ファイル名',
      dataIndex: 'fileName',
      key: 'fileName',
      ellipsis: true,
    },
    {
      title: 'ステータス',
      dataIndex: 'status',
      key: 'status',
      render: (status: ReportExportStatusEnum) => (
        <StatusIcon status={status} />
      ),
      width: '130px',
    },
    {
      title: '出力日時',
      dataIndex: 'requestedAt',
      key: 'requestedAt',
      render: (requestedAt: Date) => format(requestedAt, 'yyyy/MM/dd HH:mm'),
      width: '160px',
    },
    {
      title: '有効期限',
      dataIndex: 'expiredAt',
      key: 'expiredAt',
      render: (requestedAt: Date) => format(requestedAt, 'yyyy/MM/dd HH:mm'),
      width: '160px',
    },
    {
      key: 'actions',
      render: (reportExport: ReportExport) => {
        if (reportExport.status !== ReportExportStatusEnum.COMPLETED)
          return null
        return (
          <Button
            onClick={() =>
              downloadFile({
                reportExportId: reportExport.id,
              })
            }
            style={styles.downloadButton}
            type="link"
          >
            ダウンロード
          </Button>
        )
      },
      width: '130px',
    },
  ]

  if (reportExportsQuery.isError) {
    return (
      <ReportExportListWrapper>
        <ErrorMessage noMargin>エラーが発生しました</ErrorMessage>
      </ReportExportListWrapper>
    )
  }

  return (
    <ReportExportListWrapper>
      <Table
        columns={columns}
        dataSource={reportExportsQuery.data?.reportExports}
        loading={
          // ページを切り替えるたびにテーブルが消失してUIが悪化しないよう、isErrorと同じ場所でなく、あえてここでisLoadingを見ている。
          reportExportsQuery.isLoading
          // 現状では、2回目以降のバックグランドでのフェッチ中(reportExports.isFetching)では特にUI上は何もしていないが、
          // より親切にするならば NProgress の表示などを検討してもよさそう。
        }
        pagination={{
          current: filters.page,
          onChange: (page, pageSize) => {
            setFilters({ page, pageSize })
          },
          pageSize: filters.pageSize,
          pageSizeOptions: ['10', '25', '50', '100'],
          position: ['bottomCenter'],
          total: reportExportsCount,
        }}
        rowKey="id"
      ></Table>
    </ReportExportListWrapper>
  )
}

const styles = {
  downloadButton: {
    border: 0,
    padding: 0,
    height: '20px',
    lineHeight: '20px',
  },
}
