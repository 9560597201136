import { css as emotionCss } from '@emotion/react'
import { ComponentProps, FC } from 'react'
import {
  AppBackgroundColor,
  Gray,
  RequiredBadgeColor,
  ScrollBarThumbColor,
  TableBorderColor,
  White,
} from 'src/features/theme/KdsThemeColor'
import styled, { css } from 'styled-components'
import {
  CellFontSize,
  CellLineHeight,
  bodyFontStyles,
  commonCellStyles,
} from './styles'

export const Table = styled.table`
  display: block;
  overflow-x: auto;
  padding-bottom: 8px;
  border-spacing: 0;
  border-collapse: separate;
  word-break: break-word;

  &::-webkit-scrollbar {
    height: 16px;
    background-color: ${White};
  }

  &::-webkit-scrollbar-track {
    background-color: ${White};
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${ScrollBarThumbColor};
    border-top: solid 4px ${White};
    border-bottom: solid 4px ${White};
    border-left: solid 8px ${White};
    border-right: solid 8px ${White};
  }
`

export const TableHeader = styled.thead``
export const TableBody = styled.tbody``
export const TableRow = styled.tr``

export const TableHeaderCell = styled.th`
  ${commonCellStyles}
  background-color: ${White};
  font-size: ${CellFontSize}px;
  line-height: ${CellLineHeight}px;
  font-weight: 700;

  border-bottom: 2px solid ${TableBorderColor};
  border-right: 1px solid ${TableBorderColor};
  border-top: 1px solid ${TableBorderColor};
`

export const TableHeaderCellText = styled.div<{ isRequired?: boolean }>`
  display: inline-block;
  position: relative;
`

export const RequiredBadge = styled.div`
  display: inline-block;
  position: absolute;
  height: 8px;
  width: 8px;
  border-radius: 50px;
  top: -2px;
  left: -8px;
  background-color: ${RequiredBadgeColor};
`

const columnFirstStyles = css`
  position: sticky;
  left: 0;
  z-index: 1;
`
export const TableHeaderFirstCell = styled.th`
  ${columnFirstStyles}
  border-top: none;
  border-left: none;
  background-color: ${AppBackgroundColor};

  border-right: 1px solid ${TableBorderColor};
  border-bottom: 1px solid ${TableBorderColor};
`

export const TableBodyFirstCell = styled.td`
  ${bodyFontStyles}
  ${columnFirstStyles}
  background-color: ${White};
  padding: 8px 8px;
  color: ${Gray};

  border-left: 1px solid ${TableBorderColor};
  border-right: 1px solid ${TableBorderColor};
  border-bottom: 1px solid ${TableBorderColor};
`

export const CellInner: FC<ComponentProps<'div'>> = props => (
  <div
    {...props}
    css={emotionCss`
      display: flex;
      align-items: center;
      flex-direction: column;
    `}
  />
)
