import { useMutation } from '@tanstack/react-query'
import { UsersApi } from '@ulysses-inc/harami_api_client'
import { baseClient } from 'src/state/middleware/saga/baseClient'
import { handleHTTPError } from 'src/state/middleware/saga/handleHttpError'

type Variables = {
  loginId: string
  password: string
}

// RUMP移行後には不要になる
export const useLoginMutation = () => {
  return useMutation({
    mutationFn: async (variables: Variables) => {
      const result = await baseClient
        .getApi(UsersApi, false)
        .login({
          login: {
            loginId: variables.loginId,
            password: variables.password,
          },
        })
        .catch(handleHTTPError)
      return result
    },
  })
}
