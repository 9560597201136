import { TemplateIconTypeEnum } from '@ulysses-inc/harami_api_client'
import CheckListSvg from 'src/constants/svg/checklist.svg?react'
import FacilitySvg from 'src/constants/svg/facility.svg?react'
import FoodSvg from 'src/constants/svg/food.svg?react'
import ManufactureSvg from 'src/constants/svg/manufacture.svg?react'
import TemperatureSvg from 'src/constants/svg/temperature.svg?react'
import TransportSvg from 'src/constants/svg/transport.svg?react'
import {
  Danger,
  Gray,
  Info,
  Primary,
  Success,
  Warning,
} from 'src/features/theme/KdsThemeColor'

export const templateIconTypes = [
  {
    backgroundColor: Warning,
    value: TemplateIconTypeEnum.Checklist,
    svgComponent: CheckListSvg,
    label: 'チェックリスト',
  },
  {
    backgroundColor: Success,
    value: TemplateIconTypeEnum.Temperature,
    svgComponent: TemperatureSvg,
    label: '温度',
  },
  {
    backgroundColor: Primary,
    value: TemplateIconTypeEnum.Facility,
    svgComponent: FacilitySvg,
    label: '設備',
  },
  {
    backgroundColor: Info,
    value: TemplateIconTypeEnum.Food,
    svgComponent: FoodSvg,
    label: '食品',
  },
  {
    backgroundColor: Gray,
    value: TemplateIconTypeEnum.Manufacture,
    svgComponent: ManufactureSvg,
    label: '製造',
  },
  {
    backgroundColor: Danger,
    value: TemplateIconTypeEnum.Transport,
    svgComponent: TransportSvg,
    label: '輸送',
  },
]
