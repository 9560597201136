import { useQuery } from '@tanstack/react-query'
import { PlaceNodeTypeEnum, PlacesApi } from '@ulysses-inc/harami_api_client'
import { commonQueryKeys } from 'src/features/tanStackQuery/commonQueries/commonQueryKeys'
import { baseClient } from 'src/state/middleware/saga/baseClient'
import { localeAwareSort } from 'src/util/sortStringsLocaleAware'

type SimplifiedPlaceNode = {
  name: string
  type: 'place' | 'placeGroup'
  uuid: string
}

/**
 * ユーザーが所属する企業に存在する全ての現場ノードを取得する
 *
 * 今後必要に応じてフィルタ条件などを指定できるようにすると便利かも(e.g. 現場グループのノードのみ取得する、など)
 */
export const useQueryPlaceNodes = () =>
  useQuery({
    queryKey: commonQueryKeys.allPlaceNodes,
    queryFn: () => baseClient.getApi(PlacesApi).getPlaceNodes(),
    select: (data): SimplifiedPlaceNode[] => {
      const result = data.map(placeNode => {
        switch (placeNode.type) {
          case PlaceNodeTypeEnum.Place: {
            return {
              name: placeNode.place?.name || '',
              uuid: placeNode.uuid,
              type: 'place' as const,
            }
          }
          case PlaceNodeTypeEnum.PlaceGroup: {
            return {
              name: placeNode.placeGroup?.name || '',
              uuid: placeNode.uuid,
              type: 'placeGroup' as const,
            }
          }
          default: {
            throw new Error(`Unexpected place node type: ${placeNode.type}`)
          }
        }
      })
      return [...result].sort((a, b) => localeAwareSort(a.name, b.name))
    },
    // 画面を二回目以降に開く際に、時間差でデータが更新されることによる事故を防ぐため
    gcTime: 0,
  })
