import {
  Improve,
  ImprovesApi,
  ImproveStatusStatusEnum,
  UpdateImproveRequest,
  UpdateImproveStatusRequest,
  User,
} from '@ulysses-inc/harami_api_client'
import { call, put } from 'redux-saga/effects'
import { localStorageKeys } from 'src/constants/localStorageKeys'
import {
  createImproveReviseSendEmail,
  createImproveSendEmail,
} from 'src/exShared/util/email/createSendEmail'
import * as notificationServiceActions from 'src/features/notificationService/slice'
import { default as improveEmailLogsAction } from 'src/state/ducks/improveEmailLogs/actions'
import interceptionsActions from 'src/state/ducks/interceptions/actions'
import BaseClient from 'src/state/middleware/saga/baseClient'
import {
  handleHTTPError,
  HTTPError,
} from 'src/state/middleware/saga/handleHttpError'
import { updateImprove as updateImproveActions } from '../actions'

const baseClient = new BaseClient()

const updateImproveRequest = (req: UpdateImproveRequest) => {
  return baseClient
    .getApi(ImprovesApi)
    .updateImprove(req)
    .then(improve => improve)
    .catch(handleHTTPError)
}

const updateImproveStatusRequest = (req: UpdateImproveStatusRequest) => {
  return baseClient
    .getApi(ImprovesApi)
    .updateImproveStatus(req)
    .then(improve => improve)
    .catch(handleHTTPError)
}

function* updateImprove(
  action: ReturnType<typeof updateImproveActions.updateRequestImprove>,
) {
  const improve: Improve = {
    id: action.improve.id,
    uuid: action.improve.uuid,
    reportUUID: action.improve.reportUUID,
    reportName: action.improve.reportName,
    status: action.improve.status,
    place: action.improve.place,
    indicatedIssueGroups: action.improve.indicatedIssueGroups,
    placeUserGroup: action.improve.placeUserGroup,
    auditUserGroup: action.improve.auditUserGroup,
    auditUser: action.improve.auditUser,
  }
  // 被監査 -> 監査側 へのメール送信の userリストは auditUserGroup を用いる
  const userUUIDs =
    improve.status?.status === ImproveStatusStatusEnum.PLACE_USER_WORKING
      ? (improve.auditUserGroup?.users ?? []).map(
          (user: User) => user.uuid ?? '',
        )
      : (improve.placeUserGroup?.users ?? []).map(
          (user: User) => user.uuid ?? '',
        )
  const loginUserUUID =
    localStorage.getItem(localStorageKeys.loginUserUuid) ?? ''
  const loginUserName =
    localStorage.getItem(localStorageKeys.loginUserName) ?? ''

  try {
    switch (improve.status?.status) {
      case ImproveStatusStatusEnum.AUDITOR_WORKING:
        if (!action.message) {
          yield call(updateImproveRequest, {
            improve,
            improveUUID: improve.uuid,
          })
          yield put(updateImproveActions.updateSuccessImprove())
          yield put(
            notificationServiceActions.showNotification({
              message: temporarySavedMessage,
            }),
          )
          return
        }

        improve.status.status = ImproveStatusStatusEnum.PLACE_USER_WORKING
        yield call(updateImproveRequest, { improve, improveUUID: improve.uuid })
        yield put(updateImproveActions.updateSuccessImprove())
        if (userUUIDs.length > 0) {
          yield put(
            improveEmailLogsAction.addImproveEmailLog(
              {
                addImproveEmail: {
                  improveEmailLog: {
                    improveUuid: improve.uuid,
                    improveStatus: ImproveStatusStatusEnum.AUDITOR_WORKING,
                    userUuid: loginUserUUID,
                    userName: loginUserName,
                    userGroupId: improve.placeUserGroup?.id,
                    userGroupName: improve.placeUserGroup?.name,
                    content: action.message,
                    sendAt: new Date(),
                  },
                  content: createImproveSendEmail(
                    action.improveUUID,
                    userUUIDs,
                    action.message,
                  ),
                },
              },
              'メッセージを送信しました',
            ),
          )
        }
        yield put(
          notificationServiceActions.showNotification({
            message: submitMessages[improve.status.status].message,
          }),
        )
        break
      case ImproveStatusStatusEnum.COMPLETE:
        yield call(updateImproveRequest, { improve, improveUUID: improve.uuid })
        yield put(updateImproveActions.updateSuccessImprove())

        if (!action.message) {
          yield put(
            notificationServiceActions.showNotification({
              message: temporarySavedMessage,
            }),
          )
          return
        }
        if (userUUIDs.length > 0) {
          yield put(
            improveEmailLogsAction.addImproveEmailLog(
              {
                addImproveEmail: {
                  improveEmailLog: {
                    improveUuid: improve.uuid,
                    improveStatus: ImproveStatusStatusEnum.COMPLETE,
                    userUuid: loginUserUUID,
                    userName: loginUserName,
                    userGroupId: improve.placeUserGroup?.id,
                    userGroupName: improve.placeUserGroup?.name,
                    content: action.message,
                    sendAt: new Date(),
                  },
                  content: createImproveSendEmail(
                    action.improveUUID,
                    userUUIDs,
                    action.message,
                  ),
                },
              },
              'メッセージを送信しました',
            ),
          )
        }
        yield put(
          notificationServiceActions.showNotification({
            message: submitMessages[improve.status.status].message,
          }),
        )
        break
      case ImproveStatusStatusEnum.PLACE_USER_WORKING:
        if (!action.message) {
          yield put(
            notificationServiceActions.showNotification({
              message: temporarySavedMessage,
            }),
          )
          return
        }

        yield call(updateImproveStatusRequest, {
          improveUUID: improve.uuid,
          improveStatus: {
            status: ImproveStatusStatusEnum.AUDITOR_WORKING,
          },
        })
        yield put(
          updateImproveActions.updateSuccessImproveStatus(
            improve.status.status,
          ),
        )

        if (improve.auditUser?.uuid) {
          userUUIDs.push(improve.auditUser.uuid)
        }
        if (userUUIDs.length > 0) {
          yield put(
            improveEmailLogsAction.addImproveEmailLog(
              {
                addImproveEmail: {
                  improveEmailLog: {
                    improveUuid: improve.uuid,
                    improveStatus: ImproveStatusStatusEnum.PLACE_USER_WORKING,
                    userUuid: loginUserUUID,
                    userName: loginUserName,
                    userGroupId: improve.auditUserGroup?.id,
                    userGroupName: improve.auditUserGroup?.name,
                    content: action.message,
                    sendAt: new Date(),
                  },
                  content: createImproveReviseSendEmail(
                    action.improveUUID,
                    userUUIDs,
                    action.message,
                  ),
                },
              },
              'メッセージを送信しました',
            ),
          )
        }

        yield put(
          notificationServiceActions.showNotification({
            message: submitMessages[improve.status.status].message,
          }),
        )
        break
      default:
        break
    }
  } catch (error) {
    // TODO remove `as`
    yield put(interceptionsActions.handleHttpError(error as HTTPError))
    // TODO remove `as`
    yield put(updateImproveActions.updateErrorImprove(error as Error))
  }
}

const submitMessages = {
  [ImproveStatusStatusEnum.COMPLETE]: {
    message: 'カイゼンを完了しました',
  },
  [ImproveStatusStatusEnum.AUDITOR_WORKING]: {
    message: '提出しました',
  },
  [ImproveStatusStatusEnum.PLACE_USER_WORKING]: {
    message: 'カイゼンを提出しました',
  },
}

const temporarySavedMessage = 'カイゼンを一時保存しました'

export { updateImprove }
