import { TemplateNodeSchema } from '@ulysses-inc/harami_api_client'
import { EditDeviateCommentAction } from 'src/state/ducks/templates/editDeviateComment/actions'
import { ActionTypes } from 'src/state/ducks/templates/editDeviateComment/types'

interface EditDeviateCommentState {
  questionNode?: TemplateNodeSchema
  isOpenModal: boolean
}

const initialEditDeviateCommentState: EditDeviateCommentState = {
  isOpenModal: false,
}

export const editDeviateCommentReducer = (
  state: EditDeviateCommentState = initialEditDeviateCommentState,
  action: EditDeviateCommentAction,
) => {
  switch (action.type) {
    case ActionTypes.SHOW_EDIT_DEVIATE_COMMENT: {
      return { ...state, isOpenModal: true, questionNode: action.node }
    }
    case ActionTypes.CLOSE_EDIT_DEVIATE_COMMENT: {
      return { ...state, isOpenModal: false }
    }
    default: {
      return { ...state }
    }
  }
}
