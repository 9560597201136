// prettier-ignore
export const ActionTypes = {
  REQUEST_GET_EXCEL_CONVERSION_FLOWS: 'harami/excelConversionFlows/REQUEST_GET_EXCEL_CONVERSION_FLOWS',
  SUCCESS_GET_EXCEL_CONVERSION_FLOWS: 'harami/excelConversionFlows/SUCCESS_GET_EXCEL_CONVERSION_FLOWS',
  ERROR_GET_EXCEL_CONVERSION_FLOWS: 'harami/excelConversionFlows/ERROR_GET_EXCEL_CONVERSION_FLOWS',
  REQUEST_CHANGE_EXCEL_CONVERSION_FLOWS_PAGE: 'harami/excelConversionFlows/REQUEST_CHANGE_EXCEL_CONVERSION_FLOWS_PAGE',
  REQUEST_CHANGE_EXCEL_CONVERSION_FLOWS_SIZE: 'harami/excelConversionFlows/REQUEST_CHANGE_EXCEL_CONVERSION_FLOWS_SIZE',

  REQUEST_GET_EXCEL_CONVERSION_FLOW: 'harami/excelConversionFlows/REQUEST_GET_EXCEL_CONVERSION_FLOW',
  SUCCESS_GET_EXCEL_CONVERSION_FLOW: 'harami/excelConversionFlows/SUCCESS_GET_EXCEL_CONVERSION_FLOW',
  ERROR_GET_EXCEL_CONVERSION_FLOW: 'harami/excelConversionFlows/ERROR_GET_EXCEL_CONVERSION_FLOW',

  REQUEST_DELETE_EXCEL_CONVERSION_FLOW: 'harami/excelConversionFlows/REQUEST_DELETE_EXCEL_CONVERSION_FLOW',
  SUCCESS_DELETE_EXCEL_CONVERSION_FLOW: 'harami/excelConversionFlows/SUCCESS_DELETE_EXCEL_CONVERSION_FLOW',
  ERROR_DELETE_EXCEL_CONVERSION_FLOW: 'harami/excelConversionFlows/ERROR_DELETE_EXCEL_CONVERSION_FLOW',

  REQUEST_ADD_EXCEL_CONVERSION_FLOW: 'harami/excelConversionFlows/REQUEST_ADD_EXCEL_CONVERSION_FLOW',
  SUCCESS_ADD_EXCEL_CONVERSION_FLOW: 'harami/excelConversionFlows/SUCCESS_ADD_EXCEL_CONVERSION_FLOW',
  ERROR_ADD_EXCEL_CONVERSION_FLOW: 'harami/excelConversionFlows/ERROR_ADD_EXCEL_CONVERSION_FLOW',

  REQUEST_UPDATE_EXCEL_CONVERSION_FLOW: 'harami/excelConversionFlows/REQUEST_UPDATE_EXCEL_CONVERSION_FLOW',
  SUCCESS_UPDATE_EXCEL_CONVERSION_FLOW: 'harami/excelConversionFlows/SUCCESS_UPDATE_EXCEL_CONVERSION_FLOW',
  ERROR_UPDATE_EXCEL_CONVERSION_FLOW: 'harami/excelConversionFlows/ERROR_UPDATE_EXCEL_CONVERSION_FLOW',

  REQUEST_UPLOAD_EXCEL_ATTACHMENT_FILE: 'harami/excelConversionFlows/REQUEST_UPLOAD_EXCEL_ATTACHMENT_FILE',
  ADD_EXCEL_ATTACHMENT_FILE: 'harami/excelConversionFlows/ADD_EXCEL_ATTACHMENT_FILE',

  RESET_EXCEL_CONVERSION_FLOW: 'harami/excelConversionFlows/RESET_EXCEL_CONVERSION_FLOW',

  REQUEST_GET_SCHEDULES: 'harami/excelConversionFlows/RESET_GET_SCHEDULES',
  SUCCESS_GET_SCHEDULES: 'harami/excelConversionFlows/SUCCESS_GET_SCHEDULES',
  ERROR_GET_SCHEDULES: 'harami/excelConversionFlows/ERROR_GET_SCHEDULES',

  REQUEST_TEST_EXCEL_CONVERSION_FLOW: 'harami/excelConversionFlows/REQUEST_TEST_EXCEL_CONVERSION_FLOW',
  ERROR_TEST_EXCEL_CONVERSION_FLOW: 'harami/excelConversionFlows/ERROR_TEST_EXCEL_CONVERSION_FLOW',
} as const
