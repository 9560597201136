import { MultipleChoice } from '@ulysses-inc/harami_api_client'
import { FC } from 'react'
import { TMultipleChoicesState } from './AddMultipleChoiceDrawer'
import { DeleteButton, TrashBinIcon } from './AddMultipleChoiceDrawer.styled'

const ActionColumn: FC<{
  multipleChoicesState: TMultipleChoicesState
  multipleChoice: MultipleChoice
}> = ({
  multipleChoicesState: [multipleChoices, setMultipleChoices],
  multipleChoice,
}) => {
  const onClick = () => {
    const filtered = Object.keys(multipleChoices)
      .map(Number)
      .filter(key => key !== multipleChoice.id)
      .reduce((acc, cur) => ({ ...acc, [`${cur}`]: multipleChoices[cur] }), {})

    setMultipleChoices(filtered)
  }

  return <DeleteButton type="link" icon={<TrashBinIcon />} onClick={onClick} />
}

export default ActionColumn
