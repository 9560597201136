import { useState } from 'react'

/**
 * モーダルやサイドメニューなど、開閉可能なコンポーネントの状態を簡潔に管理するためのカスタムフック
 */
export const useTogglable = () => {
  const [isOpen, setIsOpen] = useState(false)

  const toggle = () => setIsOpen(current => !current)
  const open = () => setIsOpen(true)
  const close = () => setIsOpen(false)

  return { isOpen, toggle, open, close }
}
