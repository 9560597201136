import {
  DATE_CELL_WIDTH,
  RequiredMark,
  StickyTH,
  TH,
} from './EditGridVariables.styled'

export const HeaderRow = () => (
  <>
    <StickyTH>#</StickyTH>
    <TH theme={{ width: DATE_CELL_WIDTH }}>
      適用開始日
      <RequiredMark>＊</RequiredMark>
    </TH>
    <TH theme={{ width: DATE_CELL_WIDTH }}>
      適用終了日
      <RequiredMark>＊</RequiredMark>
    </TH>
    <TH>
      セクション名
      <RequiredMark>＊</RequiredMark>
    </TH>
    <TH>取り込みセクション</TH>
    <TH>取り込み項目1</TH>
    <TH>取り込み項目2</TH>
    <TH>取り込み項目3</TH>
    <TH>取り込み項目4</TH>
    <TH>取り込み項目5</TH>
    <TH>取り込み項目6</TH>
  </>
)
