import { StyleSheet, Text, View } from '@react-pdf/renderer'
import React from 'react'
import { getFormattedRecordedAt } from 'src/exShared/util/date'

interface OwnProps {
  isTimeDisplayed: boolean
  recordedAt?: Date
}

const styles = StyleSheet.create({
  recordedAtTimeText: { fontSize: 8, color: '#656565', paddingLeft: 5 },
})

const RecordedTime: React.FC<OwnProps> = (props: OwnProps) => {
  return (
    <View>
      {props.isTimeDisplayed && props.recordedAt !== undefined && (
        <Text style={styles.recordedAtTimeText}>
          記録時間: {getFormattedRecordedAt(props.recordedAt)}
        </Text>
      )}
    </View>
  )
}

export default RecordedTime
