import { TemplatePageSchema } from '@ulysses-inc/harami_api_client'
import { Typography } from 'antd'
import { FC } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { updateTemplatePage as updateTemplatePageAction } from 'src/state/ducks/templates/actions'
import { useTemplatePageNodes } from 'src/state/ducks/templates/selectors'
import { RootState } from 'src/state/store'
import styled from 'styled-components'
import GridSection from '../section/GridSection'
import QuestionHeader from './QuestionHeader'
import './animation.scss'

const TemplatePageContainer = styled.div`
  overflow-x: auto;
`

const TemplatePageWrap = styled.div`
  width: 100&;
  padding-right: 20px;
`

const { Title } = Typography

interface Props {
  templatePageId: number
}

const AddGridTemplatePageContent: FC<Props> = ({ templatePageId }) => {
  const dispatch = useDispatch()

  const { templatePages } = useSelector(
    (state: RootState) => state.templatesState.templatePages,
    shallowEqual,
  )
  const templatePage = templatePages[templatePageId] ?? { name: '' }

  const updateTemplatePage = (
    templatePageId: number,
    changeTemplatePage: TemplatePageSchema,
  ) => {
    dispatch(updateTemplatePageAction(templatePageId, changeTemplatePage))
  }

  const templateNodes = useTemplatePageNodes(templatePage)

  // 「表形式」のひな形では、セクションが必ず１つのみ存在する仕様のため
  // 新規追加時でも、セクションが存在する状態で遷移する。
  // 新規登録時は、別途useEffectで追加されるため、templateNodes.length === 0の場合にも対処し、
  // 追加後に再描画させる必要がある
  const node = templateNodes[0]

  return (
    <>
      <Title
        level={4}
        editable={{
          onChange: (text: string) => {
            updateTemplatePage(templatePage.id ?? 0, {
              ...templatePage,
              name: text,
            })
          },
        }}
      >
        {templatePage.name}
      </Title>
      <TemplatePageContainer>
        <TemplatePageWrap>
          <QuestionHeader
            templateHints={[]}
            isShowScore={false}
            isExcelConversion={false}
          />
          {node && <GridSection node={node} />}
        </TemplatePageWrap>
      </TemplatePageContainer>
    </>
  )
}

export default AddGridTemplatePageContent
