import { takeEvery } from 'redux-saga/effects'
import {
  addReportAttachmentFile,
  addReportComment,
  deleteReportAttachmentFile,
  getReportResult,
  updateReportStatus,
  uploadReportAttachmentFile,
} from './reportResult/sagas'
import { deleteReport, getReports, updateReportPlaces } from './reports/sagas'
import { getReportsTemplates } from './reportsTemplates/sagas'
import { ActionTypes } from './types'

const sagas = [
  // reports
  takeEvery(ActionTypes.REQUEST_GET_REPORTS, getReports),
  takeEvery(ActionTypes.REQUEST_CHANGE_REPORTS_PAGE, getReports),
  takeEvery(ActionTypes.REQUEST_CHANGE_REPORTS_SIZE, getReports),
  takeEvery(ActionTypes.REQUEST_DELETE_REPORT, deleteReport),
  takeEvery(ActionTypes.REQUEST_UPDATE_REPORT_PLACES, updateReportPlaces),

  // reportsTemplates
  takeEvery(ActionTypes.REQUEST_GET_REPORTS_TEMPLATES, getReportsTemplates),

  // reportResult
  takeEvery(ActionTypes.REQUEST_GET_REPORT_RESULT, getReportResult),
  takeEvery(ActionTypes.REQUEST_ADD_REPORT_COMMENT, addReportComment),
  takeEvery(
    ActionTypes.REQUEST_UPLOAD_REPORT_ATTACHMENT_FILE,
    uploadReportAttachmentFile,
  ),
  takeEvery(
    ActionTypes.REQUEST_ADD_REPORT_ATTACHMENT_FILE,
    addReportAttachmentFile,
  ),
  takeEvery(
    ActionTypes.REQUEST_DELETE_REPORT_ATTACHMENT_FILE,
    deleteReportAttachmentFile,
  ),
  takeEvery(ActionTypes.REQUEST_UPDATE_REPORT_STATUS, updateReportStatus),
]

export default sagas
