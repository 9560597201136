import { DeleteOutlined } from '@ant-design/icons'
import { css } from '@emotion/react'
import { MultipleChoiceGroup } from '@ulysses-inc/harami_api_client'
import { Button, Modal } from 'antd'
import { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Spinner } from 'src/features/reportExport/list/components/Spinner'
import { useSourceTemplateIdParam } from 'src/features/templateEdit/useSourceTemplateIdParam'
import {
  addMultipleChoices,
  deleteMultipleChoices,
  updateMultipleChoices,
} from 'src/state/ducks/templates/responseMultipleChoices/actions'
import { RootState } from 'src/state/store'
import { isDefined } from 'src/util/idDefined'
import DeleteConfirmDialog from 'src/views/components/dialogs/DeleteConfirmDialog'
import { TMultipleChoicesState } from './AddMultipleChoiceDrawer'
import { useUsageState } from './AddMultipleChoiceDrawer.hooks'
import {
  ButtonGroup,
  ButtonGroupRow,
  ConfirmModalContentTemplates,
  ConfirmModalContentTemplatesList,
  ConfirmModalContentWrapper,
  FooterButton,
} from './AddMultipleChoiceDrawer.styled'
import AlertModalContent from './AlertModalContent'

const ButtonsRow: FC<{
  onClose: () => void
  isVisible: boolean
  multipleChoicesState: TMultipleChoicesState
  multipleChoiceGroupForUpdate: MultipleChoiceGroup | undefined
}> = ({
  onClose,
  multipleChoicesState: [multipleChoices, setMultipleChoices],
  multipleChoiceGroupForUpdate,
  isVisible,
}) => {
  const isInUse = useUsageState(multipleChoiceGroupForUpdate)
  const dispatch = useDispatch()
  const templateId = useSourceTemplateIdParam()
  const templateName = useSelector(
    (state: RootState) => state.templatesState.editTemplate?.name || '',
  )
  const relatedTemplates = useSelector(
    (state: RootState) =>
      state.templatesState.responseMultipleChoices.relatedTemplates,
  )
  const isNew = multipleChoiceGroupForUpdate === undefined
  const isFetchingRelatedTemplates = !isNew && relatedTemplates === null

  const handleCloseDrawer = async () => {
    // modalとdrawerが同時に閉じるとスクロールできなくなるので、遅延させる
    await new Promise(resolve => setTimeout(resolve, 100))
    onClose()
    setMultipleChoices({})
  }

  const confirmModal = () =>
    Modal.confirm({
      onOk: (close: () => void) => {
        const filteredMultipleChoices = Object.keys(multipleChoices)
          .map(Number)
          .map(key => multipleChoices[key])
          .filter(multipleChoice => multipleChoice?.response !== '')
          .filter(isDefined)

        if (filteredMultipleChoices.length > 0) {
          if (multipleChoiceGroupForUpdate) {
            dispatch(
              updateMultipleChoices(
                multipleChoiceGroupForUpdate.id ?? 0,
                filteredMultipleChoices,
              ),
            )
          } else {
            dispatch(addMultipleChoices(filteredMultipleChoices))
          }
        }

        close()
        handleCloseDrawer()
      },
      title: '選択肢を保存してよろしいですか？',
      content:
        !isVisible || !relatedTemplates?.length ? null : (
          <ConfirmModalContentWrapper>
            この選択肢の変更は、以下のひな形にも影響する可能性があります。
            <ConfirmModalContentTemplates>
              <ConfirmModalContentTemplatesList>
                {relatedTemplates.map(template => (
                  <li key={template.id}>{template.name}</li>
                ))}
              </ConfirmModalContentTemplatesList>
            </ConfirmModalContentTemplates>
          </ConfirmModalContentWrapper>
        ),
      okText: '保存する',
      cancelText: 'キャンセル',
    })

  const confirmDeleteModal = () => {
    const deletingMultipleChoiceGroupId = multipleChoiceGroupForUpdate?.id
    if (!deletingMultipleChoiceGroupId) return
    if (isVisible && (relatedTemplates?.length || isInUse)) {
      Modal.error({
        title: '以下のひな形で使用されているため削除できません。',
        content: (
          <AlertModalContent
            relatedTemplates={relatedTemplates}
            templateId={templateId}
            templateName={templateName}
            isInUse={isInUse}
          />
        ),
      })
      return
    }
    DeleteConfirmDialog({
      title: '選択肢を削除してよろしいですか？',
      onOk: () => {
        dispatch(deleteMultipleChoices(deletingMultipleChoiceGroupId))
        handleCloseDrawer()
      },
    })
  }

  return (
    <>
      <ButtonGroupRow>
        <ButtonGroup>
          {!isNew && (
            <Button
              danger
              disabled={isFetchingRelatedTemplates}
              type="link"
              icon={<DeleteOutlined />}
              onClick={confirmDeleteModal}
            >
              選択肢を削除
            </Button>
          )}
        </ButtonGroup>
        <ButtonGroup>
          <FooterButton
            type="link"
            onClick={() => {
              onClose()
              setMultipleChoices({})
            }}
          >
            キャンセル
          </FooterButton>
          <FooterButton
            disabled={isFetchingRelatedTemplates}
            type="primary"
            onClick={confirmModal}
          >
            保存
          </FooterButton>
        </ButtonGroup>
      </ButtonGroupRow>

      {isFetchingRelatedTemplates && (
        <div css={styles.spinner}>
          <Spinner />
        </div>
      )}
    </>
  )
}

const styles = {
  spinner: css`
    margin: 16px;
  `,
}

export default ButtonsRow
