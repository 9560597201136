import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { FC, ReactNode } from 'react'

// TanStack Queryのデフォルト動作は便利だが、注意しないとデバッグが難しくなる可能性があるため、
// デフォルトでは安全側に立って余計なことをしない設定としている。
// これらの動作の必要性は使用する場面や実装者の経験によって異なるため、
// 各 Query or Mutation において設定を上書きして使用することを想定している。
// https://tanstack.com/query/latest/docs/react/guides/important-defaults
const safeQueryOptions = {
  networkMode: 'always',
  refetchInterval: false,
  refetchOnReconnect: false,
  refetchOnWindowFocus: false,
  retry: false,
} as const

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      ...safeQueryOptions,
      // その他のデフォルト設定はここに追記してください
    },
  },
})

type SetupTanStackQueryProps = {
  children: ReactNode
}

export const SetupTanStackQuery: FC<SetupTanStackQueryProps> = ({
  children,
}) => {
  return (
    <>
      <QueryClientProvider client={queryClient}>
        {children}
        {!!import.meta.env['VITE_APP_TAN_STACK_DEVTOOLS'] && (
          <ReactQueryDevtools />
        )}
      </QueryClientProvider>
    </>
  )
}
