import { Input } from 'antd'
import { FormikHandlers } from 'formik'
import React, { useRef } from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  margin-right: 3px;
`

const Textarea = styled(Input.TextArea)`
  &.ant-input {
    height: 100%;
    padding: 11px 8px 11px 8px;
    &:not(.focus-visible),
    &:not([data-focusvisible-polyfill='true']) {
      border-color: transparent;
      resize: none;
    }
    &.focus-visible,
    &[data-focusvisible-polyfill='true'] {
      resize: auto;
      box-shadow: unset;
    }
    &[disabled] {
      color: #000000;
      background-color: transparent;
    }
  }
`

type Props = {
  name: string
  value?: string
  placeholder: string
  onChange: FormikHandlers['handleChange']
  rows: number
  errorMessage?: React.ReactElement
  disabled: boolean
  active: boolean
}

const TextAreaComponent: React.FC<Props> = ({
  name,
  value,
  placeholder,
  onChange,
  rows = 2,
  errorMessage,
  disabled,
  active,
  ...other
}) => {
  const textareaRef = useRef<any>(null)
  const textareaProps = {
    name,
    value,
    placeholder,
    onChange,
    rows,
    disabled,
  }

  return (
    <Wrapper {...other}>
      <Textarea
        autoSize={{ minRows: 3 }}
        {...textareaProps}
        ref={textareaRef}
        data-active={active}
      />
      {errorMessage}
    </Wrapper>
  )
}

export default TextAreaComponent
