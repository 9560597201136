import { Checkbox } from 'antd'
import { FC } from 'react'
import { TableRow } from './ScheduleList.styled'
import { TChooseScheduleUUIDsState } from './ScheduleListContent'
import { TBaseColumnProps } from './ScheduleListRow'

const CheckboxRow: FC<TBaseColumnProps<TChooseScheduleUUIDsState>> = ({
  schedule: { uuid },
  chooseScheduleUUIDsState,
}) => {
  const [chooseScheduleUUIDs, setChooseScheduleUUIDs] = chooseScheduleUUIDsState

  const handleCheckSchedule = (scheduleUUID: string, checked: boolean) => {
    if (checked) {
      setChooseScheduleUUIDs([...chooseScheduleUUIDs, scheduleUUID])
    } else {
      setChooseScheduleUUIDs(
        chooseScheduleUUIDs.filter((uuid: string) => uuid !== scheduleUUID),
      )
    }
  }

  return (
    <TableRow theme={{ width: '3%' }}>
      <Checkbox
        checked={uuid ? chooseScheduleUUIDs.some(v => v === uuid) : false}
        onChange={e => uuid && handleCheckSchedule(uuid, e.target.checked)}
      />
    </TableRow>
  )
}

export default CheckboxRow
