import { HomeOutlined } from '@ant-design/icons'
import { css } from '@emotion/react'
import { Button } from 'antd'

type Props = {
  onAssignPlaceButtonClicked: () => void
  onClearButtonClicked: () => void
  selectedReportCount: number
}

/**
 * 現場付け替えボタンとその背景にあるオーバーレイ
 */
export const OverlaySubHeader = ({
  onAssignPlaceButtonClicked,
  onClearButtonClicked,
  selectedReportCount,
}: Props) => {
  const label = '現場の付け替え'

  return (
    <div css={styles.container}>
      <div css={styles.leftPart}>
        <span>{selectedReportCount}件選択中</span>
        <Button css={styles.clearButton} onClick={onClearButtonClicked}>
          選択を解除
        </Button>
      </div>

      <Button
        aria-label={label}
        onClick={onAssignPlaceButtonClicked}
        type="primary"
      >
        <HomeOutlined />
        {label}
      </Button>
    </div>
  )
}

const styles = {
  container: css`
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: space-between;
    padding: 0 24px;
  `,
  leftPart: css`
    display: flex;
    align-items: center;
  `,
  clearButton: css`
    margin-left: 16px;
  `,
}
