import { css } from '@emotion/react'
import {
  Image as ApiImage,
  IndicatedIssue,
  ReportImage,
  ReportNodeSchema,
  ReportQuestion,
  ResponseDatetime,
  ResponseTypeEnum,
  TemplateNodeTypeEnum,
} from '@ulysses-inc/harami_api_client'
import React from 'react'
import { Badge } from 'src/components/nativeCompat/Badge'
import { Text } from 'src/components/nativeCompat/Text'
import { View } from 'src/components/nativeCompat/View'
import { getFormattedRecordedAt } from 'src/exShared/util/date'
import { convertDecimal } from 'src/exShared/util/numberDecimalPoint/numberDecimalPoint'
import { isInvalid } from 'src/exShared/util/report/isInvalid'
import { WebReportResultProps } from 'src/features/reports/result/WebReportResultProps'
import { PhotoSlider } from 'src/features/reports/result/components/reportResult/PhotoSlider/PhotoSlider'
import {
  Black,
  BorderColor,
  Default,
  Gray,
  Primary,
  White,
} from 'src/features/theme/KdsThemeColor'
import IndicatedIssueLabels from './Labels'
import Logic from './Logic'
import { Memo } from './Memo'
import { PdfThumbnail } from './PdfThumbnail'
import { Photo } from './Photo'
import { URLText } from './Question.dumb'
import { QuestionDeviatedRibbon } from './QuestionDeviatedRibbon'
import { formatDatetimeValue } from './datetimeFormatter.util'

const emotionStyles = {
  card: css`
    padding: 16px;
    background-color: ${White};
    border-radius: 8px;
    border-width: 0.5px;
    border-color: #d6d7da;
    margin-bottom: 16px;
  `,
  deviateCard: css`
    padding: 16px;
    background-color: #fff1f0;
    border-radius: 8px;
    border-width: 0.5px;
    border-color: #d6d7da;
    margin-bottom: 16px;
  `,
  selectedChoicesView: css`
    flex-direction: row;
    flex-wrap: wrap;
  `,
  selectedChoiceView: css`
    flex-direction: row;
    justify-content: center;
    max-width: 95%;
    min-width: 120px;
    margin: 12px 0 12px 24px;
    padding: 8px 24px;
    border-color: ${Primary};
    border-width: 1px;
    border-radius: 8px;
  `,
  signatureImageView: css`
    flex: 1;
    margin-top: 6px;
    margin-bottom: 6px;
    border-width: 1px;
    width: 384px;
    border-color: ${BorderColor};
  `,
  informationView: css`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
    height: 300px;
    border-width: 1px;
    border-color: ${BorderColor};
  `,
  signatureImage: css`
    width: 100%;
  `,
  indicatedIssue: {
    deviatedCard: css`
      padding: 16px;
      background-color: ${White};
      border-radius: 8px;
      border-width: 0.5px;
      border-color: #f5f5f5;
      margin-bottom: 3px;
      margin-top: 5px;
      flex-direction: row;
      height: 64px;
      width: 100%;
      display: flex;
    `,
    card: css`
      padding: 16px;
      background-color: #f5f5f5;
      border-radius: 8px;
      border-width: 0.5px;
      border-color: #f5f5f5;
      margin-bottom: 3px;
      margin-top: 5px;
      flex-direction: row;
      height: 64px;
      width: 100%;
      display: flex;
    `,
    imageView: css`
      width: 64px;
      height: 48px;
      background-color: #f0f0f0;
      border-color: #f5f5f5;
      border-width: 1px;
      margin-top: -8px;
      margin-left: -5px;
    `,
    noImageView: css`
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      justify-content: center;
      align-items: center;
    `,
    noImageText: css`
      text-align: center;
      justify-content: center;
      align-items: center;
      color: #bfbfbf;
      font-size: 10px;
      font-weight: 400;
    `,
    indicatedIssueView: css`
      flex-direction: row;
      flex-wrap: wrap;
      display: flex;
      width: 92%;
    `,
    text: css`
      font-size: 12px;
      font-weight: 400;
      color: ${Black};
      margin: -8px 8px 0 8px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    `,
    title: css`
      font-size: 14px;
      font-weight: 700;
      color: #595959;
      margin-top: 16px;
      margin-left: 8px;
    `,
  },
}

export interface QuestionProps {
  node?: ReportNodeSchema
  question?: ReportQuestion
  isDeviatedRender?: boolean // 逸脱項目側のレンダリングを示すフラグ
}

export const Question: React.FC<QuestionProps & WebReportResultProps> = (
  props: QuestionProps & WebReportResultProps,
) => {
  const isTimeDisplayed = !!props.question?.isTimeDisplayed

  const timeDisplayRender = () => {
    return (
      isTimeDisplayed && (
        <Text small color={Gray}>
          {getFormattedRecordedAt(props.question?.responseAnswer?.recordedAt)}
        </Text>
      )
    )
  }

  const deviatedAnswers = Object.values(props.report.nodes).filter(
    (node: ReportNodeSchema) => {
      if (node.type !== TemplateNodeTypeEnum.Question) {
        return false
      }

      return isInvalid(node.question)
    },
  )

  const isDeviated =
    props.question?.id &&
    deviatedAnswers.some(
      (node: ReportNodeSchema) => node.question?.id === props.question?.id,
    )

  const renderAnswer = () => {
    const scale = props.question?.scale || ''
    // 数値タイプの回答で「該当なし」が選択されている場合
    if (props.question?.responseAnswer?.noAnswer) {
      return (
        <View>
          <Text style={{ fontWeight: 'bold', marginTop: 8 }}>該当なし</Text>
          {props.question?.responseAnswer?.noAnswer?.reason?.length > 0 && (
            <>
              <Text small style={{ color: Gray, marginTop: 8 }}>
                該当なしの理由
              </Text>
              <Text style={{ marginTop: 4 }}>
                {props.question.responseAnswer.noAnswer.reason}
              </Text>
            </>
          )}
        </View>
      )
    }
    switch (props.question?.responseType) {
      case ResponseTypeEnum.MULTIPLE_CHOICE: {
        if (!props.question?.responseAnswer?.multipleChoice) {
          return <Text style={{ color: Gray, marginTop: 8 }}>未回答</Text>
        }
        const backgroundColor =
          props.question?.responseAnswer?.multipleChoice?.color || Default
        return (
          <>
            <Badge
              css={css`
                background-color: ${backgroundColor};
              `}
            >
              <Text style={{ color: White }}>
                {props.question?.responseAnswer?.multipleChoice?.response}
              </Text>
            </Badge>
            {timeDisplayRender()}
          </>
        )
      }
      case ResponseTypeEnum.RESPONSE_SET:
        if ((props.question?.responseAnswer?.responseSets ?? []).length < 1) {
          return <Text style={{ color: Gray, marginTop: 8 }}>未回答</Text>
        }
        return (
          <>
            <View css={emotionStyles.selectedChoicesView}>
              {(props.question.responseAnswer?.responseSets ?? []).map(
                choice => {
                  return (
                    <View
                      key={choice.id}
                      css={emotionStyles.selectedChoiceView}
                    >
                      <Text>{choice.name}</Text>
                    </View>
                  )
                },
              )}
            </View>
            {timeDisplayRender()}
          </>
        )
      case ResponseTypeEnum.NUMBER: {
        if (!props.question?.responseAnswer?.numberValue) {
          return <Text style={{ color: Gray, marginTop: 8 }}>未回答</Text>
        }
        const numberValue = convertDecimal(
          props.question?.responseAnswer?.numberValue?.numberValue ?? '',
          (props.question?.responseNumbers ?? [])[0]?.decimalPoint,
        )

        return (
          <>
            <Text
              style={{ marginTop: 8, display: 'flex', alignItems: 'flex-end' }}
            >
              {numberValue}
              <Text small style={{ color: Gray }}>
                {scale}
              </Text>
            </Text>
            {timeDisplayRender()}
          </>
        )
      }
      case ResponseTypeEnum.SIGNATURE: {
        if (
          !props.question?.responseAnswer?.signatures ||
          props.question?.responseAnswer?.signatures.length === 0
        ) {
          return <Text style={{ color: Gray, marginTop: 8 }}>未回答</Text>
        }
        const signature = props.question!.responseAnswer!.signatures[0]!

        return (
          <>
            <View css={emotionStyles.signatureImageView}>
              <img src={signature?.url} css={emotionStyles.signatureImage} />
            </View>
            {timeDisplayRender()}
          </>
        )
      }
      case ResponseTypeEnum.INFORMATION: {
        if (
          !props.question?.responseInformations ||
          props.question?.responseInformations.length === 0
        ) {
          return <></>
        }
        let questionInformation = props.question!.responseInformations[0]!
        if (props.isOffline) {
          questionInformation = {
            url: props.templateImages[questionInformation.uuid ?? ''],
          }
        }
        const answerInformation = (props.question!.responseAnswer
          ?.informations ?? [undefined])[0]
        const informations =
          answerInformation === undefined
            ? [questionInformation?.url ?? '']
            : [answerInformation?.url ?? '']

        return (
          <>
            <View css={emotionStyles.informationView}>
              <div style={{ cursor: 'pointer', width: '100%', height: '100%' }}>
                {answerInformation?.url === undefined &&
                  (questionInformation?.url!.split('.').pop() === 'pdf' ? (
                    <PdfThumbnail
                      fileName={questionInformation.name ?? ''}
                      url={questionInformation.url}
                    />
                  ) : (
                    <img
                      onClick={() => props.goPreviewPhoto(informations, 0)}
                      src={questionInformation?.url}
                      style={{
                        width: '50%',
                        height: '100%',
                        objectFit: 'contain',
                        position: 'absolute',
                        left: 1,
                      }}
                    />
                  ))}
                {answerInformation?.url !== undefined &&
                  (answerInformation?.url!.split('.').pop() === 'pdf' ? (
                    <PdfThumbnail
                      fileName={answerInformation.name ?? ''}
                      url={answerInformation.url}
                    />
                  ) : (
                    <img
                      onClick={() => props.goPreviewPhoto(informations, 0)}
                      src={answerInformation?.url}
                      style={{
                        width: '50%',
                        height: '100%',
                        objectFit: 'contain',
                        position: 'absolute',
                        left: 1,
                      }}
                    />
                  ))}
              </div>
            </View>
            {timeDisplayRender()}
          </>
        )
      }
      case ResponseTypeEnum.TEXT: {
        if (
          !props.question?.responseAnswer?.textValue ||
          !props.question?.responseAnswer?.textValue?.textValue
        ) {
          return <Text style={{ color: Gray, marginTop: 8 }}>未回答</Text>
        }
        const textValue = props.question?.responseAnswer?.textValue?.textValue

        return (
          <>
            <Text style={{ marginTop: 8 }}>{textValue}</Text>
            {timeDisplayRender()}
          </>
        )
      }
      case ResponseTypeEnum.RESULT_IMAGE: {
        const photos: ApiImage[] =
          props.question?.responseAnswer?.resultImages ?? []
        const notNullableUrls: string[] = []
        // NOTE: https://kaminashi.atlassian.net/browse/HPB-586
        // 同じuuidのphotoが登録されているケースがあり、フロントで重複を排除している
        const uniqPhotos = photos.reduce((arr: ApiImage[], photo: ApiImage) => {
          if (!arr.find(p => p.uuid === photo.uuid)) {
            arr.push(photo)
          }
          return arr
        }, [])
        uniqPhotos.forEach(
          photo => photo.url !== undefined && notNullableUrls.push(photo.url),
        )

        if (photos.length === 0) {
          return <Text style={{ color: Gray, marginTop: 8 }}>未回答</Text>
        }
        return (
          <PhotoSlider
            photos={uniqPhotos}
            onImageTouched={(_, selectedImageIndex: number) => {
              props.goPreviewPhoto(notNullableUrls, selectedImageIndex)
            }}
            isTimeDisplayed={isTimeDisplayed}
          />
        )
      }
      case ResponseTypeEnum.DATETIME: {
        if (
          !props.question?.responseAnswer?.datetimeValue ||
          !props.question?.responseAnswer?.datetimeValue?.datetimeValue
        ) {
          return <Text style={{ color: Gray, marginTop: 8 }}>未回答</Text>
        }
        const responseDatetime: ResponseDatetime | undefined =
          props.question?.responseDatetimes &&
          props.question.responseDatetimes.length > 0
            ? props.question.responseDatetimes[0]
            : undefined
        const subType = responseDatetime?.subType

        const formattedDatetimeValue = formatDatetimeValue(
          subType!,
          props.question?.responseAnswer?.datetimeValue?.datetimeValue,
        )

        return (
          <>
            <Text style={{ marginTop: 8 }}>{formattedDatetimeValue}</Text>
            {timeDisplayRender()}
          </>
        )
      }
      case ResponseTypeEnum.EMPLOYEE:
        if ((props.question?.responseAnswer?.employees ?? []).length < 1) {
          return <Text style={{ color: Gray, marginTop: 8 }}>未回答</Text>
        }
        return (
          <>
            <View css={emotionStyles.selectedChoicesView}>
              {(props.question.responseAnswer?.employees ?? []).map(
                employee => {
                  return (
                    <View
                      key={employee.id}
                      css={emotionStyles.selectedChoiceView}
                    >
                      <Text>{employee.name}</Text>
                    </View>
                  )
                },
              )}
            </View>
            {timeDisplayRender()}
          </>
        )
      case ResponseTypeEnum.FORMULA: {
        if (!props.question?.responseAnswer?.formulaValue) {
          return <Text style={{ color: Gray, marginTop: 8 }}>未回答</Text>
        }
        const formulaValue =
          props.question?.responseAnswer?.formulaValue?.formulaValue

        return (
          <>
            <Text
              style={{ marginTop: 8, display: 'flex', alignItems: 'flex-end' }}
            >
              {formulaValue}
              <Text small style={{ color: Gray }}>
                {scale}
              </Text>
            </Text>
            {timeDisplayRender()}
          </>
        )
      }
      case ResponseTypeEnum.URL: {
        const url = props.question?.responseURLs?.[0]?.url ?? ''
        return (
          <>
            <URLText>{url}</URLText>
            {timeDisplayRender()}
          </>
        )
      }
      default:
        return <></>
    }
  }

  const renderLinkedIndicatedIssues = (nodeUUID?: string) => {
    const linkedIndicatedIssue = () => {
      const issues: IndicatedIssue[] = []
      props.report.linkedIndicatedIssues
        .filter(link => link.reportNodeUUID === nodeUUID)
        .map(value => {
          const group = Object.values(
            props.report?.improve?.indicatedIssueGroups ?? [],
          ).find(group => group.uuid === value?.indicatedIssueGroupUUID)
          if (group?.indicatedIssues?.[0] !== undefined) {
            issues.push(group?.indicatedIssues?.[0])
          }
        })
      return issues
    }

    const issues = linkedIndicatedIssue()

    const renderIssue = (indicatedIssue: IndicatedIssue) => (
      <View
        id={`IndicatedIssue-${indicatedIssue?.id || 0}`}
        css={
          isDeviated
            ? emotionStyles.indicatedIssue.deviatedCard
            : emotionStyles.indicatedIssue.card
        }
      >
        <View css={emotionStyles.indicatedIssue.imageView}>
          {indicatedIssue?.images?.length ?? 0 > 0 ? (
            <img
              src={indicatedIssue?.images?.slice(-1)[0]?.url}
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
              }}
            ></img>
          ) : (
            <View css={emotionStyles.indicatedIssue.noImageView}>
              <Text css={emotionStyles.indicatedIssue.noImageText}>
                写真が{'\n'}ありません
              </Text>
            </View>
          )}
        </View>
        <View css={emotionStyles.indicatedIssue.indicatedIssueView}>
          <div css={emotionStyles.indicatedIssue.text}>
            {indicatedIssue?.comment}
          </div>
          <IndicatedIssueLabels labels={indicatedIssue.labels || []} />
        </View>
      </View>
    )

    if (issues.length > 0) {
      return (
        <View>
          <Text css={emotionStyles.indicatedIssue.title}>指摘</Text>
          {issues.map(value => renderIssue(value))}
        </View>
      )
    }

    return <></>
  }

  return (
    <>
      <View
        id={`Question-${props.node?.id || 0}`}
        css={isDeviated ? emotionStyles.deviateCard : emotionStyles.card}
      >
        {isDeviated && <QuestionDeviatedRibbon />}
        <div style={{ width: '80%' }}>{props.question?.name}</div>
        {/* ひな形作成時に選択肢タイプの質問に添付できる画像群 */}
        {(props.question?.informationImages ?? []).length > 0 && (
          <Photo
            listKey={`${props.question?.id}-Photo`}
            imageUrls={
              props.question?.informationImages?.map(
                (image: ReportImage) => image.url,
              ) ?? []
            }
            goPreviewPhoto={props.goPreviewPhoto}
          />
        )}
        {props.question?.responseAnswer ||
        props.question?.responseType === ResponseTypeEnum.INFORMATION ||
        props.question?.responseType === ResponseTypeEnum.URL ? (
          renderAnswer()
        ) : (
          <Text style={{ color: Gray, marginTop: 8 }}>未回答</Text>
        )}
        {props.question?.responseAnswer?.manualMemos &&
          props.question?.responseAnswer?.manualMemos.length > 0 && (
            <View>
              <Text small style={{ color: Gray }}>
                手書きメモ
              </Text>
              <Memo
                listKey={`${props.question.id}-Memo`}
                memoUris={props.question.responseAnswer.manualMemos.map(
                  (memo: ReportImage) => memo.url,
                )}
                goPreviewMemo={props.goPreviewMemo}
              />
            </View>
          )}
        {props.question?.responseAnswer?.textMemos &&
          props.question.responseAnswer.textMemos.length > 0 &&
          props.question.responseAnswer.textMemos?.[0]?.name !== '' && (
            <View>
              <Text small style={{ color: Gray }}>
                テキストメモ
              </Text>
              <Text small style={{ color: Gray }}>
                {props.question?.responseAnswer?.textMemos?.[0]?.name}
              </Text>
            </View>
          )}
        {props.question?.responseAnswer?.images &&
          props.question?.responseAnswer?.images.length > 0 && (
            <View>
              <Text small style={{ color: Gray }}>
                写真
              </Text>
              <PhotoSlider
                photos={props.question.responseAnswer.images}
                onImageTouched={(_, selectedImageIndex: number) => {
                  const notNullableUrls: string[] = []
                  props.question?.responseAnswer?.images?.forEach(
                    image =>
                      image.url !== undefined &&
                      notNullableUrls.push(image.url),
                  )
                  props.goPreviewPhoto(notNullableUrls, selectedImageIndex)
                }}
                isTimeDisplayed={false}
              />
            </View>
          )}
        {props.node?.id && renderLinkedIndicatedIssues(props.node?.uuid)}
      </View>
      {!props.isDeviatedRender &&
        Object.values(props.report.nodes).length > 0 &&
        props.node?.question?.responseAnswer !== undefined &&
        props.getReportNodesNodes(props.node).map(node => {
          return <Logic key={node.id} {...props} node={node} />
        })}
    </>
  )
}
