import {
  AddMultipleChoiceSetRequest,
  DeleteMultipleChoiceSetRequest,
  GetMultipleChoiceSetRequest,
  GetMultipleChoiceSetsRequest,
  MultipleChoiceSetsApi,
  UpdateMultipleChoiceSetRequest,
} from '@ulysses-inc/harami_api_client'
import { call, put, takeEvery } from 'redux-saga/effects'
import * as notificationServiceActions from 'src/features/notificationService/slice'
import { history } from 'src/state/store'
import BaseClient from '../../middleware/saga/baseClient'
import { handleHTTPError } from '../../middleware/saga/handleHttpError'
import interceptionsActions from '../interceptions/actions'
import { default as actions } from './actions'
import { ActionTypes } from './types'

const baseClient = new BaseClient()

const getMultipleChoiceSetsRequest = (req: GetMultipleChoiceSetsRequest) => {
  return baseClient
    .getApi(MultipleChoiceSetsApi)
    .getMultipleChoiceSets(req)
    .then(multipleChoiceSets => multipleChoiceSets)
    .catch(handleHTTPError)
}

const getMultipleChoiceSetRequest = (req: GetMultipleChoiceSetRequest) => {
  return baseClient
    .getApi(MultipleChoiceSetsApi)
    .getMultipleChoiceSet(req)
    .then(multipleChoiceSet => multipleChoiceSet)
    .catch(handleHTTPError)
}

const deleteMultipleChoiceSetRequest = (
  req: DeleteMultipleChoiceSetRequest,
) => {
  return baseClient
    .getApi(MultipleChoiceSetsApi)
    .deleteMultipleChoiceSet(req)
    .catch(handleHTTPError)
}

const addMultipleChoiceSetRequest = (req: AddMultipleChoiceSetRequest) => {
  return baseClient
    .getApi(MultipleChoiceSetsApi)
    .addMultipleChoiceSet(req)
    .then(multipleChoiceSets => multipleChoiceSets)
    .catch(handleHTTPError)
}

const updateMultipleChoiceSetRequest = (
  req: UpdateMultipleChoiceSetRequest,
) => {
  return baseClient
    .getApi(MultipleChoiceSetsApi)
    .updateMultipleChoiceSet(req)
    .then(multipleChoiceSets => multipleChoiceSets)
    .catch(handleHTTPError)
}

function* getMultipleChoiceSets(
  action: ReturnType<typeof actions.getMultipleChoiceSets>,
) {
  try {
    const response = yield call(getMultipleChoiceSetsRequest, action.request)
    yield put(actions.getSuccessMultipleChoiceSets(response))
  } catch (error) {
    yield put(interceptionsActions.handleHttpError(error))
    yield put(actions.getErrorMultipleChoiceSets(error))
  }
}

function* getMultipleChoiceSet(
  action: ReturnType<typeof actions.getMultipleChoiceSet>,
) {
  try {
    const multipleChoiceSet = yield call(getMultipleChoiceSetRequest, {
      multipleChoiceSetId: action.multipleChoiceSetId,
    })
    yield put(actions.getSuccessMultipleChoiceSet(multipleChoiceSet))
  } catch (error) {
    yield put(interceptionsActions.handleHttpError(error))
    yield put(actions.getErrorMultipleChoiceSet(error))
  }
}

function* deleteMultipleChoiceSet(
  action: ReturnType<typeof actions.deleteMultipleChoiceSet>,
) {
  try {
    yield call(deleteMultipleChoiceSetRequest, {
      multipleChoiceSetId: action.multipleChoiceSetId,
    })
    yield put(
      actions.deleteSuccessMultipleChoiceSet(action.multipleChoiceSetId),
    )
  } catch (error) {
    yield put(interceptionsActions.handleHttpError(error))
    yield put(actions.deleteErrorMultipleChoiceSet(error))
  }
}

function* addMultipleChoiceSet(
  action: ReturnType<typeof actions.addMultipleChoiceSet>,
) {
  try {
    const response = yield call(addMultipleChoiceSetRequest, {
      multipleChoiceSet: action.multipleChoiceSet,
    })
    yield put(actions.getSuccessMultipleChoiceSets(response))
    yield put(actions.addSuccessMultipleChoiceSet())
    yield put(actions.resetMultipleChoiceSet())
    yield put(
      notificationServiceActions.showNotification({
        message: 'セット選択肢の作成に成功しました',
      }),
    )
    history.push(`/multipleChoiceSets`)
  } catch (error) {
    yield put(interceptionsActions.handleHttpError(error))
    yield put(actions.addErrorMultipleChoiceSet(error))
  }
}

function* updateMultipleChoiceSet(
  action: ReturnType<typeof actions.updateMultipleChoiceSet>,
) {
  try {
    const response = yield call(updateMultipleChoiceSetRequest, {
      multipleChoiceSetId: action.multipleChoiceSetId,
      multipleChoiceSet: action.multipleChoiceSet,
    })
    yield put(actions.getSuccessMultipleChoiceSets(response))
    yield put(actions.updateSuccessMultipleChoiceSet())
    yield put(actions.resetMultipleChoiceSet())
    yield put(
      notificationServiceActions.showNotification({
        message: 'セット選択肢の更新に成功しました',
      }),
    )
    history.push(`/multipleChoiceSets`)
  } catch (error) {
    yield put(interceptionsActions.handleHttpError(error))
    yield put(actions.updateErrorMultipleChoiceSet(error))
  }
}

const sagas = [
  takeEvery(
    ActionTypes.REQUEST_GET_MULTIPLE_CHOICE_SETS,
    getMultipleChoiceSets,
  ),
  takeEvery(
    ActionTypes.REQUEST_CHANGE_MULTIPLE_CHOICE_SETS_PAGE,
    getMultipleChoiceSets,
  ),
  takeEvery(
    ActionTypes.REQUEST_CHANGE_MULTIPLE_CHOICE_SETS_SIZE,
    getMultipleChoiceSets,
  ),
  takeEvery(ActionTypes.REQUEST_GET_MULTIPLE_CHOICE_SET, getMultipleChoiceSet),
  takeEvery(
    ActionTypes.REQUEST_DELETE_MULTIPLE_CHOICE_SET,
    deleteMultipleChoiceSet,
  ),
  takeEvery(ActionTypes.REQUEST_ADD_MULTIPLE_CHOICE_SET, addMultipleChoiceSet),
  takeEvery(
    ActionTypes.REQUEST_UPDATE_MULTIPLE_CHOICE_SET,
    updateMultipleChoiceSet,
  ),
]

export default sagas
