import { css } from '@emotion/react'

type Props = {
  title: string
}
export const NavSection = (props: Props) => {
  const { title } = props
  return <div css={styles.container}>{title}</div>
}

const styles = {
  container: css`
    font-size: 12px;
    margin-left: 16px;
    min-height: 20px;
  `,
}
