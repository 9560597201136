import {
  AddImproveExcelConvertsRequest,
  GetImproveExcelConvertsRequest,
  GetImproveExcelConvertsResponse,
} from '@ulysses-inc/harami_api_client'
import { ActionTypes, GetImproveExcelConversionLogsFilter } from './types'

const getImproveExcelConversionLogs = (
  request: GetImproveExcelConvertsRequest,
  filter?: GetImproveExcelConversionLogsFilter,
) => {
  return {
    type: ActionTypes.REQUEST_GET_IMPROVE_EXCEL_CONVERSION_LOGS,
    isLoading: true,
    request,
    filter,
  }
}

const getSuccessImproveExcelConversionLogs = ({
  excelConversionLogs,
  count,
}: GetImproveExcelConvertsResponse) => {
  return {
    type: ActionTypes.SUCCESS_GET_IMPROVE_EXCEL_CONVERSION_LOGS,
    isLoading: false,
    excelConversionLogs,
    count,
  }
}

const getErrorImproveExcelConversionLogs = (error: Error) => {
  return {
    type: ActionTypes.ERROR_GET_IMPROVE_EXCEL_CONVERSION_LOGS,
    isLoading: false,
    error,
  }
}

const changeImproveExcelConversionLogsPage = (
  improveUUID: string,
  page: number,
  pageSize?: number,
  filter?: GetImproveExcelConversionLogsFilter,
) => {
  const offset = pageSize ? (page - 1) * pageSize : 0
  return {
    type: ActionTypes.REQUEST_CHANGE_IMPROVE_EXCEL_CONVERSION_LOGS_PAGE,
    isLoading: true,
    request: {
      improveUUID,
      limit: pageSize,
      offset,
    },
    filter,
  }
}

const changeImproveExcelConversionLogsSize = (
  improveUUID: string,
  pageSize: number,
  filter?: GetImproveExcelConversionLogsFilter,
) => {
  return {
    type: ActionTypes.REQUEST_CHANGE_IMPROVE_EXCEL_CONVERSION_LOGS_SIZE,
    isLoading: true,
    request: {
      improveUUID,
      limit: pageSize,
      offset: 0,
    },
    filter,
  }
}

const updatePagination = (
  improveUUID: string,
  limit: number,
  offset: number,
) => {
  return {
    type: ActionTypes.UPDATE_PAGINATION,
    request: {
      improveUUID,
      limit,
      offset,
    },
  }
}

const getImproveExcelConversionLogFiles = (fileUUIDs: string[]) => {
  return {
    type: ActionTypes.REQUEST_GET_IMPROVE_EXCEL_CONVERSION_LOG_FILES,
    fileUUIDs,
  }
}

const addImproveExcelConversionLogs = (
  request: AddImproveExcelConvertsRequest,
) => {
  return {
    type: ActionTypes.REQUEST_ADD_IMPROVE_EXCEL_CONVERSION_LOGS,
    isLoading: true,
    request,
  }
}

const addErrorImproveExcelConversionLogs = (error: Error) => {
  return {
    type: ActionTypes.ERROR_ADD_IMPROVE_EXCEL_CONVERSION_LOGS,
    isLoading: false,
    error,
  }
}

export type ImproveExcelConversionLogAction =
  | ReturnType<typeof getImproveExcelConversionLogs>
  | ReturnType<typeof getSuccessImproveExcelConversionLogs>
  | ReturnType<typeof getErrorImproveExcelConversionLogs>
  | ReturnType<typeof changeImproveExcelConversionLogsPage>
  | ReturnType<typeof changeImproveExcelConversionLogsSize>
  | ReturnType<typeof updatePagination>
  | ReturnType<typeof getImproveExcelConversionLogFiles>
  | ReturnType<typeof addImproveExcelConversionLogs>
  | ReturnType<typeof addErrorImproveExcelConversionLogs>

export default {
  getImproveExcelConversionLogs,
  getSuccessImproveExcelConversionLogs,
  getErrorImproveExcelConversionLogs,
  changeImproveExcelConversionLogsPage,
  changeImproveExcelConversionLogsSize,
  updatePagination,
  getImproveExcelConversionLogFiles,
  addImproveExcelConversionLogs,
  addErrorImproveExcelConversionLogs,
}
