import {
  ResponseTypeEnum,
  Template,
  TemplateGridVariableRow,
  TemplateNode,
  TemplatePage,
} from '@ulysses-inc/harami_api_client'
import { deepCopyTable } from './helpers/deepCopyTable'
import { SoftLimitErrorCode } from './reducers'
import {
  ActionTypes,
  CellMap,
  GridVariablesByNumber,
  SectionsByName,
  Table,
  Values,
} from './types'

export const requestFetchGridVariablesTable = (templateId: number) => {
  return {
    type: ActionTypes.REQUEST_FETCH_GRID_VARIABLES_TABLE,
    isLoading: true,
    templateId,
  }
}

export const successFetchGridVariablesTable = (
  template: Template,
  templatePages: TemplatePage[],
  templateGridVariableRows: TemplateGridVariableRow[],
) => {
  const sectionsByName = createSectionsByName(templatePages)
  const table = createTable(templateGridVariableRows, sectionsByName)

  return {
    type: ActionTypes.SUCCESS_FETCH_GRID_VARIABLES_TABLE,
    sectionsByName,
    isLoading: false,
    originalTable: table,
    template,
    inputTable: deepCopyTable(table),
  }
}

export const errorFetchGridVariablesTable = (error: Error) => {
  return {
    type: ActionTypes.ERROR_FETCH_GRID_VARIABLES_TABLE,
    isLoading: false,
    error,
  }
}

export const requestUpdateGridVariablesTable = (templateId: number) => {
  return {
    type: ActionTypes.REQUEST_UPDATE_GRID_VARIABLES_TABLE,
    templateId,
  }
}

export const successUpdateGridVariablesTable = () => {
  return {
    type: ActionTypes.SUCCESS_UPDATE_GRID_VARIABLES_TABLE,
  }
}

export const errorUpdateGridVariablesTable = (error: Error) => {
  return {
    type: ActionTypes.ERROR_UPDATE_GRID_VARIABLES_TABLE,
    error,
  }
}

export const setPatchGridVariablesError = (
  errorMsg: string,
  code: SoftLimitErrorCode,
) => {
  return {
    type: ActionTypes.SET_PATCH_GRID_VARIABLES_ERROR,
    errorMsg,
    code,
  }
}

export const clearPatchGridVariablesError = () => {
  return {
    type: ActionTypes.CLEAR_PATCH_GRID_VARIABLES_ERROR,
  }
}

export const startRangeSelect = (rowNum: number, colNum: number) => {
  return {
    type: ActionTypes.START_RANGE_SELECT,
    dragStartedCell: { rowNum, colNum },
  }
}

export const continueRangeSelect = (rowNum: number, colNum: number) => {
  return {
    type: ActionTypes.CONTINUE_RANGE_SELECT,
    edgeCell: { rowNum, colNum },
  }
}

export const finishRangeSelect = () => {
  return {
    type: ActionTypes.FINISH_RANGE_SELECT,
  }
}

export const doubleClickCell = (rowNumber: number, colNumber: number) => {
  const selectedCells: CellMap = {
    [rowNumber]: {
      [colNumber]: true,
    },
  }

  return {
    type: ActionTypes.DOUBLE_CLICK_CELL,
    selectedCells,
    mode: 'edit' as const,
  }
}

export const selectAllCell = () => {
  return {
    type: ActionTypes.SELECT_ALL_CELL,
  }
}

export const pickDate = () => {
  return {
    type: ActionTypes.PICK_DATE,
    mode: 'select' as const,
  }
}

export const selectOption = () => {
  return {
    type: ActionTypes.SELECT_OPTION,
    mode: 'select' as const,
  }
}

/**
 * テーブルに対してこのパッチを当てることで、行の各セルを変更できる
 *
 * - `undefined` を当てると、入力内容は変更されない(パッチされない)
 * - 空文字を当てると、値がクリアされる
 * - 上記以外の string を当てると、値が上書きされる(パッチされる)
 */
export type RowPatch = {
  /** 適用開始日 */
  from?: string
  /** 適用終了日 */
  through?: string
  /** セクション名 */
  sectionName?: string
  /** 取り込みセクション */
  variableSection?: string
  /** 取り込み項目 1〜 */
  values?: {
    [key: number]: string | undefined
  }
}

/**
 * InputTableの更新パターンは下記.
 *
 * 1. 更新
 * key/valueが存在する場合
 *
 * 2. 更新対象外(スキップ操作)
 * key/valueが存在しない場合
 *
 * 3. クリア(Rowデータの削除操作)
 * value を null にした場合 inputTable から行データ自体が削除される
 * 例)
 * inputTable: { 1: { from: ..., through: ... } }
 * ↓
 * inputTable: { }
 *
 */
export type PatchInputTable = {
  /** key = rowNumber */
  [key: number]: RowPatch | null
}

export const patchInputTable = (patchData: PatchInputTable) => {
  return {
    type: ActionTypes.PATCH_INPUT_TABLE,
    patchData,
  }
}

export const clearState = () => {
  return {
    type: ActionTypes.CLEAR_STATE,
  }
}

export type EditGridVariablesAction =
  | ReturnType<typeof requestFetchGridVariablesTable>
  | ReturnType<typeof successFetchGridVariablesTable>
  | ReturnType<typeof errorFetchGridVariablesTable>
  | ReturnType<typeof requestUpdateGridVariablesTable>
  | ReturnType<typeof successUpdateGridVariablesTable>
  | ReturnType<typeof errorUpdateGridVariablesTable>
  | ReturnType<typeof setPatchGridVariablesError>
  | ReturnType<typeof clearPatchGridVariablesError>
  | ReturnType<typeof startRangeSelect>
  | ReturnType<typeof continueRangeSelect>
  | ReturnType<typeof finishRangeSelect>
  | ReturnType<typeof doubleClickCell>
  | ReturnType<typeof selectAllCell>
  | ReturnType<typeof pickDate>
  | ReturnType<typeof selectOption>
  | ReturnType<typeof patchInputTable>
  | ReturnType<typeof clearState>

/**
 * 行の配列からテーブル(連想配列)を生成する
 *
 * @param rows 行の配列
 * @param sectionsByName セクション名と uuid のペアの配列
 * @returns テーブル
 */
const createTable = (
  rows: TemplateGridVariableRow[],
  sectionsByName: SectionsByName,
) => {
  /**
   * 以降のループ処理内部で線形探索させたくないので、事前に連想配列に変換する
   *
   * @key セクション uuid
   * @value セクション名
   */
  const sectionNamesByUuid: { [sectionUuid: string]: string } = {}
  for (const sectionName of Object.keys(sectionsByName)) {
    const sectionUuid = sectionsByName[sectionName]?.uuid
    if (!sectionUuid) continue

    sectionNamesByUuid[sectionUuid] = sectionName
  }

  const table: Table = {}

  rows.forEach(v => {
    // 先に、取り込み項目 1〜 を詰める
    const values: Values = {}
    v.values.forEach(w => {
      // gridQuestionNumber がユニークであることを前提として、要素の上書きが起こらないと想定している
      values[w.gridQuestionNumber] = {
        value: w.value,
        uuid: w.gridQuestionUUID,
        errors: [],
      }
    })

    // orderNumber がユニークであることを前提として、要素の上書きが起こらないと想定している
    table[v.orderNumber] = {
      from: {
        dateValue: v.from.toISOString(),
        errors: [],
      },
      through: {
        dateValue: v.through.toISOString(),
        errors: [],
      },
      sectionName: {
        name: sectionNamesByUuid[v.gridSectionUUID] || '',
        errors: [],
      },
      variableSection: {
        name: v.variableSection,
        errors: [],
      },
      values,
      hasErrors: false,
      hasChanged: false,
    }
  })

  return table
}

/**
 * セクション名と取り込み項目のマップを作る
 *
 * @param templatePages
 * @returns
 */
const createSectionsByName = (templatePages: TemplatePage[]) => {
  const sectionsByName: SectionsByName = {}

  for (const page of templatePages) {
    const node = (page.nodes || [])[0]

    if (!node || !node.nodes || !node.section?.name) {
      continue
    }

    let gridVariableNumber = 1
    const gridVariables: GridVariablesByNumber = {}
    for (const obj of node.nodes) {
      // WARN: 型 assertion は使いたくないが、API スキーマが `object` で定義されているのでやむをえない
      const nodeUnderSection = obj as TemplateNode

      if (
        nodeUnderSection.question?.responseType ===
        ResponseTypeEnum.GRID_VARIABLE
      ) {
        gridVariables[gridVariableNumber] = nodeUnderSection.uuid
        gridVariableNumber++
      }
    }

    sectionsByName[node.section.name] = {
      uuid: node.uuid,
      gridVariables,
    }
  }

  return sectionsByName
}
