// prettier-ignore
export const ActionTypes = {
  REQUEST_GET_APPROVAL_FLOW: 'harami/approvalFlows/REQUEST_GET_APPROVAL_FLOW',
  SUCCESS_GET_APPROVAL_FLOW: 'harami/approvalFlows/SUCCESS_GET_APPROVAL_FLOW',
  ERROR_GET_APPROVAL_FLOW: 'harami/approvalFlows/ERROR_GET_APPROVAL_FLOW',

  REQUEST_GET_APPROVAL_FLOWS: 'harami/approvalFlows/REQUEST_GET_APPROVAL_FLOWS',
  SUCCESS_GET_APPROVAL_FLOWS: 'harami/approvalFlows/SUCCESS_GET_APPROVAL_FLOWS',
  ERROR_GET_APPROVAL_FLOWS: 'harami/approvalFlows/ERROR_GET_APPROVAL_FLOWS',

  REQUEST_ADD_APPROVAL_FLOW: 'harami/approvalFlows/REQUEST_ADD_APPROVAL_FLOW',
  SUCCESS_ADD_APPROVAL_FLOW: 'harami/approvalFlows/SUCCESS_ADD_APPROVAL_FLOW',
  ERROR_ADD_APPROVAL_FLOW: 'harami/approvalFlows/ERROR_ADD_APPROVAL_FLOW',
  RESET_ADD_APPROVAL_FLOW: 'harami/approvalFlows/RESET_ADD_APPROVAL_FLOW',

  REQUEST_UPDATE_APPROVAL_FLOW: 'harami/approvalFlows/REQUEST_UPDATE_APPROVAL_FLOW',
  SUCCESS_UPDATE_APPROVAL_FLOW: 'harami/approvalFlows/SUCCESS_UPDATE_APPROVAL_FLOW',
  ERROR_UPDATE_APPROVAL_FLOW: 'harami/approvalFlows/ERROR_UPDATE_APPROVAL_FLOW',
  RESET_UPDATE_APPROVAL_FLOW: 'harami/approvalFlows/RESET_UPDATE_APPROVAL_FLOW',

  REQUEST_DELETE_APPROVAL_FLOW: 'harami/approvalFlows/REQUEST_DELETE_APPROVAL_FLOW',
  SUCCESS_DELETE_APPROVAL_FLOW: 'harami/approvalFlows/SUCCESS_DELETE_APPROVAL_FLOW',
  ERROR_DELETE_APPROVAL_FLOW: 'harami/approvalFlows/ERROR_DELETE_APPROVAL_FLOW',
} as const
