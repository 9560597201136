import { CheckOutlined, CloseOutlined } from '@ant-design/icons'
import { Switch, Typography } from 'antd'
import { FC } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { SwitchDisabledColor } from 'src/features/theme/KdsThemeColor'
import { toggleExcelConversion } from 'src/state/ducks/templates/editTemplate/actions'
import { RootState } from 'src/state/store'
import { ScoreSwitchWrap, ToggleWrap } from './EditTemplateOption.styled'

const { Text } = Typography

const ExcelSwitch: FC = () => {
  const dispatch = useDispatch()
  const isExcelConversion = useSelector(
    (state: RootState) => state.templatesState.editTemplate.isExcelConversion,
    shallowEqual,
  )

  return (
    <ToggleWrap>
      <ScoreSwitchWrap>
        <Switch
          checkedChildren={<CheckOutlined />}
          unCheckedChildren={<CloseOutlined />}
          checked={isExcelConversion}
          onChange={checked => {
            dispatch(toggleExcelConversion(checked))
          }}
          style={
            !isExcelConversion
              ? { backgroundColor: SwitchDisabledColor }
              : undefined
          }
        />
      </ScoreSwitchWrap>
      <div>
        <Text strong>EXCEL出力機能をつける</Text>
      </div>
    </ToggleWrap>
  )
}

export default ExcelSwitch
