import { Modal } from 'antd'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { clearCopyTemplatePageError } from 'src/state/ducks/templates/actions'
import { RootState } from 'src/state/store'

/**
 * ページコピー時のエラーモーダル表示用のhooks
 * @returns
 */
export const useCopyPageErrorDialog = () => {
  const dispatch = useDispatch()

  const copyTemplatePageError = useSelector(
    (state: RootState) =>
      state.templatesState.templatePages.copyTemplatePageError,
  )

  const handleClose = () => dispatch(clearCopyTemplatePageError())

  // レンダリングが複数回走っても一度しかモーダルを出さない
  useEffect(() => {
    if (copyTemplatePageError) {
      Modal.error({
        onOk: handleClose,
        title: `「${copyTemplatePageError.targetPageName}」のコピーが出来ません`,
        content: copyTemplatePageError.message,
      })
    }

    // handleCloseは依存配列に含める必要はない
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [copyTemplatePageError])
}
