import {
  ScheduleFrequencyCustom,
  ScheduleFrequencyEnum,
  ScheduleFrequencyMonthly,
  ScheduleFrequencyWeekly,
} from '@ulysses-inc/harami_api_client'
import { InputNumber, Select } from 'antd'
import { FormikErrors, FormikTouched } from 'formik'
import React from 'react'
import FormikErrorMessage from 'src/components/formikErrorMessage/FormikErrorMessage'
import styled from 'styled-components'

const { Option } = Select

const FrequencySelect = styled(Select)`
  width: 200px;
`

const FrequencyContainer = styled.div`
  margin-top: 10px;
`

interface OwnProps {
  frequency: ScheduleFrequencyEnum | null
  frequencyWeekly?: ScheduleFrequencyWeekly
  frequencyMonthly?: ScheduleFrequencyMonthly
  frequencyCustom?: ScheduleFrequencyCustom
  touched: FormikTouched<any>
  errors: FormikErrors<any>
  submitCount: number
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
}

const FrequencySelectOptionInDuration: React.FC<OwnProps> = (
  props: OwnProps,
) => {
  return (
    <div>
      <FrequencySelect
        value={props.frequency || undefined}
        onChange={(value: unknown) => {
          if (typeof value !== 'number') return
          props.setFieldValue('frequency', value)
        }}
      >
        <Option value={ScheduleFrequencyEnum.Weekly}>週単位</Option>
        <Option value={ScheduleFrequencyEnum.Monthly}>月単位</Option>
        <Option value={ScheduleFrequencyEnum.Custom}>カスタム</Option>
      </FrequencySelect>
      <div>
        {props.submitCount > 0 && (
          <FormikErrorMessage name="frequency" errors={props.errors} />
        )}
      </div>
      {props.frequency === ScheduleFrequencyEnum.Weekly && (
        <FrequencyContainer>
          <div>
            <InputNumber
              value={props.frequencyWeekly?.every}
              onChange={(value: string | number | undefined | null) => {
                if (value === undefined && typeof value !== 'number') return
                props.setFieldValue('frequencyWeekly', {
                  ...props.frequencyWeekly,
                  every: value,
                  dayOfWeeks: [],
                })
              }}
            />
            週間ごと
          </div>
          <div>
            {props.submitCount > 0 && (
              <FormikErrorMessage
                name="frequencyWeekly.every"
                errors={props.errors}
              />
            )}
          </div>
        </FrequencyContainer>
      )}
      {props.frequency === ScheduleFrequencyEnum.Monthly && (
        <FrequencyContainer>
          <div>
            <InputNumber
              value={props.frequencyMonthly?.every}
              onChange={(value: string | number | undefined | null) => {
                if (value === undefined && typeof value !== 'number') return
                props.setFieldValue('frequencyMonthly', {
                  ...props.frequencyMonthly,
                  every: value,
                })
              }}
            />
            ヵ月ごと
          </div>
          <div>
            {props.submitCount > 0 && (
              <FormikErrorMessage
                name="frequencyMonthly.every"
                errors={props.errors}
              />
            )}
          </div>
        </FrequencyContainer>
      )}
      {props.frequency === ScheduleFrequencyEnum.Custom && (
        <FrequencyContainer>
          <div>
            <InputNumber
              value={props.frequencyCustom?.every}
              onChange={(value: string | number | undefined | null) => {
                if (value === undefined && typeof value !== 'number') return
                props.setFieldValue('frequencyCustom', {
                  ...props.frequencyCustom,
                  every: value,
                })
              }}
            />
            日ごと
          </div>
          <div>
            {props.submitCount > 0 && (
              <FormikErrorMessage
                name="frequencyCustom.every"
                errors={props.errors}
              />
            )}
          </div>
        </FrequencyContainer>
      )}
    </div>
  )
}

export default FrequencySelectOptionInDuration
