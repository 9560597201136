import { GetExcelConversionLogsRequest } from '@ulysses-inc/harami_api_client'
import Redux from 'redux'
import { GetExcelConversionLogsFilter } from 'src/state/ducks/excelConversionLogs/types'
import actions from './actions'

const getExcelConversionLogs = (
  dispatch: Redux.Dispatch,
  request: GetExcelConversionLogsRequest,
  filter?: GetExcelConversionLogsFilter,
) => {
  dispatch(actions.getExcelConversionLogs(request, filter))
}

const changeExcelConversionLogsPage = (
  dispatch: Redux.Dispatch,
  page: number,
  pageSize?: number,
  filter?: GetExcelConversionLogsFilter,
) => {
  dispatch(actions.changeExcelConversionLogsPage(page, pageSize, filter))
}

const changeExcelConversionLogsSize = (
  dispatch: Redux.Dispatch,
  pageSize: number,
  filter?: GetExcelConversionLogsFilter,
) => {
  dispatch(actions.changeExcelConversionLogsSize(pageSize, filter))
}

const updatePagination = (
  dispatch: Redux.Dispatch,
  limit: number,
  offset: number,
) => {
  dispatch(actions.updatePagination(limit, offset))
}

const getExcelConversionLogFiles = (
  dispatch: Redux.Dispatch,
  fileUUIDs: string[],
) => {
  dispatch(actions.getExcelConversionLogFiles(fileUUIDs))
}

export default {
  getExcelConversionLogs,
  changeExcelConversionLogsPage,
  changeExcelConversionLogsSize,
  updatePagination,
  getExcelConversionLogFiles,
}
