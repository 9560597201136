import {
  CopyTemplatesV2Request,
  DeleteTemplateRequest,
  ErrorCodeEnum,
  GetTemplatesResponse,
  GetTemplatesV2Request,
  TemplateFilter,
  TemplatesApi,
} from '@ulysses-inc/harami_api_client'
import { call, put, takeEvery } from 'redux-saga/effects'
import * as notificationServiceActions from 'src/features/notificationService/slice'
import interceptionsActions from 'src/state/ducks/interceptions/actions'
import * as templateListPageActions from 'src/state/ducks/templateList/actions'
import { ActionTypes } from 'src/state/ducks/templateList/types'
import BaseClient from 'src/state/middleware/saga/baseClient'
import {
  HTTPError,
  handleHTTPError,
} from 'src/state/middleware/saga/handleHttpError'

const baseClient = new BaseClient()

const getTemplatesV2Request = (req: GetTemplatesV2Request) => {
  return baseClient
    .getApi(TemplatesApi)
    .getTemplatesV2(req)
    .then(templates => templates)
    .catch(handleHTTPError)
}

const deleteTemplateRequest = (req: DeleteTemplateRequest) => {
  return baseClient
    .getApi(TemplatesApi)
    .deleteTemplate(req)
    .catch(handleHTTPError)
}

const copyTemplatesV2Request = (req: CopyTemplatesV2Request) => {
  return baseClient
    .getApi(TemplatesApi)
    .copyTemplatesV2(req)
    .then(templates => templates)
    .catch(handleHTTPError)
}

function* getTemplates(
  action: ReturnType<typeof templateListPageActions.getTemplates>,
) {
  try {
    const params: GetTemplatesV2Request = {
      ...action.request,
      // OpenAPI定義が間違っており型エラー出る。影響範囲が広すぎてOpenAPIの修正が現実的に不可能なため、やむなくキャストしている。
      templateFilter: action.filter as TemplateFilter,
    }
    const response: GetTemplatesResponse = yield call(
      getTemplatesV2Request,
      params,
    )
    yield put(templateListPageActions.getSuccessTemplates(response))
  } catch (error) {
    yield put(interceptionsActions.handleHttpError(error as HTTPError))
    yield put(templateListPageActions.getErrorTemplates(error as Error))
  }
}

function* copyTemplates(
  action: ReturnType<typeof templateListPageActions.copyTemplates>,
) {
  try {
    const params: CopyTemplatesV2Request = {
      ...action.request,
      copyTemplates: {
        templateIds: action.templateIds,
        // OpenAPI定義が間違っており型エラー出る。影響範囲が広すぎてOpenAPIの修正が現実的に不可能なため、やむなくキャストしている。
        templateFilter: action.filter as TemplateFilter,
      },
    }
    const response: GetTemplatesResponse = yield call(
      copyTemplatesV2Request,
      params,
    )
    yield put(templateListPageActions.copySuccessTemplates(response))
    yield put(
      notificationServiceActions.showNotification({
        message: 'ひな形のコピーに成功しました',
      }),
    )
  } catch (error) {
    yield put(interceptionsActions.handleHttpError(error as HTTPError))
    yield put(templateListPageActions.copyErrorTemplates(error as Error))
  }
}

function* deleteTemplate(
  action: ReturnType<typeof templateListPageActions.deleteTemplate>,
) {
  try {
    yield call(deleteTemplateRequest, {
      templateId: action.templateId,
    })
    yield put(templateListPageActions.deleteSuccessTemplate(action.templateId))
  } catch (error) {
    const httpError = error as HTTPError
    if (
      httpError.status === 400 &&
      httpError.code === ErrorCodeEnum.CannotDeleteTemplateByGridVariablesError
    ) {
      yield put(
        templateListPageActions.setTemplateDeleteErrorTemplateId(
          action.templateId,
        ),
      )
    } else {
      yield put(interceptionsActions.handleHttpError(httpError))
      yield put(templateListPageActions.deleteErrorTemplate(error as Error))
    }
  }
}

const sagas = [
  takeEvery(ActionTypes.REQUEST_GET_TEMPLATES, getTemplates),
  takeEvery(ActionTypes.REQUEST_DELETE_TEMPLATE, deleteTemplate),
  takeEvery(ActionTypes.REQUEST_CHANGE_TEMPLATES_PAGE, getTemplates),
  takeEvery(ActionTypes.REQUEST_CHANGE_TEMPLATES_SIZE, getTemplates),
  takeEvery(ActionTypes.REQUEST_COPY_TEMPLATES, copyTemplates),
]

export default sagas
