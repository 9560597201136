import {
  DoubleRightOutlined,
  HomeOutlined,
  TagOutlined,
} from '@ant-design/icons'
import { css } from '@emotion/react'
import { Button, Select, Spin } from 'antd'
import { ErrorMessage } from 'src/components/errorMessage/ErrorMessage'
import { FiltersDrawerFieldset } from 'src/features/scheduledReportSummary/components/FiltersDrawerFieldset'
import {
  ScheduledReportSummaryFilters,
  useFilters,
} from 'src/features/scheduledReportSummary/useFilters'
import { useQueryPlaceNodes } from 'src/features/tanStackQuery/commonQueries/useQueryPlaceNodes'
import { useQueryUserPlaceNodes } from 'src/features/tanStackQuery/commonQueries/useQueryUserPlaceNodes'

type Props = {
  filters: ScheduledReportSummaryFilters
  onCloseButtonClicked: () => void
  setFilterOf: ReturnType<typeof useFilters>['setFilterOf']
  resetFilterOf: ReturnType<typeof useFilters>['resetFilterOf']
}

export const FiltersDrawer = ({
  filters,
  onCloseButtonClicked,
  setFilterOf,
  resetFilterOf,
}: Props) => {
  const userPlaceNodesQuery = useQueryUserPlaceNodes()
  const allPlaceNodesQuery = useQueryPlaceNodes()

  const isPending =
    userPlaceNodesQuery.isPending || allPlaceNodesQuery.isPending
  const isError = userPlaceNodesQuery.isError || allPlaceNodesQuery.isError

  if (isPending) {
    return <Spin />
  }

  if (isError) {
    return <ErrorMessage noMargin>エラーが発生しました</ErrorMessage>
  }

  // 現状では、現場に未所属のユーザーはすべての現場に属しているのと同じ扱いとなっているため
  const userHasSomeAssignedPlaceNodes = userPlaceNodesQuery.data.length > 0
  const selectablePlaceNodes = userHasSomeAssignedPlaceNodes
    ? userPlaceNodesQuery.data
    : allPlaceNodesQuery.data

  return (
    <div>
      <div css={styles.topButtonsRow}>
        <Button
          icon={<DoubleRightOutlined style={{ fontSize: 12 }} />}
          onClick={onCloseButtonClicked}
          size="small"
        />
        <Button
          css={styles.clearButton}
          onClick={() => {
            // ドロワー内のクリアボタンでは、ドロワー内に入力欄がある項目だけをクリアする仕様である
            resetFilterOf.allExceptInDrawer()
          }}
        >
          すべてクリア
        </Button>
      </div>

      <div css={styles.restRow}>
        <FiltersDrawerFieldset title="現場">
          <Select
            css={styles.placeNodeSelect}
            mode="multiple"
            onChange={uuids => {
              setFilterOf.placeNodeUuids(uuids)
            }}
            optionFilterProp="label"
            value={filters.placeNodeUuids}
            placeholder="現場を選択"
            showSearch
          >
            {selectablePlaceNodes.map(placeNode => {
              return (
                <Select.Option
                  key={placeNode.uuid}
                  value={placeNode.uuid}
                  label={placeNode.name}
                >
                  <div css={styles.placeNodesOptionInner}>
                    <div css={styles.placeNodesOptionIcon}>
                      {placeNode.type === 'placeGroup' ? (
                        <TagOutlined />
                      ) : (
                        <HomeOutlined />
                      )}
                    </div>
                    <span>{placeNode.name}</span>
                  </div>
                </Select.Option>
              )
            })}
          </Select>
        </FiltersDrawerFieldset>
      </div>
    </div>
  )
}

const styles = {
  topButtonsRow: css`
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 16px;
  `,
  clearButton: css`
    align-self: flex-end;
  `,
  placeNodeSelect: css`
    width: 100%;
  `,
  restRow: css`
    display: grid;
    grid-gap: 24px;
    padding: 16px 24px 24px;
  `,
  placeNodesOptionInner: css`
    display: flex;
    align-items: center;
  `,
  placeNodesOptionIcon: css`
    margin-right: 8px;
  `,
}
