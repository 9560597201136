import { motion } from 'framer-motion'
import { Black, Danger } from 'src/features/theme/KdsThemeColor'
import styled from 'styled-components'

export const Root = styled(motion.div)`
  border-radius: 8px;
  border: 1px solid ${Danger};
  background-color: #ffe3e3;
  padding: 16px;
  color: ${Black};
  width: 100%;
`
export const Inner = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  display: flex;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`
export const IconWrapper = styled.div`
  font-size: 24px;
  line-height: 24px;
  color: ${Danger};
`
export const MessageArea = styled.div`
  margin-left: 16px;
`
export const Title = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
`
export const Message = styled.div`
  font-weight: 400;
  font-size: 14px;
  margin-top: 8px;
  line-height: 22px;
`
