import { ArrowLeftOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import { ComponentProps } from 'react'

/**
 * ヘッダーで使用する「<-戻る」ボタン
 */
export const HeaderBackButton = (props: ComponentProps<typeof Button>) => {
  const { children = '戻る', ...restProps } = props
  return (
    <Button {...restProps} icon={<ArrowLeftOutlined />} type="link">
      {children}
    </Button>
  )
}
