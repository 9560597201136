import {
  ResponseDatetime,
  ResponseDatetimeRuleSubTypeEnum,
} from '@ulysses-inc/harami_api_client'

const convertDisplayDatetimeCharacters = (rn: ResponseDatetime): string => {
  const rule = rn.rule
  if (rule) {
    const value = rule.value
    const beforeOrAfter = !value ? '' : value < 0 ? '前' : '後'
    const absValue = value && Math.abs(value)
    switch (rule.subType) {
      case ResponseDatetimeRuleSubTypeEnum.LESS_THAN_OR_EQUAL: {
        return `${absValue}日${beforeOrAfter}以内の日付`
      }
      case ResponseDatetimeRuleSubTypeEnum.GREATER_THAN_OR_EQUAL: {
        return `${absValue}日${beforeOrAfter}以上の日付`
      }
      case ResponseDatetimeRuleSubTypeEnum.EQUAL: {
        return `${absValue}日${beforeOrAfter}と同じ日付`
      }
      default: {
        return `${value}`
      }
    }
  }
  return ''
}

export default convertDisplayDatetimeCharacters
