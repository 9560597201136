import { useMutation } from '@tanstack/react-query'
import { ReportPlace, ReportsApi } from '@ulysses-inc/harami_api_client'
import { baseClient } from 'src/state/middleware/saga/baseClient'

type Variables = {
  newPlaceName: string
  newPlaceUuid: string
  reportIds: number[]
}

export const useMutationAssignPlace = () => {
  return useMutation({
    mutationFn: async (variables: Variables) => {
      const { newPlaceName, newPlaceUuid, reportIds } = variables

      // FIXME:
      // 現在のAPIでは、フロントエンドから送信された現場名と現場のuuidは、
      // 何らバリデーションを経ることなく直接DBまで到達するため注意。
      // また、API側がuuidを基に現場をクエリしていないため、フロントエンドから現場名を送信する必要が生じている。
      // 参考: https://kaminashi-inc.slack.com/archives/C047X5HBRK5/p1692348858531329?thread_ts=1692322303.032539&cid=C047X5HBRK5
      const payload: ReportPlace[] = reportIds.map(reportId => ({
        reportId,
        name: newPlaceName,
        nodeId: newPlaceUuid,
      }))

      await baseClient.getApi(ReportsApi).updateReportPlacesV2({
        updateReportPlace: {
          updateReportPlaces: payload,
          // 既存APIがレスポンスとしてレポート一覧を返す機能を持っている(!)ため必要となったもの。
          // このカスタムフックではレスポンスのデータは使わないので、何を渡しても影響はない。
          // ただし省略するとAPIがエラーを返すので注意。
          reportFilter: {},
        },
      })
    },
  })
}
