import {
  GetTemplateHintsRequest,
  TemplateHint,
  TemplatesApi,
} from '@ulysses-inc/harami_api_client'
import { call, put, takeEvery } from 'redux-saga/effects'
import interceptionsActions from 'src/state/ducks/interceptions/actions'
import * as templateHintsActions from 'src/state/ducks/templates/templateHints/actions'
import { ActionTypes } from 'src/state/ducks/templates/templateHints/types'
import { baseClient } from 'src/state/middleware/saga/baseClient'
import {
  HTTPError,
  handleHTTPError,
} from 'src/state/middleware/saga/handleHttpError'

const getTemplateHintsRequest = (req: GetTemplateHintsRequest) => {
  return baseClient
    .getApi(TemplatesApi)
    .getTemplateHints(req)
    .then(templateHints => templateHints)
    .catch(handleHTTPError)
}

function* getTemplateHints(
  action: ReturnType<typeof templateHintsActions.getTemplateHints>,
) {
  try {
    const templateHints: TemplateHint[] = yield call(getTemplateHintsRequest, {
      templateId: action.templateId,
    })
    yield put(templateHintsActions.getSuccessTemplateHints(templateHints))
  } catch (error) {
    yield put(interceptionsActions.handleHttpError(error as HTTPError))
    yield put(templateHintsActions.getErrorTemplateHints(error as Error))
  }
}

export const templateHintsSagas = [
  takeEvery(ActionTypes.REQUEST_GET_TEMPLATE_HINTS, getTemplateHints),
]
