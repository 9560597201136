import {
  Improve,
  ImproveStatusStatusEnum,
} from '@ulysses-inc/harami_api_client'
import { ActionTypes } from '../types'

const updateRequestImprove = (
  improve: Improve,
  improveUUID: string,
  message?: string,
) =>
  ({
    type: ActionTypes.REQUEST_UPDATE_IMPROVE,
    isLoading: true,
    improve,
    improveUUID,
    message,
  }) as const

const updateSuccessImprove = () =>
  ({
    type: ActionTypes.SUCCESS_UPDATE_IMPROVE,
    isLoading: false,
  }) as const

const updateErrorImprove = (error: Error) =>
  ({
    type: ActionTypes.ERROR_UPDATE_IMPROVE,
    isLoading: false,
    error,
  }) as const

const updateRequestImproveStatus = (
  improveUUID: string,
  status: ImproveStatusStatusEnum,
) =>
  ({
    type: ActionTypes.REQUEST_UPDATE_IMPROVE_STATUS,
    isLoading: true,
    improveUUID,
    status,
  }) as const

const updateSuccessImproveStatus = (status: ImproveStatusStatusEnum) =>
  ({
    type: ActionTypes.SUCCESS_UPDATE_IMPROVE_STATUS,
    isLoading: false,
    status,
  }) as const

const updateErrorImproveStatus = (error: Error) =>
  ({
    type: ActionTypes.ERROR_UPDATE_IMPROVE_STATUS,
    isLoading: false,
    error,
  }) as const

const resetEditImprove = () =>
  ({
    type: ActionTypes.RESET_EDIT_IMPROVE,
  }) as const

export type UpdateImproveAction =
  | ReturnType<typeof updateRequestImprove>
  | ReturnType<typeof updateSuccessImprove>
  | ReturnType<typeof updateErrorImprove>
  | ReturnType<typeof updateRequestImproveStatus>
  | ReturnType<typeof updateSuccessImproveStatus>
  | ReturnType<typeof updateErrorImproveStatus>
  | ReturnType<typeof resetEditImprove>

export default {
  updateRequestImprove,
  updateSuccessImprove,
  updateErrorImprove,
  updateRequestImproveStatus,
  updateSuccessImproveStatus,
  updateErrorImproveStatus,
  resetEditImprove,
}
