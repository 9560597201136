import {
  GetSchedulesRequest,
  GetSchedulesResponse,
  Schedule,
  Template,
} from '@ulysses-inc/harami_api_client'
import { ActionTypes, GetSchedulesFilter } from './types'

const resetSchedule = () => {
  return {
    type: ActionTypes.REQUEST_RESET_SCHEDULE,
    isLoading: false,
  }
}

const getSchedule = (scheduleId: string) => {
  return {
    type: ActionTypes.REQUEST_GET_SCHEDULE,
    isLoading: true,
    scheduleId,
  }
}

const getSuccessSchedule = (schedule: Schedule) => {
  return {
    type: ActionTypes.SUCCESS_GET_SCHEDULE,
    isLoading: false,
    schedule,
  }
}

const getErrorSchedule = (error: Error) => {
  return {
    type: ActionTypes.ERROR_GET_SCHEDULE,
    isLoading: false,
    error,
  }
}

export type ScheduleAction =
  | ReturnType<typeof getSchedule>
  | ReturnType<typeof getSuccessSchedule>
  | ReturnType<typeof getErrorSchedule>
  | ReturnType<typeof resetSchedule>

const addSchedule = (schedule: Schedule) => {
  return {
    type: ActionTypes.REQUEST_ADD_SCHEDULE,
    isLoading: true,
    schedule,
  }
}

const addSuccessSchedule = () => {
  return {
    type: ActionTypes.SUCCESS_ADD_SCHEDULE,
    isLoading: false,
  }
}

const addErrorSchedule = (error: Error) => {
  return {
    type: ActionTypes.ERROR_ADD_SCHEDULE,
    isLoading: false,
    error,
  }
}

export type AddScheduleAction =
  | ReturnType<typeof addSchedule>
  | ReturnType<typeof addSuccessSchedule>
  | ReturnType<typeof addErrorSchedule>

const updateSchedule = (scheduleId: string, schedule: Schedule) => {
  return {
    type: ActionTypes.REQUEST_UPDATE_SCHEDULE,
    isLoading: true,
    schedule,
    scheduleId,
  }
}

const updateSuccessSchedule = () => {
  return {
    type: ActionTypes.SUCCESS_UPDATE_SCHEDULE,
    isLoading: false,
  }
}

const updateErrorSchedule = (error: Error) => {
  return {
    type: ActionTypes.ERROR_UPDATE_SCHEDULE,
    isLoading: false,
    error,
  }
}

export type UpdateScheduleAction =
  | ReturnType<typeof updateSchedule>
  | ReturnType<typeof updateSuccessSchedule>
  | ReturnType<typeof updateErrorSchedule>

const copySchedule = (schedule: Schedule) => {
  return {
    type: ActionTypes.REQUEST_COPY_SCHEDULE,
    isLoading: true,
    schedule,
  }
}

const copySuccessSchedule = () => {
  return {
    type: ActionTypes.SUCCESS_COPY_SCHEDULE,
    isLoading: false,
  }
}

const copyErrorSchedule = (error: Error) => {
  return {
    type: ActionTypes.ERROR_COPY_SCHEDULE,
    isLoading: false,
    error,
  }
}

// 未利用
// export type CopyScheduleAction =
//   | ReturnType<typeof copySchedule>
//   | ReturnType<typeof copySuccessSchedule>
//   | ReturnType<typeof copyErrorSchedule>

const deleteSchedule = (
  scheduleId: string,
  request: GetSchedulesRequest,
  filter?: GetSchedulesFilter,
) => {
  return {
    type: ActionTypes.REQUEST_DELETE_SCHEDULE,
    isLoading: true,
    scheduleId,
    request,
    filter,
  }
}

const deleteSuccessSchedule = () => {
  return {
    type: ActionTypes.SUCCESS_DELETE_SCHEDULE,
    isLoading: false,
  }
}

const deleteErrorSchedule = (error: Error) => {
  return {
    type: ActionTypes.ERROR_DELETE_SCHEDULE,
    isLoading: false,
    error,
  }
}

const deleteSchedules = (
  scheduleUUIDs: string[],
  request: GetSchedulesRequest,
  filter?: GetSchedulesFilter,
) => {
  return {
    type: ActionTypes.REQUEST_DELETE_SCHEDULES,
    scheduleUUIDs,
    request,
    filter,
  }
}

export type DeleteScheduleAction =
  | ReturnType<typeof deleteSchedule>
  | ReturnType<typeof deleteSuccessSchedule>
  | ReturnType<typeof deleteErrorSchedule>
  | ReturnType<typeof deleteSchedules>

const getSchedules = (
  request: GetSchedulesRequest,
  filter?: GetSchedulesFilter,
) => {
  return {
    type: ActionTypes.REQUEST_GET_SCHEDULES,
    isLoading: true,
    request,
    filter,
  }
}

const getSuccessSchedules = ({ schedules, count }: GetSchedulesResponse) => {
  return {
    type: ActionTypes.SUCCESS_GET_SCHEDULES,
    isLoading: false,
    schedules,
    count,
  }
}

const getErrorSchedules = (error: Error) => {
  return {
    type: ActionTypes.ERROR_GET_SCHEDULES,
    isLoading: false,
    error,
  }
}

const changeSchedulesPage = (
  page: number,
  pageSize?: number,
  filter?: GetSchedulesFilter,
) => {
  const offset = pageSize ? (page - 1) * pageSize : 0
  return {
    type: ActionTypes.REQUEST_CHANGE_SCHEDULES_PAGE,
    isLoading: true,
    request: {
      limit: pageSize,
      offset,
    },
    filter,
  }
}

const changeSchedulesSize = (pageSize: number, filter?: GetSchedulesFilter) => {
  return {
    type: ActionTypes.REQUEST_CHANGE_SCHEDULES_SIZE,
    isLoading: true,
    request: {
      limit: pageSize,
      offset: 0,
    },
    filter,
  }
}

const updatePagination = (limit: number, offset: number) => {
  return {
    type: ActionTypes.UPDATE_PAGINATION,
    request: {
      limit,
      offset,
    },
  }
}

export type SchedulesAction =
  | ReturnType<typeof getSchedules>
  | ReturnType<typeof getSuccessSchedules>
  | ReturnType<typeof getErrorSchedules>
  | ReturnType<typeof changeSchedulesPage>
  | ReturnType<typeof changeSchedulesSize>
  | ReturnType<typeof updatePagination>

const downloadScheduleQrCodes = (scheduleUUIDs: string[]) => {
  return {
    type: ActionTypes.DOWNLOAD_SCHEDULE_QR_CODES,
    scheduleUUIDs,
  }
}

// 未利用
// export type DownloadScheduleAction = ReturnType<typeof downloadScheduleQrCodes>

const getSchedulesTemplates = () => {
  return {
    type: ActionTypes.REQUEST_GET_SCHEDULES_TEMPLATES,
  }
}

const getSuccessSchedulesTemplates = (templates: Template[]) => {
  return {
    type: ActionTypes.SUCCESS_GET_SCHEDULES_TEMPLATES,
    templates,
  }
}

const getErrorSchedulesTemplates = (error: Error) => {
  return {
    type: ActionTypes.ERROR_GET_SCHEDULES_TEMPLATES,
    error,
  }
}

const resetSchedulesTemplates = () => {
  return {
    type: ActionTypes.RESET_SCHEDULES_TEMPLATES,
  }
}

export type SchedulesTemplatesAction =
  | ReturnType<typeof getSchedulesTemplates>
  | ReturnType<typeof getSuccessSchedulesTemplates>
  | ReturnType<typeof getErrorSchedulesTemplates>
  | ReturnType<typeof resetSchedulesTemplates>

export default {
  resetSchedule,
  getSchedule,
  getSuccessSchedule,
  getErrorSchedule,
  changeSchedulesPage,
  changeSchedulesSize,
  updatePagination,
  addSchedule,
  addSuccessSchedule,
  addErrorSchedule,
  updateSchedule,
  updateSuccessSchedule,
  updateErrorSchedule,
  copySchedule,
  copySuccessSchedule,
  copyErrorSchedule,
  deleteSchedule,
  deleteSuccessSchedule,
  deleteErrorSchedule,
  deleteSchedules,
  getSchedules,
  getSuccessSchedules,
  getErrorSchedules,
  downloadScheduleQrCodes,
  getSchedulesTemplates,
  getSuccessSchedulesTemplates,
  getErrorSchedulesTemplates,
  resetSchedulesTemplates,
}
