import { CheckOutlined, EditOutlined } from '@ant-design/icons'
import { TemplateManual } from '@ulysses-inc/harami_api_client'
import { Button } from 'antd'
import React, { useState } from 'react'
import { Success } from 'src/features/theme/KdsThemeColor'
import styled from 'styled-components'
import EditManualModal from './EditManualModal'

const EditManualBlocksContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const ManualCheckIconWrapper = styled.span`
  display: flex;
  align-items: center;
  height: 32px;
  color: ${Success};
  margin-right: 10px;
`

const ManualCheckText = styled.span`
  margin-left: 5px;
`

interface OwnProps {
  manual: TemplateManual
}

const EditManualBlocks: React.FC<OwnProps> = (props: OwnProps) => {
  const [isOpenEditManual, setIsOpenEditManual] = useState(false)
  return (
    <EditManualBlocksContainer data-testid="manual-content-cell">
      <Button
        type="link"
        icon={<EditOutlined />}
        onClick={() => setIsOpenEditManual(true)}
      />
      {(props.manual.blocks ?? []).length > 0 && (
        <ManualCheckIconWrapper>
          <CheckOutlined />
          <ManualCheckText>入力済</ManualCheckText>
        </ManualCheckIconWrapper>
      )}
      <EditManualModal
        templateManual={props.manual}
        isOpenModal={isOpenEditManual}
        setIsOpenModal={(isOpenEditManual: boolean) => {
          setIsOpenEditManual(isOpenEditManual)
        }}
        onClose={() => setIsOpenEditManual(false)}
        blocks={props.manual.blocks}
      />
    </EditManualBlocksContainer>
  )
}

export default EditManualBlocks
