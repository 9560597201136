import { css } from '@emotion/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { Modal, Select } from 'antd'
import { useEffect } from 'react'
import { Controller, DefaultValues, useForm } from 'react-hook-form'
import { ErrorMessage } from 'src/components/errorMessage/ErrorMessage'
import { useMutationAssignPlace } from 'src/features/reports/assignPlaceModal/api/useMutationAssignPlace'
import { useQueryPlaces } from 'src/features/reports/assignPlaceModal/api/useQueryPlaces'
import { Spinner } from 'src/features/reports/assignPlaceModal/components/Spinner'
import yup from 'src/features/validation/yup'

const formValuesSchema = yup
  .object({
    newPlaceUuid: yup.string().required('現場を選択してください'),
  })
  .required()

type ValidatedFormValues = yup.InferType<typeof formValuesSchema>

const defaultFormValues: DefaultValues<ValidatedFormValues> = {
  newPlaceUuid: undefined,
}

type Props = {
  currentlyEditingReportIds: number[]
  isOpen: boolean
  onClose: (isSomeReportUpdated: boolean) => void
}

/**
 * 現場の付け替えモーダル
 *
 * 現状ではシン・レポート一覧画面のみで使用されるものではあるが、以下の理由によりフォルダを分けた。
 * - シン・レポート一覧画面とは意味的に疎結合であるため (どの画面でも使いまわせる)
 * - 分けた方がコードを読みやすくなり、後の管理もしやすいため
 */
export const AssignPlaceModal = (props: Props) => {
  const { currentlyEditingReportIds, isOpen, onClose } = props

  const placesQuery = useQueryPlaces(
    // - このモーダルを使う全ての場所のテストでAPIのモックが必要になってしまうため
    // - ほとんどのユーザはこのモーダルを使うことはないため
    { enabled: isOpen },
  )
  const isPending = placesQuery.isPending
  const isError = placesQuery.isError

  const { mutate: assignPlaceMutation, isPending: isAssigningPlace } =
    useMutationAssignPlace()

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset: resetFormData,
  } = useForm<ValidatedFormValues>({
    defaultValues: defaultFormValues,
    mode: 'onChange',
    resolver: yupResolver(formValuesSchema),
  })

  useEffect(() => {
    if (!isOpen) {
      resetFormData()
    }
  }, [isOpen, resetFormData])

  const onOkClick = handleSubmit(validatedFormData => {
    // この関数自体なくしたいが既存APIの制約上必須
    const lookupPlaceNameByUuid = (placeUuid: string): string => {
      const place = placesQuery.data?.find(place => place.uuid === placeUuid)
      // 基本的にあり得ない
      if (place?.name === undefined) {
        throw new Error('現場名の取得に失敗しました')
      }
      return place.name
    }

    assignPlaceMutation(
      {
        newPlaceUuid: validatedFormData.newPlaceUuid,
        newPlaceName: lookupPlaceNameByUuid(validatedFormData.newPlaceUuid),
        reportIds: currentlyEditingReportIds,
      },
      {
        onSuccess: () => {
          onClose(true)
        },
      },
    )
  })

  return (
    <Modal
      confirmLoading={isAssigningPlace}
      okText="保存"
      onCancel={() => onClose(false)}
      onOk={onOkClick}
      open={isOpen}
      okButtonProps={{
        style: styles.button,
      }}
      cancelButtonProps={{
        style: styles.button,
      }}
      title="現場の付け替え"
      width={412}
    >
      {isPending && <Spinner css={styles.spinner} />}

      {isError && <ErrorMessage noMargin>エラーが発生しました</ErrorMessage>}

      {!isPending && !isError && (
        <div css={styles.container}>
          <Controller
            control={control}
            name="newPlaceUuid"
            render={({ field }) => (
              <Select
                {...field}
                css={styles.newPlaceSelect}
                optionFilterProp="label"
                options={placesQuery.data.map(place => ({
                  value: place.uuid,
                  label: place.name,
                }))}
                placeholder="付け替える現場を選択してください"
                showSearch
              />
            )}
          ></Controller>
          <ErrorMessage>{errors['newPlaceUuid']?.message}</ErrorMessage>
        </div>
      )}
    </Modal>
  )
}

const styles = {
  container: css`
    padding: 8px 0;
  `,
  spinner: css`
    display: flex;
    justify-content: center;
  `,
  newPlaceSelect: css`
    width: 100%;
  `,
  button: {
    paddingLeft: 0,
    paddingRight: 0,
    width: '88px',
  },
}
