import { TemplateNodeSchema } from '@ulysses-inc/harami_api_client'
import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { RootState } from 'src/state/store'
import MultipleChoiceLogic from './MultipleChoiceLogic'
import NumberConditionLogic from './NumberConditionLogic'

interface OwnProps {
  node: TemplateNodeSchema
  parentNode: TemplateNodeSchema
  path: number[]
}

const Logic: React.FC<OwnProps> = ({ node, parentNode, path }) => {
  const responseMultipleChoices = useSelector(
    (state: RootState) =>
      state.templatesState.templatePages.templateNodes[node.id]?.logic
        ?.responseMultipleChoices,
    shallowEqual,
  )

  const { id, logic, nodes } = node

  if (!logic) return null

  if (responseMultipleChoices?.length) {
    return (
      <MultipleChoiceLogic
        {...{ id, parentNode, node, nodes, path, responseMultipleChoices }}
      />
    )
  }

  const { numberConditions } = logic

  if (numberConditions?.length) {
    const numberCondition = numberConditions[0]

    if (numberCondition) {
      return (
        <NumberConditionLogic
          {...{ id, parentNode, node, path, numberCondition }}
        />
      )
    }
  }

  return null
}

export default Logic
