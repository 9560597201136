import { Spin } from 'antd'
import React from 'react'
import styled from 'styled-components'

const DisplaySpin = styled.div`
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Loading: React.FC = () => {
  return (
    <DisplaySpin>
      <Spin size="large" />
    </DisplaySpin>
  )
}

export default Loading
