import { css } from '@emotion/react'
import { FC, SVGProps } from 'react'
import { useSelector } from 'react-redux'
import DateSvg from 'src/assets/icons/date.svg?react'
import EmployeeSvg from 'src/assets/icons/employee.svg?react'
import FormulaSvg from 'src/assets/icons/formula.svg?react'
import ImageSvg from 'src/assets/icons/image.svg?react'
import InformationSvg from 'src/assets/icons/information.svg?react'
import NumberSvg from 'src/assets/icons/number.svg?react'
import SignatureSvg from 'src/assets/icons/signature.svg?react'
import TextSvg from 'src/assets/icons/text.svg?react'
import TimeMeasurement from 'src/assets/icons/time_measurement.svg?react'
import { selectIsGridLayout } from 'src/state/ducks/templates/selectors'
import { useGridLayoutFormulaAvailability } from './useGridLayoutFormulaAvailability'
import { useGridLayoutSignaturesAvailability } from './useGridLayoutSignaturesAvailability'

type MenuItem = {
  key: string
  label: string
  SvgComponent: FC<SVGProps<SVGSVGElement>>
}

const NumberItem: MenuItem = {
  key: 'number',
  label: '数値',
  SvgComponent: NumberSvg,
}

const SignatureItem: MenuItem = {
  key: 'signature',
  label: '署名',
  SvgComponent: SignatureSvg,
}

const TextItem: MenuItem = {
  key: 'text',
  label: 'テキスト',
  SvgComponent: TextSvg,
}

const ResultImageItem: MenuItem = {
  key: 'resultImage',
  label: '写真撮影',
  SvgComponent: ImageSvg,
}

const DateTimeItem: MenuItem = {
  key: 'datetime',
  label: '日時',
  SvgComponent: DateSvg,
}

const TimeMeasurementItem: MenuItem = {
  key: 'timeMeasurement',
  label: '時間計測',
  SvgComponent: TimeMeasurement,
}

const InformationItem: MenuItem = {
  key: 'information',
  label: 'インフォメーション',
  SvgComponent: InformationSvg,
}

const EmployeeItem: MenuItem = {
  key: 'employee',
  label: '従業員',
  SvgComponent: EmployeeSvg,
}

const FormulaItem: MenuItem = {
  key: 'formula',
  label: '計算式',
  SvgComponent: FormulaSvg,
}

const defaultItems: MenuItem[] = [
  NumberItem,
  SignatureItem,
  TextItem,
  ResultImageItem,
  DateTimeItem,
  InformationItem,
  EmployeeItem,
  FormulaItem,
]

const itemsForGrid: MenuItem[] = [
  NumberItem,
  TextItem,
  ResultImageItem,
  DateTimeItem,
  TimeMeasurementItem,
]

interface Props {
  onClick: (key: string) => void
}

/**
 * ひな形編集画面 -> 各質問 -> 質問種別選択 -> アイコンとラベルの集合
 * (e.g. 数値、署名、...計算式)
 */
const InputMenu = (props: Props) => {
  const { onClick } = props

  const isGridLayout = useSelector(selectIsGridLayout)
  const isGridLayoutFormulaAvailable = useGridLayoutFormulaAvailability()
  const isGridLayoutSignaturesAvailable = useGridLayoutSignaturesAvailability()

  const getItemsForGrid = () => {
    const result = [...itemsForGrid]

    // TODO: 最終的に定数自体に計算式を追加する
    if (isGridLayoutFormulaAvailable) {
      result.push(FormulaItem)
    }

    // TODO: 最終的に定数自体に署名を追加する
    if (isGridLayoutSignaturesAvailable) {
      result.push(SignatureItem)
    }

    return result
  }
  const items = isGridLayout ? getItemsForGrid() : defaultItems

  return items.map(({ key, label, SvgComponent }) => (
    <div css={styles.container} key={key} onClick={() => onClick(key)}>
      <SvgComponent width={30} height={30} />
      <div css={styles.label}>{label}</div>
    </div>
  ))
}

const styles = {
  container: css`
    align-items: center;
    cursor: pointer;
    display: flex;
    height: 40px;
    margin: 5px;
    padding: 0 16px;
  `,
  label: css`
    margin-left: 10px;
  `,
}

export default InputMenu
