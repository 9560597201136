import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
  InboxOutlined,
} from '@ant-design/icons'
import { IndicatedIssueStatusEnum } from '@ulysses-inc/harami_api_client'
import { Danger, Success, Warning } from 'src/features/theme/KdsThemeColor'

export const getStatusName = (
  isAuditorWorking: boolean,
  rejectExists: boolean,
  reviseExists: boolean,
  isRejectEditStatus: boolean,
  status?: IndicatedIssueStatusEnum,
): string => {
  switch (status) {
    case IndicatedIssueStatusEnum.Incomplete:
      if (isAuditorWorking) {
        if (isRejectEditStatus) {
          return '差し戻し'
        }
        if (reviseExists) {
          return '改善確認中'
        }
        return ''
      }
      if (rejectExists) {
        return '差し戻し'
      }
      return '指摘確認中'
    case IndicatedIssueStatusEnum.Completed:
      return '完了'
    default:
      return ''
  }
}

export const getStatusColor = (
  isAuditorWorking: boolean,
  isRejectEditStatus: boolean,
  status?: IndicatedIssueStatusEnum,
): string => {
  switch (status) {
    case IndicatedIssueStatusEnum.Incomplete:
      return isAuditorWorking && !isRejectEditStatus ? Danger : Warning
    case IndicatedIssueStatusEnum.Completed:
      return Success
    default:
      return ''
  }
}

export const getStatusIconComponent = (
  isAuditorWorking: boolean,
  isRejectEditStatus: boolean,
  status?: IndicatedIssueStatusEnum,
) => {
  switch (status) {
    case IndicatedIssueStatusEnum.Incomplete:
      return isAuditorWorking && !isRejectEditStatus
        ? ExclamationCircleOutlined
        : InboxOutlined
    default:
      return CheckCircleOutlined
  }
}
