import { useParams } from 'react-router-dom'
import { isNonNegativeInteger } from 'src/util/isNonNegativeInteger'
import invariant from 'tiny-invariant'

/**
 * ひな形編集画面のURLパラメータからひな形のIDを取得するカスタムフック
 */
export const useSourceTemplateIdParam = () => {
  const urlParams = useParams<{ id: string }>()

  // 新規作成時にはURLパラメータにidという値は存在しないため0になるが、それがもともとの仕様
  const sourceTemplateId = Number(urlParams.id ?? 0)

  invariant(
    isNonNegativeInteger(sourceTemplateId),
    '指定されたひな形のIDが0以上の整数ではありません',
  )

  return sourceTemplateId
}
