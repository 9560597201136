// prettier-ignore
export const ActionTypes =  {
  REQUEST_GET_MULTIPLE_CHOICE_SETS: 'harami/multipleChoiceSets/REQUEST_GET_MULTIPLE_CHOICE_SETS',
  SUCCESS_GET_MULTIPLE_CHOICE_SETS: 'harami/multipleChoiceSets/SUCCESS_GET_MULTIPLE_CHOICE_SETS',
  ERROR_GET_MULTIPLE_CHOICE_SETS: 'harami/multipleChoiceSets/ERROR_GET_MULTIPLE_CHOICE_SETS',
  REQUEST_CHANGE_MULTIPLE_CHOICE_SETS_PAGE: 'harami/multipleChoiceSets/REQUEST_CHANGE_MULTIPLE_CHOICE_SETS_PAGE',
  REQUEST_CHANGE_MULTIPLE_CHOICE_SETS_SIZE: 'harami/multipleChoiceSets/REQUEST_CHANGE_MULTIPLE_CHOICE_SETS_SIZE',

  REQUEST_GET_MULTIPLE_CHOICE_SET: 'harami/multipleChoiceSets/REQUEST_GET_MULTIPLE_CHOICE_SET',
  SUCCESS_GET_MULTIPLE_CHOICE_SET: 'harami/multipleChoiceSets/SUCCESS_GET_MULTIPLE_CHOICE_SET',
  ERROR_GET_MULTIPLE_CHOICE_SET: 'harami/multipleChoiceSets/ERROR_GET_MULTIPLE_CHOICE_SET',

  REQUEST_DELETE_MULTIPLE_CHOICE_SET: 'harami/multipleChoiceSets/REQUEST_DELETE_MULTIPLE_CHOICE_SET',
  SUCCESS_DELETE_MULTIPLE_CHOICE_SET: 'harami/multipleChoiceSets/SUCCESS_DELETE_MULTIPLE_CHOICE_SET',
  ERROR_DELETE_MULTIPLE_CHOICE_SET: 'harami/multipleChoiceSets/ERROR_DELETE_MULTIPLE_CHOICE_SET',

  REQUEST_ADD_MULTIPLE_CHOICE_SET: 'harami/multipleChoiceSets/REQUEST_ADD_MULTIPLE_CHOICE_SET',
  SUCCESS_ADD_MULTIPLE_CHOICE_SET: 'harami/multipleChoiceSets/SUCCESS_ADD_MULTIPLE_CHOICE_SET',
  ERROR_ADD_MULTIPLE_CHOICE_SET: 'harami/multipleChoiceSets/ERROR_ADD_MULTIPLE_CHOICE_SET',

  REQUEST_UPDATE_MULTIPLE_CHOICE_SET: 'harami/multipleChoiceSets/REQUEST_UPDATE_MULTIPLE_CHOICE_SET',
  SUCCESS_UPDATE_MULTIPLE_CHOICE_SET: 'harami/multipleChoiceSets/SUCCESS_UPDATE_MULTIPLE_CHOICE_SET',
  ERROR_UPDATE_MULTIPLE_CHOICE_SET: 'harami/multipleChoiceSets/ERROR_UPDATE_MULTIPLE_CHOICE_SET',

  RESET_MULTIPLE_CHOICE_SET: 'harami/multipleChoiceSets/RESET_MULTIPLE_CHOICE_SET',
} as const
