import {
  IndustryFoodTypeEnum,
  IndustryTypeEnum,
} from '@ulysses-inc/harami_api_client'
import FoodSvg from 'src/assets/icons/industry/food.svg?react'
import ManufactureSvg from 'src/assets/icons/industry/food_manufacture.svg?react'
import HotelSvg from 'src/assets/icons/industry/hotel.svg?react'
import LogisticsSvg from 'src/assets/icons/industry/logistics.svg?react'
import MachineSvg from 'src/assets/icons/industry/machine.svg?react'
import OtherSvg from 'src/assets/icons/industry/other.svg?react'
import LayoutScrollOneSvg from 'src/assets/icons/layout_scroll_1.svg?react'
import LayoutScrollTwoSvg from 'src/assets/icons/layout_scroll_2.svg?react'
import LayoutSlideSvg from 'src/assets/icons/layout_slide.svg?react'
import LayoutTableSvg from 'src/assets/icons/layout_table.svg?react'
import { IndustryNames } from 'src/features/templateEdit/onboarding/constants'
import { IndustryInfo } from 'src/features/templateEdit/onboardingDynamic/types'

export const NewTemplateGroups: IndustryInfo[][] = [
  [
    {
      svgDescriptionComponent: [
        LayoutScrollOneSvg,
        LayoutScrollTwoSvg,
        LayoutSlideSvg,
      ],
      title: 'スクロール・紙芝居形式のひな形',
      type: IndustryFoodTypeEnum.EMPTY,
      disabled: false,
    },
    {
      svgDescriptionComponent: [LayoutTableSvg],
      title: '表形式のひな形',
      type: IndustryFoodTypeEnum.EMPTY,
      disabled: false,
    },
  ],
]

export const IndustryGroups: IndustryInfo[][] = [
  [
    {
      svgComponent: FoodSvg,
      title: IndustryNames.FOOD,
      type: IndustryTypeEnum.FOOD,
      disabled: false,
    },
    {
      svgComponent: ManufactureSvg,
      title: IndustryNames.MANUFACTURE,
      type: IndustryTypeEnum.MANUFACTURE,
      disabled: false,
    },
    {
      svgComponent: HotelSvg,
      title: IndustryNames.HOTEL,
      type: IndustryTypeEnum.HOTEL,
      disabled: false,
    },
    {
      svgComponent: LogisticsSvg,
      title: IndustryNames.LOGISTICS,
      type: IndustryTypeEnum.LOGISTICS,
      disabled: false,
    },
    {
      svgComponent: MachineSvg,
      title: IndustryNames.MACHINE,
      type: IndustryTypeEnum.MACHINE,
      disabled: false,
    },
    {
      svgComponent: OtherSvg,
      title: IndustryNames.OTHER,
      type: IndustryTypeEnum.OTHER,
      disabled: false,
    },
    // NOTE: NDF のテンプレートは URL を直接指定した時のみ利用可能にしている
    // そのため、"テンプレートからつくる" には表示させないため
    // ここに NDF の設定は記載しない
  ],
]
