import {
  Schedule,
  ScheduleFrequencyEnum,
  ScheduleTypeEnum,
} from '@ulysses-inc/harami_api_client'
import { getHours, getMinutes, getSeconds, isAfter, set } from 'date-fns'
import { FormikProps, useFormik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import schedulesOperations from 'src/state/ducks/schedules/operations'
import { RootState } from 'src/state/store'
import { isNullish } from 'src/util/isNullish'
import { ScheduleForm } from './EditSchedule'
import { flattenNodes, isPlace, validationSchema } from './EditScheduleValue'

export const useFormikProps = (): FormikProps<ScheduleForm> => {
  const dispatch = useDispatch()
  const scheduleId = useParams<{ scheduleId?: string }>().scheduleId ?? ''
  const placeNodes = useSelector(
    (state: RootState) => state.placesState.placeGroups.nodes,
  )
  const userGroups = useSelector(
    (state: RootState) => state.usersState.userGroups.userGroups,
  )
  const schedule = useSelector(
    (state: RootState) => state.schedulesState.schedule.schedule,
  )
  const isLoadingSchedule = useSelector(
    (state: RootState) => state.schedulesState.schedule.isLoading,
  )
  const isLoadingUpdateSchedule = useSelector(
    (state: RootState) => state.schedulesState.updateSchedule.isLoading,
  )
  const isLoadingAddSchedule = useSelector(
    (state: RootState) => state.schedulesState.addSchedule.isLoading,
  )
  const isLoadingPlaceNodes = useSelector(
    (state: RootState) => state.placesState.placeGroups.isLoading,
  )

  const createInitialValues = (isUpdate: boolean) => {
    // TODO 以下 initialValues を作るために本当に必要なのか精査する
    // especially about flags
    const stateProps = {
      scheduleId,
      placeNodes,
      userGroups,
      schedule,
      isLoadingSchedule,
      isLoadingUpdateSchedule,
      isLoadingAddSchedule,
      isLoadingPlaceNodes,
    }

    const newValues: ScheduleForm = {
      name: '',
      templates: [],
      placeNodes: [],
      type: ScheduleTypeEnum.Date,
      userGroups: [],
      frequency: undefined,
      frequencyWeekly: {
        every: undefined,
        dayOfWeeks: [],
      },
      frequencyMonthly: {
        every: undefined,
      },
      frequencyCustom: {
        every: undefined,
      },
      startReportDate: undefined,
      endReportDate: undefined,
      visibleEndReportDate: false,
      isActive: true,
      isCopied: false,
    }

    return isUpdate
      ? {
          id: stateProps.schedule.id ?? 0,
          name: stateProps.schedule.name ?? '',
          templates: stateProps.schedule.templates,
          placeNodes:
            stateProps.schedule.placeNodes?.map(
              placeNode => `${placeNode.uuid}`,
            ) ?? [],
          type: stateProps.schedule.type,
          frequency: stateProps.schedule.frequency,
          frequencyWeekly: stateProps.schedule.frequencyWeekly,
          frequencyMonthly: stateProps.schedule.frequencyMonthly,
          frequencyCustom: stateProps.schedule.frequencyCustom,
          startReportDate: stateProps.schedule.startReportDate,
          endReportDate: stateProps.schedule.endReportDate,
          visibleEndReportDate: !isNullish(stateProps.schedule.endReportDate),
          startTime: stateProps.schedule.startTime,
          endTime: stateProps.schedule.endTime,
          isActive: stateProps.schedule.isActive,
          userGroups: stateProps.schedule.userGroups,
          isCopied: false,
        }
      : newValues
  }

  const isUpdate = scheduleId !== undefined && scheduleId !== ''
  const initialValues: ScheduleForm = createInitialValues(isUpdate)

  return useFormik<ScheduleForm>({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values, formikHelpers) => {
      const now = new Date()
      // 終了日のhh:mm:ssを終了時間に設定し直す
      const editEndReportDate = set(values.endReportDate ?? now, {
        hours: getHours(values.endTime ?? now),
        minutes: getMinutes(values.endTime ?? now),
        seconds: getSeconds(values.endTime ?? now),
      })

      if (
        values.visibleEndReportDate &&
        isAfter(values.startReportDate ?? now, values.endReportDate ?? now)
      ) {
        formikHelpers.setErrors({
          endReportDate: '終了日は開始日より後ろに設定してください',
        })
        return
      }
      const schedule: Schedule = {
        ...values,
        placeNodes:
          values.placeNodes
            ?.filter((placeNodeId: string) =>
              isPlace(placeNodeId, flattenNodes(placeNodes)),
            )
            .map((placeNodeId: string) => ({ uuid: placeNodeId })) ?? [],
        frequencyWeekly:
          values.frequency === ScheduleFrequencyEnum.Weekly
            ? values.frequencyWeekly
            : undefined,
        frequencyMonthly:
          values.frequency === ScheduleFrequencyEnum.Monthly
            ? values.frequencyMonthly
            : undefined,
        frequencyCustom:
          values.frequency === ScheduleFrequencyEnum.Custom
            ? values.frequencyCustom
            : undefined,
        endReportDate:
          values.visibleEndReportDate && !!values.endReportDate
            ? editEndReportDate
            : undefined,
      }
      if (
        schedule.placeNodes === undefined ||
        schedule.placeNodes?.length === 0
      ) {
        formikHelpers.setErrors({
          placeNodes: 'アサインする現場を1つ以上設定してください',
        })
        return
      }
      if (values.isCopied) {
        schedulesOperations.copySchedule(dispatch, schedule)
        formikHelpers.setFieldValue('isCopied', false)
        return
      }
      if (isUpdate) {
        schedulesOperations.updateSchedule(dispatch, scheduleId ?? '', schedule)
      } else {
        schedulesOperations.addSchedule(dispatch, schedule)
      }
      formikHelpers.resetForm()
    },
    enableReinitialize: true,
  })
}
