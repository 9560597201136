import { useQuery } from '@tanstack/react-query'
import {
  GetScheduledReportSummariesResponse,
  ScheduleListItemsApi,
  ScheduleTypeEnum,
  ScheduledReportStatusEnum,
} from '@ulysses-inc/harami_api_client'
import { subDays } from 'date-fns'
import { queryKeys } from 'src/features/scheduledReportSummary/api/queryKeys'
import {
  ScheduledReportDailyDetail,
  ScheduledReportDailyStatus,
  ScheduledReportSummariesWithDaysInfo,
  ScheduledReportSummary,
} from 'src/features/scheduledReportSummary/types'
import { ScheduledReportSummaryFilters } from 'src/features/scheduledReportSummary/useFilters'
import { useQueryPlaceNodes } from 'src/features/tanStackQuery/commonQueries/useQueryPlaceNodes'
import { useQueryUserPlaceNodes } from 'src/features/tanStackQuery/commonQueries/useQueryUserPlaceNodes'
import { baseClient } from 'src/state/middleware/saga/baseClient'
import { stripTimeInfo } from 'src/util/datetime/stripTimeInfo'

/**
 * APIのレスポンス(実施状況のステータス)をフロントエンドで定めた形式に変換する
 */
const adaptStatus: {
  [key in ScheduledReportStatusEnum]: ScheduledReportDailyStatus
} = {
  [ScheduledReportStatusEnum.Implemented]: 'implemented',
  [ScheduledReportStatusEnum.ImplementedAfterDeadline]:
    'implementedAfterDeadline',
  [ScheduledReportStatusEnum.NotImplementedYet]: 'notImplementedYet',
  [ScheduledReportStatusEnum.NotScheduled]: 'notScheduled',
  [ScheduledReportStatusEnum.NotStarted]: 'notStarted',
  [ScheduledReportStatusEnum.DuringPeriod]: 'duringPeriod',
}

/**
 * APIのレスポンス(スケジュールタイプ)をフロントエンドで定めた形式に変換する
 */
const adoptScheduleType: {
  [key in ScheduleTypeEnum]: ScheduledReportSummary['scheduleType']
} = {
  [ScheduleTypeEnum.Date]: 'date',
  [ScheduleTypeEnum.Duration]: 'duration',
}

/**
 * APIのレスポンス(実施状況と期間情報)をフロントエンドで定めた形式に変換する
 */
const adaptResponse = (
  rawResponse: GetScheduledReportSummariesResponse,
): ScheduledReportSummariesWithDaysInfo => {
  return {
    targetDates: rawResponse.targetDates,
    summaries: rawResponse.scheduledReportSummaries.map(
      (rawSummary): ScheduledReportSummary => ({
        placeNodeUuid: rawSummary.placeNodeUUID,
        placeName: rawSummary.placeName,
        scheduleUuid: rawSummary.scheduleUUID,
        scheduleName: rawSummary.scheduleName,
        scheduleType: adoptScheduleType[rawSummary.scheduleType],
        // 今後拡張される可能性があるので可変長にしておく
        dailyDetails: rawSummary.dailyDetails.map(
          (rawDetail): ScheduledReportDailyDetail => {
            switch (rawDetail.type) {
              case ScheduleTypeEnum.Date: {
                return {
                  reportUuid: rawDetail.reportUUID,
                  type: 'date',
                  status: adaptStatus[rawDetail.status],
                }
              }
              case ScheduleTypeEnum.Duration: {
                return {
                  reportUuid: rawDetail.reportUUID,
                  type: 'duration',
                  status: adaptStatus[rawDetail.status],
                  isEndDay: rawDetail.isEndDay ?? false,
                  isStartDay: rawDetail.isStartDay ?? false,
                }
              }
              default: {
                throw new Error('想定外の値です')
              }
            }
          },
        ),
      }),
    ),
  }
}

export const useQueryScheduledReportSummariesWithDaysInfo = (
  filters: ScheduledReportSummaryFilters,
) => {
  // このコードブロックは、現場フィルタを指定しない限りはバックエンドが
  // データを返さないという問題に一時的に対応するためのもの。
  // 問題はバックエンド側で修正される予定であり、その際にはこのコードは不要になる。
  // ただし、実施状況一覧の正式リリースまでは修正作業が先送りされている。
  //
  // 参考: https://kaminashi.atlassian.net/browse/HONE-595?focusedCommentId=30999
  //
  // コードの概要:
  // 1. ユーザーが現場フィルタを指定している場合は、その現場群を採用する
  // 2. 指定されていない場合は、ユーザーにアサインされている現場群を採用する
  // 3. ユーザーにアサインされている現場がない場合は、全ての現場群を採用する
  //
  // ---- TODO: 一時的なHack Start ----
  const userPlaceNodesQuery = useQueryUserPlaceNodes()
  const allPlaceNodesQuery = useQueryPlaceNodes()
  const isFetchDone =
    !userPlaceNodesQuery.isPending &&
    !allPlaceNodesQuery.isPending &&
    !userPlaceNodesQuery.isError &&
    !allPlaceNodesQuery.isError
  const userHasSomeAssignedPlaceNodes =
    (userPlaceNodesQuery.data || []).length > 0
  const userPlaces = userHasSomeAssignedPlaceNodes
    ? userPlaceNodesQuery.data || []
    : allPlaceNodesQuery.data || []
  const filteringPlaceNodeUuids =
    filters.placeNodeUuids.length > 0
      ? filters.placeNodeUuids
      : userPlaces.map(place => place.uuid)
  // ---- TODO: 一時的なHack End ----

  return useQuery<ScheduledReportSummariesWithDaysInfo>({
    enabled: isFetchDone,
    queryFn: async () => {
      // 期間がフィルタで指定されていればその期間を、指定されていなければ直近7日間を取得対象とする
      let startDate = stripTimeInfo(subDays(new Date(), 6))
      let endDate = stripTimeInfo(new Date())
      if (filters.dateRange) {
        startDate = stripTimeInfo(new Date(filters.dateRange[0]))
        endDate = stripTimeInfo(new Date(filters.dateRange[1]))
      }

      const requestParams = {
        // APIに渡される開始日時と終了日時は、サーバサイドで日付部分のみが抽出されたうえで使用される。
        // この際、タイムゾーンは`acceptLanguage`で指定したものが採用される。
        //
        // 本来は日付と時刻の両方を含む形式（広義のdate = date-time）ではなく
        // 日付のみ含む形式（狭義のdate）で投げればすむはずだが、
        // 現在のバックエンドの仕様上、前者の形式が必須となっている。
        //
        // e.g.
        // (`2023-12-10T15:00:00Z` または `2023-12-11T00:00:00+0900`) + `Asia/Tokyo`でAPIに投げれば、
        // サーバサイドでは`2023-12-11`という*日付*として解釈される。
        acceptLanguage: 'Asia/Tokyo',
        getScheduledReportSummariesFilter: {
          placeNodeId: {
            $in: filteringPlaceNodeUuids,
          },
          startDate,
          endDate,
        },
      }

      const rawResponse = await baseClient
        .getApi(ScheduleListItemsApi)
        .getScheduledReportSummaries(requestParams)

      return adaptResponse(rawResponse)
    },
    queryKey: queryKeys.scheduledReportSummariesWithDaysInfo(filters),
  })
}
