import Icon from '@ant-design/icons'
import { Modal } from 'antd'
import ExternalLinkSvg from 'src/assets/icons/external_link.svg?react'
import styled from 'styled-components'

const ModalTitle = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 23px;
`

const Link = styled.a`
  text-decoration: underline;
`

const ModalContentWrapper = styled.div`
  margin-top: 8px;
`

const ModalLinkWrapper = styled.div`
  margin-top: 8px;
`

type Props = {
  /** 削除しようとしたページの名前 */
  pageName: string
  /** 削除しようとしたページの親にあたるひな形の名前 */
  templateName: string
  /** 削除しようとしたページの親にあたるひな形の id */
  templateId: number
}

/**
 * ページの削除が出来ない旨を示すときのダイアログを出す
 */
export const openPreventPageDeletionDialog = ({
  pageName,
  templateName,
  templateId,
}: Props) => {
  Modal.error({
    title: <ModalTitle>「{pageName}」の削除が出来ません</ModalTitle>,
    width: 417,
    content: (
      <ModalContentWrapper>
        <div>取り込み項目データがすでに登録されていたので削除できません。</div>
        <ModalLinkWrapper>
          <Link href={`/gridVariables/${templateId}/edit`} target="_blank">
            {`「${templateName}」の取り込み項目`}
            <sup style={{ paddingLeft: '4px' }}>
              <Icon component={ExternalLinkSvg} />
            </sup>
          </Link>
        </ModalLinkWrapper>
      </ModalContentWrapper>
    ),
    okText: '閉じる',
  })
}
