import { ConfigProvider } from 'antd'
import 'antd/dist/reset.css' // ブラウザごとのデフォルトスタイルをリセットする。別にantdのものでなくてもいい。
import jaJP from 'antd/locale/ja_JP' // Antd を日本語で表示するため
import 'moment/dist/locale/ja' // Antd の DatePicker の曜日等を日本語で表示するため。公式と差異がある理由はこれ-> https://qiita.com/oedkty/items/61837af79d05f2794ad9
import { ReactNode } from 'react'
import { semanticColors } from 'src/features/theme/semanticColors' // Antd のデフォルトスタイルを上書きしてカスタマイズするため

export const AntdConfigProvider = ({ children }: { children: ReactNode }) => {
  return (
    <ConfigProvider
      autoInsertSpaceInButton={false}
      locale={jaJP}
      // アプリケーション全体に適用するAntdのテーマをここで設定する。
      // 書き方は以下を参照すること。
      // https://ant.design/docs/react/customize-theme
      theme={{
        token: {
          borderRadius: 6,
          ...semanticColors,
        },
        components: {
          Dropdown: {
            paddingBlock: 10,
          },
          Table: {
            headerBg: 'white',
            rowHoverBg: semanticColors.colorFillTertiary,
            rowSelectedBg: semanticColors.colorPrimaryBg,
            selectionColumnWidth: 48,
          },
        },
      }}
    >
      {children}
    </ConfigProvider>
  )
}
