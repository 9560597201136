import { BuildOutlined } from '@ant-design/icons'
import styled from 'styled-components'

export const BuildIcon = styled(BuildOutlined)`
  margin-right: 5px;
`

export const ModalTitle = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 23px;
`
export const ModalContentWrapper = styled.div`
  margin-top: 8px;
`
