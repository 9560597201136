import { Document, Font, Page, StyleSheet, Text } from '@react-pdf/renderer'
import {
  ApprovalFlow,
  ReportNodeSchema,
  ReportStatusEnum,
  TemplateLayoutTypeEnum,
  TemplateNodeTypeEnum,
} from '@ulysses-inc/harami_api_client'
import React from 'react'
import nasuBold from 'src/assets/fonts/NasuFont/Nasu-Bold.ttf'
import nasuRegular from 'src/assets/fonts/NasuFont/Nasu-Regular.ttf'
import getCalculateScorePercentage from 'src/exShared/hooks/reports/getCalculateScorePercentage'
import { ReportResultSchema } from 'src/exShared/types/types'
import { getDescendantNodes } from 'src/exShared/util/nodes/getDescendantNodes'
import { isInvalid } from 'src/exShared/util/report/isInvalid'
import BreakText from '../BreakText'
import Col from '../Col'
import Grid from '../Grid'
import Approvals from './Approvals'
import Overview from './Overview'
import ReportDetail from './reportDetail/ReportDetail'

interface OwnProps {
  report: ReportResultSchema
  comment: string
}

interface MergeProps {
  getDeviatedAnswers: () => ReportNodeSchema[]
}

type ReportSummaryDocumentProps = OwnProps & MergeProps

const ReportSummaryDocumentContainer: React.FC<OwnProps> = (
  _props: OwnProps,
) => {
  const mergeProps: MergeProps = {
    // Object.valuesはObjectのkeyがuint32の上限を超えると昇順が保証されなくなるため、ReportNodeIdの昇順でソートする
    // Notion: https://www.notion.so/harami_web-20231218-cb708f3ce48d482f9dc9123889fcf272
    getDeviatedAnswers: (): ReportNodeSchema[] => {
      return Object.values(_props.report.nodes)
        .filter((node: ReportNodeSchema) => {
          if (node.type !== TemplateNodeTypeEnum.Question) {
            return false
          }
          return isInvalid(node.question)
        })
        .sort((a, b) => a.id - b.id)
    },
  }
  const props = { ..._props, ...mergeProps }
  return <ReportSummaryDocument {...props} />
}

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
    fontFamily: 'Nasu',
    fontSize: 10,
  },
  scoringTitle: {
    fontSize: 14,
  },
  textPadding: {
    padding: 5,
  },
})

// NOTE: 改行時に付与される - を付与しないように設定する
// https://v2.react-pdf.org/fonts#registerHyphenationCallback
//  Disabling hyphenation
// https://github.com/diegomura/react-pdf/issues/419#issuecomment-1161308596
Font.registerHyphenationCallback(word => {
  return Array.from(word).flatMap(char => [char, ''])
})

// NOTE: 日本語fontがreact-pdfが対応されていないので、日本語fontのNasuFontを利用
Font.register({
  family: 'Nasu',
  fonts: [{ src: nasuRegular }, { src: nasuBold, fontWeight: 'bold' }],
})

const showApprovals = (
  apporovalFlows: ApprovalFlow[],
  status: ReportStatusEnum,
) => {
  if (apporovalFlows.length === 0) {
    return false
  }

  if (
    status === ReportStatusEnum.INCOMPLETE ||
    status === ReportStatusEnum.APPROVAL_REMAND
  ) {
    return false
  }

  return true
}

const ReportSummaryDocument: React.FC<ReportSummaryDocumentProps> = (
  props: ReportSummaryDocumentProps,
) => {
  const isGridLayout = props.report.layoutType === TemplateLayoutTypeEnum.Grid
  const deviatedAnswers = props.getDeviatedAnswers()
  const allChildNodes = Object.values(props.report.pages).reduce(
    (prev, cur) => {
      const { dictOfNode: pageChildNodes } = getDescendantNodes(
        cur.nodes ?? [],
        props.report.nodes,
        {
          ignoreRepeatOriginalSection: true,
        },
      )
      return Object.assign(prev, pageChildNodes)
    },
    {},
  )
  const { sumAllTotalScores, sumAllResultScores, calculateScorePercentage } =
    getCalculateScorePercentage(allChildNodes)
  return (
    <Document>
      {props.report !== undefined && (
        <Page
          size="A4"
          orientation={isGridLayout ? 'landscape' : 'portrait'}
          style={styles.body}
        >
          <Overview
            {...{
              reportName: props.report.name,
              reportDate: props.report.reportDate,
              schedules: props.report.schedules,
              userName: props.report.assignee?.name ?? '',
              isShowScore: props.report.isShowScore,
              sumAllTotalScores: sumAllTotalScores,
              sumAllResultScores: sumAllResultScores,
              score: calculateScorePercentage,
              status: props.report.status?.status ?? ReportStatusEnum.COMPLETE,
              deviatedAnswersCount: deviatedAnswers.length,
              comment: props.comment,
              placeName: props.report.place.name ?? '',
            }}
          />
          <Grid borderLeft borderRight borderBottom>
            <Col>
              <Text style={{ ...styles.textPadding }}>コメント</Text>
              <BreakText text={props.comment} />
            </Col>
          </Grid>
          {showApprovals(
            props.report.approvalFlows,
            props.report.status?.status ?? ReportStatusEnum.COMPLETE,
          ) && (
            <Approvals
              approvals={props.report.approvals}
              approvalFlows={props.report.approvalFlows ?? []}
            />
          )}
        </Page>
      )}
      <ReportDetail report={props.report} deviatedAnswers={deviatedAnswers} />
    </Document>
  )
}

export default ReportSummaryDocumentContainer
