import Icon from '@ant-design/icons'
import { ErrorCodeEnum } from '@ulysses-inc/harami_api_client'
import { Modal } from 'antd'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ExternalLinkSvg from 'src/assets/icons/external_link.svg?react'
import { useSourceTemplateIdParam } from 'src/features/templateEdit/useSourceTemplateIdParam'
import { clearAddTemplatePagesError } from 'src/state/ducks/templates/editTemplate/actions'
import { HTTPError } from 'src/state/middleware/saga/handleHttpError'
import { RootState } from 'src/state/store'
import styled from 'styled-components'
import { ErrorCodeErrorType } from '../../firebase/featureSpecificEventLog/gridLayoutSoftLimitError'
import { useGridLayoutSoftLimitErrorLog } from '../useSoftLimitErrorLog'

const ModalTitle = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 23px;
`
const ModalContentWrapper = styled.div`
  margin-top: 8px;
`

const ModalLinkWrapper = styled.div`
  margin-top: 8px;
`

const Link = styled.a`
  text-decoration: underline;
`

const toSoftLimitErrorType = (
  error: HTTPError | null,
): ErrorCodeErrorType | undefined => {
  if (!error) {
    return undefined
  }

  switch (error.code) {
    case ErrorCodeEnum.MaxSubstantialCellCountExceededInGridVariablesError:
      return 'cellCount'
    case ErrorCodeEnum.MaxRowCountExceededInGridVariablesError:
      return 'rowCount'
    case ErrorCodeEnum.MaxTableCountExceededInGridVariablesError:
      return 'tableCount'
    default:
      return undefined
  }
}

export const SoftLimitErrorDialogForGrid = () => {
  const dispatch = useDispatch()
  const error = useSelector(
    (state: RootState) => state.templatesState.editTemplate.error,
  )
  const templateId = useSourceTemplateIdParam()

  const { logSoftLimitErrorEvent } =
    useGridLayoutSoftLimitErrorLog('updateTemplate')

  useEffect(() => {
    // ソフトリミットエラーになったらエラーダイアログを出す
    const errorType = toSoftLimitErrorType(error)
    const errorMessage = error?.errors?.[0] || ''
    if (!!templateId && errorType) {
      Modal.error({
        title: <ModalTitle>ひな形を保存できませんでした</ModalTitle>,
        width: 417,
        content: (
          <ModalContentWrapper>
            <div>{errorMessage}</div>
            <ModalLinkWrapper>
              <Link href={`/gridVariables/${templateId}/edit`} target="_blank">
                {`取り込み項目の設定`}
                <sup style={{ paddingLeft: '4px' }}>
                  <Icon component={ExternalLinkSvg} />
                </sup>
              </Link>
            </ModalLinkWrapper>
          </ModalContentWrapper>
        ),
        okText: '閉じる',
        onOk: () => dispatch(clearAddTemplatePagesError()),
      })

      logSoftLimitErrorEvent(errorType, errorMessage)
    }
  }, [error, dispatch, templateId, logSoftLimitErrorEvent])

  return <></>
}
