import { StyleSheet, View } from '@react-pdf/renderer'
import { Image } from '@ulysses-inc/harami_api_client'
import React from 'react'
import Grid from '../../Grid'
import OptionImages from './OptionImages'
import OptionManualMemo from './OptionManualMemo'

const styles = StyleSheet.create({
  tableRow: {
    marginTop: 10,
    width: '100%',
    flexDirection: 'row',
  },
})

interface OwnProps {
  images?: Array<Image>
  manualMemos?: Array<Image>
}

const ReportOptions: React.FC<OwnProps> = (props: OwnProps) => {
  return (
    <>
      <View style={styles.tableRow}>
        <Grid>
          {props.images !== undefined && props.images.length > 0 && (
            <OptionImages images={props.images} />
          )}
        </Grid>
      </View>
      <View style={styles.tableRow}>
        <Grid>
          {props.manualMemos !== undefined &&
            props.manualMemos.length > 0 &&
            props.manualMemos[0] && (
              <OptionManualMemo memo={props.manualMemos[0]} />
            )}
        </Grid>
      </View>
    </>
  )
}

export default ReportOptions
