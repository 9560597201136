import { StyleSheet, Text, View } from '@react-pdf/renderer'
import { FormulaValue, ResponseFormula } from '@ulysses-inc/harami_api_client'
import React from 'react'
import { convertRuleCharacters } from 'src/exShared/util/numberRule/convertDisplayCharacters'
import BreakText from '../../BreakText'
import Col from '../../Col'
import Grid from '../../Grid'
import RecordedTime from './RecordedTime'

interface OwnProps {
  questionName: string
  questionScale?: string
  responseFormula?: ResponseFormula
  formulaValue?: FormulaValue
  recordedAt?: Date
  isTimeDisplayed: boolean
}

const styles = StyleSheet.create({
  gridLeft: { width: '70%' },
  gridRight: { width: '30%' },
  numberGrid: { width: '100%' },
  answerNumberValueWrap: { width: '100%', marginBottom: 5 },
  answerNumberValueText: { paddingLeft: 5, paddingTop: 5 },
  answerNumberRule: { color: '#656565', paddingLeft: 5 },
  recordedTimeWrap: { width: '100%', marginBottom: 5 },
})

const Formula: React.FC<OwnProps> = (props: OwnProps) => {
  return (
    <Grid style={styles.numberGrid}>
      <Col borderLeft borderRight style={styles.gridLeft}>
        <BreakText text={props.questionName} />
      </Col>
      <Col borderRight style={{ ...styles.gridRight }}>
        <View style={styles.answerNumberValueWrap}>
          <Text style={styles.answerNumberValueText}>
            {`回答: ${
              props.formulaValue?.formulaValue !== undefined
                ? `${props.formulaValue?.formulaValue} ${
                    props.questionScale ?? ''
                  }`
                : '未回答'
            }`}
          </Text>
          {props.responseFormula !== undefined &&
            props.responseFormula.subType !== undefined && (
              <Text style={styles.answerNumberRule}>
                (
                {convertRuleCharacters(
                  props.questionScale ?? '',
                  props.responseFormula,
                )}
                )
              </Text>
            )}
        </View>
        <View style={styles.recordedTimeWrap}>
          <RecordedTime
            isTimeDisplayed={props.isTimeDisplayed}
            recordedAt={props.recordedAt}
          />
        </View>
      </Col>
    </Grid>
  )
}

export default Formula
