import { Popover, Typography } from 'antd'
import { FC, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { templateIconTypes } from 'src/constants/templateIconTypes'
import { RootState } from 'src/state/store'
import {
  BasicRow,
  FormLabel,
  PlusIcon,
  PlusIconWrapper,
  PopoverIcons,
  TemplateIcons,
} from './EditTemplateOption.styled'
import PopoverIcon from './PopoverIcon'
import TemplateIcon from './TemplateIcon'

const { Text } = Typography

const IconRow: FC = () => {
  const templateIcons = useSelector(
    (state: RootState) => state.templatesState.editTemplate.icons,
    shallowEqual,
  )
  const activeIconState = useState<number>(0)

  const selectedTemplateIconLength = templateIcons.length ?? 0

  return (
    <BasicRow>
      <FormLabel>
        <Text strong>ひな形の種類</Text>
      </FormLabel>
      <TemplateIcons>
        {templateIcons &&
          templateIcons.map((selectedTemplateIcon, i) => (
            <TemplateIcon
              key={i}
              {...{ activeIconState, selectedTemplateIcon }}
            />
          ))}
        <Popover
          trigger="click"
          placement="right"
          content={
            <PopoverIcons>
              {templateIconTypes.map((templateIcon, i) => (
                <PopoverIcon key={i} {...{ templateIcon }} />
              ))}
            </PopoverIcons>
          }
        >
          <PlusIconWrapper
            theme={{ marginLeft: selectedTemplateIconLength ? 24 : 0 }}
            data-testid="add-template-type-icon"
          >
            <PlusIcon />
          </PlusIconWrapper>
        </Popover>
      </TemplateIcons>
    </BasicRow>
  )
}

export default IconRow
