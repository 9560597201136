import { css } from '@emotion/react'
import React, { DetailedHTMLProps, HTMLAttributes } from 'react'

// reactで定義されている型をそのまま流用
type DivProps = DetailedHTMLProps<
  HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>

export const View: React.FC<DivProps> = props => {
  return (
    <div
      css={css({
        // 以下はreact-native-webのViewの設定をまるっとコピペしたもの
        // https://github.com/necolas/react-native-web/blob/e8098fd029102d7801c32c1ede792bce01808c00/packages/react-native-web/src/exports/View/index.js#L151-L166
        alignItems: 'stretch',
        backgroundColor: 'transparent',
        border: '0 solid black',
        boxSizing: 'border-box',
        display: 'flex',
        flexBasis: 'auto',
        flexDirection: 'column',
        flexShrink: 0,
        listStyle: 'none',
        margin: 0,
        minHeight: 0,
        minWidth: 0,
        padding: 0,
        position: 'relative',
        textDecoration: 'none',
        zIndex: 0,
      })}
      {...props}
    />
  )
}
