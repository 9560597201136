import { EyeOutlined } from '@ant-design/icons'
import { Image } from '@ulysses-inc/harami_api_client'
import { CSSProperties, FC, useMemo } from 'react'
import { White } from 'src/features/theme/KdsThemeColor'
import { TableBodyCellWrapper } from './Common.dumb'
import {
  ImageListWrapper,
  ImageRowWrapper,
  ImageWrapper,
  ThumbCover,
} from './TableBodyCellResultImage.dumb'

type Props = {
  value?: Image[]
  goPreviewPhoto: (imageUrls: string[], selectedImageIndex: number) => void
}

/**
 * サムネイルを２列表示するために、行ごとの配列に変換する
 * @param resultImages
 * @returns
 */
const createImageRowArray = (resultImages: Image[]) => {
  const ret: Image[][] = []
  resultImages.map((image, index) => {
    if (index % 2 === 0) {
      const row: Image[] = []
      row.push(image)
      ret.push(row)
    } else {
      const row = ret[ret.length - 1]
      row?.push(image)
    }
  })
  return ret
}

type ThumbProps = {
  image: Image | undefined
  preview: () => void
  wrapperStyle?: CSSProperties
}

/**
 * １つの写真サムネイルを表すコンポーネント
 *
 * @param param0
 * @returns
 */
const Thumb: FC<ThumbProps> = ({ image, preview, wrapperStyle }) => {
  return (
    <ImageWrapper style={wrapperStyle} onClick={preview}>
      <img
        alt={image?.name}
        src={image?.url}
        style={{
          height: '34px',
          width: 'auto',
        }}
      />
      <ThumbCover>
        <EyeOutlined style={{ fontSize: '16px', color: White }} />
      </ThumbCover>
    </ImageWrapper>
  )
}

export const TableBodyCellResultImage: FC<Props> = ({
  value,
  goPreviewPhoto,
}) => {
  const imageRows = useMemo(() => createImageRowArray(value ?? []), [value])
  const imageSrcs = useMemo(
    () => (value || []).map(image => image.url || ''),
    [value],
  )

  if (!value) {
    return <TableBodyCellWrapper />
  }

  const preview = (imageIndex: number) => goPreviewPhoto(imageSrcs, imageIndex)

  return (
    <TableBodyCellWrapper style={{ width: '120px', padding: 0 }}>
      <ImageListWrapper>
        {imageRows.map((imageRow, index) => {
          const resultImageLeft = imageRow[0]
          const resultImageRight = imageRow[1]
          return (
            <ImageRowWrapper
              key={index}
              style={{
                marginBottom: index === imageRows.length - 1 ? 0 : 4,
              }}
            >
              <Thumb
                image={resultImageLeft}
                preview={() => preview(index * 2)}
              />
              {resultImageRight && (
                <Thumb
                  image={resultImageRight}
                  preview={() => preview(index * 2 + 1)}
                  wrapperStyle={{
                    marginLeft: '4px',
                  }}
                />
              )}
            </ImageRowWrapper>
          )
        })}
      </ImageListWrapper>
    </TableBodyCellWrapper>
  )
}
