import { Col, Row } from 'antd'
import { FC, useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import approvalFlowsOperations from 'src/state/ducks/approvalFlows/operations'
import placesOperations from 'src/state/ducks/places/operations'
import usersOperations from 'src/state/ducks/users/operations'
import { RootState } from 'src/state/store'
import ApprovalFlowRow from './ApprovalFlowRow'
import { OptionRow } from './EditTemplateOption.styled'
import IconRow from './IconRow'
import NameRow from './NameRow'
import PlaceRow from './PlaceRow'

const EditTemplateOption: FC = () => {
  const approvalFlows = useSelector(
    (state: RootState) => state.approvalFlowsState.approvalFlows.approvalFlows,
    shallowEqual,
  )
  const templatePlaceNodes = useSelector(
    (state: RootState) => state.placesState.placeNodes.nodes,
    shallowEqual,
  )

  const dispatch = useDispatch()

  useEffect(() => {
    approvalFlowsOperations.getApprovalFlows(dispatch)
    usersOperations.getUsers(dispatch, {})
    placesOperations.getPlaceNodes(dispatch)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch])

  return (
    <Row>
      <Col span={24}>
        <OptionRow>
          <Col span={12}>
            <NameRow />
            <IconRow />
            {approvalFlows.length > 0 && <ApprovalFlowRow />}
            {
              /* type 的にはこれいつでも true だけど大丈夫？ */ templatePlaceNodes && (
                <PlaceRow />
              )
            }
          </Col>
        </OptionRow>
      </Col>
    </Row>
  )
}

export default EditTemplateOption
