import { localStorageKeys } from 'src/constants/localStorageKeys'
import date from 'src/exShared/util/date'
import { LocalStorageService } from '../../libraries/localStorage'
import {
  ApprovalReportsFilter,
  ListFilter,
} from '../../views/types/utils/ListFilter'

export const useApprovalReportsListFilter = () => {
  const localStorageService = new LocalStorageService<ListFilter>()
  const requests = localStorageService.getItem(localStorageKeys.listFilter)
  const approvalReportsRequest = requests?.approvalReports

  const setListFilter = (requests: ListFilter) => {
    localStorageService.setItem(localStorageKeys.listFilter, requests)
  }

  const saveApprovalReportsRequest = (conditions: ApprovalReportsFilter) => {
    const updatedFilters =
      requests === null
        ? {
            approvalReports: {
              filter: conditions,
            },
          }
        : {
            ...requests,
            approvalReports: {
              pagination: requests.approvalReports?.pagination,
              filter: conditions,
            },
          }
    setListFilter(updatedFilters)
  }

  return { approvalReportsRequest, saveApprovalReportsRequest }
}

export const getApprovalReportsFilter = (
  conditions: ApprovalReportsFilter,
  // placeNodes: PlaceNode[],
) => {
  // const allPlaceNodeIds = mergedPlaceNodeIds(
  //   placeNodes,
  //   conditions.placeNodeIds ?? [],
  //   conditions.placeGroupNodeIds ?? [],
  // )
  const reportNameCondition =
    conditions.reportName && conditions.reportName.length > 0
      ? { $like: conditions.reportName }
      : undefined
  // const placeNodeIdCondition =
  //   allPlaceNodeIds && allPlaceNodeIds.length > 0
  //     ? { $in: allPlaceNodeIds }
  //     : undefined
  // throughは画面表示の日付＋１日する
  const reportDateCondition =
    conditions.from && conditions.through
      ? {
          $gte: date.parseYYYYMMDD(conditions.from),
          $lte: date.nextDay(date.parseYYYYMMDD(conditions.through)),
        }
      : undefined
  const isInvalidCondition = conditions.isInvalid
    ? { $in: [conditions.isInvalid ?? 0] }
    : undefined
  const usertCondition =
    conditions.user !== undefined && conditions.user.length > 0
      ? { $like: conditions.user }
      : undefined
  return {
    reportName: reportNameCondition,
    // placeNodeId: placeNodeIdCondition,
    reportDate: reportDateCondition,
    isInvalid: isInvalidCondition,
    assignee: {
      user: usertCondition,
    },
  }
}
