import { TemplateIcon as ITemplateIcon } from '@ulysses-inc/harami_api_client'
import { Dispatch, FC, SetStateAction } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { templateIconTypes } from 'src/constants/templateIconTypes'
import { White } from 'src/features/theme/KdsThemeColor'
import { updateTemplateIcons } from 'src/state/ducks/templates/editTemplate/actions'
import { RootState } from 'src/state/store'
import { DeleteIcon, TemplateIconWrapper } from './EditTemplateOption.styled'

const TemplateIcon: FC<{
  selectedTemplateIcon: ITemplateIcon
  activeIconState: [number, Dispatch<SetStateAction<number>>]
}> = ({
  selectedTemplateIcon: { iconType },
  activeIconState: [activeIcon, setActiveIcon],
}) => {
  const dispatch = useDispatch()
  const templateIcons = useSelector(
    (state: RootState) => state.templatesState.editTemplate.icons,
    shallowEqual,
  )

  const onClick = () => {
    dispatch(
      updateTemplateIcons(templateIcons.filter(i => i.iconType !== iconType)),
    )
    setActiveIcon(0)
  }

  const templateIcon = templateIconTypes.find(({ value }) => value === iconType)

  if (templateIcon === undefined) {
    return <></>
  }

  const { backgroundColor, svgComponent: SVG, label } = templateIcon

  return (
    <TemplateIconWrapper
      key={iconType}
      theme={{ backgroundColor }}
      onMouseEnter={() => setActiveIcon(iconType ?? 0)}
      onMouseLeave={() => setActiveIcon(0)}
    >
      {iconType === activeIcon && <DeleteIcon type="close" onClick={onClick} />}
      <SVG width={24} height={24} fill={White} role="img" aria-label={label} />
    </TemplateIconWrapper>
  )
}

export default TemplateIcon
