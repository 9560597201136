import {
  GetImproveRequest,
  GetImprovesV2Request,
  ImproveStatusStatusEnum,
  UpdateImproveRequest,
} from '@ulysses-inc/harami_api_client'
import Redux from 'redux'
import * as actions from './actions'
import { GetImprovesFilter } from './types'

const getImprove = (dispatch: Redux.Dispatch, request: GetImproveRequest) => {
  dispatch(actions.getImprove.getRequestImprove(request.improveUUID))
}

const updateImprove = (
  dispatch: Redux.Dispatch,
  request: UpdateImproveRequest,
  message: string,
) => {
  dispatch(
    actions.updateImprove.updateRequestImprove(
      request.improve,
      request.improveUUID,
      message,
    ),
  )
}

const updateImproveStatus = (
  dispatch: Redux.Dispatch,
  improveUUID: string,
  status: ImproveStatusStatusEnum,
) => {
  dispatch(
    actions.updateImprove.updateRequestImproveStatus(improveUUID, status),
  )
}

const resetEditImprove = (dispatch: Redux.Dispatch) => {
  dispatch(actions.updateImprove.resetEditImprove())
}

const getImproves = (
  dispatch: Redux.Dispatch,
  request: GetImprovesV2Request,
  filter?: GetImprovesFilter,
) => {
  dispatch(actions.improves.getImproves(request, filter))
}

const changeImprovesPage = (
  dispatch: Redux.Dispatch,
  page: number,
  pageSize?: number,
  filter?: GetImprovesFilter,
) => {
  dispatch(actions.improves.changeImprovesPage(page, pageSize, filter))
}

const changeImprovesSize = (
  dispatch: Redux.Dispatch,
  pageSize: number,
  filter?: GetImprovesFilter,
) => {
  dispatch(actions.improves.changeImprovesSize(pageSize, filter))
}

const updatePagination = (
  dispatch: Redux.Dispatch,
  limit: number,
  offset: number,
) => {
  dispatch(actions.improves.updatePagination(limit, offset))
}

const deleteImprove = (dispatch: Redux.Dispatch, improveUUID: string) => {
  dispatch(actions.improves.deleteImprove(improveUUID))
}

export default {
  getImprove,
  updateImprove,
  updateImproveStatus,
  getImproves,
  deleteImprove,
  changeImprovesPage,
  changeImprovesSize,
  updatePagination,
  resetEditImprove,
}
