import { css } from '@emotion/react'
import { ReactNode } from 'react'

type Props = {
  children: ReactNode
  title: string
}

export const ReportListFiltersDrawerFieldset = ({ children, title }: Props) => {
  return (
    <fieldset css={styles.container}>
      <legend css={styles.title}>{title}</legend>
      {children}
    </fieldset>
  )
}

const styles = {
  container: css`
    flex-direction: column;
  `,
  title: css`
    font-size: 15px;
    font-weight: 700;
    line-height: 1;
    margin-bottom: 13px;
  `,
}
