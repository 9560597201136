import {
  ExcelConversionLogsApi,
  GetExcelConversionLogsResponse,
  GetExcelConversionLogsV2Request,
} from '@ulysses-inc/harami_api_client'
import { call, put, takeEvery } from 'redux-saga/effects'
import BaseClient from '../../middleware/saga/baseClient'
import {
  HTTPError,
  handleHTTPError,
} from '../../middleware/saga/handleHttpError'
import { downloadFile } from '../download/sagas'
import interceptionsActions from '../interceptions/actions'
import { default as actions } from './actions'
import { ActionTypes } from './types'

const baseClient = new BaseClient()

const getExcelConversionLogsV2Request = (
  req: GetExcelConversionLogsV2Request,
) => {
  return baseClient
    .getApi(ExcelConversionLogsApi)
    .getExcelConversionLogsV2(req)
    .then(logs => logs)
    .catch(handleHTTPError)
}

function* getExcelConversionLogs(
  action: ReturnType<typeof actions.getExcelConversionLogs>,
) {
  try {
    const params: GetExcelConversionLogsV2Request = {
      ...action.request,
      excelConversionLogFilter: { ...action.filter },
    }
    const response: GetExcelConversionLogsResponse = yield call(
      getExcelConversionLogsV2Request,
      params,
    )
    yield put(actions.getSuccessExcelConversionLogs(response))
  } catch (error) {
    yield put(interceptionsActions.handleHttpError(error as HTTPError))
    yield put(actions.getErrorExcelConversionLogs(error as Error))
  }
}

function* getExcelConversionLogFiles(
  action: ReturnType<typeof actions.getExcelConversionLogFiles>,
) {
  try {
    yield call(
      downloadFile,
      'excelConversionLogs',
      JSON.stringify({ fileUUIDs: action.fileUUIDs }),
    )
  } catch (error) {
    yield put(interceptionsActions.handleHttpError(error as HTTPError))
  }
}

const sagas = [
  takeEvery(
    ActionTypes.REQUEST_GET_EXCEL_CONVERSION_LOGS,
    getExcelConversionLogs,
  ),
  takeEvery(
    ActionTypes.REQUEST_CHANGE_EXCEL_CONVERSION_LOGS_PAGE,
    getExcelConversionLogs,
  ),
  takeEvery(
    ActionTypes.REQUEST_CHANGE_EXCEL_CONVERSION_LOGS_SIZE,
    getExcelConversionLogs,
  ),
  takeEvery(
    ActionTypes.REQUEST_GET_EXCEL_CONVERSION_LOG_FILES,
    getExcelConversionLogFiles,
  ),
]

export default sagas
