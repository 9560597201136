// templates
import {
  MultipleChoiceSet,
  PlaceNode,
  TemplateHint,
  TemplateIcon,
  TemplateLayoutTypeEnum,
  TemplateManual,
  TemplateNodeSchema,
  TemplatePageSchema,
} from '@ulysses-inc/harami_api_client'

// prettier-ignore
export const ActionTypes = {
  // template pages
  REQUEST_ADD_TEMPLATE_PAGES: 'harami/templates/REQUEST_ADD_TEMPLATE_PAGES',
  SUCCESS_ADD_TEMPLATE_PAGES: 'harami/templates/SUCCESS_ADD_TEMPLATE_PAGES',
  ERROR_ADD_TEMPLATE_PAGES: 'harami/templates/ERROR_ADD_TEMPLATE_PAGES',
  REQUEST_GET_TEMPLATE_PAGES: 'harami/templates/REQUEST_GET_TEMPLATE_PAGES',
  SUCCESS_GET_TEMPLATE_PAGES: 'harami/templates/SUCCESS_GET_TEMPLATE_PAGES',
  ERROR_GET_TEMPLATE_PAGES: 'harami/templates/ERROR_GET_TEMPLATE_PAGES',
  RESET_TEMPLATE_PAGES: 'harami/templates/RESET_TEMPLATE_PAGES',
  CHANGE_ACTIVE_PAGE_ID: 'harami/templates/CHANGE_ACTIVE_PAGE_ID',
  CHANGE_ACTIVE_NODE_ID: 'harami/templates/CHANGE_ACTIVE_NODE_ID',
  ADD_TEMPLATE_NODE: 'harami/templates/ADD_TEMPLATE_NODE',
  ADD_TEMPLATE_NODE_INTO_PAGE: 'harami/templates/ADD_TEMPLATE_NODE_INTO_PAGE',
  ADD_TEMPLATE_NODE_INTO_NODE: 'harami/templates/ADD_TEMPLATE_NODE_INTO_NODE',
  ADD_TEMPLATE_MULTIPLE_CHOICE_LOGIC: 'harami/templates/ADD_TEMPLATE_MULTIPLE_CHOICE_LOGIC',
  UPDATE_TEMPLATE_MULTIPLE_CHOICE_LOGIC: 'harami/templates/UPDATE_TEMPLATE_MULTIPLE_CHOICE_LOGIC',
  ADD_TEMPLATE_NUMBER_LOGIC: 'harami/templates/ADD_TEMPLATE_NUMBER_LOGIC',
  UPDATE_TEMPLATE_NUMBER_LOGIC: 'harami/templates/UPDATE_TEMPLATE_NUMBER_LOGIC',
  ADD_TEMPLATE_PAGE: 'harami/templates/ADD_TEMPLATE_PAGE',
  DELETE_TEMPLATE_PAGE: 'harami/templates/DELETE_TEMPLATE_PAGE',
  UPDATE_TEMPLATE_PAGE: 'harami/templates/UPDATE_TEMPLATE_PAGE',
  COPY_TEMPLATE_PAGE: 'harami/templates/COPY_TEMPLATE_PAGE',
  START_COPY_TEMPLATE_PAGE: 'harami/templates/START_COPY_TEMPLATE_PAGE',
  CLEAR_COPY_TEMPLATE_PAGE_ERROR: 'harami/templates/CLEAR_COPY_TEMPLATE_PAGE_ERROR',
  UPDATE_TEMPLATE_NODE: 'harami/templates/UPDATE_TEMPLATE_NODE',
  MOVE_TEMPLATE_NODE: 'harami/templates/MOVE_TEMPLATE_NODE',
  CHANGE_QUESTION_TO_NEXT: 'harami/templates/CHANGE_QUESTION_TO_NEXT',
  CHANGE_SECTION_TO_NEXT: 'harami/templates/CHANGE_SECTION_TO_NEXT',
  CHANGE_LOGIC_TO_NEXT: 'harami/templates/CHANGE_LOGIC_TO_NEXT',
  DELETE_TEMPLATE_NODE: 'harami/templates/DELETE_TEMPLATE_NODE',
  ADD_SECTION_TO_PAGE: 'harami/templates/ADD_SECTION_TO_PAGE',
  ADD_SECTION_TO_SECTION: 'harami/templates/ADD_SECTION_TO_SECTION',
  UPDATE_TEMPLATE_NODE_MULTIPLE_CHOICE: 'harami/templates/UPDATE_TEMPLATE_NODE_MULTIPLE_CHOICE',
  SET_TEMPLATE_PAGE_ERROR_MESSAGE: 'harami/templates/SET_TEMPLATE_PAGE_ERROR_MESSAGE',
  MOVE_TEMPLATE_PAGE: 'harami/templates/MOVE_TEMPLATE_PAGE',
  REQUEST_UPLOAD_INFORMATION_FILES: 'harami/templates/REQUEST_UPLOAD_INFORMATION_FILES',
  SUCCESS_UPLOAD_INFORMATION_FILES: 'harami/templates/SUCCESS_UPLOAD_INFORMATION_FILES',
  ERROR_UPLOAD_INFORMATION_FILES: 'harami/templates/ERROR_UPLOAD_INFORMATION_FILES',
  ADD_GRID_SECTION_INTO_ACTIVE_PAGE:'harami/templates/ADD_GRID_SECTION_INTO_ACTIVE_PAGE',
} as const

export interface TemplateArgs {
  templatePageIds: Array<number>
  templatePages: { [key: number]: TemplatePageSchema }
  templateNodes: { [key: number]: TemplateNodeSchema }
  templateHints: Array<TemplateHint>
  name: string
  isShowScore: boolean
  isEditable: boolean
  isExcelConversion: boolean
  isHideQuestionOptions: boolean
  isAudit: boolean
  isKeyboard: boolean
  layoutType: TemplateLayoutTypeEnum
  hasVariables: boolean
  manuals: Array<TemplateManual>
  icons: Array<TemplateIcon>
  multipleChoiceSets: Array<MultipleChoiceSet>
  approvalFlowId: number
  placeNodes: Array<PlaceNode>
}
