import { useCallback, useRef } from 'react'

type Debounce = (fn: () => void) => void

export const useDebounce = (delay: number): Debounce => {
  const timer = useRef<ReturnType<typeof setTimeout> | null>(null)
  const debounce: Debounce = useCallback(
    fn => {
      if (timer.current) {
        clearTimeout(timer.current)
      }
      timer.current = setTimeout(() => {
        fn()
      }, delay)
    },
    [delay],
  )
  return debounce
}
