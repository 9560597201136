import { useEffect, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import usePrevious from 'src/exShared/hooks/usePrevious'
import { RootState } from '../../state/store'

export const useIsBulkApproving = () => {
  const isApiRequesting = useSelector(
    (state: RootState) => state.approvalsState.bulkApprove.isLoading,
    shallowEqual,
  )
  const [isBulkApproving, setIsBulkApproving] = useState(false)
  const prevIsApiRequesting = usePrevious(isApiRequesting)

  useEffect(() => {
    if (prevIsApiRequesting === true) {
      // isBulkApprovingはtrueからfalseになった状態、つまりAPIリクエストが終わった時
      setIsBulkApproving(false)
    }
  }, [prevIsApiRequesting])

  return { isBulkApproving, setIsBulkApproving }
}
