/* こちらの onboardingDynamic はダイナミック帳票が正式リリースされるまでのトライアル版として作成している
  正式リリースの際に、こちらのファイル群の onboarding との置き換えを行う
*/

import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import TemplateHeader from 'src/features/templateEdit/onboarding/TemplateHeader'
import {
  IndustryGroups,
  NewTemplateGroups,
} from 'src/features/templateEdit/onboardingDynamic/ShowIndustryInfo'
import { IndustryInfo } from 'src/features/templateEdit/onboardingDynamic/types'
import SelectGridVariableModal from './SelectGridVariableModal'
import ShowTemplateIndustryType from './ShowIndustryType'
import ShowNewTemplateType from './ShowNewTemplateType'

export const CreateTemplateIndustryScene = () => {
  const history = useHistory()

  const [
    isSelectGridVariableModalVisible,
    setIsSelectGridVariableModalVisible,
  ] = useState(false)

  const onBack = () => {
    history.push('/templates')
  }

  const onClick = (industry: IndustryInfo) => {
    switch (industry.title) {
      case 'スクロール・紙芝居形式のひな形': {
        // 空のテンプレート
        history.push('/templates/pages/add')
        break
      }
      case '表形式のひな形': {
        // ダイナミック帳票テンプレート
        setIsSelectGridVariableModalVisible(true)
        break
      }
      default: {
        history.push(`/templates/create/type?industry=${industry.type}`)
      }
    }
  }

  const title = 'ひな形をお選びください'
  const subTitle =
    '空のひな形もしくは業界ごとのおすすめテンプレートから作成してください。'
  const backTitle = '戻る'
  const showIndustries = IndustryGroups
  const showNewTemplates = NewTemplateGroups

  return (
    <div>
      <TemplateHeader
        title={title}
        subTitle={subTitle}
        backTitle={backTitle}
        onBack={onBack}
      />
      <ShowNewTemplateType
        showNewTemplates={showNewTemplates}
        onClick={onClick}
      />
      <ShowTemplateIndustryType
        showIndustries={showIndustries}
        onClick={onClick}
      />
      {isSelectGridVariableModalVisible && (
        <SelectGridVariableModal
          onCancel={() => setIsSelectGridVariableModalVisible(false)}
        />
      )}
    </div>
  )
}

export default CreateTemplateIndustryScene
