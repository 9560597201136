import { RootState } from 'src/state/store'

export const selectQuestionNameByUuid = (state: RootState, uuid: string) => {
  if (uuid === '') return ''

  const node = Object.values(state.reportsState.reportResult.report.nodes).find(
    node => node.uuid === uuid,
  )

  return node?.question?.name || ''
}
