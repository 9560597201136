import { PlaceNode } from '@ulysses-inc/harami_api_client'
import { GetTemplatesFilter } from 'src/exShared/types/types'
import { FlatNode } from 'src/exShared/util/place/flattenNodePath'
import { mergedPlaceNodeIds } from 'src/exShared/util/place/placeNode'
import { splitLoginPlaceUUIDs } from 'src/exShared/util/place/splitLoginPlace'
import { TemplatesFilter } from 'src/views/types/utils/ListFilter'

const deleteDuplicateUUIDs = (UUIDs: string[]) => {
  return UUIDs.filter((uuid, i, self) => self.indexOf(uuid) === i)
}

/**
 * 検索元の現場情報一覧のうち、絞り込みたい現場のIDのみ取得する
 * その際に親の現場のIDも一緒に一覧に付与する
 * @param flattenNodePath 取得元となる全現場情報
 * @param placeNodeUUIDs 絞り込みたい現場のID
 * @returns 絞り込みたい現場IDおよび親の現場IDの一覧
 */
const mergePlaceNodeUUIDs = (
  flattenNodePath: FlatNode[],
  placeNodeUUIDs: string[],
) => {
  let parentPlaceUUIDs: string[] = []
  flattenNodePath.forEach((flatNode: FlatNode) => {
    if (placeNodeUUIDs.some(uuid => uuid === flatNode.uuid)) {
      // pathには親のID一覧が入っているため、それも追加する
      parentPlaceUUIDs = [...parentPlaceUUIDs, flatNode.uuid, ...flatNode.path]
    }
  })
  return parentPlaceUUIDs
}

const getFilter = (
  placeNodes: PlaceNode[],
  flattenNodePath: FlatNode[],
  conditions: TemplatesFilter,
): GetTemplatesFilter => {
  const templateNameCondition =
    conditions.templateName !== undefined && conditions.templateName.length > 0
      ? { $like: conditions.templateName }
      : undefined

  const { loginPlaceGroupIds, loginPlaceIds } = splitLoginPlaceUUIDs()

  let placeNodeIdCondition = undefined
  const allPlaceNodeIds = mergedPlaceNodeIds(
    placeNodes,
    conditions.placeNodeIds || [],
    conditions.placeGroupNodeIds || [],
    true,
  )
  if (allPlaceNodeIds.length === 0) {
    const allLoginPlaceNodeIds = mergedPlaceNodeIds(
      placeNodes,
      loginPlaceIds || [],
      loginPlaceGroupIds || [],
      true,
    )
    const parentLoginPlaceNodeUUIDs = mergePlaceNodeUUIDs(
      flattenNodePath,
      allLoginPlaceNodeIds,
    )
    if (
      allLoginPlaceNodeIds.length > 0 ||
      parentLoginPlaceNodeUUIDs.length > 0
    ) {
      placeNodeIdCondition = {
        $in: deleteDuplicateUUIDs([
          ...allLoginPlaceNodeIds,
          ...parentLoginPlaceNodeUUIDs,
        ]),
      }
    }
  } else {
    placeNodeIdCondition = {
      $in: deleteDuplicateUUIDs(
        mergePlaceNodeUUIDs(flattenNodePath, allPlaceNodeIds),
      ),
    }
  }

  let sortColumnCondition = ''
  if (conditions.sortColumn !== undefined) {
    sortColumnCondition = conditions.sortColumn
  }

  let sortOrderCondition = ''
  if (conditions.sortOrder !== undefined) {
    sortOrderCondition = conditions.sortOrder
  }

  return {
    templateName: templateNameCondition,
    placeNodeId: placeNodeIdCondition,
    sortColumn: { $in: sortColumnCondition },
    sortOrder: { $in: sortOrderCondition },
  }
}

export { getFilter }
