import { PlaceNode } from '@ulysses-inc/harami_api_client'

export interface FlatNode extends PlaceNode {
  path: string[]
}

/**
 * ツリー階層の現場情報をフラットに配列化する。
 * 引数(nodes)に渡した現場ノードの子孫ノードを再帰しながら全て探索し、経路となった全てのノードの uuid をFlatNode.pathに格納する。
 * @param nodes ツリー階層を持つ現場情報。
 * @param path 再帰関数として途中経過を持ち越すための配列。最初は空配列で渡すことを想定している。再帰が停止すると、最初の呼び出しに渡された nodes に到達するまでの経路中の uuid 一覧を持つことになる。
 * @returns フラットに展開した現場情報。
 */
const flattenNodePath = (nodes: PlaceNode[], path: string[]): FlatNode[] => {
  const flatNodes: FlatNode[] = []
  nodes &&
    nodes.length &&
    nodes.forEach(n => {
      const node = n as PlaceNode
      // 引数に設定したトップのノードから、自身の親までの値がpathフィールドに入っている
      flatNodes.push({ ...node, path: [...path] })
      if (node.nodes && node.nodes.length)
        // 子階層のノード一覧について再起処理を行う。引数のトップノードから自分までのIDを渡す。
        flatNodes.push(
          ...flattenNodePath(node.nodes as PlaceNode[], [...path, node.uuid]),
        )
    })
  return flatNodes
}

export { flattenNodePath }
