import { Employee, GetEmployeesRequest } from '@ulysses-inc/harami_api_client'
import { combineReducers } from 'redux'
import { EmployeesAction } from './actions'
import { ActionTypes, GetEmployeesFilter } from './types'
interface EmployeesState {
  employees: Employee[]
  segmentIndex: string
  isLoading: boolean
  request: GetEmployeesRequest
  filter: GetEmployeesFilter
  count: number
  error: Error | null
  isShowEditEmployeeDrawer: boolean
  activeEmployeeUUID: string
  placeNodeWarning: string
}

const initialEmployeesState: EmployeesState = {
  employees: [],
  segmentIndex: '1',
  isLoading: false,
  request: {
    offset: 0,
    limit: 25,
  },
  filter: {},
  count: 0,
  error: null,
  isShowEditEmployeeDrawer: false,
  activeEmployeeUUID: '',
  placeNodeWarning: '',
}

const employees = (
  state: EmployeesState = initialEmployeesState,
  action: EmployeesAction,
): EmployeesState => {
  switch (action.type) {
    case ActionTypes.REQUEST_GET_EMPLOYEES: {
      return {
        ...state,
        isLoading: action.isLoading,
        employees: [],
        request: action.request,
        filter: action.filter ?? {},
        error: null,
      }
    }
    case ActionTypes.SUCCESS_GET_EMPLOYEES: {
      return {
        ...state,
        isLoading: action.isLoading,
        employees: action.employees,
        count: action.count,
        error: null,
      }
    }
    case ActionTypes.ERROR_GET_EMPLOYEES: {
      return {
        ...state,
        isLoading: action.isLoading,
        error: action.error,
      }
    }
    case ActionTypes.REQUEST_CHANGE_EMPLOYEES_PAGE: {
      return {
        ...state,
        request: action.request,
        filter: action.filter ?? {},
      }
    }
    case ActionTypes.REQUEST_CHANGE_EMPLOYEES_SIZE: {
      return {
        ...state,
        request: action.request,
        filter: action.filter ?? {},
      }
    }
    case ActionTypes.UPDATE_PAGINATION: {
      return {
        ...state,
        request: action.request,
      }
    }
    case ActionTypes.REQUEST_ADD_EMPLOYEE: {
      return {
        ...state,
        isLoading: action.isLoading,
        error: null,
      }
    }
    case ActionTypes.SUCCESS_ADD_EMPLOYEE: {
      return {
        ...state,
        isLoading: action.isLoading,
        error: null,
      }
    }
    case ActionTypes.ERROR_ADD_EMPLOYEE: {
      return {
        ...state,
        isLoading: action.isLoading,
        error: action.error,
      }
    }
    case ActionTypes.REQUEST_UPDATE_EMPLOYEE: {
      return { ...state, isLoading: action.isLoading, error: null }
    }
    case ActionTypes.SUCCESS_UPDATE_EMPLOYEE: {
      return {
        ...state,
        isLoading: action.isLoading,
        error: null,
        employees: state.employees
          ? state.employees.map(employee => {
              if (employee.uuid === action.employee.uuid) {
                return action.employee
              } else {
                return employee
              }
            })
          : [],
      }
    }
    case ActionTypes.ERROR_UPDATE_EMPLOYEE: {
      return {
        ...state,
        isLoading: action.isLoading,
        error: action.error,
      }
    }
    case ActionTypes.SUCCESS_DELETE_EMPLOYEE: {
      return {
        ...state,
        isLoading: action.isLoading,
        error: null,
        employees: state.employees.filter(
          employee => employee.uuid !== action.employeeUUID,
        ),
      }
    }
    case ActionTypes.ERROR_DELETE_EMPLOYEE: {
      return {
        ...state,
        isLoading: action.isLoading,
        error: action.error,
      }
    }
    case ActionTypes.SUCCESS_DELETE_MULTIPLE_EMPLOYEES: {
      return {
        ...state,
        isLoading: action.isLoading,
        error: null,
        employees: action.employees,
        count: action.count,
      }
    }
    case ActionTypes.CHANGE_IS_SHOW_EDIT_EMPLOYEE_DRAWER: {
      return {
        ...state,
        isShowEditEmployeeDrawer: action.isShowEditEmployeeDrawer,
      }
    }
    case ActionTypes.UPDATE_ACTIVE_EMPLOYEE_UUID: {
      return { ...state, activeEmployeeUUID: action.activeEmployeeUUID }
    }
    case ActionTypes.UPDATE_SEGMENT_INDEX: {
      return { ...state, segmentIndex: action.segmentIndex }
    }
    case ActionTypes.REQUEST_ADD_MULTIPLE_EMPLOYEES: {
      return {
        ...state,
        isLoading: action.isLoading,
        error: null,
        request: action.request,
        filter: action.filter ?? {},
      }
    }
    case ActionTypes.SUCCESS_ADD_MULTIPLE_EMPLOYEES: {
      return {
        ...state,
        isLoading: action.isLoading,
        error: null,
        employees: action.employees,
      }
    }
    case ActionTypes.ERROR_ADD_MULTIPLE_EMPLOYEES: {
      return {
        ...state,
        isLoading: action.isLoading,
        error: action.error,
      }
    }
    case ActionTypes.UPDATE_PLACE_NODE_WARNING: {
      return {
        ...state,
        placeNodeWarning: action.message,
      }
    }
    default: {
      return { ...state }
    }
  }
}

const employeesReducer = combineReducers({ employees })

export default employeesReducer
