import { PlaceNode, PlaceNodeTypeEnum } from '@ulysses-inc/harami_api_client'
import { FormikProps, useFormik } from 'formik'
import { useDispatch } from 'react-redux'
import { UUID } from 'src/exShared/util/uuid'
import Yup from 'src/features/validation/yup'
import placesOperations from 'src/state/ducks/places/operations'
import { FlatNode } from './PlaceGroupsProps'

export interface PlaceGroupForm {
  nodeId?: string
  name: string
  parentNodeId?: string
  nodeIds: string[]
}

const validationSchema = Yup.object().shape({
  name: Yup.string().required().label('現場グループ名'),
})

export const useFormikProps = ({
  activeNode,
  activeParentNode,
}: {
  activeNode: FlatNode | undefined
  activeParentNode: PlaceNode | undefined
}): FormikProps<PlaceGroupForm> => {
  const dispatch = useDispatch()
  const updatePlaceGroup = (node: PlaceNode, isEdit: boolean) => {
    if (!isEdit) {
      placesOperations.addPlaceGroup(dispatch, node)
    } else {
      placesOperations.updatePlaceGroup(dispatch, node.uuid, node)
    }
  }

  return useFormik<PlaceGroupForm>({
    initialValues: {
      nodeId: activeNode?.uuid || undefined,
      name: activeNode?.placeGroup?.name || '',
      parentNodeId: activeParentNode?.id.toString() || undefined,
      nodeIds:
        (activeNode?.nodes as PlaceNode[])?.map(({ id }) => id.toString()) ||
        [],
    },
    validationSchema: validationSchema,
    onSubmit: (form, formikBag) => {
      const groupNodeIds: string[] = ((activeNode?.nodes as PlaceNode[]) || [])
        .filter(({ type }) => type === PlaceNodeTypeEnum.PlaceGroup)
        .map(({ id }) => id.toString())
      const nodeIds = [...(form.nodeIds ?? []), ...groupNodeIds].filter(
        (nodeId, i, self) => self.indexOf(nodeId) === i,
      )

      updatePlaceGroup(
        {
          id: activeNode?.id ?? 0,
          uuid: activeNode?.uuid ?? UUID(),
          type: PlaceNodeTypeEnum.PlaceGroup,
          isRootNode:
            form.parentNodeId === 'root' || form.parentNodeId === undefined
              ? 1
              : 0,
          placeGroup: {
            id: activeNode?.placeGroup?.id || undefined,
            name: form.name,
          },
          parentNodes:
            form.parentNodeId !== 'root' && form.parentNodeId
              ? [{ id: Number(form.parentNodeId) }]
              : [],
          nodes: nodeIds.map(strId => ({
            id: Number(strId),
          })),
        },
        activeNode !== undefined,
      )
      formikBag.resetForm()
    },
  })
}
