import { Button, Layout, notification } from 'antd'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { Header } from 'src/components/header/Header'
import { HeaderBackButton } from 'src/components/header/HeaderBackButton'
import { HeaderTitle } from 'src/components/header/HeaderTitle'
import Loading from 'src/components/loading/Loading'
import DataUnavailableWidget from 'src/features/dataUnavailableWidget/DataUnavailableWidget'
import { useDataUnavailableWidget } from 'src/features/dataUnavailableWidget/useDataUnavailableWidget'
import placesOperations from 'src/state/ducks/places/operations'
import schedulesOperations from 'src/state/ducks/schedules/operations'
import usersOperations from 'src/state/ducks/users/operations'
import { RootState } from 'src/state/store'
import { useFormikProps } from './EditSchedule.hooks'
import { Content } from './EditSchedule.styled'
import EditScheduleContent from './EditScheduleContent'

const EditSchedulePage: React.FC = () => {
  const scheduleId = useParams<{ scheduleId?: string }>().scheduleId ?? ''
  // 更新時にスケジュールを取得するクエリステータス
  const isLoadingSchedule = useSelector(
    (state: RootState) => state.schedulesState.schedule.isLoading,
  )
  // スケジュール更新処理時のステータス
  const isLoadingUpdateSchedule = useSelector(
    (state: RootState) => state.schedulesState.updateSchedule.isLoading,
  )
  // スケジュール追加処理時のステータス
  const isLoadingAddSchedule = useSelector(
    (state: RootState) => state.schedulesState.addSchedule.isLoading,
  )

  const dispatch = useDispatch()
  const history = useHistory()
  const goSchedules = () => {
    history.push('/schedules')
  }

  const formProps = useFormikProps()
  const { handleSubmit, isValid } = formProps

  const dataUnavailableWidget = useDataUnavailableWidget()

  useEffect(() => {
    if (
      scheduleId !== undefined &&
      scheduleId !== '' &&
      window.location.pathname !== '/schedules/add'
    ) {
      schedulesOperations.getSchedule(dispatch, scheduleId)
    } else {
      schedulesOperations.resetSchedule(dispatch)
    }
    placesOperations.getPlaceGroups(dispatch)
    usersOperations.getUserGroups(dispatch, {})
    schedulesOperations.getSchedulesTemplates(dispatch)

    return () => {
      schedulesOperations.resetSchedule(dispatch)
      schedulesOperations.resetSchedulesTemplates(dispatch)
      dataUnavailableWidget.reset()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const isSpinning = isLoadingAddSchedule || isLoadingUpdateSchedule

  return (
    <Layout>
      <Header
        rightSlot={[
          <Button
            disabled={dataUnavailableWidget.isVisible}
            key={'cancel-schedule-button'}
            type="default"
            onClick={() => goSchedules()}
          >
            キャンセル
          </Button>,
          <Button
            disabled={dataUnavailableWidget.isVisible}
            key={'register-schedule-button'}
            type="primary"
            onClick={() => {
              if (!isValid) {
                notification.error({
                  message: '記入事項が正しくないので、保存できません',
                  duration: 2,
                })
              }
              handleSubmit()
            }}
          >
            保存
          </Button>,
        ]}
        sticky
      >
        <HeaderBackButton onClick={() => goSchedules()} />
        <HeaderTitle title="スケジュール編集" />
      </Header>
      <Content>
        {(isLoadingSchedule || isLoadingUpdateSchedule) && !isSpinning ? (
          <Loading />
        ) : dataUnavailableWidget.isVisible ? (
          <DataUnavailableWidget />
        ) : (
          <EditScheduleContent formProps={formProps} />
        )}
      </Content>
    </Layout>
  )
}

export default EditSchedulePage
