export const ActionTypes = {
  REQUEST_FETCH_GRID_VARIABLES_TABLE:
    'harami/editGridVariables/REQUEST_FETCH_GRID_VARIABLES_TABLE',
  SUCCESS_FETCH_GRID_VARIABLES_TABLE:
    'harami/editGridVariables/SUCCESS_FETCH_GRID_VARIABLES_TABLE',
  ERROR_FETCH_GRID_VARIABLES_TABLE:
    'harami/editGridVariables/ERROR_FETCH_GRID_VARIABLES_TABLE',
  REQUEST_UPDATE_GRID_VARIABLES_TABLE:
    'harami/editGridVariables/REQUEST_UPDATE_GRID_VARIABLES_TABLE',
  SUCCESS_UPDATE_GRID_VARIABLES_TABLE:
    'harami/editGridVariables/SUCCESS_UPDATE_GRID_VARIABLES_TABLE',
  ERROR_UPDATE_GRID_VARIABLES_TABLE:
    'harami/editGridVariables/ERROR_UPDATE_GRID_VARIABLES_TABLE',
  SET_PATCH_GRID_VARIABLES_ERROR:
    'harami/editGridVariables/SET_PATCH_GRID_VARIABLES_ERROR',
  CLEAR_PATCH_GRID_VARIABLES_ERROR:
    'harami/editGridVariables/CLEAR_PATCH_GRID_VARIABLES_ERROR',
  START_RANGE_SELECT: 'harami/editGridVariables/START_RANGE_SELECT',
  CONTINUE_RANGE_SELECT: 'harami/editGridVariables/CONTINUE_RANGE_SELECT',
  FINISH_RANGE_SELECT: 'harami/editGridVariables/FINISH_RANGE_SELECT',
  DOUBLE_CLICK_CELL: 'harami/editGridVariables/DOUBLE_CLICK_CELL',
  SELECT_ALL_CELL: 'harami/editGridVariables/SELECT_ALL_CELL',
  PICK_DATE: 'harami/editGridVariables/PICK_DATE',
  SELECT_OPTION: 'harami/editGridVariables/SELECT_OPTION',
  PASTE_ROWS: 'harami/editGridVariables/PASTE_ROWS',
  PATCH_INPUT_TABLE: 'harami/editGridVariables/PATCH_INPUT_TABLE',
  CLEAR_STATE: 'harami/editGridVariables/CLEAR_STATE',
} as const

/**
 * 取り込み項目(1〜)の uuid のマップ
 *
 * @key 取り込み項目番号(1〜)
 * @value 取り込み項目 uuid
 * @memberOf SectionsByName
 */
export type GridVariablesByNumber = { [key: number]: string }

/**
 * セクション名をキーに、セクションの uuid と、
 * そのセクションに紐づく取り込み項目(1〜)の uuid のマップを保持するルックアップテーブル
 *
 * @key セクション名
 * @value セクション uuid, 取り込み項目(1〜)の uuid のマップ
 */
export type SectionsByName = {
  [name: string]: {
    /* セクション uuid */
    uuid: string

    /* 取り込み項目(1〜) */
    gridVariables: GridVariablesByNumber
  }
}

/** 取り込み項目 */
export type Value = {
  value: string
  uuid: string
  errors: string[]
}

/** 取り込み項目 1〜 */
export type Values = {
  // FIXME: keyをリテラルタイプのユニオンにしたほうが良いかもしれない
  //        コンポーネント側はそうなっている
  //        type GridVariableNumber = 1 | 2 | 3 | 4 | 5 | 6
  //        { [key in GridVariableNumber]?: Value }
  /** key = gridVariableNumber( 取り込み項目の通番 ) */
  [key: number]: Value
}

/** 取り込み項目の行 */
export type Row = {
  /** 適用開始日 */
  from: {
    dateValue: string
    errors: string[]
  }
  /** 適用終了日 */
  through: {
    dateValue: string
    errors: string[]
  }
  /** セクション名 */
  sectionName: {
    name: string
    errors: string[]
  }
  /** 取り込みセクション */
  variableSection: {
    name: string
    errors: string[]
  }
  /** 取り込み項目 1〜 */
  values: Values

  // -------------------------------------
  // 以下は InputTable のみで使用するプロパティ
  // -------------------------------------
  /** 1セルでもエラーがある場合 true */
  hasErrors: boolean
  /** 1セルでも originalTable から変更がある場合 true */
  hasChanged: boolean
}

/** 取り込み項目のテーブル */
export type Table = {
  [key: number]: Row
}

export type CellMap = {
  /** 行番号: rowNumber */
  [key: number]: {
    /** 列番号: colNumber */
    [key: number]: true
  }
}

/**
 * 画面のモード
 *
 * - `none` - 初期のモード
 * - `select` - セルが範囲選択されている状態を表すモード
 * - `edit` - 単一のセルが編集状態であることを表すモード
 * - `drag` - マウスのドラッグ操作で、セルの範囲選択をしている状態を表すモード
 */
export type Mode = 'none' | 'select' | 'edit' | 'drag'
