import { ImproveEmail } from '@ulysses-inc/harami_api_client'
import { combineReducers } from 'redux'
import { ImproveEmailLogsAction } from './actions'
import { ActionTypes } from './types'

interface ImproveEmailLogsState {
  improveEmailLogs: ImproveEmail[]
  isLoading: boolean
  error: Error | null
  message: string
  isShow: boolean
}

const initialImproveEmailLogsState: ImproveEmailLogsState = {
  improveEmailLogs: [],
  isLoading: false,
  error: null,
  message: '',
  isShow: false,
}

const improveEmailLogs = (
  state: ImproveEmailLogsState = initialImproveEmailLogsState,
  action: ImproveEmailLogsAction,
): ImproveEmailLogsState => {
  switch (action.type) {
    case ActionTypes.REQUEST_GET_IMPROVE_EMAIL_LOGS: {
      return {
        ...state,
        isLoading: action.isLoading,
        improveEmailLogs: [],
        error: null,
      }
    }
    case ActionTypes.SUCCESS_GET_IMPROVE_EMAIL_LOGS: {
      return {
        ...state,
        isLoading: action.isLoading,
        improveEmailLogs: action.improveEmails || [],
        error: null,
      }
    }
    case ActionTypes.ERROR_GET_IMPROVE_EMAIL_LOGS: {
      return {
        ...state,
        isLoading: action.isLoading,
        error: action.error,
      }
    }
    case ActionTypes.REQUEST_ADD_IMPROVE_EMAIL_LOG: {
      return {
        ...state,
        isLoading: action.isLoading,
        error: null,
        message: action.message,
      }
    }
    case ActionTypes.ERROR_ADD_IMPROVE_EMAIL_LOG: {
      return {
        ...state,
        isLoading: action.isLoading,
        error: action.error,
      }
    }
    case ActionTypes.CHANGE_IS_SHOW_IMPROVE_EMAIL_LOGS_DRAWER: {
      return {
        ...state,
        isShow: action.isShow,
      }
    }
    default: {
      return { ...state }
    }
  }
}

const improveEmailLogsReducer = combineReducers({
  improveEmailLogs,
})

export default improveEmailLogsReducer
