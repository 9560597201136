import {
  GetReportsRequest,
  ReportListItem,
  ReportPlace,
} from '@ulysses-inc/harami_api_client'
import { ActionTypes, GetReportsFilter } from '../types'

const getReports = (request: GetReportsRequest, filter?: GetReportsFilter) => {
  return {
    type: ActionTypes.REQUEST_GET_REPORTS,
    isLoading: true,
    request,
    filter,
  }
}

const getSuccessReports = (reports: ReportListItem[], count: number) => {
  return {
    type: ActionTypes.SUCCESS_GET_REPORTS,
    isLoading: false,
    reports,
    count,
  }
}

const getErrorReports = (error: Error) => {
  return {
    type: ActionTypes.ERROR_GET_REPORTS,
    isLoading: false,
    error,
  }
}

const changeReportsPage = (
  page: number,
  pageSize?: number,
  filter?: GetReportsFilter,
) => {
  const offset = pageSize ? (page - 1) * pageSize : 0
  return {
    type: ActionTypes.REQUEST_CHANGE_REPORTS_PAGE,
    isLoading: true,
    request: {
      limit: pageSize,
      offset,
    },
    filter,
  }
}

const changeReportsSize = (pageSize: number, filter?: GetReportsFilter) => {
  return {
    type: ActionTypes.REQUEST_CHANGE_REPORTS_SIZE,
    isLoading: true,
    request: {
      limit: pageSize,
      offset: 0,
    },
    filter,
  }
}

const updatePagination = (limit: number, offset: number) => {
  return {
    type: ActionTypes.UPDATE_PAGINATION,
    request: {
      limit,
      offset,
    },
  }
}

const deleteReport = (reportId: string) => {
  return {
    type: ActionTypes.REQUEST_DELETE_REPORT,
    reportId,
    isLoading: true,
  }
}

const deleteSuccessReport = (reportId: string) => {
  return {
    type: ActionTypes.SUCCESS_DELETE_REPORT,
    reportId,
    isLoading: false,
  }
}

const deleteErrorReport = (error: Error) => {
  return {
    type: ActionTypes.ERROR_DELETE_REPORT,
    isLoading: false,
    error,
  }
}

const updateReportPlaces = (
  reports: Array<{ id: number }>,
  reportPlace: ReportPlace,
  request: GetReportsRequest,
  filter?: GetReportsFilter,
) => {
  return {
    type: ActionTypes.REQUEST_UPDATE_REPORT_PLACES,
    isLoading: true,
    reports,
    reportPlace,
    request,
    filter,
  }
}

const updateSuccessReportPlaces = (
  reports: ReportListItem[],
  count: number,
) => {
  return {
    type: ActionTypes.SUCCESS_UPDATE_REPORT_PLACES,
    isLoading: false,
    reports,
    count,
  }
}

const updateErrorReportPlaces = (error: Error) => {
  return {
    type: ActionTypes.ERROR_UPDATE_REPORT_PLACES,
    isLoading: false,
    error,
  }
}

export type ReportsAction =
  | ReturnType<typeof getReports>
  | ReturnType<typeof getSuccessReports>
  | ReturnType<typeof getErrorReports>
  | ReturnType<typeof changeReportsPage>
  | ReturnType<typeof changeReportsSize>
  | ReturnType<typeof updatePagination>
  | ReturnType<typeof deleteReport>
  | ReturnType<typeof deleteSuccessReport>
  | ReturnType<typeof deleteErrorReport>
  | ReturnType<typeof updateReportPlaces>
  | ReturnType<typeof updateSuccessReportPlaces>
  | ReturnType<typeof updateErrorReportPlaces>

export default {
  getReports,
  getSuccessReports,
  getErrorReports,
  changeReportsPage,
  changeReportsSize,
  updatePagination,
  deleteReport,
  deleteSuccessReport,
  deleteErrorReport,
  updateReportPlaces,
  updateSuccessReportPlaces,
  updateErrorReportPlaces,
}
