import {
  GetTemplatesRequest,
  GetTemplatesResponse,
} from '@ulysses-inc/harami_api_client'
import { GetTemplatesFilter } from 'src/exShared/types/types'
import { ActionTypes } from 'src/state/ducks/templateList/types'

/**
 * ひな形の一覧をサーバーから取得する
 */
export const getTemplates = (
  request: GetTemplatesRequest,
  filter?: GetTemplatesFilter,
) => {
  return {
    type: ActionTypes.REQUEST_GET_TEMPLATES,
    isLoading: true,
    request,
    filter,
  }
}

export const getSuccessTemplates = (response: GetTemplatesResponse) => {
  return {
    type: ActionTypes.SUCCESS_GET_TEMPLATES,
    isLoading: false,
    templates: response.templates,

    // TODO: 型を合わせるために「|| 0」を追加。schema の定義を正しくすれば必要ないはず
    // https://kaminashi.atlassian.net/browse/HPB-2440 で対応する
    count: response.count || 0,
  }
}

export const getErrorTemplates = (error: Error) => {
  return {
    type: ActionTypes.ERROR_GET_TEMPLATES,
    isLoading: false,
    error: error,
  }
}

/**
 * ひな形をサーバー上で複製する
 */
export const copyTemplates = (
  templateIds: number[],
  request: GetTemplatesRequest,
  filter?: GetTemplatesFilter,
) => {
  return {
    type: ActionTypes.REQUEST_COPY_TEMPLATES,
    isLoading: true,
    templateIds,
    request,
    filter,
  }
}

export const copySuccessTemplates = (response: GetTemplatesResponse) => {
  return {
    type: ActionTypes.SUCCESS_COPY_TEMPLATES,
    isLoading: false,
    templates: response.templates,

    // TODO: 型を合わせるために「|| 0」を追加。schema の定義を正しくすれば必要ないはず
    // https://kaminashi.atlassian.net/browse/HPB-2440 で対応する
    count: response.count || 0,
  }
}

export const copyErrorTemplates = (error: Error) => {
  return {
    type: ActionTypes.ERROR_COPY_TEMPLATES,
    isLoading: false,
    error: error,
  }
}

/**
 * ひな形をサーバー上から削除する
 */
export const deleteTemplate = (templateId: number) => {
  return {
    type: ActionTypes.REQUEST_DELETE_TEMPLATE,
    templateId,
    isLoading: true,
  }
}

export const deleteSuccessTemplate = (templateId: number) => {
  return {
    type: ActionTypes.SUCCESS_DELETE_TEMPLATE,
    templateId,
    isLoading: false,
  }
}

export const deleteErrorTemplate = (error: Error) => {
  return {
    type: ActionTypes.ERROR_DELETE_TEMPLATE,
    isLoading: false,
    error: error,
  }
}

/**
 * ひな形削除時に、特定のエラーが発生した場合に、そのひな形のtemplates.idをセットするために使用するアクション
 *
 * @param templateId
 * @returns
 */
export const setTemplateDeleteErrorTemplateId = (templateId: number) => {
  return {
    type: ActionTypes.SET_TEMPLATE_DELETE_ERROR_TEMPLATE_ID,
    // TODO: エラーが複数種類あり、そのエラーによってモーダルの内容などを表示し分けたい場合には、
    // エラーのタイプなどの追加情報が必要だが、現状１種類しかないためtemplateIdのみを指定している。
    templateId,
  }
}

/**
 * ひな形削除時に、setTemplateDeleteErrorTemplateIdでセットされたtemplates.idをクリアするアクション
 *
 * @returns
 */
export const clearTemplateDeleteErrorTemplateId = () => {
  return {
    type: ActionTypes.CLEAR_TEMPLATE_DELETE_ERROR_TEMPLATE_ID,
  }
}

/**
 * ひな形一覧ページでページを切り替える
 */
export const changeTemplatesPage = (
  page: number,
  pageSize?: number,
  filter?: GetTemplatesFilter,
) => {
  const offset = pageSize ? (page - 1) * pageSize : 0
  return {
    type: ActionTypes.REQUEST_CHANGE_TEMPLATES_PAGE,
    isLoading: true,
    request: {
      limit: pageSize,
      offset,
    },
    filter,
  }
}

/**
 * ひな形一覧ページで表示件数を切り替える
 */
export const changeTemplatesSize = (
  pageSize: number,
  filter?: GetTemplatesFilter,
) => {
  return {
    type: ActionTypes.REQUEST_CHANGE_TEMPLATES_SIZE,
    isLoading: true,
    request: {
      limit: pageSize,
      offset: 0,
    },
    filter,
  }
}

/**
 * ひな形一覧ページでページと表示件数を切り替える
 */
export const updatePagination = (limit: number, offset: number) => {
  return {
    type: ActionTypes.UPDATE_PAGINATION,
    request: {
      limit,
      offset,
    },
  }
}

export type TemplatesAction =
  | ReturnType<typeof getTemplates>
  | ReturnType<typeof getSuccessTemplates>
  | ReturnType<typeof getErrorTemplates>
  | ReturnType<typeof copyTemplates>
  | ReturnType<typeof copySuccessTemplates>
  | ReturnType<typeof copyErrorTemplates>
  | ReturnType<typeof deleteTemplate>
  | ReturnType<typeof deleteSuccessTemplate>
  | ReturnType<typeof changeTemplatesPage>
  | ReturnType<typeof changeTemplatesSize>
  | ReturnType<typeof updatePagination>
  | ReturnType<typeof deleteErrorTemplate>
  | ReturnType<typeof setTemplateDeleteErrorTemplateId>
  | ReturnType<typeof clearTemplateDeleteErrorTemplateId>
