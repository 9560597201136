import { css } from '@emotion/react'
import { FC } from 'react'

type Props = {
  children?: string
  noMargin?: boolean
}

export const ErrorMessage: FC<Props> = ({ children, noMargin }) => {
  if (!children) return null

  return (
    <p
      aria-label={children}
      css={[styles.errorMessage, css({ marginTop: noMargin ? 0 : '8px' })]}
      role="alert"
    >
      {children}
    </p>
  )
}

const styles = {
  errorMessage: css`
    color: #ff4d4f;
    font-size: 14px;
    font-weight: 700;
    margin: 0;
  `,
}
