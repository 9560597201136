import { CloseCircleOutlined } from '@ant-design/icons'
import { css } from '@emotion/react'
import * as Sentry from '@sentry/browser'
import { Result } from 'antd'
import { Component, ErrorInfo, ReactNode } from 'react'
import { ErrorMessage } from 'src/components/errorMessage/ErrorMessage'
import { appEnv } from 'src/util/appEnv'

interface Props {
  children: ReactNode
  simple?: boolean
}

interface State {
  hasError: boolean
}

class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  override componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    if (appEnv.isLocal) return
    Sentry.withScope(scope => {
      scope.setExtras({ ...errorInfo })
      Sentry.captureException(error)
    })
  }

  override render() {
    if (!this.state.hasError) {
      return this.props.children
    }

    if (this.props.simple) {
      return (
        <div css={{ margin: '24px' }}>
          <ErrorMessage>エラーが発生しました</ErrorMessage>
        </div>
      )
    }

    return (
      <Result status="error" title="エラーが発生しました" subTitle="">
        <div css={styles.messages}>
          <div css={styles.boldText}>エラーには以下の原因が考えられます</div>
          <div>
            <CloseCircleOutlined style={{ color: 'red' }} />
            インターネットの接続が悪い環境で確認している場合は、お手数ですがインターネットの接続が良い環境で再度ご確認ください
          </div>
          <div>
            <CloseCircleOutlined style={{ color: 'red' }} />
            システムエラーの可能性があるため、サポートまでお問い合わせください
          </div>
        </div>
      </Result>
    )
  }
}

const styles = {
  messages: css`
    display: flex;
    flex-direction: column;
    gap: 8px;
  `,
  boldText: css`
    font-weight: bold;
    font-size: 16px;
  `,
}

export default ErrorBoundary
