import { takeEvery } from 'redux-saga/effects'
import { getGridVariableTemplates } from './list/sagas'
import { ActionTypes } from './types'

const sagas = [
  takeEvery(
    ActionTypes.REQUEST_CHANGE_GRID_VARIABLE_TEMPLATES_PAGE,
    getGridVariableTemplates,
  ),
]

export default sagas
