import { Layout, Pagination } from 'antd'
import React, { useEffect, useState } from 'react'
import { Header } from 'src/components/header/Header'
import { HeaderBackButton } from 'src/components/header/HeaderBackButton'
import { HeaderTitle } from 'src/components/header/HeaderTitle'
import Loading from 'src/components/loading/Loading'
import { GlobalNavigation } from 'src/features/globalNavigation/GlobalNavigation'
import ImproveExcelConversionLogList from 'src/features/improveExcelConversionLogs/ImproveExcelConversionLogList'
import { ImproveExcelConversionLogsProps } from 'src/features/improveExcelConversionLogs/ImproveExcelConversionLogsProps'
import { useListFilter } from 'src/hooks/filter/useListFilter'
import styled from 'styled-components'

const { Content } = Layout

const LogsPagination = styled(Pagination)`
  margin-top: 16px;
  margin-bottom: 16px;
  display: flex;
  justify-content: center;
`

const ImproveExcelConversionLogsScene: React.FC<
  ImproveExcelConversionLogsProps
> = (props: ImproveExcelConversionLogsProps) => {
  const [chooseLogFileUUIDs, setChooseLogFileUUIDs] = useState<{
    [key: string]: string
  }>({})
  const [logGetTrigger, setLogGetTrigger] = useState<boolean>(false)
  const { filters, setListFilter } = useListFilter()

  useEffect(() => {
    props.getLogs({
      improveUUID: props.improveUUID,
      limit: props.request?.limit,
      offset: props.request?.offset,
    })
    setLogGetTrigger(!logGetTrigger)
    if (filters) {
      const excelConversionLogsFilter = filters.improveExcelConversionLogs
      props.updatePagination(
        props.improveUUID,
        excelConversionLogsFilter?.pagination?.limit ?? 25,
        excelConversionLogsFilter?.pagination?.offset ?? 0,
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setListFilter({
      ...(filters ?? {}),
      improveExcelConversionLogs: {
        pagination: { ...props.request, improveUUID: props.improveUUID },
        filter: filters?.improveExcelConversionLogs?.filter,
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.request?.limit, props.request?.offset])

  const currentPage =
    props.request?.offset === undefined || props.request?.limit === undefined
      ? 1
      : props.request?.offset / props.request?.limit + 1

  const renderList = () => {
    return (
      <>
        {props.isLoading ? (
          <Loading />
        ) : (
          <ImproveExcelConversionLogList
            logs={props.logs}
            getImproveExcelConversionLogFiles={
              props.getImproveExcelConversionLogFiles
            }
            chooseLogUUIDs={chooseLogFileUUIDs}
            setChooseLogUUIDs={setChooseLogFileUUIDs}
          />
        )}
      </>
    )
  }
  return (
    // TODO: サイドメニュー描写の責務を`Routes.tsx`に移植する
    <GlobalNavigation
      resetPagination={() => {
        setListFilter({
          ...(filters ?? {}),
          improveExcelConversionLogs: {
            pagination: {
              improveUUID: props.improveUUID,
              limit: 25,
              offset: 0,
            },
            filter: filters?.improveExcelConversionLogs?.filter,
          },
        })
      }}
    >
      <Layout>
        <Header>
          <HeaderBackButton
            onClick={() => {
              props.goImproveDetail(props.improveUUID)
            }}
          />
          <HeaderTitle
            title={`${props.logs?.[0]?.reportName ?? ''} ダウンロードファイル一覧`}
          />
        </Header>
        <Content>{renderList()}</Content>
        <LogsPagination
          showSizeChanger
          current={currentPage}
          pageSizeOptions={['10', '25', '50', '100']}
          pageSize={props.request?.limit ?? 25}
          defaultCurrent={1}
          total={props.count}
          onChange={(pageNum, pageSize) =>
            props.changeLogsPage(
              props.improveUUID,
              pageNum,
              pageSize,
              props.filter,
            )
          }
          onShowSizeChange={(_, size) =>
            props.changeLogsSize(props.improveUUID, size, props.filter)
          }
        />
      </Layout>
    </GlobalNavigation>
  )
}

export default ImproveExcelConversionLogsScene
