import { GridVariableValue } from '@ulysses-inc/harami_api_client'
import { FC } from 'react'
import { CellUnAnsweredVariableFontColor } from 'src/features/theme/KdsThemeColor'
import { CellInner, TableBodyCellWrapper } from './Common.dumb'

type Props = {
  value?: GridVariableValue['value']
}

export const TableBodyCellGridVariable: FC<Props> = ({ value }) => (
  <TableBodyCellWrapper
    backgroundColor="fixed"
    customFontColor={value === '' ? CellUnAnsweredVariableFontColor : undefined}
  >
    <CellInner>{value === '' ? 'ー' : value}</CellInner>
  </TableBodyCellWrapper>
)
