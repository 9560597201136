import { css } from '@emotion/react'
import { Tabs } from 'antd'
import { ComponentProps, ReactElement } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import {
  addTemplatePage as addTemplatePageAction,
  changeActivePageId as changeActivePageIdAction,
  moveTemplatePage as moveTemplatePageAction,
} from 'src/state/ducks/templates/actions'
import { RootState } from 'src/state/store'
import invariant from 'tiny-invariant'

type DraggableProps = {
  children: ReactElement
  itemKey: string
}

// ドラッグ中のオブジェクト(DnDではitemと呼ばれている)に持たせる値の型
// 要件に応じて勝手に決めていいっぽい
type DragObject = { key: number }

const Draggable = (props: DraggableProps) => {
  const { children, itemKey } = props
  const dispatch = useDispatch()

  const moveTemplatePage = (draggingItemKey: number, targetItemKey: number) => {
    dispatch(moveTemplatePageAction(draggingItemKey, targetItemKey))
  }

  const myKey = parseInt(itemKey, 10)

  const [, drag] = useDrag<DragObject>(() => ({
    type: 'DND_NODE',
    item: { key: myKey },
  }))

  const [, drop] = useDrop<DragObject>(() => ({
    accept: 'DND_NODE',
    // 私にドロップされたときはこうします、の意
    drop: draggingItem => {
      if (draggingItem.key === myKey) {
        return
      }
      moveTemplatePage(draggingItem.key, myKey)
    },
  }))

  return (
    <div ref={node => drag(drop(node))} data-testid="template-page-tab">
      {children}
    </div>
  )
}

interface Props {
  onAddPage?: () => void
  tabItems: ComponentProps<typeof Tabs>['items']
}

export const TemplatePageTabs = ({ onAddPage, tabItems }: Props) => {
  const activePageId = useSelector(
    (state: RootState) => state.templatesState.templatePages.activePageId,
    shallowEqual,
  )

  const dispatch = useDispatch()

  const onEdit: ComponentProps<typeof Tabs>['onEdit'] = (_, action) => {
    // ここでは追加のアクションのみ扱う。
    // コピーや削除は各タブ右端に配置されたアイコンから行われる。
    if (action === 'add') {
      dispatch(addTemplatePageAction())
      if (onAddPage) {
        onAddPage()
      }
    }
  }

  const onChangeTab = (activeKey: string) =>
    dispatch(changeActivePageIdAction(parseInt(activeKey, 10)))

  const renderTabBar: ComponentProps<typeof Tabs>['renderTabBar'] = (
    props,
    DefaultTabBar,
  ) => (
    <DefaultTabBar {...props}>
      {eachTabPane => {
        invariant(
          typeof eachTabPane.key === 'string',
          '各タブに与えられたキーはstringである必要があります',
        )
        return <Draggable itemKey={eachTabPane.key}>{eachTabPane}</Draggable>
      }}
    </DefaultTabBar>
  )

  return (
    <Tabs
      css={styles.container}
      onEdit={onEdit}
      type="editable-card"
      activeKey={`${activePageId}`}
      onChange={onChangeTab}
      renderTabBar={renderTabBar}
      items={tabItems}
    />
  )
}

const styles = {
  container: css`
    width: 100%;

    .ant-tabs-nav {
      margin-bottom: 0px !important;
    }

    .ant-tabs-nav-wrap {
      margin-top: 10px;
      margin-bottom: 0px;
      margin-left: 20px;
    }

    .ant-tabs-extra-content {
      margin-right: 20px;
    }
  `,
}
