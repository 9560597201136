import { Improve } from '@ulysses-inc/harami_api_client'
import { ActionTypes } from '../types'

const getRequestImprove = (improveUUID: string) =>
  ({
    type: ActionTypes.REQUEST_GET_IMPROVE,
    isLoading: true,
    improveUUID,
  }) as const

const getSuccessImprove = (improve: Improve) =>
  ({
    type: ActionTypes.SUCCESS_GET_IMPROVE,
    isLoading: false,
    improve,
  }) as const

const getErrorImprove = (error: Error) =>
  ({
    type: ActionTypes.ERROR_GET_IMPROVE,
    isLoading: false,
    error,
  }) as const

export type GetImproveAction =
  | ReturnType<typeof getRequestImprove>
  | ReturnType<typeof getSuccessImprove>
  | ReturnType<typeof getErrorImprove>

export default {
  getRequestImprove,
  getSuccessImprove,
  getErrorImprove,
}
