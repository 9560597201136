import { css } from '@emotion/react'
import { GetPlacesV2Request, PlaceNode } from '@ulysses-inc/harami_api_client'
import { Button, Drawer, Layout, Pagination, Row } from 'antd'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Header } from 'src/components/header/Header'
import { HeaderTitle } from 'src/components/header/HeaderTitle'
import placesOperations from 'src/state/ducks/places/operations'
import { GetPlacesFilter } from 'src/state/ducks/places/types'
import { RootState } from 'src/state/store'
import styled from 'styled-components'
import EditPlace from './EditPlace'
import PlaceList from './PlaceList'
import PlacesFilterDropDown from './PlacesFilterDropDown'

interface StateProps {
  places: PlaceNode[]
  isLoading: boolean
  isShowEditPlaceDrawer: boolean
  segmentIndex: number
  activePlaceId?: string
  count: number
  request: GetPlacesV2Request
  updatedPlaces: boolean
  filter: GetPlacesFilter
}

interface DispatchProps {
  getPlacesV2: (request: GetPlacesV2Request, filter?: GetPlacesFilter) => void
  changePlacesV2Page: (
    page: number,
    pageSize?: number,
    filter?: GetPlacesFilter,
  ) => void
  changePlacesV2Size: (pageSize: number, filter?: GetPlacesFilter) => void
  updateSegmentIndex: (index: number) => void
  changeIsShowEditPlaceDrawer: (isShow: boolean) => void
  openAddPlace: () => void
  openEditPlace: (place: PlaceNode) => void
  deletePlace: (id: string) => void
  changeUpdatedPlacesFlag: (edited: boolean) => void
}

type PlacesProps = StateProps & DispatchProps

const { Content } = Layout

const ContentWrapper = styled(Content)`
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
`

const ListContainer = styled.div`
  width: 100%;
  max-width: 800px;
  margin-bottom: 24px;
`

const PlacesPagination = styled(Pagination)`
  display: flex;
  justify-content: center;
`

const PlacesScene = () => {
  const [placeGetTrigger, setPlaceGetTrigger] = useState<boolean>(false)

  // react-reduxのconnect関数の利用をやめた際のなごり
  // (今後このコンポーネントを触ることがあればそのときに解体する)
  const exProps = useProps()

  useEffect(() => {
    // 現場一覧に遷移したとき、props.request が {} になっているため初期値を入れる
    // {} になってしまう原因は別画面と共用のstateを利用しているため、requestが引きづられている
    const getPlaceRequest: GetPlacesV2Request = {
      limit: exProps.request.limit ?? 25,
      offset: exProps.request.offset ?? 0,
    }
    exProps.getPlacesV2(getPlaceRequest, exProps.filter)
    setPlaceGetTrigger(!placeGetTrigger)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // 現場の登録または更新があった際に再更新
  useEffect(() => {
    if (!exProps.isLoading && exProps.updatedPlaces) {
      exProps.getPlacesV2(exProps.request, exProps.filter)
      exProps.changeUpdatedPlacesFlag(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exProps.isLoading])

  // 閉じるボタン or Drawer外のクリックしたとき
  const onClose = () => {
    exProps.updateSegmentIndex(0)
    exProps.changeIsShowEditPlaceDrawer(false)
  }

  const currentPage =
    exProps.request.offset === undefined || exProps.request.limit === undefined
      ? 1
      : exProps.request.offset / exProps.request.limit + 1

  const renderPlaceList = () => {
    return (
      <ListContainer>
        <Row
          justify="space-between"
          css={css`
            margin: 15px;
          `}
        >
          <PlacesFilterDropDown
            request={exProps.request}
            updatedPlaces={exProps.updatedPlaces}
            isLoadingPlace={exProps.isLoading}
            placeGetTrigger={placeGetTrigger}
            setPlaceGetTrigger={setPlaceGetTrigger}
          />
          <Button
            type="primary"
            onClick={() => exProps.openAddPlace()}
            style={{ borderRadius: 4 }}
          >
            現場を追加
          </Button>
        </Row>
        <PlaceList {...exProps} />
      </ListContainer>
    )
  }

  return (
    <Layout>
      <Header>
        <HeaderTitle title="現場" />
      </Header>
      <ContentWrapper>{renderPlaceList()}</ContentWrapper>
      <Drawer
        placement="right"
        maskClosable={false}
        closable={false}
        onClose={() => onClose()}
        destroyOnClose={true}
        open={exProps.isShowEditPlaceDrawer}
        width={600}
        drawerStyle={{ padding: 0 }}
      >
        <EditPlace />
      </Drawer>
      <PlacesPagination
        showSizeChanger
        current={currentPage}
        pageSizeOptions={['1', '10', '25', '50', '100']}
        pageSize={exProps.request.limit ?? 25}
        defaultCurrent={1}
        total={exProps.count}
        onChange={(pageNum, pageSize) =>
          exProps.changePlacesV2Page(pageNum, pageSize, exProps.filter)
        }
        onShowSizeChange={(_, size) =>
          exProps.changePlacesV2Size(size, exProps.filter)
        }
      />
    </Layout>
  )
}

const useProps = (): PlacesProps => {
  const dispatch = useDispatch()
  const state = useSelector((state: RootState) => state)

  return {
    // -----------------------------------
    // StateProps
    // -----------------------------------

    places: state.placesState.places.places ?? [],
    isLoading: state.placesState.places.isLoading,
    isShowEditPlaceDrawer: state.placesState.places.isShowEditPlaceDrawer,
    segmentIndex: state.placesState.places.segmentIndex,
    activePlaceId: state.placesState.places.activePlaceId,
    count: state.placesState.places.count,
    request: state.placesState.places.request,
    updatedPlaces: state.placesState.places.updatedPlaces,
    filter: state.placesState.places.filter ?? {},

    // -----------------------------------
    // DispatchProps
    // -----------------------------------

    getPlacesV2: (request: GetPlacesV2Request, filter?: GetPlacesFilter) => {
      placesOperations.getPlacesV2(dispatch, request, filter)
    },
    changePlacesV2Page: (
      page: number,
      pageSize?: number,
      filter?: GetPlacesFilter,
    ) => {
      placesOperations.changePlacesV2Page(dispatch, page, pageSize, filter)
    },
    changePlacesV2Size: (pageSize: number, filter?: GetPlacesFilter) => {
      placesOperations.changePlacesV2Size(dispatch, pageSize, filter)
    },
    updateSegmentIndex: (index: number) => {
      placesOperations.updateSegmentIndex(dispatch, index)
    },
    changeIsShowEditPlaceDrawer: (isShow: boolean) => {
      placesOperations.changeIsShowEditPlaceDrawer(dispatch, isShow)
    },
    openAddPlace: () => {
      placesOperations.updateActivePlaceId(dispatch)
      placesOperations.changeIsShowEditPlaceDrawer(dispatch, true)
    },
    openEditPlace: (place: PlaceNode) => {
      placesOperations.updateActivePlaceId(dispatch, place.uuid)
      placesOperations.changeIsShowEditPlaceDrawer(dispatch, true)
    },
    deletePlace: (id: string) => {
      placesOperations.deletePlace(dispatch, id)
    },
    changeUpdatedPlacesFlag: (edited: boolean) => {
      placesOperations.changeUpdatedPlacesFlag(dispatch, edited)
    },
  }
}

export default PlacesScene
