import { createSlice } from '@reduxjs/toolkit'

interface DataUnavailableWidgetState {
  isVisible: boolean
}

const initialState: DataUnavailableWidgetState = {
  isVisible: false,
}

export const dataUnavailableWidgetSlice = createSlice({
  name: 'harami/dataUnavailableWidget',
  initialState,
  reducers: {
    show: state => {
      state.isVisible = true
    },
    reset: () => initialState,
  },
})

export const { show, reset } = dataUnavailableWidgetSlice.actions
export default dataUnavailableWidgetSlice.reducer
