import { TemplateNodeSchema } from '@ulysses-inc/harami_api_client'
import { ActionTypes } from 'src/state/ducks/templates/editDeviateComment/types'

/**
 * 逸脱時通知先の編集ドロワーを開く
 */
export const showEditDeviateComment = (node: TemplateNodeSchema) => {
  return {
    type: ActionTypes.SHOW_EDIT_DEVIATE_COMMENT,
    node,
  }
}

/**
 * 逸脱時通知先の編集ドロワーを閉じる
 */
export const closeEditDeviateComment = () => {
  return {
    type: ActionTypes.CLOSE_EDIT_DEVIATE_COMMENT,
  }
}

export type EditDeviateCommentAction =
  | ReturnType<typeof showEditDeviateComment>
  | ReturnType<typeof closeEditDeviateComment>
