import { ReportStatusEnum } from '@ulysses-inc/harami_api_client'

export const getStatusName = (status: ReportStatusEnum) => {
  switch (status) {
    case ReportStatusEnum.INCOMPLETE:
      return '未完了'
    case ReportStatusEnum.APPROVAL_PENDING:
      return '承認待ち'
    case ReportStatusEnum.APPROVAL_REMAND:
      return '差し戻し'
    case ReportStatusEnum.COMPLETE:
      return '完了'
  }
}
