import { Row } from 'antd'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import EditDeviateCommentDrawer from 'src/features/templateEdit/editDeviationComment/EditDeviateCommentDrawer'
import EditDeviateNotifyDrawer from 'src/features/templateEdit/editDeviationNotification/EditDeviateNotifyDrawer'
import EditMultipleChoiceLogicModal from 'src/features/templateEdit/editLogicOfMultipleChoice/EditMultipleChoiceLogicModal'
import EditNumberLogicModal from 'src/features/templateEdit/editLogicOfNumber/EditNumberLogicModal'
import { addGridSectionIntoActivePage } from 'src/state/ducks/templates/actions'
import { RootState } from 'src/state/store'
import { getBoolParamValue } from 'src/util/urlSearchParamsUtils'
import AddGridTemplatePageContent from './AddGridTemplatePageContent'
import {
  ErrorMessageText,
  TemplatePageContentWrapper,
} from './EditTemplatePages.dumb'
import { TabEditButton } from './TabEditButton'
import { TemplatePageTabs } from './TemplatePageTabs'
import { useCopyPageErrorDialog } from './useCopyPageErrorDialog'

const EditGridTemplatePagesContainer: React.FC = () => {
  useCopyPageErrorDialog()

  const { templatePageIds, templatePages, templatePageErrorMessage } =
    useSelector((state: RootState) => state.templatesState.templatePages)

  const hasVariables = useSelector(
    (state: RootState) => state.templatesState.editTemplate.hasVariables,
  )

  const dispatch = useDispatch()

  const createSection = (hasVariables: boolean) => {
    dispatch(addGridSectionIntoActivePage(hasVariables))
  }

  const { pathname, search } = useLocation()
  const isNew = pathname.includes('addGridTemplate')
  const hasVariablesParam = getBoolParamValue(search, 'hasVariables')

  useEffect(() => {
    // 新規作成の場合で、初期表示時にセクションを一つ追加する
    // 表形式のひな形ではセクションをあらかじめ一つ追加しておく必要があるため
    if (isNew) {
      createSection(hasVariablesParam)
    }

    // 初期レンダリング時のみ動作させたいので空配列を渡している
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Row>
        {!!templatePageErrorMessage && (
          <ErrorMessageText id="error-message">
            {templatePageErrorMessage}
          </ErrorMessageText>
        )}
        <TemplatePageTabs
          onAddPage={() => createSection(hasVariables)}
          tabItems={templatePageIds.map(templatePageId => ({
            children: (
              <TemplatePageContentWrapper>
                <AddGridTemplatePageContent templatePageId={templatePageId} />
              </TemplatePageContentWrapper>
            ),
            closeIcon: <TabEditButton pageId={templatePageId} />,
            key: `${templatePageId}`,
            label: templatePages[templatePageId]?.name,
          }))}
        />
      </Row>
      <EditMultipleChoiceLogicModal />
      <EditNumberLogicModal />
      <EditDeviateCommentDrawer />
      <EditDeviateNotifyDrawer />
    </>
  )
}

export default EditGridTemplatePagesContainer
