import {
  MultipleChoice,
  MultipleChoiceGroup,
  ReportNodeSchema,
  ReportQuestion,
} from '@ulysses-inc/harami_api_client'
import getActiveQuestions from 'src/exShared/hooks/reports/getActiveQuestions'
import { isAnswered } from 'src/exShared/util/report/isAnswered'

const getCalculateScorePercentage = (nodes: {
  [key: number]: ReportNodeSchema
}) => {
  const countAllQuestion = (nodes: {
    [key: number]: ReportNodeSchema
  }): number => getActiveQuestions(nodes).length

  const countAllResult = (nodes: {
    [key: number]: ReportNodeSchema
  }): number => {
    const questions = getActiveQuestions(nodes)
    return questions.filter(
      (question: ReportQuestion) => isAnswered(question),
      [],
    ).length
  }
  const completedQuestionsPercentage = (nodes: {
    [key: number]: ReportNodeSchema
  }): number => {
    const questions = getActiveQuestions(nodes)
    if (questions.length === 0) {
      return 0
    }
    return Math.round(
      (questions.filter((question: ReportQuestion) => isAnswered(question), [])
        .length /
        questions.length) *
        100,
    )
  }

  const sumAllTotalScores = (nodes: {
    [key: number]: ReportNodeSchema
  }): number => {
    const questions = getActiveQuestions(nodes)
    // The questionIDs which the user chose to be excluded from scoring.
    const excludedQuestionIDs = questions
      .filter(
        (q: ReportQuestion) =>
          !!q?.responseAnswer?.multipleChoice?.isExcludedFromScoring,
      )
      .map((q: ReportQuestion) => q?.id)
    return questions
      .map((question: ReportQuestion) => {
        // If the user selects a choice that is not eligible for scoring,
        // remove it from the total.
        if (excludedQuestionIDs.includes(question.id)) return 0
        const mChoice: MultipleChoiceGroup | undefined =
          (question.responseMultipleChoices ?? [])[0]
        const mChoiceResponses: MultipleChoice[] = mChoice?.responses ?? []
        if (mChoiceResponses.length === 0) return 0
        return mChoiceResponses
          .filter((m: MultipleChoice) => !m.isExcludedFromScoring)
          .map((m: MultipleChoice) => m.score ?? 0)
          .reduce((prev, cur) => Math.max(prev, cur), 0)
      })
      .reduce((prev, cur) => prev + cur, 0)
  }
  const sumAllResultScores = (nodes: {
    [key: number]: ReportNodeSchema
  }): number => {
    const questions = getActiveQuestions(nodes)
    return questions
      .map((question: ReportQuestion) => {
        const mChoice = question?.responseAnswer?.multipleChoice
        const score = !mChoice?.isExcludedFromScoring ? mChoice?.score ?? 0 : 0
        return score
      })
      .reduce((prev, cur) => prev + cur, 0)
  }
  const calculateScorePercentage = (nodes: {
    [key: number]: ReportNodeSchema
  }) => {
    if (sumAllTotalScores(nodes) === 0) {
      return 0
    }
    return Math.round(
      (sumAllResultScores(nodes) / sumAllTotalScores(nodes)) * 100,
    )
  }
  return {
    countAllQuestion: countAllQuestion(nodes),
    countAllResult: countAllResult(nodes),
    completedQuestionsPercentage: completedQuestionsPercentage(nodes),
    sumAllTotalScores: sumAllTotalScores(nodes),
    sumAllResultScores: sumAllResultScores(nodes),
    // TODO: 要改名、正しくは`calculatedScorePercentage`
    calculateScorePercentage: calculateScorePercentage(nodes),
  }
}

export default getCalculateScorePercentage
