import { Button, Tag, Typography } from 'antd'
import { Collapse } from 'react-collapse'
import DateSvg from 'src/assets/icons/date.svg?react'
import EmployeeSvg from 'src/assets/icons/employee.svg?react'
import FormulaSvg from 'src/assets/icons/formula.svg?react'
import ImageSvg from 'src/assets/icons/image.svg?react'
import InformationSvg from 'src/assets/icons/information.svg?react'
import NumberSvg from 'src/assets/icons/number.svg?react'
import SignatureSvg from 'src/assets/icons/signature.svg?react'
import TextSvg from 'src/assets/icons/text.svg?react'
import TimeMeasurement from 'src/assets/icons/time_measurement.svg?react'
import {
  DarkGray,
  DragButtonColor,
  Primary,
  QuestionOpenButtonColor,
  Success,
} from 'src/features/theme/KdsThemeColor'
import styled from 'styled-components'

const { Text } = Typography

export const Table = styled.div`
  display: table;
  border-collapse: collapse;
  height: 46px;
  line-height: 46px;
`

const TableCell = styled.div`
  display: table-cell;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-collapse: collapse;
  background: #fafafa;
  vertical-align: middle;
`

export const DraggingPlaceholder = styled.div`
  display: flex;
  height: 46px;
  line-height: 46px;
  width: 100%;
  background: ${Primary};
`
export const DragHandle = styled.div`
  margin-left: 0.7rem;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  width: 2rem;
  height: auto;
  z-index: 1;
  outline: none;
  border-radius: 50%;
  cursor: grab;
`

export const QuestionOpenButtonWrapper = styled.div`
  margin-left: 15px;
  display: flex;
  align-items: center;
`

export const LogicOpenButton = styled(Button)`
  &.ant-btn-primary {
    background-color: ${QuestionOpenButtonColor};
    color: ${DarkGray};
    border: 1px solid ${QuestionOpenButtonColor};
  }
`

export const BasicTableCell = styled(TableCell)`
  min-width: ${({ theme }) => theme.minWidth};
  background-color: ${({ theme }) => theme.backgroundColor};
`

export const QuestionTableCell = styled(TableCell)`
  width: ${({ theme }) => theme.width};
`

export const QuestionWrapper = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
`

export const QuestionNameParagraphWrapper = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  height: 100%;
  margin-left: 10px;
  /**
   * 質問文を入力中に、TextAreaが2行以上に増えた際に、
   * TextAreaとその一つ外の枠の隙間が保たれるようにする
   * (Tableで指定されている1行の高さ 46px - TextArea|Inputの高さ 32px) / 2px = 7px
   */
  padding: 7px 0;
`

export const QuestionNameParagraph = styled.div`
  padding-left: 10px;
  word-break: break-all;
  line-height: 1.5;
`

export const ColumnText = styled(Text)`
  margin-left: 10px;
`

export const ColumnTexScoretWrapper = styled(Text)`
  margin-left: 10px;
  color: ${({ theme }) => theme.color};
`

export const LogicWrapper = styled(Collapse)`
  border: 0;
`

export const HintRowWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

export const HintCheckIconWrapper = styled.span`
  display: flex;
  align-items: center;
  height: 32px;
  color: ${Success};
  margin-right: 10px;
`

export const HintCheckText = styled.span`
  margin-left: 5px;
`

export const ResponseTag = styled.div`
  display: flex;
`

export const ResponseTagIconWrap = styled.div`
  width: 30px;
  display: flex;
  vertical-align: center;
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 10px;
`

export const IconButtonTag = styled.div`
  display: flex;
  align-items: center;
`

export const MultipleChoiceTagWrapper = styled.div`
  display: inline-flex;
  flex-wrap: wrap;
`
export const MultipleChoiceTag = styled(Tag)`
  max-width: 220px;
  text-overflow: ellipsis;
  overflow: hidden;
  margin: 8px 12px 8px 0;
`

export const MultipleChoiceSetTextWrapper = styled.div`
  display: flex;
`

export const MultipleChoiceSetText = styled.span`
  max-width: 250px;
  font-weight: bold;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

export const ConfirmModalContentList = styled.ul`
  max-height: 220px;
  padding-left: 20px;
  padding-bottom: 30px;
  overflow-y: scroll;
`

export const HandleIcon = () => {
  return (
    <svg viewBox="0 0 24 24" width="24" height="24" focusable="false">
      <path fill="none" d="M0 0h24v24H0V0z"></path>
      <path
        fill={DragButtonColor}
        d="M11 18c0 1.1-.9 2-2 2s-2-.9-2-2 .9-2 2-2 2 .9 2 2zm-2-8c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0-6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm6 4c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"
      ></path>
    </svg>
  )
}

export const numberTag = () => {
  return (
    <ResponseTag>
      <ResponseTagIconWrap>
        <NumberSvg width={30} height={30} />
      </ResponseTagIconWrap>
      <span>数値</span>
    </ResponseTag>
  )
}

export const signatureTag = () => {
  return (
    <ResponseTag>
      <ResponseTagIconWrap>
        <SignatureSvg width={30} height={30} />
      </ResponseTagIconWrap>
      <span>署名</span>
    </ResponseTag>
  )
}

export const textTag = () => {
  return (
    <ResponseTag>
      <ResponseTagIconWrap>
        <TextSvg width={30} height={30} />
      </ResponseTagIconWrap>
      <span>テキスト</span>
    </ResponseTag>
  )
}

export const resultImageTag = () => {
  return (
    <ResponseTag>
      <ResponseTagIconWrap>
        <ImageSvg width={30} height={30} />
      </ResponseTagIconWrap>
      <span>写真撮影</span>
    </ResponseTag>
  )
}

export const datetimeTag = () => {
  return (
    <ResponseTag>
      <ResponseTagIconWrap>
        <DateSvg width={30} height={30} />
      </ResponseTagIconWrap>
      <span>日時</span>
    </ResponseTag>
  )
}

export const timeMeasurementTag = () => {
  return (
    <ResponseTag>
      <ResponseTagIconWrap>
        <TimeMeasurement width={30} height={30} />
      </ResponseTagIconWrap>
      <span>時間計測</span>
    </ResponseTag>
  )
}

export const informationTag = () => {
  return (
    <ResponseTag>
      <ResponseTagIconWrap>
        <InformationSvg width={30} height={30} />
      </ResponseTagIconWrap>
      <span>インフォメーション</span>
    </ResponseTag>
  )
}

export const employeeTag = () => {
  return (
    <ResponseTag>
      <ResponseTagIconWrap>
        <EmployeeSvg width={30} height={30} />
      </ResponseTagIconWrap>
      <span>従業員</span>
    </ResponseTag>
  )
}

export const formulaTag = () => {
  return (
    <ResponseTag>
      <ResponseTagIconWrap>
        <FormulaSvg width={30} height={30} />
      </ResponseTagIconWrap>
      <span>計算式</span>
    </ResponseTag>
  )
}
