import { DownOutlined, UpOutlined } from '@ant-design/icons'
import { BorderColor, White } from 'src/features/theme/KdsThemeColor'
import styled from 'styled-components'

const TableCell = styled.div`
  display: table-cell;
  border: 1px solid ${BorderColor};
  background: ${White};
  vertical-align: middle;
`
export const FilterButton = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  cursor: pointer;
`

export const UpOutlinedIcon = styled(UpOutlined)`
  color: rgba(0, 0, 0, 0.65);
`

export const DownOutlinedIcon = styled(DownOutlined)`
  color: rgba(0, 0, 0, 0.65);
`

export const SelectFilterTableCell = styled(TableCell)`
  min-width: ${({ theme }) => theme.minWidth};
  background-color: ${({ theme }) => theme.backgroundColor};
  height: 32px;
`

export const DropDownTableCell = styled(TableCell)`
  width: 400px;
  background-color: ${White};
  padding: 16px;
`
