import { ExclamationCircleOutlined } from '@ant-design/icons'
import { css } from '@emotion/react'
import { Tag } from 'antd'
import { ClampText } from 'src/components/clampText/ClampText'

type Props = {
  reportName: string
  hasSomeDeviatedAnswer?: boolean
}

/**
 * レポート名のセル
 */
export const ReportNameCell = ({
  reportName,
  hasSomeDeviatedAnswer = false,
}: Props) => {
  return (
    <div css={styles.container}>
      <ClampText line={2}>{reportName}</ClampText>
      {hasSomeDeviatedAnswer && (
        <Tag css={styles.errorTag}>
          <ExclamationCircleOutlined css={styles.errorIcon} />
          逸脱
        </Tag>
      )}
    </div>
  )
}

const styles = {
  container: css`
    align-items: center;
    display: flex;
    justify-content: flex-start;
    overflow: hidden;
  `,
  errorTag: css`
    border: none;
    margin-left: 8px;
    padding: 1px 8px;
    color: #f03e3e;
    background-color: #fff5f5;
  `,
  errorIcon: css`
    margin-right: 4px;
  `,
}
