export const ActionTypes = {
  REQUEST_GET_IMPROVE: 'harami/improves/REQUEST_GET_IMPROVE',
  SUCCESS_GET_IMPROVE: 'harami/improves/SUCCESS_GET_IMPROVE',
  ERROR_GET_IMPROVE: 'harami/improves/ERROR_GET_IMPROVE',

  REQUEST_UPDATE_IMPROVE: 'harami/improves/REQUEST_UPDATE_IMPROVE',
  SUCCESS_UPDATE_IMPROVE: 'harami/improves/SUCCESS_UPDATE_IMPROVE',
  ERROR_UPDATE_IMPROVE: 'harami/improves/ERROR_UPDATE_IMPROVE',

  REQUEST_UPDATE_IMPROVE_STATUS:
    'harami/improves/REQUEST_UPDATE_IMPROVE_STATUS',
  SUCCESS_UPDATE_IMPROVE_STATUS:
    'harami/improves/SUCCESS_UPDATE_IMPROVE_STATUS',
  ERROR_UPDATE_IMPROVE_STATUS: 'harami/improves/ERROR_UPDATE_IMPROVE_STATUS',

  RESET_EDIT_IMPROVE: 'harami/improves/RESET_EDIT_IMPROVE',

  REQUEST_GET_IMPROVES: 'harami/improves/REQUEST_GET_IMPROVES',
  SUCCESS_GET_IMPROVES: 'harami/improves/SUCCESS_GET_IMPROVES',
  ERROR_GET_IMPROVES: 'harami/improves/ERROR_GET_IMPROVES',
  REQUEST_CHANGE_IMPROVES_PAGE: 'harami/improves/REQUEST_CHANGE_IMPROVES_PAGE',
  REQUEST_CHANGE_IMPROVES_SIZE: 'harami/improves/REQUEST_CHANGE_IMPROVES_SIZE',
  UPDATE_PAGINATION: 'harami/improves/UPDATE_PAGINATION',

  REQUEST_DELETE_IMPROVE: 'harami/improves/REQUEST_DELETE_IMPROVE',
  SUCCESS_DELETE_IMPROVE: 'harami/improves/SUCCESS_DELETE_IMPROVE',
  ERROR_DELETE_IMPROVE: 'harami/improves/ERROR_DELETE_IMPROVE',
} as const

export interface GetImprovesFilter {
  reportName?: { $like: string }
  placeNodeId?: { $in: string[] }
  reportDate?: { $gte: Date; $lte: Date }
}
