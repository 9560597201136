import { HTMLAttributes } from 'react'
import { Link } from 'react-router-dom'
import { ClampText } from 'src/components/clampText/ClampText'
import { sumMenuItemStyle } from 'src/features/globalNavigation/components/subMenuItem/subMenuItemStyle'

type Props = {
  /**
   * 内部リンク
   * e.g. `/dashboards
   */
  internalPath: string
  title: string
} & HTMLAttributes<HTMLElement>

/**
 * グローバルナビゲーションのサブメニュー内の個々のアイテム (内部リンク)
 */
export const SubMenuItemInternalLink = (props: Props) => {
  const { internalPath, title, ...restProps } = props

  return (
    <Link {...restProps} css={sumMenuItemStyle.container} to={internalPath}>
      <ClampText css={sumMenuItemStyle.title}>{title}</ClampText>
    </Link>
  )
}
