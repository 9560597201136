import deepEqual from 'fast-deep-equal'
import { useState } from 'react'

/**
 * 「undefined ではない直近の値」を保持し続けるカスタムフック
 *
 * TanStack Query の keepPreviousData と同じような機能をより柔軟に使えるようにしたもの。
 * API のレスポンスのデータ構造に起因して keepPreviousData がうまくマッチしない場合に使用されることを想定している。
 */
export const useLastDefinedValue = <T>(newValue: T) => {
  const [value, setValue] = useState<T>(newValue)

  if (newValue !== undefined && !deepEqual(value, newValue)) {
    setValue(newValue)
  }

  return value
}
