import { GetSchedulesRequest } from '@ulysses-inc/harami_api_client'
import { Modal } from 'antd'
import { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { RowActionButton } from 'src/components/rowActionButton/RowActionButton'
import { ModalDeleteButtonColor } from 'src/features/theme/KdsThemeColor'
import schedulesOperations from 'src/state/ducks/schedules/operations'
import { GetSchedulesFilter } from 'src/state/ducks/schedules/types'
import { RootState } from 'src/state/store'
import { ButtonGroupRow, ConfirmModalContentList } from './ScheduleList.styled'
import { TBaseColumnProps } from './ScheduleListRow'

const ActionButtons: FC<TBaseColumnProps> = ({ schedule }) => {
  const request = useSelector(
    (state: RootState) => state.schedulesState.schedules.request,
  )
  const filter = useSelector(
    (state: RootState) => state.schedulesState.schedules.filter,
  )

  const history = useHistory()
  const dispatch = useDispatch()
  const goEditSchedule = (scheduleId: string) => {
    history.push(`/schedules/${scheduleId}`)
  }
  const deleteSchedule = (
    scheduleId: string,
    request: GetSchedulesRequest,
    filter?: GetSchedulesFilter,
  ) => {
    schedulesOperations.deleteSchedule(dispatch, scheduleId, request, filter)
  }

  const onDeleteButtonClick = () => {
    Modal.confirm({
      ...{
        onOk: () => deleteSchedule(schedule.uuid ?? '', request, filter),
        okText: '削除',
        cancelText: 'キャンセル',
        okButtonProps: {
          style: {
            backgroundColor: ModalDeleteButtonColor,
            border: 'none',
          },
        },
      },
      ...(schedule.excelConversionFlows?.length
        ? {
            title:
              '以下のExcel変換設定に紐づいています。削除を実行しますがよろしいですか？',
            content: (
              <ConfirmModalContentList>
                {schedule.excelConversionFlows.map((flow, index) => (
                  <li key={index}>{flow.flowName}</li>
                ))}
              </ConfirmModalContentList>
            ),
          }
        : {
            title: `「${schedule.name}」の削除を実行しますがよろしいですか？`,
          }),
    })
  }

  return (
    <ButtonGroupRow>
      <RowActionButton
        onClick={() => goEditSchedule(schedule.uuid ?? '')}
        type="edit"
      />
      <RowActionButton onClick={onDeleteButtonClick} type="delete" />
    </ButtonGroupRow>
  )
}

export default ActionButtons
