import { DownOutlined, UpOutlined } from '@ant-design/icons'
import { css } from '@emotion/react'
import { GetEmployeesRequest, PlaceNode } from '@ulysses-inc/harami_api_client'
import { Button, Dropdown, Input, Select, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import { useDebounce } from 'src/exShared/hooks/useDebounce'
import { usePlaceLoadingStatus } from 'src/exShared/hooks/usePlaceLoadingStatus'
import { splitAccessiblePlaceNode } from 'src/exShared/libraries/filterDropDown/place'
import { GetTemplatesFilter } from 'src/exShared/types/types'
import { FlatNode } from 'src/exShared/util/place/flattenNodePath'
import { splitLoginPlaceUUIDs } from 'src/exShared/util/place/splitLoginPlace'
import { getFilter } from 'src/exShared/util/template/getFilter'
import { BorderColor, White } from 'src/features/theme/KdsThemeColor'
import { useListFilter } from 'src/hooks/filter/useListFilter'
import { TemplatesFilter } from 'src/views/types/utils/ListFilter'
import styled from 'styled-components'

const { Text } = Typography
const { Option } = Select

const TableCell = styled.div`
  display: table-cell;
  border: 1px solid ${BorderColor};
  background: ${White};
  vertical-align: middle;
`
const FilterButton = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  cursor: pointer;
`

const UpOutlinedIcon = styled(UpOutlined)`
  color: rgba(0, 0, 0, 0.65);
`

const DownOutlinedIcon = styled(DownOutlined)`
  color: rgba(0, 0, 0, 0.65);
`

const SelectFilterTableCell = styled(TableCell)`
  min-width: ${({ theme }) => theme.minWidth};
  background-color: ${({ theme }) => theme.backgroundColor};
  height: 32px;
`

const DropDownTableCell = styled(TableCell)`
  width: 400px;
  background-color: ${White};
  padding: 16px;
`

const styles = {
  clearButtonRow: css`
    display: flex;
    justify-content: flex-end;
  `,
}

interface OwnProps {
  places: PlaceNode[]
  placeNodes: PlaceNode[]
  getFilterTemplates: (filter?: GetTemplatesFilter) => void
  flattenNodePath: FlatNode[]
  request?: GetEmployeesRequest
}

const TemplatesFilterDropDown: React.FC<OwnProps> = (props: OwnProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const [placeNodeIds, setPlaceNodeIds] = useState<string[]>([])
  const [placeGroupNodeIds, setPlaceGroupNodeIds] = useState<string[]>([])
  const { filters, setListFilter } = useListFilter()
  const [templateName, setTemplateName] = useState<string>('')
  const { loginPlaceGroupIds, loginPlaceIds } = splitLoginPlaceUUIDs()

  const debounce = useDebounce(300)
  const placeLoadingStatus = usePlaceLoadingStatus()

  const saveFilter = (conditions: TemplatesFilter) => {
    setListFilter({
      ...(filters ?? {}),
      templates: {
        pagination: props.request,
        filter: conditions,
      },
    })
  }

  const getTemplateFilter = (conditions: TemplatesFilter) => {
    saveFilter(conditions)
    return getFilter(props.placeNodes, props.flattenNodePath, conditions)
  }

  const isFilterOn = () => {
    return (
      templateName || placeGroupNodeIds.length > 0 || placeNodeIds.length > 0
    )
  }

  const clearFilter = () => {
    const conditions = {
      templateName: '',
      placeNodeIds: loginPlaceIds,
      placeGroupNodeIds: loginPlaceGroupIds,
    }
    const filter = getTemplateFilter(conditions)
    props.getFilterTemplates(filter)
    setTemplateName('')
    setPlaceNodeIds([])
    setPlaceGroupNodeIds([])
    saveFilter(conditions)
  }

  useEffect(() => {
    const loginPlaceUUIDs = splitLoginPlaceUUIDs()
    setPlaceNodeIds(loginPlaceUUIDs.loginPlaceIds)
    setPlaceGroupNodeIds(loginPlaceUUIDs.loginPlaceGroupIds)
  }, [])

  useEffect(() => {
    if (placeLoadingStatus !== 'finish') {
      return
    }
    // MEMO: 以下の条件に合致する動作がないので、不要かも知れません。
    if (!filters || !filters.templates) {
      const filter =
        loginPlaceIds.length || loginPlaceGroupIds
          ? getTemplateFilter({
              placeNodeIds: loginPlaceIds,
              placeGroupNodeIds: loginPlaceGroupIds,
            })
          : undefined
      setTemplateName('')
      props.getFilterTemplates(filter)
      setPlaceNodeIds(loginPlaceIds || [])
      setPlaceGroupNodeIds(loginPlaceGroupIds || [])
      return
    }
    const templatesFilter = filters.templates.filter
    let filterPlaceNodeIds: string[] = []
    let filterPlaceGroupNodeIds: string[] = []
    if (
      (templatesFilter?.placeNodeIds ?? []).length > 0 ||
      (templatesFilter?.placeGroupNodeIds ?? []).length > 0
    ) {
      filterPlaceNodeIds = templatesFilter?.placeNodeIds ?? []
      filterPlaceGroupNodeIds = templatesFilter?.placeGroupNodeIds ?? []
    } else {
      filterPlaceNodeIds = loginPlaceIds
      filterPlaceGroupNodeIds = loginPlaceGroupIds
    }
    setTemplateName(filters.templates.filter?.templateName ?? '')
    setPlaceNodeIds(filterPlaceNodeIds)
    setPlaceGroupNodeIds(filterPlaceGroupNodeIds)
    const filter = getTemplateFilter({
      templateName: filters.templates.filter?.templateName,
      placeNodeIds: filterPlaceNodeIds,
      placeGroupNodeIds: filterPlaceGroupNodeIds,
    })
    props.getFilterTemplates(filter)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [placeLoadingStatus])

  const accessiblePlaceNode = splitAccessiblePlaceNode(
    props.placeNodes,
    props.places,
  )

  const handleTemplateNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTemplateName(e.target.value)
    debounce(() => {
      const filter = getTemplateFilter({
        templateName: e.target.value,
        placeNodeIds: placeNodeIds,
        placeGroupNodeIds: placeGroupNodeIds,
      })
      props.getFilterTemplates(filter)
    })
  }

  const groundArea = (
    <SelectFilterTableCell theme={{ width: '160px' }}>
      <FilterButton>
        <div style={{ flex: 1, marginLeft: 12 }}>
          フィルター：{isFilterOn() ? 'ON' : 'OFF'}
        </div>
        <div style={{ minWidth: 20, marginRight: 12 }}>
          {isOpen ? <UpOutlinedIcon /> : <DownOutlinedIcon />}
        </div>
      </FilterButton>
    </SelectFilterTableCell>
  )

  const hoverArea = (
    <div style={{ display: 'flex' }}>
      <DropDownTableCell>
        <div css={styles.clearButtonRow}>
          <Button onClick={clearFilter}>クリア</Button>
        </div>
        <div style={{ marginBottom: 5 }}>
          <Text style={{ marginLeft: 3 }}>ひな形名</Text>
        </div>
        <Input
          allowClear
          onChange={handleTemplateNameChange}
          value={templateName}
        />
        <div style={{ marginTop: 16, marginBottom: 5 }}>
          <Text style={{ marginLeft: 3 }}>現場グループ名</Text>
        </div>
        <Select
          mode="multiple"
          style={{ width: '100%' }}
          value={placeGroupNodeIds}
          optionFilterProp="label"
          onChange={(selectedPlaceGroupIds: string[]) => {
            setPlaceGroupNodeIds(selectedPlaceGroupIds || [])
            const filter = getTemplateFilter({
              templateName: templateName,
              placeNodeIds: placeNodeIds,
              placeGroupNodeIds: selectedPlaceGroupIds || [],
            })
            props.getFilterTemplates(filter)
          }}
        >
          {accessiblePlaceNode.accessiblePlaceGroups.map(
            (placeNode: PlaceNode, index: number) => (
              <Option
                key={index}
                value={placeNode.uuid}
                label={placeNode.placeGroup?.name}
              >
                {placeNode.placeGroup?.name}
              </Option>
            ),
          )}
        </Select>
        <div style={{ marginTop: 16, marginBottom: 5 }}>
          <Text style={{ marginLeft: 3 }}>現場名</Text>
        </div>
        <Select
          mode="multiple"
          style={{ width: '100%' }}
          value={placeNodeIds}
          optionFilterProp="label"
          onChange={(selectedPlaceNodeIds: string[]) => {
            setPlaceNodeIds(selectedPlaceNodeIds || [])
            const filter = getTemplateFilter({
              templateName: templateName,
              placeNodeIds: selectedPlaceNodeIds || [],
              placeGroupNodeIds: placeGroupNodeIds,
            })
            props.getFilterTemplates(filter)
          }}
        >
          {accessiblePlaceNode.accessiblePlaces.map(
            (placeNode: PlaceNode, index: number) => (
              <Option
                key={index}
                value={placeNode.uuid}
                label={placeNode.place?.name}
              >
                {placeNode.place?.name}
              </Option>
            ),
          )}
        </Select>
      </DropDownTableCell>
    </div>
  )

  return (
    <Dropdown
      dropdownRender={() => hoverArea}
      trigger={['click']}
      open={isOpen}
      onOpenChange={setIsOpen}
    >
      {groundArea}
    </Dropdown>
  )
}

export default TemplatesFilterDropDown
