import { css } from '@emotion/react'
import { globalNavigationTheme } from 'src/features/globalNavigation/theme'

export const SubMenuDivider = () => {
  return (
    <div css={styles.container}>
      <div css={styles.divider} />
    </div>
  )
}

const styles = {
  container: css`
    height: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
  `,
  divider: css`
    background-color: ${globalNavigationTheme.colorBorderSubMenu};
    height: 1px;
    width: 100%;
  `,
}
