import { WarningFilled } from '@ant-design/icons'
import { Button, Result } from 'antd'

type Props = {
  onClickRetryButton: () => void
}

/**
 * CSVファイルの送信に失敗したときに表示するメッセージ
 */
export const ResultOfFailure = (props: Props) => {
  const { onClickRetryButton } = props

  return (
    <Result
      extra={
        <Button onClick={onClickRetryButton} type="default">
          もう一度アップロードする
        </Button>
      }
      icon={<WarningFilled style={{ color: '#FAB005' }} />}
      subTitle="もう一度CSVファイルをアップロードし直してください"
      title="アップロードに失敗しました"
    />
  )
}
