import { RightOutlined } from '@ant-design/icons'
import { css } from '@emotion/react'
import {
  autoPlacement,
  FloatingPortal,
  offset,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useTransitionStyles,
} from '@floating-ui/react'
import { HTMLAttributes, ReactNode, useState } from 'react'
import { ClampText } from 'src/components/clampText/ClampText'
import { navItemStyle } from 'src/features/globalNavigation/components/navItem/navItemStyle'

type Props = {
  /**
   * サブメニューのコンテンツ
   */
  renderSubmenu: (closeSubMenu: () => void) => ReactNode
  icon: ReactNode
  /**
   * 折り畳まれているかどうか
   */
  isCollapsed: boolean
  title: string
} & HTMLAttributes<HTMLElement>

/**
 * グローバルナビゲーションの個々のアイテム（ホバー時及びタップ時にサブメニューを表示するタイプ）
 */
export const NavItemSubMenuOpener = (props: Props) => {
  const { icon, isCollapsed, renderSubmenu, title, ...restProps } = props

  const [isOpen, setIsOpen] = useState(false)
  const closeSubMenu = () => setIsOpen(false)

  const { context, refs, floatingStyles } = useFloating({
    middleware: [
      // フローティング要素が見切れる場合には位置を上にずらして調整する
      autoPlacement({ allowedPlacements: ['right-start', 'right-end'] }),
      // フローティング要素とトリガー要素の隙間
      offset(12),
    ],
    open: isOpen,
    onOpenChange: setIsOpen,
  })

  // クリック時やタップ時にフローティング要素を開く。
  const click = useClick(context)

  // ESCボタン押下時や、トリガー要素やフローティング要素の外側をクリックした時に、フローティング要素を閉じる
  const dismiss = useDismiss(context)

  const { getReferenceProps, getFloatingProps } = useInteractions([
    click,
    dismiss,
  ])

  // フローティング要素の開閉時に透過率をアニメーションさせる。
  // isMountedはisOpenのラッパーであり、閉じるアニメーションが終わるまでfalseになるのを遅延させてくれるもの。
  const { isMounted, styles: animatedStyles } = useTransitionStyles(context)

  // 開閉状態に応じて表示内容を切り替える
  let content: ReactNode
  if (isCollapsed) {
    content = <>{icon}</>
  } else {
    content = (
      <>
        <ClampText css={navItemStyle.title}>{title}</ClampText>
        <RightOutlined css={styles.rightArrow} />
      </>
    )
  }

  return (
    <>
      {/* トリガー要素 */}
      <div
        {...restProps}
        {...getReferenceProps()}
        css={[
          navItemStyle.container,
          isCollapsed && navItemStyle.containerCollapsed,
        ]}
        ref={refs.setReference}
        role="button"
      >
        {content}
      </div>

      {/* フローティング要素 */}
      {isMounted && (
        <FloatingPortal>
          <div
            {...getFloatingProps()}
            ref={refs.setFloating}
            style={{
              ...floatingStyles,
              ...animatedStyles,
              // これ以上少なくすると実施状況一覧画面でテーブルの後ろに隠れてしまうので注意
              zIndex: 2,
            }}
          >
            {renderSubmenu(closeSubMenu)}
          </div>
        </FloatingPortal>
      )}
    </>
  )
}

const styles = {
  rightArrow: css`
    margin-left: auto;
    width: 12px;
  `,
}
