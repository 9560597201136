import { CheckOutlined, CloseOutlined } from '@ant-design/icons'
import { Switch, Typography } from 'antd'
import { FC } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { SwitchDisabledColor } from 'src/features/theme/KdsThemeColor'
import { toggleEditable } from 'src/state/ducks/templates/editTemplate/actions'
import { RootState } from 'src/state/store'
import { ScoreSwitchWrap, ToggleWrap } from './EditTemplateOption.styled'

const { Text } = Typography

const EditableSwitch: FC = () => {
  const dispatch = useDispatch()
  const isEditable = useSelector(
    (state: RootState) => state.templatesState.editTemplate.isEditable,
    shallowEqual,
  )
  const templateApprovalFlowId = useSelector(
    (state: RootState) => state.templatesState.editTemplate.approvalFlowId,
  )

  return (
    <ToggleWrap>
      <ScoreSwitchWrap>
        <Switch
          checkedChildren={<CheckOutlined />}
          unCheckedChildren={<CloseOutlined />}
          checked={templateApprovalFlowId ? isEditable : true}
          onChange={checked => dispatch(toggleEditable(checked))}
          disabled={templateApprovalFlowId ? false : true}
          style={
            !isEditable ? { backgroundColor: SwitchDisabledColor } : undefined
          }
        />
      </ScoreSwitchWrap>
      <div>
        <Text strong>レポート完了後の編集可</Text>
      </div>
    </ToggleWrap>
  )
}

export default EditableSwitch
