import { CellMap } from 'src/state/ducks/editGridVariables/types'

/**
 * セル群の中から始端(左上)にあるセルの座標を求める
 *
 * @param cells セル群
 * @returns [行番号(無ければ undefined), 列番号(無ければ undefined)]
 */
export const getStartPosition = (cells: CellMap) => {
  // 選択されている座標のうち、最も行番号が小さいものを探す
  const rowNumber = Math.min(...Object.keys(cells).map(Number))
  if (rowNumber === Infinity) {
    return [undefined, undefined]
  }

  // 上記行の中で、最も列番号が小さいものを探す
  const colNumber = Math.min(...Object.keys(cells[rowNumber] || {}).map(Number))
  if (colNumber === Infinity) {
    return [rowNumber, undefined]
  }

  return [rowNumber, colNumber]
}
