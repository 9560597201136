import React from 'react'
import { useLocation } from 'react-router-dom'
import logoImg from 'src/assets/images/kaminashi_report_logo_flat.png'
import {
  Area,
  Description,
  LinkText,
  MaintenanceTime,
  MaintenanceTitle,
  Note,
  Panel,
  StyledImg,
  Title,
} from './Maintenance.components'

const MaintenanceContainer: React.FC = () => {
  const location = useLocation()

  const params = location.search.replace('?params=', '') || ''

  const [msg, time] = decodeURI(params).split(',')

  return (
    <Area>
      <Panel>
        <StyledImg alt="kaminashi_report_logo_flat" src={logoImg} />
        <Title>システムメンテナンスのお知らせ</Title>
        <Description>{msg}</Description>
        <MaintenanceTitle>[メンテナンス時間]</MaintenanceTitle>
        <MaintenanceTime>{time}</MaintenanceTime>
        <Note>
          なお、上記時間は前後する可能性がございます。
          <br />
          ご利用の皆様には、ご不便をおかけして大変申し訳ありませんが、ご理解いただきますようお願い申し上げます。
          <br />
          詳細な情報につきましては、
          <LinkText
            href="https://support.kaminashi.com/hc/ja/articles/9767728439833-システムメンテナンスのご案内"
            target="_blank"
            rel="noreferrer noopener"
          >
            メンテナンス情報ページ
          </LinkText>
          をご覧ください。
        </Note>
      </Panel>
    </Area>
  )
}

export default MaintenanceContainer
