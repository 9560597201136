import { Spin } from 'antd'
import { FC } from 'react'

type Props = {
  'aria-label'?: string
}

export const Spinner: FC<Props> = props => {
  return (
    <div
      {...props}
      aria-label={props['aria-label'] || '読み込み中'}
      role="progressbar"
    >
      <Spin size="large" />
    </div>
  )
}
