import { pdfjs } from 'react-pdf'
import invariant from 'tiny-invariant'

// `react-pdf`がChrome 119以降にしか存在しない、
// `Promise.withResolvers()`という攻めたAPIを使っているため、しばらくの間はPolyfillする。
// なお、ユニットテストはNode.jsで、ランタイムはブラウザで動く点に注意。
// Chrome 119が出たのが2023/10/25なので、その1年後くらいまでは入れておいたほうが無難そう。
import '@ungap/with-resolvers'

// https://github.com/wojtekmaj/react-pdf/blob/main/packages/react-pdf/README.md#configure-pdfjs-worker
pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.mjs',
  import.meta.url,
).toString()

/**
 * PDFからサムネイル画像（PDFの１ページ目）を作成してData URLとして返す
 */
export const getFirstPageThumbnail = async (pdfFile: File) => {
  const pdfFileArrayBuffer = await pdfFile.arrayBuffer()
  const pdfDoc = await pdfjs.getDocument({ data: pdfFileArrayBuffer }).promise
  const page = await pdfDoc.getPage(1)
  const viewport = page.getViewport({ scale: 1 })

  const canvas = document.createElement('canvas')
  const canvasContext = canvas.getContext('2d')
  invariant(canvasContext)
  canvas.height = viewport.height
  canvas.width = viewport.width

  await page.render({
    canvasContext,
    viewport,
  }).promise

  return canvas.toDataURL('image/png')
}
