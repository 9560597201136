import { Template } from '@ulysses-inc/harami_api_client'
import { Select } from 'antd'
import React, { ComponentProps } from 'react'
import FormikErrorMessage from 'src/components/formikErrorMessage/FormikErrorMessage'
import { TBaseFormRowProps } from './EditSchedule'
import {
  FormLabel,
  FormRow,
  Option,
  TemplateSelect,
  Text,
} from './EditSchedule.styled'

const ScheduleTemplateRow: React.FC<
  TBaseFormRowProps<{
    templates: Template[]
  }>
> = ({
  templates: existingTemplates,
  setFieldValue,
  values: { templates },
  errors,
  touched,
}) => {
  const onChange: ComponentProps<typeof Select>['onChange'] = id => {
    setFieldValue('templates', [{ id }])
  }

  return (
    <FormRow>
      <FormLabel>
        <Text strong>ひな形</Text>
      </FormLabel>
      <div>
        {existingTemplates.length > 0 && (
          <TemplateSelect
            showSearch
            optionFilterProp="children"
            value={(templates ?? [])[0]?.id}
            onChange={onChange}
          >
            {existingTemplates.map(({ id, name }: Template) => (
              <Option key={id} value={id || 0}>
                {name}
              </Option>
            ))}
          </TemplateSelect>
        )}
      </div>
      <div>
        <FormikErrorMessage
          name="templates"
          errors={errors}
          touched={touched}
        />
      </div>
    </FormRow>
  )
}

export default ScheduleTemplateRow
