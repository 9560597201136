import {
  TemplateNodeSchema,
  TemplateNodeTypeEnum,
  TemplateQuestion,
} from '@ulysses-inc/harami_api_client'
import Redux from 'redux'
import { UUID } from 'src/exShared/util/uuid'
import {
  addTemplateNodeIntoNode,
  addTemplateNodeIntoPage,
} from 'src/state/ducks/templates/actions'

interface CreateNodeOptions {
  addTemplateNodeType: TemplateNodeTypeEnum
  targetNode: TemplateNodeSchema | null
  parentNode: TemplateNodeSchema | null
  position: 'top' | 'bottom'
  question?: TemplateQuestion
}

// 新規にQuestionかSectionを作成する処理
// FIXME: templateNodesは後段の処理でnodeのidの最大値を取得する処理でしか使われていないので、
//        idの最大値をTemplateStateから参照する形にすれば、引数としては不要になる
const createNode = (
  templateNodes: { [key: number]: TemplateNodeSchema },
  dispatch: Redux.Dispatch,
  opts: CreateNodeOptions,
) => {
  if (opts.parentNode === null) {
    createNodeIntoPage(templateNodes, dispatch, opts)
  } else {
    createNodeIntoNode(templateNodes, dispatch, opts)
  }
}

// 新規にnodeをpage内に作成する処理
// nodeのtypeがQuestionかSectionかで分岐しているが、
// アクティブになっているページのnodeを追加しているだけである
const createNodeIntoPage = (
  templateNodes: { [key: number]: TemplateNodeSchema },
  dispatch: Redux.Dispatch,
  opts: CreateNodeOptions,
) => {
  if (opts.addTemplateNodeType === TemplateNodeTypeEnum.Question) {
    const [createNodeId, createNode] = getCreateQuestionNode(
      templateNodes,
      opts.question ?? {},
    )
    dispatch(
      addTemplateNodeIntoPage(
        opts.targetNode,
        createNode,
        createNodeId,
        opts.position,
      ),
    )
  }
  if (opts.addTemplateNodeType === TemplateNodeTypeEnum.Section) {
    const [createNodeId, createNode] = getCreateSectionNode(templateNodes)
    dispatch(
      addTemplateNodeIntoPage(
        opts.targetNode,
        createNode,
        createNodeId,
        opts.position,
      ),
    )
  }
}

const createNodeIntoNode = (
  templateNodes: { [key: number]: TemplateNodeSchema },
  dispatch: Redux.Dispatch,
  opts: CreateNodeOptions,
) => {
  if (opts.addTemplateNodeType === TemplateNodeTypeEnum.Question) {
    const [createNodeId, createNode] = getCreateQuestionNode(
      templateNodes,
      opts.question ?? {},
    )
    dispatch(
      addTemplateNodeIntoNode(
        opts.targetNode,
        opts.parentNode,
        createNode,
        createNodeId,
        opts.position,
      ),
    )
  }
  if (opts.addTemplateNodeType === TemplateNodeTypeEnum.Section) {
    const [createNodeId, createNode] = getCreateSectionNode(templateNodes)
    dispatch(
      addTemplateNodeIntoNode(
        opts.targetNode,
        opts.parentNode,
        createNode,
        createNodeId,
        opts.position,
      ),
    )
  }
}

const getCreateQuestionNode = (
  templateNodes: { [key: number]: TemplateNodeSchema },
  question: TemplateQuestion,
): [number, TemplateNodeSchema] => {
  const createNodeId = getCreateNodeId(templateNodes)
  return [createNodeId, createNewQuestionNode(createNodeId, question)]
}

const getCreateSectionNode = (templateNodes: {
  [key: number]: TemplateNodeSchema
}): [number, TemplateNodeSchema] => {
  const createNodeId = getCreateNodeId(templateNodes)
  return [createNodeId, createNewSectionNode(createNodeId)]
}

// templateNodes内のid(正の自然数)の最大値 +1 の値を生成している
const getCreateNodeId = (templateNodes: {
  [key: number]: TemplateNodeSchema
}): number => {
  return Math.max(...Object.keys(templateNodes).map(Number), 0) + 1
}

// TemplateNodeTypeがQuestionのnodeを新規作成している
const createNewQuestionNode = (id: number, question?: TemplateQuestion) => {
  return {
    ...createNewTemplateNode(id, TemplateNodeTypeEnum.Question),
    // targetNodeのquestionの項目をコピーして新規作成している
    question: {
      ...question,
      isShowInformationImages: 0,
      informationImages: [],
      hints: [],
      name: '',
      deviateComment: '逸脱しています。責任者に確認してください。',
    },
  }
}

// TemplateNodeTypeがSectionのnodeを新規作成している
const createNewSectionNode = (id: number) => {
  return {
    ...createNewTemplateNode(id, TemplateNodeTypeEnum.Section),
    section: { name: '' },
  }
}

const createNewTemplateNode = (
  id: number,
  type: TemplateNodeTypeEnum,
): TemplateNodeSchema => {
  return {
    id: id,
    uuid: UUID(),
    type: type,
    nodes: [],
  }
}

// FIXME: getCreateSectionNode, getCreateQuestionNodeをreducerから使用するようにしたが
// 本ファイルがoperationに依存しているため相互依存の構成になってしまっているので、要リファクタ
export { createNode, getCreateQuestionNode, getCreateSectionNode }
