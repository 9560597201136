import { EditorCore } from '@react-editor-js/core/src/editor-core'
import { TemplateManual } from '@ulysses-inc/harami_api_client'
import React, { useRef } from 'react'
import { createReactEditorJS } from 'react-editor-js'
import { useDispatch } from 'react-redux'
import { editorJsCommonSettings } from 'src/features/templateEdit/common/editorJsCommonSettings'
import { updateTemplateManual as updateTemplateManualAction } from 'src/state/ducks/templates/editTemplate/actions'
import {
  EditorModal,
  ModalTitle,
  ModalTitleWrap,
} from './EditManualModal.styled'

const ReactEditorJS = createReactEditorJS()

interface OwnProps {
  templateManual: TemplateManual
  isOpenModal: boolean
  setIsOpenModal: (isOpenModal: boolean) => void
  onClose: () => void
  blocks: Array<any> | null
}

const EditManualModalContainer: React.FC<OwnProps> = ({
  isOpenModal,
  onClose,
  blocks,
  templateManual,
  setIsOpenModal,
}) => {
  const editorCore = useRef<EditorCore | null>(null)

  const dispatch = useDispatch()
  const updateTemplateManual = (templateManual: TemplateManual) =>
    dispatch(updateTemplateManualAction(templateManual))

  const onSubmit = async () => {
    const savedData = await editorCore.current?.save()

    // TODO error handling
    if (!savedData) return

    updateTemplateManual({ ...templateManual, blocks: savedData.blocks })
    setIsOpenModal(false)
  }

  return (
    <EditorModal
      title={
        <ModalTitleWrap>
          <ModalTitle level={4}>マニュアルの編集</ModalTitle>
        </ModalTitleWrap>
      }
      open={isOpenModal}
      destroyOnClose={true}
      onOk={onSubmit}
      onCancel={onClose}
      okText="保存"
      cancelText="キャンセル"
    >
      {isOpenModal && blocks !== null && (
        <ReactEditorJS
          onInitialize={instance => {
            editorCore.current = instance
          }}
          tools={editorJsCommonSettings.tools}
          defaultValue={{
            time: editorJsCommonSettings.outputOption.time,
            blocks: blocks?.length
              ? blocks
              : editorJsCommonSettings.initialBlocks,
            version: editorJsCommonSettings.outputOption.version,
          }}
          placeholder="こちらをクリックして入力してください"
        />
      )}
    </EditorModal>
  )
}

export default EditManualModalContainer
