import { DownOutlined, UpOutlined } from '@ant-design/icons'
import { css } from '@emotion/react'
import {
  ExcelConversionFlow,
  ExcelConversionLogStatusEnum,
  GetExcelConversionLogsRequest,
  PlaceNode,
} from '@ulysses-inc/harami_api_client'
import { Button, Dropdown, Select, Typography } from 'antd'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { MomentDatePicker } from 'src/components/datepicker/MomentDatePicker'
import { getStatusName } from 'src/constants/excelConversionLogStatus'
import {
  initializePlaceFilter,
  splitAccessiblePlaceNode,
} from 'src/exShared/libraries/filterDropDown/place'
import date from 'src/exShared/util/date'
import { mergedPlaceNodeIds } from 'src/exShared/util/place/placeNode'
import { splitLoginPlaceUUIDs } from 'src/exShared/util/place/splitLoginPlace'
import { useListFilter } from 'src/hooks/filter/useListFilter'
import { GetExcelConversionLogsFilter } from 'src/state/ducks/excelConversionLogs/types'
import styled from 'styled-components'
import { BorderColor, White } from '../theme/KdsThemeColor'
import { getStatusBadge } from './ExcelConversionLogStatus'

const { Text } = Typography
const { Option } = Select

const TableCell = styled.div`
  display: table-cell;
  border: 1px solid ${BorderColor};
  background: ${White};
  vertical-align: middle;
`
const FilterButton = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  cursor: pointer;
`

const UpOutlinedIcon = styled(UpOutlined)`
  color: rgba(0, 0, 0, 0.65);
`

const DownOutlinedIcon = styled(DownOutlined)`
  color: rgba(0, 0, 0, 0.65);
`

const SelectFilterTableCell = styled(TableCell)`
  min-width: ${({ theme }) => theme.minWidth};
  background-color: ${({ theme }) => theme.backgroundColor};
  height: 32px;
`

const DropDownTableCell = styled(TableCell)`
  width: 400px;
  background-color: ${White};
  padding: 16px;
`

const styles = {
  clearButtonRow: css`
    display: flex;
    justify-content: flex-end;
  `,
}

interface OwnProps {
  flows: ExcelConversionFlow[]
  places: PlaceNode[]
  placeNodes: PlaceNode[]
  getExcelConversionLogs: (filter?: GetExcelConversionLogsFilter) => void
  isLoadingPlace?: boolean
  logGetTrigger?: boolean
  request?: GetExcelConversionLogsRequest
}

const ExcelConversionLogStatusOptions: { [key: string]: number } = {
  [getStatusName(ExcelConversionLogStatusEnum.SUCCESS)]:
    ExcelConversionLogStatusEnum.SUCCESS,
  [getStatusName(ExcelConversionLogStatusEnum.FAILED)]:
    ExcelConversionLogStatusEnum.FAILED,
}

const ExcelConversionLogFilterDropDown: React.FC<OwnProps> = (
  props: OwnProps,
) => {
  const [isOpen, setIsOpen] = useState(false)
  const [flowIds, setFlowIds] = useState<string[]>([])
  const [status, setStatus] = useState<number | undefined>(undefined)
  const [placeGroupNodeIds, setPlaceGroupNodeIds] = useState<string[]>([])
  const [placeNodeIds, setPlaceNodeIds] = useState<string[]>([])
  const [from, setFrom] = useState<string | undefined>(undefined)
  const [through, setThrough] = useState<string | undefined>(undefined)
  const { filters, setListFilter } = useListFilter()

  const { loginPlaceGroupIds, loginPlaceIds } = splitLoginPlaceUUIDs()

  useEffect(() => {
    if (!props.logGetTrigger || props.isLoadingPlace) {
      return
    }
    if (!filters?.excelConversionLogs) {
      const placeFilter = initializePlaceFilter(props.placeNodes)
      if (placeFilter === undefined || placeFilter.$in.length === 0) {
        props.getExcelConversionLogs()
        return
      }
      const filter = { placeNodeUUIDs: placeFilter }
      props.getExcelConversionLogs(filter)
      setPlaceNodeIds(loginPlaceIds)
      setPlaceGroupNodeIds(loginPlaceGroupIds)
      return
    }
    const logFilter = filters.excelConversionLogs?.filter
    let filterPlaceNodeIds: string[] = []
    let filterPlaceGroupNodeIds: string[] = []
    if (
      (logFilter?.placeNodeIds ?? []).length > 0 ||
      (logFilter?.placeGroupNodeIds ?? []).length > 0
    ) {
      filterPlaceNodeIds = logFilter?.placeNodeIds ?? []
      filterPlaceGroupNodeIds = logFilter?.placeGroupNodeIds ?? []
    } else {
      filterPlaceNodeIds = loginPlaceIds
      filterPlaceGroupNodeIds = loginPlaceGroupIds
    }
    setPlaceNodeIds(filterPlaceNodeIds)
    setPlaceGroupNodeIds(filterPlaceGroupNodeIds)
    setFlowIds(logFilter?.flowUUIDs ?? [])
    setStatus(logFilter?.status)
    setFrom(logFilter?.logDate?.from)
    setThrough(logFilter?.logDate?.through)
    const filter = getFilter(
      logFilter?.flowUUIDs,
      logFilter?.status,
      filterPlaceNodeIds,
      filterPlaceGroupNodeIds,
      logFilter?.logDate?.from,
      logFilter?.logDate?.through,
    )
    props.getExcelConversionLogs(filter)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.placeNodes.length, props.isLoadingPlace])

  const accessiblePlaceNode = splitAccessiblePlaceNode(
    props.placeNodes,
    props.places,
  )

  const isFilterOn = () => {
    return (
      flowIds.length > 0 ||
      from !== undefined ||
      placeGroupNodeIds.length > 0 ||
      placeNodeIds.length > 0 ||
      through !== undefined ||
      status !== undefined
    )
  }

  const clearFilter = () => {
    const filter = getFilter([], undefined, loginPlaceIds, loginPlaceGroupIds)
    props.getExcelConversionLogs(filter)
    setPlaceNodeIds([])
    setPlaceGroupNodeIds([])
    setFlowIds([])
    setStatus(undefined)
    setFrom(undefined)
    setThrough(undefined)
    saveFilter()
  }

  const saveFilter = (
    flowIds?: string[],
    status?: number,
    placeNodeIds?: string[],
    placeGroupNodeIds?: string[],
    from?: string,
    through?: string,
  ) => {
    setListFilter({
      ...(filters ?? {}),
      excelConversionLogs: {
        pagination: props.request,
        filter: {
          flowUUIDs: flowIds,
          placeNodeIds: placeNodeIds,
          placeGroupNodeIds: placeGroupNodeIds,
          logDate: { from, through },
          status: status,
        },
      },
    })
  }

  const getFilter = (
    flowIds?: string[],
    status?: number,
    placeNodeIds?: string[],
    placeGroupNodeIds?: string[],
    from?: string,
    through?: string,
  ) => {
    const allPlaceNodeIds = mergedPlaceNodeIds(
      props.placeNodes,
      placeNodeIds ?? [],
      placeGroupNodeIds ?? [],
    )
    const flowIdCondition =
      flowIds && flowIds.length > 0 ? { $in: flowIds } : undefined
    // throughは画面表示の日付＋１日する
    const logDateCondition =
      from && through
        ? {
            $gte: date.parseYYYYMMDD(from),
            $lte: date.nextDay(date.parseYYYYMMDD(through)),
          }
        : undefined
    const placeNodeIdCondition =
      allPlaceNodeIds && allPlaceNodeIds.length > 0
        ? { $in: allPlaceNodeIds }
        : undefined
    const statusCondition = status !== undefined ? { $in: [status] } : undefined
    saveFilter(flowIds, status, placeNodeIds, placeGroupNodeIds, from, through)
    return {
      flowUUIDs: flowIdCondition,
      logDate: logDateCondition,
      placeNodeUUIDs: placeNodeIdCondition,
      status: statusCondition,
    }
  }

  const groundArea = (
    <SelectFilterTableCell theme={{ width: '160px' }}>
      <FilterButton>
        <div style={{ flex: 1, marginLeft: 12 }}>
          フィルター：{isFilterOn() ? 'ON' : 'OFF'}
        </div>
        <div style={{ minWidth: 20, marginRight: 12 }}>
          {isOpen ? <UpOutlinedIcon /> : <DownOutlinedIcon />}
        </div>
      </FilterButton>
    </SelectFilterTableCell>
  )

  const hoverArea = (
    <div style={{ display: 'flex' }}>
      <DropDownTableCell>
        <div css={styles.clearButtonRow}>
          <Button onClick={clearFilter}>クリア</Button>
        </div>
        <div style={{ marginBottom: 5 }}>
          <Text style={{ marginLeft: 3 }}>変換フロー名</Text>
        </div>
        <Select
          mode="multiple"
          style={{ width: '100%' }}
          value={flowIds}
          optionFilterProp="label"
          onChange={(selectedFlowIds: string[] | undefined) => {
            setFlowIds(selectedFlowIds || [])
            const filterPlaceNodeIds =
              placeNodeIds.length > 0 || placeGroupNodeIds.length > 0
                ? placeNodeIds
                : loginPlaceIds
            const filterPlaceGroupNodeIds =
              placeNodeIds.length > 0 || placeGroupNodeIds.length > 0
                ? placeGroupNodeIds
                : loginPlaceGroupIds
            const filter = getFilter(
              selectedFlowIds || [],
              status,
              filterPlaceNodeIds,
              filterPlaceGroupNodeIds,
              from,
              through,
            )
            props.getExcelConversionLogs(filter)
          }}
        >
          {props.flows.map((flow: ExcelConversionFlow, index: number) => (
            <Option key={index} value={flow.uuid || ''} label={flow.name}>
              {flow.name}
            </Option>
          ))}
        </Select>
        <div style={{ marginTop: 16, marginBottom: 5 }}>
          <Text style={{ marginLeft: 3 }}>実行結果</Text>
        </div>
        <Select
          style={{ width: '100%' }}
          value={status}
          onChange={(status: number | undefined) => {
            setStatus(status)
            const filterPlaceNodeIds =
              placeNodeIds.length > 0 || placeGroupNodeIds.length > 0
                ? placeNodeIds
                : loginPlaceIds
            const filterPlaceGroupNodeIds =
              placeNodeIds.length > 0 || placeGroupNodeIds.length > 0
                ? placeGroupNodeIds
                : loginPlaceGroupIds
            const filter = getFilter(
              flowIds,
              status,
              filterPlaceNodeIds,
              filterPlaceGroupNodeIds,
              from,
              through,
            )
            props.getExcelConversionLogs(filter)
          }}
          allowClear
        >
          {Object.entries(ExcelConversionLogStatusOptions).map(
            ([label, value]) => (
              <Option key={value} value={value} label={label}>
                {getStatusBadge(value)}
              </Option>
            ),
          )}
        </Select>
        <div style={{ marginTop: 16, marginBottom: 5 }}>
          <Text style={{ marginLeft: 3 }}>現場グループ名</Text>
        </div>
        <Select
          mode="multiple"
          style={{ width: '100%' }}
          value={placeGroupNodeIds}
          optionFilterProp="label"
          onChange={(selectedPlaceGroupIds: string[] | undefined) => {
            setPlaceGroupNodeIds(selectedPlaceGroupIds || [])
            const filterPlaceNodeIds =
              placeNodeIds.length > 0 ||
              (selectedPlaceGroupIds ?? []).length > 0
                ? placeNodeIds
                : loginPlaceIds
            const filterPlaceGroupNodeIds =
              placeNodeIds.length > 0 ||
              (selectedPlaceGroupIds ?? []).length > 0
                ? selectedPlaceGroupIds
                : loginPlaceGroupIds
            const filter = getFilter(
              flowIds,
              status,
              filterPlaceNodeIds,
              filterPlaceGroupNodeIds || [],
              from,
              through,
            )
            props.getExcelConversionLogs(filter)
          }}
        >
          {accessiblePlaceNode.accessiblePlaceGroups.map(
            (placeNode: PlaceNode, index: number) => (
              <Option
                key={index}
                value={placeNode.uuid}
                label={placeNode.placeGroup?.name}
              >
                {placeNode.placeGroup?.name}
              </Option>
            ),
          )}
        </Select>
        <div style={{ marginTop: 16, marginBottom: 5 }}>
          <Text style={{ marginLeft: 3 }}>現場名</Text>
        </div>
        <Select
          mode="multiple"
          style={{ width: '100%' }}
          value={placeNodeIds}
          optionFilterProp="label"
          onChange={(selectedPlaceNodeIds: string[] | undefined) => {
            setPlaceNodeIds(selectedPlaceNodeIds || [])
            const filterPlaceNodeIds =
              (selectedPlaceNodeIds ?? []).length > 0 ||
              placeGroupNodeIds.length > 0
                ? selectedPlaceNodeIds
                : loginPlaceIds
            const filterPlaceGroupNodeIds =
              (selectedPlaceNodeIds ?? []).length > 0 ||
              placeGroupNodeIds.length > 0
                ? placeGroupNodeIds
                : loginPlaceGroupIds
            const filter = getFilter(
              flowIds,
              status,
              filterPlaceNodeIds || [],
              filterPlaceGroupNodeIds,
              from,
              through,
            )
            props.getExcelConversionLogs(filter)
          }}
        >
          {accessiblePlaceNode.accessiblePlaces.map(
            (placeNode: PlaceNode, index: number) => (
              <Option
                key={index}
                value={placeNode.uuid}
                label={placeNode.place?.name}
              >
                {placeNode.place?.name}
              </Option>
            ),
          )}
        </Select>
        <div style={{ marginTop: 16, marginBottom: 5 }}>
          <Text style={{ marginLeft: 3 }}>実施日</Text>
        </div>
        <MomentDatePicker.RangePicker
          placeholder={['開始日', '終了日']}
          value={
            from && through ? [moment(from), moment(through)] : [null, null]
          }
          onChange={(_, dateStrings: [string, string]) => {
            const filterPlaceNodeIds =
              placeNodeIds.length > 0 || placeGroupNodeIds.length > 0
                ? placeNodeIds
                : loginPlaceIds
            const filterPlaceGroupNodeIds =
              placeNodeIds.length > 0 || placeGroupNodeIds.length > 0
                ? placeGroupNodeIds
                : loginPlaceGroupIds
            if (
              dateStrings === undefined ||
              dateStrings[0] === undefined ||
              dateStrings[1] === undefined
            ) {
              setFrom(undefined)
              setThrough(undefined)
              const filter = getFilter(
                flowIds,
                status,
                filterPlaceNodeIds,
                filterPlaceGroupNodeIds,
                undefined,
                undefined,
              )
              props.getExcelConversionLogs(filter)
            } else {
              setFrom(dateStrings[0])
              setThrough(dateStrings[1])
              const filter = getFilter(
                flowIds,
                status,
                filterPlaceNodeIds,
                filterPlaceGroupNodeIds,
                dateStrings[0],
                dateStrings[1],
              )
              props.getExcelConversionLogs(filter)
            }
          }}
        />
      </DropDownTableCell>
    </div>
  )

  return (
    <Dropdown
      dropdownRender={() => hoverArea}
      trigger={['click']}
      open={isOpen}
      onOpenChange={setIsOpen}
    >
      {groundArea}
    </Dropdown>
  )
}

export default ExcelConversionLogFilterDropDown
