import { ReportNodeSchema } from '@ulysses-inc/harami_api_client'

const getChildNodes = (
  nodeIds: number[],
  nodes: { [key: number]: ReportNodeSchema },
): ReportNodeSchema[] => {
  if (nodeIds.length === 0) return []
  let returns = []
  const targets = nodeIds.map(id => nodes[id]).filter(n => n)
  returns = returns.concat(targets)
  const children = targets
    .map(target => getChildNodes(target.nodes, nodes))
    .flat()
  returns = returns.concat(children)
  return returns
}

export default getChildNodes
