import { FC } from 'react'
import { CellNotWorked, HorizontalLine } from './Common.dumb'

export const TableBodyCellNotWorked: FC = () => {
  return (
    <CellNotWorked>
      <HorizontalLine />
    </CellNotWorked>
  )
}
