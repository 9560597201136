import { ResponseSetExcelOutputSettingEnum } from '@ulysses-inc/harami_api_client'
import { Radio, Typography } from 'antd'
import { RadioChangeEvent } from 'antd/lib/radio'
import { FC } from 'react'
import FormikErrorMessage from 'src/components/formikErrorMessage/FormikErrorMessage'
import { useLimitedFeatureAvailability } from 'src/features/featureAvailability/useLimitedFeatureAvailability'
import { FormLabel } from './EditMultipleChoiceSet.dumb'
import { useFormikProps } from './EditMultipleChoiceSet.hooks'

const { Text } = Typography

const ExcelConversionSettingsRow: FC = () => {
  const {
    formikProps: { setFieldValue, values, errors, touched },
  } = useFormikProps()

  const { isFeatureAvailable } = useLimitedFeatureAvailability()

  return isFeatureAvailable('excelConversionEdit') ? (
    <>
      <FormLabel>
        <Text strong>EXCEL出力設定</Text>
      </FormLabel>
      <div>
        <Radio.Group
          onChange={(e: RadioChangeEvent) => {
            setFieldValue('excelOutputOption', e.target.value)
          }}
          value={values.excelOutputOption}
        >
          <Radio value={ResponseSetExcelOutputSettingEnum.DiscriminationKey}>
            「判別キー」をEXCEL変換時使用する
          </Radio>
          <Radio value={ResponseSetExcelOutputSettingEnum.Name}>
            「選択肢名」をEXCEL変換時使用する
          </Radio>
        </Radio.Group>
      </div>
      <div>
        <FormikErrorMessage
          name="excelOutputOption"
          errors={errors}
          touched={touched}
        />
      </div>
    </>
  ) : null
}

export default ExcelConversionSettingsRow
