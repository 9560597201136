/** ダイナミック帳票 phase 3 時点では、テーブルの行数を 1000 に固定する */
export const ROW_COUNT = 1000

/**
 * ダイナミック帳票 phase 3 時点では、テーブルの列数を 11 に固定する
 * - 0 列目: 行番号(#)
 * - 1 列目: 適用開始日
 * - 2 列目: 適用終了日
 * - 3 列目: セクション名
 * - 4 列目: 取り込みセクション
 * - 5〜10 列目: 取り込み項目 1〜6
 */
export const COL_COUNT = 11

/**
 * 適用開始日、終了日のDatePickerで使うフォーマット
 */
export const DATE_PICKER_FORMAT = 'YYYY/MM/DD'
