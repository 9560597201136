import { v4 as uuidv4 } from 'uuid'

let fakeUUID = ''
const setFakeUUID = (uuid: string) => {
  fakeUUID = uuid
}

const resetFakeUUID = () => {
  fakeUUID = ''
}

const UUID = (): string => {
  if (fakeUUID !== '') {
    return fakeUUID
  }
  return uuidv4()
}

export { UUID, resetFakeUUID, setFakeUUID }
