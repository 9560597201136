import { css } from '@emotion/react'
import { ReactNode } from 'react'
import { AppBackgroundColor } from 'src/features/theme/KdsThemeColor'

type Props = {
  children: ReactNode
  overlay?: ReactNode
}

export const SubHeader = ({ children, overlay }: Props) => {
  const isOverlayVisible = !!overlay

  return (
    <div css={styles.container}>
      <div css={styles.grid}>{children}</div>
      {isOverlayVisible && <div css={styles.overlay}>{overlay}</div>}
    </div>
  )
}

const styles = {
  container: css`
    align-items: center;
    display: flex;
    height: 64px;
    justify-content: flex-end;
    padding: 0 24px;
    position: relative;
  `,
  grid: css`
    display: grid;
    grid-auto-flow: column;
    grid-gap: 16px;
  `,
  overlay: css`
    background: ${AppBackgroundColor};
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  `,
}
