import { StyleSheet } from '@react-pdf/renderer'
import { Gray } from 'src/features/theme/KdsThemeColor'

export const styles = StyleSheet.create({
  recordedAtText: {
    fontSize: '10px',
    'text-align': 'center',
    color: Gray,
  },
})
