// prettier-ignore
export const ActionTypes = {
  REQUEST_ADD_MULTIPLE_CHOICES: 'harami/templates/REQUEST_ADD_MULTIPLE_CHOICES',
  SUCCESS_ADD_MULTIPLE_CHOICES: 'harami/templates/SUCCESS_ADD_MULTIPLE_CHOICES',
  ERROR_ADD_MULTIPLE_CHOICES: 'harami/templates/ERROR_ADD_MULTIPLE_CHOICES',
  REQUEST_UPDATE_MULTIPLE_CHOICES: 'harami/templates/REQUEST_UPDATE_MULTIPLE_CHOICES',
  SUCCESS_UPDATE_MULTIPLE_CHOICES: 'harami/templates/SUCCESS_UPDATE_MULTIPLE_CHOICES',
  ERROR_UPDATE_MULTIPLE_CHOICES: 'harami/templates/ERROR_UPDATE_MULTIPLE_CHOICES',
  REQUEST_DELETE_MULTIPLE_CHOICES: 'harami/templates/REQUEST_DELETE_MULTIPLE_CHOICES',
  SUCCESS_DELETE_MULTIPLE_CHOICES: 'harami/templates/SUCCESS_DELETE_MULTIPLE_CHOICES',
  ERROR_DELETE_MULTIPLE_CHOICES: 'harami/templates/ERROR_DELETE_MULTIPLE_CHOICES',
  REQUEST_GET_MULTIPLE_CHOICES: 'harami/templates/REQUEST_GET_MULTIPLE_CHOICES',
  SUCCESS_GET_MULTIPLE_CHOICES: 'harami/templates/SUCCESS_GET_MULTIPLE_CHOICES',
  ERROR_GET_MULTIPLE_CHOICES: 'harami/templates/ERROR_GET_MULTIPLE_CHOICES',
  REQUEST_GET_TEMPLATES_BY_MULTIPLE_CHOICE_GROUP: 'harami/templates/REQUEST_GET_TEMPLATES_BY_MULTIPLE_CHOICE_GROUP',
  SUCCESS_GET_TEMPLATES_BY_MULTIPLE_CHOICE_GROUP: 'harami/templates/SUCCESS_GET_TEMPLATES_BY_MULTIPLE_CHOICE_GROUP',
  ERROR_GET_TEMPLATES_BY_MULTIPLE_CHOICE_GROUP: 'harami/templates/ERROR_GET_TEMPLATES_BY_MULTIPLE_CHOICE_GROUP',
  RESET_RELATED_TEMPLATES: 'harami/templates/RESET_RELATED_TEMPLATES',
} as const
