import {
  CaretDownOutlined,
  CaretRightOutlined,
  TagOutlined,
  UserOutlined,
} from '@ant-design/icons'
import { User, UserGroup } from '@ulysses-inc/harami_api_client'
import { Button, List, Modal, Row, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import LoadingOverlay from 'src/components/loading/LoadingOverlay'
import { RowActionButton } from 'src/components/rowActionButton/RowActionButton'
import {
  ModalDeleteButtonColor,
  TableBorderColor,
  White,
} from 'src/features/theme/KdsThemeColor'
import usersOperations from 'src/state/ducks/users/operations'
import { RootState } from 'src/state/store'
import styled from 'styled-components'

const { Text } = Typography

const UserGroupContainer = styled(Row)`
  margin: 15px;
  width: 100%;
  min-width: 600px;
  overflow-x: auto;
  padding-right: 30px;
`

const UserGroupTableHeader = styled.div`
  width: 100%;
  height: 46px;
  display: flex;
  align-items: center;
  flex-direction: row;
  background: ${White};
  border-bottom: 1px solid ${TableBorderColor};
`

const UserGroupTableHeaderText = styled.div`
  width: 100%;
  font-weight: bold;
  padding-left: 15px;
`

const UserGroupRow = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: auto;
  min-height: 45px;
  padding-left: 15px;
`

const UserGroupTreeIconButton = styled(Button)`
  display: flex;
  align-items: center;
  height: auto;
  border: none;
  user-select: none;
  padding: 0;
`

const UserGroupName = styled.span`
  text-align: left;
  white-space: pre-wrap;
  word-break: break-all;
`

const ActionButtonGroup = styled.div`
  position: absolute;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  flex-direction: row;
  height: 100%;
  right: 0;
`

const UserNameWrap = styled.div`
  width: 100%;
  min-height: 45px;
  padding-left: 45px;
  display: flex;
  align-items: center;
  border-top: 0.5px solid #e8e8e8;
`

const UserName = styled(Text)`
  word-break: break-all;
  margin-left: 8px;
`

interface Props {
  userGroups: UserGroup[]
}

const UserGroupList: React.FC<Props> = (props: Props) => {
  const dispatch = useDispatch()

  const {
    userGroups: {
      userGroupDeleteValidateLoading,
      userGroupDeleteValidateResult,
      userGroupDeleteValidateErrorMessage,
    },
  } = useSelector((state: RootState) => state.usersState)

  const [activeUserGroupId, setActiveUserGroupId] = useState<number>()
  const [collapsedGroup, setCllapsedGroup] = useState<number[]>([])
  const [activeDeleteUserGroup, setActiveDeleteUserGroup] = useState<
    UserGroup | undefined
  >(undefined)

  const openEditUserGroup = (userGroup: UserGroup) => {
    usersOperations.updateActiveUserGroupId(dispatch, userGroup.id)
    usersOperations.changeIsShowEditUserGroupDrawer(dispatch, true)
  }
  const closeGroup = (userGroupId: number) => {
    setCllapsedGroup([...collapsedGroup, userGroupId])
  }
  const openGroup = (userGroupId: number) => {
    setCllapsedGroup([...collapsedGroup.filter(id => id !== userGroupId)])
  }
  const validateDeleteUserGroup = (userGroupId: number) => {
    usersOperations.validateDeleteUserGroup(dispatch, userGroupId)
  }

  useEffect(() => {
    if (userGroupDeleteValidateResult !== 'cannotDelete') return

    const splitErrMsg = userGroupDeleteValidateErrorMessage.split('\n')
    usersOperations.resetValidateDeleteUserGroup(dispatch)

    if (activeDeleteUserGroup === undefined) {
      return
    }
    Modal.error({
      onOk: () => usersOperations.resetValidateDeleteUserGroup(dispatch),
      title: (
        <div>
          <b>「{activeDeleteUserGroup.name}」の削除が出来ません</b>
        </div>
      ),
      width: 470,
      content: (
        <div>
          <p>
            未完了のカイゼンのメール返送先・送信先に削除対象のユーザーグループが含まれる場合、ユーザーグループの削除が出来ません。
          </p>
          <p>
            <b>削除が出来ない理由</b>
          </p>
          {splitErrMsg.map((msg, i) => {
            return <div key={i}>・{msg}</div>
          })}
        </div>
      ),
      okText: '閉じる',
      afterClose: () => {
        setActiveDeleteUserGroup(undefined)
      },
    })
  }, [
    activeDeleteUserGroup,
    dispatch,
    userGroupDeleteValidateErrorMessage,
    userGroupDeleteValidateResult,
  ])

  useEffect(() => {
    if (userGroupDeleteValidateResult !== 'canDelete') return

    usersOperations.resetValidateDeleteUserGroup(dispatch)

    if (activeDeleteUserGroup === undefined) return

    Modal.confirm({
      onOk: () =>
        usersOperations.deleteUserGroup(dispatch, activeDeleteUserGroup.id),
      title: `「${activeDeleteUserGroup.name}」の削除を実行しますがよろしいですか？`,
      okText: '削除',
      cancelText: 'キャンセル',
      afterClose: () => {
        setActiveDeleteUserGroup(undefined)
      },
      okButtonProps: {
        style: {
          backgroundColor: ModalDeleteButtonColor,
          border: 'none',
        },
      },
    })
  }, [activeDeleteUserGroup, dispatch, userGroupDeleteValidateResult])
  return (
    <>
      <LoadingOverlay
        spinning={userGroupDeleteValidateLoading}
        size="large"
        render={
          <UserGroupContainer justify="center">
            <div style={{ width: '100%' }}>
              <UserGroupTableHeader>
                <UserGroupTableHeaderText>
                  ユーザーグループ名
                </UserGroupTableHeaderText>
              </UserGroupTableHeader>
              <List
                style={{ width: '100%' }}
                itemLayout="horizontal"
                dataSource={props.userGroups}
                renderItem={(userGroup: UserGroup) => {
                  const isOpen = !collapsedGroup.includes(userGroup.id!)
                  return (
                    <List.Item
                      style={{
                        position: 'relative',
                        display: 'flex',
                        flexDirection: 'column',
                        backgroundColor: White,
                        padding: 0,
                      }}
                      onMouseEnter={() => setActiveUserGroupId(userGroup.id)}
                      onMouseLeave={() => setActiveUserGroupId(undefined)}
                    >
                      <UserGroupRow>
                        <UserGroupTreeIconButton
                          onClick={() =>
                            isOpen
                              ? closeGroup(userGroup.id!)
                              : openGroup(userGroup.id!)
                          }
                        >
                          {isOpen ? (
                            <CaretDownOutlined />
                          ) : (
                            <CaretRightOutlined />
                          )}
                          <TagOutlined />
                          <UserGroupName>{userGroup.name}</UserGroupName>
                        </UserGroupTreeIconButton>
                        {activeUserGroupId === userGroup.id && (
                          <ActionButtonGroup>
                            <RowActionButton
                              onClick={() => openEditUserGroup(userGroup)}
                              type="edit"
                            />
                            <RowActionButton
                              onClick={() => {
                                setActiveDeleteUserGroup(userGroup)
                                validateDeleteUserGroup(userGroup.id ?? 0)
                              }}
                              type="delete"
                            />
                          </ActionButtonGroup>
                        )}
                      </UserGroupRow>
                      {userGroup.users &&
                        userGroup.users.length > 0 &&
                        !collapsedGroup.includes(userGroup.id!) &&
                        userGroup.users.map((user: User, index: number) => (
                          <UserNameWrap key={index}>
                            <UserOutlined />
                            <UserName>{user.name}</UserName>
                          </UserNameWrap>
                        ))}
                    </List.Item>
                  )
                }}
              />
            </div>
          </UserGroupContainer>
        }
      />
    </>
  )
}

export default UserGroupList
