import { CopyOutlined } from '@ant-design/icons'
import { Col, Row } from 'antd'
import React, { ChangeEvent } from 'react'
import FormikErrorMessage from 'src/components/formikErrorMessage/FormikErrorMessage'
import { TBaseFormRowProps } from './EditSchedule'
import {
  CopyButton,
  FormLabel,
  FormRow,
  NameInput,
  RequiedLabel,
  Text,
} from './EditSchedule.styled'

const ScheduleNameAndCopyButtonRow: React.FC<
  TBaseFormRowProps<{
    scheduleId: string
  }>
> = ({
  setFieldValue,
  handleSubmit,
  scheduleId,
  values: { name },
  errors,
  touched,
}) => {
  const onScheduleNameChange = (e: ChangeEvent<HTMLInputElement>) =>
    setFieldValue('name', e.target.value)
  const onCopyButtonClick = () => {
    setFieldValue('name', `${name}_コピー`)
    setFieldValue('id', 0)
    setFieldValue('isCopied', true)
    handleSubmit()
  }

  return (
    <FormRow>
      {/* コピーボタンを画面右側に配置するためのRow */}
      <Row>
        <Col span={16}>
          <FormLabel>
            <Text strong>スケジュール名</Text>
          </FormLabel>
          <NameInput onChange={onScheduleNameChange} value={name} />
          <RequiedLabel>※必須入力</RequiedLabel>
          <div>
            <FormikErrorMessage name="name" errors={errors} touched={touched} />
          </div>
        </Col>
        {scheduleId && (
          <Col span={8}>
            <CopyButton type="default" onClick={onCopyButtonClick}>
              <CopyOutlined />
              コピー
            </CopyButton>
          </Col>
        )}
      </Row>
    </FormRow>
  )
}

export default ScheduleNameAndCopyButtonRow
