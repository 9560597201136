import { css } from '@emotion/react'
import { globalNavigationTheme } from 'src/features/globalNavigation/theme'

export const BetaBadge = () => {
  return <div css={styles.container}>β版</div>
}

const styles = {
  container: css`
    align-items: center;
    background-color: ${globalNavigationTheme.betaBadgeBackground};
    border-radius: 6px;
    border: none;
    color: ${globalNavigationTheme.betaBadgeTextColor};
    display: flex;
    font-size: 12px;
    height: 22px;
    justify-content: center;
    width: 36px;
  `,
}
