import {
  GetImproveExcelConvertsRequest,
  ImproveExcelConverts,
} from '@ulysses-inc/harami_api_client'
import { combineReducers } from 'redux'
import { ImproveExcelConversionLogAction } from './actions'
import { ActionTypes, GetImproveExcelConversionLogsFilter } from './types'

interface ImproveExcelConversionLogsState {
  improveExcelConversionLogs: ImproveExcelConverts[]
  isLoading: boolean
  error: Error | null
  count: number
  filter?: GetImproveExcelConversionLogsFilter
  request: GetImproveExcelConvertsRequest
}

const initialImproveExcelConversionLogsState: ImproveExcelConversionLogsState =
  {
    improveExcelConversionLogs: [],
    isLoading: false,
    error: null,
    count: 0,
    filter: undefined,
    request: {
      improveUUID: '',
      offset: 0,
      limit: 25,
    },
  }

const improveExcelConversionLogs = (
  state: ImproveExcelConversionLogsState = initialImproveExcelConversionLogsState,
  action: ImproveExcelConversionLogAction,
): ImproveExcelConversionLogsState => {
  switch (action.type) {
    case ActionTypes.REQUEST_GET_IMPROVE_EXCEL_CONVERSION_LOGS: {
      return {
        ...state,
        isLoading: action.isLoading,
        improveExcelConversionLogs: [],
        error: null,
        filter: action.filter,
        request: action.request,
      }
    }
    case ActionTypes.SUCCESS_GET_IMPROVE_EXCEL_CONVERSION_LOGS: {
      return {
        ...state,
        isLoading: action.isLoading,
        improveExcelConversionLogs: action.excelConversionLogs || [],
        error: null,
        count: action.count || 0,
      }
    }
    case ActionTypes.ERROR_GET_IMPROVE_EXCEL_CONVERSION_LOGS: {
      return {
        ...state,
        isLoading: action.isLoading,
        error: action.error,
        count: 0,
      }
    }
    case ActionTypes.REQUEST_CHANGE_IMPROVE_EXCEL_CONVERSION_LOGS_PAGE: {
      return {
        ...state,
        request: action.request,
        filter: action.filter,
      }
    }
    case ActionTypes.UPDATE_PAGINATION: {
      return {
        ...state,
        request: action.request,
      }
    }
    case ActionTypes.REQUEST_CHANGE_IMPROVE_EXCEL_CONVERSION_LOGS_SIZE: {
      return {
        ...state,
        request: action.request,
        filter: action.filter,
      }
    }
    case ActionTypes.REQUEST_ADD_IMPROVE_EXCEL_CONVERSION_LOGS: {
      return {
        ...state,
        isLoading: action.isLoading,
        error: null,
        request: action.request,
        count: 0,
      }
    }
    case ActionTypes.ERROR_ADD_IMPROVE_EXCEL_CONVERSION_LOGS: {
      return {
        ...state,
        isLoading: action.isLoading,
        error: action.error,
      }
    }
    default: {
      return { ...state }
    }
  }
}

const improveExcelConversionLogsReducer = combineReducers({
  improveExcelConversionLogs,
})

export default improveExcelConversionLogsReducer
