import { Text } from '@react-pdf/renderer'
import { MultipleChoice } from '@ulysses-inc/harami_api_client'
import { FC } from 'react'
import { gridLayoutTableBodyCell } from '../../../shared/styles'

type Props = {
  value?: MultipleChoice
}

export const TableBodyCellMultipleChoice: FC<Props> = ({ value }) => {
  return (
    <Text
      style={[
        gridLayoutTableBodyCell.baseTableBodyCell,
        gridLayoutTableBodyCell.tableBodyCell,
      ]}
    >
      {value?.response}
    </Text>
  )
}
