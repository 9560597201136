import {
  MultipleChoiceSet,
  ResponseTypeEnum,
  TemplateNodeSchema,
  TemplateNodeTypeEnum,
} from '@ulysses-inc/harami_api_client'

/**
 * ひな形で利用されているセット選択肢をユニークに抽出する
 *
 * 結果である`MultipleChoiceSet`の`responses`は何も含まれていない(常にnullになる)ようなので注意。
 */
export const extractUniqueMultipleChoiceSets = (templateNodes: {
  [key: string]: TemplateNodeSchema
}): MultipleChoiceSet[] => {
  const multipleChoiceSets: MultipleChoiceSet[] = []
  Object.values(templateNodes).forEach((node: TemplateNodeSchema) => {
    if (
      node.type === TemplateNodeTypeEnum.Question &&
      node.question?.responseType === ResponseTypeEnum.RESPONSE_SET
    ) {
      const questionMultipleChoiceSets =
        node.question.responseMultipleChoiceSets ?? []
      questionMultipleChoiceSets.forEach(questionMultipleChoice => {
        const multipleChoiceSetUUIDs = multipleChoiceSets.map(
          multipleChoiceSet => {
            return multipleChoiceSet.uuid
          },
        )
        if (!multipleChoiceSetUUIDs.includes(questionMultipleChoice.uuid)) {
          multipleChoiceSets.push(questionMultipleChoice)
        }
      })
    }
  })
  return multipleChoiceSets
}
