import { MultipleChoice } from '@ulysses-inc/harami_api_client'
import { Row } from 'antd'
import { FC } from 'react'
import ActionColumn from './ActionColumn'
import { TMultipleChoicesState } from './AddMultipleChoiceDrawer'
import { CustomizedTable } from './AddMultipleChoiceDrawer.styled'
import ColorColumn from './ColorColumn'
import DefaultnessColumn from './DefaultnessColumn'
import ResponseColumn from './ResponseColumn'
import ScoreColumn from './ScoreColumn'
import ValidityColumn from './ValidityColumn'

const columns = [
  {
    title: '回答',
    dataIndex: 'response',
    key: 'response',
    width: 178,
  },
  {
    title: '初期値',
    dataIndex: 'isDefault',
    key: 'isDefault',
    width: 60,
  },
  {
    title: '逸脱',
    dataIndex: 'isInValid',
    key: 'isInValid',
    width: 60,
  },
  {
    title: '配点',
    dataIndex: 'score',
    key: 'score',
    width: 200,
  },
  {
    title: '色',
    dataIndex: 'color',
    key: 'color',
    width: 96,
  },
  {
    title: '',
    dataIndex: 'action',
    key: 'action',
    width: 56,
  },
]

const TableRow: FC<{
  multipleChoicesState: TMultipleChoicesState
  lastKey: number
  addNewMultipleChoice: () => void
}> = ({ multipleChoicesState, lastKey, addNewMultipleChoice }) => {
  const [multipleChoices] = multipleChoicesState
  const getMultipleChoiceDateSource = (multipleChoice: MultipleChoice) => {
    const multipleChoiceId = multipleChoice.id ?? 0
    return {
      key: `${multipleChoiceId}`,
      response: (
        <ResponseColumn
          {...{
            multipleChoice,
            multipleChoicesState,
            lastKey,
            addNewMultipleChoice,
          }}
        />
      ),
      isDefault: (
        <DefaultnessColumn {...{ multipleChoice, multipleChoicesState }} />
      ),
      isInValid: (
        <ValidityColumn {...{ multipleChoice, multipleChoicesState }} />
      ),
      score: <ScoreColumn {...{ multipleChoice, multipleChoicesState }} />,
      color: <ColorColumn {...{ multipleChoice, multipleChoicesState }} />,
      action: <ActionColumn {...{ multipleChoice, multipleChoicesState }} />,
    }
  }

  const tableData = Object.keys(multipleChoices)
    .map(Number)
    .map(key => getMultipleChoiceDateSource(multipleChoices[key]))

  return (
    <Row>
      <CustomizedTable
        dataSource={tableData}
        columns={columns}
        pagination={false}
        style={{ width: '100%' }}
      />
    </Row>
  )
}

export default TableRow
