import {
  ReportQuestion,
  ResponseTypeEnum,
} from '@ulysses-inc/harami_api_client'
import { isNullish } from 'src/util/isNullish'

export const isInvalid = (question?: ReportQuestion): boolean => {
  if (isNullish(question)) {
    return false
  }
  if (isNullish(question.responseAnswer)) {
    return false
  }

  switch (question.responseType) {
    case ResponseTypeEnum.MULTIPLE_CHOICE:
      return question.responseAnswer.multipleChoice?.isInvalid === 1
    case ResponseTypeEnum.NUMBER:
      return question.responseAnswer.numberValue?.isInvalid === 1
    case ResponseTypeEnum.DATETIME:
      return question.responseAnswer.datetimeValue?.isInvalid === 1
    case ResponseTypeEnum.FORMULA:
      return question.responseAnswer.formulaValue?.isInvalid === 1
    case ResponseTypeEnum.TIME_MEASUREMENT:
      return !!question.responseAnswer.timeMeasurementValue?.isInvalid
    default:
      return false
  }
}
