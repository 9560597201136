import { GetPlacesRequest, PlaceNode } from '@ulysses-inc/harami_api_client'
import { combineReducers } from 'redux'
import { PlaceGroupsAction, PlaceNodesAction, PlacesAction } from './actions'
import { ActionTypes, GetPlacesFilter } from './types'

interface PlacesState {
  places: PlaceNode[]
  activePlaceId?: string
  segmentIndex: number
  isLoading: boolean
  isShowEditPlaceDrawer: boolean
  error: Error | null
  count: number
  request: GetPlacesRequest
  updatedPlaces: boolean
  filter?: GetPlacesFilter
}

const initialPlacesState: PlacesState = {
  places: [],
  segmentIndex: 0,
  isLoading: false,
  isShowEditPlaceDrawer: false,
  error: null,
  count: 0,
  request: {
    offset: 0,
    limit: 25,
  },
  updatedPlaces: false,
  filter: {},
}

const places = (
  state: PlacesState = initialPlacesState,
  action: PlacesAction,
): PlacesState => {
  switch (action.type) {
    case ActionTypes.REQUEST_GET_PLACES: {
      return {
        ...state,
        isLoading: action.isLoading,
        places: [],
        error: null,
        request: action.request,
      }
    }
    case ActionTypes.SUCCESS_GET_PLACES: {
      return {
        ...state,
        isLoading: action.isLoading,
        places: action.places,
        count: action.count,
        error: null,
      }
    }
    case ActionTypes.ERROR_GET_PLACES: {
      return { ...state, isLoading: action.isLoading, error: null }
    }
    case ActionTypes.REQUEST_CHANGE_PLACES_PAGE: {
      return {
        ...state,
        request: action.request,
      }
    }
    case ActionTypes.REQUEST_CHANGE_PLACES_SIZE: {
      return {
        ...state,
        request: action.request,
      }
    }
    case ActionTypes.REQUEST_GET_PLACES_V2: {
      return {
        ...state,
        isLoading: action.isLoading,
        places: [],
        error: null,
        request: action.request,
        filter: action.filter ?? {},
      }
    }
    case ActionTypes.REQUEST_CHANGE_PLACES_V2_PAGE: {
      return {
        ...state,
        request: action.request,
        filter: action.filter ?? {},
      }
    }
    case ActionTypes.REQUEST_CHANGE_PLACES_V2_SIZE: {
      return {
        ...state,
        request: action.request,
        filter: action.filter ?? {},
      }
    }
    case ActionTypes.REQUEST_ADD_PLACE: {
      return { ...state, isLoading: action.isLoading, error: null }
    }
    case ActionTypes.SUCCESS_ADD_PLACE: {
      return {
        ...state,
        isLoading: action.isLoading,
        error: null,
        places: [...state.places, action.place],
      }
    }
    case ActionTypes.ERROR_ADD_PLACE: {
      return { ...state, isLoading: action.isLoading, error: action.error }
    }
    case ActionTypes.REQUEST_UPDATE_PLACE: {
      return { ...state, isLoading: action.isLoading, error: null }
    }
    case ActionTypes.SUCCESS_UPDATE_PLACE: {
      return {
        ...state,
        isLoading: action.isLoading,
        error: null,
        places: state.places.map(place => {
          if (place.uuid === action.place.uuid) {
            return action.place
          } else {
            return place
          }
        }),
      }
    }
    case ActionTypes.ERROR_UPDATE_PLACE: {
      return { ...state, isLoading: action.isLoading, error: action.error }
    }
    case ActionTypes.SUCCESS_DELETE_PLACE: {
      return {
        ...state,
        isLoading: action.isLoading,
        error: null,
        places: state.places.filter(place => place.uuid !== action.placeId),
      }
    }
    case ActionTypes.ERROR_DELETE_PLACE: {
      return { ...state, isLoading: action.isLoading, error: action.error }
    }
    case ActionTypes.CHANGE_UPDATED_PLACES_FLAG: {
      return { ...state, updatedPlaces: action.updatedPlaces }
    }
    case ActionTypes.CHANGE_IS_SHOW_EDIT_PLACE_DRAWER: {
      return { ...state, isShowEditPlaceDrawer: action.isShowEditPlaceDrawer }
    }
    case ActionTypes.UPDATE_ACTIVE_PLACE_ID: {
      return { ...state, activePlaceId: action.activePlaceId }
    }
    case ActionTypes.UPDATE_SEGMENT_INDEX: {
      return { ...state, segmentIndex: action.segmentIndex }
    }
    default: {
      return { ...state }
    }
  }
}

interface PlaceGroupsState {
  nodes: PlaceNode[]
  activeNodeId?: string
  activeParentNodeId?: number
  isLoading: boolean
  isShowEditPlaceGroupDrawer: boolean
  error: Error | null
}

const initialPlaceGroupsState: PlaceGroupsState = {
  nodes: [],
  isLoading: false,
  isShowEditPlaceGroupDrawer: false,
  error: null,
}

const placeGroups = (
  state: PlaceGroupsState = initialPlaceGroupsState,
  action: PlaceGroupsAction,
): PlaceGroupsState => {
  switch (action.type) {
    case ActionTypes.REQUEST_GET_PLACE_GROUPS: {
      return {
        ...state,
        isLoading: action.isLoading,
        nodes: [],
        error: null,
      }
    }
    case ActionTypes.SUCCESS_GET_PLACE_GROUPS: {
      return {
        ...state,
        isLoading: action.isLoading,
        nodes: action.nodes,
        error: null,
      }
    }
    case ActionTypes.ERROR_GET_PLACE_GROUPS: {
      // 認証期限切れの場合に到達する
      return { ...state, isLoading: action.isLoading, error: action.error }
    }
    case ActionTypes.REQUEST_ADD_PLACE_GROUP: {
      return { ...state, isLoading: action.isLoading, error: null }
    }
    case ActionTypes.SUCCESS_ADD_PLACE_GROUP: {
      return {
        ...state,
        isLoading: action.isLoading,
        nodes: action.nodes,
        error: null,
      }
    }
    case ActionTypes.ERROR_ADD_PLACE_GROUP: {
      return { ...state, isLoading: action.isLoading, error: action.error }
    }
    case ActionTypes.REQUEST_UPDATE_PLACE_GROUP: {
      return { ...state, isLoading: action.isLoading, error: null }
    }
    case ActionTypes.SUCCESS_UPDATE_PLACE_GROUP: {
      return {
        ...state,
        isLoading: action.isLoading,
        nodes: action.nodes,
        error: null,
      }
    }
    case ActionTypes.ERROR_UPDATE_PLACE_GROUP: {
      return { ...state, isLoading: action.isLoading, error: action.error }
    }
    case ActionTypes.CHANGE_IS_SHOW_EDIT_PLACE_GROUP_DRAWER: {
      return {
        ...state,
        isShowEditPlaceGroupDrawer: action.isShowEditPlaceGroupDrawer,
      }
    }
    case ActionTypes.UPDATE_ACTIVE_NODE_ID: {
      return {
        ...state,
        activeNodeId: action.activeNodeId,
        activeParentNodeId: action.activeParentNodeId,
      }
    }
    default: {
      return { ...state }
    }
  }
}

interface PlaceNodesState {
  nodes: PlaceNode[]
  isLoading: boolean
  error: Error | null
}

const initialPlaceNodesState: PlaceNodesState = {
  nodes: [],
  isLoading: false,
  error: null,
}

const placeNodes = (
  state: PlaceNodesState = initialPlaceNodesState,
  action: PlaceNodesAction,
): PlaceNodesState => {
  switch (action.type) {
    case ActionTypes.REQUEST_GET_PLACE_NODES: {
      return {
        ...state,
        isLoading: true,
        nodes: [],
        error: null,
      }
    }
    case ActionTypes.SUCCESS_GET_PLACE_NODES: {
      return {
        ...state,
        isLoading: false,
        nodes: action.nodes,
        error: null,
      }
    }
    case ActionTypes.ERROR_GET_PLACE_NODES: {
      // 認証期限切れの場合に到達する
      return { ...state, isLoading: action.isLoading, error: action.error }
    }
    default: {
      return { ...state }
    }
  }
}

const placesReducer = combineReducers({ places, placeGroups, placeNodes })

export default placesReducer
