import { TemplateNodeTypeEnum } from '@ulysses-inc/harami_api_client'
import { FC } from 'react'
import { useSelector } from 'react-redux'
import { useLimitedFeatureAvailability } from 'src/features/featureAvailability/useLimitedFeatureAvailability'
import { RootState } from 'src/state/store'
import DisabledAuditSwitch from './DisabledAuditSwitch'
import { ToggleGroup } from './EditTemplateOption.styled'
import EditabilitySwitch from './EditabilitySwitch'
import EnabledAuditSwitch from './EnabledAuditSwitch'
import ExcelSwitch from './ExcelSwitch'
import KeyboardSwitch from './KeyboardSwitch'
import ScoreSwitch from './ScoreSwitch'

const SwitchRow: FC = () => {
  const isActiveEmployeeCheck = useSelector(
    (state: RootState) =>
      Object.values(state.templatesState.templatePages.templateNodes)
        .filter(node => node.type === TemplateNodeTypeEnum.Section)
        .filter(node => node.section?.isEmployeeCheck)?.length > 0,
  )
  const { isFeatureAvailable } = useLimitedFeatureAvailability()

  return (
    <ToggleGroup justify="start">
      <ScoreSwitch />
      {isFeatureAvailable('excelConversionEdit') && <ExcelSwitch />}
      {isFeatureAvailable('improves') && !isActiveEmployeeCheck && (
        <EnabledAuditSwitch />
      )}
      {isFeatureAvailable('improves') && isActiveEmployeeCheck && (
        <DisabledAuditSwitch />
      )}
      <EditabilitySwitch />
      <KeyboardSwitch />
    </ToggleGroup>
  )
}

export default SwitchRow
