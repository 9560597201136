import {
  ReportNode,
  ResponseTypeEnum,
  TemplateNodeSchema,
  TemplateNodeTypeEnum,
} from '@ulysses-inc/harami_api_client'
import { denormalize, normalize } from 'normalizr'
import { UUID } from 'src/exShared/util/uuid'
import { SchemaReportNodes } from 'src/state/ducks/reports/schemas'

const walk = (children: Array<ReportNode>, count: number): number => {
  if (!children || children.length === 0) {
    return count
  }
  children.forEach((child: ReportNode) => {
    child.id = count++
    child.uuid = UUID()
    switch (child.type) {
      case TemplateNodeTypeEnum.Section:
        if (!child.section) {
          break
        }
        child.section.id = count++
        break
      case TemplateNodeTypeEnum.Question:
        if (!child.question) {
          break
        }
        if (
          child.question.responseType === ResponseTypeEnum.FORMULA &&
          child.question.responseFormulas
        ) {
          child.question.responseFormulas = [{}]
        }
        child.question.id = count++
        break
      case TemplateNodeTypeEnum.Logic:
        if (!child.logic) {
          break
        }
        child.logic.id = count++
        break
    }
    count = walk(child.nodes, count)
  })
  return count
}

const copyPageParams = (
  nodes: { [key: number]: TemplateNodeSchema },
  originalNodeUuid: string,
) => {
  const originalNode = Object.values(nodes).find(
    node => node.uuid === originalNodeUuid,
  )
  if (!originalNode) {
    return {}
  }
  const nestedChildren: Array<ReportNode> = denormalize(
    originalNode.nodes,
    SchemaReportNodes,
    { nodes },
  )
  const mutableNestedChildren: Array<ReportNode> = JSON.parse(
    JSON.stringify(nestedChildren),
  )
  const copiedNode: TemplateNodeSchema = JSON.parse(
    JSON.stringify(originalNode),
  )
  if (!copiedNode.section) {
    return {}
  }
  let last = Math.max(...Object.keys(nodes).map(Number), 0) + 1
  copiedNode.id = last++
  copiedNode.section.id = last++
  copiedNode.uuid = UUID()
  walk(mutableNestedChildren, last)
  copiedNode.nodes = mutableNestedChildren.map(child => child.id)
  const flattenChildren = normalize<
    any,
    { nodes: { [key: number]: TemplateNodeSchema } }
  >(mutableNestedChildren, SchemaReportNodes).entities.nodes
  return { copiedNode, flattenChildren }
}

const copyNodeParams = (
  nodes: { [key: number]: TemplateNodeSchema },
  originalNodeUuid: string,
) => {
  const originalNode = Object.values(nodes).find(
    node => node.uuid === originalNodeUuid,
  )
  if (!originalNode) {
    return {}
  }
  const nestedChildren: Array<ReportNode> = denormalize(
    originalNode.nodes,
    SchemaReportNodes,
    {
      nodes: nodes,
    },
  )
  const mutableNestedChildren: Array<ReportNode> = JSON.parse(
    JSON.stringify(nestedChildren),
  )

  const copiedNode: TemplateNodeSchema = JSON.parse(
    JSON.stringify(originalNode),
  )
  if (!copiedNode.section) {
    return {}
  }
  let last = Math.max(...Object.keys(nodes).map(Number), 0) + 1
  copiedNode.id = last++
  copiedNode.section.id = last++
  copiedNode.uuid = UUID()
  walk(mutableNestedChildren, last)
  copiedNode.nodes = mutableNestedChildren.map(child => child.id)

  const flattenChildren = normalize<
    any,
    { nodes: { [key: number]: TemplateNodeSchema } }
  >(mutableNestedChildren, SchemaReportNodes).entities.nodes
  return { copiedNode, flattenChildren }
}

export { copyNodeParams, copyPageParams }
