import { createSlice } from '@reduxjs/toolkit'

interface AuthErrorState {
  summary: string
  message: string
}

const initialState: AuthErrorState = {
  summary: '',
  message: '',
}

interface SetAction {
  type: string
  payload: AuthErrorState
}

export const authErrorSlice = createSlice({
  name: 'harami/authError',
  initialState,
  reducers: {
    showDialog: (state, action: SetAction) => {
      state.summary = action.payload.summary
      state.message = action.payload.message
    },
    reset: () => initialState,
  },
})

export const { showDialog, reset } = authErrorSlice.actions
export default authErrorSlice.reducer
