import { CheckOutlined, CloseOutlined } from '@ant-design/icons'
import { Switch, Typography } from 'antd'
import { FC } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { SwitchDisabledColor } from 'src/features/theme/KdsThemeColor'
import { toggleShowScore } from 'src/state/ducks/templates/editTemplate/actions'
import { RootState } from 'src/state/store'
import { ScoreSwitchWrap, ToggleWrap } from './EditTemplateOption.styled'

const { Text } = Typography

const ScoreSwitch: FC = () => {
  const dispatch = useDispatch()

  const isShowScore = useSelector(
    (state: RootState) => state.templatesState.editTemplate.isShowScore,
    shallowEqual,
  )

  return (
    <ToggleWrap>
      <ScoreSwitchWrap>
        <Switch
          checkedChildren={<CheckOutlined />}
          unCheckedChildren={<CloseOutlined />}
          checked={isShowScore}
          onChange={checked => {
            dispatch(toggleShowScore(checked))
          }}
          style={
            !isShowScore ? { backgroundColor: SwitchDisabledColor } : undefined
          }
        />
      </ScoreSwitchWrap>
      <div>
        <Text strong>配点機能をつける</Text>
      </div>
    </ToggleWrap>
  )
}

export default ScoreSwitch
