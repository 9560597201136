import { TemplateNodesDict } from 'src/exShared/types/types'

/**
 * nodeの位置情報や関係性に関するメソッド郡を提供する
 *
 * @param allNodesDict 対象となるnodeの辞書
 * @returns nodePositionChecker
 */
export const createNodePositionChecker = (allNodesDict: TemplateNodesDict) => {
  /**
   * childParentMap: { [子ノードのuuid]: 親ノードのuuid }
   */
  const childParentMap: { [key: string]: string } = {}
  for (const parentNode of Object.values(allNodesDict)) {
    for (const childNodeId of parentNode.nodes) {
      const childNode = allNodesDict[childNodeId]
      if (childNode === undefined) continue

      childParentMap[childNode.uuid || ''] = parentNode.uuid || ''
    }
  }

  /**
   * 親ノードが同一であるか判定する
   * @param uuid1 1つ目のノード
   * @param uuid2 2つ目のノード
   */
  const sameParent = (uuid1: string, uuid2: string): boolean => {
    return childParentMap[uuid1] === childParentMap[uuid2]
  }

  return { sameParent }
}
