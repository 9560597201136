import {
  AppRoleEnum,
  CompanyContractPlan,
  FeatureNameEnum,
} from '@ulysses-inc/harami_api_client'
import { getContractPlan } from 'src/features/auth/getContractPlan'
import { useFeatureFlagsBulk } from 'src/features/featureFlags/useFeatureFlags'
import { appRole } from 'src/libraries/roles/appRole'
import { appEnv } from 'src/util/appEnv'

/**
 * 機能の利用可否を判定する際の単位となるもの
 */
export type Feature =
  | 'DUMMY_FEATURE_fullRestriction' // ユニットテスト用のダミー (制約あり)
  | 'DUMMY_FEATURE_noRestriction' // ユニットテスト用のダミー (制約なし)
  | 'approvalFlows'
  | 'approvals'
  | 'companySettings'
  | 'dashboard'
  | 'dynamicSheet'
  | 'employees'
  | 'excelConversionEdit'
  | 'excelConversionRead'
  | 'improves' // カイゼン・ラベルどちらも含む
  | 'multipleChoiceSets'
  | 'places' // 現場・現場グループどちらも含む
  | 'reportExports'
  | 'reports'
  | 'scheduledReportSummary'
  | 'schedules'
  | 'templates'
  | 'userImport'
  | 'users' // ユーザー・ユーザーグループどちらも含む

/**
 * ある機能を利用するにあたって、どのような条件を満たす必要があるかを定義したもの。
 * 条件はANDで結合される。
 * e.g. 契約プラン、ユーザーロール、フィーチャーフラグの3つが指定された場合は、3つすべてを満たす必要がある。
 */
type Setting = {
  eligibleFeatureFlag?: FeatureNameEnum
  eligiblePlans?: CompanyContractPlan[] // いずれかのプランに当てはまればよい
  eligibleRoles?: AppRoleEnum[] // いずれかのロールに当てはまればよい
}

export type Settings = Record<Feature, Setting>

const roles = {
  leaderOrMore: [AppRoleEnum.LEADER, AppRoleEnum.EDITOR, AppRoleEnum.OWNER],
  editorOrMore: [AppRoleEnum.EDITOR, AppRoleEnum.OWNER],
  owner: [AppRoleEnum.OWNER],
}

export const settings: Settings = {
  DUMMY_FEATURE_fullRestriction: {
    eligibleFeatureFlag: FeatureNameEnum.DUMMY_1, // テストコード側と揃っていればなんでもよい
    eligiblePlans: [CompanyContractPlan.PREMIUM],
    eligibleRoles: roles.owner,
  },
  DUMMY_FEATURE_noRestriction: {},
  // 承認フロー
  approvalFlows: {
    eligibleRoles: roles.owner,
  },
  // 承認
  approvals: {
    eligibleRoles: roles.leaderOrMore,
  },
  // アプリ設定
  companySettings: {
    eligibleRoles: roles.owner,
  },
  // ダッシュボード
  dashboard: {
    eligibleRoles: roles.leaderOrMore,
  },
  // 表形式
  dynamicSheet: {
    eligiblePlans: [CompanyContractPlan.BASIC, CompanyContractPlan.PREMIUM],
    eligibleRoles: roles.editorOrMore,
  },
  // 従業員
  employees: {
    eligibleRoles: roles.owner,
  },
  // Excel変換 (編集権限あり)
  excelConversionEdit: {
    eligiblePlans: [
      CompanyContractPlan.OLD_BASIC,
      CompanyContractPlan.BASIC,
      CompanyContractPlan.PREMIUM,
    ],
    eligibleRoles: roles.editorOrMore,
  },
  // Excel変換 (閲覧権限のみ)
  excelConversionRead: {
    eligiblePlans: [
      CompanyContractPlan.OLD_BASIC,
      CompanyContractPlan.BASIC,
      CompanyContractPlan.PREMIUM,
    ],
    eligibleRoles: roles.leaderOrMore,
  },
  // カイゼン・ラベル
  improves: {
    eligiblePlans: [CompanyContractPlan.OLD_BASIC, CompanyContractPlan.PREMIUM],
    eligibleRoles: roles.leaderOrMore,
  },
  // セット選択肢
  multipleChoiceSets: {
    eligibleRoles: roles.editorOrMore,
  },
  // 現場・現場グループ
  places: {
    eligibleRoles: roles.editorOrMore,
  },
  // レポート
  reports: {
    eligibleRoles: roles.leaderOrMore,
  },
  // レポート出力
  reportExports: {
    eligiblePlans: [
      CompanyContractPlan.OLD_BASIC,
      CompanyContractPlan.BASIC,
      CompanyContractPlan.PREMIUM,
    ],
    eligibleRoles: roles.leaderOrMore,
  },
  // 実施状況
  scheduledReportSummary: {
    eligibleRoles: roles.leaderOrMore,
  },
  // スケジュール
  schedules: {
    eligibleRoles: roles.editorOrMore,
  },
  // ひな形
  templates: {
    eligibleRoles: roles.editorOrMore,
  },
  // ユーザー一括登録
  userImport: {
    eligibleFeatureFlag: FeatureNameEnum.USER_IMPORT,
    eligibleRoles: roles.owner,
  },
  // ユーザー・ユーザーグループ
  users: {
    eligibleRoles: roles.owner,
  },
}

/**
 * 限定されたユーザーにのみ開放している機能について、利用可能か判定するカスタムフック。
 * 判定にあたっては、契約プラン、ユーザーロール、FeatureFlagのすべてが勘案される。
 */
export const useLimitedFeatureAvailability = () => {
  const enabledFeatures = useFeatureFlagsBulk()

  const isFeatureAvailable = (feature: Feature) => {
    const { eligiblePlans, eligibleRoles, eligibleFeatureFlag } =
      settings[feature]

    // 有効な契約プランか
    const hasValidContractPlan = (() => {
      const contractPlan = getContractPlan()
      switch (true) {
        case !eligiblePlans: {
          // そもそも利用可能プランが限定されていないとき
          return true
        }
        case !contractPlan: {
          // 契約プランの取得に失敗したとき
          return false
        }
        default: {
          return eligiblePlans.includes(contractPlan)
        }
      }
    })()

    // 有効なロールか
    const hasValidRole = (() => {
      const loggedInUserRole = appRole()
      switch (true) {
        case !eligibleRoles: {
          // そもそも利用可能ロールが限定されていないとき
          return true
        }
        case !loggedInUserRole: {
          // ユーザー情報の取得に失敗したとき
          return false
        }
        default: {
          return eligibleRoles.includes(loggedInUserRole)
        }
      }
    })()

    // FeatureFlagで許可されているか
    const hasValidFeatureFlag = (() => {
      // そもそもFeatureFlagによる許可が不要な機能の場合
      if (!eligibleFeatureFlag) return true
      return enabledFeatures.includes(eligibleFeatureFlag) || appEnv.isLocal // 簡便さのため、ローカル環境ではFeatureFlagの設定に関わらず常に表示する
    })()

    return hasValidContractPlan && hasValidRole && hasValidFeatureFlag
  }

  return { isFeatureAvailable }
}
