import { ErrorCodeEnum, Template } from '@ulysses-inc/harami_api_client'
import { combineReducers } from 'redux'
import { EditGridVariablesAction } from './actions'
import { COL_COUNT, ROW_COUNT } from './constants'
import { calcRectangle } from './helpers/calcRectangle'
import { deepCopyTable } from './helpers/deepCopyTable'
import { hasInput } from './helpers/hasInput'
import { createPatchedRow, hasChanged } from './helpers/patchInputTable'
import { RowValidator } from './helpers/rowValidator'
import { ActionTypes, CellMap, Mode, SectionsByName, Table } from './types'

type EditGridVariablesState = {
  templateId: number
  template?: Template
  sectionsByName: SectionsByName
  /**
   * API から取得したデータを格納するテーブル
   *
   * `originalTable` と `inputTable` の差分から、テーブルで変更された行を求める際に見るので、
   * API のレスポンスを格納するときを除いて、変更してはいけない
   */
  originalTable: Table
  /** ユーザー入力により生じたセル・行の変更は、このテーブルに反映する */
  inputTable: Table
  isLoading: boolean
  isUpdating: boolean
  error: Error | null
  /** patchメソッド実行時に返却されたエラーメッセージ */
  patchGridVariablesError?: PatchGridVariablesError
  /** drag モードの起点になったセルの座標 */
  dragStartedCell: {
    rowNum?: number
    colNum?: number
  }
  /** 選択されているセルの座標群 */
  selectedCells: CellMap
  mode: Mode
}

export type SoftLimitErrorCode =
  | ErrorCodeEnum.MaxSubstantialCellCountExceededInGridVariablesError
  | ErrorCodeEnum.MaxRowCountExceededInGridVariablesError
  | ErrorCodeEnum.MaxTableCountExceededInGridVariablesError

type PatchGridVariablesError = {
  errorMessage: string
  code: SoftLimitErrorCode
}

const initialEditGridVariablesState: EditGridVariablesState = {
  templateId: 0,
  template: undefined,
  sectionsByName: {},
  originalTable: {},
  inputTable: {},
  isLoading: true,
  isUpdating: false,
  error: null,
  patchGridVariablesError: undefined,
  dragStartedCell: {},
  selectedCells: {},
  mode: 'none',
}

const editGridVariables = (
  state: EditGridVariablesState = initialEditGridVariablesState,
  action: EditGridVariablesAction,
): EditGridVariablesState => {
  switch (action.type) {
    case ActionTypes.REQUEST_FETCH_GRID_VARIABLES_TABLE: {
      return {
        ...state,
        templateId: action.templateId,
        template: undefined,
        isLoading: action.isLoading,
      }
    }
    case ActionTypes.SUCCESS_FETCH_GRID_VARIABLES_TABLE: {
      return {
        ...state,
        sectionsByName: action.sectionsByName,
        originalTable: action.originalTable,
        inputTable: action.inputTable,
        template: action.template,
        isLoading: action.isLoading,
      }
    }
    case ActionTypes.ERROR_FETCH_GRID_VARIABLES_TABLE: {
      return {
        ...state,
        isLoading: action.isLoading,
        error: action.error,
      }
    }
    case ActionTypes.REQUEST_UPDATE_GRID_VARIABLES_TABLE: {
      return {
        ...state,
        isUpdating: true,
      }
    }
    case ActionTypes.SUCCESS_UPDATE_GRID_VARIABLES_TABLE: {
      // 更新完了とともにinputTableがoriginalTableになる
      const newOriginalTable = deepCopyTable(state.inputTable)
      const newInputTable: Table = {}
      Object.keys(state.inputTable).forEach(key => {
        const rowNumber = Number(key)
        const value = state.inputTable[rowNumber]
        if (!value) {
          return
        }
        newInputTable[rowNumber] = { ...value, hasChanged: false }
      })
      return {
        ...state,
        originalTable: newOriginalTable,
        inputTable: newInputTable,
        isUpdating: false,
      }
    }
    case ActionTypes.ERROR_UPDATE_GRID_VARIABLES_TABLE: {
      return {
        ...state,
        isUpdating: false,
        error: action.error,
      }
    }
    case ActionTypes.SET_PATCH_GRID_VARIABLES_ERROR: {
      return {
        ...state,
        isUpdating: false,
        patchGridVariablesError: {
          errorMessage: action.errorMsg,
          code: action.code,
        },
      }
    }
    case ActionTypes.CLEAR_PATCH_GRID_VARIABLES_ERROR: {
      return {
        ...state,
        patchGridVariablesError: undefined,
      }
    }
    case ActionTypes.START_RANGE_SELECT: {
      // ドラッグを開始したセルの座標
      const {
        dragStartedCell: { rowNum: startRowNum, colNum: startColNum },
      } = action

      // ドラッグ開始座標を選択範囲にセットする
      const newSelectedCells: CellMap = {
        [startRowNum]: { [startColNum]: true },
      }

      return {
        ...state,
        dragStartedCell: action.dragStartedCell,
        selectedCells: newSelectedCells,
        mode: 'drag',
      }
    }
    case ActionTypes.CONTINUE_RANGE_SELECT: {
      // ドラッグを開始したセルの座標
      const {
        dragStartedCell: { rowNum: startRowNum, colNum: startColNum },
      } = state

      // 終端にある(= 現在マウスが指している)セルの座標
      const {
        edgeCell: { rowNum: edgeRowNum, colNum: edgeColNum },
      } = action

      // ドラッグ開始位置が undefined なのに、この case に来ることは、実装ミス以外ありえない
      if (startRowNum === undefined || startColNum === undefined) {
        // 先に `START_RANGE_SELECT` action を呼ぶなどして `dragStartedCell` に値を入れなければならない
        console.error(
          'called `CONTINUE_RANGE_SELECT` before setting `dragStartedCell`',
        )
        return { ...state }
      }

      // 矩形範囲を求める
      const newSelectedCells = calcRectangle(
        { rowNum: startRowNum, colNum: startColNum },
        { rowNum: edgeRowNum, colNum: edgeColNum },
      )

      return {
        ...state,
        selectedCells: newSelectedCells,
      }
    }
    case ActionTypes.FINISH_RANGE_SELECT: {
      return {
        ...state,
        mode: 'select',
      }
    }
    case ActionTypes.DOUBLE_CLICK_CELL: {
      return {
        ...state,
        selectedCells: action.selectedCells,
        mode: action.mode,
      }
    }
    case ActionTypes.SELECT_ALL_CELL: {
      // 全選択状態の CellMap を作る
      const allCell: CellMap = {}
      for (const rowNumber of Array(ROW_COUNT).keys()) {
        const row: { [key: number]: true } = {}
        for (const colNumber of Array(COL_COUNT).keys()) {
          // 列番号は 1 始まりなので 1 を足す
          row[colNumber + 1] = true
        }
        // 行番号は 1 始まりなので 1 を足す
        allCell[rowNumber + 1] = row
      }

      return {
        ...state,
        selectedCells: allCell,
        mode: 'select',
      }
    }
    case ActionTypes.PICK_DATE: {
      return {
        ...state,
        mode: action.mode,
      }
    }
    case ActionTypes.SELECT_OPTION: {
      return {
        ...state,
        mode: action.mode,
      }
    }
    case ActionTypes.PATCH_INPUT_TABLE: {
      const { patchData } = action
      const { originalTable, inputTable, sectionsByName } = state

      // newInputTable 変数を更新していき return 時に既存の inputTable から置き換える
      const newInputTable = deepCopyTable(inputTable)

      Object.keys(patchData).forEach(k => {
        const rowNumber = Number(k)

        const rowPatchData = patchData[rowNumber]
        if (rowPatchData === undefined) return

        if (rowPatchData === null) {
          delete newInputTable[rowNumber]
          return
        }

        const oldRow = inputTable[rowNumber]
        const newRow = createPatchedRow(rowPatchData, oldRow, sectionsByName)

        // 1セルも入力がない場合はその行のデータを削除
        if (!hasInput(newRow)) {
          delete newInputTable[rowNumber]
          return
        }

        // diffチェック
        const originalRow = originalTable[rowNumber]
        newRow.hasChanged = hasChanged(newRow, originalRow)

        // バリデーション
        const validator = new RowValidator(sectionsByName)
        const validatedNewRow = validator.getValidatedRow(newRow)

        newInputTable[rowNumber] = validatedNewRow
      })

      return { ...state, inputTable: newInputTable }
    }
    case ActionTypes.CLEAR_STATE: {
      return initialEditGridVariablesState
    }
    default:
      return { ...state }
  }
}

export default combineReducers({
  editGridVariables,
})
