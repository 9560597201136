import { FC } from 'react'
import { CellInner, TableBodyCellWrapper } from './Common.dumb'

export const TableBodyCellNotInputted: FC = () => {
  return (
    <TableBodyCellWrapper fontColor="notInputted">
      <CellInner>未回答</CellInner>
    </TableBodyCellWrapper>
  )
}
