import { FeatureNameEnum } from '@ulysses-inc/harami_api_client'
import { useFeature } from 'src/features/featureFlags/useFeatureFlags'
import { appEnv } from 'src/util/appEnv'

/**
 * 表形式の計算式が使用できるかどうかを返すカスタムフック
 */
export const useGridLayoutFormulaAvailability = (): boolean => {
  // TODO: 最終的にこのコードは消す
  const isFeatureEnabledForTheCompany =
    useFeature(FeatureNameEnum.GRID_LAYOUT_FORMULA).canUse === 'yes'

  // ローカル環境では無条件で表示する
  if (appEnv.isLocal) return true

  return isFeatureEnabledForTheCompany
}
