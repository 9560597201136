const regexValidChar = /^[\x20-\x7e]*$/

export const isAllCharsSame = (str: string) => {
  if (str.length === 0) {
    return false // 空文字列は同じ文字とみなさない
  }

  const firstChar = str[0]
  return str.split('').every(char => char === firstChar)
}

const isValidChar = (str: string) => {
  return str.match(regexValidChar)
}

export const validateLoginIdPolicy = (str: string) => {
  if (!isValidChar(str)) {
    return false
  }

  if (str.length < 6) {
    return false
  }

  return true
}

export const validatePasswordPolicy = (str: string) => {
  if (!isValidChar(str)) {
    return false
  }

  if (str.length < 8) {
    return false
  }

  return true
}
