import { Text } from '@react-pdf/renderer'
import { ResponseSet } from '@ulysses-inc/harami_api_client'
import { FC } from 'react'
import { gridLayoutTableBodyCell } from '../../../shared/styles'

const convertMultipleSets = (
  responseSets: ResponseSet[] | undefined,
): string => {
  return responseSets?.map(item => item.name).join('、') ?? ''
}

type Props = {
  value?: ResponseSet[]
}

export const TableBodyCellResponseSet: FC<Props> = ({ value }) => {
  return (
    <Text
      style={[
        gridLayoutTableBodyCell.baseTableBodyCell,
        gridLayoutTableBodyCell.tableBodyCell,
      ]}
    >
      {value && convertMultipleSets(value)}
    </Text>
  )
}
