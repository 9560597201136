import {
  GetImproveEmailsRequest,
  ImproveEmail,
  ImprovesApi,
  PostImproveEmailRequest,
} from '@ulysses-inc/harami_api_client'
import { call, put, takeEvery } from 'redux-saga/effects'
import * as notificationServiceActions from 'src/features/notificationService/slice'
import interceptionsActions from 'src/state/ducks/interceptions/actions'
import BaseClient from 'src/state/middleware/saga/baseClient'
import {
  HTTPError,
  handleHTTPError,
} from 'src/state/middleware/saga/handleHttpError'
import { default as actions } from './actions'
import { ActionTypes } from './types'

const baseClient = new BaseClient()

const getImproveEmailLogsRequest = (req: GetImproveEmailsRequest) => {
  return baseClient
    .getApi(ImprovesApi)
    .getImproveEmails(req)
    .then(improveEmails => improveEmails)
    .catch(handleHTTPError)
}

const addImproveEmailLogRequest = (req: PostImproveEmailRequest) => {
  return baseClient
    .getApi(ImprovesApi)
    .postImproveEmail(req)
    .catch(handleHTTPError)
}

function* getImproveEmailLogs(
  action: ReturnType<typeof actions.getImproveEmailLogs>,
) {
  try {
    const response: ImproveEmail[] = yield call(
      getImproveEmailLogsRequest,
      action.request,
    )
    yield put(actions.getSuccessImproveEmailLogs(response))
  } catch (error) {
    yield put(interceptionsActions.handleHttpError(error as HTTPError))
    yield put(actions.addErrorImproveEmailLog(error as Error))
  }
}

function* addImproveEmailLog(
  action: ReturnType<typeof actions.addImproveEmailLog>,
) {
  try {
    yield call(addImproveEmailLogRequest, action.request)
    yield put(actions.addSuccessImproveEmailLog())
    if (action.message !== '') {
      yield put(
        notificationServiceActions.showNotification({
          message: action.message,
        }),
      )
    }
  } catch (error) {
    yield put(interceptionsActions.handleHttpError(error as HTTPError))
    yield put(actions.addErrorImproveEmailLog(error as Error))
  }
}

const sagas = [
  takeEvery(ActionTypes.REQUEST_GET_IMPROVE_EMAIL_LOGS, getImproveEmailLogs),
  takeEvery(ActionTypes.REQUEST_ADD_IMPROVE_EMAIL_LOG, addImproveEmailLog),
]

export default sagas
