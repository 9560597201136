import styled from 'styled-components'

export const DrawerTitle = styled.div`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
`

export const FormRow = styled.div`
  margin-bottom: 15px;
`

export const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
`
