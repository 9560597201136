const localeJa = {
  mixed: {
    notType: function notType(_ref: { path: string; type: string }) {
      switch (_ref.type) {
        case 'number':
          return `${_ref.path}は数字を入力してください`
        case 'string':
          return `${_ref.path}は数字を入力してください`
        default:
          return `${_ref.path}は入力する型が間違っています`
      }
    },
    default: '${path}が間違っています',
    required: '${path}は必須項目です',
    oneOf: '${path}は次の値のいずれかでなければなりません: ${values}',
    notOneOf: '${path}は次の値のいずれかであってはなりません: ${values}',
  },
  string: {
    required: '${path}は必須項目です',
    length: '${path}の長さは${length}文字以下でなければいけません',
    min: '${path}は少なくとも${min}文字である必要があります',
    max: '${path}は最大${max}文字でなければなりません',
    matches: '${path}は次と一致する必要があります: "${regex}"',
    email: '${path}は有効なメールアドレスである必要があります',
    url: '${path}は有効なURLである必要があります',
    trim: '${path}はトリミングされた文字列でなければなりません',
    lowercase: '${path}は小文字の文字列でなければなりません',
    uppercase: '${path}は大文字の文字列でなければなりません',
  },
  number: {
    required: '${path}は必須項目です',
    min: '${path}は${min}以上である必要があります',
    max: '${path}は${max}以下でなければなりません',
    lessThan: '${path}は${less}未満でなければなりません',
    moreThan: '${path}は${more}より大きくなければなりません',
    notEqual: '${path}は${notEqual}と等しくない必要があります',
    positive: '${path}は正数でなければなりません',
    negative: '${path}は負の数でなければなりません',
    integer: '${path}は整数でなければなりません',
  },
  date: {
    required: '${path}は必須項目です',
    min: '${path}は${min}より後でなければなりません',
    max: '${path}は${max}より前でなければなりません',
  },
  object: {
    required: '${path}は必須項目です',
    noUnknown:
      '${path}には、オブジェクトに指定されていないキーを含めることはできません',
  },
  array: {
    required: '${path}は必須項目です',
    min: '${path}には少なくとも${min}個のアイテムが必要です',
    max: '${path}には${max}個以下のアイテムが必要です',
  },
}

export default localeJa
