import React from 'react'
import { Header } from 'src/components/header/Header'
import { HeaderTitle } from 'src/components/header/HeaderTitle'
import ApprovalReports from 'src/features/approvals/ApprovalReports'

const ApprovalListScene: React.FC = () => {
  return (
    <div>
      <Header>
        <HeaderTitle title="承認" />
      </Header>
      <ApprovalReports />
    </div>
  )
}

export default ApprovalListScene
