import { Modal, Typography } from 'antd'
import styled from 'styled-components'

export const EditorModal = styled(Modal)`
  min-width: 1000px;
`

export const ModalTitleWrap = styled.div`
  display: flex;
  align-items: center;
`

export const ModalTitle = styled(Typography.Title)`
  && {
    margin-bottom: 0px;
    margin-left: 10px;
  }
`
