import {
  Schedule,
  ScheduleFrequencyEnum,
  ScheduleTypeEnum,
} from '@ulysses-inc/harami_api_client'
import date from 'src/exShared/util/date'

const formatReportDate = (reportDate: Date, schedules?: Schedule[]): string => {
  const reportDateStr = date.formatYYYYMMDD_JP(reportDate)
  const schedule = schedules && schedules.length > 0 ? schedules[0] : undefined
  if (schedule && schedule.type === ScheduleTypeEnum.Duration) {
    switch (schedule.frequency) {
      case ScheduleFrequencyEnum.Monthly:
        return `${reportDateStr} ~ ${date.formatYYYYMMDD_JP(
          date.endOfMonth(reportDate),
        )}`
      case ScheduleFrequencyEnum.Weekly:
        return `${reportDateStr} ~ ${date.formatYYYYMMDD_JP(
          date.endOfWeek(reportDate),
        )}`
      case ScheduleFrequencyEnum.Custom:
        return `${reportDateStr} ~ ${date.formatYYYYMMDD_JP(
          date.addDays(
            reportDate,
            schedule.frequencyCustom?.every
              ? schedule.frequencyCustom.every - 1
              : 0,
          ),
        )}`
    }
  }
  return reportDateStr
}

export default formatReportDate
