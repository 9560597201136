import { DownOutlined, UpOutlined } from '@ant-design/icons'
import { Typography } from 'antd'
import {
  BorderColor,
  DownIconColor,
  UpIconColor,
} from 'src/features/theme/KdsThemeColor'
import styled from 'styled-components'

const { Text } = Typography

const TableCell = styled.div`
  display: table-cell;
  border: 1px solid rgba(0, 0, 0, 0.12);
  background: #fafafa;
  vertical-align: middle;
`

export const SelectResponseTableCell = styled(TableCell)`
  min-width: ${({ theme }) => theme.minWidth};
  background-color: ${({ theme }) => theme.backgroundColor};
`

export const SelectAnswerWrapper = styled.div`
  flex: 1;
  margin-left: 12px;
`

export const MultipleChoiceHeaderContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  line-height: 40px;
  margin: 5px;
  height: 40px;
  > :first-child {
    margin-right: auto;
  }
`

export const UpOutlinedIcon = styled(UpOutlined)`
  color: ${UpIconColor};
`

export const DownOutlinedIcon = styled(DownOutlined)`
  color: ${DownIconColor};
`

export const ScrollContainer = styled.div`
  overflow: scroll;
  max-height: 400px;
`

export const ResponseMenu = styled.div`
  width: 320px;
`
export const ResponseItem = styled.div`
  float: left;
  height: 56px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
  position: relative;
`

export const MultipleChoiceSetHeaderContainer = styled.div`
  float: left;
  width: 100%;
  border-top: 0.5px solid ${BorderColor};
  margin-top: 16px;
  padding: 8px 4px;
`

export const MultipleChoiceSetTitleText = styled(Text)``

export const MultipleChoiceSetItem = styled.div`
  height: 40px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
  position: relative;
  padding-left: 8px;
`

export const MultipleChoiceSetText = styled(Text)`
  font-weight: bold;
`

export const ResponseEditButton = styled.div`
  position: sticky;
  left: 0;
  align-self: center;
  background: white;
  margin-right: 1rem;
`
