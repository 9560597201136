import { QueryClient, useQuery } from '@tanstack/react-query'
import {
  FeatureFlagsApi,
  FeatureNameEnum,
} from '@ulysses-inc/harami_api_client'
import { baseClient } from 'src/state/middleware/saga/baseClient'
import { queryKeyPrefix } from '../tanStackQuery/queryKeyPrefix'

/*
 * ユーザーには以下のような認識で使って貰えばOK
 *
 * 以下のタイミングで、新機能が表示（非表示）になる
 *  - ブラウザをリロードした時
 *  - ブラウザを開いたままにした場合でも、30 分ほど経過した後で、画面操作した時
 *
 * Feature Flags を OFF にした瞬間に機能を塞ぐ、ということはできないが、
 * まずは、以下のような点を重視して、決めで staleTime を定義しておく。
 * もちろんやってみた結果、再検討の余地は大いにある。
 *
 * - Feature Flags 周りの機構に負荷をかけすぎないこと
 * - ユーザーが不思議に思う動作が発生する確率を下げること
 *   - staleTime が短い場合、操作をしている最中に機能の表示/非表示が切り替わったという現象に遭遇する確率が高くなる
 *
 */
const STALE_TIME_MILLIS = 30 * 60 * 1000 // 30 分

const useEnabledFeaturesQuery = () => {
  return useQuery({
    queryKey: queryKeyPrefix.featureFlags,
    queryFn: () =>
      baseClient.getApi(FeatureFlagsApi, true).getEnabledFeatures(),
    select: data =>
      new Set<FeatureNameEnum>(data.features.map(feature => feature.name)),
    gcTime: Infinity,
    staleTime: STALE_TIME_MILLIS,
  })
}

type CanUse = 'yes' | 'no' | 'unknown'

/**
 * featureName で表される機能が 利用できるかどうかを判定するためのカスタムフック
 *
 * @param featureName
 * @returns canUse: yes: 機能が利用できる, no: 機能が利用できない(エラーの場合も兼ねる)、unknown: 通信中で判断できない
 */
export const useFeature = (
  featureName: FeatureNameEnum,
): {
  canUse: CanUse
} => {
  const { data, isLoading } = useEnabledFeaturesQuery()

  if (isLoading) {
    return { canUse: 'unknown' }
  }

  if (data && data.has(featureName)) {
    return { canUse: 'yes' }
  }

  return { canUse: 'no' }
}

/**
 * 利用できる機能を一覧で取得したい場合に利用するカスタムフック
 */
export const useFeatureFlagsBulk = (): FeatureNameEnum[] => {
  const { data } = useEnabledFeaturesQuery()

  if (!data) {
    return []
  }

  return [...data]
}

/**
 * ログイン時に、Feature Flags のクエリをinvalidate（強制 stale させる）
 *
 * Feature Flags を利用する画面での「認証切れ」の場合に、API から取得されたものではない
 * 情報がキャッシュされてしまうケースがある。
 *
 * 再度ログインし直した場合に、このキャッシュを無効化し、改めて API から取得された情報がキャッシュされるようにするために
 * ログイン直後に呼び出すことを想定した処理。
 *
 * @see https://tanstack.com/query/v4/docs/react/guides/query-invalidation
 * @param client
 */
export const invalidateFeatureFlagsQuery = (client: QueryClient) => {
  client.invalidateQueries({
    queryKey: queryKeyPrefix.featureFlags,
    exact: true,
  })
}
