import { css } from '@emotion/react'
import React, { FC } from 'react'

const styles = {
  badge: css`
    align-items: center;
    align-self: flex-start;
    border-radius: 8px;
    display: flex;
    height: 27px;
    margin-bottom: 8px;
    margin-top: 8px;
    padding: 0 9px;
  `,
}

// reactでの定義をそのまま流用
type DivProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>

export const Badge: FC<DivProps> = props => {
  return <div css={styles.badge} {...props}></div>
}
