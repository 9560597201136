import { TemplateHint } from '@ulysses-inc/harami_api_client'
import React from 'react'
import { TableHeaderBackgroundColor } from 'src/features/theme/KdsThemeColor'
import styled from 'styled-components'

const Table = styled.div`
  display: table;
  border-collapse: collapse;
  height: 46px;
  line-height: 46px;
`

const TableHeaderCell = styled.div`
  display: table-cell;
  border: 1px solid rgba(0, 0, 0, 0.12);
  background-color: ${TableHeaderBackgroundColor};
`

const BasicTableHeaderCell = styled<any>(TableHeaderCell)`
  min-width: ${({ theme }) => theme.minWidth};
`

const QuestionTableHeaderCell = styled<any>(TableHeaderCell)`
  width: ${({ theme }) => theme.width};
`

const ColumnParagraph = styled.div`
  font-weight: bold;
  margin-left: 10px;
`

interface OwnProps {
  templateHints: Array<TemplateHint>
  isShowScore: boolean
  isExcelConversion: boolean
}

const QuestionHeader: React.FC<OwnProps> = (props: OwnProps) => {
  return (
    <Table>
      <QuestionTableHeaderCell theme={{ width: '100%' }}>
        <ColumnParagraph>質問</ColumnParagraph>
      </QuestionTableHeaderCell>
      <BasicTableHeaderCell theme={{ minWidth: '300px' }}>
        <ColumnParagraph>回答</ColumnParagraph>
      </BasicTableHeaderCell>
      {props.templateHints.map((templateHint: TemplateHint) => {
        return (
          <BasicTableHeaderCell
            key={templateHint.id}
            theme={{ minWidth: '200px' }}
          >
            <ColumnParagraph>{templateHint.name}</ColumnParagraph>
          </BasicTableHeaderCell>
        )
      })}
      {props.isExcelConversion && (
        <BasicTableHeaderCell theme={{ minWidth: '216px' }}>
          <ColumnParagraph>EXCEL出力ラベル</ColumnParagraph>
        </BasicTableHeaderCell>
      )}
      {props.isShowScore && (
        <BasicTableHeaderCell theme={{ minWidth: '90px' }}>
          <ColumnParagraph>配点</ColumnParagraph>
        </BasicTableHeaderCell>
      )}
    </Table>
  )
}

export default QuestionHeader
