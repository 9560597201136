import {
  ResponseFormula,
  ResponseNumber,
  ResponseNumberSubTypeEnum,
} from '@ulysses-inc/harami_api_client'

const convertDisplayCharacters = (
  scale: string,
  rn: ResponseNumber | ResponseFormula,
): string => {
  const ruleCharacters = convertRuleCharacters(scale, rn)
  const decimalPointCharacters = convertDecimalPointCharacters(
    scale,
    rn as ResponseNumber,
  )
  return [ruleCharacters, decimalPointCharacters].filter(v => !!v).join('・')
}

export const convertRuleCharacters = (
  scale: string,
  rn: ResponseNumber | ResponseFormula,
): string => {
  const type = rn ? rn.subType ?? -1 : -1
  switch (type) {
    case ResponseNumberSubTypeEnum.BETWEEN:
      return `${rn.minimum}${scale}〜${rn.maximum}${scale}の間`
    case ResponseNumberSubTypeEnum.LESS_THAN:
      return `${rn.maximum}${scale}以下`
    case ResponseNumberSubTypeEnum.GREATER_THAN:
      return `${rn.minimum}${scale}以上`
    default:
      return 'ルールなし'
  }
}
export const convertDecimalPointCharacters = (
  scale: string,
  rn: ResponseNumber,
): string => {
  if (rn?.decimalPoint?.isActive === 1) {
    return `小数点${rn.decimalPoint.value || 0}桁`
  }
  return ''
}

export default convertDisplayCharacters
