import {
  ReportNodeSchema,
  ResponseFormulaToken,
  ResponseFormulaTokenOperatorTypeEnum,
  ResponseFormulaTokenTypeEnum,
  TemplateNodeSchema,
} from '@ulysses-inc/harami_api_client'

const convertDisplayFormulaCharacters = (
  numberNodes: (ReportNodeSchema | TemplateNodeSchema)[],
  tokens: ResponseFormulaToken[],
): string => {
  const convertedTokens: string[] = []
  for (const token of tokens) {
    switch (token.type) {
      case ResponseFormulaTokenTypeEnum.QUESTION: {
        const node = numberNodes.find(
          node => node.uuid === token.questionNodeUUID,
        )
        if (!node) {
          // 数値・質問が非表示設定されている場合
          convertedTokens.push('未使用')
        } else {
          convertedTokens.push(node.question?.name || '質問名なし')
        }
        break
      }
      case ResponseFormulaTokenTypeEnum.CONSTANT:
        convertedTokens.push(String(token.constant))
        break
      case ResponseFormulaTokenTypeEnum.OPERATOR: {
        convertedTokens.push(convertOperatorString(token.operator))
        break
      }
    }
  }

  return convertedTokens.join(' ')
}

const convertOperatorString = (
  operatorEnum?: ResponseFormulaTokenOperatorTypeEnum,
): string => {
  if (operatorEnum === undefined) {
    throw new Error(
      'unexpected enum, ResponseFormulaTokenOperatorTypeEnum was undefined',
    )
  }

  switch (operatorEnum) {
    case ResponseFormulaTokenOperatorTypeEnum.PLUS:
      return '+'
    case ResponseFormulaTokenOperatorTypeEnum.MINUS:
      return '-'
    case ResponseFormulaTokenOperatorTypeEnum.MULTI:
      return 'x'
    case ResponseFormulaTokenOperatorTypeEnum.DIVIDED:
      return '÷'
  }
}

export default convertDisplayFormulaCharacters
