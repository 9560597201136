import { TemplateHint } from '@ulysses-inc/harami_api_client'
import { TemplateHintAction } from 'src/state/ducks/templates/templateHints/actions'
import { ActionTypes } from 'src/state/ducks/templates/templateHints/types'

interface TemplateHintsState {
  templateHints: Array<TemplateHint>
  isLoading: boolean
  isDirty: boolean
  error: Error | null
  templateHintErrorMessage: string
}

const initialTemplateHintsState: TemplateHintsState = {
  templateHints: [],
  isLoading: false,
  isDirty: false,
  error: null,
  templateHintErrorMessage: '',
}

export const templateHintsReducer = (
  state: TemplateHintsState = initialTemplateHintsState,
  action: TemplateHintAction,
) => {
  switch (action.type) {
    case ActionTypes.REQUEST_GET_TEMPLATE_HINTS: {
      return {
        ...state,
        isLoading: action.isLoading,
        templateId: action.templateId,
        error: null,
      }
    }
    case ActionTypes.SUCCESS_GET_TEMPLATE_HINTS: {
      return {
        ...state,
        isLoading: action.isLoading,
        templateHints: action.templateHints,
        isDirty: false,
      }
    }
    case ActionTypes.ERROR_GET_TEMPLATE_HINTS: {
      return { ...state, isLoading: action.isLoading, error: action.error }
    }
    case ActionTypes.RESET_TEMPLATE_HINTS: {
      return { ...state, ...initialTemplateHintsState, isDirty: false }
    }
    case ActionTypes.SUCCESS_ADD_TEMPLATE_HINTS: {
      return { ...state, isLoading: action.isLoading, isDirty: false }
    }
    case ActionTypes.ADD_TEMPLATE_HINT: {
      const createTemplateHint: TemplateHint = {
        id: action.uid,
        name: '',
      }
      return {
        ...state,
        templateHints: [...state.templateHints, createTemplateHint],
        isDirty: true,
      }
    }
    case ActionTypes.UPDATE_TEMPLATE_HINT: {
      state.templateHints = state.templateHints.map(
        (templateHint: TemplateHint) => {
          if (templateHint.id === action.hintId)
            return action.changeTemplateHint
          return templateHint
        },
      )
      return { ...state, isDirty: true }
    }
    case ActionTypes.DELETE_TEMPLATE_HINT: {
      state.templateHints = state.templateHints.filter(
        (templateHint: TemplateHint) => templateHint.id !== action.hintId,
      )
      return { ...state, isDirty: true }
    }
    case ActionTypes.SET_TEMPLATE_HINT_ERROR_MESSAGE: {
      return {
        ...state,
        templateHintErrorMessage: action.message,
      }
    }
    default: {
      return { ...state }
    }
  }
}
