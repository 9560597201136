import { TemplateNodeSchema } from '@ulysses-inc/harami_api_client'
import { ActionTypes } from 'src/state/ducks/templates/editDeviateNotify/types'

/**
 * 逸脱時通知先の編集ドロワーを開く
 */
export const showEditDeviateNotify = (node: TemplateNodeSchema) => {
  return {
    type: ActionTypes.SHOW_EDIT_DEVIATE_NOTIFY,
    node,
  }
}

/**
 * 逸脱時通知先の編集ドロワーを閉じる
 */
export const closeEditDeviateNotify = () => {
  return {
    type: ActionTypes.CLOSE_EDIT_DEVIATE_NOTIFY,
  }
}

export type EditDeviateNotifyAction =
  | ReturnType<typeof showEditDeviateNotify>
  | ReturnType<typeof closeEditDeviateNotify>
