import { Improve } from '@ulysses-inc/harami_api_client'
import { Tag } from 'antd'
import React from 'react'
import dateUtil from 'src/exShared/util/date'
import { White } from 'src/features/theme/KdsThemeColor'
import styled from 'styled-components'

const SubInfo = styled.div`
  align-items: center;
  background: ${White};
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 34px;
  display: flex;
`

const SubInfoLabel = styled(Tag)`
  background: #595959;
  color: ${White};
  border: 0;
`

const SubInfoValue = styled.div`
  font-size: 14px;
  font-weight: bold;
  margin-right: 12px;
`

interface OwnProps {
  improve: Improve
}

const SubInfos: React.FC<OwnProps> = (props: OwnProps) => {
  return (
    <SubInfo>
      <SubInfoLabel>実施日</SubInfoLabel>
      <SubInfoValue>
        {dateUtil.formatYYYYMMDD_JP(props.improve.reportCreatedAt)}
      </SubInfoValue>
      <SubInfoLabel>現場名</SubInfoLabel>
      <SubInfoValue>{props.improve.place?.name}</SubInfoValue>
      <SubInfoLabel>指摘報告者</SubInfoLabel>
      <SubInfoValue>{props.improve.auditUser?.name}</SubInfoValue>
    </SubInfo>
  )
}
export default SubInfos
