import {
  CalendarOutlined as CalendarIcon,
  CommentOutlined as CommentIcon,
  DownOutlined,
  MessageOutlined as MessageIcon,
  ReloadOutlined as ReloadIcon,
  TagOutlined,
  UpOutlined,
} from '@ant-design/icons'
import {
  ExcelConversionStepTypeEnum,
  TemplateQuestionExcelConversionTypes,
} from '@ulysses-inc/harami_api_client'
import { Checkbox, Dropdown, Popover } from 'antd'
import React from 'react'
import {
  DownIconColor,
  Gray,
  UpIconColor,
  White,
} from 'src/features/theme/KdsThemeColor'
import styled from 'styled-components'
import ExcelLabelHover from './ExcelLabelHover'

const excelConversionStepTypes = [
  {
    type: ExcelConversionStepTypeEnum.Single,
    name: '単数質問出力',
    Icon: <MessageIcon />,
    color: White,
    background: '#389E0D',
  },
  {
    type: ExcelConversionStepTypeEnum.Multiple,
    name: '連続質問出力',
    Icon: <CommentIcon />,
    color: Gray,
    background: '#F2C94C',
  },
  {
    type: ExcelConversionStepTypeEnum.Repeat,
    name: '繰り返し出力',
    Icon: <ReloadIcon />,
    color: Gray,
    background: '#F2994A',
  },
  {
    type: ExcelConversionStepTypeEnum.Period,
    name: '期間出力',
    Icon: <CalendarIcon />,
    color: White,
    background: '#1890FF',
  },
]

const TableCell = styled.div`
  display: table-cell;
  border: 1px solid rgba(0, 0, 0, 0.12);
  background: #fafafa;
  vertical-align: middle;
`

const SelectLabelTableCell = styled<any>(TableCell)`
  min-width: ${({ theme }) => theme.minWidth};
  background-color: ${({ theme }) => theme.backgroundColor};
`

const UpOutlinedIcon = styled(UpOutlined)`
  color: ${UpIconColor};
`

const DownOutlinedIcon = styled(DownOutlined)`
  color: ${DownIconColor};
`

const ExcelLabelWrapper = styled.label`
  align-items: center;
  cursor: pointer;
  display: flex;

  :not(:first-child) {
    border-top: 1px solid rgba(0, 0, 0, 0.12);
  }

  padding: 8px 16px;
`

const ExcelLabel = styled.div`
  display: inline-flex;
  align-items: center;
  padding: 0 8px;
  margin-left: 16px;
  background: ${({ theme }) => theme.background};
  color: ${({ theme }) => theme.color};
  font-weight: bold;
  font-size: 13px;
  line-height: 30px;
`

const ExcelLabelIconWrapper = styled.div`
  margin-right: 8px;
`

interface OwnProps {
  checkedExcelConversionTypes: TemplateQuestionExcelConversionTypes[]
  onClickLabel: (stepType: number, checked: boolean) => void
  isRepeat: boolean
  onChangeSerialNumber: (changedIndex: number, value: number) => void
}

const ExcelLabelDropDown: React.FC<OwnProps> = (props: OwnProps) => {
  const [isOpen, setIsOpen] = React.useState(false)

  const filteredExcelConversionTypes = excelConversionStepTypes.filter(
    stepType => {
      if (props.isRepeat) {
        return stepType.type === ExcelConversionStepTypeEnum.Repeat
      } else {
        return stepType.type !== ExcelConversionStepTypeEnum.Repeat
      }
    },
  )
  const hasSerialNumberTypes = [
    ExcelConversionStepTypeEnum.Multiple,
    ExcelConversionStepTypeEnum.Repeat,
  ]

  const mainArea = (
    <SelectLabelTableCell
      theme={{ minWidth: '216px' }}
      data-testid="question-excel-cell"
    >
      <div style={{ display: 'flex' }}>
        <div style={{ flex: 1, marginLeft: 12 }}>
          {props.checkedExcelConversionTypes.map((conversionType, index) => {
            const stepType = excelConversionStepTypes.find(
              stepType => stepType.type === conversionType.excelConversionType,
            )
            return (
              stepType && (
                <div key={stepType.type}>
                  <TagOutlined />
                  {hasSerialNumberTypes.includes(stepType.type) && (
                    <Popover
                      content={
                        <div
                          onClick={event => {
                            event.stopPropagation()
                          }}
                        >
                          <ExcelLabelHover
                            serialNumber={conversionType.serialNumber || 1}
                            changeSerialNumber={serialNumber => {
                              props.onChangeSerialNumber(index, serialNumber)
                            }}
                          />
                        </div>
                      }
                    >
                      <ExcelLabel
                        theme={{
                          color: stepType.color,
                          background: stepType.background,
                        }}
                      >
                        <ExcelLabelIconWrapper>
                          {stepType.Icon}
                        </ExcelLabelIconWrapper>
                        <span>{`${stepType.name}${conversionType.serialNumber}`}</span>
                      </ExcelLabel>
                    </Popover>
                  )}
                  {!hasSerialNumberTypes.includes(stepType.type) && (
                    <ExcelLabel
                      theme={{
                        color: stepType.color,
                        background: stepType.background,
                      }}
                    >
                      <ExcelLabelIconWrapper>
                        {stepType.Icon}
                      </ExcelLabelIconWrapper>
                      <span>{stepType.name}</span>
                    </ExcelLabel>
                  )}
                </div>
              )
            )
          })}
        </div>
        <div style={{ minWidth: 20, marginRight: 12 }}>
          {isOpen ? <UpOutlinedIcon /> : <DownOutlinedIcon />}
        </div>
      </div>
    </SelectLabelTableCell>
  )

  const hoverArea = (
    <SelectLabelTableCell theme={{ minWidth: '216px', backgroundColor: White }}>
      {filteredExcelConversionTypes.map(stepType => {
        return (
          <ExcelLabelWrapper key={stepType.type}>
            <Checkbox
              checked={props.checkedExcelConversionTypes
                .map(c => c.excelConversionType)
                .includes(stepType.type)}
              onChange={e =>
                props.onClickLabel(stepType?.type?.valueOf(), e.target.checked)
              }
            />
            <ExcelLabel
              theme={{
                color: stepType.color,
                background: stepType.background,
              }}
            >
              {stepType.Icon}
              <span>{stepType.name}</span>
            </ExcelLabel>
          </ExcelLabelWrapper>
        )
      })}
    </SelectLabelTableCell>
  )

  return (
    <Dropdown
      dropdownRender={() => hoverArea}
      trigger={['click']}
      open={isOpen}
      onOpenChange={setIsOpen}
    >
      {mainArea}
    </Dropdown>
  )
}

export default ExcelLabelDropDown
