import { Moment } from 'moment'
import { ComponentProps, useState } from 'react'
import { MomentDatePicker } from 'src/components/datepicker/MomentDatePicker'
import { maxSelectableDays } from 'src/features/scheduledReportSummary/ScheduledReportSummary'

type RangePickerProps = ComponentProps<typeof MomentDatePicker.RangePicker>

type LocalRange = [Moment | null, Moment | null] | null

/**
 * 選択できる期間に制約をかけられるようにするために作成したRangePickerのラッパー
 *
 * 選択できる期間に制約をかけるためには、コンポーネントレベルで別途Stateを保持する必要がある。
 * 詳しくは以下の例を参照のこと。
 * 例: https://4x.ant.design/components/date-picker/#components-date-picker-demo-select-in-range
 */
export const LimitedRangePicker = (props: RangePickerProps) => {
  const { value: parentRange } = props

  const [localRange, setLocalRange] = useState<LocalRange>(null)

  const onOpenChange = (open: boolean) => {
    if (open) {
      setLocalRange([null, null])
    } else {
      setLocalRange(null)
    }
  }

  // 開始日または終了日のいずれかに変更があった段階で呼ばれる
  // 一方、親において使用するであろう`onChange`は開始日と終了日の両方が確定した段階で呼ばれる
  const onCalendarChange: RangePickerProps['onCalendarChange'] = newRange => {
    setLocalRange(newRange)
  }

  // Pickerを開いている時は、localRangeの値が表示される (localRangeは`[Moment | null, Moment | null]`のため)
  // Pickerを開く前または閉じた後は、parentRangeの値が表示される (localRangeは`null`のため)
  const rangeToDisplay = localRange || parentRange

  // 日付選択範囲に制約を加える
  const disabledDate = (someDay: Moment) => {
    const startDate = localRange?.[0]
    const endDate = localRange?.[1]

    const isTooLate =
      startDate && someDay.diff(startDate, 'days') >= maxSelectableDays
    const isTooEarly =
      endDate && someDay.diff(endDate, 'days') <= -1 * maxSelectableDays
    const isAfterTomorrow = someDay.isAfter(new Date())

    return isAfterTomorrow || !!isTooLate || !!isTooEarly
  }

  return (
    <MomentDatePicker.RangePicker
      {...props}
      disabledDate={disabledDate}
      onCalendarChange={onCalendarChange}
      onOpenChange={onOpenChange}
      value={rangeToDisplay}
    />
  )
}
