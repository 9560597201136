import {
  TemplateNodeSchema,
  TemplateNodeTypeEnum,
} from '@ulysses-inc/harami_api_client'
import React from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { createNode } from 'src/features/templateEdit/common/createNode'
import { MoveNodeEvent } from 'src/features/templateEdit/dragAndDrop/DnDTemplateType'
import { moveTemplateNode } from 'src/state/ducks/templates/actions'
import templateSelectors from 'src/state/ducks/templates/selectors'
import { RootState } from 'src/state/store'
import styled from 'styled-components'
import AddTemplateModifyButtonGroupInEmpty from '../itemAppender/AddTemplateModifyButtonGroupInEmpty'
import Question from '../question/Question'
import Section from '../section/Section'

interface OwnProps {
  node: TemplateNodeSchema
  path: number[]
}

const LogicContentContainer = styled.div`
  width: 100%;
`

const LogicContent: React.FC<OwnProps> = ({ node, path }) => {
  const activeNodeId = useSelector(
    (state: RootState) => state.templatesState.templatePages.activeNodeId,
    shallowEqual,
  )
  const templateNodes = useSelector(
    (state: RootState) => state.templatesState.templatePages.templateNodes,
    shallowEqual,
  )

  const dispatch = useDispatch()
  const templateNodeNodes = templateSelectors.useTemplateNodeNodes(node)
  const addTemplateNode = (
    targetNode: TemplateNodeSchema | null,
    parentNode: TemplateNodeSchema | null,
    position: 'top' | 'bottom',
    addTemplateNodeType: TemplateNodeTypeEnum,
  ) =>
    createNode(templateNodes, dispatch, {
      targetNode,
      parentNode,
      position,
      addTemplateNodeType,
      question: targetNode?.question,
    })

  // 空の条件分岐ノードに対して、初めてノードを移動したときに発動するっぽい
  const moveNode = (event: MoveNodeEvent) => {
    const isSameParent =
      (!event.dragNodeParent && !event.dropNodeParent) ||
      (!!event.dragNodeParent &&
        !!event.dropNodeParent &&
        event.dragNodeParent.id === event.dropNodeParent.id)
    dispatch(moveTemplateNode(event, isSameParent))
  }

  return (
    <LogicContentContainer>
      {node.nodes.length === 0 ? (
        <AddTemplateModifyButtonGroupInEmpty
          node={null}
          parentNode={node}
          addTemplateNode={addTemplateNode}
          moveNode={moveNode}
        />
      ) : (
        templateNodeNodes.map(templateNode => {
          const { id, type } = templateNode

          return type === TemplateNodeTypeEnum.Question ? (
            <Question
              key={id}
              node={templateNode}
              parentNode={node}
              path={[...path, node.id]}
              isActive={activeNodeId === id}
              isLastChild={false}
            />
          ) : (
            <Section
              key={id}
              node={templateNode}
              parentNode={node}
              path={[...path, node.id]}
              isActive={activeNodeId === id}
            />
          )
        })
      )}
    </LogicContentContainer>
  )
}

export default LogicContent
