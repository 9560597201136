import { localStorageKeys } from 'src/constants/localStorageKeys'
import { LocalStorageService } from 'src/libraries/localStorage'

export type TemplatesSortOrder = {
  sortOrder: string
  sortColumn: string
}

const localStorageService = new LocalStorageService<TemplatesSortOrder>()

/**
 * ひな形一覧の並び替えの条件をローカルストレージで管理する
 *
 * フィルターに影響が出ないようにlistFilterとはkeyを分ける
 */
export const templatesSortOrderStorage = {
  get: () => {
    return localStorageService.getItem(localStorageKeys.sortOrderTemplates)
  },
  set: (updatedSortOrder: TemplatesSortOrder) => {
    localStorageService.setItem(
      localStorageKeys.sortOrderTemplates,
      updatedSortOrder,
    )
  },
}
