import { PauseCircleOutlined, PlayCircleOutlined } from '@ant-design/icons'
import {
  ExcelConversionDayOfWeekEnum,
  ExcelConversionFlow,
  ExcelConversionScheduleFrequencyEnum,
  ExcelConversionScheduleStartupTypeEnum,
} from '@ulysses-inc/harami_api_client'
import { List, Modal, Row, Tag } from 'antd'
import { ListProps } from 'antd/lib/list'
import React, { JSX, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { RowActionButton } from 'src/components/rowActionButton/RowActionButton'
import date from 'src/exShared/util/date'
import { useLimitedFeatureAvailability } from 'src/features/featureAvailability/useLimitedFeatureAvailability'
import {
  Disable,
  ModalDeleteButtonColor,
  Primary,
  TableBorderColor,
  White,
} from 'src/features/theme/KdsThemeColor'
import excelConversionFlowsOperations from 'src/state/ducks/excelConversionFlows/operations'
import styled from 'styled-components'

const useDispatchProps = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  return {
    goEditFlow: (flowId: string) => {
      history.push(`/excelConversionFlows/${flowId}`)
    },
    deleteFlow: (flowId: string) => {
      excelConversionFlowsOperations.deleteExcelConversionFlow(dispatch, flowId)
    },
  }
}

const ButtonGroupRow = styled.div`
  position: absolute;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: row;
  height: 100%;
  right: 0;
`

const TableBody = styled<
  (props: ListProps<ExcelConversionFlow>) => JSX.Element
>(List)`
  width: 100%;
`

const TableRowWrap = styled(List.Item)`
  position: relative;
  min-height: 46px;
  background-color: ${White};
`

const TableRow = styled.div`
  word-break: break-all;
  width: ${props => props.theme.width};
  padding-left: 16px;
`

const FlowsHeaderContainer = styled(Row)`
  overflow-x: auto;
  justify-content: start;
  margin: 15px 0 15px 15px;
`

const FlowsHeaderWrap = styled.div`
  width: 100%;
  min-width: 800px;
  padding-right: 15px;
`

const FlowsHeader = styled.div`
  width: 100%;
  height: 46px;
  display: flex;
  align-items: center;
  flex-direction: row;
  background: ${White};
  border-bottom: 1px solid ${TableBorderColor};
`

const HeaderRow = styled.div`
  font-weight: bold;
  padding-left: 15px;
  width: ${props => props.theme.width};
`

const PlayCircleOutlinedIcon = styled(PlayCircleOutlined)`
  margin-right: 5px;
`

const PauseCircleOutlinedIcon = styled(PauseCircleOutlined)`
  margin-right: 5px;
`

const getDayOfWeek = (dayOfWeek: ExcelConversionDayOfWeekEnum) => {
  switch (dayOfWeek) {
    case ExcelConversionDayOfWeekEnum.Monday:
      return '月'
    case ExcelConversionDayOfWeekEnum.Tuesday:
      return '火'
    case ExcelConversionDayOfWeekEnum.Wednesday:
      return '水'
    case ExcelConversionDayOfWeekEnum.Thursday:
      return '木'
    case ExcelConversionDayOfWeekEnum.Friday:
      return '金'
    case ExcelConversionDayOfWeekEnum.Saturday:
      return '土'
    case ExcelConversionDayOfWeekEnum.Sunday:
      return '日'
  }
}

const getMakeTime = (flow: ExcelConversionFlow) => {
  if (!(flow.schedules && flow.schedules[0])) {
    return ''
  }
  const schedule = flow.schedules[0]
  if (
    schedule.startupType ===
    ExcelConversionScheduleStartupTypeEnum.OnCompletedReport
  ) {
    return 'レポート完了時'
  }
  const startupTime = schedule.makeTime
    ? date.formatHHMM(schedule.makeTime)
    : ''
  switch (schedule.frequency) {
    case ExcelConversionScheduleFrequencyEnum.Daily: {
      return `毎日 ${startupTime}`
    }
    case ExcelConversionScheduleFrequencyEnum.Weekly: {
      if (!schedule.frequencyWeekly) {
        return ``
      }
      const frequencyWeekly = schedule.frequencyWeekly
      const dayOfWeek = frequencyWeekly.dayOfWeek
        ? getDayOfWeek(frequencyWeekly.dayOfWeek)
        : ''
      return `毎週 ${dayOfWeek}曜 ${startupTime}`
    }
    case ExcelConversionScheduleFrequencyEnum.Monthly: {
      if (!schedule.frequencyMonthly) {
        return ``
      }
      const frequencyMonthly = schedule.frequencyMonthly
      const dayOfMonth = frequencyMonthly.dayOfMonth
      return `毎月 ${dayOfMonth}日 ${startupTime}`
    }
  }
  return ''
}

const getFileName = (flow: ExcelConversionFlow) => {
  return flow.inputFiles?.[0] ? flow.inputFiles[0].name : ''
}

const isActiveBadge = (flow: ExcelConversionFlow) => {
  if (flow.schedules && flow.schedules[0] && flow.schedules[0].isActive) {
    return (
      <Tag color={Primary}>
        <PlayCircleOutlinedIcon />
        アクティブ
      </Tag>
    )
  } else {
    return (
      <Tag color={Disable}>
        <PauseCircleOutlinedIcon />
        停止中
      </Tag>
    )
  }
}

type ExcelConversionFlowListProps = ExcelConversionFlowListContainerProps & {
  goEditFlow: (flowId: string) => void
  deleteFlow: (flowId: string) => void
}

const ExcelConversionFlowList: React.FC<ExcelConversionFlowListProps> = (
  props: ExcelConversionFlowListProps,
) => {
  const { isFeatureAvailable } = useLimitedFeatureAvailability()

  const [activeFlowId, setActiveFlowId] = useState<number>()

  return (
    <FlowsHeaderContainer justify="center">
      <FlowsHeaderWrap>
        <FlowsHeader>
          <HeaderRow theme={{ width: '35%' }}>変換フロー名</HeaderRow>
          <HeaderRow theme={{ width: '25%' }}>作成タイミング</HeaderRow>
          <HeaderRow theme={{ width: '25%' }}>変換対象ファイル名</HeaderRow>
          <HeaderRow theme={{ width: '15%' }}>ステータス</HeaderRow>
        </FlowsHeader>
        <TableBody
          itemLayout="horizontal"
          dataSource={props.flows}
          renderItem={(flow: ExcelConversionFlow) => (
            <TableRowWrap
              onMouseEnter={() => setActiveFlowId(flow.id)}
              onMouseLeave={() => setActiveFlowId(undefined)}
            >
              <TableRow theme={{ width: '35%' }}>{flow.name}</TableRow>
              <TableRow theme={{ width: '25%' }}>{getMakeTime(flow)}</TableRow>
              <TableRow theme={{ width: '25%' }}>{getFileName(flow)}</TableRow>
              <TableRow theme={{ width: '15%' }}>
                {isActiveBadge(flow)}
              </TableRow>
              {activeFlowId === flow.id &&
                isFeatureAvailable('excelConversionEdit') && (
                  <ButtonGroupRow>
                    <RowActionButton
                      onClick={() => props.goEditFlow(flow.uuid ?? '')}
                      type="edit"
                    />
                    <RowActionButton
                      onClick={() => {
                        Modal.confirm({
                          onOk: () => {
                            props.deleteFlow(flow.uuid ?? '')
                          },
                          title: `「${flow.name}」の削除を実行しますがよろしいですか？`,
                          okText: '削除',
                          cancelText: 'キャンセル',
                          okButtonProps: {
                            style: {
                              backgroundColor: ModalDeleteButtonColor,
                              border: 'none',
                            },
                          },
                        })
                      }}
                      type="delete"
                    />
                  </ButtonGroupRow>
                )}
            </TableRowWrap>
          )}
        />
      </FlowsHeaderWrap>
    </FlowsHeaderContainer>
  )
}

type ExcelConversionFlowListContainerProps = {
  flows: ExcelConversionFlow[]
}

const ExcelConversionFlowListContainer: React.FC<
  ExcelConversionFlowListContainerProps
> = _props => {
  const dispatchProps = useDispatchProps()
  const props = { ...dispatchProps, ..._props }
  return <ExcelConversionFlowList {...props} />
}

export default ExcelConversionFlowListContainer
