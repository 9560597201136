import { Tooltip } from 'antd'
import Text from 'antd/lib/typography/Text'
import HintSvg from 'src/assets/icons/hint.svg?react'
import { Gray5, White } from 'src/features/theme/KdsThemeColor'

type OwnProps = {
  title: string
  content: string
}

/**
 * 行ヘッダーに表示するヒント。マウスホバーするとツールチップが表示される
 */
export const Hint = ({ title, content }: OwnProps) => {
  return (
    <Tooltip
      placement="top"
      title={
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Text style={{ color: Gray5, fontSize: '12px' }}>{title}</Text>
          <Text style={{ color: White, fontSize: '12px' }}>{content}</Text>
        </div>
      }
      arrowPointAtCenter
    >
      <HintSvg style={{ minWidth: '14px' }} />
    </Tooltip>
  )
}
