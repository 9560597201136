import { DatePicker as RawDatePicker } from 'antd'
import type { Moment } from 'moment'
import momentGenerateConfig from 'rc-picker/lib/generate/moment'

/**
 * antdのv4までは日時ライブラリが`moment`だったことに起因する破壊的変更を吸収するためのDatePicker
 *
 * @deprecated haramiでは日時ライブラリを`date-fns`に寄せていく方針のため、`DateFnsDatePicker`の方を使ってください。
 */
export const MomentDatePicker =
  RawDatePicker.generatePicker<Moment>(momentGenerateConfig)
