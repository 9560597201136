const delimiter = '、'

export const ellipsizeStrings = (strings: string[], limit = 2) => {
  if (strings.length <= limit) {
    return strings.join(delimiter)
  } else {
    const selectedItems = strings.slice(0, limit)
    const remainingCount = strings.length - limit
    const remainingItems = `他${remainingCount}件`

    return `${selectedItems.join(delimiter)}${delimiter}${remainingItems}`
  }
}
