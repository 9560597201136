// prettier-ignore
export const ActionTypes = {
  //
  REQUEST_GET_USERS: 'harami/users/REQUEST_GET_USERS',
  SUCCESS_GET_USERS: 'harami/users/SUCCESS_GET_USERS',
  ERROR_GET_USERS: 'harami/users/ERROR_GET_USERS',
  REQUEST_CHANGE_USERS_PAGE: 'harami/templates/REQUEST_CHANGE_USERS_PAGE', // FIXME: `templates`になっている
  REQUEST_CHANGE_USERS_SIZE: 'harami/templates/REQUEST_CHANGE_USERS_SIZE', // FIXME: `templates`になっている
  UPDATE_PAGINATION: 'harami/employees/UPDATE_PAGINATION',
  ERROR_ADD_USER: 'harami/users/ERROR_ADD_USER',
  REQUEST_ADD_USER: 'harami/users/REQUEST_ADD_USER',
  SUCCESS_ADD_USER: 'harami/users/SUCCESS_ADD_USER',
  REQUEST_UPDATE_USER_LEGACY: 'harami/users/REQUEST_UPDATE_USER_LEGACY',
  REQUEST_UPDATE_USER: 'harami/users/REQUEST_UPDATE_USER',
  SUCCESS_UPDATE_USER: 'harami/users/SUCCESS_UPDATE_USER',
  ERROR_UPDATE_USER: 'harami/users/ERROR_UPDATE_USER',
  REQUEST_DELETE_USER: 'harami/users/REQUEST_DELETE_USER',
  SUCCESS_DELETE_USER: 'harami/users/SUCCESS_DELETE_USER',
  ERROR_DELETE_USER: 'harami/users/ERROR_DELETE_USER',
  REQUEST_VALIDATE_DELETE_USER: 'harami/users/REQUEST_VALIDATE_DELETE_USER',
  SUCCESS_VALIDATE_DELETE_USER: 'harami/users/SUCCESS_VALIDATE_DELETE_USER',
  USER_DELETE_VALIDATE_ERROR_MESSAGE: 'harami/users/USER_DELETE_VALIDATE_ERROR_MESSAGE',
  ERROR_VALIDATE_DELETE_USER: 'harami/users/ERROR_VALIDATE_DELETE_USER',
  RESET_VALIDATE_DELETE_USER: 'harami/users/RESET_VALIDATE_DELETE_USER',
  UPDATE_SEGMENT_INDEX: 'harami/users/UPDATE_SEGMENT_INDEX',
  UPDATE_ACTIVE_USER_ID: 'harami/users/UPDATE_ACTIVE_USER_ID',
  CHANGE_IS_SHOW_EDIT_USER_DRAWER: 'harami/users/CHANGE_IS_SHOW_EDIT_USER_DRAWER',
  CHANGE_UPDATED_USERS_FLAG: 'harami/users/CHANGE_UPDATED_USERS_FLAG',
  //
  REQUEST_GET_USER_GROUPS: 'harami/userGroups/REQUEST_GET_USER_GROUPS',
  REQUEST_CHANGE_USER_GROUPS_PAGE: 'harami/templates/REQUEST_CHANGE_USER_GROUPS_PAGE',
  REQUEST_CHANGE_USER_GROUPS_SIZE: 'harami/templates/REQUEST_CHANGE_USER_GROUPS_SIZE',
  SUCCESS_GET_USER_GROUPS: 'harami/userGroups/SUCCESS_GET_USER_GROUPS',
  ERROR_GET_USER_GROUPS: 'harami/userGroups/ERROR_GET_USER_GROUPS',
  ERROR_ADD_USER_GROUP: 'harami/userGroups/ERROR_ADD_USER_GROUP',
  REQUEST_ADD_USER_GROUP: 'harami/userGroups/REQUEST_ADD_USER_GROUP',
  SUCCESS_ADD_USER_GROUP: 'harami/userGroups/SUCCESS_ADD_USER_GROUP',
  REQUEST_UPDATE_USER_GROUP: 'harami/userGroups/REQUEST_UPDATE_USER_GROUP',
  SUCCESS_UPDATE_USER_GROUP: 'harami/userGroups/SUCCESS_UPDATE_USER_GROUP',
  ERROR_UPDATE_USER_GROUP: 'harami/userGroups/ERROR_UPDATE_USER_GROUP',
  REQUEST_DELETE_USER_GROUP: 'harami/userGroups/REQUEST_DELETE_USER_GROUP',
  SUCCESS_DELETE_USER_GROUP: 'harami/userGroups/SUCCESS_DELETE_USER_GROUP',
  ERROR_DELETE_USER_GROUP: 'harami/userGroups/ERROR_DELETE_USER_GROUP',
  REQUEST_VALIDATE_DELETE_USER_GROUP: 'harami/userGroups/REQUEST_VALIDATE_DELETE_USER_GROUP',
  SUCCESS_VALIDATE_DELETE_USER_GROUP: 'harami/userGroups/SUCCESS_VALIDATE_DELETE_USER_GROUP',
  USER_GROUP_DELETE_VALIDATE_ERROR_MESSAGE: 'harami/userGroups/USER_GROUP_DELETE_VALIDATE_ERROR_MESSAGE',
  ERROR_VALIDATE_DELETE_USER_GROUP: 'harami/userGroups/ERROR_VALIDATE_DELETE_USER_GROUP',
  RESET_VALIDATE_DELETE_USER_GROUP: 'harami/userGroups/RESET_VALIDATE_DELETE_USER_GROUP',
  UPDATE_ACTIVE_USER_GROUP_ID: 'harami/userGroups/UPDATE_ACTIVE_USER_GROUP_ID',
  CHANGE_IS_SHOW_EDIT_USER_GROUP_DRAWER: 'harami/userGroups/CHANGE_IS_SHOW_EDIT_USER_GROUP_DRAWER',
  //
  REQUEST_GET_USER: 'harami/users/REQUEST_GET_USER',
  SUCCESS_GET_USER: 'harami/users/SUCCESS_GET_USER',
  ERROR_GET_USER: 'harami/users/ERROR_GET_USER',
} as const

export interface GetUsersFilter {
  userName?: { $like: string }
  placeNodeId?: { $in: string[] }
}
