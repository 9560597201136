import { PlaceNode, PlaceNodeTypeEnum } from '@ulysses-inc/harami_api_client'
import { FlatNode } from 'src/features/places/PlaceGroupsProps'

const parentPlaceNodes = (
  groupNodeId: number,
  placeGroupNodes: FlatNode[],
): PlaceNode[] => {
  let hasPlaceNodes: PlaceNode[] = []
  const placeGroupNode = placeGroupNodes.find(node => node.id === groupNodeId)
  if (placeGroupNode) {
    placeGroupNode.path.forEach(
      (nodeId: number) =>
        (hasPlaceNodes = [
          ...hasPlaceNodes,
          ...parentPlaceNodes(nodeId, placeGroupNodes),
        ]),
    )
    hasPlaceNodes = [
      ...hasPlaceNodes,
      ...(placeGroupNode.nodes.filter(
        node => (node as PlaceNode).type === PlaceNodeTypeEnum.Place,
      ) as PlaceNode[]),
    ]
    const placeGroups = placeGroupNode.nodes.filter(
      node => (node as PlaceNode).type === PlaceNodeTypeEnum.PlaceGroup,
    ) as PlaceNode[]

    placeGroups.forEach(node => {
      const childNodes = childPlaceNodes(node.id, placeGroupNodes)
      hasPlaceNodes = [
        ...hasPlaceNodes,
        ...(childNodes.filter(
          node => (node as PlaceNode).type === PlaceNodeTypeEnum.Place,
        ) as PlaceNode[]),
      ]
    })
  }
  return hasPlaceNodes
}

const childPlaceNodes = (
  groupNodeId: number,
  placeGroupNodes: FlatNode[],
): PlaceNode[] => {
  let hasPlaceNodes: PlaceNode[] = []
  const placeGroupNode = placeGroupNodes.find(node => node.id === groupNodeId)
  if (placeGroupNode) {
    placeGroupNode.nodes.forEach(
      node =>
        (hasPlaceNodes = [
          ...hasPlaceNodes,
          ...childPlaceNodes((node as PlaceNode).id, placeGroupNodes),
        ]),
    )
    hasPlaceNodes = [
      ...hasPlaceNodes,
      ...(placeGroupNode.nodes.filter(
        node => (node as PlaceNode).type === PlaceNodeTypeEnum.Place,
      ) as PlaceNode[]),
    ]
  }
  return hasPlaceNodes
}

const removeDuplicatePlaceNodes = (placeNodes: PlaceNode[]) => {
  return placeNodes.reduce(
    (accumulators: PlaceNode[], placeNode: PlaceNode) => {
      if (
        accumulators.some(accumulator => accumulator.uuid === placeNode.uuid)
      ) {
        return accumulators
      } else {
        return [...accumulators, placeNode]
      }
    },
    [],
  )
}

const useDisplayedPlaceNodes = (
  nodeId: number,
  activeNodes: PlaceNode[],
  places: PlaceNode[],
  placeGroupNodes: FlatNode[],
): PlaceNode[] => {
  const removePlaceNodes = removeDuplicatePlaceNodes([
    ...parentPlaceNodes(nodeId, placeGroupNodes),
    ...childPlaceNodes(nodeId, placeGroupNodes),
  ])
  const activePlaceNodes = activeNodes.filter(
    node => node.type === PlaceNodeTypeEnum.Place,
  )
  const displayedPlaceNodes = places.filter(
    placeNode =>
      !removePlaceNodes.some(
        removePlaceNode => removePlaceNode.id === placeNode.id,
      ),
  )

  return removeDuplicatePlaceNodes(
    [...displayedPlaceNodes, ...activePlaceNodes].sort((a, b) =>
      a.id < b.id ? -1 : 1,
    ),
  )
}

export default useDisplayedPlaceNodes
