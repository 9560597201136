import { Button, List, Pagination, Row, Tag } from 'antd'
import {
  Black,
  GridTemplateLabelColor,
  IndicatedIssueLabelColor,
  TableBorderColor,
  White,
} from 'src/features/theme/KdsThemeColor'
import styled from 'styled-components'
import TemplatesSortDropDown from './TemplatesSortDropDown'

export const CopyButton = styled(Button)`
  margin-right: 8px;
`

export const ButtonGroupRow = styled.div`
  position: absolute;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: row;
  height: 100%;
  right: 0;
  background-color: ${White};
`

export const TableHeaderWrap = styled.div`
  width: 100%;
  min-width: 800px;
  padding-right: 15px;
`

export const TableHeader = styled.div`
  width: 100%;
  height: 46px;
  display: flex;
  align-items: center;
  flex-direction: row;
  background-color: ${White};
  border-bottom: 1px solid ${TableBorderColor};
`

export const TableHeaderCol = styled.div`
  display: flex;
  width: 40%;
`

export const TemplatePlaceCol = styled.div`
  width: 30%;
  font-weight: bold;
`

export const TableCreatedAtCol = styled.div`
  font-weight: bold;
  display: flex;
  width: 15%;
`

export const TableUpdateAtCol = styled.div`
  font-weight: bold;
  display: flex;
  width: 15%;
`

export const TableHeaderLabel = styled.div`
  margin-left: 16px;
  font-weight: bold;
`

export const TableBody = styled<any>(List)`
  width: 100%;
`

export const TableRowWrap = styled(List.Item)`
  position: relative;
  min-height: 46px;
  background-color: ${White};
`

export const CheckRow = styled.div`
  margin-left: 16px;
`

export const TemplateNameRow = styled.div`
  display: flex;
  width: 40%;
  padding-right: 16px;
`

export const TemplateNameLabel = styled.div`
  word-break: break-all;
  padding-left: 16px;
  display: flex;
  align-items: center;
`

export const TemplatePlaceNameRow = styled.div`
  word-break: break-all;
  padding-right: 16px;
  width: 30%;
`

export const TemplateCreatedAtRow = styled.div`
  width: 15%;
`

export const TemplateModifiedAtRow = styled.div`
  width: 15%;
`

export const DropDownArea = styled.div`
  border-collapse: separate;
  border-spacing: 8px 0;
  margin-left: -8px;
`

export const ActionRow = styled(Row)`
  margin: 15px 30px 15px 15px;
`

export const ContentRow = styled(Row)`
  margin: 15px;
  overflow-x: auto;
  justify-content: start;
`

export const TemplatesPagination = styled(Pagination)`
  margin-top: 16px;
  margin-bottom: 16px;
  display: flex;
  justify-content: center;
`

export const ImproveOptionTag = styled(Tag)`
  color: ${Black};
  background-color: ${IndicatedIssueLabelColor};
  font-size: 12px;
  height: 18px;
  width: 56px;
  display: inline-flex;
  align-items: center;
  margin-left: 8px;
  padding: 3px 4px;
  border: none;
`

export const GridTemplateTag = styled(Tag)`
  color: ${Black};
  background-color: ${GridTemplateLabelColor};
  font-size: 12px;
  height: 18px;
  width: 44px;
  display: inline-flex;
  align-items: center;
  margin-left: 8px;
  padding: 3px 4px;
  border: none;
`

export const StyledTemplatesSortDropDown = styled(TemplatesSortDropDown)`
  margin-left: 16px;
`
