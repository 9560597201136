import {
  ReportNodeSchema,
  ReportPageSchema,
} from '@ulysses-inc/harami_api_client'
import { schema } from 'normalizr'

const node = new schema.Entity('nodes')
export const SchemaReportNodes = new schema.Array(node)
node.define({ nodes: SchemaReportNodes })
const schemaTemplatePage = new schema.Entity('pages', {
  nodes: [new schema.Entity('nodes', { nodes: SchemaReportNodes })],
})
const schemaReportPage = new schema.Entity('pages', {
  nodes: [new schema.Entity('nodes', { nodes: SchemaReportNodes })],
})
export const SchemaTemplatePages = new schema.Array(schemaTemplatePage)
export const SchemaReportPages = new schema.Array(schemaReportPage)

export type ReportPagesEntities = {
  pages: { [key: number]: ReportPageSchema }
  nodes: { [key: number]: ReportNodeSchema }
}
