import { combineReducers } from 'redux'
import reportResult from './reportResult/reducers'
import reports from './reports/reducers'
import reportsTemplates from './reportsTemplates/reducers'

// TODO:
// レポート一覧画面とレポート結果画面は別のものなので、
// `reports`というstore配下にネストさせる必要はなく、しないほうが水平スケールしやすいことから、
// いつか以下のフォルダ構成に変更したい。
// - ducks/reports (既存のducks/reports/reportsを1階層昇格)
// - ducks/reportResult (既存のducks/reports/reportResultを1階層昇格)
const reportsReducer = combineReducers({
  reports,
  reportsTemplates,
  reportResult,
})

export default reportsReducer
