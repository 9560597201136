// prettier-ignore
export const ActionTypes = {
  // indicatedIssueLabel
  REQUEST_ADD_INDICATED_ISSUE_LABEL: 'harami/indicatedIssues/REQUEST_ADD_INDICATED_ISSUE_LABEL',
  SUCCESS_ADD_INDICATED_ISSUE_LABEL: 'harami/indicatedIssues/SUCCESS_ADD_INDICATED_ISSUE_LABEL',
  ERROR_ADD_INDICATED_ISSUE_LABEL: 'harami/indicatedIssues/ERROR_ADD_INDICATED_ISSUE_LABEL',
  REQUEST_UPDATE_INDICATED_ISSUE_LABEL: 'harami/indicatedIssues/REQUEST_UPDATE_INDICATED_ISSUE_LABEL',
  SUCCESS_UPDATE_INDICATED_ISSUE_LABEL: 'harami/indicatedIssues/SUCCESS_UPDATE_INDICATED_ISSUE_LABEL',
  ERROR_UPDATE_INDICATED_ISSUE_LABEL: 'harami/indicatedIssues/ERROR_UPDATE_INDICATED_ISSUE_LABEL',
  REQUEST_GET_INDICATED_ISSUE_LABELS: 'harami/indicatedIssues/REQUEST_GET_INDICATED_ISSUE_LABELS',
  SUCCESS_GET_INDICATED_ISSUE_LABELS: 'harami/indicatedIssues/SUCCESS_GET_INDICATED_ISSUE_LABELS',
  ERROR_GET_INDICATED_ISSUE_LABELS: 'harami/indicatedIssues/ERROR_GET_INDICATED_ISSUE_LABELS',
  CHANGE_IS_SHOW_DRAWER: 'harami/indicatedIssues/CHANGE_IS_SHOW_DRAWER',
  RESET_EDIT_INDICATED_ISSUE_LABEL: 'harami/indicatedIssues/RESET_EDIT_INDICATED_ISSUE_LABEL',
  CHANGE_EDIT_INDICATED_ISSUE_LABEL: 'harami/indicatedIssues/CHANGE_EDIT_INDICATED_ISSUE_LABEL',
  REQUEST_DELETE_INDICATED_ISSUE_LABEL: 'harami/indicatedIssues/REQUEST_DELETE_INDICATED_ISSUE_LABEL',
  SUCCESS_DELETE_INDICATED_ISSUE_LABEL: 'harami/indicatedIssues/SUCCESS_DELETE_INDICATED_ISSUE_LABEL',
  ERROR_DELETE_INDICATED_ISSUE_LABEL: 'harami/indicatedIssues/ERROR_DELETE_INDICATED_ISSUE_LABEL',

  // indicatedIssueRevise
  REQUEST_UPDATE_INDICATED_ISSUE_REVISES: 'harami/indicatedIssues/REQUEST_UPDATE_INDICATED_ISSUE_REVISES',
  SUCCESS_UPDATE_INDICATED_ISSUE_REVISES: 'harami/indicatedIssues/SUCCESS_UPDATE_INDICATED_ISSUE_REVISES',
  ERROR_UPDATE_INDICATED_ISSUE_REVISES: 'harami/indicatedIssues/ERROR_UPDATE_INDICATED_ISSUE_REVISES',
  REQUEST_SAVE_DRAFT_INDICATED_ISSUE_REVISES: 'harami/indicatedIssues/REQUEST_SAVE_DRAFT_INDICATED_ISSUE_REVISES',
  SUCCESS_SAVE_DRAFT_INDICATED_ISSUE_REVISES: 'harami/indicatedIssues/SUCCESS_SAVE_DRAFT_INDICATED_ISSUE_REVISES',
  ERROR_SAVE_DRAFT_INDICATED_ISSUE_REVISES: 'harami/indicatedIssues/ERROR_SAVE_DRAFT_INDICATED_ISSUE_REVISES',
} as const
