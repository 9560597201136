import { StyleSheet, View } from '@react-pdf/renderer'
import { TextValue } from '@ulysses-inc/harami_api_client'
import React from 'react'
import BreakText from '../../BreakText'
import Grid from '../../Grid'
import RecordedTime from './RecordedTime'

interface OwnProps {
  questionName: string
  textValue?: TextValue
  recordedAt?: Date
  isTimeDisplayed: boolean
}

const styles = StyleSheet.create({
  gridText: { width: '100%' },
  recordedTimeWrap: { width: '100%', marginBottom: 10 },
})

const Text: React.FC<OwnProps> = (props: OwnProps) => {
  const isAnswered =
    props.textValue?.textValue !== undefined &&
    props.textValue?.textValue !== ''
  return (
    <Grid borderLeft borderRight style={styles.gridText}>
      <View>
        <BreakText text={props.questionName} />
        <BreakText
          text={isAnswered ? props.textValue?.textValue ?? '' : '未回答'}
        />
        {isAnswered && (
          <View style={styles.recordedTimeWrap}>
            <RecordedTime
              isTimeDisplayed={props.isTimeDisplayed}
              recordedAt={props.recordedAt}
            />
          </View>
        )}
      </View>
    </Grid>
  )
}

export default Text
