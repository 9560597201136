import { Text } from '@react-pdf/renderer'
import {
  DatetimeValue,
  ResponseDatetimeSubTypeEnum,
} from '@ulysses-inc/harami_api_client'
import { FC } from 'react'
import { formatDatetimeValue } from 'src/features/reports/result/components/reportResult/datetimeFormatter.util'
import { gridLayoutTableBodyCell } from '../../../shared/styles'

type Props = {
  value?: DatetimeValue
  subType: ResponseDatetimeSubTypeEnum
}

export const TableBodyCellDatetime: FC<Props> = ({ value, subType }) => {
  const datetimeValue = value?.datetimeValue

  return (
    <Text
      style={[
        gridLayoutTableBodyCell.baseTableBodyCell,
        gridLayoutTableBodyCell.tableBodyCell,
      ]}
    >
      {datetimeValue && formatDatetimeValue(subType, datetimeValue)}
    </Text>
  )
}
