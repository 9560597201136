import { TemplateNodeSchema, UserGroup } from '@ulysses-inc/harami_api_client'
import { EditDeviateNotifyAction } from 'src/state/ducks/templates/editDeviateNotify/actions'
import { ActionTypes } from 'src/state/ducks/templates/editDeviateNotify/types'

interface EditDeviateNotifyState {
  questionNode?: TemplateNodeSchema
  userGroups: Array<UserGroup>
  isOpenModal: boolean
}

const initialEditDeviateNotifyState: EditDeviateNotifyState = {
  isOpenModal: false,
  userGroups: [],
}

export const editDeviateNotifyReducer = (
  state: EditDeviateNotifyState = initialEditDeviateNotifyState,
  action: EditDeviateNotifyAction,
) => {
  switch (action.type) {
    case ActionTypes.SHOW_EDIT_DEVIATE_NOTIFY: {
      return { ...state, isOpenModal: true, questionNode: action.node }
    }
    case ActionTypes.CLOSE_EDIT_DEVIATE_NOTIFY: {
      return { ...state, isOpenModal: false }
    }
    default: {
      return { ...state }
    }
  }
}
