import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons'
import {
  ApprovalFlow,
  ApprovalFlowStep,
  User,
} from '@ulysses-inc/harami_api_client'
import { Badge, Button, Select, Typography } from 'antd'
import { FormikErrors, FormikTouched } from 'formik'
import React, { useCallback } from 'react'
import { BorderColor, Gray, White } from 'src/features/theme/KdsThemeColor'
import styled from 'styled-components'

const { Text } = Typography
const { Option } = Select

const ApprovalFlowStepsContainer = styled.div`
  border-radius: 8px;
  background: ${White};
  width: 100%;
  padding: 15px;
`

const ApprovalFlowStepsWrap = styled.div`
  border-radius: 8px;
  padding: 15px;
  background: ${White};
`

const ApprovalFlowStepContainer = styled.div`
  padding: 10px;
  border-radius: 8px;
  margin-bottom: 15px;
  border: 2px solid ${BorderColor};
`

const ApproverSelect = styled(Select)`
  width: 100%;
`

const ApproverSelectWrap = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`

const ApproverOrTextRow = styled.div`
  text-align: center;
  margin-top: 5px;
  margin-bottom: 5px;
`

const AddOrApproverButtonRow = styled.div`
  display: flex;
  justify-content: center;
`

const AddApproverButton = styled(Button)`
  width: 100%;
`

const ApprovalFlowStepOrder = styled(Badge)`
  .ant-scroll-number.ant-badge-count {
    margin-right: 5px;
    background-color: ${Gray};
  }
`

interface OwnProps {
  steps?: ApprovalFlowStep[]
  users: User[]
  touched: FormikTouched<ApprovalFlow>
  errors: FormikErrors<ApprovalFlow>
  setFieldValue: (
    field: string,
    value: ApprovalFlowStep[] | undefined,
    shouldValidate?: boolean,
  ) => void
}

const EditApprovalFlowSteps: React.FC<OwnProps> = ({
  users,
  steps,
  setFieldValue,
}) => {
  const onChangeApprover = useCallback(
    (stepIndex: number, approverIndex: number) => {
      return (value: unknown) => {
        const newValue = steps?.map((step, i) => {
          // 対象のステップ以外はそのまま返す
          if (i !== stepIndex) {
            return step
          }

          step.approvers =
            step.approvers?.map((approver, j) => {
              // 対象の承認者以外はそのまま返す
              if (j !== approverIndex) {
                return approver
              }

              // 対象ステップ内の対象の承認者を更新
              return {
                ...approver,
                id: value as number,
                name: users.find(user => user.id === value)?.name || '',
              }
            }) ?? []
          return step
        })

        setFieldValue(`steps`, newValue)
      }
    },
    [setFieldValue, steps, users],
  )

  return (
    <ApprovalFlowStepsContainer>
      <ApprovalFlowStepsWrap>
        {steps?.map((step: ApprovalFlowStep, stepIndex: number) => (
          <ApprovalFlowStepContainer key={stepIndex}>
            <div
              style={{
                marginBottom: '15px',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <ApprovalFlowStepOrder count={stepIndex + 1} />
              {stepIndex !== 0 && (
                <Button
                  style={{ marginLeft: '5px' }}
                  type="link"
                  icon={<MinusCircleOutlined />}
                  onClick={() => {
                    setFieldValue(
                      'steps',
                      (steps ?? []).filter((_, i: number) => i !== stepIndex),
                    )
                  }}
                />
              )}
            </div>
            {step?.approvers?.map((approver, approverIndex) => (
              <React.Fragment key={approverIndex}>
                <ApproverSelectWrap key={approverIndex}>
                  <ApproverSelect
                    showSearch
                    key={approverIndex}
                    optionFilterProp="children"
                    onChange={onChangeApprover(stepIndex, approverIndex)}
                    /* NOTE: 
                      antd の Select は、value に id をセットすると、その id に対応する Option の label が表示される（users 内の id が一致するユーザー名）
                      しかし、users に含まれない論理削除済みのユーザーを表示するために、approver.name を value にセットして表示するようにしている
                    */
                    value={approver.name}
                    placeholder="承認者"
                  >
                    {users.map((user: User) => (
                      <Option key={user.id} value={user.id}>
                        {user.name}
                      </Option>
                    ))}
                  </ApproverSelect>
                </ApproverSelectWrap>
                <ApproverOrTextRow>
                  <Text strong>or</Text>
                </ApproverOrTextRow>
              </React.Fragment>
            ))}
            <AddOrApproverButtonRow>
              <Button
                onClick={() => {
                  setFieldValue(
                    'steps',
                    steps
                      ?.filter(step => step.approvers)
                      ?.map((step, i) => {
                        if (i === stepIndex) step.approvers?.push({})
                        return step
                      }),
                  )
                }}
                type="link"
                icon={<PlusCircleOutlined />}
              />
              {(step.approvers ?? []).length > 1 && (
                <Button
                  onClick={() => {
                    setFieldValue(
                      'steps',
                      steps
                        ?.filter(step => step.approvers)
                        ?.map((step, i) => {
                          if (i === stepIndex) step.approvers?.pop()
                          return step
                        }),
                    )
                  }}
                  type="link"
                  icon={<MinusCircleOutlined />}
                />
              )}
            </AddOrApproverButtonRow>
          </ApprovalFlowStepContainer>
        ))}
        <div>
          <AddApproverButton
            type="dashed"
            icon={<PlusCircleOutlined />}
            onClick={() => {
              setFieldValue('steps', [...(steps ?? []), { approvers: [{}] }])
            }}
          >
            承認ステップを追加
          </AddApproverButton>
        </div>
      </ApprovalFlowStepsWrap>
    </ApprovalFlowStepsContainer>
  )
}

export default EditApprovalFlowSteps
