import {
  ExcelConversionLog,
  ExcelConversionLogStatusEnum,
  PlaceNode,
} from '@ulysses-inc/harami_api_client'
import { Checkbox, List, Row } from 'antd'
import { ListProps } from 'antd/lib/list'
import React, { JSX, useEffect, useState } from 'react'
import { RowActionButton } from 'src/components/rowActionButton/RowActionButton'
import date from 'src/exShared/util/date'
import { TableBorderColor, White } from 'src/features/theme/KdsThemeColor'
import styled from 'styled-components'
import { getStatusBadge } from './ExcelConversionLogStatus'

const ButtonRow = styled.div`
  position: absolute;
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  height: 100%;
  right: 0;
`

const TableBody = styled<(props: ListProps<ExcelConversionLog>) => JSX.Element>(
  List,
)`
  width: 100%;
`

const TableRowWrap = styled(List.Item)`
  position: relative;
  height: auto;
  background-color: ${White};
`

const TableRow = styled.div`
  width: ${props => props.theme.width};
  padding-left: 16px;
`

const TableRowWithCheckbox = styled.div`
  width: ${props => props.theme.width};
  display: flex;
`

const TableHeaderCol = styled.div`
  display: flex;
`

const LogsHeaderContainer = styled(Row)`
  margin: 15px;
  overflow-x: auto;
  justify-content: start;
`

const LogsHeaderWrap = styled.div`
  width: 100%;
  min-width: 800px;
`

const LogsHeader = styled.div`
  width: 100%;
  height: 46px;
  display: flex;
  align-items: center;
  flex-direction: row;
  background: ${White};
  border-bottom: 1px solid ${TableBorderColor};
`

const HeaderRow = styled.div`
  font-weight: bold;
  padding-left: 15px;
  width: ${props => props.theme.width};
`

const CheckHeaderRow = styled.div`
  margin-right: 16px;
`

const CheckRow = styled.div`
  margin: 0 8px 0 16px;
`

const PlaceWrap = styled.div`
  display: flex;
  flex-direction: column;
`

interface OwnProps {
  logs: ExcelConversionLog[]
  goFlows: () => void
  getExcelConversionLogFiles: (fileUUIDs: string[]) => void
  chooseLogUUIDs: { [key: string]: string }
  setChooseLogUUIDs: React.Dispatch<
    React.SetStateAction<{ [key: string]: string }>
  >
}

const ExcelConversionLogList: React.FC<OwnProps> = (props: OwnProps) => {
  const [activeLogId, setActiveLogId] = useState<number>()

  useEffect(() => {
    Object.keys(props.chooseLogUUIDs).forEach(logUUID => {
      const isContain =
        props.logs.findIndex(log => log.logFile?.file?.uuid === logUUID) >= 0
      if (!isContain) {
        handleCheckLog(logUUID, false)
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.logs])

  const isCheckedAll = () => {
    if (props.logs.length === 0) return false
    const successLogs = props.logs.filter(
      (log: ExcelConversionLog) =>
        log.status === ExcelConversionLogStatusEnum.SUCCESS,
    )
    if (successLogs.length === 0) return false
    const unChecked = successLogs.filter(
      (log: ExcelConversionLog) =>
        log.id !== undefined &&
        !Object.hasOwnProperty.call(
          props.chooseLogUUIDs,
          log.logFile?.file?.uuid || '',
        ),
    )
    return unChecked.length === 0
  }

  const handleCheckLog = (fileUUID: string | undefined, checked: boolean) => {
    if (fileUUID === undefined) {
      return
    }
    if (checked) {
      props.chooseLogUUIDs[fileUUID] = fileUUID
      props.setChooseLogUUIDs({ ...props.chooseLogUUIDs })
    } else {
      delete props.chooseLogUUIDs[fileUUID]
      props.setChooseLogUUIDs({ ...props.chooseLogUUIDs })
    }
  }

  const handleCheckAll = (checked: boolean) => {
    if (checked) {
      props.logs
        .filter(log => log.status === ExcelConversionLogStatusEnum.SUCCESS)
        .forEach(log => {
          if (!log.logFile?.file?.uuid) {
            return
          }
          props.chooseLogUUIDs[log.logFile.file.uuid] = log.logFile.file.uuid
        })
      props.setChooseLogUUIDs({ ...props.chooseLogUUIDs })
    } else {
      props.logs.forEach(log => {
        if (!log.logFile?.file?.uuid) {
          return
        }
        delete props.chooseLogUUIDs[log.logFile.file.uuid]
      })
      props.setChooseLogUUIDs({ ...props.chooseLogUUIDs })
    }
  }

  return (
    <LogsHeaderContainer justify="center">
      <LogsHeaderWrap>
        <LogsHeader>
          <HeaderRow theme={{ width: '15%' }}>
            <TableHeaderCol>
              <CheckHeaderRow>
                <Checkbox
                  checked={isCheckedAll()}
                  onChange={e => handleCheckAll(e.target.checked)}
                />
              </CheckHeaderRow>
              <span>日付</span>
            </TableHeaderCol>
          </HeaderRow>
          <HeaderRow theme={{ width: '10%' }}>実行結果</HeaderRow>
          <HeaderRow theme={{ width: '25%' }}>変換フロー名</HeaderRow>
          <HeaderRow theme={{ width: '30%' }}>作成ファイル名</HeaderRow>
          <HeaderRow theme={{ width: '20%' }}>現場名</HeaderRow>
        </LogsHeader>
        <TableBody
          itemLayout="horizontal"
          dataSource={props.logs}
          renderItem={(log: ExcelConversionLog) => (
            <TableRowWrap
              onMouseEnter={() => setActiveLogId(log.id)}
              onMouseLeave={() => setActiveLogId(undefined)}
            >
              <TableRowWithCheckbox theme={{ width: '15%' }}>
                <CheckRow>
                  <Checkbox
                    disabled={
                      log.status === ExcelConversionLogStatusEnum.FAILED &&
                      !(
                        log.logFile &&
                        log.logFile.file &&
                        log.logFile.file.uuid
                      )
                    }
                    checked={
                      log.id
                        ? Object.hasOwnProperty.call(
                            props.chooseLogUUIDs,
                            log.logFile?.file?.uuid || '',
                          )
                        : false
                    }
                    onChange={e =>
                      log.id &&
                      handleCheckLog(log.logFile?.file?.uuid, e.target.checked)
                    }
                  />
                </CheckRow>
                {date.formatYYYYMMDD(log.createdAt)}
              </TableRowWithCheckbox>
              <TableRow theme={{ width: '10%' }}>
                {getStatusBadge(log.status)}
              </TableRow>
              <TableRow theme={{ width: '25%' }}>{log.flowName || ''}</TableRow>
              <TableRow theme={{ width: '30%' }}>
                {log.logFile?.file?.name || ''}
              </TableRow>
              {activeLogId === log.id &&
                log.id !== undefined &&
                log.status === ExcelConversionLogStatusEnum.SUCCESS && (
                  <ButtonRow>
                    <RowActionButton
                      onClick={() =>
                        log.logFile?.file?.uuid !== undefined &&
                        props.getExcelConversionLogFiles([
                          log.logFile.file.uuid,
                        ])
                      }
                      type="download"
                    />
                  </ButtonRow>
                )}
              <TableRow theme={{ width: '20%' }}>
                <PlaceWrap>
                  {log.placeNodes &&
                    log.placeNodes.map((placeNode: PlaceNode) => (
                      <span key={placeNode.uuid}>
                        {placeNode.place?.name ?? ''}
                      </span>
                    ))}
                </PlaceWrap>
              </TableRow>
            </TableRowWrap>
          )}
        />
      </LogsHeaderWrap>
    </LogsHeaderContainer>
  )
}

export default ExcelConversionLogList
