import { PlusCircleOutlined } from '@ant-design/icons'
import { css } from '@emotion/react'
import { Button, Row } from 'antd'
import { FC } from 'react'

const AddChoiceRow: FC<{
  addNewMultipleChoice: () => void
}> = ({ addNewMultipleChoice }) => (
  <Row
    css={css`
      margin: 12px 0;
    `}
  >
    <Button
      onClick={addNewMultipleChoice}
      type="link"
      icon={<PlusCircleOutlined />}
      style={{ marginLeft: '1px' }}
    >
      回答を追加
    </Button>
  </Row>
)

export default AddChoiceRow
