import { ClientTypeEnum } from '@ulysses-inc/harami_api_client'
import { getBasePath } from 'src/config'
import { getApiKey } from '../../middleware/saga/getApiKey'
import { HTTPError } from '../../middleware/saga/handleHttpError'

const basePath = getBasePath()

export const downloadFile = async (url: string, body: string) => {
  const options: RequestInit = {
    method: 'POST',
    body: body,
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      'X-API-KEY': getApiKey(),
      ct: `${ClientTypeEnum.WEB}`,
      locationHref: `${location.href}`,
    },
  }

  let fileName = ''

  return fetch(`${basePath}/${url}`, options)
    .catch(e => {
      throw Error(e)
    })
    .then(handleCriticalErrors)
    .then(res => {
      fileName =
        getFileNameFromContentDisposition(
          res.headers.get('content-disposition'),
        ) ?? ''
      if (fileName !== '') return res.blob()
    })
    .then(blob => {
      const anchor = document.createElement('a')
      anchor.download = fileName
      anchor.href = window.URL.createObjectURL(blob)
      anchor.click()
    })
    .catch(async err => {
      const e: HTTPError = await err.json()
      throw e
    })
}

const getFileNameFromContentDisposition = (disposition: string) => {
  const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
  const matches = filenameRegex.exec(disposition)
  if (matches != null && matches[1]) {
    const fileName = matches[1].replace(/['"]/g, '')
    return decodeURI(fileName)
  } else {
    return undefined
  }
}

const handleCriticalErrors = (res: any) => {
  if (res.ok) return res
  throw res
}
