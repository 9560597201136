import styled from 'styled-components'

export const ImageListWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-top: 8px;
  margin-bottom: 8px;
`

export const ImageRowWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-left: 8px;
`

export const ImageWrapper = styled.div`
  height: 34px;
  width: 50px;
  overflow: hidden;
  position: relative;
`

export const ThumbCover = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
  background-color: #000;
`
