import { isValid, parseISO } from 'date-fns'

/**
 * 日付文字列が有効かどうか（不正ではないか）を判定する
 */
export const isValidDateString = (
  dateString: unknown,
): dateString is string => {
  if (typeof dateString !== 'string') return false
  if (!/^\d{4}-\d{2}-\d{2}$/.test(dateString)) {
    return false
  }
  return isValid(parseISO(dateString))
}
