import { DeleteOutlined, PlusOutlined, QrcodeOutlined } from '@ant-design/icons'
import { GetSchedulesRequest, Schedule } from '@ulysses-inc/harami_api_client'
import { Button, Modal } from 'antd'
import { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import Loading from 'src/components/loading/Loading'
import { ModalDeleteButtonColor } from 'src/features/theme/KdsThemeColor'
import schedulesOperations from 'src/state/ducks/schedules/operations'
import { GetSchedulesFilter } from 'src/state/ducks/schedules/types'
import { RootState } from 'src/state/store'
import {
  ActionButtons,
  ActionRow,
  ConfirmModalContentList,
} from './ScheduleList.styled'
import ScheduleListContent from './ScheduleListContent'
import SchedulesFilterDropDown from './SchedulesFilterDropDown'

const ScheduleList: FC = () => {
  const schedules = useSelector(
    (state: RootState) => state.schedulesState.schedules.schedules,
  )
  const isLoading = useSelector(
    (state: RootState) => state.schedulesState.schedules.isLoading,
  )
  const request = useSelector(
    (state: RootState) => state.schedulesState.schedules.request,
  )
  const filter = useSelector(
    (state: RootState) => state.schedulesState.schedules.filter,
  )
  const history = useHistory()
  const dispatch = useDispatch()
  const deleteSchedules = (
    scheduleUUIDs: string[],
    request: GetSchedulesRequest,
    filter?: GetSchedulesFilter,
  ) => {
    schedulesOperations.deleteSchedules(
      dispatch,
      scheduleUUIDs,
      request,
      filter,
    )
  }
  const goAddSchedule = () => {
    history.push('/schedules/add')
  }
  const downloadScheduleQrCodes = (scheduleUUIDs: string[]) =>
    schedulesOperations.downloadScheduleQrCodes(dispatch, scheduleUUIDs)

  const chooseScheduleUUIDsState = useState<string[]>([])
  const [chooseScheduleUUIDs, setChooseScheduleUUIDs] = chooseScheduleUUIDsState

  useEffect(() => {
    setChooseScheduleUUIDs([])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schedules])

  const getSchedulesHandler = (f?: GetSchedulesFilter) =>
    schedulesOperations.getSchedules(dispatch, request, f)
  const onDownloadListButtonClick = () => {
    downloadScheduleQrCodes(chooseScheduleUUIDs)
  }
  const onBulkDeleteButtonClick = () => {
    const flowUUIDs: { [uuid: string]: string } = {}
    schedules
      ?.filter(
        (schedule: Schedule) =>
          schedule.uuid &&
          chooseScheduleUUIDs.includes(schedule.uuid) &&
          schedule.excelConversionFlows?.length,
      )
      .forEach(schedule => {
        schedule.excelConversionFlows?.forEach(flow => {
          if (!flow.flowUUID) return
          flowUUIDs[flow.flowUUID] = flow.flowName || ''
        })
      })

    const flowNames = Object.values(flowUUIDs)

    Modal.confirm({
      ...{
        onOk: () => deleteSchedules(chooseScheduleUUIDs, request, filter),
        okText: '削除',
        cancelText: 'キャンセル',
        okButtonProps: {
          style: {
            backgroundColor: ModalDeleteButtonColor,
            border: 'none',
          },
        },
      },
      ...(flowNames.length > 0
        ? {
            title:
              '以下のExcel変換設定に紐づいています。一括削除しますがよろしいですか？',
            content: (
              <ConfirmModalContentList>
                {flowNames.map((name, index) => (
                  <li key={index}>{name}</li>
                ))}
              </ConfirmModalContentList>
            ),
          }
        : {
            title: '選択中のスケジュールを一括削除しますがよろしいですか？',
          }),
    })
  }

  return (
    <>
      <ActionRow justify="space-between">
        <SchedulesFilterDropDown getSchedules={getSchedulesHandler} />
        <ActionButtons>
          <Button onClick={() => goAddSchedule()} type="primary">
            <PlusOutlined />
            スケジュールを追加
          </Button>
          <Button
            onClick={onDownloadListButtonClick}
            disabled={chooseScheduleUUIDs.length === 0}
            type="primary"
          >
            <QrcodeOutlined />
            QRコード一括ダウンロード
          </Button>
          <Button
            danger
            onClick={onBulkDeleteButtonClick}
            disabled={chooseScheduleUUIDs.length === 0}
            type="primary"
          >
            <DeleteOutlined />
            一括削除
          </Button>
        </ActionButtons>
      </ActionRow>
      {isLoading ? (
        <Loading />
      ) : (
        <ScheduleListContent
          chooseScheduleUUIDsState={chooseScheduleUUIDsState}
        />
      )}
    </>
  )
}

export default ScheduleList
