import { Black } from 'src/features/theme/KdsThemeColor'
import styled from 'styled-components'

export const URLText = styled.div`
  font-size: 15px;
  line-height: 15px;
  font-weight: 500;
  color: ${Black};
  margin-top: 8px;
  margin-bottom: 8px;
  overflow-wrap: break-word;
`
