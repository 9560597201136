import {
  ExcelConversionLog,
  GetExcelConversionLogsRequest,
} from '@ulysses-inc/harami_api_client'
import { combineReducers } from 'redux'
import { ExcelConversionLogAction } from './actions'
import { ActionTypes, GetExcelConversionLogsFilter } from './types'

interface ExcelConversionLogsState {
  excelConversionLogs: ExcelConversionLog[]
  isLoading: boolean
  error: Error | null
  count: number
  filter?: GetExcelConversionLogsFilter
  request: GetExcelConversionLogsRequest
}

const initialExcelConversionLogsState: ExcelConversionLogsState = {
  excelConversionLogs: [],
  isLoading: false,
  error: null,
  count: 0,
  filter: undefined,
  request: {
    offset: 0,
    limit: 25,
  },
}

const excelConversionLogs = (
  state: ExcelConversionLogsState = initialExcelConversionLogsState,
  action: ExcelConversionLogAction,
): ExcelConversionLogsState => {
  switch (action.type) {
    case ActionTypes.REQUEST_GET_EXCEL_CONVERSION_LOGS: {
      return {
        ...state,
        isLoading: action.isLoading,
        excelConversionLogs: [],
        error: null,
        filter: action.filter,
        request: action.request,
      }
    }
    case ActionTypes.SUCCESS_GET_EXCEL_CONVERSION_LOGS: {
      return {
        ...state,
        isLoading: action.isLoading,
        excelConversionLogs: action.excelConversionLogs || [],
        error: null,
        count: action.count || 0,
      }
    }
    case ActionTypes.ERROR_GET_EXCEL_CONVERSION_LOGS: {
      return {
        ...state,
        isLoading: action.isLoading,
        error: action.error,
        count: 0,
      }
    }
    case ActionTypes.REQUEST_CHANGE_EXCEL_CONVERSION_LOGS_PAGE: {
      return {
        ...state,
        request: action.request,
        filter: action.filter,
      }
    }
    case ActionTypes.UPDATE_PAGINATION: {
      return {
        ...state,
        request: action.request,
      }
    }
    case ActionTypes.REQUEST_CHANGE_EXCEL_CONVERSION_LOGS_SIZE: {
      return {
        ...state,
        request: action.request,
        filter: action.filter,
      }
    }
    default: {
      return { ...state }
    }
  }
}

const excelConversionLogsReducer = combineReducers({ excelConversionLogs })

export default excelConversionLogsReducer
