import { CheckOutlined, CloseOutlined } from '@ant-design/icons'
import {
  ResponseDatetimeSubTypeEnum,
  ResponseTimeMeasurement,
  ResponseTimeMeasurementRule,
  ResponseTimeMeasurementRuleTypeEnum,
  ResponseTimeMeasurementRuleUnitEnum,
  TemplateNodeSchema,
} from '@ulysses-inc/harami_api_client'
import { Button, Drawer, Form, Row, Select } from 'antd'
import { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateTemplateNode } from 'src/state/ducks/templates/actions'
import { selectDateTimeQuestionMap } from 'src/state/ducks/templates/selectors'
import {
  AnnotationRow,
  AnnotationText,
  ButtonGroup,
  DecimalPointSwitch,
  ErrorText,
  HourLabel,
  InputHour,
  InputMinutes,
  MinutesLabel,
  RuleInputsRow,
  RuleTypeSelect,
} from './RuleDrawer.dumb'
import { validate } from './validateMeasurementRule'

const { Option } = Select

type OwnProps = {
  isOpen: boolean
  questionNode: TemplateNodeSchema
  rule?: ResponseTimeMeasurementRule
  onClose: () => void
}
export const RuleDrawer: FC<OwnProps> = ({
  isOpen,
  questionNode,
  rule,
  onClose,
}) => {
  const dispatch = useDispatch()
  const dateTimeQuestions = useSelector(
    selectDateTimeQuestionMap(questionNode.id),
  )

  const [hasRule, setHasRule] = useState(false)
  const [hour, setHour] = useState(0)
  const [minutes, setMinutes] = useState(0)
  const [ruleType, setRuleType] = useState<number | undefined>(undefined)

  useEffect(() => {
    setHasRule(!!rule)
    setHour(Math.floor((rule?.value ?? 0) / 60))
    setMinutes((rule?.value ?? 0) % 60)
    setRuleType(rule?.type)
  }, [rule])

  const [errorMessage, setErrorMessage] = useState('')

  const onSave = () => {
    // 計測開始時刻に設定された日時質問のフォーマットを取得
    const startQuestionNodeUUID =
      questionNode.question?.responseTimeMeasurements?.[0]
        ?.startQuestionNodeUUID ?? ''
    const startNode = dateTimeQuestions.get(startQuestionNodeUUID)
    const subType = startNode?.format

    // 分の値
    const value = hour * 60 + minutes
    const validateResult = validate(hasRule, subType, ruleType, value)
    setErrorMessage(validateResult)
    // WARN: errorMessage を検証しても、再レンダリングされるまで値が更新されないので、validateResult の方を見ること
    if (validateResult !== '') return

    // この時点でレスポンスが未設定の場合はデフォル値を設定する（必須項目なので何かしら設定しておく必要がある）
    const response = questionNode.question?.responseTimeMeasurements?.[0] ?? {
      subType: ResponseDatetimeSubTypeEnum.DATETIME,
      startQuestionNodeUUID: '',
      endQuestionNodeUUID: '',
    }

    const newResponse: ResponseTimeMeasurement = {
      ...response,
      rule:
        hasRule && !!ruleType
          ? {
              type: ruleType,
              value,
              unit: ResponseTimeMeasurementRuleUnitEnum.MINUTES,
            }
          : undefined,
    }

    dispatch(
      // 時間計測タイプの質問にルールを設定する
      updateTemplateNode(questionNode.id, {
        ...questionNode,
        question: {
          ...questionNode.question,
          responseTimeMeasurements: [newResponse],
        },
      }),
    )

    onClose()
  }

  return (
    <Drawer
      title="ルールの設定"
      maskClosable={false}
      placement="right"
      onClose={onClose}
      open={isOpen}
      width="600"
    >
      <Form>
        <Row style={{ justifyContent: 'flex-start' }}>
          <DecimalPointSwitch
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
            checked={hasRule}
            onChange={() => setHasRule(!hasRule)}
          />
          <label>正常値を設定する</label>
        </Row>
        {hasRule && (
          <>
            <RuleInputsRow>
              <InputHour
                min={0}
                max={47}
                value={hour}
                onChange={value => setHour(Number(value))}
              />
              <HourLabel>時間</HourLabel>
              <InputMinutes
                min={0}
                max={59}
                value={minutes}
                onChange={value => setMinutes(Number(value))}
              />
              <MinutesLabel>分</MinutesLabel>
              <RuleTypeSelect
                value={ruleType}
                onChange={value => setRuleType(Number(value))}
              >
                <Option
                  value={ResponseTimeMeasurementRuleTypeEnum.LESS_THAN_OR_EQUAL}
                >
                  以下
                </Option>
                <Option
                  value={
                    ResponseTimeMeasurementRuleTypeEnum.GREATER_THAN_OR_EQUAL
                  }
                >
                  以上
                </Option>
              </RuleTypeSelect>
            </RuleInputsRow>
            <AnnotationRow>
              <AnnotationText>
                設定範囲は計測する質問のフォーマットによって違います。
              </AnnotationText>
              <AnnotationText>・時刻：23時間59分まで</AnnotationText>
              <AnnotationText>・日時：47時間59分まで</AnnotationText>
            </AnnotationRow>
            {!!errorMessage && <ErrorText>{errorMessage}</ErrorText>}
          </>
        )}
        <Row style={{ justifyContent: 'flex-end' }}>
          <ButtonGroup>
            <Button type="link" onClick={onClose}>
              キャンセル
            </Button>
            <Button type="primary" onClick={onSave}>
              保存
            </Button>
          </ButtonGroup>
        </Row>
      </Form>
    </Drawer>
  )
}
