import { useRef } from 'react'

// 初回レンダー時、currentStateは初期値なので前回の状態は存在し得ないため、その時はundefinedを返す
const usePrevious = <T>(currentState: T): T | undefined => {
  const currentRef = useRef<T>(currentState)
  const previousRef = useRef<T>()

  if (currentRef.current !== currentState) {
    previousRef.current = currentRef.current
    currentRef.current = currentState
  }

  return previousRef.current
}

export default usePrevious
