import { Page, StyleSheet, Text, View } from '@react-pdf/renderer'
import { ReportPageSchema } from '@ulysses-inc/harami_api_client'
import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { ReportResultSchema } from 'src/exShared/types/types'
import { primitiveColors } from 'src/features/theme/primitiveColors'
import { selectGridLayoutVisiblePageIds } from 'src/state/ducks/reports/reports/selectors'
import { reportDetailLayout } from '../../shared/styles'
import { GirdLayoutTableBody } from './components/GirdLayoutTableBody/GirdLayoutTableBody'
import { GirdLayoutTableHeader } from './components/GirdLayoutTableHeader/GirdLayoutTableHeader'
import { GirdLayoutPage, getGridLayoutPages } from './utils/getGridLayoutPages'

export const styles = StyleSheet.create({
  pageName: {
    marginTop: 5,
    marginBottom: 5,
  },
  sectionName: {
    marginTop: 5,
    marginBottom: 5,
  },
  noDataText: {
    color: primitiveColors.gray._6,
  },
})

interface Props {
  report: ReportResultSchema
  getPages: () => Array<ReportPageSchema>
}

export const ReportDetailGridLayout: React.FC<Props> = ({
  report,
  getPages,
}) => {
  const gridLayoutVisiblePageIds = new Set(
    useSelector(selectGridLayoutVisiblePageIds, shallowEqual),
  )
  const pages = getPages().filter(
    // 表形式の場合、有効なページのみを表示対象にする
    page => page.id && gridLayoutVisiblePageIds.has(page.id),
  )

  // PDF のページ単位に表形式のデータを整形する
  const girdLayoutPageList: { [key: string]: GirdLayoutPage } =
    getGridLayoutPages(pages, report.nodes)

  return (
    <>
      {/* 表形式のひな形かつ、表示するページがない場合、エラー文言を表示する */}
      {Object.keys(girdLayoutPageList).length === 0 && (
        <Page
          // key={key}
          size="A4"
          orientation={'landscape'}
          style={reportDetailLayout.body}
        >
          <View>
            <Text style={styles.noDataText}>
              取り込み項目のデータが登録されていなかったので、記録がありません。
            </Text>
          </View>
        </Page>
      )}
      {Object.keys(girdLayoutPageList).map(key => (
        <Page
          key={key}
          size="A4"
          orientation={'landscape'}
          style={reportDetailLayout.body}
        >
          <View>
            <View fixed>
              <Text style={styles.pageName}>
                {girdLayoutPageList[key]?.pageName}
              </Text>
              <Text style={styles.sectionName}>
                {girdLayoutPageList[key]?.sectionName}
              </Text>
            </View>
            <GirdLayoutTableHeader girdLayoutPage={girdLayoutPageList[key]} />
            <GirdLayoutTableBody girdLayoutPage={girdLayoutPageList[key]} />
          </View>
        </Page>
      ))}
    </>
  )
}
