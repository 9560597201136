import { TemplateNodeSchema } from '@ulysses-inc/harami_api_client'
import { EditLogicAction } from 'src/state/ducks/templates/editLogic/actions'
import { ActionTypes } from 'src/state/ducks/templates/editLogic/types'

interface EditLogicState {
  questionNode?: TemplateNodeSchema
  isOpenMultipleChoiceModal: boolean
  isOpenNumberModal: boolean
}

const initialEditLogicState: EditLogicState = {
  isOpenMultipleChoiceModal: false,
  isOpenNumberModal: false,
}

export const editLogicReducer = (
  state: EditLogicState = initialEditLogicState,
  action: EditLogicAction,
) => {
  switch (action.type) {
    case ActionTypes.SHOW_EDIT_MULTIPLE_CHOICE_LOGIC: {
      return {
        ...state,
        isOpenMultipleChoiceModal: true,
        isOpenNumberModal: false,
        questionNode: action.node,
      }
    }
    case ActionTypes.SHOW_EDIT_NUMBER_LOGIC: {
      return {
        ...state,
        isOpenMultipleChoiceModal: false,
        isOpenNumberModal: true,
        questionNode: action.node,
      }
    }
    case ActionTypes.CLOSE_EDIT_LOGIC: {
      return {
        ...state,
        isOpenMultipleChoiceModal: false,
        isOpenNumberModal: false,
      }
    }
    default: {
      return { ...state }
    }
  }
}
