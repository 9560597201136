import { useMutation } from '@tanstack/react-query'
import {
  AddReportsExportsRequestBody,
  ExportsApi,
} from '@ulysses-inc/harami_api_client'
import { baseClient } from 'src/state/middleware/saga/baseClient'

export const useMutationAddReportExport = () => {
  return useMutation({
    mutationFn: async (requestBody: AddReportsExportsRequestBody) => {
      await baseClient.getApi(ExportsApi).addReportsExports({
        addReportsExportsRequestBody: requestBody,
      })
    },
  })
}
