import { Button, Layout, Pagination, Row, Typography } from 'antd'
import styled from 'styled-components'

const { Content } = Layout
const { Text } = Typography

export const ContentWrapper = styled(Content)`
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  width: 100%;
`

export const ListContainer = styled.div`
  width: 100%;
`

export const ListHeader = styled(Row)`
  margin: 15px 15px 15px 15px;
`

export const AddUserButton = styled(Button)`
  margin-right: 0px;
  margin-top: 0px;
`

export const UserLimitMessage = styled(Text)`
  margin-right: 15px;
`

export const UsersPagination = styled(Pagination)`
  margin-top: 16px;
  margin-bottom: 16px;
  display: flex;
  justify-content: center;
`
