import { EditorCore } from '@react-editor-js/core/src/editor-core'
import { TemplateQuestionHint } from '@ulysses-inc/harami_api_client'
import React, { useRef } from 'react'
import { createReactEditorJS } from 'react-editor-js'
import { useDispatch, useSelector } from 'react-redux'
import { editorJsCommonSettings } from 'src/features/templateEdit/common/editorJsCommonSettings'
import { updateTemplateNode } from 'src/state/ducks/templates/actions'
import { closeEditHint } from 'src/state/ducks/templates/editHint/actions'
import { RootState } from 'src/state/store'
import { EditorModal, ModalTitle, ModalTitleWrap } from './EditHintModal.styled'

const ReactEditorJS = createReactEditorJS()

const EditHintModal: React.FC = () => {
  const editorCore = useRef<EditorCore | null>(null)

  const { isOpenModal, questionNode, blocks, hintId } = useSelector(
    (state: RootState) => state.templatesState.editHint,
  )

  const dispatch = useDispatch()
  const onClose = () => dispatch(closeEditHint())

  const onSubmit = async () => {
    // イケてないOpenAPI定義への対処
    if (typeof questionNode?.id !== 'number') return

    const savedData = await editorCore.current?.save()

    // TODO: error handling
    if (!savedData) return

    const createQuestionHintId =
      ((questionNode?.question?.hints ?? []).slice(-1)[0]?.id ?? 0) + 1
    const createQuestionHint = {
      id: createQuestionHintId,
      hintId: hintId,
      blocks: savedData.blocks,
    }
    const hints =
      questionNode?.question?.hints?.filter(
        (hint: TemplateQuestionHint) => hint.hintId !== hintId,
      ) ?? []

    dispatch(
      // 各質問のヒントを更新
      updateTemplateNode(questionNode.id, {
        ...questionNode,
        question: {
          ...questionNode?.question,
          hints: [...hints, createQuestionHint],
        },
      }),
    )
    dispatch(closeEditHint())
  }

  return (
    <EditorModal
      title={
        <ModalTitleWrap>
          <ModalTitle level={4}>ヒントの編集</ModalTitle>
        </ModalTitleWrap>
      }
      open={isOpenModal}
      destroyOnClose={true}
      onOk={onSubmit}
      onCancel={onClose}
      okText="保存"
      cancelText="キャンセル"
    >
      {blocks !== null && (
        <ReactEditorJS
          onInitialize={instance => {
            editorCore.current = instance
          }}
          tools={editorJsCommonSettings.tools}
          defaultValue={{
            time: editorJsCommonSettings.outputOption.time,
            blocks: blocks?.length
              ? blocks
              : editorJsCommonSettings.initialBlocks,
            version: editorJsCommonSettings.outputOption.version,
          }}
          placeholder="こちらをクリックして入力してください"
        />
      )}
    </EditorModal>
  )
}

export default EditHintModal
