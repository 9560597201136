import { DownOutlined } from '@ant-design/icons'
import { ReportQuestion } from '@ulysses-inc/harami_api_client'
import { Select } from 'antd'
import React, { useState } from 'react'
import styled from 'styled-components'

const { Option } = Select

const QuestionWrapper = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  padding-right: 10px;
  cursor: pointer;
  &[data-disabled='true'] {
    cursor: not-allowed;
    padding-right: 0;
  }
`
const QuestionItem = styled.div`
  align-items: flex-start;
  word-wrap: break-word;
  width: 100%;
`

type OwnProps = {
  defaultQuestion?: ReportQuestion
  questions: ReportQuestion[]
  disabled: boolean
  setQuestionValue: (question: ReportQuestion) => void
}
const Component: React.FC<OwnProps> = ({
  defaultQuestion,
  questions,
  disabled,
  setQuestionValue,
}) => {
  const [isFocus, setIsFocus] = useState(false)
  return isFocus ? (
    <Select
      showSearch
      autoFocus={true}
      defaultOpen={true}
      style={{ width: '100%' }}
      optionFilterProp="children"
      disabled={disabled}
      onChange={(value: unknown) => {
        setQuestionValue(
          questions.filter(question => question.id?.toString() === value)[0],
        )
      }}
      onBlur={() => setIsFocus(false)}
      allowClear
    >
      {questions.map(question => (
        <Option
          key={question.id?.toString()}
          value={question.id?.toString() || ''}
        >
          {question.name}
        </Option>
      ))}
    </Select>
  ) : (
    <QuestionWrapper
      onClick={() => !disabled && setIsFocus(true)}
      data-disabled={disabled}
    >
      <QuestionItem>
        {defaultQuestion?.name}
        {!disabled && (
          <DownOutlined
            style={{
              position: 'absolute',
              right: '0',
              top: '2px',
              fontSize: '12px',
            }}
          />
        )}
      </QuestionItem>
    </QuestionWrapper>
  )
}

export default Component
