import { TemplateNodeSchema } from '@ulysses-inc/harami_api_client'
import { Button, Drawer, Input, Row, Typography } from 'antd'
import { useFormik } from 'formik'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Yup from 'src/features/validation/yup'
import { updateTemplateNode } from 'src/state/ducks/templates/actions'
import { closeEditDeviateComment } from 'src/state/ducks/templates/editDeviateComment/actions'
import { RootState } from 'src/state/store'
import styled from 'styled-components'

const { TextArea } = Input
const { Text } = Typography

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
`

const DeviatedRow = styled(Row)`
  margin-top: 15px;
  margin-bottom: 15px;
`

const SubTitle = styled.div`
  font-weight: bold;
`

const validationSchema = Yup.object().shape({
  deviateComment: Yup.string().required().label('違反時コメント'),
})

const EditDeviateCommentDrawer: React.FC = () => {
  const dispatch = useDispatch()

  const { questionNode, isOpenModal: isOpen } = useSelector(
    (state: RootState) => state.templatesState.editDeviateComment,
  )

  const initialValues = {
    deviateComment:
      questionNode?.question?.deviateComment === ''
        ? '違反しています。責任者に確認してください。'
        : questionNode?.question?.deviateComment,
  }

  const saveDeviateComment = (
    node: TemplateNodeSchema,
    deviateComment: string,
  ) => {
    dispatch(
      // 逸脱時コメントの更新
      updateTemplateNode(node.id, {
        ...node,
        question: { ...node.question, deviateComment: deviateComment },
      }),
    )
    dispatch(closeEditDeviateComment())
  }

  const { values, handleSubmit, handleChange, resetForm, errors, touched } =
    useFormik({
      initialValues,
      validationSchema,
      onSubmit: (values, formikHelpers) => {
        // 単に型の都合であり、実際にはundefinedになることはない想定
        if (questionNode === undefined || values.deviateComment === undefined) {
          return
        }
        saveDeviateComment(questionNode, values.deviateComment)
        formikHelpers.resetForm()
      },
      enableReinitialize: true,
    })

  const onClose = () => {
    dispatch(closeEditDeviateComment())
  }

  return (
    <Drawer
      title="逸脱時警告メッセージの編集"
      maskClosable={false}
      placement="right"
      onClose={() => {
        resetForm()
        onClose()
      }}
      open={isOpen}
      width="600"
    >
      <DeviatedRow>
        <SubTitle>
          「{questionNode?.question?.name}」の逸脱時警告メッセージ
        </SubTitle>
      </DeviatedRow>
      <DeviatedRow>
        <TextArea
          name="deviateComment"
          rows={4}
          value={values.deviateComment}
          onChange={handleChange}
        />
        {touched.deviateComment && errors.deviateComment && (
          <Text type="danger">{errors.deviateComment}</Text>
        )}
      </DeviatedRow>
      <DeviatedRow style={{ justifyContent: 'flex-end' }}>
        <ButtonGroup>
          <Button
            type="link"
            onClick={() => {
              resetForm()
              onClose()
            }}
          >
            キャンセル
          </Button>
          <Button
            type="primary"
            onClick={() => {
              handleSubmit()
            }}
          >
            保存
          </Button>
        </ButtonGroup>
      </DeviatedRow>
    </Drawer>
  )
}

export default EditDeviateCommentDrawer
