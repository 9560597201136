import * as Sentry from '@sentry/browser'
import { useCallback, useEffect, useState } from 'react'

/**
 * 10回に1回だけ与えられた関数を実行するためのカスタムフック
 * @param fn 実行したい関数
 */
const useInvokeOnceInTenTimes = (fn: () => void) => {
  const times = 10
  const [count, setCount] = useState(0)

  useEffect(() => {
    if (count === times) {
      fn()
      setCount(0)
    }
  }, [count, fn])

  return useCallback(() => {
    setCount(count => count + 1)
  }, [])
}

/**
 * Sentryにダミーのエラーを送信するためのカスタムフック
 */
const useSendDummyError = () => {
  return useCallback(() => {
    Sentry.captureException(
      new Error('これはテスト用に送信されたダミーのエラーです'),
    )
  }, [])
}

export const useSendDummyErrorOnceInTenTimes = () => {
  const sendDummyError = useSendDummyError()
  const invokeOnceInTenTimes = useInvokeOnceInTenTimes(sendDummyError)

  return invokeOnceInTenTimes
}
