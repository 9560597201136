import { TextValue } from '@ulysses-inc/harami_api_client'
import { FC } from 'react'
import { CellInner, TableBodyCellWrapper } from './Common.dumb'

type Props = {
  value?: TextValue
}

export const TableBodyCellText: FC<Props> = ({ value }) => {
  return (
    <TableBodyCellWrapper>
      <CellInner>{value?.textValue}</CellInner>
    </TableBodyCellWrapper>
  )
}
