/* eslint-disable import/no-named-as-default-member */
import {
  GetTemplatesResponse,
  GetTemplatesV2Request,
  TemplatesApi,
} from '@ulysses-inc/harami_api_client'
import { call, put } from 'redux-saga/effects'
import interceptionsActions from 'src/state/ducks/interceptions/actions'
import actions from 'src/state/ducks/reports/reportsTemplates/actions'
import BaseClient from 'src/state/middleware/saga/baseClient'
import {
  HTTPError,
  handleHTTPError,
} from 'src/state/middleware/saga/handleHttpError'

const baseClient = new BaseClient()

const getTemplatesV2Request = (req: GetTemplatesV2Request) => {
  return baseClient
    .getApi(TemplatesApi)
    .getTemplatesV2(req)
    .then(templates => templates)
    .catch(handleHTTPError)
}

function* getReportsTemplates() {
  try {
    // MEMO: レポート一覧画面で取得するひな形は、すべて取得するため「offset, limit, filter」を使用しない。
    const params: GetTemplatesV2Request = {
      templateFilter: {},
    }
    const { templates }: GetTemplatesResponse = yield call(
      getTemplatesV2Request,
      params,
    )
    yield put(actions.getSuccessReportsTemplates(templates ?? []))
  } catch (error) {
    yield put(interceptionsActions.handleHttpError(error as HTTPError))
    yield put(actions.getErrorReportsTemplates(error as Error))
  }
}

export { getReportsTemplates }
