import {
  DayOfWeekEnum,
  ResponseDatetimeSubTypeEnum,
} from '@ulysses-inc/harami_api_client'
// https://github.com/import-js/eslint-plugin-import/issues/1479
import {
  addDays,
  addHours,
  endOfDay,
  endOfMonth,
  endOfWeek,
  format,
  getISODay,
  isAfter,
  isBefore,
  isSameDay,
  parse,
  startOfDay,
  startOfMonth,
} from 'date-fns' // eslint-disable-line import/no-duplicates
// eslint-disable-next-line import/no-duplicates
import ja from 'date-fns/locale/ja'

interface DateUtil {
  formatMM_JP: (date?: Date) => string
  formatYYYYMM_JP: (date?: Date) => string
  formatYYYYMMDD: (date?: Date) => string
  formatYYYYMMDD_locale: (date?: Date) => string
  formatYYYYMMDD_hyphen: (date?: Date) => string
  formatYYYYMMDD_hyphen_locale: (date?: Date) => string
  formatYYYYMMDD_JP: (date?: Date) => string
  formatYYYYMMDDHH_TimeStamp_UTC: (date?: Date) => string
  formatYYYYMMDDHHMM: (date?: Date) => string
  formatYYYYMMDDHHMM_JP: (date?: Date) => string
  formatYYYYMMDDHHMMSS_JP: (date?: Date) => string
  formatMMDD_JP: (date?: Date) => string
  formatHHMM: (date?: Date) => string
  formatHHMM_JP: (date?: Date) => string
  formatYYYYMMDDEEE: (date?: Date) => string
  formatDayOfMonth_locale: (date?: Date) => string
  formatISODayOfWeek: (date?: Date) => string
  formatISODayOfWeek_locale: (date?: Date) => string
  today: () => Date // FIXME: 現在時刻が返るので、todayよりnowが正しい
  yesterday: () => Date
  todayMidnight: () => Date
  dateCast: (date?: Date) => Date
  parseYYYYMMDD: (strDate: string) => Date
  parse: (strDate: string, format: string) => Date
  nextDay: (date: Date) => Date
  addDays: (date: Date, amount: number) => Date
  addHours: (date: Date, amount: number) => Date
  isWithinRange: (date: Date, startDate: Date, endDate: Date) => boolean
  startOfDay: (date: Date) => Date
  startOfMonth: (date: Date) => Date
  endOfDay: (date: Date) => Date
  endOfMonth: (date: Date) => Date
  endOfWeek: (date: Date) => Date
  getWeekdayString: (date: Date) => string
  isBefore: (date1: Date, date2: Date) => boolean
  isBeforeNow: (date: Date) => boolean
  isAfter: (date1: Date, date2: Date) => boolean
  isAfterNow: (date: Date) => boolean
  isSameDay: (date1: Date, date2: Date) => boolean
  isToday: (date: Date) => boolean
  isYesterday: (date: Date) => boolean
  getExpireDays: (date: Date | undefined) => number
}

let fakeDate: Date | null = null
const setFakeDate = (date: Date) => {
  fakeDate = date
}

const resetFakeDate = () => {
  fakeDate = null
}

const date: DateUtil = {
  formatMM_JP: (date?: Date) => format(fakeDate ?? date ?? new Date(), 'MM月'),
  formatYYYYMM_JP: (date?: Date) =>
    format(fakeDate ?? date ?? new Date(), 'yyyy年MM月'),
  formatYYYYMMDD: (date?: Date) =>
    format(fakeDate ?? date ?? new Date(), 'yyyy/MM/dd'),
  formatYYYYMMDD_locale: (date?: Date) =>
    format(fakeDate ?? date ?? new Date(), 'yyyy/MM/dd', { locale: ja }),
  formatYYYYMMDD_hyphen: (date?: Date) =>
    format(fakeDate ?? date ?? new Date(), 'yyyy-MM-dd'),
  formatYYYYMMDD_hyphen_locale: (date?: Date) =>
    format(fakeDate ?? date ?? new Date(), 'yyyy-MM-dd', { locale: ja }),
  formatYYYYMMDD_JP: (date?: Date) =>
    format(fakeDate ?? date ?? new Date(), 'yyyy年MM月dd日'),
  formatYYYYMMDDHH_TimeStamp_UTC: (date?: Date) => {
    // NOTE formatでUTC時刻が得られなかったため関数を使わずに実装
    const newDate = fakeDate ?? date ?? new Date()
    const utcYear = newDate.getUTCFullYear()
    const utcMonth = String(newDate.getUTCMonth() + 1).padStart(2, '0')
    const utcDate = String(newDate.getUTCDate()).padStart(2, '0')
    const utcHour = String(newDate.getUTCHours()).padStart(2, '0')

    return `${utcYear}${utcMonth}${utcDate}${utcHour}`
  },
  formatYYYYMMDDHHMM: (date?: Date) =>
    format(fakeDate ?? date ?? new Date(), 'yyyy/MM/dd HH:mm'),
  formatYYYYMMDDHHMM_JP: (date?: Date) =>
    format(fakeDate ?? date ?? new Date(), 'yyyy年MM月dd日 HH時mm分'),
  formatYYYYMMDDHHMMSS_JP: (date?: Date) =>
    format(fakeDate ?? date ?? new Date(), 'yyyy年MM月dd日 HH時mm分ss秒'),
  formatMMDD_JP: (date?: Date) =>
    format(fakeDate ?? date ?? new Date(), 'MM月dd日'),
  formatHHMM: (date?: Date) => format(fakeDate ?? date ?? new Date(), 'HH:mm'),
  formatHHMM_JP: (date?: Date) =>
    format(fakeDate ?? date ?? new Date(), 'HH時mm分'),
  formatYYYYMMDDEEE: (date: Date) =>
    format(fakeDate ?? date ?? new Date(), 'yyyy/MM/dd (eee)', { locale: ja }),
  formatDayOfMonth_locale: (date: Date) =>
    format(fakeDate ?? date ?? new Date(), 'dd', { locale: ja }),
  formatISODayOfWeek: (date: Date) =>
    format(fakeDate ?? date ?? new Date(), 'iii'),
  formatISODayOfWeek_locale: (date: Date) =>
    format(fakeDate ?? date ?? new Date(), 'iii', { locale: ja }),
  today: () => fakeDate ?? new Date(),
  yesterday: () => addDays(fakeDate ?? new Date(), -1),
  todayMidnight: () => {
    const today = fakeDate ?? new Date()
    today.setHours(0)
    today.setMinutes(0)
    today.setSeconds(0)
    return today
  },
  dateCast: (date?: Date) => {
    if (fakeDate) return fakeDate
    return date ? new Date(date) : new Date()
  },
  parseYYYYMMDD: (strDate: string) => {
    return parse(strDate, 'yyyy-MM-dd', new Date())
  },
  parse: (strDate: string, format: string) => {
    return parse(strDate, format, new Date(), { locale: ja })
  },
  nextDay: (date: Date) => {
    return addDays(date, 1)
  },
  addDays: (date: Date, amount: number) => {
    return addDays(date, amount)
  },
  addHours: (date: Date, amount: number) => {
    return addHours(date, amount)
  },
  isWithinRange: (date: Date, startDate: Date, endDate: Date) => {
    return isAfter(date, startDate) && isBefore(date, endDate)
  },
  startOfDay: (date: Date) => {
    return startOfDay(date)
  },
  startOfMonth: (date: Date) => {
    return startOfMonth(date)
  },
  endOfDay: (date: Date) => {
    return endOfDay(date)
  },
  endOfMonth: (date: Date) => {
    return endOfMonth(date)
  },
  endOfWeek: (date: Date) => {
    return endOfWeek(date)
  },
  getWeekdayString: (date: Date) => {
    switch (getISODay(date)) {
      case DayOfWeekEnum.Monday:
        return '月'
      case DayOfWeekEnum.Tuesday:
        return '火'
      case DayOfWeekEnum.Wednesday:
        return '水'
      case DayOfWeekEnum.Thursday:
        return '木'
      case DayOfWeekEnum.Friday:
        return '金'
      case DayOfWeekEnum.Saturday:
        return '土'
      case DayOfWeekEnum.Sunday:
        return '日'
      default:
        return ''
    }
  },
  isBefore: (date1: Date, date2: Date) => {
    return isBefore(date1, date2)
  },
  isBeforeNow: (date: Date) => {
    return isBefore(date, new Date())
  },
  isAfter: (date1: Date, date2: Date) => {
    return isAfter(date1, date2)
  },
  isAfterNow: (date: Date) => {
    return isAfter(date, new Date())
  },
  isSameDay: (date1: Date, date2: Date) => isSameDay(date1, date2),
  isToday: (date: Date) => {
    const today = fakeDate ?? new Date()
    return isSameDay(date, today)
  },
  isYesterday: (date: Date) => {
    const yesterday = addDays(fakeDate ?? new Date(), -1)
    return isSameDay(date, yesterday)
  },
  getExpireDays: (date: Date | undefined) => {
    const today = new Date()
    const todayMilliseconds = today.valueOf()
    const targetMilliseconds = date?.valueOf() || 0
    const duration = todayMilliseconds - targetMilliseconds
    const expired =
      (duration - (duration % (24 * 60 * 60 * 1000))) / (24 * 60 * 60 * 1000) // 24 * 60 * 60 * 1000 = milliseconds/day
    return expired
  },
}
// 引数のrecordedAtはAPIから返ってきた値の場合stringになる
const getFormattedRecordedAt = (recordedAt?: Date | string) => {
  if (!recordedAt) {
    return ''
  }
  return date.formatYYYYMMDDHHMM_JP(new Date(recordedAt))
}

const getFormattedResponseDatetimeSubType = (
  inputDate: Date,
  subType: ResponseDatetimeSubTypeEnum,
): string => {
  switch (subType) {
    case ResponseDatetimeSubTypeEnum.DATETIME:
      return date.formatYYYYMMDDHHMM_JP(inputDate)
    case ResponseDatetimeSubTypeEnum.DATE:
      return date.formatYYYYMMDD_JP(inputDate)
    case ResponseDatetimeSubTypeEnum.TIME:
      return date.formatHHMM_JP(inputDate)
    default:
      return ''
  }
}

export const isValidDateStr = (str: string | undefined): str is string => {
  if (str === undefined) {
    return false
  }

  if (isNaN(new Date(str).getDate())) {
    return false
  }

  return true
}

export default date
export {
  getFormattedRecordedAt,
  getFormattedResponseDatetimeSubType,
  resetFakeDate,
  setFakeDate,
}
