import { ReportListItem } from '@ulysses-inc/harami_api_client'
import { Checkbox } from 'antd'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'
import { Dispatch, FC, SetStateAction } from 'react'
import { TReportListItemWithId, isReportListItemWithId } from './Reports'
import {
  CheckboxCol,
  ColumnBackground,
  IsInvalidCol,
  ReportNameCol,
  ReportPlaceCol,
  ReportScoreCol,
  ReportStatusCol,
  ReportUpdatedCol,
  ReportUserCol,
} from './Reports.styled'

const ReportsTableHeader: FC<{
  flatReports: ReportListItem[]
  selectedReportIdsState: [Set<number>, Dispatch<SetStateAction<Set<number>>>]
}> = ({
  flatReports,
  selectedReportIdsState: [selectedReportIds, setSelectedReportIds],
}) => {
  const isEveryReportSelected = () => {
    if (flatReports.length === 0) {
      return false
    }
    return flatReports
      .filter((report): report is TReportListItemWithId =>
        isReportListItemWithId(report),
      )
      .every(report => selectedReportIds.has(report.id))
  }

  const selectAllReports = (e: CheckboxChangeEvent) => {
    if (e.target.checked) {
      const allReportIds = flatReports
        .filter((report): report is TReportListItemWithId =>
          isReportListItemWithId(report),
        )
        .map(report => report.id)
      setSelectedReportIds(new Set(allReportIds))
    } else {
      setSelectedReportIds(new Set([]))
    }
  }

  return (
    <ColumnBackground>
      <CheckboxCol theme={{ widthPercentage: 3 }}>
        <Checkbox
          checked={isEveryReportSelected()}
          onChange={selectAllReports}
        />
      </CheckboxCol>
      <ReportNameCol theme={{ widthPercentage: 23 }}>レポート名</ReportNameCol>
      <ReportPlaceCol theme={{ widthPercentage: 18 }}>現場名</ReportPlaceCol>
      <ReportUserCol theme={{ widthPercentage: 20 }}>ユーザー名</ReportUserCol>
      <IsInvalidCol theme={{ widthPercentage: 8 }}>逸脱有無</IsInvalidCol>
      <ReportUpdatedCol theme={{ widthPercentage: 12 }}>
        更新日時
      </ReportUpdatedCol>
      <ReportStatusCol theme={{ widthPercentage: 10 }}>
        ステータス
      </ReportStatusCol>
      <ReportScoreCol theme={{ widthPercentage: 6 }}>スコア</ReportScoreCol>
    </ColumnBackground>
  )
}

export default ReportsTableHeader
