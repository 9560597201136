import { differenceInDays, parseISO } from 'date-fns'
import deepEqual from 'fast-deep-equal'
import * as qs from 'qs'
import { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { maxSelectableDays } from 'src/features/scheduledReportSummary/ScheduledReportSummary'
import { isValidDateString } from 'src/util/datetime/isValidDateString'
import { removeMatchingKeysAndValues } from 'src/util/removeMatchingKeysAndValues'

/**
 * スケジュール実施状況群を取得する際のフィルタ条件
 */
export type ScheduledReportSummaryFilters = {
  /**
   * 開始日と終了日を持つ配列
   * ISO8601 拡張形式の日付 (YYYY-MM-DD) により表現される
   */
  dateRange: [string, string] | null
  placeNodeUuids: string[]
}

export const initialFilters: ScheduledReportSummaryFilters = {
  dateRange: null,
  placeNodeUuids: [],
}

const qsOptions = {
  parse: { ignoreQueryPrefix: true },
  stringify: {
    skipNulls: true,
    encode: false,
  },
}

/**
 * 型の保証と不正な値のデフォルト値へのフォールバックを行う
 */
const sanitize = {
  dateRange: (arg: unknown): ScheduledReportSummaryFilters['dateRange'] => {
    const [arg1, arg2] = Array.isArray(arg) ? arg : []
    if (!isValidDateString(arg1) || !isValidDateString(arg2)) {
      return initialFilters.dateRange
    }
    const dateFrom = parseISO(arg1)
    const dateTo = parseISO(arg2)
    if (
      // 日付が未来であってはならない
      dateFrom > new Date() ||
      dateTo > new Date() ||
      // 開始日が終了日より後の日付であってはならない
      dateFrom > dateTo ||
      // 日付の差が最大選択可能日数を超えてはならない
      differenceInDays(dateTo, dateFrom) >= maxSelectableDays
    ) {
      return initialFilters.dateRange
    }
    return [arg1, arg2]
  },
  placeNodeUuids: (arg: unknown): string[] => {
    if (Array.isArray(arg)) {
      return arg.filter((uuid): uuid is string => typeof uuid === 'string')
    }
    return initialFilters.placeNodeUuids
  },
}

export const useFilters = () => {
  const history = useHistory()
  const location = useLocation()

  const getFiltersFromQueryString = (): ScheduledReportSummaryFilters => {
    const rawFilters = qs.parse(location.search, qsOptions.parse)
    return {
      dateRange: sanitize.dateRange(rawFilters['dateRange']),
      placeNodeUuids: sanitize.placeNodeUuids(rawFilters['placeNodeUuids']),
    }
  }

  const [filters, setFilters] = useState<ScheduledReportSummaryFilters>(() =>
    getFiltersFromQueryString(),
  )

  // フィルタが変更されたらURLを更新する
  useEffect(() => {
    // URLが散らかるのを防ぎたいため、初期値と同じフィルタはURLから取り除く
    const filtersToSet = removeMatchingKeysAndValues(filters, initialFilters)
    const newQueryString = qs.stringify(filtersToSet, qsOptions.stringify)

    const isAlreadySynchronized =
      newQueryString === history.location.search.substring(1)
    if (isAlreadySynchronized) return

    history.push({ search: newQueryString })
  }, [filters, history])

  // URLが変更されたらフィルタを更新する
  useEffect(
    () => {
      const newFilters = getFiltersFromQueryString()

      const isAlreadySynchronized = deepEqual(newFilters, filters)
      if (isAlreadySynchronized) return

      setFilters(newFilters)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      // getFiltersFromQueryString,
      location.search,
    ],
  )

  /**
   * フィルタの値を更新する
   *
   * 型のおかげで本来はsanitize関数が不要なプロパティもあるものの、
   * 一貫性を重視してすべてのプロパティに一括してsanitize関数を適用している。
   */
  const setFilterOf = {
    dateRange: (dateFrom: string, dateTo: string) => {
      setFilters(prev => ({
        ...prev,
        dateRange: sanitize.dateRange([dateFrom, dateTo]),
      }))
    },
    placeNodeUuids: (placeNodeUuids: string[]) => {
      setFilters(prev => ({
        ...prev,
        placeNodeUuids: sanitize.placeNodeUuids(placeNodeUuids),
      }))
    },
  }

  const resetFilterOf = {
    dateRange: () => {
      setFilters(prev => ({
        ...prev,
        dateRange: null,
      }))
    },
    allExceptInDrawer: () => {
      setFilters(current => ({
        ...initialFilters,
        dateRange: current.dateRange,
      }))
    },
  }

  const isSomeFilterInDrawerActive = (() => {
    const { dateRange: _1, ...restFilters } = filters
    const { dateRange: _2, ...restInitialFilters } = initialFilters
    return !deepEqual(restFilters, restInitialFilters)
  })()

  return {
    filters,
    isSomeFilterInDrawerActive,
    resetFilterOf,
    setFilterOf,
  }
}
