import {
  ReportNodeSchema,
  ReportQuestion,
} from '@ulysses-inc/harami_api_client'
import convertDisplayFormulaCharacters from 'src/exShared/util/formula/convertDisplayFormulaCharacters'
import { Hint } from './Hint'

type OwnProps = {
  question: ReportQuestion
  headerRow: ReportNodeSchema[]
}

/**
 * 行ヘッダーに表示する計算式質問のヒント
 * @param question 計算式質問
 * @param headerRow 行ヘッダー
 */
export const FormulaHint = ({ question, headerRow }: OwnProps) => {
  const title = '計算式'

  const tokens = question.responseFormulas?.[0]?.tokens
  if (!tokens) {
    return <Hint title={title} content={'計算式が不正です'} />
  }

  const content = convertDisplayFormulaCharacters(headerRow, tokens)
  return <Hint title={title} content={content} />
}
