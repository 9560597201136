import { css } from '@emotion/react'
import { PlaceNode } from '@ulysses-inc/harami_api_client'
import { Button, Dropdown, Select, Switch, Typography } from 'antd'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { usePlaceLoadingStatus } from 'src/exShared/hooks/usePlaceLoadingStatus'
import {
  initializePlaceFilter,
  splitAccessiblePlaceNode,
} from 'src/exShared/libraries/filterDropDown/place'
import { getUniqueFlattenNodes } from 'src/exShared/util/place/getUniqueFlattenNodes'
import {
  flattenNodes,
  mergedPlaceNodeIds,
} from 'src/exShared/util/place/placeNode'
import { splitLoginPlaceUUIDs } from 'src/exShared/util/place/splitLoginPlace'
import { useListFilter } from 'src/hooks/filter/useListFilter'
import employeesOperations from 'src/state/ducks/employees/operations'
import { GetEmployeesFilter } from 'src/state/ducks/employees/types'
import { RootState } from 'src/state/store'
import {
  DownOutlinedIcon,
  DropDownTableCell,
  FilterButton,
  SelectFilterTableCell,
  UpOutlinedIcon,
} from './EmployeesFilterDropDown.styled'

const { Text } = Typography
const { Option } = Select

const EmployeesFilterDropDown = () => {
  const dispatch = useDispatch()

  const [isOpen, setIsOpen] = useState(false)
  const [placeNodeIds, setPlaceNodeIds] = useState<string[]>([])
  const [placeGroupNodeIds, setPlaceGroupNodeIds] = useState<string[]>([])
  const [isIncludeNonAffiliated, setIsIncludeNonAffiliated] =
    useState<boolean>(false)
  const { filters, setListFilter } = useListFilter()
  const { loginPlaceGroupIds, loginPlaceIds } = splitLoginPlaceUUIDs()
  const placeLoadingStatus = usePlaceLoadingStatus()

  const {
    employees: { request },
  } = useSelector((state: RootState) => state.employeesState)
  const {
    places: { places },
    placeGroups: { nodes },
  } = useSelector((state: RootState) => state.placesState)

  const placeNodes = getUniqueFlattenNodes([
    ...flattenNodes(nodes ?? []),
    ...(places ?? []),
  ])

  useEffect(() => {
    const loginPlaceUUIDs = splitLoginPlaceUUIDs()
    setPlaceNodeIds(loginPlaceUUIDs.loginPlaceIds)
    setPlaceGroupNodeIds(loginPlaceUUIDs.loginPlaceGroupIds)
  }, [])

  useEffect(() => {
    if (placeLoadingStatus !== 'finish') {
      return
    }
    // MEMO: 以下の条件に合致する動作がないので、不要かも知れません。
    if (!filters || !filters.employees) {
      const placeFilter = initializePlaceFilter(placeNodes, true)
      if (placeFilter === undefined || placeFilter.$in.length === 0) {
        getFilterEmployees()
        return
      }
      const filter = { placeNodeId: placeFilter, isIncludeNonAffiliated: false }
      getFilterEmployees(filter)
      setPlaceNodeIds(loginPlaceIds)
      setPlaceGroupNodeIds(loginPlaceGroupIds)
      return
    }
    const employeeFilter = filters.employees.filter
    let filterPlaceNodeIds: string[] = []
    let filterPlaceGroupNodeIds: string[] = []
    if (
      (employeeFilter?.placeNodeIds ?? []).length > 0 ||
      (employeeFilter?.placeGroupNodeIds ?? []).length > 0
    ) {
      filterPlaceNodeIds = employeeFilter?.placeNodeIds ?? []
      filterPlaceGroupNodeIds = employeeFilter?.placeGroupNodeIds ?? []
    } else {
      filterPlaceNodeIds = loginPlaceIds
      filterPlaceGroupNodeIds = loginPlaceGroupIds
    }
    setPlaceNodeIds(filterPlaceNodeIds)
    setPlaceGroupNodeIds(filterPlaceGroupNodeIds)
    setIsIncludeNonAffiliated(employeeFilter?.isIncludeNonAffiliated ?? false)
    const filter = getFilter(
      filterPlaceNodeIds,
      filterPlaceGroupNodeIds,
      employeeFilter?.isIncludeNonAffiliated,
    )
    getFilterEmployees(filter)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [placeNodes.length, placeLoadingStatus])

  const getFilterEmployees = (filter?: GetEmployeesFilter) => {
    const limit = Object.keys(request).length ? request.limit : 25
    employeesOperations.getEmployees(
      dispatch,
      { limit: limit, offset: 0 },
      filter,
    )
  }
  const isFilterOn = () => {
    return (
      placeGroupNodeIds.length > 0 ||
      placeNodeIds.length > 0 ||
      isIncludeNonAffiliated
    )
  }

  const clearFilter = () => {
    const filter = getFilter(loginPlaceIds, loginPlaceGroupIds, false)
    getFilterEmployees(filter)
    setPlaceNodeIds([])
    setPlaceGroupNodeIds([])
    setIsIncludeNonAffiliated(false)
    saveFilter()
  }

  const saveFilter = (
    placeNodeIds?: string[],
    placeGroupNodeIds?: string[],
    isIncludeNonAffiliated?: boolean,
  ) => {
    setListFilter({
      ...(filters ?? {}),
      employees: {
        pagination: request,
        filter: {
          placeNodeIds: placeNodeIds,
          placeGroupNodeIds: placeGroupNodeIds,
          isIncludeNonAffiliated: isIncludeNonAffiliated ?? false,
        },
      },
    })
  }

  const getFilter = (
    placeNodeIds: string[],
    placeGroupNodeIds?: string[],
    isIncludeNonAffiliated?: boolean,
  ): GetEmployeesFilter => {
    const allPlaceNodeIds = mergedPlaceNodeIds(
      placeNodes,
      placeNodeIds ?? [],
      placeGroupNodeIds ?? [],
      true,
    )
    const placeNodeIdCondition =
      allPlaceNodeIds && allPlaceNodeIds.length > 0
        ? { $in: allPlaceNodeIds }
        : undefined
    saveFilter(placeNodeIds, placeGroupNodeIds, isIncludeNonAffiliated)
    return {
      placeNodeId: placeNodeIdCondition,
      isIncludeNonAffiliated: isIncludeNonAffiliated ?? false,
    }
  }

  const accessiblePlaceNode = splitAccessiblePlaceNode(placeNodes, places)

  const groundArea = (
    <SelectFilterTableCell theme={{ width: '160px' }}>
      <FilterButton>
        <div style={{ flex: 1, marginLeft: 12 }}>
          フィルター：{isFilterOn() ? 'ON' : 'OFF'}
        </div>
        <div style={{ minWidth: 20, marginRight: 12 }}>
          {isOpen ? <UpOutlinedIcon /> : <DownOutlinedIcon />}
        </div>
      </FilterButton>
    </SelectFilterTableCell>
  )

  const hoverArea = (
    <div style={{ display: 'flex' }}>
      <DropDownTableCell>
        <div css={styles.clearButtonRow}>
          <Button onClick={clearFilter}>クリア</Button>
        </div>
        <div style={{ marginBottom: 5 }}>
          <Text style={{ marginLeft: 3 }}>現場グループ名</Text>
        </div>
        <Select
          mode="multiple"
          style={{ width: '100%' }}
          value={placeGroupNodeIds}
          optionFilterProp="label"
          onChange={(selectedPlaceGroupIds: string[]) => {
            setPlaceGroupNodeIds(selectedPlaceGroupIds || [])
            const filterPlaceNodeIds =
              placeNodeIds.length > 0 ||
              (selectedPlaceGroupIds ?? []).length > 0
                ? placeNodeIds
                : loginPlaceIds
            const filterPlaceGroupNodeIds =
              placeNodeIds.length > 0 ||
              (selectedPlaceGroupIds ?? []).length > 0
                ? selectedPlaceGroupIds
                : loginPlaceGroupIds
            const filter = getFilter(
              filterPlaceNodeIds,
              filterPlaceGroupNodeIds ?? [],
              isIncludeNonAffiliated,
            )
            getFilterEmployees(filter)
          }}
        >
          {accessiblePlaceNode.accessiblePlaceGroups.map(
            (placeNode: PlaceNode, index: number) => (
              <Option
                key={index}
                value={placeNode.uuid}
                label={placeNode.placeGroup?.name}
              >
                {placeNode.placeGroup?.name}
              </Option>
            ),
          )}
        </Select>
        <div style={{ marginTop: 16, marginBottom: 5 }}>
          <Text style={{ marginLeft: 3 }}>現場名</Text>
        </div>
        <Select
          mode="multiple"
          style={{ width: '100%' }}
          value={placeNodeIds}
          optionFilterProp="label"
          onChange={(selectedPlaceNodeIds: string[]) => {
            setPlaceNodeIds(selectedPlaceNodeIds || [])
            const filterPlaceNodeIds =
              (selectedPlaceNodeIds ?? []).length > 0 ||
              placeGroupNodeIds.length > 0
                ? selectedPlaceNodeIds
                : loginPlaceIds
            const filterPlaceGroupNodeIds =
              (selectedPlaceNodeIds ?? []).length > 0 ||
              placeGroupNodeIds.length > 0
                ? placeGroupNodeIds
                : loginPlaceGroupIds
            const filter = getFilter(
              filterPlaceNodeIds || [],
              filterPlaceGroupNodeIds,
              isIncludeNonAffiliated,
            )
            getFilterEmployees(filter)
          }}
        >
          {accessiblePlaceNode.accessiblePlaces.map(
            (placeNode: PlaceNode, index: number) => (
              <Option
                key={index}
                value={placeNode.uuid}
                label={placeNode.place?.name}
              >
                {placeNode.place?.name}
              </Option>
            ),
          )}
        </Select>
        <div style={{ marginTop: 16, marginBottom: 5 }}>
          <Text>未所属を含む</Text>
        </div>
        <Switch
          checked={isIncludeNonAffiliated}
          onChange={(checked: boolean) => {
            setIsIncludeNonAffiliated(checked)
            const filterPlaceNodeIds =
              placeNodeIds.length > 0 || placeGroupNodeIds.length > 0
                ? placeNodeIds
                : loginPlaceIds
            const filterPlaceGroupNodeIds =
              placeNodeIds.length > 0 || placeGroupNodeIds.length > 0
                ? placeGroupNodeIds
                : loginPlaceGroupIds
            const filter = getFilter(
              filterPlaceNodeIds,
              filterPlaceGroupNodeIds || [],
              checked,
            )
            getFilterEmployees(filter)
          }}
        />
      </DropDownTableCell>
    </div>
  )

  return (
    <Dropdown
      dropdownRender={() => hoverArea}
      trigger={['click']}
      open={isOpen}
      onOpenChange={setIsOpen}
    >
      {groundArea}
    </Dropdown>
  )
}

const styles = {
  clearButtonRow: css`
    display: flex;
    justify-content: flex-end;
  `,
}

export default EmployeesFilterDropDown
