import { Button, Checkbox, Input, InputNumber, Row, Table } from 'antd'
import TrashBinSvg from 'src/assets/icons/trashbin.svg?react'
import { BorderColor } from 'src/features/theme/KdsThemeColor'
import styled from 'styled-components'

export const TrashBinIcon = styled(TrashBinSvg)`
  font-size: 14px;
`

export const CustomizedTable = styled(Table)`
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td,
  .ant-table tfoot > tr > th,
  .ant-table tfoot > tr > td {
    padding: 8px 8px;
  }
`

export const ButtonGroupRow = styled(Row)`
  justify-content: space-between;
  border-top: 1px solid ${BorderColor};
  padding-top: 24px;
  padding-right: 16px;
`

export const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
`

export const FooterButton = styled(Button)`
  padding: 5px 15px;
  margin-left: 8px;
`

export const ScoreGroup = styled.div`
  display: flex;
  height: 32px;
  align-items: center;
`

export const Score = styled(InputNumber)`
  width: 72px;
`

export const ResponseInput = styled(Input)`
  width: 154px;
`

export const CenteredCheckbox = styled(Checkbox)`
  display: flex !important;
  justify-content: center;
`

export const DeleteButton = styled(Button)`
  display: flex !important;
  align-items: center;
  justify-content: center;
`

export const ConfirmModalContentWrapper = styled.div`
  margin-top: 20px;
`
export const ConfirmModalContentTemplates = styled.div`
  position: relative;
  margin-top: 20px;
  margin-bottom: -30px;
  &::after {
    content: '';
    position: absolute;
    display: block;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 30px;
    background: linear-gradient(0deg, #ffffff 0%, #ffffff00 100%);
  }
`
export const ConfirmModalContentTemplatesList = styled.ul`
  max-height: 220px;
  padding-left: 20px;
  padding-bottom: 30px;
  overflow-y: scroll;
`
export const Link = styled.a`
  text-decoration: underline;
`

export const DrawerBodyStyle = {
  padding: '0',
}

export const DrawerHeaderStyle = {
  paddingLeft: '16px',
  paddingTop: '16.5px',
  paddingBottom: '16.5px',
}

export const ButtonSuffixText = styled.div`
  margin-left: 8px;
`
