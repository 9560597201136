import { StyleSheet, Text, View } from '@react-pdf/renderer'
import { ReportNodeSchema } from '@ulysses-inc/harami_api_client'
import React from 'react'
import { TableBorderColor, White } from 'src/features/theme/KdsThemeColor'
import { GirdLayoutPage } from '../../utils/getGridLayoutPages'

const styles = StyleSheet.create({
  tableRow: {
    flexDirection: 'row',
  },
  baseTableHeaderCell: {
    padding: 5,
    backgroundColor: White,
    textAlign: 'center',
    fontSize: 8,
    fontWeight: 700,
    borderRight: `1px solid ${TableBorderColor}`,
    borderLeft: 'none',
  },
  tableHeaderFirstCell: {
    width: 25,
    borderTop: 'none',
    borderBottom: `1px solid ${TableBorderColor}`,
  },
  tableHeaderCell: {
    width: 65,
    borderTop: `1px solid ${TableBorderColor}`,
    borderBottom: `2px solid ${TableBorderColor}`,
  },
})

interface Props {
  girdLayoutPage: GirdLayoutPage | undefined
}

export const GirdLayoutTableHeader: React.FC<Props> = ({ girdLayoutPage }) => {
  if (!girdLayoutPage) {
    return null
  }
  return (
    <View style={styles.tableRow} fixed>
      {/* 行数を表示するための空セル */}
      <Text
        style={[
          styles.baseTableHeaderCell,
          // 行数を変更する場合、 border を追加する
          girdLayoutPage.bodyRowNodes.length > 0
            ? styles.tableHeaderFirstCell
            : {},
        ]}
      >
        &nbsp;
      </Text>
      {girdLayoutPage.headerCellNodes.map((cellNode: ReportNodeSchema) => {
        if (!cellNode || !cellNode.question) {
          return null
        }
        return (
          <Text
            style={[styles.baseTableHeaderCell, styles.tableHeaderCell]}
            key={cellNode.id}
          >
            {cellNode.question.name}
          </Text>
        )
      })}
    </View>
  )
}
