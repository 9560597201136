// prettier-ignore
export const ActionTypes = {
  // Places
  REQUEST_GET_PLACES: 'harami/places/REQUEST_GET_PLACES',
  SUCCESS_GET_PLACES: 'harami/places/SUCCESS_GET_PLACES',
  ERROR_GET_PLACES: 'harami/places/ERROR_GET_PLACES',
  REQUEST_CHANGE_PLACES_PAGE: 'harami/templates/REQUEST_CHANGE_PLACES_PAGE',
  REQUEST_CHANGE_PLACES_SIZE: 'harami/templates/REQUEST_CHANGE_PLACES_SIZE',
  REQUEST_GET_PLACES_V2: 'harami/places/REQUEST_GET_PLACES_V2',
  REQUEST_CHANGE_PLACES_V2_PAGE: 'harami/templates/REQUEST_CHANGE_PLACES_V2_PAGE',
  REQUEST_CHANGE_PLACES_V2_SIZE: 'harami/templates/REQUEST_CHANGE_PLACES_V2_SIZE',
  ERROR_ADD_PLACE: 'harami/places/ERROR_ADD_PLACE',
  REQUEST_ADD_PLACE: 'harami/places/REQUEST_ADD_PLACE',
  SUCCESS_ADD_PLACE: 'harami/places/SUCCESS_ADD_PLACE',
  REQUEST_UPDATE_PLACE: 'harami/places/REQUEST_UPDATE_PLACE',
  SUCCESS_UPDATE_PLACE: 'harami/places/SUCCESS_UPDATE_PLACE',
  ERROR_UPDATE_PLACE: 'harami/places/ERROR_UPDATE_PLACE',
  REQUEST_DELETE_PLACE: 'harami/places/REQUEST_DELETE_PLACE',
  SUCCESS_DELETE_PLACE: 'harami/places/SUCCESS_DELETE_PLACE',
  ERROR_DELETE_PLACE: 'harami/places/ERROR_DELETE_PLACE',
  CHANGE_UPDATED_PLACES_FLAG: 'harami/places/CHANGE_UPDATED_PLACES_FLAG',
  UPDATE_SEGMENT_INDEX: 'harami/places/UPDATE_SEGMENT_INDEX',
  UPDATE_ACTIVE_PLACE_ID: 'harami/places/UPDATE_ACTIVE_PLACE_ID',
  CHANGE_IS_SHOW_EDIT_PLACE_DRAWER: 'harami/places/CHANGE_IS_SHOW_EDIT_PLACE_DRAWER',

  // Place Groups
  REQUEST_GET_PLACE_GROUPS: 'harami/placeGroups/REQUEST_GET_PLACE_GROUPS',
  SUCCESS_GET_PLACE_GROUPS: 'harami/placeGroups/SUCCESS_GET_PLACE_GROUPS',
  ERROR_GET_PLACE_GROUPS: 'harami/placeGroups/ERROR_GET_PLACE_GROUPS',
  ERROR_ADD_PLACE_GROUP: 'harami/placeGroups/ERROR_ADD_PLACE_GROUP',
  REQUEST_ADD_PLACE_GROUP: 'harami/placeGroups/REQUEST_ADD_PLACE_GROUP',
  SUCCESS_ADD_PLACE_GROUP: 'harami/placeGroups/SUCCESS_ADD_PLACE_GROUP',
  REQUEST_UPDATE_PLACE_GROUP: 'harami/placeGroups/REQUEST_UPDATE_PLACE_GROUP',
  SUCCESS_UPDATE_PLACE_GROUP: 'harami/placeGroups/SUCCESS_UPDATE_PLACE_GROUP',
  ERROR_UPDATE_PLACE_GROUP: 'harami/placeGroups/ERROR_UPDATE_PLACE_GROUP',
  REQUEST_DELETE_PLACE_GROUP: 'harami/placeGroups/REQUEST_DELETE_PLACE_GROUP',
  SUCCESS_DELETE_PLACE_GROUP: 'harami/placeGroups/SUCCESS_DELETE_PLACE_GROUP',
  ERROR_DELETE_PLACE_GROUP: 'harami/placeGroups/ERROR_DELETE_PLACE_GROUP',
  UPDATE_ACTIVE_NODE_ID: 'harami/placeGroups/UPDATE_ACTIVE_NODE_ID',
  CHANGE_IS_SHOW_EDIT_PLACE_GROUP_DRAWER: 'harami/placeGroups/CHANGE_IS_SHOW_EDIT_PLACE_GROUP_DRAWER',

  // Place Nodes
  REQUEST_GET_PLACE_NODES: 'harami/placeNodes/REQUEST_GET_PLACE_NODES',
  SUCCESS_GET_PLACE_NODES: 'harami/placeNodes/SUCCESS_GET_PLACE_NODES',
  ERROR_GET_PLACE_NODES: 'harami/placeNodes/ERROR_GET_PLACE_NODES',
} as const

export interface GetPlacesFilter {
  placeName?: { $like: string }
}
