import {
  ReportNodeSchema,
  ReportPageSchema,
  TemplateLayoutTypeEnum,
} from '@ulysses-inc/harami_api_client'
import React from 'react'
import { ReportResultSchema } from 'src/exShared/types/types'
import { isDefined } from 'src/util/idDefined'
import ReportDetailDefaultLayout from './components/reportDetailDefaultLayout/ReportDetailDefaultLayout'
import { ReportDetailGridLayout } from './components/reportDetailGridLayut/ReportDetailGridLayout'

interface OwnProps {
  report: ReportResultSchema
  deviatedAnswers: ReportNodeSchema[]
}

interface MergeProps {
  getPages: () => Array<ReportPageSchema>
}

type ReportDetailProps = OwnProps & MergeProps

const ReportDetailContainer: React.FC<OwnProps> = (_props: OwnProps) => {
  const mergeProps: MergeProps = {
    getPages: () =>
      _props.report.pageIds
        .map((id: number) => _props.report.pages[id])
        .filter(isDefined),
  }
  const props = { ..._props, ...mergeProps }
  return <ReportDetail {...props} />
}

const ReportDetail: React.FC<ReportDetailProps> = (
  props: ReportDetailProps,
) => {
  switch (props.report.layoutType) {
    case TemplateLayoutTypeEnum.Grid:
      return <ReportDetailGridLayout {...props} />
    default:
      return <ReportDetailDefaultLayout {...props} />
  }
}

export default ReportDetailContainer
