import { ExcelConversionStepTypeEnum } from '@ulysses-inc/harami_api_client'
import { EditSerialNumberAction } from 'src/state/ducks/templates/editSerialNumber/actions'
import { ActionTypes } from 'src/state/ducks/templates/editSerialNumber/types'

interface EditSerialNumberState {
  repeatSerialNumbers: {
    [path: string]: number
  }
}

const initialEditSerialNumberState: EditSerialNumberState = {
  repeatSerialNumbers: {},
}

export const editSerialNumberReducer = (
  state: EditSerialNumberState = initialEditSerialNumberState,
  action: EditSerialNumberAction,
) => {
  switch (action.type) {
    case ActionTypes.SET_REPEAT_SERIAL_NUMBERS: {
      const repeatSerialNumberNodes = action.nodes.filter(node =>
        node?.question?.excelConversionTypes?.some(
          c => c.excelConversionType === ExcelConversionStepTypeEnum.Repeat,
        ),
      )
      const repeatSerialNumberEntries = repeatSerialNumberNodes
        .map<[number, number] | null>(node => {
          const parentNode = action.nodes.find(n => n.nodes.includes(node.id))
          const excelConversionType = node.question?.excelConversionTypes?.find(
            c => c.excelConversionType === ExcelConversionStepTypeEnum.Repeat,
          )
          if (parentNode) {
            return [parentNode.id, excelConversionType?.serialNumber || 1]
          }
          return null
        })
        .filter((e): e is [number, number] => !!e)
      return {
        ...state,
        repeatSerialNumbers: Object.fromEntries(repeatSerialNumberEntries),
      }
    }
    case ActionTypes.UPDATE_REPEAT_SERIAL_NUMBER: {
      return {
        ...state,
        repeatSerialNumbers: {
          ...state.repeatSerialNumbers,
          [action.parentNodeId]: action.number,
        },
      }
    }
    default: {
      return { ...state }
    }
  }
}
