import { GetTemplatesRequest, Template } from '@ulysses-inc/harami_api_client'
import { GetTemplatesFilter } from 'src/exShared/types/types'
import { TemplatesAction } from 'src/state/ducks/templateList/actions'
import { ActionTypes } from 'src/state/ducks/templateList/types'

interface TemplatesState {
  templates: Template[]
  count: number
  isLoading: boolean
  error: Error | null
  request: GetTemplatesRequest
  filter: GetTemplatesFilter
  /**
   * 一覧でひな形を削除しようとした際に、特定のエラーが発生した際にセットされるID。
   * 例えば、取り込み項目ありの表形式のひな形において、取り込み詳細でレコードが１件でも登録されている場合が該当する
   * templateDeleteErrorTemplateId = 0でも、存在しない状態を表すことができるが、
   * エラーがない状態の存在を明瞭にするため、undefinedとのunion型にしている
   */
  templateDeleteErrorTemplateId: number | undefined
}

const initialTemplatesState: TemplatesState = {
  templates: [],
  count: 0,
  isLoading: false,
  error: null,
  request: {
    limit: 25,
    offset: 0,
  },
  filter: {},
  templateDeleteErrorTemplateId: undefined,
}

export const templateListReducer = (
  state: TemplatesState = initialTemplatesState,
  action: TemplatesAction,
) => {
  switch (action.type) {
    case ActionTypes.REQUEST_GET_TEMPLATES: {
      return {
        ...state,
        isLoading: action.isLoading,
        request: action.request,
        filter: action.filter ?? {},
        error: null,
      }
    }
    case ActionTypes.SUCCESS_GET_TEMPLATES: {
      if (action.count === 0) {
        return {
          ...state,
          templates: [],
          isLoading: action.isLoading,
        }
      }
      return {
        ...state,
        isLoading: action.isLoading,
        templates: [...(action.templates ?? [])],
        count: action.count,
      }
    }
    case ActionTypes.ERROR_GET_TEMPLATES: {
      return {
        ...state,
        isLoading: action.isLoading,
        error: action.error,
      }
    }
    case ActionTypes.REQUEST_DELETE_TEMPLATE: {
      return {
        ...state,
        isLoading: action.isLoading,
        templateId: action.templateId,
        error: null,
      }
    }
    case ActionTypes.SUCCESS_DELETE_TEMPLATE: {
      return {
        ...state,
        isLoading: action.isLoading,
        templates: state.templates.filter(
          template => template.id !== action.templateId,
        ),
      }
    }
    case ActionTypes.ERROR_DELETE_TEMPLATE: {
      return {
        ...state,
        isLoading: action.isLoading,
        error: action.error,
      }
    }
    case ActionTypes.REQUEST_CHANGE_TEMPLATES_PAGE: {
      return {
        ...state,
        request: action.request,
      }
    }
    case ActionTypes.REQUEST_CHANGE_TEMPLATES_SIZE: {
      return {
        ...state,
        request: action.request,
      }
    }
    case ActionTypes.UPDATE_PAGINATION: {
      return {
        ...state,
        request: action.request,
      }
    }
    case ActionTypes.REQUEST_COPY_TEMPLATES: {
      return {
        ...state,
        isLoading: action.isLoading,
        error: null,
      }
    }
    case ActionTypes.SUCCESS_COPY_TEMPLATES: {
      return {
        ...state,
        isLoading: action.isLoading,
        templates: action.templates ? [...action.templates] : [],
        count: action.count,
        filter: { ...state.filter },
      }
    }
    case ActionTypes.ERROR_COPY_TEMPLATES: {
      return {
        ...state,
        isLoading: action.isLoading,
        error: action.error,
      }
    }
    case ActionTypes.SET_TEMPLATE_DELETE_ERROR_TEMPLATE_ID: {
      return {
        ...state,
        templateDeleteErrorTemplateId: action.templateId,
        isLoading: false,
      }
    }
    case ActionTypes.CLEAR_TEMPLATE_DELETE_ERROR_TEMPLATE_ID: {
      return {
        ...state,
        templateDeleteErrorTemplateId: undefined,
      }
    }
    default: {
      return { ...state }
    }
  }
}
