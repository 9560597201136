import { DownloadOutlined } from '@ant-design/icons'
import {
  ExcelConversionFlow,
  ExcelConversionLog,
  GetExcelConversionLogsRequest,
  PlaceNode,
} from '@ulysses-inc/harami_api_client'
import { Button, Layout, Pagination, Row } from 'antd'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Header } from 'src/components/header/Header'
import { HeaderBackButton } from 'src/components/header/HeaderBackButton'
import { flattenNodes } from 'src/exShared/util/place/placeNode'
import { GlobalNavigation } from 'src/features/globalNavigation/GlobalNavigation'
import excelConversionFlowsOperations from 'src/state/ducks/excelConversionFlows/operations'
import excelConversionLogsOperations from 'src/state/ducks/excelConversionLogs/operations'
import { GetExcelConversionLogsFilter } from 'src/state/ducks/excelConversionLogs/types'
import placesOperations from 'src/state/ducks/places/operations'
import { RootState } from 'src/state/store'
import styled from 'styled-components'
import Loading from '../../components/loading/Loading'
import { useListFilter } from '../../hooks/filter/useListFilter'
import ExcelConversionLogFilterDropDown from './ExcelConversionLogFilterDropDown'
import ExcelConversionLogList from './ExcelConversionLogList'

interface StateProps {
  logs: ExcelConversionLog[]
  flows: ExcelConversionFlow[]
  isLoading: boolean
  count: number
  places: PlaceNode[]
  placeNodes: PlaceNode[]
  filter?: GetExcelConversionLogsFilter
  request: GetExcelConversionLogsRequest
  isLoadingPlace: boolean
}

interface DispatchProps {
  getLogs: (
    request: GetExcelConversionLogsRequest,
    filter?: GetExcelConversionLogsFilter,
  ) => void
  getFilterData: () => void
  changeLogsPage: (
    page: number,
    pageSize?: number,
    filter?: GetExcelConversionLogsFilter,
  ) => void
  changeLogsSize: (
    pageSize: number,
    filter?: GetExcelConversionLogsFilter,
  ) => void
  updatePagination: (limit: number, offset: number) => void
  goFlows: () => void
  getExcelConversionLogFiles: (fileUUIDs: string[]) => void
}

type ExcelConversionLogsProps = StateProps & DispatchProps

const useStateProps = (): StateProps => {
  return {
    logs: useSelector(
      (state: RootState) =>
        state.excelConversionLogsState.excelConversionLogs.excelConversionLogs,
    ),
    flows: useSelector(
      (state: RootState) =>
        state.excelConversionFlowsState.excelConversionFlows
          .excelConversionFlows,
    ),
    isLoading: useSelector(
      (state: RootState) =>
        state.excelConversionLogsState.excelConversionLogs.isLoading,
    ),
    count: useSelector(
      (state: RootState) =>
        state.excelConversionLogsState.excelConversionLogs.count,
    ),
    filter: useSelector(
      (state: RootState) =>
        state.excelConversionLogsState.excelConversionLogs.filter,
    ),
    request: useSelector(
      (state: RootState) =>
        state.excelConversionLogsState.excelConversionLogs.request,
    ),
    places: useSelector((state: RootState) => state.placesState.places.places),
    placeNodes: useSelector((state: RootState) =>
      flattenNodes(state.placesState.placeGroups.nodes),
    ),
    isLoadingPlace: useSelector(
      (state: RootState) =>
        state.placesState.places.isLoading &&
        state.placesState.placeGroups.isLoading,
    ),
  }
}

const useDispatchProps = (): DispatchProps => {
  const dispatch = useDispatch()
  const history = useHistory()
  return {
    getLogs: (
      request: GetExcelConversionLogsRequest,
      filter?: GetExcelConversionLogsFilter,
    ) => {
      excelConversionLogsOperations.getExcelConversionLogs(
        dispatch,
        request,
        filter,
      )
    },
    changeLogsPage: (
      page: number,
      pageSize?: number,
      filter?: GetExcelConversionLogsFilter,
    ) => {
      excelConversionLogsOperations.changeExcelConversionLogsPage(
        dispatch,
        page,
        pageSize,
        filter,
      )
    },
    changeLogsSize: (
      pageSize: number,
      filter?: GetExcelConversionLogsFilter,
    ) => {
      excelConversionLogsOperations.changeExcelConversionLogsSize(
        dispatch,
        pageSize,
        filter,
      )
    },
    updatePagination: (limit: number, offset: number) => {
      excelConversionLogsOperations.updatePagination(dispatch, limit, offset)
    },
    getFilterData: () => {
      excelConversionFlowsOperations.getExcelConversionFlows(dispatch, {})
      placesOperations.getPlaces(dispatch, {})
      placesOperations.getPlaceGroups(dispatch)
    },
    goFlows: () => {
      history.push('/excelConversionFlows')
    },
    getExcelConversionLogFiles: (fileUUIDs: string[]) => {
      excelConversionLogsOperations.getExcelConversionLogFiles(
        dispatch,
        fileUUIDs,
      )
    },
  }
}

const ExcelConversionLogsContainer: React.FC = () => {
  const stateProps = useStateProps()
  const dispatchProps = useDispatchProps()
  const props = { ...stateProps, ...dispatchProps }
  return <ExcelConversionLogsScene {...props} />
}

const { Content } = Layout

const ActionRow = styled(Row)`
  margin: 15px;
`

const LogsPagination = styled(Pagination)`
  margin-top: 16px;
  margin-bottom: 16px;
  display: flex;
  justify-content: center;
`

const ExcelConversionLogsScene: React.FC<ExcelConversionLogsProps> = (
  props: ExcelConversionLogsProps,
) => {
  const [chooseLogFileUUIDs, setChooseLogFileUUIDs] = useState<{
    [key: string]: string
  }>({})
  const [logGetTrigger, setLogGetTrigger] = useState<boolean>(false)
  const { filters, setListFilter } = useListFilter()

  useEffect(() => {
    props.getFilterData()
    setLogGetTrigger(!logGetTrigger)
    if (filters) {
      const excelConversionLogsFilter = filters.excelConversionLogs
      props.updatePagination(
        excelConversionLogsFilter?.pagination?.limit ?? 25,
        excelConversionLogsFilter?.pagination?.offset ?? 0,
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setListFilter({
      ...(filters ?? {}),
      excelConversionLogs: {
        pagination: props.request,
        filter: filters?.excelConversionLogs?.filter,
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.request.limit, props.request.offset])

  const currentPage =
    props.request.offset === undefined || props.request.limit === undefined
      ? 1
      : props.request.offset / props.request.limit + 1

  const renderList = () => {
    return (
      <>
        <ActionRow justify="space-between">
          <ExcelConversionLogFilterDropDown
            flows={props.flows}
            places={props.places}
            placeNodes={props.placeNodes}
            getExcelConversionLogs={(filter?: GetExcelConversionLogsFilter) => {
              props.getLogs(props.request, filter)
            }}
            isLoadingPlace={props.isLoadingPlace}
            logGetTrigger={logGetTrigger}
            request={props.request}
          />
          <Button
            disabled={Object.keys(chooseLogFileUUIDs).length === 0}
            onClick={() =>
              props.getExcelConversionLogFiles(
                Object.values(chooseLogFileUUIDs),
              )
            }
            type="primary"
          >
            <DownloadOutlined />
            一括ダウンロード
          </Button>
        </ActionRow>
        {props.isLoading ? (
          <Loading />
        ) : (
          <ExcelConversionLogList
            logs={props.logs}
            goFlows={props.goFlows}
            getExcelConversionLogFiles={props.getExcelConversionLogFiles}
            chooseLogUUIDs={chooseLogFileUUIDs}
            setChooseLogUUIDs={setChooseLogFileUUIDs}
          />
        )}
      </>
    )
  }
  return (
    // TODO: サイドメニュー描写の責務を`Routes.tsx`に移植する
    <GlobalNavigation
      resetPagination={() => {
        setListFilter({
          ...(filters ?? {}),
          excelConversionLogs: {
            pagination: { limit: 25, offset: 0 },
            filter: filters?.excelConversionLogs?.filter,
          },
        })
      }}
    >
      <Layout>
        <Header>
          <HeaderBackButton
            onClick={() => {
              props.goFlows()
            }}
          />
        </Header>
        <Content>{renderList()}</Content>
        <LogsPagination
          showSizeChanger
          current={currentPage}
          pageSizeOptions={['10', '25', '50', '100']}
          pageSize={props.request.limit ?? 25}
          defaultCurrent={1}
          total={props.count}
          onChange={(pageNum, pageSize) =>
            props.changeLogsPage(pageNum, pageSize, props.filter)
          }
          onShowSizeChange={(_, size) =>
            props.changeLogsSize(size, props.filter)
          }
        />
      </Layout>
    </GlobalNavigation>
  )
}

export default ExcelConversionLogsContainer
