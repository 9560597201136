import { Employee, GetEmployeesRequest } from '@ulysses-inc/harami_api_client'
import Redux from 'redux'
import actions from './actions'
import { GetEmployeesFilter } from './types'

const getEmployees = (
  dispatch: Redux.Dispatch,
  request: GetEmployeesRequest,
  filter?: GetEmployeesFilter,
) => {
  dispatch(actions.getEmployees(request, filter))
}

const changeEmployeesPage = (
  dispatch: Redux.Dispatch,
  page: number,
  pageSize?: number,
  filter?: GetEmployeesFilter,
) => {
  dispatch(actions.changeEmployeesPage(page, pageSize, filter))
}

const changeEmployeesSize = (
  dispatch: Redux.Dispatch,
  pageSize: number,
  filter?: GetEmployeesFilter,
) => {
  dispatch(actions.changeEmployeesSize(pageSize, filter))
}

const updatePagination = (
  dispatch: Redux.Dispatch,
  limit: number,
  offset: number,
) => {
  dispatch(actions.updatePagination(limit, offset))
}

const addEmployee = (dispatch: Redux.Dispatch, employee: Employee) => {
  dispatch(actions.addEmployee(employee))
}

const updateEmployee = (
  dispatch: Redux.Dispatch,
  employeeUUID: string,
  employee: Employee,
) => {
  dispatch(actions.updateEmployee(employeeUUID, employee))
}

const deleteEmployee = (dispatch: Redux.Dispatch, employeeUUID: string) => {
  dispatch(actions.deleteEmployee(employeeUUID))
}

const deleteMultipleEmployees = (
  dispatch: Redux.Dispatch,
  employees: Employee[],
  request: GetEmployeesRequest,
  filter?: GetEmployeesFilter,
) => {
  dispatch(actions.deleteMultipleEmployees(employees, request, filter))
}

const changeIsShowEditEmployeeDrawer = (
  dispatch: Redux.Dispatch,
  isShowEditEmployeeDrawer: boolean,
) => {
  dispatch(actions.changeIsShowEditEmployeeDrawer(isShowEditEmployeeDrawer))
}

const updateActiveEmployeeUUID = (
  dispatch: Redux.Dispatch,
  activeEmployeeUUID: string,
) => {
  dispatch(actions.updateActiveEmployeeUUID(activeEmployeeUUID))
}

const updateSegmentIndex = (dispatch: Redux.Dispatch, segmentIndex: string) => {
  dispatch(actions.updateSegmentIndex(segmentIndex))
}

const addMultipleEmployees = (
  dispatch: Redux.Dispatch,
  employees: Employee[],
  request: GetEmployeesRequest,
  filter?: GetEmployeesFilter,
) => {
  dispatch(actions.addMultipleEmployees(employees, request, filter))
}

const updatePlaceNodeWarning = (dispatch: Redux.Dispatch, message: string) => {
  dispatch(actions.updatePlaceNodeWarning(message))
}

export default {
  getEmployees,
  changeEmployeesPage,
  changeEmployeesSize,
  updatePagination,
  addEmployee,
  updateEmployee,
  deleteEmployee,
  deleteMultipleEmployees,
  changeIsShowEditEmployeeDrawer,
  updateActiveEmployeeUUID,
  updateSegmentIndex,
  addMultipleEmployees,
  updatePlaceNodeWarning,
}
