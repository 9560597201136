import {
  TemplateLayoutTypeEnum,
  TemplateNodeTypeEnum,
} from '@ulysses-inc/harami_api_client'
import {
  LogicNodeTable,
  NodeIdPaths,
  ReportNodesDict,
  VisibleGridColumns,
} from 'src/exShared/types/types'
import { isNullish } from 'src/util/isNullish'
import { isRepeatedSectionMaster } from './isRepeatedSectionMaster'

/**
 * 表形式ひな形における、表毎の全 question の node.id の配列を生成する
 *
 * @param allNodes
 * @param idPaths
 * @returns key: originalSectionNodeId, value: keyで示される表における全 question の node.id
 */
const generateQuestionNodeIdsByTable = (
  allNodes: ReportNodesDict,
  idPaths: NodeIdPaths,
) => {
  const questionNodeIdsByTable: { [originalSectionNodeId: number]: number[] } =
    {}

  const allNodesArray = Object.values(allNodes)

  // originalNodeUuid を node.id にマッピングするためのルックアップテーブルを作成
  const nodeIdByOriginalSectionUUID = allNodesArray.reduce<{
    [originalSectionNodeUUID: string]: number
  }>((prev, node) => {
    if (
      node.type !== TemplateNodeTypeEnum.Section ||
      !isRepeatedSectionMaster(node)
    ) {
      return prev
    }

    const uuid = node.uuid
    if (isNullish(uuid)) {
      return prev
    }

    prev[uuid] = node.id
    return prev
  }, {})

  for (const node of allNodesArray) {
    if (node.type !== TemplateNodeTypeEnum.Question) continue

    // idPaths の path の先頭は、表形式のひな形の場合、セクションである
    const sectionNodeInfo = idPaths[node.id]?.[0]
    if (isNullish(sectionNodeInfo)) continue

    const sectionNode = allNodes[sectionNodeInfo.nodeId]
    // originalNodeUuid が存在しないセクションは、自身が originalNode のため、
    // 自身の uuid で補完する
    const originalSectionNodeUUID =
      sectionNode?.section?.originalNodeUuid || sectionNode?.uuid
    if (isNullish(originalSectionNodeUUID)) continue

    const originalSectionNodeId =
      nodeIdByOriginalSectionUUID[originalSectionNodeUUID]
    if (isNullish(originalSectionNodeId)) continue

    let questionIds = questionNodeIdsByTable[originalSectionNodeId]
    if (isNullish(questionIds)) {
      questionIds = []
      questionNodeIdsByTable[originalSectionNodeId] = questionIds
    }
    questionIds.push(node.id)
  }

  return questionNodeIdsByTable
}

/**
 * 表形式のひな形において、条件分岐を考慮して各列が表示対象かどうかを判断するための VisibleGridColumns を生成する
 *
 * NOTE:
 * 現時点では、表形式のひな形を前提とした実装となっている。
 * 行形式のひな形の場合、空のテーブルを作成している。
 *
 * @param layoutType 表形式のひな形でない場合、空のテーブルを作成する
 * @param allNodes
 * @param idPaths
 * @param logicNodeTable
 * @returns
 * @see VisibleGridColumns
 */
export const generateVisibleGridColumns = (
  layoutType: TemplateLayoutTypeEnum,
  allNodes: ReportNodesDict,
  idPaths: NodeIdPaths,
  logicNodeTable: LogicNodeTable,
): VisibleGridColumns => {
  if (layoutType !== TemplateLayoutTypeEnum.Grid) {
    return {}
  }

  const visibleGridColumns: VisibleGridColumns = {}

  const questionNodeIdsByTable = generateQuestionNodeIdsByTable(
    allNodes,
    idPaths,
  )

  for (const _originalSectionNodeId of Object.keys(questionNodeIdsByTable)) {
    const originalSectionNodeId = Number(_originalSectionNodeId)

    const questionNodeIds = questionNodeIdsByTable[originalSectionNodeId] ?? []
    for (const questionNodeId of questionNodeIds) {
      const questionNode = allNodes[questionNodeId]
      if (isNullish(questionNode) || isNullish(questionNode.uuid)) continue

      // この質問の全ての親ロジックが活性である場合のみ、この質問が属する行に起因して、
      // この質問が属する列が表示される
      const isVisible = (idPaths[questionNodeId] ?? [])
        .filter(parentInfo => parentInfo.type === TemplateNodeTypeEnum.Logic)
        .reduce(
          (prev, currentParent) =>
            prev && !!logicNodeTable[currentParent.nodeId],
          // セクション直下の質問の場合、親 Logic は存在しないので、空の場合のデフォルト値: true となる
          true,
        )

      let currentVisibility = visibleGridColumns[originalSectionNodeId]
      if (isNullish(currentVisibility)) {
        currentVisibility = {}
        visibleGridColumns[originalSectionNodeId] = currentVisibility
      }

      // いづれかの行に起因して、この列が表示対象となっていれば良い
      currentVisibility[questionNode.uuid] =
        currentVisibility[questionNode.uuid] || isVisible
    }
  }

  return visibleGridColumns
}
