import { Button, Pagination, Row, Tag, Typography } from 'antd'
import {
  Black,
  IndicatedIssueLabelColor,
  ReportTableDateColumnColor,
  White,
} from 'src/features/theme/KdsThemeColor'
import styled from 'styled-components'

const { Text } = Typography

const tableWidth = 800
const tableMinWidth =
  (width: number) => (props: { theme: { widthPercentage: number } }) =>
    (props.theme.widthPercentage / 100) * width

export const PlaceGroupTreeModalButton = styled(Button)`
  margin-right: 32px;
`

export const ListHeader = styled.div`
  height: 40px;
  min-width: ${tableWidth}px;
  display: flex;
  align-items: center;
  padding-left: 16px;
  font-weight: bold;
  background: ${ReportTableDateColumnColor};
`
export const ReportsPagination = styled(Pagination)`
  margin-top: 16px;
  margin-bottom: 16px;
  display: flex;
  justify-content: center;
`

export const DateReportListItemWrap = styled.div`
  min-width: ${tableWidth}px;
  display: flex;
  align-items: flex-start;
`

export const DateReportListItem = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  min-width: ${tableWidth}px;
  min-height: 46px;
  position: relative;
  border-bottom: 1px solid #f0f0f0;
  background-color: ${White};
`

export const CheckboxColumn = styled.div`
  min-width: ${tableMinWidth(tableWidth)}px;
  width: ${props => props.theme.widthPercentage}%;
  padding-left: 16px;
`

export const ReportNameColumn = styled.div`
  word-break: break-all;
  min-width: ${tableMinWidth(tableWidth)}px;
  width: ${props => props.theme.widthPercentage}%;
  padding-left: 24px;
  display: flex;
  align-items: center;
`

export const ReportPlaceColumn = styled.div`
  word-break: break-all;
  min-width: ${tableMinWidth(tableWidth)}px;
  width: ${props => props.theme.widthPercentage}%;
  padding-left: 16px;
`

export const ReportIsInvalidColumn = styled.div`
  word-break: break-all;
  min-width: ${tableMinWidth(tableWidth)}px;
  width: ${props => props.theme.widthPercentage}%;
  padding-left: 16px;
`

export const ReportUserColumn = styled.div`
  word-break: break-all;
  min-width: ${tableMinWidth(tableWidth)}px;
  width: ${props => props.theme.widthPercentage}%;
  padding-left: 16px;
`

export const ReportUpdatedColumn = styled.div`
  word-break: break-all;
  min-width: ${tableMinWidth(tableWidth)}px;
  width: ${props => props.theme.widthPercentage}%;
  padding-left: 4px;
`

export const ReportStatusColumn = styled.div`
  word-break: break-all;
  min-width: ${tableMinWidth(tableWidth)}px;
  width: ${props => props.theme.widthPercentage}%;
`

export const ReportScoreColumn = styled.div`
  word-break: break-all;
  min-width: ${tableMinWidth(tableWidth)}px;
  width: ${props => props.theme.widthPercentage}%;
  text-align: center;
`

export const ReportStatusContent = styled.div`
  flex-direction: row;
  justify-content: flex-end;
  padding-left: 12px;
  padding-right: 12px;
`

export const ReportStatusText = styled(Text)`
  margin-left: 10px;
`

export const FilterRow = styled(Row)`
  margin-top: 16px;
  margin-bottom: 10px;
  margin-left: 34px;
`

export const ReportsTable = styled(Row)`
  margin: 10px 34px;
  overflow-x: auto;
  justify-content: flex-start;
`

export const ReportsTableWidth = styled.div`
  width: 100%;
  min-width: ${tableWidth}px;
  overflow-x: auto;
  justify-content: flex-start;
`

export const ColumnBackground = styled.div`
  width: 100%;
  min-width: ${tableWidth}px;
  height: 46px;
  display: flex;
  align-items: center;
  flex-direction: row;
  background: ${White};
`

export const CheckboxCol = styled.div`
  min-width: ${tableMinWidth(tableWidth)}px;
  width: ${props => props.theme.widthPercentage}%;
  padding-left: 16px;
`

export const ReportNameCol = styled.div`
  min-width: ${tableMinWidth(tableWidth)}px;
  width: ${props => props.theme.widthPercentage}%;
  font-weight: bold;
  padding-left: 24px;
`

export const ReportPlaceCol = styled.div`
  min-width: ${tableMinWidth(tableWidth)}px;
  width: ${props => props.theme.widthPercentage}%;
  font-weight: bold;
  padding-left: 16px;
`

export const IsInvalidCol = styled.div`
  min-width: ${tableMinWidth(tableWidth)}px;
  width: ${props => props.theme.widthPercentage}%;
  font-weight: bold;
  padding-left: 4px;
`

export const ReportUserCol = styled.div`
  min-width: ${tableMinWidth(tableWidth)}px;
  width: ${props => props.theme.widthPercentage}%;
  font-weight: bold;
  padding-left: 16px;
`

export const ReportUpdatedCol = styled.div`
  min-width: ${tableMinWidth(tableWidth)}px;
  width: ${props => props.theme.widthPercentage}%;
  font-weight: bold;
  padding-left: 8px;
`

export const ReportStatusCol = styled.div`
  min-width: ${tableMinWidth(tableWidth)}px;
  width: ${props => props.theme.widthPercentage}%;
  font-weight: bold;
  padding-left: 12px;
`

export const ReportScoreCol = styled.div`
  min-width: ${tableMinWidth(tableWidth)}px;
  width: ${props => props.theme.widthPercentage}%;
  font-weight: bold;
  text-align: center;
`

export const ActionButtonBackground = styled.div`
  position: absolute;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: row;
  height: 100%;
  right: 0;
`

export const ImproveOptionTag = styled(Tag)`
  color: ${Black};
  background-color: ${IndicatedIssueLabelColor};
  border: none;
  font-size: 12px;
  height: 18px;
  width: 56px;
  display: inline-flex;
  align-items: center;
  margin-left: 8px;
  padding: 3px 4px;
`
