import { Analytics, getAnalytics } from 'firebase/analytics'
import { initializeApp } from 'firebase/app'
import { appEnv } from 'src/util/appEnv'

/**
 * このファイルをインポートすることでfirebase の初期化が行われる。
 * なお、複数回インポートしても初期化処理は1回しか行われないのでご心配なく。
 */

export let firebaseAnalytics: Analytics

if (!appEnv.isLocal) {
  const firebaseConfig = {
    apiKey: import.meta.env['VITE_APP_FIREBASE_API_KEY'],
    authDomain: import.meta.env['VITE_APP_FIREBASE_AUTH_DOMAIN'],
    databaseURL: import.meta.env['VITE_APP_FIREBASE_DATABASE_URL'],
    projectId: import.meta.env['VITE_APP_FIREBASE_PROJECT_ID'],
    storageBucket: import.meta.env['VITE_APP_FIREBASE_STORAGE_BUCKET'],
    messagingSenderId: import.meta.env['VITE_APP_FIREBASE_MESSAGING_SENDER_ID'],
    appId: import.meta.env['VITE_APP_FIREBASE_APP_ID'],
    measurementId: import.meta.env['VITE_APP_FIREBASE_MEASUREMENT_ID'],
  }

  const app = initializeApp(firebaseConfig)
  firebaseAnalytics = getAnalytics(app)
}
