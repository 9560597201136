import { GetTemplatesV2Request, Template } from '@ulysses-inc/harami_api_client'
import { GetTemplatesFilter } from 'src/exShared/types/types'
import { ActionTypes } from '../types'
import { GridVariableTemplatesAction } from './actions'

interface GridVariableTemplatesState {
  templates: Template[]
  count: number
  isLoading: boolean
  error: Error | null
  request: GetTemplatesV2Request
  filter: GetTemplatesFilter
}

const initialGridVariableTemplatesState: GridVariableTemplatesState = {
  templates: [],
  count: 0,
  isLoading: true,
  error: null,
  request: {},
  filter: {},
}

const gridVariableTemplates = (
  state: GridVariableTemplatesState = initialGridVariableTemplatesState,
  action: GridVariableTemplatesAction,
): GridVariableTemplatesState => {
  switch (action.type) {
    case ActionTypes.REQUEST_CHANGE_GRID_VARIABLE_TEMPLATES_PAGE: {
      return {
        ...state,
        request: action.request,
        filter: action.filter,
      }
    }
    case ActionTypes.SUCCESS_GET_GRID_VARIABLE_TEMPLATES: {
      if (action.count === 0) {
        return {
          ...state,
          templates: [],
          isLoading: action.isLoading,
        }
      }
      return {
        ...state,
        isLoading: action.isLoading,
        templates: [...(action.templates ?? [])],
        count: action.count,
      }
    }
    case ActionTypes.ERROR_GET_GRID_VARIABLE_TEMPLATES: {
      return {
        ...state,
        isLoading: action.isLoading,
        error: action.error,
      }
    }
    case ActionTypes.REQUEST_CHANGE_GRID_VARIABLE_TEMPLATES_SIZE: {
      return {
        ...state,
        request: action.request,
      }
    }
    case ActionTypes.UPDATE_PAGINATION: {
      return {
        ...state,
        request: action.request,
      }
    }
    case ActionTypes.RESET_PAGINATION: {
      return {
        ...state,
        request: {},
      }
    }
    case ActionTypes.UPDATE_FILTER: {
      return {
        ...state,
        filter: action.filter,
      }
    }
    default:
      return { ...state }
  }
}

export default gridVariableTemplates
