import { MultipleChoiceSet } from '@ulysses-inc/harami_api_client'
import React from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import multipleChoiceSetsOperations from 'src/state/ducks/multipleChoiceSets/operations'
import {
  HeaderRow,
  MultipleChoiceSetsHeader,
  MultipleChoiceSetsHeaderContainer,
  MultipleChoiceSetsHeaderWrap,
  TableBody,
} from './MultipleChoiceSetList.styled'
import MultipleChoiceSetListRow from './MultipleChoiceSetListRow'

interface OwnProps {
  multipleChoiceSets: MultipleChoiceSet[]
}

const MultipleChoiceSetList: React.FC<OwnProps> = ({ multipleChoiceSets }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const goEditMultipleChoiceSet = (multipleChoiceSetId: string) => {
    history.push(`/multipleChoiceSets/${multipleChoiceSetId}`)
  }
  const deleteMultipleChoiceSet = (multipleChoiceSetId: string) => {
    multipleChoiceSetsOperations.deleteMultipleChoiceSet(
      dispatch,
      multipleChoiceSetId,
    )
  }

  const renderRow = (multipleChoiceSet: MultipleChoiceSet) => (
    <MultipleChoiceSetListRow
      {...{
        multipleChoiceSet,
        goEditMultipleChoiceSet,
        deleteMultipleChoiceSet,
      }}
    />
  )

  return (
    <MultipleChoiceSetsHeaderContainer justify="center">
      <MultipleChoiceSetsHeaderWrap>
        <MultipleChoiceSetsHeader>
          <HeaderRow theme={{ width: '100%' }}>セット選択名</HeaderRow>
        </MultipleChoiceSetsHeader>
        <TableBody
          itemLayout="horizontal"
          dataSource={multipleChoiceSets}
          renderItem={renderRow}
        />
      </MultipleChoiceSetsHeaderWrap>
    </MultipleChoiceSetsHeaderContainer>
  )
}

export default MultipleChoiceSetList
