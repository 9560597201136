import {
  CompanySettingsApi,
  UpdateCompanySettingsRequest,
} from '@ulysses-inc/harami_api_client'
import { call, put, takeEvery } from 'redux-saga/effects'
import * as notificationServiceActions from 'src/features/notificationService/slice'
import BaseClient from '../../middleware/saga/baseClient'
import { handleHTTPError } from '../../middleware/saga/handleHttpError'
import interceptionsActions from '../interceptions/actions'
import { default as actions } from './actions'
import { ActionTypes } from './types'

const baseClient = new BaseClient()

const getCompanySettingsRequest = () => {
  return baseClient
    .getApi(CompanySettingsApi)
    .getCompanySettings()
    .then((res: any) => res)
    .catch(handleHTTPError)
}

const updateCompanySettingsRequest = (req: UpdateCompanySettingsRequest) => {
  return baseClient
    .getApi(CompanySettingsApi)
    .updateCompanySettings(req)
    .then((res: any) => res)
    .catch(handleHTTPError)
}

function* getCompanySettings() {
  try {
    const response = yield call(getCompanySettingsRequest)
    yield put(actions.getSuccessCompanySettings(response))
  } catch (error) {
    yield put(interceptionsActions.handleHttpError(error))
    yield put(actions.getErrorCompanySettings(error))
  }
}

function* updateCompanySettings(
  action: ReturnType<typeof actions.updateCompanySettings>,
) {
  try {
    yield call(updateCompanySettingsRequest, {
      companySettings: action.companySettings,
    })
    yield put(actions.updateSuccessCompanySettings())
    yield put(
      notificationServiceActions.showNotification({
        message: 'アプリ設定の更新に成功しました',
      }),
    )
  } catch (error) {
    yield put(interceptionsActions.handleHttpError(error))
    yield put(actions.updateErrorCompanySettings(error))
  }
}

const sagas = [
  takeEvery(ActionTypes.REQUEST_GET_COMPANY_SETTINGS, getCompanySettings),
  takeEvery(ActionTypes.REQUEST_UPDATE_COMPANY_SETTINGS, updateCompanySettings),
]

export default sagas
