import {
  AddPlaceV2Request,
  GetPlacesRequest,
  PlaceNode,
  PlaceNodeTypeEnum,
  UpdatePlaceV2Request,
} from '@ulysses-inc/harami_api_client'
import { Input, Typography } from 'antd'
import { useFormik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { UUID } from 'src/exShared/util/uuid'
import { Danger } from 'src/features/theme/KdsThemeColor'
import Yup from 'src/features/validation/yup'
import placesOperations from 'src/state/ducks/places/operations'
import { RootState } from 'src/state/store'
import FooterButton from 'src/views/components/drawer/FooterButton'
import styled from 'styled-components'

interface StateProps {
  activePlace?: PlaceNode
  isLoadingPlace: boolean
  request: GetPlacesRequest
}

interface DispatchProps {
  changeIsShowEditPlaceDrawer: (isShow: boolean) => void
  updatePlace: (request: UpdatePlaceV2Request) => void
  addPlaceNode: (request: AddPlaceV2Request) => void
  changeUpdatedPlacesFlag: (edited: boolean) => void
}

type EditPlaceProps = StateProps & DispatchProps

interface PlaceForm {
  nodeId?: string
  name: string
}

const { Text } = Typography

const PlacePageHeader = styled.div`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
`

const PlaceTitle = styled.div`
  margin-top: 16px;
  margin-bottom: 5px;
`

const PlaceText = styled(Text)`
  margin-left: 3px;
`

const ErrorText = styled(Text)`
  color: ${Danger};
`

const EditPlace = () => {
  // react-reduxのconnect関数の利用をやめた際のなごり
  // (今後このコンポーネントを触ることがあればそのときに解体する)
  const exProps = useProps()

  const { handleChange, handleSubmit, values, errors } = useFormik<PlaceForm>({
    initialValues: {
      nodeId: exProps.activePlace?.uuid || undefined,
      name: exProps.activePlace?.place?.name || '',
    },
    validationSchema: validationSchema,
    onSubmit: (form, formikHelpers) => {
      const placeNode = {
        id: exProps.activePlace ? exProps.activePlace.id : 0,
        uuid: exProps.activePlace ? exProps.activePlace.uuid : UUID(),
        type: PlaceNodeTypeEnum.Place,
        isRootNode: exProps.activePlace ? exProps.activePlace.isRootNode : 0,
        place: {
          id: exProps.activePlace?.place ? exProps.activePlace.place.id : 0,
          name: form.name,
        },
        parentNodes: exProps.activePlace ? exProps.activePlace.parentNodes : [],
        nodes: exProps.activePlace ? exProps.activePlace.nodes : [],
      }

      if (exProps.activePlace !== undefined) {
        exProps.updatePlace({
          placeNodeId: placeNode.uuid,
          placeNode: placeNode,
        })
      } else {
        exProps.addPlaceNode({
          placeNode: placeNode,
        })
      }
      formikHelpers.resetForm()
    },
  })

  return (
    <>
      <PlacePageHeader>
        {exProps.activePlace ? '現場の編集' : '現場の追加'}
      </PlacePageHeader>
      <PlaceTitle>
        <PlaceText>現場名</PlaceText>
      </PlaceTitle>
      <Input
        onChange={handleChange('name')}
        defaultValue={exProps.activePlace?.place?.name ?? ''}
        value={values.name}
      />
      <ErrorText>{errors.name}</ErrorText>
      <FooterButton
        spinning={exProps.isLoadingPlace}
        onCancel={() => exProps.changeIsShowEditPlaceDrawer(false)}
        onSubmit={() => {
          handleSubmit()
          exProps.changeUpdatedPlacesFlag(true)
        }}
      />
    </>
  )
}

const useProps = (): EditPlaceProps => {
  const dispatch = useDispatch()
  const state = useSelector((state: RootState) => state)

  const activePlaceId = state.placesState.places.activePlaceId

  return {
    // -----------------------------------
    // StateProps
    // -----------------------------------

    activePlace: activePlaceId
      ? state.placesState.places.places.find(
          place => place.uuid === activePlaceId,
        )
      : undefined,
    isLoadingPlace: state.placesState.places.isLoading,
    request: state.placesState.places.request,

    // -----------------------------------
    // DispatchProps
    // -----------------------------------

    addPlaceNode: (request: AddPlaceV2Request) => {
      placesOperations.addPlace(dispatch, request)
    },
    updatePlace: (request: UpdatePlaceV2Request) => {
      placesOperations.updatePlace(dispatch, request)
    },
    changeIsShowEditPlaceDrawer: (isShow: boolean) => {
      placesOperations.changeIsShowEditPlaceDrawer(dispatch, isShow)
    },
    changeUpdatedPlacesFlag: (edited: boolean) => {
      placesOperations.changeUpdatedPlacesFlag(dispatch, edited)
    },
  }
}

const validationSchema = Yup.object().shape({
  name: Yup.string().required().label('現場名'),
})

export default EditPlace
