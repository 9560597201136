import {
  AddScheduleRequest,
  DeleteScheduleRequest,
  DeleteSchedulesRequest,
  GetScheduleRequest,
  GetSchedulesResponse,
  GetSchedulesV2Request,
  GetTemplatesResponse,
  GetTemplatesV2Request,
  Schedule,
  SchedulesApi,
  TemplatesApi,
  UpdateScheduleRequest,
} from '@ulysses-inc/harami_api_client'
import { call, put, takeEvery } from 'redux-saga/effects'
import * as notificationServiceActions from 'src/features/notificationService/slice'
import { history } from 'src/state/store'
import BaseClient from '../../middleware/saga/baseClient'
import {
  HTTPError,
  handleHTTPError,
} from '../../middleware/saga/handleHttpError'
import { downloadFile } from '../download/sagas'
import interceptionsActions from '../interceptions/actions'
import { default as actions } from './actions'
import { ActionTypes } from './types'

const baseClient = new BaseClient()

const getScheduleRequest = (req: GetScheduleRequest) => {
  return baseClient
    .getApi(SchedulesApi)
    .getSchedule(req)
    .then(schedules => schedules)
    .catch(handleHTTPError)
}

const getSchedulesV2Request = (req: GetSchedulesV2Request) => {
  return baseClient
    .getApi(SchedulesApi)
    .getSchedulesV2(req)
    .then(schedules => schedules)
    .catch(handleHTTPError)
}

const addScheduleRequest = (req: AddScheduleRequest) => {
  return baseClient.getApi(SchedulesApi).addSchedule(req).catch(handleHTTPError)
}

const updateScheduleRequest = (req: UpdateScheduleRequest) => {
  return baseClient
    .getApi(SchedulesApi)
    .updateSchedule(req)
    .catch(handleHTTPError)
}

const deleteScheduleRequest = (req: DeleteScheduleRequest) => {
  return baseClient
    .getApi(SchedulesApi)
    .deleteSchedule(req)
    .catch(handleHTTPError)
}

const deleteSchedulesRequest = (req: DeleteSchedulesRequest) => {
  return baseClient
    .getApi(SchedulesApi)
    .deleteSchedules(req)
    .catch(handleHTTPError)
}

const getTemplatesV2Request = (req: GetTemplatesV2Request) => {
  return baseClient
    .getApi(TemplatesApi)
    .getTemplatesV2(req)
    .then(templates => templates)
    .catch(handleHTTPError)
}

function* getSchedule(action: ReturnType<typeof actions.getSchedule>) {
  try {
    const schedule: Schedule = yield call(getScheduleRequest, {
      scheduleId: action.scheduleId,
    })
    yield put(actions.getSuccessSchedule(schedule))
  } catch (error) {
    yield put(interceptionsActions.handleHttpError(error as HTTPError))
    yield put(actions.getErrorSchedule(error as Error))
  }
}

function* getSchedules(action: ReturnType<typeof actions.getSchedules>) {
  try {
    const params: GetSchedulesV2Request = {
      ...action.request,
      scheduleFilter: { ...action.filter },
    }
    const response: GetSchedulesResponse = yield call(
      getSchedulesV2Request,
      params,
    )
    yield put(actions.getSuccessSchedules(response))
  } catch (error) {
    yield put(interceptionsActions.handleHttpError(error as HTTPError))
    yield put(actions.getErrorSchedules(error as Error))
  }
}

function* addSchedule(action: ReturnType<typeof actions.addSchedule>) {
  try {
    yield call(addScheduleRequest, { schedule: action.schedule })
    yield put(actions.addSuccessSchedule())
    const response: GetSchedulesResponse = yield call(getSchedulesV2Request, {
      scheduleFilter: {},
    })
    yield put(actions.getSuccessSchedules(response))
    yield put(
      notificationServiceActions.showNotification({
        message: 'スケジュールの作成に成功しました',
      }),
    )
    history.push(`/schedules`)
  } catch (error) {
    yield put(interceptionsActions.handleHttpError(error as HTTPError))
    yield put(actions.addErrorSchedule(error as Error))
  }
}

function* updateSchedule(action: ReturnType<typeof actions.updateSchedule>) {
  try {
    yield call(updateScheduleRequest, {
      schedule: action.schedule,
      scheduleId: action.scheduleId,
    })
    yield put(actions.getSuccessSchedule(action.schedule))
    yield put(
      notificationServiceActions.showNotification({
        message: 'スケジュールの更新に成功しました',
      }),
    )
    yield put(actions.updateSuccessSchedule())
  } catch (error) {
    yield put(interceptionsActions.handleHttpError(error as HTTPError))
    yield put(actions.updateErrorSchedule(error as Error))
  }
}

function* copySchedule(action: ReturnType<typeof actions.copySchedule>) {
  try {
    const response: Schedule = yield call(addScheduleRequest, {
      schedule: action.schedule,
    })
    yield put(actions.copySuccessSchedule())
    yield put(
      notificationServiceActions.showNotification({
        message: 'スケジュールのコピーに成功しました',
      }),
    )
    history.replace(`/schedules/${response.uuid}`)
  } catch (error) {
    yield put(interceptionsActions.handleHttpError(error as HTTPError))
    yield put(actions.copyErrorSchedule(error as Error))
  }
}

function* deleteSchedule(action: ReturnType<typeof actions.deleteSchedule>) {
  try {
    yield call(deleteScheduleRequest, { scheduleId: action.scheduleId })
    yield put(actions.deleteSuccessSchedule())

    const params: GetSchedulesV2Request = {
      ...action.request,
      scheduleFilter: { ...action.filter },
    }
    const response: GetSchedulesResponse = yield call(
      getSchedulesV2Request,
      params,
    )
    yield put(actions.getSuccessSchedules(response))
  } catch (error) {
    yield put(interceptionsActions.handleHttpError(error as HTTPError))
    yield put(actions.deleteErrorSchedule(error as Error))
  }
}

function* deleteSchedules(action: ReturnType<typeof actions.deleteSchedules>) {
  try {
    yield call(deleteSchedulesRequest, {
      deleteSchedules: { scheduleUUIDs: action.scheduleUUIDs },
    })
    yield put(
      notificationServiceActions.showNotification({
        message: 'スケジュールの一括削除に成功しました',
      }),
    )

    const params: GetSchedulesV2Request = {
      ...action.request,
      scheduleFilter: { ...action.filter },
    }
    const response: GetSchedulesResponse = yield call(
      getSchedulesV2Request,
      params,
    )
    yield put(actions.getSuccessSchedules(response))
  } catch (error) {
    yield put(interceptionsActions.handleHttpError(error as HTTPError))
  }
}

function* downloadScheduleQrCodes(
  action: ReturnType<typeof actions.downloadScheduleQrCodes>,
) {
  try {
    const body = JSON.stringify({ scheduleUUIDs: action.scheduleUUIDs })
    yield call(downloadFile, 'schedules/qrcodes', body)
  } catch (error) {
    yield put(interceptionsActions.handleHttpError(error as HTTPError))
  }
}

function* getSchedulesTemplates() {
  try {
    const params: GetTemplatesV2Request = {
      templateFilter: {},
    }
    const { templates }: GetTemplatesResponse = yield call(
      getTemplatesV2Request,
      params,
    )
    yield put(actions.getSuccessSchedulesTemplates(templates ?? []))
  } catch (error) {
    yield put(interceptionsActions.handleHttpError(error as HTTPError))
    yield put(actions.getErrorSchedulesTemplates(error as Error))
  }
}

const sagas = [
  takeEvery(ActionTypes.REQUEST_GET_SCHEDULE, getSchedule),
  takeEvery(ActionTypes.REQUEST_GET_SCHEDULES, getSchedules),
  takeEvery(ActionTypes.REQUEST_CHANGE_SCHEDULES_PAGE, getSchedules),
  takeEvery(ActionTypes.REQUEST_CHANGE_SCHEDULES_SIZE, getSchedules),
  takeEvery(ActionTypes.REQUEST_ADD_SCHEDULE, addSchedule),
  takeEvery(ActionTypes.REQUEST_UPDATE_SCHEDULE, updateSchedule),
  takeEvery(ActionTypes.REQUEST_COPY_SCHEDULE, copySchedule),
  takeEvery(ActionTypes.REQUEST_DELETE_SCHEDULE, deleteSchedule),
  takeEvery(ActionTypes.REQUEST_DELETE_SCHEDULES, deleteSchedules),
  takeEvery(ActionTypes.DOWNLOAD_SCHEDULE_QR_CODES, downloadScheduleQrCodes),
  takeEvery(ActionTypes.REQUEST_GET_SCHEDULES_TEMPLATES, getSchedulesTemplates),
]

export default sagas
