import { ApprovalFlow } from '@ulysses-inc/harami_api_client'
import Redux from 'redux'
import actions from './actions'

const getApprovalFlow = (dispatch: Redux.Dispatch, approvalFlowId: string) => {
  dispatch(actions.getApprovalFlow(approvalFlowId))
}

const getApprovalFlows = (dispatch: Redux.Dispatch) => {
  dispatch(actions.getApprovalFlows())
}

const addApprovalFlow = (
  dispatch: Redux.Dispatch,
  approvalFlow: ApprovalFlow,
) => {
  dispatch(actions.addApprovalFlow(approvalFlow))
}

const updateApprovalFlow = (
  dispatch: Redux.Dispatch,
  approvalFlowId: string,
  approvalFlow: ApprovalFlow,
) => {
  dispatch(actions.updateApprovalFlow(approvalFlowId, approvalFlow))
}

const deleteApprovalFlow = (
  dispatch: Redux.Dispatch,
  approvalFlowId: string,
) => {
  dispatch(actions.deleteApprovalFlow(approvalFlowId))
}

export default {
  getApprovalFlow,
  getApprovalFlows,
  addApprovalFlow,
  updateApprovalFlow,
  deleteApprovalFlow,
}
