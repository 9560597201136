import {
  MultipleChoice,
  NumberCondition,
  ReportNodeSchema,
  ReportQuestion,
  ResponseTypeEnum,
  TemplateNodeTypeEnum,
} from '@ulysses-inc/harami_api_client'
import getCalcNumberLogicType from 'src/exShared/hooks/reports/getCalcNumberLogicType'
import getChildNodes from 'src/exShared/hooks/reports/getChildNodes'
import getLogicNodes from 'src/exShared/hooks/reports/getLogicNodes'
import { isDefined } from 'src/util/idDefined'
import { isNullish } from 'src/util/isNullish'

/**
 * 「条件分岐の状態」を考慮して質問をフィルタリングする
 * @param nodes
 */
const getActiveQuestions = (nodes: {
  [key: number]: ReportNodeSchema
}): ReportQuestion[] => {
  const allNodeArray = Object.values(nodes)
  const allLogicNodes = allNodeArray.filter(
    (node: ReportNodeSchema) =>
      node.type === TemplateNodeTypeEnum.Logic && !!node.logic,
  )
  const allQuestionNodes = allNodeArray.filter((node: ReportNodeSchema) =>
    isQuestionNode(node),
  )

  const invisibleQuestionNodes = getInvisibleQuestionsFromLogics(
    allLogicNodes,
    allQuestionNodes,
    nodes,
  )
  const visibleQuestions = allQuestionNodes
    .filter((node: ReportNodeSchema) => !node.hide?.hide)
    .filter(
      (node: ReportNodeSchema) =>
        !invisibleQuestionNodes.some(
          (invisible: ReportNodeSchema) => invisible.id === node.id,
        ),
    )
    .map((node: ReportNodeSchema) => node.question)
    .filter(isDefined)

  return visibleQuestions
}

const getInvisibleQuestionsFromLogics = (
  logicNodes: ReportNodeSchema[],
  allQuestionNodes: ReportNodeSchema[],
  allNodes: { [key: number]: ReportNodeSchema },
): ReportNodeSchema[] => {
  const questionNodes = logicNodes
    .map((logicNode: ReportNodeSchema) => {
      const parentQuestion = allQuestionNodes.find(
        (question: ReportNodeSchema) => question.nodes.includes(logicNode.id),
      )

      if (!parentQuestion) {
        return []
      }

      if (!hasResponseAnswered(parentQuestion)) {
        return getAllChildQuestionNodes(parentQuestion.nodes, allNodes)
      }

      // Logic配下の通らないnodesを取得
      return getInvisibleLogicQuestionNodes(parentQuestion, allNodes)
    })
    .flat()

  return questionNodes
}

const getAllChildQuestionNodes = (
  nodes: number[],
  allNodes: { [key: number]: ReportNodeSchema },
): ReportNodeSchema[] => {
  return getLogicNodes(nodes, allNodes)
    .map((logicNode: ReportNodeSchema) =>
      getChildNodes(logicNode.nodes, allNodes),
    )
    .flat()
    .filter((node: ReportNodeSchema) => isQuestionNode(node))
}

const getInvisibleLogicQuestionNodes = (
  parentQuestion: ReportNodeSchema,
  allNodes: { [key: number]: ReportNodeSchema },
) => {
  const invisibleLogicNodes = getInvisibleLogicNodes(parentQuestion, allNodes)
  const invisibleNodes = invisibleLogicNodes
    .map((logicNode: ReportNodeSchema) =>
      getChildNodes(logicNode.nodes, allNodes),
    )
    .flat()

  return invisibleNodes.filter((node: ReportNodeSchema) => isQuestionNode(node))
}

const isQuestionNode = (node: ReportNodeSchema): boolean => {
  return node.type === TemplateNodeTypeEnum.Question
}

const hasResponseAnswered = (node: ReportNodeSchema): boolean => {
  return !isNullish(node.question?.responseAnswer)
}

const getInvisibleLogicNodes = (
  node: ReportNodeSchema,
  allNodes: { [key: number]: ReportNodeSchema },
): ReportNodeSchema[] => {
  const mapToLogicNodes = () =>
    (node.nodes ?? [])
      .map(nodeId => allNodes[nodeId])
      .filter(isDefined)
      .filter(node => node.type === TemplateNodeTypeEnum.Logic && !!node.logic)

  switch (node.question?.responseType) {
    case ResponseTypeEnum.MULTIPLE_CHOICE: {
      const response = node.question?.responseAnswer?.multipleChoice?.response
      return mapToLogicNodes().filter((childNode: ReportNodeSchema) => {
        if (childNode.logic?.responseMultipleChoices) {
          return !childNode.logic.responseMultipleChoices.some(
            (multipleChoice: MultipleChoice) =>
              multipleChoice.response === response,
          )
        }
        return false
      })
    }
    case ResponseTypeEnum.NUMBER: {
      const childLogicNodes = mapToLogicNodes()
      const numberValue =
        node.question?.responseAnswer?.numberValue?.numberValue
      // 未回答の場合は、どちらの分岐も invisible
      // 0 の場合を除外してはいけないことに注意
      if (isNullish(numberValue)) {
        return childLogicNodes
      }
      const responseNumber = node.question?.responseNumbers?.[0]
      const numberLogicType = getCalcNumberLogicType(
        numberValue,
        responseNumber,
      )

      return childLogicNodes.filter((logicNode: ReportNodeSchema) => {
        if (logicNode.logic?.numberConditions) {
          return !logicNode.logic.numberConditions.some(
            (numberCondition: NumberCondition) =>
              numberCondition.logicType === numberLogicType,
          )
        }
        return false
      })
    }
    default:
      return []
  }
}

export default getActiveQuestions
