import { StyleSheet, Text, View } from '@react-pdf/renderer'
import {
  ReportNodeSchema,
  TemplateNodeTypeEnum,
} from '@ulysses-inc/harami_api_client'
import React from 'react'
import ReportSummaryQuestion from '../reportSummaryQuestion/ReportSummaryQuestion'

interface OwnProps {
  deviatedAnswers: Array<ReportNodeSchema>
  nodes: { [key: number]: ReportNodeSchema }
}

interface MergeProps {
  getSectionName: (id: number) => string
}

type DeviatedAnswersProps = OwnProps & MergeProps

const DeviatedAnswersContainer: React.FC<OwnProps> = (_props: OwnProps) => {
  const mergeProps: MergeProps = {
    getSectionName: (id: number) =>
      Object.values(_props.nodes).find(node => node.nodes.includes(id))?.section
        ?.name ?? '',
  }
  const props = { ..._props, ...mergeProps }
  return <DeviatedAnswers {...props} />
}

const styles = StyleSheet.create({
  titleWrap: {
    width: '100%',
    marginBottom: 5,
    borderBottom: 0.5,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  title: {
    fontSize: 14,
    width: '30%',
  },
  deviatedAnswersCount: {
    fontSize: 8,
    width: '30%',
    textAlign: 'right',
    paddingVertical: 1,
    marginTop: 6,
  },
  sectionWrap: {
    marginTop: 5,
    marginBottom: 5,
  },
  sectionNameWrap: {
    marginTop: 5,
  },
  sectionName: {
    fontSize: 8,
    color: '#656565',
  },
})

const DeviatedAnswers: React.FC<DeviatedAnswersProps> = (
  props: DeviatedAnswersProps,
) => {
  return (
    <View style={styles.sectionWrap}>
      <View style={styles.titleWrap}>
        <Text style={styles.title}>逸脱項目</Text>
        <View style={styles.deviatedAnswersCount}>
          <Text>逸脱項目数: {props.deviatedAnswers.length}</Text>
        </View>
      </View>
      {props.deviatedAnswers.map((node: ReportNodeSchema, index: number) => {
        const sectionName = props.getSectionName(node.id)
        if (node.type === TemplateNodeTypeEnum.Question)
          return (
            <View key={node.id}>
              {sectionName !== '' && (
                <View style={styles.sectionNameWrap}>
                  <Text style={styles.sectionName}>{sectionName}</Text>
                </View>
              )}
              <ReportSummaryQuestion
                node={node}
                nodes={props.nodes}
                isFirst={index === 0}
                isSectionPrev={sectionName !== ''}
                isShowLogic={false}
              />
            </View>
          )
        return null
      })}
    </View>
  )
}

export default DeviatedAnswersContainer
