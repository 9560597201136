import { DownOutlined, UpOutlined } from '@ant-design/icons'
import { Button, Dropdown, Radio, Select, Space } from 'antd'
import { RadioChangeEvent } from 'antd/lib/radio'
import React, { useState } from 'react'
import { BorderColor, White } from 'src/features/theme/KdsThemeColor'
import styled from 'styled-components'
import { TemplatesSortOrder } from './templatesSortOrder'

const { Option } = Select

const TableCell = styled.div`
  display: table-cell;
  border: 1px solid ${BorderColor};
  background: ${White};
  vertical-align: middle;
`
const FilterButton = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  cursor: pointer;
`

const RadioButtonArea = styled.div`
  margin-top: 12px;
`

const ClearButtonArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  align-self: center;
  flex: 1 1 0%;
  margin-top: 12px;
`

const UpOutlinedIcon = styled(UpOutlined)`
  color: rgba(0, 0, 0, 0.65);
`

const DownOutlinedIcon = styled(DownOutlined)`
  color: rgba(0, 0, 0, 0.65);
`

const SelectFilterTableCell = styled(TableCell)`
  min-width: ${({ theme }) => theme.minWidth};
  background-color: ${({ theme }) => theme.backgroundColor};
  height: 32px;
`

const DropDownTableCell = styled(TableCell)`
  width: 200px;
  background-color: ${White};
  padding: 16px;
`

interface OwnProps {
  getSortedTemplates: (
    isClear: boolean,
    sortColumn: string,
    order: string,
  ) => void
  templatesSortOrder?: TemplatesSortOrder
}

const SortColumn = {
  TEMPLATE_NAME: 'template_name',
  CREATED_AT: 'created_at',
  UPDATED_AT: 'updated_at',
  EMPTY: '',
} as const

const SortOrder = {
  ASC: 'asc',
  DESC: 'desc',
} as const

type SortOrderValue = (typeof SortOrder)[keyof typeof SortOrder]

type SortDisplayValue = { name: string; asc: string; desc: string }
type SortDisplayObject = { [key: string]: SortDisplayValue }

const sortDisplay: SortDisplayObject = {
  template_name: {
    name: 'ひな形名',
    asc: '五十音順',
    desc: '五十音 逆順',
  },
  created_at: {
    name: '作成日時',
    asc: '古い順',
    desc: '新しい順',
  },
  updated_at: {
    name: '更新日時',
    asc: '古い順',
    desc: '新しい順',
  },
}

const TemplatesSortDropDown: React.FC<OwnProps> = (props: OwnProps) => {
  const [isOpen, setIsOpen] = useState(false)

  const sortColumn = props.templatesSortOrder?.sortColumn ?? SortColumn.EMPTY
  const order = props.templatesSortOrder?.sortOrder ?? SortOrder.ASC
  const [isClear, setIsClear] = useState(sortColumn === SortColumn.EMPTY)

  const changeSortColumn = (val: string) => {
    setIsClear(false)
    props.getSortedTemplates(false, val, order)
  }

  const clearFilter = () => {
    setIsClear(true)
    props.getSortedTemplates(true, SortColumn.EMPTY, SortOrder.ASC)
  }

  const changeSortOrder = (e: SortOrderValue) => {
    setIsClear(false)
    props.getSortedTemplates(false, sortColumn, e)
  }

  const groundArea = (
    <SelectFilterTableCell theme={{ width: '160px' }}>
      <FilterButton>
        <div style={{ flex: 1, marginLeft: 12 }}>
          並び替え
          {!isClear &&
            `: ${sortDisplay[sortColumn].name}(${sortDisplay[sortColumn][order]})`}
        </div>
        <div style={{ minWidth: 20, marginRight: 12, marginLeft: 12 }}>
          {isOpen ? <UpOutlinedIcon /> : <DownOutlinedIcon />}
        </div>
      </FilterButton>
    </SelectFilterTableCell>
  )

  const hoverArea = (
    <div style={{ display: 'flex' }}>
      <DropDownTableCell>
        <Select
          style={{ width: '100%' }}
          value={sortColumn === SortColumn.EMPTY ? undefined : sortColumn}
          optionFilterProp="label"
          onChange={(val: string) => changeSortColumn(val)}
          placeholder="列名を選択"
        >
          {Object.keys(sortDisplay).map((key: string, index: number) => (
            <Option key={index} value={key} label={key}>
              {sortDisplay[key].name}
            </Option>
          ))}
        </Select>
        {!isClear && (
          <RadioButtonArea>
            <Radio.Group
              value={order}
              onChange={(e: RadioChangeEvent) =>
                changeSortOrder(e.target.value)
              }
            >
              <Space direction="vertical">
                {sortColumn === SortColumn.TEMPLATE_NAME ? (
                  <>
                    <Radio value={SortOrder.ASC}>
                      {sortDisplay[sortColumn].asc}
                    </Radio>
                    <Radio value={SortOrder.DESC}>
                      {sortDisplay[sortColumn].desc}
                    </Radio>
                  </>
                ) : (
                  <>
                    <Radio value={SortOrder.DESC}>
                      {sortDisplay[sortColumn].desc}
                    </Radio>
                    <Radio value={SortOrder.ASC}>
                      {sortDisplay[sortColumn].asc}
                    </Radio>
                  </>
                )}
              </Space>
            </Radio.Group>
          </RadioButtonArea>
        )}
        <ClearButtonArea>
          <Button onClick={clearFilter}>クリア</Button>
        </ClearButtonArea>
      </DropDownTableCell>
    </div>
  )

  return (
    <Dropdown
      dropdownRender={() => hoverArea}
      trigger={['click']}
      open={isOpen}
      onOpenChange={setIsOpen}
    >
      {groundArea}
    </Dropdown>
  )
}

export default TemplatesSortDropDown
