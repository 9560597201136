import { ExcelConversionLogStatusEnum } from '@ulysses-inc/harami_api_client'

export const getStatusName = (status: ExcelConversionLogStatusEnum) => {
  switch (status) {
    case ExcelConversionLogStatusEnum.FAILED:
      return '失敗'
    case ExcelConversionLogStatusEnum.SUCCESS:
      return '成功'
    default:
      return ''
  }
}
