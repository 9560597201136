import { Row } from '../types'

export const createEmptyRow = () => {
  const row: Row = {
    /** 適用開始日 */
    from: {
      dateValue: '',
      errors: [],
    },
    /** 適用終了日 */
    through: {
      dateValue: '',
      errors: [],
    },
    /** セクション名 */
    sectionName: {
      name: '',
      errors: [],
    },
    /** 取り込みセクション */
    variableSection: {
      name: '',
      errors: [],
    },
    /** 取り込み項目 1〜 */
    values: {},
    hasErrors: false,
    hasChanged: false,
  }
  return row
}
