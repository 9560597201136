import { css } from '@emotion/react'
import { InputNumber, Row, Select, Switch, Typography } from 'antd'
import { ComponentProps, FC } from 'react'
import { Danger, Gray6 } from 'src/features/theme/KdsThemeColor'

export const ButtonGroup: FC<ComponentProps<'div'>> = props => (
  <div
    {...props}
    css={css`
      display: flex;
      justify-content: flex-end;
    `}
  />
)

export const DecimalPointSwitch: FC<ComponentProps<typeof Switch>> = props => (
  <Switch
    {...props}
    css={css`
      &.ant-switch {
        margin-right: 12px;
      }
    `}
  />
)

export const RuleInputsRow: FC<ComponentProps<typeof Row>> = props => (
  <Row
    {...props}
    css={css`
      justify-content: flex-start;
      align-items: center;
      margin-top: 16px;
      margin-bottom: 8px;
    `}
  />
)

export const InputHour: FC<ComponentProps<typeof InputNumber>> = props => (
  <InputNumber
    {...props}
    css={css`
      width: 72px;
      margin-right: 4px;
    `}
  />
)

export const HourLabel: FC<ComponentProps<'label'>> = props => (
  <label
    {...props}
    css={css`
      margin-right: 12px;
    `}
  />
)

export const InputMinutes: FC<ComponentProps<typeof InputNumber>> = props => (
  <InputNumber
    {...props}
    css={css`
      width: 72px;
      margin-right: 4px;
    `}
  />
)

export const MinutesLabel: FC<ComponentProps<'label'>> = props => (
  <label
    {...props}
    css={css`
      margin-right: 12px;
    `}
  />
)

export const RuleTypeSelect: FC<ComponentProps<typeof Select>> = props => (
  <Select
    {...props}
    css={css`
      && {
        width: 72px;
      }
    `}
  />
)

export const AnnotationRow: FC<ComponentProps<typeof Row>> = props => (
  <Row
    {...props}
    css={css`
      justify-content: flex-start;
      flex-direction: column;
      margin-bottom: 8px;
    `}
  />
)

export const AnnotationText: FC<
  ComponentProps<typeof Typography.Text>
> = props => (
  <Typography.Text
    {...props}
    css={css`
      color: ${Gray6};
    `}
  />
)

export const ErrorText: FC<ComponentProps<typeof Typography.Text>> = props => (
  <Typography.Text
    {...props}
    css={css`
      color: ${Danger};
    `}
  />
)
