interface PdfThumbnailProps {
  fileName: string
  url: string
}

export const PdfThumbnail: React.FC<PdfThumbnailProps> = (
  props: PdfThumbnailProps,
) => {
  const fileURL = props.url
  const fileName = props.fileName
  const thumbnailURL = fileURL?.replace('.pdf', '.png')

  return (
    <a href={fileURL} target="_blank" rel="noopener noreferrer">
      {fileName && (
        <div
          style={{
            height: '10%',
            width: '100%',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {fileName}
        </div>
      )}
      <img
        src={thumbnailURL}
        style={{
          width: '50%',
          height: '90%',
          objectFit: 'contain',
          position: 'absolute',
          left: 1,
        }}
      />
    </a>
  )
}
