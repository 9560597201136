import { BarsOutlined, PlusOutlined } from '@ant-design/icons'
import { Layout } from 'antd'
import { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Header } from 'src/components/header/Header'
import { HeaderTitle } from 'src/components/header/HeaderTitle'
import ExcelConversionFlowList from 'src/features/excelConversionFlows/ExcelConversionFlowList'
import {
  ActionRow,
  AddFlowButton,
  DownloadListButton,
  FlowsPagination,
} from 'src/features/excelConversionFlows/ExcelConversionFlows.style'
import { useLimitedFeatureAvailability } from 'src/features/featureAvailability/useLimitedFeatureAvailability'
import {
  changeExcelConversionFlowsPage as changeExcelConversionFlowsPageAction,
  changeExcelConversionFlowsSize as changeExcelConversionFlowsSizeAction,
  getExcelConversionFlows as getExcelConversionFlowsAciton,
} from 'src/state/ducks/excelConversionFlows/actions'
import { RootState } from 'src/state/store'
import Loading from '../../components/loading/Loading'

const { Content } = Layout

const ExcelConversionFlowsContainer: FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const { isFeatureAvailable } = useLimitedFeatureAvailability()

  const changeFlowsPage = (page: number, pageSize?: number) => {
    dispatch(changeExcelConversionFlowsPageAction(page, pageSize))
  }

  const changeFlowsSize = (pageSize: number) => {
    dispatch(changeExcelConversionFlowsSizeAction(pageSize))
  }

  const goAddFlow = () => {
    history.push('/excelConversionFlows/add')
  }

  const goLogs = () => {
    history.push('/excelConversionLogs')
  }

  const { excelConversionFlows, isLoading, count, request } = useSelector(
    (state: RootState) => state.excelConversionFlowsState.excelConversionFlows,
  )

  useEffect(() => {
    dispatch(getExcelConversionFlowsAciton(request))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const currentPage =
    request.offset === undefined || request.limit === undefined
      ? 1
      : request.offset / request.limit + 1

  return (
    <Layout>
      <Header>
        <HeaderTitle title="変換フロー" />
      </Header>
      <Content>
        {isLoading ? (
          <Loading />
        ) : (
          <>
            <ActionRow justify="end">
              <DownloadListButton onClick={() => goLogs()}>
                <BarsOutlined />
                ダウンロードファイル一覧
              </DownloadListButton>
              {isFeatureAvailable('excelConversionEdit') && (
                <AddFlowButton onClick={() => goAddFlow()} type="primary">
                  <PlusOutlined />
                  変換フローを追加
                </AddFlowButton>
              )}
            </ActionRow>
            <ExcelConversionFlowList flows={excelConversionFlows} />
          </>
        )}
      </Content>
      <FlowsPagination
        showSizeChanger
        current={currentPage}
        pageSizeOptions={['10', '25', '50', '100']}
        pageSize={request.limit ?? 25}
        defaultCurrent={1}
        total={count}
        onChange={(pageNum, pageSize) => changeFlowsPage(pageNum, pageSize)}
        onShowSizeChange={(_, size) => changeFlowsSize(size)}
      />
    </Layout>
  )
}

export default ExcelConversionFlowsContainer
