import Icon, {
  CheckOutlined,
  CloseOutlined,
  MinusOutlined,
} from '@ant-design/icons'
import { css } from '@emotion/react'
import { ReactNode } from 'react'
import { Link } from 'react-router-dom'
import CheckExclamation from 'src/assets/icons/check_exclamation.svg?react'
import { ScheduledReportDailyStatus } from 'src/features/scheduledReportSummary/types'

type Props = {
  reportUuid?: string
  status: ScheduledReportDailyStatus
}

/**
 * 日毎の実施状況を表示するセル
 */
export const StatusCell = (props: Props) => {
  const { reportUuid, status } = props

  const wrapWithLink = (children: ReactNode) => {
    if (reportUuid) {
      return <Link to={`/reports/${reportUuid}`}>{children}</Link>
    } else {
      return children
    }
  }

  switch (status) {
    case 'implemented': {
      return wrapWithLink(
        <div aria-label="実施済み" css={[styles.container, styles.implemented]}>
          <CheckOutlined />
        </div>,
      )
    }
    case 'implementedAfterDeadline': {
      return wrapWithLink(
        <div
          aria-label="期限切れで実施"
          css={[styles.container, styles.implementedAfterDeadline]}
        >
          <Icon component={CheckExclamation} />
        </div>,
      )
    }
    case 'notScheduled': {
      return (
        <div
          aria-label="予定なし"
          css={[styles.container, styles.notScheduled]}
        >
          <MinusOutlined />
        </div>
      )
    }
    case 'notImplementedYet': {
      return (
        <div
          aria-label="未実施"
          css={[styles.container, styles.notImplementedYet]}
        >
          <CloseOutlined />
        </div>
      )
    }
    case 'notStarted': {
      return (
        <div aria-label="開始前" css={[styles.container, styles.notStarted]}>
          <div css={[styles.container]}>{'開始前'}</div>
        </div>
      )
    }
    case 'duringPeriod': {
      return (
        <div aria-label="期間中" css={[styles.container]}>
          <div css={[styles.container]}>{'期間中'}</div>
        </div>
      )
    }
    // 現状ではその他のステータスがサーバーから返ってくることはない
    default: {
      return null
    }
  }
}

const styles = {
  container: css`
    align-items: center;
    display: flex;
    height: 38px; // 100%だと適用されない
    justify-content: center;
  `,
  implemented: css`
    color: #1e242c40;
    :hover {
      background: #1e242c0a;
    }
  `,
  implementedAfterDeadline: css`
    background: #fff9db;
    color: #f59f00;
    :hover {
      background: #fff3bf;
    }
  `,
  notScheduled: css`
    color: #1e242c40;
  `,
  notImplementedYet: css`
    background: #fff5f5;
    color: #f03e3e;
  `,
  notStarted: css`
    color: #1e242c40;
  `,
}
