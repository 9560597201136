import { css } from '@emotion/react'
import { FC, ReactNode } from 'react'
import { Header } from 'src/components/header/Header'
import { HeaderTitle } from 'src/components/header/HeaderTitle'
import { OutputButtonRow } from 'src/features/reportExport/list/components/OutputButtonRow'

type Props = {
  children: ReactNode
}

/**
 * レポート出力一覧画面において、データの取得状態に関わらず必ず表示する部分を抜き出したコンポーネント
 */
export const ReportExportListWrapper: FC<Props> = ({ children }) => {
  return (
    <div>
      <Header>
        <HeaderTitle title="レポート出力" />
      </Header>
      <div css={styles.contentArea}>
        <OutputButtonRow />
        <div css={styles.spacer} />
        {children}
      </div>
    </div>
  )
}

const styles = {
  contentArea: css`
    padding: 16px 24px 32px;
  `,
  spacer: css`
    margin: 16px 0 0 0;
  `,
}
