import {
  IndicatedIssueLabel,
  IndicatedIssueRevise,
} from '@ulysses-inc/harami_api_client'
import { ActionTypes } from './types'

const getIndicatedIssueLabels = () => {
  return {
    type: ActionTypes.REQUEST_GET_INDICATED_ISSUE_LABELS,
    isLoading: true,
  }
}

const getSuccessIndicatedIssueLabels = (
  indicatedIssueLabels: Array<IndicatedIssueLabel>,
) => {
  return {
    type: ActionTypes.SUCCESS_GET_INDICATED_ISSUE_LABELS,
    isLoading: false,
    indicatedIssueLabels,
  }
}

const getErrorIndicatedIssueLabels = (error: Error) => {
  return {
    type: ActionTypes.ERROR_ADD_INDICATED_ISSUE_LABEL,
    isLoading: false,
    error,
  }
}

const addIndicatedIssueLabel = (indicatedIssueLabel: IndicatedIssueLabel) => {
  return {
    type: ActionTypes.REQUEST_ADD_INDICATED_ISSUE_LABEL,
    isLoading: true,
    indicatedIssueLabel,
  }
}

const addSuccessIndicatedIssueLabel = (
  indicatedIssueLabel: IndicatedIssueLabel,
) => {
  return {
    type: ActionTypes.SUCCESS_ADD_INDICATED_ISSUE_LABEL,
    isLoading: false,
    indicatedIssueLabel,
  }
}

const addErrorIndicatedIssueLabel = (error: Error) => {
  return {
    type: ActionTypes.ERROR_ADD_INDICATED_ISSUE_LABEL,
    isLoading: false,
    error,
  }
}

const updateIndicatedIssueLabel = (
  indicatedIssueLabel: IndicatedIssueLabel,
) => {
  return {
    type: ActionTypes.REQUEST_UPDATE_INDICATED_ISSUE_LABEL,
    isLoading: true,
    indicatedIssueLabel,
  }
}

const updateSuccessIndicatedIssueLabel = (
  indicatedIssueLabel: IndicatedIssueLabel,
) => {
  return {
    type: ActionTypes.SUCCESS_UPDATE_INDICATED_ISSUE_LABEL,
    isLoading: false,
    indicatedIssueLabel,
  }
}

const updateErrorIndicatedIssueLabel = (error: Error) => {
  return {
    type: ActionTypes.ERROR_UPDATE_INDICATED_ISSUE_LABEL,
    isLoading: false,
    error,
  }
}

const resetEditIndicatedIssueLabel = () => {
  return {
    type: ActionTypes.RESET_EDIT_INDICATED_ISSUE_LABEL,
  }
}

const changeEditIndicatedIssueLabel = (
  indicatedIssueLabel: IndicatedIssueLabel,
) => {
  return {
    type: ActionTypes.CHANGE_EDIT_INDICATED_ISSUE_LABEL,
    indicatedIssueLabel,
  }
}

const deleteIndicatedIssueLabel = (uuid: string) => {
  return {
    type: ActionTypes.REQUEST_DELETE_INDICATED_ISSUE_LABEL,
    uuid,
  }
}

const deleteSuccessIndicatedIssueLabel = (uuid: string) => {
  return {
    type: ActionTypes.SUCCESS_DELETE_INDICATED_ISSUE_LABEL,
    uuid,
  }
}

const deleteErrorIndicatedIssueLabel = (err: Error) => {
  return {
    type: ActionTypes.ERROR_DELETE_INDICATED_ISSUE_LABEL,
    err,
  }
}

const changeIsShowDrawer = (isShowDrawer: boolean) => {
  return {
    type: ActionTypes.CHANGE_IS_SHOW_DRAWER,
    isShowDrawer,
  }
}

export type IndicatedIssueLabelAction =
  | ReturnType<typeof getIndicatedIssueLabels>
  | ReturnType<typeof getSuccessIndicatedIssueLabels>
  | ReturnType<typeof getErrorIndicatedIssueLabels>
  | ReturnType<typeof addIndicatedIssueLabel>
  | ReturnType<typeof addSuccessIndicatedIssueLabel>
  | ReturnType<typeof addErrorIndicatedIssueLabel>
  | ReturnType<typeof updateIndicatedIssueLabel>
  | ReturnType<typeof updateSuccessIndicatedIssueLabel>
  | ReturnType<typeof updateErrorIndicatedIssueLabel>
  | ReturnType<typeof resetEditIndicatedIssueLabel>
  | ReturnType<typeof changeEditIndicatedIssueLabel>
  | ReturnType<typeof deleteIndicatedIssueLabel>
  | ReturnType<typeof deleteSuccessIndicatedIssueLabel>
  | ReturnType<typeof deleteErrorIndicatedIssueLabel>
  | ReturnType<typeof changeIsShowDrawer>

const updateRequestIndicatedIssueRevises = (
  revises: IndicatedIssueRevise[],
) => {
  return {
    type: ActionTypes.REQUEST_UPDATE_INDICATED_ISSUE_REVISES,
    isLoading: true,
    revises,
  }
}

const updateSuccessIndicatedIssueRevises = () => {
  return {
    type: ActionTypes.SUCCESS_UPDATE_INDICATED_ISSUE_REVISES,
    isLoading: false,
  }
}

const updateErrorIndicatedIssueRevises = (error: Error) => {
  return {
    type: ActionTypes.ERROR_UPDATE_INDICATED_ISSUE_REVISES,
    isLoading: false,
    error,
  }
}

const saveDraftRequestIndicatedIssueRevises = (
  revises: IndicatedIssueRevise[],
) => {
  return {
    type: ActionTypes.REQUEST_SAVE_DRAFT_INDICATED_ISSUE_REVISES,
    isLoading: true,
    revises,
  }
}

const saveDraftSuccessIndicatedIssueRevises = () => {
  return {
    type: ActionTypes.SUCCESS_SAVE_DRAFT_INDICATED_ISSUE_REVISES,
    isLoading: false,
  }
}

const saveDraftErrorIndicatedIssueRevises = (error: Error) => {
  return {
    type: ActionTypes.ERROR_SAVE_DRAFT_INDICATED_ISSUE_REVISES,
    isLoading: false,
    error,
  }
}

export type IndicatedIssueReviseAction =
  | ReturnType<typeof updateRequestIndicatedIssueRevises>
  | ReturnType<typeof updateSuccessIndicatedIssueRevises>
  | ReturnType<typeof updateErrorIndicatedIssueRevises>
  | ReturnType<typeof saveDraftRequestIndicatedIssueRevises>
  | ReturnType<typeof saveDraftSuccessIndicatedIssueRevises>
  | ReturnType<typeof saveDraftErrorIndicatedIssueRevises>

export default {
  addIndicatedIssueLabel,
  addSuccessIndicatedIssueLabel,
  addErrorIndicatedIssueLabel,
  updateIndicatedIssueLabel,
  updateSuccessIndicatedIssueLabel,
  updateErrorIndicatedIssueLabel,
  getIndicatedIssueLabels,
  resetEditIndicatedIssueLabel,
  changeEditIndicatedIssueLabel,
  deleteIndicatedIssueLabel,
  deleteSuccessIndicatedIssueLabel,
  deleteErrorIndicatedIssueLabel,
  getSuccessIndicatedIssueLabels,
  getErrorIndicatedIssueLabels,
  changeIsShowDrawer,
  updateRequestIndicatedIssueRevises,
  updateSuccessIndicatedIssueRevises,
  updateErrorIndicatedIssueRevises,
  saveDraftRequestIndicatedIssueRevises,
  saveDraftSuccessIndicatedIssueRevises,
  saveDraftErrorIndicatedIssueRevises,
}
