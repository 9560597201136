import { localStorageKeys } from 'src/constants/localStorageKeys'

export const getContractPlan = (): number | null => {
  const contractPlanRaw = localStorage.getItem(
    localStorageKeys.loginCompanyContractPlan,
  )
  if (!contractPlanRaw) return null
  const contractPlan = Number(contractPlanRaw)
  return Number.isNaN(contractPlan) ? null : contractPlan
}
