import React from 'react'
import Exclamate from 'src/assets/icons/questiondeviated.svg?react'
import { White } from 'src/features/theme/KdsThemeColor'
import styled from 'styled-components'

const DeviatedRibbonBox = styled.div`
  border-top-right-radius: 8px;
  border-bottom-left-radius: 8px;
  width: 92px;
  height: 25px;
  background-color: rgb(235, 87, 87);
  position: absolute;
  top: 0;
  right: 0;
  color: ${White};
  text-align: center;
`

const DeviatedIcon = styled.span`
  position: absolute;
  left: 13px;
  top: 2px;
`

const DeviatedRibbonText = styled.span`
  position: absolute;
  font-size: 12px;
  top: 4px;
  left: 32px;
  line-height: 17px;
`

export const QuestionDeviatedRibbon: React.FC = () => {
  return (
    <DeviatedRibbonBox>
      <DeviatedIcon>
        <Exclamate />
      </DeviatedIcon>
      <DeviatedRibbonText>逸脱項目</DeviatedRibbonText>
    </DeviatedRibbonBox>
  )
}
