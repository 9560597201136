import { css } from '@emotion/react'
import React from 'react'
import { View } from 'src/components/nativeCompat/View'
import { BorderColor } from 'src/features/theme/KdsThemeColor'

export interface PhotoProps {
  imageUrls: (string | undefined)[]
  listKey: string
  goPreviewPhoto: (images: string[], selectedImageIndex: number) => void
}

export const Photo: React.FC<PhotoProps> = props => {
  const notNullableUrls: string[] = []
  props.imageUrls.forEach(url => url !== undefined && notNullableUrls.push(url))

  if (notNullableUrls.length === 0) {
    return <></>
  }
  return (
    <View css={styles.photoContainer}>
      {notNullableUrls.map((item, index) => {
        return (
          <View css={styles.photoView} key={`${props.listKey}-${index}`}>
            <div
              style={{ cursor: 'pointer', width: '100%', height: '100%' }}
              onClick={() => props.goPreviewPhoto(notNullableUrls, index)}
            >
              <img
                src={item}
                style={{
                  width: '100px',
                  height: '100px',
                  objectFit: 'contain',
                }}
              />
            </div>
          </View>
        )
      })}
    </View>
  )
}

const styles = {
  photoContainer: css`
    flex: 1;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
  `,
  photoView: css`
    border-style: solid;
    border-color: ${BorderColor};
    border-width: 1px;
    margin-bottom: 12px;
    margin-right: 12px;
  `,
}
