import { GetImprovesRequest, Improve } from '@ulysses-inc/harami_api_client'
import { ImprovesAction } from '../actions'
import { ActionTypes, GetImprovesFilter } from '../types'

interface ImprovesState {
  improves: Improve[]
  improveUUID: string
  isLoading: boolean
  error: Error | null
  count: number
  request: GetImprovesRequest
  filter: GetImprovesFilter
}

const initialImprovesState: ImprovesState = {
  improves: [],
  improveUUID: '',
  isLoading: false,
  error: null,
  count: 0,
  request: {
    offset: 0,
    limit: 25,
  },
  filter: {},
}

const improves = (
  state: ImprovesState = initialImprovesState,
  action: ImprovesAction,
): ImprovesState => {
  switch (action.type) {
    case ActionTypes.REQUEST_GET_IMPROVES: {
      return {
        ...state,
        improves: [],
        isLoading: action.isLoading,
        error: null,
        request: action.request,
        filter: action.filter ?? {},
      }
    }
    case ActionTypes.SUCCESS_GET_IMPROVES: {
      return {
        ...state,
        improves: action.improves ?? [],
        isLoading: action.isLoading,
        error: null,
        count: action.count ?? 0,
      }
    }
    case ActionTypes.ERROR_GET_IMPROVES: {
      return {
        ...state,
        isLoading: action.isLoading,
        error: action.error,
        count: 0,
      }
    }
    case ActionTypes.REQUEST_CHANGE_IMPROVES_PAGE: {
      return {
        ...state,
        request: action.request,
        filter: action.filter ?? {},
      }
    }
    case ActionTypes.REQUEST_CHANGE_IMPROVES_SIZE: {
      return {
        ...state,
        request: action.request,
        filter: action.filter ?? {},
      }
    }
    case ActionTypes.UPDATE_PAGINATION: {
      return {
        ...state,
        request: action.request,
      }
    }
    case ActionTypes.REQUEST_DELETE_IMPROVE: {
      return {
        ...state,
        improveUUID: action.improveUUID,
        isLoading: action.isLoading,
        error: null,
      }
    }
    case ActionTypes.SUCCESS_DELETE_IMPROVE: {
      return {
        ...state,
        isLoading: action.isLoading,
        error: null,
        improves: state.improves.filter(
          improve => improve.uuid !== action.improveUUID,
        ),
      }
    }
    case ActionTypes.ERROR_DELETE_IMPROVE: {
      return {
        ...state,
        isLoading: action.isLoading,
        error: action.error,
      }
    }
    default: {
      return { ...state }
    }
  }
}

export default improves
