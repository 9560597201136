import { LockOutlined, UserOutlined } from '@ant-design/icons'
import { Form } from 'antd'
import { FC, ImgHTMLAttributes } from 'react'
import logoImg from 'src/assets/images/kaminashi_report_logo.png'
import { IconInputDefaultColor } from 'src/features/theme/KdsThemeColor'
import styled from 'styled-components'

const { Item } = Form

export const LoginFormWrap = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 124px;
  width: 100%;
`

export const LoginForm: typeof Form = styled(Form)`
  width: 400px;
`
export const UserOutlinedIcon = styled(UserOutlined)`
  color: ${IconInputDefaultColor};
`

export const LockOutlinedIcon = styled(LockOutlined)`
  color: ${IconInputDefaultColor};
`

const ImageWrap = styled.div`
  text-align: center;
`

export const LoginItem = styled(Item)`
  && {
    margin: 39px 0 0;
    &:nth-child(2) {
      margin: 15px 0 0;
    }
    &:last-child {
      margin-bottom: 15px;
    }
  }
`

export const Logo: FC<ImgHTMLAttributes<HTMLImageElement>> = props => (
  <ImageWrap>
    <img alt="kaminashi_report_logo" src={logoImg} {...props} />
  </ImageWrap>
)

export const ErrorMessageWrapper = styled.div`
  position: fixed;
  top: 16px;
  left: 16px;
  right: 16px;
  z-index: 1;
`
