import { Checkbox } from 'antd'
import React from 'react'
import styled from 'styled-components'

const RejectCheckbox = styled(Checkbox)`
  pointer-events: initial;
`

type OwnProps = {
  index: number
  isRejectEditStatus: boolean
  isDisabled: boolean
  checked: boolean
  setFieldIndicateIssueStatusValue: (bool: boolean) => void
}
const Component: React.FC<OwnProps> = props => {
  if (props.isRejectEditStatus) {
    return (
      <div>
        <RejectCheckbox
          disabled={props.isDisabled}
          checked={props.checked}
          onChange={e => {
            props.setFieldIndicateIssueStatusValue(e.target.checked)
          }}
        />
      </div>
    )
  }
  return <div>{props.index}</div>
}

export default Component
