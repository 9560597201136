import { css } from '@emotion/react'
import { Modal } from 'antd'
import { TitleAndContent } from 'src/features/reportExport/detail/components/TitleAndContent'

type Props = {
  isSendingData: boolean
  onCancel: () => void
  onOk: () => void
  open: boolean
  sendingDataAsString: {
    dateFromTo: string
    outputFileName: string
    places: string
    templateName: string
  }
}

export const ReportExportDetailSendConfirmation = (props: Props) => {
  const {
    isSendingData,
    onCancel,
    onOk,
    open,
    sendingDataAsString: { dateFromTo, outputFileName, places, templateName },
  } = props

  return (
    <Modal
      cancelButtonProps={{
        disabled: isSendingData,
      }}
      cancelText="キャンセル"
      okButtonProps={{
        disabled: isSendingData,
        loading: isSendingData,
      }}
      okText="出力"
      onCancel={onCancel}
      onOk={onOk}
      open={open}
      title="出力の確認"
    >
      <div css={styles.grid}>
        <TitleAndContent title="ひな形">{templateName}</TitleAndContent>
        <TitleAndContent title="現場">{places}</TitleAndContent>
        <TitleAndContent title="期間">{dateFromTo}</TitleAndContent>
        <TitleAndContent title="出力ファイル名">
          {outputFileName}
        </TitleAndContent>
      </div>
    </Modal>
  )
}

const styles = {
  grid: css`
    display: grid;
    grid-gap: 22px;
    grid-template-columns: 1fr;
    margin-top: 24px;
  `,
}
