import {
  ReportNodeSchema,
  ReportPageSchema,
  ReportQuestion,
  TemplateNodeTypeEnum,
} from '@ulysses-inc/harami_api_client'

const getAllQuestions = (nodes: {
  [key: number]: ReportNodeSchema
}): Array<ReportQuestion> => {
  if (!nodes) {
    return []
  }
  return Object.keys(nodes)
    .map(Number)
    .filter((key: number) => nodes[key].type === TemplateNodeTypeEnum.Question)
    .map((key: number) => nodes[key].question!)
}

const allPagesChildNodes = (
  pages: { [key: number]: ReportPageSchema },
  nodes: { [key: number]: ReportNodeSchema },
): { [key: number]: ReportNodeSchema } =>
  Object.values(pages).reduce((prev, cur) => {
    return Object.assign(prev, pageChildNodes(cur, nodes))
  }, {})

const pageChildNodes = (
  page: ReportPageSchema,
  nodes: { [key: number]: ReportNodeSchema },
): { [key: number]: ReportNodeSchema } => {
  return childNodeIds(page.nodes ?? [], [], nodes).reduce(
    (prev: any, cur: any) => ({ ...prev, [cur]: nodes[cur] }),
    {},
  )
}

const sectionChildNodes = (
  node: ReportNodeSchema,
  nodes: { [key: number]: ReportNodeSchema },
): { [key: number]: ReportNodeSchema } =>
  childNodeIds(node.nodes ?? [], [], nodes).reduce(
    (prev: any, cur: any) => ({ ...prev, [cur]: nodes[cur] }),
    {},
  )

const childNodeIds = (
  nodeIds: Array<number>,
  prevNodeIds: Array<number>,
  allNodes: { [key: number]: ReportNodeSchema },
) => {
  if (nodeIds.length === 0) return prevNodeIds

  const filtered = nodeIds.filter(nodeId => {
    if (
      allNodes[nodeId].section &&
      allNodes[nodeId].type === TemplateNodeTypeEnum.Section
    ) {
      return !(
        allNodes[nodeId].section!.isRepeat === 1 &&
        !allNodes[nodeId].section!.originalNodeUuid
      )
    }
    return true
  })
  if (filtered.length === 0) return []
  const res: any = filtered
    .map(nodeId => {
      const grandchildNodeIds = allNodes[nodeId].nodes ?? []
      if (grandchildNodeIds.length === 0) return [nodeId]
      return [nodeId, ...childNodeIds(grandchildNodeIds, nodeIds, allNodes)]
    })
    .reduce((prev, cur) => prev.concat(cur))
    .filter(
      (id: number, index: number, ids: number[]) => ids.indexOf(id) === index,
    )
  return res
}

export {
  allPagesChildNodes,
  getAllQuestions,
  pageChildNodes,
  sectionChildNodes,
}
