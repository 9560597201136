import {
  DayOfWeekEnum,
  ScheduleFrequencyEnum,
  ScheduleFrequencyMonthly,
  ScheduleFrequencyWeekly,
  WeekOfMonthEnum,
} from '@ulysses-inc/harami_api_client'
import { Checkbox, InputNumber, Select } from 'antd'
import { FormikErrors, FormikTouched } from 'formik'
import React from 'react'
import FormikErrorMessage from 'src/components/formikErrorMessage/FormikErrorMessage'
import styled from 'styled-components'

const { Option } = Select

const FrequencySelect = styled(Select)`
  width: 200px;
`

const FrequencyContainer = styled.div`
  margin-top: 10px;
`

const FrequencyMonthlySelect = styled(Select)`
  width: 100px;
  margin-right: 10px;
`

interface OwnProps {
  frequency: ScheduleFrequencyEnum | null
  frequencyWeekly?: ScheduleFrequencyWeekly
  frequencyMonthly?: ScheduleFrequencyMonthly
  touched: FormikTouched<any>
  submitCount: number
  errors: FormikErrors<any>
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
}

const FrequencySelectOptionInDate: React.FC<OwnProps> = (props: OwnProps) => {
  return (
    <div>
      <FrequencySelect
        value={props.frequency || undefined}
        onChange={(value: unknown) => {
          if (typeof value !== 'number') return
          props.setFieldValue('frequency', value)
          props.setFieldValue('startReportDate', undefined)
          props.setFieldValue('endReportDate', undefined)
          props.setFieldValue('frequencyWeekly', undefined)
          props.setFieldValue('frequencyMonthly', undefined)
          props.setFieldValue('frequencyCustom', undefined)
          if (value === ScheduleFrequencyEnum.Once)
            props.setFieldValue('visibleEndReportDate', false)
        }}
      >
        <Option value={ScheduleFrequencyEnum.Daily}>毎日</Option>
        <Option value={ScheduleFrequencyEnum.Weekly}>週単位</Option>
        <Option value={ScheduleFrequencyEnum.Monthly}>月単位</Option>
        <Option value={ScheduleFrequencyEnum.Once}>1度だけ</Option>
      </FrequencySelect>
      <div>
        {props.submitCount > 0 && (
          <FormikErrorMessage name="frequency" errors={props.errors} />
        )}
      </div>
      {props.frequency === ScheduleFrequencyEnum.Weekly && (
        <FrequencyContainer>
          <div>
            <InputNumber
              value={props.frequencyWeekly?.every}
              onChange={(value: string | number | undefined | null) => {
                if (value === undefined && typeof value !== 'number') return
                props.setFieldValue('frequencyWeekly', {
                  ...props.frequencyWeekly,
                  every: value,
                })
              }}
            />
            週間ごと
          </div>
          <FrequencyContainer>
            <Checkbox.Group
              value={props.frequencyWeekly?.dayOfWeeks?.map(String)}
              onChange={checkedValues => {
                props.setFieldValue('startReportDate', undefined)
                props.setFieldValue('endReportDate', undefined)
                props.setFieldValue('frequencyWeekly', {
                  ...props.frequencyWeekly,
                  dayOfWeeks: checkedValues.map(Number),
                })
              }}
            >
              <Checkbox value={`${DayOfWeekEnum.Monday}`}>月</Checkbox>
              <Checkbox value={`${DayOfWeekEnum.Tuesday}`}>火</Checkbox>
              <Checkbox value={`${DayOfWeekEnum.Wednesday}`}>水</Checkbox>
              <Checkbox value={`${DayOfWeekEnum.Thursday}`}>木</Checkbox>
              <Checkbox value={`${DayOfWeekEnum.Friday}`}>金</Checkbox>
              <Checkbox value={`${DayOfWeekEnum.Saturday}`}>土</Checkbox>
              <Checkbox value={`${DayOfWeekEnum.Sunday}`}>日</Checkbox>
            </Checkbox.Group>
          </FrequencyContainer>
          <div>
            {props.submitCount > 0 && (
              <FormikErrorMessage
                name="frequencyWeekly.every"
                errors={props.errors}
              />
            )}
          </div>
          <div>
            {props.submitCount > 0 && (
              <FormikErrorMessage
                name="frequencyWeekly.dayOfWeeks"
                errors={props.errors}
              />
            )}
          </div>
        </FrequencyContainer>
      )}
      {props.frequency === ScheduleFrequencyEnum.Monthly && (
        <FrequencyContainer>
          <div>
            <InputNumber
              value={props.frequencyMonthly?.every}
              onChange={(value: string | number | undefined | null) => {
                if (value === undefined && typeof value !== 'number') return
                props.setFieldValue('frequencyMonthly', {
                  ...props.frequencyMonthly,
                  every: value,
                })
              }}
            />
            ヵ月ごと
          </div>
          <FrequencyContainer>
            <FrequencyMonthlySelect
              value={props.frequencyMonthly?.weekOfMonth}
              onChange={(value: unknown) => {
                if (typeof value !== 'number') return
                props.setFieldValue('startReportDate', undefined)
                props.setFieldValue('endReportDate', undefined)
                props.setFieldValue('frequencyMonthly', {
                  ...props.frequencyMonthly,
                  weekOfMonth: value,
                })
              }}
            >
              <Option value={WeekOfMonthEnum.First}>最初の</Option>
              <Option value={WeekOfMonthEnum.Second}>第2</Option>
              <Option value={WeekOfMonthEnum.Third}>第3</Option>
              <Option value={WeekOfMonthEnum.Fourth}>第4</Option>
              <Option value={WeekOfMonthEnum.Last}>最終</Option>
            </FrequencyMonthlySelect>
            <FrequencyMonthlySelect
              value={props.frequencyMonthly?.dayOfWeek}
              onChange={(value: unknown) => {
                if (typeof value !== 'number') return
                props.setFieldValue('startReportDate', undefined)
                props.setFieldValue('endReportDate', undefined)
                props.setFieldValue('frequencyMonthly', {
                  ...props.frequencyMonthly,
                  dayOfWeek: value,
                })
              }}
            >
              <Option value={DayOfWeekEnum.Monday}>月曜日</Option>
              <Option value={DayOfWeekEnum.Tuesday}>火曜日</Option>
              <Option value={DayOfWeekEnum.Wednesday}>水曜日</Option>
              <Option value={DayOfWeekEnum.Thursday}>木曜日</Option>
              <Option value={DayOfWeekEnum.Friday}>金曜日</Option>
              <Option value={DayOfWeekEnum.Saturday}>土曜日</Option>
              <Option value={DayOfWeekEnum.Sunday}>日曜日</Option>
            </FrequencyMonthlySelect>
          </FrequencyContainer>
          <div>
            {props.submitCount > 0 && (
              <FormikErrorMessage
                name="frequencyMonthly.every"
                errors={props.errors}
              />
            )}
          </div>
          <div>
            {props.submitCount > 0 && (
              <FormikErrorMessage
                name="frequencyMonthly.weekOfMonth"
                errors={props.errors}
              />
            )}
          </div>
          <div>
            {props.submitCount > 0 && (
              <FormikErrorMessage
                name="frequencyMonthly.dayOfWeek"
                errors={props.errors}
              />
            )}
          </div>
        </FrequencyContainer>
      )}
    </div>
  )
}

export default FrequencySelectOptionInDate
