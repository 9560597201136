import { CheckOutlined, CloseOutlined } from '@ant-design/icons'
import { Row } from 'antd'
import { InputNumberProps } from 'antd/lib/input-number'
import { SwitchProps } from 'antd/lib/switch'
import { FormikProps } from 'formik'
import { FC, useId } from 'react'
import FormikErrorMessage from 'src/components/formikErrorMessage/FormikErrorMessage'
import { NumberRuleProps } from './EditResponseNumberRuleDrawer'
import {
  DecimalPointInputNumber,
  DecimalPointSwitch,
  FormGroup,
  FormGroupDecimalPointRow,
  FormGroupDecimalPointValueRow,
} from './EditResponseNumberRuleDrawer.styled'

type TSwitchValue = Parameters<Exclude<SwitchProps['onChange'], undefined>>[0]
type TInputValue = Parameters<
  Exclude<InputNumberProps['onChange'], undefined>
>[0]

const FractionalDigitsRow: FC<{
  formikProps: FormikProps<NumberRuleProps>
}> = ({
  formikProps: {
    values: { decimalPoint },
    setFieldValue,
    errors,
    touched,
  },
}) => {
  const onToggle = (checked: TSwitchValue) =>
    setFieldValue('decimalPoint.isActive', checked ? 1 : 0)
  const onChangeDigits = (value: TInputValue) =>
    setFieldValue('decimalPoint.value', Number(value))

  const decimalPointSwitchLabelId = useId()
  const decimalPointInputLabelId = useId()

  return (
    <FormGroup>
      <FormGroupDecimalPointRow>
        <DecimalPointSwitch
          checkedChildren={<CheckOutlined />}
          unCheckedChildren={<CloseOutlined />}
          checked={!!decimalPoint?.isActive}
          onChange={onToggle}
          aria-labelledby={decimalPointSwitchLabelId}
        />
        <label id={decimalPointSwitchLabelId}>小数点以下の桁数を設定する</label>
      </FormGroupDecimalPointRow>
      <FormGroupDecimalPointValueRow>
        <DecimalPointInputNumber
          name="decimalPoint"
          placeholder="1"
          min={0}
          value={decimalPoint?.value}
          onChange={onChangeDigits}
          disabled={!decimalPoint?.isActive}
          aria-labelledby={decimalPointInputLabelId}
        />
        <label id={decimalPointInputLabelId}>桁</label>
      </FormGroupDecimalPointValueRow>
      <Row>
        <div>
          <FormikErrorMessage
            name="decimalPoint"
            errors={errors}
            touched={touched}
          />
        </div>
      </Row>
    </FormGroup>
  )
}

export default FractionalDigitsRow
