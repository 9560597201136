import { useCallback, useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { usePlaceLoadingStatus } from 'src/exShared/hooks/usePlaceLoadingStatus'
import { GetTemplatesFilter } from 'src/exShared/types/types'
import {
  changeGridVariableTemplatesPage,
  changeGridVariableTemplatesSize as changeGridVariableTemplatesSizeAction,
  resetPagination as resetPaginationAction,
  updateFilter as updateFilterAction,
  updatePagination as updatePaginationAction,
  updatePaginationWithPage as updatePaginationWithPageAction,
} from 'src/state/ducks/gridVariables/list/actions'
import { RootState } from 'src/state/store'

/**
 * 取り込み項目「あり」のひな形一覧のstateを取得するためのカスタムフック
 *
 * コンポーネント側のReduxへの依存をなくし、移植性を高める試みのために作成したもの
 * (https://docs.google.com/document/d/1-ZEqu0vWmBBxfH2-wtdfLb2UWME6Q_e7uKa7AyEUE54/edit)
 *
 * @param fetchesData APIからデータを取得するアクションを実行したい場合true。
 *                  重複してAPIコールをするActionを仕込まないように、一覧画面のトップレベルのコンポーネントでのみtrueとする
 * @returns
 */
export const useGridVariableTemplates = (fetchesData?: boolean) => {
  const templates = useSelector(
    (state: RootState) => state.gridVariablesState.templates.templates,
    shallowEqual,
  )
  const count = useSelector(
    (state: RootState) => state.gridVariablesState.templates.count,
  )
  const isLoading = useSelector(
    (state: RootState) => state.gridVariablesState.templates.isLoading,
  )
  const error = useSelector(
    (state: RootState) => state.gridVariablesState.templates.error,
  )
  const request = useSelector(
    (state: RootState) => state.gridVariablesState.templates.request,
    shallowEqual,
  )
  const filter = useSelector(
    (state: RootState) => state.gridVariablesState.templates.filter,
    shallowEqual,
  )
  const placeLoadingStatus = usePlaceLoadingStatus()

  const dispatch = useDispatch()
  useEffect(() => {
    if (!fetchesData) {
      return
    }
    if (placeLoadingStatus !== 'finish') {
      return
    }
    if (!request.limit || (!request.offset && request.offset !== 0)) {
      return
    }
    dispatch(
      changeGridVariableTemplatesPage(request.limit, request.offset, filter),
    )

    // 現状はfetchesDataを呼び出し元が切り替えるような使い方はしない想定だが、
    // 意味的にはfetchesDataにも依存させた方が正しいと思われるので、依存配列に含めておく
  }, [
    fetchesData,
    dispatch,
    request.limit,
    request.offset,
    filter,
    placeLoadingStatus,
  ])

  const updatePaginationWithPage = useCallback(
    (page: number, pageSize?: number) =>
      dispatch(updatePaginationWithPageAction(page, pageSize)),
    [dispatch],
  )

  const changeTemplatesSize = useCallback(
    (pageSize: number) =>
      dispatch(changeGridVariableTemplatesSizeAction(pageSize)),
    [dispatch],
  )

  const updatePagination = useCallback(
    (limit: number, offset: number) =>
      dispatch(updatePaginationAction(limit, offset)),
    [dispatch],
  )

  const resetPagination = useCallback(
    () => dispatch(resetPaginationAction()),
    [dispatch],
  )

  const updateFilter = useCallback(
    (filter: GetTemplatesFilter) => dispatch(updateFilterAction(filter)),
    [dispatch],
  )

  return {
    data: {
      templates,
      count,
      request,
    },
    isLoading,
    error,
    methods: {
      updatePaginationWithPage,
      changeTemplatesSize,
      updatePagination,
      resetPagination,
      updateFilter,
    },
  }
}
