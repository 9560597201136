/**
 * 選択肢の色のバリエーション
 *
 * これらのカラーコードは最終的にレポート(`report_answer_multiple_choices`テーブル)にそのまま文字列として記録される。
 * ただし透明の場合のみ、文字列ではなく空文字として記録される。
 *
 * フロント側では、レポート結果画面の描写時にカラーコードを文字列比較して処理分岐していたりするので、
 * 変更する際は細心の注意が必要。
 */

export const multipleChoiceColors = {
  default: '#DDDDDD',
  primary: '#07B7B7',
  info: '#20446A',
  success: '#27AE60',
  danger: '#EB5757',
  warning: '#F2994A',
  transparent: 'rgba(0, 0, 0, 0)',
} as const
