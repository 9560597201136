import { TemplateHint } from '@ulysses-inc/harami_api_client'
import { UUID } from 'src/exShared/util/uuid'
import { ActionTypes } from 'src/state/ducks/templates/templateHints/types'

export const getTemplateHints = (templateId: number) => {
  return {
    type: ActionTypes.REQUEST_GET_TEMPLATE_HINTS,
    isLoading: true,
    templateId,
  }
}

export const getSuccessTemplateHints = (templateHints: Array<TemplateHint>) => {
  return {
    type: ActionTypes.SUCCESS_GET_TEMPLATE_HINTS,
    isLoading: false,
    templateHints,
  }
}

export const getErrorTemplateHints = (error: Error) => {
  return {
    type: ActionTypes.ERROR_GET_TEMPLATE_HINTS,
    isLoading: false,
    error: error,
  }
}

export const addSuccessTemplateHints = () => {
  return {
    type: ActionTypes.SUCCESS_ADD_TEMPLATE_HINTS,
    isLoading: false,
  }
}

export const resetTemplateHints = () => {
  return {
    type: ActionTypes.RESET_TEMPLATE_HINTS,
  }
}

/**
 * ヒント列を追加する
 */
export const addTemplateHint = () => {
  return {
    type: ActionTypes.ADD_TEMPLATE_HINT,
    uid: UUID(),
  }
}

/**
 * ヒント列の名前入力欄を更新する
 */
export const updateTemplateHint = (
  hintId: string,
  changeTemplateHint: TemplateHint,
) => {
  return {
    type: ActionTypes.UPDATE_TEMPLATE_HINT,
    hintId,
    changeTemplateHint,
  }
}

/**
 * ヒント列を削除できる
 */
export const deleteTemplateHint = (hintId: string) => {
  return {
    type: ActionTypes.DELETE_TEMPLATE_HINT,
    hintId,
  }
}

export const setTemplateHintErrorMessage = (message: string) => {
  return { type: ActionTypes.SET_TEMPLATE_HINT_ERROR_MESSAGE, message }
}

export type TemplateHintAction =
  | ReturnType<typeof getTemplateHints>
  | ReturnType<typeof getSuccessTemplateHints>
  | ReturnType<typeof getErrorTemplateHints>
  | ReturnType<typeof resetTemplateHints>
  | ReturnType<typeof addSuccessTemplateHints>
  | ReturnType<typeof addTemplateHint>
  | ReturnType<typeof updateTemplateHint>
  | ReturnType<typeof deleteTemplateHint>
  | ReturnType<typeof setTemplateHintErrorMessage>
