import {
  Approval,
  ApprovalStep,
  BulkApproveReportsRequest,
  GetApprovalReportsRequest,
  GetApprovalReportsResponse,
} from '@ulysses-inc/harami_api_client'
import {
  ActionTypes,
  DeleteApprovalArg,
  GetApprovalReportsFilter,
} from './types'

export const getApproval = (approvalUUID: string) => {
  return {
    type: ActionTypes.REQUEST_GET_APPROVAL,
    isLoading: true,
    approvalUUID,
  }
}

const getSuccessApproval = (approval: Approval) => {
  return {
    type: ActionTypes.SUCCESS_GET_APPROVAL,
    isLoading: false,
    approval,
  }
}

const getErrorApproval = (error: Error) => {
  return {
    type: ActionTypes.ERROR_GET_APPROVAL,
    isLoading: false,
    error,
  }
}

export const resetApproval = () => {
  return {
    type: ActionTypes.RESET_APPROVAL,
  }
}

export type ApprovalAction =
  | ReturnType<typeof getApproval>
  | ReturnType<typeof getSuccessApproval>
  | ReturnType<typeof getErrorApproval>
  | ReturnType<typeof resetApproval>

export const deleteApproval = (arg: DeleteApprovalArg) => {
  return {
    type: ActionTypes.REQUEST_DELETE_APPROVAL,
    isLoading: true,
    arg,
  }
}

const deleteSuccessApproval = () => {
  return {
    type: ActionTypes.SUCCESS_DELETE_APPROVAL,
    isLoading: false,
  }
}

const deleteErrorApproval = (error: Error) => {
  return {
    type: ActionTypes.ERROR_DELETE_APPROVAL,
    isLoading: false,
    error,
  }
}

export type DeleteApprovalAction =
  | ReturnType<typeof deleteApproval>
  | ReturnType<typeof deleteSuccessApproval>
  | ReturnType<typeof deleteErrorApproval>

export const addApprovalStep = (
  reportUUID: string,
  approvalUUID: string,
  currentStep: ApprovalStep,
  nextStep: ApprovalStep,
  url: string,
) => {
  return {
    type: ActionTypes.REQUEST_ADD_APPROVAL_STEP,
    isLoading: true,
    reportUUID,
    approvalUUID,
    currentStep,
    nextStep,
    url,
  }
}

const addSuccessApprovalStep = () => {
  return {
    type: ActionTypes.SUCCESS_ADD_APPROVAL_STEP,
    isLoading: false,
  }
}

const addErrorApprovalStep = (error: Error) => {
  return {
    type: ActionTypes.ERROR_ADD_APPROVAL_STEP,
    isLoading: false,
    error,
  }
}

export type AddApprovalStepAction =
  | ReturnType<typeof addApprovalStep>
  | ReturnType<typeof addSuccessApprovalStep>
  | ReturnType<typeof addErrorApprovalStep>

const addApprovalReport = (
  reportUUID: string,
  approvalFlowId: number,
  url: string,
) => {
  return {
    type: ActionTypes.REQUEST_ADD_APPROVAL_REPORT,
    isLoading: true,
    reportUUID,
    approvalFlowId,
    url,
  }
}

const addSuccessApprovalReport = () => {
  return {
    type: ActionTypes.SUCCESS_ADD_APPROVAL_REPORT,
    isLoading: false,
  }
}

const addErrorApprovalReport = (error: Error) => {
  return {
    type: ActionTypes.ERROR_ADD_APPROVAL_REPORT,
    isLoading: false,
    error,
  }
}

export type AddApprovalReportAction =
  | ReturnType<typeof addApprovalReport>
  | ReturnType<typeof addSuccessApprovalReport>
  | ReturnType<typeof addErrorApprovalReport>

const getApprovalReports = (
  request: GetApprovalReportsRequest,
  filter?: GetApprovalReportsFilter,
) => {
  return {
    type: ActionTypes.REQUEST_GET_APPROVAL_REPORTS,
    request: request,
    filter: filter,
    isLoading: true,
  }
}

const getSuccessApprovalReports = (response: GetApprovalReportsResponse) => {
  return {
    type: ActionTypes.SUCCESS_GET_APPROVAL_REPORTS,
    isLoading: false,
    reports: response.reports,
    count: response.count,
  }
}

const getErrorApprovalReports = (error: Error) => {
  return {
    type: ActionTypes.ERROR_GET_APPROVAL_REPORTS,
    isLoading: false,
    error,
  }
}

const changeApprovalReportsPage = (
  page: number,
  pageSize?: number,
  filter?: GetApprovalReportsFilter,
) => {
  const offset = pageSize ? (page - 1) * pageSize : 0
  return {
    type: ActionTypes.REQUEST_CHANGE_APPROVAL_REPORTS_PAGE,
    isLoading: true,
    request: {
      limit: pageSize,
      offset,
    },
    filter,
  }
}

const changeApprovalReportsSize = (
  pageSize: number,
  filter?: GetApprovalReportsFilter,
) => {
  return {
    type: ActionTypes.REQUEST_CHANGE_APPROVAL_REPORTS_SIZE,
    isLoading: true,
    request: {
      limit: pageSize,
      offset: 0,
    },
    filter,
  }
}

const updatePagination = (limit: number, offset: number) => {
  return {
    type: ActionTypes.UPDATE_PAGINATION,
    request: {
      limit,
      offset,
    },
  }
}

export type GetApprovalReportsAction =
  | ReturnType<typeof getApprovalReports>
  | ReturnType<typeof getSuccessApprovalReports>
  | ReturnType<typeof getErrorApprovalReports>
  | ReturnType<typeof changeApprovalReportsPage>
  | ReturnType<typeof changeApprovalReportsSize>
  | ReturnType<typeof updatePagination>

const bulkApproveReports = (request: BulkApproveReportsRequest) => {
  return {
    type: ActionTypes.REQUEST_BULK_APPROVE_REPORTS,
    request,
    isLoading: true,
  }
}

const bulkSuccessApproveReports = () => {
  return {
    type: ActionTypes.SUCCESS_BULK_APPROVE_REPORTS,
    isLoading: false,
  }
}

const bulkErrorApproveReports = (error: Error) => {
  return {
    type: ActionTypes.ERROR_BULK_APPROVE_REPORTS,
    isLoading: false,
    error,
  }
}

// 未利用
// export type BulkApproveReportsAction =
//   | ReturnType<typeof bulkApproveReports>
//   | ReturnType<typeof bulkSuccessApproveReports>
//   | ReturnType<typeof bulkErrorApproveReports>

export default {
  getApproval,
  getSuccessApproval,
  getErrorApproval,
  resetApproval,
  deleteApproval,
  deleteSuccessApproval,
  deleteErrorApproval,
  addApprovalStep,
  addSuccessApprovalStep,
  addErrorApprovalStep,
  addApprovalReport,
  addSuccessApprovalReport,
  addErrorApprovalReport,
  getApprovalReports,
  getSuccessApprovalReports,
  getErrorApprovalReports,
  changeApprovalReportsPage,
  changeApprovalReportsSize,
  updatePagination,
  bulkApproveReports,
  bulkSuccessApproveReports,
  bulkErrorApproveReports,
}
