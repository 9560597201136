import { useQuery } from '@tanstack/react-query'
import {
  AppRoleEnum,
  CompanyContractPlan,
  ErrorCodeEnum,
  GetLoginUserContextResponse,
  LoginUserApi,
  PlaceNode,
} from '@ulysses-inc/harami_api_client'
import { commonQueryKeys } from 'src/features/tanStackQuery/commonQueries/commonQueryKeys'
import { baseClient } from 'src/state/middleware/saga/baseClient'
import {
  HTTPError,
  isHTTPError,
} from 'src/state/middleware/saga/handleHttpError'

export class AuthError extends Error {
  errorResponse: HTTPError

  constructor(errorResponse: HTTPError, cause?: unknown) {
    super(JSON.stringify(errorResponse, null, 2), { cause })
    this.errorResponse = errorResponse
  }

  // 原因となったエラーレスポンスに含まれるエラーコードを返す
  // エラーレスポンスが予期しない形式の場合はundefinedを返す
  code(): ErrorCodeEnum | undefined {
    return this.errorResponse.code
  }

  // 原因となったエラーレスポンスに含まれるエラーメッセージを返す
  // エラーレスポンスが予期しない形式の場合は認証切れのメッセージを返す
  userMessage(): string {
    return this.errorResponse.errors.join('\n')
  }
}

type LoginUserContext = {
  companyId: number
  companyName: string
  contractPlan: CompanyContractPlan
  userLoginId: string
  userName: string
  userPlaceNodes: PlaceNode[]
  userRole: AppRoleEnum
  userUuid: string
  rumpRealmId: string
  rumpCustomerMemberId: string
  isRumpRealmAdmin: boolean
}

type Options = {
  noCache?: boolean
}

const noCacheConfig = {
  staleTime: 0,
  gcTime: 0,
} as const

export const useQueryLoginUserContext = (options?: Options) => {
  const { noCache = false } = options ?? {}

  return useQuery({
    queryKey: commonQueryKeys.loginUserContext,
    queryFn: async (): Promise<GetLoginUserContextResponse> => {
      try {
        return await baseClient.getApi(LoginUserApi).getLoginUserContext()
      } catch (e) {
        if (
          e instanceof Response &&
          (e.status === 400 || e.status === 401 || e.status === 403)
        ) {
          const respBody = await e.json()
          if (isHTTPError(respBody)) {
            throw new AuthError(
              respBody,
              `GET /loginUser/context failed: status=${e.status}, code=${e.statusText}`,
            )
          }
        }
        // 未知のエラー
        throw e
      }
    },
    select: (data): LoginUserContext => {
      return {
        contractPlan: data.contractPlan,
        companyId: data.companyId,
        companyName: data.companyName,
        userLoginId: data.userLoginId,
        userName: data.userName,
        userPlaceNodes: data.placeNodes,
        userRole: data.userRole,
        userUuid: data.userUuid,
        rumpRealmId: data.rumpRealmId,
        rumpCustomerMemberId: data.rumpCustomerMemberId,
        isRumpRealmAdmin: data.isRumpRealmAdmin,
      }
    },
    ...(noCache && noCacheConfig),
  })
}
