import { EditOutlined as PencilIcon } from '@ant-design/icons'
import { css } from '@emotion/react'
import { ReportStatusEnum } from '@ulysses-inc/harami_api_client'
import { Input } from 'antd'
import React, { useState } from 'react'
import { Text } from 'src/components/nativeCompat/Text'
import { View } from 'src/components/nativeCompat/View'
import getCalculateScorePercentage from 'src/exShared/hooks/reports/getCalculateScorePercentage'
import formatReportDate from 'src/exShared/util/reportResult/formatReportDate'
import { WebReportResultProps } from 'src/features/reports/result/WebReportResultProps'
import {
  Black,
  DisableButtonColor,
  Gray,
  PlaceholderText,
  Primary,
  White,
} from 'src/features/theme/KdsThemeColor'
import { Divider } from './Divider'

const { TextArea } = Input

export const Overview: React.FC<WebReportResultProps> = (
  props: WebReportResultProps,
) => {
  const {
    report,
    isOffline,
    reportId,
    deviatedAnswers,
    allPagesChildNodes,
    addReportComment,
  } = props
  const { sumAllTotalScores, sumAllResultScores, calculateScorePercentage } =
    getCalculateScorePercentage(allPagesChildNodes(true))

  const reportName = report.name
  const userName = report.assignee?.name ?? ''

  const statusName = getStatusName(report.status.status)

  const [newestComment, setNewestComment] = useState<string>(report.comment)
  const isCommentChanged = report.comment !== newestComment
  const isSaveButtonActive = isCommentChanged && !isOffline

  const onChangeText = (text: string) => {
    setNewestComment(text)
  }

  const onPressSaveButton = () => {
    addReportComment(reportId, newestComment)
  }

  return (
    <View id="Overview" css={styles.section}>
      <Text large style={{ fontWeight: 'bold' }}>
        概要
      </Text>
      <View css={styles.card}>
        <div css={styles.row}>
          <View css={styles.description}>
            <Text large style={{ marginBottom: 8, fontWeight: 'bold' }}>
              {reportName}
            </Text>
            <View css={styles.execution}>
              {report?.reportDate ? (
                <Text small style={{ color: Gray }}>
                  実施日：
                  {formatReportDate(report?.reportDate, report?.schedules)}
                </Text>
              ) : (
                <Text>実施なし</Text>
              )}
              <Text small style={{ color: Gray }}>
                現場： {report?.place?.name ?? ''}
              </Text>
              <Text small style={{ color: Gray }}>
                ユーザー：{userName}
              </Text>
            </View>
          </View>
          <View css={styles.result}>
            <div css={styles.scoreBlock}>
              <Text small>スコア</Text>
              <View css={styles.score}>
                <Text style={{ width: '100%', textAlign: 'center' }}>
                  <Text large style={{ fontWeight: 'bold' }}>
                    {report.isShowScore
                      ? `${sumAllResultScores} / ${sumAllTotalScores} (${calculateScorePercentage}%)`
                      : 'ー'}
                  </Text>
                </Text>
              </View>
            </div>
            <Divider />
            <div css={styles.row}>
              <div css={styles.statusBlock}>
                <View css={styles.summaryHeader}>
                  <Text small style={{ color: White }}>
                    ステータス
                  </Text>
                </View>
                <View css={styles.summaryBody}>
                  <Text>{statusName}</Text>
                </View>
              </div>
              <div css={styles.deviationBlock}>
                <View css={styles.summaryHeader}>
                  <Text small style={{ color: White }}>
                    逸脱項目
                  </Text>
                </View>
                <View css={styles.summaryBody}>
                  <Text
                    style={{
                      color: deviatedAnswers.length ? '#EB5757' : Black,
                    }}
                  >
                    {deviatedAnswers.length}
                  </Text>
                </View>
              </div>
            </div>
          </View>
        </div>
        <Divider />
        <div style={{ width: '100%', padding: 16 }}>
          <Text style={{ width: '100%' }}>
            コメント
            <PencilIcon css={styles.pencilIcon} />
          </Text>
          <TextArea
            autoSize={{ minRows: 6, maxRows: 30 }}
            onChange={e => onChangeText(e.target.value)}
            placeholder="コメントを入力"
            css={styles.commentTextArea}
            value={newestComment}
          />
        </div>
      </View>

      <View style={{ width: '100%', height: 60 }}>
        <div style={{ width: '100%' }}>
          <button
            style={{
              backgroundColor: isSaveButtonActive
                ? Primary
                : DisableButtonColor,
              width: 110,
              marginTop: 8,
              position: 'absolute',
              right: 0,
              top: 2,
              color: White,
              borderRadius: 4,
              border: '0.3px solid #ccc',
              cursor: isSaveButtonActive ? 'pointer' : 'default',
              lineHeight: '2rem',
            }}
            onClick={onPressSaveButton}
            disabled={!isCommentChanged || isOffline}
          >
            保存
          </button>
        </div>
      </View>
    </View>
  )
}

const getStatusName = (status?: ReportStatusEnum) => {
  switch (status) {
    case ReportStatusEnum.INCOMPLETE:
      return '未完了'
    case ReportStatusEnum.COMPLETE:
      return '完了'
    case ReportStatusEnum.APPROVAL_PENDING:
      return '承認待ち'
    case ReportStatusEnum.APPROVAL_REMAND:
      return '差し戻し'
    default:
      return ''
  }
}

const styles = {
  row: css`
    display: flex;
    flex: 1 1 0;
    flex-direction: row;
  `,
  scoreBlock: css`
    display: flex;
    flex-direction: column;
    padding: 16px;
  `,
  statusBlock: css`
    display: flex;
    flex: 1 1 0;
    flex-direction: column;
    border-right: 1px solid #d6d7da;
  `,
  deviationBlock: css`
    display: flex;
    flex: 1 1 0;
    flex-direction: column;
  `,
  commentTextArea: css`
    border: none;
    color: Black;
    font-size: 16px;
    font-weight: bold;
    line-height: 20px !important;
    margin: 0;
    padding-bottom: 4px;
    padding-top: 4px;
    resize: none;
    text-align: left;

    :focus {
      outline: -webkit-focus-ring-color auto 1px;
    }

    ::placeholder {
      color: ${PlaceholderText};
    }
  `,
  pencilIcon: css`
    color: ${Primary};
  `,
  section: css`
    flex: 1;
    flex-direction: column;
    width: 100%;
    margin-top: 0;
  `,
  card: css`
    margin-top: 8px;
    background-color: ${White};
    border-radius: 8px;
    border-width: 0.5px;
    border-color: #d6d7da;
  `,
  description: css`
    min-height: 160px;
    width: 50%;
    padding: 16px;
    padding-bottom: 10px;
    border-right-width: 1px;
    border-right-color: #d6d7da;
  `,
  execution: css`
    margin-top: 4px;
  `,
  summaryHeader: css`
    height: 20px;
    background-color: ${Primary};
    flex: 1;
    justify-content: center;
    align-items: center;
  `,
  summaryBody: css`
    height: 50px;
    flex: 1;
    justify-content: center;
    align-items: center;
  `,
  result: css`
    width: 50%;
  `,
  score: css`
    flex: 1;
    justify-content: center;
  `,
}
