import {
  Image,
  Improve,
  IndicatedIssue,
  IndicatedIssueGroup,
  IndicatedIssueLabel,
  IndicatedIssueReviseImage,
  IndicatedIssueStatusEnum,
  ReportQuestion,
} from '@ulysses-inc/harami_api_client'
import { Button, Modal, Radio } from 'antd'
import { RadioChangeEvent } from 'antd/lib/radio'
import { FormikHandlers, FormikHelpers } from 'formik'
import moment from 'moment'
import React, { useState } from 'react'
import TrashBinSvg from 'src/assets/icons/trashbin.svg?react'
import { MomentDatePicker } from 'src/components/datepicker/MomentDatePicker'
import FormikErrorMessage from 'src/components/formikErrorMessage/FormikErrorMessage'
import date from 'src/exShared/util/date'
import IndexColumn from 'src/features/improves/improveDetail/listItem/IndexColumn'
import LabelColumn from 'src/features/improves/improveDetail/listItem/LabelColumn'
import QuestionBindingColumn from 'src/features/improves/improveDetail/listItem/QuestionBindingColumn'
import RejectCommentColumn from 'src/features/improves/improveDetail/listItem/RejectCommentColumn'
import ReviseCommentColumn from 'src/features/improves/improveDetail/listItem/ReviseCommentColumn'
import StatusColumn from 'src/features/improves/improveDetail/listItem/StatusColumn'
import TextArea from 'src/features/improves/improveDetail/listItem/TextArea'
import PicturesWall from 'src/features/improves/improveDetail/listItem/picturesWall/PicturesWall'
import RevisePicturesWall from 'src/features/improves/improveDetail/listItem/revisePicturesWall/RevisePicturesWall'
import { ModalDeleteButtonColor, White } from 'src/features/theme/KdsThemeColor'
import styled from 'styled-components'

const Colors = {
  border: '#E9E9E9',
  thickborder: '#D9D9D9',
  background: White,
}

const TrashBinIcon = styled(TrashBinSvg)`
  font-size: 14px;
`

const Row = styled.div`
  display: flex;
`

const ColumnNoLeftBorder = styled.div<{ flex: number }>`
  flex: ${({ flex }) => `0 0 ${flex}px`};
  font-size: 14px;
  padding: 7.5px 8px;
  overflow: hidden;
  border-bottom: 1px solid ${Colors.border};
  align-items: flex-start;
  display: flex;
  background-color: ${Colors.background};
`

const Column = styled.div<{ flex: number; fontSize: number }>`
  flex: ${({ flex }) => `0 0 ${flex}px`};
  font-size: ${({ fontSize }) => `${fontSize}px`};
  padding: 12px 8px;
  overflow: hidden;
  border-left: 1px solid ${Colors.border};
  border-bottom: 1px solid ${Colors.border};
  align-items: flex-start;
  display: flex;
  background-color: ${Colors.background};
`

const ColumnWided = styled.div<{ flex: number }>`
  flex: ${({ flex }) => `0 0 ${flex}px`};
  font-size: 14px;
  overflow: hidden;
  border-left: 1px solid ${Colors.border};
  border-bottom: 1px solid ${Colors.border};
  align-items: center;
  display: flex;
  background-color: ${Colors.background};
`

const Error = styled(FormikErrorMessage)`
  font-size: 14px;
`

const HeaderButton = styled(Button)`
  align-items: center;
`

const IsRequiredRadio = styled(Radio)`
  font-size: 14px;
  margin-bottom: 8px;
`

const NoDueDateSavedText = styled.div`
  padding: 12px 0 0 8px;
  align-self: flex-start;
  color: #d9d9d9;
`
const DueDateText = styled.div`
  padding: 12px 0 0 8px;
  align-self: flex-start;
  color: #000000;
`

const ColumnItemBox = styled.div<{
  flex: number
  width: number
  isFirst: boolean
  isLast: boolean
  isLastItem: boolean
  isBorderTopRequired: boolean
}>`
  flex: ${({ flex }) => `0 0 ${flex}px`};
  border-left: ${({ width, isFirst }) =>
    isFirst ? `${width}px solid ${Colors.thickborder}` : ``};
  border-right: ${({ width, isLast }) =>
    isLast
      ? `${width}px solid ${Colors.thickborder}`
      : `0px solid ${Colors.thickborder}`};
  border-bottom: ${({ width, isLastItem }) =>
    isLastItem
      ? `${width}px solid ${Colors.thickborder}`
      : `0px solid ${Colors.thickborder}`};
  border-top: ${({ isBorderTopRequired }) =>
    isBorderTopRequired
      ? `3px solid ${Colors.thickborder}`
      : `0px solid ${Colors.thickborder}`};
  display: flex;
  overflow: hidden;
`

const ReviseColumnItemBox = styled.div<{
  flex: number
  width: number
  isFirst: boolean
  isLast: boolean
  isLastItem: boolean
}>`
  flex: ${({ flex }) => `0 0 ${flex}px`};
  border-left: ${({ width, isFirst }) =>
    isFirst ? `${width}px solid ${Colors.thickborder}` : ``};
  border-right: ${({ width, isLast }) =>
    isLast
      ? `${width}px solid ${Colors.thickborder}`
      : `0px solid ${Colors.thickborder}`};
  border-bottom: ${({ width, isLastItem }) =>
    isLastItem
      ? `${width}px solid ${Colors.thickborder}`
      : `0px solid ${Colors.thickborder}`};
  display: flex;
  overflow: hidden;
`

const TimePickerArea = styled.div<{
  width: number
}>`
  display: flex;
  align-items: top;
  height: 100%;
  margin-right: ${({ width }) => `${width}px`};
`

const dateFormat = 'YYYY/MM/DD'

type OwnProps = {
  item: IndicatedIssueGroup
  indicatedIssueLabels: IndicatedIssueLabel[]
  reportQuestions: ReportQuestion[]
  improveUUID: string
  reportUUID: string
  placeUserGroupId: number
  index: number
  handleChange: FormikHandlers['handleChange']
  setFieldTouched: FormikHelpers<Improve>['setFieldTouched']
  setFieldValue: FormikHelpers<Improve>['setFieldValue']
  resetField: (name: string) => void
  isAuditor: boolean
  isPlaceUser: boolean
  isAuditorWorking: boolean
  isEditing: boolean
  isFirstAudit: boolean
  isRejectStatus: boolean
  errors?: any
  touched?: any
  deleteIndicatedIssue: () => void
  entries: {
    [key: string]: {
      width: number
      title: string
      visible: boolean
    }
  }
  entriesRevise: {
    [key: string]: {
      width: number
      title: string
      visible: boolean
    }
  }
  widths: {
    [key: string]: {
      width: number
      visible: boolean
    }
  }
  isFirst: boolean
  isLast: boolean
  isCompleted: boolean
}

const ImproveListItem: React.FC<OwnProps> = (props: OwnProps) => {
  const [isDirtyReviseComment, setIsDirtyReviseComment] = useState(false)

  if (!props.item.indicatedIssues || !props.item.indicatedIssues.length) {
    return null
  }
  const lastIndicatedIssueIndex = props.item.indicatedIssues.length - 1
  const indicatedIssue: IndicatedIssue =
    props.item.indicatedIssues[lastIndicatedIssueIndex]
  if (!indicatedIssue) {
    return null
  }
  const rejectExists = !!indicatedIssue.rejectComment?.comment?.length
  const FieldName = `indicatedIssueGroups[${props.index}]['indicatedIssues'][${lastIndicatedIssueIndex}]`
  const isAuditorOnly =
    props.isAuditorWorking && (props.isFirstAudit || props.isEditing)
  const isPlaceUserOnly =
    props.isPlaceUser && !props.isAuditorWorking && !props.isCompleted
  const setFieldIndicateIssueStatusValue = (bool: boolean) => {
    const value = bool
      ? IndicatedIssueStatusEnum.Incomplete
      : IndicatedIssueStatusEnum.Completed
    props.setFieldValue(`${FieldName}['status']['status']`, value)
    if (bool && !rejectExists) {
      props.setFieldValue(
        `${FieldName}['rejectComment']['comment']`,
        '改善が見られませんでした',
      )
    }
    if (!bool) {
      props.resetField(`${FieldName}['rejectComment']['comment']`)
    }
  }
  const setFieldImagesValue = (images: Image[]) => {
    props.setFieldValue(`${FieldName}['images']`, images)
    props.setFieldTouched(`${FieldName}['images']`, true, false)
  }
  const addFieldImagesValue = (image: Image, index: number) => {
    props.setFieldValue(`${FieldName}['images'][${index}]`, image)
    props.setFieldTouched(`${FieldName}['images']`, true, false)
  }
  const setFieldReviseImagesValue = (images: IndicatedIssueReviseImage[]) => {
    if (!indicatedIssue.revise?.indicatedIssueUUID) {
      props.setFieldValue(
        `${FieldName}['revise']['indicatedIssueUUID']`,
        indicatedIssue.uuid,
      )
    }
    props.setFieldValue(`${FieldName}['revise']['images']`, images)
    props.setFieldTouched(`${FieldName}['revise']['images']`, true, false)
  }
  const addFieldReviseImagesValue = (
    image: IndicatedIssueReviseImage,
    index: number,
  ) => {
    if (!indicatedIssue.revise?.indicatedIssueUUID) {
      props.setFieldValue(
        `${FieldName}['revise']['indicatedIssueUUID']`,
        indicatedIssue.uuid,
      )
    }
    props.setFieldValue(`${FieldName}['revise']['images'][${index}]`, image)
    props.setFieldTouched(`${FieldName}['revise']['images']`, true, false)
  }
  const boxBorderWidth = props.isAuditorWorking && !props.isRejectStatus ? 3 : 0
  const boxBorderWidthRevise =
    !props.isAuditorWorking && !props.isCompleted ? 3 : 0
  const lastAuditItemName = props.entries.rejectComment.visible
    ? 'rejectComment'
    : 'isRequired'
  const isRejectEditStatus = props.isRejectStatus && props.isEditing
  const isBorderTopRequired = isRejectEditStatus && props.isFirst
  const boxBorderWidthReject = isRejectEditStatus ? 3 : 0

  const disabledDate = (current: moment.Moment): boolean => {
    // Can not select days before today
    return current && current.toDate() < date.addDays(date.today(), -1)
  }
  const isRequiredText = indicatedIssue.isRequired ? '必須' : '任意'

  const dueDate =
    indicatedIssue?.dueDate?.dueDate &&
    date.formatYYYYMMDD(indicatedIssue?.dueDate?.dueDate) != '0001/01/01'
      ? moment(indicatedIssue?.dueDate?.dueDate)
      : undefined

  const reviseDueDate =
    indicatedIssue.revise?.dueDate?.dueDate &&
    date.formatYYYYMMDD(indicatedIssue?.revise?.dueDate?.dueDate) !=
      '0001/01/01'
      ? moment(indicatedIssue?.revise?.dueDate?.dueDate)
      : undefined
  const renderDueDate = dueDate ? (
    <DueDateText>{dueDate.format(dateFormat)}</DueDateText>
  ) : (
    <NoDueDateSavedText>改善期限</NoDueDateSavedText>
  )
  const renderReviseDueDate = reviseDueDate ? (
    <DueDateText>{reviseDueDate.format(dateFormat)}</DueDateText>
  ) : (
    <NoDueDateSavedText>改善確認期限</NoDueDateSavedText>
  )

  return (
    <Row>
      <ColumnNoLeftBorder flex={props.widths.remove.width}>
        <HeaderButton
          icon={<TrashBinIcon />}
          type="text"
          onClick={() => {
            Modal.confirm({
              onOk: () => props.deleteIndicatedIssue(),
              title: `「No${
                props.index + 1
              }」の削除を実行しますがよろしいですか？`,
              okText: '削除',
              cancelText: 'キャンセル',
              okButtonProps: {
                style: {
                  backgroundColor: ModalDeleteButtonColor,
                  border: 'none',
                },
              },
            })
          }}
          disabled={!isAuditorOnly}
        ></HeaderButton>
      </ColumnNoLeftBorder>
      <ColumnItemBox
        flex={props.widths.no.width}
        width={boxBorderWidthReject}
        isFirst={true}
        isLast={true}
        isBorderTopRequired={isBorderTopRequired}
        isLastItem={props.isLast}
      >
        <Column flex={props.widths.no.width} fontSize={14}>
          <IndexColumn
            isRejectEditStatus={isRejectEditStatus}
            index={props.index + 1}
            checked={
              indicatedIssue.status?.status ===
              IndicatedIssueStatusEnum.Incomplete
            }
            isDisabled={indicatedIssue.revise === undefined}
            setFieldIndicateIssueStatusValue={setFieldIndicateIssueStatusValue}
          />
        </Column>
      </ColumnItemBox>
      {props.widths.status.visible && (
        <Column flex={props.widths.status.width} fontSize={14}>
          <StatusColumn
            isAuditorWorking={props.isAuditorWorking}
            rejectExists={rejectExists}
            reviseExists={!!indicatedIssue.revise?.comment?.length}
            isRejectEditStatus={isRejectEditStatus}
            status={indicatedIssue.status?.status}
          />
        </Column>
      )}
      <ColumnItemBox
        flex={props.entries.dueDate.width}
        width={Math.max(boxBorderWidth, boxBorderWidthReject)}
        isFirst={true}
        isLast={props.isRejectStatus}
        isBorderTopRequired={isBorderTopRequired}
        isLastItem={props.isLast}
      >
        <ColumnWided flex={props.entries.dueDate.width}>
          <TimePickerArea width={boxBorderWidth}>
            {!isAuditorOnly ? (
              renderDueDate
            ) : (
              <MomentDatePicker
                format={dateFormat}
                disabled={!isAuditorOnly}
                suffixIcon={null}
                style={{
                  padding: '12px 0 0 3px',
                  alignItems: 'flex-start',
                  height: '40px',
                }}
                bordered={false}
                disabledDate={disabledDate}
                value={dueDate}
                onChange={date => {
                  props.setFieldValue(
                    `${FieldName}['dueDate']['dueDate']`,
                    date ? date?.toDate() : undefined,
                  )
                }}
                placeholder="改善期限"
              />
            )}
          </TimePickerArea>
        </ColumnWided>
      </ColumnItemBox>
      <ColumnItemBox
        flex={props.entries.questionBinding.width}
        width={boxBorderWidth}
        isFirst={false}
        isLast={false}
        isBorderTopRequired={false}
        isLastItem={props.isLast}
      >
        <Column flex={props.entries.questionBinding.width} fontSize={14}>
          <QuestionBindingColumn
            defaultQuestion={
              props.reportQuestions.filter(
                question =>
                  question.reportNodeUuid ===
                  indicatedIssue.reportQuestion?.reportNodeUUID,
              )[0]
            }
            questions={props.reportQuestions}
            disabled={!isAuditorOnly}
            setQuestionValue={question => {
              if (question) {
                props.setFieldValue(
                  `${FieldName}['reportQuestion']['reportNodeId']`,
                  question.reportNodeId,
                )
                props.setFieldValue(
                  `${FieldName}['reportQuestion']['reportNodeUUID']`,
                  question.reportNodeUuid?.toString(),
                )
                props.setFieldValue(
                  `${FieldName}['reportQuestion']['reportUUID']`,
                  props.reportUUID,
                )
              } else {
                props.setFieldValue(`${FieldName}['reportQuestion']`, null)
              }
            }}
          />
        </Column>
      </ColumnItemBox>
      <ColumnItemBox
        flex={props.entries.indicatedIssueImages.width}
        width={boxBorderWidth}
        isFirst={false}
        isLast={false}
        isBorderTopRequired={false}
        isLastItem={props.isLast}
      >
        <ColumnWided flex={props.entries.indicatedIssueImages.width}>
          <PicturesWall
            setFieldImagesValue={setFieldImagesValue}
            addFieldImagesValue={addFieldImagesValue}
            images={indicatedIssue.images || []}
            disabled={!isAuditorOnly}
            errorMessage={
              <Error
                name={`${FieldName}['images']`}
                errors={props.errors}
                touched={props.touched}
              />
            }
          />
        </ColumnWided>
      </ColumnItemBox>
      <ColumnItemBox
        flex={props.entries.indicatedIssueComment.width}
        width={boxBorderWidth}
        isFirst={false}
        isLast={false}
        isBorderTopRequired={false}
        isLastItem={props.isLast}
      >
        <ColumnWided flex={props.entries.indicatedIssueComment.width}>
          <TextArea
            placeholder="指摘内容を入力"
            name={`${FieldName}['comment']`}
            defaultValue={indicatedIssue.comment}
            disabled={!isAuditorOnly}
            onChange={(e: React.ChangeEvent<any>) => {
              props.setFieldValue(`${FieldName}['comment']`, e.target.value)
              props.setFieldTouched(`${FieldName}['comment']`, true, false)
            }}
            rows={2}
            errorMessage={
              <Error
                name={`${FieldName}['comment']`}
                errors={props.errors}
                touched={props.touched}
              />
            }
          />
        </ColumnWided>
      </ColumnItemBox>
      <ColumnItemBox
        flex={props.entries.indicatedIssueLabels.width}
        width={boxBorderWidth}
        isFirst={false}
        isLast={false}
        isBorderTopRequired={false}
        isLastItem={props.isLast}
      >
        <Column flex={props.entries.indicatedIssueLabels.width} fontSize={12}>
          <LabelColumn
            defaultLabels={indicatedIssue.labels || []}
            labels={props.indicatedIssueLabels}
            disabled={!isAuditorOnly}
            setLabelValue={labels => {
              props.setFieldValue(`${FieldName}['labels']`, labels)
            }}
          />
        </Column>
      </ColumnItemBox>
      <ColumnItemBox
        flex={props.entries.isRequired.width}
        width={boxBorderWidth}
        isFirst={false}
        isLast={lastAuditItemName === 'isRequired' ? true : false}
        isBorderTopRequired={false}
        isLastItem={props.isLast}
      >
        <Column flex={props.entries.isRequired.width} fontSize={14}>
          {isAuditorOnly ? (
            <Radio.Group
              name={`${FieldName}['isRequired']`}
              disabled={!isAuditorOnly}
              onChange={(e: RadioChangeEvent) => {
                props.handleChange(e)
              }}
              defaultValue={indicatedIssue.isRequired}
            >
              <IsRequiredRadio value={1}>必須</IsRequiredRadio>
              <IsRequiredRadio value={0}>任意</IsRequiredRadio>
            </Radio.Group>
          ) : (
            <div>{isRequiredText}</div>
          )}
        </Column>
      </ColumnItemBox>
      {props.entries.rejectComment.visible && (
        <ColumnItemBox
          flex={props.entries.rejectComment.width}
          width={Math.max(boxBorderWidth, boxBorderWidthReject)}
          isFirst={props.isRejectStatus}
          isLast={lastAuditItemName === 'rejectComment' ? true : false}
          isBorderTopRequired={isBorderTopRequired}
          isLastItem={props.isLast}
        >
          <ColumnWided flex={props.entries.rejectComment.width}>
            <RejectCommentColumn
              placeholder="差し戻しコメントを入力"
              name={`${FieldName}['rejectComment']['comment']`}
              value={indicatedIssue.rejectComment?.comment}
              disabled={
                !isAuditorOnly ||
                indicatedIssue.status?.status !==
                  IndicatedIssueStatusEnum.Incomplete ||
                indicatedIssue.revise === undefined
              }
              active={
                indicatedIssue.status?.status ===
                  IndicatedIssueStatusEnum.Incomplete &&
                (!props.isAuditorWorking ||
                  rejectExists ||
                  (props.isEditing && indicatedIssue.revise !== undefined))
              }
              onChange={(e: React.ChangeEvent<any>) => {
                props.setFieldValue(
                  `${FieldName}['rejectComment']['comment']`,
                  e.target.value,
                )
                props.setFieldTouched(
                  `${FieldName}['rejectComment']['comment']`,
                  true,
                  false,
                )
              }}
              rows={2}
              errorMessage={
                indicatedIssue?.status?.status ===
                IndicatedIssueStatusEnum.Incomplete ? (
                  <Error
                    name={`${FieldName}['rejectComment']['comment']`}
                    errors={props.errors}
                    touched={props.touched}
                  />
                ) : (
                  <React.Fragment />
                )
              }
            />
          </ColumnWided>
        </ColumnItemBox>
      )}
      <ReviseColumnItemBox
        flex={props.entriesRevise.reviseDueDate.width}
        width={boxBorderWidthRevise}
        isFirst={true}
        isLast={false}
        isLastItem={props.isLast}
      >
        <ColumnWided flex={props.entriesRevise.reviseDueDate.width}>
          <TimePickerArea width={boxBorderWidthRevise}>
            {!isPlaceUserOnly ? (
              renderReviseDueDate
            ) : (
              <MomentDatePicker
                format={dateFormat}
                disabled={!isPlaceUserOnly}
                suffixIcon={null}
                style={{
                  padding: '12px 0 0 3px',
                  alignItems: 'flex-start',
                  height: '40px',
                }}
                bordered={false}
                disabledDate={disabledDate}
                value={reviseDueDate}
                onChange={date => {
                  props.setFieldValue(
                    `${FieldName}['revise']['dueDate']['dueDate']`,
                    date ? date?.toDate() : undefined,
                  )
                  if (date) {
                    props.setFieldValue(
                      `${FieldName}['revise']['dueDate']['userGroupId']`,
                      props.placeUserGroupId,
                    )
                    props.setFieldValue(
                      `${FieldName}['revise']['dueDate']['improveUUID']`,
                      props.improveUUID,
                    )
                    props.setFieldValue(
                      `${FieldName}['revise']['indicatedIssueUUID']`,
                      indicatedIssue.uuid,
                    )
                  }
                }}
                placeholder="改善確認期限"
              />
            )}
          </TimePickerArea>
        </ColumnWided>
      </ReviseColumnItemBox>
      <ReviseColumnItemBox
        flex={props.entriesRevise.reviseImages.width}
        width={boxBorderWidthRevise}
        isFirst={false}
        isLast={false}
        isLastItem={props.isLast}
      >
        <ColumnWided flex={props.entriesRevise.reviseImages.width}>
          <RevisePicturesWall
            setFieldImagesValue={setFieldReviseImagesValue}
            addFieldImagesValue={addFieldReviseImagesValue}
            reviseImages={indicatedIssue.revise?.images || []}
            disabled={!isPlaceUserOnly}
            errorMessage={
              <Error
                name={`${FieldName}['revise']['images']`}
                errors={props.errors}
                touched={props.touched}
              />
            }
          />
        </ColumnWided>
      </ReviseColumnItemBox>
      <ReviseColumnItemBox
        flex={props.entriesRevise.reviseComment.width}
        width={boxBorderWidthRevise}
        isFirst={false}
        isLast={true}
        isLastItem={props.isLast}
      >
        <ColumnWided flex={props.entriesRevise.reviseComment.width}>
          <ReviseCommentColumn
            placeholder="改善内容を入力"
            name={`${FieldName}['revise']['comment']`}
            value={indicatedIssue.revise?.comment ?? ''}
            disabled={!isPlaceUserOnly}
            onChange={(e: React.ChangeEvent<any>) => {
              props.handleChange(e)
              props.setFieldTouched(
                `${FieldName}['revise']['comment']`,
                true,
                false,
              )
              props.setFieldValue(
                `${FieldName}['revise']['indicatedIssueUUID']`,
                indicatedIssue.uuid,
              )
              setIsDirtyReviseComment(true)
            }}
            active={
              indicatedIssue.status?.status ===
                IndicatedIssueStatusEnum.Incomplete &&
              !props.isAuditorWorking &&
              !isDirtyReviseComment &&
              indicatedIssue.isRequired === 1
            }
            rows={2}
            errorMessage={
              <Error
                name={`${FieldName}['revise']['comment']`}
                errors={props.errors}
                touched={props.touched}
              />
            }
          />
        </ColumnWided>
      </ReviseColumnItemBox>
    </Row>
  )
}

export default ImproveListItem
