import { PlaceNode } from '@ulysses-inc/harami_api_client'
import { localStorageKeys } from 'src/constants/localStorageKeys'
import date from 'src/exShared/util/date'
import { mergedPlaceNodeIds } from 'src/exShared/util/place/placeNode'
import { LocalStorageService } from '../../libraries/localStorage'
import { ListFilter, ReportsFilter } from '../../views/types/utils/ListFilter'

export const useReportsListFilter = () => {
  const localStorageService = new LocalStorageService<ListFilter>()
  const requests = localStorageService.getItem(localStorageKeys.listFilter)
  const reportsRequest = requests?.reports

  const setListFilter = (requests: ListFilter) => {
    localStorageService.setItem(localStorageKeys.listFilter, requests)
  }

  const saveReportsRequest = (conditions: ReportsFilter) => {
    const updatedFilters =
      requests === null
        ? {
            reports: {
              filter: conditions,
            },
          }
        : {
            ...requests,
            reports: {
              pagination: requests.reports?.pagination,
              filter: conditions,
            },
          }
    setListFilter(updatedFilters)
  }

  return {
    reportsRequest,
    saveReportsRequest,
  }
}

export const getReportFilter = (
  conditions: ReportsFilter,
  placeNodes: PlaceNode[],
  visibleStatusFilter: boolean,
  visibleEmployeeFilter: boolean,
) => {
  const allPlaceNodeIds = mergedPlaceNodeIds(
    placeNodes,
    conditions.placeNodeIds ?? [],
    conditions.placeGroupNodeIds ?? [],
  )
  const reportNameCondition =
    conditions.reportName && conditions.reportName.length > 0
      ? { $like: conditions.reportName }
      : undefined
  const templateIdCondition =
    conditions.templateIds && conditions.templateIds.length > 0
      ? { $in: conditions.templateIds }
      : undefined
  const placeNodeIdCondition =
    allPlaceNodeIds && allPlaceNodeIds.length > 0
      ? { $in: allPlaceNodeIds }
      : undefined
  // throughは画面表示の日付＋１日する
  const reportDateCondition =
    conditions.from && conditions.through
      ? {
          $gte: date.parseYYYYMMDD(conditions.from),
          $lte: date.nextDay(date.parseYYYYMMDD(conditions.through)),
        }
      : undefined
  const statusCondition =
    visibleStatusFilter && conditions.statuses && conditions.statuses.length > 0
      ? { $in: conditions.statuses }
      : undefined
  const isInvalidCondition = conditions.isInvalid
    ? { $in: [conditions.isInvalid ?? 0] }
    : undefined
  const employeeCondition =
    visibleEmployeeFilter &&
    conditions.employee !== undefined &&
    conditions.employee.length > 0
      ? { $like: conditions.employee }
      : undefined
  return {
    reportName: reportNameCondition,
    templateId: templateIdCondition,
    placeNodeId: placeNodeIdCondition,
    reportDate: reportDateCondition,
    status: statusCondition,
    isInvalid: isInvalidCondition,
    answer: {
      employee: employeeCondition,
    },
  }
}
