import { StickyCell } from '../EditGridVariables.styled'

type Props = {
  /** 行番号 */
  rowNumber: number
}

/**
 * 読み取り専用の行番号セル
 */
export const RowNumberCell = ({ rowNumber }: Props) => {
  return (
    <StickyCell>
      <div style={{ userSelect: 'none' }}>{rowNumber}</div>
    </StickyCell>
  )
}
