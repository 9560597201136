import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { v4 as uuidv4 } from 'uuid'

export type NotificationType = 'success' | 'error'

type NotificationServiceState = {
  duration: number
  message: string
  /**
   * 同一メッセージのNotificationが連続して発出されたときに各メッセージを区別するために必要
   */
  messageId: string
  notificationType: NotificationType
}

const initialState: NotificationServiceState = {
  duration: 0,
  message: '',
  messageId: '',
  notificationType: 'success',
}

const slice = createSlice({
  name: 'harami/notificationService',
  initialState,
  reducers: {
    showNotification: (
      state,
      action: PayloadAction<{
        duration?: number
        message: string
        notificationType?: NotificationType
      }>,
    ) => {
      const {
        duration = 2,
        message,
        notificationType = 'success',
      } = action.payload

      state.duration = duration
      state.message = message
      state.messageId = uuidv4()
      state.notificationType = notificationType
    },
  },
})

export const { showNotification } = slice.actions
export default slice.reducer
