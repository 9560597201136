// prettier-ignore
export const ActionTypes = {
  RESET_TEMPLATE_HINTS: 'harami/templates/RESET_TEMPLATE_HINTS',
  REQUEST_GET_TEMPLATE_HINTS: 'harami/templates/REQUEST_GET_TEMPLATE_HINTS',
  SUCCESS_GET_TEMPLATE_HINTS: 'harami/templates/SUCCESS_GET_TEMPLATE_HINTS',
  ERROR_GET_TEMPLATE_HINTS: 'harami/templates/ERROR_GET_TEMPLATE_HINTS',
  SUCCESS_ADD_TEMPLATE_HINTS: 'harami/templates/SUCCESS_ADD_TEMPLATE_HINTS',
  ADD_TEMPLATE_HINT: 'harami/templates/ADD_TEMPLATE_HINT',
  UPDATE_TEMPLATE_HINT: 'harami/templates/UPDATE_TEMPLATE_HINT',
  DELETE_TEMPLATE_HINT: 'harami/templates/DELETE_TEMPLATE_HINT',
  SET_TEMPLATE_HINT_ERROR_MESSAGE: 'harami/templates/SET_TEMPLATE_HINT_ERROR_MESSAGE',
} as const
