import { UserGroup } from '@ulysses-inc/harami_api_client'
import { Button, Drawer, Row, Select } from 'antd'
import { LabeledValue } from 'antd/lib/select'
import { useFormik } from 'formik'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Disable } from 'src/features/theme/KdsThemeColor'
import Yup from 'src/features/validation/yup'
import { updateTemplateNode } from 'src/state/ducks/templates/actions'
import { closeEditDeviateNotify } from 'src/state/ducks/templates/editDeviateNotify/actions'
import { RootState } from 'src/state/store'
import styled from 'styled-components'

const validationSchema = Yup.object().shape({
  userGroups: Yup.array().label('ユーザーグループ'),
})

const EditDeviateNotifyDrawer: React.FC = () => {
  const dispatch = useDispatch()

  const {
    // 編集対象の質問ノード
    questionNode,
    isOpenModal: isOpen,
  } = useSelector((state: RootState) => state.templatesState.editDeviateNotify)

  const allUserGroups = useSelector(
    (state: RootState) => state.usersState.userGroups.userGroups || [],
  )

  const currentlyAssignedUserGroups =
    questionNode?.question?.deviateNotify?.userGroups ?? []

  const { values, handleSubmit, setFieldValue, resetForm } = useFormik({
    initialValues: {
      userGroups: currentlyAssignedUserGroups,
    },
    validationSchema,
    onSubmit: (values, formikHelpers) => {
      // 単に型の都合であり、実際にはundefinedになることはない想定
      if (questionNode === undefined) return
      dispatch(
        // 逸脱時通知先の更新
        updateTemplateNode(questionNode.id, {
          ...questionNode,
          question: {
            ...questionNode.question,
            // `deviateNotify`の中に逸脱時通知先の情報が入っており、
            // その中には実のところuserGroupsというプロパティしかないので、このように書き換えればOKらしい。
            deviateNotify: values,
          },
        }),
      )
      dispatch(closeEditDeviateNotify())
      formikHelpers.resetForm()
    },
    enableReinitialize: true,
  })

  return (
    <Drawer
      title="逸脱時通知先の編集"
      maskClosable={false}
      placement="right"
      onClose={() => {
        resetForm()
        dispatch(closeEditDeviateNotify())
      }}
      open={isOpen}
      width="600"
    >
      <DeviatedRow>
        <SubTitle>ユーザーグループ</SubTitle>
      </DeviatedRow>
      <DeviatedRow>
        <Select
          mode="multiple"
          data-testid="select-deviate-notify-user-group"
          style={{ width: '100%' }}
          optionFilterProp="children"
          onSelect={(value: string | number | LabeledValue) =>
            setFieldValue(
              'userGroups',
              (values.userGroups ?? []).length
                ? (values.userGroups ?? []).concat(
                    allUserGroups.filter(
                      userGroup => userGroup.id?.toString() === value,
                    ),
                  )
                : allUserGroups.filter(
                    userGroup => userGroup.id?.toString() === value,
                  ),
            )
          }
          onDeselect={(value: string | number | LabeledValue) =>
            setFieldValue(
              'userGroups',
              (values.userGroups ?? []).filter(
                userGroup => userGroup.id?.toString() !== value,
              ),
            )
          }
          value={(values.userGroups ?? []).map(
            userGroup => userGroup.id?.toString() || '',
          )}
        >
          {allUserGroups.map(userGroup =>
            hasUsers(userGroup) ? (
              <Option
                data-testid="select-deviate-notify-user-group-item"
                key={userGroup.id?.toString()}
                value={userGroup.id?.toString() || ''}
              >
                {userGroup.name || ''}
              </Option>
            ) : (
              <Option
                data-testid="select-deviate-notify-user-group-item"
                key={userGroup.id?.toString()}
                value={'disabled'}
                style={{ color: Disable }}
              >
                {`${userGroup.name || ''} (ユーザーが紐づいていません)`}
              </Option>
            ),
          )}
        </Select>
      </DeviatedRow>
      <DeviatedRow style={{ justifyContent: 'flex-end' }}>
        <ButtonGroup>
          <Button
            type="link"
            onClick={() => {
              resetForm()
              dispatch(closeEditDeviateNotify())
            }}
          >
            キャンセル
          </Button>
          <Button
            type="primary"
            onClick={() => {
              handleSubmit()
            }}
          >
            保存
          </Button>
        </ButtonGroup>
      </DeviatedRow>
    </Drawer>
  )
}

const { Option } = Select

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
`

const DeviatedRow = styled(Row)`
  margin-top: 15px;
  margin-bottom: 15px;
`

const SubTitle = styled.div`
  font-weight: bold;
`

function hasUsers(userGroup: UserGroup) {
  return (userGroup.users ?? []).length > 0
}

export default EditDeviateNotifyDrawer
