import {
  AddEmployeeRequest,
  AddMultipleEmployeesV2Request,
  DeleteEmployeeRequest,
  DeleteMultipleEmployeesV2Request,
  Employee,
  EmployeesApi,
  GetEmployeesResponse,
  GetEmployeesV2Request,
  UpdateEmployeeRequest,
} from '@ulysses-inc/harami_api_client'
import { call, put, takeEvery } from 'redux-saga/effects'
import * as notificationServiceActions from 'src/features/notificationService/slice'
import BaseClient from '../../middleware/saga/baseClient'
import {
  HTTPError,
  handleHTTPError,
} from '../../middleware/saga/handleHttpError'
import interceptionsActions from '../interceptions/actions'
import { default as actions } from './actions'
import { ActionTypes } from './types'

const baseClient = new BaseClient()

const getEmployeesV2Request = (req: GetEmployeesV2Request) => {
  return baseClient
    .getApi(EmployeesApi)
    .getEmployeesV2(req)
    .then(res => res)
    .catch(handleHTTPError)
}

const addEmployeeRequest = (req: AddEmployeeRequest) => {
  return baseClient
    .getApi(EmployeesApi)
    .addEmployee(req)
    .then(res => res)
    .catch(handleHTTPError)
}

const updateEmployeeRequest = (req: UpdateEmployeeRequest) => {
  return baseClient
    .getApi(EmployeesApi)
    .updateEmployee(req)
    .catch(handleHTTPError)
}

const deleteEmployeeRequest = (req: DeleteEmployeeRequest) => {
  return baseClient
    .getApi(EmployeesApi)
    .deleteEmployee(req)
    .catch(handleHTTPError)
}

const deleteMultipleEmployeesV2Request = (
  req: DeleteMultipleEmployeesV2Request,
) => {
  return baseClient
    .getApi(EmployeesApi)
    .deleteMultipleEmployeesV2(req)
    .then(res => res)
    .catch(handleHTTPError)
}

const addMultipleEmployeesV2Request = (req: AddMultipleEmployeesV2Request) => {
  return baseClient
    .getApi(EmployeesApi)
    .addMultipleEmployeesV2(req)
    .then(res => res)
    .catch(handleHTTPError)
}

function* getEmployees(action: ReturnType<typeof actions.getEmployees>) {
  try {
    const params: GetEmployeesV2Request = {
      ...action.request,
      employeeFilter: { ...action.filter },
    }
    const { employees, count } = yield call(getEmployeesV2Request, params)
    yield put(actions.getSuccessEmployees(employees, count))
  } catch (error) {
    yield put(interceptionsActions.handleHttpError(error as HTTPError))
    yield put(actions.getErrorEmployees(error as Error))
  }
}

function* addEmployee(action: ReturnType<typeof actions.addEmployee>) {
  try {
    const employee: Employee = yield call(addEmployeeRequest, {
      employee: action.employee,
    })
    yield put(actions.addSuccessEmployee(employee))
    yield put(actions.changeIsShowEditEmployeeDrawer(false))
    yield put(
      notificationServiceActions.showNotification({
        message: '従業員を登録しました',
      }),
    )
  } catch (error) {
    yield put(interceptionsActions.handleHttpError(error as HTTPError))
    yield put(actions.addErrorEmployee(error as Error))
  }
}

function* updateEmployee(action: ReturnType<typeof actions.updateEmployee>) {
  try {
    yield call(updateEmployeeRequest, {
      employee: action.employee,
      employeeUUID: action.employeeUUID,
    })
    yield put(actions.updateSuccessEmployee(action.employee))
    yield put(actions.changeIsShowEditEmployeeDrawer(false))
    yield put(
      notificationServiceActions.showNotification({
        message: '従業員を更新しました',
      }),
    )
  } catch (error) {
    yield put(interceptionsActions.handleHttpError(error as HTTPError))
    yield put(actions.updateErrorEmployee(error as Error))
  }
}

function* deleteEmployee(action: ReturnType<typeof actions.deleteEmployee>) {
  try {
    yield call(deleteEmployeeRequest, { employeeUUID: action.employeeUUID })
    yield put(actions.deleteSuccessEmployee(action.employeeUUID))
    yield put(
      notificationServiceActions.showNotification({
        message: '従業員を削除しました',
      }),
    )
  } catch (error) {
    yield put(interceptionsActions.handleHttpError(error as HTTPError))
    yield put(actions.deleteErrorEmployee(error as Error))
  }
}

function* deleteMultipleEmployees(
  action: ReturnType<typeof actions.deleteMultipleEmployees>,
) {
  try {
    const params: DeleteMultipleEmployeesV2Request = {
      ...action.request,
      deleteMultipleEmployees: {
        employees: action.employees,
        employeeFilter: { ...action.filter },
      },
    }
    const res: GetEmployeesResponse = yield call(
      deleteMultipleEmployeesV2Request,
      params,
    )
    yield put(
      actions.deleteMultipleSuccessEmployees(
        res.employees ?? [],
        res.count ?? 0,
      ),
    )
    yield put(
      notificationServiceActions.showNotification({
        message: '従業員を一括削除しました',
      }),
    )
  } catch (error) {
    yield put(interceptionsActions.handleHttpError(error as HTTPError))
    yield put(actions.deleteErrorEmployee(error as Error))
  }
}

function* addMultipleEmployees(
  action: ReturnType<typeof actions.addMultipleEmployees>,
) {
  try {
    const params: AddMultipleEmployeesV2Request = {
      limit: 25,
      offset: 0,
      addMultipleEmployees: {
        employees: action.employees,
        employeeFilter: { ...action.filter },
      },
    }
    const res: GetEmployeesResponse = yield call(
      addMultipleEmployeesV2Request,
      params,
    )
    yield put(actions.updateSegmentIndex('1'))
    yield put(
      notificationServiceActions.showNotification({
        message: '従業員を登録しました',
      }),
    )
    yield put(
      actions.addSuccessMultipleEmployees(res.employees ?? [], res.count ?? 0),
    )
  } catch (error) {
    yield put(interceptionsActions.handleHttpError(error as HTTPError))
    yield put(actions.addErrorMultipleEmployees(error as Error))
  }
}

const sagas = [
  takeEvery(ActionTypes.REQUEST_GET_EMPLOYEES, getEmployees),
  takeEvery(ActionTypes.REQUEST_CHANGE_EMPLOYEES_PAGE, getEmployees),
  takeEvery(ActionTypes.REQUEST_CHANGE_EMPLOYEES_SIZE, getEmployees),
  takeEvery(ActionTypes.REQUEST_ADD_EMPLOYEE, addEmployee),
  takeEvery(ActionTypes.REQUEST_UPDATE_EMPLOYEE, updateEmployee),
  takeEvery(ActionTypes.REQUEST_DELETE_EMPLOYEE, deleteEmployee),
  takeEvery(
    ActionTypes.REQUEST_DELETE_MULTIPLE_EMPLOYEES,
    deleteMultipleEmployees,
  ),
  takeEvery(ActionTypes.REQUEST_ADD_MULTIPLE_EMPLOYEES, addMultipleEmployees),
]

export default sagas
