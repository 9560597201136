import { useState } from 'react'

/**
 * 現場の付け替えモーダルの公開API及び公開APIにに関連するstateのみを抽出したカスタムフック
 *
 * 本来、`open`と`close`以外は隠蔽したい値ではあるものの、
 * jotai等を導入するまでは公開するほか選択肢がない。
 */
export const useAssignPlaceModal = () => {
  const [assignPlaceModalState, setAssignPlaceModalState] = useState({
    currentlyEditingReportIds: [] as number[],
    isOpen: false,
  })

  const open = (reportIdsToEdit: number | number[]) => {
    setAssignPlaceModalState({
      currentlyEditingReportIds: Array.isArray(reportIdsToEdit)
        ? reportIdsToEdit
        : [reportIdsToEdit],
      isOpen: true,
    })
  }

  const close = () => {
    setAssignPlaceModalState({
      currentlyEditingReportIds: [],
      isOpen: false,
    })
  }

  return {
    close,
    currentlyEditingReportIds: assignPlaceModalState.currentlyEditingReportIds,
    isOpen: assignPlaceModalState.isOpen,
    open,
  }
}
