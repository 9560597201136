import { User, UserGroup } from '@ulysses-inc/harami_api_client'
import { Input, Select } from 'antd'
import { useFormik } from 'formik'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'
import FormikErrorMessage from 'src/components/formikErrorMessage/FormikErrorMessage'
import Yup from 'src/features/validation/yup'
import usersOperations from 'src/state/ducks/users/operations'
import { RootState } from 'src/state/store'
import FooterButton from 'src/views/components/drawer/FooterButton'
import {
  UserGroupArea,
  UserGroupPageHeader,
  UserGroupText,
} from './EditUserGroup.styled'

interface UserGroupForm {
  id?: number
  name: string
  userIds: string[]
}

const { Option } = Select

const validationSchema = Yup.object().shape({
  name: Yup.string().required().label('ユーザーグループ名'),
})

const EditUserGroup: React.FC = () => {
  const {
    activeUserGroup,
    users,
    isLoadingUserGroup,
    request: { offset, limit },
  } = useSelector(
    ({
      usersState: {
        users: { users },
        userGroups: { activeUserGroupId, userGroups, isLoading, request },
      },
    }: RootState) => ({
      activeUserGroup: activeUserGroupId
        ? userGroups.find(({ id }) => id === activeUserGroupId)
        : undefined,
      users,
      isLoadingUserGroup: isLoading,
      request,
    }),
  )

  const dispatch = useDispatch()
  const changeIsShowEditUserGroupDrawer = () =>
    usersOperations.changeIsShowEditUserGroupDrawer(dispatch, false)

  const { setFieldValue, handleChange, handleSubmit, values, errors, touched } =
    useFormik<UserGroupForm>({
      initialValues: {
        id: activeUserGroup?.id,
        name: activeUserGroup?.name ?? '',
        userIds: activeUserGroup?.users?.map(({ id }) => `${id}`) ?? [],
      },
      validationSchema,
      onSubmit: ({ id, name, userIds }, { resetForm }) => {
        const userGroup: UserGroup = {
          id,
          name,
          users: userIds?.map(strId => ({ id: Number(strId) })) ?? [],
        }

        if (id) {
          usersOperations.updateUserGroup(dispatch, id, userGroup)
        } else {
          usersOperations.addUserGroup(dispatch, { userGroup, limit, offset })
        }

        resetForm()
      },
    })

  const onChange = (userIds: string[] | undefined) =>
    setFieldValue('userIds', userIds)
  const defaultValue =
    activeUserGroup?.users?.map(({ id }: User) => `${id || ''}`) ?? []

  return (
    <>
      <UserGroupPageHeader>{`ユーザーグループの${activeUserGroup ? '編集' : '追加'}`}</UserGroupPageHeader>

      <UserGroupArea>
        <UserGroupText>ユーザーグループ名</UserGroupText>

        <Input
          onChange={handleChange('name')}
          defaultValue={activeUserGroup?.name ?? ''}
          value={values.name}
        />
        <FormikErrorMessage name="name" errors={errors} touched={touched} />
      </UserGroupArea>

      <UserGroupArea>
        <UserGroupText>所属させるユーザー</UserGroupText>

        <Select
          mode="multiple"
          style={{ width: '100%' }}
          optionFilterProp="children"
          onChange={onChange}
          defaultValue={defaultValue}
        >
          {users.map(({ id, name }) => (
            <Option key={id} value={id?.toString() ?? ''}>
              {name}
            </Option>
          ))}
        </Select>
      </UserGroupArea>

      <FooterButton
        spinning={isLoadingUserGroup}
        onCancel={changeIsShowEditUserGroupDrawer}
        onSubmit={handleSubmit}
      />
    </>
  )
}

export default withRouter(EditUserGroup)
