import { css } from '@emotion/react'
import { globalNavigationTheme } from 'src/features/globalNavigation/theme'

type Props = {
  isCollapsed: boolean
}

export const Divider = (props: Props) => {
  const { isCollapsed } = props
  return <div css={[styles.divider, isCollapsed && styles.dividerCollapsed]} />
}

const styles = {
  divider: css`
    background-color: ${globalNavigationTheme.colorBorder};
    height: 1px;
    margin: 16px;
  `,
  dividerCollapsed: css`
    margin: 16px 8px;
  `,
}
