import { css } from '@emotion/react'
import { Tooltip } from 'antd'
import { HTMLAttributes, ReactNode } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { ClampText } from 'src/components/clampText/ClampText'
import { BetaBadge } from 'src/features/globalNavigation/components/BetaBadge'
import { navItemStyle } from 'src/features/globalNavigation/components/navItem/navItemStyle'
import { globalNavigationTheme } from 'src/features/globalNavigation/theme'

type Props = {
  icon: ReactNode
  /**
   * 内部リンク
   * e.g. `/dashboards
   */
  internalPath: string
  isBeta?: boolean
  /**
   * 折り畳まれているかどうか
   */
  isCollapsed: boolean
  title: string
} & HTMLAttributes<HTMLElement>

/**
 * グローバルナビゲーションの個々のアイテム (内部リンク)
 */
export const NavItemInternalLink = (props: Props) => {
  const {
    icon,
    internalPath,
    isBeta = false,
    isCollapsed,
    title,
    ...restProps
  } = props

  const { pathname } = useLocation()
  let isActive = false
  if (internalPath) {
    isActive = pathname.startsWith(internalPath)
  }

  // 開閉状態に応じて表示内容を切り替える
  let content: ReactNode
  if (isCollapsed) {
    content = <>{icon}</>
  } else {
    content = (
      <>
        {icon}
        <ClampText css={navItemStyle.title}>{title}</ClampText>
        {isBeta && <BetaBadge />}
      </>
    )
  }

  return (
    <Tooltip title={isCollapsed ? title : ''} placement="right">
      <Link
        {...restProps}
        css={[
          navItemStyle.container,
          isCollapsed && navItemStyle.containerCollapsed,
          isActive && styles.containerActive,
        ]}
        to={internalPath}
      >
        {content}
      </Link>
    </Tooltip>
  )
}

const styles = {
  containerActive: css`
    background: ${globalNavigationTheme.backgroundHover};
  `,
}
