import { DownOutlined, UpOutlined } from '@ant-design/icons'
import { White } from 'src/features/theme/KdsThemeColor'
import styled from 'styled-components'

export const FilterButton = styled.div`
  cursor: pointer;
`

export const DropDownContentButton = styled.div`
  cursor: pointer;
  margin-top: 5px;
`

export const UpOutlinedIcon = styled(UpOutlined)`
  color: rgba(0, 0, 0, 0.65);
`

export const DownOutlinedIcon = styled(DownOutlined)`
  color: rgba(0, 0, 0, 0.65);
`

export const DropDownContainer = styled.div`
  width: 100px;
  background-color: ${White};
  padding: 10px;
  box-shadow: 0px 0px 30px -20px #000000;
`
export const DisplaySpin = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
`
