import { InputNumber } from 'antd'
import React from 'react'

interface OwnProps {
  serialNumber: number
  changeSerialNumber: (serialNumber: number) => void
}
const ExcelLabelHover: React.FC<OwnProps> = (props: OwnProps) => {
  return (
    <div onClick={event => event.preventDefault()}>
      <InputNumber
        min={1}
        value={props.serialNumber}
        onChange={value => {
          if (!value || Number.isNaN(value)) {
            // 入力内容が不正な場合はデフォルト値として1をセット
            props.changeSerialNumber(1)
            return
          }
          props.changeSerialNumber(Number(value))
        }}
      />
    </div>
  )
}

export default ExcelLabelHover
