import {
  DoubleRightOutlined,
  HomeOutlined,
  TagOutlined,
} from '@ant-design/icons'
import { css } from '@emotion/react'
import { Button, Checkbox, Select, Spin } from 'antd'
import moment, { Moment } from 'moment'
import { MomentDatePicker } from 'src/components/datepicker/MomentDatePicker'
import { DebouncedInput } from 'src/components/debounsedInput/DebounsedInput'
import { ErrorMessage } from 'src/components/errorMessage/ErrorMessage'
import { ReportListFiltersDrawerFieldset } from 'src/features/reports/listBetaFilters/drawer/ReportListFiltersDrawerFieldset'
import {
  // 名前がコンポーネントと重複するため
  ReportListFilters as ReportListFiltersType,
  isValidFilterValueOf,
} from 'src/features/reports/listBetaFilters/types'
import { useReportListFilters } from 'src/features/reports/listBetaFilters/useReportListFilters'
import { useQueryPlaceNodes } from 'src/features/tanStackQuery/commonQueries/useQueryPlaceNodes'
import { useQueryUserPlaceNodes } from 'src/features/tanStackQuery/commonQueries/useQueryUserPlaceNodes'
import { parseDateString } from 'src/util/datetime/parseDateString'
import { toDateString } from 'src/util/datetime/toDateString'
import invariant from 'tiny-invariant'

const deviatedAnswerCheckboxGroupOptions: {
  label: string
  value: ReportListFiltersType['deviatedAnswer'][number]
}[] = [
  {
    label: '逸脱あり',
    value: 'present',
  },
  {
    label: '逸脱なし',
    value: 'absent',
  },
]

const statusCheckboxGroupOptions: {
  label: string
  value: ReportListFiltersType['statuses'][number]
}[] = [
  {
    label: '未完了',
    value: 'inCompleted',
  },
  {
    label: '承認待ち',
    value: 'approvalPending',
  },
  {
    label: '差し戻し',
    value: 'approvalRemand',
  },
  {
    label: '完了',
    value: 'completed',
  },
]

type Props = {
  filters: ReportListFiltersType
  onCloseButtonClicked: () => void
  setFilterOf: ReturnType<typeof useReportListFilters>['setFilterOf']
  resetFilterOf: ReturnType<typeof useReportListFilters>['resetFilterOf']
}

export const ReportListFiltersDrawer = ({
  filters,
  onCloseButtonClicked,
  setFilterOf,
  resetFilterOf,
}: Props) => {
  const userPlaceNodesQuery = useQueryUserPlaceNodes()
  const allPlaceNodesQuery = useQueryPlaceNodes()

  const isPending =
    userPlaceNodesQuery.isPending || allPlaceNodesQuery.isPending
  const isError = userPlaceNodesQuery.isError || allPlaceNodesQuery.isError

  let dateRangeValue: [Moment, Moment] | null = null
  if (filters.dateFrom && filters.dateTo) {
    dateRangeValue = [
      moment(parseDateString(filters.dateFrom)),
      moment(parseDateString(filters.dateTo)),
    ]
  }

  if (isError) {
    return <ErrorMessage noMargin>エラーが発生しました</ErrorMessage>
  }

  if (isPending) {
    return <Spin />
  }

  // 現状では、現場に未所属のユーザーはすべての現場に属しているのと同じ扱いとなっているため
  const userHasSomeAssignedPlaceNodes = userPlaceNodesQuery.data.length > 0
  const selectablePlaceNodes = userHasSomeAssignedPlaceNodes
    ? userPlaceNodesQuery.data
    : allPlaceNodesQuery.data

  return (
    <div>
      <div css={styles.topButtonsRow}>
        <Button
          icon={<DoubleRightOutlined style={{ fontSize: 12 }} />}
          onClick={onCloseButtonClicked}
          size="small"
        />
        <Button
          css={styles.clearButton}
          onClick={() => {
            // ドロワー内のクリアボタンでは、ドロワー内に入力欄がある項目だけをクリアする仕様である
            resetFilterOf.allExceptReportName()
          }}
        >
          すべてクリア
        </Button>
      </div>

      <div css={styles.restRow}>
        <ReportListFiltersDrawerFieldset title="現場">
          <Select
            css={styles.placeNodeSelect}
            mode="multiple"
            onChange={uuids => {
              setFilterOf.placeNodeUuids(uuids)
              resetFilterOf.currentPage()
            }}
            optionFilterProp="label"
            value={filters.placeNodeUuids}
            placeholder="現場を選択"
            showSearch
          >
            {(selectablePlaceNodes || []).map(placeNode => {
              return (
                <Select.Option
                  key={placeNode.uuid}
                  value={placeNode.uuid}
                  label={placeNode.name}
                >
                  <div css={styles.placeNodesOptionInner}>
                    <div css={styles.placeNodesOptionIcon}>
                      {placeNode.type === 'placeGroup' ? (
                        <TagOutlined />
                      ) : (
                        <HomeOutlined />
                      )}
                    </div>
                    <span>{placeNode.name}</span>
                  </div>
                </Select.Option>
              )
            })}
          </Select>
        </ReportListFiltersDrawerFieldset>

        <ReportListFiltersDrawerFieldset title="逸脱有無">
          <Checkbox.Group
            options={deviatedAnswerCheckboxGroupOptions}
            value={filters.deviatedAnswer}
            onChange={checkedValues => {
              // filterしているのは単に型の都合
              setFilterOf.deviatedAnswer(
                checkedValues.filter(isValidFilterValueOf.deviatedAnswer),
              )
              resetFilterOf.currentPage()
            }}
          />
        </ReportListFiltersDrawerFieldset>

        <ReportListFiltersDrawerFieldset title="ステータス">
          <Checkbox.Group
            options={statusCheckboxGroupOptions}
            value={filters.statuses}
            onChange={checkedValues => {
              // filterしているのは単に型の都合
              setFilterOf.statuses(
                checkedValues.filter(isValidFilterValueOf.status),
              )
              resetFilterOf.currentPage()
            }}
          />
        </ReportListFiltersDrawerFieldset>

        <ReportListFiltersDrawerFieldset title="実施日">
          <MomentDatePicker.RangePicker
            onChange={value => {
              if (!value) {
                resetFilterOf.dateRange()
                return
              }
              const dateTimeFrom = value[0]?.toDate()
              const dateTimeTo = value[1]?.toDate()
              invariant(dateTimeFrom && dateTimeTo)
              setFilterOf.dateRange(
                toDateString(dateTimeFrom),
                toDateString(dateTimeTo),
              )
              resetFilterOf.currentPage()
            }}
            placeholder={['開始日', '終了日']}
            style={{ width: '100%' }}
            value={dateRangeValue}
          />
        </ReportListFiltersDrawerFieldset>

        <ReportListFiltersDrawerFieldset title="従業員">
          <DebouncedInput
            allowClear
            delay={300}
            onChangeValue={value => {
              setFilterOf.employeeNameOrCode(value)
              resetFilterOf.currentPage()
            }}
            placeholder="従業員名または従業員番号"
            value={filters.employeeNameOrCode}
          />
        </ReportListFiltersDrawerFieldset>
      </div>
    </div>
  )
}

const styles = {
  topButtonsRow: css`
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 16px;
  `,
  clearButton: css`
    align-self: flex-end;
  `,
  placeNodeSelect: css`
    width: 100%;
  `,
  restRow: css`
    display: grid;
    grid-gap: 24px;
    padding: 16px 24px 24px;
  `,
  placeNodesOptionInner: css`
    display: flex;
    align-items: center;
  `,
  placeNodesOptionIcon: css`
    margin-right: 8px;
  `,
}
