import { MultipleChoice } from '@ulysses-inc/harami_api_client'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'
import { FC } from 'react'
import { TMultipleChoicesState } from './AddMultipleChoiceDrawer'
import { CenteredCheckbox } from './AddMultipleChoiceDrawer.styled'

const DefaultnessColumn: FC<{
  multipleChoicesState: TMultipleChoicesState
  multipleChoice: MultipleChoice
}> = ({
  multipleChoicesState: [multipleChoices, setMultipleChoices],
  multipleChoice,
}) => {
  const onChange = (e: CheckboxChangeEvent) => {
    let tempChoices: { [key: number]: MultipleChoice } = {}

    Object.values(multipleChoices).forEach(res => {
      tempChoices = {
        ...tempChoices,
        [res.id ?? 0]: { ...res, isDefault: 0 },
      }
    })

    setMultipleChoices({
      ...tempChoices,
      [`${multipleChoice.id}`]: {
        ...multipleChoice,
        isDefault: e.target.checked ? 1 : 0,
      },
    })
  }

  return (
    <CenteredCheckbox
      onChange={onChange}
      disabled={multipleChoice.isInvalid === 1}
      checked={multipleChoice.isDefault === 1}
    />
  )
}

export default DefaultnessColumn
