import { css } from '@emotion/react'
import { ReactNode } from 'react'
import { primitiveColors } from 'src/features/theme/primitiveColors'

type Props = {
  children: ReactNode
}

export const SubMenuContainer = (props: Props) => {
  const { children } = props
  return <div css={styles.container}>{children}</div>
}

const styles = {
  container: css`
    background: ${primitiveColors.white};
    border-radius: 6px;
    box-shadow:
      0 1px 8px 0 #1e242c0d,
      0 2px 4px 0 #1e242c0f,
      0 1px 2px 0 #1e242c14,
      0 1px 1px 0 #ffffff40 inset;
    padding: 4px;
  `,
}
