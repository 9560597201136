import { MultipleChoiceSet } from '@ulysses-inc/harami_api_client'
import { Modal } from 'antd'
import React, { useState } from 'react'
import { RowActionButton } from 'src/components/rowActionButton/RowActionButton'
import { ModalDeleteButtonColor } from 'src/features/theme/KdsThemeColor'
import {
  ButtonGroupRow,
  TableRow,
  TableRowWrap,
} from './MultipleChoiceSetList.styled'

interface IProps {
  multipleChoiceSet: MultipleChoiceSet
  goEditMultipleChoiceSet: (multipleChoiceSetId: string) => void
  deleteMultipleChoiceSet: (multipleChoiceSetId: string) => void
}

const MultipleChoiceSetRow: React.FC<IProps> = ({
  multipleChoiceSet: { id, uuid, name },
  goEditMultipleChoiceSet,
  deleteMultipleChoiceSet,
}) => {
  const [activeMultipleChoiceSetId, setActiveMultipleChoiceSetId] = useState<
    number | undefined
  >(undefined)

  const onDeleteButtonClick = () =>
    Modal.confirm({
      onOk: () => deleteMultipleChoiceSet(uuid ?? ''),
      title: `「${name}」を削除しますがよろしいですか？`,
      okText: '削除',
      cancelText: 'キャンセル',
      okButtonProps: {
        style: {
          backgroundColor: ModalDeleteButtonColor,
          border: 'none',
        },
      },
    })

  const isActive = activeMultipleChoiceSetId === id

  return (
    <TableRowWrap
      onMouseEnter={() => setActiveMultipleChoiceSetId(id)}
      onMouseLeave={() => setActiveMultipleChoiceSetId(undefined)}
    >
      <TableRow theme={{ width: '100%' }}>{name}</TableRow>
      {isActive && (
        <ButtonGroupRow>
          <RowActionButton
            type="edit"
            onClick={() => goEditMultipleChoiceSet(uuid ?? '')}
          />
          <RowActionButton type="delete" onClick={onDeleteButtonClick} />
        </ButtonGroupRow>
      )}
    </TableRowWrap>
  )
}

export default MultipleChoiceSetRow
