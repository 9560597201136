import {
  ReportQuestion,
  ResponseTypeEnum,
} from '@ulysses-inc/harami_api_client'
import { FC } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import {
  selectBodyRows,
  selectHeaderRow,
} from 'src/state/ducks/reports/reportResult/grid/selectors'
import { isNullish } from 'src/util/isNullish'
import { FormulaHint } from './FormulaHint'
import {
  CellInner,
  RequiredBadge,
  Table,
  TableBody,
  TableBodyFirstCell,
  TableHeader,
  TableHeaderCell,
  TableHeaderCellText,
  TableHeaderFirstCell,
  TableRow,
} from './SectionTable.dumb'
import { TableBodyCell } from './TableBodyCell'
import { TimeMeasurementHint } from './TimeMeasurementHint'

export type Props = {
  pageId: number
  originalSectionNodeId: number
  goPreviewPhoto: (imageUrls: string[], selectedImageIndex: number) => void
}

export const SectionTable: FC<Props> = ({
  pageId,
  originalSectionNodeId,
  goPreviewPhoto,
}) => {
  const headerRow = useSelector(
    selectHeaderRow(originalSectionNodeId),
    shallowEqual,
  )

  const bodyRows = useSelector(
    selectBodyRows(pageId, originalSectionNodeId),
    shallowEqual,
  )

  // 取り込みありの項目で表示できる項目が1つでもある、もしくは取り込み項目が1つもなく他の表示項目(数値やテキストなど)場合はテーブルを表示
  // それ以外はテーブルを表示させない
  const shouldShowTable = headerRow
    .map(r => r.question)
    .filter((item): item is ReportQuestion => !!item)
    .some(
      q =>
        q.responseType !== ResponseTypeEnum.GRID_VARIABLE ||
        (q.responseType === ResponseTypeEnum.GRID_VARIABLE &&
          q.responseGridVariables &&
          q.responseGridVariables[0]?.isEnabled),
    )
  if (!shouldShowTable) return <></>

  return (
    <Table>
      <TableHeader>
        <TableRow>
          {/* クリップボードからexcelへコピペしたした際に表の段組が崩れてしまうので、対策としてスペースを入れておく */}
          <TableHeaderFirstCell>&nbsp;</TableHeaderFirstCell>
          {headerRow.map((item, i) => {
            const { question } = item
            if (isNullish(question)) return <></>

            const isDisabledGridVariable =
              question.responseType === ResponseTypeEnum.GRID_VARIABLE &&
              question.responseGridVariables &&
              !question.responseGridVariables[0]?.isEnabled
            // ひな形側の取り込み項目質問のラジオボタンがoffで設定された場合、セルを非表示
            if (isDisabledGridVariable) return <></>

            return (
              <TableHeaderCell key={i}>
                <CellInner
                  css={{
                    justifyContent: 'center',
                    gap: '4px',
                    flexDirection: 'row',
                  }}
                >
                  <TableHeaderCellText isRequired={question.isRequired === 1}>
                    {question.isRequired === 1 && <RequiredBadge />}
                    {question.name}
                  </TableHeaderCellText>
                  {question.responseType ===
                    ResponseTypeEnum.TIME_MEASUREMENT && (
                    <TimeMeasurementHint
                      startUUID={
                        question.responseTimeMeasurements?.[0]
                          ?.startQuestionNodeUUID || ''
                      }
                      endUUID={
                        question.responseTimeMeasurements?.[0]
                          ?.endQuestionNodeUUID || ''
                      }
                    />
                  )}
                  {
                    // 時間計測ヒントとまとめることも考えたが、時間計測ヒントはselectorを使用しており、
                    // 計算式ヒントはpropsのみで完結するため別にしている
                    question.responseType === ResponseTypeEnum.FORMULA && (
                      <FormulaHint question={question} headerRow={headerRow} />
                    )
                  }
                </CellInner>
              </TableHeaderCell>
            )
          })}
        </TableRow>
      </TableHeader>
      <TableBody>
        {/* TODO:  HPB-2714 で逸脱行のみ表示表示する場合のロジックを追加する */}
        {bodyRows.map((row, i) => (
          <TableRow key={i}>
            <TableBodyFirstCell>
              {/*
                MEMO:
                行番号はIndexを見て表示している。
                これは、現状DBのデータが順番どおりに登録されている前提であるため。
                表表示用のデータは、既存のSectionデータ型で扱っており、D帳票開発の実装工数の兼ね合いでそうなっている。
                詳しくは下記DesignDoc参照。
                https://docs.google.com/document/d/1DZjdJnZidonRCkCr_hMYeYBf8FWdyXbR7iSb7yZBcSo
              */}
              <CellInner>{i + 1}</CellInner>
            </TableBodyFirstCell>
            {row.map(node => {
              const { question } = node
              if (isNullish(question)) return <></>

              return (
                <TableBodyCell
                  key={node.id}
                  nodeId={node.id}
                  nodeUUID={node.uuid} // IoT 機器検証用に追加、検証完了後に削除予定
                  question={question}
                  goPreviewPhoto={goPreviewPhoto}
                />
              )
            })}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}
