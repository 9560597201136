import { Button } from 'antd'
import React from 'react'
import styled from 'styled-components'
import LoadingDrawer from '../../../components/loading/LoadingDrawer'

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
`

const FormRow = styled.div`
  margin-top: 20px;
`

interface OwnProps {
  spinning: boolean
  onCancel: () => void
  onSubmit: () => void
  // TODO: craco.config.jsのprimaryにKDSの色を定義するときにこのpropsを消す
  isKds?: boolean
}

const FooterButton: React.FC<OwnProps> = (props: OwnProps) => {
  return (
    <>
      <FormRow>
        <ButtonGroup>
          <Button type="default" onClick={() => props.onCancel()}>
            キャンセル
          </Button>

          <LoadingDrawer
            spinning={props.spinning}
            render={
              <Button type="primary" onClick={() => props.onSubmit()}>
                保存
              </Button>
            }
          />
        </ButtonGroup>
      </FormRow>
    </>
  )
}

export default FooterButton
