import { css } from '@emotion/react'
import { Spin } from 'antd'

export const Spinner = () => {
  return (
    // antdのSpinnerにARIA関連の情報が設定がされていないため
    <div aria-label="処理中" css={styles.container} role="progressbar">
      <Spin size="large" />
    </div>
  )
}

const styles = {
  container: css`
    display: contents;
  `,
}
