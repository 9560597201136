import { DeleteOutlined, EyeOutlined, LoadingOutlined } from '@ant-design/icons'
import { css } from '@emotion/react'
import React, { ImgHTMLAttributes } from 'react'
import { Black, White } from 'src/features/theme/KdsThemeColor'

type Props = {
  disabled: boolean
  imgProps: ImgHTMLAttributes<HTMLImageElement>
  isLoading: boolean
  onClickPreview: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  onClickRemove: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
}

export const Thumb: React.FC<Props> = ({
  disabled,
  imgProps,
  isLoading,
  onClickPreview,
  onClickRemove,
}) => {
  if (isLoading) {
    return (
      <div css={styles.container}>
        <div css={styles.spinner}>
          <LoadingOutlined style={{ color: Black }} />
        </div>
      </div>
    )
  }

  return (
    <div css={styles.container}>
      <img css={styles.image} {...imgProps} />
      <div css={styles.overlay}>
        <span css={styles.overlayButton} onClick={onClickPreview}>
          <EyeOutlined style={{ color: White }} />
        </span>
        {!disabled && (
          <span css={styles.overlayButton} onClick={onClickRemove}>
            <DeleteOutlined style={{ color: White }} />
          </span>
        )}
      </div>
    </div>
  )
}

const styles = {
  container: css`
    position: relative;
    margin: 4px 0 0 4px;
    height: 48px;
    width: 64px;
  `,
  image: css`
    width: 100%;
    height: 100%;
  `,
  overlay: css`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    &:hover {
      background-color: rgba(0, 0, 0, 0.8);
      opacity: 1;
    }
  `,
  overlayButton: css`
    display: inline-block;
    line-height: 1;
    padding: 4px;
    margin: 2px;
    &:hover {
      background-color: rgba(255, 255, 255, 0.3);
      opacity: 1;
    }
  `,
  spinner: css`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ccc;
    transition: opacity 0.2s ease-in-out;
  `,
}
