import { FilterOutlined } from '@ant-design/icons'
import { css } from '@emotion/react'
import { useQueryClient } from '@tanstack/react-query'
import { Button, Modal } from 'antd'
import { ColumnsType } from 'antd/es/table'
import { format } from 'date-fns'
import { ComponentProps } from 'react'
import { useHistory } from 'react-router-dom'
import { ClampText } from 'src/components/clampText/ClampText'
import { ErrorMessage } from 'src/components/errorMessage/ErrorMessage'
import { HEADER_HEIGHT } from 'src/components/header/Header'
import { Pagination } from 'src/components/pagination/Pagination'
import { RevalidationSpinner } from 'src/components/revalidationSpinner/RevalidationSpinner'
import { Table } from 'src/components/table/Table'
import { WithDrawer } from 'src/components/withDrawer/WithDrawer'
import { sessionStorageKeys } from 'src/constants/sessionStorageKeys'
import { AssignPlaceModal } from 'src/features/reports/assignPlaceModal/AssignPlaceModal'
import { useAssignPlaceModal } from 'src/features/reports/assignPlaceModal/useAssignPlaceModal'
import { ReportListWrapper } from 'src/features/reports/listBeta/ReportListWrapper'
import { queryKeys } from 'src/features/reports/listBeta/api/queryKeys'
import { useMutationDeleteReport } from 'src/features/reports/listBeta/api/useMutationDeleteReport'
import {
  Report,
  useQueryReports,
} from 'src/features/reports/listBeta/api/useQueryReports'
import { OverlaySubHeader } from 'src/features/reports/listBeta/components/OverlaySubHeader'
import { PropagationCanceller } from 'src/features/reports/listBeta/components/PropagationCanceller'
import { ReportItemDropdown } from 'src/features/reports/listBeta/components/ReportItemDropdown'
import { ReportNameCell } from 'src/features/reports/listBeta/components/ReportNameCell'
import { Status } from 'src/features/reports/listBeta/components/Status'
import { SubHeader } from 'src/features/reports/listBeta/components/SubHeader'
import { toFriendlyDateString } from 'src/features/reports/listBeta/toFriendlyDateString'
import { ReportListFiltersReportName } from 'src/features/reports/listBetaFilters/ReportListFiltersReportName'
import { ReportListFiltersDrawer } from 'src/features/reports/listBetaFilters/drawer/ReportListFiltersDrawer'
import { useReportListFilters } from 'src/features/reports/listBetaFilters/useReportListFilters'
import { ModalDeleteButtonColor } from 'src/features/theme/KdsThemeColor'
import { useItemSelection } from 'src/hooks/useItemSelection'
import { useOnClickOutside } from 'src/hooks/useOnClickOutside'
import { useScrollRestorer } from 'src/hooks/useScrollRestorer'
import { useTogglable } from 'src/hooks/useTogglable'

/**
 * シン・レポート一覧画面
 *
 * 既存のレポート一覧画面を段階的にこのシン・レポート一覧画面に移行し、最終的には完全に置き換える
 */
export const ReportList = () => {
  const history = useHistory()
  const queryClient = useQueryClient()

  const {
    filters,
    isSomeFilterActiveExceptReportName,
    setFilterOf,
    resetFilterOf,
  } = useReportListFilters()

  // Queries
  const reportsQuery = useQueryReports(filters)

  const isLoadingInitially = reportsQuery.isLoading
  const isLoadingForRevalidation =
    !isLoadingInitially && reportsQuery.isFetching

  // Mutations
  const { mutate: deleteReportMutation, isPending: isDeletingReport } =
    useMutationDeleteReport()

  useScrollRestorer(`.ant-table-body`, sessionStorageKeys.uiReportListScrollY)

  const assignPlaceModal = useAssignPlaceModal()

  const onClickDeleteReport = async (
    reportName: string,
    reportUuid: string,
  ) => {
    Modal.confirm({
      cancelText: 'キャンセル',
      okButtonProps: {
        style: {
          backgroundColor: ModalDeleteButtonColor,
          border: 'none',
        },
      },
      okText: '削除',
      onOk: () => {
        deleteReportMutation(reportUuid, {
          onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: queryKeys.reportsAll })
          },
        })
      },
      title: `「${reportName}」の削除を実行しますがよろしいですか？`,
    })
  }

  // ページ番号やフィルター条件が変更された場合は、選択状態をリセットする
  const resetTrigger = JSON.stringify(filters)
  const reportSelection = useItemSelection(resetTrigger)

  const filterDrawer = useTogglable()

  const { classNameToIgnore, ref: drawerRef } =
    useOnClickOutside<HTMLDivElement>(filterDrawer.close)

  const onCloseAssignPlaceModal: ComponentProps<
    typeof AssignPlaceModal
  >['onClose'] = isSomeReportUpdated => {
    assignPlaceModal.close()
    if (isSomeReportUpdated) {
      queryClient.invalidateQueries({ queryKey: queryKeys.reportsAll }).then()
    }
  }

  // minWidthの指定はできない模様
  const columns: ColumnsType<Report> = [
    {
      dataIndex: 'reportDate',
      key: 'reportDate',
      render: (reportDate: Date) => toFriendlyDateString(reportDate),
      title: '実施日',
      width: '130px',
    },
    {
      dataIndex: 'name',
      key: 'name',
      render: (reportName: string, thisReport) => (
        <ReportNameCell
          reportName={reportName}
          hasSomeDeviatedAnswer={thisReport.hasSomeDeviatedAnswer}
        />
      ),
      title: 'レポート名',
    },
    {
      dataIndex: 'placeName',
      key: 'placeName',
      render: (placeName: string) => (
        <ClampText line={2}>{placeName}</ClampText>
      ),
      title: '現場名',
      width: '138px',
    },
    {
      dataIndex: 'assigneeName',
      key: 'assigneeName',
      render: (placeName: string) => (
        <ClampText line={2}>{placeName}</ClampText>
      ),
      title: 'ユーザー',
      width: '110px',
    },
    {
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (updatedAt: Date) => format(updatedAt, 'yyyy/MM/dd HH:mm'),
      title: '更新日時',
      width: '144px',
    },
    {
      dataIndex: 'status',
      key: 'status',
      render: (status: Report['status']) => <Status status={status} />,
      title: 'ステータス',
      width: '96px',
    },
    {
      dataIndex: 'score',
      key: 'score',
      title: 'スコア',
      width: '60px',
    },
    {
      key: 'actions',
      render: (_, thisReport) => (
        <>
          <PropagationCanceller />
          <ReportItemDropdown
            onClickAssignPlaceButton={() =>
              assignPlaceModal.open(thisReport.id)
            }
            onClickDeleteButton={() =>
              onClickDeleteReport(thisReport.name, thisReport.uuid)
            }
          />
        </>
      ),
      // 左padding 8px + ボタンwidth 32px + 右padding 24px
      width: '64px',
    },
  ]

  // なお、isLoading|isFetching 時の早期リターンは行わない。
  // なぜならページを切り替えるたびにテーブルが消失してUIが悪化するため。
  if (reportsQuery.isError) {
    return (
      <ReportListWrapper>
        <div css={styles.errorMessageContainer}>
          <ErrorMessage noMargin>エラーが発生しました</ErrorMessage>
        </div>
      </ReportListWrapper>
    )
  }

  return (
    <ReportListWrapper>
      <SubHeader
        overlay={
          reportSelection.isSomeSelected && (
            <OverlaySubHeader
              onAssignPlaceButtonClicked={() =>
                assignPlaceModal.open(reportSelection.ids)
              }
              onClearButtonClicked={() => reportSelection.reset()}
              selectedReportCount={reportSelection.count}
            />
          )
        }
      >
        <ReportListFiltersReportName
          filters={filters}
          resetFilterOf={resetFilterOf}
          setFilterOf={setFilterOf}
        />
        <Button
          className={classNameToIgnore}
          icon={<FilterOutlined />}
          onClick={filterDrawer.toggle}
          style={{
            background: isSomeFilterActiveExceptReportName
              ? '#C7F1FF'
              : undefined,
          }}
        >
          フィルター
        </Button>
      </SubHeader>

      <WithDrawer
        drawerContent={
          <ReportListFiltersDrawer
            filters={filters}
            onCloseButtonClicked={filterDrawer.close}
            resetFilterOf={resetFilterOf}
            setFilterOf={setFilterOf}
          />
        }
        drawerRef={drawerRef}
        drawerWidth={400}
        isDrawerOpen={filterDrawer.isOpen}
        onCloseDrawer={filterDrawer.close}
      >
        <div css={css(styles.tableArea)}>
          <Table
            className={classNameToIgnore}
            columns={columns}
            dataSource={reportsQuery.data?.reports}
            kaminashiOption={{
              pointerOnRowHover: true,
            }}
            loading={isLoadingInitially || isDeletingReport}
            onRow={thisReport => {
              const targetUrl = `/reports/${thisReport.uuid}`
              return {
                // 子孫コンポーネント（≒セル）のonClickハンドラでは
                // このハンドラが意図せず呼ばれないよう、常に`stopPropagation()`が必須なので注意
                // 参考: https://github.com/Ulysses-inc/harami_web/pull/371#discussion_r1296582461
                onClick: e => {
                  // Ctrl(Windows) or Command(Mac)キーが押されている場合は
                  if (e.ctrlKey || e.metaKey) {
                    window.open(targetUrl)
                    return
                  }
                  history.push(targetUrl)
                },
                // 中クリックなら
                onAuxClick: () => {
                  window.open(targetUrl)
                },
              }
            }}
            pagination={false}
            rowKey="id"
            rowSelection={{
              type: 'checkbox',
              selectedRowKeys: reportSelection.ids,
              onChange: selectedRowKeys =>
                // mapしているのは単に型の都合
                reportSelection.select(selectedRowKeys.map(Number)),
              renderCell: (_checked, _report, _index, node) => (
                <>
                  <PropagationCanceller />
                  {node}
                </>
              ),
            }}
            scroll={{ y: styles.tableRowsHeight }}
            size="middle"
          ></Table>
        </div>
      </WithDrawer>

      <Pagination
        pagination={reportsQuery.data?.pagination}
        current={filters.page}
        onPageChange={page => setFilterOf.pageAndPageSize(page, 50)} // ミニマムなpagination実装のため、1ページあたりの表示件数を50件に固定
      />

      <AssignPlaceModal
        currentlyEditingReportIds={assignPlaceModal.currentlyEditingReportIds}
        isOpen={assignPlaceModal.isOpen}
        onClose={onCloseAssignPlaceModal}
      />
      {isLoadingForRevalidation && <RevalidationSpinner />}
    </ReportListWrapper>
  )
}

const styles = {
  errorMessageContainer: css`
    margin: 16px 24px;
  `,
  paginator: css`
    display: flex;
    justify-content: center;
    padding: 16px 0;
  `,
  tableArea: css`
    background: white;
    // 余っている高さをすべて占有したい
    height: calc(
      100vh -
        ${
          HEADER_HEIGHT +
          64 + // SubHeader
          64 // Paginator
        }px
    );
  `,
  // 行エリアだけを縦スクロール可能にしたい
  tableRowsHeight: `calc(100vh - ${
    HEADER_HEIGHT +
    64 + // SubHeader
    47 + // TableHeader
    64 // Paginator
  }px)`,
}
