import { MenuFoldOutlined, MenuOutlined } from '@ant-design/icons'
import { css } from '@emotion/react'
import { HTMLAttributes } from 'react'
import LogoSvg from 'src/features/globalNavigation/assets/logo.svg?react'
import { globalNavigationTheme } from 'src/features/globalNavigation/theme'

type Props = {
  isCollapsed: boolean
} & HTMLAttributes<HTMLDivElement>

export const LogoButton = (props: Props) => {
  const { isCollapsed, ...restProps } = props

  const content = isCollapsed ? (
    <MenuOutlined />
  ) : (
    <>
      <LogoSvg fill={globalNavigationTheme.textColor} />
      <MenuFoldOutlined />
    </>
  )

  return (
    <div
      {...restProps}
      css={[styles.container, isCollapsed && styles.containerCollapsed]}
    >
      {content}
    </div>
  )
}

const styles = {
  container: css`
    align-items: center;
    cursor: pointer;
    display: flex;
    height: 48px;
    justify-content: space-between;
    margin-bottom: 8px;
    padding: 8px 16px 8px 24px;
  `,
  containerCollapsed: css`
    padding: 0;
    justify-content: center;
  `,
}
