import { DownloadOutlined } from '@ant-design/icons'
import { Button } from 'antd'

export const SampleCsvDownloadButton = () => {
  return (
    <Button
      download="user_import_sample.csv"
      href="/samples/user_import_sample.csv"
      icon={<DownloadOutlined />}
      type="default"
    >
      サンプルダウンロード
    </Button>
  )
}
