import {
  TemplateNodeSchema,
  TemplateNodeTypeEnum,
} from '@ulysses-inc/harami_api_client'
import { FC } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import {
  changeActiveNodeId,
  changeSectionToNext,
  moveTemplateNode,
  updateTemplateNode,
} from 'src/state/ducks/templates/actions'
import { useTemplateNodeNodes } from 'src/state/ducks/templates/selectors'
import { RootState } from 'src/state/store'
import { createNode } from '../common/createNode'
import { MoveNodeEvent } from '../dragAndDrop/DnDTemplateType'
import SelectNodeEditor from '../question/SelectNodeEditor'
import {
  SectionContentWrapper,
  SectionNameParagraph,
  SectionNameParagraphWrapper,
  SectionTableCell,
  SectionWrapper,
  Table,
} from './GridSection.dumb'
import SectionContent from './SectionContent'

interface Props {
  node: TemplateNodeSchema
}

const cloneNodeWithNewName = (node: TemplateNodeSchema, name: string) => {
  return {
    ...node,
    section: { ...node.section, name },
  }
}

const GridSection: FC<Props> = ({ node }) => {
  const dispatch = useDispatch()

  const { activeNodeId, templateNodes } = useSelector(
    (state: RootState) => state.templatesState.templatePages,
    shallowEqual,
  )

  const isActive = activeNodeId === node.id

  const handleChangeSectionToNext = (text: string) =>
    dispatch(changeSectionToNext(node.id, cloneNodeWithNewName(node, text)))

  const handleUpdateTemplateNode = (text: string) =>
    dispatch(updateTemplateNode(node.id, cloneNodeWithNewName(node, text)))

  const handleChangeActiveNodeId = () => dispatch(changeActiveNodeId(node.id))

  const handleAddTemplateNode = (
    targetNode: TemplateNodeSchema | null,
    parentNode: TemplateNodeSchema | null,
    position: 'top' | 'bottom',
    addTemplateNodeType: TemplateNodeTypeEnum,
  ) => {
    createNode(templateNodes, dispatch, {
      targetNode,
      parentNode,
      position,
      addTemplateNodeType,
      question: targetNode?.question,
    })
  }

  const handleMoveNode = (event: MoveNodeEvent) => {
    const isSameParent =
      (!event.dragNodeParent && !event.dropNodeParent) ||
      (!!event.dragNodeParent &&
        !!event.dropNodeParent &&
        event.dragNodeParent.id === event.dropNodeParent.id)
    dispatch(moveTemplateNode(event, isSameParent))
  }

  return (
    <>
      <Table onClick={handleChangeActiveNodeId}>
        <SectionTableCell theme={{ width: '100%' }}>
          <SectionWrapper>
            <SectionNameParagraphWrapper data-testid="grid-section-bar">
              {isActive ? (
                <SelectNodeEditor
                  onChangeText={handleChangeSectionToNext}
                  onUpdate={handleUpdateTemplateNode}
                  name={node.section?.name ?? ''}
                  node={node}
                />
              ) : (
                <SectionNameParagraph>
                  {node.section?.name ?? ''}
                </SectionNameParagraph>
              )}
            </SectionNameParagraphWrapper>
          </SectionWrapper>
        </SectionTableCell>
      </Table>
      <SectionContentWrapper isOpened={true} key={node.id}>
        <SectionContent
          node={node}
          activeNodeId={activeNodeId}
          path={[]}
          useTemplateNodeNodes={useTemplateNodeNodes}
          addTemplateNode={handleAddTemplateNode}
          moveNode={handleMoveNode}
        />
      </SectionContentWrapper>
    </>
  )
}
export default GridSection
