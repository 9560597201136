import { css } from '@emotion/react'
import { globalNavigationTheme } from 'src/features/globalNavigation/theme'

// メニュー項目のコンポーネントに種類（内部リンク・外部リンク・サブメニューほか）が多く、
// Elementの種類やラッパーを種類に応じて切り替える必要があった。
// 一つのコンポーネントで対処するのではなく、コンポーネント自体を分割したうえで、
// 以下のようにスタイルのみを共通化するのが最もコードを簡素にできると判断した。
export const navItemStyle = {
  container: css`
    align-items: center;
    color: ${globalNavigationTheme.textColor};
    cursor: pointer;
    display: flex;
    gap: 8px;
    height: 40px;
    justify-content: flex-start;
    overflow: hidden;
    padding-left: 24px;
    padding-right: 16px;
    text-decoration: none;
    transition: background 0.25s;
    :hover {
      color: ${globalNavigationTheme.textColor};
      background: ${globalNavigationTheme.backgroundHover};
    }
  `,
  containerCollapsed: css`
    justify-content: center;
    padding: 0;
  `,
  title: css`
    color: ${globalNavigationTheme.textColor};
    flex: 1 0 0;
    overflow: hidden;
  `,
}
