import { Button, Dropdown, Input, Switch, Typography } from 'antd'
import moment from 'moment'
import { ChangeEvent, FC, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { MomentDatePicker } from 'src/components/datepicker/MomentDatePicker'
import {
  getApprovalReportsFilter,
  useApprovalReportsListFilter,
} from 'src/hooks/filter/useApprovalReportsFilter'
import { GetApprovalReportsFilter } from 'src/state/ducks/approvals/types'
import { RootState } from 'src/state/store'
import { ApprovalReportsFilter } from 'src/views/types/utils/ListFilter'
import {
  ButtonGroup,
  DownOutlinedIcon,
  DropDownTableCell,
  FilterButton,
  SelectFilterTableCell,
  UpOutlinedIcon,
} from './ApprovalReportsFilterDropDown.styled'
const { Text } = Typography

interface OwnProps {
  isLoading?: boolean
  setApprovalReportsFilters: (filter?: GetApprovalReportsFilter) => void
}

const ApprovalReportsFilterDropDown: FC<OwnProps> = ({
  isLoading,
  setApprovalReportsFilters,
}: OwnProps) => {
  const isLoadingPlace = useSelector(
    (state: RootState) =>
      state.placesState.places.isLoading ||
      state.placesState.placeGroups.isLoading,
  )

  const [isOpen, setIsOpen] = useState(false)
  const [reportName, setReportName] = useState<string>('')
  const [isInvalid, setIsInvalid] = useState<number>(0)
  const [from, setFrom] = useState<string | undefined>(undefined)
  const [through, setThrough] = useState<string | undefined>(undefined)
  const [user, setUser] = useState<string>('')
  const { approvalReportsRequest, saveApprovalReportsRequest } =
    useApprovalReportsListFilter()

  useEffect(() => {
    if (isLoading || isLoadingPlace) {
      return
    }

    if (!approvalReportsRequest) {
      setApprovalReportsFilters()
      return
    }

    const approvalReportsFilter = approvalReportsRequest.filter
    setReportName(approvalReportsFilter?.reportName ?? '')
    setIsInvalid(approvalReportsFilter?.isInvalid ?? 0)
    setFrom(approvalReportsFilter?.from)
    setThrough(approvalReportsFilter?.through)
    setUser(approvalReportsFilter?.user ?? '')
    const filter = getFilter({
      reportName: approvalReportsFilter?.reportName,
      from: approvalReportsFilter?.from,
      through: approvalReportsFilter?.through,
      isInvalid: approvalReportsFilter?.isInvalid,
      user: approvalReportsFilter?.user,
    })
    setApprovalReportsFilters(filter)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingPlace])

  const isFilterOn = () => {
    return (
      reportName.length > 0 ||
      (from !== '' && from !== null && from !== undefined) ||
      (through !== '' && through !== null && through !== undefined) ||
      isInvalid === 1 ||
      user.length > 0
    )
  }

  const clearFilter = () => {
    const conditions = {}
    const filter = getFilter(conditions)
    setApprovalReportsFilters(filter)
    setReportName('')
    setIsInvalid(0)
    setFrom(undefined)
    setThrough(undefined)
    setUser('')
    saveFilter(conditions)
  }

  const saveFilter = (conditions: ApprovalReportsFilter) => {
    saveApprovalReportsRequest(conditions)
  }

  const getFilter = (
    conditions: ApprovalReportsFilter,
  ): GetApprovalReportsFilter => {
    saveFilter(conditions)
    return getApprovalReportsFilter(conditions)
  }

  const groundArea = (
    <SelectFilterTableCell theme={{ width: '160px' }}>
      <FilterButton>
        <div style={{ flex: 1, marginLeft: 12 }}>
          フィルター：{isFilterOn() ? 'ON' : 'OFF'}
        </div>
        <div style={{ minWidth: 20, marginRight: 12 }}>
          {isOpen ? <UpOutlinedIcon /> : <DownOutlinedIcon />}
        </div>
      </FilterButton>
    </SelectFilterTableCell>
  )

  const hoverArea = (
    <div style={{ display: 'flex' }}>
      <DropDownTableCell>
        <ButtonGroup>
          <Button onClick={clearFilter}>クリア</Button>
        </ButtonGroup>
        <>
          <div style={{ marginBottom: 5 }}>
            <Text style={{ marginLeft: 3 }}>レポート名</Text>
          </div>
          <Input
            allowClear
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              const newValue = event.target.value
              setReportName(newValue)
              const filter = getFilter({
                reportName: newValue,
                from,
                through,
                isInvalid,
                user,
              })
              setApprovalReportsFilters(filter)
            }}
            defaultValue={reportName}
            value={reportName}
          />
        </>
        <>
          <div style={{ marginTop: 16, marginBottom: 5 }}>
            <Text>逸脱有無</Text>
          </div>
          <Switch
            checked={isInvalid === 1}
            onChange={(checked: boolean) => {
              const isInvalid = checked ? 1 : 0
              setIsInvalid(isInvalid)
              const filter = getFilter({
                reportName,
                from,
                through,
                isInvalid,
                user,
              })
              setApprovalReportsFilters(filter)
            }}
          />
        </>
        <div style={{ marginTop: 16, marginBottom: 5 }}>
          <Text style={{ marginLeft: 3 }}>実施日</Text>
        </div>
        <MomentDatePicker.RangePicker
          placeholder={['開始日', '終了日']}
          value={
            from && through ? [moment(from), moment(through)] : [null, null]
          }
          onChange={(_, dateStrings: [string, string]) => {
            if (
              dateStrings === undefined ||
              dateStrings[0] === undefined ||
              dateStrings[1] === undefined
            ) {
              setFrom(undefined)
              setThrough(undefined)
              const filter = getFilter({
                reportName,
                from: undefined,
                through: undefined,
                isInvalid,
                user,
              })
              setApprovalReportsFilters(filter)
            } else {
              setFrom(dateStrings[0])
              setThrough(dateStrings[1])
              const filter = getFilter({
                reportName,
                from: dateStrings[0],
                through: dateStrings[1],
                isInvalid,
                user,
              })
              setApprovalReportsFilters(filter)
            }
          }}
        />
        <>
          <div style={{ marginTop: 16, marginBottom: 5 }}>
            <Text style={{ marginLeft: 3 }}>依頼者</Text>
          </div>
          <Input
            placeholder="ユーザー名を入力"
            allowClear
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              const newValue = event.target.value
              setUser(newValue)
              const filter = getFilter({
                reportName,
                from,
                through,
                isInvalid,
                user: newValue,
              })
              setApprovalReportsFilters(filter)
            }}
            defaultValue={user}
            value={user}
          />
        </>
      </DropDownTableCell>
    </div>
  )

  return (
    <Dropdown
      dropdownRender={() => hoverArea}
      trigger={['click']}
      open={isOpen}
      onOpenChange={setIsOpen}
    >
      {groundArea}
    </Dropdown>
  )
}

export default ApprovalReportsFilterDropDown
