import { MoreOutlined } from '@ant-design/icons'
import { css } from '@emotion/react'
import { Button } from 'antd'
import { ComponentProps } from 'react'

type AntdButtonProps = ComponentProps<typeof Button>

/**
 * テーブル各行の右端にある、ドロップダウンを開くための3点ドットボタン
 */
export const ReportItemDropdownButton = (props: AntdButtonProps) => {
  const onClick: AntdButtonProps['onClick'] = e => {
    e.stopPropagation()

    // これがないとantdのDropdownが開かない
    // おそらくDropdownコンポーネントが暗示的に子コンポーネントにonClickを渡しているのだと思われる
    props.onClick?.(e)
  }

  return (
    <Button
      {...props}
      aria-label="more"
      css={styles.container}
      icon={<MoreOutlined css={styles.icon} />}
      onClick={onClick}
    />
  )
}

const styles = {
  container: css`
    height: 32px;
    width: 32px;
  `,
  icon: css`
    transform: rotate(90deg);
  `,
}
