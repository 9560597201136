import React from 'react'
import { IndustryInfo } from 'src/features/templateEdit/onboardingDynamic/types'
import { Primary } from 'src/features/theme/KdsThemeColor'
import {
  ButtonWrapper,
  CreateButton,
  Description,
  SvgTag,
  SvgTagDescription,
  SvgTagDescriptionIcon,
  SvgTagDescriptionIconWrap,
  SvgTagIconWrap,
  SvgTagTextWrap,
  SvgTagTitle,
  TableHeader,
  TableRow,
} from './ShowNewTemplateType.dumb'

interface OwnProps {
  showNewTemplates?: IndustryInfo[][]
  onClick: (industry: IndustryInfo) => void
}

const ShowNewTemplateType: React.FC<OwnProps> = (props: OwnProps) => {
  return (
    <>
      <Description>空のひな形からつくる</Description>
      {props.showNewTemplates?.map(
        (templates: IndustryInfo[], index: number) => {
          return (
            <TableHeader key={`tableHeader-${index}`}>
              <TableRow key={`tableRow-${index}`}>
                {templates.map((industry: IndustryInfo, index: number) => {
                  return (
                    <ButtonWrapper key={`industry-col-${index}`}>
                      <CreateButton
                        onClick={() => props.onClick(industry)}
                        disabled={industry.disabled}
                      >
                        <SvgTag>
                          {industry.svgComponent && (
                            <SvgTagIconWrap>
                              <industry.svgComponent
                                width={48}
                                height={48}
                                fill={Primary}
                              />
                            </SvgTagIconWrap>
                          )}
                          <SvgTagTextWrap>
                            <SvgTagTitle>{industry.title}</SvgTagTitle>
                            <SvgTagDescription>
                              {industry.description}
                            </SvgTagDescription>
                          </SvgTagTextWrap>
                        </SvgTag>
                        {industry.svgDescriptionComponent && (
                          <SvgTagDescriptionIconWrap>
                            {industry.svgDescriptionComponent.map(
                              (
                                Item: React.FunctionComponent<
                                  React.SVGProps<SVGSVGElement>
                                >,
                                index: number,
                              ) => (
                                <SvgTagDescriptionIcon
                                  key={`industry-col-desc-icon-${index}`}
                                >
                                  <Item width={48} height={48} />
                                </SvgTagDescriptionIcon>
                              ),
                            )}
                          </SvgTagDescriptionIconWrap>
                        )}
                      </CreateButton>
                    </ButtonWrapper>
                  )
                })}
              </TableRow>
            </TableHeader>
          )
        },
      )}
    </>
  )
}

export default ShowNewTemplateType
