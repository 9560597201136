import { primitiveColors } from 'src/features/theme/primitiveColors'

/**
 * @deprecated 🚨このファイルはdeprecatedです。semanticColors の方を使用してください。
 */

export const Default = primitiveColors.gray._6 // 7f8c9d
export const Primary = primitiveColors.blue._6 // 10a8eb
export const Info = primitiveColors.indigo._6 // 4c6ef5
export const Success = primitiveColors.green._6 // 40c057
export const Danger = primitiveColors.red._6 // fa5252
export const Warning = primitiveColors.yellow._6 // fab005
export const White = primitiveColors.white // ffffff
export const Black = primitiveColors.gray._9 // 1e242c
export const Gray = primitiveColors.gray._6 // 7f8c9d
export const DarkGray = primitiveColors.gray._7 // 434e5d
export const Disable = primitiveColors.gray._6 // 7f8c9d
export const AppBackgroundColor = primitiveColors.gray._1 // eff2f7
export const ModalDeleteButtonColor = primitiveColors.red._6 // fa5252
export const PlaceholderText = 'rgba(0, 0, 0, 0.25)'

// Table
export const TableBorderColor = primitiveColors.gray._3

// Border
export const BorderColor = primitiveColors.gray._4

// Tab
export const TabBackgroudColor = primitiveColors.gray._0

// ラベル
export const IndicatedIssueLabelColor = primitiveColors.indigo._1

// 表形式ラベル
export const GridTemplateLabelColor = primitiveColors.cyan._1

// レポート一覧画面
export const ReportTableDateColumnColor = primitiveColors.gray._2

// レポート結果画面
export const DisableButtonColor = primitiveColors.gray._4
export const ApprovalStepBadgeColor = primitiveColors.gray._6
// レポート結果画面/テーブル
export const ScrollBarThumbColor = primitiveColors.gray._4
export const RequiredBadgeColor = primitiveColors.red._6
// レポート結果画面/テーブル/背景色
export const CellDefaultBackgroundColor = primitiveColors.white
export const CellInvalidBackgroundColor = primitiveColors.red._0
export const CellFixedBackgroundColor = primitiveColors.gray._1
// レポート結果画面/テーブル/文字色
export const CellDefaultFontColor = primitiveColors.gray._9
export const CellInvalidFontColor = primitiveColors.red._6
export const CellUnAnsweredFontColor = primitiveColors.gray._5
export const CellUnAnsweredVariableFontColor = primitiveColors.gray._6

// inputにあるicon
export const IconInputDefaultColor = primitiveColors.gray._5

// Dashboard
export const SuccessCellBackgroundColor = primitiveColors.green._1
export const InvalidCellBackgroundColor = primitiveColors.red._1

// TemplateEdit
export const SectionColor = primitiveColors.white
export const SectionBackgroundColor = primitiveColors.gray._7
export const LogicBackgroundColor = primitiveColors.blue._2
export const DisabledTableCellBackgroundColor = primitiveColors.gray._2
export const SwitchDisabledColor = primitiveColors.gray._5
export const TableHeaderBackgroundColor = primitiveColors.gray._0
export const ScoreTextColor = primitiveColors.gray._9
export const ScoreSlashColor = primitiveColors.gray._5

// TemplateEdit内のIcon
export const DeleteIconColor = primitiveColors.red._6
export const CopyIconColor = primitiveColors.blue._6
export const BranchesIconColor = primitiveColors.blue._6
export const QuestionOpenButtonColor = primitiveColors.blue._2
export const DragButtonColor = primitiveColors.gray._7
export const UpIconColor = primitiveColors.gray._5
export const DownIconColor = primitiveColors.gray._5

// 取り込み項目のセルエラー時の背景色
export const GridVariableCellErrorBackgroundColor = primitiveColors.red._2

export const Blue0 = primitiveColors.blue._0

// Gray
export const Gray1 = primitiveColors.gray._1
export const Gray5 = primitiveColors.gray._5
export const Gray6 = primitiveColors.gray._6
export const ColorTextTertiary = primitiveColors.gray._9 + '73'

// Yellow
export const Yellow0 = primitiveColors.yellow._0
export const Yellow9 = primitiveColors.yellow._9

// Indigo
export const Indigo0 = primitiveColors.indigo._0
export const Indigo7 = primitiveColors.indigo._7
