import { Tooltip } from 'antd'
import Text from 'antd/lib/typography/Text'
import { useSelector } from 'react-redux'
import HintSvg from 'src/assets/icons/hint.svg?react'
import { Gray5, White } from 'src/features/theme/KdsThemeColor'
import { selectQuestionNameByUuid } from 'src/state/ducks/reports/reportResult/selectors'
import { RootState } from 'src/state/store'

type Props = {
  /** 時間計測の開始日時の質問 UUID. これをキーに質問名を取得し、ツールチップに表示する */
  startUUID: string
  /** 時間計測の終了日時の質問 UUID. これをキーに質問名を取得し、ツールチップに表示する */
  endUUID: string
}

/**
 * 時間計測質問のヒント。マウスホバーすると開始日時と終了日時の質問名が表示される
 */
export const TimeMeasurementHint = ({ startUUID, endUUID }: Props) => {
  const startName = useSelector((state: RootState) =>
    selectQuestionNameByUuid(state, startUUID),
  )
  const endName = useSelector((state: RootState) =>
    selectQuestionNameByUuid(state, endUUID),
  )
  const hint = `${startName || '質問名なし'}から ${endName || '質問名なし'}まで`

  return (
    <Tooltip
      placement="top"
      title={
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Text style={{ color: Gray5, fontSize: '12px' }}>計測</Text>
          <Text style={{ color: White, fontSize: '12px' }}>{hint}</Text>
        </div>
      }
      arrowPointAtCenter
    >
      <HintSvg style={{ minWidth: '14px' }} />
    </Tooltip>
  )
}
