import { Typography } from 'antd'
import styled from 'styled-components'

export const EditFormControl = styled.div`
  margin-top: 16px;
  margin-bottom: 5px;
`

export const EditFormLabel = styled(Typography.Text)`
  margin-left: 3px;
`

export const EmployeePageHeader = styled.div`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
`
