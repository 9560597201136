import { DeleteOutlined } from '@ant-design/icons'
import { TemplateHint } from '@ulysses-inc/harami_api_client'
import { Button } from 'antd'
import { FC } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { updateTemplateNode } from 'src/state/ducks/templates/actions'
import { deleteTemplateHint } from 'src/state/ducks/templates/templateHints/actions'
import { RootState } from 'src/state/store'
import DeleteConfirmDialog from 'src/views/components/dialogs/DeleteConfirmDialog'

const HintAction: FC<{
  hint: TemplateHint
}> = ({ hint }) => {
  const templateNodes = useSelector(
    (state: RootState) => state.templatesState.templatePages.templateNodes,
    shallowEqual,
  )

  const dispatch = useDispatch()
  const onDeleteClick = () =>
    DeleteConfirmDialog({
      title: 'ヒントを削除してもよろしいですか？',
      onOk() {
        const targetHintId = hint.id ?? ''

        // NOTE: すべてのノードのquestion.hints.blockを空にする
        for (const key of Object.keys(templateNodes)) {
          const thisNode = templateNodes[Number(key)]

          // 単に型の都合であり、実際にはundefinedになることはない想定
          if (thisNode?.id === undefined) continue

          const thisNodeHints = thisNode?.question?.hints ?? []
          if (thisNodeHints.length === 0) continue

          const nonTargetHints = thisNodeHints.filter(
            hint => hint.hintId !== targetHintId,
          )

          // 未調査: 空とはいえ、あえて削除したヒントを追加する必要があるだろうか？
          const emptiedTargetHint = {
            hintId: targetHintId,
            blocks: [],
          }

          dispatch(
            // ひな形からヒントを削除する
            updateTemplateNode(thisNode.id, {
              ...thisNode,
              question: {
                ...thisNode?.question,
                hints: [...nonTargetHints, emptiedTargetHint],
              },
            }),
          )
        }

        dispatch(deleteTemplateHint(targetHintId))
      },
    })

  return (
    <Button
      danger
      onClick={onDeleteClick}
      type="primary"
      icon={<DeleteOutlined />}
    />
  )
}

export default HintAction
