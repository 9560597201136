import { localStorageKeys } from 'src/constants/localStorageKeys'
import { LocalStorageService } from '../../libraries/localStorage'
import { ListFilter } from '../../views/types/utils/ListFilter'

const localStorageService = new LocalStorageService<ListFilter>()

/**
 * 一覧系画面のフィルタ条件をローカルストレージに保存したり、読み出したりするときに使う関数
 *
 * - 新規の利用は非推奨
 * - 宣言的に利用できるhooksっぽく書いてあるが、単なる同期的・命令的な関数である
 * - setListFilterを実行しても再レンダリングは走らないので、利用側コンポーネントでfiltersの値が常に最新であることは保証されない
 */
export const useListFilter = () => {
  const filters = localStorageService.getItem(localStorageKeys.listFilter)

  const setListFilter = (updatedFilters: ListFilter) => {
    localStorageService.setItem(localStorageKeys.listFilter, updatedFilters)
  }

  return { filters, setListFilter }
}
