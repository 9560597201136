import { ReportResultSchema } from 'src/exShared/types/types'

/**
 * ReportResultSchemaから最上位階層のセクションのnodeIdsを抽出できる
 * @param reportResult
 */
export const getTopLevelSectionNodeIds = (
  reportResult: ReportResultSchema,
): number[] => {
  const topLevelSectionNodeIds: number[] = []

  Object.values(reportResult.pages).map(page => {
    const sectionIdsInThisPage = page.nodes || []
    topLevelSectionNodeIds.push(...sectionIdsInThisPage)
  })

  return topLevelSectionNodeIds
}
