import {
  NumberLogicTypeEnum,
  ResponseNumber,
  ResponseNumberSubTypeEnum,
} from '@ulysses-inc/harami_api_client'
import { isNullish } from 'src/util/isNullish'

export const extractCalcNumberLogicType = (
  numberValue?: number,
  responseNumber?: ResponseNumber,
): NumberLogicTypeEnum.NORMAL | NumberLogicTypeEnum.INVALID => {
  if (isNullish(numberValue)) return NumberLogicTypeEnum.NORMAL

  const minimum = responseNumber?.minimum ?? 0
  const maximum = responseNumber?.maximum ?? 0
  switch (responseNumber?.subType) {
    case ResponseNumberSubTypeEnum.BETWEEN:
      return minimum <= numberValue && numberValue <= maximum
        ? NumberLogicTypeEnum.NORMAL
        : NumberLogicTypeEnum.INVALID
    case ResponseNumberSubTypeEnum.LESS_THAN:
      return numberValue <= maximum
        ? NumberLogicTypeEnum.NORMAL
        : NumberLogicTypeEnum.INVALID
    case ResponseNumberSubTypeEnum.GREATER_THAN:
      return numberValue >= minimum
        ? NumberLogicTypeEnum.NORMAL
        : NumberLogicTypeEnum.INVALID
    default:
      return NumberLogicTypeEnum.INVALID
  }
}
