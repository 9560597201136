import { css } from '@emotion/react'

/**
 * イベント伝播をキャンセルするコンポーネント
 *
 * テーブルに行のクリックイベントが設定される場合に、特定のセル、例えばチェックボックス列において、
 * クリック操作が意図しない画面遷移を引き起こし、UXが低下することがある。
 * このコンポーネントは、そのようなセルにおいて行のクリックイベントが発生しないようにするためのもの。
 */
export const PropagationCanceller = () => {
  return (
    <div
      css={styles.container}
      onClick={e => {
        e.stopPropagation()
      }}
    ></div>
  )
}

const styles = {
  container: css`
    bottom: 0;
    cursor: initial;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  `,
}
