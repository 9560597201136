import { StyleSheet, Text, View } from '@react-pdf/renderer'
import { ReportNodeSchema } from '@ulysses-inc/harami_api_client'
import React from 'react'
import { Gray, TableBorderColor, White } from 'src/features/theme/KdsThemeColor'
import { GirdLayoutPage } from '../../utils/getGridLayoutPages'
import { GridLayoutTableBodyCell } from './components/gridLayoutTableBodyCell/GridLayoutTableBodyCell'

const styles = StyleSheet.create({
  tableRow: {
    flexDirection: 'row',
  },
  baseTableBodyCell: {
    padding: 5,
    backgroundColor: White,
    textAlign: 'center',
    fontSize: 8,
    fontWeight: 500,
    borderTop: 'none',
    borderBottom: `1px solid ${TableBorderColor}`,
    borderLeft: 'none',
    borderRight: `1px solid ${TableBorderColor}`,
  },
  tableBodyFirstCell: {
    width: 25,
    color: Gray,
    borderLeft: `1px solid ${TableBorderColor}`,
  },
  tableBodyCell: {
    width: 65,
  },
})

interface Props {
  girdLayoutPage: GirdLayoutPage | undefined
}

export const GirdLayoutTableBody: React.FC<Props> = ({ girdLayoutPage }) => {
  if (!girdLayoutPage) {
    return null
  }
  return (
    <>
      {girdLayoutPage.bodyRowNodes.map(
        (bodyRowNode: ReportNodeSchema[], rowIndex: number) => (
          <View key={rowIndex} style={styles.tableRow}>
            {/* 行数 */}
            <Text style={[styles.baseTableBodyCell, styles.tableBodyFirstCell]}>
              {rowIndex + 1}
            </Text>
            {bodyRowNode.map((node, colIndex) => (
              <GridLayoutTableBodyCell
                key={`${rowIndex}-${colIndex}`}
                node={node}
              />
            ))}
          </View>
        ),
      )}
    </>
  )
}
