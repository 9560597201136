import { Empty } from 'antd'

export const CustomEmptyText = () => {
  return (
    <Empty
      description="指定されたページにはデータがありません"
      image={Empty.PRESENTED_IMAGE_SIMPLE}
    />
  )
}
