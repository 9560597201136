import { Dropdown, MenuProps } from 'antd'
import React from 'react'
import ImproveEmailLogsContainer from 'src/features/improveEmailLogs/ImproveEmailLogsContainer'
import { Primary, White } from 'src/features/theme/KdsThemeColor'
import styled from 'styled-components'

interface OwnProps {
  improveUUID: string
  changeIsShowImproveEmailLogsDrawer: (isShow: boolean) => void
  /** 「提出履歴」をクリックしたときのハンドラー */
  getImproveEmailLogs: (uuid: string) => void
  /** 「提出」をクリックしたときのハンドラー */
  handleSubmitClick: () => void
  isButtonColored: boolean
  /** ボタンに表示する文字列 */
  text: string
  disabled: boolean
}

const StyledDropdownButton = styled(Dropdown.Button)<{
  isColored: boolean
}>`
  color: ${({ isColored }) => (isColored ? White : '#1e242c')};
  border: ${({ isColored }) => (isColored ? `1px ${Primary}` : '1px #cbd2dd')};
`

export const SubmitDropdownButton: React.FC<OwnProps> = props => {
  const {
    isButtonColored,
    improveUUID,
    changeIsShowImproveEmailLogsDrawer,
    getImproveEmailLogs,
    handleSubmitClick,
    text,
    disabled,
  } = props

  const menuProps: MenuProps = {
    items: [
      {
        label: '提出履歴',
        key: '1',
      },
    ],
    onClick: () => {
      changeIsShowImproveEmailLogsDrawer(true)
      getImproveEmailLogs(improveUUID)
    },
  }

  return (
    <>
      <StyledDropdownButton
        menu={menuProps}
        type="primary"
        onClick={() => handleSubmitClick()}
        isColored={isButtonColored}
        disabled={disabled}
      >
        {text}
      </StyledDropdownButton>
      <ImproveEmailLogsContainer improveUUID={improveUUID} />
    </>
  )
}
