import {
  Image,
  LogicConditionTypeEnum,
  MultipleChoice,
  NumberCondition,
  PlaceNode,
  TemplateNodeSchema,
  TemplatePageSchema,
} from '@ulysses-inc/harami_api_client'
import { MoveNodeEvent } from 'src/features/templateEdit/dragAndDrop/DnDTemplateType'
import { ActionTypes } from './types'

export const addSuccessTemplatePages = () => {
  return {
    type: ActionTypes.SUCCESS_ADD_TEMPLATE_PAGES,
    isLoading: false,
  }
}

export const addErrorTemplatePages = (error: Error) => {
  return {
    type: ActionTypes.ERROR_ADD_TEMPLATE_PAGES,
    isLoading: false,
    error,
  }
}

export const resetTemplatePages = () => {
  return {
    type: ActionTypes.RESET_TEMPLATE_PAGES,
  }
}

export const getTemplatePages = (
  templateId: number,
  loginPlaceNode?: PlaceNode,
) => {
  return {
    type: ActionTypes.REQUEST_GET_TEMPLATE_PAGES,
    isLoading: true,
    templateId,
    loginPlaceNode,
  }
}

export const getSuccessTemplatePages = (
  templatePages: { [key: number]: TemplatePageSchema },
  templateNodes: { [key: number]: TemplateNodeSchema },
  templatePageIds: Array<number>,
) => {
  return {
    type: ActionTypes.SUCCESS_GET_TEMPLATE_PAGES,
    isLoading: false,
    templateNodes,
    templatePages,
    templatePageIds,
  }
}

export const getErrorTemplatePages = (error: Error) => {
  return {
    type: ActionTypes.ERROR_GET_TEMPLATE_PAGES,
    isLoading: false,
    error,
  }
}

export const addTemplatePage = () => {
  return {
    type: ActionTypes.ADD_TEMPLATE_PAGE,
  }
}

export const deleteTemplatePage = (templatePageId: number) => {
  return {
    type: ActionTypes.DELETE_TEMPLATE_PAGE,
    templatePageId,
  }
}

export const updateTemplatePage = (
  templatePageId: number,
  changeTemplatePage: TemplatePageSchema,
) => {
  return {
    type: ActionTypes.UPDATE_TEMPLATE_PAGE,
    templatePageId,
    changeTemplatePage,
  }
}

// コピー処理を開始するアクション
// ローディングを出すために発火させる。
// コンポーネントからはこのアクションが呼ばれる。
export const startCopyTemplatePage = (targetPageId: number) => {
  return {
    type: ActionTypes.START_COPY_TEMPLATE_PAGE,
    targetPageId,
  }
}

// 実際にコピーを実行するアクション。
// reducer でコピー処理を行っている。
// saga から発火される。
export const copyTemplatePage = (targetPageId: number) => {
  return {
    type: ActionTypes.COPY_TEMPLATE_PAGE,
    targetPageId,
  }
}

export const clearCopyTemplatePageError = () => {
  return {
    type: ActionTypes.CLEAR_COPY_TEMPLATE_PAGE_ERROR,
  }
}

export const moveTemplatePage = (dragKey: number, hoverKey: number) => {
  return {
    type: ActionTypes.MOVE_TEMPLATE_PAGE,
    dragKey,
    hoverKey,
  }
}

/**
 * ドラッグアンドドロップ操作にもとづき、ノードを適切な位置に移動する
 */
export const moveTemplateNode = (
  moveNodeEvent: MoveNodeEvent,
  isSameParent: boolean,
) => {
  return {
    type: ActionTypes.MOVE_TEMPLATE_NODE,
    moveNodeEvent,
    isSameParent,
  }
}

export const changeActivePageId = (activePageId: number) => {
  return {
    type: ActionTypes.CHANGE_ACTIVE_PAGE_ID,
    activePageId,
  }
}

/**
 * 編集対象のノードを変更する
 */
export const changeActiveNodeId = (activeNodeId: number) => {
  return {
    type: ActionTypes.CHANGE_ACTIVE_NODE_ID,
    activeNodeId,
  }
}

/**
 * ページの直下にノードを追加する
 *
 * ノードの直下への追加では使用されないので注意。
 */
export const addTemplateNodeIntoPage = (
  targetNode: TemplateNodeSchema | null,
  createNode: TemplateNodeSchema,
  createNodeId: number,
  position: 'top' | 'bottom',
) => {
  return {
    type: ActionTypes.ADD_TEMPLATE_NODE_INTO_PAGE,
    targetNode,
    createNode,
    createNodeId,
    position,
  }
}

/**
 * ノードの直下にノードを追加する
 *
 * セクションノードや条件分岐ノードの直下にノードを追加する際に利用される。
 */
export const addTemplateNodeIntoNode = (
  targetNode: TemplateNodeSchema | null,
  parentNode: TemplateNodeSchema | null,
  createNode: TemplateNodeSchema,
  createNodeId: number,
  position: 'top' | 'bottom',
) => {
  return {
    type: ActionTypes.ADD_TEMPLATE_NODE_INTO_NODE,
    targetNode,
    parentNode,
    createNode,
    createNodeId,
    position,
  }
}

/**
 * 選択肢による条件分岐ノードを追加する
 */
export const addTemplateMultipleChoiceLogic = (
  targetNode: TemplateNodeSchema,
  conditionType: LogicConditionTypeEnum,
  responseMultipleChoices: Array<MultipleChoice>,
) => {
  return {
    type: ActionTypes.ADD_TEMPLATE_MULTIPLE_CHOICE_LOGIC,
    targetNode,
    conditionType,
    responseMultipleChoices,
  }
}

/**
 * 選択肢による条件分岐ノードを更新する
 */
export const updateTemplateMultipleChoiceLogic = (
  targetNode: TemplateNodeSchema,
  conditionType: LogicConditionTypeEnum,
  responseMultipleChoices: Array<MultipleChoice>,
) => {
  return {
    type: ActionTypes.UPDATE_TEMPLATE_MULTIPLE_CHOICE_LOGIC,
    targetNode,
    conditionType,
    responseMultipleChoices,
  }
}

/**
 * 数値による条件分岐ノードを追加する
 */
export const addTemplateNumberLogic = (
  targetNode: TemplateNodeSchema,
  numberConditions: Array<NumberCondition>,
) => {
  return {
    type: ActionTypes.ADD_TEMPLATE_NUMBER_LOGIC,
    targetNode,
    numberConditions,
  }
}

/**
 * 数値による条件分岐ノードを更新する
 */
export const updateTemplateNumberLogic = (
  targetNode: TemplateNodeSchema,
  numberConditions: Array<NumberCondition>,
) => {
  return {
    type: ActionTypes.UPDATE_TEMPLATE_NUMBER_LOGIC,
    targetNode,
    numberConditions,
  }
}

/**
 * ひな形ノードの情報を更新する
 *
 * データを直接的に改変しており、Reduxのメリットをほぼ捨て去っている邪悪なアクションである。
 * 今後、分割と抽象化を行うことが必須。
 *
 * - 逸脱時通知先を設定する
 * - 各質問のヒントを設定する
 * - 計算式タイプの質問に計算式を設定する
 * - 数値タイプの質問にルールを設定する
 * - 計算式タイプの質問にルールを設定する
 * - 時間計測タイプの質問に計測を設定する
 * - 時間計測タイプの質問にルールを設定する
 * - ヒントをまるっと削除する
 * - セクション名入力欄にセクション名を入力する
 * - セクションを繰り返すかどうかを設定する
 * - セクションを従業員チェックタイプに設定する
 * - （その他多数）
 */
export const updateTemplateNode = (
  nodeId: number,
  changeNode: TemplateNodeSchema,
) => {
  return {
    type: ActionTypes.UPDATE_TEMPLATE_NODE,
    nodeId,
    changeNode,
  }
}

export const changeQuestionToNext = (
  nodeId: number,
  node: TemplateNodeSchema,
  parentNode: TemplateNodeSchema | null,
) => {
  return {
    type: ActionTypes.CHANGE_QUESTION_TO_NEXT,
    nodeId,
    node,
    parentNode,
  }
}

/**
 * セクション配下の最初のノードをアクティブにする。配下にノードがなければ作成する。
 */
export const changeSectionToNext = (
  nodeId: number,
  node: TemplateNodeSchema,
) => {
  return {
    type: ActionTypes.CHANGE_SECTION_TO_NEXT,
    nodeId,
    node,
  }
}

export const changeLogicToNext = (nodeId: number, node: TemplateNodeSchema) => {
  return {
    type: ActionTypes.CHANGE_LOGIC_TO_NEXT,
    nodeId,
    node,
  }
}

/**
 * 指定したノードを削除する
 */
export const deleteTemplateNode = (
  nodeId: number,
  parentNode: TemplateNodeSchema | null,
) => {
  return {
    type: ActionTypes.DELETE_TEMPLATE_NODE,
    nodeId,
    parentNode,
  }
}

/**
 * ページの直下にセクションを追加する
 *
 * セクションの直下にセクションを追加する際には使用されないので注意。
 */
export const addSectionToPage = (
  originalNodeId: number,
  node: TemplateNodeSchema,
  childNodes: { [key: number]: TemplateNodeSchema },
  parentPageId: number,
) => {
  return {
    type: ActionTypes.ADD_SECTION_TO_PAGE,
    originalNodeId,
    node,
    childNodes,
    parentPageId,
  }
}

/**
 * セクションの直下にセクションを追加する
 */
export const addSectionToSection = (
  originalNodeId: number,
  node: TemplateNodeSchema,
  childNodes: { [key: number]: TemplateNodeSchema },
  parentNodeId: number,
) => {
  return {
    type: ActionTypes.ADD_SECTION_TO_SECTION,
    originalNodeId,
    node,
    childNodes,
    parentNodeId,
  }
}

export const updateTemplateNodeMultipleChoice = (
  multipleChoiceId: number,
  multipleChoices: Array<MultipleChoice>,
) => {
  return {
    type: ActionTypes.UPDATE_TEMPLATE_NODE_MULTIPLE_CHOICE,
    multipleChoiceId,
    multipleChoices,
  }
}

export const setTemplatePageErrorMessage = (message: string) => {
  return {
    type: ActionTypes.SET_TEMPLATE_PAGE_ERROR_MESSAGE,
    message,
  }
}

/**
 * インフォメーションタイプの質問に画像やPDFファイルを添付する
 */
export const uploadInformationFiles = (files: File[], nodeId: number) => {
  return {
    type: ActionTypes.REQUEST_UPLOAD_INFORMATION_FILES,
    isLoadingInformation: true,
    files,
    nodeId,
  }
}

export const uploadSuccessInformationFiles = (
  nodeId: number,
  informationFiles: Image[],
) => {
  return {
    type: ActionTypes.SUCCESS_UPLOAD_INFORMATION_FILES,
    isLoadingInformation: false,
    nodeId,
    informationFiles,
  }
}

export const uploadErrorInformationFiles = (error: Error) => {
  return {
    type: ActionTypes.ERROR_UPLOAD_INFORMATION_FILES,
    isLoadingInformation: false,
    error,
  }
}

export const addGridSectionIntoActivePage = (hasVariables: boolean) => {
  return {
    type: ActionTypes.ADD_GRID_SECTION_INTO_ACTIVE_PAGE,
    hasVariables,
  }
}

export type TemplatePagesAction =
  | ReturnType<typeof addSuccessTemplatePages>
  | ReturnType<typeof addErrorTemplatePages>
  | ReturnType<typeof resetTemplatePages>
  | ReturnType<typeof addTemplatePage>
  | ReturnType<typeof deleteTemplatePage>
  | ReturnType<typeof updateTemplatePage>
  | ReturnType<typeof copyTemplatePage>
  | ReturnType<typeof startCopyTemplatePage>
  | ReturnType<typeof clearCopyTemplatePageError>
  | ReturnType<typeof moveTemplatePage>
  | ReturnType<typeof moveTemplateNode>
  | ReturnType<typeof getTemplatePages>
  | ReturnType<typeof getSuccessTemplatePages>
  | ReturnType<typeof getErrorTemplatePages>
  | ReturnType<typeof changeActivePageId>
  | ReturnType<typeof changeActiveNodeId>
  | ReturnType<typeof addTemplateNodeIntoPage>
  | ReturnType<typeof addTemplateNodeIntoNode>
  | ReturnType<typeof addTemplateMultipleChoiceLogic>
  | ReturnType<typeof updateTemplateMultipleChoiceLogic>
  | ReturnType<typeof addTemplateNumberLogic>
  | ReturnType<typeof updateTemplateNumberLogic>
  | ReturnType<typeof updateTemplateNode>
  | ReturnType<typeof changeQuestionToNext>
  | ReturnType<typeof changeSectionToNext>
  | ReturnType<typeof changeLogicToNext>
  | ReturnType<typeof deleteTemplateNode>
  | ReturnType<typeof addSectionToPage>
  | ReturnType<typeof addSectionToSection>
  | ReturnType<typeof updateTemplateNodeMultipleChoice>
  | ReturnType<typeof setTemplatePageErrorMessage>
  | ReturnType<typeof uploadInformationFiles>
  | ReturnType<typeof uploadSuccessInformationFiles>
  | ReturnType<typeof uploadErrorInformationFiles>
  | ReturnType<typeof addGridSectionIntoActivePage>
