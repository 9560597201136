import { Button } from 'antd'
import { FC } from 'react'
import { Header } from 'src/components/header/Header'
import { HeaderBackButton } from 'src/components/header/HeaderBackButton'
import { HeaderTitle } from 'src/components/header/HeaderTitle'

type Props = {
  onBackButtonClicked: () => void
  onOutputButtonClicked: () => void
}

export const ReportExportDetailHeader: FC<Props> = ({
  onBackButtonClicked,
  onOutputButtonClicked,
}) => {
  const headerText = '新規でレポート出力'

  return (
    <Header
      aria-label={headerText}
      rightSlot={
        <Button onClick={onOutputButtonClicked} type="primary">
          出力
        </Button>
      }
    >
      <HeaderBackButton onClick={onBackButtonClicked} />
      <HeaderTitle title={headerText} />
    </Header>
  )
}
