import {
  ReportNodeSchema,
  TemplateNodeTypeEnum,
} from '@ulysses-inc/harami_api_client'

/**
 * 与えられたノードがセクションであるか判定する。
 */
export const isSection = (node: ReportNodeSchema): boolean => {
  return node.type === TemplateNodeTypeEnum.Section
}
