/**
 * Tomatoで使用する色を原始的に定義したもの。
 * この変数はセマンティックなカラーを介して使うことを想定しているため、
 * 直接コンポーネント等から呼び出して使用することは避けること。
 * 詳細は`semanticColors.ts`を参照。
 */
export const primitiveColors = {
  red: {
    _0: '#fff5f5',
    _1: '#ffe3e3',
    _2: '#ffc9c9',
    _3: '#ffa8a8',
    _4: '#ff8787',
    _5: '#ff6b6b',
    _6: '#fa5252',
    _7: '#f03e3e',
    _8: '#e03131',
    _9: '#c92a2a',
    _10: '#8a1d1d',
    _11: '#601414',
  },
  pink: {
    _0: '#fff0f6',
    _1: '#ffdeeb',
    _2: '#fcc2d7',
    _3: '#faa2c1',
    _4: '#f783ac',
    _5: '#f06595',
    _6: '#e64980',
    _7: '#d6336c',
    _8: '#c2255c',
    _9: '#a61e4d',
    _10: '#7b1639',
    _11: '#500e25',
  },
  grape: {
    _0: '#f8f0fc',
    _1: '#f3d9fa',
    _2: '#eebefa',
    _3: '#e599f7',
    _4: '#da77f2',
    _5: '#cc5de8',
    _6: '#be4bdb',
    _7: '#ae3ec9',
    _8: '#9c36b5',
    _9: '#862e9c',
    _10: '#642275',
    _11: '#42174d',
  },
  violet: {
    _0: '#f3f0ff',
    _1: '#e5dbff',
    _2: '#d0bfff',
    _3: '#b197fc',
    _4: '#9775fa',
    _5: '#845ef7',
    _6: '#7950f2',
    _7: '#7048e8',
    _8: '#6741d9',
    _9: '#5f3dc4',
    _10: '#422a8a',
    _11: '#301e63',
  },
  indigo: {
    _0: '#edf2ff',
    _1: '#dbe4ff',
    _2: '#bac8ff',
    _3: '#91a7ff',
    _4: '#748ffc',
    _5: '#5c7cfa',
    _6: '#4c6ef5',
    _7: '#4263eb',
    _8: '#3b5bdb',
    _9: '#364fc7',
    _10: '#2b3f9f',
    _11: '#1b2763',
  },
  blue: {
    _0: '#ebfaff',
    _1: '#c7f1ff',
    _2: '#a3e7ff',
    _3: '#6ed6fe',
    _4: '#48c6f7',
    _5: '#27b6f2',
    _6: '#10a8eb',
    _7: '#1499e0',
    _8: '#1085c4',
    _9: '#0d71a6',
    _10: '#0b618e',
    _11: '#07415f',
  },
  cyan: {
    _0: '#e3fafc',
    _1: '#c5f6fa',
    _2: '#99e9f2',
    _3: '#66d9e8',
    _4: '#3bc9db',
    _5: '#22b8cf',
    _6: '#15aabf',
    _7: '#1098ad',
    _8: '#0c8599',
    _9: '#0b7285',
    _10: '#095e6d',
    _11: '#074a56',
  },
  teal: {
    _0: '#e6fcf5',
    _1: '#c3fae8',
    _2: '#96f2d7',
    _3: '#63e6be',
    _4: '#38d9a9',
    _5: '#20c997',
    _6: '#12b886',
    _7: '#0ca678',
    _8: '#099268',
    _9: '#087f5b',
    _10: '#06674a',
    _11: '#054f39',
  },
  green: {
    _0: '#ebfbee',
    _1: '#d3f9d8',
    _2: '#b2f2bb',
    _3: '#8ce99a',
    _4: '#69db7c',
    _5: '#51cf66',
    _6: '#40c057',
    _7: '#37b24d',
    _8: '#2f9e44',
    _9: '#2b8a3e',
    _10: '#1f632d',
    _11: '#195024',
  },
  lime: {
    _0: '#f4fce3',
    _1: '#e9fac8',
    _2: '#d8f5a2',
    _3: '#c0eb75',
    _4: '#a9e34b',
    _5: '#94d82d',
    _6: '#82c91e',
    _7: '#74b816',
    _8: '#66a80f',
    _9: '#5c940d',
    _10: '#3F6509',
    _11: '#3f6509',
  },
  yellow: {
    _0: '#fff9db',
    _1: '#fff3bf',
    _2: '#ffec99',
    _3: '#ffe066',
    _4: '#ffd43b',
    _5: '#fcc419',
    _6: '#fab005',
    _7: '#f59f00',
    _8: '#f08c00',
    _9: '#e67700',
    _10: '#b35d00',
    _11: '#804200',
  },
  orange: {
    _0: '#fff4e6',
    _1: '#ffe8cc',
    _2: '#ffd8a8',
    _3: '#ffc078',
    _4: '#ffa94d',
    _5: '#ff922b',
    _6: '#fd7e14',
    _7: '#f76707',
    _8: '#e8590c',
    _9: '#d9480f',
    _10: '#a9380c',
    _11: '#7a2808',
  },
  gray: {
    _0: '#f7f9fb',
    _1: '#eff2f7',
    _2: '#e6ebf2',
    _3: '#dbe1e9',
    _4: '#cbd2dd',
    _5: '#a8b3c2',
    _6: '#7f8c9d',
    _7: '#434e5d',
    _8: '#303844',
    _9: '#1e242c',
    // grayは9までしかないのが仕様
  },
  white: '#ffffff',
  /**
   * テキストやオーバーレイなど、透過率をもつパーツの色
   *
   * https://ant.design/docs/spec/colors#neutral-color
   */
  neutralColor: {
    _2: '#1e242c05',
    _4: '#1e242c0a',
    _6: '#1e242c0f',
    _15: '#1e242c26',
    _25: '#1e242c40',
    _45: '#1e242c73',
    _65: '#1e242ca6',
    _88: '#1e242ce0',
  },
} as const

/**
 * 以下は、Figmaからエクスポートした色情報を加工するためのスクリプトを念のため残しておいたもの。
 * `deno run --allow-read --allow-write ./thisCode.ts` で実行できる。
 *
 * ```ts
 * import fs from 'fs'
 * import jsonFile from './token.json' with { type: 'json' }
 *
 * const requiredColors = [
 *   'red',
 *   'pink',
 *   'grape',
 *   'violet',
 *   'indigo',
 *   'blue',
 *   'cyan',
 *   'teal',
 *   'green',
 *   'lime',
 *   'yellow',
 *   'orange',
 *   'gray',
 * ]
 *
 * const requiredTones = [
 *   '0',
 *   '1',
 *   '2',
 *   '3',
 *   '4',
 *   '5',
 *   '6',
 *   '7',
 *   '8',
 *   '9',
 *   '10',
 *   '11',
 * ]
 *
 * const result = {}
 *
 * for (const color of requiredColors) {
 *   result[color] = {}
 *
 *   for (const tone of requiredTones) {
 *     result[color][tone] =
 *       jsonFile.colors.light.colors[color][tone]?.value || 'error'
 *   }
 * }
 *
 * fs.writeFileSync('result.json', JSON.stringify(result))
 * ```
 */
