import {
  MultipleChoice,
  MultipleChoiceGroup,
  Template,
} from '@ulysses-inc/harami_api_client'
import { ResponseMultipleChoiceAction } from 'src/state/ducks/templates/responseMultipleChoices/actions'
import { ActionTypes } from 'src/state/ducks/templates/responseMultipleChoices/types'

interface ResponseMultipleChoiceState {
  /**
   * 選択肢を更新または削除する際に、その選択肢のIDを保持している
   */
  multipleChoiceId: number // FIXME: 未利用では？
  /**
   * 選択肢を追加または更新する際に、その選択肢群を保持している
   */
  multipleChoices: Array<MultipleChoice> // FIXME: 未利用では？
  /**
   * ユーザーが画面上で選択できる選択肢群
   */
  multipleChoiceGroups: Array<MultipleChoiceGroup>
  /**
   * ある選択肢を編集 or 削除する際に、その選択肢が現に使用されているひな形群をAPIから取得して、一時的に保持するための場所。
   * すでに使われている場合は削除させないようにしたり、編集時に警告したりするために使用している。
   *
   * - nullはAPIから情報を取得できていない or 取得中であることを表す。
   * - APIからの情報取得が完了すると、必ず配列がセットされる(長さ0もあり得る)。
   */
  relatedTemplates: Array<Template> | null
  templateId: number // FIXME: 未利用では？
  isLoading: boolean // FIXME: 未利用では？
  error: Error | null // FIXME: 未利用では？
}

export const responseMultipleChoicesReducer = (
  state: ResponseMultipleChoiceState = {
    multipleChoiceId: 0,
    multipleChoices: [],
    multipleChoiceGroups: [],
    relatedTemplates: null,
    templateId: 0,
    isLoading: false,
    error: null,
  },
  action: ResponseMultipleChoiceAction,
): ResponseMultipleChoiceState => {
  switch (action.type) {
    case ActionTypes.REQUEST_ADD_MULTIPLE_CHOICES: {
      return {
        ...state,
        isLoading: action.isLoading,
        multipleChoices: action.multipleChoices,
        error: null,
      }
    }
    case ActionTypes.SUCCESS_ADD_MULTIPLE_CHOICES: {
      return { ...state, isLoading: action.isLoading }
    }
    case ActionTypes.ERROR_ADD_MULTIPLE_CHOICES: {
      return { ...state, isLoading: action.isLoading, error: action.error }
    }
    case ActionTypes.REQUEST_UPDATE_MULTIPLE_CHOICES: {
      return {
        ...state,
        isLoading: action.isLoading,
        multipleChoiceId: action.multipleChoiceId,
        multipleChoices: action.multipleChoices,
        error: null,
      }
    }
    case ActionTypes.SUCCESS_UPDATE_MULTIPLE_CHOICES: {
      const newMultipleChoice: MultipleChoiceGroup = {
        id: action.multipleChoiceId,
        responses: action.multipleChoices,
      }
      const newMultipleChoiceGroups = state.multipleChoiceGroups.map(
        multipleChoice => {
          return multipleChoice.id === action.multipleChoiceId
            ? newMultipleChoice
            : multipleChoice
        },
      )
      return {
        ...state,
        isLoading: action.isLoading,
        multipleChoiceGroups: newMultipleChoiceGroups,
      }
    }
    case ActionTypes.ERROR_UPDATE_MULTIPLE_CHOICES: {
      return { ...state, isLoading: action.isLoading, error: action.error }
    }
    case ActionTypes.REQUEST_DELETE_MULTIPLE_CHOICES: {
      return {
        ...state,
        isLoading: action.isLoading,
        multipleChoiceId: action.multipleChoiceId,
        error: null,
      }
    }
    case ActionTypes.SUCCESS_DELETE_MULTIPLE_CHOICES: {
      const multipleChoiceGroups = state.multipleChoiceGroups.filter(
        group => group.id !== action.multipleChoiceId,
      )
      return { ...state, isLoading: action.isLoading, multipleChoiceGroups }
    }
    case ActionTypes.ERROR_DELETE_MULTIPLE_CHOICES: {
      return { ...state, isLoading: action.isLoading, error: action.error }
    }
    case ActionTypes.REQUEST_GET_MULTIPLE_CHOICES: {
      return { ...state, isLoading: action.isLoading, error: null }
    }
    case ActionTypes.SUCCESS_GET_MULTIPLE_CHOICES: {
      return {
        ...state,
        isLoading: action.isLoading,
        multipleChoiceGroups: action.multipleChoiceGroups,
      }
    }
    case ActionTypes.ERROR_GET_MULTIPLE_CHOICES: {
      return { ...state, isLoading: action.isLoading, error: action.error }
    }
    case ActionTypes.REQUEST_GET_TEMPLATES_BY_MULTIPLE_CHOICE_GROUP: {
      return {
        ...state,
        isLoading: action.isLoading,
        error: null,
        relatedTemplates: null,
      }
    }
    case ActionTypes.SUCCESS_GET_TEMPLATES_BY_MULTIPLE_CHOICE_GROUP: {
      return {
        ...state,
        isLoading: action.isLoading,
        relatedTemplates: action.templates,
      }
    }
    case ActionTypes.ERROR_GET_TEMPLATES_BY_MULTIPLE_CHOICE_GROUP: {
      return { ...state, isLoading: action.isLoading, error: action.error }
    }
    case ActionTypes.RESET_RELATED_TEMPLATES: {
      return { ...state, relatedTemplates: null }
    }
    default: {
      return { ...state }
    }
  }
}
