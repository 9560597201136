import { css } from '@emotion/react'
import { IndicatedIssueStatusEnum } from '@ulysses-inc/harami_api_client'
import { Typography } from 'antd'
import React from 'react'
import {
  getStatusColor,
  getStatusIconComponent,
  getStatusName,
} from 'src/constants/indicatedIssueStatus'
import styled from 'styled-components'

const { Text } = Typography

const Row = styled.div`
  word-break: break-all;
  width: 100%;
  padding-left: 1px;
`

const statusIcon = (
  isAuditorWorking: boolean,
  rejectExists: boolean,
  reviseExists: boolean,
  isRejectEditStatus: boolean,
  status?: IndicatedIssueStatusEnum,
) => {
  if (!status) return <></>
  const StatusIconComponent = getStatusIconComponent(
    isAuditorWorking,
    isRejectEditStatus,
    status,
  )
  const statusColor = getStatusColor(
    isAuditorWorking,
    isRejectEditStatus,
    status,
  )
  const statusName = getStatusName(
    isAuditorWorking,
    rejectExists,
    reviseExists,
    isRejectEditStatus,
    status,
  )
  // The status is not displayed for the first audit submission.
  const statusNBIcon =
    isAuditorWorking && !reviseExists ? null : (
      <StatusIconComponent
        css={css`
          color: ${statusColor};
          font-size: 14px;
        `}
      />
    )
  return (
    <>
      {statusNBIcon}
      <Text style={{ color: statusColor, marginLeft: 5 }}>{statusName}</Text>
    </>
  )
}

type OwnProps = {
  isAuditorWorking: boolean
  rejectExists: boolean
  reviseExists: boolean
  isRejectEditStatus: boolean
  status?: IndicatedIssueStatusEnum
}
const Component: React.FC<OwnProps> = props => {
  return (
    <Row>
      {statusIcon(
        props.isAuditorWorking,
        props.rejectExists,
        props.reviseExists,
        props.isRejectEditStatus,
        props.status,
      )}
    </Row>
  )
}

export default Component
