import { DownOutlined, UpOutlined } from '@ant-design/icons'
import { css } from '@emotion/react'
import { GetImprovesRequest, PlaceNode } from '@ulysses-inc/harami_api_client'
import { Button, Dropdown, Input, Select, Typography } from 'antd'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { MomentDatePicker } from 'src/components/datepicker/MomentDatePicker'
import date from 'src/exShared/util/date'
import {
  flattenNodes,
  mergedPlaceNodeIds,
} from 'src/exShared/util/place/placeNode'
import { splitLoginPlaceUUIDs } from 'src/exShared/util/place/splitLoginPlace'
import { BorderColor, White } from 'src/features/theme/KdsThemeColor'
import { useListFilter } from 'src/hooks/filter/useListFilter'
import { GetImprovesFilter } from 'src/state/ducks/improves/types'
import { RootState } from 'src/state/store'
import styled from 'styled-components'

const { Text } = Typography
const { Option } = Select

const TableCell = styled.div`
  display: table-cell;
  border: 1px solid ${BorderColor};
  background: ${White};
  vertical-align: middle;
`
const FilterButton = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  cursor: pointer;
`

const UpOutlinedIcon = styled(UpOutlined)`
  color: rgba(0, 0, 0, 0.65);
`

const DownOutlinedIcon = styled(DownOutlined)`
  color: rgba(0, 0, 0, 0.65);
`

const SelectFilterTableCell = styled(TableCell)`
  min-width: ${({ theme }) => theme.minWidth};
  background-color: ${({ theme }) => theme.backgroundColor};
  height: 32px;
`

const DropDownTableCell = styled(TableCell)`
  width: 400px;
  background-color: ${White};
  padding: 16px;
`

const styles = {
  clearButtonRow: css`
    display: flex;
    justify-content: flex-end;
  `,
}

interface OwnProps {
  places: PlaceNode[]
  getFilterImproves: (filter?: GetImprovesFilter) => void
  request?: GetImprovesRequest
  isLoadingPlace: boolean
}

const ImprovesFilterDropDown: React.FC<OwnProps> = (props: OwnProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const { filters, setListFilter } = useListFilter()
  const [reportName, setReportName] = useState<string>(
    filters?.improves?.filter?.reportName || '',
  )
  const [placeNodeIds, setPlaceNodeIds] = useState<string[]>(
    filters?.improves?.filter?.placeNodeIds || [],
  )
  const [from, setFrom] = useState<string | undefined>(
    filters?.improves?.filter?.from || undefined,
  )
  const [through, setThrough] = useState<string | undefined>(
    filters?.improves?.filter?.through || undefined,
  )

  const placeNodes = useSelector((state: RootState) =>
    flattenNodes(state.placesState.placeGroups.nodes),
  )
  const isLoadingPlace = useSelector(
    (state: RootState) => state.placesState.placeGroups.isLoading,
  )
  // 初回及び、全現場表示ボタン切り替え時にフィルタで選択可能な現場をセット
  useEffect(() => {
    if (props.places.length === 0 || isLoadingPlace) {
      return
    }
    const loginPlaceUUIDs = splitLoginPlaceUUIDs()
    let filterPlaceNodeIds: string[] = placeNodeIds
    // 検索条件が保存されていない場合、ログインユーザーの所属している現場グループ、現場をフィルター条件に設定する
    if (
      !filters?.improves?.filter &&
      loginPlaceUUIDs.loginPlaceGroupIds.length > 0
    ) {
      filterPlaceNodeIds = mergedPlaceNodeIds(
        placeNodes,
        loginPlaceUUIDs.loginPlaceIds,
        loginPlaceUUIDs.loginPlaceGroupIds,
        false,
      ).filter(
        placeNodeId =>
          !loginPlaceUUIDs.loginPlaceGroupIds.includes(placeNodeId),
      )
      if (filterPlaceNodeIds.length > 0) {
        setPlaceNodeIds(filterPlaceNodeIds)
      }
    }
    const filter = getFilter(reportName, filterPlaceNodeIds, from, through)
    props.getFilterImproves(filter)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.places, isLoadingPlace])

  const isFilterOn = () => {
    return reportName || placeNodeIds.length > 0 || from || through
  }

  const clearFilter = () => {
    setReportName('')
    setPlaceNodeIds([])
    setFrom(undefined)
    setThrough(undefined)
    saveFilter()
    const filter = getFilter('', [], undefined, undefined)
    props.getFilterImproves(filter)
  }

  const saveFilter = (
    reportName?: string,
    placeNodeIds?: string[],
    from?: string,
    through?: string,
  ) => {
    setListFilter({
      ...(filters ?? {}),
      improves: {
        pagination: props.request,
        filter: {
          reportName: reportName ?? '',
          placeNodeIds: placeNodeIds,
          from: from ?? '',
          through: through ?? '',
        },
      },
    })
  }

  const getFilter = (
    reportName?: string,
    placeNodeIds?: string[],
    from?: string,
    through?: string,
  ): GetImprovesFilter => {
    const reportNameCondition =
      reportName && reportName.length > 0 ? { $like: reportName } : undefined
    const placeNodeIdCondition =
      placeNodeIds && placeNodeIds.length > 0
        ? { $in: placeNodeIds }
        : undefined
    // throughは画面表示の日付＋１日する
    const reportDateCondition =
      from && through
        ? {
            $gte: date.parseYYYYMMDD(from),
            $lte: date.nextDay(date.parseYYYYMMDD(through)),
          }
        : undefined
    saveFilter(reportName, placeNodeIds, from, through)
    return {
      reportName: reportNameCondition,
      placeNodeId: placeNodeIdCondition,
      reportDate: reportDateCondition,
    }
  }

  const handleReportNameChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const reportName = event.target.value
    setReportName(reportName)
    const filter = getFilter(reportName, placeNodeIds, from, through)
    props.getFilterImproves(filter)
  }

  const handlePlaceNodeChange = (selectedPlaceNodeIds: string[]) => {
    setPlaceNodeIds(selectedPlaceNodeIds)
    const filter = getFilter(reportName, selectedPlaceNodeIds, from, through)
    props.getFilterImproves(filter)
  }

  const handleDateRangeChange = (_: unknown, dateStrings: [string, string]) => {
    let from: string | undefined = undefined
    let through: string | undefined = undefined
    if (
      dateStrings === undefined ||
      dateStrings[0] === undefined ||
      dateStrings[1] === undefined
    ) {
      setFrom(undefined)
      setThrough(undefined)
    } else {
      from = dateStrings[0]
      through = dateStrings[1]
      setFrom(dateStrings[0])
      setThrough(dateStrings[1])
    }
    const filter = getFilter(reportName, placeNodeIds, from, through)
    props.getFilterImproves(filter)
  }

  const groundArea = (
    <SelectFilterTableCell theme={{ width: '160px' }}>
      <FilterButton>
        <div style={{ flex: 1, marginLeft: 12 }}>
          フィルター：{isFilterOn() ? 'ON' : 'OFF'}
        </div>
        <div style={{ minWidth: 20, marginRight: 12 }}>
          {isOpen ? <UpOutlinedIcon /> : <DownOutlinedIcon />}
        </div>
      </FilterButton>
    </SelectFilterTableCell>
  )

  const hoverArea = !props.isLoadingPlace && (
    <div style={{ display: 'flex' }}>
      <DropDownTableCell>
        <div css={styles.clearButtonRow}>
          <Button onClick={clearFilter}>クリア</Button>
        </div>

        <>
          <div style={{ marginBottom: 5 }}>
            <Text style={{ marginLeft: 3 }}>レポート名</Text>
          </div>
          <Input
            allowClear
            onChange={handleReportNameChange}
            defaultValue={reportName}
            value={reportName}
          />
        </>
        <div style={{ marginTop: 16, marginBottom: 5 }}>
          <Text style={{ marginLeft: 3 }}>現場名</Text>
        </div>
        <Select
          mode="multiple"
          style={{ width: '100%' }}
          value={placeNodeIds}
          optionFilterProp="label"
          onChange={handlePlaceNodeChange}
        >
          {props.places.map((placeNode: PlaceNode, index: number) => (
            <Option
              key={index}
              value={placeNode.uuid}
              label={placeNode.place?.name}
            >
              {placeNode.place?.name}
            </Option>
          ))}
        </Select>
        <div style={{ marginTop: 16, marginBottom: 5 }}>
          <Text style={{ marginLeft: 3 }}>実施日</Text>
        </div>
        <MomentDatePicker.RangePicker
          placeholder={['開始日', '終了日']}
          value={
            from && through ? [moment(from), moment(through)] : [null, null]
          }
          onChange={handleDateRangeChange}
        />
      </DropDownTableCell>
    </div>
  )

  return (
    <Dropdown
      dropdownRender={() => hoverArea}
      trigger={['click']}
      open={isOpen}
      onOpenChange={setIsOpen}
    >
      {groundArea}
    </Dropdown>
  )
}

export default ImprovesFilterDropDown
