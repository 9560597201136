import { DownOutlined, RightOutlined } from '@ant-design/icons'
import {
  ReportNodeSchema,
  ReportPageSchema,
  TemplateNodeTypeEnum,
} from '@ulysses-inc/harami_api_client'
import React, { JSX, useState } from 'react'
import { WebReportResultProps } from 'src/features/reports/result/WebReportResultProps'
import styled from 'styled-components'
import { DeviatedAnswerPerSection } from './DeviatedAnswerPerSection'
import { Divider } from './Divider'
import { Question } from './Question'

interface OwnProps {
  index: number
  sectionNodes: ReportNodeSchema[]
  page: ReportPageSchema
  answers: ReportNodeSchema[]
}

interface SectionAnswer {
  answers: ReportNodeSchema[]
  section: ReportNodeSchema
}

const DeviatedAnswersPageWrapper = styled.div`
  margin-bottom: 12px;
`

const DeviatedAnswers = styled.div`
  cursor: pointer;
`

const PagesDeviatedAnswers = styled.div`
  margin-top: 12px;
`

const ArrowWrapper = styled.div`
  margin-bottom: 12px;
`

const PageName = styled.span`
  flex: 1;
  font-size: 16px;
  font-weight: bold;
`

const SectionNodes = styled.div`
  font-size: 16px;
  font-weight: bold;
  margin-top: 12px;
  margin-bottom: 12px;
`

export const DeviatedAnswersPage: React.FC<OwnProps & WebReportResultProps> = (
  props: OwnProps & WebReportResultProps,
) => {
  const [sectionExpanded, setSectionExpanded] = useState(true)
  const handleOnSectionExpanded = () => setSectionExpanded(!sectionExpanded)

  const renderArrowIcon: () => JSX.Element = () => {
    return sectionExpanded ? (
      <DownOutlined style={{ marginRight: 10.6 }} />
    ) : (
      <RightOutlined style={{ marginRight: 10.6 }} />
    )
  }

  // セクション以外のノードを取得する
  const getNodesOtherThanSections = (
    node: ReportNodeSchema,
    nodes: { [key: number]: ReportNodeSchema },
  ): { [key: number]: ReportNodeSchema } => {
    return getChildIdsExcludingSection(node.nodes ?? [], [], nodes).reduce(
      (prev: { [key: number]: ReportNodeSchema }, cur: number) => {
        const n = nodes[cur]
        if (!n) return prev

        return { ...prev, [cur]: n }
      },
      {},
    )
  }

  // セクションを除くIDを取得
  const getChildIdsExcludingSection = (
    nodeIds: Array<number>,
    prevNodeIds: Array<number>,
    allNodes: { [key: number]: ReportNodeSchema },
  ): number[] => {
    if (nodeIds.length === 0) return prevNodeIds

    const filtered = nodeIds.filter((nodeId: number) => {
      const node = allNodes[nodeId]
      if (!node) {
        return false
      }
      return !(node.type === TemplateNodeTypeEnum.Section)
    })
    if (filtered.length === 0) return []

    const res: number[] = filtered
      .map((nodeId: number) => {
        const node = allNodes[nodeId]
        const grandchildNodeIds = node?.nodes ?? []
        if (grandchildNodeIds.length === 0) return [nodeId]
        return [
          nodeId,
          ...getChildIdsExcludingSection(grandchildNodeIds, nodeIds, allNodes),
        ]
      })
      .reduce((prev: number[], cur: number[]) => prev.concat(cur))
      .filter(
        (id: number, index: number, ids: number[]) => ids.indexOf(id) === index,
      )

    return res
  }

  const sectionNameList = () => {
    const sectionAnswers: SectionAnswer[] = props.sectionNodes.map(section => {
      // section配下にあるsection以外のnodeを取得する
      // section単位で逸脱項目を表示するため、異なるsectionの逸脱は表示しない
      const reportNodes: ReportNodeSchema[] = Object.values(
        getNodesOtherThanSections(section, props.report.nodes),
      )
      const reportNodeIds: number[] = reportNodes.map(node => node.id)

      // section単位の回答群
      const answers = props.answers.filter((answer: ReportNodeSchema) =>
        reportNodeIds.includes(answer.id),
      )

      return { answers, section }
    })

    const hasFindChildAnswer = (nodes: number[]): boolean => {
      return nodes.some((nodeId: number) => {
        const sectionAnswer = sectionAnswers.find(
          (sectionAnswer: SectionAnswer) => sectionAnswer.section.id === nodeId,
        )
        if (!sectionAnswer) {
          return false
        }
        if (sectionAnswer.answers.length > 0) {
          return true
        }
        return hasFindChildAnswer(sectionAnswer.section.nodes)
      })
    }

    const filterSectionAnswers = sectionAnswers.filter(
      (sectionAnswer: SectionAnswer) => {
        if (sectionAnswer.answers.length > 0) {
          return true
        }
        return hasFindChildAnswer(sectionAnswer.section.nodes)
      },
    )

    return filterSectionAnswers.map((sectionAnswer, index) => {
      const sectionName = sectionAnswer.section.section?.name
      const answers = sectionAnswer.answers
      return (
        <>
          <SectionNodes key={`Section-${index}`}>{sectionName}</SectionNodes>
          <DeviatedAnswerPerSection
            key={`DeviatedAnswerPerSection-${index}`}
            {...props}
            answers={answers}
          />
        </>
      )
    })
  }

  const renderPageDeviatedAnswers = () => {
    // 逸脱項目がセクションに属していないページ直下のものを表示
    // ただしセクション内に存在しているものは除外する
    const reportNodes: ReportNodeSchema[] = Object.values(
      props.sectionNodes,
    ).flatMap(n =>
      Object.values(getNodesOtherThanSections(n, props.report.nodes)),
    )
    const reportNodeIds: number[] = reportNodes.map(
      (node: ReportNodeSchema) => node.id,
    )

    return props.answers
      .filter(a => !reportNodeIds.includes(a.id))
      .map((answer, index) => {
        // Question内でLogicが呼ばれてしまうため isDeviatedRender をセット
        // 単に逸脱質問と回答を出したいだけなので、子階層を呼ばなくて良い
        return (
          <Question
            key={`Question-${index}`}
            node={answer}
            question={answer.question}
            isDeviatedRender
            {...props}
          />
        )
      })
  }

  return (
    <DeviatedAnswersPageWrapper>
      <DeviatedAnswers onClick={handleOnSectionExpanded}>
        <ArrowWrapper>
          {renderArrowIcon()}
          <PageName>{props.page.name}</PageName>
        </ArrowWrapper>
      </DeviatedAnswers>
      {sectionExpanded && <Divider />}

      {sectionExpanded && (
        <PagesDeviatedAnswers>
          {renderPageDeviatedAnswers()}
        </PagesDeviatedAnswers>
      )}

      {sectionExpanded && sectionNameList()}
    </DeviatedAnswersPageWrapper>
  )
}
