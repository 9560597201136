import {
  AddInformationFilesRequest,
  FilesApi,
  InformationFile,
  InformationFilesApi,
} from '@ulysses-inc/harami_api_client'
import { getFirstPageThumbnail } from 'src/exShared/files/pdfToImage'
import BaseClient from 'src/state/middleware/saga/baseClient'
import { handleHTTPError } from 'src/state/middleware/saga/handleHttpError'
import { compressImage } from '../images/sagas'

const baseClient = new BaseClient()

export type S3UploadResult = {
  /** アップロードしたファイルの現物 */
  file: File
  /**
   * アップロードしたファイルのKey情報
   * 例)
   * - `2024021913/4d393796-1978-4044-aa3e-5b0673e086fe`
   * - `{yyyyMMddHH(UTC)}/{uuid}` の形式
   */
  fileKey: string
}

/**
 * Pre-signed URLを使ってファイルをS3にアップロードする
 *
 * 1. APIからPre-signed URLを取得
 * 2. S3にファイルをアップロード
 *
 * PDFファイルの場合、PDF本体とサムネイル画像の2つをアップロードする。
 * PDF本体とサムネイル画像のURLは、拡張子のみ異なる同様のKeyで保存される。
 * Pre-signed URL発行時には、API側でkeyを生成しているが、同様のKeyで保存できるPre-signed URLを発行するために、
 * PDF用のAPIを別で用意して利用している。
 *
 * @param files
 * @returns
 */
export const uploadFilesToS3UsingPresignedURL = async (
  files: File[],
): Promise<S3UploadResult[]> => {
  return Promise.all(
    files.map(file => {
      if (file.type === 'application/pdf') return uploadPdfFile(file)
      return uploadImageFile(file)
    }),
  )
}

type UploadFunction = (file: File) => Promise<S3UploadResult>

const uploadImageFile: UploadFunction = async (imageFile: File) => {
  const { fileKey, url: presignedURL } = await postS3PresignedURLApi()
  await uploadFileUsingPresignedURL(imageFile, presignedURL)
  return { file: imageFile, fileKey }
}

const uploadPdfFile: UploadFunction = async (pdfFile: File) => {
  // Pre-signed URLを取得
  const {
    url: preSignedUrl,
    thumbnailUrl: preSignedUrlForThumbnail,
    fileKey,
  } = await postS3PresignedURLPdfApi()

  // PDFのアップロード
  await uploadFileUsingPresignedURL(pdfFile, preSignedUrl)

  // サムネイルのアップロード
  const thumbnailUrl = (await getFirstPageThumbnail(pdfFile)) ?? ''
  const thumbnailBlob = await fetch(thumbnailUrl).then(res => res.blob())
  await uploadFileUsingPresignedURL(thumbnailBlob, preSignedUrlForThumbnail)

  return { file: pdfFile, fileKey }
}

/**
 * filesテーブルにレコードを作成するAPIを叩く
 *
 * @param fileInfos
 * @returns
 */
export const addInformationFilesToApiServer = async (
  s3UploadResults: S3UploadResult[],
): Promise<InformationFile[]> => {
  return addInformationFilesApi({
    uploadInformationFile: {
      files: s3UploadResults.map(fileInfo => ({
        type: getFileExtension(fileInfo.file),
        name: fileInfo.file.name,
        size: fileInfo.file.size,
        key: fileInfo.fileKey,
      })),
    },
  })
}

export const compressFiles = async (files: File[]): Promise<File[]> => {
  return await Promise.all(
    files.map(async file => {
      const mime = file.type
      if (mime === 'image/jpeg' || mime === 'image/png')
        return await compressImage(file)

      return file
    }),
  )
}

const postS3PresignedURLApi = async () => {
  return baseClient.getApi(FilesApi).postS3PresignedUrl().catch(handleHTTPError)
}

const postS3PresignedURLPdfApi = async () => {
  return baseClient
    .getApi(FilesApi)
    .postS3PresignedUrlPdf()
    .catch(handleHTTPError)
}

const addInformationFilesApi = async (req: AddInformationFilesRequest) => {
  return baseClient
    .getApi(InformationFilesApi)
    .addInformationFiles(req)
    .catch(handleHTTPError)
}

const uploadFileUsingPresignedURL = async (
  file: File | Blob,
  presignedURL: string,
) => {
  const requestOptions: RequestInit = {
    method: 'PUT',
    body: file,
    headers: {
      'Content-Type': file.type,
    },
  }

  // 本来であればNetwork系エラーとレスポンスステータスコードエラーを分けてハンドリングするべきですが、
  // 現状、使用側で適切なエラーハンドリングが出来ていないため、単純にエラーをthrowしています。
  return fetch(presignedURL, requestOptions).then(res => {
    if (!res.ok) {
      throw new Error(
        `An unexpected status code was returned from S3: not within the 2xx range.` +
          `${res.status} ${res.statusText}`,
      )
    }
    // APIレスポンスは使用しないため返り値は返していない
  })
}

/**
 * PDFのURLからサムネイル画像のURLを取得する
 *
 * @param {string} fileUrl pdfファイルのURL
 * @returns {string}
 */
export const getPdfThumbUrlFromUrl = (fileUrl: string) => {
  return fileUrl.replace('.pdf', '.png')
}

const getFileExtension = (file: File) => {
  const fileName = file.name
  return fileName.split('.').slice(-1)[0] ?? ''
}
