import * as Sentry from '@sentry/browser'
import {
  AddTemplateHintsRequest,
  AddTemplatePagesRequest,
  AddTemplateRequest,
  CopyIndustryTemplatePagesRequest,
  GetIndustryTemplateHintsRequest,
  GetIndustryTemplateRequest,
  GetTemplatePagesV2Request,
  InformationFile,
  Template,
  TemplateHint,
  TemplatePage,
  TemplatesApi,
  UpdateTemplateRequest,
} from '@ulysses-inc/harami_api_client'
import { denormalize, normalize } from 'normalizr'
import { all, call, put, takeEvery } from 'redux-saga/effects'
import * as notificationServiceActions from 'src/features/notificationService/slice'
import * as actions from 'src/state/ducks/templates/actions'
import { setRepeatSerialNumbers } from 'src/state/ducks/templates/editSerialNumber/actions'
import * as editTemplateActions from 'src/state/ducks/templates/editTemplate/actions'
import { editTemplateSagas } from 'src/state/ducks/templates/editTemplate/sagas'
import { ActionTypes as ActionTypesOfEditTemplate } from 'src/state/ducks/templates/editTemplate/types'
import * as templateHintsActions from 'src/state/ducks/templates/templateHints/actions'
import { templateHintsSagas } from 'src/state/ducks/templates/templateHints/sagas'
import { history, store } from 'src/state/store'
import BaseClient from '../../middleware/saga/baseClient'
import {
  HTTPError,
  handleHTTPError,
  isHTTPError,
} from '../../middleware/saga/handleHttpError'
import {
  S3UploadResult,
  addInformationFilesToApiServer,
  compressFiles,
  uploadFilesToS3UsingPresignedURL,
} from '../files/saga'
import interceptionsActions from '../interceptions/actions'
import { alignResponseTimeMeasurements } from './function'
import { SchemaTemplatePages, TemplatePagesEntities } from './schemas'
import { ActionTypes } from './types'

const baseClient = new BaseClient()

const addTemplateRequest = (req: AddTemplateRequest) => {
  return baseClient.getApi(TemplatesApi).addTemplate(req).catch(handleHTTPError)
}

const updateTemplateRequest = (req: UpdateTemplateRequest) => {
  return baseClient
    .getApi(TemplatesApi)
    .updateTemplate(req)
    .catch(handleHTTPError)
}

const addTemplatePagesRequest = (req: AddTemplatePagesRequest) => {
  return baseClient
    .getApi(TemplatesApi)
    .addTemplatePages(req)
    .catch(e => {
      // templatePagesの登録に失敗した場合、sentryに詳細なログを送信する
      // https://kaminashi.atlassian.net/browse/HPB-5323
      Sentry.captureException(e, {
        extra: {
          addTemplatePagesRequest: req,
          templateState: store.getState().templatesState,
        },
      })
      return handleHTTPError(e)
    })
}

const getTemplatePagesV2Request = (req: GetTemplatePagesV2Request) => {
  return baseClient
    .getApi(TemplatesApi)
    .getTemplatePagesV2(req)
    .then(templatePages => templatePages)
    .catch(handleHTTPError)
}

const addTemplateHintsRequest = (req: AddTemplateHintsRequest) => {
  return baseClient
    .getApi(TemplatesApi)
    .addTemplateHints(req)
    .catch(handleHTTPError)
}

const getIndustryTemplateRequest = (req: GetIndustryTemplateRequest) => {
  return baseClient
    .getApi(TemplatesApi)
    .getIndustryTemplate(req)
    .then(template => template)
    .catch(handleHTTPError)
}

const getIndustryTemplateHintsRequest = (
  req: GetIndustryTemplateHintsRequest,
) => {
  return baseClient
    .getApi(TemplatesApi)
    .getIndustryTemplateHints(req)
    .then(templateHints => templateHints)
    .catch(handleHTTPError)
}

const copyIndustryTemplatePagesRequest = (
  req: CopyIndustryTemplatePagesRequest,
) => {
  return baseClient
    .getApi(TemplatesApi)
    .copyIndustryTemplatePages(req)
    .then(templatePages => templatePages)
    .catch(handleHTTPError)
}

function* addTemplate(
  action: ReturnType<typeof editTemplateActions.addTemplate>,
) {
  alignResponseTimeMeasurements(action.templateNodes)

  try {
    const template: Template = yield call(addTemplateRequest, {
      template: {
        name: action.name,
        isShowScore: action.isShowScore,
        isEditable: action.isEditable,
        isExcelConversion: action.isExcelConversion,
        isHideQuestionOptions: action.isHideQuestionOptions,
        isAudit: action.isAudit,
        isKeyboard: action.isKeyboard,
        layoutType: action.layoutType,
        hasVariables: action.hasVariables,
        manuals: action.manuals,
        icons: action.icons,
        multipleChoiceSets: action.multipleChoiceSets,
        approvalFlows: action.approvalFlowId
          ? [{ id: action.approvalFlowId }]
          : undefined,
        placeNodes: action.placeNodes,
      },
    })
    yield put(editTemplateActions.addSuccessTemplate())

    const templatePages = denormalize(
      action.templatePageIds,
      SchemaTemplatePages,
      {
        templatePages: action.templatePages,
        nodes: action.templateNodes,
      },
    )
    yield call(addTemplatePagesRequest, {
      addTemplatePages: { pages: templatePages },
      templateId: template.id ?? 0,
    })
    yield put(actions.addSuccessTemplatePages())
    yield call(addTemplateHintsRequest, {
      addTemplateHints: { hints: action.templateHints },
      templateId: template.id ?? 0,
    })
    yield put(templateHintsActions.addSuccessTemplateHints())
    yield put(
      notificationServiceActions.showNotification({
        message: 'ひな形の作成に成功しました',
      }),
    )
    history.push(`/templates`)
  } catch (error) {
    yield put(interceptionsActions.handleHttpError(error as HTTPError))
    yield put(editTemplateActions.addErrorTemplate(error as HTTPError))
  }
}

function* updateTemplate(
  action: ReturnType<typeof editTemplateActions.updateTemplate>,
) {
  alignResponseTimeMeasurements(action.templateNodes)

  try {
    yield call(updateTemplateRequest, {
      templateId: action.templateId,
      template: {
        name: action.name,
        isShowScore: action.isShowScore,
        isEditable: action.isEditable,
        isExcelConversion: action.isExcelConversion,
        isHideQuestionOptions: action.isHideQuestionOptions,
        isAudit: action.isAudit,
        isKeyboard: action.isKeyboard,
        layoutType: action.layoutType,
        hasVariables: action.hasVariables,
        manuals: action.manuals,
        icons: action.icons,
        multipleChoiceSets: action.multipleChoiceSets,
        approvalFlows: action.approvalFlowId
          ? [{ id: action.approvalFlowId }]
          : undefined,
        placeNodes: action.placeNodes,
      },
    })
    const templatePages = denormalize(
      action.templatePageIds,
      SchemaTemplatePages,
      {
        templatePages: action.templatePages,
        nodes: action.templateNodes,
      },
    )
    yield call(addTemplatePagesRequest, {
      addTemplatePages: { pages: templatePages },
      templateId: action.templateId,
    })
    yield put(actions.addSuccessTemplatePages())
    yield call(addTemplateHintsRequest, {
      addTemplateHints: { hints: action.templateHints },
      templateId: action.templateId,
    })
    yield put(templateHintsActions.addSuccessTemplateHints())
    yield put(editTemplateActions.updateSuccessTemplate())
    yield put(
      notificationServiceActions.showNotification({
        message: 'ひな形の更新に成功しました',
      }),
    )
  } catch (error) {
    yield put(interceptionsActions.handleHttpError(error as HTTPError))
    yield put(editTemplateActions.updateErrorTemplate(error as HTTPError))
  }
}

function* getTemplatePages(
  action: ReturnType<typeof actions.getTemplatePages>,
) {
  try {
    const params: GetTemplatePagesV2Request = {
      templateId: action.templateId,
      templateFilter: {
        placeNodeId: { $in: [action.loginPlaceNode?.uuid] },
      },
    }

    const templatePages: TemplatePage[] = yield call(
      getTemplatePagesV2Request,
      params,
    )
    const normalized = normalize<any, TemplatePagesEntities, Array<number>>(
      templatePages,
      SchemaTemplatePages,
    )
    yield put(
      actions.getSuccessTemplatePages(
        normalized.entities.templatePages,
        normalized.entities.nodes,
        normalized.result,
      ),
    )
    yield put(setRepeatSerialNumbers(Object.values(normalized.entities.nodes)))
  } catch (error) {
    yield put(interceptionsActions.handleHttpError(error as HTTPError))
    yield put(actions.getErrorTemplatePages(error as Error))
  }
}

function* uploadInformationFiles(
  action: ReturnType<typeof actions.uploadInformationFiles>,
) {
  try {
    // ファイルの圧縮
    const compressedFiles: File[] = yield call(compressFiles, action.files)

    // S3へアップロード
    const s3UploadResults: S3UploadResult[] = yield call(
      uploadFilesToS3UsingPresignedURL,
      compressedFiles,
    )

    // S3へアップロードしたファイルの情報をAPIサーバに登録
    const informationFiles: InformationFile[] = yield call(
      addInformationFilesToApiServer,
      s3UploadResults,
    )

    yield put(
      actions.uploadSuccessInformationFiles(action.nodeId, informationFiles),
    )
  } catch (error) {
    if (isHTTPError(error)) {
      // APIサーバーからのエラーレスポンスのハンドリング
      yield put(interceptionsActions.handleHttpError(error as HTTPError))
    } else {
      // それ以外のエラーのハンドリング
      // 現状UIに表示する機構がないため、とりあえずログに出力しておく
      console.error(error)
    }

    yield put(actions.uploadErrorInformationFiles(error as Error))
  }
}

function* getPreparedTemplate(
  action: ReturnType<typeof editTemplateActions.getPreparedTemplate>,
) {
  try {
    const [template, templateHints, templatePages]: [
      Template,
      TemplateHint[],
      TemplatePage[],
    ] = yield all([
      call(getIndustryTemplateRequest, { templateId: action.templateId }),
      call(getIndustryTemplateHintsRequest, {
        templateId: action.templateId,
      }),
      call(copyIndustryTemplatePagesRequest, {
        templateId: action.templateId,
      }),
    ])

    yield put(editTemplateActions.getSuccessTemplate(template))
    yield put(templateHintsActions.getSuccessTemplateHints(templateHints))
    const normalized = normalize<any, TemplatePagesEntities, Array<number>>(
      templatePages,
      SchemaTemplatePages,
    )
    yield put(
      actions.getSuccessTemplatePages(
        normalized.entities.templatePages,
        normalized.entities.nodes,
        normalized.result,
      ),
    )
  } catch (error) {
    yield put(interceptionsActions.handleHttpError(error as HTTPError))
  }
}

function* copyTemplatePage(
  action: ReturnType<typeof actions.startCopyTemplatePage>,
) {
  // 同期処理で loading の表示/非表示の state を切り替えるために nextTick 関数を使用している。
  // 例えば、isLoading: true -> isLoading: false のように state を遷移させても、
  // 一連の処理が同期的に行われると isLoading: false として render が行われるため isLoading: true では render されない。
  // nextTick 関数を挟むことにより、isLoading: true で render され、isLoading: false でも render される。
  yield call(nextTick)
  yield put(actions.copyTemplatePage(action.targetPageId))
}

const nextTick = () => {
  return new Promise(resolve => setTimeout(resolve, 0))
}

const sagas = [
  takeEvery(ActionTypesOfEditTemplate.ADD_TEMPLATE, addTemplate),
  takeEvery(ActionTypesOfEditTemplate.REQUEST_UPDATE_TEMPLATE, updateTemplate),
  // 以下のコードには到達し得ない。所要のコード修正がされるまで一旦コメントアウトする。
  // takeEvery(ActionTypes.REQUEST_ADD_TEMPLATE_PAGES, addTemplatePages),
  takeEvery(ActionTypes.REQUEST_GET_TEMPLATE_PAGES, getTemplatePages),
  takeEvery(
    ActionTypes.REQUEST_UPLOAD_INFORMATION_FILES,
    uploadInformationFiles,
  ),
  takeEvery(
    ActionTypesOfEditTemplate.REQUEST_GET_PREPARED_TEMPLATE,
    getPreparedTemplate,
  ),
  takeEvery(ActionTypes.START_COPY_TEMPLATE_PAGE, copyTemplatePage),
  ...editTemplateSagas,
  ...templateHintsSagas,
]

export default sagas
