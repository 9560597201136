import {
  GetMultipleChoiceSetsRequest,
  MultipleChoiceSet,
} from '@ulysses-inc/harami_api_client'
import { combineReducers } from 'redux'
import { MultipleChoiceSetAction, MultipleChoiceSetsAction } from './actions'
import { ActionTypes } from './types'

interface MultipleChoiceSetsState {
  multipleChoiceSets: MultipleChoiceSet[]
  isLoading: boolean
  error: Error | null
  count: number
  request: GetMultipleChoiceSetsRequest
}

const initialMultipleChoiceSetsState: MultipleChoiceSetsState = {
  multipleChoiceSets: [],
  isLoading: false,
  error: null,
  count: 0,
  request: {
    offset: 0,
    limit: 25,
  },
}

const multipleChoiceSets = (
  state: MultipleChoiceSetsState = initialMultipleChoiceSetsState,
  action: MultipleChoiceSetsAction,
): MultipleChoiceSetsState => {
  switch (action.type) {
    case ActionTypes.REQUEST_GET_MULTIPLE_CHOICE_SETS: {
      return {
        ...state,
        isLoading: action.isLoading,
        multipleChoiceSets: [],
        error: null,
        request: action.request,
      }
    }
    case ActionTypes.SUCCESS_GET_MULTIPLE_CHOICE_SETS: {
      return {
        ...state,
        isLoading: action.isLoading,
        multipleChoiceSets: action.multipleChoiceSets || [],
        error: null,
        count: action.count || 0,
      }
    }
    case ActionTypes.ERROR_GET_MULTIPLE_CHOICE_SETS: {
      return {
        ...state,
        isLoading: action.isLoading,
        error: action.error,
        count: 0,
      }
    }
    case ActionTypes.REQUEST_CHANGE_MULTIPLE_CHOICE_SETS_PAGE: {
      return {
        ...state,
        request: action.request,
      }
    }
    case ActionTypes.REQUEST_CHANGE_MULTIPLE_CHOICE_SETS_SIZE: {
      return {
        ...state,
        request: action.request,
      }
    }
    case ActionTypes.SUCCESS_DELETE_MULTIPLE_CHOICE_SET: {
      return {
        ...state,
        multipleChoiceSets: state.multipleChoiceSets.filter(
          multipleChoiceSet =>
            multipleChoiceSet.uuid !== action.multipleChoiceSetId,
        ),
        count: state.count - 1,
      }
    }
    case ActionTypes.ERROR_DELETE_MULTIPLE_CHOICE_SET: {
      return {
        ...state,
        isLoading: action.isLoading,
        error: action.error,
      }
    }
    default: {
      return { ...state }
    }
  }
}

interface MultipleChoiceSetState {
  multipleChoiceSet: MultipleChoiceSet | null
  isLoading: boolean
  error: Error | null
}

const initialMultipleChoiceSetState: MultipleChoiceSetState = {
  multipleChoiceSet: null,
  isLoading: false,
  error: null,
}

const multipleChoiceSet = (
  state: MultipleChoiceSetState = initialMultipleChoiceSetState,
  action: MultipleChoiceSetAction,
): MultipleChoiceSetState => {
  switch (action.type) {
    case ActionTypes.REQUEST_GET_MULTIPLE_CHOICE_SET: {
      return {
        ...state,
        isLoading: action.isLoading,
        multipleChoiceSet: null,
      }
    }
    case ActionTypes.SUCCESS_GET_MULTIPLE_CHOICE_SET: {
      return {
        ...state,
        isLoading: action.isLoading,
        multipleChoiceSet: action.multipleChoiceSet,
      }
    }
    case ActionTypes.ERROR_GET_MULTIPLE_CHOICE_SET: {
      return {
        ...state,
        isLoading: action.isLoading,
        error: action.error,
      }
    }
    case ActionTypes.RESET_MULTIPLE_CHOICE_SET: {
      return {
        ...state,
        multipleChoiceSet: null,
      }
    }
    default: {
      return { ...state }
    }
  }
}

const multipleChoiceSetsReducer = combineReducers({
  multipleChoiceSets,
  multipleChoiceSet,
})

export default multipleChoiceSetsReducer
