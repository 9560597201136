import {
  Range,
  defaultRangeExtractor,
  useVirtualizer,
} from '@tanstack/react-virtual'
import { useCallback, useMemo, useRef } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { HEADER_HEIGHT } from 'src/components/header/Header'
import Loading from 'src/components/loading/Loading'
import { RootState } from 'src/state/store'
import { BodyRow } from './BodyRow'
import { ROW_HEIGHT } from './EditGridVariables.styled'
import { HeaderRow } from './HeaderRow'
import { ROW_COUNT } from './constants'
import { useTable } from './hooks/useTable'
import { useWindowSize } from './hooks/useWindowSize'

type Props = { templateId: number }

export const Table = ({ templateId }: Props) => {
  return useMemo(
    () => <UnmemoizedTable templateId={templateId} />,
    [templateId],
  )
}

const UnmemoizedTable = ({ templateId }: Props) => {
  const { isLoading } = useTable(templateId)

  const { height: windowHeight } = useWindowSize()

  const parentRef = useRef<HTMLDivElement>(null)

  const rowVirtualizer = useVirtualizer({
    // ヘッダー行 + 1000 行
    count: 1 + ROW_COUNT,

    getScrollElement: () => parentRef.current,

    // ほとんどの行に当てはまる高さを指定すれば良い。ここでは、セル内で文字列の折り返しがない場合の行の高さを指定する
    estimateSize: () => ROW_HEIGHT,

    // WARN: 先読みする行数を指定する。これを大きくしすぎるとパフォーマンスを損なう
    overscan: 5,

    /**
     * このコールバックの戻り値の行が、画面にレンダリングされる仕組みになっている
     * `defaultRangeExtractor()` の戻り値に、ヘッダー行インデックスの `0` を加えることで、
     * ヘッダー行を常にレンダリングできるように制御している
     *
     * ※ `rangeExtractor()` からは、その時点でのレンダリング範囲を考慮した上での、レンダリングすべき行インデックスが返ってくる
     */
    rangeExtractor: useCallback((range: Range) => {
      const maybeContainHeaderIndexes = defaultRangeExtractor(range)
      // レンダリング範囲にすでにヘッダー行がある場合、二重でヘッダーをレンダリングしてしまうので、そうならないように制御する
      return maybeContainHeaderIndexes.indexOf(0) === -1
        ? [0, ...maybeContainHeaderIndexes]
        : maybeContainHeaderIndexes
    }, []),
  })

  // すべての SectionNameCell で options の中身は同じなので、ここで算出を済ませておく
  const sections = useSelector(
    (state: RootState) =>
      state.editGridVariablesState.editGridVariables.sectionsByName,
    shallowEqual,
  )
  const sectionNameOptions = Object.keys(sections).map(sectionName => {
    return {
      value: sectionName,
      label: sectionName,
    }
  })

  if (isLoading) {
    return <Loading />
  }

  return (
    <div
      ref={parentRef}
      style={{
        // ウィンドウサイズいっぱいの高さだと最後までスクロールできないので、テーブルの高さはそれよりも少し低くする
        height: `${windowHeight - HEADER_HEIGHT}px`,
        position: 'relative',
        overflow: 'auto',
        // 1 行目よりもさらに上にスクロールしようとしたときに、行ヘッダーが「びよーん」となるのを防ぐ
        overscrollBehavior: 'none',
      }}
    >
      <div
        style={{
          height: `${rowVirtualizer.getTotalSize()}px`,
          width: '100%',
          position: 'relative',
        }}
      >
        {rowVirtualizer.getVirtualItems().map(row => (
          <div
            key={row.key}
            data-index={row.index}
            ref={rowVirtualizer.measureElement}
            style={{
              ...(row.index === 0
                ? {
                    zIndex: 1,
                    position: 'sticky',
                  }
                : {
                    position: 'absolute',
                  }),
              top: 0,
              left: 0,
              transform: `translateY(${
                row.start - rowVirtualizer.options.scrollMargin
              }px)`,
              display: 'flex',
              // 内部のコンテンツから動的に高さを算出する都合上、セルの中身が空だと背が低くなるのでこれを指定する必要がある
              minHeight: `${ROW_HEIGHT}px`,
            }}
          >
            {row.index === 0 ? (
              <HeaderRow />
            ) : (
              <BodyRow
                rowNumber={row.index}
                rowHeight={row.size}
                sectionNameOptions={sectionNameOptions}
              />
            )}
          </div>
        ))}
      </div>
    </div>
  )
}
