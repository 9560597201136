import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { PlaceNode } from '@ulysses-inc/harami_api_client'
import { Button, List, Modal, Row, Typography } from 'antd'
import { useState } from 'react'
import {
  Danger,
  ModalDeleteButtonColor,
  Primary,
  TableBorderColor,
  White,
} from 'src/features/theme/KdsThemeColor'
import styled from 'styled-components'
import Loading from '../../components/loading/Loading'

const { Text } = Typography

interface Props {
  places: PlaceNode[]
  isLoading: boolean
  openEditPlace: (place: PlaceNode) => void
  deletePlace: (id: string) => void
}

const PlaceListContainer = styled(Row)`
  justify-content: flex-start;
  margin: 15px 0 15px 15px;
  overflow-x: auto;
`

const PlaceList = (props: Props) => {
  const [activePlaceId, setActivePlaceId] = useState<string>()

  if (props.isLoading) {
    return <Loading />
  }

  return (
    <PlaceListContainer justify="center">
      <div style={{ width: '100%', minWidth: '500px', paddingRight: '15px' }}>
        <div
          style={{
            height: 46,
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row',
            background: White,
            borderBottom: `1px solid ${TableBorderColor}`,
          }}
        >
          <div
            style={{ width: '100%', fontWeight: 'bold', paddingLeft: '15px' }}
          >
            現場名
          </div>
        </div>
        <List
          itemLayout="horizontal"
          dataSource={props.places}
          renderItem={(node: PlaceNode) => (
            <List.Item
              style={{
                position: 'relative',
                minHeight: '46px',
                backgroundColor: White,
              }}
              onMouseEnter={() => setActivePlaceId(node.uuid)}
              onMouseLeave={() => setActivePlaceId(undefined)}
            >
              <div
                style={{
                  width: '100%',
                  paddingLeft: '15px',
                  wordBreak: 'break-all',
                }}
              >
                <Text>{node.place?.name}</Text>
              </div>
              {activePlaceId === node.uuid && (
                <div
                  style={{
                    position: 'absolute',
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'flex-end',
                    flexDirection: 'row',
                    height: '100%',
                    width: '240px',
                    right: 0,
                  }}
                >
                  <Button
                    style={{
                      height: '100%',
                      width: '50%',
                      borderRadius: 0,
                      backgroundColor: Primary,
                      borderColor: Primary,
                      color: White,
                    }}
                    onClick={() => props.openEditPlace(node)}
                  >
                    <EditOutlined />
                    編集
                  </Button>
                  <Button
                    style={{
                      height: '100%',
                      width: '50%',
                      borderRadius: 0,
                      color: White,
                      borderColor: Danger,
                      backgroundColor: Danger,
                    }}
                    onClick={() => {
                      Modal.confirm({
                        onOk: () => {
                          props.deletePlace(node.uuid)
                        },
                        title: `「${node.place?.name}」の削除を実行しますがよろしいですか？`,
                        okText: '削除',
                        cancelText: 'キャンセル',
                        okButtonProps: {
                          style: {
                            backgroundColor: ModalDeleteButtonColor,
                            border: 'none',
                          },
                        },
                      })
                    }}
                  >
                    <DeleteOutlined />
                    削除
                  </Button>
                </div>
              )}
            </List.Item>
          )}
        />
      </div>
    </PlaceListContainer>
  )
}

export default PlaceList
