import { TemplateHint } from '@ulysses-inc/harami_api_client'
import { FC } from 'react'
import { useDispatch } from 'react-redux'
import { ClampText } from 'src/components/clampText/ClampText'
import { updateTemplateHint } from 'src/state/ducks/templates/templateHints/actions'

const HintName: FC<{
  hint: TemplateHint
}> = ({ hint }) => {
  const dispatch = useDispatch()
  const onChange = (text: string) => {
    dispatch(
      updateTemplateHint(hint.id ?? '', {
        ...hint,
        name: text,
      }),
    )
  }

  return <ClampText editable={{ onChange }}>{hint.name}</ClampText>
}

export default HintName
