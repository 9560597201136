import {
  AddImproveExcelConvertsRequest,
  GetImproveExcelConvertsRequest,
} from '@ulysses-inc/harami_api_client'
import Redux from 'redux'
import { GetImproveExcelConversionLogsFilter } from 'src/state/ducks/improveExcelConversionLogs/types'
import actions from './actions'

const getImproveExcelConversionLogs = (
  dispatch: Redux.Dispatch,
  request: GetImproveExcelConvertsRequest,
  filter?: GetImproveExcelConversionLogsFilter,
) => {
  dispatch(actions.getImproveExcelConversionLogs(request, filter))
}

const changeImproveExcelConversionLogsPage = (
  dispatch: Redux.Dispatch,
  improveUUID: string,
  page: number,
  pageSize?: number,
  filter?: GetImproveExcelConversionLogsFilter,
) => {
  dispatch(
    actions.changeImproveExcelConversionLogsPage(
      improveUUID,
      page,
      pageSize,
      filter,
    ),
  )
}

const changeImproveExcelConversionLogsSize = (
  dispatch: Redux.Dispatch,
  improveUUID: string,
  pageSize: number,
  filter?: GetImproveExcelConversionLogsFilter,
) => {
  dispatch(
    actions.changeImproveExcelConversionLogsSize(improveUUID, pageSize, filter),
  )
}

const updatePagination = (
  dispatch: Redux.Dispatch,
  improveUUID: string,
  limit: number,
  offset: number,
) => {
  dispatch(actions.updatePagination(improveUUID, limit, offset))
}

const getImproveExcelConversionLogFiles = (
  dispatch: Redux.Dispatch,
  fileUUIDs: string[],
) => {
  dispatch(actions.getImproveExcelConversionLogFiles(fileUUIDs))
}

const addImproveExcelConversionLogs = (
  dispatch: Redux.Dispatch,
  request: AddImproveExcelConvertsRequest,
) => {
  dispatch(actions.addImproveExcelConversionLogs(request))
}

export default {
  getImproveExcelConversionLogs,
  changeImproveExcelConversionLogsPage,
  changeImproveExcelConversionLogsSize,
  updatePagination,
  getImproveExcelConversionLogFiles,
  addImproveExcelConversionLogs,
}
