import { Button, Col } from 'antd'
import styled from 'styled-components'

export const TableHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

export const CreateButton = styled(Button)`
  min-height: 88px;
  width: 100%;
  padding: 12px 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const TableRow = styled.div`
  width: 70%;
  display: flex;
  flex-wrap: wrap;
  margin-left: 5vw;
`

export const ButtonWrapper = styled(Col)`
  margin: 16px 12px 0;
  width: 443px;
  &:last-child {
    margin-bottom: 24px;
  }
`

export const SvgTag = styled.div`
  display: flex;
  width: 100%;
`

export const SvgTagIconWrap = styled.div`
  display: flex;
  vertical-align: center;
  padding-right: 12px;
`

export const SvgTagDescriptionIconWrap = styled.div`
  display: flex;
  vertical-align: center;
`

export const SvgTagDescriptionIcon = styled.div`
  display: flex;
  vertical-align: center;
  justify-content: flex-end;
  margin-left: 7px;
`

export const Description = styled.div`
  width: 100%;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  text-align: center;
  margin-top: 64px;
`

export const SvgTagTitle = styled.span`
  display: inline-grid;
  align-items: center;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  text-align: left;
  white-space: pre-wrap;
`

export const SvgTagDescription = styled.span`
  display: inline-grid;
  align-items: center;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  text-align: left;
  white-space: pre-wrap;
`

export const SvgTagTextWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`
