import { DownOutlined } from '@ant-design/icons'
import { IndicatedIssueLabel } from '@ulysses-inc/harami_api_client'
import { Select, Tag } from 'antd'
import { LabeledValue } from 'antd/lib/select'
import React, { useState } from 'react'
import styled from 'styled-components'

const { Option } = Select

const TagGroupWrapper = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  padding-right: 10px;
  cursor: pointer;
  &[data-disabled='true'] {
    cursor: not-allowed;
  }
`
const TagGroup = styled.div`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 100%;
`
const TagLabel = styled(Tag)`
  text-overflow: ellipsis;
  max-width: 100%;
  overflow: hidden;
  margin-bottom: 3px;
`

type OwnProps = {
  defaultLabels: IndicatedIssueLabel[]
  labels: IndicatedIssueLabel[]
  disabled: boolean
  setLabelValue: (labels: IndicatedIssueLabel[]) => void
}
const Component: React.FC<OwnProps> = ({
  defaultLabels,
  labels,
  disabled,
  setLabelValue,
}) => {
  const [isFocus, setIsFocus] = useState(false)
  return isFocus ? (
    <Select
      autoFocus={true}
      defaultOpen={true}
      mode="multiple"
      style={{ width: '100%' }}
      optionFilterProp="children"
      disabled={disabled}
      onSelect={(value: string | number | LabeledValue) => {
        setLabelValue([
          ...(defaultLabels || []),
          ...labels.filter(label => label.id?.toString() === value),
        ])
      }}
      onDeselect={(value: string | number | LabeledValue) => {
        setLabelValue(
          defaultLabels?.filter(label => label.id?.toString() !== value) || [],
        )
      }}
      onBlur={() => setIsFocus(false)}
      defaultValue={defaultLabels?.map(label => label.id?.toString() || '')}
    >
      {labels.map(label => (
        <Option
          key={label.id?.toString()}
          value={label.id?.toString() || ''}
          style={{ color: label.color }}
        >
          {label.name}
        </Option>
      ))}
    </Select>
  ) : (
    <TagGroupWrapper
      onClick={() => !disabled && setIsFocus(true)}
      data-disabled={disabled}
    >
      <TagGroup>
        {defaultLabels.map(label => (
          <TagLabel color={label.color} key={label.id?.toString()}>
            {label.name}
          </TagLabel>
        ))}
        {!disabled && (
          <DownOutlined
            style={{
              position: 'absolute',
              right: '0',
              top: '2px',
              fontSize: '12px',
            }}
          />
        )}
      </TagGroup>
    </TagGroupWrapper>
  )
}

export default Component
