import { css } from '@emotion/react'
import { Button } from 'antd'
import { FC } from 'react'
import { Link } from 'react-router-dom'

export const OutputButtonRow: FC = () => {
  return (
    <div css={styles.outputButtonRow}>
      <Link to="/reportExports/new">
        <Button type="primary">新規でレポート出力</Button>
      </Link>
    </div>
  )
}

const styles = {
  outputButtonRow: css`
    display: flex;
    justify-content: flex-end;
  `,
}
