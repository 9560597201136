import { css } from '@emotion/react'
import { FeatureNameEnum } from '@ulysses-inc/harami_api_client'
import { Button, Modal } from 'antd'
import { FC } from 'react'
import { localStorageKeys } from 'src/constants/localStorageKeys'
import { useFeature } from '../featureFlags/useFeatureFlags'

interface Props {
  isOpen: boolean
  onClose: () => void
}

export const UserInfoModal: FC<Props> = (props: Props) => {
  const { isOpen, onClose } = props
  const { canUse: manageUserOnRump } = useFeature(
    FeatureNameEnum.MANAGE_USER_ON_RUMP,
  )

  const userName = localStorage.getItem(localStorageKeys.loginUserName) || ''
  const loginId = localStorage.getItem(localStorageKeys.loginUserLoginId) || ''
  const rumpCustomerMemberId =
    localStorage.getItem(localStorageKeys.loginUserRumpCustomerMemberId) || ''

  return (
    <Modal
      footer={<Button onClick={onClose}>閉じる</Button>}
      open={isOpen}
      closable={false}
    >
      <div css={styles.row}>
        <span css={styles.label}>ユーザー名：</span>
        <span>{userName}</span>
      </div>
      <div>
        {manageUserOnRump === 'yes' ? (
          <>
            <span css={styles.label}>ユーザー管理番号：</span>
            <span>{rumpCustomerMemberId}</span>
          </>
        ) : (
          <>
            <span css={styles.label}>ログインID：</span>
            <span>{loginId}</span>
          </>
        )}
      </div>
    </Modal>
  )
}

const styles = {
  row: css`
    :not(:last-child) {
      margin-bottom: 8px;
    }
  `,
  label: css`
    font-weight: bold;
  `,
}
