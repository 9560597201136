import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import LoadingOverlay from 'src/components/loading/LoadingOverlay'
import { RootState } from 'src/state/store'
import { WindowScrollPreventer } from './EditGridVariables.styled'
import { EventHandler } from './EventHandler'
import { PageHeader } from './PageHeader'
import { Table } from './Table'

/**
 * 取り込み項目設定画面のベースになるページコンポーネント
 * @returns コンポーネント
 */
export const EditGridVariables = () => {
  const { templateId } = useParams<{ templateId: string }>()
  const isUpdating = useSelector(
    (state: RootState) =>
      state.editGridVariablesState.editGridVariables.isUpdating,
  )

  return (
    <LoadingOverlay
      tip={'保存中です...'}
      spinning={isUpdating}
      size="large"
      render={
        <div style={{ width: '100%', height: '100%' }}>
          {/* ウィンドウ全体でのスクロールを抑制して、テーブルだけスクロールできるようにする */}
          <WindowScrollPreventer />
          <EventHandler />
          <PageHeader templateId={Number(templateId)} />
          <Table templateId={Number(templateId)} />
        </div>
      }
    />
  )
}
