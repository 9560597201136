import { Dropdown } from 'antd'
import React, { useState } from 'react'
import {
  DownOutlinedIcon,
  DropDownTableCellForStatusFilterDropDown,
  ExpandIconWrap,
  FilterButton,
  FilterLabel,
  FilterSelectButton,
  SelectFilterTableCell,
  UpOutlinedIcon,
} from './shared.components'
import { useIsFilteredState } from './shared.hooks'

interface OwnProps {
  isSchedule: boolean
}

const StatusFilterDropDown: React.FC<OwnProps> = ({ isSchedule }) => {
  const [isOpen, setIsOpen] = useState(false)

  const [isFiltered, setIsFiltered] = useIsFilteredState(
    isSchedule ? 'schedule' : 'deviance',
  )

  const DisplayText = isSchedule
    ? '未完了のみ'
    : '逸脱項目のあるスケジュールのみ'

  const renderSelectButton = (isFiltered: boolean) => (
    <div>
      <FilterSelectButton
        type="text"
        onClick={() => {
          setIsFiltered(isFiltered)
          setIsOpen(false)
        }}
      >
        {isFiltered ? DisplayText : 'すべて'}
      </FilterSelectButton>
    </div>
  )

  const groundArea = (
    <SelectFilterTableCell theme={{ width: '160px' }}>
      <FilterButton>
        <FilterLabel>
          {isSchedule ? '入力状況' : '逸脱状況'}：
          {isFiltered ? DisplayText : 'すべて'}
        </FilterLabel>
        <ExpandIconWrap>
          {isOpen ? <UpOutlinedIcon /> : <DownOutlinedIcon />}
        </ExpandIconWrap>
      </FilterButton>
    </SelectFilterTableCell>
  )

  const hoverArea = (
    <div>
      <DropDownTableCellForStatusFilterDropDown>
        {renderSelectButton(false)}
        {renderSelectButton(true)}
      </DropDownTableCellForStatusFilterDropDown>
    </div>
  )

  return (
    <Dropdown
      dropdownRender={() => hoverArea}
      trigger={['click']}
      open={isOpen}
      onOpenChange={setIsOpen}
    >
      {groundArea}
    </Dropdown>
  )
}

export default StatusFilterDropDown
