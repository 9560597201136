import { ComponentProps } from 'react'
import RawLightbox from 'yet-another-react-lightbox'
import Zoom from 'yet-another-react-lightbox/plugins/zoom'
import 'yet-another-react-lightbox/styles.css'

type Props = ComponentProps<typeof RawLightbox>

/**
 * カミナシで必要な要件にあわせてあらかじめセットアップされたLightboxコンポーネント
 *
 * - ズームプラグインの追加
 * - スタイルの読み込み
 */
export const Lightbox = (props: Props) => {
  const { plugins = [], ...restProps } = props
  return <RawLightbox {...restProps} plugins={[Zoom, ...plugins]} />
}
