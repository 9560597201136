import { css } from '@emotion/react'
import { ReactNode } from 'react'
import { Header } from 'src/components/header/Header'
import { HeaderTitle } from 'src/components/header/HeaderTitle'

type Props = {
  children: ReactNode
}

/**
 * アップロードの段階にかかわらず常に表示するヘッダなどの共通部分を抜き出したもの
 */
export const CommonWrapper = (props: Props) => {
  const { children } = props

  return (
    <div>
      <Header>
        <HeaderTitle isBeta title="ユーザー一括追加" />
      </Header>
      <div css={styles.contentArea}>{children}</div>
    </div>
  )
}

const styles = {
  contentArea: css`
    margin: 0 auto;
    padding-top: 40px;
    width: 695px;
  `,
}
