import React from 'react'
import { View } from 'src/components/nativeCompat/View'
import styled from 'styled-components'

const StyledContainer = styled(View)`
  display: 'flex';
  align-items: 'flex-start';
  flex-direction: 'row';
  flex-wrap: 'wrap';
`

export interface MemoProps {
  listKey: string
  memoUris: (string | undefined)[]
  goPreviewMemo: (imageUrl: string) => void
}

export const Memo: React.FC<MemoProps> = props => {
  return (
    <StyledContainer>
      {props.memoUris.map((item, index) => {
        return (
          <View key={index}>
            <div
              style={{ cursor: 'pointer', width: '100%', height: '100%' }}
              onClick={() => item && props.goPreviewMemo(item)}
            >
              <img
                src={item}
                style={{
                  width: '100px',
                  height: '100px',
                  objectFit: 'contain',
                  border: '1px solid black',
                }}
              />
            </div>
          </View>
        )
      })}
    </StyledContainer>
  )
}
