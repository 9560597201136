import { DeleteOutlined } from '@ant-design/icons'
import {
  NumberCondition,
  NumberLogicTypeEnum,
  TemplateNodeSchema,
} from '@ulysses-inc/harami_api_client'
import { Button } from 'antd'
import { FC } from 'react'
import { useDispatch } from 'react-redux'
import StyledIndentation from 'src/features/templateEdit/common/StyledIndentation'
import {
  DeleteIconColor,
  LogicBackgroundColor,
} from 'src/features/theme/KdsThemeColor'
import { deleteTemplateNode } from 'src/state/ducks/templates/actions'
import { HeaderText, LogicHeader, LogicWrapper } from './Logic.styled'
import LogicContent from './LogicContent'

const NumberConditionLogic: FC<{
  id: number
  parentNode: TemplateNodeSchema
  node: TemplateNodeSchema
  path: number[]
  numberCondition: NumberCondition
}> = ({ id, parentNode, node, path, numberCondition: { logicType } }) => {
  const dispatch = useDispatch()
  const onClick = () => {
    dispatch(deleteTemplateNode(id, parentNode))
  }

  const logicTitle =
    logicType === NumberLogicTypeEnum.NORMAL ? '正常値' : '違反値'

  return (
    <LogicWrapper data-testid="logic-wrapper">
      <LogicHeader>
        <HeaderText strong>「{logicTitle}」を入力した場合</HeaderText>
        <Button type="link" onClick={onClick} aria-label="条件分岐の削除">
          <DeleteOutlined style={{ color: DeleteIconColor }} />
        </Button>
      </LogicHeader>
      <div style={{ display: 'flex' }}>
        <StyledIndentation color={LogicBackgroundColor} />
        <LogicContent node={node} path={path} />
      </div>
    </LogicWrapper>
  )
}

export default NumberConditionLogic
