import { JSX } from 'react'
import { pseudoAtom } from 'src/state/ducks/pseudoRecoil'

export type TDashboardDataType = 'schedule' | 'deviance'

export enum ScheduleStatus {
  COMPLETED = '◯',
  INCOMPLETE = '×',
  DEFAULT = '-',
}

export type TPlace = {
  uuid: string
  name: string
}

export type TColumnValueByPlaceAndDay = {
  scheduleStatus: ScheduleStatus | null
  devianceCount: number | null
}

export type TColumnValue = TPlace | TColumnValueByPlaceAndDay

export type TDayOfWeekIn3Letters =
  | 'mon'
  | 'tue'
  | 'wed'
  | 'thu'
  | 'fri'
  | 'sat'
  | 'sun'

export type TItem = {
  key: string
  place: TPlace
} & {
  [key in TDayOfWeekIn3Letters]: TColumnValueByPlaceAndDay
}

export type TColumn = {
  title: () => JSX.Element
  dataIndex: string
  align: 'center'
  textWrap: 'word-break'
  width: number
  render: (value: TColumnValue, record: TItem) => JSX.Element
}

export type TColumnValuesByDayOfWeek = {
  [key in TDayOfWeekIn3Letters]: TColumnValueByPlaceAndDay
}

export type TVisibleFilter =
  | 'placeForSchedule'
  | 'statusForSchedule'
  | 'placeForDeviance'
  | 'statusForDeviance'
  | null

// atoms
export const filteredPlaceGroupNodeIdsForScheduleState = pseudoAtom<string[]>({
  key: 'Dashboard.filteredPlaceGroupNodeIdsForScheduleState',
  default: [],
})
export const filteredPlaceNodeIdsForScheduleState = pseudoAtom<string[]>({
  key: 'Dashboard.filteredPlaceNodeIdsForScheduleState',
  default: [],
})
export const filteredInvalidCountPlaceGroupNodeIdsState = pseudoAtom<string[]>({
  key: 'Dashboard.filteredInvalidCountPlaceGroupNodeIds',
  default: [],
})
export const invalidCountFilteredPlaceNodeIdsState = pseudoAtom<string[]>({
  key: 'Dashboard.invalidCountFilteredPlaceNodeIdsState',
  default: [],
})
export const currentStartDateForScheduleState = pseudoAtom<Date | undefined>({
  key: 'Dashboard.currentStartDateForScheduleState',
  default: undefined,
})
export const currentEndDateForScheduleState = pseudoAtom<Date | undefined>({
  key: 'Dashboard.currentEndDateForScheduleState',
  default: undefined,
})
export const currentInvalidCountStartDateState = pseudoAtom<Date | undefined>({
  key: 'Dashboard.currentInvalidCountStartDateState',
  default: undefined,
})
export const currentInvalidCountEndDateState = pseudoAtom<Date | undefined>({
  key: 'Dashboard.currentInvalidCountEndDateState',
  default: undefined,
})
export const isFilteredForScheduleState = pseudoAtom<boolean>({
  key: 'Dashboard.isFilteredForScheduleState',
  default: false,
})
export const isCountFilteredState = pseudoAtom<boolean>({
  key: 'Dashboard.isCountFilteredState',
  default: false,
})
export const hasPresentDayForScheduleState = pseudoAtom<boolean | undefined>({
  key: 'Dashboard.hasPresentDayForScheduleState',
  default: undefined,
})
export const invalidCountHasPresentDayState = pseudoAtom<boolean | undefined>({
  key: 'Dashboard.invalidCountHasPresentDayState',
  default: undefined,
})
export const columnsForScheduleState = pseudoAtom<TColumn[]>({
  key: 'Dashboard.columnsForScheduleState',
  default: [],
})
export const invalidCountColumnsState = pseudoAtom<TColumn[]>({
  key: 'Dashboard.invalidCountColumnsState',
  default: [],
})
export const visibleFilterState = pseudoAtom<TVisibleFilter>({
  key: 'Dashboard.visibleFilterState',
  default: null,
})
