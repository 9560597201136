import { ResponseNumberDecimalPoint } from '@ulysses-inc/harami_api_client'

export const getDecimalPointValue = (
  decimalPoint: ResponseNumberDecimalPoint,
): number | undefined => {
  if (decimalPoint.isActive === 1) {
    return decimalPoint.value || 0
  }
  return undefined
}

export const convertDecimal = (
  value: string | number,
  decimalPoint: ResponseNumberDecimalPoint | undefined,
): string => {
  const text = value.toString()
  if (text.length && !!decimalPoint?.isActive) {
    const splitText = text.split('.')
    const decimalPointText = splitText[1] || ''
    const decimalPointValue = getDecimalPointValue(decimalPoint)
    if (decimalPointText.length > decimalPointValue) {
      return text.slice(0, decimalPointValue - decimalPointText.length)
    } else {
      if (isNaN(Number(text))) {
        return text
      }
      return Number(text).toFixed(decimalPointValue)
    }
  }
  return text
}
