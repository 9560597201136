import {
  Improve,
  ImproveStatusStatusEnum,
  IndicatedIssue,
  IndicatedIssueGroup,
  IndicatedIssueLabel,
  IndicatedIssueRevise,
  IndicatedIssueReviseImage,
  IndicatedIssueReviseImageStageEnum,
  IndicatedIssueStatusEnum,
  User,
  UserGroup,
} from '@ulysses-inc/harami_api_client'
import { useFormik } from 'formik'
import * as H from 'history'
import React, { useMemo } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Redux from 'redux'
import { localStorageKeys } from 'src/constants/localStorageKeys'
import { UUID } from 'src/exShared/util/uuid'
import {
  DispatchProps,
  EditImproveForm,
  EditImproveProps,
  StateProps,
} from 'src/features/improves/improveDetail/EditImproveProps'
import ImproveDetailScene from 'src/features/improves/improveDetail/ImproveDetailScene'
import Yup from 'src/features/validation/yup'
import improveEmailLogsOperations from 'src/state/ducks/improveEmailLogs/operations'
import improveExcelConversionLogsOperations from 'src/state/ducks/improveExcelConversionLogs/operations'
import improvesOperations from 'src/state/ducks/improves/operations'
import indicatedIssuesOperations from 'src/state/ducks/indicatedIssues/operations'
import usersOperations from 'src/state/ducks/users/operations'

interface State {
  improvesState: {
    editImprove: {
      improve: Improve
      improveUUID: string
      isLoading: boolean
    }
  }
  usersState: {
    userGroups: {
      userGroups: UserGroup[]
      isLoading: boolean
    }
  }
  indicatedIssuesState: {
    indicatedIssueLabel: {
      indicatedIssueLabels: Array<IndicatedIssueLabel>
      isLoading: boolean
    }
  }
}
const newValues = {
  uuid: '',
  reportName: '',
  status: {
    status: 1,
  },
  indicatedIssueGroups: [],
  reportQuestions: [],
  message: '',
}

Yup.addMethod(Yup.array, 'stageRequired', function () {
  return this.test(
    'stageRequired',
    '今回改善した画像が必須です',
    function (value: Array<any> | undefined) {
      return !!value?.filter(
        (v: IndicatedIssueReviseImage) =>
          v.stage === IndicatedIssueReviseImageStageEnum.Latest,
      ).length
    },
  )
})

const validationSchemaAuditor = Yup.object({
  indicatedIssueGroups: Yup.array(
    Yup.object({
      indicatedIssues: Yup.array()
        .transform((issues: IndicatedIssue[]) => {
          const latestIndex = issues.length - 1
          return issues.map((issue, index) => {
            if (index === latestIndex) {
              return issue
            }
            return {
              ...issue,
              status: {
                status: IndicatedIssueStatusEnum.Completed,
              },
            }
          })
        })
        .of(
          Yup.object({
            comment: Yup.string().required().label('指摘の内容'),
            status: Yup.object({
              status: Yup.number(),
            }),
            revise: Yup.object({
              comment: Yup.string(),
              images: Yup.array(),
            }),
            rejectComment: Yup.object().when(
              ['status.status', 'revise.comment'],
              {
                is: (status, comment) =>
                  status === IndicatedIssueStatusEnum.Incomplete && !!comment,
                then: Yup.object({
                  comment: Yup.string().required().label('差し戻しコメント'),
                }),
              },
            ),
          }),
        ),
    }),
  ),
})

const validationSchemaPlaceUser = Yup.object({
  indicatedIssueGroups: Yup.array(
    Yup.object({
      indicatedIssues: Yup.array()
        .transform((issues: IndicatedIssue[]) => {
          const latestIndex = issues.length - 1
          return issues.map((issue, index) => {
            if (index === latestIndex) {
              return issue
            }
            return {
              ...issue,
              isRequired: false,
            }
          })
        })
        .of(
          Yup.object({
            isRequired: Yup.boolean(),
            status: Yup.object({
              status: Yup.number(),
            }),
            revise: Yup.object().when(['isRequired', 'status.status'], {
              is: (isRequired, status) =>
                isRequired && status === IndicatedIssueStatusEnum.Incomplete,
              then: Yup.object({
                comment: Yup.string().required().label('改善の内容'),
              }),
            }),
          }),
        ),
    }),
  ),
})

const mapStateProps = (state: State): StateProps => {
  const improve = state.improvesState.editImprove.improve
  const hasRevise: boolean = (improve?.indicatedIssueGroups ?? []).some(
    (group: IndicatedIssueGroup) => {
      if (!group.indicatedIssues) {
        return false
      }
      return !!group.indicatedIssues[group.indicatedIssues.length - 1]?.revise
        ?.comment
    },
  )
  const hasRejectComment: boolean = (improve?.indicatedIssueGroups ?? []).some(
    (group: IndicatedIssueGroup) => {
      if (!group.indicatedIssues) {
        return false
      }
      return !!group.indicatedIssues[group.indicatedIssues.length - 1]
        ?.rejectComment?.comment
    },
  )
  const placeUserUUIDs = (
    state.improvesState.editImprove.improve.placeUserGroup?.users ?? []
  )
    .map((user: User) => {
      return user.uuid
    })
    .filter((userUUID: string | undefined) => userUUID)
  const auditUserGroupUserUUIDs = (
    state.improvesState.editImprove.improve.auditUserGroup?.users ?? []
  )
    .map((user: User) => {
      return user.uuid
    })
    .filter((userUUID: string | undefined) => userUUID)
  const isAuditorWorking =
    improve.status?.status === ImproveStatusStatusEnum.AUDITOR_WORKING
  const loginUserUUID =
    localStorage.getItem(localStorageKeys.loginUserUuid) ?? ''
  return {
    improve: state.improvesState.editImprove.improve,
    improveUUID: state.improvesState.editImprove.improveUUID,
    isLoading:
      state.improvesState.editImprove.isLoading ||
      state.usersState.userGroups.isLoading,
    userGroups: state.usersState.userGroups.userGroups,
    isFirstAudit: !hasRevise && isAuditorWorking,
    isFirstRevise: !hasRejectComment && !isAuditorWorking,
    isAuditor: improve.auditUser?.uuid === loginUserUUID,
    isPlaceUser: placeUserUUIDs.includes(loginUserUUID),
    isAuditUserGroupUser: auditUserGroupUserUUIDs.includes(loginUserUUID),
    isAuditorWorking,
    indicatedIssueLabels:
      state.indicatedIssuesState.indicatedIssueLabel.indicatedIssueLabels,
  }
}

const mapDispatchProps = (dispatch: Redux.Dispatch): DispatchProps => {
  return {
    goBack: (history: H.History) => {
      history.push('/improves')
    },
    goImproveExcelConversionLogs: (history: H.History, improveUUID: string) => {
      history.push(`/improves/${improveUUID}/excelConversionLogs`)
    },
    goReportDetail: (
      history: H.History,
      { reportUUID }: { reportUUID: string },
    ) => {
      history.push(`/reports/${reportUUID}`)
    },
    getImprove: (improveUUID: string) => {
      improvesOperations.getImprove(dispatch, { improveUUID })
    },
    addImproveExcelConversionLogs: (
      improveUUID: string,
      companyName: string,
      userName: string,
    ) => {
      improveExcelConversionLogsOperations.addImproveExcelConversionLogs(
        dispatch,
        {
          improveUUID,
          addImproveExcelConverts: { companyName, userName },
        },
      )
    },
    changeIsShowImproveEmailLogsDrawer: (isShow: boolean) => {
      improveEmailLogsOperations.changeIsShowImproveEmailLogsDrawer(
        dispatch,
        isShow,
      )
    },
    getImproveEmailLogs: (uuid: string) => {
      improveEmailLogsOperations.getImproveEmailLogs(dispatch, {
        improveUUID: uuid,
      })
    },
    getUserGroups: () => {
      usersOperations.getUserGroups(dispatch, {})
    },
    getIndicatedIssueLabels: () => {
      indicatedIssuesOperations.getIndicatedIssueLabels(dispatch)
    },
    saveDraftImprove: (values: EditImproveForm) => {
      const improve: Improve = {
        ...values,
        id: undefined,
        indicatedIssueGroups: (values.indicatedIssueGroups ?? []).filter(
          (v: IndicatedIssueGroup) => v,
        ),
      }
      improvesOperations.updateImprove(
        dispatch,
        {
          improveUUID: improve.uuid,
          improve: improve,
        },
        '',
      )
    },
    saveDraftRevise: (values: EditImproveForm) => {
      if (!values.indicatedIssueGroups?.length) {
        return
      }
      const revises: IndicatedIssueRevise[] = values.indicatedIssueGroups
        .map((group: IndicatedIssueGroup) => {
          if (!group.indicatedIssues?.length) {
            return { comment: '' }
          }
          const index = group.indicatedIssues.length - 1
          return group.indicatedIssues[index].revise
        })
        .filter((revise): revise is IndicatedIssueRevise => {
          return !!revise
        })
      indicatedIssuesOperations.saveDraftIndicatedIssueRevises(
        dispatch,
        revises,
      )
    },
    completeImprove: (values: EditImproveForm) => {
      const improve: Improve = {
        ...values,
        id: undefined,
        status: {
          id: values.status?.id,
          status: ImproveStatusStatusEnum.COMPLETE,
        },
        indicatedIssueGroups: (values.indicatedIssueGroups ?? []).filter(
          (v: IndicatedIssueGroup) => v,
        ),
      }
      improve.indicatedIssueGroups?.forEach((group: IndicatedIssueGroup) => {
        if (!group.indicatedIssues?.length) {
          return
        }
        const indicatedIssue =
          group.indicatedIssues[group.indicatedIssues.length - 1]
        if (typeof indicatedIssue.status?.status === 'undefined') {
          return
        }
        indicatedIssue.status.status = IndicatedIssueStatusEnum.Completed
      })
      improvesOperations.updateImprove(
        dispatch,
        {
          improveUUID: improve.uuid,
          improve: improve,
        },
        '今回の指摘はすべて完了しました。\nお疲れさまでした。',
      )
    },
    resetImprove: () => {
      improvesOperations.resetEditImprove(dispatch)
    },
    dispatch,
  }
}

const ImproveDetailContainer: React.FC<EditImproveProps> = props => {
  const { improve, improveUUID, dispatch } = props
  const initialValues = useMemo(() => {
    if (improveUUID) {
      const values = {
        uuid: improve.uuid,
        reportUUID: improve.reportUUID,
        reportName: improve.reportName,
        reportQuestions: improve.reportQuestions,
        status: improve.status,
        indicatedIssueGroups: improve.indicatedIssueGroups,
        auditUser: improve.auditUser,
        placeUserGroup: improve.placeUserGroup,
        auditUserGroup: improve.auditUserGroup,
        message: '',
      }
      if (!props.isAuditorWorking && props.isPlaceUser) {
        values.indicatedIssueGroups?.forEach((group: IndicatedIssueGroup) => {
          if (!group.indicatedIssues?.length) {
            return
          }
          const indicatedIssue =
            group.indicatedIssues[group.indicatedIssues.length - 1]
          indicatedIssue.revise = {
            indicatedIssueUUID:
              indicatedIssue?.revise?.indicatedIssueUUID || indicatedIssue.uuid,
            comment: indicatedIssue?.revise?.comment || '',
            images: indicatedIssue?.revise?.images || [],
            dueDate: indicatedIssue?.revise?.dueDate || undefined,
          }
        })
      }
      return values
    }
    return newValues
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [improveUUID, improve.uuid])
  const formikProps = useFormik<EditImproveForm>({
    initialValues,
    validationSchema: props.isAuditorWorking
      ? validationSchemaAuditor
      : validationSchemaPlaceUser,
    onSubmit: (values, formikHelpers) => {
      if (!values?.indicatedIssueGroups?.length) {
        return
      }
      if (props.isAuditorWorking) {
        const improve: Improve = {
          ...values,
          id: undefined,
          uuid: improveUUID || UUID(),
        }
        if (values.status?.status === ImproveStatusStatusEnum.AUDITOR_WORKING) {
          improve.indicatedIssueGroups = values.indicatedIssueGroups.map(
            (group: IndicatedIssueGroup) => {
              if (!group.indicatedIssues?.length) {
                return group
              }
              const index = group.indicatedIssues.length - 1
              const revise = group.indicatedIssues[index].revise
              if (!revise?.images?.length) {
                return group
              }
              group.indicatedIssues[index].revise = {
                ...revise,
                dueDate: revise.dueDate,
                images: revise.images.map(
                  (image: IndicatedIssueReviseImage) => {
                    if (!image.stage) {
                      return {
                        ...image,
                        stage:
                          IndicatedIssueReviseImageStageEnum.BeforeLastTime,
                      }
                    }
                    if (
                      image.stage <
                      IndicatedIssueReviseImageStageEnum.BeforeLastTime
                    ) {
                      return {
                        ...image,
                        stage: image.stage + 1,
                      }
                    }
                    return image
                  },
                ),
              }
              return group
            },
          )
        }
        improvesOperations.updateImprove(
          dispatch,
          {
            improveUUID: improve.uuid,
            improve: improve,
          },
          values.message,
        )
      } else {
        const improve: Improve = {
          ...values,
        }
        const revises: IndicatedIssueRevise[] = values.indicatedIssueGroups
          .map((group: IndicatedIssueGroup) => {
            if (!group.indicatedIssues?.length) {
              return { comment: '' }
            }
            const index = group.indicatedIssues.length - 1
            return group.indicatedIssues[index].revise
          })
          .filter((revise): revise is IndicatedIssueRevise => {
            return !!revise
          })
        indicatedIssuesOperations.updateIndicatedIssueRevises(dispatch, revises)
        improvesOperations.updateImprove(
          dispatch,
          {
            improveUUID: improve.uuid,
            improve: improve,
          },
          values.message,
        )
      }
      formikHelpers.setFieldValue('message', '')
    },
    enableReinitialize: true,
  })

  const sceneProps = {
    ...props,
    ...formikProps,
  }
  return <ImproveDetailScene {...sceneProps} />
}

export default withRouter(
  connect(mapStateProps, mapDispatchProps)(ImproveDetailContainer),
)
