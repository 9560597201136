import {
  IndustryFoodTypeEnum,
  IndustryTypeEnum,
} from '@ulysses-inc/harami_api_client'
import AddFileSvg from 'src/assets/icons/addfile.svg?react'
import FoodSvg from 'src/assets/icons/industry/food.svg?react'
import ManufactureSvg from 'src/assets/icons/industry/food_manufacture.svg?react'
import HotelSvg from 'src/assets/icons/industry/hotel.svg?react'
import LogisticsSvg from 'src/assets/icons/industry/logistics.svg?react'
import MachineSvg from 'src/assets/icons/industry/machine.svg?react'
import OtherSvg from 'src/assets/icons/industry/other.svg?react'
import { IndustryInfo } from 'src/features/templateEdit/onboarding/types'
import { IndustryNames } from './constants'

export const DefaultIndustries: IndustryInfo[][] = [[]]

export const IndustryGroups: IndustryInfo[][] = [
  [
    {
      svgComponent: AddFileSvg,
      title: '空のひな形',
      type: IndustryFoodTypeEnum.EMPTY,
      disabled: false,
    },
    {
      svgComponent: FoodSvg,
      title: IndustryNames.FOOD,
      type: IndustryTypeEnum.FOOD,
      disabled: false,
    },
    {
      svgComponent: ManufactureSvg,
      title: IndustryNames.MANUFACTURE,
      type: IndustryTypeEnum.MANUFACTURE,
      disabled: false,
    },
    {
      svgComponent: HotelSvg,
      title: IndustryNames.HOTEL,
      type: IndustryTypeEnum.HOTEL,
      disabled: false,
    },
    {
      svgComponent: LogisticsSvg,
      title: IndustryNames.LOGISTICS,
      type: IndustryTypeEnum.LOGISTICS,
      disabled: false,
    },
    {
      svgComponent: MachineSvg,
      title: IndustryNames.MACHINE,
      type: IndustryTypeEnum.MACHINE,
      disabled: false,
    },
    {
      svgComponent: OtherSvg,
      title: IndustryNames.OTHER,
      type: IndustryTypeEnum.OTHER,
      disabled: false,
    },
  ],
]

export const FoodIndustries = (): IndustryInfo[][] => {
  return [
    [
      {
        title: 'HACCP一般衛生管理（飲食店）',
        type: IndustryFoodTypeEnum.HACCP,
        templateId: 33170,
        disabled: false,
      },
      {
        title: 'HACCP重要衛生管理（焼肉店）',
        type: IndustryFoodTypeEnum.HACCP,
        templateId: 33169,
        disabled: false,
      },
      {
        title: '清掃記録',
        templateId: 33081,
        disabled: false,
      },
      {
        title: '開店時チェック',
        templateId: 33215,
        disabled: false,
      },
      {
        title: '閉店時チェック',
        templateId: 33216,
        disabled: false,
      },
      {
        title: '冷蔵庫・冷凍庫温度チェック',
        templateId: 33168,
        disabled: false,
      },
      {
        title: '新人教育チェックシート',
        templateId: 33289,
        disabled: false,
      },
    ],
  ]
}

export const ManufactureIndustries = (): IndustryInfo[][] => {
  return [
    [
      { title: '始終業点検', templateId: 30845, disabled: false },
      {
        title: '清掃/サニテーション記録1',
        templateId: 30540,
        disabled: false,
      },
      {
        title: '清掃/サニテーション記録2',
        templateId: 29299,
        disabled: false,
      },
      { title: '温度記録', templateId: 30846, disabled: false },
      { title: '金属検出チェック', templateId: 22838, disabled: false },
      { title: '異物混入チェック', templateId: 22684, disabled: false },
      { title: '従業員健康チェック', templateId: 48322, disabled: false },
      { title: 'ラベル貼付チェック', templateId: 22967, disabled: false },
      {
        title: 'アイテム切り替え（同ラインでの別製品への切り替え）',
        templateId: 23539,
        disabled: false,
      },
      { title: '中間点検表', templateId: 23541, disabled: false },
      { title: '刃の点検', templateId: 23528, disabled: false },
      { title: 'はさみ・包丁点検', templateId: 21953, disabled: false },
      { title: '自主監査／工場巡回記録', templateId: 23210, disabled: false },
      { title: '塩素濃度チェック', templateId: 23262, disabled: false },
      { title: '照度測定記録', templateId: 23240, disabled: false },
      { title: 'AV値（酸価値）測定記録', templateId: 30847, disabled: false },
      { title: '出荷前点検', templateId: 22986, disabled: false },
      { title: '賞味期限切れチェック', templateId: 23232, disabled: false },
      { title: '微生物検査', templateId: 23447, disabled: false },
      {
        title: '塩分計・糖度計精度確認記録',
        templateId: 21954,
        disabled: false,
      },
      { title: 'ノロウィルス対策テスト', templateId: 23263, disabled: false },
      { title: '定点撮影記録', templateId: 23794, disabled: false },
      { title: '検品記録', templateId: 23237, disabled: false },
      { title: '安全巡視点検', templateId: 23203, disabled: false },
      { title: '製造数記録', templateId: 28720, disabled: false },
      {
        title: '設備日常点検チェックシート',
        templateId: 35661,
        disabled: false,
      },
    ],
  ]
}

export const HotelIndustries = (): IndustryInfo[][] => {
  return [
    [
      {
        title: 'フロント日報',
        templateId: 30691,
        disabled: false,
      },
      {
        title: '客室チェック',
        templateId: 29711,
        disabled: false,
      },
      {
        title: '大浴場チェック（残留塩素濃度チェック）',
        templateId: 37642,
        disabled: false,
      },
    ],
  ]
}

export const LogisticsIndustries = (): IndustryInfo[][] => {
  return [
    [
      {
        title: '車両日常点検チェック',
        templateId: 29743,
        disabled: false,
      },
      {
        title: 'フォークリフト点検',
        templateId: 35714,
        disabled: false,
      },
      {
        title: '点呼',
        templateId: 34932,
        disabled: false,
      },
      {
        title: '運転日報',
        templateId: 34920,
        disabled: false,
      },
      {
        title: 'コンベア点検',
        templateId: 34921,
        disabled: false,
      },
    ],
  ]
}

export const MachineIndustries = (): IndustryInfo[][] => {
  return [
    [
      {
        title: '【設備点検】設備番号：〇〇　点検記録',
        templateId: 128270,
        disabled: false,
      },
      {
        title: '抜き取り検査',
        templateId: 137325,
        disabled: false,
      },
    ],
  ]
}

export const OtherIndustries = (): IndustryInfo[][] => {
  return [
    [
      {
        title: '★カミナシクイズ〜初級編〜★',
        templateId: 53858,
        disabled: false,
      },
      {
        title: '★カミナシクイズ〜中級編〜★',
        templateId: 53859,
        disabled: false,
      },
      {
        title: 'ごーとんと学ぶ！　〜テスト運用虎の巻〜',
        templateId: 24263,
        disabled: false,
      },
      {
        title: 'カミナシフィードバックシート',
        templateId: 35668,
        disabled: false,
      },
      {
        title: '冷蔵庫点検表（Excel変換練習用）',
        templateId: 61006,
        disabled: false,
      },
      {
        title: 'ごーとんと学ぶ！試してカミナシ',
        templateId: 126156,
        disabled: false,
      },
    ],
  ]
}

// NDF
export const NdfIndustries = (): IndustryInfo[][] => {
  return [
    [
      {
        title:
          '○加工品処理室・野菜処理室・肉処理室・調味料計量室・真空冷却室　始終業点検',
        templateId: 72277,
        disabled: false,
      },
      {
        title: '○加熱調理室　始終業点検',
        templateId: 72294,
        disabled: false,
      },
      {
        title: '○盛り付け室　始終業点検',
        templateId: 72301,
        disabled: false,
      },
      {
        title: '○炊飯室　始終業点検',
        templateId: 72302,
        disabled: false,
      },
      {
        title: '○炊飯冷却室　始終業点検',
        templateId: 72303,
        disabled: false,
      },
      {
        title: '○赤飯室　始終業点検',
        templateId: 72316,
        disabled: false,
      },
      {
        title: '○器具洗浄室　始就業点検',
        templateId: 72317,
        disabled: false,
      },
      {
        title: '○番重洗浄機　始終業点検',
        templateId: 72318,
        disabled: false,
      },
      {
        title: '○品質管理室検査機器点検　始業終業点検',
        templateId: 72319,
        disabled: false,
      },
      {
        title: '○各部屋共通　サニテーションチェック',
        templateId: 80432,
        disabled: false,
      },
      {
        title: '○消耗品交換',
        templateId: 80443,
        disabled: false,
      },
      {
        title: '○定期清掃',
        templateId: 80445,
        disabled: false,
      },
    ],
  ]
}
