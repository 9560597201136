import { Page, StyleSheet, Text } from '@react-pdf/renderer'
import { ReportNodeSchema } from '@ulysses-inc/harami_api_client'
import React from 'react'
import { ReportResultSchema } from 'src/exShared/types/types'
import { getSectionNodes } from 'src/exShared/util/nodes/getSectionNodes'
import { getTopLevelSectionNodeIds } from 'src/exShared/util/nodes/getTopLevelSectionNodeIds'
import {
  sumAllResultScores,
  sumAllTotalScores,
} from 'src/exShared/util/reportResult/scores'
import Col from '../Col'
import Grid from '../Grid'
import ScoringRow from './ScoringRow'

interface OwnProps {
  reportResult: ReportResultSchema
}

interface MergeProps {
  calculateScorePercentage: (nodes: {
    [key: number]: ReportNodeSchema
  }) => number
  sectionChildNodes: (node: ReportNodeSchema) => {
    [key: number]: ReportNodeSchema
  }
}

export type ScoringProps = OwnProps & MergeProps

const childNodeIds = (
  nodeIds: Array<number>,
  prevNodeIds: Array<number>,
  allNodes: { [key: number]: ReportNodeSchema },
): Array<number> => {
  if (nodeIds.length === 0) return prevNodeIds
  const res = nodeIds
    .map(nodeId => {
      const grandchildNodeIds = allNodes[nodeId]?.nodes ?? []
      return [nodeId, ...childNodeIds(grandchildNodeIds, nodeIds, allNodes)]
    })
    .reduce((prev, cur) => prev.concat(cur))
    .filter((id, index, ids) => ids.indexOf(id) === index)
  return res
}

const sectionChildNodes = (
  node: ReportNodeSchema,
  nodes: { [key: number]: ReportNodeSchema },
): { [key: number]: ReportNodeSchema } =>
  childNodeIds(node.nodes ?? [], [], nodes).reduce(
    (prev, cur) => ({ ...prev, [cur]: nodes[cur] }),
    {},
  )

const ScoringContainer: React.FC<OwnProps> = (_props: OwnProps) => {
  const mergeProps: MergeProps = {
    calculateScorePercentage: (nodes: { [key: number]: ReportNodeSchema }) => {
      if (sumAllTotalScores(nodes) === 0) {
        return 0
      }
      return Math.round(
        (sumAllResultScores(nodes) / sumAllTotalScores(nodes)) * 100,
      )
    },
    sectionChildNodes: (node: ReportNodeSchema) =>
      sectionChildNodes(node, _props.reportResult.nodes),
  }
  const props = { ..._props, ...mergeProps }
  return <Scoring {...props} />
}

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
    fontFamily: 'Nasu',
    fontSize: 10,
  },
  title: {
    fontSize: 14,
  },
  textPadding: {
    padding: 5,
  },
  textAlignRight: {
    textAlign: 'right',
  },
})

const rowCountPerPage = 20

const Scoring: React.FC<ScoringProps> = (props: ScoringProps) => {
  const topLevelSectionNodeIds = getTopLevelSectionNodeIds(props.reportResult)
  const sections = getSectionNodes(
    props.reportResult.nodes,
    topLevelSectionNodeIds,
  )

  const requiredPageCount = Math.ceil(sections.length / rowCountPerPage)
  const pageNumbers = [...Array(requiredPageCount)].map((_, i) => i)

  return (
    <>
      {pageNumbers.map(pageNumber => {
        const startIndex = pageNumber * rowCountPerPage
        const sectionsInThisPage = sections.slice(
          startIndex,
          startIndex + rowCountPerPage,
        )
        return (
          <Page key={pageNumber} size="A4" style={styles.body}>
            <Grid
              borderBottom
              style={{ width: '100%', marginTop: 50, marginBottom: 20 }}
            >
              <Text>配点</Text>
            </Grid>
            <Grid style={{ width: '100%' }}>
              <Col borderTop style={{ width: '100%' }}>
                <Grid borderBottom style={{ width: '100%' }}>
                  <Col borderRight style={{ width: '50%' }}>
                    <Text style={{ ...styles.textPadding }}>項目</Text>
                  </Col>
                  <Col borderRight style={{ width: '50%' }}>
                    <Text
                      style={{
                        ...styles.textPadding,
                        ...styles.textAlignRight,
                      }}
                    >
                      今回(%)
                    </Text>
                  </Col>
                </Grid>
                {sectionsInThisPage.map((sectionNode, index) => {
                  if (
                    sectionNode.section?.isEmployeeCheck === 1 &&
                    !sectionNode.section?.originalNodeUuid
                  ) {
                    return <></>
                  }
                  return (
                    <ScoringRow
                      key={index}
                      {...props}
                      index={index}
                      sectionNode={sectionNode}
                    />
                  )
                })}
              </Col>
            </Grid>
          </Page>
        )
      })}
    </>
  )
}

export default ScoringContainer
