import { DateTimeQuestion } from 'src/state/ducks/templates/selectors'
import { isNullish } from 'src/util/isNullish'

/**
 * 確定ボタンを押すときのバリデーション
 *
 * @param startQuestion 開始時間の質問
 * @param endQuestion 終了時間の質問
 * @returns エラーがあればエラーメッセージを返し、なければ空文字を返す
 */
export const validateMeasurementSettings = (
  startQuestion?: DateTimeQuestion,
  endQuestion?: DateTimeQuestion,
) => {
  // 「開始」が選択されていること
  if (isNullish(startQuestion)) {
    return '「開始時間の質問」を選択してください'
  }

  // 「終了」が選択されていること
  if (isNullish(endQuestion)) {
    return '「終了時間の質問」を選択してください'
  }

  // 「開始」と「終了」のフォーマットが同一であること
  if (startQuestion.format !== endQuestion.format) {
    return '「開始時間の質問」と「終了時間の質問」には、同一フォーマット(日時または時刻)の質問を選択してください'
  }

  // 「開始」と「終了」が同一の質問でないこと
  if (startQuestion.uuid === endQuestion.uuid) {
    return '「開始時間の質問」と「終了時間の質問」に、同一の質問を設定することはできません'
  }

  return ''
}
