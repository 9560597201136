import { PauseCircleOutlined, PlayCircleOutlined } from '@ant-design/icons'
import { List, Row, Tag } from 'antd'
import {
  Black,
  IndicatedIssueLabelColor,
  TableBorderColor,
  White,
} from 'src/features/theme/KdsThemeColor'
import styled from 'styled-components'

export const ButtonGroupRow = styled.div`
  position: absolute;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: row;
  height: 100%;
  width: 240px;
  right: 0;
  background-color: ${White};
`

export const TableBody = styled<any>(List)`
  width: 100%;
`

export const TableRowWrap = styled(List.Item)`
  position: relative;
  min-height: 46px;
  background-color: ${White};
`

export const TableRow = styled.div`
  word-break: break-all;
  width: ${props => props.theme.width};
  padding-left: 16px;
`

export const SchedulesHeaderContainer = styled(Row)`
  margin: 15px 0 15px 15px;
  overflow-x: auto;
  justify-content: start;
`

export const SchedulesHeaderWrap = styled.div`
  width: 100%;
  min-width: 800px;
  padding-right: 15px;
`

export const SchedulesHeader = styled.div`
  width: 100%;
  height: 46px;
  display: flex;
  align-items: center;
  flex-direction: row;
  background: ${White};
  border-bottom: 1px solid ${TableBorderColor};
`

export const HeaderRow = styled.div`
  font-weight: bold;
  padding-left: 15px;
  width: ${props => props.theme.width};
`

export const PlayCircleOutlinedIcon = styled(PlayCircleOutlined)`
  margin-right: 5px;
`

export const PauseCircleOutlinedIcon = styled(PauseCircleOutlined)`
  margin-right: 5px;
`

export const ActionRow = styled(Row)`
  margin: 15px;
`

export const ActionButtons = styled.div`
  align-items: center;
  display: flex;
  gap: 16px;
  justify-content: space-between;
`

export const ConfirmModalContentList = styled.ul`
  max-height: 220px;
  padding-left: 20px;
  padding-bottom: 30px;
  overflow-y: scroll;
`

export const ImproveOptionTag = styled(Tag)`
  color: ${Black};
  background-color: ${IndicatedIssueLabelColor};
  font-size: 12px;
  height: 18px;
  width: 56px;
  display: inline-flex;
  align-items: center;
  margin-left: 8px;
  padding: 3px 4px;
  border: none;
`
