import { FormulaValue } from '@ulysses-inc/harami_api_client'
import { FC } from 'react'
import { CellInner, TableBodyCellWrapper } from './Common.dumb'

type Props = {
  value?: FormulaValue
  scale?: string
}

export const TableBodyCellFormula: FC<Props> = ({ value, scale }) => {
  const isInvalid = value?.isInvalid === 1

  return (
    <TableBodyCellWrapper
      backgroundColor={isInvalid ? 'invalid' : 'fixed'}
      fontColor={isInvalid ? 'invalid' : 'default'}
    >
      <CellInner>{convertDisplayFormula(value?.formulaValue, scale)}</CellInner>
    </TableBodyCellWrapper>
  )
}

const convertDisplayFormula = (value: number | undefined, scale?: string) => {
  if (value === undefined) {
    return '未計算'
  }

  return value + (scale ?? '')
}
