import { StyleSheet, Text, View } from '@react-pdf/renderer'
import { Style } from '@react-pdf/types'
import React from 'react'

const styles = StyleSheet.create({
  textWrap: {
    width: '100%',
    minHeight: 25,
    padding: 5,
  },
})

interface OwnProps {
  text: string
  style?: Style
}

const BreakText: React.FC<OwnProps> = (props: OwnProps) => {
  return (
    <View wrap={true} style={{ ...styles.textWrap, ...props.style }}>
      {props.text.split('\n').map((line, index) => (
        <Text key={index}>
          {Array.from(line).map((char, index) => (
            <Text key={index}>{char}</Text>
          ))}
        </Text>
      ))}
    </View>
  )
}

export default BreakText
