import { css } from '@emotion/react'
import { ReactNode } from 'react'

type Props = {
  leftPane: ReactNode
  rightPane: ReactNode
}

export const SubHeader = (props: Props) => {
  const { leftPane, rightPane } = props

  return (
    <div css={styles.container}>
      <div>{leftPane}</div>
      <div>{rightPane}</div>
    </div>
  )
}

const styles = {
  container: css`
    align-items: center;
    display: flex;
    height: 64px;
    justify-content: space-between;
    padding: 0 24px;
  `,
}
