import { InboxOutlined } from '@ant-design/icons'

/**
 * ファイルをドラッグできるエリアに表示するメッセージ
 *
 * `ant-***`といったクラス名は公式Docsからそのまま引用したもの
 * https://4x.ant.design/components/upload/#components-upload-demo-drag
 */
export const DraggerAreaMessages = () => {
  return (
    <>
      <p className="ant-upload-drag-icon">
        <InboxOutlined />
      </p>
      <p className="ant-upload-text">
        クリックもしくはファイルをここにドラッグ
      </p>
      <p className="ant-upload-hint">CSVファイルを選択してください</p>
    </>
  )
}
