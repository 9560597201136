/**
 * URLパラメーターからbool値を取得する
 * @param search useLocation()で取得できるsearch文字列
 * @param paramName 値を取得したいURLパラメーター名
 * @returns bool値(パラメーター自体が存在しない場合はfalse扱い)
 */
export const getBoolParamValue = (search: string, paramName: string) => {
  const params = new URLSearchParams(search)
  const stringValue = params.get(paramName)
  return stringValue !== null && stringValue.toLowerCase() === 'true'
}
