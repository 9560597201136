import { StyleSheet, View } from '@react-pdf/renderer'
import { MultipleChoice } from '@ulysses-inc/harami_api_client'
import React from 'react'
import BreakText from '../../BreakText'
import Col from '../../Col'
import Grid from '../../Grid'
import RecordedTime from './RecordedTime'

interface OwnProps {
  questionName: string
  answerMultipleChoice?: MultipleChoice
  recordedAt?: Date
  isTimeDisplayed: boolean
}

const styles = StyleSheet.create({
  gridLeft: { width: '70%' },
  gridRight: { width: '30%' },
  fullWidthWrap: { width: '100%' },
  recordedTimeWrap: { width: '100%', marginBottom: 5 },
})

const MultipleChoices: React.FC<OwnProps> = (props: OwnProps) => {
  const isAnswered = !!props.answerMultipleChoice
  return (
    <Grid style={styles.fullWidthWrap}>
      <Col borderLeft borderRight style={styles.gridLeft}>
        <BreakText text={props.questionName} />
      </Col>
      <Col
        borderRight
        style={{
          ...styles.gridRight,
          backgroundColor: props.answerMultipleChoice?.color ?? 'white',
        }}
      >
        <View style={styles.fullWidthWrap}>
          <BreakText text={props.answerMultipleChoice?.response ?? '未回答'} />
        </View>
        {isAnswered && (
          <View style={styles.recordedTimeWrap}>
            <RecordedTime
              isTimeDisplayed={props.isTimeDisplayed}
              recordedAt={props.recordedAt}
            />
          </View>
        )}
      </Col>
    </Grid>
  )
}

export default MultipleChoices
