import {
  AddExcelConversionFlowRequest,
  AttachmentFile,
  DeleteExcelConversionFlowRequest,
  ExcelConversionFlow,
  ExcelConversionFlowsApi,
  GetExcelConversionFlowRequest,
  GetExcelConversionFlowsRequest,
  GetExcelConversionFlowsResponse,
  GetSchedulesForExcelConversionFlowResponse,
  UpdateExcelConversionFlowRequest,
} from '@ulysses-inc/harami_api_client'
import { call, put, takeEvery } from 'redux-saga/effects'
import * as notificationServiceActions from 'src/features/notificationService/slice'
import { uploadAttachmentFilesRequestByFiles } from 'src/state/ducks/attachmentFiles/sagas'
import { downloadFile } from 'src/state/ducks/download/sagas'
import interceptionsActions from 'src/state/ducks/interceptions/actions'
import BaseClient from 'src/state/middleware/saga/baseClient'
import {
  HTTPError,
  handleHTTPError,
} from 'src/state/middleware/saga/handleHttpError'
import { history } from 'src/state/store'
import actions from './actions'
import { ActionTypes } from './types'

const baseClient = new BaseClient()

const getExcelConversionFlowsRequest = (
  req: GetExcelConversionFlowsRequest,
) => {
  return baseClient
    .getApi(ExcelConversionFlowsApi)
    .getExcelConversionFlows(req)
    .then(flows => flows)
    .catch(handleHTTPError)
}

const getExcelConversionFlowRequest = (req: GetExcelConversionFlowRequest) => {
  return baseClient
    .getApi(ExcelConversionFlowsApi)
    .getExcelConversionFlow(req)
    .then(flow => flow)
    .catch(handleHTTPError)
}

const deleteExcelConversionFlowRequest = (
  req: DeleteExcelConversionFlowRequest,
) => {
  return baseClient
    .getApi(ExcelConversionFlowsApi)
    .deleteExcelConversionFlow(req)
    .catch(handleHTTPError)
}

const addExcelConversionFlowRequest = (req: AddExcelConversionFlowRequest) => {
  return baseClient
    .getApi(ExcelConversionFlowsApi)
    .addExcelConversionFlow(req)
    .then(flows => flows)
    .catch(handleHTTPError)
}

const updateExcelConversionFlowRequest = (
  req: UpdateExcelConversionFlowRequest,
) => {
  return baseClient
    .getApi(ExcelConversionFlowsApi)
    .updateExcelConversionFlow(req)
    .then(flows => flows)
    .catch(handleHTTPError)
}

const getSchedulesRequest = () => {
  return baseClient
    .getApi(ExcelConversionFlowsApi)
    .getSchedulesForExcelConversionFlow()
    .then(schedules => schedules)
    .catch(handleHTTPError)
}

function* getExcelConversionFlows(
  action: ReturnType<typeof actions.getExcelConversionFlows>,
) {
  try {
    const response: GetExcelConversionFlowsResponse = yield call(
      getExcelConversionFlowsRequest,
      action.request,
    )
    yield put(actions.getSuccessExcelConversionFlows(response))
  } catch (error) {
    // TODO remove `as`
    yield put(interceptionsActions.handleHttpError(error as HTTPError))
    // TODO remove `as`
    yield put(actions.getErrorExcelConversionFlows(error as Error))
  }
}

function* getExcelConversionFlow(
  action: ReturnType<typeof actions.getExcelConversionFlow>,
) {
  try {
    const flow: ExcelConversionFlow = yield call(
      getExcelConversionFlowRequest,
      { flowId: action.flowId },
    )
    yield put(actions.getSuccessExcelConversionFlow(flow))
  } catch (error) {
    // TODO remove `as`
    yield put(interceptionsActions.handleHttpError(error as HTTPError))
    // TODO remove `as`
    yield put(actions.getErrorExcelConversionFlow(error as Error))
  }
}

function* deleteExcelConversionFlow(
  action: ReturnType<typeof actions.deleteExcelConversionFlow>,
) {
  try {
    yield call(deleteExcelConversionFlowRequest, { flowId: action.flowId })
    yield put(actions.deleteSuccessExcelConversionFlow(action.flowId))
  } catch (error) {
    // TODO remove `as`
    yield put(interceptionsActions.handleHttpError(error as HTTPError))
    // TODO remove `as`
    yield put(actions.deleteErrorExcelConversionFlow(error as Error))
  }
}

function* addExcelConversionFlow(
  action: ReturnType<typeof actions.addExcelConversionFlow>,
) {
  try {
    const response: GetExcelConversionFlowsResponse = yield call(
      addExcelConversionFlowRequest,
      { excelConversionFlow: action.flow },
    )
    yield put(actions.getSuccessExcelConversionFlows(response))
    yield put(actions.addSuccessExcelConversionFlow())
    yield put(actions.resetExcelConversionFlow())
    yield put(
      notificationServiceActions.showNotification({
        message: '変換フローの作成に成功しました',
      }),
    )
    history.push(`/excelConversionFlows`)
  } catch (error) {
    // TODO remove `as`
    yield put(interceptionsActions.handleHttpError(error as HTTPError))
    // TODO remove `as`
    yield put(actions.addErrorExcelConversionFlow(error as Error))
  }
}

function* updateExcelConversionFlow(
  action: ReturnType<typeof actions.updateExcelConversionFlow>,
) {
  try {
    const response: GetExcelConversionFlowsResponse = yield call(
      updateExcelConversionFlowRequest,
      {
        flowId: action.flowId,
        excelConversionFlow: action.flow,
      },
    )
    yield put(actions.getSuccessExcelConversionFlows(response))
    yield put(actions.updateSuccessExcelConversionFlow())
    yield put(actions.resetExcelConversionFlow())
    yield put(
      notificationServiceActions.showNotification({
        message: '変換フローの更新に成功しました',
      }),
    )
    history.push(`/excelConversionFlows`)
  } catch (error) {
    // TODO remove `as`
    yield put(interceptionsActions.handleHttpError(error as HTTPError))
    // TODO remove `as`
    yield put(actions.updateErrorExcelConversionFlow(error as Error))
  }
}

function* uploadAttachmentFile(
  action: ReturnType<typeof actions.uploadAttachmentFile>,
) {
  try {
    // TODO check if this type is valid
    const attachmentFiles: AttachmentFile[] = yield call(
      uploadAttachmentFilesRequestByFiles,
      [action.file],
    )
    // 単に型の都合
    if (!attachmentFiles[0]) return
    yield put(actions.addAttachmentFile(attachmentFiles[0]))
  } catch (error) {
    yield put(
      notificationServiceActions.showNotification({
        message: 'ファイルのアップロードに失敗しました',
        notificationType: 'error',
      }),
    )
  }
}

function* getSchedules() {
  try {
    const response: GetSchedulesForExcelConversionFlowResponse =
      yield call(getSchedulesRequest)

    // TODO check if `?? []` is a valid expression here
    yield put(actions.getSuccessSchedules(response.schedules ?? []))
  } catch (error) {
    // TODO remove `as`
    yield put(interceptionsActions.handleHttpError(error as HTTPError))
  }
}

function* testExcelConversionFlow(
  action: ReturnType<typeof actions.testExcelConversionFlow>,
) {
  try {
    yield call(
      downloadFile,
      'excelConversionFlows/test',
      JSON.stringify(action.flow),
    )
  } catch (error) {
    // TODO remove `as`
    yield put(interceptionsActions.handleHttpError(error as HTTPError))
  }
}

const sagas = [
  takeEvery(
    ActionTypes.REQUEST_GET_EXCEL_CONVERSION_FLOWS,
    getExcelConversionFlows,
  ),
  takeEvery(
    ActionTypes.REQUEST_CHANGE_EXCEL_CONVERSION_FLOWS_PAGE,
    getExcelConversionFlows,
  ),
  takeEvery(
    ActionTypes.REQUEST_CHANGE_EXCEL_CONVERSION_FLOWS_SIZE,
    getExcelConversionFlows,
  ),
  takeEvery(
    ActionTypes.REQUEST_GET_EXCEL_CONVERSION_FLOW,
    getExcelConversionFlow,
  ),
  takeEvery(
    ActionTypes.REQUEST_DELETE_EXCEL_CONVERSION_FLOW,
    deleteExcelConversionFlow,
  ),
  takeEvery(
    ActionTypes.REQUEST_ADD_EXCEL_CONVERSION_FLOW,
    addExcelConversionFlow,
  ),
  takeEvery(
    ActionTypes.REQUEST_UPDATE_EXCEL_CONVERSION_FLOW,
    updateExcelConversionFlow,
  ),
  takeEvery(
    ActionTypes.REQUEST_UPLOAD_EXCEL_ATTACHMENT_FILE,
    uploadAttachmentFile,
  ),
  takeEvery(ActionTypes.REQUEST_GET_SCHEDULES, getSchedules),
  takeEvery(
    ActionTypes.REQUEST_TEST_EXCEL_CONVERSION_FLOW,
    testExcelConversionFlow,
  ),
]

export default sagas
