import { css } from '@emotion/react'
import { GetPlacesV2Request } from '@ulysses-inc/harami_api_client'
import { Button, Dropdown, Input, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useListFilter } from 'src/hooks/filter/useListFilter'
import placesOperations from 'src/state/ducks/places/operations'
import {
  DownOutlinedIcon,
  DropDownTableCell,
  FilterButton,
  SelectFilterTableCell,
  UpOutlinedIcon,
} from './PlacesFilterDropDown.styled'

const { Text } = Typography

interface OwnProps {
  request?: GetPlacesV2Request
  updatedPlaces: boolean
  isLoadingPlace?: boolean
  placeGetTrigger?: boolean
  setPlaceGetTrigger: (val: boolean) => void
}

const PlacesFilterDropDown: React.FC<OwnProps> = ({
  request,
  updatedPlaces,
  isLoadingPlace,
  placeGetTrigger,
  setPlaceGetTrigger,
}: OwnProps) => {
  const dispatch = useDispatch()
  const [isOpen, setIsOpen] = useState(false)
  const [placeName, setPlaceName] = useState<string>('')
  const { filters, setListFilter } = useListFilter()

  // フィルター条件の変更検知して再取得
  useEffect(() => {
    // 更新が完了してない、または取得の必要がなければ場合はリターン
    if (!placeGetTrigger || updatedPlaces || isLoadingPlace) {
      return
    }

    if (!filters || !filters.places || !filters.places.filter) {
      return
    }

    const placeFilter = filters.places.filter
    setPlaceName(placeFilter.placeName ?? '')
    getFilterPlaces(placeFilter.placeName)
    setPlaceGetTrigger(!placeGetTrigger)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [placeGetTrigger, updatedPlaces, isLoadingPlace, placeName])

  const isFilterOn = () => {
    return placeName.length > 0
  }

  const clearFilter = () => {
    setPlaceName('')
    saveFilter()
    getFilterPlaces()
  }

  const saveFilter = (placeName?: string) => {
    setListFilter({
      ...(filters ?? {}),
      places: {
        pagination: request,
        filter: {
          placeName: placeName ?? '',
        },
      },
    })
  }

  const getFilterPlaces = (placeName?: string) => {
    const limit = Object.keys(request).length ? request.limit : 25
    placesOperations.getPlacesV2(
      dispatch,
      { limit: limit, offset: 0 },
      { placeName: placeName ? { $like: placeName } : undefined },
    )
  }

  const groundArea = (
    <SelectFilterTableCell theme={{ width: '160px' }}>
      <FilterButton>
        <div style={{ flex: 1, marginLeft: 12 }}>
          フィルター：{isFilterOn() ? 'ON' : 'OFF'}
        </div>
        <div style={{ minWidth: 20, marginRight: 12 }}>
          {isOpen ? <UpOutlinedIcon /> : <DownOutlinedIcon />}
        </div>
      </FilterButton>
    </SelectFilterTableCell>
  )

  const hoverArea = (
    <div style={{ display: 'flex' }}>
      <DropDownTableCell>
        <div css={styles.clearButtonRow}>
          <Button onClick={clearFilter}>クリア</Button>
        </div>
        <>
          <div style={{ marginBottom: 5 }}>
            <Text style={{ marginLeft: 3 }}>現場名</Text>
          </div>
          <Input
            allowClear
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              const newValue = event.target.value
              setPlaceName(newValue)
              saveFilter(newValue)
              getFilterPlaces(newValue)
            }}
            defaultValue={placeName}
            value={placeName}
          />
        </>
      </DropDownTableCell>
    </div>
  )

  return (
    <Dropdown
      dropdownRender={() => hoverArea}
      trigger={['click']}
      open={isOpen}
      onOpenChange={setIsOpen}
    >
      {groundArea}
    </Dropdown>
  )
}

const styles = {
  clearButtonRow: css`
    display: flex;
    justify-content: flex-end;
  `,
}

export default PlacesFilterDropDown
