import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as actions from 'src/features/dataUnavailableWidget/slice'
import { RootState } from 'src/state/store'

export const useDataUnavailableWidget = () => {
  const dispatch = useDispatch()

  const { isVisible } = useSelector(
    (state: RootState) => state.dataUnavailableWidgetState,
  )

  const reset = useCallback(() => {
    dispatch(actions.reset())
  }, [dispatch])

  // 表示についてはもっぱらsagaにおいて行われるため、ここでは公開していない
  return { isVisible, reset }
}
