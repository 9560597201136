// prettier-ignore
export const ActionTypes = {
  REQUEST_GET_IMPROVE_EXCEL_CONVERSION_LOGS: 'harami/improveExcelConversionLogs/REQUEST_GET_IMPROVE_EXCEL_CONVERSION_LOGS',
  SUCCESS_GET_IMPROVE_EXCEL_CONVERSION_LOGS: 'harami/improveExcelConversionLogs/SUCCESS_GET_IMPROVE_EXCEL_CONVERSION_LOGS',
  ERROR_GET_IMPROVE_EXCEL_CONVERSION_LOGS: 'harami/improveExcelConversionLogs/ERROR_GET_IMPROVE_EXCEL_CONVERSION_LOGS',
  REQUEST_CHANGE_IMPROVE_EXCEL_CONVERSION_LOGS_PAGE: 'harami/improveExcelConversionLogs/REQUEST_CHANGE_IMPROVE_EXCEL_CONVERSION_LOGS_PAGE',
  REQUEST_CHANGE_IMPROVE_EXCEL_CONVERSION_LOGS_SIZE: 'harami/improveExcelConversionLogs/REQUEST_CHANGE_IMPROVE_EXCEL_CONVERSION_LOGS_SIZE',
  UPDATE_PAGINATION: 'harami/improveExcelConversionLogs/UPDATE_PAGINATION',
  REQUEST_GET_IMPROVE_EXCEL_CONVERSION_LOG_FILES: 'harami/improveExcelConversionLogs/REQUEST_GET_IMPROVE_EXCEL_CONVERSION_LOG_FILES',
  SUCCESS_GET_IMPROVE_EXCEL_CONVERSION_LOG_FILES: 'harami/improveExcelConversionLogs/SUCCESS_GET_IMPROVE_EXCEL_CONVERSION_LOG_FILES',
  ERROR_GET_IMPROVE_EXCEL_CONVERSION_LOG_FILES: 'harami/improveExcelConversionLogs/ERROR_GET_IMPROVE_EXCEL_CONVERSION_LOG_FILES',
  REQUEST_ADD_IMPROVE_EXCEL_CONVERSION_LOGS: 'harami/improveExcelConversionLogs/REQUEST_ADD_IMPROVE_EXCEL_CONVERSION_LOGS',
  ERROR_ADD_IMPROVE_EXCEL_CONVERSION_LOGS: 'harami/improveExcelConversionLogs/ERROR_ADD_IMPROVE_EXCEL_CONVERSION_LOGS',
} as const

export interface GetImproveExcelConversionLogsFilter {
  status?: { $in: number[] }
  logDate?: { $gte: Date; $lte: Date }
}
