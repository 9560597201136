import { FC, memo, useState } from 'react'
import { useSelector } from 'react-redux'
import { Lightbox } from 'src/components/lightbox/Lightbox'
import { selectSectionName } from 'src/state/ducks/reports/reportResult/grid/selectors'
import styled from 'styled-components'
import { SectionTable } from './SectionTable'

const Title = styled.div`
  height: 52px;
  display: flex;
  flex-direction: row;
  align-items: center;
`

const TitleText = styled.h2`
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
`

type Props = {
  pageId: number
  originalSectionNodeId: number
}

const GridSectionComponent: FC<Props> = ({ pageId, originalSectionNodeId }) => {
  const sectionTitle = useSelector(selectSectionName(originalSectionNodeId))

  /* 写真質問のプレビュー用のstate */
  /* goPreviewPhotoのバケツリレーが辛いのでReduxのstateにもった方が良いかもしれないが、
   * 現状ではなんとか追える程度である。
   * 表形式ではないひな形との統一やRedux stateが煩雑になることを避けることを重視し、
   * コンポーネントのstateとして定義している
   */
  const [showsPictureWall, setShowsPictureWall] = useState(false)
  const [imageSrcs, setImageSrcs] = useState<string[]>([])
  const [imageSelectedIndex, setImageSelectedIndex] = useState(0)

  const goPreviewPhoto = (imageUrls: string[], selectedImageIndex: number) => {
    setImageSrcs(imageUrls)
    setImageSelectedIndex(selectedImageIndex)
    setShowsPictureWall(true)
  }

  return (
    <div>
      <Title id={`Section-${originalSectionNodeId}`}>
        <TitleText>{sectionTitle}</TitleText>
      </Title>
      <SectionTable
        pageId={pageId}
        originalSectionNodeId={originalSectionNodeId}
        goPreviewPhoto={goPreviewPhoto}
      />
      <Lightbox
        carousel={{ finite: true }}
        close={() => setShowsPictureWall(false)}
        index={imageSelectedIndex}
        open={showsPictureWall}
        slides={imageSrcs.map(image => ({ src: image }))}
      />
    </div>
  )
}

export const GridSection = memo(GridSectionComponent)
