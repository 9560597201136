import Icon from '@ant-design/icons'

const IconReportExportSvg = () => (
  <svg
    width="12"
    height="16"
    viewBox="0 0 12 16"
    // ↓ これ大事 https://kaminashi-inc.slack.com/archives/C047X5HBRK5/p1687355944933769?thread_ts=1687333741.326509&cid=C047X5HBRK5
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M9.625 11.7857L9.625 12.6429C9.625 12.7214 9.56875 12.7857 9.5 12.7857L2.5 12.7857C2.43125 12.7857 2.375 12.7214 2.375 12.6429L2.375 11.7857C2.375 11.7071 2.43125 11.6429 2.5 11.6429L9.5 11.6429C9.56875 11.6429 9.625 11.7071 9.625 11.7857ZM2.5 10.2143C2.43125 10.2143 2.375 10.15 2.375 10.0714L2.375 9.21429C2.375 9.13571 2.43125 9.07143 2.5 9.07143L6.875 9.07143C6.94375 9.07143 7 9.13571 7 9.21429L7 10.0714C7 10.15 6.94375 10.2143 6.875 10.2143L2.5 10.2143ZM5.75 2.28571L1.125 2.28572L1.125 14.7143L10.875 14.7143L10.875 8.57143C10.875 8.49286 10.9313 8.42857 11 8.42857L11.875 8.42857C11.9438 8.42857 12 8.49286 12 8.57143L12 15.4286C12 15.7446 11.7766 16 11.5 16L0.5 16C0.223438 16 -2.2324e-08 15.7446 -4.99559e-08 15.4286L-1.23357e-06 1.57143C-1.2612e-06 1.25536 0.223436 1 0.499999 1L5.75 1C5.81875 1 5.875 1.06429 5.875 1.14286L5.875 2.14286C5.875 2.22143 5.81875 2.28571 5.75 2.28571Z" />
    <path d="M2.375 7.5C2.375 7.57857 2.43125 7.64286 2.5 7.64286L6.875 7.64286C6.94375 7.64286 7 7.57857 7 7.5L7 6.64286C7 6.56429 6.94375 6.5 6.875 6.5L2.5 6.5C2.43125 6.5 2.375 6.56429 2.375 6.64286L2.375 7.5Z" />
    <path d="M9.39505 0.0674652L7.52932 3.18638C7.45935 3.30287 7.52265 3.47211 7.63427 3.47211L8.9003 3.47211L8.9003 6.82416C8.9003 6.92087 8.96027 7 9.03357 7L9.96643 7C10.0397 7 10.0997 6.92087 10.0997 6.82416L10.0997 3.47211L11.3657 3.47211C11.4773 3.47211 11.5406 3.30067 11.4707 3.18638L9.60495 0.0674652C9.59248 0.0464486 9.57655 0.0294522 9.55837 0.017763C9.54019 0.00607386 9.52023 -8.83069e-08 9.5 -8.74228e-08C9.47977 -8.65387e-08 9.45981 0.00607387 9.44163 0.0177631C9.42345 0.0294522 9.40752 0.0464486 9.39505 0.0674652Z" />
  </svg>
)

export const IconReportExport = () => {
  return (
    <Icon
      // https://4x.ant.design/components/icon/#components-icon-demo-custom
      component={IconReportExportSvg}
    />
  )
}
