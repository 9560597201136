import { StyleSheet, View } from '@react-pdf/renderer'
import { Style } from '@react-pdf/types'
import React, { ReactNode } from 'react'

interface OwnProps {
  children: ReactNode
  borderLeft?: boolean
  borderRight?: boolean
  borderTop?: boolean
  borderBottom?: boolean
  style?: Style
  debug?: boolean
}

const Grid: React.FC<OwnProps> = (props: OwnProps) => {
  const styles = StyleSheet.create({
    grid: {
      flexDirection: 'row',
      borderLeftWidth: props.borderLeft ? 0.5 : 0,
      borderRightWidth: props.borderRight ? 0.5 : 0,
      borderTopWidth: props.borderTop ? 0.5 : 0,
      borderBottomWidth: props.borderBottom ? 0.5 : 0,
      ...props.style,
    },
  })
  return (
    <View style={styles.grid} debug={props.debug ?? false}>
      {props.children}
    </View>
  )
}

export default Grid
