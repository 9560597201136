import { css } from '@emotion/react'
import {
  GetUserGroupsRequest,
  GetUsersRequest,
} from '@ulysses-inc/harami_api_client'
import { Button, Drawer, Layout, Row } from 'antd'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Header } from 'src/components/header/Header'
import { HeaderTitle } from 'src/components/header/HeaderTitle'
import Loading from 'src/components/loading/Loading'
import usersOperations from 'src/state/ducks/users/operations'
import { RootState } from 'src/state/store'
import UserGroupList from './UserGroupList'
import {
  ContentWrapper,
  ListContainer,
  UserGroupsPagination,
} from './UserGroups.styled'
import EditUserGroup from './editUser/EditUserGroup'

const UserGroupsScene: React.FC = () => {
  const dispatch = useDispatch()

  const {
    userGroups: {
      userGroups = [],
      isLoading: isLoadingUserGroups,
      isShowEditUserGroupDrawer,
      count = 0,
      request,
    },
    users: { isLoading: isLoadingUsers },
  } = useSelector((state: RootState) => state.usersState)

  const isLoading = isLoadingUserGroups || isLoadingUsers

  useEffect(() => {
    getUsers({
      // limit を指定しなかった場合、API 側のデフォルト値として 500 が設定される。
      // しかし、500 以上のユーザーが存在する場合はすべてのユーザーを取得できず、
      // 結果的に usersState に一部のデータしか存在しないことになる。
      //
      // usersState にすべてのユーザーが入っていることを期待している場合にうまく動作しなくなるため、
      // 2023-10-01 時点でもっともユーザー数が存在する company の 1.5 倍の数字を limit に指定している。
      limit: 1500,
    })
    getUserGroups(request)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const currentPage =
    request.offset === undefined || request.limit === undefined
      ? 1
      : request.offset / request.limit + 1

  const getUsers = (request: GetUsersRequest) => {
    usersOperations.getUsers(dispatch, request)
  }
  const changeUserGroupsPage = (page: number, pageSize?: number) => {
    usersOperations.changeUserGroupsPage(dispatch, page, pageSize)
  }
  const changeUserGroupsSize = (pageSize: number) => {
    usersOperations.changeUserGroupsSize(dispatch, pageSize)
  }
  const getUserGroups = (request: GetUserGroupsRequest) => {
    usersOperations.getUserGroups(dispatch, request)
  }
  const changeIsShowEditUserGroupDrawer = (isShow: boolean) => {
    usersOperations.changeIsShowEditUserGroupDrawer(dispatch, isShow)
  }
  const openAddUserGroup = () => {
    usersOperations.updateActiveUserGroupId(dispatch)
    usersOperations.changeIsShowEditUserGroupDrawer(dispatch, true)
  }

  // 閉じるボタン or Drawer外のクリックしたとき
  const onClose = () => {
    changeIsShowEditUserGroupDrawer(false)
  }

  const renderUserGroupList = () => {
    if (isLoading) {
      return <Loading />
    }

    return (
      <ListContainer>
        <Row
          justify="end"
          css={css`
            margin: 15px;
          `}
        >
          <Button
            type="primary"
            onClick={() => openAddUserGroup()}
            style={{ borderRadius: 4 }}
          >
            ユーザーグループを追加
          </Button>
        </Row>
        <UserGroupList userGroups={userGroups} />
      </ListContainer>
    )
  }

  return (
    <Layout>
      <Header>
        <HeaderTitle title="ユーザーグループ" />
      </Header>
      <ContentWrapper>{renderUserGroupList()}</ContentWrapper>
      <Drawer
        placement="right"
        maskClosable={false}
        closable={false}
        onClose={() => onClose()}
        destroyOnClose={true}
        open={isShowEditUserGroupDrawer}
        width={600}
        drawerStyle={{ padding: 0 }}
      >
        <EditUserGroup />
      </Drawer>
      <UserGroupsPagination
        showSizeChanger
        current={currentPage}
        pageSizeOptions={['10', '25', '50', '100']}
        pageSize={request.limit ?? 25}
        defaultCurrent={1}
        total={count}
        onChange={(pageNum, pageSize) =>
          changeUserGroupsPage(pageNum, pageSize)
        }
        onShowSizeChange={(_, size) => changeUserGroupsSize(size)}
      />
    </Layout>
  )
}

export default withRouter(UserGroupsScene)
