import {
  MultipleChoice,
  NumberCondition,
  ReportLogic,
  ReportQuestion,
  ResponseTypeEnum,
} from '@ulysses-inc/harami_api_client'
import { isNullish } from 'src/util/isNullish'
import { extractCalcNumberLogicType } from './extractCalcNumberLogicType'

/**
 * 条件分岐ノードが遷移状態かどうか判定
 * 未回答の場合は遷移されていないものとして扱う
 *
 * @param question 質問
 * @param logic 条件
 */
export const isActiveLogic = (question: ReportQuestion, logic: ReportLogic) => {
  // 該当なしの場合は、分岐先は表示しない
  if (question.responseAnswer?.noAnswer) return false

  switch (question.responseType) {
    case ResponseTypeEnum.MULTIPLE_CHOICE: {
      const response = question.responseAnswer?.multipleChoice?.response
      // 未回答なら非アクティブとして扱う
      if (isNullish(response)) return false

      return (logic.responseMultipleChoices || []).some(
        (multipleChoice: MultipleChoice) =>
          multipleChoice.response === response,
      )
    }
    case ResponseTypeEnum.NUMBER: {
      const numberValue = question.responseAnswer?.numberValue?.numberValue
      // 未回答なら非アクティブとして扱う
      if (isNullish(numberValue)) return false

      const responseNumber = question.responseNumbers?.[0]
      if (isNullish(responseNumber)) return false

      const numberLogicType = extractCalcNumberLogicType(
        numberValue,
        responseNumber,
      )
      return (logic.numberConditions || []).some(
        (numberCondition: NumberCondition) =>
          numberCondition.logicType === numberLogicType,
      )
    }
    default:
      return false
  }
}
