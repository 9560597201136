import { css } from '@emotion/react'
import { Tag, Tooltip } from 'antd'
import { ComponentProps, ReactNode, useId } from 'react'
import { Indigo0, Indigo7 } from 'src/features/theme/KdsThemeColor'

type Props = ComponentProps<'div'> & {
  title: string
  isBeta?: boolean
}

/**
 * ヘッダーで使用するタイトル
 */
export const HeaderTitle = (props: Props) => {
  const { isBeta, title, ...restProps } = props
  const headerLabelId = useId()

  const wrapWithTooltip = (children: ReactNode) => {
    if (!isBeta) return children

    return (
      <Tooltip
        title={
          <>
            {'この画面は正式リリース前の'}
            <br />
            {'検証版として提供しています'}
          </>
        }
      >
        {children}
      </Tooltip>
    )
  }

  return wrapWithTooltip(
    <div {...restProps} css={styles.container}>
      <h1 css={styles.title} id={headerLabelId}>
        {title}
      </h1>
      {isBeta && <Tag css={styles.tag}>β版</Tag>}
    </div>,
  )
}

const styles = {
  container: css`
    align-items: center;
    display: flex;
  `,
  title: css`
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 0;

    //  1行で収まらない場合は末尾を省略記号で省略する start
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    //  1行で収まらない場合は末尾を省略記号で省略する end
  `,
  tag: css`
    background-color: ${Indigo0};
    border: none;
    color: ${Indigo7};
    margin-left: 8px;
  `,
}
