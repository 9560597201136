import {
  Improve,
  IndicatedIssueGroup,
  IndicatedIssueLabel,
  ReportQuestion,
} from '@ulysses-inc/harami_api_client'
import { FormikErrors, FormikHandlers, FormikHelpers } from 'formik'
import React, { useMemo } from 'react'
import { EditImproveForm } from 'src/features/improves/improveDetail/EditImproveProps'
import ImproveListItem from 'src/features/improves/improveDetail/listItem/ImproveListItem'
import { Black } from 'src/features/theme/KdsThemeColor'
import styled from 'styled-components'

const Colors = {
  border: '#E9E9E9',
  thickborder: '#D9D9D9',
  background: '#FAFAFA',
}

const Wrapper = styled.section`
  padding: 0;
`
const List = styled.div`
  flex-direction: column;
`
const RowWorking = styled.div`
  display: flex;
`

const Column = styled.div<{ flex: number }>`
  flex: ${({ flex }) => `0 0 ${flex}px`};
  background-color: ${Colors.background};
  border-bottom: 1px solid ${Colors.border};
  height: 64px;
  display: flex;
`

const ColumnHeader = styled.div<{ flex: number }>`
  flex: ${({ flex }) => `0 0 ${flex}px`};
  background-color: ${Colors.background};
  border-left: 1px solid ${Colors.border};
  border-bottom: 1px solid ${Colors.border};
  height: 64px;
  display: flex;
`

const ColumnHeaderColumn = styled.div<{ flex: number }>`
  flex: ${({ flex }) => `0 0 ${flex}px`};
  flex-direction: column;
  display: flex;
  height: 64px;
`

const ColumnItem = styled.div<{ flex: number }>`
  flex: ${({ flex }) => `0 0 ${flex}px`};
  border-left: 1px solid ${Colors.border};
  border-bottom: 1px solid ${Colors.border};
  display: flex;
`

const ColumnItemBox = styled.div<{
  flex: number
  width: number
}>`
  flex: ${({ flex }) => `0 0 ${flex}px`};
  background-color: ${Colors.background};
  border-left: ${({ width }) => `${width}px solid ${Colors.thickborder}`};
  border-right: ${({ width }) => `${width}px solid ${Colors.thickborder}`};
  border-top: ${({ width }) => `${width}px solid ${Colors.thickborder}`};
  display: flex;
`

const ChildColumnItemBox = styled.div<{
  flex: number
  width: number
  isFirst: boolean
  isLast: boolean
}>`
  flex: ${({ flex }) => `0 0 ${flex}px`};
  background-color: ${Colors.background};
  border-left: ${({ width, isFirst }) =>
    isFirst ? `${width}px solid ${Colors.thickborder}` : ``};
  border-right: ${({ width, isLast }) =>
    isLast
      ? `${width}px solid ${Colors.thickborder}`
      : `0px solid ${Colors.thickborder}`};
  display: flex;
`

const RowHeader = styled.div`
  background-color: ${Colors.background};
  height: 50%;
  display: flex;
`

const Label = styled.div`
  font-size: 14px;
  color: ${Black};
  font-weight: bold;
  margin-left: 8px;
  display: flex;
  align-items: center;
  font-family: 'Noto Sans JP', sans-serif;
`

interface OwnProps {
  items: IndicatedIssueGroup[]
  indicatedIssueLabels: IndicatedIssueLabel[]
  reportQuestions: ReportQuestion[]
  reportUUID: string
  improveUUID: string
  placeUserGroupId: number
  handleChange: FormikHandlers['handleChange']
  setFieldTouched: FormikHelpers<Improve>['setFieldTouched']
  setFieldValue: FormikHelpers<Improve>['setFieldValue']
  resetField: (name: string) => void
  isAuditor: boolean
  isPlaceUser: boolean
  isAuditorWorking: boolean
  isCompleted: boolean
  isFirstAudit: boolean
  isFirstRevise: boolean
  isRejectStatus: boolean
  isEditing: boolean
  errors?: FormikErrors<EditImproveForm>
  touched?: any
}

const ImproveList: React.FC<OwnProps> = props => {
  const handleDeleteIndicatedIssue = (index: number) => {
    props.setFieldValue(
      `indicatedIssueGroups`,
      props.items.filter((_, i) => i !== index),
    )
  }

  const isMultileImages: boolean = props.items.some(value =>
    value.indicatedIssues?.some(
      issue => issue.images && Object.keys(issue.images).length > 1,
    ),
  )
  const isMultileReviseImages: boolean = props.items.some(value =>
    value.indicatedIssues?.some(
      issue =>
        issue.revise?.images && Object.keys(issue.revise?.images).length > 1,
    ),
  )
  const widths = {
    remove: {
      width: 40,
      visible: true,
    },
    no: {
      width: 40,
      visible: true,
    },
    status: {
      width: 120,
      visible: !props.isFirstAudit,
    },
  }
  const entries = useMemo(() => {
    return {
      dueDate: {
        width: 100,
        title: '改善期限',
        visible: true,
      },
      questionBinding: {
        width: 200,
        title: '該当質問',
        visible: true,
      },
      indicatedIssueImages: {
        width:
          props.isAuditorWorking && (props.isFirstAudit || props.isEditing)
            ? 152
            : isMultileImages
              ? 148
              : 80,
        title: '指摘画像',
        visible: true,
      },
      indicatedIssueComment: {
        width: 280,
        title: '指摘内容',
        visible: true,
      },
      indicatedIssueLabels: {
        width:
          props.isAuditorWorking && (props.isFirstAudit || props.isEditing)
            ? 128
            : 96,
        title: 'ラベル',
        visible: true,
      },
      isRequired: {
        width: 86,
        title: '現場の回答',
        visible: true,
      },
      rejectComment: {
        width: 280,
        title: '差し戻しコメント',
        visible: !props.isFirstAudit && !props.isFirstRevise,
      },
    }
  }, [props, isMultileImages])

  const entriesRevise = useMemo(() => {
    return {
      reviseDueDate: {
        width: 104,
        title: '改善確認期限',
        visible: true,
      },
      reviseImages: {
        width:
          !props.isAuditorWorking && props.isPlaceUser
            ? 152
            : isMultileReviseImages
              ? 148
              : 80,
        title: '改善画像',
        visible: true,
      },
      reviseComment: {
        width: 280,
        title: '改善内容',
        visible: true,
      },
    }
  }, [props, isMultileReviseImages])

  const boxBorderWidth = props.isAuditorWorking && !props.isRejectStatus ? 3 : 0
  const boxBorderWidthRevise =
    !props.isAuditorWorking && !props.isCompleted ? 3 : 0
  const lastAuditItemName = entries.rejectComment.visible
    ? 'rejectComment'
    : 'isRequired'

  const auditWidth = useMemo(() => {
    const auditEntryKeys = [
      'dueDate',
      'indicatedIssueImages',
      'indicatedIssueComment',
      'questionBinding',
      'indicatedIssueLabels',
      'isRequired',
      'rejectComment',
    ] as const
    return auditEntryKeys.reduce((acc, key) => {
      return acc + (entries[key].visible ? entries[key].width : 0)
    }, 0)
  }, [entries])

  const reviseWidth = useMemo(() => {
    const auditEntryKeys = [
      'reviseDueDate',
      'reviseImages',
      'reviseComment',
    ] as const
    return auditEntryKeys.reduce((acc, key) => {
      return acc + (entriesRevise[key].visible ? entriesRevise[key].width : 0)
    }, 0)
  }, [entriesRevise])

  const allWidth = auditWidth + reviseWidth

  return (
    <Wrapper style={{ minWidth: `${allWidth + 20}px`, paddingRight: '20px' }}>
      <RowWorking>
        <Column flex={widths.remove.width}></Column>
        <ColumnHeader flex={widths.no.width}>
          <Label>No</Label>
        </ColumnHeader>
        {widths.status.visible && (
          <ColumnHeader flex={widths.status.width}>
            <Label>ステータス</Label>
          </ColumnHeader>
        )}
        <ColumnHeaderColumn flex={auditWidth + reviseWidth}>
          <RowHeader>
            <ColumnItemBox flex={auditWidth} width={boxBorderWidth}>
              <ColumnItem flex={auditWidth}>
                <Label>指摘</Label>
              </ColumnItem>
            </ColumnItemBox>
            <ColumnItemBox flex={reviseWidth} width={boxBorderWidthRevise}>
              <ColumnItem flex={reviseWidth}>
                <Label>改善</Label>
              </ColumnItem>
            </ColumnItemBox>
          </RowHeader>
          <RowHeader>
            {Object.entries(entries).map(([key, entry]) => {
              const isLast = key === lastAuditItemName ? true : false
              const isFirst = key === 'dueDate' ? true : false
              return (
                entry.visible && (
                  <ChildColumnItemBox
                    flex={entry.width}
                    width={boxBorderWidth}
                    isFirst={isFirst}
                    isLast={isLast}
                  >
                    <ColumnItem flex={entry.width} key={key}>
                      <Label>{entry.title}</Label>
                    </ColumnItem>
                  </ChildColumnItemBox>
                )
              )
            })}
            {Object.entries(entriesRevise).map(([key, entry]) => {
              const isLast = key === 'reviseComment' ? true : false
              const isFirst = key === 'reviseDueDate' ? true : false
              return (
                entry.visible && (
                  <ChildColumnItemBox
                    flex={entry.width}
                    width={boxBorderWidthRevise}
                    isFirst={isFirst}
                    isLast={isLast}
                  >
                    <ColumnItem flex={entry.width} key={key}>
                      <Label>{entry.title}</Label>
                    </ColumnItem>
                  </ChildColumnItemBox>
                )
              )
            })}
          </RowHeader>
        </ColumnHeaderColumn>
      </RowWorking>
      <List>
        {props.items.map((item, index) => {
          if (!item) {
            return null
          }
          const indicatedIssue =
            item.indicatedIssues &&
            item.indicatedIssues[item.indicatedIssues.length - 1]
          if (!indicatedIssue) {
            return null
          }
          const isFirst: boolean = index === 0 ? true : false
          const isLast: boolean =
            index === props.items.length - 1 ? true : false
          return (
            <ImproveListItem
              item={item}
              indicatedIssueLabels={props.indicatedIssueLabels}
              reportQuestions={props.reportQuestions || []}
              reportUUID={props.reportUUID}
              improveUUID={props.improveUUID}
              placeUserGroupId={props.placeUserGroupId}
              index={index}
              handleChange={props.handleChange}
              setFieldTouched={props.setFieldTouched}
              setFieldValue={props.setFieldValue}
              resetField={props.resetField}
              isPlaceUser={props.isPlaceUser}
              isAuditor={props.isAuditor}
              isAuditorWorking={props.isAuditorWorking}
              isEditing={props.isEditing}
              isFirstAudit={props.isFirstAudit}
              isRejectStatus={props.isRejectStatus}
              key={item.uuid || index}
              errors={props.errors}
              touched={props.touched}
              deleteIndicatedIssue={() => handleDeleteIndicatedIssue(index)}
              entries={entries}
              entriesRevise={entriesRevise}
              widths={widths}
              isFirst={isFirst}
              isLast={isLast}
              isCompleted={props.isCompleted}
            />
          )
        })}
      </List>
    </Wrapper>
  )
}

export default ImproveList
