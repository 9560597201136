import { ResponseDatetimeSubTypeEnum } from '@ulysses-inc/harami_api_client'
import dateUtil from 'src/exShared/util/date'

export const formatDatetimeValue = (
  subType: ResponseDatetimeSubTypeEnum,
  datetimeValue: Date,
) => {
  switch (subType) {
    case ResponseDatetimeSubTypeEnum.DATETIME:
      return dateUtil.formatYYYYMMDDHHMM_JP(new Date(datetimeValue))
    case ResponseDatetimeSubTypeEnum.DATE:
      return dateUtil.formatYYYYMMDD_JP(new Date(datetimeValue))
    case ResponseDatetimeSubTypeEnum.TIME:
      return dateUtil.formatHHMM_JP(new Date(datetimeValue))
  }
}
