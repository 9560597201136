import {
  ReportNodeSchema,
  ReportPageSchema,
  TemplateLayoutTypeEnum,
  TemplateNodeTypeEnum,
} from '@ulysses-inc/harami_api_client'
import React, { ReactNode } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { Link } from 'react-scroll'
import { HEADER_HEIGHT } from 'src/components/header/Header'
import { WebReportResultProps } from 'src/features/reports/result/WebReportResultProps'
import { Primary } from 'src/features/theme/KdsThemeColor'
import { selectGridLayoutVisiblePageIds } from 'src/state/ducks/reports/reports/selectors'
import styled from 'styled-components'

const PageText = styled.p`
  :not(:first-child) {
    margin-top: 16px;
  }
`

const SideMenuLink = styled(Link)`
  color: ${Primary};
  cursor: pointer;
  font-size: 14px;
`

export const PagesLink: React.FC<WebReportResultProps> = props => {
  const isGridLayout = props.report.layoutType === TemplateLayoutTypeEnum.Grid

  // 表形式の場合、有効なページのみを表示する
  const gridLayoutVisiblePageIds = useSelector(
    selectGridLayoutVisiblePageIds,
    shallowEqual,
  )
  const pageIds = useSelector(() =>
    isGridLayout ? gridLayoutVisiblePageIds : props.report.pageIds,
  )

  const filterReportNodes = (nodeIds: number[]) => {
    const allNodes = props.report.nodes
    return nodeIds
      .map(nodeId => allNodes[nodeId])
      .filter(node => {
        if (node) {
          return (
            node.type === TemplateNodeTypeEnum.Section &&
            !(node.section!.isRepeat === 1 && !node.section!.originalNodeUuid)
          )
        }
        return false
      })
      .map(node => {
        if (node) {
          return renderSectionLink(node)
        }
        return null
      })
  }

  const renderOriginalSectionLinks = (nodeIds: number[]) => {
    const allNodes = props.report.nodes
    return nodeIds
      .map(nodeId => allNodes[nodeId])
      .filter(node => !node?.section?.originalNodeUuid)
      .filter((node): node is ReportNodeSchema => !!node)
      .map(originalSectionNode => renderSectionLink(originalSectionNode))
  }

  const renderSectionLink = (node: ReportNodeSchema): ReactNode => {
    const section = node.section!

    return (
      <>
        <PageText key={`SectionLink-${node.id}`}>
          <SideMenuLink
            to={`Section-${node.id}`}
            spy={true}
            smooth={true}
            offset={-HEADER_HEIGHT}
            duration={200}
          >
            {section.name}
          </SideMenuLink>
        </PageText>
        {node.nodes && filterReportNodes(node.nodes)}
      </>
    )
  }

  const renderPageLink = (page: ReportPageSchema, index: number): ReactNode => {
    return (
      <>
        <PageText key={`PageLink-${page.id}`}>
          <SideMenuLink
            to={`Page-${page.id}`}
            spy={true}
            smooth={true}
            offset={-HEADER_HEIGHT}
            duration={200}
          >
            {page.name || `ページ${index + 1}`}
          </SideMenuLink>
        </PageText>
        {page.nodes &&
          (isGridLayout
            ? renderOriginalSectionLinks(page.nodes)
            : filterReportNodes(page.nodes))}
      </>
    )
  }

  return (
    <>
      {pageIds &&
        pageIds
          .map((pageId: number) => props.report.pages[pageId])
          .filter((page): page is ReportPageSchema => !!page)
          .map((page, index) => {
            return renderPageLink(page, index)
          })}
    </>
  )
}
