import { HomeOutlined, TagOutlined } from '@ant-design/icons'
import {
  GetReportsRequest,
  PlaceNode,
  PlaceNodeTypeEnum,
  ReportPlace,
} from '@ulysses-inc/harami_api_client'
import { Input, Modal, Radio, Tree } from 'antd'
import React, { Dispatch, SetStateAction, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Gray, Success } from 'src/features/theme/KdsThemeColor'
import reportsOperations from 'src/state/ducks/reports/operations'
import { GetReportsFilter } from 'src/state/ducks/reports/types'
import { RootState } from 'src/state/store'
import styled from 'styled-components'
import { flattenNodes } from './Reports'

// antdのTreeコンポーネントが受け取る`treeData`の定義がイマイチなので、自前で定義しないと型パズルが完成しない
// https://4x.ant.design/components/tree/#Tree-props
type TreeDataItem = {
  key: string
  title: React.ReactNode
  children?: TreeDataItem[]
}

const { Search } = Input

const PlaceModal = styled(Modal)`
  .ant-modal-content {
    width: 700px;
    height: 700px;
  }
  .ant-modal-wrap {
    overflow-y: hidden;
  }
  .ant-modal-body {
    padding-right: 12px;
    padding-bottom: 8px;
  }
`

const TreeWrap = styled.div`
  margin-top: 8px;
  overflow-y: scroll;
  height: 520px;
`

const TreeNodeIconTag = styled(TagOutlined)`
  margin-right: 8px;
`
const TreeNodeIconHome = styled(HomeOutlined)`
  margin-right: 8px;
`

const TreeNodeText = styled.span`
  color: ${({ theme }) => theme.color};
`

interface OwnProps {
  isVisiblePlaceModalState: [boolean, Dispatch<SetStateAction<boolean>>]
  selectedReportIdsState: [Set<number>, Dispatch<SetStateAction<Set<number>>>]
}

/**
 * レポート一覧画面の現場の付け替えモーダル
 */
const PlaceGroupsTreeModal: React.FC<OwnProps> = ({
  isVisiblePlaceModalState: [isVisiblePlaceModal, setIsVisiblePlaceModal],
  selectedReportIdsState: [selectedReportIds, setSelectedReportIds],
}) => {
  const { filter, request } = useSelector(
    (state: RootState) => state.reportsState.reports,
  )
  const placeNodes = useSelector((state: RootState) =>
    flattenNodes(state.placesState.placeGroups.nodes),
  ).sort((left, right) => right.type - left.type)

  const dispatch = useDispatch()
  const updateReportPlaces = (
    reportIDs: Array<number>,
    placeNodeID: ReportPlace,
    request: GetReportsRequest,
    filter?: GetReportsFilter,
  ) => {
    const reports = reportIDs.map(reportID => {
      return { id: reportID }
    })
    reportsOperations.updateReportPlaces(
      dispatch,
      reports,
      placeNodeID,
      request,
      filter,
    )
  }

  const [reportPlace, setReportPlace] = useState<ReportPlace>()
  const [searchPlaceText, setSearchPlaceText] = useState('')
  const getPlaceNodeText = (node: PlaceNode, placeNames: string) => {
    if (node.type === PlaceNodeTypeEnum.PlaceGroup) {
      const newPlaceNames = placeNames + node.placeGroup?.name
      const childNodeNames: Array<string> = (node.nodes || []).map(
        (childNode: any) => {
          return getPlaceNodeText(childNode, newPlaceNames)
        },
      )
      return childNodeNames.join(' ')
    }
    return placeNames + node.place?.name
  }
  const filteredPlaceNodes = placeNodes
    .filter(placeNode => {
      if (placeNode.type === PlaceNodeTypeEnum.Place) {
        return false
      }
      // トップレベルにないまたは現場を持たない現場グループは表示しない
      if (!placeNode.isRootNode || (placeNode.nodes || []).length === 0) {
        return false
      }
      const childNodes = flattenNodes(placeNode.nodes as Array<PlaceNode>)
      return childNodes.some(node => node.type === PlaceNodeTypeEnum.Place)
    })
    .filter(placeNode => {
      if (searchPlaceText === '') return true
      const placeNames = getPlaceNodeText(placeNode, '')
      return placeNames.includes(searchPlaceText)
    })

  const generateTreeData = (nodes: PlaceNode[]): TreeDataItem[] =>
    nodes.map((node, index) => {
      switch (node.type) {
        case PlaceNodeTypeEnum.PlaceGroup: {
          const hasSearchText =
            searchPlaceText !== '' &&
            (node.placeGroup?.name || '').includes(searchPlaceText)
          return {
            children: generateTreeData(node.nodes || []),
            key: `${index}${node.uuid}`,
            title: (
              <TreeNodeText theme={{ color: hasSearchText ? Success : Gray }}>
                <TreeNodeIconTag />
                {node.placeGroup?.name}
              </TreeNodeText>
            ),
          }
        }

        case PlaceNodeTypeEnum.Place: {
          const hasSearchText =
            searchPlaceText !== '' &&
            (node.place?.name || '').includes(searchPlaceText)
          return {
            key: `${index}${node.uuid}`,
            title: (
              <Radio
                checked={reportPlace?.nodeId === node.uuid}
                onClick={() =>
                  setReportPlace({
                    nodeId: node.uuid,
                    name: node.place?.name,
                  })
                }
              >
                <TreeNodeText
                  onClick={() =>
                    setReportPlace({
                      nodeId: node.uuid,
                      name: node.place?.name,
                    })
                  }
                  theme={{ color: hasSearchText ? Success : Gray }}
                >
                  <TreeNodeIconHome />
                  {node.place?.name}
                </TreeNodeText>
              </Radio>
            ),
          }
        }

        default: {
          throw new Error('不正な現場のデータです')
        }
      }
    })

  const saveReportPlace = () => {
    if (reportPlace === undefined) {
      return
    }
    const reportIds = Array.from(selectedReportIds)
    updateReportPlaces(reportIds, reportPlace, request, filter)
    setIsVisiblePlaceModal(false)
    setSelectedReportIds(new Set())
  }

  return (
    <>
      <PlaceModal
        onCancel={() => setIsVisiblePlaceModal(false)}
        onOk={() => {
          saveReportPlace()
          setReportPlace(undefined)
        }}
        okText="保存"
        cancelText="キャンセル"
        open={isVisiblePlaceModal}
        centered
      >
        <h2>現場の付け替え</h2>
        <Search onChange={e => setSearchPlaceText(e.target.value)} />
        {placeNodes.length > 0 && (
          <TreeWrap>
            <Tree
              defaultExpandAll
              treeData={generateTreeData(filteredPlaceNodes)}
            />
          </TreeWrap>
        )}
      </PlaceModal>
    </>
  )
}

export default PlaceGroupsTreeModal
