import { ErrorCodeEnum } from '@ulysses-inc/harami_api_client'
import { Button, Card, ConfigProvider, Input, Row, Spin } from 'antd'
import { useFormik } from 'formik'
import { useHistory } from 'react-router-dom'
import { localStorageKeys } from 'src/constants/localStorageKeys'
import { useLoginMutation } from 'src/features/login/useLoginMutation'
import { useSendDummyErrorOnceInTenTimes } from 'src/features/sentry/useSendDummyErrorOnceInTenTimes'
import Yup from 'src/features/validation/yup'
import { errorIs } from 'src/state/middleware/saga/handleHttpError'
import { ErrorMessage } from './ErrorMessage'
import {
  ErrorMessageWrapper,
  LockOutlinedIcon,
  LoginForm,
  LoginFormWrap,
  LoginItem,
  Logo,
  UserOutlinedIcon,
} from './Login.dumb'

interface ILoginForm {
  loginId: string
  password: string
}

const validationSchema = Yup.object().shape({
  loginId: Yup.string().required().label('ログインid'),
  password: Yup.string().required().label('パスワード'),
})

const LoginLegacy = () => {
  const history = useHistory()
  const {
    data: loginUserContext,
    mutate: sendLoginRequest,
    isPending: isLoggingIn,
    error,
  } = useLoginMutation()

  if (loginUserContext) {
    localStorage.setItem(
      localStorageKeys.accessToken,
      loginUserContext.token ?? '',
    )
    history.push('/')
  }

  const sendDummyErrorOnceInTenTimes = useSendDummyErrorOnceInTenTimes()

  const {
    values: { loginId, password },
    handleSubmit,
    handleChange,
    errors,
    touched,
  } = useFormik<ILoginForm>({
    initialValues: {
      loginId: '',
      password: '',
    },
    validationSchema: validationSchema,
    onSubmit: formValues => {
      const { loginId, password } = formValues
      sendLoginRequest({ loginId, password })
    },
  })

  const guaranteeNoError = (key: keyof ILoginForm) =>
    !errors[key] || !touched[key]

  const getValidateStatus = (key: Parameters<typeof guaranteeNoError>[0]) =>
    guaranteeNoError(key) ? '' : 'error'
  const getHelp = (key: Parameters<typeof guaranteeNoError>[0]) =>
    guaranteeNoError(key) ? '' : errors[key]

  const showErrorMessage =
    !isLoggingIn &&
    error !== null &&
    // ネットワークエラーなどの場合は共通エラーメッセージが表示されるためログイン失敗のエラーメッセージは表示しない
    errorIs(error, ErrorCodeEnum.AuthenticationFailure)

  return (
    <Row>
      {showErrorMessage && (
        <ErrorMessageWrapper>
          <ErrorMessage></ErrorMessage>
        </ErrorMessageWrapper>
      )}
      <LoginFormWrap>
        <Spin spinning={isLoggingIn}>
          <Card>
            <LoginForm onFinish={handleSubmit} className="login-form">
              <Logo onClick={sendDummyErrorOnceInTenTimes} />
              <LoginItem
                validateStatus={getValidateStatus('loginId')}
                help={getHelp('loginId')}
              >
                <Input
                  name="loginId"
                  prefix={<UserOutlinedIcon />}
                  placeholder="ログインID"
                  value={loginId}
                  onChange={handleChange}
                />
              </LoginItem>
              <LoginItem
                validateStatus={getValidateStatus('password')}
                help={getHelp('password')}
              >
                <Input
                  name="password"
                  prefix={<LockOutlinedIcon />}
                  type="password"
                  placeholder="パスワード"
                  value={password}
                  onChange={handleChange}
                />
              </LoginItem>
              <LoginItem>
                <ConfigProvider
                  theme={{
                    // TODO: 歴史的な経緯によりログイン画面だけロゴやボタンの色を古い色（緑）のままにしている。
                    //       緑ヤギを葬るのと同時にこのConfigProviderも葬ること。
                    components: {
                      Button: {
                        colorPrimary: '#07b7b7',
                        colorPrimaryHover: '#07b7b7',
                      },
                    },
                  }}
                >
                  <Button
                    block
                    type="primary"
                    htmlType="submit"
                    disabled={isLoggingIn}
                  >
                    ログイン
                  </Button>
                </ConfigProvider>
              </LoginItem>
            </LoginForm>
          </Card>
        </Spin>
      </LoginFormWrap>
    </Row>
  )
}

export default LoginLegacy
