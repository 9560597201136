import * as qs from 'qs'
import { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

/**
 * レポート出力一覧を取得するにあたってAPIに指定できるフィルタ条件
 */
export type ReportExportListFilters = {
  page: number
  pageSize: number
  // 今後フィルタを実装する際などは、ここに**フラットに**追記していく
}

const initialFilters: ReportExportListFilters = {
  page: 1,
  pageSize: 25,
}

const sanitize = {
  page: (page: number) => {
    if (page >= 1) return page
    return initialFilters.page
  },
  pageSize: (pageSize: number) => {
    if (pageSize >= 1 && pageSize <= 100) return pageSize
    return initialFilters.pageSize
  },
}

export const useReportExportListFilters = () => {
  const history = useHistory()
  const location = useLocation()

  const rawFilters = qs.parse(location.search, { ignoreQueryPrefix: true })

  const sanitizedFilters = {
    page: sanitize.page(Number(rawFilters['page'])),
    pageSize: sanitize.pageSize(Number(rawFilters['pageSize'])),
  }

  const [filters, setFilters] =
    useState<ReportExportListFilters>(sanitizedFilters)

  useEffect(() => {
    const queryString = qs.stringify(filters, {
      skipNulls: true,
      encode: false,
    })
    history.replace({ search: `?${queryString}` })
  }, [filters, history])

  return { filters, setFilters }
}
