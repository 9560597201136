import { Select as ASelect } from 'antd'
import { ComponentProps, FC } from 'react'
import {
  EditFormControl,
  EditFormLabel,
  EmptyOptionValue,
  Select,
} from './dumb'

type OwnProps = {
  title: string
  options: { value: string; label: string; format: string }[]
  onChange: ComponentProps<typeof Select>['onChange']
  defaultUuid: string
}

/**
 * セレクトボックスのラッパー
 */
export const SelectWrapper: FC<OwnProps> = ({
  title,
  options,
  onChange,
  defaultUuid,
}) => {
  // 与えられた defaultUuid をキーに初期値を決定する
  const initialValue = options.find(o => o.value === defaultUuid)?.value

  return (
    <EditFormControl>
      <EditFormLabel>{title}</EditFormLabel>
      <Select onChange={onChange} value={initialValue}>
        {options.map((o, i) => {
          return (
            <ASelect.Option key={i} value={o.value}>
              {o.label ? (
                `[${o.format}] ${o.label}`
              ) : (
                <EmptyOptionValue>
                  {`[${o.format}] 質問名なし`}
                </EmptyOptionValue>
              )}
            </ASelect.Option>
          )
        })}
      </Select>
    </EditFormControl>
  )
}
