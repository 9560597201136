import { StyleSheet, Text, View } from '@react-pdf/renderer'
import { Image } from '@ulysses-inc/harami_api_client'
import React from 'react'
import notFoundImage from 'src/assets/images/imageNotFound1000x1000.png'
import Grid from 'src/features/reports/result/downloadPDF/Grid'
import ReactPDFImage from 'src/features/reports/result/downloadPDF/ReactPDFImage'

const styles = StyleSheet.create({
  titleLabel: {
    padding: 5,
    fontSize: 8,
    color: '#656565',
  },
  images: {
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    flexDirection: 'row',
    marginBottom: 16,
  },
  imageColumn: {
    margin: 10,
    flexDirection: 'column',
  },
  image: {
    height: 100,
    width: 100,
  },
  optionManualMemo: {
    width: '100%',
  },
})

interface OwnProps {
  memo: Image
}

const OptionManualMemo: React.FC<OwnProps> = (props: OwnProps) => {
  return (
    <Grid borderLeft borderRight style={styles.optionManualMemo}>
      <View>
        <Text style={styles.titleLabel}>- 手書きメモ</Text>
        <View key={props.memo.id} style={styles.imageColumn}>
          <ReactPDFImage
            style={styles.image}
            uri={
              props.memo?.url !== undefined
                ? `${props.memo?.url ?? ''}`
                : notFoundImage
            }
          />
        </View>
      </View>
    </Grid>
  )
}

export default OptionManualMemo
