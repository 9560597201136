/**
 * 一覧画面の現在のページ数を調整する
 * @param limit
 * @param offset
 * @param count
 * @returns ページ数
 */
export const adjustCurrentPage = (
  limit: number | undefined,
  offset: number | undefined,
  count: number,
) => {
  // 初期表示は 1
  let currentPage = 1

  // アクティブなページを更新する
  if (offset !== undefined && limit !== undefined && count !== 0) {
    currentPage = offset / limit + 1

    // 表示していたページが存在しない場合、末尾のページを表示対象とする
    const maxPageSize = Math.ceil(count / limit)
    if (currentPage > maxPageSize) {
      // NOTE: 今現在の実装方式では、 再度 API がリクエストされてしまうため、 offset の変更は行わない
      // offset が更新されていない場合の問題:
      //  画面をリロードした時に表示していたページと別なページが表示される
      // API が再度リクエストされる場合の問題:
      //  画面表示までの時間が最大で ２ 倍になる
      // offset を更新しない方針とした理由:
      // 承認一覧はデータ量の関係でパフォーマンスに影響が出やすい画面のため
      // 将来的に、 API のコールバックで計算し直したり、 Pagination の current 部分を拡張するなど
      // 根治方法を検討し、解決する必要はある

      // Ex: 以下は一括承認後に表示ページを変更した場合、 offset を表示ページに合わせて変更する処理
      //     使う画面に合わせて変更する必要がある
      // const offset =
      //   maxPageSize <= 1 ? 0 : (maxPageSize - 1) * limit
      // approvalsOperations.updatePagination(
      //   dispatch,
      //   limit,
      //   offset,
      // )
      currentPage = maxPageSize
    }
  }
  return currentPage
}
