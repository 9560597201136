import { useQuery } from '@tanstack/react-query'
import {
  ReportFilter,
  ReportsApi,
  ReportStatusEnum,
} from '@ulysses-inc/harami_api_client'
import { addDays } from 'date-fns'
import { queryKeys } from 'src/features/reports/listBeta/api/queryKeys'
import { ReportListFilters } from 'src/features/reports/listBetaFilters/types'
import { baseClient } from 'src/state/middleware/saga/baseClient'
import { parseDateString } from 'src/util/datetime/parseDateString'
import invariant from 'tiny-invariant'

export type Report = {
  assigneeName: string
  hasSomeDeviatedAnswer: boolean
  id: number
  isAudit: boolean
  name: string
  placeName: string
  reportDate: Date
  updatedAt: Date
  uuid: string
  score: string
  status:
    | 'inCompleted'
    | 'approvalPending'
    | 'approvalRemand'
    | 'completed'
    | 'unknown'
}

type ReportsQueryResult = {
  pagination: {
    prevPage: boolean
    nextPage: boolean
  }
  reports: Report[]
}

/**
 * フィルタ条件をAPIに投げられる形のオブジェクトに変換する
 */
export const toApiFilters = (filters: ReportListFilters): ReportFilter => {
  const result: ReportFilter = {}

  const {
    dateFrom,
    dateTo,
    deviatedAnswer,
    employeeNameOrCode,
    placeNodeUuids,
    reportName,
    statuses,
  } = filters

  if (dateFrom && dateTo) {
    const dateTimeFrom = parseDateString(dateFrom)
    const dateTimeTo = parseDateString(dateTo)
    invariant(dateTimeFrom && dateTimeTo)
    result.reportDate = {
      $gte: dateTimeFrom,
      // 例えば日付の範囲指定が「2021-01-01から2021-01-02」だった場合、
      // 終点は2021-01-03T00:00:00になるべきであるため、1日足しておく。
      $lt: addDays(dateTimeTo, 1),
    }
  }

  if (deviatedAnswer && deviatedAnswer.length > 0) {
    const deviatedAnswerForApi = deviatedAnswer.map(deviatedAnswerFilter => {
      switch (deviatedAnswerFilter) {
        case 'absent':
          return false
        case 'present':
          return true
      }
    })
    result.isInvalid = { $in: deviatedAnswerForApi }
  }

  if (employeeNameOrCode) {
    result.answer ??= {}
    result.answer.employee = { $like: employeeNameOrCode }
  }

  if (placeNodeUuids.length > 0) {
    result.placeNodeId = { $in: placeNodeUuids }
  }

  if (reportName) {
    result.reportName = { $like: reportName }
  }

  if (statuses && statuses.length > 0) {
    const statusesForApi = statuses.map(status => {
      switch (status) {
        case 'inCompleted':
          return ReportStatusEnum.INCOMPLETE
        case 'approvalPending':
          return ReportStatusEnum.APPROVAL_PENDING
        case 'approvalRemand':
          return ReportStatusEnum.APPROVAL_REMAND
        case 'completed':
          return ReportStatusEnum.COMPLETE
      }
    })
    result.status = { $in: statusesForApi }
  }

  return result
}

export const useQueryReports = (filters: ReportListFilters) => {
  return useQuery({
    queryKey: queryKeys.reports(filters),
    queryFn: async () => {
      // TODO: リリース前に消す
      // await new Promise(resolve => setTimeout(resolve, 1000))

      return baseClient.getApi(ReportsApi).searchReports({
        page: filters.page,
        pageSize: filters.pageSize,
        searchReportsRequestBody: toApiFilters(filters),
      })
    },
    select: (data): ReportsQueryResult => ({
      pagination: data.pagination,
      reports: (data.reports || []).map(report => {
        const convertStatus = (status: ReportStatusEnum) => {
          switch (status) {
            case ReportStatusEnum.INCOMPLETE:
              return 'inCompleted'
            case ReportStatusEnum.APPROVAL_PENDING:
              return 'approvalPending'
            case ReportStatusEnum.APPROVAL_REMAND:
              return 'approvalRemand'
            case ReportStatusEnum.COMPLETE:
              return 'completed'
            default:
              return 'unknown'
          }
        }

        return {
          assigneeName: report.assigneeName,
          // isInvalidが0なら逸脱回答がない、1なら逸脱回答がある、ということらしい
          hasSomeDeviatedAnswer: !!report.hasSomeDeviatedAnswer,
          id: report.id,
          isAudit: report.isAudit || false,
          name: report.name,
          placeName: report.placeName || '',
          reportDate: report.reportDate,
          score: report.isShowScore ? `${report.score}%` : '-',
          status: convertStatus(report.status),
          updatedAt: report.updatedAt,
          uuid: report.uuid,
        }
      }),
    }),
  })
}
