import { CompanySettings } from '@ulysses-inc/harami_api_client'
import { combineReducers } from 'redux'
import {
  GetCompanySettingsAction,
  UpdateCompanySettingsAction,
} from './actions'
import { ActionTypes } from './types'

interface CompanySettingsState {
  companySettings: CompanySettings | null
  isLoading: boolean
  error: Error | null
}

const initialCompanySettingsState: CompanySettingsState = {
  companySettings: null,
  isLoading: false,
  error: null,
}

const companySettings = (
  state: CompanySettingsState = initialCompanySettingsState,
  action: GetCompanySettingsAction,
): CompanySettingsState => {
  switch (action.type) {
    case ActionTypes.REQUEST_GET_COMPANY_SETTINGS: {
      return {
        ...state,
        isLoading: action.isLoading,
        companySettings: null,
        error: null,
      }
    }
    case ActionTypes.SUCCESS_GET_COMPANY_SETTINGS: {
      return {
        ...state,
        isLoading: action.isLoading,
        companySettings: action.companySettings,
        error: null,
      }
    }
    case ActionTypes.ERROR_GET_COMPANY_SETTINGS: {
      return {
        ...state,
        isLoading: action.isLoading,
        error: action.error,
      }
    }
    default: {
      return { ...state }
    }
  }
}

interface UpdateCompanySettingsState {
  isLoading: boolean
  error: Error | null
}

const initialUpdateCompanySettingsState: UpdateCompanySettingsState = {
  isLoading: false,
  error: null,
}

const updateCompanySettings = (
  state: UpdateCompanySettingsState = initialUpdateCompanySettingsState,
  action: UpdateCompanySettingsAction,
): UpdateCompanySettingsState => {
  switch (action.type) {
    case ActionTypes.REQUEST_UPDATE_COMPANY_SETTINGS: {
      return {
        ...state,
        isLoading: action.isLoading,
        error: null,
      }
    }
    case ActionTypes.SUCCESS_UPDATE_COMPANY_SETTINGS: {
      return {
        ...state,
        isLoading: action.isLoading,
        error: null,
      }
    }
    case ActionTypes.ERROR_UPDATE_COMPANY_SETTINGS: {
      return {
        ...state,
        isLoading: action.isLoading,
        error: action.error,
      }
    }
    default: {
      return { ...state }
    }
  }
}

const companySettingsReducer = combineReducers({
  companySettings,
  updateCompanySettings,
})

export default companySettingsReducer
