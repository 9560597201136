import { css } from '@emotion/react'
import { Button, Dropdown, Select } from 'antd'
import React, { useEffect, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { splitAccessiblePlaceNode } from 'src/exShared/libraries/filterDropDown/place'
import {
  flattenNodes,
  mergedPlaceNodeIds,
} from 'src/exShared/util/place/placeNode'
import { splitLoginPlaceUUIDs } from 'src/exShared/util/place/splitLoginPlace'
import { useListFilter } from 'src/hooks/filter/useListFilter'
import { RootState } from 'src/state/store'
import {
  DownOutlinedIcon,
  DropDownTableCellForPlaceFilterDropDown,
  ExpandIconWrap,
  FilterButton,
  FilterLabel,
  SelectFilterTableCell,
  SelectLabel,
  SelectLabelWrap,
  UpOutlinedIcon,
} from './shared.components'
import {
  useCurrentEndDateState,
  useCurrentStartDateState,
  useFilteredPlaceGroupNodeIdsState,
  useFilteredPlaceNodeIdsState,
  useRequestScheduleData,
} from './shared.hooks'

const { Option } = Select

interface OwnProps {
  isSchedule: boolean
}

const PlaceFilterDropDown: React.FC<OwnProps> = ({ isSchedule }) => {
  const type = isSchedule ? 'schedule' : 'deviance'

  const places = useSelector(
    (state: RootState) => state.placesState.places.places,
    shallowEqual,
  )
  const placeNodes = useSelector(
    (state: RootState) => flattenNodes(state.placesState.placeGroups.nodes),
    shallowEqual,
  )

  const [startDate] = useCurrentStartDateState(type)
  const [endDate] = useCurrentEndDateState(type)
  const [filteredPlaceIds, setFilteredPlaceIds] =
    useFilteredPlaceNodeIdsState(type)
  const [filteredPlaceGroupIds, setFilteredPlaceGroupIds] =
    useFilteredPlaceGroupNodeIdsState(type)

  const [isOpen, setIsOpen] = useState(false)

  const [placeGroupNodeIds, setPlaceGroupNodeIds] = useState<string[]>(
    filteredPlaceGroupIds,
  )
  const [placeNodeIds, setPlaceNodeIds] = useState<string[]>(filteredPlaceIds)

  const requestScheduleData = useRequestScheduleData()
  const { filters, setListFilter } = useListFilter()

  useEffect(() => {
    setPlaceGroupNodeIds(filteredPlaceGroupIds)
    setPlaceNodeIds(filteredPlaceIds)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredPlaceGroupIds, filteredPlaceIds])

  const { loginPlaceGroupIds, loginPlaceIds } = splitLoginPlaceUUIDs()

  const isFilterOn = !!placeGroupNodeIds.length || !!placeNodeIds.length

  const changeFilter = (
    mergedPlaceNodeIds: string[],
    filteredPlaceIds: string[],
    filteredPlaceGroupIds: string[],
  ) => {
    if (!(startDate && endDate)) {
      return
    }

    setListFilter({
      ...(filters ?? {}),
      [isSchedule ? 'unstartedSchedules' : 'invalidCountSchedules']: {
        filter: {
          placeNodeIds: filteredPlaceIds,
          placeGroupNodeIds: filteredPlaceGroupIds,
        },
      },
    })
    setFilteredPlaceIds(filteredPlaceIds)
    setFilteredPlaceGroupIds(filteredPlaceGroupIds)

    requestScheduleData(isSchedule, mergedPlaceNodeIds, startDate, endDate)
  }

  const clearFilter = () => {
    const defaultPlaceNodeIDs = mergedPlaceNodeIds(
      placeNodes,
      loginPlaceIds || [],
      loginPlaceGroupIds || [],
    )

    setPlaceNodeIds([])
    setPlaceGroupNodeIds([])
    changeFilter(defaultPlaceNodeIDs, loginPlaceIds, loginPlaceGroupIds)
  }

  const onChange =
    (type: 'place' | 'placeGroup') =>
    (selectedPlaceOrPlaceGroupIds: string[] = []) => {
      const isPlace = type === 'place'
      const currentPlaceNodeIds = isPlace
        ? selectedPlaceOrPlaceGroupIds
        : placeNodeIds
      const currentPlaceGroupNodeIds = isPlace
        ? placeGroupNodeIds
        : selectedPlaceOrPlaceGroupIds

      ;(isPlace ? setPlaceNodeIds : setPlaceGroupNodeIds)(
        selectedPlaceOrPlaceGroupIds,
      )

      const nodeIds = mergedPlaceNodeIds(
        placeNodes,
        currentPlaceNodeIds,
        currentPlaceGroupNodeIds,
      )

      if (nodeIds.length) {
        changeFilter(nodeIds, currentPlaceNodeIds, currentPlaceGroupNodeIds)
      } else {
        const defaultPlaceNodeIDs = mergedPlaceNodeIds(
          placeNodes,
          loginPlaceIds || [],
          loginPlaceGroupIds || [],
        )
        changeFilter(defaultPlaceNodeIDs, loginPlaceIds, loginPlaceGroupIds)
      }
    }

  const renderSelect = (type: 'place' | 'placeGroup') => {
    const isPlace = type === 'place'

    const accessiblePlaceAndPlaceGroupNodes = splitAccessiblePlaceNode(
      placeNodes,
      places,
    )[isPlace ? 'accessiblePlaces' : 'accessiblePlaceGroups']

    return (
      <>
        <SelectLabelWrap>
          <SelectLabel>現場{isPlace ? '' : 'グループ'}名</SelectLabel>
        </SelectLabelWrap>

        <Select
          mode="multiple"
          style={{ width: '100%' }}
          value={isPlace ? placeNodeIds : placeGroupNodeIds}
          optionFilterProp="children"
          onChange={onChange(type)}
        >
          {accessiblePlaceAndPlaceGroupNodes.map(
            ({ uuid, place, placeGroup }, index) => {
              const name = (isPlace ? place : placeGroup)?.name

              return (
                <Option key={index} value={uuid} label={name}>
                  {name}
                </Option>
              )
            },
          )}
        </Select>
      </>
    )
  }

  const groundArea = (
    <SelectFilterTableCell theme={{ width: '160px' }}>
      <FilterButton>
        <FilterLabel>現場を絞り込む：{isFilterOn ? 'ON' : 'OFF'}</FilterLabel>
        <ExpandIconWrap>
          {isOpen ? <UpOutlinedIcon /> : <DownOutlinedIcon />}
        </ExpandIconWrap>
      </FilterButton>
    </SelectFilterTableCell>
  )

  const hoverArea = (
    <div style={{ display: 'flex' }}>
      <DropDownTableCellForPlaceFilterDropDown>
        <div css={styles.clearButtonRow}>
          <Button onClick={clearFilter}>クリア</Button>
        </div>
        {renderSelect('placeGroup')}
        {renderSelect('place')}
      </DropDownTableCellForPlaceFilterDropDown>
    </div>
  )

  return (
    <Dropdown
      dropdownRender={() => hoverArea}
      trigger={['click']}
      open={isOpen}
      onOpenChange={setIsOpen}
    >
      {groundArea}
    </Dropdown>
  )
}

const styles = {
  clearButtonRow: css`
    display: flex;
    justify-content: flex-end;
  `,
}

export default PlaceFilterDropDown
