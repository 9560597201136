import { Link, StyleSheet, View } from '@react-pdf/renderer'
import { Image } from '@ulysses-inc/harami_api_client'
import React from 'react'
import BreakText from 'src/features/reports/result/downloadPDF/BreakText'
import Grid from 'src/features/reports/result/downloadPDF/Grid'
import ReactPDFImage from 'src/features/reports/result/downloadPDF/ReactPDFImage'
import RecordedTime from './RecordedTime'

interface OwnProps {
  questionName: string
  questionInformation?: Image
  answerInformation?: Image
  recordedAt?: Date
  isTimeDisplayed: boolean
}

const styles = StyleSheet.create({
  gridInformation: { width: '100%' },
  image: {
    width: '50%',
    height: 140,
    objectFit: 'contain',
    left: 1,
  },
  recordedTimeWrap: { width: '100%', marginBottom: 5 },
  fullWidthWrap: { width: '100%' },
})

const Information: React.FC<OwnProps> = (props: OwnProps) => {
  const quesInfoUrl = props?.questionInformation?.url || ''
  const quesInfoUrlExt = quesInfoUrl.split('.').pop()
  const isPDF = quesInfoUrlExt === 'pdf'
  const quesInfoThumbUrl = isPDF
    ? quesInfoUrl.replace('.pdf', '.png')
    : quesInfoUrl
  return (
    <Grid borderLeft borderRight style={styles.gridInformation}>
      <View style={styles.fullWidthWrap}>
        <BreakText text={props.questionName} />
        {props.answerInformation?.url === undefined ? (
          props.questionInformation?.url !== undefined && (
            <View>
              {isPDF && (
                <Link src={quesInfoThumbUrl} style={{ marginLeft: 5 }}>
                  {props.questionInformation.name}
                </Link>
              )}
              <ReactPDFImage style={styles.image} uri={quesInfoThumbUrl} />
            </View>
          )
        ) : (
          <View>
            {isPDF && (
              <Link src={props.answerInformation.url} style={{ marginLeft: 5 }}>
                {props.answerInformation.name}
              </Link>
            )}
            <ReactPDFImage
              style={styles.image}
              uri={props.answerInformation.url}
            />
          </View>
        )}
        <View style={styles.recordedTimeWrap}>
          <RecordedTime
            isTimeDisplayed={props.isTimeDisplayed}
            recordedAt={props.recordedAt}
          />
        </View>
      </View>
    </Grid>
  )
}

export default Information
