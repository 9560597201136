import {
  MultipleChoiceGroup,
  ResponseTypeEnum,
  TemplateNodeSchema,
  TemplateNodeTypeEnum,
} from '@ulysses-inc/harami_api_client'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'src/state/store'

export const isUsed = (
  groupId: number,
  templateNodes: { [key: number]: TemplateNodeSchema },
): boolean =>
  Object.values(templateNodes).some(
    ({ type, question }) =>
      type === TemplateNodeTypeEnum.Question &&
      question?.responseType === ResponseTypeEnum.MULTIPLE_CHOICE &&
      question?.responseMultipleChoices?.some(group => group?.id === groupId),
  )

export const useUsageState = (
  multipleChoiceGroupForUpdate: MultipleChoiceGroup | undefined,
): boolean => {
  const templateNodes = useSelector(
    (state: RootState) => state.templatesState.templatePages.templateNodes,
  )
  // 選択肢群が現在のテンプレートで使われているか
  const [isInUse, setIsInUse] = useState(false)

  useEffect(() => {
    // 選択肢群が現在のテンプレートで使われているかを確認する
    if (multipleChoiceGroupForUpdate?.id && templateNodes) {
      const groupId = multipleChoiceGroupForUpdate.id
      setIsInUse(isUsed(groupId, templateNodes))
    } else {
      setIsInUse(false)
    }
  }, [templateNodes, multipleChoiceGroupForUpdate])

  return isInUse
}
