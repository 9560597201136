import Redux from 'redux'
import actions from './actions'
import { GetScheduleListInRangeItems } from './types'

const getScheduleListInRangeItems = (
  dispatch: Redux.Dispatch,
  request: GetScheduleListInRangeItems,
  locale: string,
) => {
  dispatch(actions.getScheduleListInRangeItems(request, locale))
}

const getPlaceScheduleListInRangeItems = (
  dispatch: Redux.Dispatch,
  request: GetScheduleListInRangeItems,
  locale: string,
) => {
  dispatch(actions.getPlaceScheduleListInRangeItems(request, locale))
}

const resetPlaceScheduleListInRangeItems = (dispatch: Redux.Dispatch) => {
  dispatch(actions.resetPlaceScheduleListInRangeItems())
}

const getInvalidCountScheduleListInRangeItems = (
  dispatch: Redux.Dispatch,
  request: GetScheduleListInRangeItems,
  locale: string,
) => {
  dispatch(actions.getInvalidCountScheduleListInRangeItems(request, locale))
}

const resetInvalidCountScheduleListInRangeItems = (
  dispatch: Redux.Dispatch,
) => {
  dispatch(actions.resetInvalidCountScheduleListInRangeItems())
}

const getMonthlyPlaceScheduleListInRangeItems = (
  dispatch: Redux.Dispatch,
  request: GetScheduleListInRangeItems,
  locale: string,
) => {
  dispatch(actions.getMonthlyPlaceScheduleListInRangeItems(request, locale))
}

export default {
  getScheduleListInRangeItems,
  getPlaceScheduleListInRangeItems,
  resetPlaceScheduleListInRangeItems,
  getInvalidCountScheduleListInRangeItems,
  resetInvalidCountScheduleListInRangeItems,
  getMonthlyPlaceScheduleListInRangeItems,
}
