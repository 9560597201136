import {
  TemplateLayoutTypeEnum,
  TemplateNodeTypeEnum,
} from '@ulysses-inc/harami_api_client'
import {
  LogicNodeTable,
  NodeIdPaths,
  ReportNodesDict,
} from 'src/exShared/types/types'
import { isNullish } from 'src/util/isNullish'
import { isActiveLogic } from './isActiveLogic'

/**
 * 条件分岐 Node の条件が成立しているかどうかを保持するマップを生成する
 *
 * NOTE:
 * 現時点では、表形式のひな形を前提とした実装となっている。
 * 行形式のひな形の場合、空のテーブルを作成している。
 *
 * @param layoutType 表形式のひな形でない場合、空のテーブルを作成する
 * @param idPaths 型情報のコメント参照
 * @param allNodes 対象としたい条件分岐が含まれるノード辞書
 * @returns
 */
export const generateLogicNodeTable = (
  layoutType: TemplateLayoutTypeEnum,
  idPaths: NodeIdPaths,
  allNodes: ReportNodesDict,
) => {
  if (layoutType !== TemplateLayoutTypeEnum.Grid) {
    return {}
  }

  const logicNodeTable: LogicNodeTable = {}

  for (const node of Object.values(allNodes)) {
    if (node.type !== TemplateNodeTypeEnum.Logic) continue

    const idPath = idPaths[node.id]
    if (!idPath) continue

    const parentNodeInfo = idPath[idPath.length - 1]
    if (isNullish(parentNodeInfo)) continue

    const parentQuestionNode = allNodes[parentNodeInfo.nodeId]
    if (isNullish(parentQuestionNode)) continue

    const { question } = parentQuestionNode
    if (isNullish(question)) continue

    const { logic } = node
    if (isNullish(logic)) continue

    logicNodeTable[node.id] = isActiveLogic(question, logic)
  }

  return logicNodeTable
}
