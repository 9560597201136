import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
  InboxOutlined,
} from '@ant-design/icons'
import {
  ReportListItem,
  ReportStatusEnum,
  Schedule,
} from '@ulysses-inc/harami_api_client'
import { Checkbox, Modal } from 'antd'
import { Dispatch, FC, ReactNode, SetStateAction, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { RowActionButton } from 'src/components/rowActionButton/RowActionButton'
import dateUtil from 'src/exShared/util/date'
import { useLimitedFeatureAvailability } from 'src/features/featureAvailability/useLimitedFeatureAvailability'
import {
  Danger,
  ModalDeleteButtonColor,
  Success,
  Warning,
} from 'src/features/theme/KdsThemeColor'
import reportsOperations from 'src/state/ducks/reports/operations'
import {
  ActionButtonBackground,
  CheckboxColumn,
  DateReportListItem,
  DateReportListItemWrap,
  ImproveOptionTag,
  ListHeader,
  ReportIsInvalidColumn,
  ReportNameColumn,
  ReportPlaceColumn,
  ReportScoreColumn,
  ReportStatusColumn,
  ReportStatusContent,
  ReportStatusText,
  ReportUpdatedColumn,
  ReportUserColumn,
} from './Reports.styled'

const ReportsTableRow: FC<{
  report: {
    sectionDate: string
    dateReports: ReportListItem[]
  }
  selectedReportIdsState: [Set<number>, Dispatch<SetStateAction<Set<number>>>]
}> = ({
  report: { sectionDate, dateReports: reports },
  selectedReportIdsState: [selectedReportIds, setSelectedReportIds],
}) => {
  const { isFeatureAvailable } = useLimitedFeatureAvailability()

  /**
   * スケジュールに紐づくtemplate_idを取得する
   * @param schedules スケジュール
   * @returns スケジュールに紐づくtemplateId、存在しない場合は 0 を返す
   */
  const getScheduleTemplateId = (schedules: Schedule[] | undefined) => {
    let templateId = 0
    const schedule = (schedules || [])[0]
    if (schedule) {
      const template = (schedule.templates || [])[0]
      if (template) {
        templateId = template.id || 0
      }
    }
    return templateId
  }

  const dispatch = useDispatch()
  const deleteReport = (reportId: string) => {
    reportsOperations.deleteReport(dispatch, reportId)
  }
  const ActionButtonWrapper = ({
    children,
    report,
  }: {
    children: ReactNode
    report: ReportListItem
  }) => {
    const { uuid, templateId, schedules } = report
    // reports.template_idに0が入るケースがあるため、schedules_templates.template_idを優先する
    let parameterTemplateId = getScheduleTemplateId(schedules)
    if (parameterTemplateId === 0) {
      // スケジュールに紐づくtemplateIdがない、または存在しない場合は reports.templateIdを使う
      parameterTemplateId = templateId ?? 0
    }

    return (
      <Link style={{ height: '100%' }} to={`/reports/${uuid}`}>
        {children}
      </Link>
    )
  }

  const [hoveredReportId, setHoveredReportId] = useState<string | null>()

  const selectReport = (reportId: number, checked: boolean) => {
    if (checked) {
      setSelectedReportIds(
        new Set([...Array.from(selectedReportIds), reportId]),
      )
      return
    }

    const prunedSelectedTemplateIds = Array.from(selectedReportIds).filter(
      selectedReportId => selectedReportId !== reportId,
    )
    setSelectedReportIds(new Set(prunedSelectedTemplateIds))
  }

  const renderReportStatus = (report: ReportListItem) => {
    switch (report.status?.status) {
      case ReportStatusEnum.COMPLETE:
        return (
          <ReportStatusContent>
            <CheckCircleOutlined style={{ color: Success }} />
            <ReportStatusText style={{ color: Success }}>完了</ReportStatusText>
          </ReportStatusContent>
        )
      case ReportStatusEnum.INCOMPLETE:
        return (
          <ReportStatusContent>
            <ExclamationCircleOutlined style={{ color: Danger }} />
            <ReportStatusText style={{ color: Danger }}>
              未完了
            </ReportStatusText>
          </ReportStatusContent>
        )
      case ReportStatusEnum.APPROVAL_PENDING:
        return (
          <ReportStatusContent>
            <InboxOutlined style={{ color: Warning }} />
            <ReportStatusText style={{ color: Warning }}>
              承認待ち
            </ReportStatusText>
          </ReportStatusContent>
        )
      case ReportStatusEnum.APPROVAL_REMAND:
        return (
          <ReportStatusContent>
            <InboxOutlined style={{ color: Danger }} />
            <ReportStatusText style={{ color: Danger }}>
              差し戻し
            </ReportStatusText>
          </ReportStatusContent>
        )
      default:
        return <></>
    }
  }

  return (
    <div>
      <ListHeader>{sectionDate}</ListHeader>
      {reports.map((report: ReportListItem) => (
        <DateReportListItemWrap
          key={`sectionDate_${report.uuid}-${report.id}`}
          onMouseEnter={() => setHoveredReportId(report.uuid)}
          onMouseLeave={() => setHoveredReportId(null)}
        >
          <DateReportListItem>
            <CheckboxColumn theme={{ widthPercentage: 3 }}>
              <Checkbox
                /* eslint-disable-next-line no-prototype-builtins */
                checked={report.id ? selectedReportIds.has(report.id) : false}
                onChange={e =>
                  report.id && selectReport(report.id, e.target.checked)
                }
              />
            </CheckboxColumn>
            <ReportNameColumn theme={{ widthPercentage: 23 }}>
              {report.name}
              {isFeatureAvailable('improves') && report.isAudit ? (
                <ImproveOptionTag>カイゼン</ImproveOptionTag>
              ) : null}
            </ReportNameColumn>
            <ReportPlaceColumn theme={{ widthPercentage: 18 }}>
              {report.place?.name}
            </ReportPlaceColumn>
            <ReportUserColumn theme={{ widthPercentage: 20 }}>
              {report.assignee?.name}
            </ReportUserColumn>
            <ReportIsInvalidColumn theme={{ widthPercentage: 8 }}>
              {report.isInvalid === 1 && (
                <ExclamationCircleOutlined style={{ color: Danger }} />
              )}
            </ReportIsInvalidColumn>
            <ReportUpdatedColumn theme={{ widthPercentage: 12 }}>
              {dateUtil.formatYYYYMMDDHHMM(report.updatedAt)}
            </ReportUpdatedColumn>
            <ReportStatusColumn theme={{ widthPercentage: 10 }}>
              {renderReportStatus(report)}
            </ReportStatusColumn>
            <ReportScoreColumn theme={{ widthPercentage: 6 }}>
              {report.isShowScore ? `${report.score}%` : '-'}
            </ReportScoreColumn>
            {hoveredReportId === report.uuid && (
              <ActionButtonBackground>
                <ActionButtonWrapper report={report}>
                  <RowActionButton type="viewDetail" />
                </ActionButtonWrapper>
                <RowActionButton
                  onClick={() => {
                    Modal.confirm({
                      onOk: () => {
                        deleteReport(report.uuid ?? '')
                      },
                      title: `「${report.name}」の削除を実行しますがよろしいですか？`,
                      okText: '削除',
                      cancelText: 'キャンセル',
                      okButtonProps: {
                        style: {
                          backgroundColor: ModalDeleteButtonColor,
                          border: 'none',
                        },
                      },
                    })
                  }}
                  type="delete"
                />
              </ActionButtonBackground>
            )}
          </DateReportListItem>
        </DateReportListItemWrap>
      ))}
    </div>
  )
}

export default ReportsTableRow
