import { parseISO } from 'date-fns'
import { isValidDateString } from 'src/util/datetime/isValidDateString'

/**
 * 日付文字列をDateに変換する。
 * 時刻は実行環境のタイムゾーンベースで0時0分0秒にする。
 */
export const parseDateString = (dateString: string): Date | null => {
  if (!isValidDateString(dateString)) {
    return null
  }
  return parseISO(dateString)
}
