import { DatabaseOutlined, PlusCircleOutlined } from '@ant-design/icons'
import {
  TemplateLayoutTypeEnum,
  TemplateNodeSchema,
  TemplateNodeTypeEnum,
} from '@ulysses-inc/harami_api_client'
import { Button } from 'antd'
import React, { useRef } from 'react'
import { useDrop } from 'react-dnd'
import { shallowEqual, useSelector } from 'react-redux'
import { Primary } from 'src/features/theme/KdsThemeColor'
import { RootState } from 'src/state/store'
import styled from 'styled-components'
import { DragItem, MoveNodeEvent } from '../dragAndDrop/DnDTemplateType'

const ModifyButtonGroupWrap = styled.div`
  display: flex;
  margin: 10px;
  height: auto;
  overflow: initial;
`

const ModifyButton = styled(Button)`
  flex: 1;
`

const DraggingPlaceholder = styled.div`
  display: flex;
  height: 46px;
  line-height: 46px;
  width: 100%;
  background: ${Primary};
`

interface OwnProps {
  node: TemplateNodeSchema | null
  parentNode: TemplateNodeSchema | null
  addTemplateNode: (
    targetNode: TemplateNodeSchema | null,
    parentNode: TemplateNodeSchema | null,
    position: 'top' | 'bottom',
    addTemplateNodeType: TemplateNodeTypeEnum,
  ) => void
  moveNode: (event: MoveNodeEvent) => void
}

// 一つも質問がないセクション、ロジック配下に質問を移動するためのコンポーネント
// セクション、ロジック直下の「質問を追加・セクションを追加」ボタンに使用されている
// それ以外の「質問を追加・セクションを追加」ボタンは AddTemplateModifyButtonGroup が使用されている
// https://github.com/Ulysses-inc/harami_client/pull/2390
const AddTemplateModifyButtonGroupInEmpty: React.FC<OwnProps> = (
  props: OwnProps,
) => {
  const { layoutType } = useSelector(
    (state: RootState) => state.templatesState.editTemplate,
    shallowEqual,
  )

  const ref = useRef<HTMLDivElement>(null)

  const [{ isOver }, drop] = useDrop({
    accept: 'NODE',
    collect: monitor => ({
      isOver: monitor.isOver({ shallow: true }),
    }),
    drop: (dragingItem: DragItem) => {
      const dragNode = dragingItem.node

      if (!isOver) {
        return
      }

      const event: MoveNodeEvent = {
        dragNode: dragNode,
        dropNode: props.node,
        dropPosition: 'TOP',
        dragNodeParent: dragingItem.parentNode,
        dropNodeParent: props.parentNode,
      }
      props.moveNode(event)
    },
  })

  drop(ref)

  return (
    <div ref={ref}>
      {isOver && <DraggingPlaceholder />}
      <ModifyButtonGroupWrap>
        <ModifyButton
          type="dashed"
          onMouseDown={() => {
            props.addTemplateNode(
              props.node,
              props.parentNode,
              'top',
              TemplateNodeTypeEnum.Question,
            )
          }}
        >
          <PlusCircleOutlined />
          質問を追加
        </ModifyButton>
        {layoutType !== TemplateLayoutTypeEnum.Grid && (
          <ModifyButton
            type="dashed"
            onMouseDown={() => {
              props.addTemplateNode(
                props.node,
                props.parentNode,
                'top',
                TemplateNodeTypeEnum.Section,
              )
            }}
          >
            <DatabaseOutlined />
            セクションを追加
          </ModifyButton>
        )}
      </ModifyButtonGroupWrap>
    </div>
  )
}

export default AddTemplateModifyButtonGroupInEmpty
