import { DeleteOutlined, PlusCircleOutlined } from '@ant-design/icons'
import { Collapse, Input, Row, Select, Table, Typography } from 'antd'
import CheckSvg from 'src/assets/icons/check.svg?react'
import { Danger, Primary, White } from 'src/features/theme/KdsThemeColor'
import styled from 'styled-components'

const { Panel } = Collapse

const { Text } = Typography

export const BasicRow = styled(Row)`
  margin-top: 10px;
  margin-left: 20px;
  margin-right: 20px;
`

export const ToggleGroup = styled(Row)`
  margin-left: 20px;
  margin-right: 20px;
  display: flex;
  flex-wrap: wrap;
`

export const BasicRowHint = styled(BasicRow)`
  display: flex;
`

export const FormLabel = styled.div`
  margin-bottom: 10px;
  width: 100%;
`

export const NameInput = styled(Input)`
  max-width: 440px;
  width: 100%;
`

export const RequiedLabel = styled.div`
  font-size: 12px;
  margin-top: 6px;
  width: 100%;
`

export const ToggleWrap = styled.div`
  display: flex;
  margin-top: 10px;
  margin-right: 24px;
`

export const ApprovalFlowsSelect = styled(Select)`
  width: 500px;
`

export const TemplateHintTable = styled(Table)`
  .ant-table-thead {
    background-color: #fafafa;
  }
  .ant-table-body {
    background-color: ${White};
  }
`

export const ScoreSwitchWrap = styled.div`
  margin-right: 10px;
`

export const OptionRow = styled(Row)`
  margin-top: 20px;
  margin-bottom: 20px;
`

export const TableCollapse = styled(Collapse)`
  background-color: transparent;
  width: 600px;
`

export const TablePanel = styled(Panel)`
  border: 0;
`

export const ErrorMessageText = styled(Text)`
  color: red;
  font-weight: bold;
`

export const TemplateIcons = styled.div`
  display: flex;
  margin: 8px 0;
`

export const TemplateIconWrapper = styled.div`
  :not(:first-child) {
    margin-left: 24px;
  }
  cursor: pointer;
  position: relative;
  width: 40px;
  height: 40px;
  background-color: ${props => props.theme.backgroundColor};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const PlusIconWrapper = styled.div`
  margin-left: ${props => props.theme.marginLeft}px;
  background-color: ${White};
  width: 40px;
  height: 40px;
  border-radius: 50%;
`

export const PopoverIcons = styled.div`
  display: flex;
`

export const PlusIcon = styled(PlusCircleOutlined)`
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: ${Primary};
  width: 40px;
  height: 40px;
  font-size: 40px;
  background-color: ${White};
`

export const DeleteIcon = styled(DeleteOutlined)`
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  color: ${White};
  background-color: ${Danger};
  width: 42px;
  height: 42px;
  font-size: 24px;
`

export const CheckIcon = styled(CheckSvg)`
  cursor: pointer;
  top: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 15px;
  height: 15px;
  background-color: ${White};
`

export const ChooseLayout = styled.div`
  display: flex;
  flex-direction: column;
`
export const LayoutRadio = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
`

export const LayoutRadioArea = styled.div`
  margin-bottom: 8px;
`

export const LayoutDescription = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
`
