import {
  GridVariable,
  PlaceNode,
  PlaceNodeTypeEnum,
  Template,
} from '@ulysses-inc/harami_api_client'
import { FC, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { RowActionButton } from 'src/components/rowActionButton/RowActionButton'
import date from 'src/exShared/util/date'
import {
  ButtonGroupRow,
  ContentRow,
  TableBody,
  TableHeader,
  TableHeaderWrap,
  TableNameCol,
  TableRowWrap,
  TableUpdateAtCol,
  TemplateModifiedAtRow,
  TemplateNameLabel,
  TemplateNameRow,
  TemplatePlaceCol,
  TemplatePlaceNameRow,
} from './Templates.dumb'
import { useGridVariableTemplates } from './Templates.hooks'

const Table = () => {
  const {
    data: { templates },
  } = useGridVariableTemplates()

  return (
    <ContentRow justify="center">
      <TableHeaderWrap>
        <TableHeader>
          <TableNameCol>ひな形名</TableNameCol>
          <TemplatePlaceCol>現場名</TemplatePlaceCol>
          <TableUpdateAtCol>更新日時</TableUpdateAtCol>
        </TableHeader>
        <TableBody
          itemLayout="horizontal"
          dataSource={templates}
          renderItem={(template: Template) => <Row template={template} />}
        />
      </TableHeaderWrap>
    </ContentRow>
  )
}

/**
 * 各行の表示に必要なひな形情報
 */
interface TemplateRowData {
  id?: number
  name: string
  placeNodes?: Array<PlaceNode>
  gridVariable?: GridVariable
}

const Row: FC<{
  template: TemplateRowData
}> = ({ template }) => {
  const history = useHistory()

  const [isHovered, setIsHovered] = useState<boolean>(false)

  const editTemplatePages = () => {
    history.push(`/gridVariables/${template.id}/edit`)
  }

  const getPlaceNames = (placeNodes: PlaceNode[]) =>
    placeNodes
      .map(({ type, place, placeGroup }) =>
        type === PlaceNodeTypeEnum.PlaceGroup ? placeGroup?.name : place?.name,
      )
      .filter(name => name)
      .join(' ')

  return (
    <TableRowWrap
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <TemplateNameRow>
        <TemplateNameLabel>{template.name}</TemplateNameLabel>
      </TemplateNameRow>
      <TemplatePlaceNameRow>
        {getPlaceNames(template.placeNodes ?? [])}
      </TemplatePlaceNameRow>
      <TemplateModifiedAtRow>
        {!template.gridVariable?.updatedAt
          ? ''
          : date.formatYYYYMMDDHHMM(template.gridVariable.updatedAt)}
      </TemplateModifiedAtRow>
      {isHovered && (
        <ButtonGroupRow>
          <RowActionButton onClick={editTemplatePages} type="edit" />
        </ButtonGroupRow>
      )}
    </TableRowWrap>
  )
}

export default Table
