import { InfoCircleOutlined } from '@ant-design/icons'
import { CompanySettings } from '@ulysses-inc/harami_api_client'
import { Button, Layout, Switch, Tooltip, Typography } from 'antd'
import React, { ReactElement, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Header } from 'src/components/header/Header'
import { HeaderTitle } from 'src/components/header/HeaderTitle'
import Loading from 'src/components/loading/Loading'
import LoadingOverlay from 'src/components/loading/LoadingOverlay'
import {
  getCompanySettings,
  updateCompanySettings,
} from 'src/state/ducks/companySettings/actions'
import { RootState } from 'src/state/store'
import {
  ButtonWrap,
  CompanySettingsPageContent,
  FormLabel,
  FormRow,
  SwitchWrap,
  ToggleWrap,
} from './CompanySettings.dumb'

const { Text } = Typography

const CompanySettingsContainer: React.FC = () => {
  const dispatch = useDispatch()
  const [companySettings, setCompanySettings] = useState<CompanySettings>({})

  const companySettingsState = useSelector(
    (state: RootState) =>
      state.companySettingsState.companySettings.companySettings,
  )

  const isLoading = useSelector(
    (state: RootState) => state.companySettingsState.companySettings.isLoading,
  )

  const isUpdating = useSelector(
    (state: RootState) =>
      state.companySettingsState.updateCompanySettings.isLoading,
  )

  useEffect(() => {
    dispatch(getCompanySettings())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setCompanySettings(companySettingsState ?? {})
  }, [companySettingsState])

  const renderSwitch = (
    k: keyof CompanySettings,
    title: string | ReactElement,
  ) => (
    <FormRow>
      <ToggleWrap>
        <SwitchWrap>
          <Switch
            checked={companySettings[k] === 1}
            onChange={checked =>
              setCompanySettings({
                ...companySettings,
                [k]: checked ? 1 : 0,
              })
            }
          />
        </SwitchWrap>
        <div>{typeof title === 'string' ? <Text>{title}</Text> : title}</div>
      </ToggleWrap>
    </FormRow>
  )

  const onSave = () => dispatch(updateCompanySettings(companySettings))

  return (
    <LoadingOverlay
      tip="アプリ設定を更新中です"
      spinning={isUpdating}
      size="large"
    >
      <Layout>
        <Header>
          <HeaderTitle title="アプリ設定" />
        </Header>
        <CompanySettingsPageContent>
          {isLoading ? (
            <Loading />
          ) : (
            <>
              <FormRow>
                <FormLabel>
                  <Text>
                    アプリ内の本日実施分画面に表示する項目を選択してください
                  </Text>
                </FormLabel>
              </FormRow>
              {renderSwitch('appShowHomeQrcode', 'QRコードから記入する')}
              {renderSwitch('appShowHomeTemplate', 'ひな形から記入する')}
              {renderSwitch(
                'appShowHomeExpiredSchedule',
                <Tooltip
                  placement="right"
                  title="オフにした場合、終了時刻を超過したスケジュールが表示されなくなります。"
                >
                  <Text>
                    終了時刻を超過したスケジュール <InfoCircleOutlined />
                  </Text>
                </Tooltip>,
              )}
              <FormRow>
                <ButtonWrap>
                  <Button type="primary" onClick={onSave}>
                    保存
                  </Button>
                </ButtonWrap>
              </FormRow>
            </>
          )}
        </CompanySettingsPageContent>
      </Layout>
    </LoadingOverlay>
  )
}

export default CompanySettingsContainer
