import { css } from '@emotion/react'
import { Table as AntdTable, TableProps } from 'antd'
import { CustomEmptyText } from 'src/components/table/CustomEmptyText'

type Props<T> = TableProps<T> & {
  /**
   * オリジナルのantdのコンポーネントにはない、独自に拡張したオプション
   */
  kaminashiOption?: {
    disableRowHoverBackground?: boolean
    pointerOnRowHover?: boolean
  }
}

/**
 * AntdのTableコンポーネントにharami独自のスタイルを適用したコンポーネント
 */
export const Table = <T extends object>(props: Props<T>) => {
  const { kaminashiOption } = props
  const { disableRowHoverBackground = false, pointerOnRowHover = false } =
    kaminashiOption ?? {}

  const dynamicStyle = css`
    .ant-table {
      .ant-table-tbody {
        .ant-table-row {
          cursor: ${pointerOnRowHover ? 'pointer' : 'default'};

          td {
            background: ${disableRowHoverBackground ? 'white' : undefined};
          }
        }
      }
    }
  `

  return (
    <div css={[styles.container, dynamicStyle]}>
      <AntdTable
        {...props}
        locale={{
          ...props.locale,
          emptyText: <CustomEmptyText />,
        }}
      />
    </div>
  )
}

const styles = {
  // 🚨メンテナンスが困難になるため、antdのスタイル設定はテーマにより行うことを原則とし、以下への記載はできる限り避けること
  container: css`
    display: contents;

    .ant-table {
      // 列が潰れて見えなくなるのを防ぐ
      // - antdのTableのAPIが貧弱なので、このような方法で全体に一律で設定するほかない
      // - 列が存在することが視認できる程度に最低限の幅を確保している (これ以上狭く列を表示したいデザインニーズは存在しない前提の幅）
      colgroup col {
        min-width: 40px;
      }

      // ヘッダ・ボディの区別なくすべてのセルに適用するスタイル
      .ant-table-cell {
        // 最も左端のセルには24pxのpaddingを設定する
        // https://kaminashi-inc.slack.com/archives/C047X5HBRK5/p1699924496176469?thread_ts=1699511682.035829&cid=C047X5HBRK5
        &:first-of-type {
          padding-left: 24px !important; // !importantを付けて上書きするしか方法がない
        }
      }
    }
  `,
}
