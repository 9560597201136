import { StyleSheet, Text, View } from '@react-pdf/renderer'
import {
  ReportNodeSchema,
  ReportPageSchema,
  TemplateNodeTypeEnum,
} from '@ulysses-inc/harami_api_client'
import React from 'react'
import filterNodesAnsweredBranch from 'src/exShared/util/reportResult/filterNodesAnsweredBranch'
import isEmployeeCheckOriginalSection from 'src/exShared/util/reportResult/isEmployeeCheckOriginalSection'
import { isDefined } from 'src/util/idDefined'
import ReportSummaryQuestion from '../reportSummaryQuestion/ReportSummaryQuestion'
import ReportSummarySection from '../reportSummarySection/ReportSummarySection'

interface OwnProps {
  page: ReportPageSchema
  nodes: { [key: number]: ReportNodeSchema }
}

interface MergeProps {
  childNodes: Array<ReportNodeSchema>
  isFirstQuestionId: number
  isSectionPrevQuestionIds: Array<number>
}

type ReportSummaryPageProps = OwnProps & MergeProps

const ReportSummaryPageContainer: React.FC<OwnProps> = (_props: OwnProps) => {
  const pageNodes = _props.page.nodes ?? []
  const allNodes = _props.nodes
  const childNodes = filterNodesAnsweredBranch(
    pageNodes.map((id: number) => allNodes[id]).filter(isDefined),
    allNodes,
  ).filter(node => !isEmployeeCheckOriginalSection(node))

  const mergeProps: MergeProps = {
    childNodes,
    isFirstQuestionId:
      childNodes.find(
        (node: ReportNodeSchema) => node.type === TemplateNodeTypeEnum.Question,
      )?.id ?? 0,
    isSectionPrevQuestionIds: childNodes
      .filter(
        (_, index: number) =>
          childNodes[index - 1]?.type === TemplateNodeTypeEnum.Section,
      )
      .map((node: ReportNodeSchema) => node.id),
  }

  const props = { ..._props, ...mergeProps }
  return <ReportSummaryPage {...props} />
}

const styles = StyleSheet.create({
  pageName: {
    marginTop: 5,
    marginBottom: 5,
  },
})

const ReportSummaryPage: React.FC<ReportSummaryPageProps> = (
  props: ReportSummaryPageProps,
) => {
  return (
    <View>
      <View style={styles.pageName}>
        <Text>{props.page.name}</Text>
      </View>
      {props.childNodes.map((node: ReportNodeSchema) => {
        if (node.type === TemplateNodeTypeEnum.Section)
          return (
            <ReportSummarySection
              key={node.id}
              node={node}
              nodes={props.nodes}
              parentPath=""
            />
          )
        if (node.type === TemplateNodeTypeEnum.Question)
          return (
            <ReportSummaryQuestion
              key={node.id}
              node={node}
              nodes={props.nodes}
              isFirst={props.isFirstQuestionId === node.id}
              isSectionPrev={props.isSectionPrevQuestionIds.includes(node.id)}
            />
          )
        return null
      })}
    </View>
  )
}

export default ReportSummaryPageContainer
