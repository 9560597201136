import { ApprovalFlow } from '@ulysses-inc/harami_api_client'
import { ActionTypes } from './types'

export const getApprovalFlow = (approvalFlowId: string) =>
  ({
    type: ActionTypes.REQUEST_GET_APPROVAL_FLOW,
    isLoading: true,
    approvalFlowId,
  }) as const

const getSuccessApprovalFlow = (approvalFlow: ApprovalFlow) =>
  ({
    type: ActionTypes.SUCCESS_GET_APPROVAL_FLOW,
    isLoading: false,
    approvalFlow,
  }) as const

const getErrorApprovalFlow = (error: Error) =>
  ({
    type: ActionTypes.ERROR_GET_APPROVAL_FLOW,
    isLoading: false,
    error,
  }) as const

export type ApprovalFlowAction =
  | ReturnType<typeof getApprovalFlow>
  | ReturnType<typeof getSuccessApprovalFlow>
  | ReturnType<typeof getErrorApprovalFlow>

export const getApprovalFlows = () =>
  ({
    type: ActionTypes.REQUEST_GET_APPROVAL_FLOWS,
    isLoading: true,
  }) as const

const getSuccessApprovalFlows = (approvalFlows: ApprovalFlow[]) =>
  ({
    type: ActionTypes.SUCCESS_GET_APPROVAL_FLOWS,
    isLoading: false,
    approvalFlows,
  }) as const

const getErrorApprovalFlows = (error: Error) =>
  ({
    type: ActionTypes.ERROR_GET_APPROVAL_FLOWS,
    isLoading: false,
    error,
  }) as const

export type ApprovalFlowsAction =
  | ReturnType<typeof getApprovalFlows>
  | ReturnType<typeof getSuccessApprovalFlows>
  | ReturnType<typeof getErrorApprovalFlows>

export const addApprovalFlow = (approvalFlow: ApprovalFlow) =>
  ({
    type: ActionTypes.REQUEST_ADD_APPROVAL_FLOW,
    isLoading: true,
    approvalFlow,
  }) as const

const addSuccessApprovalFlow = () =>
  ({
    type: ActionTypes.SUCCESS_ADD_APPROVAL_FLOW,
    isLoading: false,
    isCompleted: true,
  }) as const

const addErrorApprovalFlow = (error: Error) =>
  ({
    type: ActionTypes.ERROR_ADD_APPROVAL_FLOW,
    isLoading: false,
    error,
  }) as const

export const resetApprovalFlowForAdd = () =>
  ({
    type: ActionTypes.RESET_ADD_APPROVAL_FLOW,
    isCompleted: false,
  }) as const

export type AddApprovalFlowAction =
  | ReturnType<typeof addApprovalFlow>
  | ReturnType<typeof addSuccessApprovalFlow>
  | ReturnType<typeof addErrorApprovalFlow>
  | ReturnType<typeof resetApprovalFlowForAdd>

export const updateApprovalFlow = (
  approvalFlowId: string,
  approvalFlow: ApprovalFlow,
) =>
  ({
    type: ActionTypes.REQUEST_UPDATE_APPROVAL_FLOW,
    isLoading: true,
    approvalFlow,
    approvalFlowId,
  }) as const

const updateSuccessApprovalFlow = () =>
  ({
    type: ActionTypes.SUCCESS_UPDATE_APPROVAL_FLOW,
    isLoading: false,
    isCompleted: true,
  }) as const

const updateErrorApprovalFlow = (error: Error) =>
  ({
    type: ActionTypes.ERROR_UPDATE_APPROVAL_FLOW,
    isLoading: false,
    error,
  }) as const

export const resetApprovalFlowForUpdate = () =>
  ({
    type: ActionTypes.RESET_UPDATE_APPROVAL_FLOW,
    isCompleted: false,
  }) as const

export type UpdateApprovalFlowAction =
  | ReturnType<typeof updateApprovalFlow>
  | ReturnType<typeof updateSuccessApprovalFlow>
  | ReturnType<typeof updateErrorApprovalFlow>
  | ReturnType<typeof resetApprovalFlowForUpdate>

export const deleteApprovalFlow = (approvalFlowId: string) =>
  ({
    type: ActionTypes.REQUEST_DELETE_APPROVAL_FLOW,
    isLoading: true,
    approvalFlowId,
  }) as const

const deleteSuccessApprovalFlow = () =>
  ({
    type: ActionTypes.SUCCESS_DELETE_APPROVAL_FLOW,
    isLoading: false,
  }) as const

const deleteErrorApprovalFlow = (error: Error) =>
  ({
    type: ActionTypes.ERROR_DELETE_APPROVAL_FLOW,
    isLoading: false,
    error,
  }) as const

export type DeleteApprovalFlowAction =
  | ReturnType<typeof deleteApprovalFlow>
  | ReturnType<typeof deleteSuccessApprovalFlow>
  | ReturnType<typeof deleteErrorApprovalFlow>

export default {
  getApprovalFlow,
  getSuccessApprovalFlow,
  getErrorApprovalFlow,
  getApprovalFlows,
  getSuccessApprovalFlows,
  getErrorApprovalFlows,
  addApprovalFlow,
  addSuccessApprovalFlow,
  addErrorApprovalFlow,
  resetApprovalFlowForAdd,
  updateApprovalFlow,
  updateSuccessApprovalFlow,
  updateErrorApprovalFlow,
  resetApprovalFlowForUpdate,
  deleteApprovalFlow,
  deleteSuccessApprovalFlow,
  deleteErrorApprovalFlow,
}
