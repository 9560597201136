import { ResponseDatetimeSubTypeEnum } from '@ulysses-inc/harami_api_client'
// https://github.com/import-js/eslint-plugin-import/issues/1479
import {
  addDays,
  endOfDay,
  endOfMonth,
  endOfWeek,
  format,
  isAfter,
  isBefore,
  isSameDay,
  parse,
  startOfMonth,
} from 'date-fns' // eslint-disable-line import/no-duplicates
// eslint-disable-next-line import/no-duplicates
import ja from 'date-fns/locale/ja'

export const getTimeDifferenceFromUTC = () =>
  // 2022-02-22 22:22:22 UTC
  (new Date('2022-02-22T22:22:22Z').valueOf() -
    // 2022-02-22 22:22:22 JST = 2022-02-22 13:22:22 UTC
    // 2022-02-22 22:22:22 NPT = 2022-02-22 16:37:22 UTC
    new Date('2022-02-22T22:22:22').valueOf()) /
  1000

let fakeDate: Date | null = null
export const setFakeDate = (date: Date) => {
  fakeDate = date
}

export const resetFakeDate = () => {
  fakeDate = null
}

const getFakeDate = () => fakeDate ?? new Date()

const date = {
  formatMM_JP: (date?: Date): string => format(date ?? getFakeDate(), 'MM月'),
  formatYYYYMM_JP: (date?: Date): string =>
    format(date ?? getFakeDate(), 'yyyy年MM月'),
  formatYYYYMMDD: (date?: Date): string =>
    format(date ?? getFakeDate(), 'yyyy/MM/dd'),
  formatYYYYMMDD_locale: (date?: Date): string =>
    format(date ?? getFakeDate(), 'yyyy/MM/dd', { locale: ja }),
  formatYYYYMMDD_hyphen: (date?: Date): string =>
    format(date ?? getFakeDate(), 'yyyy-MM-dd'),
  formatYYYYMMDD_hyphen_locale: (date?: Date): string =>
    format(date ?? getFakeDate(), 'yyyy-MM-dd', { locale: ja }),
  formatYYYYMMDD_JP: (date?: Date): string =>
    format(date ?? getFakeDate(), 'yyyy年MM月dd日'),
  formatYYYYMMDDHH_TimeStamp_UTC: (date?: Date): string => {
    // NOTE formatでUTC時刻が得られなかったため関数を使わずに実装
    const newDate = date ?? getFakeDate()
    const utcYear = newDate.getUTCFullYear()
    const utcMonth = String(newDate.getUTCMonth() + 1).padStart(2, '0')
    const utcDate = String(newDate.getUTCDate()).padStart(2, '0')
    const utcHour = String(newDate.getUTCHours()).padStart(2, '0')

    return `${utcYear}${utcMonth}${utcDate}${utcHour}`
  },
  formatYYYYMMDDHHMM: (date?: Date): string =>
    format(date ?? getFakeDate(), 'yyyy/MM/dd HH:mm'),
  formatYYYYMMDDHHMM_JP: (date?: Date): string =>
    format(date ?? getFakeDate(), 'yyyy年MM月dd日 HH時mm分'),
  formatYYYYMMDDHHMMSS_JP: (date?: Date): string =>
    format(date ?? getFakeDate(), 'yyyy年MM月dd日 HH時mm分ss秒'),
  formatMMDD_JP: (date?: Date): string =>
    format(date ?? getFakeDate(), 'MM月dd日'),
  formatHHMM: (date?: Date): string => format(date ?? getFakeDate(), 'HH:mm'),
  formatHHMM_JP: (date?: Date): string =>
    format(date ?? getFakeDate(), 'HH時mm分'),
  formatYYYYMMDDEEE: (date?: Date): string =>
    format(date ?? getFakeDate(), 'yyyy/MM/dd (eee)', { locale: ja }),
  formatDayOfMonth_locale: (date?: Date): string =>
    format(date ?? getFakeDate(), 'dd', { locale: ja }),
  formatISODayOfWeek: (date?: Date): string =>
    format(date ?? getFakeDate(), 'iii'),
  formatISODayOfWeek_locale: (date?: Date): string =>
    format(date ?? getFakeDate(), 'iii', { locale: ja }),
  formatMMDDHHMM: (date?: Date): string =>
    format(date ?? getFakeDate(), 'MM/dd HH:mm'),
  today: (): Date => getFakeDate(),
  yesterday: (): Date => addDays(getFakeDate(), -1),
  parseYYYYMMDD: (strDate: string): Date => {
    return parse(strDate, 'yyyy-MM-dd', getFakeDate())
  },
  /** @deprecated date-fns で十分 */
  nextDay: (date: Date): Date => {
    return addDays(date, 1)
  },
  /** @deprecated date-fns で十分 */
  addDays: (date: Date, amount: number): Date => {
    return addDays(date, amount)
  },
  isWithinRange: (date: Date, startDate: Date, endDate: Date): boolean => {
    return isAfter(date, startDate) && isBefore(date, endDate)
  },
  /** @deprecated date-fns で十分 */
  startOfMonth: (date: Date): Date => {
    return startOfMonth(date)
  },
  /** @deprecated date-fns で十分 */
  endOfDay: (date: Date): Date => {
    return endOfDay(date)
  },
  /** @deprecated date-fns で十分 */
  endOfMonth: (date: Date): Date => {
    return endOfMonth(date)
  },
  /** @deprecated date-fns で十分 */
  endOfWeek: (date: Date): Date => {
    return endOfWeek(date)
  },
  /** @deprecated date-fns で十分 */
  isBefore: (date1: Date, date2: Date): boolean => {
    return isBefore(date1, date2)
  },
  isBeforeNow: (date: Date): boolean => {
    return isBefore(date, getFakeDate())
  },
  /** @deprecated date-fns で十分 */
  isAfter: (date1: Date, date2: Date): boolean => {
    return isAfter(date1, date2)
  },
  isAfterNow: (date: Date): boolean => {
    return isAfter(date, getFakeDate())
  },
  /** @deprecated date-fns で十分 */
  isSameDay: (date1: Date, date2: Date): boolean => isSameDay(date1, date2),
  isToday: (date: Date): boolean => {
    const today = getFakeDate()
    return isSameDay(date, today)
  },
}

// 引数のrecordedAtはAPIから返ってきた値の場合stringになる
export const getFormattedRecordedAt = (recordedAt?: Date | string): string => {
  if (!recordedAt) {
    return ''
  }

  const validDate = new Date(recordedAt)

  if (validDate instanceof Date && Number.isNaN(validDate.valueOf())) {
    return ''
  }

  return date.formatYYYYMMDDHHMM_JP(validDate)
}

export const getFormattedResponseDatetimeSubType = (
  inputDate: Date,
  subType: ResponseDatetimeSubTypeEnum,
): string => {
  switch (subType) {
    case ResponseDatetimeSubTypeEnum.DATETIME:
      return date.formatYYYYMMDDHHMM_JP(inputDate)
    case ResponseDatetimeSubTypeEnum.DATE:
      return date.formatYYYYMMDD_JP(inputDate)
    case ResponseDatetimeSubTypeEnum.TIME:
      return date.formatHHMM_JP(inputDate)
    default:
      return ''
  }
}

// NOTE: IoT 用に一時的に追加
// 検証時点では影響を与えないよう、別で実装している
// getFormattedRecordedAt に ResponseDatetimeSubTypeEnum などを追加するのが望ましい
export const getFormattedRecordedAtWithIoT = (
  recordedAt?: Date | string,
): string => {
  if (!recordedAt) {
    return ''
  }

  const validDate = new Date(recordedAt)

  if (validDate instanceof Date && Number.isNaN(validDate.valueOf())) {
    return ''
  }

  // NOTE: 表示内容を検証中のため、コメントアウトしている
  // FB 次第で最終的な表示フォーマットを決定予定
  // return date.formatYYYYMMDDHHMM(validDate)
  return date.formatMMDDHHMM(validDate)
  // return date.formatHHMM(validDate)
}

export default date
