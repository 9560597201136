import { localStorageKeys } from 'src/constants/localStorageKeys'
import { useLocalStorage } from 'src/hooks/useLocalStorage'

export const useShinReportPreference = () => {
  const [preferShinReport, setPreferShinReport] = useLocalStorage(
    localStorageKeys.preferShinReportList20240321,
    true,
  )
  return [preferShinReport, setPreferShinReport] as const
}
