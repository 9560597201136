import { DeleteOutlined, PlusCircleOutlined } from '@ant-design/icons'
import { TemplateManual } from '@ulysses-inc/harami_api_client'
import { Button, Table } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { ClampText } from 'src/components/clampText/ClampText'
import { TableBorderColor, White } from 'src/features/theme/KdsThemeColor'
import {
  addTemplateManual as addTemplateManualAction,
  deleteTemplateManual as deleteTemplateManualAction,
  updateTemplateManual as updateTemplateManualAction,
} from 'src/state/ducks/templates/editTemplate/actions'
import { RootState } from 'src/state/store'
import DeleteConfirmDialog from 'src/views/components/dialogs/DeleteConfirmDialog'
import styled from 'styled-components'
import EditManualBlocks from './EditManualBlocks'

const TemplateManualTable = styled(Table)`
  .ant-table-thead > tr > th {
    background-color: ${White};
    border-bottom: 1px solid ${TableBorderColor};
  }
  .ant-table-body {
    background-color: ${White};
  }
`

const EditManualsTable = () => {
  const dispatch = useDispatch()

  const templateManuals = useSelector(
    (state: RootState) => state.templatesState.editTemplate.manuals,
  )
  const someTemplateManualExists = templateManuals.length !== 0

  const dataSource = templateManuals.map((manual: TemplateManual) => {
    return {
      key: `${manual.id}`,
      name: (
        <ClampText
          editable={{
            onChange: (text: string) => {
              dispatch(updateTemplateManualAction({ ...manual, name: text }))
            },
          }}
          data-testid="manual-name-cell"
        >
          {manual.name}
        </ClampText>
      ),
      edit: <EditManualBlocks manual={manual} />,
      delete: (
        <Button
          danger
          onClick={() => {
            DeleteConfirmDialog({
              title: 'マニュアルを削除してもよろしいですか？',
              onOk() {
                dispatch(deleteTemplateManualAction(manual))
              },
            })
          }}
          type="primary"
          icon={<DeleteOutlined />}
        />
      ),
    }
  })

  return (
    <>
      <Button
        onClick={() => {
          dispatch(addTemplateManualAction())
        }}
        type="link"
      >
        <PlusCircleOutlined />
        マニュアル列を追加
      </Button>
      {someTemplateManualExists && (
        <TemplateManualTable
          columns={[
            {
              title: 'マニュアル名',
              dataIndex: 'name',
              key: 'name',
              width: '60%',
            },
            {
              title: '内容の編集',
              dataIndex: 'edit',
              key: 'edit',
              width: '30%',
            },
            {
              title: '削除',
              dataIndex: 'delete',
              key: 'delete',
              width: '10%',
            },
          ]}
          dataSource={dataSource}
          pagination={false}
        />
      )}
    </>
  )
}

export default EditManualsTable
