import { Template } from '@ulysses-inc/harami_api_client'
import { ActionTypes } from '../types'
import { ReportsTemplatesAction } from './actions'

interface ReportsTemplatesState {
  templates: Template[]
  isLoading: boolean
  error: Error | null
}

const initialReportsTemplatesState: ReportsTemplatesState = {
  templates: [],
  isLoading: false,
  error: null,
}

// レポート一覧画面でひな形一覧の取得結果を保持する
const reportsTemplates = (
  state: ReportsTemplatesState = initialReportsTemplatesState,
  action: ReportsTemplatesAction,
) => {
  switch (action.type) {
    case ActionTypes.REQUEST_GET_REPORTS_TEMPLATES: {
      return {
        ...state,
        templates: [],
        isLoading: true,
        error: null,
      }
    }
    case ActionTypes.SUCCESS_GET_REPORTS_TEMPLATES: {
      return {
        ...state,
        isLoading: false,
        templates: action.templates,
      }
    }
    case ActionTypes.ERROR_GET_REPORTS_TEMPLATES: {
      return {
        ...state,
        isLoading: false,
        error: action.error,
      }
    }
    case ActionTypes.RESET_REPORTS_TEMPLATES: {
      return { ...state, ...initialReportsTemplatesState }
    }
    default: {
      return { ...state }
    }
  }
}

export default reportsTemplates
