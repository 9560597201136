import {
  ReportNodeSchema,
  TemplateNodeTypeEnum,
} from '@ulysses-inc/harami_api_client'

const getLogicNodes = (
  parentNodeIds: number[],
  allNodes: { [key: number]: ReportNodeSchema },
): ReportNodeSchema[] => {
  let retNodes: ReportNodeSchema[] = []
  parentNodeIds.forEach(nodeId => {
    const node = allNodes[nodeId]
    if (!node) return

    const childNodes = node.nodes
    if (childNodes.length === 0) return

    retNodes = retNodes.concat(getLogicNodes(childNodes, allNodes))

    childNodes.forEach(childNodeId => {
      const childNode = allNodes[childNodeId]
      if (
        childNode &&
        childNode.type === TemplateNodeTypeEnum.Logic &&
        childNode.logic
      ) {
        retNodes = [...retNodes, childNode]
      }
    })

    if (node.type === TemplateNodeTypeEnum.Logic && node.logic) {
      retNodes = [...retNodes, node]
    }
  })

  return retNodes
}

export default getLogicNodes
