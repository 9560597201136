import { HomeOutlined } from '@ant-design/icons'
import { Dispatch, FC, SetStateAction } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import reportsOperations from 'src/state/ducks/reports/operations'
import { GetReportsFilter } from 'src/state/ducks/reports/types'
import { RootState } from 'src/state/store'
import { FilterRow, PlaceGroupTreeModalButton } from './Reports.styled'
import ReportsFilterDropDown from './ReportsFilterDropDown'

const ReportsFilter: FC<{
  reportGetTrigger: boolean
  setIsVisiblePlaceModal: Dispatch<SetStateAction<boolean>>
  selectedReportIds: Set<number>
}> = ({ reportGetTrigger, setIsVisiblePlaceModal, selectedReportIds }) => {
  const request = useSelector(
    (state: RootState) => state.reportsState.reports.request,
  )

  const dispatch = useDispatch()
  const getReports = (filter?: GetReportsFilter) =>
    reportsOperations.getReports(dispatch, request, filter)

  return (
    <FilterRow justify="space-between">
      <ReportsFilterDropDown
        {...{
          reportGetTrigger,
          visibleStatusFilter: true,
          visibleEmployeeFilter: true,
          scene: 'reports',
          getReports,
        }}
      />
      <PlaceGroupTreeModalButton
        disabled={selectedReportIds.size === 0}
        onClick={() => {
          setIsVisiblePlaceModal(true)
        }}
        type="primary"
      >
        <HomeOutlined />
        現場の付け替え
      </PlaceGroupTreeModalButton>
    </FilterRow>
  )
}

export default ReportsFilter
