import { css } from '@emotion/react'
import { IndicatedIssueReviseImageStageEnum } from '@ulysses-inc/harami_api_client'
import React from 'react'
import {
  ReviseFile,
  ReviseFileList,
} from 'src/features/improves/improveDetail/listItem/revisePicturesWall/RevisePicturesWall'
import { Thumb } from 'src/features/improves/improveDetail/listItem/revisePicturesWall/Thumb'
import { White } from 'src/features/theme/KdsThemeColor'

type Props = {
  fileList: ReviseFileList
  disabled: boolean
  handlePreview: (index: number) => void
  handleRemove: (file: ReviseFile) => void
}

export const ThumbList: React.FC<Props> = ({
  fileList,
  disabled,
  handlePreview,
  handleRemove,
}) => {
  if (!fileList.length) {
    return null
  }

  const handlePreviewClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index = 0,
  ) => {
    event.preventDefault()
    event.stopPropagation()
    handlePreview(index)
  }

  const handleRemoveClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    file: ReviseFile,
  ) => {
    event.preventDefault()
    event.stopPropagation()
    handleRemove(file)
  }

  const getColorByStage = (stage: IndicatedIssueReviseImageStageEnum) => {
    switch (stage) {
      case IndicatedIssueReviseImageStageEnum.Latest: {
        return 'blue'
      }
      case IndicatedIssueReviseImageStageEnum.LastTime: {
        return 'red'
      }
      default: {
        return 'none'
      }
    }
  }

  return (
    <div css={styles.container}>
      <div css={styles.inner}>
        {fileList.map((file, index) => {
          const isLatest =
            file.stage === IndicatedIssueReviseImageStageEnum.Latest

          return (
            <Thumb
              disabled={disabled || !isLatest}
              key={file.file.uid}
              imgBorderColor={getColorByStage(file.stage)}
              imgProps={{
                src: file.file.url,
                alt: file.file.name,
              }}
              isLoading={!file.file.url}
              onClickPreview={e => handlePreviewClick(e, index)}
              onClickRemove={e => handleRemoveClick(e, file)}
            />
          )
        })}
      </div>
    </div>
  )
}

const styles = {
  container: css`
    position: relative;
    display: flex;
    align-items: flex-start;
    height: calc(100% + 2px);
    background-color: ${White};
    z-index: 2;
    margin: -1px;
  `,
  inner: css`
    flex: 1 1 auto;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 8px 0 12px 4px;
  `,
}
