// eslint-disable-next-line import/no-duplicates
import { format, getYear, isToday } from 'date-fns'
// eslint-disable-next-line import/no-duplicates
import { ja } from 'date-fns/locale'

export const toFriendlyDateString = (targetDate: Date): string => {
  if (isToday(targetDate)) {
    return '今日'
  }

  const now = new Date()
  const thisYear = getYear(now)
  const targetYear = getYear(targetDate)

  // 今年かどうか判断し、フォーマットを変更
  const dateFormat = targetYear === thisYear ? 'M/d' : 'yyyy/M/d'

  // 曜日
  const dayOfWeekFormat = '(EEE)'

  return format(targetDate, dateFormat + dayOfWeekFormat, { locale: ja })
}
