import { ScheduleListInRangeItem } from '@ulysses-inc/harami_api_client'
import { ActionTypes, GetScheduleListInRangeItems } from './types'

export const getScheduleListInRangeItems = (
  request: GetScheduleListInRangeItems,
  locale: string,
) => {
  return {
    type: ActionTypes.REQUEST_GET_SCHEDULE_LIST_IN_RANGE_ITEMS,
    isLoading: true,
    request,
    locale,
  }
}

const getSuccessScheduleListInRangeItems = (
  scheduleListInRangeItems: ScheduleListInRangeItem[],
  startDate: Date,
  endDate: Date,
) => {
  return {
    type: ActionTypes.SUCCESS_GET_SCHEDULE_LIST_IN_RANGE_ITEMS,
    isLoading: false,
    scheduleListInRangeItems,
    startDate,
    endDate,
  }
}

const getErrorScheduleListInRangeItems = (error: Error) => {
  return {
    type: ActionTypes.ERROR_GET_SCHEDULE_LIST_IN_RANGE_ITEMS,
    isLoading: false,
    error,
  }
}

export type ScheduleListInRangeItemsAction =
  | ReturnType<typeof getScheduleListInRangeItems>
  | ReturnType<typeof getSuccessScheduleListInRangeItems>
  | ReturnType<typeof getErrorScheduleListInRangeItems>

const getPlaceScheduleListInRangeItems = (
  request: GetScheduleListInRangeItems,
  locale: string,
) => {
  return {
    type: ActionTypes.REQUEST_GET_PLACE_SCHEDULE_LIST_IN_RANGE_ITEMS,
    isLoading: true,
    request,
    locale,
  }
}

const getSuccessPlaceScheduleListInRangeItems = (
  scheduleListInRangeItems: ScheduleListInRangeItem[],
  targetDate: Date,
) => {
  return {
    type: ActionTypes.SUCCESS_GET_PLACE_SCHEDULE_LIST_IN_RANGE_ITEMS,
    isLoading: false,
    scheduleListInRangeItems,
    targetDate,
  }
}

const getErrorPlaceScheduleListInRangeItems = (error: Error) => {
  return {
    type: ActionTypes.ERROR_GET_PLACE_SCHEDULE_LIST_IN_RANGE_ITEMS,
    isLoading: false,
    error,
  }
}

export const resetPlaceScheduleListInRangeItems = () => {
  return {
    type: ActionTypes.RESET_PLACE_SCHEDULE_LIST_IN_RANGE_ITEMS,
  }
}

export type PlaceScheduleListInRangeItemsAction =
  | ReturnType<typeof getPlaceScheduleListInRangeItems>
  | ReturnType<typeof getSuccessPlaceScheduleListInRangeItems>
  | ReturnType<typeof getErrorPlaceScheduleListInRangeItems>
  | ReturnType<typeof resetPlaceScheduleListInRangeItems>

export const getInvalidCountScheduleListInRangeItems = (
  request: GetScheduleListInRangeItems,
  locale: string,
) =>
  ({
    type: ActionTypes.REQUEST_GET_INVALID_COUNT_SCHEDULE_LIST_IN_RANGE_ITEMS,
    isLoading: true,
    request,
    locale,
  }) as const

const getSuccessInvalidCountScheduleListInRangeItems = (
  scheduleListInRangeItems: ScheduleListInRangeItem[],
  startDate: Date,
  endDate: Date,
) =>
  ({
    type: ActionTypes.SUCCESS_GET_INVALID_COUNT_SCHEDULE_LIST_IN_RANGE_ITEMS,
    isLoading: false,
    scheduleListInRangeItems,
    startDate,
    endDate,
  }) as const

const getErrorInvalidCountScheduleListInRangeItems = (error: Error) =>
  ({
    type: ActionTypes.ERROR_GET_INVALID_COUNT_SCHEDULE_LIST_IN_RANGE_ITEMS,
    isLoading: false,
    error,
  }) as const

const resetInvalidCountScheduleListInRangeItems = () =>
  ({
    type: ActionTypes.RESET_INVALID_COUNT_SCHEDULE_LIST_IN_RANGE_ITEMS,
  }) as const

export type InvalidCountScheduleListInRangeItemsAction =
  | ReturnType<typeof getInvalidCountScheduleListInRangeItems>
  | ReturnType<typeof getSuccessInvalidCountScheduleListInRangeItems>
  | ReturnType<typeof getErrorInvalidCountScheduleListInRangeItems>
  | ReturnType<typeof resetInvalidCountScheduleListInRangeItems>

const getMonthlyPlaceScheduleListInRangeItems = (
  request: GetScheduleListInRangeItems,
  locale: string,
) => {
  return {
    type: ActionTypes.REQUEST_GET_MONTHLY_PLACE_SCHEDULE_LIST_IN_RANGE_ITEMS,
    isLoading: true,
    request,
    locale,
  }
}

const getSuccessMonthlyPlaceScheduleListInRangeItems = (
  scheduleListInRangeItems: ScheduleListInRangeItem[],
) => {
  return {
    type: ActionTypes.SUCCESS_GET_MONTHLY_PLACE_SCHEDULE_LIST_IN_RANGE_ITEMS,
    isLoading: false,
    scheduleListInRangeItems: scheduleListInRangeItems,
  }
}

const getErrorMonthlyPlaceScheduleListInRangeItems = (error: Error) => {
  return {
    type: ActionTypes.ERROR_GET_MONTHLY_PLACE_SCHEDULE_LIST_IN_RANGE_ITEMS,
    isLoading: false,
    error,
  }
}

export type MonthlyPlaceScheduleListInRangeItemsAction =
  | ReturnType<typeof getMonthlyPlaceScheduleListInRangeItems>
  | ReturnType<typeof getSuccessMonthlyPlaceScheduleListInRangeItems>
  | ReturnType<typeof getErrorMonthlyPlaceScheduleListInRangeItems>

export default {
  getScheduleListInRangeItems,
  getSuccessScheduleListInRangeItems,
  getErrorScheduleListInRangeItems,
  getPlaceScheduleListInRangeItems,
  getSuccessPlaceScheduleListInRangeItems,
  getErrorPlaceScheduleListInRangeItems,
  resetPlaceScheduleListInRangeItems,
  getMonthlyPlaceScheduleListInRangeItems,
  getSuccessMonthlyPlaceScheduleListInRangeItems,
  getErrorMonthlyPlaceScheduleListInRangeItems,
  getInvalidCountScheduleListInRangeItems,
  getSuccessInvalidCountScheduleListInRangeItems,
  getErrorInvalidCountScheduleListInRangeItems,
  resetInvalidCountScheduleListInRangeItems,
}
