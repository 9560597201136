import { Layout } from 'antd'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Header } from 'src/components/header/Header'
import { HeaderTitle } from 'src/components/header/HeaderTitle'
import Loading from 'src/components/loading/Loading'
import { GetTemplatesFilter } from 'src/exShared/types/types'
import { flattenNodePath } from 'src/exShared/util/place/flattenNodePath'
import { getUniqueFlattenNodes } from 'src/exShared/util/place/getUniqueFlattenNodes'
import { flattenNodes } from 'src/exShared/util/place/placeNode'
import { getFilter } from 'src/exShared/util/template/getFilter'
import { GlobalNavigation } from 'src/features/globalNavigation/GlobalNavigation'
import { useListFilter } from 'src/hooks/filter/useListFilter'
import placesOperations from 'src/state/ducks/places/operations'
import { RootState } from 'src/state/store'
import { adjustCurrentPage } from 'src/util/adjustCurrentPage'
import Table from './Table'
import { ActionRow, TemplatesPagination } from './Templates.dumb'
import { useGridVariableTemplates } from './Templates.hooks'
import TemplatesFilterDropDown from './TemplatesFilterDropDown'

const { Content } = Layout

const DEFAULT_PAGING_LIMIT = 25

const Templates = () => {
  const dispatch = useDispatch()
  const [templateGetTrigger, setTemplateGetTrigger] = useState<boolean>(false)

  const places = useSelector(
    (state: RootState) => state.placesState.places.places,
  )
  const isLoadingPlaces = useSelector(
    (state: RootState) => state.placesState.places.isLoading,
  )
  const placeGroups = useSelector(
    (state: RootState) => state.placesState.placeGroups.nodes,
  )
  const isLoadingPlaceGroups = useSelector(
    (state: RootState) => state.placesState.placeGroups.isLoading,
  )

  const isLoadingPlace = isLoadingPlaces && isLoadingPlaceGroups
  const placeNodes = getUniqueFlattenNodes([
    ...flattenNodes(placeGroups),
    ...places,
  ])
  const flattenedNodePath = flattenNodePath([...placeNodes, ...placeGroups], [])

  const {
    data: { count, request },
    isLoading,
    methods: {
      updatePaginationWithPage,
      changeTemplatesSize,
      updatePagination,
      resetPagination,
      updateFilter,
    },
  } = useGridVariableTemplates(true)

  const {
    // localstorageから読み出したページネーション設定
    filters,
    setListFilter,
  } = useListFilter()

  useEffect(() => {
    placesOperations.getPlaces(dispatch, {})
    placesOperations.getPlaceGroups(dispatch)
    setTemplateGetTrigger(!templateGetTrigger)

    const gridVariablesFilter = filters?.gridVariables

    updatePagination(
      gridVariablesFilter?.pagination?.limit ?? DEFAULT_PAGING_LIMIT,
      gridVariablesFilter?.pagination?.offset ?? 0,
    )

    // 初回マウント時（初期表示時）に、localStorageからページネーションの情報を
    // 読み出すための処理であるため、依存配列は空でOK
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    // ユーザのUI操作によりstoreのページネーション設定に変更があった場合は、
    // localstorageに保存しておく。
    setListFilter({
      ...filters,
      gridVariables: {
        pagination: request,
        filter: filters?.gridVariables?.filter,
      },
    })

    // やりたいことは、ページネーションのためのlimit, offsetに変更があったら
    // localStorageに保存することなので、
    // 現状、依存配列の情報はlimit, offsetの２つのみでOK
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [request.limit, request.offset])

  const initFilter = {
    templateName: filters?.gridVariables?.filter?.templateName,
    placeNodeIds: filters?.gridVariables?.filter?.placeNodeIds,
    placeGroupNodeIds: filters?.gridVariables?.filter?.placeGroupNodeIds,
  }

  const updateTemplatesFilter = (
    getTemplatesFilter: GetTemplatesFilter | undefined,
  ) => {
    const templateFilter = templateGetTrigger
      ? getTemplatesFilter
      : getFilter(placeNodes, flattenedNodePath, initFilter)
    updateFilter(templateFilter ?? {})
  }

  // 現在のページを調整する
  const currentPage = adjustCurrentPage(request.limit, request.offset, count)

  return (
    // TODO: サイドメニュー描写の責務を`Routes.tsx`に移植する
    <GlobalNavigation
      // サイドバーのメニューをクリックして他画面へ遷移した時に発動する。
      // `resetPagination`ではなく`onClickMenuItem`くらいが正しい名前と思われる。
      resetPagination={() => {
        // デフォルトのページネーション変更 -> 別画面に遷移 -> 再び取り込み一覧表示した際に、
        // APIリクエストが２重で実行されないようにリセットする
        resetPagination()
        // ページネーション設定をlocalstorageに保存しておく。その際、
        // - ページネーション設定（ページあたりの表示上限件数と、オフセット）は初期値にリセットしておく
        setListFilter({
          ...filters,
          gridVariables: {
            pagination: { limit: DEFAULT_PAGING_LIMIT, offset: 0 },
            filter: filters?.gridVariables?.filter,
          },
        })
      }}
    >
      <Header>
        <HeaderTitle title="取り込み項目" />
      </Header>
      <Layout className="layout">
        <Content>
          <ActionRow>
            <TemplatesFilterDropDown
              places={places}
              placeNodes={placeNodes}
              updateTemplatesFilter={updateTemplatesFilter}
              flattenNodePath={flattenedNodePath}
              isLoadingPlace={isLoadingPlace}
              templateGetTrigger={templateGetTrigger}
              request={request}
            />
          </ActionRow>
          {isLoading ? <Loading /> : <Table />}
        </Content>
        <TemplatesPagination
          showSizeChanger
          current={currentPage}
          pageSizeOptions={['10', '25', '50', '100']}
          pageSize={request.limit ?? DEFAULT_PAGING_LIMIT}
          defaultCurrent={1}
          total={count}
          onChange={updatePaginationWithPage}
          onShowSizeChange={(_, size) => changeTemplatesSize(size)}
        />
      </Layout>
    </GlobalNavigation>
  )
}

export default Templates
