import { PlusCircleOutlined } from '@ant-design/icons'
import {
  ResponseFormula,
  ResponseFormulaToken,
  ResponseFormulaTokenOperatorTypeEnum,
  ResponseFormulaTokenTypeEnum,
  ResponseTypeEnum,
  TemplateNodeSchema,
  TemplateNodeTypeEnum,
} from '@ulysses-inc/harami_api_client'
import { Button, Modal, Typography } from 'antd'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { EditResponseFormulaDrawerBlock } from 'src/features/templateEdit/editResponseFormula/EditResponseFormulaDrawerBlock'
import { isExistsNode } from 'src/features/templateEdit/editResponseFormula/utils'
import { Danger } from 'src/features/theme/KdsThemeColor'
import { updateTemplateNode } from 'src/state/ducks/templates/actions'
import { RootState } from 'src/state/store'
import styled from 'styled-components'

interface Props {
  /**
   * 編集対象の質問ノード
   */
  questionNode: TemplateNodeSchema
  /**
   * 編集対象の質問ノードの親ノード。ページ直下にある（親ノードがない）場合はnull。
   */
  parentNode: TemplateNodeSchema | null
  /**
   * 編集対象の質問ノードに設定されている計算式 (編集前の状態)
   */
  responseFormula?: ResponseFormula
  onClose: () => void
}

// モーダル上で計算式を編集する際のブロック1つ分を表現したもの
export type FormulaBlock = {
  // 1番目のブロックはオペレーターを持たない。2番目以降のブロックはオペレーターを持つ。
  operator?: ResponseFormulaToken
  // 質問or定数の別。オプショナルになっているが実のところ必須のはず。
  questionOrConstant?: ResponseFormulaToken
}

const { Text } = Typography

const AddButton = styled(Button)`
  width: 100%;
  margin-bottom: 8px;
`

const ErrorText = styled(Text)`
  color: ${Danger};
`

const validate = (
  tokens: ResponseFormulaToken[],
  numberNodes: TemplateNodeSchema[],
): string => {
  if (tokens.length < 2) {
    return '式は2つ以上設定する必要があります'
  }
  if (
    tokens.filter(t => t.type === ResponseFormulaTokenTypeEnum.QUESTION)
      .length === 0
  ) {
    return '質問を1つ以上設定する必要があります'
  }
  for (const token of tokens) {
    switch (token.type) {
      case ResponseFormulaTokenTypeEnum.QUESTION: {
        if (!token.questionNodeUUID) {
          return '設定値が不正です'
        }
        if (!isExistsNode(numberNodes, token.questionNodeUUID)) {
          return '設定値が不正です'
        }
        break
      }
      case ResponseFormulaTokenTypeEnum.CONSTANT: {
        if (token.constant === undefined || token.constant === null) {
          return '設定値が不正です'
        }
      }
    }
  }
  return ''
}

const EditResponseFormulaDrawer = (props: Props) => {
  const dispatch = useDispatch()

  const { onClose, parentNode, questionNode, responseFormula } = props

  const { activePageId, templatePages, templateNodes } = useSelector(
    (state: RootState) => state.templatesState.templatePages,
  )

  const [blocks, setBlocks] = useState<FormulaBlock[]>([{}])
  const [errorMessage, setErrorMessage] = useState<string>('')

  const getNumberNodes = () => {
    if (parentNode) {
      const parentSectionNodes = parentNode.nodes
      return Object.values(templateNodes)
        .filter(node => parentSectionNodes.includes(node.id))
        .filter(
          node =>
            node.type === TemplateNodeTypeEnum.Question &&
            node.question &&
            node.question.responseType === ResponseTypeEnum.NUMBER,
        )
    } else {
      const activePage = templatePages[activePageId]
      const activePageNodes = activePage?.nodes
      return Object.values(templateNodes)
        .filter(node => activePageNodes?.includes(node.id))
        .filter(
          node =>
            node.type === TemplateNodeTypeEnum.Question &&
            node.question &&
            node.question.responseType === ResponseTypeEnum.NUMBER,
        )
    }
  }
  const numberNodes = getNumberNodes()

  useEffect(() => {
    if (responseFormula?.tokens) {
      const createBlocks: FormulaBlock[] = []
      let operator: ResponseFormulaToken = {}
      for (const token of responseFormula.tokens) {
        switch (token.type) {
          case ResponseFormulaTokenTypeEnum.QUESTION:
          case ResponseFormulaTokenTypeEnum.CONSTANT: {
            const block: FormulaBlock = {
              operator: operator,
              questionOrConstant: token,
            }
            createBlocks.push(block)
            break
          }
          case ResponseFormulaTokenTypeEnum.OPERATOR: {
            operator = token
            break
          }
        }
      }
      setBlocks(createBlocks)
      setErrorMessage('')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [responseFormula?.tokens])

  return (
    <Modal
      title="計算式の設定"
      maskClosable={false}
      cancelText="キャンセル"
      onCancel={() => {
        onClose()
      }}
      okText="登録"
      onOk={() => {
        const newTokens: ResponseFormulaToken[] = []
        blocks.forEach((block, index) => {
          if (index !== 0) {
            block.operator && newTokens.push(block.operator)
          }
          block.questionOrConstant && newTokens.push(block.questionOrConstant)
        })
        const newResponseFormula = {
          ...responseFormula,
          tokens: newTokens,
        }
        const errorMessage = validate(newTokens, numberNodes)
        setErrorMessage(errorMessage)
        if (!errorMessage) {
          dispatch(
            // 計算式タイプの質問に計算式を設定する
            updateTemplateNode(questionNode.id, {
              ...questionNode,
              question: {
                ...questionNode.question,
                responseFormulas: [newResponseFormula],
              },
            }),
          )
          onClose()
        }
      }}
      open={true}
      width="80%"
    >
      {blocks.map((block, index) => {
        return (
          <EditResponseFormulaDrawerBlock
            key={index}
            numberNodes={numberNodes}
            block={block}
            isFirstBlock={index === 0}
            changeBlock={change => {
              const newBlock = blocks.map((b, i) => {
                return i === index ? change : b
              })
              setBlocks([...newBlock])
            }}
            deleteBlock={() => {
              blocks.splice(index, 1)
              setBlocks([...blocks])
            }}
          />
        )
      })}
      <AddButton
        type="dashed"
        icon={<PlusCircleOutlined />}
        onClick={() => {
          setBlocks([
            ...blocks,
            {
              operator: {
                type: ResponseFormulaTokenTypeEnum.OPERATOR,
                operator: ResponseFormulaTokenOperatorTypeEnum.PLUS,
              },
              questionOrConstant: {
                type: ResponseFormulaTokenTypeEnum.QUESTION,
              },
            },
          ])
        }}
      >
        式を追加
      </AddButton>
      {!!errorMessage && <ErrorText>{errorMessage}</ErrorText>}
    </Modal>
  )
}

export default EditResponseFormulaDrawer
