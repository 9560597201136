import * as Sentry from '@sentry/browser'
import {
  MultipleChoiceSet,
  PlaceNode,
  PlaceNodeTypeEnum,
  TemplateExcelConversionFlow,
  TemplateIcon,
  TemplateIconTypeEnum,
  TemplateLayoutTypeEnum,
  TemplateManual,
} from '@ulysses-inc/harami_api_client'
import { EditTemplateAction } from 'src/state/ducks/templates/editTemplate/actions'
import { ActionTypes } from 'src/state/ducks/templates/editTemplate/types'
import { HTTPError } from 'src/state/middleware/saga/handleHttpError'
import { isNullish } from 'src/util/isNullish'

export interface EditTemplateState {
  name: string
  isShowScore: boolean
  isEditable: boolean
  isExcelConversion: boolean
  isHideQuestionOptions: boolean
  isAudit: boolean
  isKeyboard: boolean
  layoutType: TemplateLayoutTypeEnum
  hasVariables: boolean
  manuals: Array<TemplateManual>
  icons: Array<TemplateIcon>
  multipleChoiceSets: Array<MultipleChoiceSet>
  isLoading: boolean
  error: HTTPError | null
  templateNameErrorMessage: string
  approvalFlowId: number
  selectedPlaceNodes: Array<PlaceNode>
  excelConversionFlows: Array<TemplateExcelConversionFlow>
  isDirty: boolean
}

const initialEditTemplateState = {
  name: '',
  isShowScore: false,
  isEditable: true,
  isExcelConversion: false,
  isHideQuestionOptions: false,
  isAudit: false,
  isKeyboard: false,
  layoutType: TemplateLayoutTypeEnum.Scroll,
  hasVariables: false,
  manuals: [],
  icons: [],
  multipleChoiceSets: [],
  isLoading: false,
  error: null,
  templateNameErrorMessage: '',
  approvalFlowId: 0,
  selectedPlaceNodes: [],
  excelConversionFlows: [],
  isDirty: false,
}

export const editTemplateReducer = (
  state: EditTemplateState = initialEditTemplateState,
  action: EditTemplateAction,
): EditTemplateState => {
  switch (action.type) {
    case ActionTypes.ADD_TEMPLATE: {
      return { ...state, isLoading: true, error: null }
    }
    case ActionTypes.SUCCESS_ADD_TEMPLATE: {
      return {
        ...state,
        isLoading: action.isLoading,
        error: null,
        isDirty: false,
      }
    }
    case ActionTypes.ERROR_ADD_TEMPLATE: {
      return { ...state, isLoading: false, error: action.error }
    }
    // 意図と名前があっていない。本来はtemplatePagesのsliceに入るべきだったもの？
    case ActionTypes.CLEAR_ADD_TEMPLATE_PAGES_ERROR: {
      return { ...state, isLoading: false, error: null }
    }
    case ActionTypes.REQUEST_UPDATE_TEMPLATE: {
      return { ...state, isLoading: action.isLoading, error: null }
    }
    case ActionTypes.SUCCESS_UPDATE_TEMPLATE: {
      return {
        ...state,
        isLoading: action.isLoading,
        error: null,
        isDirty: false,
      }
    }
    case ActionTypes.ERROR_UPDATE_TEMPLATE: {
      return { ...state, isLoading: action.isLoading, error: action.error }
    }
    case ActionTypes.RESET_TEMPLATE: {
      return { ...state, ...initialEditTemplateState }
    }
    case ActionTypes.UPDATE_TEMPLATE_NAME: {
      return { ...state, name: action.name, isDirty: true }
    }
    case ActionTypes.UPDATE_TEMPLATE_ICONS: {
      return { ...state, icons: action.icons, isDirty: true }
    }
    case ActionTypes.SUCCESS_GET_TEMPLATE: {
      // 基本的に想定されないが怖いので一定期間確認。一定期間エラーが発生しなければ後述の`as TemplateLayoutTypeEnum`とともに削除して問題ない。
      if (isNullish(action.layoutType)) {
        Sentry.captureException(
          new Error('レイアウトタイプが取得できませんでした'),
        )
      }
      return {
        ...state,
        isLoading: false,
        name: action.name,
        isShowScore: !!action.isShowScore,
        isEditable: !!action.isEditable,
        isExcelConversion: !!action.isExcelConversion,
        isHideQuestionOptions: !!action.isHideQuestionOptions,
        isAudit: !!action.isAudit,
        isKeyboard: !!action.isKeyboard,
        layoutType: action.layoutType as TemplateLayoutTypeEnum,
        hasVariables: action.hasVariables,
        manuals: action.manuals ?? [],
        icons: action.icons ?? [],
        approvalFlowId: action.approvalFlowId,
        selectedPlaceNodes: action.placeNodes || [],
        excelConversionFlows: action.excelConversionFlows ?? [],
        isDirty: false,
      }
    }
    case ActionTypes.ERROR_GET_TEMPLATE: {
      return { ...state, isLoading: false, error: action.error }
    }
    case ActionTypes.GET_EMPTY_TEMPLATE: {
      return {
        ...state,
        icons: [
          {
            iconType: TemplateIconTypeEnum.Checklist,
          },
        ],
        isDirty: false,
      }
    }
    case ActionTypes.GET_EMPTY_GRID_TEMPLATE: {
      return {
        ...state,
        icons: [
          {
            iconType: TemplateIconTypeEnum.Checklist,
          },
        ],
        isDirty: false,
        layoutType: TemplateLayoutTypeEnum.Grid,
        hasVariables: action.hasVariables,
      }
    }
    case ActionTypes.TOGGLE_SHOW_SCORE: {
      return { ...state, isShowScore: action.isShowScore, isDirty: true }
    }
    case ActionTypes.TOGGLE_EDITABLE: {
      return { ...state, isEditable: action.isEditable, isDirty: true }
    }
    case ActionTypes.TOGGLE_EXCEL_CONVERSION: {
      return {
        ...state,
        isExcelConversion: action.isExcelConversion,
        isDirty: true,
      }
    }
    case ActionTypes.TOGGLE_AUDIT: {
      return { ...state, isAudit: action.isAudit, isDirty: true }
    }
    case ActionTypes.TOGGLE_TENKEY: {
      return { ...state, isKeyboard: action.isKeyboard, isDirty: true }
    }
    case ActionTypes.UPDATE_LAYOUT_TYPE: {
      return { ...state, layoutType: action.layoutType, isDirty: true }
    }
    case ActionTypes.ADD_TEMPLATE_MANUAL: {
      const newTemplateManualId =
        ((state.manuals ?? []).map(manual => manual.id).slice(-1)[0] ?? 0) + 1
      const newTemplateManual = {
        id: newTemplateManualId,
        name: '',
        blocks: [],
      }
      return {
        ...state,
        manuals: [...state.manuals, newTemplateManual],
        isDirty: true,
      }
    }
    case ActionTypes.UPDATE_TEMPLATE_MANUAL: {
      return {
        ...state,
        manuals: state.manuals.map(manual => {
          if (manual.id === action.templateManual.id)
            return action.templateManual
          return manual
        }),
        isDirty: true,
      }
    }
    case ActionTypes.DELETE_TEMPLATE_MANUAL: {
      return {
        ...state,
        manuals: state.manuals.filter(
          manual => manual.id !== action.templateManual.id,
        ),
        isDirty: true,
      }
    }
    case ActionTypes.SET_TEMPLATE_NAME_ERROR_MESSAGE: {
      return {
        ...state,
        templateNameErrorMessage: action.message,
      }
    }
    case ActionTypes.UPDATE_APPROVAL_FLOW_ID: {
      return {
        ...state,
        approvalFlowId: action.approvalFlowId,
        isDirty: true,
      }
    }
    case ActionTypes.UPDATE_PLACE_NODES: {
      return {
        ...state,
        // この`selectedPlaceNodes`は最終的に`baseClient.getApi(TemplatesApi).updateTemplate(ここ)`に与えられる。
        // おそらくサーバーサイドではidしか見ていないので他のプロパティは何でも良い。
        // typeで型エラーが出ているが、適当な値をセットして意図しない挙動になるのも避けたいし、
        // かといって修正も困難なため、型アサーションでお茶を濁しておく。
        selectedPlaceNodes: (action.placeNodeIds ?? []).map(placeNodeId => {
          return {
            id: 0,
            uuid: placeNodeId,
            type: 0 as PlaceNodeTypeEnum,
            isRootNode: 0,
            parentNodes: [],
            nodes: [],
          }
        }),
        isDirty: true,
      }
    }
    default: {
      return { ...state }
    }
  }
}
