import { css } from '@emotion/react'
import { ComponentProps, ReactNode } from 'react'
import { White } from 'src/features/theme/KdsThemeColor'

export const HEADER_HEIGHT = 72

type Props = ComponentProps<'header'> & {
  /**
   * ヘッダーの左端に表示したい要素（e.g. タイトル）
   */
  children?: ReactNode
  /**
   * ヘッダーの右端に表示したい要素（e.g. 保存ボタン）
   */
  rightSlot?: ReactNode
  /**
   * ヘッダーを画面上部に固定するか
   */
  sticky?: boolean
}

/**
 * ヘッダーを表示する際にコンテナとなるコンポーネント
 */
export const Header = (props: Props) => {
  const { children, rightSlot, sticky = false, ...restProps } = props

  return (
    <header
      {...restProps}
      css={[styles.container, sticky && styles.containerSticky]}
    >
      <div css={styles.slot}>{children}</div>
      <div css={styles.slot}>{rightSlot}</div>
    </header>
  )
}

const styles = {
  container: css`
    align-items: center;
    background: ${White};
    border-bottom: 1px solid #dbe1e9;
    display: flex;
    height: ${HEADER_HEIGHT}px;
    justify-content: space-between;
    padding: 0 24px;
  `,
  containerSticky: css`
    position: sticky;
    top: 0;
    z-index: 3;
  `,
  // 複数の要素が与えられたら16pxの間隔で自動的に横方向に並べる
  slot: css`
    display: flex;
    gap: 16px;
  `,
}
