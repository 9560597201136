import * as Sentry from '@sentry/browser'
import {
  LogicConditionTypeEnum,
  MultipleChoice,
  MultipleChoiceGroup,
  NumberCondition,
  NumberLogicTypeEnum,
  ReportNode,
  ResponseTypeEnum,
  TemplateNodeSchema,
  TemplateNodeTypeEnum,
  TemplatePageSchema,
} from '@ulysses-inc/harami_api_client'
import { denormalize, normalize } from 'normalizr'
import { GRID_VARIABLES_COUNT } from 'src/constants/responseGridVariable'
import { UUID } from 'src/exShared/util/uuid'
import {
  getCreateQuestionNode,
  getCreateSectionNode,
} from 'src/features/templateEdit/common/createNode'
import { SchemaReportNodes } from 'src/state/ducks/reports/schemas'
import { TemplatePagesAction } from 'src/state/ducks/templates/actions'
import { EditTemplateAction } from 'src/state/ducks/templates/editTemplate/actions'
import { ActionTypes as ActionTypesOfEditTemplate } from 'src/state/ducks/templates/editTemplate/types'
import {
  copyPageValidate,
  createNewLogicNode,
  createNewNumberLogicNode,
  createNewQuestionNode,
  deleteUnnecessaryCopiedProperties,
  getDeleteNodeIds,
  walkAndUpdateId,
} from 'src/state/ducks/templates/function'
import { moveTemplateNodeReducer } from 'src/state/ducks/templates/templatePages/reducer.evil'
import { ActionTypes } from 'src/state/ducks/templates/types'
import { HTTPError } from 'src/state/middleware/saga/handleHttpError'
import { isDefined } from 'src/util/idDefined'
import invariant from 'tiny-invariant'

export interface TemplatePagesState {
  templatePages: { [key: number]: TemplatePageSchema }
  templateNodes: { [key: number]: TemplateNodeSchema }
  templatePageIds: Array<number>
  activePageId: number
  activeNodeId: number
  isLoading: boolean
  isLoadingInformation: boolean
  error: Error | HTTPError | null
  templatePageErrorMessage: string
  isEdit: boolean
  isScrollToTopPosition: boolean
  isDirty: boolean
  isCopyingPage: boolean
  copyTemplatePageError?: { targetPageName: string; message: string }
}

const initialTemplatePageId = 1

export const initialTemplatePagesState: TemplatePagesState = {
  isLoading: false,
  isLoadingInformation: false,
  activePageId: initialTemplatePageId,
  activeNodeId: 0,
  templatePages: {
    [initialTemplatePageId]: { id: initialTemplatePageId, name: '', nodes: [] },
  },
  templateNodes: {},
  templatePageIds: [initialTemplatePageId],
  error: null,
  templatePageErrorMessage: '',
  isScrollToTopPosition: false,
  isEdit: false,
  isDirty: false,
  isCopyingPage: false,
}

export const templatePagesReducer = (
  state: TemplatePagesState = initialTemplatePagesState,
  action: TemplatePagesAction | EditTemplateAction,
): TemplatePagesState => {
  switch (action.type) {
    // ひな形メタデータの取得に失敗したときはひな形ページの取得も停止するためと思われる
    case ActionTypesOfEditTemplate.ERROR_ADD_TEMPLATE: {
      return { ...state, isLoading: action.isLoading, error: action.error }
    }
    // 以下のコードには到達し得ない。所要のコード修正がされるまで一旦コメントアウトする。
    // case ActionTypes.REQUEST_ADD_TEMPLATE_PAGES: {
    //   return {
    //     ...state,
    //     isLoading: action.isLoading,
    //     error: null,
    //   }
    // }
    case ActionTypes.SUCCESS_ADD_TEMPLATE_PAGES: {
      return {
        ...state,
        isLoading: action.isLoading,
        error: null,
        isDirty: false,
      }
    }
    case ActionTypes.ERROR_ADD_TEMPLATE_PAGES: {
      return { ...state, isLoading: action.isLoading, error: action.error }
    }
    case ActionTypes.REQUEST_GET_TEMPLATE_PAGES: {
      return { ...state, isLoading: action.isLoading }
    }
    case ActionTypes.SUCCESS_GET_TEMPLATE_PAGES: {
      // 型エラーをなくすための事前調査。しばらく計測してエラーが出ないようであれば、invariantの記述に置き換える。
      // invariant(action.templatePageIds[0])
      if (action.templatePageIds[0] === undefined) {
        Sentry.captureException(
          new Error('action.templatePageIds[0]がundefinedです'),
        )
      }
      return {
        ...state,
        templatePages: action.templatePages ?? {},
        templateNodes: action.templateNodes ?? {},
        templatePageIds: action.templatePageIds ?? [],
        activePageId: action.templatePageIds[0],
        isLoading: action.isLoading,
        isDirty: false,
      }
    }
    case ActionTypes.ERROR_GET_TEMPLATE_PAGES: {
      return { ...state, isLoading: action.isLoading, error: action.error }
    }
    case ActionTypes.RESET_TEMPLATE_PAGES: {
      return {
        ...state,
        ...{
          ...initialTemplatePagesState,
          templatePages: {
            [initialTemplatePageId]: {
              id: initialTemplatePageId,
              name: '',
              nodes: [],
            },
          },
          templateNodes: {},
        },
        isDirty: false,
      }
    }
    case ActionTypes.ADD_TEMPLATE_PAGE: {
      const addKey = Math.max(...state.templatePageIds) + 1
      const addPage = { id: addKey, name: '', nodes: [] }
      return {
        ...state,
        activePageId: addKey,
        templatePages: { ...state.templatePages, [addKey]: addPage },
        templatePageIds: [...state.templatePageIds, addKey],
        isDirty: true,
      }
    }
    case ActionTypes.DELETE_TEMPLATE_PAGE: {
      const templatePages = Object.keys(state.templatePages)
        .map(Number)
        .filter((key: number) => key !== action.templatePageId)
        .reduce(
          (prev, cur) => ({ ...prev, [cur]: state.templatePages[cur] }),
          {},
        )

      const deletedPage = state.templatePages[action.templatePageId]
      const toDeleteNodeIds = deletedPage
        ? deletedPage?.nodes
            ?.map((pageNodeId: number) =>
              getDeleteNodeIds(pageNodeId, state.templateNodes),
            )
            .flat()
        : []
      const templateNodes = Object.keys(state.templateNodes)
        .map(Number)
        .filter(
          (nodeId: number) =>
            !toDeleteNodeIds?.some(
              (pageNodeId: number) => pageNodeId === nodeId,
            ),
        )
        .reduce(
          (prev, cur) => ({ ...prev, [cur]: state.templateNodes[cur] }),
          {},
        )
      let activePageId
      if (state.activePageId === action.templatePageId) {
        const currentActivePageIndex = state.templatePageIds.indexOf(
          action.templatePageId,
        )
        activePageId =
          currentActivePageIndex === 0
            ? state.templatePageIds[1]
            : state.templatePageIds[currentActivePageIndex - 1]
      }
      activePageId =
        activePageId !== undefined ? activePageId : state.activePageId
      return {
        ...state,
        activePageId: activePageId,
        templatePages: templatePages,
        templateNodes: templateNodes,
        templatePageIds: state.templatePageIds.filter(
          (key: number) => key !== action.templatePageId,
        ),
        isDirty: true,
      }
    }
    case ActionTypes.UPDATE_TEMPLATE_PAGE: {
      return {
        ...state,
        templatePages: {
          ...state.templatePages,
          [action.templatePageId]: action.changeTemplatePage,
        },
        isDirty: true,
      }
    }
    case ActionTypes.START_COPY_TEMPLATE_PAGE: {
      return { ...state, isCopyingPage: true }
    }
    case ActionTypes.COPY_TEMPLATE_PAGE: {
      const targetPage = state.templatePages[action.targetPageId]
      if (targetPage === undefined) return { ...state }

      // 現在雛形内に存在する最大の nodeId
      let maxNodeId = Math.max(
        ...Object.keys(state.templateNodes).map(key =>
          Number.parseInt(key, 10),
        ),
      )
      // 新しく作るページの pageId
      const newPageId =
        Math.max(
          ...Object.keys(state.templatePages).map(key =>
            Number.parseInt(key, 10),
          ),
        ) + 1

      const copiedNodes: { [key: number]: TemplateNodeSchema } = {}
      const copiedNodeIds: number[] = []
      // responseFormulas、responseTimeMeasurements の UUID を更新するためのマッピング
      const oldUUIDMappings: { [key: string]: string } = {}
      targetPage.nodes?.forEach(nodeId => {
        if (state.templateNodes[nodeId] === undefined) return

        const parentNodeId = ++maxNodeId

        const node: TemplateNodeSchema = JSON.parse(
          JSON.stringify(state.templateNodes[nodeId]),
        )

        copiedNodeIds.push(parentNodeId)
        const parentNode = {
          ...node,
          id: parentNodeId,
        }
        // logicId, sectionId, questionId は作成時には定義しなくてよい（DB挿入時に自動で採番されるため）
        deleteUnnecessaryCopiedProperties(parentNode)

        const uuid = UUID()
        if (node.uuid) oldUUIDMappings[node.uuid] = uuid
        parentNode.uuid = uuid

        copiedNodes[parentNodeId] = parentNode

        // ReportNode は子ノードを id の配列ではなく、実体として持っているので、構造上 TemplateNodeSchema よりも再帰的に更新するのに向いている
        const nestedChildren: ReportNode[] = denormalize(
          node.nodes,
          SchemaReportNodes,
          { nodes: state.templateNodes },
        )
        if (nestedChildren.length === 0) return

        const mutableNestedChildren: ReportNode[] = JSON.parse(
          JSON.stringify(nestedChildren),
        )
        maxNodeId = walkAndUpdateId(
          mutableNestedChildren,
          parentNodeId,
          oldUUIDMappings,
        )

        node.nodes = mutableNestedChildren.map(child => child.id)
        const flattenChildren = normalize<
          any,
          { nodes: { [key: number]: TemplateNodeSchema } }
        >(mutableNestedChildren, SchemaReportNodes).entities.nodes

        Object.values(flattenChildren).forEach(child => {
          deleteUnnecessaryCopiedProperties(child)
          copiedNodes[child.id] = child
        })

        // 子を追加して再度更新
        parentNode.nodes = mutableNestedChildren.map(child => child.id)
        copiedNodes[parentNodeId] = parentNode
      })

      // responseFormulas、responseTimeMeasurements を更新
      Object.values(copiedNodes).forEach(node => {
        if (
          node.type === TemplateNodeTypeEnum.Question &&
          node.question !== undefined
        ) {
          node.question.responseFormulas?.forEach(formula => {
            formula.tokens?.forEach(token => {
              // UUID を更新
              if (token.questionNodeUUID !== undefined)
                token.questionNodeUUID = oldUUIDMappings[token.questionNodeUUID]
            })
          })
          node.question.responseTimeMeasurements?.forEach(timeMeasurement => {
            // 時間計測の開始/終了質問のUUID を更新
            const newStartQuestionNodeUUID =
              oldUUIDMappings[timeMeasurement.startQuestionNodeUUID]
            const newEndQuestionNodeUUID =
              oldUUIDMappings[timeMeasurement.endQuestionNodeUUID]
            if (
              timeMeasurement.startQuestionNodeUUID !== undefined &&
              newStartQuestionNodeUUID
            ) {
              timeMeasurement.startQuestionNodeUUID = newStartQuestionNodeUUID
            }
            if (
              timeMeasurement.endQuestionNodeUUID !== undefined &&
              newEndQuestionNodeUUID
            ) {
              timeMeasurement.endQuestionNodeUUID = newEndQuestionNodeUUID
            }
          })
        }
      })

      // NOTE: ページコピー時のソフトリミットのバリデーションをここで行っている。
      // 本来であれば、コピーボタン押下のイベントのハンドラーで行うべきだが、
      // ページに紐づく nodes 数をカウントする処理が現状では複雑で重い処理になるので、実際にコピーを行った後にカウントしたいため。
      const { valid, error } = copyPageValidate(
        state.templatePageIds.length + 1,
        Object.keys(copiedNodes).length +
          Object.keys(state.templateNodes).length,
      )

      if (!valid) {
        return {
          ...state,
          copyTemplatePageError: {
            targetPageName: targetPage.name,
            message: error,
          },
          isCopyingPage: false,
        }
      }

      return {
        ...state,
        templatePages: {
          ...state.templatePages,
          [newPageId]: {
            ...targetPage,
            id: newPageId,
            nodes: copiedNodeIds,
          },
        },
        templatePageIds: [...state.templatePageIds, newPageId],
        templateNodes: {
          ...state.templateNodes,
          ...copiedNodes,
        },
        activePageId: newPageId,
        isDirty: true,
        isCopyingPage: false,
      }
    }
    case ActionTypes.CLEAR_COPY_TEMPLATE_PAGE_ERROR: {
      return { ...state, copyTemplatePageError: undefined }
    }
    case ActionTypes.MOVE_TEMPLATE_PAGE: {
      const hoverKeyIndex = state.templatePageIds.indexOf(action.hoverKey)
      const templatePageIds = state.templatePageIds.filter(
        pageId => pageId !== action.dragKey,
      )
      templatePageIds.splice(hoverKeyIndex, 0, action.dragKey)
      return {
        ...state,
        templatePageIds: templatePageIds,
        isDirty: true,
      }
    }
    case ActionTypes.MOVE_TEMPLATE_NODE: {
      return moveTemplateNodeReducer(state, action)
    }
    case ActionTypes.CHANGE_ACTIVE_PAGE_ID: {
      return { ...state, activePageId: action.activePageId }
    }
    case ActionTypes.CHANGE_ACTIVE_NODE_ID: {
      return { ...state, activeNodeId: action.activeNodeId }
    }
    case ActionTypes.ADD_TEMPLATE_NODE_INTO_PAGE: {
      // 選択しているpageの直下のnodeのidの配列を取得。
      // FIXME: 変数名を適切な命名へ変更する
      let parentNodeNodes = state.templatePages[state.activePageId]?.nodes

      // アクティブになっているpageのnodeの配列からtargetNodeのidと一致するindexを取得する
      const targetNodeKey: number =
        state.templatePages[state.activePageId]?.nodes?.findIndex(
          key => key === action.targetNode?.id,
        ) ?? -1

      if (parentNodeNodes?.length === 0) {
        parentNodeNodes = [action.createNodeId]
      } else {
        // actionから渡されたnodeのidをtargetNodeの前あるいは後に配置する
        parentNodeNodes?.splice(
          action.position === 'top' ? targetNodeKey : targetNodeKey + 1,
          0,
          action.createNodeId,
        )
      }
      const activePage = state.templatePages[state.activePageId]
      invariant(activePage, 'アクティブなページがありません')
      return {
        ...state,
        templatePages: {
          ...state.templatePages,
          [state.activePageId]: {
            ...activePage,
            nodes: parentNodeNodes,
          },
        },
        templateNodes: {
          ...state.templateNodes,
          [action.createNodeId]: { ...action.createNode, createdAt: undefined },
        },
        activeNodeId: action.createNodeId,
        isDirty: true,
      }
    }
    case ActionTypes.ADD_TEMPLATE_NODE_INTO_NODE: {
      const parentNodeId = action.parentNode?.id
      invariant(parentNodeId, '親ノードIDがありません')
      const parentNode = state.templateNodes[parentNodeId]
      invariant(parentNode, '親ノードがありません')
      let parentNodeNodes = parentNode.nodes || []

      const targetNodeKey: number =
        parentNodeNodes.findIndex(key => key === action.targetNode?.id) ?? -1

      // 🚨stateを直接改変しており危険
      if (parentNodeNodes.length === 0) {
        parentNodeNodes = [action.createNodeId]
      } else {
        parentNodeNodes.splice(
          action.position === 'top' ? targetNodeKey : targetNodeKey + 1,
          0,
          action.createNodeId,
        )
      }

      return {
        ...state,
        templateNodes: {
          ...state.templateNodes,
          [action.createNodeId]: { ...action.createNode, createdAt: undefined },
          [parentNodeId]: {
            ...parentNode,
            nodes: parentNodeNodes,
          },
        },
        activeNodeId: action.createNodeId,
        isDirty: true,
      }
    }
    case ActionTypes.ADD_TEMPLATE_MULTIPLE_CHOICE_LOGIC: {
      const templateNodes = state.templateNodes
      let createLogicId =
        Math.max(...Object.keys(templateNodes).map(Number), 0) + 1

      // 「選択したそれぞれの項目で分岐する」の場合
      if (action.conditionType === LogicConditionTypeEnum.Is) {
        action.responseMultipleChoices.forEach(
          (multipleChoice: MultipleChoice) => {
            createLogicId += 1
            const createLogicNode = createNewLogicNode(
              createLogicId,
              action.conditionType,
              [multipleChoice],
            )
            const targetNode = templateNodes[action.targetNode.id]
            // 単に型の都合
            if (targetNode) {
              targetNode.nodes = [...targetNode.nodes, createLogicId]
            }
            templateNodes[createLogicId] = createLogicNode
          },
        )
      }

      // 「まとめて一つの質問へ分岐する」の場合
      if (action.conditionType === LogicConditionTypeEnum.IsOneOf) {
        createLogicId += 1
        const createLogicNode = createNewLogicNode(
          createLogicId,
          action.conditionType,
          action.responseMultipleChoices,
        )
        const targetNode = templateNodes[action.targetNode.id]
        // 単に型の都合
        if (targetNode) {
          targetNode.nodes = [...targetNode.nodes, createLogicId]
        }
        templateNodes[createLogicId] = createLogicNode
      }

      return { ...state, templateNodes: templateNodes, isDirty: true }
    }
    case ActionTypes.UPDATE_TEMPLATE_MULTIPLE_CHOICE_LOGIC: {
      const templateNodes = state.templateNodes
      // 既存のロジックノード
      const oldLogicNodes = action.targetNode.nodes
        .filter(
          nodeId => templateNodes[nodeId].type === TemplateNodeTypeEnum.Logic,
        )
        .map(nodeId => templateNodes[nodeId])
      // 既存のconditionType
      const oldConditionType = oldLogicNodes[0]?.logic?.conditionType
      // 新規のconditionType
      const newConditionType = action.conditionType

      if (oldConditionType !== newConditionType) {
        // 既存のロジックを削除
        const oldLogicNodeIds = oldLogicNodes.map(node => node.id)
        if (action.targetNode.id in templateNodes) {
          templateNodes[action.targetNode.id].nodes =
            action.targetNode.nodes.filter(
              nodeId => !oldLogicNodeIds.includes(nodeId),
            )
        }
        oldLogicNodeIds.forEach(nodeId => {
          delete templateNodes[nodeId]
        })

        // 新規のロジックを追加
        let createLogicId =
          Math.max(...Object.keys(templateNodes).map(Number), 0) + 1
        if (action.conditionType === LogicConditionTypeEnum.Is) {
          action.responseMultipleChoices.forEach(
            (multipleChoice: MultipleChoice) => {
              createLogicId += 1
              const createLogicNode = createNewLogicNode(
                createLogicId,
                action.conditionType,
                [multipleChoice],
              )
              if (action.targetNode.id in templateNodes) {
                templateNodes[action.targetNode.id].nodes = [
                  ...templateNodes[action.targetNode.id].nodes,
                  createLogicId,
                ]
              }
              templateNodes[createLogicId] = createLogicNode
            },
          )
        }
        if (action.conditionType === LogicConditionTypeEnum.IsOneOf) {
          createLogicId += 1
          const createLogicNode = createNewLogicNode(
            createLogicId,
            action.conditionType,
            action.responseMultipleChoices,
          )
          if (action.targetNode.id in templateNodes) {
            templateNodes[action.targetNode.id].nodes = [
              ...templateNodes[action.targetNode.id].nodes,
              createLogicId,
            ]
          }
          templateNodes[createLogicId] = createLogicNode
        }
        return { ...state, templateNodes: templateNodes, isDirty: true }
      } else if (newConditionType === LogicConditionTypeEnum.Is) {
        // 「選択したそれぞれの項目で分岐する」の場合
        // 更新後（最新）の条件式のid一覧
        const newMultipleChoiceIds = action.responseMultipleChoices?.map(
          (multipleChoice: MultipleChoice) => multipleChoice.id,
        )
        // 既存の条件式のid一覧
        const oldMultipleChoiceIds = oldLogicNodes.map(
          node => node.logic.responseMultipleChoices[0].id,
        )
        // 既存から不要なロジックを削除
        const unnecessaryMultipleChoiceIds = oldMultipleChoiceIds.filter(
          oldId => !newMultipleChoiceIds.includes(oldId),
        )
        const unnecessaryLogicNodeIds = oldLogicNodes
          .filter(node =>
            unnecessaryMultipleChoiceIds.includes(
              node.logic.responseMultipleChoices[0].id,
            ),
          )
          .map(node => node.id)
        if (action.targetNode.id in templateNodes) {
          templateNodes[action.targetNode.id].nodes =
            action.targetNode.nodes.filter(
              nodeId => !unnecessaryLogicNodeIds.includes(nodeId),
            )
        }
        unnecessaryLogicNodeIds.forEach(nodeId => {
          delete templateNodes[nodeId]
        })

        const ignoreMultipleChoiceIds = newMultipleChoiceIds.filter(newId =>
          oldMultipleChoiceIds.includes(newId),
        )

        // 新規のロジックを追加
        let createLogicId =
          Math.max(...Object.keys(templateNodes).map(Number), 0) + 1
        action.responseMultipleChoices
          .filter(
            (multipleChoice: MultipleChoice) =>
              !ignoreMultipleChoiceIds.includes(multipleChoice.id),
          )
          .forEach((multipleChoice: MultipleChoice) => {
            createLogicId += 1
            const createLogicNode = createNewLogicNode(
              createLogicId,
              action.conditionType,
              [multipleChoice],
            )
            if (action.targetNode.id in templateNodes) {
              templateNodes[action.targetNode.id].nodes = [
                ...templateNodes[action.targetNode.id].nodes,
                createLogicId,
              ]
            }
            templateNodes[createLogicId] = createLogicNode
          })
        return { ...state, templateNodes: templateNodes, isDirty: true }
      } else {
        // 「まとめて一つの質問へ分岐する」の場合
        const oldLogicNode = oldLogicNodes[0]
        oldLogicNode.logic.responseMultipleChoices =
          action.responseMultipleChoices
        return { ...state, templateNodes: templateNodes, isDirty: true }
      }
    }
    case ActionTypes.ADD_TEMPLATE_NUMBER_LOGIC: {
      const templateNodes = state.templateNodes
      let newLogicId =
        Math.max(...Object.keys(templateNodes).map(Number), 0) + 1
      action.numberConditions?.forEach((numberCondition: NumberCondition) => {
        newLogicId += 1
        const newLogicNode = createNewNumberLogicNode(
          newLogicId,
          numberCondition,
        )
        if (action.targetNode.id in templateNodes) {
          templateNodes[action.targetNode.id].nodes = [
            ...templateNodes[action.targetNode.id].nodes,
            newLogicId,
          ]
        }
        templateNodes[newLogicId] = newLogicNode
      })
      return { ...state, templateNodes: templateNodes, isDirty: true }
    }
    case ActionTypes.UPDATE_TEMPLATE_NUMBER_LOGIC: {
      const templateNodes = state.templateNodes
      // 更新後（最新）のlogicType一覧
      const newLogicTypes = action.numberConditions?.map(
        (numberCondition: NumberCondition) => numberCondition.logicType,
      )
      // 既存のロジックノード
      const oldLogicNodes = action.targetNode.nodes
        .filter(
          nodeId => templateNodes[nodeId].type === TemplateNodeTypeEnum.Logic,
        )
        .map(nodeId => templateNodes[nodeId])

      // 更新が不要(既存にもあって、新規にもある)なlogicType一覧
      const ignoreLogicTypes: NumberLogicTypeEnum[] = []
      oldLogicNodes.forEach(node => {
        const logicType = node.logic?.numberConditions[0].logicType
        if (newLogicTypes.includes(logicType)) {
          ignoreLogicTypes.push(logicType)
        }
      })

      // 既存から不要なロジックを削除
      const unnecessaryLogicNodeIds = oldLogicNodes
        .filter(
          node =>
            !ignoreLogicTypes.includes(
              node.logic.numberConditions[0].logicType,
            ),
        )
        .map(node => node.id)
      if (action.targetNode.id in templateNodes) {
        templateNodes[action.targetNode.id].nodes =
          action.targetNode.nodes.filter(
            nodeId => !unnecessaryLogicNodeIds.includes(nodeId),
          )
      }
      unnecessaryLogicNodeIds.forEach(nodeId => {
        delete templateNodes[nodeId]
      })

      // 新規のロジックを追加
      let newLogicId =
        Math.max(...Object.keys(templateNodes).map(Number), 0) + 1
      action.numberConditions
        ?.filter(
          (numberCondition: NumberCondition) =>
            !ignoreLogicTypes.includes(numberCondition.logicType),
        )
        .forEach((numberCondition: NumberCondition) => {
          newLogicId += 1
          const newLogicNode = createNewNumberLogicNode(
            newLogicId,
            numberCondition,
          )
          if (action.targetNode.id in templateNodes) {
            templateNodes[action.targetNode.id].nodes = [
              ...templateNodes[action.targetNode.id].nodes,
              newLogicId,
            ]
          }
          templateNodes[newLogicId] = newLogicNode
        })
      return { ...state, templateNodes: templateNodes, isDirty: true }
    }
    case ActionTypes.UPDATE_TEMPLATE_NODE: {
      return {
        ...state,
        templateNodes: {
          ...state.templateNodes,
          [action.nodeId]: action.changeNode,
        },
        isDirty: true,
      }
    }
    case ActionTypes.CHANGE_QUESTION_TO_NEXT: {
      const siblingNodeIds =
        action.parentNode !== null
          ? action.parentNode.nodes
          : state.templatePages[state.activePageId]?.nodes
      if (siblingNodeIds === undefined) {
        return {
          ...state,
          templateNodes: {
            ...state.templateNodes,
            [action.nodeId]: action.node,
          },
        }
      }
      const nextNodeId = siblingNodeIds.find(
        (_, index) => siblingNodeIds[index - 1] === action.nodeId,
      )
      if (nextNodeId !== undefined) {
        return {
          ...state,
          activeNodeId: nextNodeId,
          templateNodes: {
            ...state.templateNodes,
            [action.nodeId]: action.node,
          },
        }
      }
      const createNodeId =
        Math.max(...Object.keys(state.templateNodes).map(Number), 0) + 1
      const isGridVariableQuestion =
        action.node.question?.responseType === ResponseTypeEnum.GRID_VARIABLE
      // 取り込み項目質問からの移動の場合responseTypeがコピーされないようにする
      const originalQuestion = isGridVariableQuestion
        ? undefined
        : action.node.question
      const createNode: TemplateNodeSchema = createNewQuestionNode(
        createNodeId,
        originalQuestion,
      )
      // ページの場合
      if (action.parentNode === null) {
        return {
          ...state,
          activeNodeId: createNodeId,
          templatePages: {
            ...state.templatePages,
            [state.activePageId]: {
              ...state.templatePages[state.activePageId],
              nodes: [...siblingNodeIds, createNodeId],
            },
          },
          templateNodes: {
            ...state.templateNodes,
            [action.nodeId]: action.node,
            [createNodeId]: createNode,
          },
        }
      }
      return {
        ...state,
        activeNodeId: createNodeId,
        templateNodes: {
          ...state.templateNodes,
          [createNodeId]: createNode,
          [action.nodeId]: action.node,
          [action.parentNode.id]: {
            ...state.templateNodes[action.parentNode.id],
            nodes: [...siblingNodeIds, createNodeId],
          },
        },
      }
    }
    case ActionTypes.CHANGE_SECTION_TO_NEXT: {
      const targetNode = action.node
      const childFirstNodeId = action.node.nodes[0]
      if (childFirstNodeId !== undefined)
        return {
          ...state,
          activeNodeId: childFirstNodeId,
          templateNodes: {
            ...state.templateNodes,
            [action.nodeId]: targetNode,
          },
        }
      const createNodeId =
        Math.max(...Object.keys(state.templateNodes).map(Number), 0) + 1
      const createNode: TemplateNodeSchema = createNewQuestionNode(
        createNodeId,
        {},
      )
      targetNode.nodes[0] = createNodeId
      return {
        ...state,
        activeNodeId: createNodeId,
        templateNodes: {
          ...state.templateNodes,
          [action.nodeId]: targetNode,
          [createNodeId]: createNode,
        },
      }
    }
    case ActionTypes.CHANGE_LOGIC_TO_NEXT: {
      const targetNode = action.node
      const firstLogicId = action.node.nodes[0]
      const firstLogicNode = state.templateNodes[firstLogicId]
      if (!firstLogicNode) {
        return state
      }
      const childFirstNodeId = firstLogicNode.nodes[0]
      if (childFirstNodeId !== undefined)
        return {
          ...state,
          activeNodeId: childFirstNodeId,
          templateNodes: {
            ...state.templateNodes,
            [action.nodeId]: targetNode,
          },
        }
      const createNodeId =
        Math.max(...Object.keys(state.templateNodes).map(Number), 0) + 1
      const createNode: TemplateNodeSchema = createNewQuestionNode(
        createNodeId,
        {},
      )
      firstLogicNode.nodes[0] = createNodeId

      return {
        ...state,
        activeNodeId: createNodeId,
        templateNodes: {
          ...state.templateNodes,
          [action.nodeId]: targetNode,
          [createNodeId]: createNode,
        },
      }
    }
    case ActionTypes.ADD_SECTION_TO_PAGE: {
      state.templateNodes[action.node.id] = action.node
      Object.values(action.childNodes).forEach(child => {
        state.templateNodes[child.id] = child
      })
      let insertIndex = -1
      state.templatePages[action.parentPageId]?.nodes?.forEach(
        (nodeId, index) => {
          if (nodeId === action.originalNodeId) {
            insertIndex = index + 1
          }
        },
      )
      state.templatePages[action.parentPageId]?.nodes?.splice(
        insertIndex,
        0,
        action.node.id,
      )
      return {
        ...state,
        templateNodes: {
          ...state.templateNodes,
        },
        templatePages: {
          ...state.templatePages,
        },
      }
    }
    case ActionTypes.ADD_SECTION_TO_SECTION: {
      state.templateNodes[action.node.id] = action.node
      Object.values(action.childNodes).forEach(child => {
        state.templateNodes[child.id] = child
      })
      let insertIndex = -1
      state.templateNodes[action.parentNodeId]?.nodes.forEach(
        (nodeId, index) => {
          if (nodeId === action.originalNodeId) {
            insertIndex = index + 1
          }
        },
      )
      state.templateNodes[action.parentNodeId]?.nodes?.splice(
        insertIndex,
        0,
        action.node.id,
      )
      return {
        ...state,
        templateNodes: {
          ...state.templateNodes,
        },
        templatePages: {
          ...state.templatePages,
        },
        isDirty: true,
      }
    }
    case ActionTypes.DELETE_TEMPLATE_NODE: {
      const toDeleteNodeIds = getDeleteNodeIds(
        action.nodeId,
        state.templateNodes,
      )
      const restNodes: { [key: number]: TemplateNodeSchema } = Object.keys(
        state.templateNodes,
      )
        .map(Number)
        .filter(
          (key: number) =>
            key !== action.nodeId && !toDeleteNodeIds.some(id => id === key),
        )
        .reduce(
          (prev, cur) => ({ ...prev, [cur]: state.templateNodes[cur] }),
          {},
        )

      if (action.parentNode) {
        action.parentNode.nodes.splice(
          action.parentNode.nodes.indexOf(action.nodeId),
          1,
        )
      }

      const nodes = state.templatePages[state.activePageId]?.nodes ?? []
      const targetNodeIndex = nodes.indexOf(action.nodeId)
      if (targetNodeIndex !== -1) {
        nodes.splice(nodes.indexOf(action.nodeId), 1)
      }

      Object.keys(restNodes)
        .map(Number)
        .map((key: number) => restNodes[key])
        .filter(isDefined)
        .forEach((node: TemplateNodeSchema) => {
          if (node.nodes.length > 0) {
            node.nodes = node.nodes.filter(nodeId => restNodes[nodeId])
          }
        })

      return {
        ...state,
        templateNodes: restNodes,
        isDirty: true,
      }
    }
    case ActionTypes.UPDATE_TEMPLATE_NODE_MULTIPLE_CHOICE: {
      const newMultipleChoice: MultipleChoiceGroup = {
        id: action.multipleChoiceId,
        responses: action.multipleChoices,
      }

      const oldMultipleChoice = Object.values(state.templateNodes)
        .filter(
          node =>
            node?.question?.responseType === ResponseTypeEnum.MULTIPLE_CHOICE,
        )
        ?.find(node =>
          node.question.responseMultipleChoices.some(
            group => group.id === action.multipleChoiceId,
          ),
        )
        ?.question?.responseMultipleChoices?.find(
          group => group.id === action.multipleChoiceId,
        )

      if (!oldMultipleChoice) {
        return { ...state }
      }

      const updateTemplateNodes: { [key: number]: TemplateNodeSchema } = {}
      const updateLogicNodeIds: number[] = []
      Object.values(state.templateNodes).forEach((node: TemplateNodeSchema) => {
        if (
          node.type === TemplateNodeTypeEnum.Question &&
          node.question?.responseType === ResponseTypeEnum.MULTIPLE_CHOICE
        ) {
          const multipleChoices = (
            node.question.responseMultipleChoices ?? []
          ).map((multipleChoiceGroup: MultipleChoiceGroup) => {
            if (multipleChoiceGroup.id === action.multipleChoiceId) {
              !updateLogicNodeIds.includes(node.id) &&
                updateLogicNodeIds.push(...node.nodes)
              return newMultipleChoice
            }
            return multipleChoiceGroup
          })
          node.question.responseMultipleChoices = multipleChoices
        }
        updateTemplateNodes[node.id] = { ...node }
      })

      const deleteNodeIds: number[] = []
      if (updateLogicNodeIds.length) {
        updateLogicNodeIds.forEach(id => {
          const logicNode = updateTemplateNodes[id]
          if (!logicNode?.logic?.responseMultipleChoices) {
            return
          }
          logicNode.logic.responseMultipleChoices =
            logicNode.logic.responseMultipleChoices
              .map(response => {
                return newMultipleChoice.responses.find(
                  r => r.id === response.id,
                )
              })
              .filter(response => response)
          if (!logicNode.logic.responseMultipleChoices.length) {
            deleteNodeIds.push(...getDeleteNodeIds(id, updateTemplateNodes))
          }
        })
        if (deleteNodeIds.length) {
          deleteNodeIds.forEach(id => {
            delete updateTemplateNodes[id]
          })
          Object.keys(updateTemplateNodes).forEach(id => {
            if (id in updateTemplateNodes) {
              updateTemplateNodes[id].nodes = updateTemplateNodes[
                id
              ].nodes.filter(nodeId => !deleteNodeIds.includes(nodeId))
            }
          })
        }
      }

      return { ...state, templateNodes: updateTemplateNodes, isDirty: true }
    }
    case ActionTypes.SET_TEMPLATE_PAGE_ERROR_MESSAGE: {
      return { ...state, templatePageErrorMessage: action.message }
    }
    case ActionTypes.REQUEST_UPLOAD_INFORMATION_FILES: {
      return { ...state, isLoadingInformation: action.isLoadingInformation }
    }
    case ActionTypes.SUCCESS_UPLOAD_INFORMATION_FILES: {
      const node = state.templateNodes[action.nodeId]
      if (!node) {
        return { ...state }
      }

      const newNode: TemplateNodeSchema = {
        ...node,
        question: {
          ...node.question,
          responseInformations: action.informationFiles,
        },
      }

      return {
        ...state,
        isLoadingInformation: action.isLoadingInformation,
        templateNodes: {
          ...state.templateNodes,
          [action.nodeId]: newNode,
        },
        isDirty: true,
      }
    }
    case ActionTypes.ERROR_UPLOAD_INFORMATION_FILES: {
      return {
        ...state,
        isLoadingInformation: action.isLoadingInformation,
        error: action.error,
      }
    }
    case ActionTypes.ADD_GRID_SECTION_INTO_ACTIVE_PAGE: {
      const activePage = state.templatePages[state.activePageId]
      if (!activePage) {
        return state
      }

      const [createSectionNodeId, createSectionNode] = getCreateSectionNode(
        state.templateNodes,
      )

      const repeatSectionNode = {
        ...createSectionNode,
        section: { ...createSectionNode.section, isRepeat: 1 },
      }
      const newActivePageNodes = activePage.nodes
        ? [...activePage.nodes, createSectionNodeId]
        : [createSectionNodeId]

      const newTemplateNodes = { ...state.templateNodes }
      newTemplateNodes[createSectionNodeId] = repeatSectionNode

      if (action.hasVariables) {
        // 取り込み項目の質問を6つセットする
        Array.from({ length: GRID_VARIABLES_COUNT }).forEach(() => {
          const [createQuestionNodeId, createQuestionNode] =
            getCreateQuestionNode(newTemplateNodes, {
              responseType: ResponseTypeEnum.GRID_VARIABLE,
              responseGridVariables: [{ isEnabled: false }],
            })
          newTemplateNodes[createQuestionNodeId] = createQuestionNode
          repeatSectionNode.nodes = [
            ...repeatSectionNode.nodes,
            createQuestionNodeId,
          ]
        })
      }

      return {
        ...state,
        templatePages: {
          ...state.templatePages,
          [state.activePageId]: {
            ...activePage,
            nodes: newActivePageNodes,
          },
        },
        templateNodes: newTemplateNodes,
        isDirty: true,
      }
    }
    default: {
      return { ...state }
    }
  }
}
