import { GetSchedulesRequest, Schedule } from '@ulysses-inc/harami_api_client'
import Redux from 'redux'
import actions from './actions'
import { GetSchedulesFilter } from './types'

const resetSchedule = (dispatch: Redux.Dispatch) => {
  dispatch(actions.resetSchedule())
}

const getSchedule = (dispatch: Redux.Dispatch, scheduleId: string) => {
  dispatch(actions.getSchedule(scheduleId))
}

const getSchedules = (
  dispatch: Redux.Dispatch,
  request: GetSchedulesRequest,
  filter?: GetSchedulesFilter,
) => {
  dispatch(actions.getSchedules(request, filter))
}

const changeSchedulesPage = (
  dispatch: Redux.Dispatch,
  page: number,
  pageSize?: number,
  filter?: GetSchedulesFilter,
) => {
  dispatch(actions.changeSchedulesPage(page, pageSize, filter))
}

const changeSchedulesSize = (
  dispatch: Redux.Dispatch,
  pageSize: number,
  filter?: GetSchedulesFilter,
) => {
  dispatch(actions.changeSchedulesSize(pageSize, filter))
}

const updatePagination = (
  dispatch: Redux.Dispatch,
  limit: number,
  offset: number,
) => {
  dispatch(actions.updatePagination(limit, offset))
}

const addSchedule = (dispatch: Redux.Dispatch, schedule: Schedule) => {
  dispatch(actions.addSchedule(schedule))
}

const updateSchedule = (
  dispatch: Redux.Dispatch,
  scheduleId: string,
  schedule: Schedule,
) => {
  dispatch(actions.updateSchedule(scheduleId, schedule))
}

const copySchedule = (dispatch: Redux.Dispatch, schedule: Schedule) => {
  dispatch(actions.copySchedule(schedule))
}

const deleteSchedule = (
  dispatch: Redux.Dispatch,
  scheduleId: string,
  request: GetSchedulesRequest,
  filter?: GetSchedulesFilter,
) => {
  dispatch(actions.deleteSchedule(scheduleId, request, filter))
}

const deleteSchedules = (
  dispatch: Redux.Dispatch,
  scheduleUUIDs: string[],
  request: GetSchedulesRequest,
  filter?: GetSchedulesFilter,
) => {
  dispatch(actions.deleteSchedules(scheduleUUIDs, request, filter))
}

const downloadScheduleQrCodes = (
  dispatch: Redux.Dispatch,
  scheduleUUIDs: string[],
) => {
  dispatch(actions.downloadScheduleQrCodes(scheduleUUIDs))
}

const getSchedulesTemplates = (dispatch: Redux.Dispatch) => {
  dispatch(actions.getSchedulesTemplates())
}

const resetSchedulesTemplates = (dispatch: Redux.Dispatch) => {
  dispatch(actions.resetSchedulesTemplates())
}

export default {
  resetSchedule,
  getSchedule,
  getSchedules,
  changeSchedulesPage,
  changeSchedulesSize,
  updatePagination,
  addSchedule,
  updateSchedule,
  copySchedule,
  deleteSchedule,
  deleteSchedules,
  downloadScheduleQrCodes,
  getSchedulesTemplates,
  resetSchedulesTemplates,
}
