import { ErrorCodeEnum } from '@ulysses-inc/harami_api_client'
import { put, takeEvery } from 'redux-saga/effects'
import * as authError from 'src/features/auth/error/state'
import * as dataUnavailableWidgetAction from 'src/features/dataUnavailableWidget/slice'
import * as notificationServiceActions from 'src/features/notificationService/slice'
import { default as actions } from './actions'
import { ActionTypes } from './types'

/**
 * ほぼ全ての通信を監視し、400系・500系エラーが発生した場合に以下のいずれかの対応を行う
 *
 * - 認証切れの旨を伝えるモーダルを表示した後にユーザをログイン画面にリダイレクトする (authExpiration)
 * - 画面右上に通知を表示する (notificationService)
 * - データが存在しないことを伝えるパーツを表示する (dataUnavailableWidget)
 *
 * 🚨認証切れに関するものを除いて、このようなグローバルな形でエラーを扱うことは
 * 責務の適切な分離の観点から避けるべきである。
 * このため、今後は可能な限り使用しない・拡張しないこと。
 */
function* handleHttpError(action: ReturnType<typeof actions.handleHttpError>) {
  // やむなく`code`プロパティの有無でError型とHTTPError型の判別を行っている
  if (!('code' in action.error)) {
    if (action.error?.message?.includes('Network request failed')) {
      yield put(
        notificationServiceActions.showNotification({
          message: 'ネットワークが不安定なため通信に失敗しました。',
          notificationType: 'error',
        }),
      )
    }
    return
  }

  switch (action.error.code) {
    case ErrorCodeEnum.AuthenticationExpired: {
      yield put(
        authError.showDialog({
          summary: '認証期限切れ',
          message: '認証期限切れのため、ログイン画面へ遷移します',
        }),
      )
      return
    }
    case ErrorCodeEnum.AuthenticationFailure:
    case ErrorCodeEnum.InvalidParameter:
    case ErrorCodeEnum.InternalServerError:
    case ErrorCodeEnum.ConflictError:
    case ErrorCodeEnum.BadRequestError:
    case ErrorCodeEnum.UnHandledError:
    case ErrorCodeEnum.CannotDeleteUserByApprovalError: {
      yield put(
        notificationServiceActions.showNotification({
          message: action.error.errors.join('\n'),
          notificationType: 'error',
        }),
      )
      return
    }
    case ErrorCodeEnum.NotFoundError:
    case ErrorCodeEnum.ForbiddenError: {
      yield put(dataUnavailableWidgetAction.show())
      return
    }
    case ErrorCodeEnum.ServiceAssignmentRequiredError: {
      yield put(
        authError.showDialog({
          summary: '権限不足',
          message: action.error.errors.join('\n'),
        }),
      )
      return
    }
    case ErrorCodeEnum.InvalidAudienceError: {
      yield put(
        authError.showDialog({
          summary: '認証エラー',
          message: '再ログインが必要です',
        }),
      )
      return
    }
    case ErrorCodeEnum.CredentialsNotFoundError:
    case ErrorCodeEnum.AccessTokenInactiveError: {
      yield put(
        authError.showDialog({
          summary: '認証期限切れ',
          message: action.error.errors.join('\n'),
        }),
      )
      return
    }
    default: {
      console.warn('unknown handleHttpError')
    }
  }
}

const sagas = [takeEvery(ActionTypes.HANDLE_HTTP_ERROR, handleHttpError)]
export default sagas
