import { TemplateLayoutTypeEnum } from '@ulysses-inc/harami_api_client'
import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { WebReportResultProps } from 'src/features/reports/result/WebReportResultProps'
import { selectGridLayoutVisiblePageIds } from 'src/state/ducks/reports/reports/selectors'
import { DeviatedAnswers } from './DeviatedAnswers'
import { Overview } from './Overview'
import { Page } from './Page'
import {
  AllPageHeader,
  Content,
  EmptyStateText,
} from './ReportResultSummary.dumb'
import { Scoring } from './Scoring'

export const ReportResultSummary: React.FC<WebReportResultProps> = (
  props: WebReportResultProps,
) => {
  const gridLayoutVisiblePageIds = new Set(
    useSelector(selectGridLayoutVisiblePageIds, shallowEqual),
  )

  const isGridLayout = props.report.layoutType === TemplateLayoutTypeEnum.Grid

  const pages = props.getPages().filter(
    // 表形式以外の場合、すべてのページを表示対象にする
    // 表形式の場合、有効なページのみを表示対象にする
    page => page.id && (!isGridLayout || gridLayoutVisiblePageIds.has(page.id)),
  )

  return (
    <Content>
      <Overview {...props} />
      {props.report.isShowScore && <Scoring {...props} />}
      {/* 表形式ひな形の場合は一旦非表示にしておく
        下記チケットで逸脱項目表示は対応予定
        https://kaminashi.atlassian.net/browse/HPB-2714 */}
      {!isGridLayout && props.deviatedAnswers.length > 0 && (
        <DeviatedAnswers {...props} />
      )}
      <AllPageHeader>すべての回答</AllPageHeader>
      {/* 表形式のひな形かつ、表示するページがない場合、エラー文言を表示する */}
      {isGridLayout && pages.length === 0 && (
        <EmptyStateText>
          取り込み項目のデータが登録されていなかったので、記録がありません。
        </EmptyStateText>
      )}
      {pages.map((page, index) => {
        return <Page key={index} index={index} page={page} {...props} />
      })}
    </Content>
  )
}
