import { StyleSheet, Text, View } from '@react-pdf/renderer'
import { Image } from '@ulysses-inc/harami_api_client'
import React from 'react'
import notFoundImage from 'src/assets/images/imageNotFound1000x1000.png'
import BreakText from 'src/features/reports/result/downloadPDF/BreakText'
import Grid from 'src/features/reports/result/downloadPDF/Grid'
import ReactPDFImage from 'src/features/reports/result/downloadPDF/ReactPDFImage'
import RecordedTime from './RecordedTime'

interface OwnProps {
  questionName: string
  signature?: Image
  recordedAt?: Date
  isTimeDisplayed: boolean
}

const styles = StyleSheet.create({
  gridSignature: { width: '100%', height: 200 },
  notAnswerText: { padding: 5 },
  signatureImage: {
    width: 200,
    objectFit: 'cover',
    alignContent: 'flex-start',
  },
  recordedTimeWrap: { width: '100%', marginBottom: 5 },
})

const Signature: React.FC<OwnProps> = (props: OwnProps) => {
  return (
    <Grid borderLeft borderRight style={styles.gridSignature}>
      <View>
        <BreakText text={props.questionName} />
        {props.signature?.url === undefined ? (
          <Text style={styles.notAnswerText}>未回答</Text>
        ) : (
          <ReactPDFImage
            style={styles.signatureImage}
            uri={
              props.signature?.url !== undefined
                ? `${props.signature?.url}`
                : notFoundImage
            }
          />
        )}
        <View style={styles.recordedTimeWrap}>
          <RecordedTime
            isTimeDisplayed={props.isTimeDisplayed}
            recordedAt={props.recordedAt}
          />
        </View>
      </View>
    </Grid>
  )
}

export default Signature
