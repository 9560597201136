import { addDays, endOfDay, getDay, startOfWeek } from 'date-fns'
import React, { useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { Header } from 'src/components/header/Header'
import { HeaderTitle } from 'src/components/header/HeaderTitle'
import { usePlaceLoadingStatus } from 'src/exShared/hooks/usePlaceLoadingStatus'
import date from 'src/exShared/util/date'
import {
  flattenNodes,
  mergedPlaceNodeIds,
} from 'src/exShared/util/place/placeNode'
import { splitLoginPlaceUUIDs } from 'src/exShared/util/place/splitLoginPlace'
import { useListFilter } from 'src/hooks/filter/useListFilter'
import { getPlaceGroups, getPlaces } from 'src/state/ducks/places/actions'
import { usePseudoRecoilState } from 'src/state/ducks/pseudoRecoil'
import { RootState } from 'src/state/store'
import DashboardStatusTable from './DashboardStatusTable'
import { Body, WrapTabs } from './shared.components'
import {
  useGetDevianceCountColumns,
  useGetScheduleStatusColumns,
  useRequestScheduleData,
} from './shared.hooks'
import {
  TDashboardDataType,
  columnsForScheduleState,
  currentEndDateForScheduleState,
  currentInvalidCountEndDateState,
  currentInvalidCountStartDateState,
  currentStartDateForScheduleState,
  filteredInvalidCountPlaceGroupNodeIdsState,
  filteredPlaceGroupNodeIdsForScheduleState,
  filteredPlaceNodeIdsForScheduleState,
  hasPresentDayForScheduleState,
  invalidCountColumnsState,
  invalidCountFilteredPlaceNodeIdsState,
  invalidCountHasPresentDayState,
} from './shared.types'

const Dashboard: React.FC = () => {
  const startDateForSchedule = useSelector(
    (state: RootState) =>
      state.scheduleListInRangeItemsState.scheduleListInRangeItems.startDate,
    shallowEqual,
  )
  const invalidCountStartDate = useSelector(
    (state: RootState) =>
      state.scheduleListInRangeItemsState.invalidCountScheduleListInRangeItems
        .startDate,
    shallowEqual,
  )

  const [currentStartDateForSchedule, setCurrentStartDateForSchedule] =
    usePseudoRecoilState(currentStartDateForScheduleState)
  const [currentEndDateForSchedule, setCurrentEndDateForSchedule] =
    usePseudoRecoilState(currentEndDateForScheduleState)
  // 本日を含むかどうか（次の週が未来かどうか）
  const [, setHasPresentDayForSchedule] = usePseudoRecoilState(
    hasPresentDayForScheduleState,
  )

  // 逸脱詳細用の日付
  const [currentInvalidCountStartDate, setCurrentInvalidCountStartDate] =
    usePseudoRecoilState(currentInvalidCountStartDateState)
  const [currentInvalidCountEndDate, setCurrentInvalidCountEndDate] =
    usePseudoRecoilState(currentInvalidCountEndDateState)
  // 本日を含むかどうか（次の週が未来かどうか）
  const [, setInvalidCountHasPresentDay] = usePseudoRecoilState(
    invalidCountHasPresentDayState,
  )

  const dispatch = useDispatch()
  const placeLoadingStatus = usePlaceLoadingStatus()

  useEffect(() => {
    dispatch(getPlaces({}))
    dispatch(getPlaceGroups())

    const setCurrentRangeToState = (type: TDashboardDataType) => {
      const isSchedule = type === 'schedule'

      const newStartDate =
        (isSchedule ? startDateForSchedule : invalidCountStartDate) ??
        date.today()
      const startDayOfWeek = addDays(
        startOfWeek(newStartDate),
        getDay(newStartDate) === 0 ? -6 : 1,
      )
      const endDayOfWeek = addDays(startDayOfWeek, 6)
      ;(isSchedule
        ? setCurrentStartDateForSchedule
        : setCurrentInvalidCountStartDate)(startDayOfWeek)
      ;(isSchedule
        ? setCurrentEndDateForSchedule
        : setCurrentInvalidCountEndDate)(endDayOfWeek)
      ;(isSchedule
        ? setHasPresentDayForSchedule
        : setInvalidCountHasPresentDay)(
        date.isWithinRange(
          date.today(),
          startDayOfWeek,
          endOfDay(endDayOfWeek),
        ),
      )
    }

    setCurrentRangeToState('schedule')
    setCurrentRangeToState('deviance')

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const placeNodes = useSelector(
    (state: RootState) => flattenNodes(state.placesState.placeGroups.nodes),
    shallowEqual,
  )

  const requestScheduleData = useRequestScheduleData()
  const { filters } = useListFilter()
  const { loginPlaceGroupIds, loginPlaceIds } = splitLoginPlaceUUIDs()

  const [, setFilteredPlaceGroupNodeIdsForSchedule] = usePseudoRecoilState(
    filteredPlaceGroupNodeIdsForScheduleState,
  )
  const [, setFilteredPlaceNodeIdsForSchedule] = usePseudoRecoilState(
    filteredPlaceNodeIdsForScheduleState,
  )

  // 逸脱詳細用の現場
  const [, setFilteredInvalidCountPlaceGroupNodeIds] = usePseudoRecoilState(
    filteredInvalidCountPlaceGroupNodeIdsState,
  )
  const [, setInvalidCountFilteredPlaceNodeIds] = usePseudoRecoilState(
    invalidCountFilteredPlaceNodeIdsState,
  )

  const [, setColumnsForSchedule] = usePseudoRecoilState(
    columnsForScheduleState,
  )
  const [, setInvalidCountColumns] = usePseudoRecoilState(
    invalidCountColumnsState,
  )

  const getScheduleStatusColumns = useGetScheduleStatusColumns()
  const getDevianceCountColumns = useGetDevianceCountColumns()

  useEffect(() => {
    if (placeLoadingStatus !== 'finish') {
      return
    }
    if (
      !placeNodes.length ||
      !currentStartDateForSchedule ||
      !currentEndDateForSchedule
    ) {
      return
    }

    const setScheduleDateOfCurrentRange = (type: TDashboardDataType) => {
      const isSchedule = type === 'schedule'

      const startDate = isSchedule
        ? currentStartDateForSchedule
        : currentInvalidCountStartDate
      const endDate = isSchedule
        ? currentEndDateForSchedule
        : currentInvalidCountEndDate

      if (!startDate || !endDate) {
        return
      }

      const filter =
        filters?.[isSchedule ? 'unstartedSchedules' : 'invalidCountSchedules']
      const currentPlaceIds = filter?.filter?.placeNodeIds ?? loginPlaceIds
      const currentPlaceGroupIds =
        filter?.filter?.placeGroupNodeIds ?? loginPlaceGroupIds

      ;(isSchedule ? setColumnsForSchedule : setInvalidCountColumns)(
        (isSchedule ? getScheduleStatusColumns : getDevianceCountColumns)(
          startDate,
          endDate,
        ),
      )
      ;(isSchedule
        ? setFilteredPlaceNodeIdsForSchedule
        : setInvalidCountFilteredPlaceNodeIds)(currentPlaceIds)
      ;(isSchedule
        ? setFilteredPlaceGroupNodeIdsForSchedule
        : setFilteredInvalidCountPlaceGroupNodeIds)(currentPlaceGroupIds)

      const placeNodeIds = mergedPlaceNodeIds(
        placeNodes,
        currentPlaceIds,
        currentPlaceGroupIds,
      )
      requestScheduleData(isSchedule, placeNodeIds, startDate, endDate)
    }

    setScheduleDateOfCurrentRange('schedule')
    setScheduleDateOfCurrentRange('deviance')

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [placeLoadingStatus])

  return (
    <div>
      <Header>
        <HeaderTitle title="ダッシュボード" />
      </Header>
      <Body>
        <WrapTabs
          items={[
            {
              label: 'レポート入力詳細',
              key: '1',
              children: <DashboardStatusTable type="schedule" />,
            },
            {
              label: '逸脱詳細',
              key: '2',
              children: <DashboardStatusTable type="deviance" />,
            },
          ]}
        ></WrapTabs>
      </Body>
    </div>
  )
}

export default Dashboard
