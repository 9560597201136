import { createRoot } from 'react-dom/client'
import 'src/index.scss'
import invariant from 'tiny-invariant'
import App from './views/App'

const container = document.getElementById('root')
invariant(container)
const root = createRoot(container)

root.render(<App />)
