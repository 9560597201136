import { Modal, Radio, Typography } from 'antd'
import { FC, useState } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

interface Props {
  onCancel: () => void
}
const { Title, Text } = Typography

const SelectModal = styled(Modal)`
  width: 100%;
  .ant-modal-footer {
    border-top: 0px;
    padding-top: 12px;
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 24px;
  }
`
const ModalTitleWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
`

const ModalTitle = styled(Title)`
  && {
    margin-bottom: 0px;
  }
`
const ModalTitleExplanation = styled.div`
  margin-top: 8px;
`

const GridVariableRadioGroup = styled(Radio.Group)`
  display: flex;
  flex-direction: column;
`

const SelectGridVariableModal: FC<Props> = ({ onCancel }) => {
  const history = useHistory()
  const [hasVariables, setHasVariables] = useState(false)

  return (
    <SelectModal
      title={
        <ModalTitleWrap>
          <ModalTitle level={4}>取り込み項目の設定</ModalTitle>
          <ModalTitleExplanation>
            <Text style={{ fontSize: '14px' }}>
              表形式のひな形では、日々変動する項目を「取り込み項目」として設定することが出来ます。
            </Text>
          </ModalTitleExplanation>
        </ModalTitleWrap>
      }
      centered
      style={{ minWidth: '1000px' }}
      visible
      onOk={() => {
        history.push({
          pathname: '/templates/pages/addGridTemplate',
          search: `hasVariables=${hasVariables}`,
        })
      }}
      onCancel={() => onCancel()}
      okText="作成"
      cancelText="キャンセル"
    >
      <>
        <GridVariableRadioGroup
          onChange={e => setHasVariables(e.target.value)}
          value={hasVariables}
        >
          <Radio value={false} style={{ fontSize: '14px' }}>
            取り込み項目 <strong>なし</strong> で設定
          </Radio>
          <Radio value={true} style={{ marginTop: '8px', fontSize: '14px' }}>
            取り込み項目 <strong>あり</strong> で設定
          </Radio>
        </GridVariableRadioGroup>
      </>
    </SelectModal>
  )
}

export default SelectGridVariableModal
