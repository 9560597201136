// prettier-ignore
export const ActionTypes = {
  REQUEST_RESET_SCHEDULE :'harami/schedules/REQUEST_RESET_SCHEDULE',
  REQUEST_GET_SCHEDULE :'harami/schedules/REQUEST_GET_SCHEDULE',
  SUCCESS_GET_SCHEDULE :'harami/schedules/SUCCESS_GET_SCHEDULE',
  ERROR_GET_SCHEDULE :'harami/schedules/ERROR_GET_SCHEDULE',
  REQUEST_CHANGE_SCHEDULES_PAGE :'harami/schedules/REQUEST_CHANGE_SCHEDULES_PAGE',
  REQUEST_CHANGE_SCHEDULES_SIZE :'harami/schedules/REQUEST_CHANGE_SCHEDULES_SIZE',
  UPDATE_PAGINATION :'harami/schedules/UPDATE_PAGINATION',

  REQUEST_ADD_SCHEDULE :'harami/schedules/REQUEST_ADD_SCHEDULE',
  SUCCESS_ADD_SCHEDULE :'harami/schedules/SUCCESS_ADD_SCHEDULE',
  ERROR_ADD_SCHEDULE :'harami/schedules/ERROR_ADD_SCHEDULE',

  REQUEST_UPDATE_SCHEDULE :'harami/schedules/REQUEST_UPDATE_SCHEDULE',
  SUCCESS_UPDATE_SCHEDULE :'harami/schedules/SUCCESS_UPDATE_SCHEDULE',
  ERROR_UPDATE_SCHEDULE :'harami/schedules/ERROR_UPDATE_SCHEDULE',

  REQUEST_COPY_SCHEDULE :'harami/schedules/REQUEST_COPY_SCHEDULE',
  SUCCESS_COPY_SCHEDULE :'harami/schedules/SUCCESS_COPY_SCHEDULE',
  ERROR_COPY_SCHEDULE :'harami/schedules/ERROR_COPY_SCHEDULE',

  REQUEST_DELETE_SCHEDULE :'harami/schedules/REQUEST_DELETE_SCHEDULE',
  SUCCESS_DELETE_SCHEDULE :'harami/schedules/SUCCESS_DELETE_SCHEDULE',
  ERROR_DELETE_SCHEDULE :'harami/schedules/ERROR_DELETE_SCHEDULE',

  REQUEST_DELETE_SCHEDULES :'harami/schedules/REQUEST_DELETE_SCHEDULES',

  REQUEST_GET_SCHEDULES :'harami/schedules/REQUEST_GET_SCHEDULES',
  SUCCESS_GET_SCHEDULES :'harami/schedules/SUCCESS_GET_SCHEDULES',
  ERROR_GET_SCHEDULES :'harami/schedules/ERROR_GET_SCHEDULES',

  DOWNLOAD_SCHEDULE_QR_CODES :'harami/schedules/DOWNLOAD_SCHEDULE_QR_CODES',

  /**
   * schedulesTemplates
   */
  RESET_SCHEDULES_TEMPLATES: 'harami/schedules/RESET_SCHEDULES_TEMPLATES',
  REQUEST_GET_SCHEDULES_TEMPLATES: 'harami/schedules/REQUEST_GET_SCHEDULES_TEMPLATES',
  SUCCESS_GET_SCHEDULES_TEMPLATES: 'harami/schedules/SUCCESS_GET_SCHEDULES_TEMPLATES',
  ERROR_GET_SCHEDULES_TEMPLATES: 'harami/schedules/ERROR_GET_SCHEDULES_TEMPLATES',
} as const

/**
 * スケジュール一覧取得時のフィルタ条件（OpenAPI Clientにそのまま渡せる形式）
 */
export interface GetSchedulesFilter {
  scheduleName?: { $like: string }
  templateId?: { $in: number[] }
  placeNodeId?: { $in: string[] }
  status?: { $in: number[] }
}
