import {
  DeleteOutlined,
  DownloadOutlined,
  EditOutlined,
  FileSearchOutlined,
} from '@ant-design/icons'
import { css } from '@emotion/react'
import { ReactNode } from 'react'
import { Danger, Primary } from 'src/features/theme/KdsThemeColor'

type ButtonType = 'delete' | 'download' | 'edit' | 'viewDetail'

type Props = {
  onClick?: () => void
  type: ButtonType
}

type Setting = {
  iconComponent: ReactNode
  color: string
  text: string
}

const settings: Record<ButtonType, Setting> = {
  delete: {
    iconComponent: <DeleteOutlined />,
    color: Danger,
    text: '削除',
  },
  download: {
    iconComponent: <DownloadOutlined />,
    color: Primary,
    text: 'ダウンロード',
  },
  edit: {
    iconComponent: <EditOutlined />,
    color: Primary,
    text: '編集',
  },
  viewDetail: {
    iconComponent: <FileSearchOutlined />,
    color: Primary,
    text: '詳細を見る',
  },
}

/**
 * テーブルの各行にホバーしたときに行の右端に表示されるタイプのボタン（e.g. 編集、削除など）
 *
 * 🚨このUIは主にアクセシビリティなどの点で問題を抱えているので、新規の利用は非推奨。
 */
export const RowActionButton = (props: Props) => {
  const { onClick, type } = props

  const { iconComponent, color, text } = settings[type]

  return (
    <button
      css={[styles.container, css({ background: color })]}
      onClick={onClick}
    >
      {iconComponent}
      {text}
    </button>
  )
}

const styles = {
  container: css`
    align-items: center;
    border: none;
    color: white;
    display: flex;
    gap: 8px;
    height: 100%;
    justify-content: center;
    min-width: 120px;
    padding: 0 16px;
    white-space: nowrap;

    &:hover {
      cursor: pointer;
      filter: brightness(110%);
    }
  `,
}
