import { useCallback, useEffect, useState } from 'react'

/**
 * リスト内のアイテムの選択状態を管理するためのフック
 */
export const useItemSelection = <T = number>(
  // この値が変更されたときには選択状態をリセットする
  resetTrigger = '',
) => {
  const [selectedItemIds, setSelectedItemIds] = useState<T[]>([])

  const count = selectedItemIds.length
  const isSomeSelected = count > 0

  const select = useCallback((itemIds: T[]) => {
    setSelectedItemIds(itemIds)
  }, [])

  const reset = useCallback(() => {
    setSelectedItemIds([])
  }, [])

  useEffect(() => {
    reset()
  }, [reset, resetTrigger])

  return {
    count,
    ids: selectedItemIds,
    isSomeSelected,
    reset,
    select,
  }
}
