import { ApprovalFlow } from '@ulysses-inc/harami_api_client'
import { List, Modal, Row, Typography } from 'antd'
import React, { useState } from 'react'
import { RowActionButton } from 'src/components/rowActionButton/RowActionButton'
import {
  ModalDeleteButtonColor,
  TableBorderColor,
  White,
} from 'src/features/theme/KdsThemeColor'
import styled from 'styled-components'

const { Text } = Typography

const ButtonGroupRow = styled.div`
  position: absolute;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: row;
  height: 100%;
  width: 240px;
  right: 0;
  background-color: ${White};
`

const TableBody = styled<any>(List)`
  width: 100%;
`

const TableRowWrap = styled(List.Item)`
  word-break: break-all;
  position: relative;
  min-height: 46px;
  background-color: ${White};
`

const TableRow = styled.div`
  width: ${props => props.theme.width};
  padding-left: 16px;
`

const ApprovalFlowsHeaderContainer = styled(Row)`
  margin: 15px;
`

const ApprovalFlowsHeaderWrap = styled.div`
  width: 100%;
`

const ApprovalFlowsHeader = styled.div`
  width: 100%;
  height: 46px;
  display: flex;
  align-items: center;
  flex-direction: row;
  background: ${White};
  border-bottom: 1px solid ${TableBorderColor};
`

const HeaderRow = styled.div`
  font-weight: bold;
  padding-left: 15px;
  width: ${props => props.theme.width};
`

const DescriptionText = styled(Text)`
  width: 100%;
`

interface OwnProps {
  approvalFlows: ApprovalFlow[]
  onEdit: (approvalFlowId: string) => void
  onDelete: (approvalFlowId: string) => void
}

const ApprovalFlowList: React.FC<OwnProps> = (props: OwnProps) => {
  const [activeApprovalFlowId, setActiveApprovalFlowId] = useState<string>('')
  return (
    <ApprovalFlowsHeaderContainer justify="center">
      <ApprovalFlowsHeaderWrap>
        <ApprovalFlowsHeader>
          <HeaderRow theme={{ width: '35%' }}>承認フロー名</HeaderRow>
          <HeaderRow theme={{ width: '65%' }}>説明</HeaderRow>
        </ApprovalFlowsHeader>
        <TableBody
          itemLayout="horizontal"
          dataSource={props.approvalFlows}
          renderItem={(approvalFlow: ApprovalFlow) => (
            <TableRowWrap
              onMouseEnter={() =>
                setActiveApprovalFlowId(approvalFlow.uuid ?? '')
              }
              onMouseLeave={() => setActiveApprovalFlowId('')}
            >
              <TableRow theme={{ width: '35%' }}>{approvalFlow.name}</TableRow>
              <TableRow theme={{ width: '65%' }}>
                <DescriptionText ellipsis>
                  {approvalFlow.description}
                </DescriptionText>
              </TableRow>
              {activeApprovalFlowId === approvalFlow.uuid && (
                <ButtonGroupRow>
                  <RowActionButton
                    onClick={() => props.onEdit(approvalFlow.uuid ?? '')}
                    type="edit"
                  />
                  <RowActionButton
                    onClick={() => {
                      Modal.confirm({
                        onOk: () => {
                          props.onDelete(approvalFlow.uuid ?? '')
                        },
                        title: `「${approvalFlow.name}」の削除を実行しますがよろしいですか？`,
                        okText: '削除',
                        cancelText: 'キャンセル',
                        okButtonProps: {
                          style: {
                            backgroundColor: ModalDeleteButtonColor,
                            border: 'none',
                          },
                        },
                      })
                    }}
                    type="delete"
                  />
                </ButtonGroupRow>
              )}
            </TableRowWrap>
          )}
        />
      </ApprovalFlowsHeaderWrap>
    </ApprovalFlowsHeaderContainer>
  )
}

export default ApprovalFlowList
