import { useMutation } from '@tanstack/react-query'
import { ReportsApi } from '@ulysses-inc/harami_api_client'
import { baseClient } from 'src/state/middleware/saga/baseClient'

export const useMutationDeleteReport = () => {
  return useMutation({
    mutationFn: async (reportUuid: string) => {
      await baseClient.getApi(ReportsApi).deleteReport({ reportId: reportUuid })
    },
  })
}
