import { View } from '@react-pdf/renderer'
import {
  ReportNodeSchema,
  TemplateNodeTypeEnum,
} from '@ulysses-inc/harami_api_client'
import React from 'react'
import filterNodesAnsweredBranch from 'src/exShared/util/reportResult/filterNodesAnsweredBranch'
import ReportSummaryQuestion from 'src/features/reports/result/downloadPDF/reportSummaryQuestion/ReportSummaryQuestion'
import ReportSummarySection from 'src/features/reports/result/downloadPDF/reportSummarySection/ReportSummarySection'
import { isDefined } from 'src/util/idDefined'

interface OwnProps {
  node: ReportNodeSchema
  nodes: { [key: number]: ReportNodeSchema }
}

interface MergeProps {
  childNodes: Array<ReportNodeSchema>
  isSectionPrevQuestionIds: Array<number>
}

type ReportSummaryLogicProps = OwnProps & MergeProps

const ReportSummaryLogic: React.FC<ReportSummaryLogicProps> = (
  props: ReportSummaryLogicProps,
) => {
  return (
    <View>
      {props.childNodes.map((node: ReportNodeSchema) => {
        if (node.type === TemplateNodeTypeEnum.Logic)
          return (
            <ReportSummaryLogicContainer
              key={node.id}
              node={node}
              nodes={props.nodes}
            />
          )
        if (node.type === TemplateNodeTypeEnum.Section)
          return (
            <ReportSummarySection
              key={node.id}
              node={node}
              nodes={props.nodes}
              parentPath=""
            />
          )
        if (node.type === TemplateNodeTypeEnum.Question)
          return (
            <ReportSummaryQuestion
              key={node.id}
              node={node}
              nodes={props.nodes}
              isFirst={false} // 分岐前と分岐後の間にスペースを入れないので isFirst を false に固定する
              isSectionPrev={props.isSectionPrevQuestionIds.includes(node.id)}
            />
          )
        return null
      })}
    </View>
  )
}

const ReportSummaryLogicContainer: React.FC<OwnProps> = (_props: OwnProps) => {
  const nodeNodes = _props.node.nodes ?? []
  const allNodes = _props.nodes
  const childNodes = filterNodesAnsweredBranch(
    nodeNodes.map((id: number) => allNodes[id]).filter(isDefined),
    allNodes,
  )
  const mergeProps: MergeProps = {
    childNodes,
    isSectionPrevQuestionIds: childNodes
      .filter(
        (_, index: number) =>
          childNodes[index - 1]?.type === TemplateNodeTypeEnum.Section,
      )
      .map((node: ReportNodeSchema) => node.id),
  }
  const props = { ..._props, ...mergeProps }
  return <ReportSummaryLogic {...props} />
}

export default ReportSummaryLogicContainer
