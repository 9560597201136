import { Row } from '../types'

/**
 * rowに1セルでも入力値があればtrueを返す。
 * 入力が1セルもない場合はfalseを返す。
 *
 * @param row
 */
export const hasInput = (row: Row) => {
  if (row.from.dateValue !== '') {
    return true
  }
  if (row.through.dateValue !== '') {
    return true
  }
  if (row.sectionName.name !== '') {
    return true
  }
  if (row.variableSection.name !== '') {
    return true
  }

  for (const value of Object.values(row.values)) {
    if (value.value !== '') {
      return true
    }
  }

  return false
}
