import { LayoutOutlined } from '@ant-design/icons'
import { Tag } from 'antd'
import styled from 'styled-components'
import { Black, GridTemplateLabelColor } from '../theme/KdsThemeColor'

export const LayoutIcon = styled(LayoutOutlined)`
  margin-right: 5px;
`

export const GridTemplateLabel = styled(Tag)`
  color: ${Black};
  background-color: ${GridTemplateLabelColor};
  font-size: 12px;
  height: 18px;
  width: 44px;
  display: inline-flex;
  align-items: center;
  margin-left: 8px;
  padding: 3px 4px;
  border: none;
`
