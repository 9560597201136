import { Schedule } from '@ulysses-inc/harami_api_client'
import { Tag } from 'antd'
import { FC, useState } from 'react'
import { useSelector } from 'react-redux'
import dateUtil from 'src/exShared/util/date'
import { useLimitedFeatureAvailability } from 'src/features/featureAvailability/useLimitedFeatureAvailability'
import { Disable, Primary } from 'src/features/theme/KdsThemeColor'
import { RootState } from 'src/state/store'
import ActionButtons from './ActionButtons'
import CheckboxRow from './CheckboxRow'
import {
  ImproveOptionTag,
  PauseCircleOutlinedIcon,
  PlayCircleOutlinedIcon,
  TableRow,
  TableRowWrap,
} from './ScheduleList.styled'
import { TChooseScheduleUUIDsState } from './ScheduleListContent'

export type TBaseColumnProps<
  T extends Record<string, unknown> = Record<string, unknown>,
> = { schedule: Schedule } & T

const isActiveBadge = (isActive?: boolean) => {
  if (isActive)
    return (
      <Tag color={Primary}>
        <PlayCircleOutlinedIcon />
        アクティブ
      </Tag>
    )
  return (
    <Tag color={Disable}>
      <PauseCircleOutlinedIcon />
      停止中
    </Tag>
  )
}

const ScheduleListRow: FC<
  TChooseScheduleUUIDsState & {
    schedule: Schedule
  }
> = ({ schedule, chooseScheduleUUIDsState }) => {
  const { isFeatureAvailable } = useLimitedFeatureAvailability()

  const [activeScheduleId, setActiveScheduleId] = useState<number>()

  const templates = useSelector(
    (state: RootState) => state.schedulesState.schedulesTemplates.templates,
  )

  const firstScheduleTemplate = (schedule.templates ?? [])[0]
  const isAudit = templates.find(
    item => item.id === (firstScheduleTemplate?.id ?? 0),
  )?.isAudit

  return (
    <TableRowWrap
      onMouseEnter={() => setActiveScheduleId(schedule.id)}
      onMouseLeave={() => setActiveScheduleId(undefined)}
    >
      <CheckboxRow
        schedule={schedule}
        chooseScheduleUUIDsState={chooseScheduleUUIDsState}
      />
      <TableRow theme={{ width: '35%' }}>
        {schedule.name}
        {isFeatureAvailable('improves') && isAudit && (
          <ImproveOptionTag>カイゼン</ImproveOptionTag>
        )}
      </TableRow>
      <TableRow theme={{ width: '35%' }}>
        {firstScheduleTemplate?.name ?? '【削除済み】'}
        {isFeatureAvailable('improves') && isAudit && (
          <ImproveOptionTag>カイゼン</ImproveOptionTag>
        )}
      </TableRow>
      <TableRow theme={{ width: '10%' }}>
        {isActiveBadge(schedule.isActive)}
      </TableRow>
      <TableRow theme={{ width: '10%' }}>
        {dateUtil.formatYYYYMMDD_locale(
          new Date(schedule.startReportDate ?? ''),
        )}
      </TableRow>
      <TableRow theme={{ width: '10%' }}>
        {schedule.endReportDate &&
          dateUtil.formatYYYYMMDD_locale(
            new Date(schedule.endReportDate ?? ''),
          )}
      </TableRow>
      {activeScheduleId === schedule.id && (
        <ActionButtons schedule={schedule} />
      )}
    </TableRowWrap>
  )
}

export default ScheduleListRow
