import {
  ErrorCodeEnum,
  Template,
  TemplateGridVariable,
  TemplateGridVariableRow,
  TemplateGridVariablesApi,
  TemplateGridVariableValue,
  TemplatePage,
  TemplatesApi,
} from '@ulysses-inc/harami_api_client'
import { all, call, put, select, takeEvery } from 'redux-saga/effects'
import { ROW_COUNT } from 'src/features/gridVariables/edit/constants'
import * as notificationServiceActions from 'src/features/notificationService/slice'
import interceptionsActions from 'src/state/ducks/interceptions/actions'
import BaseClient from 'src/state/middleware/saga/baseClient'
import {
  handleHTTPError,
  HTTPError,
} from 'src/state/middleware/saga/handleHttpError'
import { RootState } from 'src/state/store'
import {
  errorFetchGridVariablesTable,
  errorUpdateGridVariablesTable,
  requestFetchGridVariablesTable,
  requestUpdateGridVariablesTable,
  setPatchGridVariablesError,
  successFetchGridVariablesTable,
  successUpdateGridVariablesTable,
} from './actions'
import { SoftLimitErrorCode } from './reducers'
import { ActionTypes, Row, SectionsByName, Table } from './types'

/**
 * 取り込み項目詳細画面のテーブルの元になるデータを取得する
 */
export function* fetchGridVariablesTable(
  action: ReturnType<typeof requestFetchGridVariablesTable>,
) {
  try {
    const { templateId } = action

    // 3 つの API は呼ぶ順番を考慮する必要がないので、並列で呼び出す
    const [template, templatePages, templateGridVariableRows]: [
      Template,
      TemplatePage[],
      TemplateGridVariableRow[],
    ] = yield all([
      call(callGetTemplate, templateId),
      call(callPostTemplatePagesV2, templateId),
      call(callGetTemplateGridVariables, templateId),
    ])

    yield put(
      successFetchGridVariablesTable(
        template,
        templatePages,
        templateGridVariableRows,
      ),
    )
  } catch (error) {
    yield put(interceptionsActions.handleHttpError(error as HTTPError))
    yield put(errorFetchGridVariablesTable(error as Error))
  }
}

const isSoftLimitErrorCode = (
  code: ErrorCodeEnum,
): code is SoftLimitErrorCode => {
  return (
    code === ErrorCodeEnum.MaxTableCountExceededInGridVariablesError ||
    code ===
      ErrorCodeEnum.MaxSubstantialCellCountExceededInGridVariablesError ||
    code === ErrorCodeEnum.MaxRowCountExceededInGridVariablesError
  )
}
/**
 * 取り込み項目詳細画面のテーブルのデータを保存する（更新含む）
 */
export function* updateGridVariablesTable(
  action: ReturnType<typeof requestUpdateGridVariablesTable>,
) {
  try {
    const { templateId } = action
    const [originalTable, inputTable, sectionsByName]: [
      Table,
      Table,
      SectionsByName,
    ] = yield all([
      select(
        (state: RootState) =>
          state.editGridVariablesState.editGridVariables.originalTable,
      ),
      select(
        (state: RootState) =>
          state.editGridVariablesState.editGridVariables.inputTable,
      ),
      select(
        (state: RootState) =>
          state.editGridVariablesState.editGridVariables.sectionsByName,
      ),
    ])

    const templateGridVariable = createPatchRequest(
      originalTable,
      inputTable,
      sectionsByName,
    )
    yield callPatchGridVariables(templateId, templateGridVariable)

    yield put(successUpdateGridVariablesTable())
    yield put(
      notificationServiceActions.showNotification({
        message: '取り込み項目詳細の保存に成功しました',
      }),
    )
  } catch (error) {
    const httpError = error as HTTPError
    const errMsg = httpError.errors && httpError.errors[0]
    const code = httpError.code

    if (httpError.status === 400 && errMsg && isSoftLimitErrorCode(code)) {
      yield put(setPatchGridVariablesError(errMsg, code))
    } else {
      yield put(interceptionsActions.handleHttpError(error as HTTPError))
      yield put(errorUpdateGridVariablesTable(error as Error))
    }
  }
}

export default [
  takeEvery(
    ActionTypes.REQUEST_FETCH_GRID_VARIABLES_TABLE,
    fetchGridVariablesTable,
  ),
  takeEvery(
    ActionTypes.REQUEST_UPDATE_GRID_VARIABLES_TABLE,
    updateGridVariablesTable,
  ),
]

// 以下は export せず、このファイル内でのみ使うヘルパー

const baseClient = new BaseClient()

/**
 * GET /templates/{templateId} を呼ぶ
 */
const callGetTemplate = (templateId: number) => {
  return baseClient
    .getApi(TemplatesApi)
    .getTemplate({ templateId })
    .then(res => res)
    .catch(handleHTTPError)
}
/**
 * POST /v2/templates/{templateId}/pages を呼ぶ
 */
const callPostTemplatePagesV2 = (templateId: number) => {
  return baseClient
    .getApi(TemplatesApi)
    .getTemplatePagesV2({ templateId, templateFilter: {} })
    .then(res => res)
    .catch(handleHTTPError)
}

/**
 * GET /templates/{templateId}/variables を呼ぶ
 */
const callGetTemplateGridVariables = (templateId: number) => {
  return baseClient
    .getApi(TemplateGridVariablesApi)
    .getTemplateGridVariables({ templateId })
    .then(res => res)
    .catch(handleHTTPError)
}

/**
 * PATCH /templates/{templateId}/variables を呼ぶ
 */
const callPatchGridVariables = (
  templateId: number,
  templateGridVariable: TemplateGridVariable,
) => {
  return baseClient
    .getApi(TemplateGridVariablesApi)
    .patchTemplateGridVariables({ templateId, templateGridVariable })
    .then(res => res)
    .catch(handleHTTPError)
}

/**
 * patchメソッドのリクエスト情報を作成する
 *
 * @param originalTable GET時点の取り込み項目詳細情報
 * @param inputTable 画面編集後の取り込み項目詳細情報
 * @param sectionsByName セクションの連想配列(キーはセクション名)
 * @returns 差分(追加/更新情報, 削除行)
 */
const createPatchRequest = (
  originalTable: Table,
  inputTable: Table,
  sectionsByName: SectionsByName,
): TemplateGridVariable => {
  const rows: TemplateGridVariableRow[] = []
  const clearedRowNumbers: number[] = []

  Array.from({ length: ROW_COUNT }).forEach((_, index) => {
    const rowNumber = index + 1
    const originalRow = originalTable[rowNumber]
    const inputRow = inputTable[rowNumber]

    if (!originalRow && !inputRow) {
      // 空行のままなのでスキップ
      return
    }

    if (!inputRow) {
      // 既存行の削除が行われている場合
      clearedRowNumbers.push(rowNumber)
      return
    }

    if (!originalRow) {
      // 新規の行追加が行われている場合
      rows.push(convertTypeFromStateToAPI(rowNumber, inputRow, sectionsByName))
      return
    }

    if (!inputRow.hasChanged) {
      // 変更がないためスキップ
      return
    }
    // 既存行の変更が行われている場合
    rows.push(convertTypeFromStateToAPI(rowNumber, inputRow, sectionsByName))
  })

  return {
    rows,
    clearedRowNumbers,
  }
}

/**
 * 取り込み項目詳細の行単位のState型をAPIの型に変換する
 * @param rowNumber 行番号
 * @param row 行情報(state型)
 * @param sectionsByName セクションの連想配列(キーはセクション名)
 * @returns 行情報(API型)
 */
const convertTypeFromStateToAPI = (
  rowNumber: number,
  row: Row,
  sectionsByName: SectionsByName,
): TemplateGridVariableRow => {
  const apiValues = Object.keys(row.values)
    .map(key => {
      const gridQuestionNumber = Number(key)
      const value = row.values[gridQuestionNumber]
      if (!value) {
        return undefined
      }

      return {
        gridQuestionUUID: value.uuid,
        gridQuestionNumber,
        value: value.value,
      }
    })
    // undefinedを除去するためのfilter
    .filter((item): item is TemplateGridVariableValue => !!item)

  if (!row.from.dateValue || !row.through.dateValue) {
    // 必須エラーがない状態でしか保存操作は行えないので、ここが通ることはない前提
    throw new Error(`from/throughのDateがundefinedです rowNumber=${rowNumber}`)
  }

  // ユーザーから入力されたセクション名をキーに、セクション uuid を見つけ出す
  const gridSectionUUID = sectionsByName[row.sectionName.name]?.uuid
  if (!gridSectionUUID) {
    // ここを通ることは実装ミス以外ありえない
    throw new Error(
      `sectionUUIDがundefinedです rowNumber=${rowNumber} row.sectionName.name=${row.sectionName.name}`,
    )
  }

  return {
    gridSectionUUID,
    orderNumber: rowNumber,
    variableSection: row.variableSection.name,
    from: new Date(row.from.dateValue),
    through: new Date(row.through.dateValue),
    values: apiValues,
  }
}
