import { StyleSheet, Text, View } from '@react-pdf/renderer'
import { NoAnswer as NoAnswerType } from '@ulysses-inc/harami_api_client'
import React from 'react'
import BreakText from '../../BreakText'
import Col from '../../Col'
import Grid from '../../Grid'
import RecordedTime from './RecordedTime'

interface OwnProps {
  questionName: string
  noAnswer: NoAnswerType
  recordedAt?: Date
  isTimeDisplayed: boolean
}

const styles = StyleSheet.create({
  gridLeft: { width: '70%' },
  gridRight: { width: '30%' },
  numberGrid: { width: '100%' },
  answerNumberValueWrap: { width: '100%', marginBottom: 5 },
  answerNumberValueText: { paddingLeft: 5, paddingTop: 5 },
  answerNumberRule: { color: '#656565', paddingLeft: 5 },
  recordedTimeWrap: { width: '100%', marginBottom: 5 },
})

const NoAnswer: React.FC<OwnProps> = (props: OwnProps) => {
  return (
    <Grid style={styles.numberGrid}>
      <Col borderLeft borderRight style={styles.gridLeft}>
        <BreakText text={props.questionName} />
      </Col>
      <Col borderRight style={{ ...styles.gridRight }}>
        <View style={styles.answerNumberValueWrap}>
          <Text style={styles.answerNumberValueText}>回答: 該当なし</Text>
          {props.noAnswer.reason?.length > 0 && (
            <Text style={styles.answerNumberRule}>
              理由: {props.noAnswer.reason}
            </Text>
          )}
        </View>
        <View style={styles.recordedTimeWrap}>
          <RecordedTime
            isTimeDisplayed={props.isTimeDisplayed}
            recordedAt={props.recordedAt}
          />
        </View>
      </Col>
    </Grid>
  )
}

export default NoAnswer
