import { SendEmail } from '@ulysses-inc/harami_api_client'
import { ActionTypes } from './types'

const requestSendEmail = (sendEmail: SendEmail, message: string) => {
  return {
    type: ActionTypes.REQUEST_SEND_EMAIL,
    isLoading: true,
    sendEmail,
    message,
  }
}

const successSendEmail = () => {
  return {
    type: ActionTypes.SUCCESS_SEND_EMAIL,
    isLoading: false,
  }
}

const errorSendEmail = (error: Error) => {
  return {
    type: ActionTypes.ERROR_SEND_EMAIL,
    isLoading: false,
    error,
  }
}

// 未利用
// export type SendEmailAction =
//   | ReturnType<typeof requestSendEmail>
//   | ReturnType<typeof successSendEmail>
//   | ReturnType<typeof errorSendEmail>

export default {
  requestSendEmail,
  successSendEmail,
  errorSendEmail,
}
