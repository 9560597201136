import { useQuery } from '@tanstack/react-query'
import { PlacesApi } from '@ulysses-inc/harami_api_client'
import { queryKeys } from 'src/features/reports/assignPlaceModal/api/queryKeys'
import { baseClient } from 'src/state/middleware/saga/baseClient'
import invariant from 'tiny-invariant'

type SimplifiedPlace = {
  name: string
  uuid: string
}

type Option = {
  enabled: boolean
}

export const useQueryPlaces = ({ enabled }: Option) =>
  useQuery({
    enabled,
    queryKey: queryKeys.places,
    queryFn: () =>
      baseClient.getApi(PlacesApi).getPlacesV2({ placeFilter: {} }),
    select: (data): SimplifiedPlace[] =>
      (data.placeNodes || []).map(placeNode => {
        invariant(placeNode.uuid && placeNode.place?.name)
        return {
          name: placeNode.place.name,
          uuid: placeNode.uuid,
        }
      }),
    gcTime: 0,
  })
