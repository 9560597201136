import { CheckCircleOutlined } from '@ant-design/icons'
import { Typography } from 'antd'
import { Disable, Primary, Success } from 'src/features/theme/KdsThemeColor'
import styled from 'styled-components'

const { Text } = Typography

export const ApprovalFlowStepContainer = styled.div`
  border-radius: 8px;
  border: 2px solid rgba(0, 0, 0, 0.12);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  margin-top: 24px;
  padding: 12px;
`

export const ApproverName = styled(Text)`
  font-weight: bold;
  margin: 0 auto 0 8px;
  padding-right: 12px;
`

export const ApproverNameDisabled = styled(Text)`
  color: ${Disable};
  font-weight: bold;
  margin-left: 28px;
`

export const StatusTextApproved = styled(Text)`
  color: ${Primary};
  font-weight: bold;
  white-space: nowrap;
`

export const StatusTextPending = styled(Text)`
  font-weight: bold;
  white-space: nowrap;
`

export const ApprovalTime = styled.div`
  color: rgba(0, 0, 0, 0.54);
  font-size: 12px;
  font-weight: bold;
  margin: 8px 0 0 auto;
  text-align: right;
`

export const ApproverWithStatusRow = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
`

export const ApprovalButtons = styled.div`
  display: flex;
  justify-content: space-evenly;
  padding: 8px 0 8px;
  width: 100%;
`

export const IconApproved = styled(CheckCircleOutlined)`
  color: ${Success};
  font-size: 20px;
`
