import { StopTwoTone } from '@ant-design/icons'
import { Result } from 'antd'
import { ReactNode } from 'react'

type Props = {
  children: ReactNode
}

export const UAGuard = ({ children }: Props) => {
  const userAgent = window.navigator.userAgent.toLowerCase()

  // 対応 UserAgent を下記に限定する
  // (1) ブラウザが iOS 版 Chrome のもの、かつ、端末が iPad のもの
  const isIPadChrome =
    userAgent.indexOf('crios') !== -1 && userAgent.indexOf('ipad') !== -1

  // (2) ブラウザが Chrome (Microsoft Edgeは除く) のもの
  const isChrome =
    userAgent.indexOf('chrome') !== -1 && userAgent.indexOf('edg') === -1

  // (3) ブラウザが Microsoft Edge (Chromium版) のもの
  // ref: https://learn.microsoft.com/ja-jp/microsoft-edge/web-platform/user-agent-guidance
  const isEdge =
    userAgent.indexOf('chrome/') !== -1 &&
    userAgent.indexOf('edg/') !== -1 && // Microsoft Edge (Chromium版)
    userAgent.indexOf('edge/') === -1 && // Microsoft Edge (EdgeHTML版) は除く
    userAgent.indexOf('edga/') === -1 && // Microsoft Edge (Android版) は除く
    userAgent.indexOf('edgios/') === -1 // Microsoft Edge (iOS版) は除く

  const isSupportedUA = isIPadChrome || isChrome || isEdge
  if (isSupportedUA === false) {
    return (
      <>
        <Result
          icon={<StopTwoTone twoToneColor="red" />}
          title="このブラウザは現在サポートされていません"
          subTitle="Google Chromeをインストールしてご利用ください"
        />
      </>
    )
  }

  return children
}
