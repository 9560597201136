import { StyleSheet } from '@react-pdf/renderer'

export const reportDetailLayout = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
    fontFamily: 'Nasu',
    fontSize: 10,
  },
  scoringTitle: {
    fontSize: 14,
  },
  textPadding: {
    padding: 5,
  },
})
