import { combineReducers } from 'redux'
import { editDeviateCommentReducer } from 'src/state/ducks/templates/editDeviateComment/reducer'
import { editDeviateNotifyReducer } from 'src/state/ducks/templates/editDeviateNotify/reducer'
import { editHintReducer } from 'src/state/ducks/templates/editHint/reducer'
import { editLogicReducer } from 'src/state/ducks/templates/editLogic/reducer'
import { editSerialNumberReducer } from 'src/state/ducks/templates/editSerialNumber/reducer'
import { editTemplateReducer } from 'src/state/ducks/templates/editTemplate/reducer'
import { responseMultipleChoicesReducer } from 'src/state/ducks/templates/responseMultipleChoices/reducer'
import { templateHintsReducer } from 'src/state/ducks/templates/templateHints/reducer'
import { templatePagesReducer } from 'src/state/ducks/templates/templatePages/reducer'

/**
 * ひな形詳細画面で利用するReducer群
 */
const templateDetailReducer = combineReducers({
  /**
   * ひな形のメタデータ
   */
  editTemplate: editTemplateReducer,
  /**
   * ひな形のページ (=実際のコンテンツ部分)
   */
  templatePages: templatePagesReducer,
  /**
   * ひな形のヒント
   */
  templateHints: templateHintsReducer,
  /**
   * 選択肢
   */
  responseMultipleChoices: responseMultipleChoicesReducer,
  /**
   * 「条件分岐の設定」モーダル
   */
  editLogic: editLogicReducer,
  /**
   * 「ヒントの編集」モーダル
   */
  editHint: editHintReducer,
  /**
   * 「逸脱時警告メッセージの編集」ドロワー
   */
  editDeviateComment: editDeviateCommentReducer,
  /**
   * 「逸脱時通知先の編集」モーダル
   */
  editDeviateNotify: editDeviateNotifyReducer,
  /**
   * エクセル変換の「繰り返し出力」ラベル関連
   */
  editSerialNumber: editSerialNumberReducer,
})

export default templateDetailReducer
