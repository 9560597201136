import { DeleteOutlined, PlusCircleOutlined } from '@ant-design/icons'
import {
  ExcelConversionScheduleStartupTypeEnum,
  ExcelConversionStep,
  ExcelConversionStepDirectionEnum,
  ExcelConversionStepTypeEnum,
  ScheduleForExcelConversionFlow,
  TemplateNodeForExcelConversionFlow,
} from '@ulysses-inc/harami_api_client'
import {
  Button,
  Card,
  Col,
  Divider,
  Input,
  Row,
  Select,
  Tooltip,
  Typography,
} from 'antd'
import React, { useEffect, useState } from 'react'
import { Danger, Gray, White } from 'src/features/theme/KdsThemeColor'
import styled, { keyframes } from 'styled-components'

const { Text } = Typography
const { Option } = Select

const ModifyButton = styled(Button)`
  width: 100%;
  margin-bottom: 15px;
`

const StepTypeSelect = styled(Select)`
  width: 160px;
  .ant-select-selector {
    padding: 0 0 !important;
  }
  .ant-select-selection {
    width: 100%;
    height: 100%;
  }
  .ant-select-selection-item {
    padding-right: 0 !important;
  }
  .ant-select-selection__rendered {
    height: 100%;
    width: 100%;
    margin-left: 0px;
  }
  .ant-select-selection-selected-value {
    height: 100%;
    width: 100%;
    display: flex !important;
    align-items: center;
  }
`

const StepTypeOption = styled.div`
  width: 100%;
  padding-left: 8px;
  border-radius: 1.5px;
  background: ${({ theme }) => theme.backgroundColor};
  color: ${({ theme }) => theme.color};
  font-weight: bold;
`

const FormLabel = styled.div`
  margin-bottom: 5px;
`

const AppearAnimation = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`
const Appearance = styled.div`
  animation: ${AppearAnimation} 0.6s linear 1;
`

const ClickCard = styled.div`
  height: 250px;
  width: 100%;
  position: absolute;
  left: 0;
`

interface OwnProps {
  activeClickCard: boolean
  schedules: ScheduleForExcelConversionFlow[]
  sheetNames: string[]
  step: ExcelConversionStep
  updateStep: (step: ExcelConversionStep) => void
  deleteStep: () => void
  startupType: ExcelConversionScheduleStartupTypeEnum
  activeScheduleUUID?: string
  setActiveScheduleUUID: (uuid?: string) => void
  isLoading: boolean
  isFirstStep: boolean
  cardLength: number
  index: number
  addExcelConversionSettings: (index: number) => void
  tooltip: boolean
  handleactiveClickCard: (index: number) => void
}

type ExcelConversionStepTypes = {
  [key: number]: {
    name: string
    color: string
    background: string
  }
}

const excelConversionStepTypes: ExcelConversionStepTypes = {
  [ExcelConversionStepTypeEnum.Single]: {
    name: '単数質問出力',
    color: White,
    background: '#389E0D',
  },
  [ExcelConversionStepTypeEnum.Multiple]: {
    name: '連続質問出力',
    color: Gray,
    background: '#F2C94C',
  },
  [ExcelConversionStepTypeEnum.Repeat]: {
    name: '繰り返し出力',
    color: Gray,
    background: '#F2994A',
  },
  [ExcelConversionStepTypeEnum.Period]: {
    name: '期間出力',
    color: White,
    background: '#1890FF',
  },
}

const getStepTypes = (schedule: ScheduleForExcelConversionFlow) => {
  const result: { [key: string]: StepTypeOption } = {}
  schedule.nodes &&
    schedule.nodes.forEach(node => {
      const typeInfo = excelConversionStepTypes[node.excelConversionType]
      if (!typeInfo) {
        return
      }
      if (
        node.excelConversionType === ExcelConversionStepTypeEnum.Multiple ||
        node.excelConversionType === ExcelConversionStepTypeEnum.Repeat
      ) {
        if (!node.serialNumber) {
          return
        }
        result[`${node.excelConversionType}_${node.serialNumber}`] = {
          ...typeInfo,
          type: node.excelConversionType,
          serialNumber: node.serialNumber,
        }
      } else {
        result[`${node.excelConversionType}_`] = {
          ...typeInfo,
          type: node.excelConversionType,
          serialNumber: undefined,
        }
      }
    })
  return Object.values(result).sort((a, b) => {
    return a.type - b.type
  })
}

interface StepTypeOption {
  type: ExcelConversionStepTypeEnum
  serialNumber: number | undefined
  name: string
  color: string
  background: string
}

const ExcelConversionStepSetting: React.FC<OwnProps> = (props: OwnProps) => {
  const [stepTypeOptions, setStepTypeOptions] = useState<StepTypeOption[]>([])
  const [singleTypeTemplateNodes, setSingleTypeTemplateNodes] = useState<
    TemplateNodeForExcelConversionFlow[]
  >([])
  const [isDeletedSchedule, setIsDeletedSchedule] = useState<boolean>(false)

  useEffect(() => {
    if (props.step.scheduleUuid && !props.isLoading) {
      const schedule = props.schedules.find(
        schedule => schedule.uuid === props.step.scheduleUuid,
      )
      if (!schedule) {
        props.updateStep({
          ...props.step,
          scheduleUuid: undefined,
          type: undefined,
          serialNumber: undefined,
        })
        setIsDeletedSchedule(true)
        setStepTypeOptions([])
        setSingleTypeTemplateNodes([])
        return
      }
      setIsDeletedSchedule(false)
      const stepTypes = getStepTypes(schedule)
      setStepTypeOptions(stepTypes)
      const singleTypeTemplateNodes = schedule.nodes
        ? schedule.nodes.filter(
            node =>
              node.excelConversionType === ExcelConversionStepTypeEnum.Single,
          )
        : []
      setSingleTypeTemplateNodes(singleTypeTemplateNodes)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.step.scheduleUuid, props.isLoading])

  const getSheetName = () => {
    switch (props.step.type) {
      case ExcelConversionStepTypeEnum.Single:
        return props.step.singleStep?.excelSheetName || ''
      case ExcelConversionStepTypeEnum.Multiple:
        return props.step.multipleStep?.excelSheetName || ''
      case ExcelConversionStepTypeEnum.Repeat:
        return props.step.repeatStep?.excelSheetName || ''
      case ExcelConversionStepTypeEnum.Period:
        return props.step.periodStep?.excelSheetName || ''
    }
    return ''
  }

  const getStartCell = () => {
    switch (props.step.type) {
      case ExcelConversionStepTypeEnum.Single:
        return props.step.singleStep?.excelStartCell || ''
      case ExcelConversionStepTypeEnum.Multiple:
        return props.step.multipleStep?.excelStartCell || ''
      case ExcelConversionStepTypeEnum.Repeat:
        return props.step.repeatStep?.excelStartCell || ''
      case ExcelConversionStepTypeEnum.Period:
        return props.step.periodStep?.excelStartCell || ''
    }
    return ''
  }

  const getDirection = () => {
    switch (props.step.type) {
      case ExcelConversionStepTypeEnum.Single:
        return undefined
      case ExcelConversionStepTypeEnum.Multiple:
        return props.step.multipleStep?.direction || undefined
      case ExcelConversionStepTypeEnum.Repeat:
        return props.step.repeatStep?.direction || undefined
      case ExcelConversionStepTypeEnum.Period:
        return props.step.periodStep?.direction || undefined
    }
    return undefined
  }

  const selectValue = props.step.type
    ? `${props.step.type}_${props.step.serialNumber || 0}`
    : undefined
  const isExistsOption = stepTypeOptions.find(
    stepType =>
      selectValue === `${stepType.type}_${stepType.serialNumber || 0}`,
  )
  const hasSerialNumberTypes = [
    ExcelConversionStepTypeEnum.Multiple,
    ExcelConversionStepTypeEnum.Repeat,
  ]

  const handleSettings = () => {
    props.handleactiveClickCard(props.index)
  }

  // antdesignのTooltipで改行表示させるための関数
  const tooltipMessage = (first: string, second: string) => {
    return (
      <>
        {first}
        <br />
        {second}
      </>
    )
  }

  return (
    <Appearance>
      <Card style={{ width: '100%', marginBottom: '15px' }}>
        <ClickCard onClick={() => handleSettings()} />
        {/* 変換設定カードが2枚ある時のみツールチップを出したいための条件 */}
        {props.index === 0 && props.tooltip && (
          <Tooltip
            title={() =>
              tooltipMessage(
                'クリックすると、変換設定の',
                '追加ボタンが表示されます',
              )
            }
          >
            <ClickCard onClick={() => handleSettings()} />
          </Tooltip>
        )}
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginBottom: 10,
          }}
        >
          <Text strong>変換元</Text>
          <DeleteOutlined
            style={{ fontSize: 20, color: Danger, zIndex: 1 }}
            onClick={() => {
              props.isFirstStep && props.setActiveScheduleUUID(undefined)
              props.deleteStep()
            }}
          ></DeleteOutlined>
        </div>
        <Row gutter={16}>
          <Col style={{ display: 'flex', flexDirection: 'column' }} span={6}>
            <FormLabel>
              <Text strong>スケジュール</Text>
            </FormLabel>
            <Select
              showSearch
              optionFilterProp="children"
              value={
                isDeletedSchedule
                  ? 'deletedSchedule'
                  : props.step?.scheduleUuid || undefined
              }
              onChange={(value: string) => {
                props.setActiveScheduleUUID(value)
                props.updateStep({
                  ...props.step,
                  scheduleUuid: value,
                  type: undefined,
                  serialNumber: undefined,
                })
              }}
              onClick={() => handleSettings()}
            >
              {props.schedules
                .filter(schedule => {
                  if (
                    props.startupType !==
                    ExcelConversionScheduleStartupTypeEnum.OnCompletedReport
                  ) {
                    return true
                  }
                  if (!props.activeScheduleUUID) {
                    return true
                  }
                  return props.activeScheduleUUID === schedule.uuid
                })
                .map((schedule, index) => {
                  return (
                    <Option key={index} value={schedule.uuid}>
                      {schedule.name}
                    </Option>
                  )
                })}
              {isDeletedSchedule && (
                <Option key={'deletedSchedule'} value={'deletedSchedule'}>
                  スケジュールが無効です
                </Option>
              )}
            </Select>
          </Col>
          <Col style={{ display: 'flex', flexDirection: 'column' }} span={6}>
            <FormLabel>
              <Text strong>変換種類</Text>
            </FormLabel>
            <StepTypeSelect
              value={isExistsOption ? selectValue : undefined}
              onChange={type => {
                if (typeof type === 'string') {
                  const serialNumber = Number(type.split('_')[1]) || undefined
                  props.updateStep({
                    ...props.step,
                    type: Number(type.split('_')[0]),
                    serialNumber,
                  })
                }
              }}
              onClick={() => handleSettings()}
            >
              {stepTypeOptions.map(
                (stepType: StepTypeOption, index: number) => {
                  return (
                    <Option
                      key={index}
                      value={`${stepType.type}_${stepType.serialNumber || 0}`}
                    >
                      <StepTypeOption
                        theme={{
                          backgroundColor: stepType.background,
                          color: stepType.color,
                        }}
                      >
                        <div>
                          {stepType.name}
                          {hasSerialNumberTypes.includes(stepType.type)
                            ? stepType.serialNumber
                            : ''}
                        </div>
                      </StepTypeOption>
                    </Option>
                  )
                },
              )}
            </StepTypeSelect>
          </Col>
          {props.step.type === ExcelConversionStepTypeEnum.Single && (
            <Col style={{ display: 'flex', flexDirection: 'column' }} span={6}>
              <FormLabel>
                <Text strong>追加質問名</Text>
              </FormLabel>
              <Select
                value={props.step.singleStep?.templateNodeUUID}
                onChange={(value: string) => {
                  const singleStep = props.step.singleStep
                    ? props.step.singleStep
                    : {}
                  props.updateStep({
                    ...props.step,
                    singleStep: { ...singleStep, templateNodeUUID: value },
                  })
                }}
              >
                {singleTypeTemplateNodes.map((node, index) => {
                  return (
                    <Option key={index} value={node.uuid}>
                      {node.questionName}
                    </Option>
                  )
                })}
              </Select>
            </Col>
          )}
        </Row>
        <Divider />
        <FormLabel>
          <Text strong>変換先</Text>
        </FormLabel>
        <Row gutter={16}>
          <Col style={{ display: 'flex', flexDirection: 'column' }} span={6}>
            <FormLabel>
              <Text strong>シート名</Text>
            </FormLabel>
            <Select
              showSearch
              optionFilterProp="children"
              value={getSheetName()}
              onChange={(value: string) => {
                switch (props.step.type) {
                  case ExcelConversionStepTypeEnum.Single: {
                    const singleStep = props.step.singleStep
                      ? props.step.singleStep
                      : {}
                    props.updateStep({
                      ...props.step,
                      singleStep: { ...singleStep, excelSheetName: value },
                    })
                    break
                  }
                  case ExcelConversionStepTypeEnum.Multiple: {
                    const multipleStep = props.step.multipleStep
                      ? props.step.multipleStep
                      : {}
                    props.updateStep({
                      ...props.step,
                      multipleStep: { ...multipleStep, excelSheetName: value },
                    })
                    break
                  }
                  case ExcelConversionStepTypeEnum.Repeat: {
                    const repeatStep = props.step.repeatStep
                      ? props.step.repeatStep
                      : {}
                    props.updateStep({
                      ...props.step,
                      repeatStep: { ...repeatStep, excelSheetName: value },
                    })
                    break
                  }
                  case ExcelConversionStepTypeEnum.Period: {
                    const periodStep = props.step.periodStep
                      ? props.step.periodStep
                      : {}
                    props.updateStep({
                      ...props.step,
                      periodStep: { ...periodStep, excelSheetName: value },
                    })
                    break
                  }
                }
              }}
              onClick={() => handleSettings()}
            >
              {props.sheetNames &&
                props.sheetNames.length &&
                props.sheetNames.map((sheetName: string, index: number) => {
                  return (
                    <Option key={index} value={sheetName}>
                      {sheetName}
                    </Option>
                  )
                })}
            </Select>
          </Col>
          <Col style={{ display: 'flex', flexDirection: 'column' }} span={3}>
            <FormLabel>
              <Text strong>開始セル</Text>
            </FormLabel>
            <Input
              value={getStartCell()}
              onChange={e => {
                const value = e.target.value ? e.target.value.toUpperCase() : ''
                switch (props.step.type) {
                  case ExcelConversionStepTypeEnum.Single: {
                    const singleStep = props.step.singleStep
                      ? props.step.singleStep
                      : {}
                    props.updateStep({
                      ...props.step,
                      singleStep: { ...singleStep, excelStartCell: value },
                    })
                    break
                  }
                  case ExcelConversionStepTypeEnum.Multiple: {
                    const multipleStep = props.step.multipleStep
                      ? props.step.multipleStep
                      : {}
                    props.updateStep({
                      ...props.step,
                      multipleStep: { ...multipleStep, excelStartCell: value },
                    })
                    break
                  }
                  case ExcelConversionStepTypeEnum.Repeat: {
                    const repeatStep = props.step.repeatStep
                      ? props.step.repeatStep
                      : {}
                    props.updateStep({
                      ...props.step,
                      repeatStep: { ...repeatStep, excelStartCell: value },
                    })
                    break
                  }
                  case ExcelConversionStepTypeEnum.Period: {
                    const periodStep = props.step.periodStep
                      ? props.step.periodStep
                      : {}
                    props.updateStep({
                      ...props.step,
                      periodStep: { ...periodStep, excelStartCell: value },
                    })
                    break
                  }
                }
              }}
              onClick={() => handleSettings()}
            />
          </Col>
          {props.step.type !== ExcelConversionStepTypeEnum.Single && (
            <Col style={{ display: 'flex', flexDirection: 'column' }} span={3}>
              <FormLabel>
                <Text strong>追加方向</Text>
              </FormLabel>
              <Select
                value={getDirection()}
                onChange={(chooseValue: number) => {
                  const value =
                    chooseValue === ExcelConversionStepDirectionEnum.Vertical
                      ? ExcelConversionStepDirectionEnum.Vertical
                      : ExcelConversionStepDirectionEnum.Horizontal
                  switch (props.step.type) {
                    case ExcelConversionStepTypeEnum.Single:
                      break
                    case ExcelConversionStepTypeEnum.Multiple: {
                      const multipleStep = props.step.multipleStep
                        ? props.step.multipleStep
                        : {}
                      props.updateStep({
                        ...props.step,
                        multipleStep: { ...multipleStep, direction: value },
                      })
                      break
                    }
                    case ExcelConversionStepTypeEnum.Repeat: {
                      const repeatStep = props.step.repeatStep
                        ? props.step.repeatStep
                        : {}
                      props.updateStep({
                        ...props.step,
                        repeatStep: { ...repeatStep, direction: value },
                      })
                      break
                    }
                    case ExcelConversionStepTypeEnum.Period: {
                      const periodStep = props.step.periodStep
                        ? props.step.periodStep
                        : {}
                      props.updateStep({
                        ...props.step,
                        periodStep: { ...periodStep, direction: value },
                      })
                      break
                    }
                  }
                }}
              >
                <Option value={ExcelConversionStepDirectionEnum.Vertical}>
                  縦
                </Option>
                <Option value={ExcelConversionStepDirectionEnum.Horizontal}>
                  横
                </Option>
              </Select>
            </Col>
          )}
        </Row>
      </Card>
      {props.activeClickCard && (
        <ModifyButton
          type="dashed"
          onMouseDown={() => {
            props.addExcelConversionSettings(props.index)
          }}
        >
          <PlusCircleOutlined />
          変換設定の追加
        </ModifyButton>
      )}
    </Appearance>
  )
}

export default ExcelConversionStepSetting
