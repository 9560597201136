import { TemplateNodeSchema } from '@ulysses-inc/harami_api_client'
import { ActionTypes } from 'src/state/ducks/templates/editLogic/types'

export const showEditMultipleChoiceLogic = (node: TemplateNodeSchema) => {
  return {
    type: ActionTypes.SHOW_EDIT_MULTIPLE_CHOICE_LOGIC,
    node,
  }
}

export const showEditNumberLogic = (node: TemplateNodeSchema) => {
  return {
    type: ActionTypes.SHOW_EDIT_NUMBER_LOGIC,
    node,
  }
}

/**
 * 数値による条件分岐の設定モーダル、および選択肢による条件分岐の設定モーダルを閉じる
 */
export const closeEditLogic = () => {
  return {
    type: ActionTypes.CLOSE_EDIT_LOGIC,
  }
}

export type EditLogicAction =
  | ReturnType<typeof showEditMultipleChoiceLogic>
  | ReturnType<typeof showEditNumberLogic>
  | ReturnType<typeof closeEditLogic>
