import { DropTargetMonitor, XYCoord } from 'react-dnd'
import { HoveringPosition } from './DnDTemplateType'

export class DnDUtils {
  public static getHoveringPosition(
    ref: React.RefObject<HTMLDivElement>,
    monitor: DropTargetMonitor,
  ): HoveringPosition {
    // Determine rectangle on screen
    const hoverBoundingRect = ref.current!.getBoundingClientRect()

    // Get vertical middle
    const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2

    // Determine mouse position
    const clientOffset = monitor.getClientOffset()

    // Get pixels to the top
    const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top

    return hoverClientY < hoverMiddleY ? 'TOP' : 'BOTTOM'
  }
}
