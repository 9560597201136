import {
  DeleteImproveRequest,
  GetImproves,
  GetImprovesV2Request,
  ImprovesApi,
} from '@ulysses-inc/harami_api_client'
import { call, put } from 'redux-saga/effects'
import * as notificationServiceActions from 'src/features/notificationService/slice'
import BaseClient from 'src/state/middleware/saga/baseClient'
import {
  HTTPError,
  handleHTTPError,
} from 'src/state/middleware/saga/handleHttpError'
import interceptionsActions from '../../interceptions/actions'
import { improves as improvesActions } from '../actions'

const baseClient = new BaseClient()

const getImprovesRequest = (req: GetImprovesV2Request) => {
  return baseClient
    .getApi(ImprovesApi)
    .getImprovesV2(req)
    .then(improves => improves)
    .catch(handleHTTPError)
}

const deleteImproveRequest = (req: DeleteImproveRequest) => {
  return baseClient
    .getApi(ImprovesApi)
    .deleteImprove(req)
    .catch(handleHTTPError)
}

function* getImproves(action: ReturnType<typeof improvesActions.getImproves>) {
  try {
    const params: GetImprovesV2Request = {
      ...action.request,
      improveFilter: { ...action.filter },
    }
    const response: GetImproves = yield call(getImprovesRequest, params)
    yield put(improvesActions.getSuccessImproves(response))
  } catch (error) {
    yield put(interceptionsActions.handleHttpError(error as HTTPError))
    yield put(improvesActions.getErrorImproves(error as Error))
  }
}

function* deleteImprove(
  action: ReturnType<typeof improvesActions.deleteImprove>,
) {
  try {
    yield call(deleteImproveRequest, { improveUUID: action.improveUUID })
    yield put(improvesActions.deleteSuccessImprove(action.improveUUID))
    yield put(
      notificationServiceActions.showNotification({
        message: 'カイゼンを削除しました',
      }),
    )
  } catch (error) {
    yield put(interceptionsActions.handleHttpError(error as HTTPError))
    yield put(improvesActions.deleteErrorImprove(error as Error))
  }
}

export { deleteImprove, getImproves }
