import {
  DownloadOutlined,
  ImportOutlined,
  PlusCircleOutlined,
} from '@ant-design/icons'
import { Button, Typography } from 'antd'
import { ParseResult, parse } from 'papaparse'
import React, { FC, useRef, useState } from 'react'
import { CSVLink } from 'react-csv'
import FormikErrorMessage from 'src/components/formikErrorMessage/FormikErrorMessage'
import ResponseSetTable from 'src/features/template/multipleChoiceSets/ResponseSetTable'
import { ResponseSetForm } from 'src/features/template/multipleChoiceSets/ResponseSetTypes'
import {
  CSVImportButton,
  CSVImportButtonGroup,
  CSVImportInput,
  FormLabel,
} from './EditMultipleChoiceSet.dumb'
import { useFormikProps } from './EditMultipleChoiceSet.hooks'

const sampleFileDownloadCSVData = [
  ['判別キー', 'カテゴリー', '選択肢名'],
  ['key1', 'category1', '選択肢1'],
  ['key2', 'category1,category2', '選択肢2'],
  ['key3', 'category2,category3', '選択肢3'],
]
const csvRowLength = 3
// 最大行数を1000行に固定した。
// レコード数に上限を設けておかないとmobile側での表示にも影響が出ると考えられるため一定ラインの上限は必要
const csvRowMaxCount = 1000

const { Text } = Typography

const OptionsRow: FC = () => {
  const {
    formikProps: {
      setFieldValue,
      setFieldError,
      setFieldTouched,
      values,
      errors,
      touched,
    },
  } = useFormikProps()

  const inputEl = useRef<HTMLInputElement>(null)
  const [isLoadingCsvImport, setIsLoadingCsvImport] = useState<boolean>(false)

  const parseResponsesFromFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsLoadingCsvImport(true)

    const file = e.target.files?.item(0)

    if (file) {
      parse(file, {
        complete: ({ data }: ParseResult<string[]>) => {
          if (data.length > csvRowMaxCount) {
            setFieldError(
              'responses',
              `csv取り込みの最大行数は${csvRowMaxCount}件までです`,
            )
            setFieldTouched('responses', true, false)
            setIsLoadingCsvImport(false)
            return
          }

          const responseSetForms: ResponseSetForm[] = data
            .slice(1)
            .filter(({ length }) => length === csvRowLength)
            .map((row, index) => ({
              key: index + 1,
              discriminationKey: row[0],
              categories: row[1]?.split(',').map((category, i) => ({
                name: category,
                key: `response${index}-category${i}`,
              })),
              name: row[2],
            }))

          setIsLoadingCsvImport(false)
          setFieldValue('responses', responseSetForms)
        },
      })
    }

    e.target.value = ''
  }

  const onDeleteResponseSet = (key: number) => {
    setFieldValue(
      'responses',
      values.responses?.filter(({ key: k }) => k !== key),
    )
  }

  const onChangeResponseSetDiscriminationKey = (
    discriminationKey: string,
    key: number,
  ) => {
    setFieldValue(
      'responses',
      values.responses?.map(response =>
        response.key !== key ? response : { ...response, discriminationKey },
      ),
    )
  }

  const onChangeResponseSetName = (name: string, key: number) => {
    setFieldValue(
      'responses',
      values.responses?.map(response =>
        response.key !== key ? response : { ...response, name },
      ),
    )
  }

  const onConfirmResponseSetCategoryTag = (
    responseKey: number,
    tagName: string,
  ) => {
    setFieldValue(
      'responses',
      values.responses?.map((response, i) =>
        response.key !== responseKey
          ? response
          : {
              ...response,
              categories: [
                ...(response.categories ?? []),
                {
                  name: tagName,
                  key: `response${i}-category${
                    (response.categories ?? []).length
                  }`,
                },
              ],
            },
      ),
    )
  }

  const onCloseResponseSetTag = (responseKey: number, tagIndex: number) => {
    setFieldValue(
      'responses',
      values.responses?.map(response =>
        response.key !== responseKey
          ? response
          : {
              ...response,
              categories: (response.categories ?? []).filter(
                (_, categoryTagIndex) => categoryTagIndex !== tagIndex,
              ),
            },
      ),
    )
  }

  const addResponse = () => {
    setFieldValue('responses', [
      ...values.responses,
      {
        name: '',
        discriminationKey: '',
        key: (values.responses?.slice(-1)[0]?.key ?? 0) + 1,
        categories: [],
      },
    ])
  }

  return (
    <>
      <FormLabel>
        <Text strong>選択肢</Text>
      </FormLabel>
      <CSVImportButtonGroup>
        <CSVImportButton
          type="primary"
          onClick={() => inputEl?.current?.click()}
        >
          <ImportOutlined />
          CSVインポート
        </CSVImportButton>
        <CSVImportInput
          type="file"
          ref={inputEl}
          onChange={parseResponsesFromFile}
          accept=".csv"
        />
        <CSVLink
          data={sampleFileDownloadCSVData}
          filename="セット選択肢サンプル.csv"
        >
          <Button type="primary">
            <DownloadOutlined />
            サンプルダウンロード
          </Button>
        </CSVLink>
      </CSVImportButtonGroup>
      <div>
        <ResponseSetTable
          isLoadingResponses={isLoadingCsvImport}
          responses={values.responses}
          onDelete={onDeleteResponseSet}
          onChangeDiscriminationKey={onChangeResponseSetDiscriminationKey}
          onChangeName={onChangeResponseSetName}
          onInputConfirmTag={onConfirmResponseSetCategoryTag}
          onCloseTag={onCloseResponseSetTag}
        />
        <Button type="link" onMouseDown={addResponse}>
          <PlusCircleOutlined />
          選択肢を追加
        </Button>
      </div>
      <div>
        <FormikErrorMessage
          name="responses"
          errors={errors}
          touched={touched}
        />
      </div>
    </>
  )
}

export default OptionsRow
