import { css } from '@emotion/react'
import { Tag } from 'antd'
import { Report } from 'src/features/reports/listBeta/api/useQueryReports'

type Props = {
  status: Report['status']
}

export const Status = ({ status }: Props) => {
  switch (status) {
    case 'inCompleted': {
      const label = '未完了'
      return (
        <div aria-label={label}>
          <Tag css={styles.errorTag}>{label}</Tag>
        </div>
      )
    }
    case 'approvalPending': {
      const label = '承認待ち'
      return (
        <div aria-label={label}>
          <Tag css={styles.warningTag}>{label}</Tag>
        </div>
      )
    }
    case 'approvalRemand': {
      const label = '差し戻し'
      return (
        <div aria-label={label}>
          <Tag css={styles.errorTag}>{label}</Tag>
        </div>
      )
    }
    case 'completed': {
      const label = '完了'
      return (
        <div aria-label={label}>
          <Tag css={styles.successTag}>{label}</Tag>
        </div>
      )
    }
    default: {
      return null
    }
  }
}

const styles = {
  container: css`
    align-items: center;
    display: flex;
  `,
  text: css`
    margin-left: 4px;
  `,
  errorTag: css`
    border: none;
    color: #f03e3e;
    background-color: #fff5f5;
  `,
  successTag: css`
    border: none;
    color: #37b24d;
    background-color: #ebfbee;
  `,
  warningTag: css`
    border: none;
    color: #f59f00;
    background-color: #fff9db;
  `,
}
