const validFilterValuesOf = {
  deviatedAnswer: ['present', 'absent'] as const,
  status: [
    'inCompleted',
    'approvalPending',
    'approvalRemand',
    'completed',
  ] as const,
}

type DeviatedAnswerFilterValue =
  (typeof validFilterValuesOf.deviatedAnswer)[number]
type StatusFilterValue = (typeof validFilterValuesOf.status)[number]

export const isValidFilterValueOf = {
  deviatedAnswer: (testee: unknown): testee is DeviatedAnswerFilterValue => {
    return validFilterValuesOf.deviatedAnswer.some(v => v === testee)
  },
  status: (testee: unknown): testee is StatusFilterValue => {
    return validFilterValuesOf.status.some(v => v === testee)
  },
}

/**
 * レポート一覧を取得するにあたってAPIに指定できるフィルタ条件
 */
export type ReportListFilters = {
  dateFrom: string | null
  dateTo: string | null
  deviatedAnswer: DeviatedAnswerFilterValue[]
  employeeNameOrCode: string
  page: number
  pageSize: number
  placeNodeUuids: string[]
  reportName: string
  statuses: StatusFilterValue[]
}
