import moment, { Moment } from 'moment'
import React from 'react'
import { MomentDatePicker } from 'src/components/datepicker/MomentDatePicker'
import FormikErrorMessage from 'src/components/formikErrorMessage/FormikErrorMessage'
import { TBaseFormRowProps } from './EditSchedule'
import {
  FormLabel,
  FormRow,
  Text,
  TimePickerArea,
  TimePickerMargin,
} from './EditSchedule.styled'

const timeFormat = 'HH:mm'

const SchedulePeriodPerDayRow: React.FC<TBaseFormRowProps> = ({
  setFieldValue,
  values: { startTime, endTime },
  errors,
  submitCount,
}) => {
  const start = startTime !== undefined ? moment(startTime) : undefined
  const end = endTime !== undefined ? moment(endTime) : undefined
  const onChange = (type: 'start' | 'end') => (date: Moment | null) => {
    if (type == 'end') {
      setFieldValue(
        'endTime',
        getEndTime(start, date ? date : undefined)?.toDate(),
      )
    } else {
      setFieldValue('startTime', date?.toDate())
      // 開始時刻を変更した場合でも、開始時刻と終了時刻が一致しているかを確認し、
      // 一致する場合は終了時刻を 1 分引くように変更する必要がある
      setFieldValue(
        'endTime',
        getEndTime(date ? date : undefined, end)?.toDate(),
      )
    }
  }

  return (
    <FormRow>
      <FormLabel>
        <Text strong>時刻</Text>
      </FormLabel>
      <TimePickerArea>
        <div>
          <MomentDatePicker.TimePicker
            format={timeFormat}
            value={start}
            onChange={onChange('start')}
            placeholder="開始時刻"
          />
          <div>
            {submitCount > 0 && (
              <FormikErrorMessage name="startTime" errors={errors} />
            )}
          </div>
        </div>
        <TimePickerMargin>〜</TimePickerMargin>
        <div>
          <MomentDatePicker.TimePicker
            format={timeFormat}
            value={end}
            onChange={onChange('end')}
            placeholder="終了時刻"
          />
          <div>
            {submitCount > 0 && (
              <FormikErrorMessage name="endTime" errors={errors} />
            )}
          </div>
        </div>
      </TimePickerArea>
    </FormRow>
  )
}

const getEndTime = (startTime?: moment.Moment, endTime?: moment.Moment) => {
  // 開始時刻と終了時刻が同じ場合は 24 時間を設定しようとしていると見なして終了時刻から 1 分引く
  // e.g. 21:00 〜 21:00 -> 21:00 〜 20:59
  if (
    startTime &&
    endTime &&
    startTime.hour() === endTime.hour() &&
    startTime.minute() === endTime.minute()
  ) {
    return endTime.add(-1, 'm')
  } else {
    return endTime
  }
}

export default SchedulePeriodPerDayRow
