import { InfoCircleOutlined } from '@ant-design/icons'
import { FC } from 'react'
import {
  IconWrapper,
  Inner,
  Message,
  MessageArea,
  Root,
  Title,
} from './ErrorMessage.dumb'

export const ErrorMessage: FC = () => {
  return (
    <Root
      initial={{ y: '-100%' }}
      animate={{ y: 0 }}
      transition={{ duration: 0.3, ease: 'backOut' }}
    >
      <Inner>
        <IconWrapper>
          <InfoCircleOutlined />
        </IconWrapper>
        <MessageArea>
          <Title>
            ログインに失敗しました。正しいログインIDとパスワードを入力するか、管理者に連絡してください。
          </Title>
          <Message>
            ※2023年に改訂されたID/パスワードポリシーに準拠していないユーザーはログインできません。管理者に連絡し、ID/パスワードの変更を依頼してください。
          </Message>
        </MessageArea>
      </Inner>
    </Root>
  )
}
