import { css } from '@emotion/react'
import { Button, notification, Upload } from 'antd'
import type { UploadFile } from 'antd/es/upload/interface'
import { useState } from 'react'
import { useMutationUploadCsvFile } from 'src/features/userImport/api/useMutationUploadCsvFile'
import { CommonWrapper } from 'src/features/userImport/components/CommonWrapper'
import { CsvValidationErrorMessage } from 'src/features/userImport/components/CsvValidationErrorMessage'
import { DraggerAreaMessages } from 'src/features/userImport/components/DraggerAreaMessages'
import { ResultOfFailure } from 'src/features/userImport/components/ResultOfFailure'
import { ResultOfSuccess } from 'src/features/userImport/components/ResultOfSuccess'
import { SampleCsvDownloadButton } from 'src/features/userImport/components/SampleCsvDownloadButton'
import invariant from 'tiny-invariant'

type Screen =
  | 'initial' // CSVファイルを選択する画面
  | 'upload_succeed' // アップロードが成功したことを通知する画面
  | 'upload_failed' // アップロードが失敗したことを通知する画面

/**
 * CSVファイルのバリデーションに失敗したときのエラー
 */
export class CsvValidationError extends Error {
  public errorMessages: string[]

  static {
    this.prototype.name = 'CsvValidationError'
  }

  constructor(errorMessages: string[]) {
    super('CSVファイルのバリデーションに失敗しました')
    this.errorMessages = errorMessages
  }
}
/**
 * ユーザー一括追加画面
 */
export const UserImport = () => {
  const [screen, setScreen] = useState<Screen>('initial')

  const [targetFile, setTargetFile] = useState<UploadFile | null>(null)
  const isTargetFileSelected = targetFile !== null

  const [targetFileHasSomeError, setTargetFileHasSomeError] = useState(false)

  // 単にファイル名の部分を赤字で表示したいがための処理である。
  // antdの仕様ではfileの実体がクラスインスタンスなので、こう書くほかない。
  if (isTargetFileSelected && targetFileHasSomeError) {
    targetFile.status = 'error'
  }

  const { mutate: uploadCsvFile, isPending: isUploading } =
    useMutationUploadCsvFile()

  const handeUploadCsvFileError = async (e: Error) => {
    switch (true) {
      case e instanceof CsvValidationError: {
        notification.error({
          message: <CsvValidationErrorMessage messages={e.errorMessages} />,
          duration: 15,
        })
        setTargetFileHasSomeError(true)
        break
      }

      // ネットワークエラー等を想定
      default: {
        setScreen('upload_failed')
      }
    }
  }

  const onClickUploadButton = () => {
    // 単に型の都合 (UploadFile型の実体は File オブジェクトである点に留意)
    invariant(
      targetFile instanceof File,
      'CSVファイルが選択されていないのに送信ができる状態になっているか、' +
        '(通常想定されませんが)値が不正です',
    )

    uploadCsvFile(targetFile, {
      onSuccess: () => setScreen('upload_succeed'),
      onError: handeUploadCsvFileError,
    })
  }

  switch (screen) {
    case 'initial': {
      return (
        <CommonWrapper>
          <div css={styles.instructionAndButton}>
            <div css={styles.instructionText}>
              一括追加のためのCSVファイルをアップロードしてください
            </div>
            <SampleCsvDownloadButton />
          </div>

          <Upload.Dragger
            accept=".csv"
            beforeUpload={file => {
              setTargetFile(file)
              setTargetFileHasSomeError(false)

              // このantdのUploadコンポーネントは、あくまでアップロードするべき
              // CSVファイルを選択する目的にのみ使用しており、
              // 実際にアップロードを行う機能は使用しないため、無効化しておく。
              // https://4x.ant.design/components/upload/#components-upload-demo-upload-manually
              return false
            }}
            // このコンポーネントでは最大で1つのファイルしか選択できないコードにしているが、
            // antdのAPIの都合で配列で与える必要がある。
            fileList={isTargetFileSelected ? [targetFile] : []}
            multiple={false}
            onRemove={() => {
              setTargetFile(null)
              setTargetFileHasSomeError(false)
            }}
          >
            <DraggerAreaMessages />
          </Upload.Dragger>

          <div css={styles.uploadButtonRow}>
            <Button
              disabled={!isTargetFileSelected}
              loading={isUploading}
              onClick={onClickUploadButton}
              type="primary"
            >
              アップロード
            </Button>
          </div>
        </CommonWrapper>
      )
    }

    case 'upload_succeed': {
      return (
        <CommonWrapper>
          <ResultOfSuccess />
        </CommonWrapper>
      )
    }

    case 'upload_failed': {
      return (
        <CommonWrapper>
          <ResultOfFailure onClickRetryButton={() => setScreen('initial')} />
        </CommonWrapper>
      )
    }
  }
}

const styles = {
  instructionAndButton: css`
    align-items: center;
    display: flex;
    gap: 16px;
    justify-content: center;
    margin-bottom: 16px;
  `,
  instructionText: css`
    font-weight: bold;
  `,
  uploadButtonRow: css`
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;
  `,
}
