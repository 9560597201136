import {
  TemplateHint,
  TemplateNodeSchema,
  TemplateNodeTypeEnum,
  TemplatePageSchema,
} from '@ulysses-inc/harami_api_client'
import { Typography } from 'antd'
import React from 'react'
import styled from 'styled-components'
import AddTemplateModifyButtonGroup from '../itemAppender/AddTemplateModifyButtonGroup'
import QuestionContainer from '../question/Question'
import SectionContainer from '../section/Section'
import QuestionHeader from './QuestionHeader'
import './animation.scss'

const TemplatePageContainer = styled.div`
  overflow-x: auto;
`

const calcTemplatePageWrapWidth = (theme: { hintCount: number }) => {
  // ヒント1つまでは横スクロールなしで収まるようにする
  if (theme.hintCount > 1) {
    return `calc(100% + ${theme.hintCount - 1} * 200px)`
  } else {
    return 'calc(100%)'
  }
}

const TemplatePageWrap = styled.div`
  width: ${({ theme }) => calcTemplatePageWrapWidth(theme)};
  padding-right: 20px;
`

const { Title } = Typography

interface OwnProps {
  templatePage: TemplatePageSchema
  templatePageName: string
  hintCount: number
  activeNodeId: number
  isShowScore: boolean
  isExcelConversion: boolean
  templateHints: Array<TemplateHint>
  useTemplatePageNodes: (
    templatePage: TemplatePageSchema,
  ) => Array<TemplateNodeSchema>
  addTemplateNode: (
    targetNode: TemplateNodeSchema | null,
    parentNode: TemplateNodeSchema | null,
    position: 'top' | 'bottom',
    addTemplateNodeType: TemplateNodeTypeEnum,
  ) => void
  updateTemplatePage: (
    templatePageId: number,
    changeTemplatePage: TemplatePageSchema,
  ) => void
}

const AddTemplatePageContent: React.FC<OwnProps> = (props: OwnProps) => {
  const templateNodes = props.useTemplatePageNodes(props.templatePage)
  return (
    <>
      <Title
        level={4}
        editable={{
          onChange: (text: string) => {
            props.updateTemplatePage(props.templatePage.id ?? 0, {
              ...props.templatePage,
              name: text,
            })
          },
        }}
        aria-label="ページ名"
      >
        {props.templatePageName}
      </Title>
      <TemplatePageContainer>
        <TemplatePageWrap theme={{ hintCount: props.hintCount }}>
          <QuestionHeader
            templateHints={props.templateHints}
            isShowScore={props.isShowScore}
            isExcelConversion={props.isExcelConversion}
          />
          {templateNodes.length === 0 ? (
            <AddTemplateModifyButtonGroup
              isOpen={true}
              node={null}
              position="bottom"
              parentNode={null}
              addTemplateNode={props.addTemplateNode}
            />
          ) : (
            templateNodes.map((node, index) => {
              if (node.type === TemplateNodeTypeEnum.Question) {
                return (
                  <QuestionContainer
                    key={node.id}
                    node={node}
                    path={[]}
                    parentNode={null}
                    isActive={props.activeNodeId === node.id}
                    isLastChild={templateNodes.length === index + 1}
                  />
                )
              }
              return (
                <SectionContainer
                  key={node.id}
                  node={node}
                  parentNode={null}
                  path={[]}
                  isActive={props.activeNodeId === node.id}
                />
              )
            })
          )}
        </TemplatePageWrap>
      </TemplatePageContainer>
    </>
  )
}

export default AddTemplatePageContent
