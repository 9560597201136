import {
  ResponseTypeEnum,
  TemplateLayoutTypeEnum,
  TemplateNodeTypeEnum,
} from '@ulysses-inc/harami_api_client'
import { RootState } from 'src/state/store'
// 表形式のひな形の場合、有効なページの ID を配列で返す
// 表形式以外のひな形の場合、処理を行わずに空の配列を返す
export const selectGridLayoutVisiblePageIds = (state: RootState): number[] => {
  const pages = state.reportsState.reportResult.report.pages
  const nodes = state.reportsState.reportResult.report.nodes
  const isGridLayout =
    state.reportsState.reportResult.report.layoutType ===
    TemplateLayoutTypeEnum.Grid

  const pageIds: number[] = []

  // 表形式以外の時は処理を行わない
  // NOTE: 呼び出さないのが理想だが、レポート画面が表形式以外のひな形と共通となっているため
  if (!isGridLayout) {
    return pageIds
  }

  for (const key in pages) {
    // 取り込み項目が1つでも ON だったら、 true にする
    let isEnabled = false
    // 取り込み項目詳細が入力されていたら、 true にする
    let hasOriginalNodeUuid = false
    // 取り込み項目以外の質問があった場合、 true にする
    let hasNotGridVariableQuestion = false

    const pageNodes = pages[key]?.nodes
    // ページ配下に何も紐づいていなければ、質問がないため false を返す
    if (!pageNodes) {
      continue
    }

    // HACK: 件数とかで不要なループを削ることができそうな気はするが
    // 取り込み項目の項目数の変更に弱くなったり、
    // バグを生み出しそうなので一旦全て確認している
    // ループ数によりパフォーマンスの問題が発生したら、
    // 最低限の件数だけ確認できるような処理に修正する
    for (const sectionNodeID of pageNodes) {
      const sectionNode = nodes[sectionNodeID]
      if (!sectionNode?.nodes) {
        continue
      }
      for (const nodeId of sectionNode.nodes) {
        const node = nodes[nodeId]
        if (!node || node.type !== TemplateNodeTypeEnum.Question) {
          // 質問以外の Node の場合、次の Node を検索する
          continue
        }
        // 取り込み項目以外の質問があるかの確認
        if (node.question?.responseType !== ResponseTypeEnum.GRID_VARIABLE) {
          hasNotGridVariableQuestion = true
          // 取り込み項目以外のため、後続の確認は行わない
          continue
        }

        // 取り込み項目の確認
        if (!node.question?.responseGridVariables) {
          continue
        }
        // 取り込み項目詳細が設定されているかどうか
        if (sectionNode.section?.originalNodeUuid) {
          hasOriginalNodeUuid = true
        }
        // 取り込み項目が ON に設定されている項目があるか
        if (node.question?.responseGridVariables[0]?.isEnabled) {
          isEnabled = true
        }
      }
    }

    if (isEnabled) {
      // 取り込み項目が１つでもONのとき
      if (hasOriginalNodeUuid) {
        pageIds.push(Number(key))
      }
    } else {
      // 取り込み項目すべてOFFのとき
      if (hasNotGridVariableQuestion) {
        pageIds.push(Number(key))
      }
    }
  }

  return pageIds
}
