import {
  TemplateHint,
  TemplateLayoutTypeEnum,
  TemplateNodeSchema,
} from '@ulysses-inc/harami_api_client'
import { FC } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { RootState } from 'src/state/store'
import QuestionFrame from './QuestionFrame'

interface IComparisonProps {
  isShowScore: boolean
  isExcelConversion: boolean
  templateHints: Array<TemplateHint>
  templateNodes: { [key: number]: TemplateNodeSchema }
  layoutType: TemplateLayoutTypeEnum
}

interface OwnProps {
  node: TemplateNodeSchema
  isActive: boolean
  parentNode: TemplateNodeSchema | null
  path: number[]
  isLastChild: boolean
}

export type QuestionProps = IComparisonProps & OwnProps

const QuestionContainer: FC<OwnProps> = ({
  node,
  isActive,
  parentNode,
  path,
  isLastChild,
}) => {
  const templateHints = useSelector(
    (state: RootState) => state.templatesState.templateHints.templateHints,
    shallowEqual,
  )
  const isShowScore = useSelector(
    (state: RootState) => state.templatesState.editTemplate.isShowScore,
    shallowEqual,
  )
  const isExcelConversion = useSelector(
    (state: RootState) => state.templatesState.editTemplate.isExcelConversion,
    shallowEqual,
  )
  const templateNodes = useSelector(
    (state: RootState) => state.templatesState.templatePages.templateNodes,
    shallowEqual,
  )

  const layoutType = useSelector(
    (state: RootState) => state.templatesState.editTemplate.layoutType,
    shallowEqual,
  )

  return (
    <QuestionFrame
      {...{
        node,
        parentNode,
        path,
        layoutType,
        isLastChild,
        templateNodes,
        templateHints,
        isActive,
        isExcelConversion,
        isShowScore,
      }}
    />
  )
}

export default QuestionContainer
