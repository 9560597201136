import {
  ReportNodeSchema,
  TemplateNodeTypeEnum,
} from '@ulysses-inc/harami_api_client'

const isEmployeeCheckOriginalSection = (node: ReportNodeSchema): boolean => {
  if (node.type !== TemplateNodeTypeEnum.Section) {
    return false
  }

  if (!node.section) {
    return false
  }

  if (node.section.isRepeat !== 1) {
    return false
  }

  return node.section.isEmployeeCheck === 1 && !node.section.originalNodeUuid
}

export default isEmployeeCheckOriginalSection
