import { primitiveColors } from 'src/features/theme/primitiveColors'
import styled from 'styled-components'

export const AllPageHeader = styled.div`
  font-size: 20px;
  font-weight: bold;
  margin-top: 40px;
  margin-bottom: -22px;
`

export const Content = styled.div`
  width: 100%;
`

export const EmptyStateText = styled.div`
  color: ${primitiveColors.gray._6};
  font-size: 16px;
  font-weight: bold;
  margin-top: 25px;
  margin-bottom: -22px;
  margin-left: 3;
`
