import { isToday, parseISO } from 'date-fns'

/**
 * ISO8601形式の日付文字列が今日の日付かどうかを判定する。
 * 計算は実行環境のタイムゾーンにもとづき行われる。
 */
export const checkIfToday = (dateString: string) => {
  const date = parseISO(dateString)
  return isToday(date)
}
