import { css } from '@emotion/react'
import { ReactNode } from 'react'

type Props = {
  children: ReactNode
  isActive?: boolean
}

export const DayHeaderCell = (props: Props) => {
  const { children, isActive = false } = props

  const dynamicStyle = css`
    color: ${isActive ? '#10a8eb' : 'default'};
  `

  return <div css={[styles.container, dynamicStyle]}>{children}</div>
}

const styles = {
  container: css`
    display: flex;
    justify-content: center;
  `,
}
