import { LoginOutlined } from '@ant-design/icons'
import { css } from '@emotion/react'
import { Button } from 'antd'
import Goton from 'src/assets/images/goton.svg?react'
import Logo from 'src/assets/images/kaminashi_report_logo_kana.svg?react'
import { getBasePath } from 'src/config'
import { useSendDummyErrorOnceInTenTimes } from 'src/features/sentry/useSendDummyErrorOnceInTenTimes'
import { primitiveColors } from 'src/features/theme/primitiveColors'

export const Login = () => {
  // Sentry動作テスト用のイースターエッグ的ななにか😎
  const sendDummyErrorOnceInTenTimes = useSendDummyErrorOnceInTenTimes()

  return (
    <div css={styles.container}>
      <Logo />
      <Goton css={styles.goton} onClick={sendDummyErrorOnceInTenTimes} />
      <div css={styles.text}>以下のボタンからログインをお願いします</div>
      <Button
        css={styles.button}
        icon={<LoginOutlined />}
        onClick={() => {
          window.location.href = `${getBasePath()}/auth/login`
        }}
        type="primary"
      >
        ログインに進む
      </Button>
    </div>
  )
}

const styles = {
  container: css`
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: center;
    width: 100vw;
  `,
  box: css``,
  goton: css`
    margin-top: 32px;
  `,
  text: css`
    // TODO: セマンティックなカラーパレットが整備されたらcolorTextSecondaryに置き換える
    //       https://github.com/Ulysses-inc/harami_web/pull/1243/files
    color: ${primitiveColors.neutralColor._65};
    margin-top: 10px;
  `,
  button: css`
    margin-top: 24px;
  `,
}
