import { Button, Input, Row } from 'antd'
import styled from 'styled-components'

export const FormLabel = styled.div`
  margin-top: 15px;
  margin-bottom: 5px;
  width: 100%;
`

export const FormRow = styled(Row)`
  margin-top: 5px;
  margin-bottom: 20px;
`

export const NameInput = styled(Input)`
  max-width: 440px;
  width: 100%;
`

export const RequiedLabel = styled.div`
  width: 100%;
  font-size: 12px;
  margin-top: 6px;
`

export const CSVImportButtonGroup = styled.div`
  margin-bottom: 10px;
`

export const CSVImportButton = styled(Button)`
  margin-right: 16px;
`

export const ActiveButtonGroup = styled.div`
  display: flex;
  justify-content: flex-start;
`

export const ActiveButton = styled(Button)`
  margin-top: 20px;
  margin-right: 10px;
`

export const CSVImportInput = styled.input`
  display: none;
`
