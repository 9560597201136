import { Layout } from 'antd'
import { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Header } from 'src/components/header/Header'
import { HeaderTitle } from 'src/components/header/HeaderTitle'
import { schedulesListFilter } from 'src/features/schedules/schedulesListFilter'
import placesOperations from 'src/state/ducks/places/operations'
import schedulesOperations from 'src/state/ducks/schedules/operations'
import { GetSchedulesFilter } from 'src/state/ducks/schedules/types'
import { RootState } from 'src/state/store'
import { adjustCurrentPage } from 'src/util/adjustCurrentPage'
import ScheduleList from './ScheduleList'
import { SchedulesPagination } from './Schedules.styled'

const SchedulesContainer: FC = () => {
  const history = useHistory()

  const { count, request, filter } = useSelector(
    (state: RootState) => state.schedulesState.schedules,
  )

  const dispatch = useDispatch()
  const changeSchedulesPage = (
    page: number,
    pageSize?: number,
    filter?: GetSchedulesFilter,
  ) => schedulesOperations.changeSchedulesPage(dispatch, page, pageSize, filter)
  const changeSchedulesSize = (pageSize: number, filter?: GetSchedulesFilter) =>
    schedulesOperations.changeSchedulesSize(dispatch, pageSize, filter)
  const updatePagination = (limit: number, offset: number) =>
    schedulesOperations.updatePagination(dispatch, limit, offset)
  const resetSchedule = () => {
    schedulesOperations.resetSchedulesTemplates(dispatch)
  }
  const getFilterData = () => {
    // ラベルを付けるためにひな形一覧の全データ取得をして、ScheduleList, ScheduleListRow側で利用されている
    schedulesOperations.getSchedulesTemplates(dispatch)
    placesOperations.getPlaces(dispatch, {})
    placesOperations.getPlaceGroups(dispatch)
  }

  const filters = schedulesListFilter.get()

  useEffect(() => {
    getFilterData()
    updatePagination(
      filters?.pagination?.limit ?? 25,
      filters?.pagination?.offset ?? 0,
    )
    return () => {
      resetSchedule()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    schedulesListFilter.set({
      ...filters,
      pagination: request,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [request.limit, request.offset])

  // スケジュール一覧画面 or 詳細画面から離脱したときはページネーションをリセットする
  useEffect(() => {
    return () => {
      if (!history.location.pathname.includes('/schedules')) {
        schedulesListFilter.reset()
      }
    }
  }, [history.action, history.location.pathname])

  // 現在のページを調整する
  const currentPage = adjustCurrentPage(request.limit, request.offset, count)

  return (
    <Layout>
      <Header>
        <HeaderTitle title="スケジュール" />
      </Header>
      <Layout.Content>
        <ScheduleList />
      </Layout.Content>
      <SchedulesPagination
        showSizeChanger
        current={currentPage}
        pageSizeOptions={['10', '25', '50', '100']}
        pageSize={request.limit ?? 25}
        defaultCurrent={1}
        total={count}
        onChange={(pageNum, pageSize) =>
          changeSchedulesPage(pageNum, pageSize, filter)
        }
        onShowSizeChange={(_, size) => changeSchedulesSize(size, filter)}
      />
    </Layout>
  )
}

export default SchedulesContainer
