import {
  ApprovalStep,
  BulkApproveReportsRequest,
  GetApprovalReportsRequest,
} from '@ulysses-inc/harami_api_client'
import Redux from 'redux'
import actions from './actions'
import { DeleteApprovalArg, GetApprovalReportsFilter } from './types'

const getApproval = (dispatch: Redux.Dispatch, approvalUUID: string) => {
  dispatch(actions.getApproval(approvalUUID))
}

const deleteApproval = (
  dispatch: Redux.Dispatch,
  arg: DeleteApprovalArg,
): void => {
  dispatch(actions.deleteApproval(arg))
}

const addApprovalStep = (
  dispatch: Redux.Dispatch,
  reportUUID: string,
  approvalUUID: string,
  currentStep: ApprovalStep,
  nextStep: ApprovalStep,
  url: string,
) => {
  dispatch(
    actions.addApprovalStep(
      reportUUID,
      approvalUUID,
      currentStep,
      nextStep,
      url,
    ),
  )
}

const resetApproval = (dispatch: Redux.Dispatch) => {
  dispatch(actions.resetApproval())
}

const addApprovalReport = (
  dispatch: Redux.Dispatch,
  reportUUID: string,
  approvalFlowId: number,
  url: string,
) => {
  dispatch(actions.addApprovalReport(reportUUID, approvalFlowId, url))
}

const getApprovalReports = (
  dispatch: Redux.Dispatch,
  request: GetApprovalReportsRequest,
  filter?: GetApprovalReportsFilter,
) => {
  dispatch(actions.getApprovalReports(request, filter))
}

const changeApprovalReportsPage = (
  dispatch: Redux.Dispatch,
  page: number,
  pageSize: number,
  filter?: GetApprovalReportsFilter,
) => {
  dispatch(actions.changeApprovalReportsPage(page, pageSize, filter))
}

const changeApprovalReportsSize = (
  dispatch: Redux.Dispatch,
  pageSize: number,
  filter?: GetApprovalReportsFilter,
) => {
  dispatch(actions.changeApprovalReportsSize(pageSize, filter))
}

const updatePagination = (
  dispatch: Redux.Dispatch,
  limit: number,
  offset: number,
) => {
  dispatch(actions.updatePagination(limit, offset))
}

const bulkApproveReports = (
  dispatch: Redux.Dispatch,
  bulkApproveReportsRequest: BulkApproveReportsRequest,
) => {
  dispatch(actions.bulkApproveReports(bulkApproveReportsRequest))
}

export default {
  getApproval,
  deleteApproval,
  addApprovalStep,
  resetApproval,
  addApprovalReport,
  getApprovalReports,
  changeApprovalReportsPage,
  changeApprovalReportsSize,
  updatePagination,
  bulkApproveReports,
}
