import { useLayoutEffect } from 'react'
import { useHistory } from 'react-router-dom'

/**
 * 戻る・進むボタンでの画面遷移によりコンポーネントがマウントされた際に
 * 以前のスクロール位置を復元するためのカスタムフック
 *
 * 入れ子になっている要素のスクロール位置を復元する場合においてのみ必要である。
 * windowのスクロール位置はブラウザが自動で復元してくれるのでこのhooksは不要である。
 *
 * @param cssSelector スクロール位置を復元したいDOM要素のセレクタ
 * @param sessionStorageKey スクロール位置をsessionStorageに保存するにあたり必要となる、グローバルに一意なキー
 */
export const useScrollRestorer = (
  cssSelector: string,
  sessionStorageKey: string,
) => {
  const history = useHistory()

  useLayoutEffect(() => {
    // ブラウザの進む・戻るボタンを押して画面がマウントされたときのみ、スクロール位置の復元を行う
    if (history.action !== 'POP') return

    const memorizedScrollYString = sessionStorage.getItem(sessionStorageKey)
    if (memorizedScrollYString === null) return
    const memorizedScrollY = Number(memorizedScrollYString)

    const targetElement = document.querySelector(cssSelector)
    if (!targetElement) return

    targetElement.scrollTop = Number(memorizedScrollY)
  }, [cssSelector, history.action, sessionStorageKey])

  useLayoutEffect(() => {
    return () => {
      const targetElement = document.querySelector(cssSelector)
      if (!targetElement) return
      sessionStorage.setItem(sessionStorageKey, String(targetElement.scrollTop))
    }
  }, [cssSelector, sessionStorageKey])
}
