/**
 * `(SomeType | undefined)[]`のようなイケてない配列がやむを得ず発生してしまった際に、
 * undefinedな値を取り除く目的で使用する。
 *
 * @example
 * ```
 * const someUglyArray: (SomeType | undefined)[] = [...]
 * const cleanArray = someUglyArray.filter(isDefined)
 * ```
 */
export function isDefined<T>(arg: T | undefined): arg is T {
  return arg !== undefined
}
