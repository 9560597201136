import { Typography } from 'antd'
import { Danger } from 'src/features/theme/KdsThemeColor'
import styled from 'styled-components'

const { Text } = Typography

export const PlaceGroupPageHeader = styled.div`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
`

export const PlaceGroupTitle = styled.div`
  margin-top: 16px;
  margin-bottom: 5px;
`

export const PlaceGroupText = styled(Text)`
  margin-left: 3px;
`

export const ErrorText = styled(Text)`
  color: ${Danger};
`
