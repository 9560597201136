import { css } from '@emotion/react'
import { TemplateLayoutTypeEnum } from '@ulysses-inc/harami_api_client'
import { Link } from 'react-scroll'
import { HEADER_HEIGHT } from 'src/components/header/Header'
import { Primary } from 'src/features/theme/KdsThemeColor'
import styled from 'styled-components'
import { PagesLink } from './PageLink'
import { WebReportResultProps } from './WebReportResultProps'

const ResultSider = styled.div`
  padding: 56px 24px 24px;
`

export const SideMenu = (props: WebReportResultProps) => {
  const isGridLayout = props.report.layoutType === TemplateLayoutTypeEnum.Grid
  const headerHeight = 64

  return (
    <ResultSider>
      {/* 表形式ひな形の場合は一旦非表示にしておく
        下記チケットで逸脱項目表示は対応予定
        https://kaminashi.atlassian.net/browse/HPB-2714 */}
      {!isGridLayout && props.deviatedAnswers.length > 0 && (
        <div>
          <Link
            css={styles.link}
            to="DeviatedAnswers"
            spy={true}
            smooth={true}
            offset={-HEADER_HEIGHT}
            duration={200}
          >
            逸脱項目
          </Link>
        </div>
      )}
      <PagesLink {...{ ...props, headerHeight }} />
    </ResultSider>
  )
}

const styles = {
  link: css`
    color: ${Primary};
    cursor: pointer;
    font-size: 14px;
  `,
}
