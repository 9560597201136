import { css } from '@emotion/react'
import { ErrorCodeEnum } from '@ulysses-inc/harami_api_client'
import { Breadcrumb, Button, Modal } from 'antd'
import { FC, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, Prompt, useHistory } from 'react-router-dom'
import { Header } from 'src/components/header/Header'
import { ErrorCodeErrorType } from 'src/features/firebase/featureSpecificEventLog/gridLayoutSoftLimitError'
import {
  clearPatchGridVariablesError,
  requestUpdateGridVariablesTable,
} from 'src/state/ducks/editGridVariables/actions'
import { SoftLimitErrorCode } from 'src/state/ducks/editGridVariables/reducers'
import {
  selectErrorRowNumbers,
  selectHasChangedInTable,
  selectTemplateName,
} from 'src/state/ducks/editGridVariables/selectors'
import { RootState } from 'src/state/store'
import { HistoryState } from 'src/views/components/routes/getUserConfirmation'
import { BuildIcon, ModalContentWrapper, ModalTitle } from './PageHeader.styled'
import { useSoftLimitErrorLog } from './useSoftLimitErrorLog'

type OwnProps = {
  templateId: number
}

export const PageHeader: FC<OwnProps> = ({ templateId }) => {
  return useMemo(
    () => <UnmemorizedPageHeader templateId={templateId} />,
    [templateId],
  )
}

const toSoftLimitErrorType = (code: SoftLimitErrorCode): ErrorCodeErrorType => {
  switch (code) {
    case ErrorCodeEnum.MaxSubstantialCellCountExceededInGridVariablesError:
      return 'cellCount'
    case ErrorCodeEnum.MaxRowCountExceededInGridVariablesError:
      return 'rowCount'
    case ErrorCodeEnum.MaxTableCountExceededInGridVariablesError:
      return 'tableCount'
  }
}

const UnmemorizedPageHeader: FC<OwnProps> = ({ templateId }) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const [historyState, setHistoryState] = useState<HistoryState>()
  useEffect(() => {
    setHistoryState({
      data: window.history.state,
      title: document.title,
      url: window.location.href,
    })
  }, [])

  const templateName = useSelector(selectTemplateName)
  const isChangedInTable = useSelector(selectHasChangedInTable)
  const patchGridVariablesError = useSelector(
    (state: RootState) =>
      state.editGridVariablesState.editGridVariables.patchGridVariablesError,
  )

  const { logSoftLimitErrorEvent } = useSoftLimitErrorLog()

  useEffect(() => {
    if (patchGridVariablesError) {
      // 保存ボタン後にapiからエラー返却された場合にエラーダイアログを表示
      Modal.error({
        title: <ModalTitle>取り込み項目が保存できませんでした</ModalTitle>,
        width: 417,
        content: (
          <ModalContentWrapper>
            <div>{patchGridVariablesError.errorMessage}</div>
          </ModalContentWrapper>
        ),
        okText: '閉じる',
        onOk: () => dispatch(clearPatchGridVariablesError()),
      })

      logSoftLimitErrorEvent(
        toSoftLimitErrorType(patchGridVariablesError.code),
        patchGridVariablesError.errorMessage,
      )
    }
  }, [patchGridVariablesError, dispatch, logSoftLimitErrorEvent])

  // NOTE: errorRowNumbersについて
  // レンダリングのたびにエラーになった行数を数えているので、要パフォーマンスチューニング。
  // 暫定対応でモーダルに表示している。
  const errorRowNumbers = useSelector(selectErrorRowNumbers)

  const handleClickCancel = () => {
    history.push('/gridVariables')
  }

  const handleClickSave = () => {
    if (errorRowNumbers.length > 0) {
      Modal.error({
        title: '保存できませんでした',
        content: (
          <p>
            {errorRowNumbers.length}行のデータを修正して再度保存してください。
          </p>
        ),
      })
      return
    }

    dispatch(requestUpdateGridVariablesTable(templateId))
  }

  return (
    <>
      <Prompt
        when={isChangedInTable}
        message={location => {
          const message = '変更した内容が破棄されますがよろしいですか？'
          if (location.pathname === `/gridVariables/${templateId}/edit`) {
            return JSON.stringify({ message, state: historyState })
          }
          return message
        }}
      />

      <Header
        rightSlot={[
          <Button key="1" onClick={handleClickCancel}>
            キャンセル
          </Button>,
          <Button
            key="2"
            type="primary"
            onClick={handleClickSave}
            disabled={!isChangedInTable}
          >
            保存
          </Button>,
        ]}
        sticky
      >
        <Breadcrumb css={styles.breadcrumb}>
          <Breadcrumb.Item>
            <Link to="/gridVariables">
              <BuildIcon />
              取り込み項目一覧
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>{templateName}</Breadcrumb.Item>
        </Breadcrumb>
      </Header>
    </>
  )
}

const styles = {
  breadcrumb: css`
    font-weight: bold;
  `,
}
