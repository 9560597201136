import TagManager from 'react-gtm-module'
import { appEnv } from 'src/util/appEnv'

export const initializeGtm = () => {
  if (appEnv.isLocal) return

  TagManager.initialize({
    gtmId: import.meta.env['VITE_APP_GTM_ID'] || '',
  })
}
