import {
  PlaceNode,
  PlaceNodeTypeEnum,
  Template,
  TemplateLayoutTypeEnum,
} from '@ulysses-inc/harami_api_client'
import { Checkbox, Modal } from 'antd'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'
import { Dispatch, FC, SetStateAction, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { RowActionButton } from 'src/components/rowActionButton/RowActionButton'
import date from 'src/exShared/util/date'
import { useLimitedFeatureAvailability } from 'src/features/featureAvailability/useLimitedFeatureAvailability'
import { ModalDeleteButtonColor } from 'src/features/theme/KdsThemeColor'
import { deleteTemplate } from 'src/state/ducks/templateList/actions'
import { RootState } from 'src/state/store'
import { TTemplateWithId, isTemplateWithId } from './Templates'
import {
  ButtonGroupRow,
  CheckRow,
  ContentRow,
  GridTemplateTag,
  ImproveOptionTag,
  TableBody,
  TableCreatedAtCol,
  TableHeader,
  TableHeaderCol,
  TableHeaderLabel,
  TableHeaderWrap,
  TableRowWrap,
  TableUpdateAtCol,
  TemplateCreatedAtRow,
  TemplateModifiedAtRow,
  TemplateNameLabel,
  TemplateNameRow,
  TemplatePlaceCol,
  TemplatePlaceNameRow,
} from './Templates.styled'

const Table: FC<{
  selectedTemplateIdsState: [Set<number>, Dispatch<SetStateAction<Set<number>>>]
}> = ({ selectedTemplateIdsState }) => {
  const [selectedTemplateIds, setSelectedTemplateIds] = selectedTemplateIdsState

  const { templates } = useSelector(
    (state: RootState) => state.templateListState,
  )

  const isEveryTemplateSelected = () => {
    if (!templates.length) {
      return false
    }

    return templates.every(
      template =>
        isTemplateWithId(template) && selectedTemplateIds.has(template.id),
    )
  }

  const selectAllTemplates = (e: CheckboxChangeEvent) => {
    if (e.target.checked) {
      const templateIds = templates
        .filter((template): template is TTemplateWithId =>
          isTemplateWithId(template),
        )
        .map(({ id }) => id)
      setSelectedTemplateIds(new Set(templateIds))
    } else {
      setSelectedTemplateIds(new Set([]))
    }
  }

  return (
    <ContentRow justify="center">
      <TableHeaderWrap>
        <TableHeader>
          <TableHeaderCol>
            <CheckRow>
              <Checkbox
                checked={isEveryTemplateSelected()}
                onChange={selectAllTemplates}
              />
            </CheckRow>
            <TableHeaderLabel>ひな形名</TableHeaderLabel>
          </TableHeaderCol>
          <TemplatePlaceCol>現場名</TemplatePlaceCol>
          <TableCreatedAtCol>作成日時</TableCreatedAtCol>
          <TableUpdateAtCol>更新日時</TableUpdateAtCol>
        </TableHeader>
        <TableBody
          itemLayout="horizontal"
          dataSource={templates}
          renderItem={(template: Template) => (
            <Row {...{ template, selectedTemplateIdsState }} />
          )}
        />
      </TableHeaderWrap>
    </ContentRow>
  )
}

const Row: FC<{
  template: Template
  selectedTemplateIdsState: [Set<number>, Dispatch<SetStateAction<Set<number>>>]
}> = ({
  template,
  selectedTemplateIdsState: [selectedTemplateIds, setSelectedTemplateIds],
}) => {
  const history = useHistory()
  const [isHovered, setIsHovered] = useState<boolean>(false)

  const dispatch = useDispatch()

  const isGridLayout = template.layoutType === TemplateLayoutTypeEnum.Grid

  const editTemplatePages = () => {
    if (isTemplateWithId(template)) {
      if (isGridLayout) {
        history.push(`/templates/${template.id}/pages/editGridTemplate`)
      } else {
        history.push(`/templates/${template.id}/pages/edit`)
      }
    }
  }

  const selectTemplate = (e: CheckboxChangeEvent) => {
    if (isTemplateWithId(template)) {
      if (e.target.checked) {
        setSelectedTemplateIds(
          new Set([...Array.from(selectedTemplateIds), template.id]),
        )
        return
      }

      const prunedSelectedTemplateIds = Array.from(selectedTemplateIds).filter(
        selectedTemplateId => selectedTemplateId !== template.id,
      )
      setSelectedTemplateIds(new Set(prunedSelectedTemplateIds))
    }
  }

  const onClickDelete = () => {
    if (!isTemplateWithId(template)) {
      return
    }

    Modal.confirm({
      onOk: () => {
        dispatch(deleteTemplate(template.id))
      },
      title: template.hasSchedules
        ? `「${template.name}」はスケジュールに紐いています。削除を実行しますがよろしいですか？`
        : `「${template.name}」の削除を実行しますがよろしいですか？`,
      okText: '削除',
      cancelText: 'キャンセル',
      okButtonProps: {
        style: { backgroundColor: ModalDeleteButtonColor, border: 'none' },
      },
    })
  }

  const getPlaceNames = (placeNodes: PlaceNode[]) =>
    placeNodes
      .map(({ type, place, placeGroup }) =>
        type === PlaceNodeTypeEnum.PlaceGroup ? placeGroup?.name : place?.name,
      )
      .filter(name => name)
      .join(' ')

  const isChecked = template.id ? selectedTemplateIds.has(template.id) : false

  return (
    <TableRowWrap
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <TemplateNameRow>
        <CheckRow>
          <Checkbox checked={isChecked} onChange={selectTemplate} />
        </CheckRow>
        <TemplateNameLabel>
          {template.name}
          <TemplateNameTag
            isAudit={template.isAudit}
            layoutType={template.layoutType}
          />
        </TemplateNameLabel>
      </TemplateNameRow>
      <TemplatePlaceNameRow>
        {getPlaceNames(template.placeNodes ?? [])}
      </TemplatePlaceNameRow>
      <TemplateCreatedAtRow>
        {date.formatYYYYMMDDHHMM(template.createdAt)}
      </TemplateCreatedAtRow>
      <TemplateModifiedAtRow>
        {date.formatYYYYMMDDHHMM(template.modifiedAt)}
      </TemplateModifiedAtRow>
      {isHovered && (
        <ButtonGroupRow>
          <RowActionButton type="edit" onClick={editTemplatePages} />
          <RowActionButton onClick={onClickDelete} type="delete" />
        </ButtonGroupRow>
      )}
    </TableRowWrap>
  )
}

const TemplateNameTag: FC<{
  isAudit?: boolean
  layoutType?: TemplateLayoutTypeEnum
}> = ({ isAudit, layoutType }) => {
  const { isFeatureAvailable } = useLimitedFeatureAvailability()

  if (isFeatureAvailable('improves') && isAudit) {
    return <ImproveOptionTag>カイゼン</ImproveOptionTag>
  }
  if (layoutType === TemplateLayoutTypeEnum.Grid) {
    return <GridTemplateTag>表形式</GridTemplateTag>
  }
  return <></>
}

export default Table
