import { Template } from '@ulysses-inc/harami_api_client'
import { ActionTypes } from '../types'

const getReportsTemplates = () => {
  return {
    type: ActionTypes.REQUEST_GET_REPORTS_TEMPLATES,
  }
}

const getSuccessReportsTemplates = (templates: Template[]) => {
  return {
    type: ActionTypes.SUCCESS_GET_REPORTS_TEMPLATES,
    templates,
  }
}

const getErrorReportsTemplates = (error: Error) => {
  return {
    type: ActionTypes.ERROR_GET_REPORTS_TEMPLATES,
    error,
  }
}

const resetReportsTemplates = () => {
  return {
    type: ActionTypes.RESET_REPORTS_TEMPLATES,
  }
}

export type ReportsTemplatesAction =
  | ReturnType<typeof getReportsTemplates>
  | ReturnType<typeof getSuccessReportsTemplates>
  | ReturnType<typeof getErrorReportsTemplates>
  | ReturnType<typeof resetReportsTemplates>

export default {
  getReportsTemplates,
  getSuccessReportsTemplates,
  getErrorReportsTemplates,
  resetReportsTemplates,
}
