import {
  ExclamationCircleOutlined,
  PauseCircleOutlined,
  PlayCircleOutlined,
} from '@ant-design/icons'
import { Button, Input, Layout, Row, Select, Typography } from 'antd'
import styled from 'styled-components'

export const { Content } = Layout
export const { Title, Text } = Typography
export const { Option } = Select

export const TimePickerArea = styled.div`
  display: flex;
`

export const TimePickerMargin = styled.div`
  margin: 6px 12px;
`

export const EditScheduleContentRow = styled(Row)`
  height: 100%;
`

export const TemplateSelect = styled(Select)`
  width: 500px;
`

export const FormRow = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
`
export const CheckboxArea = styled.div`
  margin-bottom: 10px;
`

export const CheckboxText = styled(Text)`
  margin-left: 8px;
`

export const FormLabel = styled.div`
  margin-bottom: 10px;
  width: 100%;
`

export const NameInput = styled(Input)`
  max-width: 500px;
  width: 100%;
`

export const RequiedLabel = styled.div`
  font-size: 12px;
  margin-top: 6px;
  width: 100%;
`

export const CopyButton = styled(Button)`
  position: absolute;
  top: 20px;
  right: 8px;
`

export const PlayCircleOutlinedIcon = styled(PlayCircleOutlined)`
  margin-right: 5px;
`

export const PauseCircleOutlinedIcon = styled(PauseCircleOutlined)`
  margin-right: 5px;
`

export const ModalContent = styled.div`
  display: flex;
`

export const WarningIconWrap = styled.div`
  margin-right: 1rem;
`
export const WarningIcon = styled(ExclamationCircleOutlined)`
  color: orange;
  font-size: 2rem;
`
