import {
  AddUserGroupRequest,
  GetUserGroupsRequest,
  GetUsersRequest,
  User,
  UserGroup,
} from '@ulysses-inc/harami_api_client'
import Redux from 'redux'
import actions from './actions'
import { GetUsersFilter } from './types'

const getUsers = (
  dispatch: Redux.Dispatch,
  request: GetUsersRequest,
  filter?: GetUsersFilter,
) => {
  dispatch(actions.getUsers(request, filter))
}

const changeUsersPage = (
  dispatch: Redux.Dispatch,
  page: number,
  pageSize?: number,
  filter?: GetUsersFilter,
) => {
  dispatch(actions.changeUsersPage(page, pageSize, filter))
}

const changeUsersSize = (
  dispatch: Redux.Dispatch,
  pageSize: number,
  filter?: GetUsersFilter,
) => {
  dispatch(actions.changeUsersSize(pageSize, filter))
}

const updatePagination = (
  dispatch: Redux.Dispatch,
  limit: number,
  offset: number,
) => {
  dispatch(actions.updatePagination(limit, offset))
}

const addUser = (dispatch: Redux.Dispatch, user: User) => {
  dispatch(actions.addUser(user))
}

const updateUser = (
  dispatch: Redux.Dispatch,
  userId: string,
  user: User,
  currentPassword?: string,
) => {
  dispatch(actions.updateUser(userId, user, currentPassword))
}

const deleteUser = (dispatch: Redux.Dispatch, userId: string) => {
  dispatch(actions.deleteUser(userId))
}

const validateDeleteUser = (dispatch: Redux.Dispatch, userId: string) => {
  dispatch(actions.validateDeleteUser(userId))
}

const resetValidateDeleteUser = (dispatch: Redux.Dispatch) => {
  dispatch(actions.resetValidateDeleteUser())
}

const changeIsShowEditUserDrawer = (
  dispatch: Redux.Dispatch,
  isShowEditUserDrawer: boolean,
) => {
  dispatch(actions.changeIsShowEditUserDrawer(isShowEditUserDrawer))
}

const changeUpdatedUsersFlag = (
  dispatch: Redux.Dispatch,
  updatedUsers: boolean,
) => {
  dispatch(actions.changeUpdatedUsersFlag(updatedUsers))
}

const updateActiveUserId = (dispatch: Redux.Dispatch, activeUserId: string) => {
  dispatch(actions.updateActiveUserId(activeUserId))
}

const updateSegmentIndex = (dispatch: Redux.Dispatch, segmentIndex: number) => {
  dispatch(actions.updateSegmentIndex(segmentIndex))
}

const getUserGroups = (
  dispatch: Redux.Dispatch,
  request: GetUserGroupsRequest,
) => {
  dispatch(actions.getUserGroups(request))
}

const changeUserGroupsPage = (
  dispatch: Redux.Dispatch,
  page: number,
  pageSize?: number,
) => {
  dispatch(actions.changeUserGroupsPage(page, pageSize))
}

const changeUserGroupsSize = (dispatch: Redux.Dispatch, pageSize: number) => {
  dispatch(actions.changeUserGroupsSize(pageSize))
}

const addUserGroup = (
  dispatch: Redux.Dispatch,
  request: AddUserGroupRequest,
) => {
  dispatch(actions.addUserGroup(request))
}

const updateUserGroup = (
  dispatch: Redux.Dispatch,
  userGroupId: number,
  userGroup: UserGroup,
) => {
  dispatch(actions.updateUserGroup(userGroupId, userGroup))
}

const deleteUserGroup = (dispatch: Redux.Dispatch, userGroupId: number) => {
  dispatch(actions.deleteUserGroup(userGroupId))
}

const validateDeleteUserGroup = (
  dispatch: Redux.Dispatch,
  userGroupId: number,
) => {
  dispatch(actions.validateDeleteUserGroup(userGroupId))
}

const resetValidateDeleteUserGroup = (dispatch: Redux.Dispatch) => {
  dispatch(actions.resetValidateDeleteUserGroup())
}

const changeIsShowEditUserGroupDrawer = (
  dispatch: Redux.Dispatch,
  isShowEditUserGroupDrawer: boolean,
) => {
  dispatch(actions.changeIsShowEditUserGroupDrawer(isShowEditUserGroupDrawer))
}

const updateActiveUserGroupId = (
  dispatch: Redux.Dispatch,
  activeUserGroupId?: number,
) => {
  dispatch(actions.updateActiveUserGroupId(activeUserGroupId))
}

const getUser = (dispatch: Redux.Dispatch, userId: string) => {
  dispatch(actions.getUser(userId))
}

export default {
  getUsers,
  changeUsersPage,
  changeUsersSize,
  updatePagination,
  addUser,
  updateUser,
  deleteUser,
  resetValidateDeleteUser,
  validateDeleteUser,
  changeIsShowEditUserDrawer,
  changeUpdatedUsersFlag,
  updateActiveUserId,
  updateSegmentIndex,
  getUserGroups,
  changeUserGroupsPage,
  changeUserGroupsSize,
  addUserGroup,
  updateUserGroup,
  deleteUserGroup,
  resetValidateDeleteUserGroup,
  validateDeleteUserGroup,
  changeIsShowEditUserGroupDrawer,
  updateActiveUserGroupId,
  getUser,
}
