import {
  AttachmentFile,
  ReportStatusEnum,
} from '@ulysses-inc/harami_api_client'
import { ReportResultSchema } from 'src/exShared/types/types'
import { ActionTypes } from '../types'

const getReportResult = (reportId?: string) => {
  return {
    type: ActionTypes.REQUEST_GET_REPORT_RESULT,
    isLoading: true,
    reportId,
  }
}

const getSuccessReportResult = (
  report: ReportResultSchema,
  templateImages: { [key: string]: string },
) => {
  return {
    type: ActionTypes.SUCCESS_GET_REPORT_RESULT,
    isLoading: false,
    report: report,
    templateImages,
  }
}

const getErrorReportResult = (error: Error) => {
  return {
    type: ActionTypes.ERROR_GET_REPORT_RESULT,
    isLoading: false,
    error,
  }
}

const addReportComment = (reportId: string, comment: string) => {
  return {
    type: ActionTypes.REQUEST_ADD_REPORT_COMMENT,
    isLoading: true,
    reportId,
    comment,
  }
}

const addSuccessReportComment = (comment: string) => {
  return {
    type: ActionTypes.SUCCESS_ADD_REPORT_COMMENT,
    comment,
    isLoading: false,
  }
}

const addErrorReportComment = (error: Error) => {
  return {
    type: ActionTypes.ERROR_ADD_REPORT_COMMENT,
    isLoading: false,
    error,
  }
}

const uploadReportAttachmentFile = (reportId: string, file: File) => {
  return {
    type: ActionTypes.REQUEST_UPLOAD_REPORT_ATTACHMENT_FILE,
    isLoading: true,
    reportId,
    file,
  }
}

const addReportAttachmentFile = (reportId: string, file: AttachmentFile) => {
  return {
    type: ActionTypes.REQUEST_ADD_REPORT_ATTACHMENT_FILE,
    isLoading: true,
    reportId,
    file,
  }
}

const addSuccessReportAttachmentFile = (
  reportId: string,
  file: AttachmentFile,
) => {
  return {
    type: ActionTypes.SUCCESS_ADD_REPORT_ATTACHMENT_FILE,
    isLoading: false,
    reportId,
    file,
  }
}

const addErrorReportAttachmentFile = (error: Error) => {
  return {
    type: ActionTypes.ERROR_ADD_REPORT_ATTACHMENT_FILE,
    isLoading: false,
    error,
  }
}

const deleteReportAttachmentFile = (reportId: string, fileId: string) => {
  return {
    type: ActionTypes.REQUEST_DELETE_REPORT_ATTACHMENT_FILE,
    isLoading: true,
    reportId,
    fileId,
  }
}

const deleteSuccessReportAttachmentFile = (
  reportId: string,
  fileId: string,
) => {
  return {
    type: ActionTypes.SUCCESS_DELETE_REPORT_ATTACHMENT_FILE,
    isLoading: false,
    reportId,
    fileId,
  }
}

const deleteErrorReportAttachmentFile = (error: Error) => {
  return {
    type: ActionTypes.ERROR_DELETE_REPORT_ATTACHMENT_FILE,
    isLoading: false,
    error,
  }
}

const resetReportResult = () => {
  return {
    type: ActionTypes.RESET_REPORT_RESULT,
  }
}

const updateReportStatus = (reportId: string, status: ReportStatusEnum) => {
  return {
    type: ActionTypes.REQUEST_UPDATE_REPORT_STATUS,
    isLoading: true,
    reportId,
    status,
  }
}

const updateSuccessReportStatus = () => {
  return {
    type: ActionTypes.SUCCESS_UPDATE_REPORT_STATUS,
    isLoading: false,
  }
}

const updateErrorReportStatus = (error: Error) => {
  return {
    type: ActionTypes.ERROR_UPDATE_REPORT_STATUS,
    isLoading: true,
    error,
  }
}

const changeReportStatus = (status: ReportStatusEnum) => {
  return {
    type: ActionTypes.CHANGE_REPORT_STATUS,
    status,
  }
}

export type ReportResultAction =
  | ReturnType<typeof getReportResult>
  | ReturnType<typeof getSuccessReportResult>
  | ReturnType<typeof getErrorReportResult>
  | ReturnType<typeof addReportComment>
  | ReturnType<typeof addSuccessReportComment>
  | ReturnType<typeof addErrorReportComment>
  | ReturnType<typeof uploadReportAttachmentFile>
  | ReturnType<typeof addReportAttachmentFile>
  | ReturnType<typeof addSuccessReportAttachmentFile>
  | ReturnType<typeof addErrorReportAttachmentFile>
  | ReturnType<typeof deleteReportAttachmentFile>
  | ReturnType<typeof deleteSuccessReportAttachmentFile>
  | ReturnType<typeof deleteErrorReportAttachmentFile>
  | ReturnType<typeof resetReportResult>
  | ReturnType<typeof updateReportStatus>
  | ReturnType<typeof updateSuccessReportStatus>
  | ReturnType<typeof updateErrorReportStatus>
  | ReturnType<typeof changeReportStatus>

export default {
  getReportResult,
  getSuccessReportResult,
  getErrorReportResult,
  addReportComment,
  addSuccessReportComment,
  addErrorReportComment,
  uploadReportAttachmentFile,
  addReportAttachmentFile,
  addSuccessReportAttachmentFile,
  addErrorReportAttachmentFile,
  deleteReportAttachmentFile,
  deleteSuccessReportAttachmentFile,
  deleteErrorReportAttachmentFile,
  updateReportStatus,
  updateSuccessReportStatus,
  updateErrorReportStatus,
  changeReportStatus,
  resetReportResult,
}
