import {
  GetTemplateRequest,
  Template,
  TemplatesApi,
} from '@ulysses-inc/harami_api_client'
import { call, put, takeEvery } from 'redux-saga/effects'
import interceptionsActions from 'src/state/ducks/interceptions/actions'
import * as editTemplateActions from 'src/state/ducks/templates/editTemplate/actions'
import { ActionTypes } from 'src/state/ducks/templates/editTemplate/types'
import { baseClient } from 'src/state/middleware/saga/baseClient'
import {
  HTTPError,
  handleHTTPError,
} from 'src/state/middleware/saga/handleHttpError'

const getTemplateRequest = (req: GetTemplateRequest) => {
  return baseClient
    .getApi(TemplatesApi)
    .getTemplate(req)
    .then(template => template)
    .catch(handleHTTPError)
}

function* getTemplate(
  action: ReturnType<typeof editTemplateActions.getTemplate>,
) {
  try {
    const template: Template = yield call(getTemplateRequest, {
      templateId: action.templateId,
    })
    yield put(editTemplateActions.getSuccessTemplate(template))
  } catch (error) {
    yield put(interceptionsActions.handleHttpError(error as HTTPError))
    yield put(editTemplateActions.getErrorTemplate(error as HTTPError))
  }
}

export const editTemplateSagas = [
  takeEvery(ActionTypes.REQUEST_GET_TEMPLATE, getTemplate),
]
