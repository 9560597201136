import { css } from '@emotion/react'
import { Card, Typography } from 'antd'
import emptyStateImage from 'src/assets/images/empty_state.png'

const { Text } = Typography

const DataUnavailableWidget = () => {
  return (
    <Card bordered={false}>
      <div css={styles.imageContent}>
        <img alt="empty_state" src={emptyStateImage} />
      </div>
      <div css={styles.displayContent}>
        <Text css={styles.errorMessage}>
          データが見つからないか、データにアクセスする権限がありません。
        </Text>
      </div>
    </Card>
  )
}

const styles = {
  displayContent: css`
    text-align: center;
    margin-top: 24px;
    margin-bottom: 24px;
  `,
  imageContent: css`
    text-align: center;
    margin-top: 48px;
    margin-bottom: 48px;
  `,
  errorMessage: css`
    font-weight: bold;
    font-size: 22px;
    line-height: 32px;
    text-align: center;
  `,
}

export default DataUnavailableWidget
