import { ComponentProps, ComponentType } from 'react'
import { Route } from 'react-router-dom'
import { NoPermissionScreen } from 'src/views/components/NoPermissionScreen'

type Props = {
  component: ComponentType<any>
  hasPermission: boolean
} & Exclude<ComponentProps<typeof Route>, 'component' | 'render'>

export const PrivateRoute = (props: Props) => {
  const { hasPermission, component: Component, ...rest } = props

  return (
    <Route
      {...rest}
      render={props => {
        return hasPermission ? <Component {...props} /> : <NoPermissionScreen />
      }}
    />
  )
}
