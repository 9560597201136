import {
  ReportNodeSchema,
  ResponseTypeEnum,
  TemplateNodeTypeEnum,
} from '@ulysses-inc/harami_api_client'
import { ReportNodesDict } from 'src/exShared/types/types'
import { RootState } from 'src/state/store'
import { isNullish } from 'src/util/isNullish'

/**
 * page内のoriginalSectionNodeIdの配列を取得するセレクタ。
 * originalSectionというのは回答を保持しないヘッダの隠しsectionのこと
 * @param {number} pageId - 対象のページID
 * @returns
 */
export const selectOriginalSectionNodeIds =
  (pageId: number) =>
  (state: RootState): number[] => {
    const page = state.reportsState.reportResult.report.pages[pageId]
    if (!page) return []

    const nodeDict = state.reportsState.reportResult.report.nodes
    return (
      page.nodes?.filter(
        nodeId => !nodeDict[nodeId]?.section?.originalNodeUuid,
      ) ?? []
    )
  }

/**
 * 引数で渡したidのsectionNodeのnameを取得するセレクタ
 * @param {number} sectionNodeId - 対象のsectionNodeId
 * @returns
 */
export const selectSectionName =
  (sectionNodeId: number) => (state: RootState) => {
    return (
      state.reportsState.reportResult.report.nodes[sectionNodeId]?.section
        ?.name ?? ''
    )
  }

/**
 * 引数で渡したidのsectionが保持するquestionNodeの一覧を返すセレクタ。
 * @param {number} sectionNodeId - 対象のsectionNodeId
 * @returns
 */
export const selectHeaderRow =
  (sectionNodeId: number) =>
  (state: RootState): ReportNodeSchema[] =>
    selectChildNodes(sectionNodeId, sectionNodeId, state)

/**
 * 繰り返しセクションのすべての回答一覧を取得するセレクタ。
 * 引数で渡したoriginalSectionNodeIdをオリジナルとするsectionの回答をすべて取得する。
 * @param {number} pageId - 対象のoriginalSectionNodeが存在するpageId。どのページに存在するか検索を用意にするために渡している。
 * @param {number} originalSectionNodeId - 対象のoriginalSectionNodeId(originalとは回答を保持しないヘッダの隠しsectionのこと)
 * @returns
 *
 * <返り値のイメージ(ReportQuestion型は本来のものではありません)>
 * [
 *  [{ answer: 10 }, { answer: '回答1' }], // 1つの繰り返しセクションnodeが持つquestionNodeの配列
 *  [{ answer: 20 }, { answer: '回答2' }],
 *  [{ answer: 30 }, { answer: '回答3' }],
 * ]
 */
export const selectBodyRows =
  (pageId: number, originalSectionNodeId: number) =>
  (state: RootState): ReportNodeSchema[][] => {
    const allNodes = state.reportsState.reportResult.report.nodes
    const originalSectionUuid = allNodes[originalSectionNodeId]?.uuid ?? ''
    const page = state.reportsState.reportResult.report.pages[pageId]
    if (!page?.nodes) return []

    // ボディー行を抽出する
    const answerSectionNodeIds = page.nodes.filter(
      nodeId =>
        allNodes[nodeId]?.section?.originalNodeUuid === originalSectionUuid,
    )

    return answerSectionNodeIds.map(nodeId =>
      selectChildNodes(originalSectionNodeId, nodeId, state),
    )
  }

/**
 * 表の指定行に対する子ノードに紐づく質問一覧を取得する
 * 条件分岐が含まれる場合は分岐先も探索して、子ノードを取得する
 *
 * @param originalSectionNodeId 表のヘッダ行に相当するSectionのnodeId
 * @param parentNodeId 表の指定行に相当するSectionのnodeId
 * @returns 子ノードに紐づく質問一覧
 */
const selectChildNodes = (
  originalSectionNodeId: number,
  parentNodeId: number,
  state: RootState,
): ReportNodeSchema[] => {
  const visibleGridColumns =
    state.reportsState.reportResult.report.logicInfos.visibleGridColumns[
      originalSectionNodeId
    ] ?? {}
  const allNodes = state.reportsState.reportResult.report.nodes

  return getDisplayCellIds(parentNodeId, allNodes)
    .filter(cellId => {
      const questionNodeUUID = allNodes[cellId]?.uuid
      // 条件分岐の分岐状態を参照し、表示対象を絞り込む
      return questionNodeUUID && visibleGridColumns[questionNodeUUID]
    })
    .map(questionNodeId => allNodes[questionNodeId])
    .filter((item): item is ReportNodeSchema => !!item)
}

/**
 * rootNodeIdが持つ全てのノードIDの配列を取得する
 *
 * @param rootNodeId
 * @param allNodes
 * @returns
 */
const getDisplayCellIds = (rootNodeId: number, allNodes: ReportNodesDict) => {
  const childNodeIds: number[] = []
  walkChildNodes(rootNodeId, allNodes, childNodeIds, displayCellFilter)
  return childNodeIds
}

/**
 * rootNodeIdが持つ全てのノードIDを再帰的に探索する
 * @param rootNodeId
 * @param allNodes
 * @param result
 * @param filter
 * @returns
 */
const walkChildNodes = (
  rootNodeId: number,
  allNodes: ReportNodesDict,
  result: number[],
  filter: (rootNode: ReportNodeSchema) => boolean,
) => {
  const rootNode = allNodes[rootNodeId]
  if (isNullish(rootNode)) return

  if (filter(rootNode)) {
    result.push(rootNodeId)
  }

  for (const nodeId of rootNode.nodes) {
    walkChildNodes(nodeId, allNodes, result, filter)
  }
}

/**
 * 画面表示用のフィルター処理
 * @param rootNode
 * @returns
 */
const displayCellFilter = (rootNode: ReportNodeSchema) => {
  if (rootNode.type !== TemplateNodeTypeEnum.Question) {
    return false
  }
  // result に push する条件
  // * responseType=Question
  // * responseType=GridVariable の場合 isEnabled が true
  switch (rootNode.question?.responseType) {
    case ResponseTypeEnum.GRID_VARIABLE: {
      return !!rootNode.question?.responseGridVariables?.[0]?.isEnabled
    }
    default: {
      return true
    }
  }
}

/**
 * テーブルの1行分のCellたちのnodeIdを配列で返すセレクタ。
 * 画面表示用（OFFの取り込み項目と条件分岐ノードは取得対象外）
 * @param rowNodeId
 * @returns
 */
export const selectDisplayCellIds =
  (rowNodeId: number) => (state: RootState) => {
    const allNodes = state.reportsState.reportResult.report.nodes

    return getDisplayCellIds(rowNodeId, allNodes)
  }

/**
 * 指定のnodeの表示ステータスを取得する
 * 親ノードに条件分岐ノードが存在する場合はその状態も考慮してステータスを判定する
 *
 * @param nodeId
 * @returns 表示ステータス
 *  - NoAnswer: 未実施の状態
 *  - Disabled: 親ノードの条件分岐が成立していない
 *  - Other: 上記以外
 */
export const selectDisplayCellStatus =
  (nodeId: number) => (state: RootState) => {
    const questionNode = state.reportsState.reportResult.report.nodes[nodeId]

    if (questionNode?.question?.responseAnswer?.noAnswer) {
      return 'NoAnswer'
    }

    const parentNodeIdInfos =
      state.reportsState.reportResult.report.nodePathInfos.idPaths[nodeId]
    if (isNullish(parentNodeIdInfos)) return 'Other'
    const hasLogicParentNode = parentNodeIdInfos.some(
      info => info.type === TemplateNodeTypeEnum.Logic,
    )

    if (!hasLogicParentNode) {
      return 'Other'
    }
    // 以降の処理は、親ノードに条件分岐nodeが存在している場合

    const hasNoAnswerParent = parentNodeIdInfos
      .filter(info => info.type === TemplateNodeTypeEnum.Question)
      .some(
        info =>
          !!state.reportsState.reportResult.report.nodes[info.nodeId]?.question
            ?.responseAnswer?.noAnswer,
      )

    if (hasNoAnswerParent) {
      // 親ノードにNoAnswerが存在する場合はNoAnswer扱いにする
      return 'NoAnswer'
    }

    const logicNodeTable =
      state.reportsState.reportResult.report.logicInfos.nodeTable

    const hasInactiveParent = parentNodeIdInfos
      .filter(info => info.type === TemplateNodeTypeEnum.Logic)
      .some(info => !logicNodeTable[info.nodeId])

    if (hasInactiveParent) {
      // 親ノードに条件が成立していない条件分岐ノードが存在する場合はDisabled扱い
      return 'Disabled'
    }

    return 'Other'
  }
