import { IndustryTypeEnum } from '@ulysses-inc/harami_api_client'
import React from 'react'
import FoodSvg from 'src/assets/icons/industry/food.svg?react'
import ManufactureSvg from 'src/assets/icons/industry/food_manufacture.svg?react'
import HotelSvg from 'src/assets/icons/industry/hotel.svg?react'
import LogisticsSvg from 'src/assets/icons/industry/logistics.svg?react'
import MachineSvg from 'src/assets/icons/industry/machine.svg?react'
import OtherSvg from 'src/assets/icons/industry/other.svg?react'
import { HeaderBackButton } from 'src/components/header/HeaderBackButton'
import { White } from 'src/features/theme/KdsThemeColor'
import styled from 'styled-components'
import { IndustryNames } from './constants'

const ListTemplatesPageHeader = styled.div`
  border: 1px solid rgb(235, 237, 240);
  background-color: ${White};
  padding: 12px 24px 60px;
`

const TitleMessage = styled.div`
  font-weight: bold;
  font-size: 22px;
  text-align: center;
  margin-top: 24px;
`

const PageHeaderTitle = styled.div`
  font-size: 22px;
`

const PageHeaderSubTitle = styled.div`
  font-weight: normal;
  font-size: 14px;
  margin-top: 12px;
`

const SvgTag = styled.div`
  display: flex;
  justify-content: center;
`

const SvgTagIconWrap = styled.div`
  padding-left: 12px;
`

const SvgTagTitle = styled.span`
  color: #8c8c8c;
  padding-left: 18px;
  font-weight: bold;
  font-size: 16px;
`

interface OwnProps {
  backTitle: string
  title: string
  subTitle?: string
  onBack: () => void
}

export const getIndustryGroupInfo = (): {
  Icon: null | React.FunctionComponent<React.SVGProps<SVGSVGElement>>
  title: string
} => {
  const searchParams = new URLSearchParams(location.search)
  const type: IndustryTypeEnum | number = searchParams.get('industry')
    ? +(searchParams.get('industry') as unknown as IndustryTypeEnum)
    : 0

  switch (type) {
    case IndustryTypeEnum.FOOD:
      return { Icon: FoodSvg, title: IndustryNames.FOOD }
    case IndustryTypeEnum.MANUFACTURE:
      return { Icon: ManufactureSvg, title: IndustryNames.MANUFACTURE }
    case IndustryTypeEnum.HOTEL:
      return { Icon: HotelSvg, title: IndustryNames.HOTEL }
    case IndustryTypeEnum.LOGISTICS:
      return { Icon: LogisticsSvg, title: IndustryNames.LOGISTICS }
    case IndustryTypeEnum.MACHINE:
      return { Icon: MachineSvg, title: IndustryNames.MACHINE }
    case IndustryTypeEnum.OTHER:
      return { Icon: OtherSvg, title: IndustryNames.OTHER }
    case IndustryTypeEnum.NDF:
      return { Icon: null, title: IndustryNames.NDF }
    default:
      break
  }
  return { Icon: null, title: '' }
}

const TemplateHeader: React.FC<OwnProps> = (props: OwnProps) => {
  const { Icon, title } = getIndustryGroupInfo()
  return (
    <>
      <ListTemplatesPageHeader>
        <HeaderBackButton onClick={() => props.onBack()}>
          {props.backTitle}
        </HeaderBackButton>
        <TitleMessage>
          {props.subTitle ? (
            <>
              <PageHeaderTitle>{props.title}</PageHeaderTitle>
              <PageHeaderSubTitle>{props.subTitle}</PageHeaderSubTitle>
            </>
          ) : (
            <>
              <SvgTag>
                <SvgTagIconWrap>
                  {Icon && <Icon width={24} height={24} fill={'#8c8c8c'} />}
                </SvgTagIconWrap>
                <SvgTagTitle>{title}</SvgTagTitle>
              </SvgTag>
              <PageHeaderTitle>{props.title}</PageHeaderTitle>
            </>
          )}
        </TitleMessage>
      </ListTemplatesPageHeader>
    </>
  )
}

export default TemplateHeader
