import React from 'react'
import { IndustryInfo } from 'src/features/templateEdit/onboardingDynamic/types'
import { Primary } from 'src/features/theme/KdsThemeColor'
import {
  ButtonWrapper,
  CreateButton,
  Description,
  SvgTag,
  SvgTagIconWrap,
  SvgTagTitle,
  TableHeader,
  TableRow,
} from './ShowIndustryType.dumb'

interface OwnProps {
  showIndustries: IndustryInfo[][]
  onClick: (industry: IndustryInfo) => void
}

const ShowTemplateIndustryType: React.FC<OwnProps> = (props: OwnProps) => {
  return (
    <>
      <Description>テンプレートからつくる</Description>
      {/* メモ: 以下は`onboarding/CreateTemplateIndustry`の中にあるコードの完全なコピペ */}
      {props.showIndustries.map((industries: IndustryInfo[], index: number) => {
        return (
          <TableHeader key={`tableHeader-${index}`}>
            <TableRow key={`tableRow-${index}`}>
              {industries.map((industry: IndustryInfo, index: number) => {
                return (
                  <ButtonWrapper key={`industry-col-${index}`}>
                    <CreateButton
                      onClick={() => props.onClick(industry)}
                      disabled={industry.disabled}
                    >
                      <SvgTag>
                        {industry.svgComponent && (
                          <SvgTagIconWrap>
                            <industry.svgComponent
                              width={48}
                              height={48}
                              fill={Primary}
                            />
                          </SvgTagIconWrap>
                        )}
                        <SvgTagTitle>{industry.title}</SvgTagTitle>
                      </SvgTag>
                    </CreateButton>
                  </ButtonWrapper>
                )
              })}
            </TableRow>
          </TableHeader>
        )
      })}
    </>
  )
}

export default ShowTemplateIndustryType
