// 以下のESLintエラーは回避策がないようなので無視する https://github.com/import-js/eslint-plugin-import/issues/1479
// eslint-disable-next-line import/no-duplicates
import { format, parseISO } from 'date-fns'
// eslint-disable-next-line import/no-duplicates
import { ja } from 'date-fns/locale'

/**
 * ISO8601形式の日付文字列を`12/31(金)`の形式に変換する
 * 計算は実行環境のタイムゾーンにもとづき行われる。
 */
export const formatToJapaneseDate = (dateString: string): string => {
  const date = parseISO(dateString)
  try {
    return format(date, 'M/d(E)', { locale: ja })
  } catch {
    return ''
  }
}
