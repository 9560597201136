import { Text } from '@react-pdf/renderer'
import {
  NumberValue,
  ResponseNumberDecimalPoint,
} from '@ulysses-inc/harami_api_client'
import { FC } from 'react'
import { convertDecimal } from 'src/exShared/util/numberDecimalPoint/numberDecimalPoint'
import { gridLayoutTableBodyCell } from '../../../shared/styles'

type Props = {
  value?: NumberValue
  scale?: string
  decimalPoint?: ResponseNumberDecimalPoint
}

export const TableBodyCellNumber: FC<Props> = ({
  value,
  scale,
  decimalPoint,
}) => {
  const numberValue = value?.numberValue
  const cellValue =
    numberValue !== undefined ? convertDecimal(numberValue, decimalPoint) : ''

  return (
    <Text
      style={[
        gridLayoutTableBodyCell.baseTableBodyCell,
        gridLayoutTableBodyCell.tableBodyCell,
      ]}
    >
      {cellValue}
      {scale}
    </Text>
  )
}
