import {
  GetSchedulesRequest,
  Schedule,
  Template,
} from '@ulysses-inc/harami_api_client'
import { combineReducers } from 'redux'
import {
  AddScheduleAction,
  DeleteScheduleAction,
  ScheduleAction,
  SchedulesAction,
  SchedulesTemplatesAction,
  UpdateScheduleAction,
} from './actions'
import { ActionTypes, GetSchedulesFilter } from './types'

interface ScheduleState {
  scheduleId: string
  schedule: Schedule
  isLoading: boolean
  error: Error | null
}

const initialScheduleState: ScheduleState = {
  scheduleId: '',
  schedule: {},
  isLoading: false,
  error: null,
}

const schedule = (
  state: ScheduleState = initialScheduleState,
  action: ScheduleAction,
): ScheduleState => {
  switch (action.type) {
    case ActionTypes.REQUEST_RESET_SCHEDULE: {
      return {
        ...state,
        ...initialScheduleState,
      }
    }
    case ActionTypes.REQUEST_GET_SCHEDULE: {
      return {
        ...state,
        scheduleId: action.scheduleId,
        isLoading: action.isLoading,
        error: null,
      }
    }
    case ActionTypes.SUCCESS_GET_SCHEDULE: {
      return {
        ...state,
        isLoading: action.isLoading,
        schedule: action.schedule,
        error: null,
      }
    }
    case ActionTypes.ERROR_GET_SCHEDULE: {
      return {
        ...state,
        isLoading: action.isLoading,
        error: action.error,
      }
    }
    default: {
      return { ...state }
    }
  }
}

interface AddScheduleState {
  isLoading: boolean
  error: Error | null
}

const initialAddScheduleState: AddScheduleState = {
  isLoading: false,
  error: null,
}

const addSchedule = (
  state: AddScheduleState = initialAddScheduleState,
  action: AddScheduleAction,
): AddScheduleState => {
  switch (action.type) {
    case ActionTypes.REQUEST_ADD_SCHEDULE: {
      return { ...state, isLoading: action.isLoading, error: null }
    }
    case ActionTypes.SUCCESS_ADD_SCHEDULE: {
      return { ...state, isLoading: action.isLoading, error: null }
    }
    case ActionTypes.ERROR_ADD_SCHEDULE: {
      return { ...state, isLoading: action.isLoading, error: action.error }
    }
    default: {
      return { ...state }
    }
  }
}

interface UpdateScheduleState {
  isLoading: boolean
  error: Error | null
}

const initialUpdateScheduleState: UpdateScheduleState = {
  isLoading: false,
  error: null,
}

const updateSchedule = (
  state: UpdateScheduleState = initialUpdateScheduleState,
  action: UpdateScheduleAction,
): UpdateScheduleState => {
  switch (action.type) {
    case ActionTypes.REQUEST_UPDATE_SCHEDULE: {
      return {
        ...state,
        isLoading: action.isLoading,
        error: null,
      }
    }
    case ActionTypes.SUCCESS_UPDATE_SCHEDULE: {
      return {
        ...state,
        isLoading: action.isLoading,
        error: null,
      }
    }
    case ActionTypes.ERROR_UPDATE_SCHEDULE: {
      return {
        ...state,
        isLoading: action.isLoading,
        error: action.error,
      }
    }
    default: {
      return { ...state }
    }
  }
}

interface SchedulesState {
  schedules: Schedule[]
  favoriteTemplates: Template[]
  isLoading: boolean
  error: Error | null
  count: number
  request: GetSchedulesRequest
  filter: GetSchedulesFilter
}

// TODO: favoriteTemplatesを取得すること
const initialSchedulesState: SchedulesState = {
  schedules: [],
  favoriteTemplates: [],
  isLoading: false,
  error: null,
  count: 0,
  request: {
    offset: 0,
    limit: 25,
  },
  filter: {},
}

const schedules = (
  state: SchedulesState = initialSchedulesState,
  action: SchedulesAction | AddScheduleAction | DeleteScheduleAction,
): SchedulesState => {
  switch (action.type) {
    case ActionTypes.REQUEST_GET_SCHEDULES: {
      return {
        ...state,
        isLoading: action.isLoading,
        schedules: [],
        favoriteTemplates: [],
        error: null,
        request: action.request,
        filter: action.filter ?? {},
      }
    }
    case ActionTypes.SUCCESS_GET_SCHEDULES: {
      return {
        ...state,
        isLoading: action.isLoading,
        schedules: action.schedules ?? [],
        error: null,
        count: action.count ?? 0,
      }
    }
    case ActionTypes.ERROR_GET_SCHEDULES: {
      return {
        ...state,
        isLoading: action.isLoading,
        error: action.error,
        count: 0,
      }
    }
    case ActionTypes.REQUEST_CHANGE_SCHEDULES_PAGE: {
      return {
        ...state,
        request: action.request,
      }
    }
    case ActionTypes.REQUEST_CHANGE_SCHEDULES_SIZE: {
      return {
        ...state,
        request: action.request,
      }
    }
    case ActionTypes.UPDATE_PAGINATION: {
      return {
        ...state,
        request: action.request,
      }
    }
    case ActionTypes.REQUEST_ADD_SCHEDULE: {
      return {
        ...state,
        request: initialSchedulesState.request,
      }
    }
    case ActionTypes.REQUEST_DELETE_SCHEDULE: {
      return {
        ...state,
      }
    }
    case ActionTypes.REQUEST_DELETE_SCHEDULES: {
      return {
        ...state,
      }
    }
    default: {
      return { ...state }
    }
  }
}

interface SchedulesTemplatesState {
  templates: Template[]
  isLoading: boolean
  error: Error | null
}

const initialSchedulesTemplatesState: SchedulesTemplatesState = {
  templates: [],
  isLoading: false,
  error: null,
}

const schedulesTemplates = (
  state: SchedulesTemplatesState = initialSchedulesTemplatesState,
  action: SchedulesTemplatesAction,
) => {
  switch (action.type) {
    case ActionTypes.REQUEST_GET_SCHEDULES_TEMPLATES: {
      return {
        ...state,
        templates: [],
        isLoading: true,
        error: null,
      }
    }
    case ActionTypes.SUCCESS_GET_SCHEDULES_TEMPLATES: {
      return {
        ...state,
        templates: action.templates,
        isLoading: false,
      }
    }
    case ActionTypes.ERROR_GET_SCHEDULES_TEMPLATES: {
      return {
        ...state,
        isLoading: false,
        error: action.error,
      }
    }
    case ActionTypes.RESET_SCHEDULES_TEMPLATES: {
      return { ...state, ...initialSchedulesTemplatesState }
    }
    default: {
      return { ...state }
    }
  }
}

const schedulesReducer = combineReducers({
  schedule,
  updateSchedule,
  schedules,
  schedulesTemplates,
  addSchedule,
})

export default schedulesReducer
