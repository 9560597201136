import { AppRoleEnum, ApprovalFlow } from '@ulysses-inc/harami_api_client'
import { Button, Divider, Drawer, Input, Typography } from 'antd'
import { FormikHelpers, useFormik } from 'formik'
import React, { useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import FormikErrorMessage from 'src/components/formikErrorMessage/FormikErrorMessage'
import Loading from 'src/components/loading/Loading'
import LoadingDrawer from 'src/components/loading/LoadingDrawer'
import Yup from 'src/features/validation/yup'
import {
  addApprovalFlow as addApprovalFlowAction,
  getApprovalFlow as getApprovalFlowAction,
  resetApprovalFlowForAdd as resetApprovalFlowForAddAction,
  resetApprovalFlowForUpdate as resetApprovalFlowForUpdateAction,
  updateApprovalFlow as updateApprovalFlowAction,
} from 'src/state/ducks/approvalFlows/actions'
import { getUsers as getUsersAction } from 'src/state/ducks/users/actions'
import { RootState } from 'src/state/store'
import {
  ButtonGroup,
  DrawerTitle,
  FormRow,
} from './EditApprovalFlowDrawer.dumb'
import EditApprovalFlowSteps from './EditApprovalFlowSteps'

interface OwnProps {
  approvalFlowId: string
  isShowDrawer: boolean
  isEditing: boolean
  onClose: () => void
}

const validationSchema = Yup.object().shape({
  name: Yup.string().required().label('タイトル'),
})

const newValues = {
  name: '',
  description: '',
  steps: [{ approvers: [{}] }],
}

const { Text } = Typography
const { TextArea } = Input

const EditApprovalFlowDrawerContainer: React.FC<OwnProps> = ({
  isShowDrawer,
  isEditing,
  approvalFlowId,
  onClose,
}) => {
  const users = useSelector(
    ({
      usersState: {
        users: { users },
      },
    }: RootState) =>
      users.filter(({ role }) => role?.role !== AppRoleEnum.REPORTER),
    shallowEqual,
  )
  const approvalFlow = useSelector(
    (state: RootState) => state.approvalFlowsState.approvalFlow.approvalFlow,
    shallowEqual,
  )
  const isLoadingUsers = useSelector(
    (state: RootState) => state.usersState.users.isLoading,
    shallowEqual,
  )
  const isLoadingApprovalFlows = useSelector(
    (state: RootState) => state.approvalFlowsState.approvalFlow.isLoading,
    shallowEqual,
  )
  const {
    isLoading: isLoadingAddApprovalFlow,
    isCompleted: isCompletedAddApprovalFlow,
  } = useSelector(
    (state: RootState) => state.approvalFlowsState.addApprovalFlow,
    shallowEqual,
  )
  const {
    isLoading: isLoadingUpdateApprovalFlow,
    isCompleted: isCompletedUpdateApprovalFlow,
  } = useSelector(
    (state: RootState) => state.approvalFlowsState.updateApprovalFlow,
    shallowEqual,
  )

  const dispatch = useDispatch()

  const {
    values,
    handleChange,
    errors,
    touched,
    handleSubmit,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues: isEditing
      ? {
          name: approvalFlow.name,
          description: approvalFlow.description,
          steps: approvalFlow.steps,
        }
      : newValues,
    validationSchema,
    onSubmit: (
      values: ApprovalFlow,
      { setErrors }: FormikHelpers<ApprovalFlow>,
    ) => {
      // TODO move this into `validationSchema` to resolve the delay of displaying the error
      const isNotEmptyApprovers =
        (values.steps ?? [])
          .map(({ approvers }) => approvers ?? [])
          .reduce((prev, cur) => prev.concat(cur), [])
          .find(({ id }) => id === undefined) === undefined

      if (!isNotEmptyApprovers) {
        setErrors({ steps: '承認者は必須です' })
        return
      }

      // セットされている承認者がユーザー一覧に存在するかチェック
      // NOTE: 論理削除済みのユーザーは users に含まれない
      const allApproversAreActive = (values.steps ?? [])
        .map(({ approvers }) => approvers ?? [])
        .reduce((prev, cur) => prev.concat(cur), [])
        .every(({ id }) => users.some(user => user.id === id))

      // 設定されている承認者が存在しない（論理削除済み）の場合はエラーメッセージを表示
      if (!allApproversAreActive) {
        setErrors({ steps: '削除済みの承認者が設定されています' })
        return
      }

      if (isEditing) {
        dispatch(updateApprovalFlowAction(approvalFlowId, values))
      } else {
        dispatch(addApprovalFlowAction(values))
      }
    },
    enableReinitialize: true,
  })

  useEffect(() => {
    dispatch(getUsersAction({}))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (isEditing) {
      dispatch(getApprovalFlowAction(approvalFlowId))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditing])

  // 新規作成の時に
  // - Formをリセット
  // - Drawerをクローズ
  // - isCompleted を false にリセットする
  useEffect(() => {
    if (!isEditing && isCompletedAddApprovalFlow) {
      setFieldValue('steps', [{ approvers: [{}] }])
      resetForm()
      onClose()
      dispatch(resetApprovalFlowForAddAction())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditing, isCompletedAddApprovalFlow])

  // 更新の時に
  // - Formをリセット
  // - Drawerをクローズ
  // - isCompleted を false にリセットする
  useEffect(() => {
    if (isEditing && isCompletedUpdateApprovalFlow) {
      setFieldValue('steps', [{ approvers: [{}] }])
      resetForm()
      onClose()
      dispatch(resetApprovalFlowForUpdateAction())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditing, isCompletedUpdateApprovalFlow])

  let isLoading = isLoadingApprovalFlows
  if (isEditing) {
    isLoading = isLoading || isLoadingUpdateApprovalFlow
  } else {
    isLoading = isLoading || isLoadingAddApprovalFlow
  }

  return (
    <Drawer
      placement="right"
      closable={false}
      destroyOnClose={true}
      open={isShowDrawer}
      width={600}
    >
      <DrawerTitle>承認フローの{isEditing ? '編集' : '追加'}</DrawerTitle>
      <FormRow>
        <Text strong>タイトル</Text>
        <Input onChange={handleChange('name')} value={values.name} />
        <div>
          <FormikErrorMessage name="name" errors={errors} touched={touched} />
        </div>
      </FormRow>
      <FormRow>
        <Text strong>説明</Text>
        <TextArea
          rows={4}
          onChange={handleChange('description')}
          value={values.description}
        />
        <div>
          <FormikErrorMessage
            name="description"
            errors={errors}
            touched={touched}
          />
        </div>
      </FormRow>
      <FormRow>
        <Text strong>承認フロー</Text>
        {isLoadingUsers ? (
          <Loading />
        ) : (
          <EditApprovalFlowSteps
            steps={values.steps}
            users={users}
            errors={errors}
            touched={touched}
            setFieldValue={setFieldValue}
          />
        )}
        <div>
          <FormikErrorMessage name="steps" errors={errors} />
        </div>
      </FormRow>
      <Divider />
      <FormRow>
        <ButtonGroup>
          <Button
            disabled={isLoading} // ローディング中はキャンセルできないようにする
            type="default"
            style={{ marginRight: '20px' }}
            onClick={() => {
              onClose()
              resetForm()
            }}
          >
            キャンセル
          </Button>
          <LoadingDrawer
            spinning={isLoading}
            render={
              <Button
                type="primary"
                onClick={() => {
                  handleSubmit()
                }}
              >
                保存
              </Button>
            }
          />
        </ButtonGroup>
      </FormRow>
    </Drawer>
  )
}

export default EditApprovalFlowDrawerContainer
