import {
  AddPlaceGroupRequest,
  AddPlaceV2Request,
  DeletePlaceNodeRequest,
  GetPlacesRequest,
  GetPlacesResponse,
  GetPlacesV2Request,
  PlacesApi,
  UpdatePlaceGroupRequest,
  UpdatePlaceV2Request,
} from '@ulysses-inc/harami_api_client'
import { call, put, takeEvery } from 'redux-saga/effects'
import BaseClient from '../../middleware/saga/baseClient'
import { handleHTTPError } from '../../middleware/saga/handleHttpError'
import interceptionsActions from '../interceptions/actions'
import { default as actions } from './actions'
import { ActionTypes } from './types'

const baseClient = new BaseClient()

const getPlacesRequest = (req: GetPlacesRequest) => {
  return baseClient
    .getApi(PlacesApi)
    .getPlaces(req)
    .then(places => places)
    .catch(handleHTTPError)
}

const getPlacesV2Request = (req: GetPlacesV2Request) => {
  return baseClient
    .getApi(PlacesApi)
    .getPlacesV2(req)
    .then(places => places)
    .catch(handleHTTPError)
}

const getPlaceGroupsRequest = () => {
  return baseClient
    .getApi(PlacesApi)
    .getPlaceGroups()
    .then(groups => groups)
    .catch(handleHTTPError)
}

const getPlaceNodesRequest = () => {
  return baseClient
    .getApi(PlacesApi)
    .getPlaceNodes()
    .then(nodes => nodes)
    .catch(handleHTTPError)
}

const addPlaceV2Request = (req: AddPlaceV2Request) => {
  return baseClient
    .getApi(PlacesApi)
    .addPlaceV2(req)
    .then(node => node)
    .catch(handleHTTPError)
}

const updatePlaceV2Request = (req: UpdatePlaceV2Request) => {
  return baseClient
    .getApi(PlacesApi)
    .updatePlaceV2(req)
    .then(node => node)
    .catch(handleHTTPError)
}

const deletePlaceNodeRequest = (req: DeletePlaceNodeRequest) => {
  return baseClient
    .getApi(PlacesApi)
    .deletePlaceNode(req)
    .catch(handleHTTPError)
}

const addPlaceGroupRequest = (req: AddPlaceGroupRequest) => {
  return baseClient
    .getApi(PlacesApi)
    .addPlaceGroup(req)
    .then(node => node)
    .catch(handleHTTPError)
}

const updatePlaceGroupRequest = (req: UpdatePlaceGroupRequest) => {
  return baseClient
    .getApi(PlacesApi)
    .updatePlaceGroup(req)
    .then(node => node)
    .catch(handleHTTPError)
}

function* getPlaces(action: ReturnType<typeof actions.getPlaces>) {
  try {
    const response: GetPlacesResponse = yield call(
      getPlacesRequest,
      action.request,
    )
    yield put(
      actions.getSuccessPlaces(response.placeNodes ?? [], response.count ?? 0),
    )
  } catch (error) {
    yield put(interceptionsActions.handleHttpError(error))
    yield put(actions.getErrorPlaces(error))
  }
}

function* getPlacesV2(action: ReturnType<typeof actions.getPlacesV2>) {
  try {
    const response: GetPlacesResponse = yield call(getPlacesV2Request, {
      ...action.request,
      placeFilter: { ...action.filter },
    })
    yield put(
      actions.getSuccessPlaces(response.placeNodes ?? [], response.count ?? 0),
    )
  } catch (error) {
    yield put(interceptionsActions.handleHttpError(error))
    yield put(actions.getErrorPlaces(error))
  }
}

function* addPlace(action: ReturnType<typeof actions.addPlace>) {
  try {
    const response = yield call(addPlaceV2Request, action.request)
    yield put(actions.changeIsShowEditPlaceDrawer(false))
    yield put(actions.getSuccessPlaces(response.placeNodes, response.count))
  } catch (error) {
    yield put(interceptionsActions.handleHttpError(error))
    yield put(actions.addErrorPlace(error))
  }
}

function* updatePlace(action: ReturnType<typeof actions.updatePlace>) {
  try {
    const response = yield call(updatePlaceV2Request, action.request)
    yield put(actions.changeIsShowEditPlaceDrawer(false))
    yield put(actions.getSuccessPlaces(response.placeNodes, response.count))
  } catch (error) {
    yield put(interceptionsActions.handleHttpError(error))
    yield put(actions.updateErrorPlace(error))
  }
}

function* deletePlace(action: ReturnType<typeof actions.deletePlace>) {
  try {
    yield call(deletePlaceNodeRequest, { placeNodeId: action.placeId })
    yield put(actions.deleteSuccessPlace(action.placeId))
  } catch (error) {
    yield put(interceptionsActions.handleHttpError(error))
    yield put(actions.deleteErrorPlace(error))
  }
}

function* getPlaceGroups() {
  try {
    const nodes = yield call(getPlaceGroupsRequest)
    yield put(actions.getSuccessPlaceGroups(nodes))
  } catch (error) {
    yield put(interceptionsActions.handleHttpError(error))
    yield put(actions.getErrorPlaceGroups(error))
  }
}

function* addPlaceGroup(action: ReturnType<typeof actions.addPlaceGroup>) {
  try {
    const response = yield call(addPlaceGroupRequest, {
      placeNode: action.node,
    })
    yield put(actions.changeIsShowEditPlaceGroupDrawer(false))
    yield put(actions.addSuccessPlaceGroup(response))
  } catch (error) {
    yield put(interceptionsActions.handleHttpError(error))
    yield put(actions.addErrorPlaceGroup(error))
  }
}

function* updatePlaceGroup(
  action: ReturnType<typeof actions.updatePlaceGroup>,
) {
  try {
    const response = yield call(updatePlaceGroupRequest, {
      placeNodeId: action.nodeId,
      placeNode: action.node,
    })
    yield put(actions.changeIsShowEditPlaceGroupDrawer(false))
    yield put(actions.updateSuccessPlaceGroup(response))
  } catch (error) {
    yield put(interceptionsActions.handleHttpError(error))
    yield put(actions.updateErrorPlaceGroup(error))
  }
}

function* deletePlaceGroup(
  action: ReturnType<typeof actions.deletePlaceGroup>,
) {
  try {
    yield call(deletePlaceNodeRequest, { placeNodeId: action.nodeId })
    yield put(actions.getPlaceGroups())
    yield put(actions.deleteSuccessPlaceGroup(action.nodeId))
  } catch (error) {
    yield put(interceptionsActions.handleHttpError(error))
    yield put(actions.deleteErrorPlaceGroup(error))
  }
}

function* getPlaceNodes() {
  try {
    const nodes = yield call(getPlaceNodesRequest)
    yield put(actions.getSuccessPlaceNodes(nodes))
  } catch (error) {
    yield put(interceptionsActions.handleHttpError(error))
    yield put(actions.getErrorPlaceNodes(error))
  }
}

const sagas = [
  takeEvery(ActionTypes.REQUEST_GET_PLACES, getPlaces),
  takeEvery(ActionTypes.REQUEST_CHANGE_PLACES_PAGE, getPlaces),
  takeEvery(ActionTypes.REQUEST_CHANGE_PLACES_SIZE, getPlaces),
  takeEvery(ActionTypes.REQUEST_GET_PLACES_V2, getPlacesV2),
  takeEvery(ActionTypes.REQUEST_CHANGE_PLACES_V2_PAGE, getPlacesV2),
  takeEvery(ActionTypes.REQUEST_CHANGE_PLACES_V2_SIZE, getPlacesV2),
  takeEvery(ActionTypes.REQUEST_ADD_PLACE, addPlace),
  takeEvery(ActionTypes.REQUEST_UPDATE_PLACE, updatePlace),
  takeEvery(ActionTypes.REQUEST_DELETE_PLACE, deletePlace),
  takeEvery(ActionTypes.REQUEST_GET_PLACE_GROUPS, getPlaceGroups),
  takeEvery(ActionTypes.REQUEST_ADD_PLACE_GROUP, addPlaceGroup),
  takeEvery(ActionTypes.REQUEST_UPDATE_PLACE_GROUP, updatePlaceGroup),
  takeEvery(ActionTypes.REQUEST_DELETE_PLACE_GROUP, deletePlaceGroup),
  takeEvery(ActionTypes.REQUEST_GET_PLACE_NODES, getPlaceNodes),
]

export default sagas
