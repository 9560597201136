import { Typography } from 'antd'
import { Black, LogicBackgroundColor } from 'src/features/theme/KdsThemeColor'
import styled from 'styled-components'

const { Text } = Typography

export const LogicWrapper = styled.div`
  margin-left: 15px;
`

export const HeaderText = styled(Text)`
  text-align: center;
  color: ${Black};
`
export const LogicHeader = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 46px;
  background-color: ${LogicBackgroundColor};
`

export const ConfirmModalContentList = styled.ul`
  max-height: 220px;
  padding-left: 20px;
  padding-bottom: 30px;
  overflow-y: scroll;
`
