import { Button, Layout } from 'antd'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Header } from 'src/components/header/Header'
import { HeaderBackButton } from 'src/components/header/HeaderBackButton'
import { HeaderTitle } from 'src/components/header/HeaderTitle'
import Loading from 'src/components/loading/Loading'
import {
  deleteApprovalFlow as deleteApprovalFlowAction,
  getApprovalFlows as getApprovalFlowsAction,
} from 'src/state/ducks/approvalFlows/actions'
import { RootState } from 'src/state/store'
import ApprovalFlowList from './ApprovalFlowList'
import { AddApprovalFlowRow } from './ApprovalFlows.dumb'
import EditApprovalFlowDrawer from './EditApprovalFlowDrawer'

const { Content } = Layout

const ApprovalFlowsContainer: React.FC = () => {
  const [isShowEditApprovalFLowDrawer, setIsShowEditApprovalFLowDrawer] =
    useState(false)
  const [isEditingApprovalFLow, setIsEditingApprovalFLow] = useState(false)
  const [editApprovalFlowId, setEditApprovalFlowId] = useState('')

  const dispatch = useDispatch()
  const { approvalFlows, isLoading: isLoadingApprovalFlows } = useSelector(
    (state: RootState) => state.approvalFlowsState.approvalFlows,
  )

  const history = useHistory()

  useEffect(() => {
    dispatch(getApprovalFlowsAction())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onClickAdd = () => setIsShowEditApprovalFLowDrawer(true)
  const onEdit = (approvalFlowId: string) => {
    setEditApprovalFlowId(approvalFlowId)
    setIsEditingApprovalFLow(true)
    setIsShowEditApprovalFLowDrawer(true)
  }
  const onDelete = (approvalFlowId: string) => {
    dispatch(deleteApprovalFlowAction(approvalFlowId))
  }
  const onClose = () => {
    setIsShowEditApprovalFLowDrawer(false)
    setIsEditingApprovalFLow(false)
  }

  return (
    <Layout>
      <Header>
        <HeaderBackButton onClick={() => history.goBack()} />
        <HeaderTitle title="承認フロー" />
      </Header>
      <Content>
        {isLoadingApprovalFlows ? (
          <Loading />
        ) : (
          <>
            <AddApprovalFlowRow justify="end">
              <Button type="primary" onClick={onClickAdd}>
                承認フローを追加
              </Button>
            </AddApprovalFlowRow>
            <ApprovalFlowList
              onEdit={onEdit}
              onDelete={onDelete}
              approvalFlows={approvalFlows}
            />
          </>
        )}
      </Content>
      <EditApprovalFlowDrawer
        approvalFlowId={editApprovalFlowId}
        isShowDrawer={isShowEditApprovalFLowDrawer}
        isEditing={isEditingApprovalFLow}
        onClose={onClose}
      />
    </Layout>
  )
}

export default ApprovalFlowsContainer
