import { EmailApi, SendEmailRequest } from '@ulysses-inc/harami_api_client'
import { call, put, takeEvery } from 'redux-saga/effects'
import * as notificationServiceActions from 'src/features/notificationService/slice'
import BaseClient from '../../middleware/saga/baseClient'
import { handleHTTPError } from '../../middleware/saga/handleHttpError'
import interceptionsActions from '../interceptions/actions'
import { default as actions } from './actions'
import { ActionTypes } from './types'

const baseClient = new BaseClient()

const sendEmailRequest = (req: SendEmailRequest) => {
  return baseClient.getApi(EmailApi).sendEmail(req).catch(handleHTTPError)
}

function* sendEmail(action: ReturnType<typeof actions.requestSendEmail>) {
  try {
    yield call(sendEmailRequest, { sendEmail: action.sendEmail })
    yield put(actions.successSendEmail())
    if (action.message !== '') {
      yield put(
        notificationServiceActions.showNotification({
          message: action.message,
        }),
      )
    }
  } catch (error) {
    yield put(interceptionsActions.handleHttpError(error))
    yield put(actions.errorSendEmail(error))
  }
}

const sagas = [takeEvery(ActionTypes.REQUEST_SEND_EMAIL, sendEmail)]

export default sagas
