import { AppRoleEnum } from '@ulysses-inc/harami_api_client'
import { localStorageKeys } from 'src/constants/localStorageKeys'

let fakeAppRole: AppRoleEnum | null = null

const setFakeAppRole = (role: AppRoleEnum | null) => {
  fakeAppRole = role
}

const resetFakeRole = () => {
  fakeAppRole = null
}

const appRole = (): AppRoleEnum | null => {
  if (fakeAppRole !== null) {
    return fakeAppRole
  }
  const parsedAppRole = parseInt(
    localStorage.getItem(localStorageKeys.loginUserRole) ?? '0',
    10,
  )
  if (
    parsedAppRole === 0 ||
    !Object.values(AppRoleEnum).includes(parsedAppRole)
  )
    return null
  return parsedAppRole
}

export { appRole, resetFakeRole, setFakeAppRole }
