import { css } from '@emotion/react'
import { UploadFile } from 'antd/lib/upload/interface'
import React from 'react'
import { Thumb } from 'src/features/improves/improveDetail/listItem/picturesWall/Thumb'
import { White } from 'src/features/theme/KdsThemeColor'

type Props = {
  fileList: UploadFile[]
  disabled: boolean
  handlePreview: (index: number) => void
  handleRemove: (file: UploadFile) => void
}

export const ThumbList: React.FC<Props> = ({
  fileList,
  disabled,
  handlePreview,
  handleRemove,
}) => {
  if (!fileList.length) {
    return null
  }

  const handlePreviewClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index = 0,
  ) => {
    event.preventDefault()
    event.stopPropagation()
    handlePreview(index)
  }

  const handleRemoveClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    file: UploadFile,
  ) => {
    event.preventDefault()
    event.stopPropagation()
    handleRemove(file)
  }

  return (
    <div css={styles.container}>
      <div css={styles.inner}>
        {fileList.map((file, index) => {
          return (
            <Thumb
              disabled={disabled}
              imgProps={{
                alt: file.name,
                src: file.url || '',
              }}
              isLoading={!file.url}
              key={file.uid}
              onClickPreview={e => handlePreviewClick(e, index)}
              onClickRemove={e => handleRemoveClick(e, file)}
            />
          )
        })}
      </div>
    </div>
  )
}

const styles = {
  container: css`
    position: relative;
    display: flex;
    align-items: flex-start;
    height: calc(100% + 2px);
    background-color: ${White};
    z-index: 2;
    margin: -1px;
  `,
  inner: css`
    flex: 1 1 auto;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 8px 0 12px 4px;
  `,
}
