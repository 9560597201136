import { css } from '@emotion/react'
import { Image as ApiImage } from '@ulysses-inc/harami_api_client'
import { FC, useRef, useState } from 'react'
import { getFormattedRecordedAt } from 'src/exShared/util/date'
import { PhotoSliderBullets } from 'src/features/reports/result/components/reportResult/PhotoSlider/PhotoSliderBullets'
import { PhotoSliderDateText } from 'src/features/reports/result/components/reportResult/PhotoSlider/PhotoSliderDateText'
import { Navigation, Pagination } from 'swiper/modules'
import { Swiper, SwiperRef, SwiperSlide } from 'swiper/react'

export interface PhotoSliderProps {
  photos: ApiImage[]
  onImageTouched: (image: ApiImage, selectedImageIndex: number) => void
  isTimeDisplayed: boolean
}

// photo list with scroll by button for web
// https://github.com/Ulysses-inc/harami_client/pull/3516
export const PhotoSlider: FC<PhotoSliderProps> = props => {
  const { isTimeDisplayed, onImageTouched, photos } = props

  const [activeBulletIndex, setActiveBulletIndex] = useState(0)
  const [bulletCount, setBulletCount] = useState(0)

  const swiperRef = useRef<SwiperRef>(null)

  return (
    <>
      <Swiper
        css={styles.swiperContainer}
        modules={[Navigation, Pagination]}
        navigation
        slidesPerView="auto"
        spaceBetween={8}
        pagination
        onPaginationUpdate={({
          activeIndex,
          pagination: { bullets },
          isEnd,
        }) => {
          // 画面幅によってBulletの数は常に変わりうる
          setBulletCount(bullets.length)

          // 一番最後の写真に到達した時にswiperがよこすactiveIndexの値が
          // 正しくない(最後より一つ前の値になってしまう)ため所要の手当をしておく
          if (isEnd) {
            const lastIndex = bullets.length - 1
            setActiveBulletIndex(lastIndex)
          } else {
            setActiveBulletIndex(activeIndex)
          }
        }}
        ref={swiperRef}
      >
        {photos.map((image, index) => (
          <SwiperSlide
            key={index}
            style={styles.swiperSlide}
            onClick={() => onImageTouched(image, index)}
          >
            <img css={styles.swiperSlideImage} src={image.url} />
            {
              // NOTE: image.recordedAtはfalsyにならないが、OpenAPIの定義でOptional Propertyなので&&演算子を使用
              // OpenAPIの定義を見直すべき
              // 詳細： https://github.com/Ulysses-inc/harami_client/pull/3097#discussion_r873563053
              image.recordedAt && isTimeDisplayed && (
                <PhotoSliderDateText
                  dateText={getFormattedRecordedAt(image.recordedAt)}
                />
              )
            }
          </SwiperSlide>
        ))}
      </Swiper>
      <PhotoSliderBullets
        bulletCount={bulletCount}
        activeBulletIndex={activeBulletIndex}
        onClickBullet={index => swiperRef.current?.swiper.slideTo(index)}
      />
    </>
  )
}

const styles = {
  swiperContainer: css`
    margin-bottom: 8px;
    // widthを100%に指定しておかないと親要素のスタイルとの兼ね合いで
    // 意図せず枠外に写真がはみ出す場合があるので注意
    width: 100%;
  `,
  // SwiperSlideにemotionでスタイルを適用しようとすると
  // 動作しなくなるのであえて使っていない
  swiperSlide: {
    width: '276px',
    maxWidth: '100%',
    borderRadius: '4px',
    border: '1px solid #d9d9d9',
    overflow: 'hidden',
  },
  swiperSlideImage: css`
    width: 100%;
    // レイアウトシフトが発生しないよう、予めアスペクト比を指定しておく。
    // 画像が読み込まれた後は、画像のアスペクト比が優先される。
    // https://coliss.com/articles/build-websites/operation/work/avoiding-img-layout-shifts.html
    aspect-ratio: auto 3 / 2;
  `,
}
