import { MultipleChoice } from '@ulysses-inc/harami_api_client'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'
import { FC } from 'react'
import { TMultipleChoicesState } from './AddMultipleChoiceDrawer'
import { CenteredCheckbox } from './AddMultipleChoiceDrawer.styled'

const ValidityColumn: FC<{
  multipleChoicesState: TMultipleChoicesState
  multipleChoice: MultipleChoice
}> = ({
  multipleChoicesState: [multipleChoices, setMultipleChoices],
  multipleChoice,
}) => {
  const onChange = (e: CheckboxChangeEvent) => {
    setMultipleChoices({
      ...multipleChoices,
      [`${multipleChoice.id}`]: {
        ...multipleChoice,
        isInvalid: e.target.checked ? 1 : 0,
        isDefault: 0,
      },
    })
  }

  return (
    <CenteredCheckbox
      onChange={onChange}
      checked={multipleChoice.isInvalid === 1}
    />
  )
}

export default ValidityColumn
