import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons'
import { ExcelConversionLogStatusEnum } from '@ulysses-inc/harami_api_client'
import { Danger, Success } from 'src/features/theme/KdsThemeColor'
import styled from 'styled-components'

const StatusWrapper = styled.div`
  display: flex;
  align-items: center;
`
const CheckCircleOutlinedIcon = styled(CheckCircleOutlined)`
  font-size: 28px;
`

const ExclamationCircleOutlinedIcon = styled(ExclamationCircleOutlined)`
  font-size: 28px;
`

const StatusText = styled.div`
  margin-left: 10px;
  color: ${props => props.theme.color};
  font-size: 14px;
  font-weight: bold;
`

export const getStatusBadge = (status: number | undefined) => {
  if (status === ExcelConversionLogStatusEnum.SUCCESS) {
    return (
      <StatusWrapper>
        <CheckCircleOutlinedIcon style={{ color: Success }} />
        <StatusText theme={{ color: Success }}>成功</StatusText>
      </StatusWrapper>
    )
  } else {
    return (
      <StatusWrapper>
        <ExclamationCircleOutlinedIcon style={{ color: Danger }} />
        <StatusText theme={{ color: Danger }}>失敗</StatusText>
      </StatusWrapper>
    )
  }
}
