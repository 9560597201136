import {
  GetImproves,
  GetImprovesV2Request,
} from '@ulysses-inc/harami_api_client'
import { ActionTypes, GetImprovesFilter } from '../types'

const getImproves = (
  request: GetImprovesV2Request,
  filter?: GetImprovesFilter,
) =>
  ({
    type: ActionTypes.REQUEST_GET_IMPROVES,
    isLoading: true,
    request,
    filter,
  }) as const

const getSuccessImproves = ({ improves, count }: GetImproves) =>
  ({
    type: ActionTypes.SUCCESS_GET_IMPROVES,
    isLoading: false,
    improves,
    count,
  }) as const

const getErrorImproves = (error: Error) =>
  ({
    type: ActionTypes.ERROR_GET_IMPROVES,
    isLoading: false,
    error,
  }) as const

const changeImprovesPage = (
  page: number,
  pageSize?: number,
  filter?: GetImprovesFilter,
) =>
  ({
    type: ActionTypes.REQUEST_CHANGE_IMPROVES_PAGE,
    isLoading: true,
    request: {
      limit: pageSize,
      offset: pageSize ? (page - 1) * pageSize : 0,
    },
    filter,
  }) as const

const changeImprovesSize = (pageSize: number, filter?: GetImprovesFilter) =>
  ({
    type: ActionTypes.REQUEST_CHANGE_IMPROVES_SIZE,
    isLoading: true,
    request: {
      limit: pageSize,
      offset: 0,
    },
    filter,
  }) as const

const deleteImprove = (improveUUID: string) =>
  ({
    type: ActionTypes.REQUEST_DELETE_IMPROVE,
    isLoading: true,
    improveUUID,
  }) as const

const deleteSuccessImprove = (improveUUID: string) =>
  ({
    type: ActionTypes.SUCCESS_DELETE_IMPROVE,
    isLoading: false,
    improveUUID,
  }) as const

const deleteErrorImprove = (error: Error) =>
  ({
    type: ActionTypes.ERROR_DELETE_IMPROVE,
    isLoading: false,
    error,
  }) as const

const updatePagination = (limit: number, offset: number) => {
  return {
    type: ActionTypes.UPDATE_PAGINATION,
    request: {
      limit,
      offset,
    },
  }
}
export type ImprovesAction =
  | ReturnType<typeof getImproves>
  | ReturnType<typeof getSuccessImproves>
  | ReturnType<typeof getErrorImproves>
  | ReturnType<typeof changeImprovesPage>
  | ReturnType<typeof changeImprovesSize>
  | ReturnType<typeof updatePagination>
  | ReturnType<typeof deleteImprove>
  | ReturnType<typeof deleteSuccessImprove>
  | ReturnType<typeof deleteErrorImprove>

export default {
  getImproves,
  getSuccessImproves,
  getErrorImproves,
  changeImprovesPage,
  changeImprovesSize,
  updatePagination,
  deleteImprove,
  deleteSuccessImprove,
  deleteErrorImprove,
}
