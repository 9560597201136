import { QuestionCircleOutlined } from '@ant-design/icons'
import { css } from '@emotion/react'
import { Switch, Tooltip } from 'antd'
import { logEvent } from 'firebase/analytics'
import { useSelector } from 'react-redux'
import { firebaseAnalytics } from 'src/features/firebase/firebase'
import { useShinReportPreference } from 'src/features/reports/listBeta/featurePreview/useShinReportPreference'
import { RootState } from 'src/state/store'

export const TryNewScreenToggler = () => {
  const [preferShinReport, setPreferShinReport] = useShinReportPreference()
  const user = useSelector((state: RootState) => state.usersState.user.user)

  return (
    <div css={styles.container}>
      <label css={styles.labelledButton}>
        <Switch
          checked={preferShinReport}
          onChange={checked => {
            setPreferShinReport(checked)
            logEvent(
              firebaseAnalytics,
              checked ? 'enable_shin_report_list' : 'disable_shin_report_list',
              {
                userId: user?.id,
                companyId: user?.company.id,
              },
            )
          }}
          size="small"
        />
        新しい画面を試してみる
      </label>
      <Tooltip
        placement="bottomRight"
        title={
          <>
            こちらからレポート一覧の
            <br />
            新しい画面をお試しいただけます
          </>
        }
      >
        <QuestionCircleOutlined css={styles.icon} />
      </Tooltip>
    </div>
  )
}

const styles = {
  container: css`
    align-items: center;
    display: flex;
    gap: 8px;
  `,
  labelledButton: css`
    align-items: center;
    display: flex;
    gap: 8px;
    font-size: 12px;
  `,
  icon: css`
    color: #434e5d;
    font-size: 16px;
  `,
}
