import { IndustryTypeEnum } from '@ulysses-inc/harami_api_client'
import { IndustryInfo } from 'src/features/templateEdit/onboarding/types'
import {
  DefaultIndustries,
  FoodIndustries,
  HotelIndustries,
  LogisticsIndustries,
  MachineIndustries,
  ManufactureIndustries,
  NdfIndustries,
  OtherIndustries,
} from './ShowIndustryInfo'

export const getIndustries = (type: IndustryTypeEnum): IndustryInfo[][] => {
  switch (type) {
    case IndustryTypeEnum.FOOD:
      return FoodIndustries()
    case IndustryTypeEnum.MANUFACTURE:
      return ManufactureIndustries()
    case IndustryTypeEnum.HOTEL:
      return HotelIndustries()
    case IndustryTypeEnum.LOGISTICS:
      return LogisticsIndustries()
    case IndustryTypeEnum.MACHINE:
      return MachineIndustries()
    case IndustryTypeEnum.OTHER:
      return OtherIndustries()
    case IndustryTypeEnum.NDF:
      return NdfIndustries()
    default:
      break
  }
  return DefaultIndustries
}
