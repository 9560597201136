import {
  ResponseDatetimeSubTypeEnum,
  ResponseTypeEnum,
  TemplateLayoutTypeEnum,
  TemplateNodeSchema,
  TemplateNodeTypeEnum,
  TemplatePageSchema,
} from '@ulysses-inc/harami_api_client'
import { shallowEqual, useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import { RootState } from 'src/state/store'
import { isDefined } from 'src/util/idDefined'
import { isNullish } from 'src/util/isNullish'

interface State {
  templatesState: {
    templatePages: {
      templatePages: { [key: number]: TemplatePageSchema }
      templateNodes: { [key: number]: TemplateNodeSchema }
    }
  }
}

export const useTemplateNodeNodes = (
  templateNode: TemplateNodeSchema,
): Array<TemplateNodeSchema> =>
  useSelector(
    (state: State) =>
      templateNode.nodes
        .map(
          (id: number) => state.templatesState.templatePages.templateNodes[id],
        )
        .filter(isDefined) ?? [],
    shallowEqual,
  )

export const useTemplatePageNodes = (
  templatePage: TemplatePageSchema,
): Array<TemplateNodeSchema> =>
  useSelector(
    (state: State) =>
      (templatePage.nodes ?? [])
        .map(
          (id: number) => state.templatesState.templatePages.templateNodes[id],
        )
        .filter(isDefined) ?? [],
    shallowEqual,
  )

export const selectIsGridLayout = (state: RootState) => {
  return (
    state.templatesState.editTemplate.layoutType === TemplateLayoutTypeEnum.Grid
  )
}

const selectAllNodes = (state: RootState) =>
  state.templatesState.templatePages.templateNodes

const selectParentSectionOrLogic = (nodeId: number) =>
  createSelector(selectAllNodes, allNodes => {
    const allSections = Object.values(allNodes).filter(
      node =>
        node.type === TemplateNodeTypeEnum.Section ||
        node.type === TemplateNodeTypeEnum.Logic,
    )
    return Object.values(allSections).find(node => {
      // WARN: セクション数が増えると、ループがネストしているため、ここがパフォーマンスのボトルネックになる
      return node.nodes.includes(nodeId)
    })
  })

/**
 * 日時質問
 */
export type DateTimeQuestion = {
  uuid: string
  name: string
  format: ResponseDatetimeSubTypeEnum
}

/**
 * 指定したノードと同じセクションまたは条件分岐の"直下"の子要素である、日時質問の uuid と name のリストを返すセレクター(孫以下の要素は対象外)
 * 仕様: https://www.notion.so/Web-1d0fd2bccd0e4cff8cf4317c6d45828e
 *
 * @param nodeId ノード id
 * @returns 日時質問のリストのセレクター
 */
export const selectDateTimeQuestionMap = (nodeId: number) =>
  createSelector(
    selectParentSectionOrLogic(nodeId),
    selectAllNodes,
    (parentSectionOrLogic, allNodes) => {
      // 仕様より、同じページかつ同じセクションまたは条件分岐に属する質問のみ取得する
      // 別ページ・同セクションとなることはありえないので、同セクションかどうかで絞るだけで良い
      if (isNullish(parentSectionOrLogic))
        return new Map<string, DateTimeQuestion>()

      /** 同じ親セクションに属する兄弟の質問 */
      const siblingQuestions = parentSectionOrLogic.nodes.reduce<
        TemplateNodeSchema[]
      >((nodes, nodeId) => {
        const node = allNodes[nodeId]

        if (isNullish(node)) {
          return nodes
        }

        return [...nodes, node]
      }, [])

      return siblingQuestions.reduce<Map<string, DateTimeQuestion>>(
        (prev, curr) => {
          const question = curr.question

          const response = question?.responseDatetimes?.[0]
          // 型ガード & 質問種別ガード
          if (
            isNullish(question) ||
            isNullish(response) ||
            question.responseType !== ResponseTypeEnum.DATETIME
          )
            return prev

          // 仕様より、subType(フォーマット) が DATE(日付) は対象外にする
          if (response.subType === ResponseDatetimeSubTypeEnum.DATE) return prev

          const uuid = curr.uuid
          // 型ガード
          if (isNullish(uuid) || isNullish(response.subType)) return prev

          prev.set(uuid, {
            uuid,
            name: question.name || '',
            format: response.subType,
          })
          return prev
        },
        new Map<string, DateTimeQuestion>(),
      )
    },
  )

export const selectQuestionNameByUuid = (state: RootState, uuid: string) => {
  if (uuid === '') return ''

  const node = Object.values(
    state.templatesState.templatePages.templateNodes,
  ).find(node => node.uuid === uuid)

  return node?.question?.name || ''
}

export default {
  useTemplateNodeNodes,
  useTemplatePageNodes,
}
