import {
  Approval,
  ApprovalStep,
  GetApprovalReportsRequest,
  ReportListItem,
} from '@ulysses-inc/harami_api_client'
import { combineReducers } from 'redux'
import {
  AddApprovalReportAction,
  AddApprovalStepAction,
  ApprovalAction,
  DeleteApprovalAction,
  GetApprovalReportsAction,
} from './actions'
import { ActionTypes, GetApprovalReportsFilter } from './types'

// FIXME: もともとインポートできていなかったもの
type BulkApproveAction = any
type BulkApproveRequest = any

interface ApprovalState {
  approval: Approval
  approvalUUID: string
  isLoading: boolean
  error: Error | null
}

const initialApprovalState: ApprovalState = {
  approval: {},
  approvalUUID: '',
  isLoading: false,
  error: null,
}

const approval = (
  state: ApprovalState = initialApprovalState,
  action: ApprovalAction,
): ApprovalState => {
  switch (action.type) {
    case ActionTypes.REQUEST_GET_APPROVAL: {
      return {
        ...state,
        isLoading: action.isLoading,
        approvalUUID: action.approvalUUID,
      }
    }
    case ActionTypes.SUCCESS_GET_APPROVAL: {
      return {
        ...state,
        isLoading: action.isLoading,
        approval: action.approval,
        error: null,
      }
    }
    case ActionTypes.ERROR_GET_APPROVAL: {
      return {
        ...state,
        isLoading: action.isLoading,
        error: action.error,
      }
    }
    case ActionTypes.RESET_APPROVAL: {
      return { ...state, ...initialApprovalState }
    }
    default: {
      return { ...state }
    }
  }
}

interface DeleteApprovalState {
  isLoading: boolean
  error: Error | null
}

const initialDeleteApprovalState: DeleteApprovalState = {
  isLoading: false,
  error: null,
}

const deleteApproval = (
  state: DeleteApprovalState = initialDeleteApprovalState,
  action: DeleteApprovalAction,
): DeleteApprovalState => {
  switch (action.type) {
    case ActionTypes.REQUEST_DELETE_APPROVAL: {
      return {
        ...state,
        isLoading: action.isLoading,
        error: null,
      }
    }
    case ActionTypes.SUCCESS_DELETE_APPROVAL: {
      return {
        ...state,
        isLoading: action.isLoading,
        error: null,
      }
    }
    case ActionTypes.ERROR_DELETE_APPROVAL: {
      return {
        ...state,
        isLoading: action.isLoading,
        error: action.error,
      }
    }
    default: {
      return { ...state }
    }
  }
}

interface AddApprovalStepState {
  reportUUID: string
  approvalUUID: string
  currentStep: ApprovalStep
  nextStep: ApprovalStep
  isLoading: boolean
  error: Error | null
}

const initialAddApprovalStepState: AddApprovalStepState = {
  reportUUID: '',
  approvalUUID: '',
  currentStep: {},
  nextStep: {},
  isLoading: false,
  error: null,
}
const addApprovalStep = (
  state: AddApprovalStepState = initialAddApprovalStepState,
  action: AddApprovalStepAction,
): AddApprovalStepState => {
  switch (action.type) {
    case ActionTypes.REQUEST_ADD_APPROVAL_STEP: {
      return {
        ...state,
        isLoading: action.isLoading,
        reportUUID: action.reportUUID,
        approvalUUID: action.approvalUUID,
        currentStep: action.currentStep,
        nextStep: action.nextStep,
        error: null,
      }
    }
    case ActionTypes.SUCCESS_ADD_APPROVAL_STEP: {
      return {
        ...state,
        isLoading: action.isLoading,
        error: null,
      }
    }
    case ActionTypes.ERROR_ADD_APPROVAL_STEP: {
      return {
        ...state,
        isLoading: action.isLoading,
        error: action.error,
      }
    }
    default: {
      return { ...state }
    }
  }
}
interface AddApprovalReportState {
  reportUUID: string
  approvalFlowId: number
  isLoading: boolean
  error: Error | null
}

const initialAddApprovalReportState: AddApprovalReportState = {
  reportUUID: '',
  approvalFlowId: 0,
  isLoading: false,
  error: null,
}

const addApprovalReport = (
  state: AddApprovalReportState = initialAddApprovalReportState,
  action: AddApprovalReportAction,
): AddApprovalReportState => {
  switch (action.type) {
    case ActionTypes.REQUEST_ADD_APPROVAL_REPORT: {
      return {
        ...state,
        isLoading: action.isLoading,
        reportUUID: action.reportUUID,
        approvalFlowId: action.approvalFlowId,
        error: null,
      }
    }
    case ActionTypes.SUCCESS_ADD_APPROVAL_REPORT: {
      return {
        ...state,
        isLoading: action.isLoading,
        error: null,
      }
    }
    case ActionTypes.ERROR_ADD_APPROVAL_REPORT: {
      return {
        ...state,
        isLoading: action.isLoading,
        error: action.error,
      }
    }
    default: {
      return { ...state }
    }
  }
}

interface ApprovalReportsState {
  reports: Array<ReportListItem>
  count: number
  isLoading: boolean
  error: Error | null
  request: GetApprovalReportsRequest
  filter?: GetApprovalReportsFilter
}

const initialApprovalReportsState: ApprovalReportsState = {
  reports: [],
  count: 0,
  isLoading: false,
  error: null,
  request: {
    limit: 25,
    offset: 0,
  },
  filter: {},
}

const getApprovalReports = (
  state: ApprovalReportsState = initialApprovalReportsState,
  action: GetApprovalReportsAction,
): ApprovalReportsState => {
  switch (action.type) {
    case ActionTypes.REQUEST_GET_APPROVAL_REPORTS: {
      return {
        ...state,
        reports: [],
        count: 0,
        isLoading: action.isLoading,
        error: null,
        filter: action.filter ?? {},
      }
    }
    case ActionTypes.SUCCESS_GET_APPROVAL_REPORTS: {
      return {
        ...state,
        reports: action.reports ? [...action.reports] : [],
        count: action.count ?? 0,
        isLoading: action.isLoading,
        error: null,
      }
    }
    case ActionTypes.ERROR_GET_APPROVAL_REPORTS: {
      return {
        ...state,
        reports: [],
        count: 0,
        isLoading: action.isLoading,
        error: action.error,
      }
    }
    case ActionTypes.REQUEST_CHANGE_APPROVAL_REPORTS_PAGE: {
      return {
        ...state,
        request: action.request,
        filter: action.filter ?? {},
      }
    }
    case ActionTypes.REQUEST_CHANGE_APPROVAL_REPORTS_SIZE: {
      return {
        ...state,
        request: action.request,
        filter: action.filter ?? {},
      }
    }
    case ActionTypes.UPDATE_PAGINATION: {
      return {
        ...state,
        request: action.request,
      }
    }
    default: {
      return { ...state }
    }
  }
}

interface BulkApproveState {
  isLoading: boolean
  error: Error | null
  request: BulkApproveRequest
}

const initialBulkApproveState: BulkApproveState = {
  isLoading: false,
  error: null,
  request: {
    bulkApprove: {},
    limit: 25,
    offset: 0,
  },
}

const bulkApprove = (
  state: BulkApproveState = initialBulkApproveState,
  action: BulkApproveAction,
): BulkApproveState => {
  switch (action.type) {
    case ActionTypes.REQUEST_BULK_APPROVE_REPORTS: {
      return {
        ...state,
        request: action.request,
        isLoading: action.isLoading,
        error: null,
      }
    }
    case ActionTypes.SUCCESS_BULK_APPROVE_REPORTS: {
      return {
        ...state,
        isLoading: action.isLoading,
        request: initialBulkApproveState.request,
        error: null,
      }
    }
    case ActionTypes.ERROR_BULK_APPROVE_REPORTS: {
      return {
        ...state,
        isLoading: action.isLoading,
        error: action.error,
      }
    }
    default: {
      return { ...state }
    }
  }
}

const approvalsReducer = combineReducers({
  approval,
  deleteApproval,
  addApprovalStep,
  addApprovalReport,
  getApprovalReports,
  bulkApprove,
})

export default approvalsReducer
