import {
  NumberLogicTypeEnum,
  ResponseNumber,
  ResponseNumberSubTypeEnum,
} from '@ulysses-inc/harami_api_client'

const getCalcNumberLogicType = (
  numberValue: number,
  responseNumber?: ResponseNumber,
) => {
  const minimum = responseNumber?.minimum ?? 0
  const maximum = responseNumber?.maximum ?? 0
  switch (responseNumber?.subType) {
    case ResponseNumberSubTypeEnum.BETWEEN:
      return minimum <= numberValue && numberValue <= maximum
        ? NumberLogicTypeEnum.NORMAL
        : NumberLogicTypeEnum.INVALID
    case ResponseNumberSubTypeEnum.LESS_THAN:
      return numberValue <= maximum
        ? NumberLogicTypeEnum.NORMAL
        : NumberLogicTypeEnum.INVALID
    case ResponseNumberSubTypeEnum.GREATER_THAN:
      return numberValue >= minimum
        ? NumberLogicTypeEnum.NORMAL
        : NumberLogicTypeEnum.INVALID
    default:
      return NumberLogicTypeEnum.INVALID
  }
}

export default getCalcNumberLogicType
