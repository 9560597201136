import { CloseOutlined } from '@ant-design/icons'
import { Switch, Tooltip, Typography } from 'antd'
import { FC } from 'react'
import { ScoreSwitchWrap, ToggleWrap } from './EditTemplateOption.styled'

const { Text } = Typography

const DisabledAuditSwitch: FC = () => (
  <Tooltip placement="top" title="従業員チェックが有効なため、使用できません">
    <ToggleWrap>
      <ScoreSwitchWrap>
        <Switch
          unCheckedChildren={<CloseOutlined />}
          checked={false}
          disabled={true}
        />
      </ScoreSwitchWrap>
      <div>
        <Text strong>カイゼン機能をつける</Text>
      </div>
    </ToggleWrap>
  </Tooltip>
)

export default DisabledAuditSwitch
