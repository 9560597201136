import { primitiveColors } from 'src/features/theme/primitiveColors'

export const globalNavigationTheme = {
  colorBorder: primitiveColors.gray._4,
  colorBorderSubMenu: primitiveColors.gray._3,
  background: primitiveColors.blue._11,
  backgroundHover: 'rgba(255,255,255,0.16)',
  backgroundHoverSubMenu: primitiveColors.neutralColor._4,
  textColor: primitiveColors.white,
  textColorSubMenu: primitiveColors.gray._9,
  betaBadgeBackground: primitiveColors.indigo._0,
  betaBadgeTextColor: primitiveColors.indigo._7,
}
