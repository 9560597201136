import {
  AddResponseMultipleChoicesRequest,
  DeleteResponseMultipleChoicesRequest,
  GetTemplatesResponse,
  GetTemplatesV2Request,
  MultipleChoice,
  MultipleChoiceGroup,
  TemplatesApi,
  UpdateResponseMultipleChoicesRequest,
} from '@ulysses-inc/harami_api_client'
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects'
import interceptionsActions from 'src/state/ducks/interceptions/actions'
import * as templateActions from 'src/state/ducks/templates/actions'
import * as actions from 'src/state/ducks/templates/responseMultipleChoices/actions'
import { ActionTypes } from 'src/state/ducks/templates/responseMultipleChoices/types'
import { baseClient } from 'src/state/middleware/saga/baseClient'
import {
  HTTPError,
  handleHTTPError,
} from 'src/state/middleware/saga/handleHttpError'

const addResponseMultipleChoicesRequest = (
  req: AddResponseMultipleChoicesRequest,
) => {
  return baseClient
    .getApi(TemplatesApi)
    .addResponseMultipleChoices(req)
    .catch(handleHTTPError)
}

const getResponseMultipleChoicesRequest = () => {
  return baseClient
    .getApi(TemplatesApi)
    .getResponseMultipleChoices()
    .then(responseMultipleChoiceGroups => responseMultipleChoiceGroups)
    .catch(handleHTTPError)
}

const updateResponseMultipleChoicesRequest = (
  req: UpdateResponseMultipleChoicesRequest,
) => {
  return baseClient
    .getApi(TemplatesApi)
    .updateResponseMultipleChoices(req)
    .then(responseMultipleChoices => responseMultipleChoices)
    .catch(handleHTTPError)
}

const deleteResponseMultipleChoicesRequest = (
  req: DeleteResponseMultipleChoicesRequest,
) => {
  return (
    baseClient
      .getApi(TemplatesApi)
      .deleteResponseMultipleChoices(req)
      // .then(responseMultipleChoices => responseMultipleChoices)
      .catch(handleHTTPError)
  )
}

const getTemplatesV2Request = (req: GetTemplatesV2Request) => {
  return baseClient
    .getApi(TemplatesApi)
    .getTemplatesV2(req)
    .then(templates => templates)
    .catch(handleHTTPError)
}

function* addMultipleChoices(
  action: ReturnType<typeof actions.addMultipleChoices>,
) {
  try {
    yield call(addResponseMultipleChoicesRequest, {
      multipleChoice: action.multipleChoices,
    })
    yield put(actions.addSuccessMultipleChoices())
    // 登録成功後、再度fetch
    const responseMultipleChoices: MultipleChoiceGroup[] = yield call(
      getResponseMultipleChoicesRequest,
    )
    yield put(actions.getSuccessMultipleChoices(responseMultipleChoices))
  } catch (error) {
    yield put(interceptionsActions.handleHttpError(error as HTTPError))
    yield put(actions.addErrorMultipleChoices(error as Error))
  }
}

function* getMultipleChoices() {
  try {
    const responseMultipleChoiceGroups: MultipleChoiceGroup[] = yield call(
      getResponseMultipleChoicesRequest,
    )
    yield put(actions.getSuccessMultipleChoices(responseMultipleChoiceGroups))
  } catch (error) {
    yield put(interceptionsActions.handleHttpError(error as HTTPError))
    yield put(actions.getErrorMultipleChoices(error as Error))
  }
}

function* updateMultipleChoices(
  action: ReturnType<typeof actions.updateMultipleChoices>,
) {
  try {
    const responseMultipleChoices: MultipleChoice[] = yield call(
      updateResponseMultipleChoicesRequest,
      {
        multipleChoiceId: action.multipleChoiceId,
        multipleChoice: action.multipleChoices,
      },
    )
    yield put(
      templateActions.updateTemplateNodeMultipleChoice(
        action.multipleChoiceId,
        responseMultipleChoices,
      ),
    )
    yield put(
      actions.updateSuccessMultipleChoices(
        action.multipleChoiceId,
        responseMultipleChoices,
      ),
    )
  } catch (error) {
    yield put(interceptionsActions.handleHttpError(error as HTTPError))
    yield put(actions.updateErrorMultipleChoices(error as Error))
  }
}

function* deleteMultipleChoices(
  action: ReturnType<typeof actions.deleteMultipleChoices>,
) {
  try {
    yield call(deleteResponseMultipleChoicesRequest, {
      multipleChoiceId: action.multipleChoiceId,
    })
    yield put(actions.deleteSuccessMultipleChoices(action.multipleChoiceId))
  } catch (error) {
    yield put(interceptionsActions.handleHttpError(error as HTTPError))
    yield put(actions.deleteErrorMultipleChoices(error as Error))
  }
}

function* getTemplatesByMultipleChoiceGroup(
  action: ReturnType<typeof actions.getTemplatesByMultipleChoiceGroup>,
) {
  try {
    const params: GetTemplatesV2Request = {
      templateFilter: {
        multipleChoiceGroupId: {
          $in: [action.multipleChoiceGroupId],
        },
      },
    }
    const response: GetTemplatesResponse = yield call(
      getTemplatesV2Request,
      params,
    )
    yield put(actions.getSuccessTemplatesByMultipleChoiceGroup(response))
  } catch (error) {
    yield put(interceptionsActions.handleHttpError(error as HTTPError))
    yield put(actions.getErrorTemplatesByMultipleChoiceGroup(error as Error))
  }
}

const sagas = [
  takeEvery(ActionTypes.REQUEST_ADD_MULTIPLE_CHOICES, addMultipleChoices),
  takeEvery(ActionTypes.REQUEST_GET_MULTIPLE_CHOICES, getMultipleChoices),
  takeEvery(ActionTypes.REQUEST_UPDATE_MULTIPLE_CHOICES, updateMultipleChoices),
  takeEvery(ActionTypes.REQUEST_DELETE_MULTIPLE_CHOICES, deleteMultipleChoices),
  takeLatest(
    ActionTypes.REQUEST_GET_TEMPLATES_BY_MULTIPLE_CHOICE_GROUP,
    getTemplatesByMultipleChoiceGroup,
  ),
]

export default sagas
