import {
  BaseAPI,
  ClientTypeEnum,
  Configuration,
  ConfigurationParameters,
} from '@ulysses-inc/harami_api_client'
import { getBasePath } from 'src/config'
import { getApiKey } from './getApiKey'

class BaseClient extends BaseAPI {
  getApi<T extends BaseAPI>(
    api: new (config: Configuration) => T,
    withAuth = true,
    additionalConfigurationParameters: ConfigurationParameters = {},
  ): T {
    const apiKey = getApiKey()
    const basePath = getBasePath()
    const clientType = ClientTypeEnum.WEB
    const config = new Configuration({
      ...(withAuth && { apiKey: apiKey }),
      basePath: basePath,
      credentials: 'include',
      headers: {
        ct: `${clientType}`,
        locationHref: `${location.href}`,
      },
      ...additionalConfigurationParameters,
    })
    return new api(config)
  }

  /**
   * JSON形式のオブジェクトをクエリパラメータに指定するための形式(文字列)に変換する
   */
  static convertQueryParameter(obj: object) {
    return { query: JSON.stringify(obj) }
  }
}

/**
 * @deprecated 後述の初期化済みの方を使ってください
 */
export default BaseClient

export const baseClient = new BaseClient()
