import { StyleSheet, Text, View } from '@react-pdf/renderer'
import { ResponseSet } from '@ulysses-inc/harami_api_client'
import React from 'react'
import BreakText from '../../BreakText'
import Col from '../../Col'
import Grid from '../../Grid'
import RecordedTime from './RecordedTime'

interface OwnProps {
  questionName: string
  responses: Array<ResponseSet>
  recordedAt?: Date
  isTimeDisplayed: boolean
}

const styles = StyleSheet.create({
  gridLeft: { width: '70%' },
  gridRight: { width: '30%' },
  fullWidthWrap: { width: '100%' },
  textWrap: {
    width: '100%',
    minHeight: 25,
    padding: 5,
  },
})

const ResponseSets: React.FC<OwnProps> = (props: OwnProps) => {
  const hasResponse = props.responses.length !== 0
  const names = hasResponse
    ? props.responses.map((response: ResponseSet) => response.name).join(', ')
    : '未回答'
  return (
    <Grid style={styles.fullWidthWrap}>
      <Col borderLeft borderRight style={styles.gridLeft}>
        <BreakText text={props.questionName} />
      </Col>
      <Col borderRight style={{ ...styles.gridRight }}>
        <View style={styles.textWrap}>
          <Text>{names}</Text>
        </View>
        <View style={styles.fullWidthWrap}>
          <RecordedTime
            isTimeDisplayed={props.isTimeDisplayed}
            recordedAt={props.recordedAt}
          />
        </View>
      </Col>
    </Grid>
  )
}

export default ResponseSets
