import { css } from '@emotion/react'
import { ReactNode } from 'react'
import { Header } from 'src/components/header/Header'
import { HeaderTitle } from 'src/components/header/HeaderTitle'
import { TryNewScreenToggler } from 'src/features/reports/listBeta/featurePreview/TryNewScreenToggler'

type Props = {
  children: ReactNode
}

/**
 * レポート一覧画面において、データの取得状態に関わらず必ず表示する部分を抜き出したコンポーネント
 */
export const ReportListWrapper = ({ children }: Props) => {
  return (
    <div>
      <Header rightSlot={<TryNewScreenToggler />}>
        <HeaderTitle isBeta title="レポート" />
      </Header>
      <div css={styles.contentArea}>{children}</div>
    </div>
  )
}

const styles = {
  contentArea: css`
    padding: 0;
  `,
}
