import {
  GetUsersRequest,
  User,
  UserGroup,
} from '@ulysses-inc/harami_api_client'
import { combineReducers } from 'redux'
import { UserAction, UserGroupsAction, UsersAction } from './actions'
import { ActionTypes, GetUsersFilter } from './types'

interface UsersState {
  users: User[]
  activeUserId?: string
  segmentIndex: number
  isLoading: boolean
  isShowEditUserDrawer: boolean
  updatedUsers: boolean
  error: Error | null
  count: number
  request: GetUsersRequest
  filter: GetUsersFilter
  userDeleteValidateLoading: boolean
  userDeleteValidateResult: 'none' | 'canBeDeleted' | 'canNotDeleted'
  userDeleteValidateErrorMessage: string
}

const initialUsersState: UsersState = {
  users: [],
  segmentIndex: 0,
  isLoading: false,
  isShowEditUserDrawer: false,
  updatedUsers: false,
  error: null,
  count: 0,
  request: {
    offset: 0,
    limit: 25,
  },
  filter: {},
  userDeleteValidateLoading: false,
  userDeleteValidateResult: 'none',
  userDeleteValidateErrorMessage: '',
}

const users = (
  state: UsersState = initialUsersState,
  action: UsersAction,
): UsersState => {
  switch (action.type) {
    case ActionTypes.REQUEST_GET_USERS: {
      return {
        ...state,
        isLoading: action.isLoading,
        users: [],
        error: null,
        request: action.request,
        filter: action.filter ?? {},
      }
    }
    case ActionTypes.SUCCESS_GET_USERS: {
      return {
        ...state,
        isLoading: action.isLoading,
        users: action.users ?? [],
        error: null,
        count: action.count ?? 0,
      }
    }
    case ActionTypes.ERROR_GET_USERS: {
      // 認証期限切れの場合に到達する
      return {
        ...state,
        isLoading: action.isLoading,
        error: action.error,
        count: 0,
      }
    }
    case ActionTypes.REQUEST_CHANGE_USERS_PAGE: {
      return {
        ...state,
        request: action.request,
        filter: action.filter ?? {},
      }
    }
    case ActionTypes.REQUEST_CHANGE_USERS_SIZE: {
      return {
        ...state,
        request: action.request,
        filter: action.filter ?? {},
      }
    }
    case ActionTypes.UPDATE_PAGINATION: {
      return {
        ...state,
        request: action.request,
      }
    }
    case ActionTypes.REQUEST_ADD_USER: {
      return { ...state, isLoading: action.isLoading, error: null }
    }
    case ActionTypes.SUCCESS_ADD_USER: {
      return {
        ...state,
        isLoading: action.isLoading,
        error: null,
        users: [...state.users, action.user],
      }
    }
    case ActionTypes.ERROR_ADD_USER: {
      return { ...state, isLoading: action.isLoading, error: action.error }
    }
    case ActionTypes.REQUEST_UPDATE_USER_LEGACY: {
      return { ...state, isLoading: action.isLoading, error: null }
    }
    case ActionTypes.REQUEST_UPDATE_USER: {
      return { ...state, isLoading: action.isLoading, error: null }
    }
    case ActionTypes.SUCCESS_UPDATE_USER: {
      return {
        ...state,
        isLoading: action.isLoading,
        error: null,
        users: state.users.map(user => {
          if (user.uuid === action.user.uuid) {
            return action.user
          } else {
            return user
          }
        }),
      }
    }
    case ActionTypes.ERROR_UPDATE_USER: {
      return { ...state, isLoading: action.isLoading, error: action.error }
    }
    case ActionTypes.SUCCESS_DELETE_USER: {
      return {
        ...state,
        isLoading: action.isLoading,
        error: null,
        users: state.users.filter(user => user.uuid !== action.userId),
      }
    }
    case ActionTypes.ERROR_DELETE_USER: {
      return { ...state, isLoading: action.isLoading, error: action.error }
    }
    case ActionTypes.REQUEST_VALIDATE_DELETE_USER: {
      return {
        ...state,
        userDeleteValidateLoading: true,
        userDeleteValidateResult: 'none',
        userDeleteValidateErrorMessage: '',
        error: null,
      }
    }
    case ActionTypes.SUCCESS_VALIDATE_DELETE_USER: {
      return {
        ...state,
        userDeleteValidateLoading: false,
        userDeleteValidateResult: 'canBeDeleted',
        error: null,
      }
    }
    case ActionTypes.USER_DELETE_VALIDATE_ERROR_MESSAGE: {
      return {
        ...state,
        userDeleteValidateLoading: false,
        userDeleteValidateResult: 'canNotDeleted',
        userDeleteValidateErrorMessage: action.userDeleteValidateErrorMessage,
        error: null,
      }
    }
    case ActionTypes.ERROR_VALIDATE_DELETE_USER: {
      return {
        ...state,
        userDeleteValidateLoading: false,
        error: action.error,
      }
    }
    case ActionTypes.RESET_VALIDATE_DELETE_USER: {
      return {
        ...state,
        userDeleteValidateResult: 'none',
        userDeleteValidateErrorMessage: '',
        error: null,
      }
    }
    case ActionTypes.CHANGE_IS_SHOW_EDIT_USER_DRAWER: {
      return { ...state, isShowEditUserDrawer: action.isShowEditUserDrawer }
    }
    case ActionTypes.CHANGE_UPDATED_USERS_FLAG: {
      return { ...state, updatedUsers: action.updatedUsers }
    }
    case ActionTypes.UPDATE_ACTIVE_USER_ID: {
      return { ...state, activeUserId: action.activeUserId }
    }
    case ActionTypes.UPDATE_SEGMENT_INDEX: {
      return { ...state, segmentIndex: action.segmentIndex }
    }
    default: {
      return { ...state }
    }
  }
}

interface UserGroupsState {
  userGroups: UserGroup[] | undefined
  activeUserGroupId?: number
  isLoading: boolean
  isShowEditUserGroupDrawer: boolean
  error: Error | null
  count: number | undefined
  request: GetUsersRequest
  userGroupDeleteValidateLoading: boolean
  userGroupDeleteValidateResult: 'none' | 'canDelete' | 'cannotDelete'
  userGroupDeleteValidateErrorMessage: string
}

const initialUserGroupsState: UserGroupsState = {
  userGroups: [],
  isLoading: false,
  isShowEditUserGroupDrawer: false,
  error: null,
  count: 0,
  request: {
    offset: 0,
    limit: 25,
  },
  userGroupDeleteValidateLoading: false,
  userGroupDeleteValidateResult: 'none',
  userGroupDeleteValidateErrorMessage: '',
}

const userGroups = (
  state: UserGroupsState = initialUserGroupsState,
  action: UserGroupsAction,
): UserGroupsState => {
  switch (action.type) {
    case ActionTypes.REQUEST_GET_USER_GROUPS: {
      return {
        ...state,
        isLoading: action.isLoading,
        userGroups: [],
        error: null,
        request: action.request,
      }
    }
    case ActionTypes.SUCCESS_GET_USER_GROUPS: {
      return {
        ...state,
        isLoading: action.isLoading,
        userGroups: action.userGroups,
        count: action.count,
        error: null,
      }
    }
    case ActionTypes.REQUEST_CHANGE_USER_GROUPS_PAGE: {
      return {
        ...state,
        request: action.request,
      }
    }
    case ActionTypes.REQUEST_CHANGE_USER_GROUPS_SIZE: {
      return {
        ...state,
        request: action.request,
      }
    }
    case ActionTypes.REQUEST_ADD_USER_GROUP: {
      return {
        ...state,
        isLoading: action.isLoading,
        error: null,
        request: action.request,
      }
    }
    case ActionTypes.SUCCESS_ADD_USER_GROUP: {
      return {
        ...state,
        isLoading: action.isLoading,
        error: null,
        request: initialUserGroupsState.request,
      }
    }
    case ActionTypes.ERROR_ADD_USER_GROUP: {
      return { ...state, isLoading: action.isLoading, error: action.error }
    }
    case ActionTypes.REQUEST_UPDATE_USER_GROUP: {
      return { ...state, isLoading: action.isLoading, error: null }
    }
    case ActionTypes.SUCCESS_UPDATE_USER_GROUP: {
      return {
        ...state,
        isLoading: action.isLoading,
        error: null,
        request: initialUserGroupsState.request,
      }
    }
    // 以下のコードには到達し得ない。所要のコード修正がされるまで一旦コメントアウトする。
    // case ActionTypes.ERROR_UPDATE_USER_GROUP: {
    //   return { ...state, isLoading: action.isLoading, error: action.error }
    // }
    case ActionTypes.ERROR_GET_USER_GROUPS: {
      // 認証期限切れの場合に到達する
      return {
        ...state,
        isLoading: action.isLoading,
        error: action.error,
        count: 0,
      }
    }
    case ActionTypes.CHANGE_IS_SHOW_EDIT_USER_GROUP_DRAWER: {
      return {
        ...state,
        isShowEditUserGroupDrawer: action.isShowEditUserGroupDrawer,
      }
    }
    case ActionTypes.UPDATE_ACTIVE_USER_GROUP_ID: {
      return { ...state, activeUserGroupId: action.activeUserGroupId }
    }
    case ActionTypes.SUCCESS_DELETE_USER_GROUP: {
      return {
        ...state,
        isLoading: action.isLoading,
        error: null,
        request: initialUserGroupsState.request,
      }
    }
    case ActionTypes.REQUEST_VALIDATE_DELETE_USER_GROUP: {
      return {
        ...state,
        userGroupDeleteValidateLoading: true,
        userGroupDeleteValidateResult: 'none',
        userGroupDeleteValidateErrorMessage: '',
        error: null,
      }
    }
    case ActionTypes.SUCCESS_VALIDATE_DELETE_USER_GROUP: {
      return {
        ...state,
        userGroupDeleteValidateLoading: false,
        userGroupDeleteValidateResult: 'canDelete',
        error: null,
      }
    }
    case ActionTypes.USER_GROUP_DELETE_VALIDATE_ERROR_MESSAGE: {
      return {
        ...state,
        userGroupDeleteValidateLoading: false,
        userGroupDeleteValidateResult: 'cannotDelete',
        userGroupDeleteValidateErrorMessage:
          action.userGroupDeleteValidateErrorMessage,
        error: null,
      }
    }
    case ActionTypes.ERROR_VALIDATE_DELETE_USER_GROUP: {
      return {
        ...state,
        userGroupDeleteValidateLoading: false,
        error: action.error,
      }
    }
    case ActionTypes.RESET_VALIDATE_DELETE_USER_GROUP: {
      return {
        ...state,
        userGroupDeleteValidateResult: 'none',
        userGroupDeleteValidateErrorMessage: '',
        error: null,
      }
    }
    default: {
      return { ...state }
    }
  }
}

interface UserState {
  user: User | null
  isLoading: boolean
  error: Error | null
}

const initialUserState: UserState = {
  user: null,
  isLoading: false,
  error: null,
}

const user = (
  state: UserState = initialUserState,
  action: UserAction,
): UserState => {
  switch (action.type) {
    case ActionTypes.SUCCESS_GET_USER: {
      return {
        ...state,
        isLoading: action.isLoading,
        user: action.user,
        error: null,
      }
    }
    case ActionTypes.ERROR_GET_USER: {
      return { ...state, isLoading: action.isLoading, error: null }
    }
    default: {
      return { ...state }
    }
  }
}

const usersReducer = combineReducers({ users, userGroups, user })

export default usersReducer
