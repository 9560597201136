import {
  MultipleChoice,
  MultipleChoiceGroup,
} from '@ulysses-inc/harami_api_client'
import { Drawer } from 'antd'
import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { multipleChoiceColors } from 'src/features/theme/MultipleChoiceColor'
import {
  getTemplatesByMultipleChoiceGroup,
  resetRelatedTemplates,
} from 'src/state/ducks/templates/responseMultipleChoices/actions'
import AddChoiceRow from './AddChoiceRow'
import {
  DrawerBodyStyle,
  DrawerHeaderStyle,
} from './AddMultipleChoiceDrawer.styled'
import ButtonsRow from './ButtonsRow'
import TableRow from './TableRow'

interface IMultipleChoices {
  [key: number]: MultipleChoice
}

type TMultipleChoiceStateSetter = Dispatch<SetStateAction<IMultipleChoices>>
export type TMultipleChoicesState = [
  IMultipleChoices,
  TMultipleChoiceStateSetter,
]

interface OwnProps {
  isVisible: boolean
  updateMultipleChoiceGroup?: MultipleChoiceGroup
  onClose: () => void
}

const initialMultipleChoice = (id: number): MultipleChoice => ({
  id,
  response: '',
  color: multipleChoiceColors.default,
  isInvalid: 0,
  isExcludedFromScoring: false,
  score: 0,
  isDefault: 0,
})

// 回答の選択肢を登録するために右から出てくるドロワー
const AddMultipleChoiceDrawer: FC<OwnProps> = ({
  isVisible,
  updateMultipleChoiceGroup: multipleChoiceGroupForUpdate,
  onClose,
}) => {
  const dispatch = useDispatch()

  const multipleChoicesState = useState<{
    [key: number]: MultipleChoice
  }>({})
  const [multipleChoices, setMultipleChoices] = multipleChoicesState
  const [lastKey, setLastKey] = useState(0)

  useEffect(() => {
    let tempChoices: { [key: number]: MultipleChoice } = {}
    let maxId = 0
    if (multipleChoiceGroupForUpdate) {
      multipleChoiceGroupForUpdate.responses.forEach(res => {
        const resId = res.id ?? 0
        tempChoices = { ...tempChoices, [resId]: res }
        maxId = maxId < resId ? resId : maxId
      })
      if (multipleChoiceGroupForUpdate.id) {
        dispatch(
          getTemplatesByMultipleChoiceGroup(multipleChoiceGroupForUpdate.id),
        )
      }
      setLastKey(maxId)
    } else {
      const firstKey = 1
      tempChoices = { [firstKey]: initialMultipleChoice(firstKey) }
      setMultipleChoices(tempChoices)
      setLastKey(firstKey)
      dispatch(resetRelatedTemplates())
    }
    setMultipleChoices(tempChoices)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [multipleChoiceGroupForUpdate])

  const addNewMultipleChoice = () => {
    const newKey = lastKey + 1
    setMultipleChoices({
      ...multipleChoices,
      [newKey]: initialMultipleChoice(newKey),
    })
    setLastKey(newKey)
  }

  return (
    <Drawer
      title="選択肢"
      maskClosable={false}
      placement="right"
      open={isVisible}
      width="650"
      headerStyle={DrawerHeaderStyle}
      bodyStyle={DrawerBodyStyle}
      closable={false}
    >
      <TableRow {...{ multipleChoicesState, lastKey, addNewMultipleChoice }} />
      <AddChoiceRow {...{ addNewMultipleChoice }} />
      <ButtonsRow
        {...{
          multipleChoicesState,
          multipleChoiceGroupForUpdate,
          isVisible,
          onClose,
        }}
      />
    </Drawer>
  )
}

export default AddMultipleChoiceDrawer
