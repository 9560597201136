import { DeleteOutlined, HomeOutlined } from '@ant-design/icons'
import { Dropdown } from 'antd'
import { ReportItemDropdownButton } from 'src/features/reports/listBeta/components/ReportItemDropdownButton'
import { Danger } from 'src/features/theme/KdsThemeColor'

type Props = {
  onClickAssignPlaceButton: () => void
  onClickDeleteButton: () => void
}

/**
 * テーブル各行の右端にある3点ドットを押下したときに開くドロップダウン
 */
export const ReportItemDropdown = (props: Props) => {
  const { onClickAssignPlaceButton, onClickDeleteButton } = props

  return (
    <Dropdown
      menu={{
        items: [
          {
            label: '現場の付け替え',
            key: 'assign-place',
            onClick: e => {
              e.domEvent.stopPropagation()
              onClickAssignPlaceButton()
            },
            icon: <HomeOutlined />,
          },
          {
            label: '削除',
            key: 'delete',
            icon: <DeleteOutlined />,
            onClick: e => {
              e.domEvent.stopPropagation()
              onClickDeleteButton()
            },
            style: {
              color: Danger,
            },
          },
        ],
      }}
      trigger={['click']}
    >
      <ReportItemDropdownButton />
    </Dropdown>
  )
}
