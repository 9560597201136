import {
  ResponseDatetimeSubTypeEnum,
  ResponseTimeMeasurement,
  TemplateNodeSchema,
} from '@ulysses-inc/harami_api_client'
import { Modal } from 'antd'
import { ComponentProps, FC, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateTemplateNode } from 'src/state/ducks/templates/actions'
import { selectDateTimeQuestionMap } from 'src/state/ducks/templates/selectors'
import { SelectWrapper } from './SelectWrapper'
import { ErrorText, Form } from './dumb'
import { validateMeasurementSettings } from './validateMeasurementSettings'

type OwnProps = {
  isOpen: boolean
  questionNode: TemplateNodeSchema
  response: ResponseTimeMeasurement
  onClose: () => void
}

const convertFormat = (format: ResponseDatetimeSubTypeEnum) => {
  switch (format) {
    case ResponseDatetimeSubTypeEnum.DATETIME:
      return '日時'
    case ResponseDatetimeSubTypeEnum.TIME:
      return '時刻'
    default:
      console.error(`想定外の ResponseDatetimeSubTypeEnum: ${format}`)
      return '不明'
  }
}

/**
 * 計測の設定モーダル
 *
 * WARN:
 *   このモーダルで開始時間と終了時間の設定を行っても、時間計測の subType(日時・時刻)は設定されない
 *
 *   時間計測の subType の設定は API へのリクエストパラメータを直接書き換えることで実現する
 *   @see `src/state/ducks/templates/sagas.ts` の `updateTemplate()` と、
 *        そこから呼び出す `alignResponseTimeMeasurements()` を参照
 *
 *   理由: 日時質問で、日時質問の subType を設定できるが、このモーダルではそれを感知することができない
 *        このため、モーダルでそれらの設定はせず、外側のモジュールに委ねることにした
 */
export const MeasurementSettingsModal: FC<OwnProps> = ({
  isOpen,
  questionNode,
  response,
  onClose,
}) => {
  const dateTimeQuestions = useSelector(
    selectDateTimeQuestionMap(questionNode.id),
  )
  const options: { value: string; label: string; format: string }[] = []
  for (const [k, v] of dateTimeQuestions.entries()) {
    options.push({
      value: k,
      label: v.name,
      format: convertFormat(v.format),
    })
  }

  const dispatch = useDispatch()

  const initialStartQuestionNodeUUID = response?.startQuestionNodeUUID
  const initialEndQuestionNodeUUID = response?.endQuestionNodeUUID

  const [startQuestionNodeUUID, setStartQuestionNodeUUID] = useState(
    initialStartQuestionNodeUUID,
  )
  const [endQuestionNodeUUID, setEndQuestionNodeUUID] = useState(
    initialEndQuestionNodeUUID,
  )

  const [errorMessage, setErrorMessage] = useState('')

  const onStartChange: ComponentProps<typeof SelectWrapper>['onChange'] = v => {
    if (typeof v !== 'string') return
    setStartQuestionNodeUUID(v)
  }
  const onEndChange: ComponentProps<typeof SelectWrapper>['onChange'] = v => {
    if (typeof v !== 'string') return
    setEndQuestionNodeUUID(v)
  }

  const onOk = () => {
    const validateResult = validateMeasurementSettings(
      dateTimeQuestions.get(startQuestionNodeUUID),
      dateTimeQuestions.get(endQuestionNodeUUID),
    )
    setErrorMessage(validateResult)
    // WARN: errorMessage を検証しても、再レンダリングされるまで値が更新されないので、validateResult の方を見ること
    if (validateResult !== '') return

    const newResponse: ResponseTimeMeasurement = {
      // WARN: 「ルールを追加」ドロワーで入力する値は変更してはいけない
      ...response,

      startQuestionNodeUUID,
      endQuestionNodeUUID,
    }

    dispatch(
      // 時間計測タイプの質問に計測を設定する
      updateTemplateNode(questionNode.id, {
        ...questionNode,
        question: {
          ...questionNode.question,
          responseTimeMeasurements: [newResponse],
        },
      }),
    )

    // フォームの終了処理
    onClose()
  }

  const onCancel = () => {
    // 確定しないのであれば、元の状態に戻す
    setStartQuestionNodeUUID(initialStartQuestionNodeUUID)
    setEndQuestionNodeUUID(initialEndQuestionNodeUUID)
    setErrorMessage('')
    onClose()
  }

  return (
    <Modal
      title="計測の設定"
      maskClosable={false}
      okText="登録"
      cancelText="キャンセル"
      onOk={onOk}
      onCancel={onCancel}
      open={isOpen}
      width="80%"
    >
      <Form>
        <SelectWrapper
          title="開始時間の質問"
          options={options}
          onChange={onStartChange}
          defaultUuid={startQuestionNodeUUID}
        />
        <SelectWrapper
          title="終了時間の質問"
          options={options}
          onChange={onEndChange}
          defaultUuid={endQuestionNodeUUID}
        />
      </Form>
      {!!errorMessage && <ErrorText>{errorMessage}</ErrorText>}
    </Modal>
  )
}
