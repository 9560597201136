import { useSelector } from 'react-redux'
import { ROW_COUNT } from 'src/features/gridVariables/edit/constants'
import { RootState } from 'src/state/store'

// TODO このファイル内で戻り値（useSelectorした結果を返すか否か）を統一する
export const selectTemplateName = (state: RootState) => {
  return state.editGridVariablesState.editGridVariables.template?.name ?? ''
}

export const selectTemplateId = (state: RootState) => {
  return state.editGridVariablesState.editGridVariables.template?.id ?? 0
}

export const useIsSelected = (rowNumber: number, colNumber: number) =>
  useSelector(
    (state: RootState) =>
      state.editGridVariablesState.editGridVariables.selectedCells[rowNumber]?.[
        colNumber
      ] ?? false,
  )

/**
 * 取り込み項目詳細テーブルに変更箇所が１つ以上存在するか
 *
 * @param state
 * @returns
 */
export const selectHasChangedInTable = (state: RootState) => {
  const originalTable =
    state.editGridVariablesState.editGridVariables.originalTable
  const inputTable = state.editGridVariablesState.editGridVariables.inputTable

  return Array.from({ length: ROW_COUNT }).some((_, index) => {
    const rowNumber = index + 1
    const originalRow = originalTable[rowNumber]
    const inputRow = inputTable[rowNumber]

    if (!originalRow) {
      return !!inputRow
    }

    if (!inputRow) {
      return true
    }

    return inputRow.hasChanged
  })
}

export const useIsInDrag = () => {
  return useSelector(
    (state: RootState) =>
      state.editGridVariablesState.editGridVariables.mode == 'drag',
  )
}

/**
 * edit モード、かつ、指定した座標にあるセルが選択中であれば true を返す
 *
 * @param rowNumber
 * @param colNumber
 * @returns
 */
export const useIsInEdit = (rowNumber: number, colNumber: number) => {
  return useSelector((state: RootState) => {
    const isEdit =
      state.editGridVariablesState.editGridVariables.mode === 'edit'

    const isSelected =
      state.editGridVariablesState.editGridVariables.selectedCells[rowNumber]?.[
        colNumber
      ] ?? false

    return isEdit && isSelected
  })
}

/**
 * エラーになっているの行数を返すセレクタ
 *
 * @param state
 * @returns
 */
export const selectErrorRowNumbers = (state: RootState) => {
  const { inputTable } = state.editGridVariablesState.editGridVariables

  const errorRowNumbers: number[] = []
  Object.keys(inputTable).forEach(k => {
    const rowNumber = Number(k)
    const row = inputTable[rowNumber]
    if (row === undefined) return

    if (row.hasErrors) {
      errorRowNumbers.push(rowNumber)
    }
  })

  return errorRowNumbers
}
