import { Button, Layout, Pagination, Row, Tabs } from 'antd'
import { TabBackgroudColor, White } from 'src/features/theme/KdsThemeColor'
import styled from 'styled-components'

const { Content } = Layout

export const EmployeeTabsWrap = styled.div`
  margin-top: 28px;
`

export const EmployeeTabs = styled(Tabs)`
  width: 100%;
  .ant-tabs-nav {
    margin-bottom: 0px !important;
  }
  .ant-tabs-nav-wrap {
    margin-top: 10px;
    margin-bottom: 0px;
    margin-left: 20px;
    .ant-tabs-nav-list {
      & > .ant-tabs-tab {
        background: ${TabBackgroudColor};
      }
      & > .ant-tabs-tab-active {
        background: ${White};
      }
    }
  }
  .ant-tabs-extra-content {
    margin-right: 20px;
  }
`

export const ContentWrapper = styled(Content)`
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  width: 100%;
`

export const ListHeader = styled(Row)`
  padding-left: 16px;
`

export const ListContainer = styled.div`
  width: 100%;
`

export const AddEmployeeButton = styled(Button)`
  margin-right: 15px;
  margin-top: 15px;
`

export const EmployeesPagination = styled(Pagination)`
  padding: 16px 0 !important;
  display: flex;
  justify-content: center;
`
