import {
  FileExcelOutlined,
  PauseCircleOutlined,
  PlayCircleOutlined,
  PlusCircleOutlined,
} from '@ant-design/icons'
import {
  ApprovalFlow,
  AttachmentFile,
  ExcelConversionFlow,
  ExcelConversionScheduleFrequencyEnum,
  ExcelConversionScheduleStartupTypeEnum,
  ExcelConversionStep,
  ExcelConversionStepTypeEnum,
  ScheduleForExcelConversionFlow,
  UserGroup,
} from '@ulysses-inc/harami_api_client'
import {
  Button,
  Card,
  Col,
  Layout,
  Radio,
  Row,
  Select,
  Switch,
  Typography,
} from 'antd'
import { RadioChangeEvent } from 'antd/lib/radio'
import { FormikHelpers, FormikProps, useFormik } from 'formik'
import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import Redux from 'redux'
import ExcelSvg from 'src/assets/icons/excel.svg?react'
import { MomentDatePicker } from 'src/components/datepicker/MomentDatePicker'
import FormikErrorMessage from 'src/components/formikErrorMessage/FormikErrorMessage'
import { Header } from 'src/components/header/Header'
import { HeaderBackButton } from 'src/components/header/HeaderBackButton'
import { HeaderTitle } from 'src/components/header/HeaderTitle'
import LoadingOverlay from 'src/components/loading/LoadingOverlay'
import { UUID } from 'src/exShared/util/uuid'
import DataUnavailableWidget from 'src/features/dataUnavailableWidget/DataUnavailableWidget'
import { useDataUnavailableWidget } from 'src/features/dataUnavailableWidget/useDataUnavailableWidget'
import ExcelConversionStepSetting from 'src/features/excelConversionFlows/ExcelConversionStepSetting'
import FrequencySelectOption from 'src/features/excelConversionFlows/FrequencySelectOption'
import Yup from 'src/features/validation/yup'
import approvalFlowsOperations from 'src/state/ducks/approvalFlows/operations'
import excelConversionFlowsOperation from 'src/state/ducks/excelConversionFlows/operations'
import usersOperations from 'src/state/ducks/users/operations'
import { RootState } from 'src/state/store'
import styled from 'styled-components'
import { WorkBook, read as xlsxRead } from 'xlsx'

// TODO: もともと型が機能していなかったことがリファクタ時に判明した。修正にコストがかかるため、一旦anyで設定した。要修正。
// type ExcelConversionFlowForm = ExcelConversionFlow & ExcelConversionSchedule & { isTest: boolean }
type ExcelConversionFlowForm = any

interface StateProps {
  flowId: string | undefined
  attachmentFile: AttachmentFile | null
  excelConversionFlow: ExcelConversionFlow | null
  schedules: ScheduleForExcelConversionFlow[]
  approvalFlows: ApprovalFlow[]
  userGroups: UserGroup[]
  isLoading: boolean
}

interface DispatchProps {
  uploadAttachmentFile: (file: File) => void
  getFlow: (flowId: string) => void
  getSchedules: () => void
  getApprovalFlows: () => void
  getUserGroups: () => void
  goExcelConversionFlows: () => void
  resetExcelConversionFlow: () => void
  dispatch: Redux.Dispatch
}

interface MergeProps {
  validate: (
    flow: ExcelConversionFlowForm,
    formikHelpers: FormikHelpers<ExcelConversionFlowForm>,
  ) => boolean
}

type EditExcelConversionFlowProps = StateProps & DispatchProps & MergeProps

const useStateProps = (): StateProps => {
  const { flowId } = useParams<{ flowId?: string }>()
  return {
    flowId: flowId,
    excelConversionFlow: useSelector(
      (state: RootState) =>
        state.excelConversionFlowsState.excelConversionFlow.excelConversionFlow,
    ),
    attachmentFile: useSelector(
      (state: RootState) =>
        state.excelConversionFlowsState.excelConversionFlow.attachmentFile,
    ),
    schedules: useSelector(
      (state: RootState) =>
        state.excelConversionFlowsState.excelConversionFlow.schedules,
    ),
    approvalFlows: useSelector(
      (state: RootState) =>
        state.approvalFlowsState.approvalFlows.approvalFlows,
    ),
    userGroups: useSelector(
      (state: RootState) => state.usersState.userGroups.userGroups,
    ),
    isLoading: useSelector(
      (state: RootState) =>
        state.excelConversionFlowsState.excelConversionFlow.isLoading ||
        state.excelConversionFlowsState.excelConversionFlow.isScheduleLoading ||
        state.approvalFlowsState.approvalFlows.isLoading ||
        state.usersState.userGroups.isLoading,
    ),
  }
}

const useDispatchProps = (): DispatchProps => {
  const dispatch = useDispatch()
  const history = useHistory()
  return {
    getFlow: (flowId: string) => {
      excelConversionFlowsOperation.getExcelConversionFlow(dispatch, flowId)
    },
    getSchedules: () => {
      excelConversionFlowsOperation.getSchedules(dispatch)
    },
    getApprovalFlows: () => {
      approvalFlowsOperations.getApprovalFlows(dispatch)
    },
    getUserGroups: () => {
      usersOperations.getUserGroups(dispatch, {})
    },
    uploadAttachmentFile: (file: File) => {
      excelConversionFlowsOperation.uploadAttachmentFile(dispatch, file)
    },
    goExcelConversionFlows: () => {
      history.push('/excelConversionFlows')
    },
    resetExcelConversionFlow: () => {
      excelConversionFlowsOperation.resetExcelConversionFlow(dispatch)
    },
    dispatch,
  }
}

const validationSchema = Yup.object({
  name: Yup.string().required().label('変換フロー名'),
  inputFiles: Yup.array()
    .min(1, 'Excelファイルは必須です')
    .label('Excelファイル'),
  steps: Yup.array().min(1, '変換設定は1つ以上設定してください'),
  startupType: Yup.number(),
  makeTime: Yup.date().label('起動時刻').when('startupType', {
    is: ExcelConversionScheduleStartupTypeEnum.Schedule,
    then: Yup.date().required(),
  }),
  frequency: Yup.number().label('頻度').when('startupType', {
    is: ExcelConversionScheduleStartupTypeEnum.Schedule,
    then: Yup.number().required(),
  }),
  frequencyWeekly: Yup.object().when('frequency', {
    is: ExcelConversionScheduleFrequencyEnum.Weekly,
    then: Yup.object({
      dayOfWeek: Yup.number()
        .required()
        .min(1, '曜日は必須項目です')
        .label('曜日'),
    }),
  }),
  frequencyMonthly: Yup.object().when('frequency', {
    is: ExcelConversionScheduleFrequencyEnum.Monthly,
    then: Yup.object({
      dayOfMonth: Yup.number().required().min(1).label('日にち'),
    }),
  }),
})

const newValues = {
  isTest: false,
  name: '',
  steps: [],
  startupType: ExcelConversionScheduleStartupTypeEnum.Schedule,
  makeTime: undefined,
  frequency: undefined,
  frequencyWeekly: {
    every: undefined,
    dayOfWeek: undefined,
  },
  frequencyMonthly: {
    every: undefined,
    dayOfMonth: undefined,
  },
  isActive: true,
  approvalFlows: [],
  userGroups: [],
  inputFiles: [],
}

const EditExcelConversionFlowContainer: React.FC = () => {
  const stateProps = useStateProps()
  const dispatchProps = useDispatchProps()
  const isUpdate = stateProps.flowId !== undefined && stateProps.flowId !== ''
  const initialValues = isUpdate
    ? {
        isTest: false,
        name: stateProps.excelConversionFlow?.name ?? '',
        steps: stateProps.excelConversionFlow?.steps ?? [],
        startupType:
          stateProps.excelConversionFlow?.schedules?.[0]?.startupType,
        makeTime: stateProps.excelConversionFlow?.schedules?.[0]?.makeTime,
        frequency:
          stateProps.excelConversionFlow?.schedules?.[0]?.frequency ??
          undefined,
        frequencyWeekly:
          stateProps.excelConversionFlow?.schedules?.[0]?.frequencyWeekly ??
          undefined,
        frequencyMonthly:
          stateProps.excelConversionFlow?.schedules?.[0]?.frequencyMonthly ??
          undefined,
        isActive: stateProps.excelConversionFlow?.schedules?.[0]?.isActive,
        approvalFlows: stateProps.excelConversionFlow?.approvalFlows ?? [],
        userGroups: stateProps.excelConversionFlow?.userGroups ?? [],
        inputFiles: stateProps.excelConversionFlow?.inputFiles ?? [],
      }
    : newValues
  const getExcelConversionFlow = (
    values: ExcelConversionFlowForm,
  ): ExcelConversionFlow => {
    return {
      ...values,
      id: undefined,
      uuid: isUpdate ? stateProps.flowId : UUID(),
      inputFiles: stateProps.attachmentFile
        ? [{ uuid: stateProps.attachmentFile.uuid }]
        : stateProps.excelConversionFlow?.inputFiles,
      schedules: [
        {
          isActive: values.isActive ? 1 : 0,
          startupType: values.startupType,
          makeTime: values.makeTime || undefined,
          frequency: values.frequency || undefined,
          frequencyWeekly: values.frequencyWeekly || undefined,
          frequencyMonthly: values.frequencyMonthly || undefined,
        },
      ],
    }
  }
  const formikProps = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (
      values: ExcelConversionFlowForm,
      formikHelpers: FormikHelpers<ExcelConversionFlowForm>,
    ) => {
      const flow: ExcelConversionFlow = getExcelConversionFlow(values)

      if (validate(flow, formikHelpers)) {
        return
      }

      if (values.isTest) {
        excelConversionFlowsOperation.testExcelConversionFlow(
          dispatchProps.dispatch,
          flow,
        )
        return
      } else if (isUpdate) {
        excelConversionFlowsOperation.updateExcelConversionFlow(
          dispatchProps.dispatch,
          stateProps.flowId ?? '',
          flow,
        )
        formikHelpers.resetForm()
      } else {
        excelConversionFlowsOperation.addExcelConversionFlow(
          dispatchProps.dispatch,
          flow,
        )
        formikHelpers.resetForm()
      }
    },
    enableReinitialize: true,
  })
  const mergeProps: MergeProps = {
    validate: (
      values: ExcelConversionFlowForm,
      formikHelpers: FormikHelpers<ExcelConversionFlowForm>,
    ): boolean => {
      const flow: ExcelConversionFlow = getExcelConversionFlow(values)
      return validate(flow, formikHelpers)
    },
  }
  const props = {
    ...stateProps,
    ...dispatchProps,
    ...mergeProps,
    ...formikProps,
  }
  return <EditExcelConversionFlowScene {...props} />
}

const validate = (
  flow: ExcelConversionFlow,
  formikHelpers: FormikHelpers<ExcelConversionFlowForm>,
) => {
  let hasError = false

  if (!flow.inputFiles || flow.inputFiles.length === 0) {
    hasError = true
    formikHelpers.setFieldError('inputFiles', 'Excelファイルは必須項目です')
    formikHelpers.setFieldTouched('inputFiles', true, false)
  }

  const scheduleUUIDs: { [key: string]: boolean } = {}
  let hasPeriodStep = false
  if (!flow.steps || flow.steps.length === 0) {
    hasError = true
    formikHelpers.setFieldError('steps', '変換設定を1つ以上設定してください')
    formikHelpers.setFieldTouched('steps', true, false)
  } else {
    for (const step of flow.steps) {
      if (step.type === ExcelConversionStepTypeEnum.Period) {
        hasPeriodStep = true
      }
      if (step.scheduleUuid) {
        scheduleUUIDs[step.scheduleUuid] = true
      }
      if (!step.type || hasEmptyValue(step)) {
        hasError = true
        formikHelpers.setFieldError('steps', '変換設定に未入力があります')
        formikHelpers.setFieldTouched('steps', true, false)
        break
      } else if (hasInvalidCellName(step)) {
        hasError = true
        formikHelpers.setFieldError(
          'steps',
          '開始セルに不正な値が入力されています',
        )
        formikHelpers.setFieldTouched('steps', true, false)
        break
      }
    }
  }

  if (flow.schedules && flow.schedules.length > 0) {
    const schedule = flow.schedules[0]
    if (
      schedule.startupType ===
      ExcelConversionScheduleStartupTypeEnum.OnCompletedReport
    ) {
      if (Object.keys(scheduleUUIDs).length > 1) {
        hasError = true
        formikHelpers.setFieldError(
          'steps',
          '異なるスケジュールが登録されています。「レポート完了時に作成」の場合は１種類のスケジュールのみ設定できます。',
        )
        formikHelpers.setFieldTouched('steps', true, false)
      }
      if (hasPeriodStep) {
        hasError = true
        formikHelpers.setFieldError(
          'steps',
          '「レポート完了時に作成」の場合は期間出力は設定できません。',
        )
        formikHelpers.setFieldTouched('steps', true, false)
      }
    }
  }

  return hasError
}

const hasEmptyValue = (step: ExcelConversionStep) => {
  switch (step.type) {
    case ExcelConversionStepTypeEnum.Single:
      return (
        !step.singleStep?.excelSheetName ||
        !step.singleStep?.excelStartCell ||
        !step.singleStep.templateNodeUUID
      )

    case ExcelConversionStepTypeEnum.Multiple:
      return (
        !step.multipleStep?.excelSheetName ||
        !step.multipleStep?.excelStartCell ||
        !step.multipleStep?.direction
      )
    case ExcelConversionStepTypeEnum.Repeat:
      return (
        !step.repeatStep?.excelSheetName ||
        !step.repeatStep?.excelStartCell ||
        !step.repeatStep?.direction
      )
    case ExcelConversionStepTypeEnum.Period:
      return (
        !step.periodStep?.excelSheetName ||
        !step.periodStep?.excelStartCell ||
        !step.periodStep?.direction
      )
  }

  return false
}

// Excelセル名の正規表現
const cellNameReg = new RegExp('^[A-Z]+[1-9]{1}[0-9]*$')

const hasInvalidCellName = (step: ExcelConversionStep) => {
  switch (step.type) {
    case ExcelConversionStepTypeEnum.Single:
      return !cellNameReg.test(step.singleStep?.excelStartCell ?? '')
    case ExcelConversionStepTypeEnum.Multiple:
      return !cellNameReg.test(step.multipleStep?.excelStartCell ?? '')
    case ExcelConversionStepTypeEnum.Repeat:
      return !cellNameReg.test(step.repeatStep?.excelStartCell ?? '')
    case ExcelConversionStepTypeEnum.Period:
      return !cellNameReg.test(step.periodStep?.excelStartCell ?? '')
  }
}

const { Content } = Layout
const { Title, Text } = Typography
const { Option } = Select

const ExcelIcon = styled(ExcelSvg)`
  width: 50px;
  margin-right: 10px;
`

const ExcelFileUploadButton = styled(Button)`
  margin-right: 10px;
`

const ExcelFileInfoContainer = styled.div`
  display: flex;
  align-items: center;
`

const EditFlowContentRow = styled(Row)`
  height: 100%;
`

const ExcelFileErrorMessage = styled.div`
  margin-top: 16px;
`

const FormRow = styled(Row)`
  margin-top: 20px;
  margin-bottom: 20px;
`

const FormLabel = styled.div`
  margin-bottom: 5px;
  width: 100%;
`

const PlayCircleOutlinedIcon = styled(PlayCircleOutlined)`
  margin-right: 5px;
`

const PauseCircleOutlinedIcon = styled(PauseCircleOutlined)`
  margin-right: 5px;
`

const ModifyButton = styled(Button)`
  width: 100%;
`

const timeFormat = 'HH:mm'

const readExcelFromUrl = (
  fileName: string,
  url: string,
  setExcel: (param: { fileName: string; book: WorkBook }) => void,
) => {
  const xhr = new XMLHttpRequest()
  xhr.open('GET', url, true)
  xhr.responseType = 'arraybuffer'
  xhr.onload = function (e) {
    const arrayBuffer = this.response
    const data = btoa(fixdata(arrayBuffer))
    const book = e.target && xlsxRead(data, { type: 'base64' })
    book && setExcel({ fileName: fileName, book: book })
  }
  xhr.send()
}

const fixdata = (data: ArrayBuffer) => {
  let o = ''
  let l = 0
  const w = 10240
  for (; l < data.byteLength / w; ++l) {
    o += String.fromCharCode.apply(
      null,
      Array.from(new Uint8Array(data.slice(l * w, l * w + w))),
    )
  }
  o += String.fromCharCode.apply(
    null,
    Array.from(new Uint8Array(data.slice(o.length))),
  )
  return o
}

const EditExcelConversionFlowScene: React.FC<
  EditExcelConversionFlowProps & FormikProps<ExcelConversionFlowForm>
> = (
  props: EditExcelConversionFlowProps & FormikProps<ExcelConversionFlowForm>,
) => {
  // 変換設定でシート名の選択肢を表示するためにExcelファイルの情報を保持しておく
  const [excel, setExcel] = useState<
    { fileName: string; book: WorkBook } | undefined
  >(undefined)
  const MAX_EXCEL_SIZE = 4194304
  const [tooltip, setTooltip] = useState<boolean>(false)

  const dataUnavailableWidget = useDataUnavailableWidget()

  // Excel変換設定カードが1枚しか無い時初期値が0だとボタンが二重に表示されてしまうため、
  // 行儀が悪いが-1としておく
  const [activeClickCard, setActiveClickCard] = useState<number>(-1)

  const handleactiveClickCard = (index: number) => {
    if (values.steps.length === index + 1) {
      setActiveClickCard(-1)
    } else {
      setActiveClickCard(index)
    }
  }

  useEffect(() => {
    if (props.flowId) {
      props.getFlow(props.flowId)
    }
    props.getSchedules()
    props.getUserGroups()
    return () => {
      props.resetExcelConversionFlow()
      dataUnavailableWidget.reset()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (
      props.excelConversionFlow &&
      props.excelConversionFlow.inputFiles &&
      props.excelConversionFlow.inputFiles.length > 0
    ) {
      const inputFile = props.excelConversionFlow.inputFiles[0]
      inputFile.name &&
        inputFile.url &&
        readExcelFromUrl(inputFile.name, inputFile.url, setExcel)
    }
  }, [props.excelConversionFlow])

  const {
    errors,
    values,
    setFieldValue,
    handleSubmit,
    touched,
    submitCount,
    setFieldError,
    setFieldTouched,
  } = props

  useEffect(() => {
    if (values.steps.length === 2) {
      setTooltip(true)
      setActiveClickCard(-1)
    } else {
      setTooltip(false)
    }
  }, [values.steps.length])

  const inputEl = useRef<HTMLInputElement>(null)
  const [activeScheduleUUID, setActiveScheduleUUID] = useState<string>()

  const hasValueDifferent = (
    values: ExcelConversionFlowForm,
    excelSheetName?: string[],
  ) => {
    if (excelSheetName) {
      const sheetNameCheck: boolean[] = []

      for (const flow of values.steps) {
        switch (flow.type) {
          case ExcelConversionStepTypeEnum.Single:
            sheetNameCheck.push(
              excelSheetName.includes(flow.singleStep.excelSheetName),
            )
            break
          case ExcelConversionStepTypeEnum.Multiple:
            sheetNameCheck.push(
              excelSheetName.includes(flow.multipleStep.excelSheetName),
            )
            break
          case ExcelConversionStepTypeEnum.Repeat:
            sheetNameCheck.push(
              excelSheetName.includes(flow.repeatStep.excelSheetName),
            )
            break
          case ExcelConversionStepTypeEnum.Period:
            sheetNameCheck.push(
              excelSheetName.includes(flow.periodStep.excelSheetName),
            )
            break
        }
      }
      if (sheetNameCheck.includes(false) || !sheetNameCheck.length) {
        return false
      } else {
        return true
      }
    } else {
      return false
    }
  }

  const addExcelConversionSettings = (index: number) => {
    const newSteps = values.steps
    // 変換設定追加時にボタンを表示させるため、
    // また、最後のカード下にはボタンを追加しないため
    if (values.steps.length === index + 1) {
      setActiveClickCard(-1)
    } else {
      setActiveClickCard(index + 1)
    }
    if (newSteps.length > 0) {
      // 直前のステップの設定を引き継ぐ
      const prevStep = newSteps[index]
      let newStep: ExcelConversionStep = {
        type: prevStep.type,
        serialNumber: prevStep.serialNumber,
        scheduleUuid: prevStep.scheduleUuid,
      }
      switch (prevStep.type) {
        case ExcelConversionStepTypeEnum.Single:
          newStep = {
            ...newStep,
            singleStep: {
              excelSheetName: prevStep.singleStep?.excelSheetName,
            },
          }
          break
        case ExcelConversionStepTypeEnum.Multiple:
          newStep = {
            ...newStep,
            multipleStep: {
              excelSheetName: prevStep.multipleStep?.excelSheetName,
            },
          }
          break
        case ExcelConversionStepTypeEnum.Repeat:
          newStep = {
            ...newStep,
            repeatStep: {
              excelSheetName: prevStep.repeatStep?.excelSheetName,
            },
          }
          break
        case ExcelConversionStepTypeEnum.Period:
          newStep = {
            ...newStep,
            periodStep: {
              excelSheetName: prevStep.periodStep?.excelSheetName,
            },
          }
          break
      }
      newSteps.splice(index + 1, 0, newStep)
    } else {
      newSteps.splice(index + 1, 0, {})
    }
    setFieldValue('steps', [...newSteps])
  }

  return (
    <>
      <LoadingOverlay
        tip={'Loading'}
        spinning={props.isLoading}
        size="large"
        render={
          <Layout>
            <Header
              rightSlot={[
                <Button
                  key={Math.random()}
                  type="primary"
                  ghost
                  onClick={() => props.goExcelConversionFlows()}
                >
                  キャンセル
                </Button>,
                <Button
                  key={Math.random()}
                  type="primary"
                  onClick={() => {
                    setFieldValue('isTest', true, false)
                    if (props.validate(values, props)) {
                      props.handleSubmit()
                      return
                    }
                    if (!hasValueDifferent(values, excel?.book.SheetNames)) {
                      setFieldError('steps', 'シート名に相違があります')
                      setFieldTouched('steps', true, false)
                      return
                    }
                    handleSubmit()
                  }}
                >
                  テスト出力
                </Button>,
                <Button
                  key={Math.random()}
                  type="primary"
                  onClick={() => {
                    setFieldValue('isTest', false, false)
                    if (props.validate(values, props)) {
                      props.handleSubmit()
                      return
                    }
                    if (!hasValueDifferent(values, excel?.book.SheetNames)) {
                      setFieldError('steps', 'シート名に相違があります')
                      setFieldTouched('steps', true, false)
                      return
                    }
                    handleSubmit()
                  }}
                >
                  {props.flowId ? '更新' : '保存'}
                </Button>,
              ]}
              sticky
            >
              <HeaderBackButton
                onClick={() => props.goExcelConversionFlows()}
              />
              <HeaderTitle title="変換フローの編集" />
            </Header>
            <Content>
              {dataUnavailableWidget.isVisible ? (
                <DataUnavailableWidget />
              ) : (
                <EditFlowContentRow>
                  <Col span={20} offset={2}>
                    <FormRow>
                      <FormLabel>
                        <Text strong>1. 変換フロー名</Text>
                      </FormLabel>
                      <div style={{ width: '100%' }}>
                        <Title
                          level={4}
                          editable={{
                            onChange: (text: string) => {
                              setFieldValue('name', text)
                            },
                          }}
                        >
                          {values.name}
                        </Title>
                      </div>

                      <div>
                        <FormikErrorMessage
                          name="name"
                          errors={errors}
                          touched={touched}
                        />
                      </div>
                    </FormRow>
                    <FormRow>
                      <FormLabel>
                        <Text strong>2. 変換対象ファイル</Text>
                      </FormLabel>
                      <Card style={{ width: '100%' }}>
                        <Row gutter={16}>
                          <ExcelFileInfoContainer>
                            <ExcelIcon />
                            <ExcelFileUploadButton
                              onClick={() => {
                                if (inputEl && inputEl.current) {
                                  inputEl.current.click()
                                }
                              }}
                            >
                              <FileExcelOutlined />
                              Excelファイルを選択
                            </ExcelFileUploadButton>
                            <input
                              style={{ display: 'none' }}
                              type="file"
                              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                              ref={inputEl}
                              onChange={e => {
                                const file =
                                  e.target.files && e.target.files.item(0)
                                if (file) {
                                  props.uploadAttachmentFile(file)
                                  const fr = new FileReader()
                                  fr.addEventListener(
                                    'load',
                                    (e: ProgressEvent<FileReader>) => {
                                      if (!e.target || !e.target.result) {
                                        return
                                      }
                                      if (file.size > MAX_EXCEL_SIZE) {
                                        setFieldError(
                                          'inputFiles',
                                          'Excelファイルの最大容量は4Mです',
                                        )
                                        setFieldTouched(
                                          'inputFiles',
                                          true,
                                          false,
                                        )
                                        return
                                      }
                                      const data = btoa(
                                        fixdata(e.target.result as ArrayBuffer),
                                      )
                                      const book =
                                        e.target &&
                                        xlsxRead(data, { type: 'base64' })
                                      book &&
                                        setExcel({
                                          fileName: file.name,
                                          book: book,
                                        })
                                      setFieldValue('inputFiles', [{}])
                                    },
                                    false,
                                  )
                                  fr.readAsArrayBuffer(file)
                                }
                              }}
                            />
                            <Text>{excel?.fileName || ''}</Text>
                          </ExcelFileInfoContainer>
                          <ExcelFileErrorMessage>
                            <FormikErrorMessage
                              name="inputFiles"
                              errors={errors}
                              touched={touched}
                            />
                          </ExcelFileErrorMessage>
                        </Row>
                      </Card>
                    </FormRow>
                    <FormRow>
                      <FormLabel>
                        <Text strong>3. 自動生成設定</Text>
                      </FormLabel>
                      <Card style={{ width: '100%' }}>
                        <Row>
                          <Col span={4}>
                            <FormLabel>
                              <Text strong>起動設定</Text>
                            </FormLabel>
                            <div>
                              <Radio.Group
                                value={values.startupType}
                                onChange={(e: RadioChangeEvent) => {
                                  setFieldValue('startupType', e.target.value)
                                  setActiveScheduleUUID(undefined)
                                }}
                              >
                                <Radio
                                  value={
                                    ExcelConversionScheduleStartupTypeEnum.Schedule
                                  }
                                >
                                  作成時間による制御
                                </Radio>
                                <Radio
                                  value={
                                    ExcelConversionScheduleStartupTypeEnum.OnCompletedReport
                                  }
                                >
                                  レポート完了時に作成
                                </Radio>
                              </Radio.Group>
                            </div>
                          </Col>
                          <Col span={4}>
                            <FormLabel>
                              <Text strong>起動可否</Text>
                            </FormLabel>
                            <div>
                              <Switch
                                checkedChildren={
                                  <>
                                    <PlayCircleOutlinedIcon /> アクティブ
                                  </>
                                }
                                unCheckedChildren={
                                  <>
                                    <PauseCircleOutlinedIcon /> 停止中
                                  </>
                                }
                                checked={values.isActive ? true : false}
                                onClick={() => {
                                  setFieldValue('isActive', !values.isActive)
                                }}
                              />
                            </div>
                          </Col>
                          {values.startupType ===
                            ExcelConversionScheduleStartupTypeEnum.Schedule && (
                            <Col span={4}>
                              <FormLabel>
                                <Text strong>作成時間</Text>
                              </FormLabel>
                              <div>
                                <MomentDatePicker.TimePicker
                                  format={timeFormat}
                                  value={
                                    values.makeTime !== undefined
                                      ? moment(values.makeTime)
                                      : undefined
                                  }
                                  onChange={(date: moment.Moment | null) => {
                                    setFieldValue('makeTime', date?.toDate())
                                  }}
                                  placeholder="作成時間"
                                />
                                <div>
                                  {submitCount > 0 && (
                                    <FormikErrorMessage
                                      name="makeTime"
                                      errors={errors}
                                    />
                                  )}
                                </div>
                              </div>
                            </Col>
                          )}
                          {values.startupType ===
                            ExcelConversionScheduleStartupTypeEnum.Schedule && (
                            <Col span={10}>
                              <FormLabel>
                                <Text strong>頻度</Text>
                              </FormLabel>
                              <FrequencySelectOption
                                errors={errors}
                                touched={touched}
                                submitCount={submitCount}
                                frequency={values.frequency ?? null}
                                frequencyWeekly={values.frequencyWeekly}
                                frequencyMonthly={values.frequencyMonthly}
                                setFieldValue={setFieldValue}
                              />
                            </Col>
                          )}
                        </Row>
                      </Card>
                    </FormRow>
                    <FormRow>
                      <FormLabel>
                        <Text strong>4. 変換設定</Text>
                      </FormLabel>
                      <Card style={{ width: '100%' }}>
                        {values.steps &&
                          values.steps.map(
                            (step: ExcelConversionStep, index: number) => (
                              <ExcelConversionStepSetting
                                key={`ExcelConversionStepSetting${index}`}
                                schedules={props.schedules}
                                sheetNames={excel?.book?.SheetNames ?? []}
                                step={step}
                                isLoading={props.isLoading}
                                updateStep={(
                                  updatedStep: ExcelConversionStep,
                                ) => {
                                  const updatedSteps = values.steps.map(
                                    (
                                      step: ExcelConversionStep,
                                      stepIndex: number,
                                    ) => {
                                      if (stepIndex !== index) {
                                        return step
                                      }
                                      return updatedStep
                                    },
                                  )
                                  setFieldValue('steps', [...updatedSteps])
                                }}
                                deleteStep={() => {
                                  // 変換設定カードが1枚だけになったとき、ボタンが二重になってしまうため以下を追加
                                  setActiveClickCard(-1)
                                  const filteredStep = values.steps.filter(
                                    (
                                      _: ExcelConversionStep,
                                      stepIndex: number,
                                    ) => stepIndex !== index,
                                  )
                                  setFieldValue(
                                    'steps',
                                    filteredStep.length > 0
                                      ? [...filteredStep]
                                      : [],
                                  )
                                }}
                                startupType={values.startupType}
                                activeScheduleUUID={activeScheduleUUID}
                                setActiveScheduleUUID={setActiveScheduleUUID}
                                isFirstStep={values.steps.length === 1}
                                cardLength={values.steps.length}
                                index={index}
                                addExcelConversionSettings={
                                  addExcelConversionSettings
                                }
                                tooltip={tooltip}
                                activeClickCard={index === activeClickCard}
                                handleactiveClickCard={handleactiveClickCard}
                              />
                            ),
                          )}
                        <ModifyButton
                          type="dashed"
                          onMouseDown={() =>
                            addExcelConversionSettings(values.steps.length - 1)
                          }
                        >
                          <PlusCircleOutlined />
                          変換設定の追加
                        </ModifyButton>
                        <div>
                          <FormikErrorMessage name="steps" errors={errors} />
                        </div>
                      </Card>
                      <div>
                        <FormikErrorMessage
                          name="placeNodes"
                          errors={errors}
                          touched={touched}
                        />
                      </div>
                    </FormRow>

                    <FormRow>
                      <FormLabel>
                        <Text strong>5. その他の設定</Text>
                      </FormLabel>
                      <Card style={{ width: '100%' }}>
                        <Row>
                          <FormLabel>
                            <Text strong>通知先のユーザグループ</Text>
                          </FormLabel>
                          <FormLabel>
                            <Text style={{ fontSize: 12, color: '#757575' }}>
                              Excelが出力された時にメールで通知されます
                            </Text>
                          </FormLabel>
                          <Select
                            mode="multiple"
                            style={{ width: '50%' }}
                            optionFilterProp="children"
                            onSelect={(userGroupId: string | undefined) =>
                              setFieldValue(
                                'userGroups',
                                (values.userGroups ?? []).concat(
                                  props.userGroups.filter(
                                    userGroup =>
                                      userGroup.id?.toString() === userGroupId,
                                  ),
                                ),
                              )
                            }
                            onDeselect={(userGroupId: string | undefined) =>
                              setFieldValue(
                                'userGroups',
                                (values.userGroups ?? []).filter(
                                  (userGroup: UserGroup) =>
                                    userGroup.id?.toString() !== userGroupId,
                                ),
                              )
                            }
                            value={(values.userGroups ?? []).map(
                              (userGroup: UserGroup) =>
                                userGroup.id?.toString(),
                            )}
                          >
                            {(props.userGroups ?? []).length &&
                              props.userGroups.map(userGroup => (
                                <Option
                                  key={userGroup.id?.toString()}
                                  value={userGroup.id?.toString() || ''}
                                >
                                  {userGroup.name}
                                </Option>
                              ))}
                          </Select>
                        </Row>
                      </Card>
                    </FormRow>
                  </Col>
                </EditFlowContentRow>
              )}
            </Content>
          </Layout>
        }
      />
    </>
  )
}

export default EditExcelConversionFlowContainer
