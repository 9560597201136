import { GetReportsRequest, ReportPlace } from '@ulysses-inc/harami_api_client'
import Redux from 'redux'
import * as actions from './actions'
import { GetReportsFilter } from './types'

const getReports = (
  dispatch: Redux.Dispatch,
  request: GetReportsRequest,
  filter?: GetReportsFilter,
) => {
  dispatch(actions.reports.getReports(request, filter))
}

const changeReportsPage = (
  dispatch: Redux.Dispatch,
  page: number,
  pageSize?: number,
  filter?: GetReportsFilter,
) => {
  dispatch(actions.reports.changeReportsPage(page, pageSize, filter))
}

const changeReportsSize = (
  dispatch: Redux.Dispatch,
  pageSize: number,
  filter?: GetReportsFilter,
) => {
  dispatch(actions.reports.changeReportsSize(pageSize, filter))
}

const updatePagination = (
  dispatch: Redux.Dispatch,
  limit: number,
  offset: number,
) => {
  dispatch(actions.reports.updatePagination(limit, offset))
}

const deleteReport = (dispatch: Redux.Dispatch, reportId: string) => {
  dispatch(actions.reports.deleteReport(reportId))
}

const updateReportPlaces = (
  dispatch: Redux.Dispatch,
  reports: Array<{ id: number }>,
  reportPlace: ReportPlace,
  request: GetReportsRequest,
  filter?: GetReportsFilter,
) => {
  dispatch(
    actions.reports.updateReportPlaces(reports, reportPlace, request, filter),
  )
}

const getReportResult = (dispatch: Redux.Dispatch, reportId?: string) => {
  dispatch(actions.reportResult.getReportResult(reportId))
}

const addReportComment = (
  dispatch: Redux.Dispatch,
  reportId: string,
  comment: string,
) => {
  dispatch(actions.reportResult.addReportComment(reportId, comment))
}

const uploadReportAttachmentFile = (
  dispatch: Redux.Dispatch,
  reportId: string,
  file: File,
) => {
  dispatch(actions.reportResult.uploadReportAttachmentFile(reportId, file))
}

const deleteReportAttachmentFile = (
  dispatch: Redux.Dispatch,
  reportId: string,
  fileId: string,
) => {
  dispatch(actions.reportResult.deleteReportAttachmentFile(reportId, fileId))
}

const resetReportResult = (dispatch: Redux.Dispatch) => {
  dispatch(actions.reportResult.resetReportResult())
}

const getReportsTemplates = (dispatch: Redux.Dispatch) => {
  dispatch(actions.reportsTemplates.getReportsTemplates())
}

const resetReportsTemplates = (dispatch: Redux.Dispatch) => {
  dispatch(actions.reportsTemplates.resetReportsTemplates())
}

export default {
  // reports
  getReports,
  changeReportsPage,
  changeReportsSize,
  updatePagination,
  deleteReport,
  updateReportPlaces,

  // reportsTemplates
  getReportsTemplates,
  resetReportsTemplates,

  // reportResult
  getReportResult,
  addReportComment,
  uploadReportAttachmentFile,
  deleteReportAttachmentFile,
  resetReportResult,
}
