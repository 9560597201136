import { useCallback, useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import {
  PatchInputTable,
  RowPatch,
  patchInputTable,
} from 'src/state/ducks/editGridVariables/actions'
import { RootState } from 'src/state/store'
import { getEndPosition } from '../helpers/getEndPosition'
import { getStartPosition } from '../helpers/getStartPosition'

export const useClearHandler = () => {
  const mode = useSelector(
    (state: RootState) => state.editGridVariablesState.editGridVariables.mode,
  )

  const selectedCells = useSelector(
    (state: RootState) =>
      state.editGridVariablesState.editGridVariables.selectedCells,
    shallowEqual,
  )

  const dispatch = useDispatch()

  const handleKeydown = useCallback(
    (e: KeyboardEvent) => {
      if (mode !== 'select') {
        return
      }

      if (e.key !== 'Backspace' && e.key !== 'Delete') {
        return
      }

      e.preventDefault()

      const [startRowNum, startColNum] = getStartPosition(selectedCells)
      if (startRowNum === undefined || startColNum === undefined) {
        return
      }

      const [endRowNum, endColNum] = getEndPosition(selectedCells)
      if (endRowNum === undefined || endColNum === undefined) {
        return
      }

      const patches = createRowClearPatch(
        startRowNum,
        startColNum,
        endRowNum,
        endColNum,
      )

      dispatch(patchInputTable(patches))
    },
    [mode, selectedCells, dispatch],
  )

  useEffect(() => {
    document.addEventListener('keydown', handleKeydown)

    // WARN: 以下のように明示的にイベントハンドラーを解除しなければならない
    return () => {
      document.removeEventListener('keydown', handleKeydown)
    }
  }, [handleKeydown])
}

/**
 * 指定範囲をクリアするための行パッチを作る
 *
 * @param startRowNum 始端の行番号
 * @param startColNum 始端の列番号
 * @param endRowNum 終端の行番号
 * @param endColNum 終端の列番号
 * @returns 行パッチの連想配列
 */
export const createRowClearPatch = (
  startRowNum: number,
  startColNum: number,
  endRowNum: number,
  endColNum: number,
): PatchInputTable => {
  const patches: PatchInputTable = {}

  for (let rowNum = startRowNum; rowNum <= endRowNum; rowNum++) {
    let from: RowPatch['from']
    let through: RowPatch['through']
    let sectionName: RowPatch['sectionName']
    let variableSection: RowPatch['variableSection']
    const values: RowPatch['values'] = {}

    for (let colNum = startColNum; colNum <= endColNum; colNum++) {
      switch (colNum) {
        case 1:
          from = ''
          break
        case 2:
          through = ''
          break
        case 3:
          sectionName = ''
          break
        case 4:
          variableSection = ''
          break
        // WARN: 2023/02 の仕様だと取り込み項目は 1~6 で固定だが、可変にしたくなったときにこれでは対応できない
        case 5:
        case 6:
        case 7:
        case 8:
        case 9:
        case 10:
          /**
           * 列番号と取り込み項目番号の対応関係
           *   - 列番号 5  => 取り込み項目 1
           *   - 列番号 10 => 取り込み項目 6
           */
          values[colNum - 4] = ''
          break
        // 11 列目以降は無視する
        default:
      }
    }

    const patch: RowPatch = {
      from,
      through,
      sectionName,
      variableSection,
      values,
    }

    patches[rowNum] = patch
  }

  return patches
}
