/**
 * 与えられた日付の時間(hh)以下を0にする。日付がundefinedの場合、そのままundefinedが返る
 *
 * @param date
 * @returns
 */
export const resetHourPrecision = (date: Date): Date => {
  if (!date) {
    return date
  }
  const copiedDate = new Date(date.getTime())
  copiedDate.setHours(0)
  copiedDate.setMinutes(0)
  copiedDate.setSeconds(0)
  copiedDate.setMilliseconds(0)
  return copiedDate
}

/**
 * 与えられた日付を分を最小単位とし、その日付の最終時刻にセットする。
 * すなわち:
 *
 * - 2022-01-01T00:00:00.000+0900 -> 2022-01-01T23:59:00.000+0900
 * - (2022-12-31T15:00:00.000Z -> 2022-01-01T14:59:00.000Z)
 *
 * 日付がundefinedの場合、そのままundefinedが返る
 *
 * @param date
 * @returns
 *
 * Visible for testing
 */
export const adjustThroughDate = (date: Date): Date => {
  const copiedDate = resetHourPrecision(date)
  if (!copiedDate) {
    return copiedDate
  }
  copiedDate.setDate(copiedDate.getDate() + 1)
  copiedDate.setMinutes(copiedDate.getMinutes() - 1)
  return copiedDate
}
