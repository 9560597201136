import {
  IndicatedIssueLabel,
  IndicatedIssueRevise,
} from '@ulysses-inc/harami_api_client'
import Redux from 'redux'
import actions from './actions'

const addIndicatedIssueLabel = (
  dispatch: Redux.Dispatch,
  indicatedIssueLabel: IndicatedIssueLabel,
) => {
  dispatch(actions.addIndicatedIssueLabel(indicatedIssueLabel))
}

const updateIndicatedIssueLabel = (
  dispatch: Redux.Dispatch,
  indicatedIssueLabel: IndicatedIssueLabel,
) => {
  dispatch(actions.updateIndicatedIssueLabel(indicatedIssueLabel))
}

const getIndicatedIssueLabels = (dispatch: Redux.Dispatch) => {
  dispatch(actions.getIndicatedIssueLabels())
}

const resetEditIndicatedIssueLabel = (dispatch: Redux.Dispatch) => {
  dispatch(actions.resetEditIndicatedIssueLabel())
}

const changeEditIndicatedIssueLabel = (
  dispatch: Redux.Dispatch,
  indicatedIssueLabel: IndicatedIssueLabel,
) => {
  dispatch(actions.changeEditIndicatedIssueLabel(indicatedIssueLabel))
}

const deleteIndicatedIssueLabel = (dispatch: Redux.Dispatch, uuid: string) => {
  dispatch(actions.deleteIndicatedIssueLabel(uuid))
}

const changeIsShowDrawer = (
  dispatch: Redux.Dispatch,
  isShowDrawer: boolean,
) => {
  dispatch(actions.changeIsShowDrawer(isShowDrawer))
}

const updateIndicatedIssueRevises = (
  dispatch: Redux.Dispatch,
  revises: IndicatedIssueRevise[],
) => {
  dispatch(actions.updateRequestIndicatedIssueRevises(revises))
}

const saveDraftIndicatedIssueRevises = (
  dispatch: Redux.Dispatch,
  revises: IndicatedIssueRevise[],
) => {
  dispatch(actions.saveDraftRequestIndicatedIssueRevises(revises))
}

export default {
  addIndicatedIssueLabel,
  updateIndicatedIssueLabel,
  getIndicatedIssueLabels,
  resetEditIndicatedIssueLabel,
  changeEditIndicatedIssueLabel,
  deleteIndicatedIssueLabel,
  changeIsShowDrawer,
  updateIndicatedIssueRevises,
  saveDraftIndicatedIssueRevises,
}
