import { Text } from '@react-pdf/renderer'
import { TimeMeasurementValue } from '@ulysses-inc/harami_api_client'
import { FC } from 'react'
import { gridLayoutTableBodyCell } from '../../../shared/styles'

type Props = {
  value?: TimeMeasurementValue
}

export const TableBodyCellTimeMeasurement: FC<Props> = ({ value }) => {
  return (
    <Text
      style={[
        gridLayoutTableBodyCell.baseTableBodyCell,
        gridLayoutTableBodyCell.tableBodyCell,
      ]}
    >
      {formatMinutes(value?.value || 0)}
    </Text>
  )
}

/**
 * 指定された分数をフォーマットする
 * 60 未満の場合は「X分」、それ以上の場合は「X時間Y分」の形式で返す
 *
 * @param minutes フォーマットする分数
 * @returns フォーマットされた時間の文字列
 */
const formatMinutes = (minutes: number): string => {
  if (minutes < 60) {
    return `${minutes}分`
  } else {
    const h = Math.floor(minutes / 60)
    const m = minutes % 60
    return `${h}時間${m}分`
  }
}
