import { CheckOutlined, CloseOutlined } from '@ant-design/icons'
import { TemplateNodeSchema } from '@ulysses-inc/harami_api_client'
import { Switch, Tooltip } from 'antd'
import { FC } from 'react'
import { useDispatch } from 'react-redux'
import GridVariableSvg from 'src/assets/icons/grid_variable.svg?react'
import { ClampText } from 'src/components/clampText/ClampText'
import {
  SelectAnswerWrapper,
  SelectResponseTableCell,
} from 'src/features/templateEdit/itemAppender/ResponseDropDown.dump'
import {
  changeActiveNodeId,
  changeQuestionToNext,
  updateTemplateNode,
} from 'src/state/ducks/templates/actions'
import styled from 'styled-components'
import { QuestionProps } from './Question'
import {
  QuestionNameParagraph,
  QuestionNameParagraphWrapper,
  QuestionTableCell,
  QuestionWrapper,
  ResponseTag,
  ResponseTagIconWrap,
  Table,
} from './Question.components'
import SelectNodeEditor from './SelectNodeEditor'

const SwitchWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 12px;
`

type Props = QuestionProps & { gridVariableNumber: number }

export const GridVariableQuestionBody: FC<Props> = ({
  node,
  isActive,
  parentNode,
  gridVariableNumber,
}) => {
  const dispatch = useDispatch()
  const dispatchChangeActiveNodeId = () => dispatch(changeActiveNodeId(node.id))
  const dispatchChangeQuestionToNext = (name: string) => {
    const n: TemplateNodeSchema = {
      ...node,
      question: {
        ...node.question,
        name,
      },
    }

    dispatch(changeQuestionToNext(node.id, n, parentNode))
  }
  const dispatchUpdateTemplateNode = (
    values: Partial<TemplateNodeSchema['question']>,
    nodeId: number = node.id,
  ) =>
    dispatch(
      updateTemplateNode(nodeId, {
        ...node,
        question: { ...node.question, ...values },
      }),
    )
  const dispatchUpdateIsEnabled = (isEnabled: boolean) =>
    dispatch(
      updateTemplateNode(node.id, {
        ...node,
        question: { ...node.question, responseGridVariables: [{ isEnabled }] },
      }),
    )

  const questionName = node.question?.name ?? ''
  const questionNamePlaceHolder = `取り込み項目名${gridVariableNumber}`
  const isEnabled = node.question?.responseGridVariables?.[0]?.isEnabled

  return (
    <>
      <Table onClick={dispatchChangeActiveNodeId}>
        <QuestionTableCell theme={{ width: '100%' }}>
          <QuestionWrapper>
            <QuestionNameParagraphWrapper>
              {isActive ? (
                <SelectNodeEditor
                  onChangeText={dispatchChangeQuestionToNext}
                  onUpdate={name => dispatchUpdateTemplateNode({ name })}
                  multiline
                  name={questionName}
                  node={node}
                />
              ) : (
                <QuestionNameParagraph>
                  <ClampText line={2}>
                    {questionName || questionNamePlaceHolder}
                  </ClampText>
                </QuestionNameParagraph>
              )}
            </QuestionNameParagraphWrapper>
            <SwitchWrapper>
              <Tooltip
                placement="topLeft"
                title="オンにすると、記録画面に取り込み項目列が表示されます。"
                arrowPointAtCenter
              >
                <Switch
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                  checked={isEnabled}
                  onChange={checked => {
                    dispatchUpdateIsEnabled(checked)
                  }}
                />
              </Tooltip>
            </SwitchWrapper>
          </QuestionWrapper>
        </QuestionTableCell>
        <SelectResponseTableCell
          theme={{
            minWidth: '300px',
          }}
        >
          <SelectAnswerWrapper>
            <ResponseTag>
              <ResponseTagIconWrap>
                <GridVariableSvg width={30} height={30} />
              </ResponseTagIconWrap>
              <span>取り込み項目{gridVariableNumber}</span>
            </ResponseTag>
          </SelectAnswerWrapper>
        </SelectResponseTableCell>
      </Table>
    </>
  )
}
