import { MultipleChoice } from '@ulysses-inc/harami_api_client'
import { Select, Tag } from 'antd'
import { FC } from 'react'
import { multipleChoiceColors } from 'src/features/theme/MultipleChoiceColor'
import { TMultipleChoicesState } from './AddMultipleChoiceDrawer'

const { Option } = Select

const colors = [
  multipleChoiceColors.transparent,
  multipleChoiceColors.danger,
  multipleChoiceColors.warning,
  multipleChoiceColors.success,
  multipleChoiceColors.primary,
  multipleChoiceColors.info,
  multipleChoiceColors.default,
]

const ColorColumn: FC<{
  multipleChoicesState: TMultipleChoicesState
  multipleChoice: MultipleChoice
}> = ({
  multipleChoicesState: [multipleChoices, setMultipleChoices],
  multipleChoice,
}) => {
  const { id, color } = multipleChoice

  const defaultValue =
    color === '' || color === null || color === undefined
      ? multipleChoiceColors.default
      : color

  const onChange = (color?: string) => {
    setMultipleChoices({
      ...multipleChoices,
      [`${id}`]: {
        ...multipleChoice,
        color: color === multipleChoiceColors.transparent ? '' : color,
      },
    })
  }

  return (
    <Select
      placeholder="色"
      style={{ width: '100%' }}
      defaultValue={defaultValue}
      onChange={onChange}
    >
      {colors.map(color => (
        <Option key={color} value={color}>
          <Tag color={color}>{'　'}</Tag>
        </Option>
      ))}
    </Select>
  )
}

export default ColorColumn
