// prettier-ignore
export const ActionTypes = {
  //
  REQUEST_GET_EMPLOYEES: 'harami/employees/REQUEST_GET_EMPLOYEES',
  SUCCESS_GET_EMPLOYEES: 'harami/employees/SUCCESS_GET_EMPLOYEES',
  ERROR_GET_EMPLOYEES: 'harami/employees/ERROR_GET_EMPLOYEES',
  REQUEST_CHANGE_EMPLOYEES_PAGE: 'harami/employees/REQUEST_CHANGE_EMPLOYEES_PAGE',
  REQUEST_CHANGE_EMPLOYEES_SIZE: 'harami/employees/REQUEST_CHANGE_EMPLOYEES_SIZE',
  UPDATE_PAGINATION: 'harami/employees/UPDATE_PAGINATION',
  //
  REQUEST_ADD_EMPLOYEE: 'harami/employees/REQUEST_ADD_EMPLOYEE',
  SUCCESS_ADD_EMPLOYEE: 'harami/employees/SUCCESS_ADD_EMPLOYEE',
  ERROR_ADD_EMPLOYEE: 'harami/employees/ERROR_ADD_EMPLOYEE',
  //
  REQUEST_UPDATE_EMPLOYEE: 'harami/employees/REQUEST_UPDATE_EMPLOYEE',
  SUCCESS_UPDATE_EMPLOYEE: 'harami/employees/SUCCESS_UPDATE_EMPLOYEE',
  ERROR_UPDATE_EMPLOYEE: 'harami/employees/ERROR_UPDATE_EMPLOYEE',
  //
  REQUEST_DELETE_EMPLOYEE: 'harami/employees/REQUEST_DELETE_EMPLOYEE',
  SUCCESS_DELETE_EMPLOYEE: 'harami/employees/SUCCESS_DELETE_EMPLOYEE',
  ERROR_DELETE_EMPLOYEE: 'harami/employees/ERROR_DELETE_EMPLOYEE',
  //
  REQUEST_DELETE_MULTIPLE_EMPLOYEES: 'harami/employees/REQUEST_DELETE_MULTIPLE_EMPLOYEES',
  SUCCESS_DELETE_MULTIPLE_EMPLOYEES: 'harami/employees/SUCCESS_DELETE_MULTIPLE_EMPLOYEES',
  //
  CHANGE_IS_SHOW_EDIT_EMPLOYEE_DRAWER: 'harami/employees/CHANGE_IS_SHOW_EDIT_EMPLOYEE_DRAWER',
  UPDATE_ACTIVE_EMPLOYEE_UUID: 'harami/employees/UPDATE_ACTIVE_EMPLOYEE_UUID',
  UPDATE_SEGMENT_INDEX: 'harami/employees/UPDATE_SEGMENT_INDEX',
  //
  REQUEST_ADD_MULTIPLE_EMPLOYEES: 'harami/employees/REQUEST_ADD_MULTIPLE_EMPLOYEES',
  SUCCESS_ADD_MULTIPLE_EMPLOYEES: 'harami/employees/SUCCESS_ADD_MULTIPLE_EMPLOYEES',
  ERROR_ADD_MULTIPLE_EMPLOYEES: 'harami/employees/ERROR_ADD_MULTIPLE_EMPLOYEES',
  UPDATE_PLACE_NODE_WARNING: 'harami/employees/UPDATE_PLACE_NODE_WARNING',
} as const

export type GetEmployeesFilter =
  | {
      placeNodeId?: { $in: string[] }
      isIncludeNonAffiliated: boolean
    }
  | Record<string, never>
