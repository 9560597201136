import { Improve } from '@ulysses-inc/harami_api_client'
import { GetImproveAction, UpdateImproveAction } from '../actions'
import { ActionTypes } from '../types'

interface EditImproveState {
  improve: Improve
  isLoading: boolean
  error: Error | null
}

// TODO
const initialImproveState: EditImproveState = {
  improve: {
    id: undefined,
    uuid: '',
    reportName: '',
    status: {
      status: 0,
    },
    place: {},
    indicatedIssueGroups: [],
  },
  isLoading: false,
  error: null,
}

const editImprove = (
  state: EditImproveState = initialImproveState,
  action: UpdateImproveAction | GetImproveAction,
) => {
  switch (action.type) {
    case ActionTypes.REQUEST_GET_IMPROVE: {
      return {
        ...state,
        isLoading: action.isLoading,
        error: null,
        improveUUID: action.improveUUID,
      }
    }
    case ActionTypes.SUCCESS_GET_IMPROVE: {
      return {
        ...state,
        isLoading: action.isLoading,
        error: null,
        improve: action.improve,
      }
    }
    case ActionTypes.ERROR_GET_IMPROVE: {
      return {
        ...state,
        isLoading: action.isLoading,
        error: action.error,
      }
    }
    case ActionTypes.REQUEST_UPDATE_IMPROVE: {
      return {
        ...state,
        isLoading: action.isLoading,
        error: null,
      }
    }
    case ActionTypes.SUCCESS_UPDATE_IMPROVE: {
      return {
        ...state,
        isLoading: action.isLoading,
        error: null,
      }
    }
    case ActionTypes.ERROR_UPDATE_IMPROVE: {
      return {
        ...state,
        isLoading: action.isLoading,
        error: action.error,
      }
    }
    case ActionTypes.REQUEST_UPDATE_IMPROVE_STATUS: {
      return {
        ...state,
        isLoading: action.isLoading,
        error: null,
        improve: {
          ...state.improve,
          status: {
            status: action.status,
          },
        },
      }
    }
    case ActionTypes.SUCCESS_UPDATE_IMPROVE_STATUS: {
      return {
        ...state,
        isLoading: action.isLoading,
        error: null,
      }
    }
    case ActionTypes.ERROR_UPDATE_IMPROVE_STATUS: {
      return {
        ...state,
        isLoading: action.isLoading,
        error: action.error,
      }
    }
    case ActionTypes.RESET_EDIT_IMPROVE: {
      return { ...state, ...initialImproveState }
    }
    default: {
      return {
        ...state,
      }
    }
  }
}

export default editImprove
