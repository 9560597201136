import { notification } from 'antd'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'src/state/store'

export const NotificationService = () => {
  const { duration, message, messageId, notificationType } = useSelector(
    (state: RootState) => state.notificationServiceState,
  )

  useEffect(() => {
    if (message === '') return
    notification[notificationType]({ message, duration })
  }, [duration, message, messageId, notificationType])

  // hooksで実装してもよいのだが、配置場所が特殊なため、
  // あえてコンポーネントにして取り回しを良くしたもの
  return null
}
