import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  clearState,
  requestFetchGridVariablesTable,
} from 'src/state/ducks/editGridVariables/actions'
import { RootState } from 'src/state/store'

/**
 * 取り込み項目詳細のテーブルの state を取得するためのカスタムフック
 *
 * @param templateId どのテンプレートに対応するテーブル state を取得するかを指定する id
 * @returns
 */
export const useTable = (templateId: number) => {
  const isLoading = useSelector(
    (state: RootState) =>
      state.editGridVariablesState.editGridVariables.isLoading,
  )
  const error = useSelector(
    (state: RootState) => state.editGridVariablesState.editGridVariables.error,
  )

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(requestFetchGridVariablesTable(templateId))

    return () => {
      dispatch(clearState())
    }
  }, [dispatch, templateId])

  return {
    data: {},
    isLoading,
    error,
    methods: {},
  }
}
