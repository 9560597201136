import { PlusOutlined } from '@ant-design/icons'
import { Input, InputRef, Tag } from 'antd'
import React, { useRef, useState } from 'react'
import { ResponseSetCategoryForm } from 'src/features/template/multipleChoiceSets/ResponseSetTypes'
import { White } from 'src/features/theme/KdsThemeColor'
import styled from 'styled-components'

const Categories = styled.span`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: -5px;
`

const CategoryEditInput = styled(Input)`
  width: 78px;
  margin-bottom: 5px;
`

const CategoryTag = styled(Tag)`
  display: inline-flex;
  align-items: center;
  max-width: 100%;
  margin-bottom: 5px;
`
const CategoryTagName = styled.span`
  display: block;
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const AddCategoryTag = styled(Tag)`
  background: ${White};
  border-style: dashed;
  margin-bottom: 5px;
`

interface OwnProps {
  tags: ResponseSetCategoryForm[]
  onInputConfirm: (tagName: string) => void
  onClose: (index: number) => void
}

const ResponseSetTags: React.FC<OwnProps> = (props: OwnProps) => {
  const saveInputRef = useRef<InputRef | null>()
  const [categoryInputVisible, setCategoryInputVisible] =
    useState<boolean>(false)
  const [inputValue, setInputValue] = useState<string>('')
  const onConfirm = () => {
    if (!inputValue) {
      return
    }
    props.onInputConfirm(inputValue)
    setCategoryInputVisible(false)
    setInputValue('')
  }
  return (
    <Categories>
      {props.tags.map((tag: ResponseSetCategoryForm, index: number) => (
        <CategoryTag
          closable={true}
          onClose={() => props.onClose(index)}
          color="blue"
          key={tag.key}
        >
          <CategoryTagName>{tag.name}</CategoryTagName>
        </CategoryTag>
      ))}
      {categoryInputVisible && (
        <CategoryEditInput
          ref={node => {
            saveInputRef.current = node
          }}
          type="text"
          size="small"
          value={inputValue}
          onChange={e => {
            setInputValue(e.target.value)
          }}
          onBlur={() => onConfirm()}
          onPressEnter={() => onConfirm()}
        />
      )}
      {!categoryInputVisible && (
        <AddCategoryTag onClick={() => setCategoryInputVisible(true)}>
          <PlusOutlined />
        </AddCategoryTag>
      )}
    </Categories>
  )
}

export default ResponseSetTags
