import { HomeOutlined, TagOutlined } from '@ant-design/icons'
import { PlaceNode, PlaceNodeTypeEnum } from '@ulysses-inc/harami_api_client'
import { Tree } from 'antd'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

// antdのTreeコンポーネントが受け取る`treeData`の定義がイマイチなので、自前で定義しないと型パズルが完成しない
// https://4x.ant.design/components/tree/#Tree-props
type TreeDataItem = {
  key: string
  title: React.ReactNode
  children?: TreeDataItem[]
}

const TreeWrap = styled(Tree)`
  background: transparent !important;
  .ant-tree {
    background: transparent !important;
  }
`

const TreeNodeIconTag = styled(TagOutlined)`
  margin-right: 8px;
`
const TreeNodeIconHome = styled(HomeOutlined)`
  margin-right: 8px;
`

type CheckedKeys =
  | (string | number)[]
  | {
      checked: (string | number)[]
      halfChecked: (string | number)[]
    }
interface OwnProps {
  placeNodes: Array<PlaceNode>
  checkedKeys: CheckedKeys
  onCheck: (checkedKeys: CheckedKeys) => void
}
interface KeyDetail {
  placeNodeType: PlaceNodeTypeEnum
  isCheck: boolean
  count: number
}

/**
 * スケジュール詳細画面の現場・現場グループ選択ツリー
 */
const PlaceGroupsTree: React.FC<OwnProps> = (props: OwnProps) => {
  const nextKeyId: { [key: string]: number } = {}

  const generateTreeData = (nodes: any[]): TreeDataItem[] => {
    return nodes.map(node => {
      // keyは一意である必要があるが、同じ現場の場合uuidが同じ値をとる
      // => 同じ現場内でインデックスを振り一意になるように設定する
      nextKeyId[node.uuid] =
        nextKeyId[node.uuid] === undefined ? 0 : nextKeyId[node.uuid] + 1

      switch (node.type) {
        case PlaceNodeTypeEnum.PlaceGroup: {
          return {
            children: generateTreeData(node.nodes),
            key: `${node.uuid}/${nextKeyId[node.uuid]}`,
            title: (
              <span>
                <TreeNodeIconTag />
                {node.placeGroup?.name}
              </span>
            ),
          }
        }

        case PlaceNodeTypeEnum.Place: {
          return {
            key: `${node.uuid}/${nextKeyId[node.uuid]}`,
            title: (
              <span>
                <TreeNodeIconHome />
                {node.place?.name}
              </span>
            ),
          }
        }

        default: {
          throw new Error('不正な現場のデータです')
        }
      }
    })
  }

  // 現場・現場グループのチェック状態を保持
  const [checkList, setCheckList] = useState<CheckedKeys>()
  // 各現場・現場グループのチェック状態や出現回数を保持
  const [keyDetail, setKeyDetail] = useState<{ [key: string]: KeyDetail }>({})
  useEffect(() => {
    const initCheckList: CheckedKeys = []
    const initKeyDetail: { [key: string]: KeyDetail } = {}
    const nextKeyId: { [key: string]: number } = {}
    // 初期状態を設定
    //
    // props.checkedKeysはuuidでチェック状態を保持している
    // => Treeのkeyと異なるため、一致するkeyをcheckListに保持させる
    const init = (nodes: any[]) => {
      nodes.map(node => {
        nextKeyId[node.uuid] =
          nextKeyId[node.uuid] === undefined ? 0 : nextKeyId[node.uuid] + 1
        const isCheck: boolean = props.checkedKeys
          .toString()
          .split(',')
          .includes(node.uuid)
        initKeyDetail[node.uuid] = {
          placeNodeType: node.type,
          isCheck: isCheck,
          count: nextKeyId[node.uuid],
        }
        if (isCheck) initCheckList.push(`${node.uuid}/${nextKeyId[node.uuid]}`)
        if (node.type === PlaceNodeTypeEnum.PlaceGroup) init(node.nodes)
      })
    }
    ;(async () => {
      await init(props.placeNodes)
      await setCheckList(initCheckList)
      await setKeyDetail(initKeyDetail)
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.checkedKeys])

  // checkedはチェックされているTreeのkeyが渡される
  // => 同じ現場間で同時にチェックが入らない
  // => APIへ送信する形（現場のuuid）とは異なる
  //   => 同時チェックを処理し、APIへ送信できる値を返す
  const onCheckPreprocess = (checked: CheckedKeys) => {
    // 現場グループのチェック状態は必要ない
    // => 現場のみに絞り込む
    const checkedStr: string = checked.toString()
    const placeKeys: CheckedKeys =
      checkedStr === ''
        ? []
        : checkedStr
            .split(',')
            .map(key => key.split('/')[0])
            .filter(
              key => keyDetail[key].placeNodeType === PlaceNodeTypeEnum.Place,
            )
    // Treeのkeyが異なると同じ現場であっても同時にチェックが入らない
    // => 同時にチェックが入るようにチェックする現場を保持
    //
    // 3パターン考慮（A,Bは同じ現場）
    // ユーザのチェック & チェック前の状態 -> 処理内容
    // Aをチェック & A,Bチェックなし -> Bもチェック
    // Aをチェック & A,Bチェックあり -> Bもチェック解除
    // Cをチェック & A,Bチェックあり -> A,Bそのまま
    const keys = new Set()
    const newIsCheck: { [key: string]: boolean } = {}
    placeKeys.forEach(key => {
      if (
        placeKeys.filter(k => k === key).length !==
        keyDetail[key].count + 1
      ) {
        if (keyDetail[key].isCheck) newIsCheck[key] = false
        else {
          keys.add(key)
          newIsCheck[key] = true
        }
      } else {
        keys.add(key)
        newIsCheck[key] = true
      }
    })
    // keyDetailのチェック状態を更新
    const newKeyDetail: { [key: string]: KeyDetail } = {}
    Object.assign(newKeyDetail, keyDetail)
    placeKeys.forEach(key => (newKeyDetail[key].isCheck = newIsCheck[key]))
    setKeyDetail(newKeyDetail)
    // keysにはチェックするuuidが保持されている
    // => Treeのkeyと形が異なるため、一致させcheckListを更新
    //
    // newCheckedにはチェックしているuuidを保持させ、関数の戻り値とする
    const newCheckList: CheckedKeys = []
    const newChecked: CheckedKeys = []
    keys.forEach(key => {
      for (let keyId = 0; keyId <= keyDetail[`${key}`].count; keyId++)
        newCheckList.push(`${key}/${keyId}`)
      newChecked.push(key as string)
    })
    setCheckList(newCheckList)
    return newChecked
  }

  return (
    <>
      {props.placeNodes.length > 0 && (
        <TreeWrap
          checkable
          onCheck={(checked: CheckedKeys) => {
            props.onCheck(onCheckPreprocess(checked))
          }}
          checkedKeys={checkList}
          treeData={generateTreeData(props.placeNodes)}
        />
      )}
    </>
  )
}

export default PlaceGroupsTree
