import { Image } from '@ulysses-inc/harami_api_client'
import { UploadFile } from 'antd/lib/upload/interface'

export const getBase64 = (file: Blob): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    // TODO remove `as` and replace it with type narrowing
    reader.onload = () => resolve(reader.result as string)
    reader.onerror = error => reject(error)
  })
}

/**
 * antdのUploadコンポーネントをControlledに扱うために、
 * haramiのAPIから取得した画像に関するデータを基にして、
 * antdに求められている`UploadFile`という型に合うデータを生成するための関数と思われる
 */
export const createDefaultFileList = (images: Image[]): UploadFile[] => {
  if (!images?.length) return []

  return images
    .filter(image => image)
    .map(image => {
      const file: UploadFile = {
        uid: image.id?.toString() ?? '0',
        name: image.name ?? '',
        url: image.url,
        status: 'done',
        size: image.size ?? 0,
        type: image.type ?? '',
        // 以下の理由により型エラーを無視する
        // - APIから取得した画像に関するデータはImage型であり、当然ながらファイルオブジェクトなど存在しない
        // - antdの提供する`RcFile`や`UploadFile`なる型についてドキュメントがなく対処のしようがない
        // - `originFileObj`という値はアプリ内で未利用である
        //
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        originFileObj: {},
      }
      return file
    })
}
