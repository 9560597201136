import {
  GetTemplatesResponse,
  GetTemplatesV2Request,
  TemplatesApi,
} from '@ulysses-inc/harami_api_client'
import { call, put } from 'redux-saga/effects'
import {
  HTTPError,
  handleHTTPError,
} from 'src/state/middleware/saga/handleHttpError'
import BaseClient from '../../../middleware/saga/baseClient'
import interceptionsActions from '../../interceptions/actions'
import {
  changeGridVariableTemplatesPage,
  getErrorGridVariableTemplates,
  getSuccessGridVariableTemplates,
} from './actions'

const baseClient = new BaseClient()

const getTemplatesV2Request = (req: GetTemplatesV2Request) => {
  return baseClient
    .getApi(TemplatesApi)
    .getTemplatesV2(req)
    .then(templates => templates)
    .catch(handleHTTPError)
}
export function* getGridVariableTemplates(
  action: ReturnType<typeof changeGridVariableTemplatesPage>,
) {
  try {
    // filter は使用している templateName / placeNodeId のみを設定
    const params: GetTemplatesV2Request = {
      ...action.request,
      templateFilter: {
        templateName: action.filter.templateName,
        placeNodeId: action.filter.placeNodeId,
        hasVariables: true,
      },
    }
    const response: GetTemplatesResponse = yield call(
      getTemplatesV2Request,
      params,
    )
    yield put(getSuccessGridVariableTemplates(response))
  } catch (error) {
    yield put(interceptionsActions.handleHttpError(error as HTTPError))
    yield put(getErrorGridVariableTemplates(error as Error))
  }
}
