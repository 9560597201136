import { useQuery } from '@tanstack/react-query'
import { TemplatesApi } from '@ulysses-inc/harami_api_client'
import { queryKeys } from 'src/features/reportExport/detail/api/queryKeys'
import { baseClient } from 'src/state/middleware/saga/baseClient'

export const useQueryTemplates = () => {
  return useQuery({
    queryKey: queryKeys.templates,
    queryFn: () =>
      baseClient.getApi(TemplatesApi).getTemplatesV2({
        // https://kaminashi-inc.slack.com/archives/C02BYDY8E1W/p1684823728362879
        templateFilter: {},
      }),
    select: data =>
      (data.templates || []).map(template => ({
        // 必ず存在するので警告は無視する。OpenAPI定義の修正が必要。
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        id: template.id!,
        name: template.name,
      })),
    // 画面を二回目以降に開く際に、時間差でデータが更新されることによる事故を防ぐため
    gcTime: 0,
  })
}
