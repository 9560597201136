import { PlaceNode } from '@ulysses-inc/harami_api_client'

const getUniqueFlattenNodes = (flatNodes: PlaceNode[]): PlaceNode[] => {
  const uniqueFlatNodes: PlaceNode[] = []
  flatNodes.forEach(node => {
    if (
      uniqueFlatNodes.findIndex(uniqueNode => uniqueNode.uuid === node.uuid) >=
      0
    ) {
      return
    }
    uniqueFlatNodes.push(node)
  })
  return uniqueFlatNodes
}

export { getUniqueFlattenNodes }
