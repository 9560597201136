import { StyleSheet, Text } from '@react-pdf/renderer'
import { ReportStatusEnum, Schedule } from '@ulysses-inc/harami_api_client'
import React from 'react'
import formatReportDate from 'src/exShared/util/reportResult/formatReportDate'
import Col from '../Col'
import Grid from '../Grid'

const styles = StyleSheet.create({
  title: {
    fontSize: 14,
  },
  pageName: {
    marginTop: 5,
    marginBottom: 5,
  },
  textPadding: {
    padding: 5,
  },
  textCenter: {
    textAlign: 'center',
  },
  score: {
    fontSize: 12,
  },
  reportName: {
    paddingBottom: 10,
    paddingTop: 10,
  },
})

interface OwnProps {
  reportName: string
  reportDate: Date
  schedules?: Schedule[]
  userName: string
  isShowScore: boolean
  sumAllTotalScores: number
  sumAllResultScores: number
  score?: number
  status: ReportStatusEnum
  deviatedAnswersCount: number
  comment: string
  placeName: string
}

const getReportStatusName = (status: ReportStatusEnum) => {
  switch (status) {
    case ReportStatusEnum.COMPLETE:
      return '完了'
    case ReportStatusEnum.INCOMPLETE:
      return '未完了'
    case ReportStatusEnum.APPROVAL_PENDING:
      return '承認待ち'
    case ReportStatusEnum.APPROVAL_REMAND:
      return '差し戻し'
    default:
      return 'ステータスなし'
  }
}

const Overview: React.FC<OwnProps> = (props: OwnProps) => {
  return (
    <>
      <Grid borderBottom style={{ width: '100%', ...styles.reportName }}>
        <Text style={{ fontSize: 16 }}>{props.reportName}</Text>
      </Grid>
      <Grid borderBottom style={{ width: '100%' }}>
        <Col borderLeft borderRight style={{ width: '50%' }}>
          <Text style={{ ...styles.textPadding }}>
            実施日: {formatReportDate(props.reportDate, props.schedules)}
          </Text>
          <Text style={{ ...styles.textPadding }}>現場: {props.placeName}</Text>
          <Text style={{ ...styles.textPadding }}>
            ユーザー: {props.userName}
          </Text>
        </Col>
        <Col borderRight style={{ width: '50%' }}>
          <Grid borderBottom style={{ width: '100%' }}>
            <Col style={{ width: '100%' }}>
              <Grid style={{ width: '100%' }}>
                <Text style={{ ...styles.textPadding, ...styles.textCenter }}>
                  スコア
                </Text>
              </Grid>
              <Grid style={{ width: '100%' }}>
                <Text
                  style={{
                    ...styles.textPadding,
                    ...styles.textCenter,
                    ...styles.score,
                  }}
                >
                  {props.isShowScore
                    ? `${props.sumAllResultScores} / ${props.sumAllTotalScores} (${props.score}%)`
                    : '-'}
                </Text>
              </Grid>
            </Col>
          </Grid>
          <Grid style={{ width: '100%' }}>
            <Col borderRight style={{ width: '33%' }}>
              <Grid borderBottom style={{ width: '100%' }}>
                <Text style={{ ...styles.textPadding, ...styles.textCenter }}>
                  ステータス
                </Text>
              </Grid>
              <Grid style={{ width: '100%' }}>
                <Text style={{ ...styles.textPadding, ...styles.textCenter }}>
                  {getReportStatusName(props.status)}
                </Text>
              </Grid>
            </Col>
            <Col style={{ width: '33%' }}>
              <Grid borderBottom style={{ width: '100%' }}>
                <Text style={{ ...styles.textPadding, ...styles.textCenter }}>
                  逸脱項目
                </Text>
              </Grid>
              <Grid style={{ width: '100%' }}>
                <Text style={{ ...styles.textPadding, ...styles.textCenter }}>
                  {props.deviatedAnswersCount}
                </Text>
              </Grid>
            </Col>
          </Grid>
        </Col>
      </Grid>
    </>
  )
}

export default Overview
