import { Col, Layout, Row } from 'antd'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { Header } from 'src/components/header/Header'
import { HeaderBackButton } from 'src/components/header/HeaderBackButton'
import { HeaderTitle } from 'src/components/header/HeaderTitle'
import LoadingOverlay from 'src/components/loading/LoadingOverlay'
import DataUnavailableWidget from 'src/features/dataUnavailableWidget/DataUnavailableWidget'
import { useDataUnavailableWidget } from 'src/features/dataUnavailableWidget/useDataUnavailableWidget'
import {
  getMultipleChoiceSet as getMultipleChoiceSetAction,
  resetMultipleChoiceSet as resetMultipleChoiceSetAction,
} from 'src/state/ducks/multipleChoiceSets/actions'
import { RootState } from 'src/state/store'
import {
  ActiveButton,
  ActiveButtonGroup,
  FormRow,
} from './EditMultipleChoiceSet.dumb'
import { useFormikProps } from './EditMultipleChoiceSet.hooks'
import ExcelConversionSettingsRow from './ExcelConversionSettingsRow'
import NameRow from './NameRow'
import OptionsRow from './OptionsRow'

const { Content } = Layout

const EditMultipleChoiceSetContainer: React.FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { multipleChoiceSetId } = useParams<{ multipleChoiceSetId: string }>()

  const isLoading = useSelector(
    (state: RootState) =>
      state.multipleChoiceSetsState.multipleChoiceSet.isLoading,
  )

  const dataUnavailableWidget = useDataUnavailableWidget()

  const { formikProps, resetFormikProps } = useFormikProps(true)

  useEffect(() => {
    if (multipleChoiceSetId) {
      dispatch(getMultipleChoiceSetAction(multipleChoiceSetId))
    }

    return () => {
      dispatch(resetMultipleChoiceSetAction())
      resetFormikProps()
      dataUnavailableWidget.reset()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // TODO LoadingOverlay `render` ではなく `children` を使う
  return (
    <>
      <LoadingOverlay
        tip={'Loading'}
        spinning={isLoading}
        size="large"
        render={
          <Layout>
            <Header>
              <HeaderBackButton
                onClick={() => history.push('/multipleChoiceSets')}
              />
              <HeaderTitle title="セット選択肢編集" />
            </Header>
            <Content>
              {dataUnavailableWidget.isVisible ? (
                <DataUnavailableWidget />
              ) : (
                <Row>
                  <Col span={20} offset={2}>
                    <FormRow>
                      <NameRow />
                      <ExcelConversionSettingsRow />
                      <OptionsRow />
                    </FormRow>
                    <FormRow>
                      <ActiveButtonGroup>
                        <ActiveButton
                          type="default"
                          onClick={() => history.push('/multipleChoiceSets')}
                        >
                          キャンセル
                        </ActiveButton>
                        <ActiveButton
                          type="primary"
                          onClick={() => formikProps.handleSubmit()}
                        >
                          保存
                        </ActiveButton>
                      </ActiveButtonGroup>
                    </FormRow>
                  </Col>
                </Row>
              )}
            </Content>
          </Layout>
        }
      />
    </>
  )
}

export default EditMultipleChoiceSetContainer
