import { ReportListFilters } from 'src/features/reports/listBetaFilters/types'
import { queryKeyPrefix } from 'src/features/tanStackQuery/queryKeyPrefix'

const prefix = queryKeyPrefix.shinReportList

export const queryKeys = {
  reportsAll: [...prefix, 'reports'],
  reports: (filters: ReportListFilters) =>
    [...prefix, 'reports', filters] as const,
}
