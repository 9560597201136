import { useCallback } from 'react'

import { useSelector } from 'react-redux'
import {
  selectTemplateId,
  selectTemplateName,
} from 'src/state/ducks/editGridVariables/selectors'
import {
  ErrorType,
  logGridLayoutSoftLimitErrorEvent,
} from '../../firebase/featureSpecificEventLog/gridLayoutSoftLimitError'

/**
 * ソフトリミット関係の firebase analytics への event log を生成する関数
 * を取得するためのカスタムフック
 *
 * @returns
 */
export const useSoftLimitErrorLog = () => {
  const templateId = useSelector(selectTemplateId)
  const templateName = useSelector(selectTemplateName)

  const logSoftLimitErrorEvent = useCallback(
    (errorType: ErrorType, errorMessage: string) => {
      logGridLayoutSoftLimitErrorEvent('patchGridVariables', {
        templateId,
        templateName,
        errorType,
        errorMessage,
      })
    },
    [templateId, templateName],
  )

  return { logSoftLimitErrorEvent }
}
