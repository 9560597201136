import {
  AttachmentFile,
  ExcelConversionFlow,
  GetExcelConversionFlowsRequest,
  GetExcelConversionFlowsResponse,
  ScheduleForExcelConversionFlow,
} from '@ulysses-inc/harami_api_client'
import { ActionTypes } from './types'

export const getExcelConversionFlows = (
  request: GetExcelConversionFlowsRequest,
) =>
  ({
    type: ActionTypes.REQUEST_GET_EXCEL_CONVERSION_FLOWS,
    isLoading: true,
    request,
  }) as const

const getSuccessExcelConversionFlows = ({
  excelConversionFlows,
  count,
}: GetExcelConversionFlowsResponse) =>
  ({
    type: ActionTypes.SUCCESS_GET_EXCEL_CONVERSION_FLOWS,
    isLoading: false,
    excelConversionFlows,
    count,
  }) as const

const getErrorExcelConversionFlows = (error: Error) =>
  ({
    type: ActionTypes.ERROR_GET_EXCEL_CONVERSION_FLOWS,
    isLoading: false,
    error,
  }) as const

export const changeExcelConversionFlowsPage = (
  page: number,
  pageSize?: number,
) =>
  ({
    type: ActionTypes.REQUEST_CHANGE_EXCEL_CONVERSION_FLOWS_PAGE,
    isLoading: true,
    request: {
      limit: pageSize,
      offset: pageSize ? (page - 1) * pageSize : 0,
    },
  }) as const

export const changeExcelConversionFlowsSize = (pageSize: number) =>
  ({
    type: ActionTypes.REQUEST_CHANGE_EXCEL_CONVERSION_FLOWS_SIZE,
    isLoading: true,
    request: {
      limit: pageSize,
      offset: 0,
    },
  }) as const

const deleteExcelConversionFlow = (flowId: string) =>
  ({
    type: ActionTypes.REQUEST_DELETE_EXCEL_CONVERSION_FLOW,
    isLoading: true,
    flowId,
  }) as const

const deleteSuccessExcelConversionFlow = (flowId: string) =>
  ({
    type: ActionTypes.SUCCESS_DELETE_EXCEL_CONVERSION_FLOW,
    isLoading: false,
    flowId,
  }) as const

const deleteErrorExcelConversionFlow = (error: Error) =>
  ({
    type: ActionTypes.ERROR_DELETE_EXCEL_CONVERSION_FLOW,
    isLoading: false,
    error,
  }) as const

export type ExcelConversionFlowsAction =
  | ReturnType<typeof getExcelConversionFlows>
  | ReturnType<typeof getSuccessExcelConversionFlows>
  | ReturnType<typeof getErrorExcelConversionFlows>
  | ReturnType<typeof changeExcelConversionFlowsPage>
  | ReturnType<typeof changeExcelConversionFlowsSize>
  | ReturnType<typeof deleteExcelConversionFlow>
  | ReturnType<typeof deleteSuccessExcelConversionFlow>
  | ReturnType<typeof deleteErrorExcelConversionFlow>

const getExcelConversionFlow = (flowId: string) =>
  ({
    type: ActionTypes.REQUEST_GET_EXCEL_CONVERSION_FLOW,
    isLoading: true,
    flowId,
  }) as const

const getSuccessExcelConversionFlow = (flow: ExcelConversionFlow) =>
  ({
    type: ActionTypes.SUCCESS_GET_EXCEL_CONVERSION_FLOW,
    isLoading: false,
    flow,
  }) as const

const getErrorExcelConversionFlow = (error: Error) =>
  ({
    type: ActionTypes.ERROR_GET_EXCEL_CONVERSION_FLOW,
    isLoading: false,
    error,
  }) as const

const addExcelConversionFlow = (flow: ExcelConversionFlow) =>
  ({
    type: ActionTypes.REQUEST_ADD_EXCEL_CONVERSION_FLOW,
    isLoading: true,
    flow,
  }) as const

const addSuccessExcelConversionFlow = () =>
  ({
    type: ActionTypes.SUCCESS_ADD_EXCEL_CONVERSION_FLOW,
    isLoading: false,
  }) as const

const addErrorExcelConversionFlow = (error: Error) =>
  ({
    type: ActionTypes.ERROR_ADD_EXCEL_CONVERSION_FLOW,
    isLoading: false,
    error,
  }) as const

const updateExcelConversionFlow = (flowId: string, flow: ExcelConversionFlow) =>
  ({
    type: ActionTypes.REQUEST_UPDATE_EXCEL_CONVERSION_FLOW,
    isLoading: true,
    flowId,
    flow,
  }) as const

const updateSuccessExcelConversionFlow = () =>
  ({
    type: ActionTypes.SUCCESS_UPDATE_EXCEL_CONVERSION_FLOW,
    isLoading: false,
  }) as const

const updateErrorExcelConversionFlow = (error: Error) =>
  ({
    type: ActionTypes.ERROR_UPDATE_EXCEL_CONVERSION_FLOW,
    isLoading: false,
    error,
  }) as const

const uploadAttachmentFile = (file: File) =>
  ({
    type: ActionTypes.REQUEST_UPLOAD_EXCEL_ATTACHMENT_FILE,
    file,
  }) as const

const addAttachmentFile = (file: AttachmentFile) =>
  ({
    type: ActionTypes.ADD_EXCEL_ATTACHMENT_FILE,
    file,
  }) as const

const getSchedules = () =>
  ({
    type: ActionTypes.REQUEST_GET_SCHEDULES,
  }) as const

const getSuccessSchedules = (schedules: ScheduleForExcelConversionFlow[]) =>
  ({
    type: ActionTypes.SUCCESS_GET_SCHEDULES,
    schedules,
  }) as const

const getErrorSchedules = (error: Error) =>
  ({
    type: ActionTypes.ERROR_GET_SCHEDULES,
    error,
  }) as const

const resetExcelConversionFlow = () =>
  ({
    type: ActionTypes.RESET_EXCEL_CONVERSION_FLOW,
  }) as const

const testExcelConversionFlow = (flow: ExcelConversionFlow) =>
  ({
    type: ActionTypes.REQUEST_TEST_EXCEL_CONVERSION_FLOW,
    flow,
  }) as const

export type ExcelConversionFlowAction =
  | ReturnType<typeof getExcelConversionFlow>
  | ReturnType<typeof getSuccessExcelConversionFlow>
  | ReturnType<typeof getErrorExcelConversionFlow>
  | ReturnType<typeof addExcelConversionFlow>
  | ReturnType<typeof addSuccessExcelConversionFlow>
  | ReturnType<typeof addErrorExcelConversionFlow>
  | ReturnType<typeof updateExcelConversionFlow>
  | ReturnType<typeof updateSuccessExcelConversionFlow>
  | ReturnType<typeof updateErrorExcelConversionFlow>
  | ReturnType<typeof uploadAttachmentFile>
  | ReturnType<typeof addAttachmentFile>
  | ReturnType<typeof getSchedules>
  | ReturnType<typeof getSuccessSchedules>
  | ReturnType<typeof getErrorSchedules>
  | ReturnType<typeof resetExcelConversionFlow>
  | ReturnType<typeof testExcelConversionFlow>

export default {
  getExcelConversionFlows,
  getSuccessExcelConversionFlows,
  getErrorExcelConversionFlows,
  changeExcelConversionFlowsPage,
  changeExcelConversionFlowsSize,
  deleteExcelConversionFlow,
  deleteSuccessExcelConversionFlow,
  deleteErrorExcelConversionFlow,
  getExcelConversionFlow,
  getSuccessExcelConversionFlow,
  getErrorExcelConversionFlow,
  addExcelConversionFlow,
  addSuccessExcelConversionFlow,
  addErrorExcelConversionFlow,
  updateExcelConversionFlow,
  updateSuccessExcelConversionFlow,
  updateErrorExcelConversionFlow,
  uploadAttachmentFile,
  addAttachmentFile,
  getSchedules,
  getSuccessSchedules,
  getErrorSchedules,
  resetExcelConversionFlow,
  testExcelConversionFlow,
}
