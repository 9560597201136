import {
  GetImproveEmailsRequest,
  PostImproveEmailRequest,
} from '@ulysses-inc/harami_api_client'
import Redux from 'redux'
import actions from './actions'

const getImproveEmailLogs = (
  dispatch: Redux.Dispatch,
  request: GetImproveEmailsRequest,
) => {
  dispatch(actions.getImproveEmailLogs(request))
}

const addImproveEmailLog = (
  dispatch: Redux.Dispatch,
  request: PostImproveEmailRequest,
) => {
  dispatch(actions.addImproveEmailLog(request, ''))
}

const changeIsShowImproveEmailLogsDrawer = (
  dispatch: Redux.Dispatch,
  isShow: boolean,
) => {
  dispatch(actions.changeIsShowImproveEmailLogsDrawer(isShow))
}

export default {
  getImproveEmailLogs,
  addImproveEmailLog,
  changeIsShowImproveEmailLogsDrawer,
}
