import { isValidDateStr } from 'src/exShared/util/date'
import { MAX_END_DATE, MIN_START_DATE } from '../constants'
import { Row, SectionsByName, Value } from '../types'

export class RowValidator {
  /** 取り込みセクションの入力可能最大文字数 */
  static VARIABLE_SECTION_MAX_CHAR_COUNT = 255
  /** 取り込み項目の入力可能最大文字数 */
  static VARIABLE_VALUE_MAX_CHAR_COUNT = 100
  /** バリデーションエラーのメッセージ */
  static ERROR_MESSAGES = {
    required: '必須項目です',
    invalidDateFormat: '正しい日付の形式で入力してください(例: 年/月/日)',
    fromDateGreaterThanThroughDate:
      '適用終了日は適用開始よりあとの日付にしてください',
    dateRangeError: '2023/01/01 ~ 2099/12/31の範囲内の日付にしてください',
    notExistsSectionName: '存在しないセクション名です',
    variableSectionGreaterThanMax: `${RowValidator.VARIABLE_SECTION_MAX_CHAR_COUNT}以内で入力してください`,
    variableValueGreaterThanMax: `${RowValidator.VARIABLE_VALUE_MAX_CHAR_COUNT}以内で入力してください`,
  }

  private sectionsByName: SectionsByName

  constructor(sectionsByName: SectionsByName) {
    this.sectionsByName = sectionsByName
  }

  /**
   * 引数で渡したrowデータに対しバリデーション処理を行い、
   * 各種フィールドのerrorsにバリデーションエラーをセットした新たなrowデータを返す。
   *
   * @param row
   * @returns
   */
  getValidatedRow(row: Row): Row {
    const copiedRow = { ...row }

    copiedRow.from.errors = this.validateFrom(copiedRow.from.dateValue)
    copiedRow.through.errors = this.validateThrough(
      copiedRow.from.dateValue,
      copiedRow.through.dateValue,
    )
    copiedRow.sectionName.errors = this.validateSectionName(
      copiedRow.sectionName.name,
    )
    copiedRow.variableSection.errors = this.validateVariableSection(
      copiedRow.variableSection.name,
    )

    Object.keys(copiedRow.values).forEach(variableNumber => {
      const value = copiedRow.values[Number(variableNumber)]
      if (value !== undefined) {
        value.errors = this.validateValue(value)
      }
    })

    copiedRow.hasErrors = this.hasErrors(copiedRow)

    return copiedRow
  }

  private hasErrors(row: Row): boolean {
    if (row.from.errors.length > 0) return true
    if (row.through.errors.length > 0) return true
    if (row.sectionName.errors.length > 0) return true
    if (row.variableSection.errors.length > 0) return true
    for (const value of Object.values(row.values)) {
      if (value.errors.length > 0) return true
    }

    return false
  }

  private validateFrom(fromDate: string): string[] {
    if (fromDate === '') {
      return [RowValidator.ERROR_MESSAGES.required]
    }

    if (!isValidDateStr(fromDate)) {
      return [RowValidator.ERROR_MESSAGES.invalidDateFormat]
    }

    const targetDate = new Date(fromDate)
    if (!this.inRange(targetDate)) {
      return [RowValidator.ERROR_MESSAGES.dateRangeError]
    }

    return []
  }

  private validateThrough(
    strFromDate: string,
    strThroughDate: string,
  ): string[] {
    if (strThroughDate === '') {
      return [RowValidator.ERROR_MESSAGES.required]
    }

    const throughDate = new Date(strThroughDate)
    if (!this.inRange(throughDate)) {
      return [RowValidator.ERROR_MESSAGES.dateRangeError]
    }

    if (!isValidDateStr(strThroughDate)) {
      return [RowValidator.ERROR_MESSAGES.invalidDateFormat]
    }

    if (strFromDate === '' || !isValidDateStr(strFromDate)) {
      return []
    }

    const fromDate = new Date(strFromDate)
    if (isFromDateGreaterThanThroughDate(fromDate, throughDate)) {
      return [RowValidator.ERROR_MESSAGES.fromDateGreaterThanThroughDate]
    }

    return []
  }

  // 2023/01/01~2099/12/31 の範囲外の場合はNG
  private inRange(date: Date): boolean {
    const disabledRange =
      date.getTime() < MIN_START_DATE.getTime() ||
      MAX_END_DATE.getTime() < date.getTime()

    if (disabledRange) {
      return false
    }

    return true
  }

  private validateSectionName(name: string): string[] {
    if (name === '') {
      return [RowValidator.ERROR_MESSAGES.required]
    }

    if (this.sectionsByName[name] === undefined) {
      return [RowValidator.ERROR_MESSAGES.notExistsSectionName]
    }

    return []
  }

  private validateVariableSection(value: string): string[] {
    if (
      isGreaterThanMaxCharCount(
        value,
        RowValidator.VARIABLE_SECTION_MAX_CHAR_COUNT,
      )
    ) {
      return [RowValidator.ERROR_MESSAGES.variableSectionGreaterThanMax]
    }

    return []
  }

  private validateValue(value: Value): string[] {
    if (
      isGreaterThanMaxCharCount(
        value.value,
        RowValidator.VARIABLE_VALUE_MAX_CHAR_COUNT,
      )
    ) {
      return [RowValidator.ERROR_MESSAGES.variableValueGreaterThanMax]
    }

    return []
  }
}

/**
 * 適用開始日 > 適用終了日の場合true
 *
 * @param fromDate
 * @param throughDate
 * @returns
 */
const isFromDateGreaterThanThroughDate = (
  fromDate: Date,
  throughDate: Date,
): boolean => {
  return fromDate.getTime() > throughDate.getTime()
}

/**
 * textの文字数が、maxCountを超えている場合true
 * @param text
 * @param maxCount
 * @returns
 */
const isGreaterThanMaxCharCount = (text: string, maxCount: number): boolean => {
  return Array.from(text).length > maxCount
}
