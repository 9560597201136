import { SettingOutlined } from '@ant-design/icons'
import { css } from '@emotion/react'
import {
  Improve,
  ImproveStatusStatusEnum,
  IndicatedIssueGroup,
} from '@ulysses-inc/harami_api_client'
import { Button, Layout, List, Modal, Pagination, Row, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import ExternalLinkSvg from 'src/assets/icons/external_link2.svg?react'
import { Header } from 'src/components/header/Header'
import { HeaderTitle } from 'src/components/header/HeaderTitle'
import { RowActionButton } from 'src/components/rowActionButton/RowActionButton'
import {
  getStatusColor,
  getStatusIconComponent,
  getStatusName,
} from 'src/constants/improveStatus'
import { localStorageKeys } from 'src/constants/localStorageKeys'
import date from 'src/exShared/util/date'
import ImprovesFilterDropDown from 'src/features/improves/improveList/ImproveFilterDropDown'
import { ImprovesProps } from 'src/features/improves/improveList/ImprovesProps'
import {
  ModalDeleteButtonColor,
  Primary,
  TableBorderColor,
  White,
} from 'src/features/theme/KdsThemeColor'
import { useListFilter } from 'src/hooks/filter/useListFilter'
import { GetImprovesFilter } from 'src/state/ducks/improves/types'
import { adjustCurrentPage } from 'src/util/adjustCurrentPage'
import styled from 'styled-components'

const { Content } = Layout
const { Text } = Typography

const ExternalLinkIcon = styled(ExternalLinkSvg)`
  font-size: 14px;
`

const ImprovesPagination = styled(Pagination)`
  margin-top: 16px;
  margin-bottom: 16px;
  display: flex;
  justify-content: center;
`

const ButtonGroupRow = styled.div`
  position: absolute;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: row;
  height: 100%;
  right: 0;
`

const TableBody = styled<any>(List)`
  width: 100%;
`

const TableRowWrap = styled(List.Item)`
  position: relative;
  min-height: 46px;
  background-color: ${White};
`

const TableRow = styled.div`
  word-break: break-all;
  width: ${props => props.theme.width};
  padding-left: 16px;
  display: flex;
  align-items: center;
`

const ImprovesHeaderContainer = styled(Row)`
  margin: 15px 0 15px 15px;
  overflow-x: auto;
  justify-content: start;
`

const ImprovesHeaderWrap = styled.div`
  width: 100%;
  min-width: 800px;
  padding-right: 15px;
`

const ImprovesHeader = styled.div`
  width: 100%;
  height: 46px;
  display: flex;
  align-items: center;
  flex-direction: row;
  background: ${White};
  border-bottom: 1px solid ${TableBorderColor};
`

const HeaderRow = styled.div`
  font-weight: bold;
  padding-left: 15px;
  width: ${props => props.theme.width};
`

const FilterRow = styled(Row)`
  margin-top: 16px;
  margin-bottom: 16px;
  margin-left: 15px;
`

const statusIcon = (
  rejectExists: boolean,
  reviseExists: boolean,
  status?: ImproveStatusStatusEnum,
) => {
  if (!status) return <></>
  const StatusIconComponent = getStatusIconComponent(status)
  const statusColor = getStatusColor(status)
  const statusName = getStatusName(status, rejectExists, reviseExists)
  return (
    <>
      <StatusIconComponent
        css={css`
          color: ${statusColor};
          font-size: 14px;
        `}
      />
      <Text style={{ color: statusColor, marginLeft: 5 }}>{statusName}</Text>
    </>
  )
}

const rejectExists = (input: IndicatedIssueGroup[]) => {
  return (input ?? []).some((group: IndicatedIssueGroup) => {
    if (!group.indicatedIssues) {
      return false
    }
    return !!group.indicatedIssues[group.indicatedIssues.length - 1]
      .rejectComment?.comment?.length
  })
}

const reviseExists = (input: IndicatedIssueGroup[]) => {
  return (input ?? []).some((group: IndicatedIssueGroup) => {
    if (!group.indicatedIssues) {
      return false
    }
    return !!group.indicatedIssues[group.indicatedIssues.length - 1].revise
      ?.comment?.length
  })
}

const ImprovesScene: React.FC<ImprovesProps> = (props: ImprovesProps) => {
  const [activeImproveId, setActiveImproveId] = useState<number>()
  const { filters, setListFilter } = useListFilter()

  useEffect(() => {
    props.getPlaces()
    props.getPlaceGroups()

    if (filters && filters.improves) {
      const improvesFilter = filters?.improves
      const limit = improvesFilter?.pagination?.limit ?? 25
      const offset = improvesFilter?.pagination?.offset ?? 0
      props.updatePagination(limit, offset)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setListFilter({
      ...(filters ?? {}),
      improves: {
        pagination: props.request,
        filter: filters?.improves?.filter,
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.request.limit, props.request.offset])

  const loginUserUUID =
    localStorage.getItem(localStorageKeys.loginUserUuid) ?? ''
  const currentPage = adjustCurrentPage(
    props.request.limit,
    props.request.offset,
    props.count,
  )

  const renderList = () => {
    return (
      <>
        <FilterRow justify="space-between">
          <ImprovesFilterDropDown
            places={props.places}
            getFilterImproves={(filter?: GetImprovesFilter) => {
              props.getImproves(props.request, filter)
            }}
            isLoadingPlace={props.isLoadingPlace}
            request={props.request}
          />
          <Link
            css={css({ marginLeft: 'auto', marginRight: 16 })}
            to="/indicatedIssueLabels"
          >
            <Button icon={<SettingOutlined />}>ラベル設定</Button>
          </Link>
        </FilterRow>
        <ImprovesHeaderContainer justify="center">
          <ImprovesHeaderWrap>
            <ImprovesHeader>
              <HeaderRow theme={{ width: '35%' }}>レポート名</HeaderRow>
              <HeaderRow theme={{ width: '25%' }}>現場名</HeaderRow>
              <HeaderRow theme={{ width: '25%' }}>実施日</HeaderRow>
              <HeaderRow theme={{ width: '15%' }}>ステータス</HeaderRow>
            </ImprovesHeader>
            <TableBody
              itemLayout="horizontal"
              dataSource={props.improves}
              renderItem={(improve: Improve) => (
                <TableRowWrap
                  onMouseEnter={() => setActiveImproveId(improve.id)}
                  onMouseLeave={() => setActiveImproveId(undefined)}
                >
                  <TableRow theme={{ width: '35%' }}>
                    {improve.reportName}
                    {improve.reportDeletedAt ? null : (
                      <Button
                        type="link"
                        icon={<ExternalLinkIcon fill={Primary} />}
                        onClick={() =>
                          props.goReportDetail({
                            reportUUID: improve.reportUUID,
                          })
                        }
                      ></Button>
                    )}
                  </TableRow>
                  <TableRow theme={{ width: '25%' }}>
                    {improve.place?.name}
                  </TableRow>
                  <TableRow theme={{ width: '25%' }}>
                    {date.formatYYYYMMDD(improve.reportCreatedAt)}
                  </TableRow>
                  <TableRow theme={{ width: '15%' }}>
                    {statusIcon(
                      rejectExists(
                        improve.indicatedIssueGroups as IndicatedIssueGroup[],
                      ),
                      reviseExists(
                        improve.indicatedIssueGroups as IndicatedIssueGroup[],
                      ),
                      improve.status?.status,
                    )}
                  </TableRow>
                  {activeImproveId === improve.id && (
                    <ButtonGroupRow>
                      <RowActionButton
                        onClick={() => props.goImproveDetail(improve.uuid)}
                        type="viewDetail"
                      />
                      {improve.auditUser?.uuid === loginUserUUID && (
                        <RowActionButton
                          onClick={() => {
                            Modal.confirm({
                              onOk: () => props.deleteImprove(improve.uuid),
                              title: `「${improve.reportName}」の削除を実行しますがよろしいですか？`,
                              okText: '削除',
                              cancelText: 'キャンセル',
                              okButtonProps: {
                                style: {
                                  backgroundColor: ModalDeleteButtonColor,
                                  border: 'none',
                                },
                              },
                            })
                          }}
                          type="delete"
                        />
                      )}
                    </ButtonGroupRow>
                  )}
                </TableRowWrap>
              )}
            />
          </ImprovesHeaderWrap>
        </ImprovesHeaderContainer>
      </>
    )
  }

  return (
    <Layout>
      <Header>
        <HeaderTitle title="カイゼン" />
      </Header>
      <Content>{renderList()}</Content>
      <ImprovesPagination
        showSizeChanger
        current={currentPage}
        pageSizeOptions={['10', '25', '50', '100']}
        pageSize={props.request.limit ?? 25}
        defaultCurrent={1}
        total={props.count}
        onChange={(pageNum, pageSize) =>
          props.changeImprovesPage(pageNum, pageSize, props.filter)
        }
        // page, sizeを変更したらonChangeが呼ばれるため、onShowSizeChangeは使わない。
      />
    </Layout>
  )
}

export default ImprovesScene
