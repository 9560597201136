import {
  GetImproveEmailsRequest,
  ImproveEmail,
  PostImproveEmailRequest,
} from '@ulysses-inc/harami_api_client'
import { ActionTypes } from './types'

const getImproveEmailLogs = (request: GetImproveEmailsRequest) => {
  return {
    type: ActionTypes.REQUEST_GET_IMPROVE_EMAIL_LOGS,
    isLoading: true,
    request: request,
  }
}

const getSuccessImproveEmailLogs = (improveEmails: ImproveEmail[]) => {
  return {
    type: ActionTypes.SUCCESS_GET_IMPROVE_EMAIL_LOGS,
    isLoading: false,
    improveEmails: improveEmails,
  }
}

const getErrorImproveEmailLogs = (error: Error) => {
  return {
    type: ActionTypes.ERROR_GET_IMPROVE_EMAIL_LOGS,
    isLoading: false,
    error,
  }
}

const addImproveEmailLog = (
  request: PostImproveEmailRequest,
  message: string,
) => {
  return {
    type: ActionTypes.REQUEST_ADD_IMPROVE_EMAIL_LOG,
    isLoading: true,
    request: request,
    message: message,
  }
}

const addSuccessImproveEmailLog = () => {
  return {
    type: ActionTypes.SUCCESS_ADD_IMPROVE_EMAIL_LOG,
    isLoading: false,
  }
}

const addErrorImproveEmailLog = (error: Error) => {
  return {
    type: ActionTypes.ERROR_ADD_IMPROVE_EMAIL_LOG,
    isLoading: false,
    error,
  }
}

const changeIsShowImproveEmailLogsDrawer = (isShow: boolean) => {
  return {
    type: ActionTypes.CHANGE_IS_SHOW_IMPROVE_EMAIL_LOGS_DRAWER,
    isShow,
  }
}

export type ImproveEmailLogsAction =
  | ReturnType<typeof getImproveEmailLogs>
  | ReturnType<typeof getSuccessImproveEmailLogs>
  | ReturnType<typeof getErrorImproveEmailLogs>
  | ReturnType<typeof addImproveEmailLog>
  | ReturnType<typeof addSuccessImproveEmailLog>
  | ReturnType<typeof addErrorImproveEmailLog>
  | ReturnType<typeof changeIsShowImproveEmailLogsDrawer>

export default {
  getImproveEmailLogs,
  getSuccessImproveEmailLogs,
  getErrorImproveEmailLogs,
  addImproveEmailLog,
  addSuccessImproveEmailLog,
  addErrorImproveEmailLog,
  changeIsShowImproveEmailLogsDrawer,
}
