import { DownOutlined, UpOutlined } from '@ant-design/icons'
import { css } from '@emotion/react'
import { PlaceNode, ReportStatusEnum } from '@ulysses-inc/harami_api_client'
import {
  Button,
  Checkbox,
  Dropdown,
  Input,
  Select,
  Switch,
  Typography,
} from 'antd'
import moment from 'moment' // TODO: momentと date-fns がプロジェクト内で混在しているため、どちらかに統一する必要がある
import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { MomentDatePicker } from 'src/components/datepicker/MomentDatePicker'
import { getStatusName } from 'src/constants/reportStatus'
import useDebounceValue from 'src/exShared/hooks/useDebounceValue'
import {
  initializePlaceFilter,
  splitAccessiblePlaceNode,
} from 'src/exShared/libraries/filterDropDown/place'
import { splitLoginPlaceUUIDs } from 'src/exShared/util/place/splitLoginPlace'
import {
  getReportFilter,
  useReportsListFilter,
} from 'src/hooks/filter/useReportsFilter'
import { GetReportsFilter } from 'src/state/ducks/reports/types'
import { RootState } from 'src/state/store'
import { ReportsFilter } from 'src/views/types/utils/ListFilter'
import styled from 'styled-components'
import { BorderColor, White } from '../theme/KdsThemeColor'
import { flattenNodes } from './Reports'

const { Text } = Typography
const { Option } = Select

const TableCell = styled.div`
  display: table-cell;
  border: 1px solid ${BorderColor};
  background: ${White};
  vertical-align: middle;
`
const FilterButton = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  cursor: pointer;
`

const UpOutlinedIcon = styled(UpOutlined)`
  color: rgba(0, 0, 0, 0.65);
`

const DownOutlinedIcon = styled(DownOutlined)`
  color: rgba(0, 0, 0, 0.65);
`

const SelectFilterTableCell = styled(TableCell)`
  min-width: ${({ theme }) => theme.minWidth};
  background-color: ${({ theme }) => theme.backgroundColor};
  height: 32px;
`

const DropDownTableCell = styled(TableCell)`
  width: 400px;
  background-color: ${White};
  padding: 16px;
`

const styles = {
  clearButtonRow: css`
    display: flex;
    justify-content: flex-end;
  `,
}

interface OwnProps {
  visibleStatusFilter: boolean
  visibleEmployeeFilter: boolean
  scene: 'reports'
  reportGetTrigger?: boolean
  activePanelIndex?: number
  getReports: (filter?: GetReportsFilter) => void
}

const ReportStatusOptions: { label: string; value: number }[] = [
  {
    label: getStatusName(ReportStatusEnum.INCOMPLETE),
    value: ReportStatusEnum.INCOMPLETE,
  },
  {
    label: getStatusName(ReportStatusEnum.APPROVAL_PENDING),
    value: ReportStatusEnum.APPROVAL_PENDING,
  },
  {
    label: getStatusName(ReportStatusEnum.APPROVAL_REMAND),
    value: ReportStatusEnum.APPROVAL_REMAND,
  },
  {
    label: getStatusName(ReportStatusEnum.COMPLETE),
    value: ReportStatusEnum.COMPLETE,
  },
]

const ReportsFilterDropDown: React.FC<OwnProps> = (props: OwnProps) => {
  const places = useSelector(
    (state: RootState) => state.placesState.places.places,
  )
  const placeNodes = useSelector((state: RootState) =>
    flattenNodes(state.placesState.placeGroups.nodes),
  )
  const isLoadingPlace = useSelector(
    (state: RootState) =>
      state.placesState.places.isLoading ||
      state.placesState.placeGroups.isLoading,
  )

  const [isOpen, setIsOpen] = useState(false)
  const [reportName, setReportName] = useState<string>('')
  const debouncedReportName = useDebounceValue(reportName, 300)
  const [templateIds, setTemplateIds] = useState<number[]>([])
  const [isInvalid, setIsInvalid] = useState<number>(0)
  const [statuses, setStatuses] = useState<number[]>([])
  const [from, setFrom] = useState<string | undefined>(undefined)
  const [through, setThrough] = useState<string | undefined>(undefined)
  const [placeNodeIds, setPlaceNodeIds] = useState<string[]>([])
  const [placeGroupNodeIds, setPlaceGroupNodeIds] = useState<string[]>([])
  const [employee, setEmployee] = useState<string>('')
  const { reportsRequest, saveReportsRequest } = useReportsListFilter()
  const { loginPlaceGroupIds, loginPlaceIds } = splitLoginPlaceUUIDs()

  useEffect(() => {
    if (!props.reportGetTrigger || isLoadingPlace) {
      return
    }
    if (!reportsRequest) {
      const placeFilter = initializePlaceFilter(placeNodes)
      if (placeFilter === undefined || placeFilter.$in.length === 0) {
        props.getReports()
        setPlaceNodeIds(loginPlaceIds)
        setPlaceGroupNodeIds(loginPlaceGroupIds)
        return
      }
      const filter = { placeNodeId: placeFilter }
      props.getReports(filter)
      setPlaceNodeIds(loginPlaceIds)
      setPlaceGroupNodeIds(loginPlaceGroupIds)
      return
    }

    const reportsFilter = reportsRequest.filter
    let filterPlaceNodeIds: string[] = []
    let filterPlaceGroupNodeIds: string[] = []
    if (
      (reportsFilter?.placeNodeIds ?? []).length > 0 ||
      (reportsFilter?.placeGroupNodeIds ?? []).length > 0
    ) {
      filterPlaceNodeIds = reportsFilter?.placeNodeIds ?? []
      filterPlaceGroupNodeIds = reportsFilter?.placeGroupNodeIds ?? []
    } else {
      filterPlaceNodeIds = loginPlaceIds
      filterPlaceGroupNodeIds = loginPlaceGroupIds
    }
    setPlaceNodeIds(filterPlaceNodeIds)
    setPlaceGroupNodeIds(filterPlaceGroupNodeIds)
    setReportName(reportsFilter?.reportName ?? '')
    setTemplateIds(reportsFilter?.templateIds ?? [])
    setIsInvalid(reportsFilter?.isInvalid ?? 0)
    setFrom(reportsFilter?.from)
    setThrough(reportsFilter?.through)
    setStatuses(reportsFilter?.statuses ?? [])
    setEmployee(reportsFilter?.employee ?? '')
    const filter = getFilter({
      reportName: reportsFilter?.reportName,
      templateIds: reportsFilter?.templateIds,
      placeNodeIds: filterPlaceNodeIds,
      placeGroupNodeIds: filterPlaceGroupNodeIds,
      from: reportsFilter?.from,
      through: reportsFilter?.through,
      statuses: reportsFilter?.statuses,
      isInvalid: reportsFilter?.isInvalid,
      employee: reportsFilter?.employee,
    })
    props.getReports(filter)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [placeNodes.length, isLoadingPlace])

  const isFilterOn = () => {
    return (
      reportName ||
      templateIds.length > 0 ||
      placeGroupNodeIds.length > 0 ||
      placeNodeIds.length > 0 ||
      from ||
      through ||
      isInvalid === 1 ||
      (props.visibleStatusFilter && statuses.length > 0) ||
      (props.visibleEmployeeFilter && employee.length > 0)
    )
  }

  const clearFilter = () => {
    const conditions = {
      placeNodeIds: loginPlaceIds,
      placeGroupNodeIds: loginPlaceGroupIds,
    }
    const filter = getFilter(conditions)
    props.getReports(filter)
    setPlaceNodeIds([])
    setPlaceGroupNodeIds([])
    setReportName('')
    setTemplateIds([])
    setIsInvalid(0)
    setStatuses([])
    setFrom(undefined)
    setThrough(undefined)
    setEmployee('')
    saveFilter(conditions)
  }

  const saveFilter = (conditions: ReportsFilter) => {
    saveReportsRequest(conditions)
  }

  const getFilter = (conditions: ReportsFilter): GetReportsFilter => {
    saveFilter(conditions)
    return getReportFilter(
      conditions,
      placeNodes,
      props.visibleStatusFilter,
      props.visibleEmployeeFilter,
    )
  }

  const dropdownRef = useRef<HTMLDivElement>(null)

  const accessiblePlaceNode = splitAccessiblePlaceNode(placeNodes, places)

  useEffect(() => {
    // debouncedReportNameが空文字のとき（初回表示や、画面リロード）に呼ばれてしまい、
    // 現場未設定で表示されてしまうのを防ぐため、フィルター画面が表示されていない場合は処理しない
    // ※現場指定あり、なしの2リクエストが発火され、現場指定なしの処理が最後になると全件出てしまう
    if (!isOpen) {
      return
    }

    const filterPlaceNodeIds =
      placeNodeIds.length > 0 || placeGroupNodeIds.length > 0
        ? placeNodeIds
        : loginPlaceIds
    const filterPlaceGroupNodeIds =
      placeNodeIds.length > 0 || placeGroupNodeIds.length > 0
        ? placeGroupNodeIds
        : loginPlaceGroupIds
    const conditions: ReportsFilter = {
      reportName: debouncedReportName,
      templateIds,
      placeNodeIds: filterPlaceNodeIds,
      placeGroupNodeIds: filterPlaceGroupNodeIds,
      from,
      through,
      statuses,
      isInvalid,
      employee,
    }
    const filter = getFilter(conditions)
    props.getReports(filter)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedReportName])

  const groundArea = (
    <SelectFilterTableCell theme={{ width: '160px' }}>
      <FilterButton>
        <div style={{ flex: 1, marginLeft: 12 }}>
          フィルター：{isFilterOn() ? 'ON' : 'OFF'}
        </div>
        <div style={{ minWidth: 20, marginRight: 12 }}>
          {isOpen ? <UpOutlinedIcon /> : <DownOutlinedIcon />}
        </div>
      </FilterButton>
    </SelectFilterTableCell>
  )

  const hoverArea = (
    <div style={{ display: 'flex' }} ref={dropdownRef}>
      <DropDownTableCell>
        <div css={styles.clearButtonRow}>
          <Button onClick={clearFilter}>クリア</Button>
        </div>

        {props.visibleStatusFilter && (
          <>
            <div style={{ marginBottom: 5 }}>
              <Text style={{ marginLeft: 3 }}>レポート名</Text>
            </div>
            <Input
              allowClear
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                const newValue = event.target.value
                setReportName(newValue)
              }}
              defaultValue={reportName}
              value={reportName}
            />
          </>
        )}
        <>
          <div style={{ marginTop: 16, marginBottom: 5 }}>
            <Text>逸脱有無</Text>
          </div>
          <Switch
            checked={isInvalid === 1}
            onChange={(checked: boolean) => {
              const isInvalid = checked ? 1 : 0
              setIsInvalid(isInvalid)
              const filterPlaceNodeIds =
                placeNodeIds.length > 0 || placeGroupNodeIds.length > 0
                  ? placeNodeIds
                  : loginPlaceIds
              const filterPlaceGroupNodeIds =
                placeNodeIds.length > 0 || placeGroupNodeIds.length > 0
                  ? placeGroupNodeIds
                  : loginPlaceGroupIds
              const filter = getFilter({
                reportName,
                templateIds,
                placeNodeIds: filterPlaceNodeIds,
                placeGroupNodeIds: filterPlaceGroupNodeIds,
                from,
                through,
                statuses,
                isInvalid,
                employee,
              })
              props.getReports(filter)
            }}
          />
        </>
        {props.visibleStatusFilter && (
          <>
            <div style={{ marginTop: 16, marginBottom: 5 }}>
              <Text style={{ marginLeft: 3 }}>ステータス</Text>
            </div>
            <Checkbox.Group
              options={ReportStatusOptions}
              value={statuses}
              onChange={checkedValue => {
                const selectedStatuses = checkedValue as number[]
                setStatuses(selectedStatuses)
                const filterPlaceNodeIds =
                  placeNodeIds.length > 0 || placeGroupNodeIds.length > 0
                    ? placeNodeIds
                    : loginPlaceIds
                const filterPlaceGroupNodeIds =
                  placeNodeIds.length > 0 || placeGroupNodeIds.length > 0
                    ? placeGroupNodeIds
                    : loginPlaceGroupIds
                const filter = getFilter({
                  reportName,
                  templateIds,
                  placeNodeIds: filterPlaceNodeIds,
                  placeGroupNodeIds: filterPlaceGroupNodeIds,
                  from,
                  through,
                  statuses: selectedStatuses || [],
                  isInvalid,
                  employee,
                })
                props.getReports(filter)
              }}
            />
          </>
        )}
        <div style={{ marginTop: 16, marginBottom: 5 }}>
          <Text style={{ marginLeft: 3 }}>現場グループ名</Text>
        </div>
        <Select
          mode="multiple"
          style={{ width: '100%' }}
          value={placeGroupNodeIds}
          optionFilterProp="label"
          onChange={(selectedPlaceGroupIds: string[] | undefined) => {
            setPlaceGroupNodeIds(selectedPlaceGroupIds || [])
            const filterPlaceNodeIds =
              placeNodeIds.length > 0 ||
              (selectedPlaceGroupIds ?? []).length > 0
                ? placeNodeIds
                : loginPlaceIds
            const filterPlaceGroupNodeIds =
              placeNodeIds.length > 0 ||
              (selectedPlaceGroupIds ?? []).length > 0
                ? selectedPlaceGroupIds
                : loginPlaceGroupIds
            const filter = getFilter({
              reportName,
              templateIds,
              placeNodeIds: filterPlaceNodeIds,
              placeGroupNodeIds: filterPlaceGroupNodeIds || [],
              from,
              through,
              statuses,
              isInvalid,
              employee,
            })
            props.getReports(filter)
          }}
        >
          {accessiblePlaceNode.accessiblePlaceGroups.map(
            (placeNode: PlaceNode, index: number) => (
              <Option
                key={index}
                value={placeNode.uuid}
                label={placeNode.placeGroup?.name}
              >
                {placeNode.placeGroup?.name}
              </Option>
            ),
          )}
        </Select>
        <div style={{ marginTop: 16, marginBottom: 5 }}>
          <Text style={{ marginLeft: 3 }}>現場名</Text>
        </div>
        <Select
          mode="multiple"
          style={{ width: '100%' }}
          value={placeNodeIds}
          optionFilterProp="label"
          onChange={(selectedPlaceNodeIds: string[] | undefined) => {
            setPlaceNodeIds(selectedPlaceNodeIds || [])
            const filterPlaceNodeIds =
              (selectedPlaceNodeIds ?? []).length > 0 ||
              placeGroupNodeIds.length > 0
                ? selectedPlaceNodeIds
                : loginPlaceIds
            const filterPlaceGroupNodeIds =
              (selectedPlaceNodeIds ?? []).length > 0 ||
              placeGroupNodeIds.length > 0
                ? placeGroupNodeIds
                : loginPlaceGroupIds
            const filter = getFilter({
              reportName,
              templateIds,
              placeNodeIds: filterPlaceNodeIds || [],
              placeGroupNodeIds: filterPlaceGroupNodeIds,
              from,
              through,
              statuses,
              isInvalid,
              employee,
            })
            props.getReports(filter)
          }}
        >
          {accessiblePlaceNode.accessiblePlaces.map(
            (placeNode: PlaceNode, index: number) => (
              <Option
                key={index}
                value={placeNode.uuid}
                label={placeNode.place?.name}
              >
                {placeNode.place?.name}
              </Option>
            ),
          )}
        </Select>
        <div style={{ marginTop: 16, marginBottom: 5 }}>
          <Text style={{ marginLeft: 3 }}>実施日</Text>
        </div>
        <MomentDatePicker.RangePicker
          placeholder={['開始日', '終了日']}
          value={
            from && through ? [moment(from), moment(through)] : [null, null]
          }
          onChange={(_, dateStrings: [string, string]) => {
            if (
              dateStrings === undefined ||
              dateStrings[0] === undefined ||
              dateStrings[1] === undefined
            ) {
              setFrom(undefined)
              setThrough(undefined)
              const filterPlaceNodeIds =
                placeNodeIds.length > 0 || placeGroupNodeIds.length > 0
                  ? placeNodeIds
                  : loginPlaceIds
              const filterPlaceGroupNodeIds =
                placeNodeIds.length > 0 || placeGroupNodeIds.length > 0
                  ? placeGroupNodeIds
                  : loginPlaceGroupIds
              const filter = getFilter({
                reportName,
                templateIds,
                placeNodeIds: filterPlaceNodeIds,
                placeGroupNodeIds: filterPlaceGroupNodeIds,
                from: undefined,
                through: undefined,
                statuses,
                isInvalid,
                employee,
              })
              props.getReports(filter)
            } else {
              setFrom(dateStrings[0])
              setThrough(dateStrings[1])
              const filterPlaceNodeIds =
                placeNodeIds.length > 0 || placeGroupNodeIds.length > 0
                  ? placeNodeIds
                  : loginPlaceIds
              const filterPlaceGroupNodeIds =
                placeNodeIds.length > 0 || placeGroupNodeIds.length > 0
                  ? placeGroupNodeIds
                  : loginPlaceGroupIds
              const filter = getFilter({
                reportName,
                templateIds,
                placeNodeIds: filterPlaceNodeIds,
                placeGroupNodeIds: filterPlaceGroupNodeIds,
                from: dateStrings[0],
                through: dateStrings[1],
                statuses,
                isInvalid,
                employee,
              })
              props.getReports(filter)
            }
          }}
        />
        {props.visibleStatusFilter && (
          <>
            <div style={{ marginTop: 16, marginBottom: 5 }}>
              <Text style={{ marginLeft: 3 }}>従業員</Text>
            </div>
            <Input
              placeholder="従業員名 または 従業員番号"
              allowClear
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                const newValue = event.target.value
                setEmployee(newValue)
                const filterPlaceNodeIds =
                  placeNodeIds.length > 0 || placeGroupNodeIds.length > 0
                    ? placeNodeIds
                    : loginPlaceIds
                const filterPlaceGroupNodeIds =
                  placeNodeIds.length > 0 || placeGroupNodeIds.length > 0
                    ? placeGroupNodeIds
                    : loginPlaceGroupIds
                const filter = getFilter({
                  reportName,
                  templateIds,
                  placeNodeIds: filterPlaceNodeIds,
                  placeGroupNodeIds: filterPlaceGroupNodeIds,
                  from,
                  through,
                  statuses,
                  isInvalid,
                  employee: newValue,
                })
                props.getReports(filter)
              }}
              defaultValue={employee}
              value={employee}
            />
          </>
        )}
      </DropDownTableCell>
    </div>
  )

  return (
    <Dropdown
      dropdownRender={() => hoverArea}
      trigger={['click']}
      open={isOpen}
      onOpenChange={setIsOpen}
    >
      {groundArea}
    </Dropdown>
  )
}

export default ReportsFilterDropDown
