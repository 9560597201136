import { TemplateNodeSchema } from '@ulysses-inc/harami_api_client'
import { EditHintAction } from 'src/state/ducks/templates/editHint/actions'
import { ActionTypes } from 'src/state/ducks/templates/editHint/types'

interface EditHintState {
  questionNode?: TemplateNodeSchema
  hintId: string
  blocks: Array<any> | null // eslint-disable-line @typescript-eslint/no-explicit-any
  isOpenModal: boolean
}

const initialEditHintState: EditHintState = {
  hintId: '',
  blocks: null,
  isOpenModal: false,
}

export const editHintReducer = (
  state: EditHintState = initialEditHintState,
  action: EditHintAction,
) => {
  switch (action.type) {
    case ActionTypes.SHOW_EDIT_HINT: {
      return {
        ...state,
        isOpenModal: true,
        questionNode: action.node,
        hintId: action.hintId,
        blocks: action.blocks,
      }
    }
    case ActionTypes.CLOSE_EDIT_HINT: {
      return {
        ...state,
        hintId: '',
        blocks: null,
        isOpenModal: false,
      }
    }
    default: {
      return { ...state }
    }
  }
}
