import { StyleSheet, Text } from '@react-pdf/renderer'
import { ReportNodeSchema } from '@ulysses-inc/harami_api_client'
import React from 'react'
import getCalculateScorePercentage from 'src/exShared/hooks/reports/getCalculateScorePercentage'
import { getDescendantNodes } from 'src/exShared/util/nodes/getDescendantNodes'
import BreakText from '../BreakText'
import Col from '../Col'
import Grid from '../Grid'
import { ScoringProps } from './Scoring'

const styles = StyleSheet.create({
  title: {
    fontSize: 14,
  },
  textPadding: {
    padding: 5,
  },
  textAlignRight: {
    textAlign: 'right',
  },
})

interface OwnProps {
  index: number
  sectionNode: ReportNodeSchema
}
const ScoringRow: React.FC<ScoringProps & OwnProps> = (
  props: ScoringProps & OwnProps,
) => {
  const { dictOfNode: sectionChildNodes } = getDescendantNodes(
    props.sectionNode.nodes ?? [],
    props.reportResult.nodes,
    { ignoreRepeatOriginalSection: true },
  )
  const { calculateScorePercentage } =
    getCalculateScorePercentage(sectionChildNodes)

  return (
    <Grid key={props.index} borderBottom style={{ width: '100%' }}>
      <Col borderRight style={{ width: '50%' }}>
        <BreakText text={props.sectionNode.section!.name ?? ''} />
      </Col>
      <Col borderRight style={{ width: '50%' }}>
        <Text style={{ ...styles.textPadding, ...styles.textAlignRight }}>
          {calculateScorePercentage}%
        </Text>
      </Col>
    </Grid>
  )
}
export default ScoringRow
