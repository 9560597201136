import { css } from '@emotion/react'
import { Text } from 'src/components/nativeCompat/Text'
import { View } from 'src/components/nativeCompat/View'
import getCalculateScorePercentage from 'src/exShared/hooks/reports/getCalculateScorePercentage'
import { getDescendantNodes } from 'src/exShared/util/nodes/getDescendantNodes'
import { getSectionNodes } from 'src/exShared/util/nodes/getSectionNodes'
import { getTopLevelSectionNodeIds } from 'src/exShared/util/nodes/getTopLevelSectionNodeIds'
import { WebReportResultProps } from 'src/features/reports/result/WebReportResultProps'
import { White } from 'src/features/theme/KdsThemeColor'

const styles = {
  title: css`
    font-size: 20px;
    font-weight: bold;
  `,
  listItem: css`
    border-bottom: 0.5px solid rgb(201, 201, 201);
    display: flex;
    justify-content: space-between;
    margin: 0;
    padding: 15px 18px 15px 0;
  `,
  listItemTitle: css`
    padding: 0 18px 15px 0;
  `,
  section: css`
    flex: 1;
    flex-direction: column;
    width: 100%;
    margin-top: 16px;
  `,
  card: css`
    background-color: ${White};
    border-radius: 8px;
    border-width: 0.5px;
    border-color: #d6d7da;
    padding: 16px;
  `,
}

export const Scoring = (props: WebReportResultProps) => {
  const topLevelSectionNodeIds = getTopLevelSectionNodeIds(props.report)
  const sections = getSectionNodes(props.report.nodes, topLevelSectionNodeIds)

  return (
    <View id="Scoring" css={styles.section}>
      <div css={styles.title}>配点</div>
      <View css={styles.card}>
        <div css={[styles.listItem, styles.listItemTitle]}>
          <Text style={{ fontWeight: 'bold' }}>項目</Text>
          <Text style={{ fontWeight: 'bold' }}>今回(%)</Text>
        </div>
        {sections.map((sectionNode, index) => {
          const { dictOfNode: sectionChildNodes } = getDescendantNodes(
            sectionNode.nodes ?? [],
            props.report.nodes,
            { ignoreRepeatOriginalSection: true },
          )
          const { calculateScorePercentage } =
            getCalculateScorePercentage(sectionChildNodes)
          return (
            <div key={index} css={styles.listItem}>
              <Text>{sectionNode.section!.name}</Text>
              <Text>{calculateScorePercentage}</Text>
            </div>
          )
        })}
      </View>
    </View>
  )
}
