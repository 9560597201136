import { StyleSheet, View } from '@react-pdf/renderer'
import { Image, TextMemo } from '@ulysses-inc/harami_api_client'
import React from 'react'
import Grid from '../../Grid'
import InformationImages from './InformationImages'
import OptionImages from './OptionImages'
import OptionManualMemo from './OptionManualMemo'
import OptionTextMemo from './OptionTextMemo'

const styles = StyleSheet.create({
  tableRow: {
    margin: 'auto',
    width: '100%',
    flexDirection: 'row',
  },
  questionOptionWrap: {
    width: '100%',
    borderBottom: 0.5,
  },
})

interface OwnProps {
  images?: Array<Image>
  textMemos?: Array<TextMemo>
  manualMemos?: Array<Image>
  informationImages?: Array<Image>
}

const ReportSummaryQuestionOptions: React.FC<OwnProps> = (props: OwnProps) => {
  return (
    <View style={styles.questionOptionWrap}>
      <View style={styles.tableRow}>
        <Grid>
          {props.informationImages !== undefined &&
            props.informationImages.length > 0 && (
              <InformationImages images={props.informationImages} />
            )}
        </Grid>
      </View>
      <View style={styles.tableRow}>
        <Grid>
          {props.images !== undefined && props.images.length > 0 && (
            <OptionImages images={props.images} />
          )}
        </Grid>
      </View>
      <View style={styles.tableRow}>
        <Grid>
          {props.manualMemos !== undefined &&
            props.manualMemos.length > 0 &&
            props.manualMemos[0] && (
              <OptionManualMemo memo={props.manualMemos[0]} />
            )}
        </Grid>
      </View>
      <View style={styles.tableRow}>
        <Grid>
          {props.textMemos !== undefined &&
            props.textMemos.length > 0 &&
            props.textMemos[0] &&
            props.textMemos[0].name !== '' && (
              <OptionTextMemo memo={props.textMemos[0].name ?? ''} />
            )}
        </Grid>
      </View>
    </View>
  )
}

export default ReportSummaryQuestionOptions
