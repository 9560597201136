import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectAllCell } from 'src/state/ducks/editGridVariables/actions'
import { RootState } from 'src/state/store'

/**
 * キーボードの Ctrl+A (Cmd+A) で全選択する機能を提供するフック
 */
export const useSelectAllHandler = () => {
  const isInEdit = useSelector(
    (state: RootState) =>
      state.editGridVariablesState.editGridVariables.mode === 'edit',
  )

  const dispatch = useDispatch()

  const handleAllSelect = useCallback(
    (e: KeyboardEvent) => {
      // edit モードのときは何もしない(セル内のテキストを全選択する機能が優先される)
      if (isInEdit) {
        return
      }

      // Ctrl+A または Cmd+A でなければ何もしない
      if (!(e.ctrlKey || e.metaKey) || e.key !== 'a') {
        return
      }

      e.preventDefault()
      dispatch(selectAllCell())
    },
    [dispatch, isInEdit],
  )

  useEffect(() => {
    document.addEventListener('keydown', handleAllSelect)

    // WARN: 以下のように明示的にイベントハンドラーを解除しなければならない
    return () => {
      document.removeEventListener('keydown', handleAllSelect)
    }
  }, [handleAllSelect])
}
