import { ClientTypeEnum } from '@ulysses-inc/harami_api_client'
import imageCompression from 'browser-image-compression'
import { getBasePath } from 'src/config'
import { getApiKey } from '../../middleware/saga/getApiKey'

const basePath = getBasePath()

/**
 * File形式の画像群からファイルのアップロードを行う
 * NOTE: webでのファイルアップロードは画面から選択された画像に対してFile形式の画像群を
 * 選んでアップロードを行う。
 * この時にブラウザで動的に「content-type:multipart/form-data」を指定して変換をかけるため
 * 意図的にcontent-typeの指定を削除している
 *
 * @param files File[] File形式の画像群
 *
 * TODO return type
 */
export const uploadImagesRequestByFiles = async (files: File[]) => {
  const formData = new FormData()
  files.forEach((file: File) => {
    formData.append('files[]', file)
  })
  const options: RequestInit = {
    method: 'POST',
    body: formData,
    credentials: 'include',
    headers: {
      'X-API-KEY': getApiKey(),
      ct: `${ClientTypeEnum.WEB}`,
      locationHref: `${location.href}`,
    },
  }
  return fetch(`${basePath}/images`, options)
    .catch(e => {
      throw Error(e)
    })
    .then(handleCriticalErrors)
    .then(uploadImage => uploadImage.json())
    .then(handleRequestError)
    .catch(error => {
      throw error
    })
}

export type compressImageOption = {
  maxSizeMB: number
  useWebWorker: boolean
  initialQuality: number
}

/**
 * 画像を圧縮する
 * @param {File} file
 * @param {compressImageOption} options https://github.com/Donaldcwl/browser-image-compression#api
 *
 * @returns
 */
export const compressImage = async (
  file: File,
  options: compressImageOption = {
    maxSizeMB: 20,
    useWebWorker: true,
    initialQuality: 0.85, // https://developers.google.com/speed/docs/insights/OptimizeImages#:~:text=%E7%94%BB%E8%B3%AA%E3%81%8C%2085%20%E3%82%92%E8%B6%85%E3%81%88%E3%81%A6%E3%81%84%E3%82%8B%E5%A0%B4%E5%90%88%E3%81%AF%E3%80%8185%20%E3%81%AB%E4%B8%8B%E3%81%92%E3%81%BE%E3%81%99%E3%80%82%E7%94%BB%E8%B3%AA%E3%81%8C%2085%20%E3%82%92%E8%B6%85%E3%81%88%E3%82%8B%E3%81%A8%E7%94%BB%E5%83%8F%E3%81%AE%E5%AE%B9%E9%87%8F%E3%81%8C%E6%80%A5%E5%A2%97%E3%81%97%E3%81%BE%E3%81%99%E3%81%8C%E3%80%81%E8%A6%96%E8%A6%9A%E7%9A%84%E3%81%AA%E5%93%81%E8%B3%AA%E3%81%AF%E3%81%BB%E3%81%A8%E3%82%93%E3%81%A9%E5%90%91%E4%B8%8A%E3%81%97%E3%81%BE%E3%81%9B%E3%82%93%E3%80%82
  },
) => {
  try {
    const compressedFile = await imageCompression(file, options)
    return compressedFile
  } catch (err) {
    throw new Error(`fail to compress image: ${err}`)
  }
}

const handleCriticalErrors = (res: any) => {
  if (res.ok || res.status === 400) {
    return res
  }

  switch (res.status) {
    case 401:
      throw res
    case 500:
      throw res
    case 502:
      throw res
    case 404:
      throw res
    default:
      throw res
  }
}

const handleRequestError = (res: any) => {
  if (res.status === 400) {
    throw res
  }
  return res
}
