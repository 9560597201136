import { css } from '@emotion/react'
import { ReportExportStatusEnum } from '@ulysses-inc/harami_api_client'
import { Danger, Success, Warning } from 'src/features/theme/KdsThemeColor'
import invariant from 'tiny-invariant'
import IconCheck from '../assets/iconCheck.svg?react'
import IconProcessing from '../assets/iconProcessing.svg?react'
import IconWarning from '../assets/iconWarning.svg?react'

type Props = {
  status: ReportExportStatusEnum
}

// Jestではsvgをインポートできず、ReactComponentがundefinedになるため、スタブに差し替える
const StubIconForTesting = () => <div></div>

export const StatusIcon = ({ status }: Props) => {
  const settings = {
    [ReportExportStatusEnum.PROCESSING]: {
      Icon: IconProcessing || StubIconForTesting,
      text: '出力中',
      color: Warning,
    },
    [ReportExportStatusEnum.COMPLETED]: {
      Icon: IconCheck || StubIconForTesting,
      text: '完了',
      color: Success,
    },
    [ReportExportStatusEnum.FAILED]: {
      Icon: IconWarning || StubIconForTesting,
      text: '出力失敗',
      color: Danger,
    },
  } as const

  invariant(settings[status], 'レポート出力結果のステータスが不正です')

  const { Icon, text, color } = settings[status]
  return (
    <div
      css={[
        styles.container,
        css`
          color: ${color};
        `,
      ]}
    >
      <Icon />
      <span css={styles.text}>{text}</span>
    </div>
  )
}

const styles = {
  container: css`
    display: flex;
    align-items: center;
  `,
  text: css`
    display: block;
    margin-left: 0.5rem;
  `,
}
