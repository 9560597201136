import {
  GetScheduleListInRangeItemsV2Request,
  ScheduleListInRangeItem,
  ScheduleListItemsApi,
} from '@ulysses-inc/harami_api_client'
import { call, put, takeEvery } from 'redux-saga/effects'
import BaseClient from '../../middleware/saga/baseClient'
import {
  HTTPError,
  handleHTTPError,
} from '../../middleware/saga/handleHttpError'
import interceptionsActions from '../interceptions/actions'
import { default as actions } from './actions'
import { ActionTypes } from './types'

const baseClient = new BaseClient()

export const getScheduleListInRangeItemsV2Request = (
  req: GetScheduleListInRangeItemsV2Request,
) => {
  return baseClient
    .getApi(ScheduleListItemsApi)
    .getScheduleListInRangeItemsV2(req)
    .then(scheduleListInRangeItems => scheduleListInRangeItems)
    .catch(handleHTTPError)
}

function* getScheduleListInRangeItems(
  action: ReturnType<typeof actions.getScheduleListInRangeItems>,
) {
  try {
    const params: GetScheduleListInRangeItemsV2Request = {
      acceptLanguage: action.locale,
      scheduleListInRangeItemFilter: { ...action.request },
    }
    const scheduleListInRangeItems: ScheduleListInRangeItem[] = action.request
      .placeNodeId
      ? yield call(getScheduleListInRangeItemsV2Request, params)
      : []
    yield put(
      actions.getSuccessScheduleListInRangeItems(
        scheduleListInRangeItems,
        action.request.startDate,
        action.request.endDate,
      ),
    )
  } catch (error) {
    yield put(interceptionsActions.handleHttpError(error as HTTPError))
    yield put(actions.getErrorScheduleListInRangeItems(error as Error))
  }
}

function* getInvalidCountScheduleListInRangeItems(
  action: ReturnType<typeof actions.getInvalidCountScheduleListInRangeItems>,
) {
  try {
    const params: GetScheduleListInRangeItemsV2Request = {
      acceptLanguage: action.locale,
      scheduleListInRangeItemFilter: { ...action.request },
    }
    const scheduleListInRangeItems: ScheduleListInRangeItem[] = action.request
      .placeNodeId
      ? yield call(getScheduleListInRangeItemsV2Request, params)
      : []
    yield put(
      actions.getSuccessInvalidCountScheduleListInRangeItems(
        scheduleListInRangeItems,
        action.request.startDate,
        action.request.endDate,
      ),
    )
  } catch (error) {
    yield put(interceptionsActions.handleHttpError(error as HTTPError))
    yield put(
      actions.getErrorInvalidCountScheduleListInRangeItems(error as Error),
    )
  }
}

function* getPlaceScheduleListInRangeItems(
  action: ReturnType<typeof actions.getPlaceScheduleListInRangeItems>,
) {
  try {
    const params: GetScheduleListInRangeItemsV2Request = {
      acceptLanguage: action.locale,
      scheduleListInRangeItemFilter: { ...action.request },
    }
    const scheduleListInRangeItems: ScheduleListInRangeItem[] = yield call(
      getScheduleListInRangeItemsV2Request,
      params,
    )
    yield put(
      actions.getSuccessPlaceScheduleListInRangeItems(
        scheduleListInRangeItems,
        action.request.startDate,
      ),
    )
  } catch (error) {
    yield put(interceptionsActions.handleHttpError(error as HTTPError))
    yield put(actions.getErrorPlaceScheduleListInRangeItems(error as Error))
  }
}

function* getMonthlyPlaceScheduleListInRangeItems(
  action: ReturnType<typeof actions.getMonthlyPlaceScheduleListInRangeItems>,
) {
  try {
    const params: GetScheduleListInRangeItemsV2Request = {
      acceptLanguage: action.locale,
      scheduleListInRangeItemFilter: { ...action.request },
    }
    const scheduleListInRangeItems: ScheduleListInRangeItem[] = yield call(
      getScheduleListInRangeItemsV2Request,
      params,
    )
    yield put(
      actions.getSuccessMonthlyPlaceScheduleListInRangeItems(
        scheduleListInRangeItems,
      ),
    )
  } catch (error) {
    yield put(interceptionsActions.handleHttpError(error as HTTPError))
    yield put(
      actions.getErrorMonthlyPlaceScheduleListInRangeItems(error as Error),
    )
  }
}

const sagas = [
  takeEvery(
    ActionTypes.REQUEST_GET_SCHEDULE_LIST_IN_RANGE_ITEMS,
    getScheduleListInRangeItems,
  ),
  takeEvery(
    ActionTypes.REQUEST_GET_INVALID_COUNT_SCHEDULE_LIST_IN_RANGE_ITEMS,
    getInvalidCountScheduleListInRangeItems,
  ),

  takeEvery(
    ActionTypes.REQUEST_GET_PLACE_SCHEDULE_LIST_IN_RANGE_ITEMS,
    getPlaceScheduleListInRangeItems,
  ),
  takeEvery(
    ActionTypes.REQUEST_GET_MONTHLY_PLACE_SCHEDULE_LIST_IN_RANGE_ITEMS,
    getMonthlyPlaceScheduleListInRangeItems,
  ),
]

export default sagas
