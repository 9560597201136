import { HTMLAttributes } from 'react'
import { ClampText } from 'src/components/clampText/ClampText'
import { sumMenuItemStyle } from 'src/features/globalNavigation/components/subMenuItem/subMenuItemStyle'

type Props = {
  title: string
} & HTMLAttributes<HTMLElement>

/**
 * グローバルナビゲーションのサブメニュー内の個々のアイテム (ただのクリック可能な要素)
 */
export const SubMenuItemClickable = (props: Props) => {
  const { title, ...restProps } = props

  return (
    <div {...restProps} css={sumMenuItemStyle.container} role="button">
      <ClampText css={sumMenuItemStyle.title}>{title}</ClampText>
    </div>
  )
}
