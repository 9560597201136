import {
  AddPlaceV2Request,
  GetPlacesRequest,
  GetPlacesV2Request,
  PlaceNode,
  UpdatePlaceV2Request,
} from '@ulysses-inc/harami_api_client'
import Redux from 'redux'
import actions from './actions'
import { GetPlacesFilter } from './types'

const getPlaces = (dispatch: Redux.Dispatch, request: GetPlacesRequest) => {
  dispatch(actions.getPlaces(request))
}

const changePlacesPage = (
  dispatch: Redux.Dispatch,
  page: number,
  pageSize?: number,
) => {
  dispatch(actions.changePlacesPage(page, pageSize))
}

const changePlacesSize = (dispatch: Redux.Dispatch, pageSize: number) => {
  dispatch(actions.changePlacesSize(pageSize))
}

const getPlacesV2 = (
  dispatch: Redux.Dispatch,
  request: GetPlacesV2Request,
  filter?: GetPlacesFilter,
) => {
  dispatch(actions.getPlacesV2(request, filter))
}

const changePlacesV2Page = (
  dispatch: Redux.Dispatch,
  page: number,
  pageSize?: number,
  filter?: GetPlacesFilter,
) => {
  dispatch(actions.changePlacesV2Page(page, pageSize, filter))
}

const changePlacesV2Size = (
  dispatch: Redux.Dispatch,
  pageSize: number,
  filter?: GetPlacesFilter,
) => {
  dispatch(actions.changePlacesV2Size(pageSize, filter))
}

const addPlace = (dispatch: Redux.Dispatch, request: AddPlaceV2Request) => {
  dispatch(actions.addPlace(request))
}

const updatePlace = (
  dispatch: Redux.Dispatch,
  request: UpdatePlaceV2Request,
) => {
  dispatch(actions.updatePlace(request))
}

const deletePlace = (dispatch: Redux.Dispatch, placeId: string) => {
  dispatch(actions.deletePlace(placeId))
}

const changeUpdatedPlacesFlag = (
  dispatch: Redux.Dispatch,
  updatedPlaces: boolean,
) => {
  dispatch(actions.changeUpdatedPlacesFlag(updatedPlaces))
}

const changeIsShowEditPlaceDrawer = (
  dispatch: Redux.Dispatch,
  isShowEditPlaceDrawer: boolean,
) => {
  dispatch(actions.changeIsShowEditPlaceDrawer(isShowEditPlaceDrawer))
}

const updateActivePlaceId = (
  dispatch: Redux.Dispatch,
  activePlaceId?: string,
) => {
  dispatch(actions.updateActivePlaceId(activePlaceId))
}

const updateSegmentIndex = (dispatch: Redux.Dispatch, segmentIndex: number) => {
  dispatch(actions.updateSegmentIndex(segmentIndex))
}

const getPlaceGroups = (dispatch: Redux.Dispatch) => {
  dispatch(actions.getPlaceGroups())
}

const addPlaceGroup = (dispatch: Redux.Dispatch, node: PlaceNode) => {
  dispatch(actions.addPlaceGroup(node))
}

const updatePlaceGroup = (
  dispatch: Redux.Dispatch,
  nodeId: string,
  node: PlaceNode,
) => {
  dispatch(actions.updatePlaceGroup(nodeId, node))
}

const deletePlaceGroup = (dispatch: Redux.Dispatch, nodeId: string) => {
  dispatch(actions.deletePlaceGroup(nodeId))
}

const changeIsShowEditPlaceGroupDrawer = (
  dispatch: Redux.Dispatch,
  isShowEditPlaceGroupDrawer: boolean,
) => {
  dispatch(actions.changeIsShowEditPlaceGroupDrawer(isShowEditPlaceGroupDrawer))
}

const updateActiveNodeId = (
  dispatch: Redux.Dispatch,
  activeNodeId?: string,
  activeParentNodeId?: number,
) => {
  dispatch(actions.updateActiveNodeId(activeNodeId, activeParentNodeId))
}

const getPlaceNodes = (dispatch: Redux.Dispatch) => {
  dispatch(actions.getPlaceNodes())
}

export default {
  getPlaces,
  changePlacesPage,
  changePlacesSize,
  getPlacesV2,
  changePlacesV2Page,
  changePlacesV2Size,
  addPlace,
  updatePlace,
  deletePlace,
  changeUpdatedPlacesFlag,
  changeIsShowEditPlaceDrawer,
  updateActivePlaceId,
  updateSegmentIndex,
  getPlaceGroups,
  addPlaceGroup,
  updatePlaceGroup,
  deletePlaceGroup,
  changeIsShowEditPlaceGroupDrawer,
  updateActiveNodeId,
  getPlaceNodes,
}
