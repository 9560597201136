import {
  TemplateNodeSchema,
  TemplatePageSchema,
} from '@ulysses-inc/harami_api_client'
import { schema } from 'normalizr'

const node = new schema.Entity('nodes')
const nodes = new schema.Array(node)
node.define({ nodes })
const schemaTemplatePage = new schema.Entity('templatePages', {
  nodes: [new schema.Entity('nodes', { nodes })],
})
export const SchemaTemplatePages = new schema.Array(schemaTemplatePage)

export type TemplatePagesEntities = {
  templatePages: { [key: number]: TemplatePageSchema }
  nodes: { [key: number]: TemplateNodeSchema }
}
