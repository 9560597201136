import { css } from '@emotion/react'
import { Image } from '@ulysses-inc/harami_api_client'
import { Modal, Typography, Upload } from 'antd'
import { UploadChangeParam, UploadFile } from 'antd/lib/upload/interface'
import React, { useState } from 'react'
import { Lightbox } from 'src/components/lightbox/Lightbox'
import { getMBSize } from 'src/exShared/util/fileSize'
import { ThumbList } from 'src/features/improves/improveDetail/listItem/picturesWall/ThumbList'
import { ModalDeleteButtonColor } from 'src/features/theme/KdsThemeColor'
import { createDefaultFileList } from 'src/libraries/upload/pictures'
import { uploadImagesRequestByFiles } from 'src/state/ducks/images/sagas'
import { isDefined } from 'src/util/idDefined'

const { Text } = Typography

type Props = {
  setFieldImagesValue: (images: Image[]) => void
  addFieldImagesValue: (image: Image, index: number) => void
  images: Image[]
  disabled: boolean
  errorMessage?: React.ReactElement
}

/**
 * カイゼン一覧画面のテーブルの「指摘画像」列のセル
 */
const PicturesWall: React.FC<Props> = ({
  setFieldImagesValue,
  addFieldImagesValue,
  images,
  disabled,
  errorMessage,
}) => {
  const [previewImage, setPreviewImage] = useState('')
  const [previewVisible, setPreviewVisible] = useState(false)
  const [fileList, setFileList] = useState<
    UploadFile<NonNullable<typeof images>[number]>[]
  >(createDefaultFileList(images || []))
  const [previewSelectedIndex, setPreviewSelectedIndex] = useState(0)
  const [visiblePreviewModal, setVisiblePreviewModal] = useState(false)
  const [error, setError] = useState('')

  const handlePreview = async (file: UploadFile) => {
    setPreviewImage(file.url || '')
    setPreviewVisible(true)
  }

  const handleUploadFile = async (file: UploadFile) => {
    if (!file || !(file instanceof File)) {
      return
    }
    const [uploadImage] = (await uploadImagesRequestByFiles([file])) as Image[]
    return uploadImage
  }

  const handleImageToUploadFile = (image: Image) => {
    // TODO: uploadImagesRequestByFilesでアップロードされた画像のIDがすべて0になってしまいuid = 0で重複するので、代わりのユニークな値をいれる
    const [uploadFile] = createDefaultFileList(
      [image].map(ui => {
        ui.id = new Date().getTime()
        return ui
      }),
    )
    return uploadFile
  }

  const handleChange = async (info: UploadChangeParam) => {
    setError('')
    const newFile = info.file
    const newFileList = info.fileList

    // MARK: 最大容量は決め打ちで 10 MB に設定した
    //       https://kaminashi.atlassian.net/browse/HPB-2307?focusedCommentId=16214
    if (newFile.size && newFile.size > getMBSize(10)) {
      setError('画像ファイルの最大容量は10MBです')
      return
    }

    setFileList(newFileList)

    const uploadedImage = await handleUploadFile(newFile)
    // 何を想定しているのかわからない（検証したところ少なくとも通信失敗時ではなさそう）
    if (!uploadedImage) {
      handleRemove(newFile)
      return
    }

    const uploadedFile = handleImageToUploadFile(uploadedImage)
    const index = newFileList.findIndex(file => file.uid === newFile.uid)

    addFieldImagesValue({ uuid: uploadedImage.uuid }, index)

    setFileList(prevFileList =>
      prevFileList
        .map(thisFile => {
          if (thisFile.uid === newFile.uid) {
            return uploadedFile
          }
          return thisFile
        })
        .filter(isDefined),
    )
  }

  const handleRemove = (removingFile: UploadFile) => {
    Modal.confirm({
      onOk: () => {
        setError('')

        // このコンポーネントで保持している画像リストから削除
        const prunedFileList = fileList.filter(f => f.uid !== removingFile.uid)
        setFileList(prunedFileList)

        // 親コンポーネントのFormikが持っている画像リストから削除
        // - 本来は子が知るべきでない情報がここで必要になっているので、改善したいところ
        // - fileListから探すのではなくてimagesから探すべきでは？🤔
        const removingFileIndex = fileList.findIndex(
          f => f.uid === removingFile.uid,
        )
        const prunedImages = images?.filter(
          (_, index) => index !== removingFileIndex,
        )
        setFieldImagesValue(prunedImages)
      },
      title: '画像の削除を実行しますがよろしいですか？',
      okText: '削除',
      cancelText: 'キャンセル',
      okButtonProps: {
        style: {
          backgroundColor: ModalDeleteButtonColor,
          border: 'none',
        },
      },
    })
  }

  const previewImages = fileList
    .filter(file => file.url || file.thumbUrl)
    .map(file => file.url || file.thumbUrl || '')

  return disabled && previewImages.length == 0 ? (
    <div css={styles.noPhotosText}>未登録</div>
  ) : (
    <div css={styles.container}>
      <Upload
        type="drag"
        beforeUpload={() => false}
        multiple={true}
        showUploadList={false}
        fileList={fileList}
        disabled={disabled}
        onPreview={handlePreview}
        accept={'.png,.jpg,.jpeg'}
        onChange={handleChange}
        onRemove={handleRemove}
      >
        <div css={styles.placeholder}>
          <div
            className="ant-upload-text"
            style={{
              textAlign: 'center',
              whiteSpace: 'pre-wrap',
            }}
          >
            {'画像を\nドラッグ＆ドロップ'}
          </div>
        </div>
        <ThumbList
          fileList={fileList}
          disabled={disabled}
          handlePreview={(index: number) => {
            setPreviewSelectedIndex(index)
            setVisiblePreviewModal(true)
          }}
          handleRemove={handleRemove}
        />
      </Upload>
      <Modal
        open={previewVisible}
        footer={null}
        onCancel={() => setPreviewVisible(false)}
      >
        <img alt="example" style={{ width: '100%' }} src={previewImage} />
      </Modal>
      <Lightbox
        carousel={{ finite: true }}
        close={() => setVisiblePreviewModal(false)}
        index={previewSelectedIndex}
        open={visiblePreviewModal}
        slides={previewImages.map(image => ({ src: image }))}
      />
      {error ? (
        <Text type="danger" style={{ fontSize: '14px' }}>
          {error}
        </Text>
      ) : (
        errorMessage
      )}
    </div>
  )
}

const styles = {
  container: css`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    z-index: 1;
    > span:first-child,
    .ant-upload {
      display: block;
      width: 100%;
      height: 100%;
    }
    .ant-upload.ant-upload-drag .ant-upload {
      padding: 0;
    }
  `,
  // おそらく不要↓
  placeholder: css`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    height: 100%;
  `,
  noPhotosText: css`
    padding: 12px 0 0 8px;
    align-self: flex-start;
    color: #d9d9d9;
  `,
}

export default PicturesWall
