import { css } from '@emotion/react'
import { FC } from 'react'
import { Text } from 'src/components/nativeCompat/Text'
import { Gray } from 'src/features/theme/KdsThemeColor'

export interface PhotoSliderTimeTextProps {
  dateText: string
}

export const PhotoSliderDateText: FC<PhotoSliderTimeTextProps> = props => {
  const { dateText } = props

  return (
    <div css={styles.container}>
      <Text color={Gray} small>
        {dateText}
      </Text>
    </div>
  )
}

const styles = {
  container: css`
    align-items: center;
    display: flex;
    height: 28px;
    margin-left: 0.5rem;
  `,
}
