import {
  LogicConditionTypeEnum,
  MultipleChoice,
  NumberCondition,
  ReportNode,
  ResponseDatetimeSubTypeEnum,
  TemplateNodeSchema,
  TemplateNodeTypeEnum,
  TemplateQuestion,
} from '@ulysses-inc/harami_api_client'
import { UUID } from 'src/exShared/util/uuid'

const getDeleteNodeIds = (
  targetNodeId: number,
  templateNodes: { [key: number]: TemplateNodeSchema },
) => {
  let nodeIds: number[] = [targetNodeId]
  const targetNode = templateNodes[targetNodeId]
  if (targetNode?.nodes?.length !== 0) {
    targetNode?.nodes?.forEach(
      (nodeId: number) =>
        (nodeIds = nodeIds.concat(getDeleteNodeIds(nodeId, templateNodes))),
    )
  }
  return nodeIds
}

const createNewTemplateNode = (
  id: number,
  type: TemplateNodeTypeEnum,
): TemplateNodeSchema => {
  return {
    id: id,
    uuid: UUID(),
    type: type,
    nodes: [],
  }
}

const createNewQuestionNode = (
  id: number,
  question?: TemplateQuestion,
): TemplateNodeSchema => {
  return {
    ...createNewTemplateNode(id, TemplateNodeTypeEnum.Question),
    question: {
      ...question,
      isShowInformationImages: 0,
      informationImages: [],
      hints: [],
      name: '',
      deviateComment: '違反しています。責任者に確認してください。',
    },
  }
}

const createNewLogicNode = (
  id: number,
  conditionType: LogicConditionTypeEnum,
  responseMultipleChoices: Array<MultipleChoice>,
): TemplateNodeSchema => {
  return {
    ...createNewTemplateNode(id, TemplateNodeTypeEnum.Logic),
    logic: {
      conditionType,
      responseMultipleChoices,
    },
  }
}

const createNewNumberLogicNode = (
  id: number,
  numberCondition: NumberCondition,
): TemplateNodeSchema => {
  return {
    ...createNewTemplateNode(id, TemplateNodeTypeEnum.Logic),
    logic: {
      numberConditions: [numberCondition],
    },
  }
}

/**
 * 再帰的に子ノードの ID を更新する
 * @param children ID を更新したい子ノード
 * @param currentId 現在雛形内に存在する最大の nodeId
 * @param oldUUIDMappings コピー元の UUID とコピー後の UUID のマッピング
 * @returns 更新後の現在雛形内に存在する最大の nodeId
 */
const walkAndUpdateId = (
  children: ReportNode[],
  currentId: number,
  oldUUIDMappings: { [key: string]: string },
): number => {
  if (!children || children.length === 0) {
    return currentId
  }
  children.forEach((child: ReportNode) => {
    child.id = ++currentId
    const uuid = UUID()
    oldUUIDMappings[child.uuid] = uuid
    child.uuid = uuid
    currentId = walkAndUpdateId(child.nodes, currentId, oldUUIDMappings)
  })
  return currentId
}

/**
 * node (TemplateNodeSchema)を deepCopy した場合に、
 * コピー先では不要となるプロパティを削除する
 *
 * @param node
 */
const deleteUnnecessaryCopiedProperties = (node: TemplateNodeSchema) => {
  delete node.section?.id
  delete node.question?.id
  delete node.logic?.id
  delete node.createdAt
}

// NOTE: ページをコピーする際に実行するソフトリミットのvalidation
// コピー機能によって、パフォーマンスの影響が出ることがないようなソフトリミットを設定している。
// そのため、コピー機能ではなく手動であれば、この制限に関係なく、ひな形が作成できる。
// コピー機能をリリースするための制限である。
//
// ソフトリミットを決める上での issue はこちら
// https://kaminashi.atlassian.net/browse/HPB-3320
const MAX_COPY_PAGE = 12
const MAX_COPY_NODE = 200
export const copyPageValidate = (
  pageLength: number,
  nodeLength: number,
): { valid: boolean; error: string } => {
  if (pageLength > MAX_COPY_PAGE) {
    return {
      valid: false,
      error: `${MAX_COPY_PAGE}ページを超えてコピーすることはできません。`,
    }
  }

  if (nodeLength > MAX_COPY_NODE) {
    return {
      valid: false,
      error: `質問、条件分岐、セクションの合計が上限を超えてしまうので、コピーできません。`,
    }
  }

  return { valid: true, error: '' }
}

/**
 * WARN: この関数は、引数 templateNodes 内の時間計測ノードを破壊的に変更する
 *
 * 引数 templateNodes 内の時間計測ノードの参照先になっている開始日時質問ノードの subType を取得し、
 * それを時間計測ノードの subType に設定する
 *
 * 参照先の開始質問と終了質問の subType が一致していることを前提としているため、
 * 意図的に、開始質問の subType しか見ないようにしている
 *
 * @param templateNodes ノードのマップ。関数内で破壊的に変更される
 */
const alignResponseTimeMeasurements = (templateNodes: {
  [key: number]: TemplateNodeSchema
}) => {
  /** 日時質問と subType の対応関係を表すマップ */
  const dtMap = Object.values(templateNodes).reduce<
    Map<
      string,
      ResponseDatetimeSubTypeEnum.TIME | ResponseDatetimeSubTypeEnum.DATETIME
    >
  >((prev, curr) => {
    const uuid = curr.uuid
    const dt = curr.question?.responseDatetimes?.[0]
    const subType = dt?.subType
    // 仕様上、時間計測の参照先となる日時質問の subType が日付になることはないのでガードする
    const isDate = subType === ResponseDatetimeSubTypeEnum.DATE
    if (!uuid || !dt || !subType || isDate) return prev

    prev.set(uuid, subType)
    return prev
  }, new Map<string, ResponseDatetimeSubTypeEnum.TIME | ResponseDatetimeSubTypeEnum.DATETIME>())

  for (const node of Object.values(templateNodes)) {
    // 時間計測ノードに対する変更しかしないので、それ以外の種別ならスキップする
    const tm = node.question?.responseTimeMeasurements?.[0]
    if (!tm) continue

    // 開始質問の subType だけ見れば良い
    const startQuestionNode = dtMap.get(tm.startQuestionNodeUUID)
    if (!startQuestionNode) {
      // この時間計測ノードが参照している日時ノードが存在しない、ということは通常ありえないが、
      // そうなった場合は、subType を日時に設定しておく
      console.error(
        `startQuestionNode is not found. set TimeMeasurement(id=${tm.id})'s subType to DATETIME`,
      )
    }

    tm.subType =
      dtMap.get(tm.startQuestionNodeUUID) ||
      ResponseDatetimeSubTypeEnum.DATETIME
  }

  return templateNodes
}

export {
  alignResponseTimeMeasurements,
  createNewLogicNode,
  createNewNumberLogicNode,
  createNewQuestionNode,
  deleteUnnecessaryCopiedProperties,
  getDeleteNodeIds,
  walkAndUpdateId,
}
