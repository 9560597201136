import { useMutation } from '@tanstack/react-query'
import {
  PostUsersImportJobs400Response,
  UsersApi,
  UsersImportJobsCSVValidationErrorEnum,
} from '@ulysses-inc/harami_api_client'
import { CsvValidationError } from 'src/features/userImport/UserImport'
import { baseClient } from 'src/state/middleware/saga/baseClient'

type ValidationMessages = {
  [key in UsersImportJobsCSVValidationErrorEnum]: string
}

const validationMessages: ValidationMessages = {
  [UsersImportJobsCSVValidationErrorEnum.InvalidFileEncoding]:
    'ファイルのエンコーディングがUTF-8ではありません',
  [UsersImportJobsCSVValidationErrorEnum.InvalidFileMaxSize]:
    'ファイルのサイズが上限を超えています',
  [UsersImportJobsCSVValidationErrorEnum.InvalidFileMinSize]:
    'ファイルの内容が空です',
  [UsersImportJobsCSVValidationErrorEnum.InvalidCsvFormat]:
    'CSVの形式が不正です',
  [UsersImportJobsCSVValidationErrorEnum.InvalidCsvMaxLineLength]:
    'CSVの行数が上限を超えています',
  [UsersImportJobsCSVValidationErrorEnum.InvalidMultipartFormData]:
    'フォームデータが不正です',
} as const

export const useMutationUploadCsvFile = () => {
  return useMutation({
    mutationFn: async (csvFile: File) => {
      try {
        await baseClient.getApi(UsersApi).postUsersImportJobs({ file: csvFile })
      } catch (e) {
        if (e instanceof Response && e.status === 400) {
          const errorResponseBody =
            (await e.json()) as PostUsersImportJobs400Response

          const messages = errorResponseBody.errors.map(
            errorEnum => validationMessages[errorEnum],
          )

          throw new CsvValidationError(messages)
        }

        // 未知のエラーの場合はほぼそのままthrowする
        throw new Error('CSVファイルのアップロードに失敗しました', {
          cause: e,
        })
      }
    },
  })
}
