import { UserGroup } from '@ulysses-inc/harami_api_client'
import { Select } from 'antd'
import { LabeledValue } from 'antd/lib/select'
import React from 'react'
import { useSelector } from 'react-redux'
import FormikErrorMessage from 'src/components/formikErrorMessage/FormikErrorMessage'
import { RootState } from 'src/state/store'
import { TBaseFormRowProps } from './EditSchedule'
import { FormLabel, FormRow, Option, Text } from './EditSchedule.styled'

const ScheduleNotifiedGroupsRow: React.FC<TBaseFormRowProps> = ({
  setFieldValue,
  values: { userGroups },
  errors,
  touched,
}) => {
  const existingUserGroups = useSelector(
    (state: RootState) => state.usersState.userGroups.userGroups,
  )

  const onSelect = (value: string | number | LabeledValue) => {
    setFieldValue(
      'userGroups',
      (userGroups ?? []).concat(
        existingUserGroups.filter(
          (userGroup: UserGroup) => userGroup.id?.toString() === value,
        ),
      ),
    )
  }
  const onDeselect = (value: string | number | LabeledValue) =>
    setFieldValue(
      'userGroups',
      (userGroups ?? []).filter(
        (userGroup: UserGroup) => userGroup.id?.toString() !== value,
      ),
    )

  const selectedValues = (userGroups ?? []).map(
    (userGroup: UserGroup) => userGroup.id?.toString() || '',
  )

  return (
    <FormRow>
      <FormLabel>
        <Text strong>通知先のユーザーグループ</Text>
      </FormLabel>
      <div style={{ width: '100%' }}>
        <Select
          mode="multiple"
          style={{ width: '50%' }}
          optionFilterProp="children"
          onSelect={onSelect}
          onDeselect={onDeselect}
          value={selectedValues}
        >
          {(existingUserGroups ?? []).map(({ id, name }) => (
            <Option key={id?.toString()} value={id?.toString() || ''}>
              {name}
            </Option>
          ))}
        </Select>
        <div>
          <FormikErrorMessage
            name="userGroups"
            errors={errors}
            touched={touched}
          />
        </div>
      </div>
    </FormRow>
  )
}

export default ScheduleNotifiedGroupsRow
