import { useQuery } from '@tanstack/react-query'
import { PlaceNodeTypeEnum, UsersApi } from '@ulysses-inc/harami_api_client'
import { localStorageKeys } from 'src/constants/localStorageKeys'
import { commonQueryKeys } from 'src/features/tanStackQuery/commonQueries/commonQueryKeys'
import { baseClient } from 'src/state/middleware/saga/baseClient'
import { localeAwareSort } from 'src/util/sortStringsLocaleAware'

type SimplifiedPlaceNode = {
  name: string
  uuid: string
  type: 'place' | 'placeGroup'
}

/**
 * ユーザーが所属している現場ノードを取得する
 *
 * 今後必要に応じてフィルタ条件などを指定できるようにすると便利かも(e.g. 直接所属している現場のみ取得する、など)
 */
export const useQueryUserPlaceNodes = () =>
  useQuery({
    queryKey: commonQueryKeys.userPlaceNodes,
    queryFn: () =>
      baseClient.getApi(UsersApi).getUserPlaceNodes({
        userId: localStorage.getItem(localStorageKeys.loginUserUuid) || '',
        recursive: true,
      }),
    select: (data): SimplifiedPlaceNode[] => {
      const simplifiedPlaceNodes = (data || []).map(placeNode => {
        switch (placeNode.type) {
          case PlaceNodeTypeEnum.Place: {
            return {
              name: placeNode.name,
              uuid: placeNode.uuid,
              type: 'place' as const,
            }
          }
          case PlaceNodeTypeEnum.PlaceGroup: {
            return {
              name: placeNode.name,
              uuid: placeNode.uuid,
              type: 'placeGroup' as const,
            }
          }
          default: {
            throw new Error(`Unexpected place node type: ${placeNode.type}`)
          }
        }
      })
      return [...simplifiedPlaceNodes].sort((a, b) =>
        localeAwareSort(a.name, b.name),
      )
    },
    // 画面を二回目以降に開く際に、時間差でデータが更新されることによる事故を防ぐため
    gcTime: 0,
  })
