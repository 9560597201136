import {
  AddIndicatedIssueLabelRequest,
  DeleteIndicatedIssueLabelRequest,
  IndicatedIssuesApi,
  UpdateIndicatedIssueLabelRequest,
  UpdateIndicatedIssueRevisesRequest,
} from '@ulysses-inc/harami_api_client'
import { call, put, takeEvery } from 'redux-saga/effects'
import * as notificationServiceActions from 'src/features/notificationService/slice'
import BaseClient from '../../middleware/saga/baseClient'
import { handleHTTPError } from '../../middleware/saga/handleHttpError'
import interceptionsActions from '../interceptions/actions'
import { default as actions } from './actions'
import { ActionTypes } from './types'

const baseClient = new BaseClient()

const getIndicatedIssueLabelsRequest = () => {
  return baseClient
    .getApi(IndicatedIssuesApi)
    .getIndicatedIssueLabels()
    .then(indicatedIssueLabels => indicatedIssueLabels)
    .catch(handleHTTPError)
}

const addIndicatedIssueLabelRequest = (req: AddIndicatedIssueLabelRequest) => {
  return baseClient
    .getApi(IndicatedIssuesApi)
    .addIndicatedIssueLabel(req)
    .catch(handleHTTPError)
}

const updateIndicatedIssueLabelRequest = (
  req: UpdateIndicatedIssueLabelRequest,
) => {
  return baseClient
    .getApi(IndicatedIssuesApi)
    .updateIndicatedIssueLabel(req)
    .catch(handleHTTPError)
}

const deleteIndicatedIssueLabelRequest = (
  req: DeleteIndicatedIssueLabelRequest,
) => {
  return baseClient
    .getApi(IndicatedIssuesApi)
    .deleteIndicatedIssueLabel(req)
    .catch(handleHTTPError)
}

const updateIndicatedIssueRevisesRequest = (
  req: UpdateIndicatedIssueRevisesRequest,
) => {
  return baseClient
    .getApi(IndicatedIssuesApi)
    .updateIndicatedIssueRevises(req)
    .then(revises => revises)
    .catch(handleHTTPError)
}

function* addIndicatedIssueLabel(
  action: ReturnType<typeof actions.addIndicatedIssueLabel>,
) {
  try {
    yield call(addIndicatedIssueLabelRequest, {
      indicatedIssueLabel: action.indicatedIssueLabel,
    })
    yield put(actions.addSuccessIndicatedIssueLabel(action.indicatedIssueLabel))
  } catch (error) {
    yield put(interceptionsActions.handleHttpError(error))
    yield put(actions.addErrorIndicatedIssueLabel(error))
  }
}

function* updateIndicatedIssueLabel(
  action: ReturnType<typeof actions.updateIndicatedIssueLabel>,
) {
  try {
    yield call(updateIndicatedIssueLabelRequest, {
      indicatedIssueLabelUUID: action.indicatedIssueLabel.uuid || '',
      indicatedIssueLabel: action.indicatedIssueLabel,
    })
    yield put(
      actions.updateSuccessIndicatedIssueLabel(action.indicatedIssueLabel),
    )
  } catch (error) {
    yield put(interceptionsActions.handleHttpError(error))
    yield put(actions.updateErrorIndicatedIssueLabel(error))
  }
}

function* deleteIndicatedIssueLabel(
  action: ReturnType<typeof actions.deleteIndicatedIssueLabel>,
) {
  try {
    yield call(deleteIndicatedIssueLabelRequest, {
      indicatedIssueLabelUUID: action.uuid,
    })

    yield put(actions.deleteSuccessIndicatedIssueLabel(action.uuid))
  } catch (error) {
    yield put(interceptionsActions.handleHttpError(error))
    yield put(actions.deleteErrorIndicatedIssueLabel(error))
  }
}

function* getIndicatedIssueLabels() {
  try {
    const indicatedIssueLabels = yield call(getIndicatedIssueLabelsRequest)
    yield put(actions.getSuccessIndicatedIssueLabels(indicatedIssueLabels))
  } catch (error) {
    yield put(interceptionsActions.handleHttpError(error))
    yield put(actions.getErrorIndicatedIssueLabels(error))
  }
}

function* updateIndicatedIssueRevises(
  action: ReturnType<typeof actions.updateRequestIndicatedIssueRevises>,
) {
  const req: UpdateIndicatedIssueRevisesRequest = {
    indicatedIssueRevise: action.revises,
  }
  try {
    yield call(updateIndicatedIssueRevisesRequest, req)
    yield put(actions.updateSuccessIndicatedIssueRevises())
  } catch (error) {
    yield put(interceptionsActions.handleHttpError(error))
    yield put(actions.updateErrorIndicatedIssueRevises(error))
  }
}

function* saveDraftIndicatedIssueRevises(
  action: ReturnType<typeof actions.saveDraftRequestIndicatedIssueRevises>,
) {
  const req: UpdateIndicatedIssueRevisesRequest = {
    indicatedIssueRevise: action.revises,
  }
  try {
    yield call(updateIndicatedIssueRevisesRequest, req)
    yield put(actions.saveDraftSuccessIndicatedIssueRevises())
    yield put(
      notificationServiceActions.showNotification({
        message: '一時保存しました',
      }),
    )
  } catch (error) {
    yield put(interceptionsActions.handleHttpError(error))
    yield put(actions.saveDraftErrorIndicatedIssueRevises(error))
  }
}

const sagas = [
  takeEvery(
    ActionTypes.REQUEST_ADD_INDICATED_ISSUE_LABEL,
    addIndicatedIssueLabel,
  ),
  takeEvery(
    ActionTypes.REQUEST_UPDATE_INDICATED_ISSUE_LABEL,
    updateIndicatedIssueLabel,
  ),
  takeEvery(
    ActionTypes.REQUEST_DELETE_INDICATED_ISSUE_LABEL,
    deleteIndicatedIssueLabel,
  ),
  takeEvery(
    ActionTypes.REQUEST_GET_INDICATED_ISSUE_LABELS,
    getIndicatedIssueLabels,
  ),
  takeEvery(
    ActionTypes.REQUEST_UPDATE_INDICATED_ISSUE_REVISES,
    updateIndicatedIssueRevises,
  ),
  takeEvery(
    ActionTypes.REQUEST_SAVE_DRAFT_INDICATED_ISSUE_REVISES,
    saveDraftIndicatedIssueRevises,
  ),
]

export default sagas
