import { ResponseSet } from '@ulysses-inc/harami_api_client'
import { FC } from 'react'
import { CellInner, TableBodyCellWrapper } from './Common.dumb'

const convertMultipleSets = (
  responseSets: ResponseSet[] | undefined,
): string => {
  return responseSets?.map(item => item.name).join('、') ?? ''
}

type Props = {
  value?: ResponseSet[]
}

export const TableBodyCellResponseSet: FC<Props> = ({ value }) => {
  return (
    <TableBodyCellWrapper>
      <CellInner>{value && convertMultipleSets(value)}</CellInner>
    </TableBodyCellWrapper>
  )
}
