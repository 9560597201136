import { Spin } from 'antd'
import React, { ReactNode } from 'react'

interface OwnProps {
  spinning: boolean
  render: ReactNode
}

const LoadingDrawer: React.FC<OwnProps> = (props: OwnProps) => {
  return <Spin {...props}>{props.render}</Spin>
}

export default LoadingDrawer
