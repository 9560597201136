import { css } from '@emotion/react'
import { Drawer } from 'antd'
import { ReactNode, RefObject } from 'react'

type Props = {
  children: ReactNode
  drawerContent: ReactNode
  /**
   * 外側クリックの判定に使用することを想定したもの
   */
  drawerRef: RefObject<HTMLDivElement>
  drawerWidth?: number
  isDrawerOpen: boolean
  onCloseDrawer: () => void
}

/**
 * 以下のような要件を実現するためのコンポーネント
 * - 何らかのメインコンテンツ(e.g. テーブル等)の上にドロワーを被せて表示したい
 * - ドロワーを開いているときは、メインコンテンツを横スクロール可能にしたい
 * - ドロワーの高さがメインコンテンツの高さを超えるときは、ドロワーを縦スクロール可能にしたい
 * - ドロワーの外をクリックしたときにはドロワーを閉じたい
 *
 * 画面例: https://github.com/Ulysses-inc/harami_dev/assets/10986861/95115b06-ee3d-417d-a941-7721d4030bce
 */
export const WithDrawer = (props: Props) => {
  const {
    children,
    drawerContent,
    drawerRef,
    drawerWidth = 365,
    isDrawerOpen,
    onCloseDrawer,
  } = props

  return (
    <div css={styles.rootContainer}>
      <div css={styles.mainContentContainer}>
        <div
          css={css({
            // ドロワーが開いているときはメインコンテンツの横スクロールを可能にしたいため、
            // ドロワーの幅分ほど親コンテナの幅を超えさせる
            paddingRight: isDrawerOpen ? drawerWidth : 0,
            width: isDrawerOpen ? `calc(100% + ${drawerWidth}px)` : '100%',
          })}
        >
          {children}
        </div>
      </div>

      <div ref={drawerRef}>
        <Drawer
          bodyStyle={{ padding: 0 }}
          contentWrapperStyle={{
            borderLeft: '1px solid #dbe1e9',
            boxShadow: 'none',
          }}
          closable={false}
          getContainer={false}
          mask={false}
          onClose={onCloseDrawer}
          open={isDrawerOpen}
          placement="right"
          width={drawerWidth}
        >
          {drawerContent}
        </Drawer>
      </div>
    </div>
  )
}

const styles = {
  rootContainer: css`
    // コンテナの幅が画面幅より狭い場合に、閉じているドロワーがうっかり見えないようにするため
    overflow-x: hidden;
    position: relative;
  `,
  mainContentContainer: css`
    // メインコンテンツの横スクロールを可能にするため
    overflow-x: auto;
  `,
}
