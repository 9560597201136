import { PlaceNode } from '@ulysses-inc/harami_api_client'
import { Modal } from 'antd'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import FormikErrorMessage from 'src/components/formikErrorMessage/FormikErrorMessage'
import PlaceGroupsTree from 'src/features/schedules/editSchedule/PlaceGroupsTree'
import {
  editorPermissions,
  hasPermission,
} from 'src/libraries/roles/permissions'
import { RootState } from 'src/state/store'
import { TBaseFormRowProps } from './EditSchedule'
import {
  FormLabel,
  FormRow,
  ModalContent,
  Text,
  WarningIcon,
  WarningIconWrap,
} from './EditSchedule.styled'

type CheckedKeys =
  | (string | number)[]
  | {
      checked: (string | number)[]
      halfChecked: (string | number)[]
    }

const SchedulePlaceRow: React.FC<
  TBaseFormRowProps<{
    placeNodes: PlaceNode[]
  }>
> = ({
  setFieldValue,
  placeNodes: existingPlaceNodes,
  values: { placeNodes },
  errors,
  touched,
}) => {
  const [visibleNoPlaceModal, setVisibleNoPlaceModal] = useState<boolean>(true)
  const isLoadingPlaceNodes = useSelector(
    (state: RootState) => state.placesState.placeGroups.isLoading,
  )
  const isActive = useSelector(
    (state: RootState) => state.schedulesState.schedule.schedule.isActive,
  )

  const onCheck = (checkedKeys: CheckedKeys) => {
    if (isActive) {
      setFieldValue('startReportDate', undefined)
      setFieldValue('endReportDate', undefined)
    }
    setFieldValue('placeNodes', checkedKeys)
  }
  const onCancel = () => setVisibleNoPlaceModal(false)

  const hasPlaceNodes =
    existingPlaceNodes !== undefined && existingPlaceNodes.length > 0
  const hadNoPlaceNodes = !isLoadingPlaceNodes && !hasPlaceNodes
  const isEditor = hasPermission(editorPermissions)

  return (
    <FormRow>
      <FormLabel>
        <Text strong>現場</Text>
      </FormLabel>
      {hasPlaceNodes && (
        <PlaceGroupsTree
          placeNodes={existingPlaceNodes}
          onCheck={onCheck}
          checkedKeys={placeNodes ?? []}
        />
      )}
      {hadNoPlaceNodes && (
        <Modal
          title=""
          centered
          open={visibleNoPlaceModal}
          onCancel={onCancel}
          footer=""
        >
          <ModalContent>
            <WarningIconWrap>
              <WarningIcon />
            </WarningIconWrap>
            <div>
              <p>現場グループが未登録のため、スケジュールが作成できません。</p>
              {isEditor && (
                <p>
                  <a href="/places">現場管理画面</a>で追加してください。
                </p>
              )}
              {!isEditor && <p>現場管理から登録してください。</p>}
            </div>
          </ModalContent>
        </Modal>
      )}
      <div>
        <FormikErrorMessage
          name="placeNodes"
          errors={errors}
          touched={touched}
        />
      </div>
    </FormRow>
  )
}

export default SchedulePlaceRow
