import { PlaceNode, PlaceNodeTypeEnum } from '@ulysses-inc/harami_api_client'
import { Select, Typography } from 'antd'
import { FC, useCallback, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useDisplayedPlaceNodes from 'src/features/places/useDisplayedPlaceNodes'
import { Gray } from 'src/features/theme/KdsThemeColor'
import placesOperations from 'src/state/ducks/places/operations'
import { RootState } from 'src/state/store'
import styled from 'styled-components'
import { flattenNodes } from './EditPlaceGroup'
import { useFormikProps } from './EditPlaceGroup.hooks'
import { PlaceGroupText, PlaceGroupTitle } from './EditPlaceGroup.styled'

const { Option } = Select
const { Text } = Typography

const FormInfo = styled.div`
  margin-bottom: 5px;
  margin-left: 3px;
  width: 100%;
`

type TProps = Pick<ReturnType<typeof useFormikProps>, 'setFieldValue'>

const PlacesRow: FC<TProps> = ({ setFieldValue }) => {
  const nodes = useSelector(
    (state: RootState) => state.placesState.placeGroups.nodes,
  )
  const activeNodeId = useSelector(
    (state: RootState) => state.placesState.placeGroups.activeNodeId,
  )
  const activeParentNodeId = useSelector(
    (state: RootState) => state.placesState.placeGroups.activeParentNodeId,
  )
  const places = useSelector(
    (state: RootState) => state.placesState.places.places,
  )
  // 上限を無視した DB へ登録されている総現場数
  const totalPlacesCount = useSelector(
    (state: RootState) => state.placesState.places.count,
  )

  const placeGroupNodes = flattenNodes(nodes, []).filter(
    ({ type }) => type === PlaceNodeTypeEnum.PlaceGroup,
  )
  const activeNode = placeGroupNodes.find(({ uuid }) => uuid === activeNodeId)
  const activeParentNode = placeGroupNodes.find(
    ({ id }) => id === activeParentNodeId,
  )
  const activeNodes = (activeNode?.nodes as PlaceNode[]) || []
  const nodeId = activeNode?.id || activeParentNode?.id || 0
  const placeNodes = useDisplayedPlaceNodes(
    nodeId,
    activeNodes,
    places || [],
    placeGroupNodes,
  )
  const placesCount = places.length
  const defaultValue = activeNodes
    .filter(({ type }) => type === PlaceNodeTypeEnum.Place)
    .map(({ id }) => id.toString())

  const dispatch = useDispatch()
  const timer = useRef<ReturnType<typeof setTimeout> | null>(null)
  // debounce の時間
  const timeout = 500
  const debounceFetch = useCallback(
    (text: string) => {
      if (timer.current) {
        clearTimeout(timer.current)
      }
      timer.current = setTimeout(() => {
        placesOperations.getPlacesV2(
          dispatch,
          {},
          { placeName: text ? { $like: text } : undefined },
        )
      }, timeout)
    },
    [dispatch],
  )

  const clearSearch = () => {
    // 現場を選択・検索文字列を消した場合、元の選択肢（上限超えていない選択肢）に戻す
    // すぐに戻すと、上限超えた現場の選択部分の表示がうまく表示されないため、少し時間を空けて戻す
    setTimeout(() => {
      placesOperations.getPlacesV2(dispatch, {}, {})
    }, 100)
  }
  const onChange = (nodeIds: string[]) => {
    setFieldValue('nodeIds', nodeIds)
    clearSearch()
  }
  const onSearch = (text: string) => {
    debounceFetch(text)
  }
  const onBlur = () => clearSearch()
  // 現場数の取得件数が、実際に登録されている現場数より少なければ上限に達する文言をユーザへ表示
  const infoText =
    placesCount < totalPlacesCount
      ? `表示されていない現場への紐付けに関しましては、サポートまでお問い合わせください。`
      : ''

  return (
    <>
      <PlaceGroupTitle>
        <PlaceGroupText>所属させる現場</PlaceGroupText>
      </PlaceGroupTitle>
      <FormInfo>
        <Text style={{ fontSize: 12, color: Gray, whiteSpace: 'pre-line' }}>
          {infoText}
        </Text>
      </FormInfo>
      <Select
        mode="multiple"
        style={{ width: '100%' }}
        optionFilterProp="children"
        defaultValue={defaultValue}
        onChange={onChange}
        onSearch={onSearch}
        onBlur={onBlur} // 入力・選択中から入力欄以外をクリックしたとき
      >
        {placeNodes.map(({ id, place }) => (
          <Option key={id?.toString()} value={id?.toString()}>
            {place?.name}
          </Option>
        ))}
      </Select>
    </>
  )
}

export default PlacesRow
