import { datadogRum } from '@datadog/browser-rum'
import React from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { Provider } from 'react-redux'
import { Router } from 'react-router-dom'
import 'src/components/swiper/setupSwiper'
import { AntdConfigProvider } from 'src/features/antd/setupAntd'
import { AuthErrorDialog } from 'src/features/auth/error/Dialog'
import 'src/features/firebase/firebase'
import { initializeGtm } from 'src/features/gtm/initializeGtm'
import { NotificationService } from 'src/features/notificationService/NotificationService'
import { initializeSentry } from 'src/features/sentry/initializeSentry'
import { SetupTanStackQuery } from 'src/features/tanStackQuery/SetupTanStackQuery'
import ErrorBoundary from 'src/libraries/ErrorBoundary'
import { history, store } from 'src/state/store'
import 'src/util/polyfill'
import Routes from 'src/views/Routes'
import { UAGuard } from './UAGuard'

if (
  import.meta.env['VITE_APP_DATADOG_RUM_APPLICATION_ID'] &&
  import.meta.env['VITE_APP_DATADOG_RUM_CLIENT_TOKEN']
) {
  // SEE: https://docs.datadoghq.com/real_user_monitoring/browser/#configuration
  datadogRum.init({
    applicationId: import.meta.env['VITE_APP_DATADOG_RUM_APPLICATION_ID'],
    clientToken: import.meta.env['VITE_APP_DATADOG_RUM_CLIENT_TOKEN'],
    site: 'datadoghq.com',
    service: 'harami-web',
    env: import.meta.env['VITE_APP_ENV'],
    version: import.meta.env['VITE_APP_GIT_COMMIT_SHA'],
    sessionSampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask',
    // e2e 環境等からトレースを送信したくないので、送信可能なオリジンを dev と prod に限定する
    allowedTracingUrls: [
      url => url.startsWith('https://report.kaminashi-dev.com/'),
      url => url.startsWith('https://report.kaminashi.com/'),
    ],
  })

  datadogRum.startSessionReplayRecording()
}

initializeSentry()
initializeGtm()

const App: React.FC = () => {
  return (
    <UAGuard>
      <ErrorBoundary>
        <DndProvider backend={HTML5Backend}>
          <Provider store={store}>
            <Router history={history}>
              <AntdConfigProvider>
                <SetupTanStackQuery>
                  <AuthErrorDialog />
                  <NotificationService />
                  <Routes />
                </SetupTanStackQuery>
              </AntdConfigProvider>
            </Router>
          </Provider>
        </DndProvider>
      </ErrorBoundary>
    </UAGuard>
  )
}

export default App
