import { Typography } from 'antd'
import { FC } from 'react'
import FormikErrorMessage from 'src/components/formikErrorMessage/FormikErrorMessage'
import {
  FormLabel,
  NameInput,
  RequiedLabel,
} from './EditMultipleChoiceSet.dumb'
import { useFormikProps } from './EditMultipleChoiceSet.hooks'

const { Text } = Typography

const NameRow: FC = () => {
  const {
    formikProps: { setFieldValue, values, errors, touched },
  } = useFormikProps()

  return (
    <>
      <FormLabel>
        <Text strong>セット選択肢名</Text>
      </FormLabel>
      <NameInput
        onChange={e => setFieldValue('name', e.target.value)}
        value={values.name}
      />
      <RequiedLabel>※必須入力</RequiedLabel>
      <div>
        <FormikErrorMessage name="name" errors={errors} touched={touched} />
      </div>
    </>
  )
}

export default NameRow
