import {
  GetExcelConversionLogsRequest,
  GetExcelConversionLogsResponse,
} from '@ulysses-inc/harami_api_client'
import { ActionTypes, GetExcelConversionLogsFilter } from './types'

const getExcelConversionLogs = (
  request: GetExcelConversionLogsRequest,
  filter?: GetExcelConversionLogsFilter,
) => {
  return {
    type: ActionTypes.REQUEST_GET_EXCEL_CONVERSION_LOGS,
    isLoading: true,
    request,
    filter,
  }
}

const getSuccessExcelConversionLogs = ({
  excelConversionLogs,
  count,
}: GetExcelConversionLogsResponse) => {
  return {
    type: ActionTypes.SUCCESS_GET_EXCEL_CONVERSION_LOGS,
    isLoading: false,
    excelConversionLogs,
    count,
  }
}

const getErrorExcelConversionLogs = (error: Error) => {
  return {
    type: ActionTypes.ERROR_GET_EXCEL_CONVERSION_LOGS,
    isLoading: false,
    error,
  }
}

const changeExcelConversionLogsPage = (
  page: number,
  pageSize?: number,
  filter?: GetExcelConversionLogsFilter,
) => {
  const offset = pageSize ? (page - 1) * pageSize : 0
  return {
    type: ActionTypes.REQUEST_CHANGE_EXCEL_CONVERSION_LOGS_PAGE,
    isLoading: true,
    request: {
      limit: pageSize,
      offset,
    },
    filter,
  }
}

const changeExcelConversionLogsSize = (
  pageSize: number,
  filter?: GetExcelConversionLogsFilter,
) => {
  return {
    type: ActionTypes.REQUEST_CHANGE_EXCEL_CONVERSION_LOGS_SIZE,
    isLoading: true,
    request: {
      limit: pageSize,
      offset: 0,
    },
    filter,
  }
}

const updatePagination = (limit: number, offset: number) => {
  return {
    type: ActionTypes.UPDATE_PAGINATION,
    request: {
      limit,
      offset,
    },
  }
}

const getExcelConversionLogFiles = (fileUUIDs: string[]) => {
  return {
    type: ActionTypes.REQUEST_GET_EXCEL_CONVERSION_LOG_FILES,
    fileUUIDs,
  }
}

export type ExcelConversionLogAction =
  | ReturnType<typeof getExcelConversionLogs>
  | ReturnType<typeof getSuccessExcelConversionLogs>
  | ReturnType<typeof getErrorExcelConversionLogs>
  | ReturnType<typeof changeExcelConversionLogsPage>
  | ReturnType<typeof changeExcelConversionLogsSize>
  | ReturnType<typeof updatePagination>
  | ReturnType<typeof getExcelConversionLogFiles>

export default {
  getExcelConversionLogs,
  getSuccessExcelConversionLogs,
  getErrorExcelConversionLogs,
  changeExcelConversionLogsPage,
  changeExcelConversionLogsSize,
  updatePagination,
  getExcelConversionLogFiles,
}
