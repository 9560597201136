import { QuestionCircleOutlined } from '@ant-design/icons'
import { Button, Modal, Typography } from 'antd'
import React, { ReactNode } from 'react'
import styled from 'styled-components'

const { Text } = Typography

const IconRemandModal = styled(QuestionCircleOutlined)`
  color: orange;
  margin-right: 10px;
`

const ApprovalModal = styled(Modal)`
  .ant-modal-header {
    border-bottom: 0px;
  }
  .ant-modal-footer {
    border-top: 0px;
  }
  .ant-modal-body {
    padding: 0px;
    padding-left: 24px;
    padding-right: 24px;
  }
`

interface OwnProps {
  title: string
  open: boolean
  loading: boolean
  disabledSubmit?: boolean
  content?: ReactNode
  onCancel: () => void
  onSubmit: () => void
}

const ApprovalsModal: React.FC<OwnProps> = (props: OwnProps) => {
  const bodyStyle = props.content ? {} : { display: 'none' }
  return (
    <ApprovalModal
      title={
        <>
          <IconRemandModal />
          <Text>{props.title}</Text>
        </>
      }
      bodyStyle={bodyStyle}
      onCancel={() => props.onCancel()}
      open={props.open}
      footer={[
        <Button key="back" onClick={() => props.onCancel()}>
          いいえ
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={props.loading}
          disabled={props.disabledSubmit}
          onClick={() => props.onSubmit()}
        >
          はい
        </Button>,
      ]}
    >
      {props.content}
    </ApprovalModal>
  )
}

export default ApprovalsModal
