import { ReportNodeSchema } from '@ulysses-inc/harami_api_client'
import React from 'react'
import { WebReportResultProps } from 'src/features/reports/result/WebReportResultProps'
import { Question } from './Question'

interface OwnProps {
  answers: ReportNodeSchema[]
}

export const DeviatedAnswerPerSection: React.FC<
  OwnProps & WebReportResultProps
> = (props: OwnProps & WebReportResultProps) => {
  return (
    <>
      {props.answers.map((answer: ReportNodeSchema, index: number) => {
        return (
          <Question
            key={index}
            node={answer}
            question={answer.question}
            isDeviatedRender
            {...props}
          />
        )
      })}
    </>
  )
}
