import React, { useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { getBasePath } from 'src/config'

const OIDCLogin: React.FC = () => {
  useEffect(() => {
    window.location.replace(`${getBasePath()}/auth/login`)
  })
  return <></>
}

export default withRouter(OIDCLogin)
