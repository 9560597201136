import {
  TemplateLayoutTypeEnum,
  TemplateNodeSchema,
} from '@ulysses-inc/harami_api_client'
import { Modal } from 'antd'
import { RefObject } from 'react'
import {
  ConnectDragPreview,
  ConnectDragSource,
  DropTargetMonitor,
  useDrag,
  useDrop,
} from 'react-dnd'
import { useDispatch } from 'react-redux'
import { TemplateNodesDict } from 'src/exShared/types/types'
import {
  DragItem,
  HoveringPosition,
  MoveNodeEvent,
} from 'src/features/templateEdit/dragAndDrop/DnDTemplateType'
import { DnDUtils } from 'src/features/templateEdit/dragAndDrop/DnDUtils'
import { moveTemplateNode } from 'src/state/ducks/templates/actions'
import { useGridLayoutSoftLimitErrorLog } from '../useSoftLimitErrorLog'
import { validateLogicNestCountOnDraggingQuestion } from '../validate'
import { TReactState, checkIsEmployeeCheck } from './Question.utils'

type UseDragAndDropParamTemplateInfo = {
  layoutType: TemplateLayoutTypeEnum
  templateNodes: TemplateNodesDict
}

type UseDragAndDropParam = {
  hoverPositionState: TReactState<HoveringPosition>
  parentNode: TemplateNodeSchema | null
  node: TemplateNodeSchema
  templateInfo: UseDragAndDropParamTemplateInfo
  ref: RefObject<HTMLDivElement>
}

export const useDragAndDrop = ({
  hoverPositionState: [hoverPosition, setHoverPosition],
  parentNode,
  node,
  templateInfo: { layoutType, templateNodes },
  ref,
}: UseDragAndDropParam): {
  isOver: boolean
  isDragging: boolean
  drag: ConnectDragSource
  preview: ConnectDragPreview
} => {
  const { logSoftLimitErrorEvent } = useGridLayoutSoftLimitErrorLog('dragNode')
  const dispatch = useDispatch()
  const moveNode = (event: MoveNodeEvent) => {
    const { dragNode, dragNodeParent, dropNodeParent } = event
    const isSameParent = !!(
      (!dragNodeParent && !dropNodeParent) ||
      (dragNodeParent &&
        dropNodeParent &&
        dragNodeParent.id === dropNodeParent.id)
    )
    const errorMsg = validateLogicNestCountOnDraggingQuestion(
      layoutType,
      dragNode,
      dropNodeParent,
      templateNodes,
    )
    const showErrorModal = (errorMsg: string) => {
      Modal.error({
        title: '条件分岐の追加ができません',
        content: errorMsg,
      })
      logSoftLimitErrorEvent('logicNestCount', errorMsg)
    }

    if (errorMsg) {
      showErrorModal(errorMsg)
    } else {
      dispatch(moveTemplateNode(event, isSameParent))
    }
  }

  const [{ isOver }, drop] = useDrop({
    accept: 'NODE',
    collect: monitor => ({
      isOver: monitor.isOver({ shallow: true }),
    }),
    hover: (_: DragItem, monitor: DropTargetMonitor) => {
      if (checkIsEmployeeCheck(parentNode, node)) {
        setHoverPosition(undefined)
        return
      }
      if (monitor.isOver({ shallow: true })) {
        const position = DnDUtils.getHoveringPosition(ref, monitor)
        if (hoverPosition !== position) {
          setHoverPosition(position)
        }
      } else {
        setHoverPosition(undefined)
      }
    },
    drop: ({ node: dragNode, parentNode: dragNodeParent }: DragItem) => {
      if (!hoverPosition) {
        return
      }
      if (dragNode.uuid === node.uuid) {
        return
      }
      if (checkIsEmployeeCheck(parentNode, node)) {
        return
      }

      const event: MoveNodeEvent = {
        dragNode,
        dropNode: node,
        dropPosition: hoverPosition,
        dragNodeParent,
        dropNodeParent: parentNode,
      }
      moveNode(event)
    },
  })

  const [{ isDragging }, drag, preview] = useDrag({
    type: 'NODE',
    item: { node, parentNode },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  })

  drop(ref)

  return {
    isOver,
    isDragging,
    drag,
    preview,
  }
}
