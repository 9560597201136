import { DeleteOutlined } from '@ant-design/icons'
import {
  ResponseFormulaToken,
  ResponseFormulaTokenOperatorTypeEnum,
  ResponseFormulaTokenTypeEnum,
  TemplateNodeSchema,
} from '@ulysses-inc/harami_api_client'
import { InputNumber, Radio, Select, Typography } from 'antd'
import { useEffect, useState } from 'react'
import { FormulaBlock } from 'src/features/templateEdit/editResponseFormula/EditResponseFormulaDrawer'
import { isExistsNode } from 'src/features/templateEdit/editResponseFormula/utils'
import { Danger, PlaceholderText } from 'src/features/theme/KdsThemeColor'
import styled from 'styled-components'

const { Text } = Typography
const { Option } = Select

const BlockWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 1rem;
  border: 0.5px solid #cccccc;
  padding: 1rem;
  border-radius: 8px;
`

const OperatorWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const DeleteIcon = styled(DeleteOutlined)`
  font-size: 20px;
  color: ${Danger};
`

const EmptyOptionValue = styled(Text)`
  color: ${PlaceholderText};
`

const operatorOptions: {
  label: string
  value: ResponseFormulaTokenOperatorTypeEnum
}[] = [
  { label: '+', value: ResponseFormulaTokenOperatorTypeEnum.PLUS },
  { label: '-', value: ResponseFormulaTokenOperatorTypeEnum.MINUS },
  { label: 'x', value: ResponseFormulaTokenOperatorTypeEnum.MULTI },
  { label: '÷', value: ResponseFormulaTokenOperatorTypeEnum.DIVIDED },
]

interface Props {
  numberNodes: TemplateNodeSchema[]
  block: FormulaBlock
  isFirstBlock: boolean
  changeBlock: (block: FormulaBlock) => void
  deleteBlock: () => void
}

export const EditResponseFormulaDrawerBlock = (props: Props) => {
  const [questionOrConstant, setQuestionOrConstant] =
    useState<ResponseFormulaTokenTypeEnum>(
      props.block.questionOrConstant?.type ||
        ResponseFormulaTokenTypeEnum.QUESTION,
    )
  useEffect(() => {
    setQuestionOrConstant(
      props.block.questionOrConstant?.type ||
        ResponseFormulaTokenTypeEnum.QUESTION,
    )
  }, [props.block.questionOrConstant])

  return (
    <BlockWrapper role="group" aria-label="計算式ブロック">
      {!props.isFirstBlock && (
        <OperatorWrapper>
          <div />
          <div
            // antdのselectのa11y対応がダメすぎるので仕方なく手動で追加したdiv
            role="button"
            aria-label="演算子選択"
          >
            <Select
              suffixIcon={null}
              dropdownStyle={{ width: 48 }}
              value={props.block.operator?.operator}
              onChange={(operator: ResponseFormulaTokenOperatorTypeEnum) => {
                const newOperator: ResponseFormulaToken = {
                  type: ResponseFormulaTokenTypeEnum.OPERATOR,
                  operator: operator,
                }
                props.changeBlock({
                  ...props.block,
                  operator: { ...newOperator },
                })
              }}
            >
              {operatorOptions.map((operator, i) => (
                <Option
                  key={i}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                  value={operator.value}
                >
                  {operator.label}
                </Option>
              ))}
            </Select>
          </div>
          <DeleteIcon type="delete" onClick={() => props.deleteBlock()} />
        </OperatorWrapper>
      )}
      <div style={{ width: '100%' }}>
        <Radio.Group
          style={{ marginBottom: '4px' }}
          value={questionOrConstant}
          onChange={e => {
            setQuestionOrConstant(
              e.target.value as ResponseFormulaTokenTypeEnum,
            )
            props.changeBlock({
              ...props.block,
              questionOrConstant: {
                type: e.target.value as ResponseFormulaTokenTypeEnum,
              },
            })
          }}
        >
          <Radio value={ResponseFormulaTokenTypeEnum.QUESTION}>質問</Radio>
          <Radio value={ResponseFormulaTokenTypeEnum.CONSTANT}>定数</Radio>
        </Radio.Group>
        {questionOrConstant === ResponseFormulaTokenTypeEnum.QUESTION ? (
          <Select
            aria-label="質問選択"
            showSearch
            optionFilterProp="children"
            style={{ width: '100%' }}
            value={
              isExistsNode(
                props.numberNodes,
                props.block.questionOrConstant?.questionNodeUUID,
              )
                ? props.block.questionOrConstant?.questionNodeUUID || ''
                : undefined
            }
            onChange={(questionNodeUUID: string) => {
              const newQuestion: ResponseFormulaToken = {
                type: ResponseFormulaTokenTypeEnum.QUESTION,
                questionNodeUUID: questionNodeUUID,
              }
              props.changeBlock({
                ...props.block,
                questionOrConstant: { ...newQuestion },
              })
            }}
          >
            {props.numberNodes.map((node, index) => {
              return (
                <Option key={index} value={node.uuid || ''}>
                  {node.question?.name ? (
                    node.question?.name
                  ) : (
                    <EmptyOptionValue>質問名なし</EmptyOptionValue>
                  )}
                </Option>
              )
            })}
          </Select>
        ) : (
          <InputNumber
            style={{ width: '100%' }}
            value={props.block.questionOrConstant?.constant ?? undefined}
            onChange={constant => {
              const newConstant: ResponseFormulaToken = {
                type: ResponseFormulaTokenTypeEnum.CONSTANT,
                constant: Number(constant),
              }
              props.changeBlock({
                ...props.block,
                questionOrConstant: { ...newConstant },
              })
            }}
          />
        )}
      </div>
    </BlockWrapper>
  )
}
