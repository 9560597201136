import {
  TemplateNodeSchema,
  TemplateNodeTypeEnum,
  TemplatePageSchema,
} from '@ulysses-inc/harami_api_client'
import { Row } from 'antd'
import React from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import EditDeviateCommentDrawer from 'src/features/templateEdit/editDeviationComment/EditDeviateCommentDrawer'
import EditDeviateNotifyDrawer from 'src/features/templateEdit/editDeviationNotification/EditDeviateNotifyDrawer'
import EditHintModal from 'src/features/templateEdit/editHint/EditHintModal'
import EditMultipleChoiceLogicModal from 'src/features/templateEdit/editLogicOfMultipleChoice/EditMultipleChoiceLogicModal'
import EditNumberLogicModal from 'src/features/templateEdit/editLogicOfNumber/EditNumberLogicModal'
import { TabEditButton } from 'src/features/templateEdit/templatePages/TabEditButton'
import { updateTemplatePage as updateTemplatePageAction } from 'src/state/ducks/templates/actions'
import { useTemplatePageNodes } from 'src/state/ducks/templates/selectors'
import { RootState } from 'src/state/store'
import { createNode } from '../common/createNode'
import AddTemplatePageContent from './AddTemplatePageContent'
import {
  ErrorMessageText,
  TemplatePageContentWrapper,
} from './EditTemplatePages.dumb'
import { TemplatePageTabs } from './TemplatePageTabs'
import { useCopyPageErrorDialog } from './useCopyPageErrorDialog'

const EditTemplatePagesContainer: React.FC = () => {
  useCopyPageErrorDialog()

  const { isShowScore, isExcelConversion } = useSelector(
    (state: RootState) => state.templatesState.editTemplate,
  )

  const { templateHints } = useSelector(
    (state: RootState) => state.templatesState.templateHints,
    shallowEqual,
  )

  const {
    activeNodeId,
    templatePageIds,
    templatePages,
    templateNodes,
    templatePageErrorMessage,
  } = useSelector((state: RootState) => state.templatesState.templatePages)

  const dispatch = useDispatch()
  const updateTemplatePage = (
    templatePageId: number,
    changeTemplatePage: TemplatePageSchema,
  ) => {
    dispatch(updateTemplatePageAction(templatePageId, changeTemplatePage))
  }

  const useAddTemplateNode = (
    targetNode: TemplateNodeSchema | null,
    parentNode: TemplateNodeSchema | null,
    position: 'top' | 'bottom',
    addTemplateNodeType: TemplateNodeTypeEnum,
  ) => {
    createNode(templateNodes, dispatch, {
      targetNode,
      parentNode,
      position,
      addTemplateNodeType,
      question: targetNode?.question,
    })
  }

  return (
    <>
      <Row>
        {!!templatePageErrorMessage && (
          <ErrorMessageText id="error-message">
            {templatePageErrorMessage}
          </ErrorMessageText>
        )}
        <TemplatePageTabs
          tabItems={templatePageIds.map(templatePageId => ({
            children: (
              <TemplatePageContentWrapper>
                <AddTemplatePageContent
                  templatePage={templatePages[templatePageId] ?? { name: '' }}
                  templatePageName={templatePages[templatePageId]?.name ?? ''}
                  hintCount={templateHints.length}
                  activeNodeId={activeNodeId}
                  isShowScore={isShowScore}
                  isExcelConversion={isExcelConversion}
                  templateHints={templateHints}
                  useTemplatePageNodes={useTemplatePageNodes}
                  addTemplateNode={useAddTemplateNode}
                  updateTemplatePage={updateTemplatePage}
                />
              </TemplatePageContentWrapper>
            ),
            closeIcon: <TabEditButton pageId={templatePageId} />,
            key: `${templatePageId}`,
            label: templatePages[templatePageId]?.name,
          }))}
        />
      </Row>
      <EditMultipleChoiceLogicModal />
      <EditHintModal />
      <EditNumberLogicModal />
      <EditDeviateCommentDrawer />
      <EditDeviateNotifyDrawer />
    </>
  )
}

export default EditTemplatePagesContainer
