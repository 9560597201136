import { useEffect, useId, useRef } from 'react'

/**
 * ターゲット要素の外側がクリックされた場合に、指定したコールバックを呼び出す。
 *
 * 具体的に、「開く」ボタンを押下するとメニューが表示される
 * いわゆるドロップダウンメニューでのユースケースで考えてみる。
 * メニュー内にはSelectやDatePickerが配置されているものとする。
 * このとき、以下の条件が揃えば外側がクリックされたと判定したい。
 *
 * - メニュー(ターゲット要素)の外側のクリックである
 * - 「開く」ボタンのクリックではない
 * - Selectの項目選択時のクリックではない
 * - DatePickerの日付選択時のクリックではない
 *
 * これらを汎化すると、以下のように表現できる。
 *
 * 1. ターゲット要素の外側のクリックである
 * 2. 明示的に指定した無視すべき要素のクリックではない
 * 3. Body直下に動的に差し込まれる要素のクリックではない
 */
export const useOnClickOutside = <T extends HTMLElement>(
  onClickOutside: () => void,
) => {
  const targetRef = useRef<T>(null)

  const handlerId = useId()
  const classNameToIgnore = `ignore-on-click-outside-${handlerId}`

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      // ターゲット要素の内側のクリックである場合は何もしない
      if (
        event.target instanceof Element && // 型の都合
        targetRef.current?.contains(event.target)
      ) {
        return
      }

      // 無視すべき要素内のクリックである場合は何もしない
      for (const element of event.composedPath()) {
        if (
          element instanceof Element && // 型の都合
          element.classList.contains(classNameToIgnore)
        ) {
          return
        }
      }

      const isClickInsideRootDom = (event.composedPath() || []).some(
        element => {
          return (
            element instanceof Element && // 型の都合
            // 例えばSelectの選択メニューやDatePickerの日付入力メニューは、
            // Reactが管理しているルートのDOMツリーの外側（≒兄弟の位置）に動的に差し込まれる。
            // なぜidが`root`なのかはindex.html/index.tsxを参照のこと。
            element.id === 'root'
          )
        },
      )

      // Body直下に動的に差し込まれる要素のクリックである場合は何もしない
      if (!isClickInsideRootDom) {
        return
      }

      onClickOutside()
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [classNameToIgnore, onClickOutside])

  return {
    /**
     * このクラス名が設定されている要素のクリックの場合は、
     * たとえ指定した要素(ドロワー等)の外側のクリックであったとしても何もしない。
     * 動作の競合を防ぐためのもので、例えば「指定した要素を開くためのボタン」等にセットすることを想定している。
     */
    classNameToIgnore,
    /**
     * この要素の外側がクリックされたときにハンドラが実行される
     */
    ref: targetRef,
  }
}
