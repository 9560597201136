import { Checkbox, Input, InputNumber, Upload } from 'antd'
import { Danger, DeleteIconColor } from 'src/features/theme/KdsThemeColor'
import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  height: ${({ theme }) => theme.height};
  background-color: #fafafa;
  border-collapse: collapse;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-top: none;
  display: flex;
  align-items: ${({ theme }) => theme.alignItems || 'center'};
`

export const OptionWrapper = styled.div`
  margin-left: 5px;
`

export const OptionInformationImagesWrapper = styled.div`
  width: 100%;
`

export const OptionColWrapper = styled.div`
  display: flex;
`

export const RuleLabel = styled.label`
  margin: auto;
`

export const FormatLabel = styled.label`
  margin-right: 4px;
`

export const InformationUpload = styled(Upload)`
  display: flex;

  .ant-upload.ant-upload-select.ant-upload-select-picture {
    margin: auto;
  }

  .ant-upload-list-item.ant-upload-list-item-list-type-picture {
    margin-top: 0px;
    margin-left: 10px;
    .anticon-delete > svg {
      color: ${DeleteIconColor};
    }
  }

  * {
    animation-duration: 0s !important;
  }
`

export const InformationError = styled.p`
  margin: 0 0 0 8px;
  color: ${Danger};
`

export const InformationImagesError = styled.p`
  margin-bottom: 8px;
  color: ${Danger};
`

export const ScaleLabel = styled.label`
  margin-left: 10px;
  margin-right: 10px;
  align-self: center;
`

export const ScaleInput = styled(Input)`
  width: 90px;
  border-radius: 8px;
`

export const DatetimeRuleContainer = styled.div`
  margin-left: 24px;
  margin-top: 4px;
`

export const DatetimeRuleInput = styled(InputNumber)`
  width: 64px;
  border-radius: 2px;
`

export const DatetimeRuleDayText = styled.span`
  display: inline-block;
  margin-left: 4px;
`

export const DatetimeRuleDescription = styled.div`
  margin-top: 8px;
  margin-bottom: 8px;
  height: 17px;
  font-family: 'Noto Sans JP';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 17px;
  color: #7f8c9d;
`

export const InformationImagesText = styled.span`
  margin-left: 5px;
`

export const InformationImagesWrapper = styled.div`
  margin-left: 8px;
  margin-top: 13px;
`

export const NoPaddingCheckbox = styled(Checkbox)`
  .ant-checkbox + span {
    padding-right: 0px;
  }
`
