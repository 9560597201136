/* eslint-disable import/no-named-as-default-member */
import {
  DeleteReportRequest,
  GetReportsResponse,
  GetReportsV2Request,
  ReportPlace,
  ReportsApi,
  UpdateReportPlacesV2Request,
} from '@ulysses-inc/harami_api_client'
import { call, put } from 'redux-saga/effects'
import * as notificationServiceActions from 'src/features/notificationService/slice'
import interceptionsActions from 'src/state/ducks/interceptions/actions'
import actions from 'src/state/ducks/reports/reports/actions'
import BaseClient from 'src/state/middleware/saga/baseClient'
import {
  HTTPError,
  handleHTTPError,
} from 'src/state/middleware/saga/handleHttpError'

const {
  getSuccessReports,
  getErrorReports,
  deleteSuccessReport,
  deleteErrorReport,
  updateSuccessReportPlaces,
  updateErrorReportPlaces,
} = actions

const baseClient = new BaseClient()

const getReportsV2Request = (req: GetReportsV2Request) => {
  return baseClient
    .getApi(ReportsApi)
    .getReportsV2(req)
    .then(reports => reports)
    .catch(handleHTTPError)
}

const deleteReportRequest = (req: DeleteReportRequest) => {
  return baseClient.getApi(ReportsApi).deleteReport(req).catch(handleHTTPError)
}

const updateReportPlacesV2Request = (req: UpdateReportPlacesV2Request) => {
  return baseClient
    .getApi(ReportsApi)
    .updateReportPlacesV2(req)
    .catch(handleHTTPError)
}

function* getReports(action: ReturnType<typeof actions.getReports>) {
  try {
    const params: GetReportsV2Request = {
      ...action.request,
      reportFilter: { ...action.filter },
    }
    const response: GetReportsResponse = yield call(getReportsV2Request, params)
    yield put(getSuccessReports(response.reports ?? [], response.count ?? 0))
  } catch (error) {
    yield put(interceptionsActions.handleHttpError(error as HTTPError))
    yield put(getErrorReports(error as Error))
  }
}

function* deleteReport(action: ReturnType<typeof actions.deleteReport>) {
  try {
    yield call(deleteReportRequest, { reportId: action.reportId })
    yield put(deleteSuccessReport(action.reportId))
  } catch (error) {
    yield put(interceptionsActions.handleHttpError(error as HTTPError))
    yield put(deleteErrorReport(error as Error))
  }
}

function* updateReportPlaces(
  action: ReturnType<typeof actions.updateReportPlaces>,
) {
  try {
    const reportPlaces: ReportPlace[] = action.reports.map(report => {
      return {
        reportId: report.id,
        name: action.reportPlace.name,
        nodeId: action.reportPlace.nodeId,
      }
    })
    const params: UpdateReportPlacesV2Request = {
      ...action.request,
      updateReportPlace: {
        updateReportPlaces: reportPlaces,
        reportFilter: { ...action.filter },
      },
    }
    const response: GetReportsResponse = yield call(
      updateReportPlacesV2Request,
      params,
    )
    yield put(
      updateSuccessReportPlaces(response.reports ?? [], response.count ?? 0),
    )
    yield put(
      notificationServiceActions.showNotification({
        message: '現場の付け替えに成功しました',
      }),
    )
  } catch (error) {
    yield put(interceptionsActions.handleHttpError(error as HTTPError))
    yield put(updateErrorReportPlaces(error as Error))
  }
}

export { deleteReport, getReports, updateReportPlaces }
