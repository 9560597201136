import { Select } from 'antd'
import { ComponentProps } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import {
  continueRangeSelect,
  doubleClickCell,
  finishRangeSelect,
  patchInputTable,
  selectOption,
  startRangeSelect,
} from 'src/state/ducks/editGridVariables/actions'
import {
  useIsInDrag,
  useIsInEdit,
  useIsSelected,
} from 'src/state/ducks/editGridVariables/selectors'
import { RootState } from 'src/state/store'
import {
  BaseCell,
  DownIcon,
  SectionNameSelect,
} from '../EditGridVariables.styled'

type Props = {
  /** 行番号 */
  rowNumber: number
  /** 列番号 */
  colNumber: number
  /** セレクトボックスの中身 */
  options: {
    /** `onChangeSectionName` の引数として渡される値 */
    value: string
    label: string
  }[]
}

/**
 * セクション名セル
 */
export const SectionNameCell = ({ rowNumber, colNumber, options }: Props) => {
  const isSelected = useIsSelected(rowNumber, colNumber)
  const isInEdit = useIsInEdit(rowNumber, colNumber)

  /**
   * OPTIMIZE: このセルの mouseenter イベントが発火したときに `CONTINUE_RANGE_SELECT` を呼ぶかどうかの判定のためにモードを見ている
   *           このため、セルをクリックするなどしてモードを変更するごとに全セルの再レンダリングが発生している
   */
  const isInDrag = useIsInDrag()

  const dispatch = useDispatch()
  const onMouseUp = () => {
    dispatch(finishRangeSelect())
  }

  const onMouseDown = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (e.button !== 0) {
      return
    }

    dispatch(startRangeSelect(rowNumber, colNumber))
  }

  const onMouseEnter = () => {
    // drag モード以外では範囲選択したくないのと、その場合に action dispatch するのは無駄なのでガードする
    if (!isInDrag) {
      return
    }

    dispatch(continueRangeSelect(rowNumber, colNumber))
  }

  const handleDoubleClick = () => {
    dispatch(doubleClickCell(rowNumber, colNumber))
  }

  const name = useSelector(
    (state: RootState) =>
      state.editGridVariablesState.editGridVariables.inputTable[rowNumber]
        ?.sectionName.name || '',
  )
  const errors = useSelector(
    (state: RootState) =>
      state.editGridVariablesState.editGridVariables.inputTable[rowNumber]
        ?.sectionName.errors,
    shallowEqual,
  )
  const hasErrors = (errors || []).length > 0

  const onChangeSectionName: ComponentProps<
    typeof Select
  >['onChange'] = selectedValue => {
    const sectionName = selectedValue?.toString()
    if (sectionName === undefined) return
    dispatch(patchInputTable({ [rowNumber]: { sectionName } }))
  }

  const onSelect = () => dispatch(selectOption())

  return (
    <>
      {/* editable */}
      <SectionNameSelect
        value={options.find(o => o.label === name)?.value || ''}
        dropdownStyle={{ width: 240 }}
        options={options}
        notFoundContent={<></>}
        dropdownMatchSelectWidth={false}
        onChange={onChangeSectionName}
        onSelect={onSelect}
        open={isInEdit}
        style={{ display: isInEdit ? 'flex' : 'none' }}
      />

      {/* readonly */}
      <BaseCell
        $cellState={{ isInEdit, hasErrors, isSelected }}
        paddingRight={32}
        onMouseDown={e => onMouseDown(e)}
        onMouseEnter={onMouseEnter}
        onMouseUp={onMouseUp}
        onDoubleClick={handleDoubleClick}
        style={{ display: isInEdit ? 'none' : 'flex' }}
      >
        {name}
        <DownIcon />
      </BaseCell>
    </>
  )
}
