import { List, Pagination, Row } from 'antd'
import { TableBorderColor, White } from 'src/features/theme/KdsThemeColor'
import styled from 'styled-components'

export const TemplatesPagination = styled(Pagination)`
  margin-top: 16px;
  margin-bottom: 16px;
  display: flex;
  justify-content: center;
`

export const ContentRow = styled(Row)`
  margin-top: 16px;
  margin-left: 24px;
  margin-right: 24px;
  margin-bottom: 15px;
  overflow-x: auto;
  justify-content: start;
`

export const TableHeaderWrap = styled.div`
  width: 100%;
  min-width: 800px;
`

export const TableHeader = styled.div`
  width: 100%;
  height: 46px;
  display: flex;
  align-items: center;
  flex-direction: row;
  background-color: ${White};
  border-bottom: 1px solid ${TableBorderColor};
`

export const TableNameCol = styled.div`
  width: 28%;
  font-weight: bold;
  padding-left: 16px;
`

export const TemplatePlaceCol = styled.div`
  width: 56%;
  font-weight: bold;
  padding-left: 8px;
`

export const TableUpdateAtCol = styled.div`
  font-weight: bold;
  display: flex;
  width: 16%;
  padding-left: 8px;
`

// FIXME: 厳密に型を定義するのが望ましいが、良い方法がわからないので暫定的に「any」としている
// もし、本箇所を修正するのであれば、ひな形一覧、レポート一覧など他のList画面も修正候補となる
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const TableBody = styled<any>(List)`
  width: 100%;
`

export const TableRowWrap = styled(List.Item)`
  position: relative;
  min-height: 46px;
  background-color: ${White};
`

export const TemplateNameRow = styled.div`
  display: flex;
  width: 28%;
  padding-right: 8px;
`

export const TemplateNameLabel = styled.div`
  word-break: break-all;
  padding-left: 16px;
  display: flex;
  align-items: center;
`

export const TemplatePlaceNameRow = styled.div`
  word-break: break-all;
  padding-left: 8px;
  width: 56%;
`

export const TemplateModifiedAtRow = styled.div`
  width: 16%;
  padding-left: 8px;
  padding-right: 8px;
`

export const ButtonGroupRow = styled.div`
  position: absolute;
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  height: 100%;
  right: 0;
`

export const ActionRow = styled(Row)`
  margin-top: 16px;
  margin-left: 24px;
  margin-right: 24px;
  margin-bottom: 15px;
`
