import { ErrorCodeEnum } from '@ulysses-inc/harami_api_client'

// 400系/500系エラーのときにharami_api_serverが返してくるレスポンスの型
export interface HTTPError {
  status: number
  code: ErrorCodeEnum
  errors: Array<string>
}

/**
 * 与えられたerrorがHTTPErrorであるかどうかを判別する
 */
const isHTTPError = (error: unknown): error is HTTPError => {
  if (error === undefined || error === null || typeof error !== 'object') {
    return false
  }
  return 'code' in error
}

/**
 * 与えられたerrorが指定のcodeを持っているかを判別する
 *
 * HTTPErrorではないerrorが渡された場合はfalseを返却する
 */
const errorIs = (error: unknown, code: ErrorCodeEnum): boolean => {
  if (!isHTTPError(error)) {
    return false
  }
  return error.code === code
}

/**
 * Open API Clientがthrowするエラーをunwrapして再びthrowする
 *
 * TODO: BaseClient生成時に`fetchApi`オプションで設定すれば、この関数を何十箇所に書かなくても済むのではないか
 */
const handleHTTPError = async (res: Body) => {
  // TODO: OpenAPI Clientがthrowするエラーが全てHTTPError型である保証はない
  const error: HTTPError = await res.json()

  // NOTE: メンテモード、且つ、webの場合にredirectさせるための記述
  if (error.status === 503)
    window.location.href = `/maintenance?params=${error.errors}`

  throw error
}

export { errorIs, handleHTTPError, isHTTPError }
