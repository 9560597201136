import { DownOutlined } from '@ant-design/icons'
import { css } from '@emotion/react'
import { Select } from 'antd'
import {
  Black,
  Blue0,
  Danger,
  Gray1,
  GridVariableCellErrorBackgroundColor,
  Primary,
  White,
} from 'src/features/theme/KdsThemeColor'
import styled, { createGlobalStyle } from 'styled-components'

const DefaultCellBorderColor = '#cbd2dd'

export const PAGE_HEADER_HEIGHT = 72

/** 行番号セルの横幅 */
export const ROW_NUMBER_CELL_WIDTH = 60
/** 適用日セルの横幅 */
export const DATE_CELL_WIDTH = 124
/** 行番号、適用日以外のセルの横幅 */
export const BASE_CELL_WIDTH = 160

export const ROW_HEIGHT = 32

interface CellState {
  isInEdit: boolean
  isSelected: boolean
  hasErrors: boolean
}

/**
 * ウィンドウ全体でのスクロールを抑制する
 */
export const WindowScrollPreventer = createGlobalStyle`
  body {
    overflow: hidden;
  }
`

export const StickyTH = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  left: 0;
  z-index: 4;
  min-width: ${ROW_NUMBER_CELL_WIDTH}px;
  max-width: ${ROW_NUMBER_CELL_WIDTH}px;
  height: ${ROW_HEIGHT}px;
  border: 1px solid ${DefaultCellBorderColor};
  background-color: ${Gray1};
`

export const TH = styled.div`
  background: #eff2f7;
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 8px 12px;
  min-width: ${({ theme }) =>
    theme.width ? `${theme.width}px` : `${BASE_CELL_WIDTH}px`};
  max-width: ${({ theme }) =>
    theme.width ? `${theme.width}px` : `${BASE_CELL_WIDTH}px`};
  height: ${ROW_HEIGHT}px;
  font-family: 'Noto Sans JP';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 17px;
  color: #1e242c;
  z-index: 3;
  border: 1px solid ${DefaultCellBorderColor};
`

export const RequiredMark = styled.div`
  font-family: 'Noto Sans JP';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 17px;
  color: #eb5757;
`

export const StickyCell = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  min-width: ${ROW_NUMBER_CELL_WIDTH}px;
  max-width: ${ROW_NUMBER_CELL_WIDTH}px;
  font-family: 'Maven Pro';
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 17px;
  background-color: ${Gray1};
  border: 1px solid ${DefaultCellBorderColor};
  position: sticky;
  left: 0;
  z-index: 1;
`

// なぜ、propsのnameの先頭に「$」がついているか？ -> DOMにプロパティを追加させないため
// 参考： https://stackoverflow.com/questions/49834251/how-to-extend-styled-component-without-passing-props-to-underlying-dom-element
// TODO: 外せそうであれば、$cellState?の「?」を外す
export const BaseCell = styled.div<{
  $cellState?: CellState
  width?: number
  paddingRight?: number
}>`
  user-select: none;
  position: relative;
  display: flex;
  flex-grow: 1;
  align-items: center;
  min-width: ${({ width }) => (width ? `${width}px` : `${BASE_CELL_WIDTH}px`)};
  max-width: ${({ width }) => (width ? `${width}px` : `${BASE_CELL_WIDTH}px`)};
  padding: 8px 12px;
  padding-right: ${({ paddingRight }) =>
    paddingRight ? `${paddingRight}px` : '12px'};
  font-family: 'Maven Pro';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 17px;
  color: ${Black};
  background-color: ${({ $cellState }) => {
    if ($cellState?.hasErrors) {
      return GridVariableCellErrorBackgroundColor
    }
    if ($cellState?.isSelected) {
      return Blue0
    }
    return White
  }};
  border: 1px solid
    ${({ $cellState }) => {
      if ($cellState?.isSelected && $cellState?.hasErrors) {
        return Danger
      }
      if ($cellState?.isSelected) {
        return Primary
      }
      return DefaultCellBorderColor
    }};
`

export const DownIcon = styled(DownOutlined)`
  position: absolute;
  right: 10px;
  width: 12px;
  height: 12px;
  color: rgba(0, 0, 0, 0.65);
`

// .ant-select-selectorセレクターは詳細度の関係で!importにしないと適用されない
// TODO: 幅などのスタイルの微調整： https://kaminashi.atlassian.net/browse/HPB-2956
// TODO: 長いテキストでの高さの調整: https://kaminashi.atlassian.net/browse/HPB-2947
export const SectionNameSelect = styled(Select)`
  min-width: ${BASE_CELL_WIDTH}px;
  max-width: ${BASE_CELL_WIDTH}px;
  .ant-select-selector {
    border-radius: 0 !important;
    height: 100% !important;
    border: 1px solid ${Primary} !important;
    padding: 0 12px !important;
  }
}`

// 型推論が効かない問題が発生したため、ここだけ一貫性を犠牲にしてやむなくemotionにしている
export const gridCellDatePickerStyle = css`
  display: flex;
  min-width: ${DATE_CELL_WIDTH}px;
  max-width: ${DATE_CELL_WIDTH}px;
  border-radius: 0;

  .ant-picker-input > input {
    font-family: 'Maven Pro';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 17px;
  }

  .ant-picker-suffix {
    display: flex;
    align-items: center;
    position: absolute;
    right: -10px;
  }
`

export const GridCellTextInput = styled.textarea`
  min-width: ${BASE_CELL_WIDTH}px;
  max-width: ${BASE_CELL_WIDTH}px;
  padding-top: 8px;
  padding-left: 12px;
  padding-right: 8px;
  overflow: hidden;
  font-family: 'Maven Pro';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 17px;
  resize: none;
  word-break: break-all;
  outline: none !important;
  border: 1px solid ${Primary};
  box-shadow: 0 0 2px ${Primary};

  &:focus {
    outline: none !important;
    border: 1px solid ${Primary};
    box-shadow: 0 0 2px ${Primary};
  }
`
