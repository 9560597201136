import {
  ExcelConversionFlow,
  GetExcelConversionFlowsRequest,
} from '@ulysses-inc/harami_api_client'
import Redux from 'redux'
import actions from './actions'

const getExcelConversionFlows = (
  dispatch: Redux.Dispatch,
  request: GetExcelConversionFlowsRequest,
) => {
  dispatch(actions.getExcelConversionFlows(request))
}

const changeExcelConversionFlowsPage = (
  dispatch: Redux.Dispatch,
  page: number,
  pageSize?: number,
) => {
  dispatch(actions.changeExcelConversionFlowsPage(page, pageSize))
}

const changeExcelConversionFlowsSize = (
  dispatch: Redux.Dispatch,
  pageSize: number,
) => {
  dispatch(actions.changeExcelConversionFlowsSize(pageSize))
}

const getExcelConversionFlow = (dispatch: Redux.Dispatch, flowId: string) => {
  dispatch(actions.getExcelConversionFlow(flowId))
}

const deleteExcelConversionFlow = (
  dispatch: Redux.Dispatch,
  flowId: string,
) => {
  dispatch(actions.deleteExcelConversionFlow(flowId))
}

const addExcelConversionFlow = (
  dispatch: Redux.Dispatch,
  flow: ExcelConversionFlow,
) => {
  dispatch(actions.addExcelConversionFlow(flow))
}

const updateExcelConversionFlow = (
  dispatch: Redux.Dispatch,
  flowId: string,
  flow: ExcelConversionFlow,
) => {
  dispatch(actions.updateExcelConversionFlow(flowId, flow))
}

const uploadAttachmentFile = (dispatch: Redux.Dispatch, file: File) => {
  dispatch(actions.uploadAttachmentFile(file))
}

const getSchedules = (dispatch: Redux.Dispatch) => {
  dispatch(actions.getSchedules())
}

const resetExcelConversionFlow = (dispatch: Redux.Dispatch) => {
  dispatch(actions.resetExcelConversionFlow())
}

const testExcelConversionFlow = (
  dispatch: Redux.Dispatch,
  flow: ExcelConversionFlow,
) => {
  dispatch(actions.testExcelConversionFlow(flow))
}

export default {
  getExcelConversionFlows,
  changeExcelConversionFlowsPage,
  changeExcelConversionFlowsSize,
  getExcelConversionFlow,
  deleteExcelConversionFlow,
  addExcelConversionFlow,
  updateExcelConversionFlow,
  uploadAttachmentFile,
  getSchedules,
  resetExcelConversionFlow,
  testExcelConversionFlow,
}
