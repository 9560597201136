import {
  MultipleChoice,
  NumberCondition,
  ReportNodeSchema,
  ResponseTypeEnum,
  TemplateNodeTypeEnum,
} from '@ulysses-inc/harami_api_client'
import getCalcNumberLogicType from 'src/exShared/hooks/reports/getCalcNumberLogicType'
import getLogicNodes from 'src/exShared/hooks/reports/getLogicNodes'
import { isAnswered } from 'src/exShared/util/report/isAnswered'

const filterNodesAnsweredBranch = (
  nodes: Array<ReportNodeSchema>,
  allNodes: { [key: number]: ReportNodeSchema },
): Array<ReportNodeSchema> => {
  // 後の判定で使用
  const sectionRepeat = 1
  const sectionEmployeeCheck = 1
  const questionRequired = 1
  // 後続の処理でnode.nodeを書き換えるので、reducerのオブジェクトを直接書き換えにようディープコピーする
  const nodesCopy: Array<ReportNodeSchema> = JSON.parse(JSON.stringify(nodes))
  return nodesCopy
    .filter((node: ReportNodeSchema) => !node.hide?.hide)
    .map((node: ReportNodeSchema) => {
      if (node.nodes.length === 0) return node
      if (node.type !== TemplateNodeTypeEnum.Question) return node
      if (
        !node.question?.responseAnswer ||
        node.question?.responseAnswer.noAnswer
      ) {
        // 回答がなしまたは回答が該当なしの場合は分岐先はないとみなす
        node.nodes = []
        return node
      }
      const parentNodes = node.nodes
      switch (node.question?.responseType) {
        case ResponseTypeEnum.MULTIPLE_CHOICE: {
          const multipleChoiceResponse =
            node.question?.responseAnswer?.multipleChoice?.response
          const responseMultipleChoices = getLogicNodes(
            parentNodes,
            allNodes,
          ).filter((node: ReportNodeSchema) => {
            if (
              node.logic?.responseMultipleChoices &&
              parentNodes.find(id => id === node.id)
            ) {
              return node.logic.responseMultipleChoices.some(
                (multipleChoice: MultipleChoice) =>
                  multipleChoice.response === multipleChoiceResponse,
              )
            }
            return false
          })
          // 選択した分岐先のnodeだけにする
          node.nodes = responseMultipleChoices.map(
            (node: ReportNodeSchema) => node.id,
          )
          return node
        }
        case ResponseTypeEnum.NUMBER: {
          const numberValue =
            node.question?.responseAnswer?.numberValue?.numberValue ?? 0
          const responseNumbers = node.question?.responseNumbers ?? []
          const responseNumber =
            responseNumbers.length > 0 ? responseNumbers[0] : undefined
          const numberLogicType = getCalcNumberLogicType(
            numberValue,
            responseNumber,
          )
          const numberConditions = getLogicNodes(parentNodes, allNodes).filter(
            (logicNode: ReportNodeSchema) => {
              if (
                logicNode.logic?.numberConditions &&
                parentNodes.find(id => id === logicNode.id)
              ) {
                return logicNode.logic.numberConditions.some(
                  (numberCondition: NumberCondition) =>
                    numberCondition.logicType === numberLogicType,
                )
              }
              return false
            },
          )
          // 選択した分岐先のnodeだけにする
          node.nodes = numberConditions.map((node: ReportNodeSchema) => node.id)
          return node
        }
        default:
          return node
      }
    })
    .filter((node: ReportNodeSchema) => {
      if (
        node.type === TemplateNodeTypeEnum.Section &&
        node.section?.isRepeat === sectionRepeat
      ) {
        return (
          !(
            node.section.isRepeat === sectionRepeat &&
            !node.section.originalNodeUuid
          ) ||
          (node.section.isEmployeeCheck === sectionEmployeeCheck &&
            !node.section?.originalNodeUuid)
        )
      }
      if (node.type === TemplateNodeTypeEnum.Question) {
        if (node.question?.isRequired === questionRequired) return true
        return node.question && isAnswered(node.question, true)
      }
      return true
    })
}

export default filterNodesAnsweredBranch
