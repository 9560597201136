import { useQuery } from '@tanstack/react-query'
import {
  ExportsApi,
  ReportExportStatusEnum,
} from '@ulysses-inc/harami_api_client'
import { queryKeys } from 'src/features/reportExport/list/api/queryKeys'
import { ReportExportListFilters } from 'src/features/reportExport/list/filters/useReportExportListFilters'
import { baseClient } from 'src/state/middleware/saga/baseClient'

export type ReportExport = {
  expiredAt: Date
  fileName: string
  id: number
  requestedAt: Date
  status: ReportExportStatusEnum
}

type ReportExportsQueryResult = {
  count: number
  reportExports: ReportExport[]
}

export const useQueryReportExports = (filters: ReportExportListFilters) => {
  return useQuery({
    queryKey: queryKeys.reportExports(filters),
    queryFn: () =>
      baseClient.getApi(ExportsApi).getReportsExports({
        limit: filters.pageSize,
        offset: (filters.page - 1) * filters.pageSize,
      }),
    select: (data): ReportExportsQueryResult => ({
      count: data.count,
      reportExports: data.results || [],
    }),
    // ユーザーがステータスの更新を自然と認識できるようにする
    refetchOnWindowFocus: 'always',
  })
}
