import {
  ReportNodeSchema,
  ReportPageSchema,
  TemplateNodeTypeEnum,
} from '@ulysses-inc/harami_api_client'
import React from 'react'
import { isInvalid } from 'src/exShared/util/report/isInvalid'
import { WebReportResultProps } from 'src/features/reports/result/WebReportResultProps'
import styled from 'styled-components'
import { DeviatedAnswersPage } from './DeviatedAnswersPage'

const SectionContent = styled.div`
  flex: 1;
  width: 100%;
`

const Section = styled.div`
  flex: 1;
  flex-direction: column;
  width: 100%;
  margin-top: 16;
`

const DeviatedHeader = styled.div`
  font-size: 20px;
  font-weight: bold;
  margin-top: 41px;
  margin-bottom: 8px;
`

export const DeviatedAnswers: React.FC<WebReportResultProps> = props => {
  const deviatedAnswers = Object.values(props.report.nodes).filter(node => {
    if (node.type !== TemplateNodeTypeEnum.Question) {
      return false
    }

    return isInvalid(node.question)
  })

  // Object.valuesはObjectのkeyがuint32の上限を超えると昇順が保証されなくなるため、ReportNodeIdの昇順でソートする
  // Notion: https://www.notion.so/harami_web-20231218-cb708f3ce48d482f9dc9123889fcf272
  const getDeviateAnswersPerPage = (page: ReportPageSchema) => {
    return Object.values(props.pageChildNodes(page))
      .filter(node => deviatedAnswers.includes(node))
      .sort((a, b) => a.id - b.id)
  }

  // Object.valuesはObjectのkeyがuint32の上限を超えると昇順が保証されなくなるため、ReportNodeIdの昇順でソートする
  // Notion: https://www.notion.so/harami_web-20231218-cb708f3ce48d482f9dc9123889fcf272
  const pageSections = (page: ReportPageSchema) => {
    return Object.values(props.pageChildNodes(page))
      .filter(p => p.type === TemplateNodeTypeEnum.Section)
      .sort((a, b) => a.id - b.id)
  }

  const deviatedAnswersPerPage = () => {
    return props
      .getPages()
      .filter(page => getDeviateAnswersPerPage(page).length > 0)
      .map(page => page)
  }

  const renderContent = () => {
    return (
      <SectionContent>
        {deviatedAnswersPerPage().map((page, index) => {
          // ページごとに存在しているセクション及びセクション内のセクションをすべて取得
          const sections: ReportNodeSchema[] = pageSections(page)
          return (
            <DeviatedAnswersPage
              key={index}
              {...props}
              index={index}
              page={page}
              answers={getDeviateAnswersPerPage(page)}
              sectionNodes={sections}
            />
          )
        })}
      </SectionContent>
    )
  }

  return (
    <>
      <DeviatedHeader
        // react-scrollによるスクロール時のターゲットとして利用されている
        id="DeviatedAnswers"
      >
        逸脱項目
      </DeviatedHeader>
      <Section>{renderContent()}</Section>
    </>
  )
}
