import {
  ReportStatusEnum,
  ScheduleListInRangeItem,
  ScheduleListInRangeItemInfo,
  ScheduleTypeEnum,
} from '@ulysses-inc/harami_api_client'
import { endOfDay, getDay } from 'date-fns'
import dateUtil from 'src/exShared/util/date'

export enum ScheduleStatus {
  COMPLETED = '◯',
  INCOMPLETE = '×',
  DEFAULT = '-',
}

export type DataItem = {
  key: string
  place: {
    uuid: string
    name: string
  }
  data: ScheduleStatus[]
  // ant-designのtableでcolumnとdataをkey名で紐付ける必要があるので、冗長だけど以下も定義する（上のdataと同じ内容が入る）
  sun: ScheduleStatus
  mon: ScheduleStatus
  tue: ScheduleStatus
  wed: ScheduleStatus
  thu: ScheduleStatus
  fri: ScheduleStatus
  sat: ScheduleStatus
}
export type DataSource = DataItem[]

const convertScheduleListInRange = (
  items: ScheduleListInRangeItem[],
): DataSource => {
  const result: { [placeNodeUUID: string]: DataItem } = {}
  items.forEach(item => {
    if (!item.targetDate || !item.infos || item.infos.length === 0) {
      return
    }
    const dayOfWeek = getDay(new Date(item.targetDate))
    const indexOfWeek = dayOfWeek === 0 ? 6 : dayOfWeek - 1
    const dayOfTheWeekEng = dateUtil
      .formatISODayOfWeek(new Date(item.targetDate))
      .toLocaleLowerCase()

    item.infos
      .filter(info => {
        if (info.scheduleType === ScheduleTypeEnum.Duration) {
          // 期間スケジュールの場合、期間の最終日のみチェック対象
          const formattedThrough = dateUtil.formatYYYYMMDD_hyphen_locale(
            info.scheduleInDuration.through,
          )
          return formattedThrough === item.targetDate
        }
        return true
      })
      .forEach((info: ScheduleListInRangeItemInfo) => {
        const placeNode = info.placeNode
        // 同一現場で既に、１回以上ステータス判定されているかどうか
        const hasResult = Object.prototype.hasOwnProperty.call(
          result,
          placeNode.uuid,
        )

        if (!hasResult) {
          result[placeNode.uuid] = {
            key: placeNode.uuid,
            place: { uuid: placeNode.uuid, name: placeNode.place.name },
            data: [
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
            ],
            sun: undefined,
            mon: undefined,
            tue: undefined,
            wed: undefined,
            thu: undefined,
            fri: undefined,
            sat: undefined,
          }
        }

        const beforeStatus = result[placeNode.uuid]?.[dayOfTheWeekEng]
        const newStatus = getScheduleStatus(info)
        const mergedStatus = getMergedStatus(beforeStatus, newStatus)
        result[placeNode.uuid].data[indexOfWeek] = mergedStatus
        result[placeNode.uuid][dayOfTheWeekEng] = mergedStatus
      })
  })
  return Object.values(result)
}

const getMergedStatus = (
  beforeStatus: ScheduleStatus | undefined,
  newStatus: ScheduleStatus,
) => {
  if (!beforeStatus) {
    return newStatus
  }

  if (
    beforeStatus === ScheduleStatus.INCOMPLETE ||
    newStatus === ScheduleStatus.INCOMPLETE
  ) {
    return ScheduleStatus.INCOMPLETE
  }

  if (beforeStatus === ScheduleStatus.DEFAULT) {
    return ScheduleStatus.DEFAULT
  }

  return newStatus
}

export const getScheduleStatus = (
  info: ScheduleListInRangeItemInfo,
): ScheduleStatus => {
  const hasReport = info.report && info.report.uuid
  const isScheduleCompleted =
    hasReport &&
    info.report.status.status !== ReportStatusEnum.INCOMPLETE &&
    info.report.status.status !== ReportStatusEnum.APPROVAL_REMAND

  if (isScheduleCompleted) {
    return ScheduleStatus.COMPLETED
  }

  // 以下、スケジュールが未開始or未完了の場合
  if (
    info.scheduleType === ScheduleTypeEnum.Date &&
    dateUtil.isToday(info.scheduleInDate.targetDate) &&
    dateUtil.isAfterNow(info.scheduleInDate.endTime)
  ) {
    // 日にちスケジュールの当日(今日)のスケジュールで、スケジュール終了時刻を経過していない
    return ScheduleStatus.DEFAULT
  }

  if (
    info.scheduleType === ScheduleTypeEnum.Date &&
    dateUtil.isAfterNow(info.scheduleInDate.targetDate)
  ) {
    // 日にちスケジュールで当日以降のスケジュールは「-」にする
    return ScheduleStatus.DEFAULT
  }

  if (
    info.scheduleType === ScheduleTypeEnum.Duration &&
    dateUtil.isAfterNow(endOfDay(info.scheduleInDuration.through))
  ) {
    // 期間スケジュールで、期間終了日を経過していない
    return ScheduleStatus.DEFAULT
  }

  if (
    info.scheduleType === ScheduleTypeEnum.Date &&
    dateUtil.isAfter(info.startReportDate, info.scheduleInDate.targetDate) &&
    !dateUtil.isSameDay(info.startReportDate, info.scheduleInDate.targetDate)
  ) {
    // レポート開始日が対象日付よりも後の日付なら、スケジュール開始日以前のスケジュールは「-」にする
    return ScheduleStatus.DEFAULT
  }

  if (
    info.scheduleType === ScheduleTypeEnum.Duration &&
    dateUtil.isAfter(info.startReportDate, info.scheduleInDuration.through)
  ) {
    // レポート開始日が対象日付よりも後の日付なら、スケジュール開始日以前のスケジュールは「-」にする
    return ScheduleStatus.DEFAULT
  }

  return ScheduleStatus.INCOMPLETE
}

export default convertScheduleListInRange
