// prettier-ignore
export const ActionTypes = {
  REQUEST_GET_TEMPLATES: 'harami/templates/REQUEST_GET_TEMPLATES',
  SUCCESS_GET_TEMPLATES: 'harami/templates/SUCCESS_GET_TEMPLATES',
  ERROR_GET_TEMPLATES: 'harami/templates/ERROR_GET_TEMPLATES',
  REQUEST_DELETE_TEMPLATE: 'harami/templates/REQUEST_DELETE_TEMPLATE',
  SUCCESS_DELETE_TEMPLATE: 'harami/templates/SUCCESS_DELETE_TEMPLATE',
  ERROR_DELETE_TEMPLATE: 'harami/templates/ERROR_DELETE_TEMPLATE',
  REQUEST_CHANGE_TEMPLATES_PAGE: 'harami/templates/REQUEST_CHANGE_TEMPLATES_PAGE',
  REQUEST_CHANGE_TEMPLATES_SIZE: 'harami/templates/REQUEST_CHANGE_TEMPLATES_SIZE',
  UPDATE_PAGINATION: 'harami/templates/UPDATE_PAGINATION',
  SET_TEMPLATE_NAME_ERROR_MESSAGE: 'harami/templates/SET_TEMPLATE_NAME_ERROR_MESSAGE',
  REQUEST_COPY_TEMPLATES: 'harami/templates/REQUEST_COPY_TEMPLATES',
  SUCCESS_COPY_TEMPLATES: 'harami/templates/SUCCESS_COPY_TEMPLATES',
  ERROR_COPY_TEMPLATES: 'harami/templates/ERROR_COPY_TEMPLATES',
  SET_TEMPLATE_DELETE_ERROR_TEMPLATE_ID: 'harami/templates/SET_TEMPLATE_DELETE_ERROR_TEMPLATE_ID',
  CLEAR_TEMPLATE_DELETE_ERROR_TEMPLATE_ID: 'harami/templates/CLEAR_TEMPLATE_DELETE_ERROR_TEMPLATE_ID',
} as const
