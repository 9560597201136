import { localStorageKeys } from 'src/constants/localStorageKeys'
import { LocalStorageService } from 'src/libraries/localStorage'

/**
 * スケジュール一覧取得時のフィルタ条件（人間が読みやすい形式）
 */
export interface SchedulesFilter {
  scheduleName?: string
  templateIds?: number[]
  placeNodeIds?: string[]
  placeGroupNodeIds?: string[]
  statuses?: number[]
}

type SchedulesListFilter = {
  pagination?: {
    limit?: number
    offset?: number
  }
  // フィルタの中にフィルタがネストしているのが気持ち悪いが、修正に大きなコストがかかるのでやらない
  filter?: SchedulesFilter
}

const localStorageService = new LocalStorageService<SchedulesListFilter>()

/**
 * useListFilter.ts の一部を切り出したもの。
 * 新たな利用や拡張は非推奨。
 */
export const schedulesListFilter = {
  get: () => {
    return localStorageService.getItem(localStorageKeys.listFilterSchedules)
  },
  set: (updatedFilters: SchedulesListFilter) => {
    localStorageService.setItem(
      localStorageKeys.listFilterSchedules,
      updatedFilters,
    )
  },
  reset: () => {
    const currentFilters = localStorageService.getItem(
      localStorageKeys.listFilterSchedules,
    )

    const newFilters = {
      ...currentFilters,
      pagination: { limit: 25, offset: 0 },
    }

    localStorageService.setItem(
      localStorageKeys.listFilterSchedules,
      newFilters,
    )
  },
}
