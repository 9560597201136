import range from 'lodash.range'
import rangeRight from 'lodash.rangeright'
import { CellMap } from '../types'

type Position = {
  rowNum: number
  colNum: number
}

/**
 * 2 つの座標を対角線上の頂点に持つ矩形範囲を求める
 *
 * @param param0 座標 A
 * @param param1 座標 B
 * @returns 矩形範囲
 */
export const calcRectangle = (
  { rowNum: rowNumA, colNum: colNumA }: Position,
  { rowNum: rowNumB, colNum: colNumB }: Position,
) => {
  // 縦の範囲を求める
  const rowRange =
    rowNumA < rowNumB
      ? range(rowNumA, rowNumB + 1)
      : rangeRight(rowNumA, rowNumB - 1)

  // 横の範囲を求める
  const colRange =
    colNumA < colNumB
      ? range(colNumA, colNumB + 1)
      : rangeRight(colNumA, colNumB - 1)

  // 矩形範囲を求める
  const outer: CellMap = {}
  for (const rowNum of rowRange) {
    const inner: { [colNum: number]: true } = {}
    for (const colNum of colRange) {
      inner[colNum] = true
    }
    outer[rowNum] = inner
  }

  return outer
}
