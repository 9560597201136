import { StyleSheet, Text } from '@react-pdf/renderer'
import { Approval, ApprovalFlow } from '@ulysses-inc/harami_api_client'
import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import date from 'src/exShared/util/date'
import { RootState } from 'src/state/store'
import Col from '../Col'
import Grid from '../Grid'

const styles = StyleSheet.create({
  textPadding: {
    padding: 5,
  },
  listTextPadding: {
    padding: '0 5px',
  },
})

const getApprovalsRate = (
  approval: Approval,
  approvalFlows: ApprovalFlow[],
) => {
  const approvalsLength =
    approval.steps?.filter(step => step.stepApprovers?.length ?? 0 > 0)
      .length ?? 0
  const approvalFlowsLength =
    approvalFlows.length > 0 ? approvalFlows[0]?.steps?.length : 0

  return `(${approvalsLength}/${approvalFlowsLength})`
}

interface OwnProps {
  approvals: Approval[]
  approvalFlows: ApprovalFlow[]
}

const Approvals: React.FC<OwnProps> = (props: OwnProps) => {
  const approval = useSelector(
    (state: RootState) => state.approvalsState.approval.approval,
    shallowEqual,
  )
  const approvalsStepsSteps = approval.steps ?? []

  // 承認フローは存在するが、承認が一つもない場合
  if (approvalsStepsSteps.length === 0) {
    if (props.approvalFlows.length > 0) {
      return (
        <Grid style={{ width: '100%' }}>
          <Col>
            <Text style={{ ...styles.textPadding }}>
              承認フロー {getApprovalsRate(approval, props.approvalFlows)}
            </Text>
            {props.approvalFlows[0]?.steps?.map((_, index) => {
              return (
                <Text key={index} style={{ ...styles.listTextPadding }}>
                  {index + 1}. 承認待ち
                </Text>
              )
            })}
          </Col>
        </Grid>
      )
    }
  }

  return (
    <Grid style={{ width: '100%' }}>
      <Col>
        <Text style={{ ...styles.textPadding }}>
          承認フロー {getApprovalsRate(approval, props.approvalFlows)}
        </Text>
        {approvalsStepsSteps.map((step, index) => {
          if ((step.stepApprovers || []).length > 0) {
            const stepId = step.id ?? 0
            const stepApprovalTime = (step.stepApprovalTimes ?? []).find(
              ({ stepId: id }) => id === stepId,
            )
            return (
              <Text
                key={index}
                style={{ ...styles.listTextPadding, width: '100%' }}
              >
                {index + 1}. 承認済み：{step.stepApprovers?.[0]?.name} (
                {date.formatYYYYMMDDHHMM(stepApprovalTime?.createdAt)})
              </Text>
            )
          }
          return (
            <Text
              key={index}
              style={{ ...styles.listTextPadding, width: '100%' }}
            >
              {index + 1}. 承認待ち
            </Text>
          )
        })}
      </Col>
    </Grid>
  )
}

export default Approvals
