import { configureStore } from '@reduxjs/toolkit'
import * as Sentry from '@sentry/browser'
import { createBrowserHistory } from 'history'
import { Action, Dispatch, Middleware, combineReducers } from 'redux'
import createSagaMiddleware from 'redux-saga'
import * as reducers from 'src/state/ducks/reducersForWeb'
import rootSaga from 'src/state/middleware/saga/sagasForWeb'
import getUserConfirmation from 'src/views/components/routes/getUserConfirmation'

const sagaMiddleware = createSagaMiddleware()

export const history = createBrowserHistory({ getUserConfirmation })

const crashReportMiddleware: Middleware =
  () => (next: Dispatch) => async action => {
    Sentry.addBreadcrumb({
      category: 'Action',
      message: action.type,
      data: {
        payload: JSON.stringify(action),
      },
    })
    return next(action)
  }

const appReducer = combineReducers(reducers)

export type RootState = ReturnType<typeof appReducer>

const CLEAR_ALL_STATE_ACTION = 'harami/CLEAR_ALL_STATE'

/**
 * 全てのstateを初期値にリセットするための特殊なアクション。ログアウト時に使用することを想定したもの。
 */
export const clearAllStateAction = () => {
  return { type: CLEAR_ALL_STATE_ACTION }
}

export const rootReducer = (state: RootState | undefined, action: Action) => {
  if (action.type === CLEAR_ALL_STATE_ACTION) {
    state = undefined
  }
  return appReducer(state, action)
}

export const store = configureStore({
  reducer: rootReducer,
  middleware: [crashReportMiddleware, sagaMiddleware],
})

// configureStore関数の実行後に呼び出す必要があるので注意
sagaMiddleware.run(rootSaga)
