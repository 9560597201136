/**
 * 対象のオブジェクトから、指定したキーと値の組み合わせを削除する
 *
 * @param originalObj 対象のオブジェクト
 * @param removingObj 対象のオブジェクトから除外したいキーと値を持つオブジェクト
 */
export const removeMatchingKeysAndValues = <T extends Record<string, unknown>>(
  originalObj: T,
  removingObj: Partial<T>,
): Partial<T> => {
  const result: T = { ...originalObj }

  for (const key in removingObj) {
    if (originalObj[key] === removingObj[key]) {
      delete result[key]
    }
  }

  return result
}
