import { StyleSheet, View } from '@react-pdf/renderer'
import React from 'react'
import BreakText from '../../BreakText'
import Grid from '../../Grid'
import RecordedTime from './RecordedTime'

interface OwnProps {
  questionName: string
  url: string
  recordedAt?: Date
  isTimeDisplayed: boolean
}

const styles = StyleSheet.create({
  gridText: { width: '100%' },
  recordedTimeWrap: { width: '100%', marginBottom: 10 },
})

export const URL: React.FC<OwnProps> = ({
  questionName,
  url,
  recordedAt,
  isTimeDisplayed,
}: OwnProps) => {
  return (
    <Grid borderLeft borderRight style={styles.gridText}>
      <View>
        <BreakText text={questionName} />
        <BreakText text={url} />
        <View style={styles.recordedTimeWrap}>
          <RecordedTime
            isTimeDisplayed={isTimeDisplayed}
            recordedAt={recordedAt}
          />
        </View>
      </View>
    </Grid>
  )
}
