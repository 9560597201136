// prettier-ignore
export const ActionTypes = {
  // GET_COMPANY_SETTINGS
  REQUEST_GET_COMPANY_SETTINGS: 'harami/companySettings/REQUEST_GET_COMPANY_SETTINGS',
  SUCCESS_GET_COMPANY_SETTINGS: 'harami/companySettings/SUCCESS_GET_COMPANY_SETTINGS',
  ERROR_GET_COMPANY_SETTINGS: 'harami/companySettings/ERROR_GET_COMPANY_SETTINGS',

  // UPDATE_COMPANY_SETTINGS
  REQUEST_UPDATE_COMPANY_SETTINGS: 'harami/companySettings/REQUEST_UPDATE_COMPANY_SETTINGS',
  SUCCESS_UPDATE_COMPANY_SETTINGS: 'harami/companySettings/SUCCESS_UPDATE_COMPANY_SETTINGS',
  ERROR_UPDATE_COMPANY_SETTINGS: 'harami/companySettings/ERROR_UPDATE_COMPANY_SETTINGS',
} as const
