import { DatabaseOutlined, PlusCircleOutlined } from '@ant-design/icons'
import {
  TemplateLayoutTypeEnum,
  TemplateNodeSchema,
  TemplateNodeTypeEnum,
} from '@ulysses-inc/harami_api_client'
import { Button } from 'antd'
import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { RootState } from 'src/state/store'
import styled, { css } from 'styled-components'

const ModifyButtonGroupContainer = styled.div`
  ${({ theme }) =>
    !theme.isOpen &&
    css`
      display: none;
    `}
`

const ModifyButtonGroupWrap = styled.div`
  display: flex;
  margin: 10px;
  height: auto;
  overflow: initial;
`

const ModifyButton = styled(Button)`
  flex: 1;
`

interface OwnProps {
  isOpen: boolean
  node: TemplateNodeSchema | null
  position: 'top' | 'bottom'
  parentNode: TemplateNodeSchema | null
  addTemplateNode: (
    targetNode: TemplateNodeSchema | null,
    parentNode: TemplateNodeSchema | null,
    position: 'top' | 'bottom',
    addTemplateNodeType: TemplateNodeTypeEnum,
  ) => void
}

const AddTemplateModifyButtonGroup: React.FC<OwnProps> = (props: OwnProps) => {
  const { layoutType } = useSelector(
    (state: RootState) => state.templatesState.editTemplate,
    shallowEqual,
  )

  return (
    <ModifyButtonGroupContainer theme={{ isOpen: props.isOpen }}>
      <ModifyButtonGroupWrap>
        <ModifyButton
          type="dashed"
          onMouseDown={() => {
            props.addTemplateNode(
              props.node,
              props.parentNode,
              props.position,
              TemplateNodeTypeEnum.Question,
            )
          }}
        >
          <PlusCircleOutlined />
          質問を追加
        </ModifyButton>
        {layoutType !== TemplateLayoutTypeEnum.Grid && (
          <ModifyButton
            type="dashed"
            onMouseDown={() => {
              props.addTemplateNode(
                props.node,
                props.parentNode,
                props.position,
                TemplateNodeTypeEnum.Section,
              )
            }}
          >
            <DatabaseOutlined />
            セクションを追加
          </ModifyButton>
        )}
      </ModifyButtonGroupWrap>
    </ModifyButtonGroupContainer>
  )
}

export default AddTemplateModifyButtonGroup
