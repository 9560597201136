import { GetTemplatesResponse } from '@ulysses-inc/harami_api_client'
import { GetTemplatesFilter } from 'src/exShared/types/types'
import { ActionTypes } from '../types'

const calcOffset = (page: number, pageSize?: number) =>
  pageSize ? (page - 1) * pageSize : 0

export const changeGridVariableTemplatesPage = (
  limit: number,
  offset: number,
  filter: GetTemplatesFilter,
) => {
  return {
    type: ActionTypes.REQUEST_CHANGE_GRID_VARIABLE_TEMPLATES_PAGE,
    isLoading: true,
    request: {
      limit,
      offset,
    },
    filter,
  }
}

export const getSuccessGridVariableTemplates = (
  response: GetTemplatesResponse,
) => {
  return {
    type: ActionTypes.SUCCESS_GET_GRID_VARIABLE_TEMPLATES,
    isLoading: false,
    templates: response.templates,

    // TODO: 型を合わせるために「|| 0」を追加。schema の定義を正しくすれば必要ないはず
    // https://kaminashi.atlassian.net/browse/HPB-2440 で対応する
    count: response.count || 0,
  }
}

export const getErrorGridVariableTemplates = (error: Error) => {
  return {
    type: ActionTypes.ERROR_GET_GRID_VARIABLE_TEMPLATES,
    isLoading: false,
    error,
  }
}

export const changeGridVariableTemplatesSize = (pageSize: number) => {
  return {
    type: ActionTypes.REQUEST_CHANGE_GRID_VARIABLE_TEMPLATES_SIZE,
    isLoading: true,
    request: {
      limit: pageSize,
      offset: 0,
    },
  }
}

export const updatePaginationWithPage = (page: number, pageSize?: number) => {
  const offset = calcOffset(page, pageSize)
  return {
    type: ActionTypes.UPDATE_PAGINATION,
    request: {
      limit: pageSize,
      offset,
    },
  }
}

export const updatePagination = (limit: number, offset: number) => {
  return {
    type: ActionTypes.UPDATE_PAGINATION,
    request: {
      limit,
      offset,
    },
  }
}

export const resetPagination = () => {
  return {
    type: ActionTypes.RESET_PAGINATION,
  }
}

export const updateFilter = (filter: GetTemplatesFilter) => {
  return {
    type: ActionTypes.UPDATE_FILTER,
    filter,
  }
}

export type GridVariableTemplatesAction =
  | ReturnType<typeof changeGridVariableTemplatesPage>
  | ReturnType<typeof getSuccessGridVariableTemplates>
  | ReturnType<typeof getErrorGridVariableTemplates>
  | ReturnType<typeof changeGridVariableTemplatesSize>
  | ReturnType<typeof updatePaginationWithPage>
  | ReturnType<typeof updatePagination>
  | ReturnType<typeof resetPagination>
  | ReturnType<typeof updateFilter>
