import { Text } from '@react-pdf/renderer'
import { FormulaValue } from '@ulysses-inc/harami_api_client'
import { FC } from 'react'
import { gridLayoutTableBodyCell } from '../../../shared/styles'

type Props = {
  value?: FormulaValue
  scale?: string
}

export const TableBodyCellFormula: FC<Props> = ({ value, scale }) => {
  return (
    <Text
      style={[
        gridLayoutTableBodyCell.baseTableBodyCell,
        gridLayoutTableBodyCell.tableBodyCell,
      ]}
    >
      {value?.formulaValue ? value.formulaValue + (scale ?? '') : '未計算'}
    </Text>
  )
}
