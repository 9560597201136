import { Collapse } from 'react-collapse'
import {
  SectionBackgroundColor,
  SectionColor,
} from 'src/features/theme/KdsThemeColor'
import styled from 'styled-components'

export const Table = styled.div`
  display: table;
  border-collapse: collapse;
  height: 46px;
  width: 100%;
  line-height: 46px;
`

export const TableCell = styled.div`
  display: table-cell;
  border: 1px solid rgba(0, 0, 0, 0.12);
  background: ${SectionBackgroundColor};
`

export const SectionTableCell = styled(TableCell)`
  width: ${({ theme }) => theme.width};
`

export const SectionWrapper = styled.div`
  display: flex;
  height: 46px;
  line-height: 46px;
`

export const SectionNameParagraphWrapper = styled.div`
  flex: 1;
  padding: 0 10px;
`

export const SectionNameParagraph = styled.div`
  width: 100%;
  padding-left: 10px;
  color: ${SectionColor};
`

export const SectionContentWrapper = styled(Collapse)`
  border: 0;
`
