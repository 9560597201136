import {
  AddImproveExcelConvertsRequest,
  GetImproveExcelConvertsRequest,
  ImprovesApi,
} from '@ulysses-inc/harami_api_client'
import { call, put, takeEvery } from 'redux-saga/effects'
import { downloadFile } from 'src/state/ducks/download/sagas'
import interceptionsActions from 'src/state/ducks/interceptions/actions'
import BaseClient from 'src/state/middleware/saga/baseClient'
import { handleHTTPError } from 'src/state/middleware/saga/handleHttpError'
import { default as actions } from './actions'
import { ActionTypes } from './types'

const baseClient = new BaseClient()

const getImproveExcelConversionLogsRequest = (
  req: GetImproveExcelConvertsRequest,
) => {
  return baseClient
    .getApi(ImprovesApi)
    .getImproveExcelConverts(req)
    .then(logs => logs)
    .catch(handleHTTPError)
}

const addImproveExcelConvertsRequest = (
  req: AddImproveExcelConvertsRequest,
) => {
  return baseClient
    .getApi(ImprovesApi)
    .addImproveExcelConvertsRaw(req)
    .catch(handleHTTPError)
}

function* getImproveExcelConversionLogs(
  action: ReturnType<typeof actions.getImproveExcelConversionLogs>,
) {
  try {
    let params = action.request
    params = {
      ...params,
      ...BaseClient.convertQueryParameter(action.filter || {}),
    }
    const response = yield call(getImproveExcelConversionLogsRequest, params)
    yield put(actions.getSuccessImproveExcelConversionLogs(response))
  } catch (error) {
    yield put(interceptionsActions.handleHttpError(error))
    yield put(actions.getErrorImproveExcelConversionLogs(error))
  }
}

function* getImproveExcelConversionLogFiles(
  action: ReturnType<typeof actions.getImproveExcelConversionLogFiles>,
) {
  try {
    yield call(
      downloadFile,
      'excelConversionLogs',
      JSON.stringify({ fileUUIDs: action.fileUUIDs }),
    )
  } catch (error) {
    yield put(interceptionsActions.handleHttpError(error))
  }
}

function* addImproveExcelConversionLogs(
  action: ReturnType<typeof actions.addImproveExcelConversionLogs>,
) {
  try {
    const params = action.request
    yield call(addImproveExcelConvertsRequest, params)
    const response = yield call(getImproveExcelConversionLogsRequest, {
      improveUUID: action.request.improveUUID,
    })
    yield call(
      downloadFile,
      'excelConversionLogs',
      JSON.stringify({
        fileUUIDs: [response.excelConversionLogs[0].logFile.file.uuid],
      }),
    )
  } catch (error) {
    yield put(interceptionsActions.handleHttpError(error))
    yield put(actions.addErrorImproveExcelConversionLogs(error))
  }
}

const sagas = [
  takeEvery(
    ActionTypes.REQUEST_GET_IMPROVE_EXCEL_CONVERSION_LOGS,
    getImproveExcelConversionLogs,
  ),
  takeEvery(
    ActionTypes.REQUEST_CHANGE_IMPROVE_EXCEL_CONVERSION_LOGS_PAGE,
    getImproveExcelConversionLogs,
  ),
  takeEvery(
    ActionTypes.REQUEST_CHANGE_IMPROVE_EXCEL_CONVERSION_LOGS_SIZE,
    getImproveExcelConversionLogs,
  ),
  takeEvery(
    ActionTypes.REQUEST_GET_IMPROVE_EXCEL_CONVERSION_LOG_FILES,
    getImproveExcelConversionLogFiles,
  ),
  takeEvery(
    ActionTypes.REQUEST_ADD_IMPROVE_EXCEL_CONVERSION_LOGS,
    addImproveExcelConversionLogs,
  ),
]

export default sagas
