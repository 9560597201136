import { DownOutlined, RightOutlined } from '@ant-design/icons'
import {
  ReportPageSchema,
  TemplateLayoutTypeEnum,
  TemplateNodeTypeEnum,
} from '@ulysses-inc/harami_api_client'
import React, { JSX, useState } from 'react'
import getCalculateScorePercentage from 'src/exShared/hooks/reports/getCalculateScorePercentage'
import { getDescendantNodes } from 'src/exShared/util/nodes/getDescendantNodes'
import { WebReportResultProps } from 'src/features/reports/result/WebReportResultProps'
import styled from 'styled-components'
import { PageContentForGridLayout } from './PageContentForGridLayout'
import { Question } from './Question'
import { Section } from './Section'

interface OwnProps {
  index: number
  page: ReportPageSchema
}

const PageStyle = styled.div`
  width: 100%;
  margin-top: 26px;
`

const PageContent = styled.div`
  width: 100%;
`

const PageName = styled.span`
  flex: 1;
  font-size: 16px;
  font-weight: bold;
`

const Arrow = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  margin-bottom: 12px;
  width: 100%;
`

const ResultScore = styled.span`
  font-color: rgba(0, 0, 0, 0);
`

export const Page: React.FC<WebReportResultProps & OwnProps> = (
  props: WebReportResultProps & OwnProps,
) => {
  const [expanded, setExpanded] = useState(true)

  const isGridLayout = props.report.layoutType === TemplateLayoutTypeEnum.Grid

  const renderArrowIcon: () => JSX.Element = () => {
    return expanded ? (
      <DownOutlined style={{ marginRight: 10.6 }} />
    ) : (
      <RightOutlined style={{ marginRight: 10.6 }} />
    )
  }

  const renderPageContent = () => {
    return (
      <PageContent>
        {props.getReportPageNodes(props.page).map((node, index) => {
          if (node.type === TemplateNodeTypeEnum.Section)
            return <Section key={index} sectionNode={node} {...props} />
          if (node.type === TemplateNodeTypeEnum.Question)
            return (
              <Question
                key={`${props.page.id}-Question-${index}`}
                node={node}
                question={node.question}
                {...props}
              />
            )
          return <></>
        })}
      </PageContent>
    )
  }

  const handleOnPressHeader = () => {
    setExpanded(!expanded)
  }

  const { dictOfNode: pageChildNodes } = getDescendantNodes(
    props.page.nodes ?? [],
    props.report.nodes,
    {
      ignoreRepeatOriginalSection: true,
    },
  )
  const { sumAllTotalScores, sumAllResultScores, calculateScorePercentage } =
    getCalculateScorePercentage(pageChildNodes)
  const renderHeaderContent = () => {
    return (
      <>
        {renderArrowIcon()}
        <PageName>{props.page.name || `ページ${props.index + 1}`}</PageName>
        {props.report.isShowScore && (
          <ResultScore>
            {sumAllResultScores}/{sumAllTotalScores}({calculateScorePercentage}
            %)
          </ResultScore>
        )}
      </>
    )
  }

  return (
    <>
      <PageStyle id={`Page-${props.page.id}`}>
        <Arrow onClick={handleOnPressHeader}>{renderHeaderContent()}</Arrow>
        {expanded && !isGridLayout && renderPageContent()}
        {expanded && isGridLayout && (
          <PageContentForGridLayout pageId={props.page.id ?? 0} />
        )}
      </PageStyle>
    </>
  )
}
