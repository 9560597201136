import { GetEmployeesRequest, PlaceNode } from '@ulysses-inc/harami_api_client'
import { Button, Dropdown, Input, Select, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import { useDebounce } from 'src/exShared/hooks/useDebounce'
import { splitAccessiblePlaceNode } from 'src/exShared/libraries/filterDropDown/place'
import { GetTemplatesFilter } from 'src/exShared/types/types'
import { FlatNode } from 'src/exShared/util/place/flattenNodePath'
import { splitLoginPlaceUUIDs } from 'src/exShared/util/place/splitLoginPlace'
import { getFilter } from 'src/exShared/util/template/getFilter'
import { useListFilter } from 'src/hooks/filter/useListFilter'
import { TemplatesFilter } from 'src/views/types/utils/ListFilter'
import {
  DownOutlinedIcon,
  DropDownTableCell,
  FilterButton,
  SelectFilterTableCell,
  UpOutlinedIcon,
  styles,
} from './TemplatesFilterDropDown.dumb'

const { Text } = Typography
const { Option } = Select

interface OwnProps {
  places: PlaceNode[]
  placeNodes: PlaceNode[]
  updateTemplatesFilter: (filter?: GetTemplatesFilter) => void
  flattenNodePath: FlatNode[]
  isLoadingPlace?: boolean
  templateGetTrigger?: boolean
  request?: GetEmployeesRequest
}

const TemplatesFilterDropDown: React.FC<OwnProps> = ({
  places,
  placeNodes,
  updateTemplatesFilter,
  flattenNodePath,
  isLoadingPlace,
  templateGetTrigger,
  request,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [placeNodeIds, setPlaceNodeIds] = useState<string[]>([])
  const [placeGroupNodeIds, setPlaceGroupNodeIds] = useState<string[]>([])
  const { filters, setListFilter } = useListFilter()
  const [templateName, setTemplateName] = useState<string>('')
  const { loginPlaceGroupIds, loginPlaceIds } = splitLoginPlaceUUIDs()

  const debounce = useDebounce(300)

  const saveFilter = (conditions: TemplatesFilter) => {
    setListFilter({
      ...(filters ?? {}),
      gridVariables: {
        pagination: request,
        filter: conditions,
      },
    })
  }

  const getTemplateFilter = (conditions: TemplatesFilter) => {
    saveFilter(conditions)
    return getFilter(placeNodes, flattenNodePath, conditions)
  }

  const isFilterOn = () => {
    return (
      templateName || placeGroupNodeIds.length > 0 || placeNodeIds.length > 0
    )
  }

  const clearFilter = () => {
    const conditions = {
      templateName: '',
      placeNodeIds: loginPlaceIds,
      placeGroupNodeIds: loginPlaceGroupIds,
    }
    setTemplateName('')
    setPlaceNodeIds([])
    setPlaceGroupNodeIds([])
    saveFilter(conditions)
    const filter = getTemplateFilter(conditions)
    updateTemplatesFilter(filter)
  }

  useEffect(() => {
    const loginPlaceUUIDs = splitLoginPlaceUUIDs()
    setPlaceNodeIds(loginPlaceUUIDs.loginPlaceIds)
    setPlaceGroupNodeIds(loginPlaceUUIDs.loginPlaceGroupIds)
  }, [])

  useEffect(() => {
    if (!templateGetTrigger || isLoadingPlace) {
      return
    }
    if (!filters || !filters.gridVariables) {
      setTemplateName('')
      setPlaceNodeIds(loginPlaceIds || [])
      setPlaceGroupNodeIds(loginPlaceGroupIds || [])

      const filter =
        loginPlaceIds.length || loginPlaceGroupIds
          ? getTemplateFilter({
              placeNodeIds: loginPlaceIds,
              placeGroupNodeIds: loginPlaceGroupIds,
            })
          : undefined
      updateTemplatesFilter(filter)
      return
    }
    const gridVariablesFilter = filters.gridVariables.filter
    let filterPlaceNodeIds: string[] = []
    let filterPlaceGroupNodeIds: string[] = []
    if (
      (gridVariablesFilter?.placeNodeIds ?? []).length > 0 ||
      (gridVariablesFilter?.placeGroupNodeIds ?? []).length > 0
    ) {
      filterPlaceNodeIds = gridVariablesFilter?.placeNodeIds ?? []
      filterPlaceGroupNodeIds = gridVariablesFilter?.placeGroupNodeIds ?? []
    } else {
      filterPlaceNodeIds = loginPlaceIds
      filterPlaceGroupNodeIds = loginPlaceGroupIds
    }
    setTemplateName(filters.gridVariables.filter?.templateName ?? '')
    setPlaceNodeIds(filterPlaceNodeIds)
    setPlaceGroupNodeIds(filterPlaceGroupNodeIds)
    const filter = getTemplateFilter({
      templateName: filters.gridVariables.filter?.templateName,
      placeNodeIds: filterPlaceNodeIds,
      placeGroupNodeIds: filterPlaceGroupNodeIds,
    })
    updateTemplatesFilter(filter)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templateGetTrigger, placeNodes.length])

  const accessiblePlaceNode = splitAccessiblePlaceNode(placeNodes, places)

  const handleTemplateNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTemplateName(e.target.value)
    debounce(() => {
      const filter = getTemplateFilter({
        templateName: e.target.value,
        placeNodeIds: placeNodeIds,
        placeGroupNodeIds: placeGroupNodeIds,
      })
      updateTemplatesFilter(filter)
    })
  }

  const groundArea = (
    <SelectFilterTableCell theme={{ width: '160px' }}>
      <FilterButton>
        <div style={{ flex: 1, marginLeft: 12 }}>
          フィルター：{isFilterOn() ? 'ON' : 'OFF'}
        </div>
        <div style={{ minWidth: 20, marginRight: 12 }}>
          {isOpen ? <UpOutlinedIcon /> : <DownOutlinedIcon />}
        </div>
      </FilterButton>
    </SelectFilterTableCell>
  )

  const hoverArea = (
    <div style={{ display: 'flex' }}>
      <DropDownTableCell>
        <div css={styles.clearButtonRow}>
          <Button onClick={clearFilter}>クリア</Button>
        </div>
        <div style={{ marginBottom: 5 }}>
          <Text style={{ marginLeft: 3 }}>ひな形名</Text>
        </div>
        <Input
          allowClear
          onChange={handleTemplateNameChange}
          value={templateName}
        />
        <div style={{ marginTop: 16, marginBottom: 5 }}>
          <Text style={{ marginLeft: 3 }}>現場グループ名</Text>
        </div>
        <Select
          mode="multiple"
          style={{ width: '100%' }}
          value={placeGroupNodeIds}
          optionFilterProp="label"
          onChange={(selectedPlaceGroupIds: string[]) => {
            setPlaceGroupNodeIds(selectedPlaceGroupIds)
            const filter = getTemplateFilter({
              templateName: templateName,
              placeNodeIds: placeNodeIds,
              placeGroupNodeIds: selectedPlaceGroupIds,
            })
            updateTemplatesFilter(filter)
          }}
        >
          {accessiblePlaceNode.accessiblePlaceGroups.map(
            (placeNode: PlaceNode, index: number) => (
              <Option
                key={index}
                value={placeNode.uuid}
                label={placeNode.placeGroup?.name}
              >
                {placeNode.placeGroup?.name}
              </Option>
            ),
          )}
        </Select>
        <div style={{ marginTop: 16, marginBottom: 5 }}>
          <Text style={{ marginLeft: 3 }}>現場名</Text>
        </div>
        <Select
          mode="multiple"
          style={{ width: '100%' }}
          value={placeNodeIds}
          optionFilterProp="label"
          onChange={(selectedPlaceNodeIds: string[]) => {
            setPlaceNodeIds(selectedPlaceNodeIds)
            const filter = getTemplateFilter({
              templateName: templateName,
              placeNodeIds: selectedPlaceNodeIds,
              placeGroupNodeIds: placeGroupNodeIds,
            })
            updateTemplatesFilter(filter)
          }}
        >
          {accessiblePlaceNode.accessiblePlaces.map(
            (placeNode: PlaceNode, index: number) => (
              <Option
                key={index}
                value={placeNode.uuid}
                label={placeNode.place?.name}
              >
                {placeNode.place?.name}
              </Option>
            ),
          )}
        </Select>
      </DropDownTableCell>
    </div>
  )

  return (
    <Dropdown
      dropdownRender={() => hoverArea}
      trigger={['click']}
      open={isOpen}
      onOpenChange={setIsOpen}
    >
      {groundArea}
    </Dropdown>
  )
}

export default TemplatesFilterDropDown
