import { DeleteOutlined } from '@ant-design/icons'
import {
  MultipleChoice,
  TemplateNodeSchema,
} from '@ulysses-inc/harami_api_client'
import { Button } from 'antd'
import { FC } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import StyledIndentation from 'src/features/templateEdit/common/StyledIndentation'
import { getRelatedExcelConversionFlowInLogic } from 'src/features/templateEdit/common/getRelatedExcelConversionFlow'
import {
  DeleteIconColor,
  LogicBackgroundColor,
} from 'src/features/theme/KdsThemeColor'
import { deleteTemplateNode } from 'src/state/ducks/templates/actions'
import { RootState } from 'src/state/store'
import DeleteConfirmDialog from 'src/views/components/dialogs/DeleteConfirmDialog'
import {
  ConfirmModalContentList,
  HeaderText,
  LogicHeader,
  LogicWrapper,
} from './Logic.styled'
import LogicContent from './LogicContent'

const MultipleChoiceLogic: FC<{
  id: number
  parentNode: TemplateNodeSchema
  node: TemplateNodeSchema
  nodes: number[]
  path: number[]
  responseMultipleChoices: MultipleChoice[]
}> = ({ id, parentNode, node, nodes, path, responseMultipleChoices }) => {
  const templateNodes = useSelector(
    (state: RootState) => state.templatesState.templatePages.templateNodes,
    shallowEqual,
  )
  const excelConversionFlows = useSelector(
    (state: RootState) =>
      state.templatesState.editTemplate.excelConversionFlows,
    shallowEqual,
  )
  const flowNames = getRelatedExcelConversionFlowInLogic(
    id,
    templateNodes,
    excelConversionFlows,
  )

  const dispatch = useDispatch()

  const onClick = () => {
    const content: Array<React.ReactNode> = []

    if (nodes.length) {
      content.push('紐づく質問やセクションも一緒に削除されます。')
    }

    if (flowNames.length) {
      !!content.length &&
        content.push(
          <>
            <br />
            <br />
          </>,
        )

      content.push(
        <>
          以下のExcel変換設定に紐づいています。
          <ConfirmModalContentList>
            {flowNames.map((name, index) => (
              <li key={index}>{name}</li>
            ))}
          </ConfirmModalContentList>
        </>,
      )
    }

    DeleteConfirmDialog({
      title: '条件分岐を削除してもよろしいですか？',
      content,
      onOk() {
        dispatch(deleteTemplateNode(id, parentNode))
      },
    })
  }

  const names = responseMultipleChoices
    .map(({ response }) => response)
    .reduce((cur, prev) => `${cur}, ${prev}`)

  return (
    <LogicWrapper>
      <LogicHeader>
        <HeaderText strong>「{names}」を選択した場合</HeaderText>
        <Button type="link" onClick={onClick}>
          <DeleteOutlined style={{ color: DeleteIconColor }} />
        </Button>
      </LogicHeader>
      <div style={{ display: 'flex' }}>
        <StyledIndentation color={LogicBackgroundColor} />
        <LogicContent node={node} path={path} />
      </div>
    </LogicWrapper>
  )
}

export default MultipleChoiceLogic
