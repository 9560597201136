import {
  MultipleChoiceSet,
  ResponseSet,
  ResponseSetExcelOutputSettingEnum,
} from '@ulysses-inc/harami_api_client'
import { FormikErrors, FormikHelpers, FormikTouched, useFormik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { UUID } from 'src/exShared/util/uuid'
import Yup from 'src/features/validation/yup'
import {
  addMultipleChoiceSet,
  updateMultipleChoiceSet,
} from 'src/state/ducks/multipleChoiceSets/actions'
import { RootState } from 'src/state/store'
import { Modify } from 'src/views/types/utils/Modify'
import { ResponseSetForm } from '../ResponseSetTypes'

export interface IFormikProps {
  handleSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void
  setFieldValue: (
    field: keyof MultipleChoiceSetForm,
    value: MultipleChoiceSetForm[keyof MultipleChoiceSetForm],
  ) => Promise<FormikErrors<MultipleChoiceSetForm> | void>
  setFieldError: (
    field: keyof MultipleChoiceSetForm,
    value: string | undefined,
  ) => void
  setFieldTouched: (
    field: keyof MultipleChoiceSetForm,
    touched?: boolean,
    shouldValidate?: boolean | undefined,
  ) => Promise<FormikErrors<MultipleChoiceSetForm> | void>
  values: MultipleChoiceSetForm
  errors: FormikErrors<MultipleChoiceSetForm>
  touched: FormikTouched<MultipleChoiceSetForm>
}

type MultipleChoiceSetForm = Modify<
  MultipleChoiceSet,
  { responses: ResponseSetForm[] }
>

const validationSchema = Yup.object().shape({
  name: Yup.string().required().label('セット選択肢名'),
  excelOutputOption: Yup.string().required().label('EXCEL出力設定'),
})

const newValues: MultipleChoiceSetForm = {
  name: '',
  excelOutputOption: ResponseSetExcelOutputSettingEnum.Name,
  responses: [],
}

let formikProps: IFormikProps | null = null

// return type に FormikProps<MultipleChoiceSetForm> とすることもできるが、IFormikProps で定義しているよりも緩く安全でない
// ReturnType<typeof useFormik<MultipleChoiceSetForm>> は ts の仕様としてできない
export const useFormikProps = (
  isNew = false,
): { formikProps: IFormikProps; resetFormikProps: () => void } => {
  const dispatch = useDispatch()
  const multipleChoiceSet = useSelector(
    (state: RootState) =>
      state.multipleChoiceSetsState.multipleChoiceSet.multipleChoiceSet,
  )
  const { multipleChoiceSetId } = useParams<{ multipleChoiceSetId: string }>()

  const isUpdate =
    multipleChoiceSetId !== undefined && multipleChoiceSetId !== ''

  const f = useFormik<MultipleChoiceSetForm>({
    initialValues: isUpdate
      ? {
          name: multipleChoiceSet?.name ?? '',
          excelOutputOption: multipleChoiceSet?.excelOutputOption,
          responses:
            multipleChoiceSet?.responses?.map((response, index) => ({
              ...response,
              key: index + 1,
              categories: response.categories?.map(({ name }, i) => ({
                name,
                key: `response${index}-category${i}`,
              })),
            })) ?? [],
        }
      : newValues,
    validationSchema,
    onSubmit: (
      values: MultipleChoiceSetForm,
      { resetForm }: FormikHelpers<MultipleChoiceSetForm>,
    ) => {
      const multipleChoiceSet: MultipleChoiceSet = {
        ...values,
        id: undefined,
        uuid: isUpdate ? multipleChoiceSetId : UUID(),
        responses: values.responses?.map(
          ({ name, discriminationKey, categories }): ResponseSet => ({
            uuid: UUID(),
            name,
            discriminationKey,
            categories,
          }),
        ),
      }

      dispatch(
        isUpdate
          ? updateMultipleChoiceSet(
              multipleChoiceSetId ?? '',
              multipleChoiceSet,
            )
          : addMultipleChoiceSet(multipleChoiceSet),
      )

      resetForm()
    },
    enableReinitialize: true,
  })

  if (isNew || formikProps === null) {
    formikProps = f
  }

  // useEffect の return function 内で実行するようにし、unmount 時に formikProps を初期化する
  const resetFormikProps = () => {
    formikProps = null
  }

  return { formikProps, resetFormikProps }
}
