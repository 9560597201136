import { FC } from 'react'
import EditManualsTable from 'src/features/templateEdit/editManual/EditManualsTable'
import {
  BasicRow,
  TableCollapse,
  TablePanel,
} from './EditTemplateOption.styled'

const ManualRow: FC = () => (
  <BasicRow>
    <TableCollapse bordered={false} accordion defaultActiveKey={[1]}>
      <TablePanel
        key={1}
        header={'マニュアルの追加'}
        data-testid="manual-table"
      >
        <EditManualsTable />
      </TablePanel>
    </TableCollapse>
  </BasicRow>
)

export default ManualRow
