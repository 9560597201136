import React from 'react'
import styled from 'styled-components'

const Indentation = styled.div`
  width: 5px;
  margin: 5px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.backgroundColor};
`

interface OwnProps {
  color?: string
}

// セクションや条件分岐の区切りを表現するインデントのコンポーネント
const StyledIndentation: React.FC<OwnProps> = (props: OwnProps) => {
  return <Indentation theme={{ backgroundColor: props.color ?? '#f48f47' }} />
}

export default StyledIndentation
