import {
  GetTemplatesResponse,
  MultipleChoice,
  MultipleChoiceGroup,
} from '@ulysses-inc/harami_api_client'
import { ActionTypes } from 'src/state/ducks/templates/responseMultipleChoices/types'

/**
 * 選択肢をサーバーに新規登録する
 */
export const addMultipleChoices = (multipleChoices: Array<MultipleChoice>) => {
  return {
    type: ActionTypes.REQUEST_ADD_MULTIPLE_CHOICES,
    isLoading: true,
    multipleChoices,
  }
}

export const addSuccessMultipleChoices = () => {
  return {
    type: ActionTypes.SUCCESS_ADD_MULTIPLE_CHOICES,
    isLoading: false,
  }
}

export const addErrorMultipleChoices = (error: Error) => {
  return {
    type: ActionTypes.ERROR_ADD_MULTIPLE_CHOICES,
    isLoading: false,
    error: error,
  }
}

/**
 * 既存の選択肢をサーバーに更新登録する
 */
export const updateMultipleChoices = (
  multipleChoiceId: number,
  multipleChoices: Array<MultipleChoice>,
) => {
  return {
    type: ActionTypes.REQUEST_UPDATE_MULTIPLE_CHOICES,
    isLoading: true,
    multipleChoiceId,
    multipleChoices,
  }
}

export const updateSuccessMultipleChoices = (
  multipleChoiceId: number,
  multipleChoices: Array<MultipleChoice>,
) => {
  return {
    type: ActionTypes.SUCCESS_UPDATE_MULTIPLE_CHOICES,
    isLoading: false,
    multipleChoiceId,
    multipleChoices,
  }
}

export const updateErrorMultipleChoices = (error: Error) => {
  return {
    type: ActionTypes.ERROR_UPDATE_MULTIPLE_CHOICES,
    isLoading: false,
    error: error,
  }
}

/**
 * 選択肢をサーバーから削除する
 */
export const deleteMultipleChoices = (multipleChoiceId: number) => {
  return {
    type: ActionTypes.REQUEST_DELETE_MULTIPLE_CHOICES,
    isLoading: true,
    multipleChoiceId,
  }
}

export const deleteSuccessMultipleChoices = (multipleChoiceId: number) => {
  return {
    type: ActionTypes.SUCCESS_DELETE_MULTIPLE_CHOICES,
    isLoading: false,
    multipleChoiceId,
  }
}

export const deleteErrorMultipleChoices = (error: Error) => {
  return {
    type: ActionTypes.ERROR_DELETE_MULTIPLE_CHOICES,
    isLoading: false,
    error: error,
  }
}

export const getMultipleChoices = () => {
  return {
    type: ActionTypes.REQUEST_GET_MULTIPLE_CHOICES,
    isLoading: true,
  }
}

export const getSuccessMultipleChoices = (
  multipleChoiceGroups: Array<MultipleChoiceGroup>,
) => {
  return {
    type: ActionTypes.SUCCESS_GET_MULTIPLE_CHOICES,
    isLoading: false,
    multipleChoiceGroups,
  }
}

export const getErrorMultipleChoices = (error: Error) => {
  return {
    type: ActionTypes.ERROR_GET_MULTIPLE_CHOICES,
    isLoading: false,
    error: error,
  }
}

/**
 * 指定した選択肢を利用しているひな形の一覧を取得する
 */
export const getTemplatesByMultipleChoiceGroup = (
  multipleChoiceGroupId: number,
) => {
  return {
    type: ActionTypes.REQUEST_GET_TEMPLATES_BY_MULTIPLE_CHOICE_GROUP,
    multipleChoiceGroupId,
    isLoading: true,
  }
}

export const getSuccessTemplatesByMultipleChoiceGroup = (
  response: GetTemplatesResponse,
) => {
  return {
    type: ActionTypes.SUCCESS_GET_TEMPLATES_BY_MULTIPLE_CHOICE_GROUP,
    templates: response.templates || [],
    isLoading: false,
  }
}

export const getErrorTemplatesByMultipleChoiceGroup = (error: Error) => {
  return {
    type: ActionTypes.ERROR_GET_TEMPLATES_BY_MULTIPLE_CHOICE_GROUP,
    error,
    isLoading: false,
  }
}

/**
 * 選択肢を利用しているひな形の一覧をリセットする
 *
 * `getTemplatesByMultipleChoiceGroup()` で取得した情報をクリアするもの
 */
export const resetRelatedTemplates = () => {
  return {
    type: ActionTypes.RESET_RELATED_TEMPLATES,
  }
}

export type ResponseMultipleChoiceAction =
  | ReturnType<typeof addMultipleChoices>
  | ReturnType<typeof addSuccessMultipleChoices>
  | ReturnType<typeof addErrorMultipleChoices>
  | ReturnType<typeof updateMultipleChoices>
  | ReturnType<typeof updateSuccessMultipleChoices>
  | ReturnType<typeof updateErrorMultipleChoices>
  | ReturnType<typeof deleteMultipleChoices>
  | ReturnType<typeof deleteSuccessMultipleChoices>
  | ReturnType<typeof deleteErrorMultipleChoices>
  | ReturnType<typeof getMultipleChoices>
  | ReturnType<typeof getSuccessMultipleChoices>
  | ReturnType<typeof getErrorMultipleChoices>
  | ReturnType<typeof getTemplatesByMultipleChoiceGroup>
  | ReturnType<typeof getSuccessTemplatesByMultipleChoiceGroup>
  | ReturnType<typeof getErrorTemplatesByMultipleChoiceGroup>
  | ReturnType<typeof resetRelatedTemplates>
