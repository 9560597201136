import dateUtil from 'src/exShared/util/date'

/** 取り込み項目数 */
export const GRID_VARIABLES_LENGTH = 6

/** 列数は、開始日・終了日・セクション・取り込みセクション・取り込み項目 1~6 で 10 個 */
export const COL_COUNT = 10

/** ダイナミック帳票 phase 3 時点では、テーブルの行数を 1000 に固定する */
export const ROW_COUNT = 1000

/** 適用開始日に指定できる最小日時 */
export const MIN_START_DATE = dateUtil.parse(
  '2023/01/01 00:00',
  'yyyy/MM/dd HH:mm',
)

/** 適用終了日に指定できる最大日時 */
export const MAX_END_DATE = dateUtil.parse(
  '2099/12/31 23:59',
  'yyyy/MM/dd HH:mm',
)
