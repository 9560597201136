import { css } from '@emotion/react'
import { Spin } from 'antd'

export const RevalidationSpinner = () => {
  return (
    <div css={styles.container}>
      <Spin />
    </div>
  )
}

const styles = {
  container: css`
    position: fixed;
    top: 8px;
    right: 8px;
  `,
}
