import { TemplateIconTypeEnum } from '@ulysses-inc/harami_api_client'
import { FC } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { White } from 'src/features/theme/KdsThemeColor'
import { updateTemplateIcons } from 'src/state/ducks/templates/editTemplate/actions'
import { RootState } from 'src/state/store'
import { CheckIcon, TemplateIconWrapper } from './EditTemplateOption.styled'

const PopoverIcon: FC<{
  templateIcon: {
    backgroundColor: string
    value: TemplateIconTypeEnum
    svgComponent: React.FunctionComponent<
      React.SVGProps<SVGSVGElement> & {
        title?: string | undefined
      }
    >
    label: string
  }
}> = ({
  templateIcon: { value, backgroundColor, svgComponent: SVG, label },
}) => {
  const dispatch = useDispatch()
  const templateIcons = useSelector(
    (state: RootState) => state.templatesState.editTemplate.icons,
    shallowEqual,
  )

  const onClick = () => {
    const selectedTemplateIcon = templateIcons.find(
      ({ iconType }) => iconType === value,
    )

    if (selectedTemplateIcon !== undefined) {
      // 未調査: こっちの分岐が実行されることはないのでは？
      dispatch(
        updateTemplateIcons(
          templateIcons.filter(({ iconType }) => iconType !== value),
        ),
      )
    } else {
      dispatch(
        updateTemplateIcons([
          ...templateIcons,
          {
            iconType: value,
          },
        ]),
      )
    }
  }

  const isSelected = templateIcons.find(({ iconType }) => iconType === value)

  return (
    <TemplateIconWrapper
      key={value}
      theme={{ backgroundColor }}
      onClick={onClick}
    >
      <SVG width={24} height={24} fill={White} role="img" aria-label={label} />
      {isSelected && <CheckIcon />}
    </TemplateIconWrapper>
  )
}

export default PopoverIcon
