import React from 'react'
import { View } from 'src/components/nativeCompat/View'

interface DividerProps {
  direction?: 'vertical' | 'horizon'
}
export const Divider: React.FC<DividerProps> = props => {
  const style =
    !props.direction || props.direction === 'horizon'
      ? {
          width: '100%',
          height: 1,
          margin: 0,
          padding: 0,
          borderBottomWidth: 1,
          borderBottomColor: 'rgba(0, 0, 0, 0.12)',
        }
      : {
          width: 1,
          height: '100%',
          margin: 0,
          padding: 0,
          borderRightWidth: 1,
          borderRightColor: 'rgba(0, 0, 0, 0.12)',
        }
  return <View style={style} />
}
