import { css } from '@emotion/react'
import {
  TemplateNodeSchema,
  TemplateNodeTypeEnum,
  TemplatePageSchema,
} from '@ulysses-inc/harami_api_client'
import { Dropdown, Modal, Spin } from 'antd'
import React, { FC } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { isSection } from 'src/exShared/util/nodes/isSection'
import { useTogglable } from 'src/hooks/useTogglable'
import {
  deleteTemplatePage as deleteTemplatePageAction,
  startCopyTemplatePage as startCopyTemplatePageAction,
} from 'src/state/ducks/templates/actions'
import { RootState } from 'src/state/store'
import DeleteConfirmDialog from 'src/views/components/dialogs/DeleteConfirmDialog'
import { getRelatedExcelConversionFlowInPage } from '../common/getRelatedExcelConversionFlow'
import { openPreventPageDeletionDialog } from './PreventDeleteDialog'
import {
  DisplaySpin,
  DownOutlinedIcon,
  DropDownContainer,
  DropDownContentButton,
  FilterButton,
  UpOutlinedIcon,
} from './TabEditButton.dumb'

type CloseButtonProps = {
  pageId: number
}

const hasEmployeeCheck = (
  page: TemplatePageSchema,
  nodes: { [key: number]: TemplateNodeSchema },
): boolean => {
  if (!page.nodes) {
    return false
  }

  for (const nodeId of page.nodes) {
    const node = nodes[nodeId]
    if (node?.type === TemplateNodeTypeEnum.Section) {
      if (node.section?.isEmployeeCheck) {
        return true
      }
    }
  }

  return false
}

export const TabEditButton: FC<CloseButtonProps> = ({ pageId }) => {
  const dispatch = useDispatch()
  const templatePages = useSelector(
    (state: RootState) => state.templatesState.templatePages.templatePages,
    shallowEqual,
  )
  const templateNodes = useSelector(
    (state: RootState) => state.templatesState.templatePages.templateNodes,
    shallowEqual,
  )
  const isCopyingPage = useSelector(
    (state: RootState) => state.templatesState.templatePages.isCopyingPage,
  )
  const excelConversionFlows = useSelector(
    (state: RootState) =>
      state.templatesState.editTemplate.excelConversionFlows,
    shallowEqual,
  )
  const templateName = useSelector(
    (state: RootState) => state.templatesState.editTemplate.name,
  )
  const { id: templateId } = useParams<{ id: string }>()

  const dropdown = useTogglable()

  const handleRemove = () => {
    // 最後の1ページの時はページの削除を無効化
    if (Object.keys(templatePages).length <= 1) {
      Modal.warning({
        title: '最後の 1 ページは削除できません',
        okText: '閉じる',
      })

      return
    }
    const nodeIds = templatePages[pageId]?.nodes || []
    // `nodeIds` の中に「セクション」なノードが含まれているので、それを探す
    const sectionId = nodeIds.find(nodeId => {
      const maybeSectionNode = templateNodes[nodeId]
      return maybeSectionNode !== undefined && isSection(maybeSectionNode)
    })

    // 取り込み項目のデータが登録されているページの場合は、削除確認モーダルの生成処理を中断して、削除できない旨をユーザーに伝える
    if (
      sectionId !== undefined &&
      templateNodes[sectionId]?.section?.hasRegisteredVariables
    ) {
      openPreventPageDeletionDialog({
        pageName: templatePages[pageId]?.name || '',
        templateName,
        templateId: parseInt(templateId, 10),
      })

      return
    }

    // 取り込み項目のデータが登録されていないページの場合は、削除確認モーダルの生成処理を継続する

    const content: React.ReactNode[] = []

    if (templatePages[pageId]?.nodes?.length) {
      content.push('紐づく質問やセクションも一緒に削除されます。')
    }

    const flowNames = getRelatedExcelConversionFlowInPage(
      pageId,
      templatePages,
      templateNodes,
      excelConversionFlows,
    )

    if (flowNames.length) {
      content.length &&
        content.push(
          <>
            <br />
            <br />
          </>,
        )

      content.push(
        <>
          以下のExcel変換設定に紐づいています。
          <ul css={styles.relatedExcelFlowList}>
            {flowNames.map((name, index) => (
              <li key={index}>{name}</li>
            ))}
          </ul>
        </>,
      )
    }

    DeleteConfirmDialog({
      title: 'ページを削除してもよろしいですか？',
      content,
      onOk: () => {
        dispatch(deleteTemplatePageAction(pageId))
      },
    })
  }

  const handleCopy = () => {
    const page = templatePages[pageId]
    if (page === undefined) {
      // 型を合わせるための if
      return
    }

    if (hasEmployeeCheck(page, templateNodes)) {
      Modal.error({
        title: `「${page.name}」をコピーできません`,
        content: '従業員チェックのあるページはコピーできません。',
      })
      return
    }

    // NOTE: ページコピーにはソフトリミットが入っているが、そのバリデーションは reducer で行っている
    // ページに紐づく nodes の数をカウントする処理が現状では重く、複雑な処理になるので、実際にコピーを行った後にカウントしたいため。
    dispatch(startCopyTemplatePageAction(pageId))
  }

  const mainArea = (
    <FilterButton>
      <div>{dropdown.isOpen ? <UpOutlinedIcon /> : <DownOutlinedIcon />}</div>
    </FilterButton>
  )

  const hoverArea = (
    <DropDownContainer>
      <div data-testid="template-page-dropdown">
        {isCopyingPage ? (
          <DisplaySpin>
            <Spin size="small" />
          </DisplaySpin>
        ) : (
          <DropDownContentButton onClick={handleCopy}>
            コピー
          </DropDownContentButton>
        )}
        <DropDownContentButton onClick={handleRemove}>
          削除
        </DropDownContentButton>
      </div>
    </DropDownContainer>
  )

  return (
    <Dropdown
      dropdownRender={() => hoverArea}
      trigger={['click']}
      open={dropdown.isOpen}
      onOpenChange={isOpen => (isOpen ? dropdown.open() : dropdown.close())}
    >
      {mainArea}
    </Dropdown>
  )
}

const styles = {
  relatedExcelFlowList: css`
    max-height: 220px;
    padding-left: 20px;
    padding-bottom: 30px;
    overflow-y: scroll;
  `,
}
