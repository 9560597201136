import { PlaceNode, PlaceNodeTypeEnum } from '@ulysses-inc/harami_api_client'

const flattenNodes = (nodes: PlaceNode[]): PlaceNode[] => {
  const flatNodes: PlaceNode[] = []
  nodes &&
    nodes.length &&
    nodes.forEach(node => {
      flatNodes.push(node)
      if (node.nodes && node.nodes.length)
        flatNodes.push(...flattenNodes(node.nodes as PlaceNode[]))
    })
  return flatNodes
}

/**
 * 子ノードを再帰的に取得する
 * @param placeNode
 * @param isIncludeGroup trueの場合取得対象に現場グループを含める
 */
const getNodeIds = (
  placeNode: PlaceNode,
  isIncludeGroup?: boolean,
): string[] => {
  if (!placeNode.nodes) {
    return []
  }
  let nodeIds: string[] = []
  placeNode.nodes.forEach(obj => {
    const node = obj as PlaceNode
    if (node.type === PlaceNodeTypeEnum.PlaceGroup) {
      if (isIncludeGroup) {
        nodeIds.push(node.uuid)
      }
      nodeIds = nodeIds.concat(getNodeIds(node, isIncludeGroup))
    } else {
      nodeIds.push(node.uuid)
    }
  })
  return nodeIds
}

const mergedPlaceNodeIds = (
  placeNodes: PlaceNode[],
  selectedPlaceNodeIds: string[],
  selectedPlaceGroupNodeIds: string[],
  isIncludeGroup?: boolean,
): string[] => {
  const placeNodeIdsMap: { [key: string]: string } = {}
  // 現場グループに紐付く現場の探索
  ;(selectedPlaceGroupNodeIds || []).forEach(groupNodeId => {
    const placeGroupNode = placeNodes.find(
      placeNode => placeNode.uuid === groupNodeId,
    )
    if (placeGroupNode) {
      getNodeIds(placeGroupNode, isIncludeGroup).forEach(
        placeNodeId => (placeNodeIdsMap[placeNodeId] = placeNodeId),
      )
      placeNodeIdsMap[groupNodeId] = groupNodeId
    }
  })
  ;(selectedPlaceNodeIds || []).forEach(
    placeNodeId => (placeNodeIdsMap[placeNodeId] = placeNodeId),
  )

  return Object.keys(placeNodeIdsMap)
}

export { flattenNodes, mergedPlaceNodeIds }
