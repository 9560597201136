import { DeleteOutlined } from '@ant-design/icons'
import {
  ResponseDatetime,
  ResponseDatetimeRuleSubTypeEnum,
} from '@ulysses-inc/harami_api_client'
import { Button, Select } from 'antd'
import { FC, useId } from 'react'
import { isNullish } from 'src/util/isNullish'
import {
  DatetimeRuleContainer,
  DatetimeRuleDayText,
  DatetimeRuleDescription,
  DatetimeRuleInput,
  RuleLabel,
} from './dumb'

interface Props {
  responseDatetimes: ResponseDatetime[]
  updateTemplateNode: (responseDatetime: ResponseDatetime) => void
}

enum RuleValueSign {
  MINUS = -1,
  PLUS = 1,
  NONE = 0,
}

/**
 * 0または正の整数が入力されていた場合のみ、その数値を返し、
 * それ以外が入力されていた場合はundefinedを返す
 *
 * TestのためにExportしている
 *
 * @param inputValue
 * @returns
 */
export const convertOnlyValidInput = (
  inputValue: string | number | null | undefined,
): number | undefined => {
  if (isNullish(inputValue)) {
    return undefined
  }
  const inputNumber = Number(inputValue)
  if (isNaN(inputNumber)) {
    return undefined
  }

  if (inputNumber < 0) {
    return undefined
  }

  if (!Number.isInteger(inputNumber)) {
    return undefined
  }

  return inputNumber
}

/**
 * ルール設定の下部に表示する説明文を生成する
 * 有効なルール設定がなされていない場合、undefinedを返す
 *
 * TestのためにExportしている
 *
 * @param signedRuleValue
 * @param subType
 * @returns
 */
export const makeHintText = (
  signedRuleValue: number | undefined,
  subType: ResponseDatetimeRuleSubTypeEnum | undefined,
): string | undefined => {
  if (isNullish(signedRuleValue)) {
    return undefined
  }
  const defaultSubType =
    subType || ResponseDatetimeRuleSubTypeEnum.LESS_THAN_OR_EQUAL
  const absInputValue = Math.abs(signedRuleValue)
  switch (defaultSubType) {
    case ResponseDatetimeRuleSubTypeEnum.GREATER_THAN_OR_EQUAL: {
      if (signedRuleValue == 0) {
        return `記録日の日付を含んだ、さらに未来の期間を指します。`
      }
      if (signedRuleValue < 0) {
        return `記録日の日付から、${absInputValue}日前の日付を含んだ、さらに過去の期間を指します。`
      }
      return `記録日の日付から、${absInputValue}日後の日付を含んだ、さらに未来の期間を指します。`
    }
    case ResponseDatetimeRuleSubTypeEnum.LESS_THAN_OR_EQUAL: {
      if (signedRuleValue == 0) {
        return `記録日の日付を指します。`
      }
      if (signedRuleValue < 0) {
        return `記録日の日付から、${absInputValue}日前までの範囲の日付を指します。`
      }
      return `記録日の日付から、${absInputValue}日後までの範囲の日付を指します。`
    }
    case ResponseDatetimeRuleSubTypeEnum.EQUAL: {
      if (signedRuleValue == 0) {
        return `記録日の日付を指します。`
      }
      if (signedRuleValue < 0) {
        return `記録日の日付から、${absInputValue}日前の日付を指します。`
      }
      return `記録日の日付から、${absInputValue}日後の日付を指します。`
    }
    default:
      return undefined
  }
}

export const DatetimeRule: FC<Props> = ({
  responseDatetimes,
  updateTemplateNode,
}) => {
  // stateには、符号付で日数を格納する。（その値を良い感じに表示するのはUIコンポーネントの責務である）
  // X日 前 -> マイナス
  // X日 後 -> プラス
  const singedRuleValue = responseDatetimes[0]?.rule?.value
  const unsignedRuleValue = singedRuleValue && Math.abs(singedRuleValue)
  const ruleValueSign = !singedRuleValue
    ? RuleValueSign.NONE
    : singedRuleValue < 0
      ? RuleValueSign.MINUS
      : RuleValueSign.PLUS

  const ruleDescription = makeHintText(
    singedRuleValue,
    responseDatetimes[0]?.rule?.subType,
  )

  const ruleLabelId = useId()

  return (
    <DatetimeRuleContainer>
      <div>
        <RuleLabel id={ruleLabelId} style={{ marginRight: 8 }}>
          ルール設定:
        </RuleLabel>
        <DatetimeRuleInput
          min={0}
          value={unsignedRuleValue}
          onChange={inputValue => {
            const newResponseDateTime: ResponseDatetime = {
              ...responseDatetimes[0],
              rule: {
                ...responseDatetimes[0]?.rule,
                value: convertOnlyValidInput(inputValue),
                subType: responseDatetimes[0]?.rule?.subType
                  ? responseDatetimes[0]?.rule.subType
                  : ResponseDatetimeRuleSubTypeEnum.LESS_THAN_OR_EQUAL,
              },
            }
            updateTemplateNode(newResponseDateTime)
          }}
          aria-labelledby={ruleLabelId}
        />
        <DatetimeRuleDayText>{'日'}</DatetimeRuleDayText>
        <Select
          value={!ruleValueSign ? undefined : ruleValueSign}
          style={{ width: 64, marginLeft: 8 }}
          disabled={ruleValueSign === RuleValueSign.NONE}
          onChange={(newSign: RuleValueSign) => {
            const inputValue = responseDatetimes[0]?.rule?.value
            const signedInputValue =
              inputValue && Math.abs(inputValue) * newSign
            const newResponseDateTime: ResponseDatetime = {
              ...responseDatetimes[0],
              rule: {
                ...responseDatetimes[0]?.rule,
                value: signedInputValue,
              },
            }
            updateTemplateNode(newResponseDateTime)
          }}
          data-testid="datetime-rule-sign-select"
        >
          <Select.Option
            value={RuleValueSign.MINUS}
            data-testid="datetime-rule-sign-select-item"
          >
            前
          </Select.Option>
          <Select.Option
            value={RuleValueSign.PLUS}
            data-testid="datetime-rule-sign-select-item"
          >
            後
          </Select.Option>
        </Select>
        <Select
          value={
            responseDatetimes[0]?.rule?.subType ??
            ResponseDatetimeRuleSubTypeEnum.LESS_THAN_OR_EQUAL
          }
          style={{ width: 120, marginLeft: 8 }}
          onChange={(changeType: ResponseDatetimeRuleSubTypeEnum) => {
            const newResponseDateTime: ResponseDatetime = {
              ...responseDatetimes[0],
              rule: {
                ...responseDatetimes[0]?.rule,
                subType: changeType,
              },
            }
            updateTemplateNode(newResponseDateTime)
          }}
          data-testid="datetime-rule-sub-type-enum-select"
        >
          <Select.Option
            value={ResponseDatetimeRuleSubTypeEnum.LESS_THAN_OR_EQUAL}
            data-testid="datetime-rule-sub-type-enum-select-item"
          >
            以内の日付
          </Select.Option>
          <Select.Option
            value={ResponseDatetimeRuleSubTypeEnum.GREATER_THAN_OR_EQUAL}
            data-testid="datetime-rule-sub-type-enum-select-item"
          >
            以上の日付
          </Select.Option>
          <Select.Option
            value={ResponseDatetimeRuleSubTypeEnum.EQUAL}
            data-testid="datetime-rule-sub-type-enum-select-item"
          >
            と同じ日付
          </Select.Option>
        </Select>
        <Button
          danger
          type="link"
          icon={<DeleteOutlined />}
          size="small"
          onClick={() => {
            const newResponseDateTime: ResponseDatetime = {
              ...responseDatetimes[0],
              rule: undefined,
            }
            updateTemplateNode(newResponseDateTime)
          }}
          style={{ marginLeft: 4 }}
        />
      </div>
      {ruleDescription && (
        <DatetimeRuleDescription>{ruleDescription}</DatetimeRuleDescription>
      )}
    </DatetimeRuleContainer>
  )
}
