import { ClientTypeEnum } from '@ulysses-inc/harami_api_client'
import { getBasePath } from 'src/config'
import { getApiKey } from '../../middleware/saga/getApiKey'

const basePath = getBasePath()

/**
 * File形式の画像群からファイルのアップロードを行う
 * NOTE: webでのファイルアップロードは画面から選択された画像に対してFile形式の画像群を
 * 選んでアップロードを行う。
 * この時にブラウザで動的に「content-type:multipart/form-data」を指定して変換をかけるため
 * 意図的にcontent-typeの指定を削除している
 *
 * @param File[] files File形式の画像群
 */
export const uploadAttachmentFilesRequestByFiles = async (files: File[]) => {
  const formData = new FormData()
  files.forEach((file: File) => {
    formData.append('files[]', file)
  })
  const options: RequestInit = {
    method: 'POST',
    body: formData,
    credentials: 'include',
    headers: {
      'X-API-KEY': getApiKey(),
      ct: `${ClientTypeEnum.WEB}`,
      locationHref: `${location.href}`,
    },
  }
  return fetch(`${basePath}/attachmentFiles`, options)
    .catch(e => {
      throw Error(e)
    })
    .then(handleCriticalErrors)
    .then(uploadAttachmentFile => uploadAttachmentFile.json())
    .then(handleRequestError)
    .catch(error => {
      throw new Error(error.message)
    })
}

const handleCriticalErrors = (res: any) => {
  if (res.ok || res.status === 400) {
    return res
  }

  switch (res.status) {
    case 401:
      throw Error('UNAUTHORIZED')
    case 500:
      throw Error('INTERNAL_SERVER_ERROR')
    case 502:
      throw Error('BAD_GATEWAY')
    case 404:
      throw Error('NOT_FOUND')
    default:
      throw Error('UNHANDLED_ERROR')
  }
}

const handleRequestError = (res: any) => {
  if (res.status === 400) {
    throw Error('ファイルのアップロードに失敗しました')
  }
  return res
}
