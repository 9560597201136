import Icon, {
  CheckOutlined,
  CloseOutlined,
  MinusOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons'
import { css } from '@emotion/react'
import { Tooltip } from 'antd'
import CheckExclamation from 'src/assets/icons/check_exclamation.svg?react'

const data = [
  {
    description: '実施済み',
    icon: <CheckOutlined css={css({ color: '#1e242c40' })} />,
  },
  {
    description: '期限切れで実施',
    icon: <Icon component={CheckExclamation} css={css({ color: '#f59f00' })} />,
  },
  {
    description: '未実施',
    icon: <CloseOutlined css={css({ color: '#f03e3e' })} />,
  },
  {
    description: '予定なし',
    icon: <MinusOutlined css={css({ color: '#1e242c40' })} />,
  },
]

/**
 * 実施状況一覧画面のアイコンに関する凡例をツールチップで表示する
 */
export const StatusIconLegend = () => {
  return (
    <Tooltip
      color="white"
      overlayInnerStyle={{ padding: 0 }}
      placement="bottomRight"
      title={
        <div css={styles.container}>
          {data.map(({ description, icon }) => (
            <div css={styles.eachLegend} key={description}>
              {icon} ：{description}
            </div>
          ))}
        </div>
      }
    >
      <QuestionCircleOutlined css={styles.icon} />
    </Tooltip>
  )
}

const styles = {
  container: css`
    align-items: start;
    color: #1e242c;
    display: flex;
    flex-direction: column;
    font-size: 14px;
    gap: 8px;
    padding: 12px;
  `,
  eachLegend: css`
    display: flex;
    gap: 8px;
  `,
  icon: css`
    color: #1e242c;
    font-size: 16px;
  `,
}
