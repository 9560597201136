import { Switch } from 'antd'
import moment from 'moment'
import React from 'react'
import date from 'src/exShared/util/date'
import { TBaseFormRowProps } from './EditSchedule'
import {
  FormRow,
  PauseCircleOutlinedIcon,
  PlayCircleOutlinedIcon,
} from './EditSchedule.styled'

const ScheduleActivenessRow: React.FC<TBaseFormRowProps> = ({
  setFieldValue,
  values: { isActive, endReportDate },
}) => {
  const isDisabled: boolean = endReportDate
    ? date.isBeforeNow(moment(endReportDate).toDate())
    : false
  const onClick = (): void => {
    setFieldValue('isActive', !isActive)
  }

  return (
    <FormRow>
      <div>
        <Switch
          checkedChildren={
            <>
              <PlayCircleOutlinedIcon /> アクティブ
            </>
          }
          unCheckedChildren={
            <>
              <PauseCircleOutlinedIcon /> 停止中
            </>
          }
          checked={isActive}
          disabled={isDisabled}
          onClick={onClick}
        />
      </div>
    </FormRow>
  )
}

export default ScheduleActivenessRow
