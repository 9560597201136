import { ScheduleTypeEnum } from '@ulysses-inc/harami_api_client'
import { Radio } from 'antd'
import { RadioChangeEvent } from 'antd/lib/radio'
import React from 'react'
import FormikErrorMessage from 'src/components/formikErrorMessage/FormikErrorMessage'
import { TBaseFormRowProps } from './EditSchedule'
import { FormLabel, FormRow, Text } from './EditSchedule.styled'

const ScheduleTypeRow: React.FC<TBaseFormRowProps> = ({
  setFieldValue,
  values: { type },
  errors,
}) => {
  const onChange = (e: RadioChangeEvent) => {
    setFieldValue('frequency', undefined)
    setFieldValue('frequencyWeekly', undefined)
    setFieldValue('frequencyMonthly', undefined)
    setFieldValue('frequencyCustom', undefined)
    setFieldValue('startReportDate', undefined)
    setFieldValue('endReportDate', undefined)
    setFieldValue('type', e.target.value)
  }

  return (
    <FormRow>
      <FormLabel>
        <Text strong>タイプ</Text>
      </FormLabel>
      <div>
        <Radio.Group name="radiogroup" onChange={onChange} value={type}>
          <Radio value={ScheduleTypeEnum.Date}>日にち</Radio>
          <Radio value={ScheduleTypeEnum.Duration}>期間</Radio>
        </Radio.Group>
      </div>
      <div>
        <FormikErrorMessage name="type" errors={errors} />
      </div>
    </FormRow>
  )
}

export default ScheduleTypeRow
