import {
  AttachmentFile,
  ExcelConversionFlow,
  GetExcelConversionFlowsRequest,
  ScheduleForExcelConversionFlow,
} from '@ulysses-inc/harami_api_client'
import { combineReducers } from 'redux'
import {
  ExcelConversionFlowAction,
  ExcelConversionFlowsAction,
} from './actions'
import { ActionTypes } from './types'

interface ExcelConversionFlowsState {
  excelConversionFlows: ExcelConversionFlow[]
  isLoading: boolean
  error: Error | null
  count: number
  request: GetExcelConversionFlowsRequest
}

const initialExcelConversionFlowsState: ExcelConversionFlowsState = {
  excelConversionFlows: [],
  isLoading: false,
  error: null,
  count: 0,
  request: {
    offset: 0,
    limit: 25,
  },
}

const excelConversionFlows = (
  state: ExcelConversionFlowsState = initialExcelConversionFlowsState,
  action: ExcelConversionFlowsAction,
): ExcelConversionFlowsState => {
  switch (action.type) {
    case ActionTypes.REQUEST_GET_EXCEL_CONVERSION_FLOWS: {
      return {
        ...state,
        isLoading: action.isLoading,
        excelConversionFlows: [],
        error: null,
        request: action.request,
      }
    }
    case ActionTypes.SUCCESS_GET_EXCEL_CONVERSION_FLOWS: {
      return {
        ...state,
        isLoading: action.isLoading,
        excelConversionFlows: action.excelConversionFlows || [],
        error: null,
        count: action.count || 0,
      }
    }
    case ActionTypes.ERROR_GET_EXCEL_CONVERSION_FLOWS: {
      return {
        ...state,
        isLoading: action.isLoading,
        error: action.error,
        count: 0,
      }
    }
    case ActionTypes.REQUEST_CHANGE_EXCEL_CONVERSION_FLOWS_PAGE: {
      return {
        ...state,
        request: action.request,
      }
    }
    case ActionTypes.REQUEST_CHANGE_EXCEL_CONVERSION_FLOWS_SIZE: {
      return {
        ...state,
        request: action.request,
      }
    }
    case ActionTypes.SUCCESS_DELETE_EXCEL_CONVERSION_FLOW: {
      return {
        ...state,
        excelConversionFlows: state.excelConversionFlows.filter(
          flow => flow.uuid !== action.flowId,
        ),
        count: state.count - 1,
      }
    }
    case ActionTypes.ERROR_DELETE_EXCEL_CONVERSION_FLOW: {
      return {
        ...state,
        isLoading: action.isLoading,
        error: action.error,
      }
    }
    default: {
      return { ...state }
    }
  }
}

interface ExcelConversionFlowState {
  excelConversionFlow: ExcelConversionFlow | null
  attachmentFile: AttachmentFile | null
  schedules: ScheduleForExcelConversionFlow[]
  isLoading: boolean
  isScheduleLoading: boolean
  error: Error | null
}

const initialExcelConversionFlowState: ExcelConversionFlowState = {
  excelConversionFlow: null,
  attachmentFile: null,
  schedules: [],
  isLoading: false,
  isScheduleLoading: false,
  error: null,
}

const excelConversionFlow = (
  state: ExcelConversionFlowState = initialExcelConversionFlowState,
  action: ExcelConversionFlowAction,
): ExcelConversionFlowState => {
  switch (action.type) {
    case ActionTypes.REQUEST_GET_EXCEL_CONVERSION_FLOW: {
      return {
        ...state,
        isLoading: action.isLoading,
        excelConversionFlow: null,
      }
    }
    case ActionTypes.SUCCESS_GET_EXCEL_CONVERSION_FLOW: {
      return {
        ...state,
        isLoading: action.isLoading,
        excelConversionFlow: action.flow,
      }
    }
    case ActionTypes.ERROR_GET_EXCEL_CONVERSION_FLOW: {
      return {
        ...state,
        isLoading: action.isLoading,
        error: action.error,
      }
    }
    case ActionTypes.ADD_EXCEL_ATTACHMENT_FILE: {
      return {
        ...state,
        attachmentFile: action.file,
      }
    }
    case ActionTypes.REQUEST_GET_SCHEDULES: {
      return {
        ...state,
        schedules: [],
        isScheduleLoading: true,
      }
    }
    case ActionTypes.SUCCESS_GET_SCHEDULES: {
      return {
        ...state,
        schedules: action.schedules,
        isScheduleLoading: false,
      }
    }
    case ActionTypes.ERROR_GET_SCHEDULES: {
      return {
        ...state,
        isScheduleLoading: false,
      }
    }
    case ActionTypes.RESET_EXCEL_CONVERSION_FLOW: {
      return {
        ...state,
        excelConversionFlow: null,
        attachmentFile: null,
      }
    }
    default: {
      return { ...state }
    }
  }
}

const excelConversionFlowsReducer = combineReducers({
  excelConversionFlows,
  excelConversionFlow,
})

export default excelConversionFlowsReducer
