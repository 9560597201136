import { FC, memo } from 'react'
import { useSelector } from 'react-redux'
import { selectOriginalSectionNodeIds } from 'src/state/ducks/reports/reportResult/grid/selectors'
import { GridSection } from './GridSection'

type Props = {
  pageId: number
}

const PageContentForGridLayoutComponent: FC<Props> = ({ pageId }) => {
  const originalSectionIds: number[] = useSelector(
    selectOriginalSectionNodeIds(pageId),
  )

  return (
    <>
      {originalSectionIds.map(originalSectionId => (
        <GridSection
          key={originalSectionId}
          pageId={pageId}
          originalSectionNodeId={originalSectionId}
        />
      ))}
    </>
  )
}

export const PageContentForGridLayout = memo(PageContentForGridLayoutComponent)
