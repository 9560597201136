import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
  InboxOutlined,
} from '@ant-design/icons'
import { ImproveStatusStatusEnum } from '@ulysses-inc/harami_api_client'
import { Danger, Success, Warning } from 'src/features/theme/KdsThemeColor'

export const getStatusName = (
  status: ImproveStatusStatusEnum,
  rejectExists: boolean,
  reviseExists: boolean,
) => {
  switch (status) {
    case ImproveStatusStatusEnum.AUDITOR_WORKING:
      if (reviseExists) {
        return '改善確認中'
      }
      return '指摘入力待ち'
    case ImproveStatusStatusEnum.PLACE_USER_WORKING:
      if (rejectExists) {
        return '差し戻し'
      }
      return '指摘確認中'
    case ImproveStatusStatusEnum.COMPLETE:
      return '完了'
  }
}

// エクセル変換後のダウンロードファイル詳細画面 ではステータスは以前の method を用いる。
export const getStatusNameForDownload = (status: ImproveStatusStatusEnum) => {
  switch (status) {
    case ImproveStatusStatusEnum.AUDITOR_WORKING:
      return '指摘入力待ち'
    case ImproveStatusStatusEnum.PLACE_USER_WORKING:
      return '改善入力待ち'
    case ImproveStatusStatusEnum.COMPLETE:
      return '完了'
  }
}

export const getStatusColor = (status: ImproveStatusStatusEnum) => {
  switch (status) {
    case ImproveStatusStatusEnum.AUDITOR_WORKING:
      return Danger
    case ImproveStatusStatusEnum.PLACE_USER_WORKING:
      return Warning
    case ImproveStatusStatusEnum.COMPLETE:
      return Success
  }
}

export const getStatusIconComponent = (status: ImproveStatusStatusEnum) => {
  switch (status) {
    case ImproveStatusStatusEnum.AUDITOR_WORKING:
      return ExclamationCircleOutlined
    case ImproveStatusStatusEnum.PLACE_USER_WORKING:
      return InboxOutlined
    case ImproveStatusStatusEnum.COMPLETE:
      return CheckCircleOutlined
  }
}
