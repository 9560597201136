import { GlobalNavigation } from 'src/features/globalNavigation/GlobalNavigation'
import Reports from 'src/features/reports/Reports'
import { ReportList } from 'src/features/reports/listBeta/ReportList'
import { useShinReportPreference } from 'src/features/reports/listBeta/featurePreview/useShinReportPreference'

export const TempReportListPageSwitcher = () => {
  const [preferShinReport] = useShinReportPreference()

  if (preferShinReport) {
    // サイドメニューがリファクタ途中のため、ここでサイドメニューを入れざるを得ない
    return (
      <GlobalNavigation>
        <ReportList />
      </GlobalNavigation>
    )
  }

  return <Reports />
}
