import { FC } from 'react'
import { CellInner } from '../SectionTable.dumb'
import { TableBodyCellWrapper } from './Common.dumb'

/**
 * 条件分岐で選ばれなかったほうのセル
 */
export const TableBodyCellDisabled: FC = () => {
  return (
    <TableBodyCellWrapper fontColor="notInputted" backgroundColor="fixed">
      <CellInner>ー</CellInner>
    </TableBodyCellWrapper>
  )
}
