import { HTTPError } from '../../middleware/saga/handleHttpError'
import { ActionTypes } from './types'

const handleHttpError = (error: HTTPError | Error) => {
  return {
    type: ActionTypes.HANDLE_HTTP_ERROR,
    error,
  }
}

export default {
  handleHttpError,
}
