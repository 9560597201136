import Icon from '@ant-design/icons'
import { Modal } from 'antd'
import { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import ExternalLinkSvg from 'src/assets/icons/external_link.svg?react'
import { clearTemplateDeleteErrorTemplateId } from 'src/state/ducks/templateList/actions'
import { RootState } from 'src/state/store'
import styled from 'styled-components'

const ModalTitle = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 23px;
`

const Link = styled.a`
  text-decoration: underline;
`

const ModalContentWrapper = styled.div`
  margin-top: 8px;
`

const ModalLinkWrapper = styled.div`
  margin-top: 8px;
`

const selectTemplateName = (templateId: number | undefined) =>
  createSelector(
    (state: RootState) => state.templateListState.templates,
    templates => {
      if (!templateId) {
        return ''
      }
      return templates.find(template => template.id === templateId)?.name
    },
  )

/**
 * 削除時のエラーモーダル表示用のコンポーネント
 * @returns
 */
export const DeleteErrorModal: FC = () => {
  const dispatch = useDispatch()

  const templateDeleteErrorTemplateId = useSelector(
    (state: RootState) => state.templateListState.templateDeleteErrorTemplateId,
  )
  const templateName = useSelector(
    selectTemplateName(templateDeleteErrorTemplateId),
  )
  if (!templateDeleteErrorTemplateId) {
    return <></>
  }

  Modal.error({
    onOk: () => dispatch(clearTemplateDeleteErrorTemplateId()),
    title: <ModalTitle>「{templateName}」の削除が出来ません</ModalTitle>,
    width: 417,
    content: (
      <ModalContentWrapper>
        <div>取り込み項目データがすでに登録されていたので削除できません。</div>
        <ModalLinkWrapper>
          <Link
            href={`/gridVariables/${templateDeleteErrorTemplateId}/edit`}
            target="_blank"
          >
            {`「${templateName}」の取り込み項目`}
            <sup style={{ paddingLeft: '4px' }}>
              <Icon component={ExternalLinkSvg} />
            </sup>
          </Link>
        </ModalLinkWrapper>
      </ModalContentWrapper>
    ),
    okText: '閉じる',
  })

  return <></>
}
