import { Image, View } from '@react-pdf/renderer'
import { Style } from '@react-pdf/types'
import React from 'react'

interface OwnProps {
  uri: string
  style?: Style | Style[]
}

const ReactPDFImage: React.FC<OwnProps> = (props: OwnProps) => {
  const { uri, style } = props
  return (
    <View>
      <Image
        style={style}
        src={{
          uri: uri,
          method: 'GET',
          body: undefined,
          // no-cacheを付けている理由
          // 特定の条件で CORS エラーが発生してしまう（ https://kaminashi.atlassian.net/browse/HPB-3617 ）
          // 原因（ https://qiita.com/mikan3rd/items/51da67a1402e27895a23 ）
          //  S3の画像URLを使用している場合、かつ、Chromeでブラウザのcacheが有効になっている場合、
          //  2度目のアクセス時にAccess-Control-Allow-OriginHeaderの付いていないキャッシュしたレスポンスをChromeが返すため、
          //  CORSエラーが発生してしまう
          headers: { 'Cache-Control': 'no-cache' },
        }}
      />
    </View>
  )
}

export default ReactPDFImage
