/**
 * 画面ごとに Query Key を独立して管理するためのキー
 *
 * - 各キーの頭にくっつけて使う
 * - 重複を避けるためここで一元管理する
 *
 * TODO: 配列である必要はなかったかもしれない
 */
export const queryKeyPrefix = {
  common: ['common'], // 複数の画面をまたいで共通的に利用するデータ (e.g. ユーザー情報やマスタデータなど)
  reportExportList: ['ui:reportExportList'], // レポート出力一覧画面
  reportExportDetail: ['ui:reportExportDetail'], // レポート出力依頼画面
  featureFlags: ['featureFlags'], // 画面表示制御に使う Feature Flags
  shinReportList: ['ui:shinReportList'], // シン・レポート一覧画面
  shinReportListFilters: ['ui:shinReportListFilters'], // シン・レポート一覧画面のうちフィルタ部分
  assignPlaceModal: ['ui:assignPlaceModal'],
  scheduledReportSummary: ['ui:scheduledReportSummary'],
} as const
