import { StyleSheet, Text, View } from '@react-pdf/renderer'
import {
  NumberValue,
  ResponseNumber,
  ResponseNumberSubTypeEnum,
} from '@ulysses-inc/harami_api_client'
import React from 'react'
import { convertDecimal } from 'src/exShared/util/numberDecimalPoint/numberDecimalPoint'
import { convertRuleCharacters } from 'src/exShared/util/numberRule/convertDisplayCharacters'
import BreakText from '../../BreakText'
import Col from '../../Col'
import Grid from '../../Grid'
import RecordedTime from './RecordedTime'

interface OwnProps {
  questionName: string
  questionScale?: string
  responseNumber?: ResponseNumber
  numberValue?: NumberValue
  recordedAt?: Date
  isTimeDisplayed: boolean
}

const styles = StyleSheet.create({
  gridLeft: { width: '70%' },
  gridRight: { width: '30%' },
  numberGrid: { width: '100%' },
  answerNumberValueWrap: { width: '100%', marginBottom: 5 },
  answerNumberValueText: { paddingLeft: 5, paddingTop: 5 },
  answerNumberRule: { color: '#656565', paddingLeft: 5 },
  recordedTimeWrap: { width: '100%', marginBottom: 5 },
})

const Number: React.FC<OwnProps> = (props: OwnProps) => {
  const isAnswered = props.numberValue?.numberValue !== undefined
  const value = convertDecimal(
    props.numberValue?.numberValue || 0,
    props?.responseNumber?.decimalPoint,
  )
  return (
    <Grid style={styles.numberGrid}>
      <Col borderLeft borderRight style={styles.gridLeft}>
        <BreakText text={props.questionName} />
      </Col>
      <Col borderRight style={{ ...styles.gridRight }}>
        <View style={styles.answerNumberValueWrap}>
          <Text style={styles.answerNumberValueText}>
            {`回答: ${
              isAnswered ? `${value} ${props.questionScale ?? ''}` : '未回答'
            }`}
          </Text>
          {props.responseNumber?.subType !== undefined &&
            Object.values(ResponseNumberSubTypeEnum).includes(
              props.responseNumber.subType,
            ) && (
              <Text style={styles.answerNumberRule}>
                (
                {convertRuleCharacters(
                  props.questionScale ?? '',
                  props.responseNumber,
                )}
                )
              </Text>
            )}
        </View>
        {isAnswered && (
          <View style={styles.recordedTimeWrap}>
            <RecordedTime
              isTimeDisplayed={props.isTimeDisplayed}
              recordedAt={props.recordedAt}
            />
          </View>
        )}
      </Col>
    </Grid>
  )
}

export default Number
