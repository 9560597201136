import * as Sentry from '@sentry/browser'
import {
  setUserProperties as setFireBaseUserProperties,
  setUserId,
} from 'firebase/analytics'
import { localStorageKeys } from 'src/constants/localStorageKeys'
import { firebaseAnalytics } from './firebase'

//
// NOTE:
// 当処理実装時点で、Mobile 側ですでに実装されていた部分と挙動を合わせる
// BigQuery などでは field 名は、スネークケースで統一されている模様。
// （例：user_id、event_timestamp など）
// UserProperties の各フィールドも、スネークケースで統一するのが良かったと思われる。
type UserProperties = {
  userId: string
  userName: string
  companyId: number
  companyName: string
}

/**
 * localStorage から UserProperties を生成して、
 * FireBase Analytics をセットアップする。
 *
 * ログインユーザーのID（ localStorage上は、'loginUserId' というキーで格納される）が
 * 存在する場合のみ、セットアップ処理を行う
 *
 * localStorage にアクセスしている副作用のある処理なので、
 * useEffect や イベントハンドラ内で呼び出すこと
 *
 * @returns
 */
export const setUserPropertiesFromLocalStorage = () => {
  // firebaseAnalytics 自体が初期化されていない場合は処理をしない
  if (!firebaseAnalytics) {
    return
  }

  const userProperties = createUserPropertiesFromLocalStorage()
  if (!userProperties) {
    return
  }
  setUserProperties(userProperties)
}

/**
 * Firebase Analytics のための userId、properties を設定する
 *
 * @param userProperties
 * @returns
 */
const setUserProperties = (userProperties: UserProperties) => {
  try {
    // Firebase Analytics の処理が失敗した場合であっても、
    // エンドユーザーの操作に影響を与えるのは適当ではないため、すべてのエラーをキャッチする
    setUserId(firebaseAnalytics, userProperties.userId)
    setFireBaseUserProperties(
      firebaseAnalytics,

      /* 当処理実装時点で、Mobile 側ですでに実装されていた部分と挙動を合わせ、すべて文字列に変換する */
      convertAllValuesToString(userProperties),
    )
  } catch (e) {
    Sentry.captureException(e)
  }
}

const convertAllValuesToString = (object: {
  [key in keyof UserProperties]: unknown
}) => {
  const res = {} as { [key in keyof UserProperties]: string }
  const keys = Object.keys(object) as (keyof UserProperties)[]

  keys.forEach(key => {
    res[key] = String(object[key])
  })

  return res
}

/**
 * localStorage に保存されているデータから、UserProperties を生成する。
 * ログインユーザーのID ( userId ) が falsy の場合、undefined を返す
 *
 * @returns
 */
const createUserPropertiesFromLocalStorage = (): UserProperties | undefined => {
  const userId = localStorage.getItem(localStorageKeys.loginUserUuid)
  if (!userId) {
    return undefined
  }
  const userName = localStorage.getItem(localStorageKeys.loginUserName) || ''
  const companyId =
    Number(localStorage.getItem(localStorageKeys.loginCompanyId)) || 0
  const companyName =
    localStorage.getItem(localStorageKeys.loginCompanyName) || ''

  return {
    userId,
    userName,
    companyId,
    companyName,
  }
}
