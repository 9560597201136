import { parseISO } from 'date-fns'
import { toDateString } from 'src/util/datetime/toDateString'

/**
 * 与えられた日時の時刻部分を00:00:00にして返す。
 * 計算は実行環境のタイムゾーンにもとづき行われる。
 */
export const stripTimeInfo = (date: Date) => {
  return parseISO(toDateString(date))
}
