import { Text } from '@react-pdf/renderer'
import { Image } from '@ulysses-inc/harami_api_client'
import { FC } from 'react'
import { gridLayoutTableBodyCell } from '../../../shared/styles'

type Props = {
  value?: Image[]
}

export const TableBodyCellResultImage: FC<Props> = ({ value }) => {
  return (
    <Text
      style={[
        gridLayoutTableBodyCell.baseTableBodyCell,
        gridLayoutTableBodyCell.tableBodyCell,
      ]}
    >
      {value ? `${value.length}枚` : '0枚'}
    </Text>
  )
}
